import React from "react";
import {
  Box,
  // Customizable Area Start
  TypographyProps,
  Container,
  Grid,
  Typography, Link,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Header from "../../../components/src/Header.web";
import Select from "@material-ui/core/Select";
import { ChangeEvent} from "react";
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import SideBar from "../../../components/src/SideBar";
import Footer from "../../../components/src/Footer";
import Loader from "../../../components/src/Loader.web";
import InviteVendorList from "../../../components/src/InviteVendorList";
import Pagination from '@material-ui/lab/Pagination';
import { styled } from "@material-ui/core/styles";
import InviteVendorListPDController,{Props} from "./InviteVendorLIstPDController";
const SearchIcon1 = styled(SearchOutlinedIcon)({
  padding: "10px 0 10px 13px",
  "@media(max-width: 500px)": {
    padding: "10px 0 10px 8px",
    fontSize: '18px'
  },
});
const SelectBox1 = styled(Select)({
  padding: '5px 15px',
  borderRadius: '25px',
  border: '1px solid #e3eaf4',
  background: 'white',
  zIndex: 1,
  marginTop: 0,
  "& :focus": {
    background: 'white',
  }
}); const SearchInput1 = styled('input')({
  fontSize: "15px",
  outline: "none",
  border: "none",
  width: "85%",
  padding: "10px 3px",
  backgroundColor: "#F8FAFE",
  "@media(max-width: 500px)": {
    fontSize: '12px',
    overflow: 'hidden',
    width: "70%",
    padding: "10px 0px",
  },
});

// Customizable Area End
export default class InviteVendorListPD extends InviteVendorListPDController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
    
  RequestProfileProjectDetail() {
    let projectDetail;
    if (this.state.Status === "ongoing") {
        projectDetail = "Ongoing Projects";
    } else if (this.state.Status === "upcoming") {
        projectDetail = "Upcoming Projects";
    } else {
        projectDetail = "All Projects";
    }

    return projectDetail;
}

renderInviteVendorList(isJobRoleideBarStatusPH: boolean  = this.state.isJobRoleRequestSideBarOpen) {
    return (
    <Grid style={webStyle.contentSetupAllinvoice}>
          <Loader loading={this.state.isLoading}></Loader>
        < Header
            navigate={this.props.navigation.navigate}
            sideBarStatus={isJobRoleideBarStatusPH}
            dropDownStatus={this.state.isJobRoleRequestHeaderDropdownOpenVal}
            handleDropdown={this.handleJobRoleHeaderDropdownVal}
            handleSideBar={this.handleJobRoleSideBar}  
        />
        <Grid style={webStyle.mainContentAllinvoice as React.CSSProperties}>
            <Box sx={webStyle.blockInfoAllinvoice}>
                <Box sx={webStyle.headInfoAllinvoice}>
                    <Box>
                        <Box sx={webStyle.activeBlockAllinvoice}>
                            <Typography variant="h3" style={webStyle.activeBlockAllinvoice as TypographyProps}><div style={{ fontSize: "15px" }}></div>Invite Vendors</Typography>
                        </Box>
                        <Typography variant="h6" style={webStyle.blockPathAllinvoice as TypographyProps}><span style={webStyle.highlightedTextAllinvoice} data-test-id="home-navigation" onClick={() => this.handleNavigation("Projects")}>Home</span>
                        <span style={webStyle.highlightedTitleextRequestProfile}>
                                <Link data-test-id="studiosBookingList" color="inherit"  onClick={() => this.handleNavigation("Projects")}>
                                / {this.RequestProfileProjectDetail()}
                                </Link>
                            </span>
                            <span style={webStyle.highlightedTitleextRequestProfile}>
                                <Link data-test-id="studiosBookingList1" color="inherit"  onClick={() => this.handleNavigation("ProjectDetailPH")}>
                                / {this.state.jobRoleprojectName}
                                </Link>
                            </span>
                            <span style={webStyle.highlightedTitleextRequestProfile}>
                                <Link data-test-id="studiosBookingList2" color="inherit"  onClick={() => this.handleNavigation("ProjectDetailJobRoleandRequest")}>
                                / All Job Roles & Requests
                                </Link>
                            </span>
                            / Invite Vendors
                            </Typography>
                    </Box>
                    <Box sx={webStyle.headerSearchBoxdropdown}>
                <SearchIcon1 style={webStyle.searchicon} />
                <SearchInput1  placeholder={`Search`}
                  style={{
                    fontSize: "14px", fontWeight: 500,
                    fontFamily: "Urbanist", letterSpacing: "0.25px"
                  }}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => this.filterHouses(event)} data-testid='search'/>
              </Box>
                 
                </Box>
               
            </Box>
             <Box className="tabsContentAllInvoice" style={webStyle.tabsContentAllInvoice as React.CSSProperties}  >
                {
               <Grid container spacing={3} style={{ paddingLeft: "12px", paddingRight: "12px" }} >
               { this.state.vendorList.length?(this.state.vendorList&&this.state.vendorList?.map((item:any,index:number) => (
                 <Grid   item xs={12} sm={6} md={4}   key={`${item}_ InviteVendor card`} data-testid='navigatevendorlist' onClick={()=>this.handleNavigationRequestProfile(item.id,item.attributes.profile_id)} >
                   <InviteVendorList data={item} />
                 </Grid>
               ))
               ):
                <Typography style={{ width: '100%', textAlign: 'center', marginTop: '30px', marginBottom: '30px', color: '#6d89af', fontFamily: 'Urbanist', fontSize: 14, fontWeight: 600 }}>No record available</Typography>
           
               }
             </Grid>
                }
                </Box>
                {this.state.count ?
                        (<Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Pagination style={{ marginTop: "30px",display:'flex',justifyContent:'center' }} count={Math.ceil((this.state.count) / 9)} page={this.state.currentpage} onChange={this.handlePageChange} />
                            </Grid>
                        </Grid>)
                        : null}
                </Grid>
        <Footer />
    </Grid>
    )
}



  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Container maxWidth="xl" className="user_detail_container">
        {
          this.state.isJobRoleRequestSideBarOpen ?
            <Grid container spacing={0}>
              <Grid style={webStyle.sideBarJobRoleRequest} item xs={3}>
                <SideBar
                  userData={{ age: 25 }}
                  activeMenuItem={"Projects"}
                  navigate={this.props.navigation.navigate}
                  handleSideBar={this.handleJobRoleSideBar} />
              </Grid>
              <Grid item
                xs={9}>{this.renderInviteVendorList(this.state.isJobRoleRequestSideBarOpen)}</Grid>
            </Grid> :
            <Grid item xs={12}>
              {
                this.renderInviteVendorList(this.state.isJobRoleRequestSideBarOpen)
              }
            </Grid>
        }
      </Container >
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = {
  contentSetupAllinvoice: {
    borderLeft: "1px solid #E3EAF4",
  },
  sideBarJobRoleRequest: {
    minHeight: "100vh",
    maxWidth: "23%",
  },
  blockInfoAllinvoice: {
  },
  headerSearchBoxdropdown: {
    display: "flex",
    border: '1px solid #e3eaf4',
    minWidth: "260px",
    borderRadius: "35px",
    alignItems: "center",
    backgroundColor: "#ffffff",
    overflowX: 'hidden',
    height: "44px",
    "@media screen and (max-width: 500px)": {
      minWidth: "100px",
      width: '160px',
      marginRight: "0px",
    }
  },
  searchicon: {
    marginRight: '6px',
    fill: '#6D89AF',
    fontSize: "22px"
  },
  blockPathAllinvoice: {
    fontSize: "14px",
    letterSpacing: "1px",
    color: "#6D89AF",
    marginBottom: "24px",
    fontWeight: 500
  },
  highlightedTextAllinvoice: {
    color: "#F5C42C",
    textDecoration: 'underline',
    textDecorationColor: "#F5C42C",
    fontWeight: 600,
    cursor: "pointer"
  },
  activeBlockAllinvoice: {
    fontSize: "28px",
    fontWeight: "600",
    letterSpacing: "1px",
    display: "flex",
    flexDirection: "row",
    lineHeight: "33px",
    alignItems: 'center',
    marginBottom: '5px',
  },
  highlightedTitleextRequestProfile: {
    fontFamily: "Urbanist",
    color: "#F5C42C",
    textDecoration: "underline",
    cursor: "pointer",
  },
  mainContentAllinvoice: {
    width: "100%",
    backgroundColor: "#F8FAFE",
    boxSizing: "border-box",
    padding: "24px",
    height: "100%"
  },
  tabsContentAllInvoice: {
    paddingTop: "16px",
    // paddingLeft:"24px",
    display: "flex",
    gap: "1.5rem",
    flexWrap: "wrap",
    marginBottom: '15px'
  },
  headInfoAllinvoice: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 15,
  },
  jobRequestHeadButton: {
    minWidth: '161px',
    borderRadius: '14px',
    background: '#17181d',
    color: '#ffffff',
    fontFamily: 'urbanist',
    fontSize: '15px',
    fontWeight: 600,
    letterSpacing: ' 0.25px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '15px 10px',
    cursor: 'pointer',
  },
};
// Customizable Area End


