import React from "react";
import { Card, Box, CardMedia, CardContent, Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { JobCardImage, LocationIconjob, rupeeiconjob, dotsIcon, headingicon, Bg_Copy, leaveproject, editstudio, BookingOpen, BookingClose, studiodelete } from "./assets";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreHoriz";
import Menu from "@material-ui/core/Menu";
interface MyProps {
    data: any;
    handleBadgeVisibility:any
    deletestudio:any;
    Editstudio:any

}



const useStyles = makeStyles({
    cardDivWithOpenSideBar: () => ({
        width: 300,
        //height: 240,
        borderRadius: 14,
        padding: 8,
        border: "1px solid #E1E6EC",
        position: 'relative',
        '& .MuiCardContent-root': {
            padding: 0
        },
        "@media(max-width: 500px)": {

        },
        "@media(min-width: 1440px)": {
            width: '312px',
        },
        backgroundImage: `url(${Bg_Copy})`, // Assuming JobCardImage is the path to your image
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: 200,
    }),
    cardDivWithClosedSideBAr: () => ({
        width: 346,
        borderRadius: 14,
        padding: 8,
        border: "1px solid #E1E6EC",
        position: 'relative',
        '& .MuiCardContent-root': {
            padding: 0
        },
        "@media(max-width: 500px)": {

        },
    }),
    parentBoxValue: () => ({
        display: "flex",
        justifyContent: 'space-between',



    }),
    parentBox: () => ({
        height: "177px",
        borderRadius: "12px",
        boxShadow: "0px 7px 18px rgba(24,39,42,0.07)",
        cursor: "pointer",
    }),
    imageContainer: () => ({
        height: "100%",
        width: "100%",
        position: "relative",
    }),
    studioImage: () => ({
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        objectFit: "cover",
        borderRadius: "12px",
        backgroundColor:"grey"
    }),
    studioStatus: () => ({
        position: "absolute",
        top: "10px",
        right: "10px",
        textAlign: "end",
        height: "23px",
    }),
    studioDetailsMainBox: () => ({
        position: "absolute",
        bottom: "5px",
        right: "5px",
        left: "5px",
        background: "#fff",
        height: 95,
        borderRadius: "10px",
        opacity: 0.8290272,
        padding: 12,
        boxSizing: "border-box",
    }),
    studioDetails: () => ({
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    }),
    ParentSubheading1: () => ({
        display: "flex",
        gap: "5px",

    })
    ,
    backgroundImage: {
        // Adjust the height according to your requirement
    },
    humbergerparent:{
        display:'flex',
        paddingLeft:'23px',
        alignItems:'center',
        gap:'10px',
        paddingBottom:'16px',
      },
      humbergerParentIcon:{
       
      },  humbergericon:{
        height:'16px',
        width:'16px',
        cursor:'pointer'
      },
      humbergerLabel:{
        color: '#17181d',
  fontFamily: 'Urbanist',
  fontSize: '14px',
  fontWeight: 600,
  cursor:'pointer'

      }

});

export default function StudioBookingListPH(props: MyProps) {
    const {data,handleBadgeVisibility,deletestudio,Editstudio}=props

    const classes = useStyles();
    const ITEM_HEIGHT = 48;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openhumberger = Boolean(anchorEl);
    const handleClick = (event:any) => {
        event.stopPropagation();
      setAnchorEl(event.currentTarget);
    };
    const handleClosehumberger = () => {
        
        setAnchorEl(null);
      };
      const options = [
        
        { label: "Edit Studio", icon: editstudio },
        { label: "Remove Studio", icon: studiodelete }
      ];
      const handleOptionClick = (event:any, label:string) => {
        event.stopPropagation();
        handleClosehumberger(); // Close the dropdown
      
        // Perform different actions based on the clicked label
        if(label === 'Edit Studio')
        {
            Editstudio(data?.id)
        }
        else if (label === 'Remove Studio') {
            deletestudio(data?.id)
        } 
        
      };
      
    return (
        <Box className={classes.parentBox}>
            <Box className={classes.imageContainer}>
                <img className={classes.studioImage} src={
                    data?.attributes?.image} />
                <Box className={classes.studioStatus}>
                    {/* {props.data.attributes.status === "Available" ? <Box style={{ minHeight: "23px", display: "flex", alignItems: "center", justifyContent: "center", background: "#3389ff", width: "73px", borderRadius: "12px" }}>
                        <Typography style={{ fontWeight: 700, color: "#ffffff", lineSpacing: "0.18181819px", fontSize: "10px", fontFamily: "Urbanist", lineHeight: "12px" } as React.CSSProperties}>{props.data.attributes.status.toUpperCase()}</Typography>
                    </Box> : <Box style={{ minHeight: "23px", display: "flex", alignItems: "center", justifyContent: "center", background: props.data.attributes.status === "Not Available" ? "#000000" : "#f5c42c", width: props.data.attributes.status === "Not Available" ? "92px" : "64px", borderRadius: "12px" }}>
                        <Typography style={{ fontWeight: 700, color: "#ffffff", lineSpacing: "0.18181819px", fontSize: "10px", fontFamily: "Urbanist", lineHeight: "12px" } as React.CSSProperties}>{props.data.attributes.status.toUpperCase()}</Typography>
                    </Box>} */}
     
     <Box style={{width:'25px',height:'25px',background:"#000000",borderRadius:'17.5px',display:'flex',justifyContent:'center',alignItems:'center'}}>                         
        <div style={{left:'468px'}}>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={openhumberger ? "long-menu" : undefined}
        aria-expanded={openhumberger ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        style={{color:'white'}}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={openhumberger}
        onClose={handleClosehumberger}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
            left:'489px'
          },
        }}
      >
        {options.map((option, index) => (
        <div key={index} onClick={(e) => handleOptionClick(e, option.label)} >
           <Box className={classes.humbergerparent}>
            <Box className={classes.humbergerParentIcon}>
          <img src={option.icon} alt="" className={classes.humbergericon} />
          </Box>
          <Box className={classes.humbergerLabel}>
          {option.label}
          </Box>
          </Box> 
        </div>
      ))}
      </Menu>
    </div>
    </Box>
                </Box>
                <Box className={classes.studioDetailsMainBox}>
                    <Box className={classes.studioDetails}>
                        <Box style={{ maxWidth: "80%" }}>
                            <Typography style={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", fontWeight: 500, color: "#17181d", lineSpacing: "0.10909091px", fontSize: "12px", fontFamily: "Urbanist", lineHeight: "14px" } as React.CSSProperties}>{data.attributes.address}, {data.attributes.city} ({data.attributes.state})</Typography>
                            <Typography style={{ marginTop: 2, fontSize: "16px", fontFamily: "Urbanist", lineHeight: "20px", lineSpacing: "0.2px", fontWeight: 700, display: "flex", alignItems: "center", color: "#17181d" } as React.CSSProperties}>{data?.attributes?.name}</Typography>
                        </Box>
                        <Box>
                            <Typography style={{ maxWidth: "81px", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", width: 43, fontWeight: 400, lineSpacing: "0.2px", color: "#42a200", fontSize: "12px", fontFamily: "Helvetica-Bold", lineHeight: "16px" } as React.CSSProperties}>₹{data?.attributes?.price_per_day}</Typography>
                            <Typography style={{ width: 43, fontWeight: 400, lineSpacing: "0.2px", color: "#42a200", fontSize: "12px", fontFamily: "Helvetica-Bold", lineHeight: "16px" } as React.CSSProperties}>Per Day</Typography>
                        </Box>
                    </Box>
                    <Box style={{ marginTop: 10.5, marginBottom: 6.5, borderTop: "1px solid #6d89af", opacity: 0.2916434 }}></Box>
                    <Box className={classes.studioDetails}>
                        <Box style={{ width: "100%", paddingRight: "5px", boxSizing: "border-box" }}>
                            {
                                data?.attributes?.booking_permission?(
                                    <Typography style={{color: '#17181d' ,fontFamily:'Urbanist',fontSize: '12px',fontWeight: 700,letterSpacing: '0.10909091px',width:'100%'} as React.CSSProperties}>Open for Booking</Typography>
                                ):(
                                    <Typography style={{color: '#17181d' ,fontFamily:'Urbanist',fontSize: '12px',fontWeight: 700,letterSpacing: '0.10909091px',width:'100%'} as React.CSSProperties}>Closed for Booking</Typography>
                                )
                            }
                          
                        </Box>
                        {
                            data?.attributes?.booking_permission ?(
                                <Box  onClick={(e: any) => handleBadgeVisibility(e, data.id, data.attributes.booking_permission, "studios")}>
                                <img src={BookingOpen} alt="Shape" />
                                </Box>
                            ):(
                                <Box  onClick={(e: any) => handleBadgeVisibility(e, data.id, data.attributes.booking_permission, "studios")}>
                        <img src={BookingClose} alt="Shape" />
                        </Box>
                            )
                        }
                        
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
