import React from "react";

import {
    // Customizable Area Start
    TypographyProps,
    Container,
    Grid,
    Box,Typography,Link
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import { arrowicon } from "./assets";
import AppliedJobDetail from "../../../components/src/AppliedJobDetail";
import JobProfileController,{ Props } from "./JobProfileController";
import Header from "../../../components/src/Header.web";
import SideBar from "../../../components/src/SideBar";
import Footer from "../../../components/src/Footer";
import Loader from "../../../components/src/Loader.web";
import AppliedPopup from "./AppliedPopup.web";

// Customizable Area End

export default class UserAppliedJobDetail extends JobProfileController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    renderMainContentAppliedJobDetail(sideBarStatus: boolean) {
        return <Grid style={webStyle.contentSetupjobDetail}>
              <Loader loading={this.state.isLoading}></Loader>
            < Header
              navigate={this.props.navigation.navigate}
                sideBarStatus={this.state.isSideBarOpen}
                dropDownStatus={this.state.isDropdownOpen}
                handleDropdown={this.handleDropdown}
                handleSideBar={this.handleSideBar}  
            />
            <Grid style={webStyle.mainContentjobDetail as React.CSSProperties}>
                <Box sx={webStyle.blockInfojob}>
                <Typography variant="h3" style={webStyle.activeBlockMainTitle as TypographyProps}>Job Details</Typography>
                <Typography variant="h6" style={webStyle.blockE2EPath as TypographyProps}>
                <span style={webStyle.highlightedTitleext}>
                  <Link data-test-id="homeLink" color="inherit" onClick={() => this.handleNavigation("JobProfile")}>
                    Home
                  </Link>
                </span>
                <span style={webStyle.highlightedTitleext}>
                  <Link data-test-id="studiosBookingList" color="inherit" onClick={() => this.handleNavigation("JobProfile")}>
                    {" "}/ Applied Jobs
                  </Link>
                </span>
                {" "}/{" "}{this.state.allJobDetail?.attributes?.role}
              </Typography>
                </Box>
                <AppliedJobDetail navigateToPHDetailPage={this.navigateToPHDetailPage} ratingvalidation={this.state.ratingvalidation} reviewvalidation={this.state.reviewvalidation} vendorjobid={this.state.vendorjobid}  withdrawJob={this.withdrawJob} data={this.state.allJobDetail} datavalue={this.state.allJobDetailRatingandReview} open={this.state.open} handleOpen={this.handleOpen} handleClose={this.handleClose} rateHandle={this.rateHandle} ratevalue={this.state.rateValue} comments={this.state.comments} commentsHandle={this.commentsHandle} addReviewApiCall={this.addReviewApiCall}/>
            </Grid>
            <Footer />
            { this.state.PopupMessage1 ? <AppliedPopup navigation={this.props.navigation} open={this.state.PopupMessage1} handleClose={this.handlPopClose1} message={this.state.messageshow1}/> : null}
        </Grid>

    }
    
    render() {
        return ( 
            <Container maxWidth="xl" className="user_detail_container">
                {
                    this.state.isSideBarOpen?
                        <Grid container spacing={0}>
                            <Grid style={webStyle.sideBarjobDetail}  item xs={3}>
                                <SideBar
                                   userData={{ age: 25 }}
                                    activeMenuItem={"Jobs"}
                                    handleSideBar={this.handleSideBar}
                                    navigate={this.props.navigation.navigate}
                                    />
                            </Grid>
                            <Grid item style={{ flexBasis: "77.75%", maxWidth: "77.75%" }} xs={9}>{this.renderMainContentAppliedJobDetail(this.state.isSideBarOpen)}</Grid>
                        </Grid> :
                        <Grid item xs={12}>
                            {
                                this.renderMainContentAppliedJobDetail(this.state.isSideBarOpen)
                            }
                           </Grid>
                }
                
            </Container >
            
        );  }
}

// Customizable Area Start
const webStyle = {
   
    contentSetupjobDetail: {
        borderLeft: "1px solid #E3EAF4",
    },
    highlightedTitleext: {
        fontFamily: "Urbanist",
        color: "#F5C42C",
        textDecoration: "underline",
        cursor: "pointer",
        fontWeight: 600,
    },
    blockE2EPath: {
        fontSize: "14px",
        letterSpacing: "1px",
        fontWeight: 500,
        color: "#6D89AF",
        lineHeight: "17px",
        paddingTop: "8px",
    },
    sideBarjobDetail: {
        minHeight: "100vh",
        maxWidth: "22.25%",
    },
    blockInfojob: {
        paddingBottom:'24px'
    },
    blockPathjobDetail: {
        fontSize: "14px",
        letterSpacing: "1px",
        color: "#6D89AF",
        marginBottom: "24px",
        fontWeight: 500
      },
      highlightedTextjobDetail: {
        color: "#F5C42C",
        textDecoration: 'underline',
        textDecorationColor: "#F5C42C",
        fontWeight: 600,
        cursor:"pointer"
      },
      activeBlockMainTitle: {
        lineHeight: "34px",
        fontSize: "28px",
        color: "#17181D",
        fontWeight: "700",
        letterSpacing: "1px",
      },
    activeBlockjobDetail: {
        fontSize: "28px",
        fontWeight: "600",
        letterSpacing: "1px",
        display: "flex",
        flexDirection: "row",
        lineHeight: "33px",
        alignItems: 'center',
        gap: '12px',
        marginBottom: '8px',   
      },
     
      mainContentjobDetail: {
        width: "100%",
        backgroundColor: "#F8FAFE",
        boxSizing: "border-box",
        padding: "24px"
      },
};
// Customizable Area End