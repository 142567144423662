import React from "react";
import { Box, Typography, Avatar, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Bg_Copy ,dummyImage } from "./assets";
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import ScheduleIcon from '@material-ui/icons/Schedule';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import moment from "moment";
interface MyProps {
    data: any;
    type: string;
    getFormattedDate: any;
    cancelBookingAPICall?: any;
    leaveStudioAPICall?: any;
    withdrawRequestAPICall?: any;
    requestAgainAPICall?: any;
}



const useStyles = makeStyles({
    cardDivWithOpenSideBar: () => ({
        width: 300,
        //height: 240,
        borderRadius: 14,
        padding: 8,
        border: "1px solid #E1E6EC",
        position: 'relative',
        '& .MuiCardContent-root': {
            padding: 0
        },
        "@media(max-width: 500px)": {

        },
        "@media(min-width: 1440px)": {
            width: '312px',
        },
        backgroundImage: `url(${Bg_Copy})`, // Assuming JobCardImage is the path to your image
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: 200,
    }),
    cardDivWithClosedSideBAr: () => ({
        width: 346,
        borderRadius: 14,
        padding: 8,
        border: "1px solid #E1E6EC",
        position: 'relative',
        '& .MuiCardContent-root': {
            padding: 0
        },
        "@media(max-width: 500px)": {

        },
    }),
    parentBoxValue: () => ({
        display: "flex",
        justifyContent: 'space-between',
    }),
    parentBox: () => ({
        height: "195px",
        borderRadius: "12px",
        border: "1px solid #e3eaf4",
        background: "#ffffff",
    }),
    imageContainer: () => ({
        height: "100%",
        width: "100%",
        position: "relative",
    }),
    studioImage: () => ({
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        objectFit: "cover",
        borderRadius: "12px"
    }),
    studioStatus: () => ({
        position: "absolute",
        top: "10px",
        right: "10px",
        textAlign: "end",
        height: "23px",
    }),
    studioDetailsMainBox: () => ({
        position: "absolute",
        bottom: "5px",
        right: "5px",
        left: "5px",
        background: "#fff",
        height: 95,
        borderRadius: "10px",
        opacity: 0.8290272,
        padding: 12,
        boxSizing: "border-box",
    }),
    studioDetails: () => ({
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    }),
    ParentSubheading1: () => ({
        display: "flex",
        gap: "5px",

    }),
    cardParentBox: () => ({
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        paddingLeft: '12px',
        paddingRight: '12px',
        paddingTop: "12px",
    }),
    innerBox: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        columnGap: '7px',
        width: "77%",
    },
    avatar: {
        width: 59,
        height: 59,
        borderRadius: "12px",
    },
    nameBox: {
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        flex: "0 0 calc(100% - 59px)",
        maxWidth: "calc(100% - 59px)",
        paddingRight: "5px",
    },
    studioName: {
        lineHeight: "19px",
        color: "#17181d",
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: 600,
        letterSpacing: "0.2px",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        maxWidth: "100%"
    },
    phName: {
        lineHeight: "14px",
        color: "#3389ff",
        fontFamily: "Urbanist",
        fontSize: "12px",
        fontWeight: 500,
        letterSpacing: "0.2px",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        maxWidth: "100%"
    },
    studioAddress: {
        lineHeight: "14px",
        color: "#6d89af",
        fontFamily: "Urbanist",
        fontSize: "12px",
        fontWeight: 500,
        letterSpacing: "0.2px",
    },
    middleBox: {
        height: "54px",
        borderTop: "1px solid #e3eaf4",
        borderBottom: "1px solid #e3eaf4",
        background: "#f8fafe",
        marginTop: "10px",
        marginBottom: "12px",
        display: "flex",
        justifyContent: "space-between",
        padding: "12px",
        alignItems: "center",
        boxSizing: "border-box",
    },
    cardEndBox: () => ({
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: '12px',
        paddingRight: '12px',
        paddingBottom: "12px",
    }),
    bookingEndsText: {
        color: "#6d89af",
        fontFamily: "Urbanist",
        fontSize: "12px",
        fontWeight: 500,
        lineHeight: "16px",
        letterSpacing: "0.25px",
        marginLeft: "3px",
        marginBottom: "2px",
    },
    endDaysText: {
        fontFamily: "Urbanist",
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: "16px",
        letterSpacing: "0.29166666px",
        display: "flex",
        alignItems: "center",
    },
    myRequestCardEndText: {
        fontFamily: "Urbanist",
        fontSize: "12px",
        fontWeight: 600,
        lineHeight: "16px",
        letterSpacing: "0.25px",
        display: "flex",
        alignItems: "center",
    },
    leaveStudioBtn: {
        width: "100%",
        height: "38px",
        borderRadius: "10px",
        background: "#e3eaf4",
    },
    leaveStudioBtnText: {
        color: "#17181d",
        fontFamily: "Urbanist",
        fontSize: "12px",
        fontWeight: 600,
        lineHeight: "14px",
        letterSpacing: "0.34285715px",
        textTransform: "none",
    },
    hoursIcon: {
        width: "16px",
        height: "16px",
        paddingRight: "2px",
    },
    dateText: {
        color: "#17181d",
        fontFamily: "Urbanist",
        fontSize: "12px",
        fontWeight: 600,
        lineHeight: "14px",
        letterSpacing: "0.2px",
        paddingBottom: "4px",
    },
    timeText: {
        color: "#6d89af",
        fontFamily: "Urbanist",
        fontSize: "10px",
        fontWeight: 500,
        lineHeight: "12px",
        letterSpacing: "0.2px",
    },
    smallYellowRound: {
        width: "5px",
        height: "5px",
        borderRadius: "26px",
        background: "#f5c42c",
    },
    bigYellowRound: {
        width: "11px",
        height: "11px",
        borderRadius: "6px",
        background: "rgba(245, 196, 44, 0.16)",
        alignItems: "center",
        display: "flex",
        justifyContent: "center"
    },
    daysBtnText: {
        height: "12px",
        color: "#ffffff",
        fontFamily: "Urbanist",
        fontSize: "10px",
        fontWeight: 700,
        letterSpacing: "0.16666667px",
        alignItems: "center",
        display: "flex",
    },
    daysBtn: {
        height: "24px",
        borderRadius: "13px",
        background: "#17181d",
    },
    studioAmountText: {
        height: "15px",
        color: "#42a200",
        fontFamily: "Helvetica",
        fontSize: "12px",
        fontWeight: 400,
        letterSpacing: "0.24px",
        textAlign: "center",
        paddingTop: "1px",
    }
});

export default function StudioMyBookingsMyRequestsCard(props: MyProps) {
    const classes = useStyles();
    const getButtonText = (status: "upcoming" | "ongoing" | "pending" | "rejected" | "cancelled" | "completed") => {
        const textobject = {
            upcoming: { label: "Cancel Booking", fun: (studioId: string) => props.cancelBookingAPICall(studioId) },
            ongoing: { label: "Leave Studio", fun: (studioId: string) => props.leaveStudioAPICall(studioId) },
            pending: { label: "Withdraw Request", fun: (studioId: string) => props.withdrawRequestAPICall(studioId) },
            rejected: { label: "Request Again", fun: (studioId: string) => props.requestAgainAPICall(studioId) },
            cancelled: { label: "Request Again", fun: (studioId: string) => props.requestAgainAPICall(studioId) },
            completed: { label: "Request Again", fun: (studioId: string) => props.requestAgainAPICall(studioId) },
        }
        return textobject[status];
    }

    const getDifferenceInDays = (date: any) => {
        let currentDate = moment(new Date());
        let startDate = moment(new Date(date));
        let getDiff = currentDate.diff(startDate, 'days');
        return getDiff > 0 ? `${getDiff} Day ago` : "Today";
    }

    return (
        <Box className={classes.parentBox}>
            <Box className={classes.cardParentBox}>
                <Box
                    className={classes.innerBox}>
                    <Avatar
                        className={classes.avatar}
                        src={props.data.attributes.studio.image_url || dummyImage}
                    >
                    </Avatar>
                    <Box className={classes.nameBox}>
                        <Typography className={classes.studioName}>
                            {props.data.attributes.studio.name}
                        </Typography>
                        <Typography className={classes.phName}>
                            {props.data.attributes.studio.production_house_name}
                        </Typography>
                        <Typography className={classes.studioAddress} style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", maxWidth: "100%" }}>
                            {props.data.attributes.studio.address}, {props.data.attributes.studio.city} ({props.data.attributes.studio.state})
                        </Typography>
                    </Box>
                </Box>
                <Box style={{ height: "59px", width: "23%", display: "flex", justifyContent: "end", textAlign: "center" } as React.CSSProperties}>
                    <Typography className={classes.studioAddress}>{getDifferenceInDays(props.data.attributes.created_at)}</Typography>
                </Box>
            </Box>
            <Box className={classes.middleBox}>
                <Box style={{ textAlign: "center" }}>
                    <Typography className={classes.dateText}>{props.data.attributes.booking_type === "day_wise" ? props.getFormattedDate(props.data.attributes.start_date) : props.getFormattedDate(props.data.attributes.date)}</Typography>
                    <Typography className={classes.timeText}>{props.data.attributes.booking_type === "day_wise" ? props.data.attributes.studio.open_time : props.data.attributes.start_time}</Typography>
                </Box>
                <Box>
                    <Box style={{ display: "flex", alignItems: "center" }}>
                        <Box style={{ display: "flex" }}>
                            <Box className={classes.bigYellowRound}>
                                <Box className={classes.smallYellowRound}></Box>
                            </Box>
                            <Box style={{ borderTop: "1px dashed #e1e6ec", width: "41px", marginTop: "5px" }}></Box>
                        </Box>
                        <Box className="studioDaysBtn">
                            <Button className={classes.daysBtn}><span className={classes.daysBtnText}>{props.data.attributes.booking_type === "day_wise" ? `${props.data.attributes.total_days} DAYS` : `${props.data.attributes.total_hours} HOURS`}</span></Button>
                        </Box>
                        <Box style={{ display: "flex" }}>
                            <Box style={{ borderTop: "1px dashed #e1e6ec", width: "41px", marginTop: "5px" }}></Box>
                            <Box className={classes.bigYellowRound}>
                                <Box className={classes.smallYellowRound}></Box>
                            </Box>
                        </Box>
                    </Box>
                    <div className={classes.studioAmountText}>₹{props.data.attributes.total_amount}</div>
                </Box>
                <Box style={{ textAlign: "center" }}>
                    <Typography className={classes.dateText}>{props.data.attributes.booking_type === "day_wise" ? props.getFormattedDate(props.data.attributes.end_date) : props.getFormattedDate(props.data.attributes.date)}</Typography>
                    <Typography className={classes.timeText}>{props.data.attributes.booking_type === "day_wise" ? props.data.attributes.studio.close_time : props.data.attributes.end_time}</Typography>
                </Box>
            </Box>
            <Box className={classes.cardEndBox}>
                {props.type === "myBookings" ? <Box style={{ width: "49.50%", paddingRight: "10px", boxSizing: "border-box" }}>
                    <Typography className={classes.bookingEndsText}>{props.data.attributes.time_difference.type}</Typography>
                    {props.data.attributes.time_difference.status === "upcoming" ?
                        <Typography className={classes.endDaysText} style={{ color: "#3389ff" }}><ScheduleIcon className={classes.hoursIcon} htmlColor="#3389ff" />{props.data.attributes.time_difference.time}</Typography>
                        : <Typography className={classes.endDaysText} style={{ color: "#f5c42c" }}><HourglassEmptyIcon className={classes.hoursIcon} htmlColor="#f5c42c" />{props.data.attributes.time_difference.time}</Typography>
                    }
                </Box>
                    : <Box style={{ width: "49.50%", paddingRight: "10px", boxSizing: "border-box" }}>
                        {props.data.attributes.time_difference.status === "pending" ?
                            <Typography className={classes.myRequestCardEndText} style={{ color: "#42a200" }}><CheckCircleIcon className={classes.hoursIcon} htmlColor="#42a200" />Requested {props.data.attributes.time_difference.time} ago</Typography>
                            : <Typography className={classes.myRequestCardEndText} style={{ color: "#ff5656" }}><CancelIcon className={classes.hoursIcon} htmlColor="#ff5656" />Request declined</Typography>
                        }
                    </Box>
                }
                <Box style={{ width: "50.50%" }}>
                    <Button onClick={() => getButtonText(props.data.attributes.time_difference.status).fun(props.data.id)} className={classes.leaveStudioBtn}><span className={classes.leaveStudioBtnText}>{getButtonText(props.data.attributes.time_difference.status).label}</span></Button>
                </Box>
            </Box>
        </Box>
    );
}
