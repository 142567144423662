import React from "react";
import { Snackbar } from '@material-ui/core';
import Alert, { AlertProps } from "@material-ui/lab/Alert";

interface MyProps {
    notification: { type: string, open: boolean, message: string, route?: string };
    handleCloseNotification: any;
    navigation?: any;
}

const CustomAlert = React.forwardRef<HTMLDivElement, AlertProps>(function CustomAlert(
    props,
    ref
) {
    return <Alert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomNotification(props: MyProps) {
    const { notification, handleCloseNotification, navigation } = props;

    const closeNotification = () => {
        if (notification.route === "LandingPage") {
            setTimeout(() => {
                navigation("LandingPage");
            }, 2000);
        }
        handleCloseNotification();
    }

    return (
        <Snackbar
            open={notification.open}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            data-test-id="sendBookingRequest"
            onClose={closeNotification}>
            <CustomAlert
                onClose={closeNotification}
                severity={notification.type as ('error' | 'info' | 'success' | 'warning')}
                style={{ width: '100%' }}>
                {notification.message}
            </CustomAlert>
        </Snackbar>
    );
}
