import React from "react";

import {
  // Customizable Area Start
  Box,
  Typography,
  Grid,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import "./forecastingandbudgeting.css";
import SummaryViewController, { Props } from "./SummaryViewController";
import Loader from "../../../components/src/Loader.web";
import CustomNotification from "../../../components/src/CustomNotification";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

export default class SummaryView extends SummaryViewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderSummaryBox = (data: any, index: number) => {
    return (
      <Grid key={`${index + data.id}_summary_box`} container style={webStyle.mainBox}>
        <Grid item xs={10}>
          <Typography style={webStyle.summaryBoxTitle}>{data.attributes.summary_name}</Typography>
          <Typography style={webStyle.summaryBoxSubTitle}>{data.attributes.detailed.project_department_name.join(", ")}</Typography>
          <Typography style={webStyle.summaryBoxSubTitle}>{data.attributes.detailed.project_categories_name.join(", ")}</Typography>
        </Grid>
        <Grid item xs={2} style={{ textAlign: 'end' }}>
          <div style={{ ...webStyle.summaryBoxAmount, fontWeight: 400, fontFamily: 'Helvetica-Bold' } as React.CSSProperties}>₹{data.attributes.detailed.budget_total}</div>
        </Grid>
      </Grid>
    )
  }
  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <>
        <Loader loading={this.state.isLoading}></Loader>
        <Grid container style={{ marginTop: 8 }}>
          {
            this.state.balanceSheetViewSummaryData.length ? <>
              <Box style={webStyle.summaryDataMainBox as React.CSSProperties}>
                {this.state.balanceSheetViewSummaryData.map((item: any, index: number) => {
                  return this.renderSummaryBox(item, index)
                })}
              </Box>
              <Box style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <Grid container style={webStyle.totalAmountBox}>
                  <Grid item xs={12} style={{ backgroundBlendMode: 'normal', height: 81, padding: 12, borderRadius: '10px', background: 'rgba(225, 230, 236, 0.3)' } as React.CSSProperties}>
                    <Grid container justifyContent="space-between">
                      <Grid>
                        <Typography style={webStyle.summaryBoxTotalText}>Contigency</Typography>
                      </Grid>
                      <Grid>
                        <Typography style={{ ...webStyle.summaryBoxAmount, lineHeight: '18px', letterSpacing: '0.22857143px' }} >Null</Typography>
                      </Grid>
                    </Grid>
                    <Grid item style={{ ...webStyle.sepratorLine, marginTop: 10, marginBottom: 10 }}></Grid>
                    <Grid container justifyContent="space-between">
                      <Grid>
                        <Typography style={webStyle.summaryBoxTotalText}>Total</Typography>
                      </Grid>
                      <Grid>
                        <div style={webStyle.summaryBoxTotalAmount}>₹{this.state.total}</div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} style={{ ...webStyle.sepratorLine, marginTop: 12, marginBottom: 15 }}></Grid>
                  <Grid item xs={12}>
                    <Typography style={webStyle.grandTotalText}>Grand Total: <span style={webStyle.grandTotalAmount}>₹{this.state.grandTotal}</span></Typography>
                  </Grid>
                </Grid>
              </Box>
            </>
              :
              <Typography style={{ width: '100%', textAlign: 'center', marginTop: '30px', color: '#6d89af', fontFamily: 'Urbanist', fontSize: 14, fontWeight: 600 }}>No record available</Typography>
          }
        </Grid>
        <CustomNotification navigation={(route: string) => this.props.navigation.navigate(route)} notification={this.state.summaryViewNotification} handleCloseNotification={this.handleSummaryViewCloseNotification} />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  summaryBoxTotalAmount: {
    lineHeight: '18px',
    color: '#17181d',
    fontFamily: 'Helvetica-Bold',
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: '0.22857143px',
  },
  summaryBoxTotalText: {
    lineHeight: '18px',
    color: '#17181d',
    fontFamily: 'Urbanist',
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: '0.2px',
  },
  summaryBoxAmount: {
    lineHeight: '19px',
    color: '#17181d',
    fontFamily: 'Urbanist',
    fontSize: 16,
    fontWeight: 700,
  },
  sepratorLine: {
    border: '1px solid #e3eaf4',
  },
  grandTotalAmount: {
    lineHeight: '24px',
    color: '#42a200',
    fontFamily: 'Helvetica-Bold',
    fontSize: '20px',
    fontWeight: 400,
    letterSpacing: '0.2px',
    marginLeft: 4,
  },
  grandTotalText: {
    lineHeight: '19px',
    color: '#18272a',
    fontFamily: 'Urbanist',
    fontSize: '16px',
    fontWeight: 600,
    letterSpacing: '0.22857143px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  summaryDataMainBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '403px',
    overflowX: 'auto',
  },
  mainBox: {
    height: 94,
    width: 629,
    borderRadius: 12,
    border: '1px solid #e3eaf4',
    background: '#ffffff',
    padding: 16,
    alignItems: 'center',
    marginTop: 16,
  },
  totalAmountBox: {
    height: 158,
    width: 629,
    borderRadius: 12,
    border: '1px solid #e3eaf4',
    background: '#ffffff',
    padding: 12,
    boxShadow: '0px -3px 14px rgba(23,24,29,0.04)',
    marginLeft: '-13px',
  },
  summaryBoxTitle: {
    lineHeight: '18px',
    color: '#17181d',
    fontFamily: 'Urbanist',
    fontSize: 14,
    fontWeight: 600,
  },
  summaryBoxSubTitle: {
    lineHeight: '18px',
    color: '#6d89af',
    fontFamily: 'Urbanist',
    fontSize: 12,
    fontWeight: 600,
  },
};
// Customizable Area End
