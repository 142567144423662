import React ,{ChangeEvent}from "react";
import {
  // Customizable Area Start
  Container,
  Box,
  Typography,
  Grid,
  TypographyProps,
  MenuItem,
  FormControl,
  ListItemText,
  Checkbox,
  
  // Customizable Area End
} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import Pagination from '@material-ui/lab/Pagination';
// Customizable Area Start
import SideBar from "../../../components/src/SideBar";
import Header from "../../../components/src/Header.web";
import Footer from "../../../components/src/Footer";
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import { arrowicon } from "./assets";
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import Loader from "../../../components/src/Loader.web";
import "./fullVisibility.css";
const theme = createTheme({
  palette: {
    primary: {
      main: "#f5c42c",
      contrastText: "#fff",
    },
  },
});

import ProjectCard from "../../../components/src/ProjectCard";
import ProjectDetailCard from "../../../components/src/ProjectDetailCard";
const SortDiv = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  alignItems: "center",
  "@media(max-width: 600px)": {
    gridColumnGap: '0px',
    display: 'block'
  },
});
const SortsubDivp = styled(Box)({
  display: 'flex',
  gap: "10px",
  alignItems: "center",
  height: 80
});
const SearchInput = styled('input')({
  fontSize: "15px",
  outline: "none",
  border: "none",
  width: "85%",
  padding: "10px 0px",
  backgroundColor: "#F8FAFE",
  "@media(max-width: 500px)": {
    fontSize: '12px',
    overflow: 'hidden',
    width: "70%",
    padding: "10px 0px",
  },
});
const SortSubDiv = styled(FormControl)({
  width: '167px',
  height:"44px",
  marginRight: "10px",
  '& .MuiSelect-select.MuiSelect-selectMenu': {
    paddingLeft: 10,
    color: "#17181d",
    fontFamily: "Urbanist",
    fontSize: "14px",
    fontWeight: 600,
    letterSpacing: "0.25454545px",
  },
  "& .MuiInput-underline::after": {
    borderBottom: 'unset'
  },
  "@media(max-width: 500px)": {
    marginRight: "0px",
  },
  "& .MuiInput-underline::before": {
    content: 'none'
  },
  "& .MuiInputLabel-animated.Mui-focused": {
    transform: 'unset',
    color: 'transparent'
  },
  "& .MuiSelect-icon": {
    marginRight: 10
  }
});
const SearchIcon = styled(SearchOutlinedIcon)({
  padding: "10px 0 10px 13px",
  "@media(max-width: 500px)": {
    padding: "10px 0 10px 8px",
    fontSize: '18px'
  },
});
const SelectBox = styled(Select)({
  padding: '5px 5px',
  borderRadius: '25px',
  border: '1px solid #e3eaf4',
  background: 'white',
  zIndex: 1,
  marginTop: 0,
  "& :focus": {
    background: 'transparent',
  },
  
});
// Customizable Area End
import AllProjectViewController, {
  Props,
} from "./AllProjectViewController";

export default class AllProjectView extends AllProjectViewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }
  // Customizable Area Start
  webrenderMainContent(sideBarStatus: boolean = this.state.isSideBarOpen) {
    const {
      projectcount,
      upcoming,
      ongoing,
      completedproject,
      
    } = this.state;
    const optionsvalue = [
      { id: 1, name: `All projects(${projectcount})`,value: "All" },
      { id: 2, name: `Ongoing projects(${ongoing})`,value: "Ongoing" },
      { id: 3, name: `UpComing Projects(${upcoming})`,value: "Upcoming" },
      { id: 4, name: `Completed Projects(${completedproject})`,value: "Completed" },
    ];
    return (
      <>
      <Loader loading={this.state.isLoading}></Loader>
    <Grid style={webStyle.contentSetup}>


      < Header
        sideBarStatus={sideBarStatus as boolean}
        dropDownStatus={this.state.isDropdownOpen as boolean}
        handleSideBar={this.handleSideBar as any}
        handleDropdown={this.handleDropdown as any}
        navigate={this.props.navigation.navigate as any}
      />

      <Grid style={webStyle.mainContent as React.CSSProperties}>
              <SortDiv>
                <Box sx={webStyle.blockInfo}>
                  <Typography variant="h3" style={webStyle.activeBlock as TypographyProps}>All Projects</Typography>
                  <Typography variant="h6" style={webStyle.blockPath as TypographyProps}><span style={webStyle.highlightedText} data-test-id="homeLink" onClick={() => this.handleNavigation("Dashboard")}>Home</span> / Projects</Typography>
                </Box>
                <SortsubDivp>
                  <SortSubDiv>

                  <SelectBox
                          data-testid='multi-selected-drop-down1'
                          displayEmpty
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left"
                            }
                          }}
                          onChange={this.handleChange}

                          renderValue={(selected :any) => {
                            if (!selected ) {
                              return "Sort By:All";
                            }
                            return `Sort By : ${selected}`;
                          }}
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          // value={options.name}
                        >
                      
                          {optionsvalue.map((option) => (
                             
                            <MenuItem key={option.id} value={option.value}  >
                              <ListItemText primary={option.name} />
                              <Checkbox value={option.value}  checked={this.state.sortValue === option.value}   style ={{
                      color: "#F5C42C"
                    }} />
                            </MenuItem>
                            
                          ))}

                          
                        </SelectBox>
                  </SortSubDiv>
                  <Box sx={webStyle.headerSearchBox} >
                    <SearchIcon style={webStyle.searchicon} />

                    <SearchInput placeholder={`Search from ${this.state.count} projects...`} style={{
                      fontSize: "14px", fontWeight: 500,
                      fontFamily: "Urbanist", letterSpacing: "0.25px"
                    }}   onChange={(event: ChangeEvent<HTMLInputElement>) => this.filterHouses(event)} data-testid='search'/>
                  </Box>
                </SortsubDivp>
              </SortDiv>
              <Box sx={webStyle.headerSearchBox1} >
                {this.state.allProjectData?.map((item: any, index: any) => (
                  <Grid item key={`${item}_project_card`} xl={3} lg={4} md={6} sm={12} xs={12} onClick={() => this.navigateToProjectDetail(item.id)} data-testid='navigateuser'>
                    <Box sx={webStyle.headerSearchParentBox}>
                      <ProjectCard isSidebar={this.state.isSideBarOpen} data={item} />
                    </Box>
                  </Grid>
                ))}
              </Box>
              {this.state.count ?
              
              (
              <Grid container spacing={2}>
                {console.log("erererer",this.state.count)}
                <Grid item xs={12}>
                  <Pagination data-test-id="pagination" count={Math.ceil((this.state.count) / 9)} page={this.state.currentpage} onChange={this.handlePageChange} />
                </Grid>
              </Grid>)
              : null}
               
         </Grid>  
       
         <Footer />       
     
    </Grid >
    </>
    
    )}

  // Customizable Area End

  render() {
    
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container style={webStyle.webparentDiv}>
          {
            this.state.isSideBarOpen ?
              <Grid container spacing={0}>
                <Grid item xs={3} style={webStyle.websidebarParent}>
                  <SideBar
                    navigate={this.props.navigation.navigate}
                    userData={{ age: 25 }}
                    activeMenuItem={"All Projects"}
                    handleSideBar={this.handleSideBar} />
                </Grid>
                <Grid item xs={9}>{this.webrenderMainContent()}</Grid>
              </Grid> :
              <Grid item xs={12}>{this.webrenderMainContent()}</Grid>
          }
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = {
  headerSearchBox: {
    display: "flex",
    border: '1px solid #e3eaf4',
    borderRadius: "35px",
    height: "44px",
    alignItems: "center",
    backgroundColor: "#ffffff",
    overflowX: 'hidden',
    minWidth: "286px",
    marginRight: "20px",
    "@media screen and (max-width: 500px)": {
      width: "160px",
      minWidth: "100px",
      marginRight: "0px",
    },
  },
  searchicon: {
    marginRight: '6px',
    fill: '#6D89AF',
    fontSize: "22px",

  },
  
  checkboxStyle:{
    '&.MuiCheckbox-colorSecondary.Mui-checked': {
      color: "#F5C42C!important"
  }
    
  },
  checDiv:{
     '&.MuiCheckbox-colorSecondary.Mui-checked': {
    color: "#F5C42C"

  },},
  webparentDiv: {
    padding: "0px",
    maxWidth: "none"
  },
  contentSetup: {
    borderLeft: "1px solid #E3EAF4",
  },
  websidebarParent: {
    minHeight: "100vh",
    maxWidth: "23%"
  },
  seperateCard: {
    border: "1px solid #E3EAF4",
    marginLeft: "16px",
    marginRight: "16px",
    marginTop: "9px",
  },
  sortbyTitle: {
    lineHeight: "14px",
    color: "#6d89af",
    fontFamily: "Urbanist",
    fontSize: "12px",
    fontWeight: 600,
    paddingLeft: "16px",
    paddingRight: "16px",
  },
  selectListText: {
    lineHeight: "16px",
    color: "#17181d",
    fontFamily: "Urbanist",
    fontSize: "13px",
    fontWeight: 600,
  },
  mainContent: {
    width: "100%",
    backgroundColor: "#F8FAFE",
    boxSizing: "border-box",
    padding: "24px"
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  highlightedText: {
    color: "#F5C42C",
    textDecoration: 'underline',
    textDecorationColor: "#F5C42C",
    fontWeight: 600,
    fontFamily: 'Urbanist',
    cursor: 'pointer',
  },
  headerSearchBox1: {
    display: "flex",
    justifyContent: "start",
    alignItems: "left",
    flexDirection: "row",
    flexWrap: "wrap",
    overflowX: 'auto'
  },
  headerSearchParentBox: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '5px'
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  blockInfo: {
    padding: "0px"
  },
  activeBlock: {
    lineHeight: "33px",
    fontSize: "28px",
    fontWeight: "700",
    letterSpacing: "1px",
    display: "flex",
    flexDirection: "row",
    gridColumnGap: '5px',
    alignItems: 'center',
    gap: '12px',
    marginBottom: '8px'
  },
  blockPath: {
    fontSize: "14px",
    letterSpacing: "1px",
    color: "#6D89AF",
    fontWeight: 500,
    marginBottom: "5px",
    marginTop: '8px',
  },
};
// Customizable Area End
