import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData ,setStorageData } from "../../../framework/src/Utilities";
import React from "react";
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  projectMembersJobRole: any[];
  projectVendorData: any[];
  isLoading: boolean;
  isModelOpen: boolean;
  modelType: string;
  projectMembersJobRoleBreadCrumb: any;
  projectId: string;
  tabValue: number;
  anchorEl: any;
  departmentsList: any[];
  permssionsList: any[];
  manageModelPermssionList: any[];
  manageModelSelectedDept: any;
  employeeIdToUpdate: string;
  rolesList: any[];
  countryCodeSelected: string;
  currentPage: number;
  totalCountPMJR: number;
  totalCountProjectVendor : number
  projectVendorProfileData : any;
  isProjectVendorProfileSideBarOpen:boolean
  bioReadMoreLessRequestPro: boolean;
  vendorProfileTabValue: number;
  isProjectVendorHeaderDropdownOpenVal: boolean;
  projectVendorRatingAndReviewData:any;
  open:boolean;
  vendorProfileCommentValue:string;
  vendorProfileRateValue : number;
  vendorCommentValidation:string;
  vendorRatingValidation:string;
  projectMemberVendorProjects : any[];
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ProjectMembersJobRoleController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  layoutRef: any = null;
  projectMembersJobRoleTitle: string = "Project Members";
  getPipelineEmployeeListApiCalledId: string = "";
  getProjectMemberProjectVendorApiCallId : string ="";
  removeFromJobRoleApiCallId : string ="";
  getPipelineEmployeePermissionListApiCalledId: string = "";
  getPipelineEmployeeDepartmentListApiCalledId: string = "";
  getPipelineEmployeeRoleListApiCalledId: string = "";
  managePipelineEmployeeAccessibilityApiCalledId: string = "";
  addPipelineEmployeeApiCalledId: string = "";
  removePipelineEmployeeFromProjectApiCalledId: string = "";
  projectMemberProjectVendorApiCalledId: string = "";
  ratingAndReviewVendorprofileApiCallId:string = "";
  postRatingAndReviewVendorProfileApiCallId:string ="";
  projectMemberVendorProfileApiCallId:string = "";
  removeFromJobRoleProjectVendorProfileApiCallId:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      projectMembersJobRole: [],
      projectVendorData:[],
      projectVendorRatingAndReviewData:{},
      isLoading: false,
      isModelOpen: false,
      isProjectVendorProfileSideBarOpen: true,
      modelType: "",
      projectMembersJobRoleBreadCrumb: [
        { title: "Home", link: "Projects" },
        { title: "Project Members", link: "ProjectMemberList" },
        { title: "", link: "ProjectMemberList" },
        { title: "All Employees Job Roles" },
      ],
      projectVendorProfileData:{
        // id: "12",
        // "type": "profile",
        attributes: {
          id: 12,
          country: "",
          city: "",
          postal_code: "",
          account_id: "",
          state: "",
          address: "",
          role: "",
          department: "",
          experience: "",
          production_house_name: "",
          profile_image: "",
          documents: [],
          bank_detail: {
            data: null
          },
          account_detail: {
            data: {
              id: "",
              type: "",
              attributes: {
                country_code: "",
                email: "",
                first_name: "",
                full_phone_number: "",
                last_name: "",
                phone_number: "",
                production_house_name: "",
                production_house_address: "",
                full_name: "",
                gst_number: "",
                tan_number: "",
                aadhar_card_number: "",
                pan_card_number: "",
                account_number: "",
                branch_name:"" ,
                bank_name: "",
                ifsc_code: "",
                experience: "",
                notification: "",
                country_name: "",
                platform: "",
                device_id: "",
                role_detail: {
                  id: "",
                  name: ""
                },
                profile_id: "",
                profile_image: ""
              }
            }
          },
          profile_bio: {
            data: ""
          },
          employee_invited: "",
          vendor_invited_to_job_role: "",
          gallary_photos: []
        }
      },
      projectId: "",
      tabValue: 0,
      anchorEl: null,
      departmentsList: [],
      permssionsList: [],
      manageModelPermssionList: [],
      manageModelSelectedDept: null,
      employeeIdToUpdate: "",
      rolesList: [],
      countryCodeSelected: "",
      currentPage: 1,
      totalCountPMJR: 0,
      totalCountProjectVendor : 0,
      bioReadMoreLessRequestPro: true,
      vendorProfileTabValue:0,
      isProjectVendorHeaderDropdownOpenVal: false,
      open:false,
      vendorProfileCommentValue:"",
      vendorProfileRateValue:0,
      vendorCommentValidation:"",
      vendorRatingValidation:"",
      projectMemberVendorProjects:[]

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.layoutRef = React.createRef();
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const projectDetailsJson = await getStorageData("projectDetails");
    const showProfileVendor = await getStorageData("profileIdProjectVendor");
    const projectDetails = JSON.parse(projectDetailsJson);
    let breadCrumb = this.state.projectMembersJobRoleBreadCrumb;
    breadCrumb[2].title = projectDetails.name;
    this.setState({ projectMembersJobRoleBreadCrumb: breadCrumb, projectId: projectDetails.id });
    this.getPipelineEmployeeListApiCalledId = await this.apiCall(configJSON.GET_Method, configJSON.getPipelineEmployeeListApiEndPoint + projectDetails.id);
    this.getProjectMemberProjectVendorApiCallId = await this.getProjectMemberProjectVendor(configJSON.GET_Method , configJSON.getProjectVendorApiEndPoint + `?project_id=${projectDetails.id}`)
    this.getPipelineEmployeePermissionListApiCalledId = await this.apiCall(configJSON.GET_Method, configJSON.getPipelineEmployeePermissionListApiEndPoint);
    this.getPipelineEmployeeDepartmentListApiCalledId = await this.apiCall(configJSON.GET_Method, configJSON.getPipelineEmployeeDepartmentListApiEndPoint + projectDetails.id);
    this.getPipelineEmployeeRoleListApiCalledId = await this.apiCall(configJSON.GET_Method, configJSON.getPipelineEmployeeRoleListApiEndPoint);
    this.ratingAndReviewVendorprofileApiCallId = await this.getProjectVendorProfileRatings(configJSON.GET_Method , configJSON.getRatingAndReviewEndPoint + `?profile_id=${showProfileVendor}`);
    this.projectMemberVendorProfileApiCallId = await this.getProjectMemberVendorProfileProjects(configJSON.GET_Method , configJSON.getProfileDataRequestEndPoint + `/${showProfileVendor}`)

    await this.getProjectMemberProjectVendorProfileData();
    let isSideBarOpen = await getStorageData("sidebarvalue");
    this.setState({ isProjectVendorProfileSideBarOpen: JSON.parse(isSideBarOpen) })
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.getPipelineEmployeeListApiCalledId === apiRequestCallId) {
        this.getPipelineEmployeeListHandleResponse(responseJson);
      } else if (this.getPipelineEmployeePermissionListApiCalledId === apiRequestCallId) {
        this.getPipelineEmployeePermissionListHandleResponse(responseJson, "permssionsList");
      } else if (this.getPipelineEmployeeDepartmentListApiCalledId === apiRequestCallId) {
        this.getPipelineEmployeeDepartmentListHandleResponse(responseJson);
      } else if (this.managePipelineEmployeeAccessibilityApiCalledId === apiRequestCallId) {
        this.managePipelineEmployeeAccessibilityHandleResponse(responseJson);
      } else if (this.getPipelineEmployeeRoleListApiCalledId === apiRequestCallId) {
        this.getPipelineEmployeePermissionListHandleResponse(responseJson, "rolesList");
      } else if (this.addPipelineEmployeeApiCalledId === apiRequestCallId) {
        this.addPipelineEmployeeHandleResponse(responseJson);
      } else if (this.removePipelineEmployeeFromProjectApiCalledId === apiRequestCallId) {
        this.removePipelineEmployeeFromProjectHandleResponse(responseJson);
      } else if(this.getProjectMemberProjectVendorApiCallId === apiRequestCallId) {
        this.projectMemberProjectVendorResponse(responseJson);
      } else if(this.removeFromJobRoleApiCallId === apiRequestCallId) {
        this.removeFromJobProjectVendorResponse(responseJson)
      } else if(this.projectMemberProjectVendorApiCalledId === apiRequestCallId){
        this.projectMemberProjectVendorProfileResponse(responseJson);
      } else if(this.ratingAndReviewVendorprofileApiCallId === apiRequestCallId){
        this.projectVendorRatingAndReviewResponse(responseJson);
      } else if(this.postRatingAndReviewVendorProfileApiCallId === apiRequestCallId){
        this.handlePostRatingVendorProfileResponse(responseJson)
      } else if(this.projectMemberVendorProfileApiCallId === apiRequestCallId){
        this.handleGetProjectMemberProjectVendorProjects(responseJson);
      } else if(this.removeFromJobRoleProjectVendorProfileApiCallId === apiRequestCallId){
        this.handleRemoveFormJobProfileResponse(responseJson)
      }
    }

    this.getTokenAndCountryCodeSelected(message);
  }
  // Customizable Area End

  // Customizable Area Start
  getTokenAndCountryCodeSelected = (message: Message) => {
    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      let selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          countryCodeSelected:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode
        });
      }
    }
  }

  handleProjectVendorProfileSideBar = () => {
    this.setState((prevState) => ({
      isProjectVendorProfileSideBarOpen: !prevState.isProjectVendorProfileSideBarOpen
    }), async () => {
        setStorageData("sidebarvalue", this.state.isProjectVendorProfileSideBarOpen.toString())
    });
};

vendorProfileHandleDownloadPDF = (pdfURL: any, fileName: string) => {
  fetch(pdfURL)
    .then(VendorManageProfileResponse => {
      VendorManageProfileResponse.blob().then(blob => {
        let photoUrl = window.URL.createObjectURL(blob);
        let aTag = document.createElement('a');
        aTag.href = photoUrl;
        aTag.download = fileName;
        aTag.click();
      });
    });
}


covertToPascalCaseProfileProject = (text = '', trimSpace = false) => {
  if (typeof text !== 'string') {
      return '';
  }

  return text.split(' ').map((t) => t[0]?.toUpperCase() + t.slice(1).toLowerCase()).join(trimSpace ? '' : ' ')
}

handleBioReadVendorProfile = () => {
  this.setState({ bioReadMoreLessRequestPro: !this.state.bioReadMoreLessRequestPro });
}

handleTabValueRequestProfileChange = (tabVal: number) => {
  this.setState({ vendorProfileTabValue: tabVal });
}

handleProjectVedorHeaderDropdownVal = () => {
  this.setState({
    isProjectVendorHeaderDropdownOpenVal: !this.state.isProjectVendorHeaderDropdownOpenVal
  })
};

navigateToProjectVendorProfile=(navigate:string)=>{
  this.props.navigation.navigate(navigate)
}

  getProjectMemberProjectVendorProfileData= async()=>{
    const showProfileVendor = await getStorageData("profileIdProjectVendor");
    this.projectMemberProjectVendorApiCalledId = await this.getProjectMemberProjectVendorProfile(configJSON.GET_Method,configJSON.projectMemberProjectVendorApiEndPoint +  `/${showProfileVendor}`)
}

  getPipelineEmployeeListHandleResponse = (pmjrResponseJson: { data: any, meta: { total_employees: number }, errors: { token: string }[] | { message: string } }) => {
    if (pmjrResponseJson.data) {
      this.setState({
        projectMembersJobRole: pmjrResponseJson.data, totalCountPMJR: pmjrResponseJson.meta.total_employees
      });
    } else if (Array.isArray(pmjrResponseJson.errors)) {
      this.layoutRef.current.handleChangeNotification("error", true, pmjrResponseJson.errors[0].token, "LandingPage");
    } else if (pmjrResponseJson.errors.message) {
      this.layoutRef.current.handleChangeNotification("error", true, pmjrResponseJson.errors.message);
    }
    this.setState({ isLoading: false });
  }

  getPipelineEmployeePermissionListHandleResponse = (pmjrResponseJson: { data: any }, stateName: keyof S) => {
    if (pmjrResponseJson.data) {
      this.setState(({
        [stateName]: pmjrResponseJson.data, isLoading: false
      } as unknown) as Pick<S, keyof S>);
    }
  }

  getPipelineEmployeeDepartmentListHandleResponse = (pmjrResponseJson: { data: any, errors: { token: string }[] | { message: string } }) => {
    if (pmjrResponseJson.data) {
      this.setState({
        departmentsList: pmjrResponseJson.data
      });
    } else if (Array.isArray(pmjrResponseJson.errors)) {
      this.layoutRef.current.handleChangeNotification("error", true, pmjrResponseJson.errors[0].token, "LandingPage");
    } else if (pmjrResponseJson.errors.message) {
      this.layoutRef.current.handleChangeNotification("error", true, pmjrResponseJson.errors.message);
    }
    this.setState({ isLoading: false });
  }

  managePipelineEmployeeAccessibilityHandleResponse = async (pmjrResponseJson: { data: any, meta: { message: string }, errors: { token: string }[] | { message: string } }) => {
    if (pmjrResponseJson.data) {
      this.layoutRef.current.handleChangeNotification("success", true, pmjrResponseJson.meta ? pmjrResponseJson.meta.message : "Employee details updated successfully");
      this.getPipelineEmployeeListApiCalledId = await this.apiCall(configJSON.GET_Method, configJSON.getPipelineEmployeeListApiEndPoint + this.state.projectId);
    } else if (Array.isArray(pmjrResponseJson.errors)) {
      this.layoutRef.current.handleChangeNotification("error", true, pmjrResponseJson.errors[0].token, "LandingPage");
    } else if (pmjrResponseJson.errors.message) {
      this.layoutRef.current.handleChangeNotification("error", true, pmjrResponseJson.errors.message);
    }
    this.setState({ isLoading: false });
  }

  addPipelineEmployeeHandleResponse = (pmjrResponseJson: { data: any, errors: { token: string }[] | { message: string } }) => {
    if (pmjrResponseJson.data) {
      this.setState({
        projectMembersJobRole: [pmjrResponseJson.data, ...this.state.projectMembersJobRole], totalCountPMJR: this.state.totalCountPMJR + 1
      });
      this.handleModelClose();
      this.layoutRef.current.handleChangeNotification("success", true, "Employee created successfully");
    } else if (Array.isArray(pmjrResponseJson.errors)) {
      this.layoutRef.current.handleChangeNotification("error", true, pmjrResponseJson.errors[0].token, "LandingPage");
    } else if (pmjrResponseJson.errors.message) {
      this.layoutRef.current.handleChangeNotification("error", true, pmjrResponseJson.errors.message);
    }
    this.setState({ isLoading: false });
  }

  removePipelineEmployeeFromProjectHandleResponse = async (pmjrResponseJson: { meta: { message: string }, status: number, error: string, errors: { token: string }[] | { message: string } }) => {
    if (pmjrResponseJson.meta) {
      this.layoutRef.current.handleChangeNotification("success", true, pmjrResponseJson.meta.message);
      this.getPipelineEmployeeListApiCalledId = await this.apiCall(configJSON.GET_Method, configJSON.getPipelineEmployeeListApiEndPoint + this.state.projectId);
    } else if (pmjrResponseJson.status) {
      this.layoutRef.current.handleChangeNotification("error", true, pmjrResponseJson.error);
    } else if (Array.isArray(pmjrResponseJson.errors)) {
      this.layoutRef.current.handleChangeNotification("error", true, pmjrResponseJson.errors[0].token, "LandingPage");
    } else if (pmjrResponseJson.errors.message) {
      this.layoutRef.current.handleChangeNotification("error", true, pmjrResponseJson.errors.message);
    }
    this.setState({ isLoading: false });
  }

  projectMemberProjectVendorResponse = (responseJson: { data: any, meta: { total_vendors: number }, errors: { token: string }[] | { message: string } }) => {
    if (responseJson.data) {
      this.setState({
        projectVendorData: responseJson.data, totalCountProjectVendor: responseJson.meta.total_vendors
      });
    } else if (Array.isArray(responseJson.errors)) {
      this.layoutRef.current.handleChangeNotification("error", true, responseJson.errors[0].token, "LandingPage");
    } else if (responseJson.errors.message) {
      this.layoutRef.current.handleChangeNotification("error", true, responseJson.errors.message);
    }
    this.setState({ isLoading: false });
  }

  projectMemberProjectVendorProfileResponse = (responseJson: { data: any, meta: { total_vendors: number }, errors: { token: string }[] | { message: string } }) => {
    if (responseJson.data) {
      this.setState({
        projectVendorProfileData: responseJson.data,
      });
    console.log(this.state.projectVendorProfileData,"projectVendorProfileData");
    } else if (Array.isArray(responseJson.errors)) {
      this.layoutRef.current.handleChangeNotification("error", true, responseJson.errors[0].token, "LandingPage");
    } else if (responseJson.errors.message) {
      this.layoutRef.current.handleChangeNotification("error", true, responseJson.errors.message);
    }
    this.setState({ isLoading: false });
  }

  projectVendorRatingAndReviewResponse(responseJson:any){
    if(responseJson){
        this.setState({
          projectVendorRatingAndReviewData:responseJson
        })
    }
}

  async handlePostRatingVendorProfileResponse(responseJson:any){
    const showProfileVendor = await getStorageData("profileIdProjectVendor");
    if(responseJson){
      this.ratingAndReviewVendorprofileApiCallId = await this.getProjectVendorProfileRatings(configJSON.GET_Method , configJSON.getRatingAndReviewEndPoint + `?profile_id=${showProfileVendor}`);
    this.setState({
        isLoading: false,
    });
    this.setState({
      vendorProfileRateValue:0,
      vendorProfileCommentValue:''
    })
    }
  }


  handleGetProjectMemberProjectVendorProjects(responseJson:any){
    if(responseJson){
        this.setState({
            projectMemberVendorProjects:responseJson.data
        })
    }
    this.setState({
      isLoading: false,
  });
}

async handleRemoveFormJobProfileResponse(responseJson:any){
  if(responseJson){
    this.props.navigation.navigate("ProjectMembersJobRole");
    this.getProjectMemberProjectVendorApiCallId = await this.getProjectMemberProjectVendor(configJSON.GET_Method,configJSON.getProjectVendorApiEndPoint + `?project_id=${this.state.projectId}`);
  }
}


  handleCommentVendorProfile=(e:any)=>{
    if(e.target.value===''){
        this.setState({vendorCommentValidation:'Please give rating'})
    }
    else{
        this.setState({vendorCommentValidation:''});
    }
    this.setState({vendorProfileCommentValue:e.target.value})
    } 


    handleRateStarVendorProfile=(e:any)=>{
      if(e.target.value===''){
          this.setState({vendorRatingValidation:'Please give rating'})
      }
      else{
          this.setState({vendorRatingValidation:''});
      }
      this.setState({vendorProfileRateValue:e.target.value}) 
      }

      handleRatingAndReviewForm=()=>{
        if(this.state.vendorProfileCommentValue===''&& this.state.vendorProfileRateValue===0){
          this.setState({
            vendorRatingValidation:"Please give rating",
            vendorCommentValidation:'Please give review'
        })
        }
        else if(this.state.vendorProfileRateValue===0){
          this.setState({vendorRatingValidation:"Please give rating"})
        }
        else if(this.state.vendorProfileCommentValue===''){
            this.setState({vendorCommentValidation:'Please give review'})
            this.setState({vendorProfileRateValue:0})
        }
        else{
            this.postInviteVendorRatingAndReviewData();
        }
    }
  
    handleCloseVendorProfileModel = () => {
      this.setState({
          open: false,
          vendorProfileCommentValue:"",
          vendorProfileRateValue:0,
          vendorCommentValidation:'',
          vendorRatingValidation:'',
      });
  }

  handleOpenVendorProfileModel=()=>{
    this.setState({
        open:true
    })
}

  removeFromJobProjectVendorResponse = async (responseJson:any) => {
    if (responseJson.message) {
      this.layoutRef.current.handleChangeNotification("success", true, responseJson.message);
      this.getProjectMemberProjectVendorApiCallId = await this.getProjectMemberProjectVendor(configJSON.GET_Method,configJSON.getProjectVendorApiEndPoint + `?project_id=${this.state.projectId}`);
    } else if (responseJson.status) {
      this.layoutRef.current.handleChangeNotification("error", true, responseJson.error);
    } else if (Array.isArray(responseJson.errors)) {
      this.layoutRef.current.handleChangeNotification("error", true, responseJson.errors[0].token, "LandingPage");
    } else if (responseJson?.errors?.message) {
      this.layoutRef.current.handleChangeNotification("error", true, responseJson.errors.message);
    }
    this.setState({ isLoading: false });
  };
  

  removeFromJobProjectMemberProjectVendor =async (id:any) =>{
    this.removeFromJobRoleApiCallId = await this.removeFromJobProjectVendor(configJSON.DELETE_Method,configJSON.reomveFromJobApiEndPoint + `/${id}`)
}

removeFormJobProjectMemberProjectVendorProfile = async()=>{
  const cardIdProfilevendor = await getStorageData("vendorCardId");
  this.removeFromJobRoleProjectVendorProfileApiCallId = await this.removeFromJobProjectVendor(configJSON.DELETE_Method,configJSON.reomveFromJobApiEndPoint + `/${cardIdProfilevendor}`)

}

  navigateToProjectVendorManageProfile=(profileId:number,account_id_vendor:number ,projectVendorCardId:number)=>{
    this.props.navigation.navigate("ProjectMemberProjectVendorProfile")
    setStorageData("profileIdProjectVendor", profileId); 
    setStorageData("vendorProfileAccountId",account_id_vendor) 
    setStorageData("vendorCardId",projectVendorCardId)
  }

  apiCall = async (pmjrMethod: string, pmjrEndPoint: string, pmjrFormData?: any) => {
    const pmjrAutoToken = await getStorageData("token");
    const pmjrHeader = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": pmjrAutoToken,
    };
    this.setState({ isLoading: true });
    const pmjrRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    pmjrRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(pmjrHeader)
    );
    pmjrRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      pmjrEndPoint
    );
    pmjrRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(pmjrFormData ? { token: pmjrAutoToken } : pmjrHeader)
    );
    pmjrFormData && pmjrRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      pmjrFormData
    );
    pmjrRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      pmjrMethod
    );
    runEngine.sendMessage(pmjrRequestMessage.id, pmjrRequestMessage);
    return pmjrRequestMessage.messageId;
  };

  getProjectMemberProjectVendor = async (method: string, endPoint: string) => {
    const authenticationToken = await getStorageData('token', false)
    const header = {
        "Content-Type": configJSON.exampleApiContentType,
        "token": authenticationToken
    };
    this.setState({ isLoading: true })
    const requestMessageProjectVendor = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessageProjectVendor.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
    );

    requestMessageProjectVendor.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessageProjectVendor.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        method
    );
    runEngine.sendMessage(requestMessageProjectVendor.id, requestMessageProjectVendor);
    return requestMessageProjectVendor.messageId;
};

removeFromJobProjectVendor = async (method: string, endPoint: string) => {
  const authenticationToken = await getStorageData('token', false)
  const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": authenticationToken
  };
  this.setState({ isLoading: true })
  const requestMessageremovefromJobProjectVendor = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  requestMessageremovefromJobProjectVendor.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
  );

  requestMessageremovefromJobProjectVendor.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );
  requestMessageremovefromJobProjectVendor.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
  );
  runEngine.sendMessage(requestMessageremovefromJobProjectVendor.id, requestMessageremovefromJobProjectVendor);
  return requestMessageremovefromJobProjectVendor.messageId;
};

getProjectMemberProjectVendorProfile = async (method: string, endPoint: string) => {
  const authenticationToken = await getStorageData('token', false)
  const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": authenticationToken
  };
  this.setState({ isLoading: true })
  const requestMessageProjectVendorProfile = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  requestMessageProjectVendorProfile.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
  );

  requestMessageProjectVendorProfile.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );
  requestMessageProjectVendorProfile.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
  );
  runEngine.sendMessage(requestMessageProjectVendorProfile.id, requestMessageProjectVendorProfile);
  return requestMessageProjectVendorProfile.messageId;
};

getProjectVendorProfileRatings = async (method: string, endPoint: string) => {
  const authenticationToken = await getStorageData('token', false)
  const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": authenticationToken
  };
  const requestMessageVendorProfileRatings = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  requestMessageVendorProfileRatings.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
  );

  requestMessageVendorProfileRatings.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );
  requestMessageVendorProfileRatings.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
  );
  runEngine.sendMessage(requestMessageVendorProfileRatings.id, requestMessageVendorProfileRatings);
  return requestMessageVendorProfileRatings.messageId;
};

getProjectMemberVendorProfileProjects = async (method: string, endPoint: string) => {
  const authenticationToken = await getStorageData('token', false)
  const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": authenticationToken
  };
  const requestMessageVendorProfileProjects = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  requestMessageVendorProfileProjects.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
  );

  requestMessageVendorProfileProjects.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );
  requestMessageVendorProfileProjects.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
  );
  runEngine.sendMessage(requestMessageVendorProfileProjects.id, requestMessageVendorProfileProjects);
  return requestMessageVendorProfileProjects.messageId;
};


postInviteVendorRatingAndReviewData = async () => {
  const accoutIdVendor = await getStorageData("vendorProfileAccountId");
  this.setState({open:false})
  const authenticationToken = await getStorageData('token', false)
  const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": authenticationToken
  };
  this.setState({ isLoading: true })
  const requestMessageInviteVendorRating = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );
  this.postRatingAndReviewVendorProfileApiCallId = requestMessageInviteVendorRating.messageId;

  requestMessageInviteVendorRating.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postRatingAndReviewEndPoint
  );

  const httpBody = {
      review : {
        "comment":this.state.vendorProfileCommentValue,
        "given_star":this.state.vendorProfileRateValue,
        "reviewable_id":accoutIdVendor,
        "reviewable_type":"AccountBlock::Account"
      }
  };

  requestMessageInviteVendorRating.addData(
  getName(MessageEnum.RestAPIRequestBodyMessage),
  JSON.stringify(httpBody)
  );

  requestMessageInviteVendorRating.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );
  requestMessageInviteVendorRating.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
  );
  runEngine.sendMessage(requestMessageInviteVendorRating.id, requestMessageInviteVendorRating);
  return requestMessageInviteVendorRating.messageId;
};

  handleModelOpen = (type: string) => {
    this.setState({ isModelOpen: true, modelType: type });
  }

  handleModelClose = () => {
    this.setState({ isModelOpen: false, modelType: "" });
  }

  handleManageAccessibility = (permissions: any, department: any, employeeId: string) => {
    this.handleModelOpen("manageAccessibility");
    const manageModelPermssionList = this.state.permssionsList.map((permission: any) => {
      return { ...permission, selected: permissions.some((data: any) => data.slug === permission.attributes.slug) }
    });
    const selectedDept = this.state.departmentsList.find((dept: any) => department && dept.attributes.name === department.name && dept.attributes.level === department.level);
    this.setState({ manageModelPermssionList: manageModelPermssionList, manageModelSelectedDept: department ? selectedDept.id : "", employeeIdToUpdate: employeeId });
  }

  handleRemoveFromProject = async () => {
    this.handleMenuClose();
    const emplyeeToUpddate = this.state.projectMembersJobRole.find((data: any) => data.id === this.state.employeeIdToUpdate);
    this.removePipelineEmployeeFromProjectApiCalledId = await this.apiCall(configJSON.DELETE_Method, (emplyeeToUpddate.attributes.activated ? configJSON.simpleRemovePipelineEmployeeFromProjectApiEndPoint : configJSON.jobRoleRemovePipelineEmployeeFromProjectApiEndPoint) + emplyeeToUpddate.id);
  }

  savePermissionsAPICall = async (formData: any) => {
    const emplyeeToUpddate = this.state.projectMembersJobRole.find((data: any) => data.id === this.state.employeeIdToUpdate);
    this.managePipelineEmployeeAccessibilityApiCalledId = await this.apiCall(configJSON.put_method, (emplyeeToUpddate.attributes.activated ? configJSON.managePipelineEmployeeAccessibilityApiEndPoint : configJSON.jobRoleManagePipelineEmployeeAccessibilityApiEndPoint) + emplyeeToUpddate.id, formData);
    this.handleModelClose();
    this.setState({ manageModelPermssionList: [], manageModelSelectedDept: "", employeeIdToUpdate: "" });
  }

  handleAppBarValue = (event: React.ChangeEvent<{}>, value: number) => {
    this.setState({ tabValue: value });
  }

  handleMenuOpen = (event: any, employeeId: string) => {
    this.setState({ anchorEl: event.currentTarget, employeeIdToUpdate: employeeId });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null, employeeIdToUpdate: "" });
  };

  handlePageChange = async (event: any, value: number) => {
    this.setState({ currentPage: value });
  }

  handlePageChangeProjectVendor = async (event: any, value: number) => {
    this.getProjectMemberProjectVendorApiCallId = await this.getProjectMemberProjectVendor(configJSON.GET_Method,configJSON.getProjectVendorApiEndPoint + `?project_id=${this.state.projectId}&page=${value}&per_page=9`);
    window.scrollTo(0, 0);
    this.setState({ currentPage: value });
};

  handleSaveEmployeeApiCall = async (formData: any) => {
    this.addPipelineEmployeeApiCalledId = await this.apiCall(configJSON.httpPostMethod, configJSON.addPipelineEmployeeApiEndPoint + this.state.projectId, formData);
  }
  // Customizable Area End
}