import React from "react";
import { Card, Box, CardContent, Typography, Link, CardMedia } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { pushpaImage, Depticon, Vendoricon, clock, iconC, location, workicon,Bg_Copy } from "./assets";
import { styled } from '@material-ui/core/styles';
import { imgvendor } from "./assets";
interface MyProps {
  data: any;
  serialnumber: any;
  datavalue: any;
}



const useStyles = makeStyles({

  card: () => ({
    width: 230,
    marginTop: '10px',
    borderRadius: 14,
    padding: 5,
    border: "1px solid #E1E6EC",
    position: 'relative',
    '& .MuiCardContent-root': {
      padding: 0
    },
    "@media(max-width: 500px)": {
      width: 256
    },
    "@media(min-width: 1440px)": {
      width: '290px',
    },
  }),
  carddiv: () => ({
    margin: '0px',
  })
  ,
  cardsub1: () => ({
    display: 'flex',
    gridColumnGap: '10px',
    justifyContent: 'start',
    marginTop: '5px',
    marginLeft: '5px',

  }),
  imgdiv: () => ({
    height:"50px",
    borderRadius:"50px"
,   
    "& .MuiCardMedia-img": {
      height:"50px",
      borderRadius:"50px"

    },

  }),
  InfoDiv: () => ({
    gridRowGap: '10px',
    width: '100%'
  }),
  heading: () => ({
    fontWeight: 500,
    fontSize: '16px',
    paddingBottom: 4,
    fontFamily: "urbanist",
  }),
  subheading: () => ({
    fontSize: '14px',
    fontFamily: "urbanist",
  }),
  address: () => ({
    display: 'flex',
    justifyContent: 'space-between',
  }),
  addtag: () => ({
    fontSize: '12px',
    color: '#6D89AF',
    margin: 0,
    paddingTop: '4px',
    fontFamily: "urbanist",
  }),
  iconWork: () => ({
    paddingTop: '2px'

  }),
  icon: () => ({}),
  exp: () => ({
    fontSize: '12px',
    marginLeft: '3px',
    color: '#3389ff',
    fontFamily: "urbanist",

  }),
})
export default function VendorsCard(props: MyProps) {

  const { data, serialnumber, datavalue } = props;
  const classes = useStyles({ data });

  return (
    <Box className={classes.carddiv}>
      <Card className={classes.card}>
        <Box className={classes.cardsub1}>
          <Box className={classes.imgdiv}>
            <CardMedia
              component="img"
              alt="Image"
              image={datavalue?.attributes?.profile_image||Bg_Copy}
            />
          </Box>
          <Box className={classes.InfoDiv}>
            <div className={classes.heading}>{datavalue?.attributes?.full_name}</div>
            <div className={classes.subheading}>{datavalue?.attributes?.position}</div>
            <div className={classes.address}>
              <p className={classes.addtag}>{datavalue?.attributes?.address}</p>
              <div className={classes.iconWork}>
                <img src={workicon} alt="" className={classes.icon} />
                <span className={classes.exp}>{datavalue?.attributes?.experience||0}Yrs</span>
              </div>
            </div>
          </Box>


        </Box>
      </Card>
    </Box>
  );
}
