import { Message } from "../../framework/src/Message";
import { runEngine } from "../../framework/src/RunEngine";
import { getStorageData, setStorageData } from "../../framework/src/Utilities";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../framework/src/BlockComponent";
import { isEmail, confirmPasswordValidate, passwordValidate, phoneValidate } from "../../framework/src/Utilities";
import React from "react";
import NewPassword from "../../blocks/forgot-password/src/NewPassword.web";

// Customizable Area Start
// Customizable Area End

//export const configJSON = require("./config");
export interface Props {
  navigation: any;
  // Customizable Area Start
  handleClose1:any
  getallStudioList:any
  navigationvalue:any
  selected?: Date | null;
  onChange?: (date: Date | null) => void;
  showTimeSelect?: boolean;
  showTimeSelectOnly?: boolean;
  timeIntervals?: number;
  timeCaption?: string;
  dateFormat?: string;
  customInput?: JSX.Element;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtSavedValue:string;
  image:any;
  imageerror:string;
  vendorProfileActiveStep: number;
  vendorProfileData: any;
  vendorProfileDataError: any;
  vendorProfileDataFiles: { old: any, new: any };
  vendorProfileDataimage: { old: any, new: any };
  vendorProfileNotification: { type: string, open: boolean, message: string, route?: string };
  amPmopen:string;
  amPmclose:string;
  cropVarietyList:any;
  initialCropFormValues:any
  chips:any;
  
  studiotype:string[]
  cityData:any;
  stateData: any;
  countryCodeSelected:any;
  countryname:any
  phoneNumberError: { invalid: boolean, require: boolean };
  editstudio:any;
  imagedataimage:any
  studiovalue:''
  imagename:any
  amPmOpen: string;
  amPmClose: string;

  // Customizable Area End
}

interface SS {
  // Customizable Area Start
 
  // Customizable Area End
}

export default class CreateNewStudioController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  notificationcallid: string = "";
  getCitiesMessageId: string = "";
  globalSearchCallId: string = "";
  getStatesMessageId: string = "";
  createStudioCallid:string="";
  updateStudioCallid:string="";
  studiodetailapiPH:string="";
  StateSearchApi:string="";
  fileUpload: any;
  imageUpload:any;
  
  
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.fileUpload = React.createRef();
    this.imageUpload = React.createRef();
    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
       getName(MessageEnum.CountryCodeMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      txtSavedValue:'',
      image:null,
      imageerror:'',
      vendorProfileActiveStep: 0,
      vendorProfileData: {
        fullname: "",
        "studio[certificates][]": [],
        "studio[photos][]":[],
        pricehour:'',
        'account[time]':new Date(),
        'account[timeclose]':new Date(),
        priceday:"",
         contact:"",
         email:"",
         Description:"",
         Address:"",
         city:'',
         state:'',
         "profile[state]":'',
         "profile[city]":'',
      
        
      },
      vendorProfileDataError: {
        fullname: { require: false },
        "studio[certificates][]": { require: false },
        "studio[photos][]":{require:false},
        pricehour:{require:false},
        priceday:{require:false},
        "account[time]":{require:false},
        'account[timeclose]':{require:false},
        contact:{require:false},
        email:{require:false},
        Description:{require:false},
        Address:{require:false},
        city:{require:false},
        state:{require:false},
        "profile[state]": { require: false },
        "profile[city]":{require: false},
        studiotype:{require:false},
        imageissue:{require:false},

       
       
      },
      vendorProfileDataFiles: { old: [], new: [] },
      vendorProfileDataimage: { old: [], new: [] },
      vendorProfileNotification: { type: "", open: false, message: "" },
      amPmopen:'AM',
      amPmclose:'AM',
      cropVarietyList:[],
      initialCropFormValues:{
        crop_variey:[]
      }
      ,
      chips:[],
  studiotype:[],
  studiovalue:'',
  cityData:[],
  stateData:[],
  countryCodeSelected:'IN',
  phoneNumberError: { invalid: false, require: false },
  editstudio:null,
  imagedataimage:null,
  imagename:'',
  countryname:'',
  amPmOpen: 'AM',
  amPmClose: 'AM',
      



      // Customizable Area End
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let msgvaluejob = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      

     
    }

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestvaluejob = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))

      const responseValuejob = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))

      if(this.createStudioCallid===apiRequestvaluejob)
      {
        if (responseValuejob.data) {
          this.props.handleClose1();
          this.props.getallStudioList();
          if (this.props.navigation && typeof this.props.navigation.navigate === 'function') {
            this.props.navigation.navigate("AllStudioBookingListPH");
          } else {
            console.error("Navigation prop is not available or navigate is not a function");
          }
        }
        else{
          this.invaliderrorNotification(responseValuejob.errors.message);
          console.log(responseValuejob.errors,"error_response......")
        }
      }
      if(this.getCitiesMessageId===apiRequestvaluejob)
      {
        this.setState({cityData:responseValuejob})
      }
      if(this.getStatesMessageId===apiRequestvaluejob)
      {

        this.setState({
          stateData:responseValuejob
        })
        
      }
      if(this.studiodetailapiPH===apiRequestvaluejob)
      {
      this.updateVendorProfile(responseValuejob)  
      }
      if(this.updateStudioCallid===apiRequestvaluejob) {
          if (responseValuejob.data) {
            this.props.handleClose1();
            this.props.getallStudioList();
            if (this.props.navigation && typeof this.props.navigation.navigate === 'function') {
              this.props.navigation.navigate("AllStudioBookingListPH");
            } else {
              console.error("Navigation prop is not available or navigate is not a function");
            }
          } else {
            this.invaliderrorNotification(responseValuejob.errors.message);
            console.log(responseValuejob.errors, "error_response......");
          }
          
          await setStorageData("studiotype", "createstudio");
      }
    }
    this.getTokenAndCountryCodeSelected(message);

  }

  // Customizable Area Start
  
  async componentDidMount() {

    this.getStatesMessageId = await this.getStatesAndCitiesApiCall("https://api.countrystatecity.in/v1/countries/in/states");
    const studiotype=await getStorageData("studiotype");
    this.setState({editstudio:studiotype})
    console.log("edit studio",this.state.editstudio)
    const id =await getStorageData("editstudio")
    
    if(studiotype=="editstudio")
    this.studiodetailapiPH = await this.apiCallvalue("GET", "bx_block_customizableformworkflow3/studios/" + id);
    const imagedatavalue=await getStorageData("imagedatavalue");
    console.log("imagedatavalue",imagedatavalue)
    this.setState({imagedataimage:imagedatavalue})
   
  };
  handleImage = async(e: any) => {
    if(e.target.value=="")
    {
      this.setState({ vendorProfileDataError: { ...this.state.vendorProfileDataError, imageissue: { require: true}}})
    }
    else
    {
      this.setState({ vendorProfileDataError: { ...this.state.vendorProfileDataError, imageissue: { require: false}}})
    }
   
  
    this.setState({ image: e.target.files[0] });
    console.log("photo",e.target.files[0])
   
  };

  convertToTimeObject = (timeString:any) => {
    const [time, modifier] = timeString.split(' ');
    let [hours, minutes] = time.split(':');
  
    if (modifier === 'PM' && hours !== '12') {
      hours = parseInt(hours, 10) + 12;
    }
    if (modifier === 'AM' && hours === '12') {
      hours = '00';
    }
  
    const date = new Date();
    date.setHours(parseInt(hours, 10));
    date.setMinutes(parseInt(minutes, 10));
    date.setSeconds(0);
  
    return { date, amPm: modifier };
  };

  async updateVendorProfile(responseValuejob:any) {
    const attributes = responseValuejob.data.attributes;
    console.log(attributes,"attributes")
    const { date: openTime, amPm: amPmOpen } = this.convertToTimeObject(attributes.open_time);    
    console.log(openTime,"OpenTime")
    const { date: closeTime, amPm: amPmClose } = this.convertToTimeObject(attributes.close_time);
    console.log(openTime,closeTime,"closeTimeAndOpenTime")
    const stateDetailedData = this.state.stateData.find((data: any) => {
      if (!attributes || !attributes.state) {
        return false;
      }
      return data.name.toLowerCase() === attributes.state.toLowerCase();
    }) || { id: null, name: "Unknown", iso2: "XX" };
    console.log('Matching state found:', stateDetailedData);

    if (stateDetailedData) {
      this.getCitiesMessageId = await this.getStatesAndCitiesApiCall("https://api.countrystatecity.in/v1/countries/in/states/" + stateDetailedData.iso2 + "/cities");
    } else {
      console.error("State not found in stateData array");
    }

    this.setState({
      vendorProfileData: {
        ...this.state.vendorProfileData,
        fullname: attributes.name,
        pricehour: attributes.price_per_hour,
        priceday: attributes.price_per_day,
        contact: attributes.phone_number,
        email: attributes.email,
        Description: attributes.description,
        Address: attributes.address,
        "profile[city]": attributes.city,
        "studio[certificates][]": attributes.certificates,
        "studio[photos][]": attributes.photos,
        "profile[state]": stateDetailedData ? stateDetailedData.iso2 : '',
        'account[time]':openTime,
        'account[timeclose]':closeTime,
      },
      amPmOpen,
      amPmClose,
      vendorProfileDataFiles: { new: [], old: attributes.certificates },
      vendorProfileDataimage: { new: [], old: attributes.photos },
      studiotype: attributes.studio_type,
      countryCodeSelected: attributes.country_code,
      image: attributes.image,
      imagename: attributes.image_file_name,
    });
  
      }
  


    handleChangeProfileDetails = async (event: any, key: string) => {
      const newValue = event.target.value;
      const profileDataErrors = this.state.vendorProfileDataError;
     console.log("stTE....",newValue)
      const profileData = this.state.vendorProfileData;
      profileData[key] = newValue;
      this.setState({ vendorProfileData: profileData });
      if (!newValue) {
        profileDataErrors[key].require = true;
        this.setState({ vendorProfileDataError: profileDataErrors });
        return false;
      } else {
        profileDataErrors[key].require = false;
        this.setState({ vendorProfileDataError: profileDataErrors });
      }
  
      if (key === "profile[state]") {
        this.getCitiesMessageId = await this.getStatesAndCitiesApiCall("https://api.countrystatecity.in/v1/countries/in/states/"+ newValue + "/cities");
        profileData["profile[city]"] = "";
        this.setState({ vendorProfileData: profileData });
      } 
      
    }
 
  handleVendorProfileMoveOnNextPage = () => {
     if (!this.state.vendorProfileActiveStep) {
      if (!this.state.vendorProfileData.fullname||!this.state.vendorProfileData.pricehour||!this.state.vendorProfileData.priceday||!this.state.vendorProfileData.contact||!this.state.vendorProfileData.email||!this.state.vendorProfileData.Description||!this.state.vendorProfileData.Address||!this.state.vendorProfileData["profile[state]"]||!this.state.vendorProfileData["profile[city]"]||!this.state.image||!this.state.studiotype) {
     this.setState({ vendorProfileDataError: { ...this.state.vendorProfileDataError, fullname: { require: !Boolean(this.state.vendorProfileData.fullname) }, pricehour:{require:!Boolean(this.state.vendorProfileData.pricehour)},priceday:{require:!Boolean(this.state.vendorProfileData.priceday)} ,email:{require:!Boolean(this.state.vendorProfileData.email)},Description:{require:!Boolean(this.state.vendorProfileData.Description)},Address:{require:!Boolean(this.state.vendorProfileData.Address)},"profile[city]":{require:!Boolean(this.state.vendorProfileData["profile[city]"])},"profile[state]":{require:!Boolean(this.state.vendorProfileData["profile[state]"])},studiotype:{require:!Boolean(this.state.vendorProfileData.studiotype)},imageissue:{require:!Boolean(this.state.vendorProfileData.imageissue)}
    } });
    this.setState({ phoneNumberError: { invalid: !Boolean(this.state.phoneNumberError), require:  !Boolean(this.state.phoneNumberError) } });
    
     return false;
    }
  
   
  }
  this.setState({ vendorProfileActiveStep: this.state.vendorProfileActiveStep + 1 });
}
  
invalidNotification = (message: string) => {
    this.setState({ vendorProfileNotification: { type: 'warning', open: true, message: message } });
    return false;
  }
  invaliderrorNotification = (message: string) => {
    this.setState({ vendorProfileNotification: { type: 'error', open: true, message: message } });
    return false;
  }
  getStatesAndCitiesApiCall = async (endPoint: string) => {
    const XCSCAPIKEY = "Z1dmZFJDWjlMSjl0M082ZUUyU3JESkJCQTdZeTd6UEVINXdreTdjWQ=="
    const header = {
      "Content-Type": "application/json",
      "X-CSCAPI-KEY": XCSCAPIKEY
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
handleVendorProfileStepBack = () => {
    this.setState({ vendorProfileActiveStep: this.state.vendorProfileActiveStep - 1 });
  }
  handleVendorProfileCloseNotification = () => {
    this.setState({ vendorProfileNotification: { type: "", open: false, message: "" } });
  }
  handleInputBlur = (labelName: string) => {
    const inputLabel = document.getElementById(labelName);
    if (inputLabel) {
      inputLabel.style.color = "#6D89AF";
    }
  };

  handleInputFocus = (labelName: string) => {
    const inputLabel = document.getElementById(labelName);
    if (inputLabel) {
      inputLabel.style.color = "#F5C42C";
    }
  };

  vendorProfileHandleCancelPDF = async (fileObj: any) => {
    if (!fileObj.id) {
      const profileData = this.state.vendorProfileData;
      profileData["studio[certificates][]"] = profileData["studio[certificates][]"].filter((data: any) => data.name !== fileObj.name);
      this.setState({ vendorProfileData: profileData, vendorProfileDataFiles: { ...this.state.vendorProfileDataFiles, new: this.state.vendorProfileDataFiles.new.filter((data: any) => data.name !== fileObj.name) } });
    } else {
      const profileData = this.state.vendorProfileData;
      profileData["studio[certificates][]"] = profileData["studio[certificates][]"].filter((data: any) => data.id !== fileObj.id);
      this.setState({ vendorProfileData: profileData, vendorProfileDataFiles: { ...this.state.vendorProfileDataFiles, old: this.state.vendorProfileDataFiles.old.filter((data: any) => data.id !== fileObj.id) } });
    }
  }
  vendorProfileHandleCancelImage = async (fileObj: any) => {
    if (!fileObj.id) {
      const profileData = this.state.vendorProfileData;
      profileData["studio[photos][]"] = profileData["studio[photos][]"].filter((data: any) => data.name !== fileObj.name);
      this.setState({ vendorProfileData: profileData,  vendorProfileDataimage: { ...this.state.vendorProfileDataimage, new: this.state.vendorProfileDataimage.new.filter((data: any) => data.name !== fileObj.name) } });
    } else {
      const profileData = this.state.vendorProfileData;
      profileData["studio[photos][]"] = profileData["studio[photos][]"].filter((data: any) => data.id !== fileObj.id);
      this.setState({ vendorProfileData: profileData, vendorProfileDataimage: { ...this.state.vendorProfileDataimage, old: this.state.vendorProfileDataimage.old.filter((data: any) => data.id !== fileObj.id) } });
    }
  }
  handleSelectDocuments = async (event: any) => {
    if (event.target.files && event.target.files[0]) {
      const profileData = this.state.vendorProfileData;
      profileData["studio[certificates][]"] = [...profileData["studio[certificates][]"], ...event.target.files];
      this.setState({ vendorProfileData: profileData, vendorProfileDataFiles: { ...this.state.vendorProfileDataFiles, new: [...this.state.vendorProfileDataFiles.new, ...event.target.files] } });

    }
    console.log("profile.......",this.state.vendorProfileData["studio[certificates][]"])
  
  }

  handleClickAddDocuments = () => {
    this.fileUpload.current.click();
  }
  handleAddMorePhotos = async (event: any) => {
    if (event.target.files && event.target.files[0]) {
      const profileData = this.state.vendorProfileData;
      profileData["studio[photos][]"] = [...profileData["studio[photos][]"], ...event.target.files];
      this.setState({ vendorProfileData: profileData, vendorProfileDataimage: { ...this.state.vendorProfileDataimage, new: [...this.state.vendorProfileDataimage.new, ...event.target.files] } });
    }
  }
  handleClickAddMore = () => {
    this.imageUpload.current.click();
  }

  handleTimeChangeOpen = (time:any) => {
    const hours = time.getHours();
    const amPmOpen = hours >= 12 ? 'PM' : 'AM';
    this.setState((prevState) => ({
      vendorProfileData: {
        ...prevState.vendorProfileData,
        'account[time]': time,
      },
      amPmOpen,
    }));
  };

  handleTimeChangeclose = (time:any) => {
    const hours = time.getHours();
    const amPmClose = hours >= 12 ? 'PM' : 'AM';
    this.setState((prevState) => ({
      vendorProfileData: {
        ...prevState.vendorProfileData,
        'account[timeclose]': time,
      },
      amPmClose,
    }));
  };

  handleAmPmChangeOpen = (event:any) => {
    const amPm = event.target.value;
    const time = new Date(this.state.vendorProfileData['account[time]']);
    let hours = time.getHours();
  
    if (amPm === 'PM' && hours < 12) {
      hours += 12;
    }
    if (amPm === 'AM' && hours >= 12) {
      hours -= 12;
    }
  
    time.setHours(hours);
  
    this.setState({
      vendorProfileData: {
        ...this.state.vendorProfileData,
        'account[time]': time,
      },
      amPmOpen: amPm,
    });
  };

  handleAmPmChangeClose = (event:any) => {
    const amPm = event.target.value;
    const time = new Date(this.state.vendorProfileData['account[timeclose]']);
    let hours = time.getHours();
  
    if (amPm === 'PM' && hours < 12) {
      hours += 12;
    }
    if (amPm === 'AM' && hours >= 12) {
      hours -= 12;
    }
  
    time.setHours(hours);
  
    this.setState({
      vendorProfileData: {
        ...this.state.vendorProfileData,
        'account[timeclose]': time,
      },
      amPmClose: amPm,
    });
  };
  
  handleChangecontact=(event:any)=>{
    const phoneNumber = event.currentTarget?.value;
    
    this.setState({
      vendorProfileData:{...this.state.vendorProfileData,contact:event.target.value.replace(/[^0-9]/g, '')}
    })

    if (!phoneNumber) {
      this.setState({ phoneNumberError: { invalid: false, require: true } });
      return false;
    } else
      this.setState({ phoneNumberError: { invalid: false, require: false } });
    const validPhoneNumber = phoneValidate("phone number", phoneNumber);
    if (!validPhoneNumber.status) {
      this.setState({ phoneNumberError: { invalid: true, require: false } })
    } else {
      if (this.state.countryCodeSelected === "91" && phoneNumber.length !== 10) {
        this.setState({ phoneNumberError: { invalid: true, require: false } })
      } else {
        this.setState({ phoneNumberError: { invalid: false, require: false } })
      }
    }
  }
 
  handleChangeName=(event:any)=>{
    if(event.target.value=="")
    {
      this.setState({ vendorProfileDataError: { ...this.state.vendorProfileDataError, fullname: { require: true}}})
    }
    else
    {
      this.setState({ vendorProfileDataError: { ...this.state.vendorProfileDataError, fullname: { require: false}}})
    }
    this.setState({
      vendorProfileData:{...this.state.vendorProfileData,fullname:event.target.value}
    })
  
  }
  handleChangecity=(event:any)=>{
    this.setState({
      vendorProfileData:{...this.state.vendorProfileData,city:event.target.value}
    })
    console.log("city.",this.state.vendorProfileData.city)
  }
  
  handleChangestate=(event:any)=>{
    this.setState({
      vendorProfileData:{...this.state.vendorProfileData,state:event.target.value}
    })
    console.log("state.",this.state.vendorProfileData.state)
  }
  handleChangepricehour=(event:any)=>{
    if(event.target.value==="")
    {
      this.setState({ vendorProfileDataError: { ...this.state.vendorProfileDataError, pricehour : { require: true}}})
    }
    else{
      this.setState({ vendorProfileDataError: { ...this.state.vendorProfileDataError, pricehour : { require: false}}})
    }
    this.setState({
      vendorProfileData:{...this.state.vendorProfileData,pricehour:event.target.value.replace(/[^0-9]/g, '')}
    })
  
  }
  handleChangepriceday=(event:any)=>{
    if(event.target.value==="")
    {
      this.setState({ vendorProfileDataError: { ...this.state.vendorProfileDataError, priceday : { require: true}}})
    }
    else{
      this.setState({ vendorProfileDataError: { ...this.state.vendorProfileDataError, priceday : { require: false}}})
    }
    this.setState({
      vendorProfileData:{...this.state.vendorProfileData,priceday:event.target.value.replace(/[^0-9]/g, '')}
    })
  
  }
  setEmail = (e: any) => {
    let email = e?.currentTarget?.value;
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const emailArray = email?.split("@");

    !regex.test(this.state.vendorProfileData.email)
      ?
      this.setState({ vendorProfileDataError: { ...this.state.vendorProfileDataError, email: { require: true}}})
      :  this.setState({ vendorProfileDataError: { ...this.state.vendorProfileDataError, email: { require: false}}})

    this.setState({ vendorProfileData:{...this.state.vendorProfileData,email: e.currentTarget?.value }});

  };
  handleChangeDescription=(event:any)=>{
    if(event.target.value=="")
    {
      this.setState({ vendorProfileDataError: { ...this.state.vendorProfileDataError, Description: { require: true}}})
    }
    else
    {
      this.setState({ vendorProfileDataError: { ...this.state.vendorProfileDataError, Description: { require: false}}})
    }
    this.setState({
      vendorProfileData:{...this.state.vendorProfileData,Description:event.target.value}
    })
    console.log("Description.....",this.state.vendorProfileData.Description)
  }
  handleChangeAddress=(event:any)=>{
    if(event.target.value=="")
    {
      this.setState({ vendorProfileDataError: { ...this.state.vendorProfileDataError, Address: { require: true}}})
    }
    else{
      this.setState({ vendorProfileDataError: { ...this.state.vendorProfileDataError, Address: { require: false}}})
    }
    this.setState({
      vendorProfileData:{...this.state.vendorProfileData,Address:event.target.value}
    })
    console.log("Address.....",this.state.vendorProfileData.Address)
  }
  handleCropVarietyValue=(newValue:any)=>{
   console.log(newValue)
  }
  returnCropVarietyPlaceholder=()=>{
    return "Select studio type"
  }
  handleChangechip=(newChips:any)=>{
  this.setState({chips:newChips})
  }

   handleSelecetedTags=(items:any)=> {
    console.log("props123 studiotype Add-->",items)
    
    // const eachvalue=items.map((item:any)=>item).join(',')
    if(items==="")
    {
      this.setState({ vendorProfileDataError: { ...this.state.vendorProfileDataError, studiotype: { require: true}}})
    }
    else{
      this.setState({ vendorProfileDataError: { ...this.state.vendorProfileDataError, studiotype: { require: false}}})
    }
    this.setState({studiotype:items})
  }



  studiocreationapi = async () => {
    const token = await getStorageData("token",false);   
    const header = {
      "token": token
    };
     if (!this.state.vendorProfileData["studio[certificates][]"].length) {
      this.invalidNotification("Please upload Certificates & Documents");
    }
  else if (!this.state.vendorProfileData["studio[photos][]"].length) {
      this.invalidNotification("Please upload image");
    }
    else
    {
  
    const formData = new FormData();
    formData.append("studio[name]",this.state.vendorProfileData.fullname) 
    formData.append("studio[price_per_hour]", this.state.vendorProfileData.pricehour);
    formData.append("studio[price_per_day]", this.state.vendorProfileData.priceday);
    formData.append("studio[open_time]", this.state.vendorProfileData['account[time]'].toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    }));
    formData.append("studio[close_time]",this.state.vendorProfileData[ 'account[timeclose]'].toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    }));
    formData.append("studio[contact_number]", this.state.countryCodeSelected+this.state.vendorProfileData.contact);
    formData.append("studio[email]", this.state.vendorProfileData.email);
    formData.append("studio[description]",this.state.vendorProfileData.Description);
    formData.append("studio[address]",this.state.vendorProfileData.Address)
    formData.append("studio[image]",this.state.image)
    formData.append("studio[studio_type]",this.state.studiotype.join(","))
    const stateDetailedData = this.state.stateData.find((data: any) => data.iso2 === this.state.vendorProfileData["profile[state]"]);
    formData.append("studio[state]",stateDetailedData.name)
    formData.append("studio[city]",this.state.vendorProfileData["profile[city]"]);
    formData.append("studio[country_name]",this.state.countryname)
    for (let file of this.state.vendorProfileDataFiles.new) {
      formData.append(`studio[certificates][]`, file);
    }
    for (let file of this.state.vendorProfileDataimage.new) {
      formData.append(`studio[photos][]`, file);
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createStudioCallid = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
   
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_customizableformworkflow3/studios"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"

    );
    runEngine.sendMessage(requestMessage.id, requestMessage);  
    } 
}   
apiCallvalue = async (studioBookingDetailMethod: string, studioBookingDetailEndPoint: string, studioBookingDetailBodyData?: any) => {
  const authToken = await getStorageData("token");
  const studioBookingDetailHeader = {
    "Content-Type": 'application/json',
    token: authToken
  };
  const studioBookingDetailRequestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  
  studioBookingDetailRequestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    studioBookingDetailEndPoint
  );
  studioBookingDetailRequestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(studioBookingDetailHeader)
  );
 
  studioBookingDetailRequestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    studioBookingDetailMethod
  );
  studioBookingDetailBodyData && studioBookingDetailRequestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(studioBookingDetailBodyData)
  );
  runEngine.sendMessage(studioBookingDetailRequestMessage.id, studioBookingDetailRequestMessage);
  return studioBookingDetailRequestMessage.messageId;
}

// handleValue=(value:string)=>{
//   const statevalue=value
//   const XCSCAPIKEY = "Z1dmZFJDWjlMSjl0M082ZUUyU3JESkJCQTdZeTd6UEVINXdreTdjWQ=="
//   const header = {
//     "Content-Type":"application/json",
//     "X-CSCAPI-KEY": XCSCAPIKEY 
//   };
//   const requestMessage = new Message(
//     getName(MessageEnum.RestAPIRequestMessage)
//   );
  
//   this.CitySearchApi = requestMessage.messageId;
//   requestMessage.addData(
//     getName(MessageEnum.RestAPIRequestHeaderMessage),
//     JSON.stringify(header)
//   );
//   requestMessage.addData(
//     getName(MessageEnum.RestAPIResponceEndPointMessage),
//     "https://api.countrystatecity.in/v1/countries/in/states/"+`${statevalue}/cities`
//   );
//   requestMessage.addData(
//     getName(MessageEnum.RestAPIRequestMethodMessage),
//     "GET"
//   );
//   runEngine.sendMessage(requestMessage.id, requestMessage);
  
  
// }



studioupdateapi = async () => {
  const token = await getStorageData("token",false);   
  const id =await getStorageData("editstudio")
  const header = {
    "token": token
  };
   if (!this.state.vendorProfileData["studio[certificates][]"].length) {
    this.invalidNotification("Please upload Certificates & Documents");
  }
else if (!this.state.vendorProfileData["studio[photos][]"].length) {
    this.invalidNotification("Please upload image");
  }
  else
  {

  const formData = new FormData();
  formData.append("studio[name]",this.state.vendorProfileData.fullname) 
  formData.append("studio[price_per_hour]", this.state.vendorProfileData.pricehour);
  formData.append("studio[price_per_day]", this.state.vendorProfileData.priceday);
  formData.append("studio[open_time]", this.state.vendorProfileData['account[time]'].toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }));
  formData.append("studio[close_time]",this.state.vendorProfileData[ 'account[timeclose]'].toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }));
  formData.append("studio[contact_number]", this.state.countryCodeSelected+this.state.vendorProfileData.contact);
  formData.append("studio[email]", this.state.vendorProfileData.email);
  formData.append("studio[description]",this.state.vendorProfileData.Description);
  formData.append("studio[address]",this.state.vendorProfileData.Address)
  if(this.state.image instanceof File && this.state.image?.type?.startsWith("image")){
    formData.append("studio[image]",this.state.image)
  }
  formData.append("studio[studio_type]",this.state.studiotype.join(","))
  const stateDetailedData = this.state.stateData.find((data: any) => data.iso2 === this.state.vendorProfileData["profile[state]"]);
  formData.append("studio[state]",stateDetailedData.name)
  formData.append("studio[country_name]",this.state.countryname)
  formData.append("studio[city]",this.state.vendorProfileData["profile[city]"]);

  const documentsIds = this.state.vendorProfileDataFiles.old.map((data: any) => data.id.toString());const photoIds= this.state.vendorProfileDataimage.old.map((data: any) => data.id.toString())
  formData.append(`studio[certificates_ids]`, documentsIds.toString());formData.append('studio[photos_ids',photoIds.toString());

    formData.append(`studio[certificates_ids]`, documentsIds.toString());


  for (let file of this.state.vendorProfileDataFiles.new) {
    formData.append(`studio[certificates][]`, file);
  }
  for (let file of this.state.vendorProfileDataimage.new) {
    formData.append(`studio[photos][]`, file);
  }
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.updateStudioCallid = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
 
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    "bx_block_customizableformworkflow3/studios/studio_update"+`/${id}`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    formData
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "PUT"

  );
  runEngine.sendMessage(requestMessage.id, requestMessage);  
  } 
}   

 getCountryName = async (countryName: string) => {
 console.log("cccc",countryName)
 this.setState({countryname:countryName})
this.setState({countryCodeSelected:countryName})
console.log("country",this.state.countryCodeSelected)
 }
 getTokenAndCountryCodeSelected = (message: Message) => {
  if (getName(MessageEnum.CountryCodeMessage) === message.id) {
    let selectedCode = message.getData(
      getName(MessageEnum.CountyCodeDataMessage)
    );

    if (selectedCode !== undefined) {
      this.setState({
        countryCodeSelected:
          selectedCode.indexOf("+") > 0
            ? selectedCode.split("+")[1]
            : selectedCode
      }, () => {
        if (this.state.countryCodeSelected === "91" && this.state.vendorProfileData.contact.length &&  this.state.vendorProfileData.contact.length !== 10) {
          this.setState({ phoneNumberError: { invalid: true, require: false } })
        }
      });
    }
  }
}
handlecountrycode=(e:any)=>{
  this.setState({countryCodeSelected:e.target.value})
}
  // Customizable Area End

}