import React from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  TypographyProps,
  Link, AppBar, Tabs, Tab, Stepper, Step, StepLabel, StepIconProps, InputAdornment, Select, MenuItem, IconButton
} from "@material-ui/core";
import CancelIcon from '@material-ui/icons/Cancel';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SideBar from "../../../components/src/SideBar";
import Header from "../../../components/src/Header.web";
import Footer from "../../../components/src/Footer";
import { createTheme, styled, ThemeProvider, withStyles } from "@material-ui/core/styles";
import Loader from "../../../components/src/Loader.web";
import VendorProfileSettingsController, {
  Props
} from "./VendorProfileSettingsController";
import { CustomButton, CustomInputlabelEmail, CustomTextField } from "../../email-account-login/src/CustomButton";
import CountryCodeSelector from "../../country-code-selector/src/CountryCodeSelector.web";
import { ColorlibConnector, ColorlibStepIconRoot } from "../../mobile-account-registration/src/ProductionHouseSignUp.web";
import CustomNotification from "../../../components/src/CustomNotification";

const images = require("./assets");

const themeStyle = createTheme({
  palette: {
    primary: {
      main: "#F5C42C",
      contrastText: "#fff"
    }
  },
  overrides: {
    MuiSelect: {
      select: {
        "&:focus": {
          border: "1px solid #F5C42C",
        }
      }
    }
  }
});


const CustomPHBackButtonBox = styled(CustomButton)({
  background: "#ffffff",
  border: "1px solid #17181d",
  '& .MuiButton-label': {
    fontSize: 14,
    color: '#17181d',
    fontWeight: 600,
    width: 163,
    height: 48,
  }
});

const CustomPHSignUPButtonBox = styled(CustomButton)({
  '& .MuiButton-label': {
    fontSize: 14,
    color: '#ffffff',
    height: 48,
    width: 163,
    fontWeight: 500,
  }
});

const CustomCSSOfTextField = withStyles({
  root: {
    backgroundColor: 'white',
    borderColor: "#f5c42c",
    "& .MuiOutlinedInput-root": {
      "& fieldset": { borderColor: "#E3EAF4" },
      "&:hover fieldset": { borderColor: "#f5c42c" },
      "&.Mui-focused fieldset": {
        backgroundColor: 'rgba(245,196,44,0.04)',
        borderColor: "#f5c42c",
      },
      borderRadius: "14px",
      fontWeight: 400,
      color: '#323232',
      "& .MuiOutlinedInput-input": {
        color: '#17181D',
        fontWeight: 600,
        fontSize: '14px',
        borderColor: '#e3eaf4',
        lineHeight: '19px',
        fontFamily: 'Urbanist',
      }
    },
    "& .MuiOutlinedInput-root.Mui-disabled": {
      "& fieldset": {
        border: "none"
      },
      "&:hover fieldset": {
        border: "none"
      },
      "&.Mui-focused fieldset": {
        border: "none",
        backgroundColor: '#f8fafe'
      },
      border: "none",
      backgroundColor: '#f8fafe',
      color: '#18272a',
      borderRadius: "14px",
      "& .MuiOutlinedInput-input": {
        fontWeight: 600,
        color: '#18272a',
        lineHeight: '19px',
        border: "none",
        fontSize: '14px',
        fontFamily: 'Urbanist',
      }
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#f5c42c"
    },
    "& .MuiInputBase-input::placeholder": {
      color: '#6d89af',
      fontWeight: 500,
      fontFamily: 'Urbanist',
    },
  }
})(CustomTextField);

export default class VendorProfileSettings extends VendorProfileSettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  vendorProfileRenderErrorMessage(errorType: boolean, errorMessage: string) {
    return <>{errorType ? <Typography style={{ color: 'red', fontFamily: 'Urbanist', fontSize: '13px', fontWeight: 600, lineHeight: '18px', letterSpacing: '0.4px' }}>{errorMessage}</Typography> : null}</>
  }

  VendorProfileColorlibStepIcon(props: StepIconProps) {
    const { active, completed } = props;
    const vendorProfileiIcons: { [index: string]: any } = { 1: "01", 2: "02", 3: "03" };
    const vendorProfileGetclassName = () => {
      if (active) { return "activeIcon" }
      else if (completed) { return "completedIcon" }
      else { return "disbaleIcon" }
    }

    return (
      <ColorlibStepIconRoot className={`stepIcon ${vendorProfileGetclassName()}`}>
        {vendorProfileiIcons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  renderVendorProfileStepOneView() {
    return <>
      <Grid container style={{ paddingInline: "12px" }}>
        <Grid item style={{ padding: "12px" }} xs={12} sm={6} md={6} lg={4}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomInputlabelEmail id="fullnameOfVendorProfile" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px" }}>Full Name</CustomInputlabelEmail>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomCSSOfTextField
              data-test-id="fullnameOfVendorProfileInput"
              type={'text'} id="outlined-basic"
              onFocus={() => {
                this.handleInputFocus("fullnameOfVendorProfile");
              }}
              onBlur={() => {
                this.handleInputBlur("fullnameOfVendorProfile");
              }}
              value={this.state.vendorProfileData["account[full_name]"]}
              placeholder="Enter the name of production house"
              variant="outlined"
              className="email_input"
              onChange={(e: any) => this.handleChangeProfileDetails(e, "account[full_name]")}
            />
            {this.vendorProfileRenderErrorMessage(this.state.vendorProfileDataError["account[full_name]"].require, "Name of vendor is required")}
          </Grid>
        </Grid>

        <Grid item style={{ padding: "12px" }} xs={12} sm={6} md={6} lg={4}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomInputlabelEmail id="emailOfVendorProfile" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px" }}>Email ID</CustomInputlabelEmail>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomCSSOfTextField data-test-id="emailOfVendorProfileInput" type={'text'} id="outlined-basic" variant="outlined"
              value={this.state.vendorProfileData["account[email]"]}
              className="email_input"
              disabled
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <img src={images.verifiedCircularIcon} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        <Grid item style={{ padding: "12px" }} xs={12} sm={6} md={6} lg={4}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomInputlabelEmail id="phoneOfVendorProfile" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px" }}>Phone Number</CustomInputlabelEmail>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomCSSOfTextField data-test-id="phoneOfVendorProfileInput" type={'text'} id="outlined-basic" variant="outlined"
              value={this.state.vendorProfileData["account[phone_number]"]}
              disabled
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <img src={images.verifiedCircularIcon} />
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position="start" style={{ cursor: 'hand', paddingRight: this.state.isVendorProfileSettingsSideBarOpen ? "35px" : "23px" }} className="phone_input_adornment_box vendor_profile_phone">
                    <CountryCodeSelector
                      allowPropChange={true}
                      navigation={this.props.navigation}
                      style={{ width: "80px" }}
                      disable={true}
                      value={this.state.vendorProfileData["account[country_code]"]}
                      id={"CountryCodeSelector"}
                    />
                    <Typography style={{ color: '#18272a', marginLeft: '9px' }}> +{this.state.vendorProfileData["account[country_code]"]}</Typography>
                    <Box style={{ height: '23px', borderLeft: '1px solid #6d89af', marginLeft: '12px' } as React.CSSProperties}></Box>
                  </InputAdornment>
                ),
              }}
              className="email_input"
            />
          </Grid>
        </Grid>

        <Grid item style={{ padding: "12px" }} xs={12} sm={6} md={6} lg={4}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomInputlabelEmail id="roleDropdown" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px" }}>Your Role</CustomInputlabelEmail>
          </Grid>
          <Grid className="roleDropdown" item xs={12} sm={12} md={12} lg={12}>
            <Select
              data-test-id="role-select-dropdown"
              id="demo-simple-select"
              value={this.state.vendorProfileData["account[role_id]"]}
              label="Age"
              displayEmpty
              onChange={(e: any) => this.handleChangeProfileDetails(e, "account[role_id]")}
              onFocus={() => {
                this.handleInputFocus("roleDropdown");
              }}
              onBlur={() => {
                this.handleInputBlur("roleDropdown");
              }}
            >
              <MenuItem value="">Select your role</MenuItem>
              {this.state.vendorProfileRolesData.map((role: any) => {
                return <MenuItem key={"role" + role.id} value={role.id}>{role.name}</MenuItem>
              })}
            </Select>
            {this.vendorProfileRenderErrorMessage(this.state.vendorProfileDataError["account[role_id]"].require, "Your role is required")}
          </Grid>
        </Grid>

        <Grid item style={{ padding: "12px" }} xs={12} sm={6} md={6} lg={4}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomInputlabelEmail id="deptDropdown" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px" }}>Select Dept.</CustomInputlabelEmail>
          </Grid>
          <Grid className="roleDropdown" item xs={12} sm={12} md={12} lg={12}>
            <Select
              data-test-id="dept-select-dropdown"
              id="demo-simple-select"
              displayEmpty
              value={this.state.vendorProfileData["account[department_id]"]}
              label="Age"
              onChange={(e: any) => this.handleChangeProfileDetails(e, "account[department_id]")}
              onFocus={() => {
                this.handleInputFocus("deptDropdown");
              }}
              onBlur={() => {
                this.handleInputBlur("deptDropdown");
              }}
            >
              <MenuItem value="">Select your department</MenuItem>
              {this.state.vendorProfileDepartmentsData.map((dept: any) => {
                return <MenuItem key={"dept" + dept.id} value={dept.id}>{dept.department_name}</MenuItem>
              })}
            </Select>
            {this.vendorProfileRenderErrorMessage(this.state.vendorProfileDataError["account[department_id]"].require, "Department is required")}
          </Grid>
        </Grid>

        <Grid item style={{ padding: "12px" }} xs={12} sm={6} md={6} lg={4}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomInputlabelEmail id="stateDropdown" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px" }}>State</CustomInputlabelEmail>
          </Grid>
          <Grid className="roleDropdown" item xs={12} sm={12} md={12} lg={12}>
            <Select
              data-test-id="state-select-dropdown"
              id="demo-simple-select"
              value={this.state.vendorProfileData["profile[state]"]}
              label="Age" displayEmpty
              onChange={(e: any) => this.handleChangeProfileDetails(e, "profile[state]")}
              onFocus={() => {
                this.handleInputFocus("stateDropdown");
              }}
              onBlur={() => {
                this.handleInputBlur("stateDropdown");
              }}
            >
              <MenuItem value="">Select your state</MenuItem>
              {this.state.stateData.map((state: any) => {
                return <MenuItem key={"state" + state.iso2} value={state.iso2}>{state.name}</MenuItem>
              })}
            </Select>
            {this.vendorProfileRenderErrorMessage(this.state.vendorProfileDataError["profile[state]"].require, "State is required")}
          </Grid>
        </Grid>

        <Grid item style={{ padding: "12px" }} xs={12} sm={6} md={6} lg={4}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomInputlabelEmail id="cityDropdown" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px" }}>City</CustomInputlabelEmail>
          </Grid>
          <Grid className="roleDropdown" item xs={12} sm={12} md={12} lg={12}>
            <Select
              data-test-id="city-select-dropdown"
              id="demo-simple-select" displayEmpty
              value={this.state.vendorProfileData["profile[city]"] || ""}
              label="Age"
              onChange={(e: any) => this.handleChangeProfileDetails(e, "profile[city]")}
              onFocus={() => {
                this.handleInputFocus("cityDropdown");
              }}
              onBlur={() => {
                this.handleInputBlur("cityDropdown");
              }}
            >
              <MenuItem value="">Select your city</MenuItem>
              {this.state.cityData.length ? this.state.cityData.map((city: any) => {
                return <MenuItem key={"city" + city.name} value={city.name}>{city.name}</MenuItem>
              }) : null}
            </Select>
            {this.vendorProfileRenderErrorMessage(this.state.vendorProfileDataError["profile[city]"].require, "City is required")}
          </Grid>
        </Grid>

        <Grid item style={{ padding: "12px" }} xs={12} sm={6} md={6} lg={4}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomInputlabelEmail id="pincodeOfVendorProfile" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px" }}>PIN Code</CustomInputlabelEmail>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomCSSOfTextField
              data-test-id="pincodeOfVendorProfileInput"
              onFocus={() => {
                this.handleInputFocus("pincodeOfVendorProfile");
              }}
              onBlur={() => {
                this.handleInputBlur("pincodeOfVendorProfile");
              }}
              type={'text'}
              value={this.state.vendorProfileData["profile[postal_code]"]}
              id="outlined-basic"
              onChange={(e: any) => this.handleChangeProfileDetails(e, "profile[postal_code]")}
              variant="outlined"
              className="email_input"
              placeholder="Enter PIN code"
            />
            {this.vendorProfileRenderErrorMessage(this.state.vendorProfileDataError["profile[postal_code]"].require, "PIN code is required")}
          </Grid>
        </Grid>
      </Grid>
    </>
  }

  renderVendorProfileStepTwoView() {
    return <>
      <Grid container style={{ paddingInline: "12px" }}>
        <Grid item style={{ padding: "12px" }} xs={12} sm={6} md={6} lg={4}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomInputlabelEmail id="gstOfVendorProfile" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px", paddingTop: "0px" }}>GST Number</CustomInputlabelEmail>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomCSSOfTextField
              onFocus={() => {
                this.handleInputFocus("gstOfVendorProfile");
              }}
              onBlur={() => {
                this.handleInputBlur("gstOfVendorProfile");
              }}
              data-test-id="gstOfVPInput"
              type={'text'}
              className="email_input"
              id="outlined-basic"
              onChange={(e: any) => this.handleChangeProfileDetails(e, "account[gst_number]")}
              variant="outlined"
              value={this.state.vendorProfileData["account[gst_number]"]}
              placeholder="Enter your GST number"
            />
            {this.vendorProfileRenderErrorMessage(this.state.vendorProfileDataError["account[gst_number]"].invalid, "GST Number should be in the format 22AAAAA0000A1Z5")}
            {this.vendorProfileRenderErrorMessage(this.state.vendorProfileDataError["account[gst_number]"].require, "GST number is required")}
          </Grid>
        </Grid>

        <Grid item style={{ padding: "12px" }} xs={12} sm={6} md={6} lg={4}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomInputlabelEmail id="tanOfVendorProfile" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px", paddingTop: "0px" }}>TAN Number</CustomInputlabelEmail>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomCSSOfTextField
              onFocus={() => {
                this.handleInputFocus("tanOfVendorProfile");
              }}
              onBlur={() => {
                this.handleInputBlur("tanOfVendorProfile");
              }}
              data-test-id="tanOfVPInput"
              value={this.state.vendorProfileData["account[tan_number]"]}
              type={'text'}
              className="email_input"
              id="outlined-basic"
              variant="outlined"
              onChange={(e: any) => this.handleChangeProfileDetails(e, "account[tan_number]")}
              placeholder="Enter your TAN number"
            />
            {this.vendorProfileRenderErrorMessage(this.state.vendorProfileDataError["account[tan_number]"].invalid, "TAN Number should be in the format PDES03028F")}
            {this.vendorProfileRenderErrorMessage(this.state.vendorProfileDataError["account[tan_number]"].require, "TAN number is required")}
          </Grid>
        </Grid>

        <Grid item style={{ padding: "12px" }} xs={12} sm={6} md={6} lg={4}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomInputlabelEmail id="aadharOfVendorProfile" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px", paddingTop: "0px" }}>Aadhaar Card Number</CustomInputlabelEmail>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomCSSOfTextField data-test-id="aadharOfVPInput" type={'text'} id="outlined-basic" variant="outlined" placeholder="Enter your aadhaar card number"
              value={this.state.vendorProfileData["account[aadhar_card_number]"]}
              onChange={(e: any) => this.handleChangeProfileDetails(e, "account[aadhar_card_number]")}
              className="email_input"
              onFocus={() => {
                this.handleInputFocus("aadharOfVendorProfile");
              }}
              onBlur={() => {
                this.handleInputBlur("aadharOfVendorProfile");
              }}
            />
            {this.vendorProfileRenderErrorMessage(this.state.vendorProfileDataError["account[aadhar_card_number]"].invalid === "numeric", "Only numeric values allowed")}
            {this.vendorProfileRenderErrorMessage(this.state.vendorProfileDataError["account[aadhar_card_number]"].invalid === "length", "Only 12 characters are allowed")}
            {this.vendorProfileRenderErrorMessage(this.state.vendorProfileDataError["account[aadhar_card_number]"].require, "Aadhaar card number is required")}
          </Grid>
        </Grid>

        <Grid item style={{ padding: "12px" }} xs={12} sm={6} md={6} lg={4}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomInputlabelEmail id="panOfVendorProfile" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px", paddingTop: "0px" }}>PAN Card Number</CustomInputlabelEmail>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomCSSOfTextField data-test-id="panOfVPInput"
              onFocus={() => {
                this.handleInputFocus("panOfVendorProfile");
              }}
              onBlur={() => {
                this.handleInputBlur("panOfVendorProfile");
              }}
              type={'text'} id="outlined-basic"
              value={this.state.vendorProfileData["account[pan_card_number]"]}
              onChange={(e: any) => this.handleChangeProfileDetails(e, "account[pan_card_number]")}
              className="email_input"
              variant="outlined" placeholder="Enter your PAN card number"
            />
            {this.vendorProfileRenderErrorMessage(this.state.vendorProfileDataError["account[pan_card_number]"].invalid, "PAN Number should be in the format ABCTY1234D")}
            {this.vendorProfileRenderErrorMessage(this.state.vendorProfileDataError["account[pan_card_number]"].require, "PAN card number is required")}
          </Grid>
        </Grid>

        <Grid item style={{ marginTop: "24px", paddingInline: "12px", display: "flex" }} xs={12} sm={12} md={12} lg={12}>
          <Grid container style={{ marginRight: "24px", width: "calc(100% - 156px)", height: "125px", borderRadius: "12px", backgroundColor: "#f8fafe", border: "1px solid #e3eaf4", boxSizing: "border-box", color: "#17181d", fontFamily: "Urbanist", fontSize: "12px", fontWeight: 500, lineHeight: "16px" }}>
            <Typography style={webStyle.vendorProfileAllReviewsTitle as React.CSSProperties}>Upload Certificates & Documents</Typography>
            <Grid key={this.state.vendorProfileData["profile[documents][]"].length} style={{ padding: "2px 7px 7px 7px", display: "flex", overflowX: "auto", width: "calc(100% - 23px)" }}>
              {this.state.vendorProfileData["profile[documents][]"].length ?
                this.state.vendorProfileData["profile[documents][]"].map((document: any, index: number) => {
                  return <Grid item key={`${index + document.id}_documentlist`} style={{ width: "112px" }}>
                    <Box style={{ background: "#ffffff", margin: "4%", padding: "4%" }}>
                      <Box style={{ width: "94px" }} className="vendor_profile_settings_cert_box">
                        <Box className="cert_inner_tool">
                          <Box style={{ backgroundColor: "rgba(245,196,44,0.09)", borderRadius: "8px", padding: "8.5px 11.6px" }}>
                            <img style={{ width: "14.61px", height: "21px" } as React.CSSProperties} src={images.certificateIcon} alt="" />
                          </Box>
                          <IconButton data-test-id={`pdf_download_${document.id}`} onClick={() => this.vendorProfileHandleCancelPDF(document)} className="vendor_profile_download_icon" size="medium">
                            <CancelIcon fontSize="small" htmlColor="#6d89af" style={{ width: "14px", height: "14px" }} />
                          </IconButton>
                        </Box>
                        <Box className="project_name">
                          <Typography noWrap className="file_name">{document.filename || document.name}</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                }) : null
              }
            </Grid>
          </Grid>
          <Grid container style={{ width: "132px", height: "125px", borderRadius: "12px", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "rgba(245,196,44,0.04)", border: "1px dashed #f5c42c", }}>
            <AddCircleIcon onClick={this.handleClickAddDocuments} htmlColor="#f5c42c" style={{ width: "20px", height: "20px", cursor: "pointer" }} />
            <input data-test-id="addDocumentsInput" type="file" ref={this.fileUpload} accept=".jpg, .jpeg, .png, .pdf" onChange={this.handleSelectDocuments} className="filetype" multiple style={{ display: "none" }} />
          </Grid>
        </Grid>
      </Grid>
    </>
  }

  renderVendorProfileStepThreeView() {
    return <>
      <Grid container style={{ paddingInline: "12px" }}>
        <Grid item style={{ padding: "12px" }} xs={12} sm={6} md={6} lg={4}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomInputlabelEmail id="bankListDropdown" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px" }}>Select Bank</CustomInputlabelEmail>
          </Grid>
          <Grid className="roleDropdown" item xs={12} sm={12} md={12} lg={12}>
            <Select
              data-test-id="bank-select-dropdown"
              id="demo-simple-select" displayEmpty
              value={this.state.vendorProfileData["account[bank_name_id]"]}
              label="Age"
              onChange={(e: any) => this.handleChangeProfileDetails(e, "account[bank_name_id]")}
              onFocus={() => {
                this.handleInputFocus("bankListDropdown");
              }}
              onBlur={() => {
                this.handleInputBlur("bankListDropdown");
              }}
            >
              <MenuItem value={""}>Select your bank</MenuItem>
              {this.state.bankList.map((bankDetail: any) => {
                return <MenuItem value={bankDetail.attributes.id}>{bankDetail.attributes.bank_name}</MenuItem>
              })}
            </Select>
            {this.vendorProfileRenderErrorMessage(this.state.vendorProfileDataError["account[bank_name_id]"].require, "Bank name is required")}
          </Grid>
        </Grid>

        <Grid item style={{ padding: "12px" }} xs={12} sm={6} md={6} lg={4}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomInputlabelEmail id="accnoOfVendorProfile" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px" }}>Account Number</CustomInputlabelEmail>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomCSSOfTextField
              onBlur={() => {
                this.handleInputBlur("accnoOfVendorProfile");
              }}
              data-test-id="accnoOfVendorProfileInput"
              type={'text'} id="outlined-basic" variant="outlined"
              placeholder="Enter your bank account number"
              onFocus={() => {
                this.handleInputFocus("accnoOfVendorProfile");
              }}
              className="email_input"
              value={this.state.vendorProfileData["account[account_number]"]}
              onChange={(e: any) => this.handleChangeProfileDetails(e, "account[account_number]")}
            />
            {this.vendorProfileRenderErrorMessage(this.state.vendorProfileDataError["account[account_number]"].require, "Account number is required")}
          </Grid>
        </Grid>

        <Grid item style={{ padding: "12px" }} xs={12} sm={6} md={6} lg={4}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomInputlabelEmail id="ifscOfVendorProfile" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px" }}>IFSC Code</CustomInputlabelEmail>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomCSSOfTextField data-test-id="ifscOfVendorProfileInput"
              onFocus={() => {
                this.handleInputFocus("ifscOfVendorProfile");
              }}
              type={'text'} id="outlined-basic" variant="outlined" placeholder="Enter your bank ifsc code"
              value={this.state.vendorProfileData["account[ifsc_code]"]}
              onBlur={() => {
                this.handleInputBlur("ifscOfVendorProfile");
              }}
              onChange={(e: any) => this.handleChangeProfileDetails(e, "account[ifsc_code]")}
              className="email_input"
            />
            {this.vendorProfileRenderErrorMessage(this.state.vendorProfileDataError["account[ifsc_code]"].require, "IFSC code is required")}
          </Grid>
        </Grid>
      </Grid>
    </>
  }

  renderVendorProfileResetPasswordTabView() {
    return <>
      <Grid container style={{ paddingInline: "12px" }}>
        <Grid item style={{ padding: "12px" }} xs={12} sm={6} md={6} lg={4}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomInputlabelEmail id="currentPasswordInput" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px" }}>Current Password</CustomInputlabelEmail>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomCSSOfTextField
              onBlur={() => {
                this.handleInputBlur("currentPasswordInput");
              }}
              data-test-id="currentPasswordInput"
              type={'text'} id="outlined-basic" variant="outlined"
              placeholder="Enter your current password"
              onFocus={() => {
                this.handleInputFocus("currentPasswordInput");
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" >
                    <img data-test-id="currentPasswordVisibility" onClick={() => this.handleVendorProfileVisibilityChange("currentPassword")} src={this.state.vendorProfileResetPasswordVisibility.currentPassword ? images.visibilityOffIcon : images.visibilityIcon} />
                  </InputAdornment>
                ),
              }}
              className="email_input"
              value={this.state.vendorProfileResetPasswordVisibility.currentPassword ? this.state.vendorProfileResetPasswordData.currentPassword : "*".repeat(this.state.vendorProfileResetPasswordData.currentPassword.length)}
              onChange={(e: any) => this.handleChangeResetPasswordDetails(e, "currentPassword")}
            />
            {this.state.vendorProfileResetPasswordData.currentPassword === "" && this.vendorProfileRenderErrorMessage(this.state.vendorProfileResetPasswordDataError.currentPassword.require, "Current password is required")}
            {this.state.vendorProfileResetPasswordData.currentPassword !== "" && this.vendorProfileRenderErrorMessage(this.state.vendorProfileResetPasswordDataError.currentPassword.invalid, "Current password is invalid")}
          </Grid>
        </Grid>

        <Grid item style={{ padding: "12px" }} xs={12} sm={6} md={6} lg={4}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomInputlabelEmail id="newPasswordInput" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px" }}>New Password</CustomInputlabelEmail>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomCSSOfTextField
              onBlur={() => {
                this.handleInputBlur("newPasswordInput");
              }}
              data-test-id="newPasswordInput"
              type={'text'} id="outlined-basic" variant="outlined"
              placeholder="Enter new password"
              onFocus={() => {
                this.handleInputFocus("newPasswordInput");
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" >
                    <img data-test-id="newPasswordVisibility" onClick={() => this.handleVendorProfileVisibilityChange("newPassword")} src={this.state.vendorProfileResetPasswordVisibility.newPassword ? images.visibilityOffIcon : images.visibilityIcon} />
                  </InputAdornment>
                ),
              }}
              className="email_input"
              value={this.state.vendorProfileResetPasswordVisibility.newPassword ? this.state.vendorProfileResetPasswordData.newPassword : "*".repeat(this.state.vendorProfileResetPasswordData.newPassword.length)}
              onChange={(e: any) => this.handleChangeResetPasswordDetails(e, "newPassword")}
            />
             {this.vendorProfileRenderErrorMessage(this.state.vendorProfileResetPasswordDataError.newPassword.require && this.state.vendorProfileResetPasswordData.newPassword === "", "New password is required")}
             {this.vendorProfileRenderErrorMessage(this.state.vendorProfileResetPasswordDataError.newPassword.invalid && this.state.vendorProfileResetPasswordData.newPassword !== "", "New password is invalid")}
          </Grid>
        </Grid>

        <Grid item style={{ padding: "12px" }} xs={12} sm={6} md={6} lg={4}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomInputlabelEmail id="confirmNewPasswordInput" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px" }}>Confirm New Password</CustomInputlabelEmail>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomCSSOfTextField data-test-id="confirmNewPasswordInput"
              onFocus={() => {
                this.handleInputFocus("confirmNewPasswordInput");
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" >
                    <img data-test-id="confirmPasswordVisibility" onClick={() => this.handleVendorProfileVisibilityChange("confirmNewPassword")} src={this.state.vendorProfileResetPasswordVisibility.confirmNewPassword ? images.visibilityOffIcon : images.visibilityIcon} />
                  </InputAdornment>
                ),
              }}
              type={'text'} id="outlined-basic" variant="outlined" placeholder="Enter your confirm password"
              value={this.state.vendorProfileResetPasswordVisibility.confirmNewPassword ? this.state.vendorProfileResetPasswordData.confirmNewPassword : "*".repeat(this.state.vendorProfileResetPasswordData.confirmNewPassword.length)}
              onBlur={() => {
                this.handleInputBlur("confirmNewPasswordInput");
              }}
              onChange={(e: any) => this.handleChangeResetPasswordDetails(e, "confirmNewPassword")}
              className="email_input"
            />
             {this.vendorProfileRenderErrorMessage(this.state.vendorProfileResetPasswordDataError.confirmNewPassword.require && this.state.vendorProfileResetPasswordData.confirmNewPassword === "", "Confirm new password is required")}
             {this.vendorProfileRenderErrorMessage(this.state.vendorProfileResetPasswordDataError.confirmNewPassword.match && this.state.vendorProfileResetPasswordData.confirmNewPassword !== "" && this.state.vendorProfileResetPasswordData.confirmNewPassword !== this.state.vendorProfileResetPasswordData.newPassword, "Confirm new password should be same as new password")}
          </Grid>
        </Grid>
      </Grid>
    </>
  }

  renderVendorProfileSettingsMainContent = (sideBarStatus: boolean = this.state.isVendorProfileSettingsSideBarOpen) => {
    return <>
      <Loader loading={this.state.isLoading}></Loader>
      <Grid style={webStyle.vendorProfileWholePageContentSetup} >
        <Header handleSideBar={this.handleVendorProfileSettingsSideBar}
          dropDownStatus={this.state.isVendorProfileSettingsDropdownOpen}
          navigate={this.props.navigation.navigate}
          sideBarStatus={sideBarStatus}
          handleDropdown={this.handleDropdown}
        />
        <Grid style={webStyle.vendorProfileDynamicMainContent as React.CSSProperties}>
          <Box sx={webStyle.vendorProfileBlockInfoTitle}>
            <Box>
              <Typography variant="h3" style={webStyle.vendorProfileActiveBlockMainTitle as TypographyProps}>Profile Settings</Typography>
              <Typography variant="h6" style={webStyle.vendorProfileBlockE2EPath as TypographyProps}>
                <span style={webStyle.vendorProfileHighlightedTitleext}>
                  <Link data-test-id="homeLink" color="inherit" onClick={() => this.handleNavigation("JobProfile")}>
                    Home
                  </Link>
                </span>
                <span style={{ color: "#F5C42C" }}>{" / "}</span>
                <span style={webStyle.vendorProfileHighlightedTitleext}>
                  <Link data-test-id="manageProfileLink" color="inherit" onClick={() => this.handleNavigation("VendorManageProfile")}>
                    Manage Profile
                  </Link>
                </span>
                {" "}/ Profile Settings
              </Typography>
            </Box>
          </Box>
          <Grid style={{ marginTop: "24px" }} item sm={12} xl={12} xs={12} md={12} lg={12}>
            <AppBar
              position="static"
              className="appbar app_bar"
              color="transparent"
            >
              <Tabs
                value={this.state.vendorProfileTabValue}
                data-test-id="tabChange"
                variant="scrollable"
                onChange={this.handleVendorProfileAppBarValue}
                TabIndicatorProps={{ style: { height: '2px', background: "orange" } as React.CSSProperties }}
                scrollButtons="on"
                className="tabs_main"
                aria-label="scrollable prevent tabs example"
              >
                <Tab className={this.state.vendorProfileTabValue == 0 ? "active_btn" : "non_active_btn"} label="Edit & Update Profile" />
                <Tab className={this.state.vendorProfileTabValue == 1 ? "active_btn" : "non_active_btn"} label="Reset Password" />
              </Tabs>
            </AppBar>
          </Grid>
          <Grid style={{ marginTop: "24px", width: "100%", minHeight: "607px", borderRadius: "12px", border: "1px solid #e3eaf4", background: "#ffffff" }} item sm={12} xl={12} xs={12} md={12} lg={12}>
            {this.state.vendorProfileTabValue == 0 ?
              <Grid container style={{ display: "flex", flexWrap: "nowrap", flexDirection: "column", minHeight: "607px" }}>
                <Box style={{ width: "100%" }}>
                  <Box style={{ width: '100%', display: "flex", justifyContent: "center" }}>
                    <Stepper style={{ width: '496.5px', paddingTop: "24px", marginLeft: '-9%', marginRight: '-12%' }} alternativeLabel activeStep={this.state.vendorProfileActiveStep} connector={<ColorlibConnector />}>
                      {
                        ["Basic Details", "Official Details", "Account Details"].map(
                          (label, index) => (<Step key={label}>
                            <StepLabel
                              className="stepLabelClass"
                              data-test-id={`stepLabelId_${index}`}
                              StepIconComponent={this.VendorProfileColorlibStepIcon}>{label}
                            </StepLabel>
                          </Step>))
                      }
                    </Stepper>
                  </Box>
                  {!this.state.vendorProfileActiveStep && this.renderVendorProfileStepOneView()}
                  {this.state.vendorProfileActiveStep === 1 && this.renderVendorProfileStepTwoView()}
                  {this.state.vendorProfileActiveStep === 2 && this.renderVendorProfileStepThreeView()}
                </Box>
                <Grid item xs={12} sm={12} md={12} lg={12} style={{ justifyContent: "space-between", display: "flex", flex: "inherit", paddingBottom: "24px", marginTop: 'auto', marginInline: "24px" }}>
                  <Grid item style={{ width: 163, visibility: this.state.vendorProfileActiveStep ? "visible" : "hidden", height: 48, backgroundColor: "#FFFFFF" } as React.CSSProperties} className="button_box">
                    <CustomPHBackButtonBox data-test-id="sign_up_button" style={{ height: 48 } as React.CSSProperties} onClick={this.handleVendorProfileStepBack}>
                      Back
                    </CustomPHBackButtonBox>
                  </Grid>
                  {this.state.vendorProfileActiveStep === 2 ?
                    <Grid item style={{ width: 202, height: 48 } as React.CSSProperties} className="button_box">
                      <CustomPHSignUPButtonBox data-test-id="sign_up_button" style={{ height: 48, backgroundColor: "#17181d" } as React.CSSProperties} onClick={this.handleSaveDetails}>
                        Save Details
                      </CustomPHSignUPButtonBox>
                    </Grid>
                    :
                    <Grid item style={{ width: 163, height: 48 } as React.CSSProperties} className="button_box">
                      <CustomPHSignUPButtonBox data-test-id="next_button" style={{ height: 48, backgroundColor: "#17181d" } as React.CSSProperties} onClick={this.handleVendorProfileMoveOnNextPage}>
                        Next
                      </CustomPHSignUPButtonBox>
                    </Grid>}
                </Grid>
              </Grid> : null}
            {this.state.vendorProfileTabValue == 1 ?
              <>
                <Grid style={{ height: "602px", display: "flex", flexDirection: "column" }}>
                  {this.renderVendorProfileResetPasswordTabView()}
                  <Grid style={{ marginTop: "auto", width: "100%", alignItems: "end", display: "flex", justifyContent: "end", paddingBottom: "24px", boxSizing: "border-box" }}>
                    <Grid item style={{ width: 180, height: 48, paddingInline: "24px" } as React.CSSProperties} className="button_box">
                      <CustomPHSignUPButtonBox data-test-id="next_button" style={{ height: 48 } as React.CSSProperties} className="button" onClick={this.handleVendorProfileResetPassword}>
                        Reset Now
                      </CustomPHSignUPButtonBox>
                    </Grid>
                  </Grid>
                </Grid>
              </> : null}
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </>
  }
  // Customizable Area End

  render() {
    return (
      <ThemeProvider theme={themeStyle}>
        <Container style={webStyle.vendorProfileParentContainer}>
          {
            this.state.isVendorProfileSettingsSideBarOpen ? (
              <Grid spacing={0} container>
                <Grid xs={3} item style={webStyle.vendorProfileSidebarParentGrid} >
                  <SideBar
                    handleSideBar={this.handleVendorProfileSettingsSideBar}
                    userData={{ name: "Manage Profile" }}
                    activeMenuItem={"Manage Profile"}
                    navigate={this.props.navigation.navigate}
                  />
                </Grid>
                <Grid style={{ display: "flex", flexDirection: "column", flexBasis: "77.75%", maxWidth: "77.75%" }} item xs={9} >
                  {this.renderVendorProfileSettingsMainContent()}
                </Grid>
              </Grid>
            ) : (
              <Grid style={{ display: "flex", flexDirection: "column" }} item xs={12}>
                {this.renderVendorProfileSettingsMainContent()}
              </Grid>
            )
          }
        </Container>
        <CustomNotification navigation={(route: string) => this.props.navigation.navigate(route)} notification={this.state.vendorProfileNotification} handleCloseNotification={this.handleVendorProfileCloseNotification} />
      </ThemeProvider>
    );
  }
}

const webStyle = {
  vendorProfileAllReviewsTitle: {
    color: "#17181d",
    fontFamily: "Urbanist",
    fontSize: "12px",
    fontWeight: 600,
    padding: "12px 12px 0px 12px",
    width: "100%",
    lineHeight: "15px",
  },
  vendorProfileParentContainer: {
    padding: "0px", maxWidth: "none",
  },
  vendorProfileSidebarParentGrid: {
    minHeight: "100vh", maxWidth: "22.25%",
  },
  vendorProfileWholePageContentSetup: {
    borderLeft: "1px solid #E3EAF4",
    // minHeight: "90vh",
    flex: 1,
  },
  vendorProfileDynamicMainContent: {
    padding: "24px",
    boxSizing: "border-box",
    minHeight: "100vh",
    backgroundColor: "#F8FAFE",
    width: "100%",
  },
  vendorProfileBlockInfoTitle: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
  },
  vendorProfileHighlightedTitleext: {
    textDecoration: "underline",
    fontFamily: "Urbanist",
    cursor: "pointer",
    color: "#F5C42C",
    fontWeight: 600,
    fontSize: "14px",
  },
  vendorProfileActiveBlockMainTitle: {
    fontWeight: "700",
    lineHeight: "34px",
    fontSize: "28px",
    letterSpacing: "1px",
    color: "#17181D",
  },
  vendorProfileBlockE2EPath: {
    lineHeight: "17px",
    paddingTop: "8px",
    color: "#6D89AF",
    fontWeight: 500,
    fontSize: "14px",
    letterSpacing: "1px",
  }
};
