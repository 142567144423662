import React from "react";
import { Card, Box, CardMedia, CardContent, Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { JobCardImage, LocationIconjob, rupeeiconjob, dotsIcon, headingicon, Bg_Copy, leaveproject, editstudio, BookingOpen, BookingClose, studiodelete, CancelStudio } from "./assets";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreHoriz";
import Menu from "@material-ui/core/Menu";
interface MyProps {
    data: any;
    cancelbooking:any
    

}



const useStyles = makeStyles({
    cardDivWithOpenSideBar: () => ({
        width: 300,
        //height: 240,
        borderRadius: 14,
        padding: 8,
        border: "1px solid #E1E6EC",
        position: 'relative',
        '& .MuiCardContent-root': {
            padding: 0
        },
        "@media(max-width: 500px)": {

        },
        "@media(min-width: 1440px)": {
            width: '312px',
        },
        backgroundImage: `url(${Bg_Copy})`, // Assuming JobCardImage is the path to your image
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: 200,
    }),
    cardDivWithClosedSideBAr: () => ({
        width: 346,
        borderRadius: 14,
        padding: 8,
        border: "1px solid #E1E6EC",
        position: 'relative',
        '& .MuiCardContent-root': {
            padding: 0
        },
        "@media(max-width: 500px)": {

        },
    }),
    parentBoxValue: () => ({
        display: "flex",
        justifyContent: 'space-between',



    }),
    parentBox: () => ({
        height: "177px",
        borderRadius: "12px",
        boxShadow: "0px 7px 18px rgba(24,39,42,0.07)",
        cursor: "pointer",
    }),
    imageContainer: () => ({
        height: "100%",
        width: "100%",
        position: "relative",
    }),
    studioImage: () => ({
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        objectFit: "cover",
        borderRadius: "12px"
    }),
    studioStatus: () => ({
        position: "absolute",
        top: "10px",
        right: "10px",
        textAlign: "end",
        height: "23px",
    }),
    studioDetailsMainBox: () => ({
        position: "absolute",
        bottom: "5px",
        right: "5px",
        left: "5px",
        background: "#fff",
        height: 95,
        borderRadius: "10px",
        opacity: 0.8290272,
        padding: 12,
        boxSizing: "border-box",
    }),
    studioDetails: () => ({
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    }),
    ParentSubheading1: () => ({
        display: "flex",
        gap: "5px",

    })
    ,
    backgroundImage: {
        // Adjust the height according to your requirement
    },
    humbergerparent:{
        display:'flex',
        paddingLeft:'23px',
        alignItems:'center',
        gap:'10px',
        paddingBottom:'16px',
      },
      humbergerParentIcon:{
       
      },  humbergericon:{
        height:'16px',
        width:'16px',
        cursor:'pointer'
      },
      humbergerLabel:{
        color: '#17181d',
  fontFamily: 'Urbanist',
  fontSize: '14px',
  fontWeight: 600,
  cursor:'pointer'

      }

});

export default function StudioBookingListPH(props: MyProps) {
    const {data,cancelbooking}=props

    const classes = useStyles();
    const ITEM_HEIGHT = 48;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openhumberger = Boolean(anchorEl);
    const handleClick = (event:any) => {
        event.stopPropagation();
      setAnchorEl(event.currentTarget);
    };
    const handleClosehumberger = () => {
        
        setAnchorEl(null);
      };
      const options = [
        
        { label: "Cancel Booking", icon: CancelStudio },
      ];
      const handleOptionClick = (event:any, label:string) => {
        event.stopPropagation();
        handleClosehumberger(); // Close the dropdown
      
        // Perform different actions based on the clicked label
        if(label === 'Edit Studio')
        {

        }
        else if (label === 'Cancel Booking') {
            cancelbooking(data?.id)
            
        } 
        
      };
      
    return (
       
     
     <Box >                         
        <div >
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={openhumberger ? "long-menu" : undefined}
        aria-expanded={openhumberger ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        style={{color:'gray',fontSize:'30px'}}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={openhumberger}
        onClose={handleClosehumberger}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
            left:'489px'
          },
        }}
      >
        {options.map((option, index) => (
        <div key={index} onClick={(e) => handleOptionClick(e, option.label)} >
           <Box className={classes.humbergerparent}>
            <Box className={classes.humbergerParentIcon}>
          <img src={option.icon} alt="" className={classes.humbergericon} />
          </Box>
          <Box className={classes.humbergerLabel}>
          {option.label}
          </Box>
          </Box> 
        </div>
      ))}
      </Menu>
    </div>
    </Box>
               
    );
}
