//Customizable Area Start
import React from 'react';
type otpProps = {
  id: string;
  onKeyUp: any;
  className?: any;
  otpInputValue: string;
  updateOtpInoutValue: any;
}
class VerifyOtpInputBox extends React.Component<otpProps>{
  validateOtpInput = (e: any) => {
    const regexStr = /\b[0-9]\b/;
    if (regexStr.test(e.target.value) || e.target.value == "") {
      this.props.updateOtpInoutValue(e.target.value);
    }
  }

  render(): React.ReactNode {
    const { id, onKeyUp, otpInputValue } = this.props;
    return (
      <div style={webStyle.root as React.CSSProperties}>
        <input className="verify_otp_input_common"
          onKeyUp={onKeyUp}
          type="text"
          value={otpInputValue}
          id={id}
          onChange={this.validateOtpInput}
          maxLength={1}
        />
      </div>
    );
  }
}
export default VerifyOtpInputBox;

const webStyle = {
  root: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  }
};
//Customizable Area End