import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start


// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isSideBarOpen: boolean;
    isLoading: boolean;
    isDropdownOpen: boolean;
    tabValue: number;
    open2:boolean;
    notification: { type: string, open: boolean, message: string, route?: string };
    requestProjectData:any[];
    projectJobRoleRequest:any[]
    alljobrolelist:any[];
    open:boolean
    projectName:any[];
    projectDepartment:any[];
    jobRoleInputs:any;
    projectRole: [],
    isProjectSelected:boolean
    isDepartmentSelected:boolean
    isRoleTypeSeleted:boolean
    validationStatusJobRole:any;
    roleReqError:any
    expError:any
    budgetError:any
    noOfPosError:any
    roleDesError:any
    setEditJobRole:boolean

    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class ProductionHouseDashboardController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
  
    getallProjectcallid: string = "";
    getongoingProjectcallid: string = "";
    getUpcomingProjectcallid: string = ""
    getAllProjectJobRoleRequestCallID:string=""
    getAlJobRoleCallID:string=""
    deleteJobRoleApiCallId:string ="";
    removeFromJobApiCallId:string=""
    getProjectNameApiCallId:string=""
    getProjectDepartmentCallId:string=""
    getProjectDeptRoleApiCallId:string=""
    addJobRoleApiCallId:string ="";
    editJobRoleApiCallId:string ="";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceDataMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.RestAPIResponceErrorMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            isSideBarOpen: true,
            isLoading: false,
            isDropdownOpen: false,
            tabValue: 0,
            notification: { type: "", open: false, message: "" },
            requestProjectData:[],
            open2:false,
            open:false,
            projectJobRoleRequest:[],
            alljobrolelist:[],
            projectName:[],
            projectDepartment:[],
            projectRole:[],
            isProjectSelected:true,
            isDepartmentSelected:true,
            isRoleTypeSeleted:true,
            setEditJobRole:false,
            validationStatusJobRole:{
                roleRequirements:{label:"Role Requirements" , valid:false , message :""},
                expRequired:{label:"Exp Required" , valid:false , message :""},
                roleBudget:{label:"Role Budget" , valid:false , message :""},
                noOfPos:{label:"No of Pos" , valid:false , message :""},
                roleDescription:{label:"Role Description" , valid:false , message :""}
            },
            jobRoleInputs:{
                selectedProject:null,
                selectedProjectDept:null,
                selectedProjectRole:null,
                roleRequirements:[],
                expRequired:"",
                roleBudget:"",
                noOfPos:"",
                roleDescription:"",
                projectId:null,
                projectDeptId:null,
                roleID:null,
            },
            roleReqError:{
                require:false
            },
            expError:{
                require:false
            },
            budgetError:{
                require:false
            },
            noOfPosError:{
                require:false
            },
            roleDesError:{
                require:false
            },
   

            // Customizable Area End
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (this.getAllProjectJobRoleRequestCallID === apiRequestCallId) {
                this.setState({
                    projectJobRoleRequest: responseJson.data,
                    isLoading: false,
                })
            }
            if(this.deleteJobRoleApiCallId===apiRequestCallId ){
                this.setState({isLoading:false})
                this.getMyJobRoleAndRequest()
            }
           if(this.getAlJobRoleCallID===apiRequestCallId){
            this.setState({alljobrolelist:responseJson.data})
           }
           if(this.removeFromJobApiCallId===apiRequestCallId) {
             this.handleRemoveFromJobResponse(responseJson)
           }
           if(this.getProjectNameApiCallId===apiRequestCallId) {
            this.getProjectNameDataAllJobRole(responseJson);
           }
           if(this.getProjectDepartmentCallId===apiRequestCallId) {
            this.getProjectDepartmentAllJobRole(responseJson);
           }
           if(this.getProjectDeptRoleApiCallId===apiRequestCallId) {
            this.getProjectDepartmentRoleProjectCreation(responseJson)
           }
           if(this.addJobRoleApiCallId===apiRequestCallId) {
            this.handleAddJobRoleResponse(responseJson);
           }
           if(this.editJobRoleApiCallId===apiRequestCallId) {
            this.handleEditJobRoleResponse(responseJson);
           }
        }
        // Customizable Area End
    }
 
    // Customizable Area Start
 
    handleRemoveFromJobResponse(responseJson:any){
        if(responseJson){
          this.getalljobrole()
        
      }
    }

    handleCloseJobRoleModel = () => {
        this.setState({
            open: false,
            projectDepartment: [],
            projectRole:[],
            jobRoleInputs:{
                selectedProject:null,
                selectedProjectDept:null,
                selectedProjectRole:null,
                roleRequirements:[],
                expRequired:"",
                roleBudget:"",
                noOfPos:"",
                roleDescription:"",
                projectId:null,
                projectDeptId:null,
                roleID:null
            },
            isProjectSelected: true,
            isDepartmentSelected: true,
            isRoleTypeSeleted: true ,
            roleReqError:{require:false},
            expError:{require:false},
            budgetError:{require:false},
            noOfPosError:{require:false},
            roleDesError:{require:false},
        });
        
    }

    getProjectNameDataAllJobRole = async (responseJson: any) => {
        const projectJobId = await getStorageData("projectidPH");
        const fetchedProject = responseJson?.data
            .filter((item: { id: number }) => item.id === projectJobId)
            .map((item: { id: number, attributes: { project_name: string } }) => ({
                id: item.id,
                name: item.attributes.project_name,
            }));
        this.setState({
            projectName: fetchedProject,
            isLoading: false
        });
    }
    

    getProjectDepartmentAllJobRole=(responseJson:any)=>{
        const projectDepartmentResponse = responseJson?.data?.map((item :{ id: number, attributes: { name: string } })=>({
            department:item.attributes.name,
            dept_id:item.id,
        }));
        this.setState({
            projectDepartment:projectDepartmentResponse,
            isLoading:false
        }) 
    }

    getProjectDepartmentRoleProjectCreation=(responseJson:any)=>{
        const projectDeptRoleRes = responseJson?.data?.map((item : {id:number , name:string})=>({
            deptRole:item.name,
            role_id:item.id
        }));
        this.setState({
            projectRole:projectDeptRoleRes,
            isLoading:false
        })
    }

    handleCloseJobRoleModel1 =()=>{
        this.setState({
            setEditJobRole:false
        })
    }

    handleAddJobRoleResponse(responseJson:any) {
        if (responseJson) {
            this.handleCloseJobRoleModel();
            this.getMyJobRoleAndRequest()
            this.setState({
                isLoading: false,
            });
        }
    }


    handleEditJobRoleResponse(responseJson:any) {
        if (responseJson) {
            this.handleCloseJobRoleModel1();
            this.handleCloseJobRoleModel();
            this.getMyJobRoleAndRequest();
            this.setState({
                isLoading: false,
            });
        }
    }

    handleProjectDeptRole =(selectedOption:any) =>{
        this.setState((prevState)=>({
            jobRoleInputs:{
            ...prevState.jobRoleInputs,    
            selectedProjectRole : selectedOption,
            roleID : selectedOption.value
            },
            isRoleTypeSeleted:true
        }),()=>{
            this.getMyAllprojectDeptRole()
        })
    }

    handleChangeRoleRequirements = (items:any) => {
        const eachvalue=items
        if (!eachvalue) {
            this.setState({
                roleReqError:{
                    require:true
                }
            })
        }
   
        else {
            this.setState((prevState)=>({
                jobRoleInputs:{
                ...prevState.jobRoleInputs,    
                roleRequirements : eachvalue,
                },
                roleReqError:{
                    require:false
        }
            }))
        }
    }

    handleChangeExp=(event:any) =>{
        const expReqData = event?.target.value;
        const regex= /^[0-9\b]+$/
        if(expReqData === '' || regex.test(expReqData)){
            this.setState((prevState)=>({
                jobRoleInputs:{
                ...prevState.jobRoleInputs,    
                expRequired : expReqData
                },
            }))
        }
        if (!expReqData) {
            this.setState({
                expError:{
                    require:true
                }
            })
            return false;
        } else {
            this.setState({
                expError:{
                    require:false
                }
            })
        }
    }

    handleChangeBudget=(event:any)=>{
        const budgetData = event?.target.value;
        const regexBudget= /^[0-9\b]+$/
        if(budgetData === '' || regexBudget.test(budgetData)){
        this.setState((prevState)=>({
            jobRoleInputs:{
            ...prevState.jobRoleInputs,    
            roleBudget : budgetData
            },
        }))
    }
        if (!budgetData) {
            this.setState({
                budgetError:{
                    require:true
                }
            })
            return false;
        } else {
            this.setState({
                budgetError:{
                    require:false
                }
            })
        }
    }

    handleChangePos=(event:any)=>{
        const noOfPosData= event.target.value
        const regexPos= /^[0-9\b]+$/
        if(noOfPosData === '' || regexPos.test(noOfPosData)){
        this.setState((prevState)=>({
            jobRoleInputs:{
            ...prevState.jobRoleInputs,    
            noOfPos : noOfPosData
            },
        }))
    }
        if (!noOfPosData) {
            this.setState({
                noOfPosError:{
                    require:true
                }
            })
            return false;
        } else {
            this.setState({
                noOfPosError:{
                    require:false
                }
            })
        }
    }

    handleChangeRoleDes=(event:any)=>{
        const roleDesData= event?.target.value
        this.setState((prevState)=>({
            jobRoleInputs:{
            ...prevState.jobRoleInputs,    
            roleDescription : roleDesData
            },
        }))
        if (!roleDesData) {
            this.setState({
                roleDesError:{
                    require:true
                }
            })
            return false;
        } else {
            this.setState({
                roleDesError:{
                    require:false
                }
            })
        }
    }

    editJobRoleRequest = async(id:any) =>{       
        setStorageData("editJobRoleId", id);  
        this.setState({ setEditJobRole: true });
        this.setState({
            open:true
        })
        this.setState({open2:false})
        for(let i=0;i<=this.state.projectJobRoleRequest.length;i++){
             if(this.state.projectJobRoleRequest[i]?.id === id){
                this.getMyAllProjectDepartmentEdit(this.state.projectJobRoleRequest[i].attributes.project_id);
                this.getMyAllprojectDeptRole();
                const project= {

                    label: this.state.projectJobRoleRequest[i].attributes.project_name,
                     value: this.state.projectJobRoleRequest[i].attributes.project_id
                }
                const projectDeptSelection= {

                    label: this.state.projectJobRoleRequest[i].attributes.project_department,
                     value: this.state.projectJobRoleRequest[i].attributes.project_department_id
                }
                const projectRoleSelection= {

                    label: this.state.projectJobRoleRequest[i].attributes.role,
                     value: this.state.projectJobRoleRequest[i].attributes.role_id
                }
                this.setState({jobRoleInputs:{
                selectedProject:project,
                selectedProjectDept:projectDeptSelection,
                selectedProjectRole:projectRoleSelection,
                roleRequirements:this.state.projectJobRoleRequest[i].attributes.job_role_requirment.map((item:any)=>item),
                expRequired:this.state.projectJobRoleRequest[i].attributes.exp_required,
                roleBudget:this.state.projectJobRoleRequest[i].attributes.budget_of_role,
                noOfPos:this.state.projectJobRoleRequest[i].attributes.number_of_positions,
                roleDescription:this.state.projectJobRoleRequest[i].attributes.role_description
    }})
}}}

    handleSubmitFormAddJobRole=()=>{
        if(!this.state.jobRoleInputs.selectedProject || !this.state.jobRoleInputs.selectedProjectDept ||!this.state.jobRoleInputs.selectedProjectRole || this.state.jobRoleInputs.roleRequirements.length<=0 || !this.state.jobRoleInputs.expRequired || !this.state.jobRoleInputs.roleBudget || !this.state.jobRoleInputs.noOfPos || !this.state.jobRoleInputs.roleDescription){
        this.validateJobRoleRequest();
        return false
    }    
    !this.state.setEditJobRole  ? this.postMyAddJobRoleFormData() : this.editMyJobRoleFormData()
    }

    handleOpenAddJobRole=async()=>{
        this.setState({
            open:true
        })
    }

    validateJobRoleRequest=()=>{
        if (!this.state.jobRoleInputs.selectedProject) {
            this.setState({ isProjectSelected: false });
        }
        
        if (!this.state.jobRoleInputs.selectedProjectDept) {
            this.setState({ isDepartmentSelected: false });
        }
        
        if (!this.state.jobRoleInputs.selectedProjectRole) {
            this.setState({ isRoleTypeSeleted: false });
        }
        
        if (this.state.jobRoleInputs.roleRequirements.length===0) {
            this.setState({ roleReqError: { require: true } });
        }
        
        if (!this.state.jobRoleInputs.expRequired) {
            this.setState({ expError: { require: true } });
        }
        
        if (!this.state.jobRoleInputs.roleBudget) {
            this.setState({ budgetError: { require: true } });
        }
        
        if (!this.state.jobRoleInputs.noOfPos) {
            this.setState({ noOfPosError: { require: true } });
        }
        
        if (!this.state.jobRoleInputs.roleDescription) {
            this.setState({ roleDesError: { require: true } });
        }
    }
   
    navigateToRequestPage=(job_role_id:number)=>{
        setStorageData("jobRoleId", job_role_id);  
        this.props.navigation.navigate("ProjectDetailRequest",)
    }

    handleSideBar = () => {
        this.setState({
          isSideBarOpen: !this.state.isSideBarOpen
        },
        async() => {
          localStorage.setItem("sidebarvalue",this.state.isSideBarOpen.toString())
    
        }
    
    
        );
      };
      
    handleNavigationRequestProfile= async (id:any,studioid:any,profileid:any)=>{
        setStorageData("dataid", id)
       await setStorageData("studioid",studioid)
       setStorageData("profileid",profileid)
          this.props.navigation.navigate("RequestProfileJobRoleProjectPH");  
      }
    
    handleDropdown = () => {
        this.setState({
            isDropdownOpen: !this.state.isDropdownOpen
        });
    };

    handleNavigation = (page: string) => {
        this.props.navigation.navigate(page)
    };

    handleAppBarValue = (event: React.ChangeEvent<{}>, value: number) => {
        this.setState({
            tabValue: value,
        });
    }


    async componentDidMount() {
     this.getMyJobRoleAndRequest()
     this.getalljobrole()
     await this.getMyAllProjectName();   
    }
    InviteVendorListNavigation=(Id:any)=>{
         setStorageData("jobroleidvendor",Id)
        this.props.navigation.navigate("InviteVendorListPD")
    }

    handleCloseNotification = () => {
        this.setState({ notification: { type: "", open: false, message: "" } });
    }

    navigateToDetailPage = (studioId: string) => {
        setStorageData("studioId", studioId);
        this.props.navigation.navigate("StudiosBookingDetail");
    }

    async componentWillUnmount() {
        setStorageData("studioBookingViewStatus", "false");
    }
 
    getMyJobRoleAndRequest = async () => {
            const projectJobId=  await getStorageData("projectidPH");
            this.getAllProjectJobRoleRequestCallID = await this.allProjectJobRoleRequest(configJSON.GET_Method , configJSON.getAllProjectJobRoleRequestEndPoint + `?project_id=${projectJobId}`);
    }

    getalljobrole = async () => {
            const projectJobId=  await getStorageData("projectidPH");
            this.getAlJobRoleCallID = await this.allProjectJobRoleRequest(configJSON.GET_Method , configJSON.getAllJobRoleRequestEndPoint + `?project_id=${projectJobId}`);
        }
    
    deleteJobRoleRequest =async (id:any) =>{
        this.deleteJobRoleApiCallId = await this.jobRoleRequestDelete(configJSON.DELETE_Method,configJSON.deleteJobRoleRequest + `/${id}`)
    }

    deletefromJobInviteVendor = async(id:any)=>{
        this.removeFromJobApiCallId = await this.removeFromJobInviteVendor(configJSON.DELETE_Method,configJSON.removeforjobEndpointdelete + `/${id}`)
    }

    getMyAllProjectName = async () => {
        this.getProjectNameApiCallId = await this.allProjectNameProjectCreation(configJSON.GET_Method, configJSON.getAllProjectNameEndPoint);
    };

    getMyAllProjectDepartment =async () =>{
        this.getProjectDepartmentCallId =await this.allProjectDepartment(configJSON.GET_Method, configJSON.getAllProjectDepartmentEndPoint + `?project_id=${this.state.jobRoleInputs.projectId}`)
    }
    getMyAllprojectDeptRole =async () =>{
        this.getProjectDeptRoleApiCallId = await this.allProjectRoleType(configJSON.GET_Method, configJSON.getProjectDeptRoleEndPoint)
    }
    postMyAddJobRoleFormData =async () =>{
        this.addJobRoleApiCallId = await this.addJobRoleApi(configJSON.httpPostMethod,configJSON.postAddJobRoleEndPoint)
    }

    getMyAllProjectDepartmentEdit =async (projectId:any) =>{
        this.getProjectDepartmentCallId =await this.allProjectDepartment(configJSON.GET_Method, configJSON.getAllProjectDepartmentEndPoint + `?project_id=${projectId}`)
    }

    editMyJobRoleFormData = async () =>{
        let editJobId = await getStorageData("editJobRoleId");
        this.editJobRoleApiCallId = await this.jobRoleRequestEdit("PUT","bx_block_roles_permissions/job_roles/" + `${editJobId}`)
    }

    handleProjectName = (selectedOption:any) => {
        this.setState((prevState) => ({
            jobRoleInputs: {
                ...prevState.jobRoleInputs,
                selectedProject: selectedOption,
                selectedProjectDept: "",
                projectId: selectedOption.value,
                selectedProjectRole: ""
            },
            isProjectSelected:true
        }), () => {
            this.getMyAllProjectDepartment();
        });
    };

    handleProjectdepartment = (selectedOption:any) => {
        this.setState((prevState) => ({
            jobRoleInputs: {
                ...prevState.jobRoleInputs,
                selectedProjectDept: selectedOption,
                projectDeptId: selectedOption.value
            },
            isDepartmentSelected:true
        }), () => {
            this.getMyAllprojectDeptRole();
        });
    };
    

    handleNavigateToAddNewProject=()=>{
        localStorage.removeItem('projectData')
        this.props.navigation.navigate("AddNewProject");
        }

    handleJobRoleEditAndDelete = (id: any) => {
        this.setState(prevState => ({
            open2: prevState.open2 === id ? null : id
        }));
    }

    

    allProjectJobRoleRequest = async (method: string, endPoint: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        const requestMessageJobRoleRequestProject = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessageJobRoleRequestProject.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        requestMessageJobRoleRequestProject.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageJobRoleRequestProject.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(requestMessageJobRoleRequestProject.id, requestMessageJobRoleRequestProject);
        return requestMessageJobRoleRequestProject.messageId;
    };
                     
    jobRoleRequestDelete = async (methoddelete: string, endPointdelete: string) => {
        const deletejobroletoken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": deletejobroletoken
        };
        this.setState({ isLoading: true })
        const requestMessageJobRoleRequestRole = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageJobRoleRequestRole.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPointdelete
        );

        requestMessageJobRoleRequestRole.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageJobRoleRequestRole.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            methoddelete
        );
        runEngine.sendMessage(requestMessageJobRoleRequestRole.id, requestMessageJobRoleRequestRole);
        return requestMessageJobRoleRequestRole.messageId;
    };
    removeFromJobInviteVendor = async (method: string, endPoint: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
    
        const requestMessageManageProfileremoveFromJob = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageManageProfileremoveFromJob.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        requestMessageManageProfileremoveFromJob.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageManageProfileremoveFromJob.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(requestMessageManageProfileremoveFromJob.id, requestMessageManageProfileremoveFromJob);
        return requestMessageManageProfileremoveFromJob.messageId;
    };

    allProjectNameProjectCreation = async (method: string, endPoint: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageJobRoleRequestProjectName = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageJobRoleRequestProjectName.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        requestMessageJobRoleRequestProjectName.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageJobRoleRequestProjectName.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(requestMessageJobRoleRequestProjectName.id, requestMessageJobRoleRequestProjectName);
        return requestMessageJobRoleRequestProjectName.messageId;
    };

    allProjectDepartment = async (method: string, endPoint: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageJobRoleRequestProjectDept = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageJobRoleRequestProjectDept.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        requestMessageJobRoleRequestProjectDept.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageJobRoleRequestProjectDept.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(requestMessageJobRoleRequestProjectDept.id, requestMessageJobRoleRequestProjectDept);
        return requestMessageJobRoleRequestProjectDept.messageId;
    };

    allProjectRoleType = async (method: string, endPoint: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageJobRoleRequestRoleType = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageJobRoleRequestRoleType.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        requestMessageJobRoleRequestRoleType.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageJobRoleRequestRoleType.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(requestMessageJobRoleRequestRoleType.id, requestMessageJobRoleRequestRoleType);
        return requestMessageJobRoleRequestRoleType.messageId;
    };

    addJobRoleApi = async (method: string, endPoint: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageJobRoleRequestProject = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageJobRoleRequestProject.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        const job_roleData = {
            job_role:{
            role_description: this.state.jobRoleInputs.roleDescription,
            exp_required: this.state.jobRoleInputs.expRequired,
            budget_of_role: this.state.jobRoleInputs.roleBudget,
            number_of_positions:this.state.jobRoleInputs.noOfPos,
            project_id:this.state.jobRoleInputs.projectId,
            project_department_id :this.state.jobRoleInputs.projectDeptId,
            role_id:this.state.jobRoleInputs.roleID,
            job_role_requirment: this.state.jobRoleInputs.roleRequirements
            }
            }

        requestMessageJobRoleRequestProject.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(job_roleData)
        );
        requestMessageJobRoleRequestProject.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            header
        );
        requestMessageJobRoleRequestProject.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(requestMessageJobRoleRequestProject.id, requestMessageJobRoleRequestProject);
        return requestMessageJobRoleRequestProject.messageId;
    };

    jobRoleRequestEdit = async (method: string, endPoint: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageJobRoleRequestRole = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageJobRoleRequestRole.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        const job_roleData_edit = {
            job_role:{
            role_description: this.state.jobRoleInputs.roleDescription,
            exp_required: this.state.jobRoleInputs.expRequired,
            budget_of_role: this.state.jobRoleInputs.roleBudget,
            number_of_positions:this.state.jobRoleInputs.noOfPos,
            project_id:this.state.jobRoleInputs.projectId,
            project_department_id :this.state.jobRoleInputs.projectDeptId,
            role_id:this.state.jobRoleInputs.roleID,
            job_role_requirment: this.state.jobRoleInputs.roleRequirements
            }
            }

            requestMessageJobRoleRequestRole.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(job_roleData_edit)
        );

        

        requestMessageJobRoleRequestRole.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageJobRoleRequestRole.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(requestMessageJobRoleRequestRole.id, requestMessageJobRoleRequestRole);
        return requestMessageJobRoleRequestRole.messageId;
    };


    // Customizable Area End
}

