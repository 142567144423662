import React from 'react';
import {
    // Customizable Area Start
    Box,
    Typography,
    styled,
    Grid    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import Modal from '@material-ui/core/Modal';

import { CustomButton } from '../../email-account-login/src/CustomButton';
import AppliedPopupController,{Props} from './AppliedPopupController';
const images = require("./assets");

const StyledModal = styled(Modal)({
    position: 'fixed',
    zIndex: 1300,
    inset: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

const style = {
    width: 375,
    borderRadius: '20px',
    backgroundColor: 'white',
    boxShadow: 'none',
    height: 274,
};

const CustomButtonBox = styled(CustomButton)({
    '& .MuiButton-label': {
        fontWeight: 600,
        fontSize: 12,
        letterSpacing: '0.34285715px',
        lineHeight: '14px',
    }
});

// Customizable Area End
export default class AppliedPopup extends AppliedPopupController {
    constructor(props: Props) {
        super(props);
      
    }

    

    render() {
      
        return (
            <StyledModal
                
                open={this.props.open}
                onClose={this.props.handleClose}
                aria-labelledby="unstyled-modal-title"
                aria-describedby="unstyled-modal-description"
            >
                <Box sx={style} style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                    <Box style={{display:'flex',flexDirection:'column'}}>
                     <Box style={{fontFamily:'arial',fontWeight:700,paddingBottom:'36px',fontSize:'20px',}}>{this.props.message}</Box>
                     <button style={{ cursor: "pointer",
    color: "#ffffff",
    backgroundColor: "#17181D",
    textTransform: "none",
    width: "165px",
    border: "none",
    borderRadius: "14px",
    bottom: "16px",
    height: "48px",
    fontWeight: 600,
    fontSize: "14px",
    paddingTop: "10px",
    paddingBottom: "14px",
    marginBottom: "5px",
    fontFamily: "Urbanist",
    marginLeft:'50px'
    }} onClick={this.props.handleClose}>OK</button>
                     </Box>
                </Box>
               
            </StyledModal>
        )
        
    }
}
