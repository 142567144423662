import React, { useState } from 'react';
import {
    Box,
    Typography,
    styled,
    Grid,
    AppBar,
    Tabs,
    Tab,
    Switch,
    Radio
} from "@material-ui/core";
import Modal from '@material-ui/core/Modal';
import { CustomButton } from '../../blocks/email-account-login/src/CustomButton';
const images = require("./assets");

const StyledModal = styled(Modal)({
    position: 'fixed',
    zIndex: 1300,
    inset: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

const style = {
    width: 720,
    borderRadius: '20px',
    padding: '20px 24px 24px',
    backgroundColor: '#ffffff',
    boxShadow: 'none',
    height: 499,
    boxSizing: "border-box",
};

const CustomButtonBox = styled(CustomButton)({
    '& .MuiButton-label': {
        fontWeight: 600,
        fontSize: 16,
        letterSpacing: '0.45714286px',
        lineHeight: '24px',
    }
});

const AntSwitch = styled(Switch)(() => ({
    width: 25,
    height: 12.5,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: "7.5px",
            height: "7.5px",
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: '#F5C42C',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: "7.5px",
        height: "7.5px",
        borderRadius: 6,
        transitionDuration: "2s",
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: '#6D89AF',
        boxSizing: 'border-box',
    },
}));

function ManageAccessibilityModel({ ...props }) {
    const [tabValue, setTabValue] = useState(0);
    const [refreshToken, setRefreshToken] = useState(true);
    const [permission, setPermission] = useState(props.permssionsList);
    const [selectedDepartment, setSelectedDepartment] = useState(props.selectedDept);

    const handleAppBarValue = (event: React.ChangeEvent<{}>, value: number) => {
        setTabValue(value);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        let updatedPermission = permission;
        updatedPermission[index].selected = event.target.checked;
        setPermission(updatedPermission);
        setRefreshToken(!refreshToken);
    };

    const handleChangeDepartment = (event: any) => {
        setSelectedDepartment(event.target.value);
    };

    const handleSavePermission = () => {
        const permissionsSelected = permission.filter((data: any) => data.selected);

        const formData = new FormData();
        formData.append("employee[permission_ids]", permissionsSelected.map((data: any) => data.id).toString());
        formData.append("employee[project_department_id]", selectedDepartment);

        props.savePermissionsAPICall(formData);
    }

    return (
        <StyledModal
            aria-labelledby="unstyled-modal-title"
            aria-describedby="unstyled-modal-description"
            open={props.open}
            onClose={props.handleClose}
        >
            <Box sx={style}>
                <Grid container style={{ height: 24, color: '#17181d', fontFamily: 'Urbanist', fontSize: 20, fontWeight: 700, textAlign: 'end', letterSpacing: '0.2px' } as React.CSSProperties}>
                    <Grid xs={8}>
                        Manage Accessibility
                    </Grid>
                    <Grid xs={4}>
                        <img src={`${images.crossIcon}`} style={{ cursor: 'pointer' }} onClick={props.handleClose} />
                    </Grid>
                </Grid>
                <Grid style={{ height: "335px", marginTop: "24px", marginBottom: "24px" }} item xs={12} sm={12} md={12} lg={12}>
                    <AppBar
                        position="static"
                        className="appbar app_bar"
                        color="transparent"
                    >
                        <Tabs
                            value={tabValue}
                            data-test-id="tabChange"
                            variant="scrollable"
                            onChange={handleAppBarValue}
                            TabIndicatorProps={{ style: { height: '3px', background: "#f5c42c" } as React.CSSProperties }}
                            scrollButtons="on"
                            className="tabs_main"
                            aria-label="scrollable prevent tabs example"
                        >
                            <Tab className={tabValue == 0 ? "active_btn" : "non_active_btn"} label="Manage Permission" />
                            <Tab className={tabValue == 1 ? "active_btn" : "non_active_btn"} label="Manage Department" />
                        </Tabs>
                    </AppBar>
                    <Grid container spacing={3} key={`${refreshToken}_refresh`} className="hideScrollBar" style={{ marginTop: "12px", marginBottom: "12px", maxHeight: "272px", overflowY: "scroll" }}>
                        {tabValue === 0 ? <>
                            {permission.length ? permission.map((permissionData: any, index: number) => {
                                return <>
                                    <Grid key={`${permissionData.id}_manage_per`} sm={12} md={6} item style={{ padding: "12px" }} >
                                        <Box style={{ height: "48px", padding: "12px", boxSizing: "border-box", borderRadius: "14px", border: "1px solid #f5c42c", borderColor: permissionData.selected ? "#f5c42c" : "#e3eaf4", background: "rgba(245,196,44,0.04)", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                            <Typography style={{ paddingLeft: "2px", color: "#17181d", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 600, lineHeight: "16px" }}>{permissionData.attributes.name}</Typography>
                                            <AntSwitch checked={permissionData.selected} onChange={(event) => handleChange(event, index)} inputProps={{ 'aria-label': 'ant design' }} />
                                        </Box>
                                    </Grid>
                                </>
                            }) : null}
                        </> :
                            <>
                                {props.departmentsList.length ? props.departmentsList.map((departmentData: any, index: number) => {
                                    return <>
                                        <Grid key={`${departmentData.id}_manage_per`} sm={12} md={6} item style={{ padding: "12px" }} >
                                            <Box style={{ height: "48px", padding: "12px 3px 12px 12px", boxSizing: "border-box", borderRadius: "14px", border: "1px solid #e3eaf4", background: "#ffffff", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                <Typography style={{ paddingLeft: "2px", color: "#17181d", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 600, lineHeight: "16px" }}>{departmentData.attributes.name.toUpperCase()}</Typography>
                                                <Box style={{ display: "flex", alignItems: "center", gap: "3px" }}>
                                                    <Typography style={{ lineHeight: "18px", color: "#3389ff", fontFamily: "Urbanist", fontSize: "15px", fontWeight: 600 }}>Level {departmentData.attributes.level}</Typography>
                                                    <Radio
                                                        className="manageAccessibilityRadio"
                                                        style={{
                                                            color: "#6D89AF"
                                                        }}
                                                        size="small"
                                                        value={departmentData.id}
                                                        checked={departmentData.id.toString() === selectedDepartment}
                                                        onChange={handleChangeDepartment}
                                                    />
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </>
                                }) : null}
                            </>
                        }
                    </Grid>
                </Grid>
                <Grid container style={{ justifyContent: "end" }}>
                    <CustomButtonBox data-test-id="sign_in_button" style={{ borderRadius: "14px", height: 48, background: "#17181d", width: "210px", color: "#ffffff" } as React.CSSProperties} onClick={handleSavePermission}>
                        Save Permissions
                    </CustomButtonBox>
                </Grid>
            </Box>
        </StyledModal>
    )
}

export default ManageAccessibilityModel;