import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isSideBarOpen: boolean;
  isLoading: boolean;
  isDropdownOpen: boolean;
  tabValue: number;
  allStudiosData: any;
  myBookingsData: any;
  myRequestsData: any;
  allStudiosSearchText: string;
  currentStudiosPage: number;
  isModelOpen: boolean;
  statesData: { id: number, name: string, iso2: string }[];
  phList: any[];
  filterValues: any;
  filteredParams: any;
  getCountForPagination: any;
  notification: { type: string, open: boolean, message: string, route?: string };
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class StudiosBookingListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getMyBookingsMessageId: string = "";
  getMyRequestsMessageId: string = "";
  cancelBookingMessageId: string = "";
  leaveStudioMessageId: string = "";
  withdrawRequestMessageId: string = "";
  requestAgainMessageId: string = "";
  getStatesMessageId: string = "";
  getPHListMessageId: string = "";
  getFilteredDataMessageId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isSideBarOpen: true,
      isLoading: false,
      isDropdownOpen: false,
      tabValue: 0,
      allStudiosData: [],
      myBookingsData: [],
      myRequestsData: [],
      allStudiosSearchText: "",
      currentStudiosPage: 1,
      isModelOpen: false,
      statesData: [],
      phList: [],
      filterValues: "",
      filteredParams: "",
      getCountForPagination: {
        0: 0,
        1: 0,
        2: 0,
      },
      notification: { type: "", open: false, message: "" },
      // Customizable Area End
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.getMyBookingsMessageId === apiRequestCallId) {
        this.getMyBookingsDataHandleResponse(responseJson);
      } else if (this.getMyRequestsMessageId === apiRequestCallId) {
        this.getMyRequestsDataHandleResponse(responseJson);
      } else if (this.getStatesMessageId === apiRequestCallId) {
        this.setState({ statesData: responseJson });
      } else if (this.getPHListMessageId === apiRequestCallId) {
        this.getPHListDataHandleResponse(responseJson);
      } else if (this.getFilteredDataMessageId === apiRequestCallId) {
        this.getFilteredDataHandleResponse(responseJson);
      } else if (this.cancelBookingMessageId === apiRequestCallId) {
        this.cancelBookingHandleResponse(responseJson);
      } else if (this.leaveStudioMessageId === apiRequestCallId) {
        this.leaveStudioHandleResponse(responseJson);
      } else if (this.withdrawRequestMessageId === apiRequestCallId) {
        this.withdrawRequestHandleResponse(responseJson);
      } else if (this.requestAgainMessageId === apiRequestCallId) {
        this.requestAgainHandleResponse(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getMyBookingsDataHandleResponse = async (responseJson: { meta: { bookings: number, message: string }, errors: { token: string }[], data: any[] }) => {
    if (responseJson.data) {
      this.setState({ myBookingsData: responseJson.data });
      this.setState({ getCountForPagination: { ...this.state.getCountForPagination, 1: responseJson.meta.bookings } });
    } else if (responseJson.errors[0].token) {
      this.setState({ notification: { open: true, type: "error", message: responseJson.errors[0].token, route: "LandingPage" } }, () => {
      });
    }
    this.setState({ isLoading: false });
  }

  getMyRequestsDataHandleResponse = async (responseJson: { meta: { booking_requests: number, message: string }, errors: { token: string }[], data: any[] }) => {
    if (responseJson.data) {
      this.setState({ myRequestsData: responseJson.data });
      this.setState({ getCountForPagination: { ...this.state.getCountForPagination, 2: responseJson.meta.booking_requests } });
    } else if (responseJson.errors[0].token) {
      this.setState({ notification: { open: true, type: "error", message: responseJson.errors[0].token, route: "LandingPage" } });
    }
    this.setState({ isLoading: false });
  }

  getPHListDataHandleResponse = (responseJson: { data: any[] }) => {
    if (responseJson.data) {
      this.setState({ phList: responseJson.data });
    }
    this.setState({ isLoading: false });
  }

  getFilteredDataHandleResponse = (responseJson: { meta: { studios: number, message: string }, errors: { token: string }[], data: any[] }) => {
    if (responseJson.data) {
      this.setState({ allStudiosData: responseJson.data });
      this.setState({ getCountForPagination: { ...this.state.getCountForPagination, 0: responseJson.meta.studios } });
    } else if (responseJson.errors[0].token) {
      this.setState({ notification: { open: true, type: "error", message: responseJson.errors[0].token, route: "LandingPage" } });
    }
    this.setState({ isLoading: false });
  }

  cancelBookingHandleResponse = async (responseJson: { data: any, errors: { message: string } | { token: string }[] }) => {
    this.cancelALeaveStudioHandleResponse(responseJson, "Booking cancelled successfully");
  }

  leaveStudioHandleResponse = (responseJson: { data: any, errors: { message: string } | { token: string }[] }) => {
    this.cancelALeaveStudioHandleResponse(responseJson, "Leave studio successfully");
  }

  cancelALeaveStudioHandleResponse = async (responseJson: any, alertMessage: string) => {
    if (responseJson.data) {
      this.setState({ notification: { open: true, type: "success", message: alertMessage } });
      this.getMyBookingsMessageId = await this.apiCall(configJSON.validationApiMethodType, configJSON.getMyBookingsAPIEndPoint + `?page=1&per_page=9`);
      this.getMyRequestsMessageId = await this.apiCall(configJSON.validationApiMethodType, configJSON.getMyRequestsAPIEndPoint + `?page=1&per_page=9`);
    } else if (Array.isArray(responseJson.errors)) {
      this.setState({ notification: { open: true, type: "error", message: responseJson.errors[0].token, route: "LandingPage" } });
    } else if (responseJson.errors.message) {
      this.setState({ notification: { open: true, type: "error", message: responseJson.errors.message } });
    }
    this.setState({ isLoading: false });
  }

  withdrawRequestHandleResponse = async (responseJson: { data: any, errors: { message: string } | { token: string }[] }) => {
    this.withdrawAndAgainRequestHandleResponse(responseJson, "Withdrawn request successfully");
  }

  requestAgainHandleResponse = async (responseJson: { data: any, errors: { message: string } | { token: string }[] }) => {
    this.withdrawAndAgainRequestHandleResponse(responseJson, "Request again successfully");
  }

  withdrawAndAgainRequestHandleResponse = async (requestResponseJson: any, alertData: string) => {
    if (requestResponseJson.data) {
      this.setState({ notification: { open: true, type: "success", message: alertData } });
      this.getMyRequestsMessageId = await this.apiCall(configJSON.validationApiMethodType, configJSON.getMyRequestsAPIEndPoint + `?page=1&per_page=9`);
    } else if (Array.isArray(requestResponseJson.errors)) {
      this.setState({ notification: { open: true, type: "error", message: requestResponseJson.errors[0].token, route: "LandingPage" } });
    } else if (requestResponseJson.errors.message) {
      this.setState({ notification: { open: true, type: "error", message: requestResponseJson.errors.message } });
    }
    this.setState({ isLoading: false });
  }

  handleSideBar = () => {
    setStorageData("sidebarvalue", (!this.state.isSideBarOpen).toString())
    this.setState({
      isSideBarOpen: !this.state.isSideBarOpen
    });
  };

  handleDropdown = () => {
    this.setState({
      isDropdownOpen: !this.state.isDropdownOpen
    });
  };

  handleNavigation = (page: string) => {
    this.props.navigation.navigate(page)
  };

  handleSearchStudios = async (event: any) => {
    this.setState({ allStudiosSearchText: event.target.value });
    this.getFilteredDataMessageId = await this.apiCall(configJSON.validationApiMethodType, configJSON.getFilteredDataAPIEndPoint +
      `?page=${this.state.currentStudiosPage}&per_page=9&search=${event.target.value}${this.state.filteredParams}`);
  }

  handleAppBarValue = (event: React.ChangeEvent<{}>, value: number) => {
    this.setState({
      tabValue: value,
      allStudiosSearchText: "",
    });
    setStorageData("studioBookingViewStatus", "false");
    this.handlePageChange({}, 1);
    this.handleSearchStudios({ target: { value: "" } });
  }

  async componentDidMount() {
    this.getFilteredDataMessageId = await this.apiCall(configJSON.validationApiMethodType, configJSON.getFilteredDataAPIEndPoint +
      `?page=${this.state.currentStudiosPage}&per_page=9&search=${this.state.allStudiosSearchText}${this.state.filteredParams}`);
    this.getMyBookingsMessageId = await this.apiCall(configJSON.validationApiMethodType, configJSON.getMyBookingsAPIEndPoint + `?page=${this.state.currentStudiosPage}&per_page=9`);
    this.getMyRequestsMessageId = await this.apiCall(configJSON.validationApiMethodType, configJSON.getMyRequestsAPIEndPoint + `?page=${this.state.currentStudiosPage}&per_page=9`);
    const studioBookingViewStatus = await getStorageData("studioBookingViewStatus");

    const isSideBarOpen = await getStorageData("sidebarvalue");
    this.setState({ isSideBarOpen: JSON.parse(isSideBarOpen), tabValue: studioBookingViewStatus === "true" ? 2 : this.state.tabValue });
  }

  apiCall = async (method: string, endPoint: string, bodyData?: any) => {
    const authToken = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.contentTypeApiUpdateUser,
      token: authToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setState({ isLoading: true });
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    bodyData && requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  getFormattedDate = (date: any) => {
    return moment.utc(date).format("DD MMM, YYYY");
  }

  handlePageChange = async (event: any, value: number) => {
    if (this.state.tabValue === 0) {
      this.getFilteredDataMessageId = await this.apiCall(configJSON.validationApiMethodType, configJSON.getFilteredDataAPIEndPoint +
        `?page=${value}&per_page=9&search=${this.state.allStudiosSearchText}${this.state.filteredParams}`);
    } else if (this.state.tabValue === 1) {
      this.getMyBookingsMessageId = await this.apiCall(configJSON.validationApiMethodType, configJSON.getMyBookingsAPIEndPoint + `?page=${value}&per_page=9`);
    } else if (this.state.tabValue === 2) {
      this.getMyRequestsMessageId = await this.apiCall(configJSON.validationApiMethodType, configJSON.getMyRequestsAPIEndPoint + `?page=${value}&per_page=9`);
    }
    this.setState({ currentStudiosPage: value });
  }

  handleModelOpen = () => {
    this.setState({ isModelOpen: true });
  }

  handleModelClose = () => {
    this.setState({ isModelOpen: false });
  }

  openStudioFilter = async () => {
    const XCSCAPIKEY = "Z1dmZFJDWjlMSjl0M082ZUUyU3JESkJCQTdZeTd6UEVINXdreTdjWQ=="
    const header = {
      "Content-Type": configJSON.contentTypeApiUpdateUser,
      "X-CSCAPI-KEY": XCSCAPIKEY
    };
    this.setState({ isLoading: true, currentStudiosPage: 1, allStudiosSearchText: "" });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getStatesMessageId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStateAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.handleModelOpen();
    this.getPHListMessageId = await this.apiCall(configJSON.validationApiMethodType, configJSON.getPHListAPIEndPoint);
  }

  cancelBookingAPICall = async (studioId: string) => {
    const bodyData = {
      data: {
        attributes: {
          status: "cancelled"
        }
      }
    };
    this.cancelBookingMessageId = await this.apiCall(configJSON.updatePasswordAPiMethod, configJSON.cancelBookingAPIEndPoint + studioId, bodyData);
  }

  leaveStudioAPICall = async (studioId: string) => {
    const bodyData = {
      data: {
        attributes: {
          status: "completed"
        }
      }
    };
    this.leaveStudioMessageId = await this.apiCall(configJSON.updatePasswordAPiMethod, configJSON.leaveStudioAPIEndPoint + studioId, bodyData);
  }

  withdrawRequestAPICall = async (studioId: string) => {
    this.withdrawRequestMessageId = await this.apiCall(configJSON.deleteAPIMethod, configJSON.withdrawRequestAPIEndPoint + studioId);
  }

  requestAgainAPICall = async (studioId: string) => {
    const bodyData = {
      data: {
        attributes: {
          status: "pending"
        }
      }
    };
    this.requestAgainMessageId = await this.apiCall(configJSON.updatePasswordAPiMethod, configJSON.requestAgainAPIEndPoint + studioId, bodyData);
  }

  handleFilterAPICall = async (params: any) => {
    this.setState({ filteredParams: params });
    this.getFilteredDataMessageId = await this.apiCall(configJSON.validationApiMethodType, configJSON.getFilteredDataAPIEndPoint +
      `?page=${this.state.currentStudiosPage}&per_page=9&search=${this.state.allStudiosSearchText}${params}`);
  }

  handleStoreFilterValues = (newFilterValues: any) => {
    this.setState({ filterValues: newFilterValues });
  }

  navigateToDetailPage = (studioId: string) => {
    setStorageData("studioId", studioId);
    this.props.navigation.navigate("StudiosBookingDetail");
  }

  async componentWillUnmount() {
    setStorageData("studioBookingViewStatus", "false");
  }

  handleCloseNotification = () => {
    this.setState({ notification: { type: "", open: false, message: "" } });
  }
  // Customizable Area End
}
