import React from "react";
import { Card, Box, CardMedia, CardContent, Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {  JobCardImage,LocationIconjob, jobpositionicon, jobyrs, rupeeiconjob ,dummyImage} from "./assets"

interface MyProps {
    data: any;
}



const useStyles = makeStyles({
    cardDivWithOpenSideBar: () => ({
        borderRadius: 14,
        padding: 8,
        border: "1px solid #E1E6EC",
        //position: 'relative',
        '& .MuiCardContent-root': {
            padding: 0
        },
        "@media(max-width: 500px)": {
            
        },
        "@media(max-width: 1300px)": {
        
        },
    }),
    cardDivWithClosedSideBAr: () => ({
        width: 346,
        borderRadius: 14,
        padding: 8,
        border: "1px solid #E1E6EC",
        position: 'relative',
        '& .MuiCardContent-root': {
            padding: 0
        },
        "@media(max-width: 500px)": {
        
        },
    }),
    parentDiv: () => ({
    }),
    ParentSubheading1:()=>({
        display:"flex",
        gap:"5px",
    })
    ,
    headingDept: () => ({
        display: 'flex',
        justifyContent: 'space-between',
    })
    ,
    positionDiv: () => ({
        display: 'flex',
        gap: '5px',
       borderRadius:'15px',
       backgroundColor:'#f8fafe',
       borderColor:'#979797',
       height:31,
       width:101,
       alignItems:'center',
       justifyContent: "center",
    }),
    iconposition: () => ({
        
    }),
    DeptDiv: () => ({
        fontSize: '13px',
        fontFamily: 'urbanist',
        color: '#17181D',
        fontWeight: 500,
        lineHeight: '16px',
    }),
    Dept: () => ({}),
    mainDiv: () => ({
        paddingTop: '4px',
        lineHeight: '19px',
        fontSize: '16px',
        fontWeight: 600,
        fontFamily: 'urbanist',
        color: '#17181D'
    }),
    YrsDiv: () => ({
        display: 'flex',
        gap:'4px',
        paddingTop: '6px',
        '& .MuiBox-root' : {
            fontSize: '13px',
            fontWeight: 600,
            color: '#6d89af',
        }
    }),
    textDiv: () => ({
        '& .MuiBox-root' : {
            fontSize: '13px',
            fontWeight: '600px',
        }
    }),
    jobiconBox:()=>({}),
    jobiconyear:()=>({
        height:'16px',
        width:'16px'
    }),
    rupeeicon:()=>({
        paddingTop:'4px',
        height:'16px',
        width:'16px'
    }),
    rupeesDiv:()=>({
        display:'flex',
        gap:'4px',
        borderBottom: '1px solid #e3eaf4',
        paddingBottom: '11px',
        '& .MuiBox-root' : {
            display: 'flex',
            gap: '4px',
            fontSize: '14px',
            color: '#6d89af',
            fontWeight: 400,
            fontFamily: 'Helvetica',
            paddingTop: '5px',
           
        }

    }),
    jobcardimage:()=>({
      //paddingTop:'10px',
      height:'40px',
      width:'40px',
      borderRadius:'10px'
    }),
    footerDiv:()=>({
        display:"flex"
    }),
    addressDiv:()=>({
      paddingTop:'13px',
      paddingLeft: "6px",
    }),
   address1:()=>({
    fontSize: '14px',
    fontFamily: 'urbanist',
    fontWeight: 600,
    color: '#17181D',
   }),
   address2:()=>({
    display:'flex',
    gap:'4px',
    paddingTop: "3px",
   }),
   ParentSubheading2:()=>({
    fontSize:'11px',
    fontFamily:'urbanist',
    fontWeight:500,
    color:'#6d89af',
    paddingTop:'22px'

   }),
   locationDiv:()=>({
    fontSize:'12px',
    fontWeight:600,
    fontFamily:'urbanist',
    color:'#6d89af',


   }),

   

   parentBox:()=>({
    width:'100%',
    

   }),

   rupeetext:()=>({})

});


export default function ProjectCard(props: MyProps) {

    const { data } = props
    const classes = useStyles();

    return (
        <Box className={classes.parentBox}>
            <Card className={classes.cardDivWithOpenSideBar}>
                <Box className={classes.headingDept}>
                    <Box>
                        <Box className={classes.DeptDiv}>
                            Dept | {data?.attributes?.project_department_name}
                        </Box>
                        <Box className={classes.mainDiv}>
                        {data?.attributes?.role}
                        </Box>
                    </Box>
                    <Box className={classes.positionDiv} >
                        <Box>
                            <img src={jobpositionicon} alt="" className={classes.iconposition} />
                        </Box>
                        <Box style={{fontSize:'13px',color:'#6d89af',fontWeight:600,fontFamily:'urbanist'}}>
                            {data?.attributes?.number_of_positions} Positions
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.YrsDiv}>
                    <Box className={classes.jobiconBox}>
                    <img src={jobyrs} alt="" className={classes.jobiconyear} />
                    </Box>
                    <Box className={classes.textDiv}>{data?.attributes?.exp_required} Yrs</Box>
                </Box>
                <Box  className={classes.rupeesDiv}>
                    <Box>
                    <img src={rupeeiconjob} alt="" className={classes.rupeeicon} />
                    </Box>
                    <Box className={classes.rupeetext}>₹{data?.attributes?.budget_of_role}</Box>
                </Box>
                
                    <Box style={{display:'flex',justifyContent:'space-between'}}>
                     <Box className={classes.ParentSubheading1}> 
                    <Box style={{paddingTop:'10px'}}>
                    <img src={data?.attributes?.project?.image_url ? data?.attributes?.project?.image_url : dummyImage} alt="" className={classes.jobcardimage} />
                    </Box>
                    <Box className={classes.addressDiv}>
                       <Box className={classes.address1}>
                       {data?.attributes?.project?.project_name}
                       </Box>
                       {data?.attributes?.city && data?.attributes?.state ? 
                       <Box className={classes.address2}>
                         <Box>
                         <img src={LocationIconjob} alt="" className={classes.jobiconyear} />
                         </Box>
                        <Box className={classes.locationDiv}>
                         { `${data?.attributes?.city}, ${data?.attributes?.state}`}
                         </Box>
                       </Box>:""}
                    </Box>
                    </Box>  
                    <Box className={classes.ParentSubheading2}>
                         
                         { data?.attributes?.days_count===0?"Today":`${data?.attributes?.days_count} Day ago`}
                    </Box>

                    </Box>
            

            </Card>
        </Box>
    );
}
