import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isStudioBookingDetailSideBarOpen: boolean;
  isLoading: boolean;
  isDropdownOpen: boolean;
  studioDetailsData: any;
  studioDetailReviewData: any;
  studioDetailTabValue: number;
  isModelOpen: boolean;
  studioBookingId: string;
  modelType: string;
  notification: { type: string, open: boolean, message: string, route?: string };
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class StudiosBookingDetailController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getStudioDetailsMessageId: string = "";
  getStudioDetailReviewDataMessageId: string = "";
  bookStudioMessageId: string = "";
  giveRatingMessageId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isStudioBookingDetailSideBarOpen: true,
      isLoading: false,
      isDropdownOpen: false,
      studioDetailsData: {},
      studioDetailReviewData: {},
      studioDetailTabValue: 0,
      isModelOpen: false,
      studioBookingId: "",
      modelType: "",
      notification: { type: "", open: false, message: "" },
      // Customizable Area End
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const studioBookingDetailApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const studioBookingDetailResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.getStudioDetailsMessageId === studioBookingDetailApiRequestCallId) {
        this.getStudioDetailsDataHandleResponse(studioBookingDetailResponseJson);
      } else if (this.getStudioDetailReviewDataMessageId === studioBookingDetailApiRequestCallId) {
        this.getStudioDetailReviewDataHandleResponse(studioBookingDetailResponseJson);
      } else if (this.bookStudioMessageId === studioBookingDetailApiRequestCallId) {
        this.bookStudioHandleResponse(studioBookingDetailResponseJson);
      } else if (this.giveRatingMessageId === studioBookingDetailApiRequestCallId) {
        this.giveRatingHandleResponse(studioBookingDetailResponseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getStudioDetailsDataHandleResponse = (studioBookingDetailResponseJson: { errors: { token: string }[], data: any }) => {
    if (studioBookingDetailResponseJson.data) {
      this.setState({ studioDetailsData: studioBookingDetailResponseJson.data.attributes });
    } else if (studioBookingDetailResponseJson.errors[0].token) {
      this.setState({ notification: { open: true, type: "error", message: studioBookingDetailResponseJson.errors[0].token, route: "LandingPage" } });
    }
    this.setState({ isLoading: false });
  }

  getStudioDetailReviewDataHandleResponse = (studioBookingDetailResponseJson: { errors: { token: string }[], average_rating: number, rating_star: any, review_rating_count: string, reviews: { data: any[] } }) => {
    if (studioBookingDetailResponseJson.reviews?.data) {
      this.setState({ studioDetailReviewData: studioBookingDetailResponseJson });
    } else if (studioBookingDetailResponseJson.errors[0].token) {
      this.setState({ notification: { open: true, type: "error", message: studioBookingDetailResponseJson.errors[0].token, route: "LandingPage" } });
    }
    this.setState({ isLoading: false });
  }

  bookStudioHandleResponse = (studioBookingDetailResponseJson: { errors: any, data: any }) => {
    if (studioBookingDetailResponseJson.data) {
      this.handleModelOpen();
      this.setState({ modelType: "successModel" });
    } else if (typeof (studioBookingDetailResponseJson.errors.message) === "string") {
      this.setState({ notification: { open: true, type: "error", message: studioBookingDetailResponseJson.errors.message } });
    } else if (studioBookingDetailResponseJson.errors.length && studioBookingDetailResponseJson.errors[0].token) {
      this.setState({ notification: { open: true, type: "error", message: studioBookingDetailResponseJson.errors[0].token, route: "LandingPage" } });
    }
    this.setState({ isLoading: false });
  }

  giveRatingHandleResponse = async (studioBookingDetailResponseJson: { errors: { token: string }[], data: any }) => {
    if (studioBookingDetailResponseJson.data) {
      this.handleModelClose();
      this.getStudioDetailReviewDataMessageId = await this.apiCall(configJSON.validationApiMethodType, configJSON.getStudioDetailReviewDataAPIEndPoint + this.state.studioBookingId);
    } else if (studioBookingDetailResponseJson.errors[0].token) {
      this.setState({ notification: { open: true, type: "error", message: studioBookingDetailResponseJson.errors[0].token, route: "LandingPage" } });
    }
    this.setState({ isLoading: false });
  }

  handleStudioDetailPageSideBar = () => {
    setStorageData("sidebarvalue", (!this.state.isStudioBookingDetailSideBarOpen).toString())
    this.setState({
      isStudioBookingDetailSideBarOpen: !this.state.isStudioBookingDetailSideBarOpen
    });
  };

  handleDropdown = () => {
    this.setState({
      isDropdownOpen: !this.state.isDropdownOpen
    });
  };

  handleNavigation = (page: string) => {
    this.props.navigation.navigate(page)
  };

  async componentDidMount() {
    const studioId = await getStorageData("studioId");
    const isSideBarOpen = await getStorageData("sidebarvalue");
    this.setState({ studioBookingId: studioId, isStudioBookingDetailSideBarOpen: JSON.parse(isSideBarOpen) });
    this.getStudioDetailsMessageId = await this.apiCall(configJSON.validationApiMethodType, configJSON.getStudioDetailsAPIEndPoint + studioId);
    this.getStudioDetailReviewDataMessageId = await this.apiCall(configJSON.validationApiMethodType, configJSON.getStudioDetailReviewDataAPIEndPoint + studioId);
  }

  apiCall = async (studioBookingDetailMethod: string, studioBookingDetailEndPoint: string, studioBookingDetailBodyData?: any) => {
    const authToken = await getStorageData("token");
    const studioBookingDetailHeader = {
      "Content-Type": configJSON.contentTypeApiUpdateUser,
      token: authToken
    };
    const studioBookingDetailRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setState({ isLoading: true });
    studioBookingDetailRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      studioBookingDetailEndPoint
    );
    studioBookingDetailRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(studioBookingDetailHeader)
    );
    studioBookingDetailBodyData && studioBookingDetailRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(studioBookingDetailBodyData)
    );
    studioBookingDetailRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      studioBookingDetailMethod
    );
    runEngine.sendMessage(studioBookingDetailRequestMessage.id, studioBookingDetailRequestMessage);
    return studioBookingDetailRequestMessage.messageId;
  }

  handleModelOpen = () => {
    this.setState({ isModelOpen: true });
  }

  handleModelClose = () => {
    this.setState({ isModelOpen: false, modelType: "" });
  }

  handleTabValueChange = (tabVal: number) => {
    this.setState({ studioDetailTabValue: tabVal });
  }

  handleBookStudioModelOpen = () => {
    this.handleModelOpen();
  }

  sendBookingRequest = async (bookingData: any) => {
    this.bookStudioMessageId = await this.apiCall(configJSON.manageprofilemethodtype, configJSON.bookStudioAPIEndPoint, bookingData);
  }

  handleRatingModelOpen = () => {
    this.handleModelOpen();
    this.setState({ modelType: "ratingModel" });
  }

  handleGiveRatingAPICall = async (ratingData: any) => {
    if (!ratingData.review.given_star) {
      this.setState({ notification: { open: true, message: "Please add rating", type: "error" } });
      return false;
    } else if (!ratingData.review.comment) {
      this.setState({ notification: { open: true, message: "Please add review", type: "error" } });
      return false;
    }
    this.giveRatingMessageId = await this.apiCall(configJSON.manageprofilemethodtype, configJSON.giveRatingAPIEndPoint, ratingData);
  }

  handleCloseNotification = () => {
    this.setState({ notification: { type: "", open: false, message: "" } });
  }

  getStatusBG = (status: string, type: "bg" | "wid") => {
    if (status === "Available") {
      return type == "bg" ? "#3389ff" : "73px"
    } else if (status === "Not Available") {
      return type == "bg" ? "#000000" : "92px"
    } else if (status === "Booked") {
      return type == "bg" ? "#f5c42c" : "64px"
    }
  }
  // Customizable Area End
}
