import React from "react";
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Travelingarrow, mytaskreceipt , dummyImage } from "./assets";

interface MyProps {
    data: any;
    navigaetoProject: any;
}

const useStyles = makeStyles({
    cardDivWithOpenSideBar: () => ({
        //width: 312,
        //height: 240,
        borderRadius: 14,
        padding: 12,
        paddingRight: 14,
        border: "1px solid #E1E6EC",
        position: 'relative',
        '& .MuiCardContent-root': {
            // padding: 0
        },
        "@media(max-width: 500px)": {
        },
        "@media(max-width: 1300px)": {
            //width: '270px',
        },
    }),
    ParentDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    arrowicon: {
        cursor: "pointer",
    },
    Profileimageandheadingandrequest: {
        display: 'flex',
        gap: '10px'
    },
    Profile: {
        width: '42px',
        height: '42px',
        borderRadius: '12px',
    },
    Profileimg: {
        borderRadius: '12px',
        width: '42px',
        height: '42px'
    },
    ProjectNameandrequest: {},
    ProjectName: {
        color: '#17181d',
        fontFamily: 'urbanist',
        fontSize: '16px',
        fontWeight: 600,
        letterSpacing: '0.2px',
        paddingTop: "3px",
    },
    requestandicon: {
        display: 'flex',
        gap: "3px",
        paddingTop: "3px",
    },
    iconrequest: {},
    requsttext: {
        color: '#6d89af',
        fontFamily: 'Urbanist',
        fontSize: '12px',
        fontWeight: 500,
        letterSpacing: '0.15px'
    },
    cardDivWithClosedSideBAr: () => ({
        width: 346,
        borderRadius: 14,
        padding: 8,
        border: "1px solid #E1E6EC",
        position: 'relative',
        '& .MuiCardContent-root': {
            padding: 0
        },
        "@media(max-width: 500px)": {
        },
    }),
    parentBox: () => ({
        width: '100%'
    }),

});

export default function MyTask(props: MyProps) {

    const { data, navigaetoProject } = props
    const classes = useStyles();

    return (
        <Box className={classes.parentBox}>
            <Box className={classes.cardDivWithOpenSideBar}>
                <Box className={classes.ParentDiv}>
                    <Box className={classes.Profileimageandheadingandrequest}>
                        <Box className={classes.Profile}>
                            <img src={data?.attributes?.image || dummyImage} alt="" className={classes.Profileimg} />
                        </Box>
                        <Box className={classes.ProjectNameandrequest}>
                            <Box className={classes.ProjectName}>
                                {data?.attributes.project_name}
                            </Box>
                            <Box className={classes.requestandicon}>
                                <Box className={classes.iconrequest}>
                                    <img src={mytaskreceipt} alt="" />
                                </Box>
                                <Box className={classes.requsttext}>
                                    {data?.attributes?.invoice_count} Invoice requests
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={classes.arrowicon} onClick={() => navigaetoProject(data.id,data.attributes.invoice_count)}>
                        <img src={Travelingarrow} alt="" />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
