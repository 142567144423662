import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from "moment";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  open: boolean;
  handleClose: any;
  sendBookingRequest: any;
  pricePerDay: any;
  pricePerHour: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  studioBookType: "day_wise" | "hour_wise";
  startDate: any;
  endDate: any;
  startTime: any;
  endTime: any;
  diffOfDate: number;
  notification: { type: string, open: boolean, message: string };
  projectName: string;
  reasonForBooking: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class BookStudioModelController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      studioBookType: "day_wise",
      startDate: null,
      endDate: null,
      startTime: null,
      endTime: null,
      diffOfDate: 0,
      notification: { type: "", open: false, message: "" },
      projectName: "",
      reasonForBooking: "",
      // Customizable Area End
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleSelectStartDate = (newDate: any) => {
    if (this.state.studioBookType === "day_wise") {
      let diff = this.state.endDate ? moment(this.state.endDate).diff(moment(newDate), "days") : 0;
      if (diff < 0) {
        this.setState({ startDate: newDate, endDate: newDate, diffOfDate: 0 });
      } else {
        this.setState({ startDate: newDate, diffOfDate: diff });
      }
    } else {
      this.setState({ startDate: newDate });
    }
  }

  handleSelectEndDate = (newDate: any) => {
    let diff = moment(newDate).diff(moment(this.state.startDate), "days");
    this.setState({ endDate: newDate, diffOfDate: diff });
  }

  handleSelectStartTime = (newTime: any) => {
    let diff = this.state.endTime ? moment(this.state.endTime).diff(moment(newTime), "hours") : 0;
    if (diff <= 0 && this.state.endTime) {
      this.setState({ startTime: newTime, endTime: newTime, diffOfDate: 0 });
    } else {
      this.setState({ startTime: newTime, diffOfDate: diff });
    }
  }

  handleSelectEndTime = (newTime: any) => {
    let diff = moment(newTime).diff(moment(this.state.startTime), "hours");
    if (diff < 0) {
      this.setState({ notification: { open: true, type: "warning", message: "End Time must be greater then Start Time!" } });
    } else {
      this.setState({ endTime: newTime, diffOfDate: diff });
    }
  }

  handleSendBookingRequest = () => {
    if (!this.state.diffOfDate || !this.state.projectName || !this.state.reasonForBooking) {
      this.setState({ notification: { open: true, type: "warning", message: "Studio booking details are mandatory!" } });
      return false;
    }
    const bookingData = {
      day_wise: {
        studio_booking: {
          booking_type: "day_wise",
          studio_id: this.props.id,
          project_name: this.state.projectName,
          start_date: moment(this.state.startDate).format('YYYY-MM-DD'),
          end_date: moment(this.state.endDate).format('YYYY-MM-DD'),
          total_days: this.state.diffOfDate,
          total_amount: this.state.diffOfDate * this.props.pricePerDay,
          booking_reason: this.state.reasonForBooking,
        }
      },
      hour_wise: {
        studio_booking: {
          booking_type: "hour_wise",
          studio_id: this.props.id,
          project_name: this.state.projectName,
          date: moment(this.state.startDate).format('YYYY-MM-DD'),
          start_time: moment(this.state.startTime).format('hh:mm A'),
          end_time: moment(this.state.endTime).format('hh:mm A'),
          total_hours: this.state.diffOfDate,
          total_amount: this.state.diffOfDate * this.props.pricePerHour,
          booking_reason: this.state.reasonForBooking,
        }
      }
    }
    this.props.sendBookingRequest(bookingData[this.state.studioBookType]);
    this.props.handleClose();
  }

  handleChangeStudioBookType = (type: "day_wise" | "hour_wise") => {
    this.setState({ studioBookType: type });
    if (type === "day_wise") {
      this.setState({ startTime: null, endTime: null, diffOfDate: 0 });
    } else {
      this.setState({ endDate: null, diffOfDate: 0 });
    }
  }

  getTotalAmountForText = () => {
    if (this.state.studioBookType === "day_wise") {
      return this.state.diffOfDate > 1 ? `${this.state.diffOfDate} Days` : `${this.state.diffOfDate} Day`
    } else {
      return this.state.diffOfDate > 1 ? `${this.state.diffOfDate} Hours` : `${this.state.diffOfDate} Hour`
    }
  }

  handleCloseNotification = () => {
    this.setState({ notification: { type: "", open: false, message: "" } });
  }

  handleChangeProjectName = (event: any) => {
    this.setState({ projectName: event.target.value });
    if (!event.target.value) {
      this.setState({ notification: { open: true, type: "warning", message: "Project name is required!" } });
    }
  }

  handleChangeReasonForBooking = (event: any) => {
    this.setState({ reasonForBooking: event.target.value });
    if (!event.target.value) {
      this.setState({ notification: { open: true, type: "warning", message: "Reason for booking is required!" } });
    }
  }
  // Customizable Area End
}
