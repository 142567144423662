import React from "react";

import {
  // Customizable Area Start
  Container,
  Box,
  InputLabel,
  Card,
  Grid,
  OutlinedInput,
  CircularProgress,
  Divider
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Badge from "@material-ui/core/Badge";
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import {
  withStyles,
  createStyles
} from "@material-ui/core/styles";
import Pagination from '@material-ui/lab/Pagination';
import AllvendorContentController, { Props } from "./AllvendorContentController";
import "./fullVisibility.css";
import BreadCrum from "./BreadCrum.web";
import CardComponent from "./CardComponent.web";
import CardFooter from "./CardFooter.web";
import SortFunctionalityVendor from "../../../components/src/sortFunctionalityVendor";
import { Filtericon } from "../../../components/src/assets";
import Modal from '@material-ui/core/Modal';
export const configJSON = require("./config");
export const StyledBadge = withStyles(() =>
  createStyles({
    badge: {
      color: "#44b700",
      "&::after": {
        position: "absolute",
        right: -3,
        top: 13,
        padding: "0 4px",
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        content: '""'
      }
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0
      }
    }
  })
)(Badge);
const useStyles = makeStyles({
   parentDiv:{

   }  
})
// Customizable Area End

export default class AllvendorContent extends AllvendorContentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <Container maxWidth="xl">
        <Grid container spacing={2} style={{ backgroundColor: "#F8FAFE" }}>
          <Grid item xs={8} sm={8} md={8} lg={8} xl={8} style={{ backgroundColor: "#F8FAFE", marginTop: "2.5%", }}>
            <Box style={{ backgroundColor: "#F8FAFE" }}>
              <InputLabel style={webStyle.vendorTypo}>{configJSON.allVendorText}</InputLabel>
              <Box sx={webStyle.allVendorTextBox}>
                <BreadCrum navigation={this.props.navigation} id="" breadcrumList={this.state.list} />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4} style={webStyle.vendorGrid as React.CSSProperties}>


            <div>
              <Box style={webStyle.bellIconBackground} onClick={this.handleOpen}>
                <img src={Filtericon} style={webStyle.FilterDiv as React.CSSProperties} />
              </Box>

              <Modal
                open={this.state.open}
                onClose={this.handleClose} // This handles clicking anywhere outside the modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
              >
                <div style={{ // You can style your modal here
                  position: 'absolute',
                  // Set your desired width
                  backgroundColor: 'white',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  borderRadius: '20px',
                  
                }}>
                  {/* Modal content goes here */}
                  <SortFunctionalityVendor handleSearchcity={this.handleSearchcityvendor} handleSearchstate={this.handleSearchvendor} ClearDataValues={this.ClearDataValues} handleClose={this.handleClose} statevalue={this.state.state} handleValue={this.handleValue} cityvalue={this.state.city} designationvalue={this.state.designation} toggleCheckbox={this.toggleCheckbox} selectedValues={this.state.selectedValues} toggleCheckboxdesignation={this.toggleCheckboxdesignation } selectedValuesRole={this.state.selectedValuesRole} value={this.state.value} handleChangeFun={this.handlechange} handleRadioChange={this.handleRadioChange} handleRadioChange1={this.handleRadioChange1} isBlocked={this.state.isBlocked} handleOptionChange={this.handleOptionChange} statename={this.state.statename} stateindex={this.state.stateindex} sortApiCallForAllVendors={this.sortApiCallForAllVendors}
                  />
                </div>
              </Modal>
            </div>
            <SearchInput
              id="component-outlined"
              startAdornment={
                <SearchIcon style={webStyle.searchIcon} />
              }
              placeholder={`Search from ${this.props.vendorListTotal} Vendors...`}
              value={this.state.searchValue}
              onChange={this.getSearchValue}
              fullWidth />
          </Grid>
          {
            this.props.allVendorsDataValue?.map((item: any, index: number) => {
              return <Grid item key={`${index}_dispalydata`} xs={12} sm={6} md={6} lg={4} xl={3} data-test-id={`card_parent_onclick_${item.id}`} onClick={() => this.navigateToUserDetail(item.id)}>
                <Card style={webStyle.card}>
                  <CardComponent item={item.attributes} navigation={this.props.navigation} id="" />
                  <Divider style={{ marginTop: '3%', marginBottom: '3%' }} />
                  <CardFooter navigation={this.props.navigation} id={item.id} item={{ blocked: item.attributes.blocked, joinDate: item.attributes.join_date }} handleBadgeVisibility={this.props.handleBadgeVisibility} />
                </Card>
              </Grid>
            })
          }
        </Grid>
        {this.props.vendorListTotal ?
          (<Grid container spacing={2}>
            <Grid item xs={12}>
              <Pagination data-test-id="vendor_pagination" count={Math.ceil(this.props.vendorListTotal / 9)} page={this.state.currentpage} onChange={this.handlePageChange} />
            </Grid>
          </Grid>)
          : null}
      </Container >
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const SearchInput = withStyles({
  root: {
    borderRadius: '22px',
    height: '62%',
    fontFamily: 'Urbanist',
    backgroundColor: '#ffffff',
    width: '286px',
  }
})(OutlinedInput);
export const webStyle = {
  parentGrid: {
    width: '100%'
  },
  card: {
    borderRadius: 8,
    padding: '2%',
    border: '1px solid lightgray',
    boxShadow: 'none'
  },
  productionCard: {
    borderRadius: 8,
    padding: '3%',
    border: '1px solid lightgray',
    boxShadow: 'none'
  },
  cardParentBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: '2%',
    paddingRight: '2%'
  },
  innerBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    columnGap: '7px'
  },
  badgeContenticon: {
    height: "5%",
    width: "5%"
  },
  link: {
    textDecoration: 'none'
  },
  avatar: {
    backgroundColor: "lime",
    border: "3px solid orange",
    width: 60,
    height: 60,
    marginRight: 1
  },
  nameBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start"
  },
  locParent: {
    fontWeight: 500,
    fontSize: "14px",
    fontFamily: "Urbanist",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    columnGap: "4px"
  },
  locIcon: {
    fontSize: "small"
  },
  cardContent: {
    padding: 0,
    marginBottom: 0
  },
  icon: {
    fontSize: "medium",
    fontWeight: "bold",
    marginRight: 10
  },
  box: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  vendorGrid: {
    marginTop: "2.5%",
    backgroundColor: "#F8FAFE",
    textAlign: "end",
    display: 'flex',
    gap: '10px'
  },
  FilterDiv: {
    height: "15px",

  },
  bellIconBackground: {
    width: "44px",
    height: "44px",
    backgroundColor: "#F8FAFE",
    border: "1px solid #DADADA",
    borderRadius: "22px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    marginLeft: "10px"
  },
  searchIcon: {
    color: 'lightgrey'
  },
  allVendorTextBox: {
    display: 'flex',
    flexdirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    columnGap: '0.5%',
    backgroundColor: "#F8FAFE",
    paddingTop: "4px"
  },
  homeTypo: {
    color: '#f5c530',
    fontSize: '13px',
    fontWeight: 600,
    fontFamily: 'Urbanist',
    cursor: 'pointer',
    textDecoration: 'underline'
  },
  vendorTypo: {
    color: '#17181d',
    fontWeight: 700,
    fontSize: '28px',
    fontFamily: 'Urbanist',
    lineHeight: '34px',
    letterSpacing: '0.31111112px',
  },
  vendor: {
    fontSize: '13px'
  }
};
// Customizable Area End