import React, { ChangeEvent } from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Grid,
  Typography,
  TypographyProps,
  FormControl,
  Link
} from "@material-ui/core";
import { createTheme, ThemeProvider,styled } from "@material-ui/core/styles";
import SideBar from "../../../components/src/SideBar";
import Header from "../../../components/src/Header.web";
import Footer from "../../../components/src/Footer";
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import Select from "@material-ui/core/Select";
// Customizable Area End
import Modal from '@material-ui/core/Modal';
import Pagination from '@material-ui/lab/Pagination';
import TaskListController, {Props } from "./TaskListController";
import InvoiceRequire from "../../../components/src/InvoiceRequire";
import { Filtericon, Shape } from "../../../components/src/assets";
import Filtermytask from "../../../components/src/Filtermytask";
import Sortbytask from "../../../components/src/Sortbytask";

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
const SortsubDivpInvoiceListing = styled(Box)({
  display: 'flex',
  gap: "10px",
  alignItems: "center",
  flexDirection: 'column'
});
const SortDivInvoiceListing = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  position: 'relative',
  alignItems: "center",
  paddingInline: "24px",
  paddingTop: "24px",
  "@media(max-width: 600px)": {
      gridColumnGap: '0px',
      display: 'block'
  },
});
const SearchInputInvoiceListing = styled('input')({
  fontSize: "15px",
  outline: "none",
  border: "none",
  width: "85%",
  padding: "10px 3px",
  backgroundColor: "#F8FAFE",
  "@media(max-width: 500px)": {
      fontSize: '12px',
      overflow: 'hidden',
      width: "70%",
      padding: "10px 0px",
  },
});

const SearchIconInvoiceListing = styled(SearchOutlinedIcon)({
  padding: "10px 0 10px 13px",
  "@media(max-width: 500px)": {
      padding: "10px 0 10px 8px",
      fontSize: '18px'
  },
});
const SelectBox1 = styled(Select)({
  padding: '5px 15px',
  borderRadius: '25px',
  border: '1px solid #e3eaf4',
  background: 'white',
  zIndex: 1,
  marginTop: 0,
  "& :focus": {
      background: 'white',
  },


});
const SortSubDiv1 = styled(FormControl)({

    marginRight: "10px",
    '& .MuiSelect-select.MuiSelect-selectMenu': {
        paddingLeft: 10,
        color: "#17181d",
        fontFamily: "Urbanist",
        fontSize: "14px",
        fontWeight: 600,
        letterSpacing: "0.25454545px",
    },
    "& .MuiInput-underline::after": {
        borderBottom: 'unset'
    },
    "@media(max-width: 500px)": {
        marginRight: "0px",
    },
    "& .MuiInput-underline::before": {
        content: 'none'
    },
    "& .MuiInputLabel-animated.Mui-focused": {
        transform: 'unset',
        color: 'transparent'
    },
    "& .MuiSelect-icon": {
        marginRight: 10
    },
});
// Customizable Area End


export default class InvoiceListing extends TaskListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  webrenderMainContentInvoiceListing(sideBarStatus: boolean = this.state.isSideBarOpen) {


    return (
        <>
            <Grid style={webStyle.contentSetupInvoiceListing}>
                <Header sideBarStatus={sideBarStatus}
                dropDownStatus={this.state.isDropdownOpen}
                handleSideBar={this.handleSideBar}
                handleDropdown={this.handleDropdown}
                navigate={this.props.navigation}/>

                <Grid style={webStyle.mainContentInvoiceListing as React.CSSProperties}>

                    <SortDivInvoiceListing>
                        <Box>
                            <Typography variant="h3" style={webStyle.activeBlockInvoiceListing as TypographyProps}>My Tasks</Typography>
                            <Typography variant="h6" style={webStyle.blockE2EPath as TypographyProps}>
                <span style={webStyle.highlightedTitleext}>
                  <Link data-test-id="homeLink" color="inherit" onClick={() => this.handleNavigation("JobProfile")}>
                    Home
                  </Link>
                </span>
                <span style={webStyle.highlightedTitleextinvoice}>
                  <Link data-test-id="invoicelist" color="inherit" style={{  color: "#6D89AF"}} onClick={() => this.handleNavigation("TaskList")}>
                    {" "}/ Invoices
                  </Link>
                </span>
            
              </Typography>
                        </Box>
                        <SortsubDivpInvoiceListing>
                            <Box style={{ display: 'flex' }}>
                            <SortSubDiv1>
                                        <Box style={{ display: 'flex' }}>
                                            <div>
                                                <Box style={webStyle.bellIconBackground} onClick={this.handleOpen} >
                                                    <img src={Shape} style={webStyle.FilterDiv as React.CSSProperties} />
                                                </Box>
                                               
                                            </div>

                                            <div>
                                                <Box style={webStyle.bellIconBackground} onClick={this.handleOpen1}>
                                                    <img src={Filtericon} style={webStyle.FilterDiv as React.CSSProperties} />
                                                </Box>
                                            </div>
                                        </Box>

                                    </SortSubDiv1>
                                
                                <Box sx={webStyle.headerSearchBoxdropdownInvoiceListing}>
                                    <SearchIconInvoiceListing style={webStyle.searchiconInvoiceListing} />
                                    <SearchInputInvoiceListing placeholder={`Search  Invoice`}
                                        style={{
                                            fontSize: "14px", fontWeight: 500,
                                            fontFamily: "Urbanist", letterSpacing: "0.25px"
                                        }}
                                        onChange={(event: ChangeEvent<HTMLInputElement>) => this.filterHouses(event)}  data-testid='search' />


                                </Box>
                            </Box>
                            
                          
                        </SortsubDivpInvoiceListing>
                    </SortDivInvoiceListing>

                    <Box sx={webStyle.headerSearchBoxInvoiceListing} >
                    {this.state.invoiceDataList.length ? this.state.invoiceDataList.map((item: any, index: number) => (
                  <Grid data-test-id={`${item.id}_studiolist`} item xs={12} sm={6} md={4} key={item.id}>
                     <Box sx={webStyle.headerSearchParentBox1}>
                                            <Box sx={webStyle.InvoiceNoandDate}>
                                                <Box sx={webStyle.InvoiceNo}>
                                                    Invoice No:{item.id}
                                                </Box>
                                                <Box sx={webStyle.DateandMonth}>
                                                    {item.attributes.invoice_date}
                                                </Box>
                                            </Box>
                                            <InvoiceRequire getInvoicedetail={this.getInvoicedetail} data={item} />
                                        </Box>
                  </Grid>
                ))
                  : <Typography style={{ width: '100%', textAlign: 'center', marginTop: '30px', marginBottom: '30px', color: '#6d89af', fontFamily: 'Urbanist', fontSize: 14, fontWeight: 600 }}>No record available</Typography>
                }
                    </Box>
                    {this.state.invoiceDataList.length&&this.state.count ?
                            (<Grid container>
                                <Grid item xs={12}>
                                    <Pagination style={{ marginTop: "6px", marginBottom: "30px", justifyContent: "center", display: "flex" }} data-test-id="pagination" count={Math.ceil((this.state.count) / 9)} page={this.state.currentpage} onChange={this.handlePageChange} />
                                </Grid>
                            </Grid>)
                            : null}
                </Grid>         
                <Footer />
            </Grid >
        </>
    )
}
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
         <Container style={webStyle.webparentDivInvoiceListing}>
        {
                        this.state.isSideBarOpen ?
                            <Grid container spacing={0}>
                                <Grid item xs={3} style={webStyle.websidebarParentInvoiceListing}>
                                    <SideBar
                                        navigate={this.props.navigation.navigate}
                                        activeMenuItem={"My Tasks"}
                                        userData={{ age: 25 }}
                                        handleSideBar={this.handleSideBar} />
                                </Grid>
                                <Grid item xs={9} style={{ flexBasis: "77.75%", maxWidth: "77.75%" }} >{this.webrenderMainContentInvoiceListing()}</Grid>
                            </Grid> :
                            <Grid item xs={12}>{this.webrenderMainContentInvoiceListing()}</Grid>
                    }
        </Container>
        <Modal
                                                    open={this.state.open1}
                                                    onClose={this.handleClose} // This handles clicking anywhere outside the modal
                                                    aria-describedby="simple-modal-description"
                                                    aria-labelledby="simple-modal-title"
                                                >
                                                    <div style={{ // You can style your modal here
                                                        top: '50%',
                                                        position: 'absolute',                                              
                                                        left: '50%',
                                                        backgroundColor: 'white',
                                                        borderRadius: '20px',
                                                        transform: 'translate(-50%, -50%)',


                                                    }}>
                                            
                                                      
                                                     <Filtermytask jobroledatalist={this.state.jobroledatalist} departmentlist={this.state.departmentlist} amountdatalist={this.state.amountdatalist} handleOptionChange1={this.handleOptionChangejobrole} jobrolevalue={this.state.jobrolevalue} data={""} handleClose={this.handleClose1} raiseinvoicetype={this.state.raiseinvoicetype} handleOptionChangeamount={this.handleOptionChangeamount} handleOptionChangedepartment={this.handleOptionChangedepartment} handleOptionChangestatus={this.handleOptionChangestatus} amountvalue={this.state.amountvalue}
                                                     statusvalue={this.state.statusvalue} departmentvalue={this.state.departmentvalue} handleChangeapply={this.handleChangeapply} ClearDataValuesfilter={this.ClearDataValuesfilter}/>
                                                    </div>
                                                </Modal>

                                                <Modal
                                                    open={this.state.open}
                                                    onClose={this.handleClose} // This handles clicking anywhere outside the modal
                                                    aria-describedby="simple-modal-description"
                                                    aria-labelledby="simple-modal-title"
                                                >
                                                    <div style={{ // You can style your modal here
                                                        top: '50%',
                                                        position: 'absolute',                                              
                                                        left: '50%',
                                                        backgroundColor: 'white',
                                                        borderRadius: '20px',
                                                        transform: 'translate(-50%, -50%)',


                                                    }}>
                                            
                                                      
                                                     <Sortbytask data={""} ClearDataValues={this.ClearDataValues} countall={this.state.countall} handleClose={this.handleClose}  countApproved={this.state.countApproved} countpending={this.state.countpending} countreject={this.state.countreject} countcomplete={this.state.countcomplete} counthold={this.state.counthold} handleOptionChange={this.handleOptionChange} selectstatus={this.state.selectstatus} handleChangeapi={this.handleChange}/>
                                                    </div>
                                                </Modal>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
    highlightedTitleext: {
        fontFamily: "Urbanist",
        color: "#F5C42C",
        textDecoration: "underline",
        cursor: "pointer",
        fontWeight: 600,
      },
      highlightedTitleextinvoice:{
        fontFamily: "Urbanist",
        color: "#F5C42C",

        cursor: "pointer",
      },

headerSearchBoxdropdownInvoiceListing: {
    display: "flex",
    border: '1px solid #e3eaf4',
    minWidth: "260px",
    borderRadius: "35px",
    alignItems: "center",
    backgroundColor: "#ffffff",
    overflowX: 'hidden',
    height: "44px",
    "@media screen and (max-width: 500px)": {
        minWidth: "100px",
        width: '160px',
    },
}, bellIconBackground: {
    width: "44px",
    height: "44px",
    backgroundColor: "#F8FAFE",
    border: "1px solid #DADADA",
    borderRadius: "22px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    marginLeft: "10px",
},
webparentDivInvoiceListing: {
    padding: "0px",
    maxWidth: "none"
},
searchiconInvoiceListing: {
    marginRight: '6px',
    fill: '#6D89AF',
    fontSize: "22px"
},
contentSetupInvoiceListing: {
    borderLeft: "1px solid #E3EAF4",
},
websidebarParentInvoiceListing: {
    minHeight: "100vh",
    maxWidth: "22.25%",
},
blockE2EPath: {
    fontSize: "14px",
    letterSpacing: "1px",
    fontWeight: 500,
    color: "#6D89AF",
    lineHeight: "17px",
    paddingTop: "8px",
  },

mainContentInvoiceListing: {
    width: "100%",
    backgroundColor: "#F8FAFE",
    boxSizing: "border-box",
    minHeight:"100vh",
    // paddingRight:'24px'
},
highlightedTextInvoiceListing: {
    color: "#F5C42C",
    textDecoration: 'underline',
    textDecorationColor: "#F5C42C",
    fontWeight: 600,
    cursor:"pointer"
},
headerSearchBoxInvoiceListing: {
    display: "flex",
    justifyContent: "start",
    align: "end",
    flexDirection: "row",
    flexWrap: "wrap",
    padding: '12px',
    width:'100%',
    boxSizing: "border-box",
},
headerSearchParentBox1: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding:'12px',
},

headerSearchParentBoxInvoiceListing: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '5px',
    flexDirection: 'column',
    paddingBottom:'20px'
},
NoInvoiceListing: {
    color: '#6d89af',
    fontFamily: 'Urbanist',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '19px',
},
DateandMonthInvoiceListing: {
    color: '#6d89af',
    fontFamily: 'Urbanist',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '19px',
},
InvoiceNoandDateListing: {
    display: 'flex',
    justifyContent: 'space-between',
    //paddingRight:'10px',
    width: "322px",
    "@media(max-width: 1300px)": {
        width: '280px',
    },
    paddingBottom:'6px'
},
InvoiceNo: {
    color: '#6d89af',
    fontFamily: 'Urbanist',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '19px',
},
DateandMonth: {
    color: '#6d89af',
    fontFamily: 'Urbanist',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '19px',
},
InvoiceNoandDate: {
    display: 'flex',
    justifyContent: 'space-between',
    //width: "322px",
    width:'100%',
    paddingBottom:'6px'
},
buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
},
FilterDiv: {
    height: "15px",

},
activeBlockInvoiceListing: {
    lineHeight: "33px",
    fontSize: "28px",
    fontWeight: "700",
    letterSpacing: "1px",
    display: "flex",
    flexDirection: "row",
    align: 'center',
    gap: '12px',
},
activeBlock: {
    lineHeight: "33px",
    fontSize: "28px",
    fontWeight: "600",
    letterSpacing: "1px",
    display: "flex",
    flexDirection: "row",

    alignItems: 'center',
    gap: '12px',
    marginBottom: '8px'
},
blockPathInvoiceListing: {
    fontSize: "14px",
    letterSpacing: "1px",
    color: "#6D89AF",
    marginBottom: "5px",
    fontWeight: 500
},

};
// Customizable Area End
