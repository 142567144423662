import React from "react";
import {
    // Customizable Area Start
    Box,Typography, makeStyles,
    // Customizable Area End
} from "@material-ui/core";
import { mytaskreceipt,Arrowinvoice,workHistoryDark, workicon , dummyImage} from "./assets";

interface MyProps {
  data: any;

}

const useStyles = makeStyles (
 {  
    invoiceWrapper: {
        borderRadius: 12,
        border: '1px solid #e3eaf4',
        padding:'12px 16px 12px 12px',
        backgroundColor: '#fff',
    },

    invoiceContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap:10
    },

    invoiceInfo: {
        display: 'flex',
        alignItems: 'center',
        gap: 10,
    },

    invoiceImage: {
        height:'59px',
        width: '59px',
        borderRadius: 10,
        backgroundColor: '#e3eaf4',
        '& img': {
            height: '100%',
            width: '100%',
            borderRadius: 10,
            objectFit: 'cover',
        }
    },

    invoiceHeads:{
        '& h6':{
            fontSize: '16px',
            width:'175px'

        },
        '& p': {
            fontSize: '12px',
            color: 'rgb(109, 137, 175)',
            display: 'flex',
            alignItems: 'center',
            gap: 5,
            width:'175px'
        }
    },
    arrowIcon: {
        cursor: 'pointer',
    }
 });

export default function InviteVendorList(props:MyProps) {
    const classes = useStyles();
    const {data} =props;

  return (
    <>
        <Box className={classes.invoiceWrapper}>
            <Box className={classes.invoiceContent}>
                <Box className={classes.invoiceInfo}>
                    <Box className={classes.invoiceImage}>
                        <img src={data?.attributes?.profile_image|| dummyImage} alt="Image"/>
                    </Box>
                    <Box style={{display:'flex',alignItems:'end'}}>
                    <Box className={classes.invoiceHeads}>
                        <Typography  style={{fontFamily:'urbanist',fontSize:'16px',fontWeight:500}} variant="h6">{data?.attributes?.full_name}</Typography>
                        <Typography style={{fontSize:'14px',fontFamily:'urbanist',color:'black'}}>
                        {data?.attributes?.position}
                        </Typography>
                    
                        <Box style={{color:'#6d89af',fontSize:'12px',fontFamily:'urbanist',fontWeight:600}}>
                        {data?.attributes?.city}  {data?.attributes?.state!==null?`(${data.attributes.state})`:null}
                        </Box> 
                  
            
                    </Box>    
                 
               </Box>
                </Box>
                <Box style={{display:'flex',justifyContent:'end',paddingTop:'42px'}}>
                {data.attributes.experience!=null?<>
                <img src={workicon} alt="work year" />
                <Box style={{color:'#3389af',fontSize:'12px',fontFamily:'urbanist',paddingLeft:'4px'}}>{data.attributes.experience.replace(/^(\d+)\s*(year|years)?$/i, '$1 Yrs')}</Box></> :null}
               </Box>
               
            </Box>
           
        </Box>
    </>
  );
}
