import React from 'react';
import {
    // Customizable Area Start
    Box,
    Typography,
    styled,
    Grid,    // Customizable Area End
    TextareaAutosize,
    Avatar
} from "@material-ui/core";
// Customizable Area Start
import Modal from '@material-ui/core/Modal';
import StudioDetailRatingModelController, { Props } from './StudioDetailRatingModelController';
import { CustomButton } from '../../email-account-login/src/CustomButton';
import Rating from '@material-ui/lab/Rating';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { StyledBadge } from '../../fullteamvisibility/src/AllvendorContent.web';
import { dummyImage } from '../../../components/src/assets';
const images = require("./assets");

const StyledModal = styled(Modal)({
    position: 'fixed',
    zIndex: 1300,
    inset: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

const style = {
    width: 375,
    borderRadius: '20px',
    backgroundColor: 'white',
    boxShadow: 'none',
    height: "auto",
};

const CustomButtonBox = styled(CustomButton)({
    '& .MuiButton-label': {
        fontWeight: 600,
        fontSize: 12,
        letterSpacing: '0.34285715px',
        lineHeight: '14px',
    }
});

// Customizable Area End
export default class StudioDetailRatingModel extends StudioDetailRatingModelController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <StyledModal
                aria-describedby="unstyled-modal-description"
                open={this.props.open}
            onClose={this.props.handleClose}
                aria-labelledby="unstyled-modal-title"
            >
                <Box sx={style}>
                    <Grid item sm={12} xs={12}  md={12} lg={12}>
                        <Box style={{ display: "flex", padding: "24px 16px 21px", flexDirection: "column", alignItems: "center" }}>
                            <Typography style={{ marginBottom: "16px", color: "#17181d", fontFamily: "Urbanist", fontSize: "21px", fontWeight: 700, lineHeight: "28px", letterSpacing: "0.2px", textAlign: "center" }}>Rate & Review</Typography>
                            <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <StyledBadge
                                    overlap="circular"
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right"
                                    }}
                                    style={{ width: 65, height: 65 }}
                                    badgeContent={
                                        <img style={{ width: 24, height: 24 }} src={images.checkedIcon} />
                                    }
                                >
                                    <Avatar
                                        style={{
                                            border: "3px solid #f5c42c",
                                            width: 62,
                                            height: 62,
                                            marginRight: 1
                                        }}
                                        src={this.props.studioImage || dummyImage}
                                    >
                                    </Avatar>
                                </StyledBadge>
                            </Box>
                            <Typography style={{ marginTop: "14px", color: "#17181d", fontFamily: "Urbanist", fontSize: "16px", fontWeight: 700, lineHeight: "24px", letterSpacing: "0.2px", textAlign: "center" }}>{this.props.studioName}</Typography>
                        </Box>
                        <Box style={{ width: "calc(100% - 48px)", border: "1px solid #e3eaf4", margin: "0px 24px", height: "45px", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "13px" }}>
                            <Rating
                                name="customized-empty"
                                value={this.state.rating}
                                size="medium"
                                precision={1}
                                onChange={this.handleChangeRating}
                                emptyIcon={<StarBorderIcon htmlColor="#ffb400" style={{ width: "24px", height: "24px" } as React.CSSProperties} />}
                            />
                        </Box>
                        <Box style={{ padding: "10px 24px 8px" }}>
                            <Typography style={{ color: "#6d89af", fontFamily: "Urbanist", fontSize: "13px", fontWeight: 600, lineHeight: "18px", letterSpacing: "0.2px", marginBottom: "6px" }}>Review</Typography>
                            <TextareaAutosize
                                style={{ width: "100%", border: "1px solid #e3eaf4", borderRadius: "14px", padding: "15px 13px", height: "90px" }}
                                className="bookstudiotextarea"
                                aria-label="minimum height"
                                minRows={3}
                                placeholder="Enter your comment..."
                                value={this.state.review}
                                onChange={this.handleChangeReview}
                            />
                        </Box>
                    </Grid>
                    <Grid container style={{ padding: "16px 24px" }}>
                        <CustomButtonBox data-test-id="sign_in_button" style={{ borderRadius: "14px", height: 48, background: "#17181d", width: "343px", color: "#ffffff" } as React.CSSProperties} onClick={this.handleGiveRating}>
                            Submit
                        </CustomButtonBox>
                    </Grid>
                </Box>
            </StyledModal>
        )
        // Customizable Area End
    }
}