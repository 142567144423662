import React, { useState } from 'react';
import { Box, Grid, List, ListItem, ListItemText, Divider, Radio, Button, FormControlLabel, styled, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { Arrowinvoice, MaskCopy, MytaskProfile, Requesticon, arrowinvoice } from './assets';
interface MyProps {
  data:any;
  handleNavigationRequestProfile:any
  
    
}




const useStyles = makeStyles({
    ParentMainRequestContent:{
      //backgroundColor:'#ffffff'
      width: '324px',
      height: '124px',
      borderRadius: '12px',
      border: '1px solid #e3eaf4',
      background: '#ffffff',
    },
    headingandCloseicon:{
      paddingTop:'20px',
      display:'flex',
      justifyContent:'space-between',
      paddingRight:'20px',
      paddingBottom:'20px'
    },
    heading:{
        color: '#17181d',
  fontFamily: 'Urbanist',
  fontSize: '20px',
  fontWeight: 700,
  textAlign: 'center',
  letterSpacing: '0.2px'
    },
    closeValue:{
        width: '24px',
  height: '24px',
 
    },
    StudioDetailandProfile:{
      paddingLeft:'20px',
        width: '672px',
  height: '80px',
  borderRadius: '20px',
  backgroundColor: '#f8fafe',
  display:'flex',
  justifyContent:'space-between',
  alignItems:'center'
    },
    Profilediv:{

        width: '36px',
  height:' 36px',
  borderRadius: '18px',
  
    },
    Requesticon:{

    },
    Requesticonandvalue:{
        width: '47px',
  height: '23px',
  borderRadius: '12px',
  background: '#3389ff',
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  gap:'3px',
  marginRight:'20px'
    },
    ProfileandName:{
        display:'flex',
        gap:'10px',
        paddingLeft:'20px',
        justifyContent:'center',
        alignItems:'center'
    },
    Requestvalue:{
        color: '#ffffff',
  fontFamily:' Urbanist',
  fontSize: '12px',
  fontWeight: 600,

  letterSpacing: '0.21818182px'
    },
    namerequest:{
        color: '#17181d',
  fontFamily: 'Urbanist',
  fontSize: '16px',
  fontWeight: 700,
  letterSpacing: '0.2px'
    },
    addressrequest:{
        color: '#6d89af',
  fontFamily: 'Urbanist',
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '19px',

    },
    ProfileNameAddressBox:{

        display:'flex',
        gap:'5px'
    },
    ProfileNameAddressArrowBox:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        paddingTop:'14px',
        paddingLeft:'12px',
        paddingRight:'12px',
        paddingBottom:'11px',
        cursor:"pointer"

    },
    Profile:{

    },
    NameandAddress:{

    },
    Name:{
        color: '#17181d',
  fontFamily: 'Urbanist',
  fontSize: '13px',
  fontWeight: 600,
  lineHeight: '19px',
  letterSpacing: '0.2px'

    },
    Address:{
        color: '#17181d',
  fontFamily: 'Urbanist',
  fontSize: '11px',
  fontWeight: 500,
  lineHeight: '16px',
  //textAlign: 'center',
  letterSpacing: '0.2px'

    },
    ArrowBox:{

        width: '26px',
        height: '26px',
        borderRadius: '15px',
        background: '#e1e6ec',
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    },
    ArrowBoximage:{
        
    },
    LowersubTask:{
        width: '324px',
  height: '64px',
  border: '1px solid #e3eaf4',
  background: '#f8fafe',
  borderRadius:'12px',
  display:'flex',
  justifyContent:'space-between'
    },
    startingdateandtime:{
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        paddingLeft:'12px',
    
    },
    opentime:{
        color:' #6d89af',
      fontFamily: 'Urbanist',
      fontSize: '10px',
      fontWeight: 500,
      letterSpacing: '0.2px'
    },
    daysandmoney:{
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center'
    },
    daydiv:{
        width: '58px',
        height: '24px',
        borderRadius: '13px',
        background: '#17181d',
        color: '#ffffff',
  fontFamily: 'Urbanist',
  fontSize: '10px',
  fontWeight: 700,
  letterSpacing: '0.16666667px',
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  marginBottom:'4px'
    },
    moneydiv:{
        color: '#42a200',
        fontFamily: "Helvetica-Bold",
        fontSize: '12px',
        fontWeight: 400,
        textAlign: 'center',
        letterSpacing: '0.2px',
    },
    enddataandtime:{
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        paddingRight:'12px'
    },
    enddate:{
        color: "#17181d",
        fontFamily: 'Urbanist',
        fontSize: '12px',
        fontWeight: 600,
        textAlign: 'right',
        letterSpacing:' 0.2px',
        paddingBottom:'4px',
    },
    enddatetime:{
        color: '#6d89af',
      fontFamily: 'Urbanist',
      fontSize: '10px',
      fontWeight: 500,
      textAlign: 'right',
      letterSpacing: '0.2px'
    
    },
    LeftDiv:{
        //position: 'absolute',
        width: '45px',
        border: '1px dashed rgb(225, 230, 236)',
        //top: '346px',
        // left: '429px',
        height:'0px',
        marginTop:'10px',
       display:'flex'
    },
    RightDiv:{
        width: '45px',
        border: '1px dashed rgb(225, 230, 236)',
        //top: '346px',
        // left: '429px',
        height:'0px',
        marginTop:'10px'
    },
    
    rectangle1:{
        //position: 'absolute',
        //left: '-2px',
        //top: '-7px',
        width: '15px',
        height: '15px',
        borderRadius: '6px',
        background: '#fff2c8',
        display:'flex',
    
        justifyContent:'center',
        alignItems:'center',
        marginTop:'-7px'
    }
    ,
    rectangle2:{
        //position: 'absolute',
        //right: '-2px',
        //top: '-7px',
        width: '15px',
        height: '15px',
        borderRadius: '6px',
        background: '#fff2c8',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        position: 'absolute',
        top:4,
        right: 0
    },
    smlrectangle1:{
        //position: 'absolute',
        //left: '4px',
        top: '4px',
        width: '8px',
        height: '8px',
        borderRadius: '26px',
        background: '#f5c42c',
    },
    
    smlrectangle2:{
        //position: 'absolute',
        //right: '4px',
        //top: '4px',
        width: '8px',
        height: '8px',
        borderRadius: '26px',
        background: '#f5c42c',
    }
    
});



export default function Filtermytask(props: MyProps) {
    const { data,handleNavigationRequestProfile} = props;
    const classes = useStyles();
    const formatDate = (dateString:any) => {
        const options = { day: 'numeric', month: 'short', year: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', options as any);
      };
   

    return (
        <Box className={classes.ParentMainRequestContent}>
        <Box className={classes.ProfileNameAddressArrowBox}>
            <Box className={classes.ProfileNameAddressBox}>
                <Box className={classes.Profile}>
                <img src= {data?.attributes?.vendor?.data?.attributes?.profile_image} className={classes.Profilediv}/>
                </Box>
                <Box className={classes.NameandAddress}>
                    <Box className={classes.Name}>
                {data?.attributes?.vendor?.data?.attributes?.full_name}
                    </Box>
                    <Box className={classes.Address}>
                    {data?.attributes?.vendor?.data?.attributes?.role}
                    </Box>
                </Box>
            </Box>
            <Box className={classes.ArrowBox} onClick={()=>handleNavigationRequestProfile(data?.id,data?.attributes?.studio_id,data?.attributes?.vendor?.data?.attributes?.profile?.data?.id,data?.attributes?.status)}>
            <img src={arrowinvoice} className={classes.ArrowBoximage}/>
            </Box>
        </Box>
        {
                    data?.attributes?.booking_type==="hour_wise"?(
                        <Box className={classes.LowersubTask}>
                        <Box className={classes.startingdateandtime}>
                            <Box style={{
                                color: '#17181d',
                                fontFamily: 'Urbanist',
                                fontSize: '12px',
                                fontWeight: 600,
                                textAlign: 'right',
                                letterSpacing: '0.2px',
                                paddingBottom:'4px'
                            }}>
                               {formatDate(data?.attributes?.date)}
                            </Box>
                            <Box className={classes.opentime} >
                            {data?.attributes?.start_time}
        
                            </Box>
                        </Box>
                        
                            
                        <Box className={classes.daysandmoney}>
                            <Box style={{display:'flex', position: 'relative'}}>
                            <Box className={classes.LeftDiv}>
                                <Box className={classes.rectangle1}>
                                    <Box className={classes.smlrectangle1}>
        
                                    </Box>
                                </Box>
        
                                {/* <Box className={classes.rectangle2}>
                                    <Box className={classes.smlrectangle2}></Box>
                                </Box> */}
                            </Box>
                            <Box className={classes.daydiv}>
                            {data?.attributes?.total_hours} HOURS
        
                            </Box>
                            <Box className={classes.RightDiv}>
                        <Box className={classes.rectangle2}>
                                    <Box className={classes.smlrectangle2}></Box>
                                </Box>
                        </Box>
                            </Box>
                            <Box className={classes.moneydiv}>
                                ₹{data?.attributes?.total_amount}
                            </Box>
        
                        
                      
                        </Box>
                        
                        <Box className={classes.enddataandtime}>
                            <Box className={classes.enddate}>
                            {formatDate(data?.attributes?.date)}
                            </Box>
                            <Box className={classes.enddatetime}>
                            {data?.attributes?.end_time}
                            </Box>
                        </Box>
        
        
                    </Box>

                    ):(
                        <Box className={classes.LowersubTask}>
                        <Box className={classes.startingdateandtime}>
                            <Box style={{
                                color: '#17181d',
                                fontFamily: 'Urbanist',
                                fontSize: '12px',
                                fontWeight: 600,
                                textAlign: 'right',
                                letterSpacing: '0.2px',
                                paddingBottom:'4px'
                            }}>
                                 {formatDate(data?.attributes?.start_date)}
                            </Box>
                            <Box className={classes.opentime} >
                            {data?.attributes?.open_time}

                            </Box>
                        </Box>
                        
                            
                        <Box className={classes.daysandmoney}>
                            <Box style={{display:'flex', position: 'relative'}}>
                            <Box className={classes.LeftDiv}>
                                <Box className={classes.rectangle1}>
                                    <Box className={classes.smlrectangle1}>
        
                                    </Box>
                                </Box>
        
                                {/* <Box className={classes.rectangle2}>
                                    <Box className={classes.smlrectangle2}></Box>
                                </Box> */}
                            </Box>
                            <Box className={classes.daydiv}>
                            {data?.attributes?.total_days} DAYS
        
                            </Box>
                            <Box className={classes.RightDiv}>
                        <Box className={classes.rectangle2}>
                                    <Box className={classes.smlrectangle2}></Box>
                                </Box>
                        </Box>
                            </Box>
                            <Box className={classes.moneydiv}>
                                ₹{data?.attributes?.total_amount}
                            </Box>
        
                        
                      
                        </Box>
                        
                        <Box className={classes.enddataandtime}>
                            <Box className={classes.enddate}>
                            {formatDate(data?.attributes?.date)}
                            </Box>
                            <Box className={classes.enddatetime}>
                            {data?.attributes?.close_time}
                            </Box>
                        </Box>
        
        
                    </Box>
                    )
                    
            }
        </Box>

    );
}
