import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ChangeEvent } from "react";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  allowAccess: boolean;
  gpsWorkTravellingList: string;
  isSpinnerShowing: boolean;
  // Customizable Area Start
  isTravelListSideBarOpen: boolean;
  isTravelListDropdownOpen: boolean;
  tabTravelListValue: number;
  liveWorkTravellingList: any[];
  travelHistoryList: any[];
  travelListSearchText: string;
  token: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class WorkTravellingListController extends BlockComponent<Props, S, SS> {
  getCityApiCallId: any;
  updateDefaultCityApiCallId: any;
  googleMapAPIId: any;
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];

    // Customizable Area Start
    this.state = {
      allowAccess: true,
      gpsWorkTravellingList: "",
      isSpinnerShowing: false,
      isTravelListSideBarOpen: true,
      isTravelListDropdownOpen: false,
      tabTravelListValue: 0,
      liveWorkTravellingList: ["a", "b", "c", "d", "f"],
      travelHistoryList: ["a", "b", "c", "d", "f", "e"],
      travelListSearchText: "",
      token: "",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    const travelTab = await getStorageData("travelTab");
    const authToken = await getStorageData("token");
    const isTravelListSideBarOpen = await getStorageData("sidebarvalue");
    this.setState({ tabTravelListValue: Number(travelTab), token: authToken, isTravelListSideBarOpen: JSON.parse(isTravelListSideBarOpen) });
  }

  handleWorkTravellingListSideBar = () => {
    setStorageData("sidebarvalue", (!this.state.isTravelListSideBarOpen).toString())
    this.setState({
      isTravelListSideBarOpen: !this.state.isTravelListSideBarOpen
    });
  };

  handleWorkTravellingListDropdown = () => {
    this.setState({
      isTravelListDropdownOpen: !this.state.isTravelListDropdownOpen
    });
  };

  handleTravelListNavigation = (page: string) => {
    this.props.navigation.navigate(page)
  };

  handleTravelListAppBarValue = (event: React.ChangeEvent<{}>, value: number) => {
    this.setState({ tabTravelListValue: value });
  }

  filterTravelList = async (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      travelListSearchText: event.target.value,
    });
  }

  handleTrackATrip = () => {
    this.handleTravelListNavigation("MapTravelling");
    setStorageData("travelTab", this.state.tabTravelListValue.toString());
  }
  // Customizable Area End
}
