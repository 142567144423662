import { Dialog, DialogTitle, Typography, Box, DialogContent, DialogActions, styled } from '@material-ui/core';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { SMSicon } from './assets';
import { CustomButton } from '../../blocks/email-account-login/src/CustomButton';

const styles = {
    modalContainer: {
        padding: '20px'
    },
    header: {
        color: ' #17181D',
        // textAlign: 'center',
        fontFamily: 'Urbanist',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: 'normal',
        letterspacing: '0.2px',
    },
    description: {
        color: '#6D89AF',
        // textAlign: 'center',
        fontFamily: 'Urbanist',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '18px',
        letterSpacing: '0.215px',
    }
}

const CustomNextStepButtonBox = styled(CustomButton)({
    '& .MuiButton-label': {
        fontSize: 14,
        color: '#ffffff',
        fontWeight: 500,
        width: 163,
        height: 48,
    }
});

interface ISMSProps {
    openSMSDialog: boolean;
    handleSMSModalToggle: () => void;
    handleSMSInvite: ()=>void;
}

const SMSModal = (props: ISMSProps) => {
    return (
        <Dialog open={props.openSMSDialog} onClose={props.handleSMSModalToggle} maxWidth='md' style={styles.modalContainer} >
            <DialogTitle>
                <Box style={{ display: "flex", justifyContent: "space-between", padding: '10px 0px' }}>
                    <Typography component='span' >{" "}</Typography >
                    <Typography component='span'>
                        <img src={SMSicon} style={{
                            height: '64px', width: '64px'
                        }} />
                    </Typography >
                    <Typography component='span' data-test-id="addDepartmentCloseBtn" onClick={props.handleSMSModalToggle}><CloseIcon /></Typography >
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                    padding: '4px 20px'
                }} >
                    <Typography style={styles.header}>Send SMS</Typography>
                    <Typography style={styles.description}>
                        The link to download the app will be share to the <br />
                        employee’s number by a text message.
                    </Typography>
                </Box>
            </DialogContent>
            <DialogActions style={{ padding: "0px 19px 24px" }}>
                <CustomNextStepButtonBox 
                data-test-id="department_button" 
                style={{ height: 48 } as React.CSSProperties} 
                className="button" 
                onClick={props.handleSMSInvite}
                >
                    Send Now
                </CustomNextStepButtonBox>
            </DialogActions>
        </Dialog>
    );
};

export default SMSModal;
