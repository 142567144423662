import React from "react";

import {
  // Customizable Area Start
  Container,
  Grid
  // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import UserDetailController, { Props } from "./UserDetailController";
import UserDetailCommon from "./UserDetailCommon.web";
import Header from "../../../components/src/Header.web";
import SideBar from "../../../components/src/SideBar";
import Footer from "../../../components/src/Footer";
// Customizable Area End

export default class UserDetail extends UserDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderMainContentAllVendorProfile(sideBarStatus: boolean) {
    return <Grid style={webStyle.mainContent}>
      < Header
        sideBarStatus={sideBarStatus}
        dropDownStatus={this.state.isDropDwnOpen}
        handleSideBar={this.handleSideDropdownOpenVal}
        handleDropdown={this.handleDropdownValue}
        navigate={this.props.navigation.navigate}
      />
      <Grid style={webStyle.mainContent as React.CSSProperties}>
        <UserDetailCommon navigation={this.props.navigation} id={""} />
      </Grid>
      <Footer />
    </Grid >
  }
  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <Container maxWidth="xl" className="user_detail_container">
        {
          this.state.isSiderBarOpenValue ?
            <Grid container spacing={0}>
              <Grid item xs={3} style={webStyle.sideBar}>
                <SideBar
                  navigate={this.props.navigation.navigate}
                  userData={{ age: 25 }}
                  activeMenuItem={"All Vendors"}
                  handleSideBar={this.handleSideDropdownOpenVal} />
              </Grid>
              <Grid item xs={9}>{this.renderMainContentAllVendorProfile(this.state.isSiderBarOpenValue)}</Grid>
            </Grid> :
            <Grid item xs={12}>
              < Header
                sideBarStatus={this.state.isSiderBarOpenValue}
                dropDownStatus={this.state.isDropDwnOpen}
                handleSideBar={this.handleSideDropdownOpenVal}
                handleDropdown={this.handleDropdownValue}
                navigate={this.props.navigation.navigate}
              />
              <UserDetailCommon navigation={this.props.navigation} id={""} />
            </Grid>
        }
      </Container >
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainContent: {
    borderLeft: "1px solid #E3EAF4",
  },
  sideBar: {
    minHeight: "100vh",
  },
  container: {
    paddingLeft: '0px !important',
    paddingRight: '0px !important'
  }
};
// Customizable Area End