import React from 'react';
import {
    // Customizable Area Start
    Box,
    Typography,
    styled,
    Grid,
    withStyles,
    TextField,
    TextareaAutosize,
    Snackbar
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import Modal from '@material-ui/core/Modal';
import BookStudioModelController, { Props } from './BookStudioModelController';
import { CustomButton } from '../../email-account-login/src/CustomButton';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    KeyboardTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import CustomNotification from '../../../components/src/CustomNotification';
const images = require("./assets");

const StyledModal = styled(Modal)({
    position: 'fixed',
    zIndex: 1300,
    inset: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

const style = {
    width: 596,
    borderRadius: '20px',
    backgroundColor: 'white',
    boxShadow: 'none',
    // maxHeight: "90%",
    // overflowY: "auto",
    // height: 192,
};

const CustomButtonBox = styled(CustomButton)({
    '& .MuiButton-label': {
        fontWeight: 600,
        fontSize: 16,
        letterSpacing: '0.45714286px',
        lineHeight: '24px',
    }
});

const CustomCSSOfTextField = withStyles({
    root: {
        backgroundColor: '#ffffff',
        borderColor: "#e3eaf4",
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#e3eaf4"
            },
            "& fieldset:hover": {
                borderColor: "#e3eaf4"
            },
            "& fieldset:focus": {
                borderColor: "#e3eaf4"
            },
            fontWeight: 400,
            color: '#6d89af',
            borderRadius: "14px",
            "& .MuiOutlinedInput-input": {
                fontWeight: 600,
                color: '#17181d',
                lineHeight: '18px',
                borderColor: '#e3eaf4',
                fontSize: '14px',
                fontFamily: "Urbanist",
            },
            "& .MuiOutlinedInput-input:hover": {
                borderColor: '#e3eaf4',
            }
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "none"
        },
        "& .MuiInputBase-input::placeholder": {
            color: '#6d89af',
            fontWeight: 500,
            fontFamily: 'Urbanist',
        },
    }
})(TextField);

// Customizable Area End
export default class BookStudioModel extends BookStudioModelController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (<>
            <StyledModal
                aria-labelledby="unstyled-modal-title"
                aria-describedby="unstyled-modal-description"
                open={this.props.open}
                onClose={this.props.handleClose}
            >
                <Box sx={style}>
                    <Grid container style={{ padding: "20px 24px 24px", color: '#17181d', fontFamily: 'Urbanist', fontSize: 20, fontWeight: 700, textAlign: 'end', letterSpacing: '0.2px' } as React.CSSProperties}>
                        <Grid item style={{ textAlign: "center", paddingLeft: "115px", boxSizing: "border-box" }} xs={9}>
                            Book a Studio
                        </Grid>
                        <Grid item xs={3}>
                            <img src={`${images.crossIcon}`} style={{ cursor: 'pointer' }} onClick={this.props.handleClose} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box style={{ padding: "0px 24px" }}>
                            <Typography style={{ color: "#17181d", fontFamily: "Urbanist", fontSize: "13px", fontWeight: 600, lineHeight: "18px", letterSpacing: "0.2px", textAlign: "center" }}>Select as per</Typography>
                            <Grid container style={{ marginTop: "6px", width: "100%", height: "58px", display: "flex", flexDirection: "column" }}>
                                <Grid data-test-id="dayWiseBookStudio" onClick={() => this.handleChangeStudioBookType("day_wise")} item sm={6} md={6} lg={6} style={{ cursor: "pointer", width: "266px", padding: "12px 0px 8px", textAlign: "center", height: "58px", marginRight: "16px", borderRadius: "10px", background: this.state.studioBookType === "day_wise" ? "rgba(245,196,44,0.07)" : "#f8fafe", border: this.state.studioBookType === "day_wise" ? "1px solid #f5c42c" : "none" }}>
                                    <div style={{ color: this.state.studioBookType === "day_wise" ? "#f5c42c" : "#6d89af", fontFamily: "Helvetica-Bold", fontSize: "18px", fontWeight: 400, lineHeight: "18px", letterSpacing: "0.25714287px" }}>₹{this.props.pricePerDay}</div>
                                    <Typography style={{ marginTop: "4px", color: this.state.studioBookType === "day_wise" ? "#17181d" : "#6d89af", fontFamily: "Urbanist", fontSize: "13px", fontWeight: 600, lineHeight: "16px", letterSpacing: "0.2px" }}>Per Day</Typography>
                                </Grid>
                                <Grid data-test-id="hourWiseBookStudio" onClick={() => this.handleChangeStudioBookType("hour_wise")} item sm={6} md={6} lg={6} style={{ cursor: "pointer", width: "266px", padding: "12px 0px 8px", textAlign: "center", height: "58px", borderRadius: "10px", background: this.state.studioBookType === "hour_wise" ? "rgba(245,196,44,0.07)" : "#f8fafe", border: this.state.studioBookType === "hour_wise" ? "1px solid #f5c42c" : "none" }}>
                                    <div style={{ color: this.state.studioBookType === "hour_wise" ? "#f5c42c" : "#6d89af", fontFamily: "Helvetica-Bold", fontSize: "18px", fontWeight: 400, lineHeight: "18px", letterSpacing: "0.25714287px" }}>₹{this.props.pricePerHour}</div>
                                    <Typography style={{ marginTop: "4px", color: this.state.studioBookType === "hour_wise" ? "#17181d" : "#6d89af", fontFamily: "Urbanist", fontSize: "13px", fontWeight: 600, lineHeight: "16px", letterSpacing: "0.2px" }}>Per Hour</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box style={{ padding: "16px 24px 0px" }}>
                            <Typography style={{ color: "#17181d", fontFamily: "Urbanist", fontSize: "13px", fontWeight: 600, lineHeight: "18px", letterSpacing: "0.2px", textAlign: "center" }}>Date of Booking</Typography>
                            {this.state.studioBookType === "day_wise" ? <Box style={{ border: "1px solid #e3eaf4", borderRadius: "14px", marginTop: "6px", width: "100%", height: "118px", padding: "10px 16px", boxSizing: "border-box" }}>
                                <Grid container style={{ width: "100%", display: "flex", flexWrap: "nowrap" }}>
                                    <Grid item sm={6} md={6} lg={6} style={{ marginRight: "14px" }}>
                                        <Typography style={{ color: "#6d89af", fontFamily: "Urbanist", fontSize: "12px", fontWeight: 600, lineHeight: "18px", letterSpacing: "0.18461539px", marginBottom: "2px" }}>Start date</Typography>
                                        <Box>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    data-test-id="startDateOfDayWiseBooking"
                                                    placeholder="DD/MM/YY"
                                                    format={"dd/MM/yy"}
                                                    className="studioFilterStartTime"
                                                    style={{ width: "250px", boxSizing: "border-box", height: 44, border: "1px solid #e3eaf4", borderRadius: "12px", paddingLeft: "13px", justifyContent: "center", background: "#f8fafe" } as React.CSSProperties}
                                                    minDate={new Date()}
                                                    value={this.state.startDate}
                                                    onChange={this.handleSelectStartDate}
                                                    animateYearScrolling
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Box>
                                    </Grid>
                                    <Grid item sm={6} md={6} lg={6} style={{}}>
                                        <Typography style={{ color: "#6d89af", fontFamily: "Urbanist", fontSize: "12px", fontWeight: 600, lineHeight: "18px", letterSpacing: "0.18461539px", marginBottom: "2px" }}>End date</Typography>
                                        <Box>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    data-test-id="endDateOfDayWiseBooking"
                                                    placeholder="DD/MM/YY"
                                                    format={"dd/MM/yy"}
                                                    className="studioFilterStartTime"
                                                    style={{ width: "250px", boxSizing: "border-box", height: 44, border: "1px solid #e3eaf4", borderRadius: "12px", paddingLeft: "13px", justifyContent: "center", background: "#f8fafe" } as React.CSSProperties}
                                                    minDate={this.state.startDate}
                                                    value={this.state.endDate}
                                                    onChange={this.handleSelectEndDate}
                                                    animateYearScrolling
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Box style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                    <Box style={{ width: "282px", height: "25px", border: "1px solid #e1e6ec", borderTop: "none", borderRadius: "0 0 14px 14px", display: "flex", justifyContent: "center" }}>
                                        <Box style={{ width: "72px", height: "26px", borderRadius: "13px", background: "#f5c42c", justifyContent: "center", display: "flex", alignItems: "center", lineHeight: "14px", color: "#ffffff", fontFamily: "Urbanist", fontSize: "12px", fontWeight: 700, textAlign: "center", letterSpacing: "0.21818182px", position: "relative", top: "12px" }}>{this.state.diffOfDate > 1 ? `${this.state.diffOfDate} DAYS` : `${this.state.diffOfDate} DAY`}</Box>
                                    </Box>
                                </Box>
                            </Box> :
                                <Box style={{ border: "1px solid #e3eaf4", borderRadius: "14px", marginTop: "6px", width: "100%", height: "210px", padding: "16px", boxSizing: "border-box" }}>
                                    <Grid container>
                                        <Typography style={{ color: "#6d89af", fontFamily: "Urbanist", fontSize: "12px", fontWeight: 600, lineHeight: "18px", letterSpacing: "0.18461539px", marginBottom: "2px" }}>Select Date</Typography>
                                        <Box>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    placeholder="DD/MM/YY"
                                                    data-test-id="dateOfHourWiseBooking"
                                                    className="studioFilterStartTime"
                                                    format={"dd/MM/yy"}
                                                    minDate={new Date()}
                                                    style={{ width: "516px", boxSizing: "border-box", height: 44, border: "1px solid #e3eaf4", borderRadius: "12px", paddingLeft: "13px", justifyContent: "center", background: "#f8fafe" } as React.CSSProperties}
                                                    onChange={this.handleSelectStartDate}
                                                    animateYearScrolling
                                                    value={this.state.startDate}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Box>
                                    </Grid>
                                    <Grid container style={{ marginTop: "16px", width: "100%", display: "flex", flexWrap: "nowrap" }}>
                                        <Grid item sm={6} md={6} lg={6} style={{ marginRight: "14px" }}>
                                            <Typography style={{ color: "#6d89af", fontFamily: "Urbanist", fontSize: "12px", fontWeight: 600, lineHeight: "18px", letterSpacing: "0.18461539px", marginBottom: "2px" }}>Time from</Typography>
                                            <Box>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardTimePicker
                                                        data-test-id="startTimeOfHourWiseBooking"
                                                        placeholder="Select Time"
                                                        format={"hh:00 a"}
                                                        className="bookStudioTimer"
                                                        style={{ width: "250px", boxSizing: "border-box", height: 44, border: "1px solid #e3eaf4", borderRadius: "12px", paddingLeft: "13px", justifyContent: "center", background: "#f8fafe" } as React.CSSProperties}
                                                        value={this.state.startTime}
                                                        views={['hours']}
                                                        onChange={this.handleSelectStartTime}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Box>
                                        </Grid>
                                        <Grid item sm={6} md={6} lg={6} style={{}}>
                                            <Typography style={{ color: "#6d89af", fontFamily: "Urbanist", fontSize: "12px", fontWeight: 600, lineHeight: "18px", letterSpacing: "0.18461539px", marginBottom: "2px" }}>Time to</Typography>
                                            <Box>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardTimePicker
                                                        data-test-id="endTimeOfHourWiseBooking"
                                                        placeholder="Select Time" format={"hh:00 a"}
                                                        className="bookStudioTimer"
                                                        style={{ width: "250px", boxSizing: "border-box", height: 44, border: "1px solid #e3eaf4", borderRadius: "12px", paddingLeft: "13px", justifyContent: "center", background: "#f8fafe" } as React.CSSProperties}
                                                        value={this.state.endTime}
                                                        views={['hours']}
                                                        disabled={!this.state.startTime}
                                                        onChange={this.handleSelectEndTime}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Box style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                        <Box style={{ width: "282px", height: "25px", border: "1px solid #e1e6ec", borderTop: "none", borderRadius: "0 0 14px 14px", display: "flex", justifyContent: "center" }}>
                                            <Box style={{ width: "72px", height: "26px", borderRadius: "13px", background: "#f5c42c", justifyContent: "center", display: "flex", alignItems: "center", lineHeight: "14px", color: "#ffffff", fontFamily: "Urbanist", fontSize: "12px", fontWeight: 700, textAlign: "center", letterSpacing: "0.21818182px", position: "relative", top: "12px" }}>{this.state.diffOfDate > 1 ? `${this.state.diffOfDate} HOURS` : `${this.state.diffOfDate} HOUR`}</Box>
                                        </Box>
                                    </Box>
                                </Box>
                            }
                        </Box>
                        <Box style={{ padding: "16px 24px 0px" }}>
                            <Typography style={{ color: "#6d89af", fontFamily: "Urbanist", fontSize: "13px", fontWeight: 600, lineHeight: "18px", letterSpacing: "0.2px", marginBottom: "6px" }}>Project Name</Typography>
                            <CustomCSSOfTextField data-test-id="addressOfPHInput" type={'text'} id="outlined-basic" variant="outlined" placeholder="Enter project name"
                                value={this.state.projectName}
                                onChange={this.handleChangeProjectName}
                                className="email_input bookstudioinput"
                            />
                        </Box>
                        <Box style={{ padding: "16px 24px 8px" }}>
                            <Typography style={{ color: "#6d89af", fontFamily: "Urbanist", fontSize: "13px", fontWeight: 600, lineHeight: "18px", letterSpacing: "0.2px", marginBottom: "6px" }}>Reason for booking</Typography>
                            <TextareaAutosize
                                style={{ width: "100%", border: "1px solid #e3eaf4", borderRadius: "14px", padding: "15px 13px", height: "90px" }}
                                className="bookstudiotextarea"
                                aria-label="minimum height"
                                minRows={3}
                                placeholder="Enter details"
                                value={this.state.reasonForBooking}
                                onChange={this.handleChangeReasonForBooking}
                            />
                        </Box>
                    </Grid>
                    <Grid container style={{ padding: "24px", justifyContent: "space-between" }}>
                        <Grid item style={{ display: "flex", alignItems: "center" }}>
                            <Box style={{ display: "flex", flexDirection: "column", alignItems: "center", borderRight: "1px solid #e3eaf4", height: "39px", paddingRight: "17px", marginRight: "14.5px" }}>
                                <Typography style={{ color: "#6d89af", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 600, letterSpacing: "0.2px" }}>Total Amount for:</Typography>
                                <Typography style={{ color: "#17181d", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 600, letterSpacing: "0.2px" }}>{this.getTotalAmountForText()}</Typography>
                            </Box>
                            <div style={{ lineHeight: "24px", color: "#42a200", fontFamily: "Helvetica-Bold", fontSize: "20px", fontWeight: 400, letterSpacing: "0.2px" }}>₹{this.state.diffOfDate * (this.state.studioBookType === "day_wise" ? this.props.pricePerDay : this.props.pricePerHour)}</div>
                        </Grid>
                        <Grid item>
                            <CustomButtonBox data-test-id="sign_in_button" style={{ borderRadius: "14px", height: 48, background: "#17181d", width: "201px", color: "#ffffff" } as React.CSSProperties} onClick={this.handleSendBookingRequest}>
                                Send Booking Request
                            </CustomButtonBox>
                        </Grid>
                    </Grid>
                </Box>
            </StyledModal>
            <CustomNotification navigation={(route: string) => this.props.navigation.navigate(route)} notification={this.state.notification} handleCloseNotification={this.handleCloseNotification} />
        </>
        )
        // Customizable Area End
    }
}