import React, { useState } from 'react';
import { Box, Grid, List, ListItem, ListItemText, Divider, Radio, Button, FormControlLabel, styled, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { MytaskProfile, Requesticon } from './assets';
import RequestCardContent from './RequestCardContent'
interface MyProps {
  data:any;
  handleClose:any;
  count:any;
  city:any;
  address:any;
  state:any;
  handleNavigationRequestProfile:any
    
}




const useStyles = makeStyles({
    ParentMain:{
      //backgroundColor:'#ffffff'
    },
    headingandCloseicon:{
      paddingTop:'20px',
      display:'flex',
      justifyContent:'space-between',
      paddingRight:'20px',
      paddingBottom:'20px'
    },
    heading:{
        color: '#17181d',
  fontFamily: 'Urbanist',
  fontSize: '20px',
  fontWeight: 700,
  textAlign: 'center',
  letterSpacing: '0.2px'
    },
    closeValue:{
        width: '24px',
  height: '24px',
 
    },
    StudioDetailandProfile:{
      paddingLeft:'20px',
        width: '672px',
  height: '80px',
  borderRadius: '20px',
  backgroundColor: '#f8fafe',
  display:'flex',
  justifyContent:'space-between',
  alignItems:'center'
    },
    Profilediv:{

        width: '48px',
  height:' 48px',
  borderRadius: '12px',
  boxShadow: '0px 7px 18px rgba(24,39,42,0.07)'
    },
    Requesticon:{

    },
    Requesticonandvalue:{
        width: '47px',
  height: '23px',
  borderRadius: '12px',
  background: '#3389ff',
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  gap:'3px',
  marginRight:'20px'
    },
    ProfileandName:{
        display:'flex',
        gap:'10px',
        paddingLeft:'20px',
        justifyContent:'center',
        alignItems:'center'
    },
    Requestvalue:{
        color: '#ffffff',
  fontFamily:' Urbanist',
  fontSize: '12px',
  fontWeight: 600,

  letterSpacing: '0.21818182px'
    },
    namerequest:{
        color: '#17181d',
  fontFamily: 'Urbanist',
  fontSize: '16px',
  fontWeight: 700,
  letterSpacing: '0.2px'
    },
    addressrequest:{
        color: '#6d89af',
  fontFamily: 'Urbanist',
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '19px',

    },
    Timeanddaterequest:{
        color: '#6d89af',
        fontFamily: 'Urbanist',
        fontSize: '12px',
        fontWeight: 600,
        letterSpacing: '0.24px',
        paddingBottom:'5px'
      
    }

});



export default function Filtermytask(props: MyProps) {
    const { data,handleClose,count,state,city,address,handleNavigationRequestProfile} = props;
    const classes = useStyles();
   

    return (
        <Box className={classes.ParentMain}>
         <Box className={ classes.headingandCloseicon}>
            <Box></Box>
            <Box className={classes.heading}>Studio Requests</Box>
            <Box className={classes.closeValue}>
            <CloseIcon onClick={handleClose} style={{color:"#6d89af"}} /> 
            </Box>
         </Box>
         <Box className={classes.StudioDetailandProfile}>
            <Box className={classes.ProfileandName}>
                <Box>
                <img src={data[0]?.attributes?.studio?.image_url} className={classes.Profilediv}/>
                </Box>
                <Box>
                    <Box className={classes.namerequest}>

                    {data[0]?.attributes?.studio?.name}
                    </Box>
                    <Box className={classes.addressrequest}>
                    {address}, {city} ({state})
                    </Box>
                </Box>
            </Box>
            <Box className={classes.Requesticonandvalue}>
                   <Box>
                   <img src={Requesticon} className={classes.Requesticon}/>
                   </Box>
                   <Box className={classes.Requestvalue}>{count}</Box>
            </Box>
         </Box>
         <Grid container spacing={2}  xs={12} style={{overflowY:'scroll',
        height:'180px',paddingLeft:'20px'}} >
            
            
            {data?.map((item:any,index:number) => (
        <Grid  key={`${item}_ pending_Request`} item xs={6}>
          <Grid style={{paddingBottom:'20px',paddingTop:'16px'}}>
            <Grid className={classes.Timeanddaterequest}>
            Today | 2min ago
            </Grid>
            <Grid>
                <RequestCardContent data={item} handleNavigationRequestProfile={handleNavigationRequestProfile}/>
            </Grid>
          </Grid>
        </Grid>
      ))}
            

         </Grid>
        </Box>

    );
}
