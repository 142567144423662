import React from "react";
import { Grid, Link, Box, Typography, Divider, Badge, Avatar } from '@material-ui/core';
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import { styled } from '@material-ui/core/styles';
import { RightIcon, LocationIconjob, jobpositionicon, TravelllingListicon, Travelingarrow, jobyrs, dotsIcon, JobProfileDetail, DateIconView, checkedImage, doticon, raiseinvoice, balancesheet, leaveproject, iconC, Balancesheeticon, iconjobdetail, dayicon, LocationIcon, editstudio, studiodelete, Allemployementimage, AllDepartment, employeerequest , dummyImage } from "./assets";
import Rating from '@material-ui/lab/Rating';
import LinearProgress from '@material-ui/core/LinearProgress';
import VendorReviewersComponent from "./VendorReviewers";
import ReviewerComponent from "./Reviewer";
import VendorReviewerComponent from "./VendorReviewers";
import Modal from '@material-ui/core/Modal';
import RateNowCard from "./RateNowCard";
import { ActivityIndicator } from "react-native";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreHoriz";
import StarBorderIcon from '@material-ui/icons/StarBorder';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
interface MyProps {
    data: any;
    deleteProjectPH:any;
    jobRoleNavigation:any;
    handleArrowRightClick:any;
    handleArrowRightClickDepartment:any;
    navigateToallinvoiceRequest:any
    EditProject:any;
}
const useStyles = makeStyles({
    jobiconyear: () => ({}),
    LocationIcon: () => ({}),
    ParentDiv: () => ({
        // height: '836px'

    }),
    icon: {},
    HeadingParentDiv: () => ({
        display: "flex",
        justifyContent: 'space-between',
        //paddingRight:'24px',
        "@media screen and (max-width: 500px)": {

            display: 'flex',
            flexDirection: 'column',
            gridRowGap: '10px'
        }

    }),
    Headingname: () => ({
        display: 'flex',
        flexDirection: 'column'
    }),
    positionDiv: () => ({
        display: 'flex',
        gap: '5px',
        alignItems: 'center',
        borderRadius: '18px',
        border: '1px solid',
        borderColor: ' #e3eaf4',
        backgroundColor: '#ffffff',
        width: "150px",
        height: '36px',


    }),
    DeptDiv: () => ({
        fontSize: '16px',
        color: '#6f7c8e',
        fontFamily: 'urbanist',
        fontWeight: 600,
        paddingBottom: '12px'

    }),
    expDiv: () => ({
        fontSize: '16px',
        color: '#6d89af',
        fontWeight: 600,
        lineHeight: '19px',
        fontFamily: 'urbanist'
    }),
    Headingaddress: () => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'end'
    }),
    Positiontext: () => ({
        fontSize: '24px',
        color: '17181D',
        fontFamily: 'urbanist',
        fontWeight: 700,
        lineHeight: '19px',
        paddingBottom: '10px'

    }),
    positionText: () => ({
        color: ' #17181d',
        fontFamily: ' Urbanist',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '16px',
    }),
    CityandStateDiv: () => ({
        display: 'flex',
        paddingTop: '10px',
        alignItems: 'center',
        gap: '5px',
        paddingBottom: '10px'
    }),
    CityandState: () => ({
        fontFamily: 'urbanist',
        fontWeight: 600,
        fontSize: '16px',
        color: '17181D'
    }),
    DayDiv: () => ({
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
        justifyContent: "end",
    }),
    DayText: () => ({
        fontFamily: 'urbanist',
        fontWeight: 600,
        fontSize: '16px',
        color: '17181D'
    }),
    expicon: () => ({
        height: "16px",
        width: '16px',

    }),
    expandrupeesdiv: () => ({
        display: 'flex',
        gap: '18px'
    }),
    experience: () => ({
        display: 'flex',
        gap: '5px',
        alignItems: 'center'
    }),
    rupees: () => ({
        display: 'flex',
        gap: '5px',
        alignItems: 'center'
    })
    ,
    rupeesDiv: () => ({
        color: '#6d89af',
        fontFamily: 'Helvetica',
        fontSize: ' 16px',
        fontWeight: 400,
        lineHeight: '19px',
    }),
    Liveicon: {},
    arrowicon: {
        cursor:'pointer'
    },
    iconposition: {
        height: '20px',
        width: '20px',
        paddingLeft: '24px',
        fontSize: '18px'
    },
    doticon1: {},
    arrowLive: {
        height: '14px', width: '14px'
    },
    submitButton: {
        cursor: "pointer",
        color: "#ffffff",
        backgroundColor: "#17181D",
        textTransform: "none",
        width: "143px",
        border: "none",
        borderRadius: "14px",
        //position: "absolute",
        bottom: "16px",
        height: "48px",
        fontWeight: 600,
        fontSize: "12px",
        paddingTop: "10px",
        paddingBottom: "14px",
        fontFamily: "urbanist",
    },
    ProfileDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        //maxWidth: '690px',
        width: '400px',

        "@media screen and (max-width: 500px)": {

            display: 'flex',
            flexDirection: 'column',
            gridRowGap: '20px'
        },
        "@media screen and (max-width: 1490px)": {
            display: 'flex',
            justifyContent: 'space-between',
            width: '400px',
            //maxWidth: '540px',
        }
    },
    ProfileImageAndOtherInformation: {
        display: 'flex',
        gap: '16px'
    },
    OtherInformation: {},
    ProfileImage: {},
    HeadingText: {
        color: '#17181d',
        fontFamily: 'Urbanist',
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: "19px",
        paddingRight: "19px",
    },
    HeadingInformation: {
        display: 'flex',
        paddingBottom: '6px',
        alignItems: "baseline",
    },
    OnGoingText: {
        background: '#F5C42C',
        color: '#ffffff',
        fontWeight: 700,
        fontSize: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: "12px",
        letterSpacing: "0.18181819px",
        fontFamily: "Urbanist",
        padding: "5px 10px",
        width:'68px'
    },
    allPictureLink: {
        paddingLeft: "2px",
        color: '#3389FF',
        textDecoration: 'underline',
        textDecorationColor: '#3389FF',
        display: '-webkit-box',
        '-webkit-line-clamp': 1,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: 170,
        fontFamily: 'Urbanist',
        fontSize: '14px'
    },
    PostedByInformation: {
        color: '#6d89af',
        fontFamily: 'urbanist',
        fontSize: '14px',
        fontWeight: 600,
        display: 'flex'
    },
    dateimage: {

    },
    DateInformation: {
        display: 'flex',
        gap: '4px',
        paddingTop: '10px',
        alignItems: 'center'
    },
    BalanceandBudget: {
        display: 'flex',
        gap: '20px'
    },
    InvoiceButtonandDotIcon: {
        display: 'flex',
        //gap:'20px',
        alignItems: 'center'
    },
    InvoiceParent: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: '30px',
        flex: 1,
        alignItems: 'end',
        paddingBottom:'24px'
    },
    InvoicandTravelingList: { display: "flex", justifyContent: "space-between" },
    DateInf: {
        color: '#17181d',
        fontFamily: 'Urbanist',
        fontSize: '14px',
        fontWeight: 600
    },

    RatingandReview: {
        display: 'flex',
        flexDirection: 'column',
        width: "100%",
        //alignItems: 'first baseline'

    },
    skillDiv: {
        paddingTop: '24px',
        //maxWidth: '690px',
        paddingBottom: '16px',
        "@media screen and (max-width: 500px)": {
            maxWidth: '230px',
            alignItems: 'center',
            paddingLeft: '58px'
        },
        "@media screen and (max-width: 1400px)": {
            //maxWidth: '590px',
        }
    },
    skillValue: {
        margin: "0px",
        border: '1px solid #e3eaf4',
        borderRadius: '12px',
        paddingTop: '14px',
        paddingBottom: '14px',
        paddingLeft: '14px',
        backgroundColor: '#ffffff',
        width: "100%",
        "@media screen and (max-width: 500px)": {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: '#ffffff',
        },
        "@media screen and (max-width: 1490px)": {
            //maxWidth: '540px',
            //width:'580px'
            width: '100%',
            backgroundColor: '#ffffff',
        }
    },
    firstp: {
        display: 'flex',
        alignItems: 'center',
        gap: '5px'
    },
    heading1: {
        color: '#17181d',
        fontFamily: 'Urbanist',
        fontSize: '14px',
        fontWeight: 600,
        letterSpacing: '0.2px'
    },
    JobDescriptionHeading: {

        color: '#6d89af',
        fontFamily: 'Urbanist',
        fontSize: '12px',
        fontWeight: 600,
        lineHeight: '19px',
        paddingBottom: '8px'
    },
    aboutproductionhouse: {
        color: '#17181d',
        fontFamily: 'Urbanist',
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '16px',
        marginTop: "16px",
        "@media screen and (max-width: 500px)": {
            maxWidth: '350px',
        }
    },
    JobDescriptionText: {
        color: '#17181d',
        fontFamily: 'Urbanist',
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '16px',
        //width: '690px',
        borderRadius: '12px',
        border: '1px solid #e3eaf4',
        paddingLeft: '10px',
        paddingTop: '10px',
        paddingBottom: '10px',
        backgroundColor: '#ffffff',
        "@media screen and (max-width: 1490px)": {

            //maxWidth: '540px',

        },
        "@media (min-width >=769px) and (max-width < 1024px)": {

            //maxWidth: '540px',
            //width:'580px'

        },
        "@media screen and (max-width: 768px)": {

            maxWidth: '100%',

        },




    },
    Aboutvalue: {
        borderRadius: '12px',
        border: '1px solid #e3eaf4',
        paddingLeft: '10px',
        paddingTop: '10px',
        paddingBottom: '10px',
        backgroundColor: '#ffffff',
        //width: '690px',
        "@media screen and (max-width: 500px)": {

            maxWidth: '350px',

        },
        "@media screen and (max-width: 1600px)": {

            //maxWidth: '540px',
            //width:'580px'

        }
    },
    AiParentDiv: {
        display: 'flex',
        gap: '10px',
        alignItems: 'center'
    },
    ProductionDiv: {
        paddingTop: '24px'
    },
    AiText: {
        color: '#17181d',
        fontFamily: 'Urbanist',
        fontSize: '18px',
        fontWeight: 600,
        textAlign: 'center',
        letterSpacing: '0.225px',
        paddingBottom: '2px'
    },
    LocationDiv: {
        display: 'flex'
    },
    LocationText: {
        color: '#6d89af',
        fontFamily: 'Urbanist',
        fontSize: '14px',
        fontWeight: 500,
        letterSpacing: ' 0.23333333px'
    },
    AiPicturetext: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start'
    },
    LeftandRightDetail: {
        paddingTop: '23px',
        display: 'flex',
        "@media screen and (max-width: 500px)": {
            display: 'flex',
            flexDirection: 'column'
        }
    },
    starandrating: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    RatingandReviewHeading: {
        color: '#17181d',
        fontFamily: 'Urbanist',
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '24px',
        textAlign: 'start',
        letterSpacing: ' 0.2769231px',
        paddingBottom: '16px',
        paddingTop: '24px',
    },
    ratingheading: {
        color: '#6d89af',
        fontFamily: 'Urbanist',
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '16px',
        letterSpacing: '0.2px',
        borderBottom: '1px solid #e3eaf4',
        paddingBottom: '12.5px'
    },
    starParent: {
        border: '1px solid #e3eaf4',
        borderRadius: '12px',
        //minWidth: '343px',
        paddingTop: '12px',
        paddingInline: '12px',
        ///width:'100%',
        background: 'rgb(255, 255, 255) none repeat scroll 0% 0%'

    }
    ,
    staricon: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    RateNow: {
        color: '#f5c42c',
        fontFamily: 'Urbanist',
        fontSize: '12px',
        fontWeight: 600,
        letterSpacing: '0.18461539px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    },
    ratingstar: {

        fontSize: '10px',
        fontWeight: 500,
        lineHeight: '16px',
        color: "#17181D",
        paddingTop: '5px',
        paddingBottom: '16px'

    },
    ReviewHeading: {
        paddingBottom: '8px',
        color: "#6d89af",
        fontFamily: "Urbanist",
        fontSize: "12px",
        fontWeight: 600,
        lineHeight: "19px",
        whiteSpace: "nowrap",
        textOverflow: "clip",
        overflow: "hidden",
    },
    ReviewParentVendor: {},
    rupeesicon: {},
    rupeeicon: {},
    positionicon: {},
    Locationicon: {},
    DetailLeftSide: {
        // width: '70%'
    },
    jobcardimage: {
        height: '73px',
        width: '73px',
        borderRadius: '10px'
    },
    DateIcon: {},
    JobDescripation: {},
    AiIcon: {},
    ratingborder: {

        borderBottom: "1px solid",
        borderBottomColor: "#D8D8D8",
        marginBottom: "10px"
    },
    CurrrentBalance: {
        top: '286px',
        width: '150px',
        height: '59px',
        borderRadius: '10px',
        border: '1px solid #e3eaf4',
        background: '#ffffff',

    },
    Currrenttext1: {
        color: '#6d89af',
        fontFamily: 'Urbanist',
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '16px',
        letterSpacing: '0.2px',
        paddingTop: '10px',
        paddingLeft: '25px'
    },
    Currenttext2: {
        color: ' #17181d',
        fontFamily: 'Urbanist',
        fontSize: '18px',
        fontWeight: 700,
        lineHeight: '19px',
        letterSpacing: ' 0.16363636px',
        paddingTop: '10px',
        paddingLeft: '25px'
    },
    TravellingList: {
        display: 'flex',
        justifyContent: 'space-between',
        height: '48px',
        borderRadius: '12px',
        border: '1px solid #e3eaf4',
        background: '#ffffff',
        cursor: "pointer",
        width: "100%",
        marginTop: "11px",
        //flex:1
    },
    TravellingList1: {
        display: 'flex',
        flexDirection:'column',
        justifyContent: 'space-between',
    
        borderRadius: '12px',
        border: '1px solid #e3eaf4',
        background: '#ffffff',
        cursor: "pointer",
        width: "100%",
        marginTop: "11px",
        marginBottom:'24px'
        //flex:1
    },
    TravellingIcon: {
        width: '38px',
        height: '38px',
        borderRadius: '12px',
        background: 'rgba(245,196,44,0.07)',

    },
    doticon: {
        paddingLeft: '10px',
        paddingTop: '10px'
    },
    TravellingParent: {
        display: 'flex',
        alignItems: "center"

    },
    TravellingParent1: {
        paddingRight: '14px',
        display: 'flex',
        alignItems: 'center',
        gap: '5px'
    },
    TrevellingText: {
        color: '#17181d',
        fontFamily: 'Urbanist',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '19px',
    },
    Daynumber: {
        color: '#0091ff',
        fontFamily: 'Urbanist',
        fontSize: '14px',
        fontWeight: 600,

        letterSpacing: '0.23333333px'
    },
    TrevellingText1: {
        color: 'white',
        fontFamily: 'Urbanist',
        fontSize: '12px',
        fontWeight: 600,
        backgroundColor:'#F5C42C',
        width:'20px',
        height:'20px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        borderRadius:'18px'
    },
    TravelingListandRatingreviews: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'first baseline',
        paddingLeft: "25px",
        boxSizing: "border-box",
        width: "100%",
        //flex: '1 1 0%',
        //paddingRight:'24px',
        // width: '40%',
        "@media screen and (max-width: 959px)": {
            paddingLeft: "0px",
            paddingTop: "13px",
        },
    },
    overviewTextTwo: {
        color: "#17181d",
        fontFamily: "urbanist",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "18px",
      },
    humbergerparent: {
        display: 'flex',
        padding: '8px',
        alignItems: 'center',
        gap: '10px',
        paddingLeft: '16px',
    },
    humbergerParentIcon: {

    },
    overviewLocationIcon: {
        width: "55px",
        height: "55px",
      },
    humbergericon: {
        height: '16px',
        width: '16px',
    },
    humbergerLabel: {
        color: '#17181d',
        fontFamily: 'Urbanist',
        fontSize: '14px',
        fontWeight: 600,
        cursor: 'pointer'

    },
    productionheading: {
        marginLeft: '16px',
      },
    projectLocation: {
        marginTop: "15px",
      },
      overviewSubTitle: {
        lineHeight: "19px",
        color: "#6d89af",
        fontFamily: "urbanist",
        fontSize: "16px",
        fontWeight: 600,
      },
      productiondemo: {
        border: "1px solid #E1E6EC",
        borderRadius: "12px",
        display: 'flex',
        padding: '10px',
        paddingLeft: '16px',
        marginTop: "7px",
        marginBottom:'145px'
      },
      headingQuickmanage:{
   display:'flex',
   width:'100%'
   
      },
      overviewText: {
        color: "#17181d",
        fontFamily: "urbanist",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "19px",
        marginBottom: "2px",
        textOverflow: 'none',
    
      },

});

export const StyledBadge = withStyles(() =>
    createStyles({
        badge: {
            color: "#44b700",
            "&::after": {
                position: "absolute",
                right: -3,
                top: 13,
                padding: "0 4px",
                width: "100%",
                height: "100%",
                borderRadius: "50%",
                content: '""'
            }
        },
        "@keyframes ripple": {
            "0%": {
                transform: "scale(.8)",
                opacity: 1
            },
            "100%": {
                transform: "scale(2.4)",
                opacity: 0
            }
        }
    })
)(Badge);


export default function JobDetail(props: MyProps) {
    const { data,deleteProjectPH,EditProject,jobRoleNavigation,handleArrowRightClick,handleArrowRightClickDepartment,navigateToallinvoiceRequest} = props;
    const classes = useStyles();
   
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openhumberger = Boolean(anchorEl);

    const handleClickhumberger = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleOptionClick = (event: any, label: string) => {
        handleClosehumberger(); // Close the dropdown

        // Perform different actions based on the clicked label
        if (label === 'Edit Project') {
            EditProject(data?.id)
        
        } else if (label === 'Delete project') {
            deleteProjectPH(data?.id)
        
        }
    };
    const handleClosehumberger = () => {
        setAnchorEl(null);
    };
    const ratings = [
        { stars: 5, value: 80 },
        { stars: 4, value: 60 },
        { stars: 3, value: 40 },
        { stars: 2, value: 20 },
        { stars: 1, value: 10 },
    ];
    const options = [
        { label: "Edit Project", icon: editstudio },
        { label: "Delete project", icon: studiodelete },
        
    ];
    const ITEM_HEIGHT = 48;
    return (

        <Grid className={classes.ParentDiv}>
            <Grid className={classes.HeadingParentDiv}>
                <Grid className={classes.ProfileDiv}>
                    <Grid className={classes.ProfileImageAndOtherInformation}>
                        <Grid className={classes.ProfileImage}>
                            <img src={data?.attributes?.image|| dummyImage} alt="" className={classes.jobcardimage} />
                        </Grid>
                        <Grid className={classes.OtherInformation}>
                            <Grid className={classes.HeadingInformation}>
                                <Grid className={classes.HeadingText}>
                                {data?.attributes?.project_name}
                                </Grid>
                               
                            </Grid>
                            <Grid className={classes.OnGoingText}>
                                 {data?.attributes?.status.toUpperCase()}
                                </Grid>
                            <Grid className={classes.DateInformation}>
                                <Grid className={classes.DateIcon}>
                                    <img src={DateIconView} alt="" className={classes.dateimage} />
                                </Grid>
                                <Grid className={classes.DateInf}>
                                
                                    {`${data?.attributes?.start_date}-${data?.attributes?.end_date}`}
                                </Grid>
                            </Grid>

                        </Grid>

                    </Grid>

                </Grid>
                <Grid className={classes.Headingaddress} style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>

                    <Grid className={classes.CityandStateDiv}>
                        <Grid className={classes.Locationicon}>
                            <img src={iconjobdetail} alt="" className={classes.jobiconyear} />
                        </Grid>
                        <Grid className={classes.CityandState}>
                            {`${data?.attributes?.city}, ${data?.attributes?.state}`}
                            
                        </Grid>
                    </Grid>
                    {
                        data?.attributes?.total_days === 0 ? (
                            <Grid className={classes.DayDiv}>
                                <Grid className={classes.Locationicon}>
                                    <img src={iconjobdetail} alt="" className={classes.jobiconyear} />
                                </Grid>
                                <Grid className={classes.DayText}>
                                    Today
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid className={classes.DayDiv}>
                                <Grid className={classes.Locationicon}>
                                    <img src={dayicon} alt="" className={classes.jobiconyear} />
                                </Grid>
                                <Grid className={classes.Daynumber}>
                                    {`${data?.attributes?.total_days} Days`}
                                </Grid>
                            </Grid>
                        )
                    }
                    
            </Grid>
            </Grid>
            
            <Grid container md={12} sm={12} className={classes.LeftandRightDetail}>
                <Grid md={8} sm={12} className={classes.DetailLeftSide}>
                    <Grid className={classes.InvoiceParent}>
                        <Grid>
                            <Grid className={classes.BalanceandBudget}>
                                <Grid className={classes.CurrrentBalance}>
                                    <Grid className={classes.Currrenttext1}>
                                        Current Balance
                                    </Grid>
                                    <Grid className={classes.Currenttext2}>
                                        {data?.attributes?.current_balance ? `₹ ${data?.attributes?.current_balance}` : "₹ 0"}
                                        
                                    </Grid>
                                </Grid>
                                <Grid className={classes.CurrrentBalance}>
                                    <Grid className={classes.Currrenttext1}>
                                        Overall Budget
                                    </Grid>
                                    <Grid className={classes.Currenttext2}>
                                        {data?.attributes?.overall_budget ? `₹ ${data?.attributes?.overall_budget}` : "₹ 0"}
                                        
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid className={classes.InvoiceButtonandDotIcon}>
                            <button className={classes.submitButton} onClick={(e:any)=>props.navigateToallinvoiceRequest(e,data.id)}  >
                                {`All Invoices (${data?.attributes?.invoice_count})`}
                               
                            </button>
                            <Grid className={classes.Currenttext2} style={{ paddingTop: "unset", paddingLeft: "8px" }}>
                                <div>
                                    <IconButton
                                        aria-label="more"
                                        aria-controls="long-menu"
                                        aria-haspopup="true"
                                        onClick={handleClickhumberger}
                                    >
                                        <MoreVertIcon />
                                    </IconButton>
                                    <Menu
                                        id="long-menu"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={openhumberger}
                                        onClose={handleClosehumberger}
                                        PaperProps={{
                                            style: {
                                                maxHeight: ITEM_HEIGHT * 4.5,
                                                width: "20ch",
                                            },
                                        }}
                                    >
                                        {options.map((option, index) => (
                                            <div key={index} onClick={(e) => handleOptionClick(e, option.label)}>
                                                <Box className={classes.humbergerparent}>
                                                    <Box className={classes.humbergerParentIcon}>
                                                        <img src={option.icon} alt="" className={classes.humbergericon} />
                                                    </Box>
                                                    <Box className={classes.humbergerLabel}>
                                                        {option.label}
                                                    </Box>
                                                </Box>
                                            </div>
                                        ))}
                                    </Menu>
                                </div>

                            </Grid>
                        </Grid>
                    </Grid>

                 
                    <Grid className={classes.JobDescripation}>
                        <Grid style={{ display: 'flex', width: '100%', gap: '5px' }}>
                            <Grid className={classes.JobDescriptionHeading}>
                               Project Overview
                            </Grid>
                            <Box style={{
                                height: '0px',
                                border: '1px dashed #e3eaf4', width: "82%", marginTop: '8px', backgroundColor: '#ffffff'
                            }}></Box>
                        </Grid>
                        <Grid className={classes.JobDescriptionText}>
                   {data?.attributes?.description}
                        </Grid>
                    </Grid>
                    <Grid className={classes.projectLocation}>
                <Typography className={classes.overviewSubTitle}>Project Location</Typography>
              </Grid>
              <Grid className={classes.productiondemo}>
                <img src={LocationIcon} alt="departmenticon icon" className={classes.overviewLocationIcon} />
                <Grid>
                  <Grid className={classes.productionheading}>
                    <Typography className={classes.overviewText}>{data?.attributes?.production_house}</Typography>
                  </Grid>
                  <Box className={classes.productionheading}>
                    <Typography className={classes.overviewTextTwo}>  {`${data?.attributes?.address},${data?.attributes?.city}, ${data?.attributes?.state}`}</Typography>
                  </Box>
                </Grid>
              </Grid>
                </Grid>

                <Grid md={4} sm={12} className={classes.TravelingListandRatingreviews}>
                    <Box className={classes.headingQuickmanage}>
                      <Box style={{fontSize:'12px',lineHeight:'19px',fontFamily:'urbanist',color:'#6d89af',fontWeight:600}}>Quick Manage</Box>  
                      <Box style={{borderBottom: '1px dotted #6d89af',


width: '70%',
display: 'flex',
justifyContent: 'center',
alignItems: 'center',
paddingBottom: '11px',
marginBottom: '7px',
marginLeft: '4px'}} ></Box>
                    
                    </Box>
                    <Box  className={classes.TravellingList1}>
                    <Box style={{display:'flex',justifyContent:'space-between',paddingTop:'8px',paddingBottom:'8px',borderBottom:'1px solid #e1e6ec'}}>
                    <Grid className={classes.TravellingParent} >
                            <Grid className={classes.TravellingIcon}>
                                <img src={employeerequest} alt="" className={classes.doticon} />
                            </Grid>
                            <Grid className={classes.TrevellingText}>
                            All Requests & Employees
                            </Grid>
                        </Grid>
                        <Grid className={classes.TravellingParent1}>
                            <Grid className={classes.TrevellingText1}>
                            {data?.attributes?.all_job_role_and_request}
                          
                          
                            </Grid>
                           
                            <Grid className={classes.arrowicon}onClick={()=>jobRoleNavigation(data?.attributes?.project_name,data?.attributes?.status)} >
                                <img src={Travelingarrow} alt="" className={classes.arrowLive} />
                            </Grid>
                        </Grid>
                       
                    </Box> 
                    <Box style={{display:'flex',justifyContent:'space-between',paddingTop:'8px',paddingBottom:'8px',borderBottom:'1px solid #e1e6ec'}}>
                    <Grid className={classes.TravellingParent}>
                            <Grid className={classes.TravellingIcon}>
                                <img src={Allemployementimage} alt="" className={classes.doticon} />
                            </Grid>
                            <Grid className={classes.TrevellingText}>
                            All Employees Permissions
                            </Grid>
                        </Grid>
                        <Grid className={classes.TravellingParent1}>
                            <Grid className={classes.TrevellingText1}>
                            {data?.attributes?.all_employee_job_role}
                            </Grid>
                           
                            <Grid className={classes.arrowicon} onClick={()=>handleArrowRightClick(data?.id,data?.attributes?.project_name,data?.attributes?.status)} >
                                <img src={Travelingarrow} alt="" className={classes.arrowLive} />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box style={{display:'flex',justifyContent:'space-between',paddingTop:'8px',paddingBottom:'8px',borderBottom:'1px solid #e1e6ec'}}>
                    <Grid className={classes.TravellingParent}>
                            <Grid className={classes.TravellingIcon}>
                                <img src={AllDepartment} alt="" className={classes.doticon} />
                            </Grid>
                            <Grid className={classes.TrevellingText}>
                                All Departments
                            </Grid>
                        </Grid>
                        <Grid className={classes.TravellingParent1}>
                            <Grid className={classes.TrevellingText1}>
                            {data?.attributes?.department_count}
                            </Grid>
                           
                            <Grid className={classes.arrowicon}  onClick={()=>handleArrowRightClickDepartment(data?.id,data?.attributes?.status,data?.attributes?.project_name)}>
                                <img src={Travelingarrow} alt="" className={classes.arrowLive} />
                            </Grid>
                        </Grid>
                    </Box>
                    </Box>
                    <Box className={classes.headingQuickmanage}>
                     
                   
                    
                    </Box>
                   
                    
                </Grid>

            </Grid>
        </Grid >

    );
}
const webStyle = {


}