import React, { useEffect, useState } from 'react'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  ListItemSecondaryAction,
  Box
} from '@material-ui/core';
// @ts-ignore
import { ReactComponent as Dashboard } from "./Sidebar_Assets/home.svg";
// @ts-ignore
import { ReactComponent as Analytics } from "./Sidebar_Assets/analytics.svg";
// @ts-ignore
import { ReactComponent as ProfileBlocked } from "./Sidebar_Assets/blocked.svg";
// @ts-ignore
import { ReactComponent as PrivacyPolicy } from "./Sidebar_Assets/policy.svg";
// @ts-ignore
import { ReactComponent as AllProductionHouses } from "./Sidebar_Assets/production.svg";
// @ts-ignore
import { ReactComponent as TermsAndConditions } from "./Sidebar_Assets/terms.svg";
// @ts-ignore
import { ReactComponent as AllVendors } from "./Sidebar_Assets/vendors.svg";
// @ts-ignore
import { ReactComponent as allProjects } from "./Sidebar_Assets/projects.svg";
// @ts-ignore
import { ReactComponent as AllStudios } from "./Sidebar_Assets/studios.svg";
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import LayersOutlinedIcon from '@material-ui/icons/LayersOutlined';
import { getStorageData, setStorageData } from '../../framework/src/Utilities';
import { VendorIcon, analyticsIcon, cameraOutdoorIcon, contactSupportIcon, exploreIcon, historyIcon, uploadFile, workHistoryIcon ,invoiceIcon, dummyImage, ProfileDummy } from "./assets"
const images = require("./assets")
interface MenuItemsList {
  name: string;
  to: string;
  Icon: any;
}

function SideBar({ ...props }) {
  const [userEmail, setUserEmail] = useState("");
  const [accountType, setAccountType] = useState("");
  const [userName, setUserName] = useState("");
  const [profile, setProfile] = useState<any>(getStorageData('image'));

  useEffect(() => {
    async function fetchData() {
      const currentUserEmail = await getStorageData('email');
      setUserEmail(currentUserEmail);
      const accountType = await getStorageData('account_type');
      setAccountType(accountType);
      const currentUserName = await getStorageData('full_name');
      setUserName(currentUserName);
      const profileImage = await getStorageData('image')
      setProfile(profileImage);
    }
    fetchData();
  }, []);

  const handleProfileNavigation = () => {
   

    if (accountType === "admin")
      props.navigate("UserProfileBasicBlock")
    else if (accountType === "vendor_account")
      props.navigate("VendorManageProfile")
  }

  const generalListItems = [
    {
      name: "Dashboard",
      to: "Dashboard",
      Icon: Dashboard,
    },
    {
      name: "All Projects",
      to: "AllProjectView",
      Icon: LayersOutlinedIcon
    },
    {
      name: "All Vendors",
      to: "FullTeamVisibility",
      Icon: AllVendors,
    },
    {
      name: "All Studios",
      to: "AllStudioView",
      Icon: AllStudios,
    },
    {
      name: "All Production Houses",
      to: "AllProductionHouses",
      Icon: AllProductionHouses,
    },
    {
      name: "Analytics",
      to: "Analytics",
      Icon: Analytics,
    },
    {
      name: "Blocked Profiles",
      to: "AccountGroups",
      Icon: ProfileBlocked,
    }
  ]

  const otherListItems = [
    {
      name: "Privacy Policy",
      to: "Contentmanagement3",
      Icon: PrivacyPolicy,
    },
    {
      name: "Terms & Conditions",
      to: "TermsAndCondition",
      Icon: TermsAndConditions,
    }
  ]

  const vendorGeneralListItems = [
    {
      name: "Jobs",
      to: "JobProfile",
      Icon: images.workHistoryIcon,
    },
    {
      name: "Invoices",
      to: "InvoiceList",
      Icon: images.receiptIcon
    },
    {
      name: "Studios & Booking",
      to: "StudiosBookingList",
      Icon: images.cameraOutdoorIcon,
    },
    {
      name: "My Tasks",
      to: "TaskList",
      Icon: images.layersVendorSidebarIcon,
    },
    {
      name: "Balance Sheets",
      to: "Forecastingandbudgeting",
      Icon: images.uploadFile,
    },
    {
      name: "Analytics",
      to: "VendorAnalytics",
      Icon: images.leaderboardIcon,
    },
    {
      name: "History",
      to: "HistoryList",
      Icon: images.historyIcon,
    },
    {
      name: "Help Center",
      to: "HelpCenter",
      Icon: images.contactSupportIcon,
    },
    {
      name: "Manage Profile",
      to: "VendorManageProfile",
      Icon: images.vendorProfileIcon,
    },
  ]
  const productionHouseGeneralListItems = [
    {
      name: "Projects",
      to: "Projects",
      Icon: workHistoryIcon
    },
    {
      name: "Invoices Requests",
      to: "AllInvoiceRequests",
      Icon: invoiceIcon
    },
    {
      name: "All Studios & Bookings",
      to: "AllStudioBookingListPH",
      Icon: cameraOutdoorIcon


    },
    {
      name: "Job Role Requests",
      to: "JobRoleRequest",
      Icon: workHistoryIcon


    },
    {
      name: "Project Members",
      to: "ProjectMemberList",
      Icon: VendorIcon


    },
    {
      name: "History",
      to: "HistoryList",
      Icon: images.historyIcon
    },
    {
      name: "Balance Sheets",
      to: "BalanceSheet",
      Icon: uploadFile


    },
    {
      name: "Analytics",
      to: "VendorAnalytics",
      Icon: analyticsIcon

    },
    {
      name: "Help Center",
      to: "HelpCenter",
      Icon: contactSupportIcon
    },
  
  ]


  const createList = (listItems: MenuItemsList[], type: string) => {
    return listItems.map(({ to, name, Icon }, index) =>
      <ListItem button onClick={() => props.navigate(to)} key={index} style={props.activeMenuItem === name ? webStyle.activeItem : webStyle.inactiveItem}>
        <ListItemIcon style={props.activeMenuItem === name ? webStyle.activemenuIcon : webStyle.inactivemenuIcon}>
          <Box>
            {type !== "vendor" && type !== "production" && name === "All Projects" ? (
              <Icon style={props.activeMenuItem === name ? webStyle.yellowIcon : webStyle.inactiveText} />
            ) : null}
            {type !== "vendor" && type !== "production" && name !== "All Projects" ? (
              <Icon style={props.activeMenuItem === name ? webStyle.activeIcon : webStyle.inactiveIcon} />
            ) : null}
            {type === "vendor" || type === 'production' ?
              <img className={props.activeMenuItem === name ? "activeVendorSidebarMenuIcon" : "vendorSidebarMenuIcon"} src={Icon} style={{ width: 21, height: "21px" } as React.CSSProperties} />
              : null}
          </Box>
        </ListItemIcon>
        <ListItemText className='fontSizeUrbanist' primary={name} style={{ fontSize: "14px", fontWeight: 500, lineHeight: "19px", color: "17181D", fontFamily: "Urbanist" }} primaryTypographyProps={props.activeMenuItem === name ? { style: { fontWeight: "bold", color: "#F5C42C", fontSize: '14px', fontFamily: "Urbanist" } } : { style: { fontSize: "14px", fontWeight: 500, lineHeight: "19px", color: "17181D", } }} />
        <ListItemSecondaryAction>
          <Typography variant="body2">
            <ArrowForwardIosOutlinedIcon
              onClick={() => props.navigate(to)}
              style={webStyle.itemArrow}
            />
          </Typography>
        </ListItemSecondaryAction>
      </ListItem>
    )
  }

  return (
    <Box data-test-id={"closeIconId"} sx={webStyle.sideBar}>
      <span className='closeIcon' onClick={() => props.handleSideBar()} style={webStyle.closeSideBarIcon as React.CSSProperties}><MenuOpenIcon style={webStyle.drawerIcon} /></span>
      <Box sx={webStyle.logoContainer}>
        <img style={webStyle.logo} src={`${images.logo}`} alt="Logo" />
        <Box style={{ backgroundColor: '#F5C42C', borderRadius: '15px 16.5px 25px 0px', fontSize: 15, lineHeight: '18px', color: 'white', fontWeight: 700, letterSpacing: '0.15px', padding: '8px 16px 7px 16px' }}>PICTURES</Box>
      </Box>
      <Box style={{ ...webStyle.userData, cursor: "pointer" }} onClick={handleProfileNavigation}>
        <Box sx={webStyle.userImageBox}>
          <img style={webStyle.userImage as React.CSSProperties} src={profile||dummyImage} />
        </Box>
        <Box>
          <Box sx={webStyle.userName}>{userName}</Box>
          <Box sx={webStyle.userEmail}>{userEmail}</Box>
        </Box>
      </Box>
      <Box sx={webStyle.lineStyle}></Box>
      <List style={{ paddingTop: "20px" }}>
        <Box sx={webStyle.generalListName}>GENERAL</Box>
        <Box sx={webStyle.generalitemlist}>
          {accountType === "vendor_account" && createList(vendorGeneralListItems, "vendor")}
          {accountType === "admin" && createList(generalListItems, "admin")}
          {accountType === "production_house_account" && createList(productionHouseGeneralListItems, "production")}
        </Box>
        <Box style={webStyle.lineStyle}></Box>
        <Box sx={webStyle.otherListName}>OTHER</Box>
        {
          createList(otherListItems, "other")
        }
      </List>
    </Box>
  )
}
const webStyle = {
  logoContainer: {
    borderBottom: "1px solid #E3EAF4",
    display: "flex",
    alignItems: "end",
    padding: "16.5px 24px",
    maxHeight: "80px",
    gap: '8.67px',
  },
  logo: {
    width: "100%",
    maxWidth: "41.3px"
  },
  yellowicon: {

  },
  drawer: {
    width: "100%",
  },
  drawerPaper: {
    width: '100%',
  },
  drawerContainer: {
    overflow: 'auto',
  },
  activemenuIcon: {
    minWidth: "unset",

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px",
    background: "#f5f2e7",
  },
  yellowIcon: {
    background: "#f5f2e7",
    fill: "#F5C42C",
    width: "21px",
    height: "21px"
  },
  inactivemenuIcon: {
    minWidth: "unset",

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px",
    background: "#F7F9FA",
    marginLeft: '5px'
  },
  sideBar: {
    position: "relative",
    "@media(max-width: 767px)": {
      position: "fixed",
      width: "100vw",
      background: "#fff",
      height: "100%",
      zIndex: 3,
    },
  },
  closeSideBarIcon: {
    position: "absolute",
    right: "-22px",
    top: "15px",
  },
  itemArrow: {
    width: "15px",
    color: "gray",
    cursor: "pointer"
  },
  activeItem: {
    color: "#F5C42C",
    borderLeft: "4px solid #F5C42C",
    gap: "15px",
    paddingBlock: "4px",
    fill:"#F5C42C",
  },
  inactiveItem: {
    gap: '15px',
    fill:"#17181D"
  },
  itemName: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#17181D",
    marginLeft: "12px"
  },
  activeIcon: {
    fill: "#F5C42C"
  },
  inactiveIcon: {

  },
  lineStyle: {
    border: "1px solid #E3EAF4",
    width: "90%",
    margin: "0 auto"
  },
  otherListName: {
    marginLeft: "20px",
    marginBottom: "20px",
    color: "#6D89AF",
    fontSize: "12px",
    paddingTop: "12px",
    fontFamily: "Urbanist"
  },
  generalListName: {
    marginLeft: "20px",
    marginBottom: "20px",
    color: "#6D89AF",
    fontSize: "12px",
    lineHeight: "19px",
    fontFamily: "Urbanist"

  },
  generalitemlist: {
    fontSize: "14px",
    marginBottom: "16px",
    '& .MuiListItemIcon-root': {
      color: 'rgba(0, 0, 0, 0.54)',
      padding: '8px',
    },
  },
  userData: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingLeft: "25px",
    paddingTop: "23.5px",
    paddingBottom: '15.5px',
  },
  userEmail: {
    color: "#6D89AF",
    fontSize: "14px",
    lineHeight: '17px',
    fontWeight: 500,
    fontFamily: 'urbanist',
  },
  userName: {
    fontWeight: "600",
    fontSize: "18px",
    color: "#17181D",
  },
  userImageBox: {
    width: "47px",
    height: "47px",
    borderRadius: "12px",
    overflow: "hidden",
    position: "relative",
    marginRight: "10px"
  },
  userImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
    top: "0",
    left: "0",
  },
  drawerIcon: {
    color: "#fff",
    backgroundColor: "black",
    borderRadius: "14px",
    padding: "10px",
    cursor: "pointer",
    fontSize: '24px',
  },
  activeText: {
    color: 'rgb(245, 196, 44)',
    cursor: "pointer",
    backgroundColor: '#f5c42c14',
    boxShadow: 'none',
    padding: '3%',
    borderRadius: '10px'
  },
  inactiveText: {
    color: "inherit",
    cursor: "pointer",
    boxShadow: 'none',
    marginLeft: '-1px',
    borderRadius: '10px',
    background: "#f5f2e7",
    fill: "black",
    width: "21px",
    height: "21px"
  },
}
export default SideBar