import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    TextField,
    styled,
    withStyles,
    Grid,
    InputLabel
} from "@material-ui/core";

import Modal from '@material-ui/core/Modal';
import { CustomButton } from '../../blocks/email-account-login/src/CustomButton';
const images = require("./assets");

const CustomInputlabel = withStyles({
    root: {
        fontFamily: 'Urbanist',
        color: '#6D89AF',
        fontWeight: 600,
        letterSpacing: '0.2px',
        fontSize: '13px',
        lineHeight: "18px",
        paddingBottom: "6px",
        paddingTop: "20px"
    },
})(InputLabel);

const CssTextField = withStyles({
    root: {
        borderColor: "#f5c42c",
        "& .MuiInput-underline:after": {
            borderBottomColor: "#f5c42c"
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#E3EAF4",
            },
            "&:hover fieldset": {
                borderColor: "#f5c42c",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#f5c42c",
                backgroundColor: 'rgba(245,196,44,0.04)',
            },
            borderRadius: "14px",
            fontWeight: 400,
            color: '#323232',
            height: 48,
            "& .MuiOutlinedInput-input": {
                borderColor: 'rgba(245,196,44,0.04)',
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '18px',
                color: '#17181D',
                padding: '0px 14px',
                fontFamily: 'Urbanist',
            }
        },
        "& .MuiInputBase-input::placeholder": {
            color: '#6d89af',
            fontWeight: 500
        },
    }
})(TextField);

const StyledModal = styled(Modal)({
    position: 'fixed',
    zIndex: 1300,
    inset: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

const style = {
    width: 343,
    borderRadius: '20px',
    padding: '24px',
    backgroundColor: 'white',
    boxShadow: 'none',
    // height: 192,
};

const CustomButtonBox = styled(CustomButton)({
    '& .MuiButton-label': {
        fontWeight: 600,
        fontSize: 14,
        color: '#ffffff',
        letterSpacing: '0.4px',
        lineHeight: '24px',
    }
});

function BudgetSheetAddCategoryModel({ ...props }) {
    const [categoryNameError, setCategoryNameError] = useState(false);
    const [categoryName, setCategoryName] = useState(props.editCategoryData?.categoryName || "");
    // const [numberOfRowsError, setNumberOfRowsError] = useState({ invalid: false, required: false });
    // const [numberOfRows, setNumberOfRows] = useState(0);

    const handleInputCategoryName = (event: any) => {
        setCategoryName(event.target.value);
        if (!event.target.value) {
            setCategoryNameError(true);
        } else setCategoryNameError(false);
    };

    // const handleInputNumberOfRows = (event: any) => {
    //     setNumberOfRows(event.target.value);
    //     if (!event.target.value) {
    //         setNumberOfRowsError({ invalid: false, required: true });
    //     } else if (!Number(event.target.value)) {
    //         setNumberOfRowsError({ invalid: true, required: false });
    //     } else setNumberOfRowsError({ invalid: false, required: false });
    // };

    const handleInputFocus = (labelName: string) => {
        const nameLabel = document.getElementById(labelName);
        if (nameLabel) {
            nameLabel.style.color = "#F5C42C";
        }
    }

    const handleInputBlur = (labelName: string) => {
        const nameLabel = document.getElementById(labelName);
        if (nameLabel) {
            nameLabel.style.color = "#6D89AF";
        }
    }

    const handleSaveCategory = () => {
        // if (!numberOfRows || !categoryName) {
        if (!categoryName) {
            setCategoryNameError(true);
            return false;
        }
        // if (!numberOfRows)
        //     setNumberOfRowsError({ invalid: false, required: true });
        // }
        if (props.editCategoryData) {
            props.handleUpdateCategory(categoryName, props.editCategoryData?.id);
        } else {
            props.handleAddCategory(categoryName);
        }
    }

    return (
        <StyledModal
            aria-labelledby="unstyled-modal-title"
            aria-describedby="unstyled-modal-description"
            open={props.open}
            onClose={props.handleClose}
        >
            <Box sx={style}>
                <Grid container style={{ height: 24, color: '#17181d', fontFamily: 'Urbanist', fontSize: 20, fontWeight: 700, textAlign: 'end', letterSpacing: '0.2px' } as React.CSSProperties}>
                    <Grid xs={9}>
                        {props.editCategoryData ? 'Update' : 'Add New'} Category
                    </Grid>
                    <Grid xs={3}>
                        <img style={{ cursor: 'pointer' }} src={`${images.crossIcon}`} onClick={props.handleClose} />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <CustomInputlabel id={props.editCategoryData ? "categorynameedit" : "categoryname"}>Category Name</CustomInputlabel>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <CssTextField data-test-id="email_id" defaultValue={categoryName} type="text" id={props.editCategoryData ? "categorynameedit" : "categoryname"} onChange={(e: any) => { handleInputCategoryName(e) }} variant="outlined" className="email_input" placeholder="Enter category name" style={{
                        color: "red"
                    }}
                        onFocus={() => {
                            handleInputFocus(`${props.editCategoryData ? "categorynameedit" : "categoryname"}`);
                        }}
                        onBlur={() => {
                            handleInputBlur(`${props.editCategoryData ? "categorynameedit" : "categoryname"}`);
                        }} />
                    {categoryNameError && <Typography style={{ color: 'red', fontFamily: 'Urbanist', fontSize: '12px', fontWeight: 500, lineHeight: '16px', letterSpacing: '0.17142858px' }}>Category name is required</Typography>}
                </Grid>
                {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                    <CustomInputlabel id={props.editCategoryData ? "numberofrowsedit" : "numberofrows"}>Number of Rows</CustomInputlabel>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <CssTextField data-test-id="email_id" defaultValue={numberOfRows} type="text" id={props.editCategoryData ? "numberofrowsedit" : "numberofrows"} onChange={(e: any) => { handleInputNumberOfRows(e) }} variant="outlined" className="email_input" placeholder="Enter number of rows" style={{
                        color: "red"
                    }} onFocus={() => {
                        handleInputFocus(`${props.editCategoryData ? "numberofrowsedit" : "numberofrows"}`);
                    }}
                        onBlur={() => {
                            handleInputBlur(`${props.editCategoryData ? "numberofrowsedit" : "numberofrows"}`);
                        }} />
                    {props.editCategoryData ?
                        <Typography style={{ color: '#6d89af', fontFamily: 'Urbanist', fontSize: '12px', fontWeight: 500, lineHeight: '16px', letterSpacing: '0.17142858px' }}>Please enter the number which is above from your last number of rows, you’re not able to decrease the number of rows.</Typography>
                        : null}
                    {numberOfRowsError.required && <Typography style={{ color: 'red', fontFamily: 'Urbanist', fontSize: '12px', fontWeight: 500, lineHeight: '16px', letterSpacing: '0.17142858px' }}>Number of rows are required</Typography>}
                    {numberOfRowsError.invalid && <Typography style={{ color: 'red', fontFamily: 'Urbanist', fontSize: '12px', fontWeight: 500, lineHeight: '16px', letterSpacing: '0.17142858px' }}>Number of rows should be number</Typography>}
                </Grid> */}
                <Grid item style={{ marginTop: 28 }}>
                    <CustomButtonBox data-test-id="sign_in_button" style={{ height: 48, background: "#17181d" } as React.CSSProperties} onClick={handleSaveCategory}>
                        {props.editCategoryData ? 'Update' : 'Save'} Category
                    </CustomButtonBox>
                </Grid>
            </Box>
        </StyledModal>
    )
}

export default BudgetSheetAddCategoryModel;