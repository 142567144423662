import React from "react";

import {
    // Customizable Area Start
    Box,
    Typography,
    Avatar,
    CardContent
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Badge from "@material-ui/core/Badge";
import {
    withStyles,
    createStyles
} from "@material-ui/core/styles";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import Divider from "@material-ui/core/Divider";
import { emailIcon, expIcon, phoneIcon, checkedIcon, dummyImgPHIcon } from "./assets";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import "./fullVisibility.css";
import { webStyle } from "./FullTeamVisibility.web";
import CardComponentController, { Props } from "./CardComponentController";
export const configJSON = require("./config");
export const StyledBadge = withStyles(() =>
    createStyles({
        badge: {
            color: "#44b700",
            "&::after": {
                position: "absolute",
                right: -3,
                top: 13,
                padding: "0 4px",
                width: "100%",
                height: "100%",
                borderRadius: "50%",
                content: '""'
            }
        },
        "@keyframes ripple": {
            "0%": {
                transform: "scale(.8)",
                opacity: 1
            },
            "100%": {
                transform: "scale(2.4)",
                opacity: 0
            }
        }
    })
)(Badge);

// Customizable Area End

export default class CardComponent extends CardComponentController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { item }: { item: any } = this.props;
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <Box sx={webStyle.cardParentBox}>
                    <Box
                        sx={webStyle.innerBox}>
                        <StyledBadge
                            overlap="circular"
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right"
                            }}
                            badgeContent={
                                <img src={checkedIcon} />
                            }
                        >
                            <Avatar
                                style={webStyle.avatar}
                                src={item.profile_image || dummyImgPHIcon}
                            >

                            </Avatar>
                        </StyledBadge>
                        <Box sx={webStyle.nameBox}>
                            <Typography
                                variant="body1"
                                className="name_typo"
                            >
                                {item && item.full_name}
                            </Typography>
                            <Typography className="position_typo" style={{ letterSpacing: "0.2px" }}>
                                <WorkOutlineIcon style={webStyle.locIcon} />
                                {item.position}
                            </Typography>

                           {item.city && item.state &&  <Typography
                                variant="body1"
                                className="position_typo">
                                <LocationOnOutlinedIcon style={webStyle.locIcon} />
                                {item.city}{" ("}{item.state}{")"}
                            </Typography>}
                        </Box>
                    </Box>
                </Box>
                <CardContent style={webStyle.cardContent}>
                    <Divider style={{ marginTop: "3%", marginBottom: "3%" }} />
                    {item && item.email && <Typography className="email_typo">
                        <img src={emailIcon}
                            className="icon" />
                        {item && item.email}
                    </Typography>}
                    <Typography className="phone_icon">
                        <img src={phoneIcon} className="icon" />
                        {item && (`+${item.country_code}-${item.phone_number}`)}
                    </Typography>
                   {item.experience && <Typography className="phone_icon">
                        <img src={expIcon} className="icon" />
                        {item.experience}
                    </Typography>}
                </CardContent>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
// Customizable Area End