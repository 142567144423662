import React from "react";
import {
    Box,
    // Customizable Area Start
    Container,
    Grid,
    Step,
    StepLabel,
    Stepper,
    TextField,
    InputAdornment,
    Typography,
    withStyles,
    styled,
    createTheme,
    IconButton,
    InputLabel,
    
    StepIconProps,
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import Select from "react-select";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SideBar from "../../../components/src/SideBar";

import ProductionHouseInviteMemberDialog from "../../../components/src/ProductionHouseInviteMemberDialog";
import UploadDefaultImageModal from "../../../components/src/UploadDefaultImageModal";
import ProductionHouseAddNewMemberDialog from "../../../components/src/AddNewMemberModal";
import AddNewDepartMentModal from "../../../components/src/AddNewDepartMentModal";
import UploadImageModal from "../../../components/src/ProjectsUploadImagePopup";
import Header from "../../../components/src/Header.web";
import Loader from "../../../components/src/Loader.web";
import Footer from "../../../components/src/Footer";
import StepConnector from '@material-ui/core/StepConnector';
import { CustomButton } from "../../email-account-login/src/CustomButton";
import {addNewMember, inviteIcon, uploadProjectIcon} from "./assets";
import AddNewProjectController from "./AddNewProjectController.web";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ActiveEmployeeCard from "../../../components/src/ActiveEmployeeCard";
import SMSModal from "../../../components/src/SMSModal";
import PublishProjectModal from "../../../components/src/PublishedProjectModal";
import SelectDepartment from "../../../components/src/SelectDepartmentModal";
import GivePermissionsModal from "../../../components/src/GivePermissionsModal";
import AutorenewOutlinedIcon from '@material-ui/icons/AutorenewOutlined';

import ManageAccessibilityModel from "../../../components/src/ManageAccessibilityModel";

import CustomNotification from "../../../components/src/CustomNotification";
import { checkeddeprtment, uncheckdepartment } from "../../../components/src/assets";
import AddNewMember from "../../../components/src/AddNewMember";
import PersonalDetailInviteVendor from "./PersonalDetailInviteVendor.web";


const themeStyle = createTheme({
    palette: {
      primary: {
        main: "#F5C42C",
        contrastText: "#fff"
      }
    },
   
  });



export const configJSON = require("./config");
const steps = [
    'Add Details',
    'Select Department',
    'Add Employees in the Project',
];
const CustomCSSOfTextField = withStyles({
    root: {
        width: "100%",
        backgroundColor: 'white',
        borderColor: "#f5c42c",
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#E3EAF4",

            },
            "&:hover fieldset": {
                borderColor: "#f5c42c"
            },
            "&.Mui-focused fieldset": {
                borderColor: "#f5c42c",
                backgroundColor: 'rgba(245,196,44,0.04)',
            },
            fontWeight: 400,
            color: '#323232',
            borderRadius: "14px",
            "& .MuiOutlinedInput-input": {
                fontWeight: 600,
                lineHeight: '19px',
                borderColor: '#CCCCCC',
                fontSize: '14px',
                fontFamily:'urbanist'
            }
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#f5c42c"
        },
        "& .MuiInputBase-input::placeholder": {
            color: '#6d89af',
            fontWeight: 500,
            fontFamily: 'Urbanist',
        },
        '& .MuiInputBase-input[type="date"]': {
            textTransform: "uppercase",
            fontWeight: 500,
            color: "#6D89AF",
            '&::after': {
                color: 'red',
            },
            
        },
        
    }
})(TextField);

const CustomGrid = styled(Grid)({
    '& .MuiOutlinedInput-notchedOutline': {
        border: '0px',
        borderColor: 'transparent !important',
    }
})
const StepsConnector = styled(StepConnector)(() => ({
    '& .MuiStepConnector-line': {
        borderColor: '#e3eaf4',
    },
    '&.MuiStepConnector-active': {
        '& .MuiStepConnector-line': {
            borderColor: '#f5c42c',
        },
    },
    '&.MuiStepConnector-alternativeLabel': {
        top: 16,
        left: 'calc(-50% - 16px)',
        right: 'calc(50% - 16px)',
    },
    '&.MuiStepConnector-completed': {
        '& .MuiStepConnector-line': {
            borderColor: '#f5c42c',
        },
    },
   
}));

const StepIconRoot = styled('div')(() => ({
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',

    
}));


const CustomNextStepButtonBox = styled(CustomButton)({
    '& .MuiButton-label': {
        fontSize: 14,
        color: '#ffffff',
        fontWeight: 600,
        width: 163,
        height: 48,
        fontFamily:'urbanist'
    }
});
const CustomSelectCity=styled(Select)({
    '&  .css-yk16xz-control':{
        borderRadius: "14px",
        height: "52px",
        width: "100%",
    },
    '& .css-lphahdxg-control:hover':{
        borderColor: "#F5C42C",
        boxShadow:"none !important",
    },
})
const CustomReactSelect = styled(Select)({
    '& .react-select__control': {
        borderRadius:"14px",
        height:"52px",
        
    },
    '& .css-lphahdxg-control':{
        borderColor: "#F5C42C",
        boxShadow:"none !important",
    },
    '& .react-select__control--is-focused':{
        outline: "none !important",
        boxShadow:"none !important",
        border: "1px solid #F5C42C",
    },
    '& .StateManager-root css-2b097c container':{
        borderColor: "#F5C42C"
    },
    '& .react-select__control:hover': {
        borderColor: "#F5C42C"
    },
    '& .react-select__control--menu-is-open': {
        borderColor: "#f5c42c"
    },
    '& .react-select__placeholder': {
        color: "#6D89AF",

        fontFamily: "Urbanist",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "18px",
        letterSpacing: "0.2px",  
      },
      "& .react-select__single-value":{
        fontWeight: 500,
        color:"#6D89AF"
      },
    "& .react-select__control css-yk16xz-control":{
        borderColor: "#f5c42c"
    },
      overrides: {
        MuiSelect: {
          select: {
            "&:focus": {
              border: "1px solid #F5C42C",
            }
          }
        }
      }
      

});

export const CustomInputlabelEmail = withStyles({
    root: {
      fontFamily: 'Urbanist',
      color: '#f5c42c',
      fontWeight: 600,
      "@media (max-width : 1440px)": {
        fontSize: '14px'
      },
      "@media (min-width : 1441px)": {
        fontSize: '14px',
        lineHeight: "14px",
        paddingBottom: "6px",
        paddingTop: "14px"
  
      }
    },
  })(InputLabel);
   export const Stepdiv = withStyles ({
    root: {

    
        borderRadius: '10px !important',
        
        border:" none !important"
      
}
})(Stepper)
const CustomInputlabel = withStyles({
    root: {
        fontFamily: 'Urbanist',
        color: '#f5c42c',
        fontWeight: 600,
        "@media (max-width : 1440px)": {
            fontSize: '14px'
        },
        "@media (min-width : 1441px)": {
            fontSize: '14px',
            lineHeight: "14px",
            paddingBottom: "6px",
            paddingTop: "14px"

        }
    },
})(InputLabel);

// Customizable Area End
export default class AddProjects extends AddNewProjectController {


    constructor(props: any) {
        super(props);
        // Customizable Area Start
        
        // Customizable Area End
    }

    // Customizable Area Start

    StepIcon(props: StepIconProps) {
        const { active, completed } = props;
    
        const icons: { [index: string]: any } = {
            1: "01",
            2: "02",
            3: "03",
        };

        const getClassname = () => {
            if (active) {
                return "activeIcon"
            } else if (completed) {
                return "completedIcon"
            } else {
                return "disbaleIcon"
            }
        }
        
          
            
                return(
                
                        <StepIconRoot className={`stepIcon ${getClassname()}`}>
                        {icons[String(props.icon)]}
                    </StepIconRoot>
                )    
                

        
          
        
        
       

    }
    ProjectImagefunctionality()
    {
        return(
             !this.state.projectImage?
                <>
                <div data-test-id="uploadProjectDiv" style={webStyle.projectDiv}  onClick={this.handleUploadImageModal} >
                        <img height={25} width={25} style={{ borderRadius: "14px"}} src={uploadProjectIcon} alt="" />

                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <span style={{ fontWeight: 600, fontSize: "14px",fontFamily:'urbanist',color:"#17181d" }}>Upload Project Image</span>
                        <span style={{color: '#6d89af',
fontFamily: 'Urbanist',
fontSize: '12px',
fontWeight: 500,
lineHeight: '18px', display:'flex',justifyContent:'center'}}>(.png, .jpg, .jpeg)</span>
                    </div>
                   

                </>
                :
                <div style={{display:"flex",justifyContent:"space-between",width:"100%",padding:"12px",alignItems:"center"}}>
                    {}
                    <div style={{display:"flex",gap:"13px",alignItems:"center"}}>
                    <img src={this.state.imagePreviewUrl} height={48} width={48} style={{borderRadius:"8px"}} />
                     <div>{this.state.projectImage?.name}</div> 
                    </div>
                    <div style={{display:"flex",gap:"13px",alignItems:"center"}}>
                    <AutorenewOutlinedIcon  onClick={this.handleUploadImageModal}/>
                      <CloseIcon style={{cursor:"pointer"}} onClick={this.handleDeleteSelectedImage} />
                    </div>
                </div>
                
        )
    }
    stepperfirstpage(){
        
        
        return (
            <Grid container md={12} style={{ padding: "0px 24px" }}>
                <Grid item container style={webStyle.inputContainer} spacing={3} >
                    <Grid item md={4}>
                        <Box style={{ borderRadius: "14px",border: "0.75px dashed #F5C42C",
                         height: "72px", marginTop: "22px", display: "flex", gap: "16px", alignItems: "center" }}>
                        {
                       this.ProjectImagefunctionality()
                        }
                        </Box>
                     
                    </Grid>

                     <Grid item md={4}>

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <CustomInputlabel id="nameOfPH" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px", paddingTop: '16px' }}>Project Name</CustomInputlabel>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <CustomCSSOfTextField data-test-id={"projectNameInput"} type={'text'} id="outlined-basic" variant="outlined" placeholder="Enter the project name"
                                value={this.state.stepOneInputs.projectName}
                                onChange={(e:any)=>this.handleStepOneInputChange("projectName",e.target.value)}
                                name="projectName"
                                className="email_input"
                                onFocus={() => {
                                    this.handleInputFocus("nameOfPH");
                                  }}
                                  onBlur={() => {
                                    this.handleInputBlur("nameOfPH");
                                  }}
                               // error={this.state.validationStatus.projectName.valid}
                                helperText={
                                  this.state.validationStatus.projectName.valid && (
                                    <Box className={""} style={{display:"flex",gap:"10px"}}>
                                       <Typography style={{
                                        color: "#D53333",
                                        fontFamily: "Poppins",
                                        fontSize: "16px",
                                        fontWeight: 400,
                                      }
                                      }
                                      >
                                     {
                                        this.state.stepOneInputs.projectName?
                                       null: this.state.validationStatus.projectName.message}
                                      </Typography>
                                    </Box>
                                  )
                                }
                            />
                        </Grid>
                    </Grid> 
                   
                    

                    <Grid item md={4}>

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <CustomInputlabel id="startdate" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px", paddingTop: '16px' }}>Project start date</CustomInputlabel>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                        <CustomCSSOfTextField  id="outlined-basic"
                            data-test-id={"projectStartDateInput"}
                            onFocus={() => {
                                this.handleInputFocus("startdate");
                              }}
                              onBlur={() => {
                                this.handleInputBlur("startdate");
                              }}
                              
                            onChange= {(e:any)=>this.handleStepOneInputChange("projectStartDate", e.target.value)}
                                inputProps={{
                                  'aria-label': 'nameOfPHInput',
                                  type: 'date',
                                  //placeholder: 'DD/MM/YYYY',
                                  name: 'projectStartDate',
                                  value: this.state.stepOneInputs.projectStartDate,
                                  
                                  required: true,
                                }}
                                variant="outlined"
                                // error={this.state.validationStatus.projectStartDate.valid}
                                helperText={
                                  this.state.validationStatus.projectStartDate.valid && (
                                    <Box className={"classes.errorBoxStyle"} style={{display:"flex",gap:"10px"}}>
                            
                                      <Typography style={{
                                        color: "#D53333",
                                        fontFamily: "Poppins",
                                        fontSize: "16px",
                                        fontWeight: 400,
                                      }}>
                                          {
                                        this.state.stepOneInputs.projectStartDate?
                                       null: this.state.validationStatus.projectStartDate.message}
                                    
                                      </Typography>
                                    </Box>
                                  )
                                }
                            
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item container style={webStyle.inputContainer} spacing={3} >
                     <Grid item md={4}>

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <CustomInputlabel id="enddate" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px", paddingTop: '16px' }}>Project end date</CustomInputlabel>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <CustomCSSOfTextField 
                            data-test-id={"projectEndDateInput"}
                            onFocus={() => {
                                this.handleInputFocus("enddate");
                              }}
                              onBlur={() => {
                                this.handleInputBlur("enddate");
                              }}
                            onChange= {(e:any)=>this.handleStepOneInputChange("projectEndDate",e.target.value)}
                            inputProps={{
                              'aria-label': 'nameOfPHInput',
                              type: 'date',
                              placeholder: 'DD/MM/YYYY',
                              name: 'projectEndDate',
                              value: this.state.stepOneInputs.projectEndDate,
                              //required: true,
                            }}
                            variant="outlined"
                           // error={this.state.validationStatus.projectEndDate.valid}
                            helperText={
                              this.state.validationStatus.projectEndDate.valid && (
                                <Box className={"classes.errorBoxStyle"} style={{display:"flex",gap:"10px"}}>
                               
                                  <Typography style={{
                                    color: "#D53333",
                                    fontFamily: "Poppins",
                                    fontSize: "16px",
                                    fontWeight: 400,
                                  }}>
                                     {this.state.stepOneInputs.projectEndDate?
                                       null: this.state.validationStatus.projectEndDate.message}
                                  </Typography>
                                </Box>
                              )
                            }
                         
                                              />
                        </Grid>
                    </Grid> 
                   

                    <Grid item md={4}>

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <CustomInputlabel id="State" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px", paddingTop: '16px' }}>State</CustomInputlabel>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <CustomReactSelect
                            data-test-id={"stateId"}
                            onFocus={() => {
                                this.handleInputFocus("State");
                              }}
                              onBlur={() => {
                                this.handleInputBlur("State");
                              }}
                            getOptionLabel={(options:any) => {
                                return options["name"];
                              }}
                              getOptionValue={(options:any) => {
                                return options["name"];
                              }}
                            classNamePrefix="react-select"
                            value={this.state.stepOneInputs.selectedState}
                            onChange={(e:any)=>this.handleStepOneInputChange("selectedState",e)}
                                options={this.state.stateList}
                                placeholder={"Select state"}
                                components={{
                                    IndicatorSeparator: null
                                }}
                                name="selectedState"
                            />
                              {
                              this.state.validationStatus.selectedState.valid && (
                                <Box className={"classes.errorBoxStyle"} style={{display:"flex",gap:"10px"}}>
                        
                                  <Typography style={{
                                    color: "#D53333",
                                    fontFamily: "Poppins",
                                    fontSize: "16px",
                                    fontWeight: 400,
                                  }}>
                                    {this.state.stepOneInputs.selectedState.isoCode?
                                       null: this.state.validationStatus.selectedState.message}
                                  
                                  </Typography>
                                </Box>
                              )
                            }

                        </Grid>
                        
                    </Grid>

                    <Grid item md={4}>

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <CustomInputlabel id="City" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px", paddingTop: '16px' }}>City</CustomInputlabel>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} style={{ width: "100%" }}>
                            <CustomReactSelect
                             onFocus={() => {
                                this.handleInputFocus("City");
                              }}
                              onBlur={() => {
                                this.handleInputBlur("City");
                              }}
                              
                               getOptionLabel={(options:any) => {
                                return options["name"];
                              }}
                              getOptionValue={(options:any) => {
                                return options["name"];
                              }}
                                data-test-id={"cityId"}
                                classNamePrefix="react-select"
                                options={this.state.cityList}
                                placeholder={"Select City"}
                                components={{
                                    IndicatorSeparator: null
                                }}
                                value={this.state.stepOneInputs.selectedCity}
                                onChange={(e:any) =>this.handleStepOneInputChange("selectedCity",e)}
                                name="selectedCity"

                            />
                           {
                              this.state.validationStatus.selectedCity.valid && (
                                <Box className={"classes.errorBoxStyle"} style={{display:"flex",gap:"10px"}}>
                
                                  <Typography style={{
                                    color: "#D53333",
                                    fontFamily: "Poppins",
                                    fontSize: "16px",
                                    fontWeight: 400,
                                  }}>
                                     {   this.state.stepOneInputs.selectedState.countryCode?
                                     null:  this.state.validationStatus.selectedCity.message}
                                   
                                  </Typography>
                                </Box>
                              )
                            }
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item container style={webStyle.inputContainer}  >
                    <Grid item md={12}>

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <CustomInputlabel id="Description" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px", paddingTop: '16px' }}>Project Description</CustomInputlabel>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <CustomCSSOfTextField
                             onFocus={() => {
                                this.handleInputFocus("Description");
                              }}
                              onBlur={() => {
                                this.handleInputBlur("Description");
                              }}
                            multiline
                            rows={2}
                            data-test-id={"projectDescInput"}
                            onChange= {(e:any)=>this.handleStepOneInputChange("projectDescription",e.target.value)}
                            inputProps={{
                              'aria-label': 'nameOfPHInput',
                              type: 'text',
                              placeholder: 'Enter about the project',
                              name: 'projectDescription',
                              value: this.state.stepOneInputs.projectDescription,
                              required: true,
                            }}
                            variant="outlined"
                            //error={this.state.validationStatus.projectDescription.valid}
                            helperText={
                              this.state.validationStatus.projectDescription.valid && (
                                <Box style={{display:"flex",gap:"10px"}} className={"classes.errorBoxStyle"}>
                                  {/* <ErrorOutlineOutlinedIcon className={"classes.errorIconStyle"} color="error" /> */}
                                  <Typography style={{
                                    color: "#D53333",
                                    fontFamily: "Poppins",
                                    fontSize: "16px",
                                    fontWeight: 400,
                                  }}>
                                     {   this.state.stepOneInputs.projectDescription?
                                     null:  this.state.validationStatus.projectDescription.message}
                                
                                  </Typography>
                                </Box>
                              )
                            }
                            
                        
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item container style={webStyle.inputContainer} >
                    <Grid item md={12}>

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <CustomInputlabel id="Address" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px", paddingTop: '16px' }}>Project Address</CustomInputlabel>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                        <CustomCSSOfTextField  id="outlined-basic"
                         onFocus={() => {
                            this.handleInputFocus("Address");
                          }}
                          onBlur={() => {
                            this.handleInputBlur("Address");
                          }}
                        multiline
                        rows={2}
                            data-test-id={"projectAddInput"}
                            onChange={(e:any)=>this.handleStepOneInputChange("projectAddress",e.target.value)}
                            inputProps={{
                              'aria-label': 'nameOfPHInput',
                              type: 'text',
                              placeholder: 'Enter the address of project location',
                              name: 'projectAddress',
                              value: this.state.stepOneInputs.projectAddress,
                              required: true,
                            }}
                            variant="outlined"
                            //error={this.state.validationStatus.projectAddress.valid}
                            helperText={
                              this.state.validationStatus.projectAddress.valid && (
                                <Box style={{display:"flex",gap:"10px"}} className={"classes.errorBoxStyle"}>
                                  {/* <ErrorOutlineOutlinedIcon className={"classes.errorIconStyle"} color="error" /> */}
                                  <Typography style={{
                                    color: "#D53333",
                                    fontFamily: "Poppins",
                                    fontSize: "16px",
                                    fontWeight: 400,
                                  }}>
                                     {   this.state.stepOneInputs.projectAddress?
                                     null:  this.state.validationStatus.projectAddress.message}
                                  
                                  </Typography>
                                </Box>
                              )
                            }
                            
                        />
                        </Grid>
                    </Grid>
                </Grid>
            <UploadImageModal
            handleDefaultImagePopup={this.handleDefaultImagePopup}
            openUploadProjectImageModal={this.state.openUploadProjectImageModal}
            handleCloseUploadProjectImageModal={this.handleCloseUploadProjectImageModal}
            handleFileChange={this.handleFileChange}
            />
             

             <UploadDefaultImageModal 
             handleSaveDefaultImage={this.handleSaveDefaultImage}
             imageList={this.state.defaultImagesList}
             selectedImageIndex={this.state.selectedImageIndex}
             handleClickOnImage={this.handleClickOnImage}
             opendefaultImageModal={this.state.opendefaultImageModal}
             closeDefaultImagePopup={this.closeDefaultImagePopup}
             />
 

            </Grid>
        );
    }
    steppersecondpage(){
        return(
            <Grid spacing={2} container   md={12}>
            <Loader loading={this.state.isLoading}></Loader>
            <Grid md={12} container item>
            <CustomGrid item md={4}>
                <TextField
                    className='fontSizeUrbanist'
                    value={this.state.searchQuery}
                    data-test-id="searchDepartmentID"
                    onChange={(e)=>this.handleSearchDepartment(e)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <IconButton style={webStyle.iconButton}>
                                    <SearchIcon style={webStyle.searchIcon} />
                                </IconButton>
                            </InputAdornment>
                        ),
                        style: {
                            borderRadius: "12px",
                            backgroundColor: "#E3EAF4",
                            padding: "0px",
                            height: "48px",
                            fontFamily: "Urbanist",
                            width:'325px'
                        } as React.CSSProperties,
                    }}
                    variant="outlined"
                    fullWidth
                    placeholder="Search for Dept."
                    data-focusvisible-polyfill="false"
                />
            </CustomGrid>
            </Grid>
            <Grid style={{ flexWrap: "wrap", padding: "16px 0px 16px 16px", display: "flex", justifyContent: "space-between",gap:"12px"}} spacing={2} md={12} container item className="t55">
            <Grid container spacing={1} style={{ marginTop: "12px",gap:'23px' ,paddingLeft:'7px'}}>
                {this.state.departmentList?.map((ele:any)=>{
                    return        <Box key={ele.id} style={webStyle.departmentdesign}>
                    <Box style={webStyle.departmentToggleDiv}>
                        <span style={webStyle.deparmentname}>{ele.attributes.name}</span>
                        
                        <Box onClick={() => this.togglePipelineDepartmentAddProject(ele.id)}      data-test-id="piplinedepartment">
                  {ele.attributes.pipeline_department ? (
                    <img src={checkeddeprtment} alt="Checked" style={{height:'20px',cursor:'pointer'}} />
                  ) : (
                    <img src={uncheckdepartment} alt="Unchecked" style={{height:'20px',cursor:'pointer'}}/>
                  )}
                </Box>
                    </Box>

                    <Box style={webStyle.departmentlevel}>
                        <span style={{ fontSize: "13px",fontFamily:'urbanist',fontWeight:500 }}>Select pipeline level of the dept.</span>
                        {ele.attributes.pipeline_department?(
                            < select data-test-id={`deptLevelChange`} onChange={(event)=>this.handleDepartmentLevelChange(event,ele.id)} value={ele.attributes.level} style={webStyle.departmentlist}>
                            <option value="0">Level 0</option>
                            <option value="1">Level 1</option>
                            <option value="2">Level 2</option>
                            <option value="3">Level 3</option>
                            <option value="4">Level 4</option>
                            <option value="5">Level 5</option>
                            <option value="6">Level 6</option>
                            <option value="7">Level 7</option>
                            <option value="8">Level 8</option>
                            <option value="9">Level 9</option>
                            <option value="10">Level 10</option>


                        </select>
                        ):(null)}
                        
                    </Box>

                </Box>
                })}
          </Grid>
            </Grid>
            <AddNewDepartMentModal
            handleCloseAddNewDepartmentModal={this.handleCloseAddNewDepartmentModal}
            handleChange={this.handleaddNewDepartmentChange}
            newDepartmentName={this.state.newDepartmentName}
            addNewDepartmentModal={this.state.addNewDepartmentModal}
            handleaddNewDepartmentModal={this.handleaddNewDepartmentModal}
            />
        </Grid>
        )
    }
    stepperthirdpage(){
        return(
            <div data-test-id="addNewMemberDiv">
            <Box style={{ display: "flex", padding: "10px", gap: "24px", justifyContent: "space-between" }}>
                <div style={webStyle.addEmployeeBox}>
                    <Box  data-test-id="inviteMemberOption" onClick={this.handleInviteMemberModal} style={webStyle.addEmployeeInnerDiv}>
                        <img src={inviteIcon} alt={"inviteIcon"} />
                        <div>
                            Invite Member
                        </div>
                    </Box>
                </div>
                <div style={webStyle.addEmployeeBox}>
                    <Box
                    data-test-id="addNewMemberOption"  
                    onClick={() => this.handleModelOpen("addEmployee")} style={webStyle.addEmployeeInnerDiv}>
                        <img src={addNewMember} alt={"addnewmembericon"} />
                        <div>
                            Add New Member
                        </div>
                    </Box>
                </div>

            </Box>

          <Box style={{
            display : 'flex', 
            flexDirection : 'row',
            flexWrap : 'wrap',
            gap : '16px',
            padding : '10px'
          }} >

       
                {this.state.activeEmployeeList.map((ele:any)=> 
            <ActiveEmployeeCard
            departmentList={configJSON.departmentList}
            handleDeleteJobRole={this.handleDeleteJobRole}
    handleDeleteJobRoleInactive={this.handleDeleteJobRoleactive}
            handleEditRole={this.handleEditJobRole}
            activeEmployeeId={this.state.activeEmployeeId}
             handleGivePermissionModal={this.handleGivePermissionModal}
             handleManageAccessibility={this.handleManageAccessibility}

            data={ele}
            handleSelectNewDepartmentModal={this.handleSelectNewDepartmentModal}
            handleSMSModalToggle={this.handleSMSModalToggle}     
            isInvited={false}
            setEmp={this.setEmp}
            />
              )}
              </Box>
            <SelectDepartment
            selectedDepartment={this.state.selectDepartMent}
            handleClose={this.handleCloseSelectNewDepartment}
            departMentList={configJSON.departmentList}
            handleSelectNewDepartmentModal={this.handleSelectNewDepartmentModal}
            selectNewDepartmentModal={this.state.selectNewDepartmentModal}
            handleSelectChange = {this.handleSelectChange}

            />
            <SMSModal data-test-id={'smsmodel'}
            openSMSDialog={this.state.openSMSDialog}
            handleSMSModalToggle={this.handleSMSModalToggle}
            handleSMSInvite={this.handleSMSInvite}
            />
            <PublishProjectModal
            openPublishProjectDialog={this.state.openPublishProjectDialog}
            handlePublishProjectModal={this.handlePublishProjectModal}
            navigateToallJobDetail={this.navigateToallJobDetail}
            />
           <ProductionHouseAddNewMemberDialog
           addNewMemberdepartmentList={this.state.addNewMemberdepartmentList}
           rolesList={this.state.rolesList}
           handleChangePermission={this.handleChangePermission}
           permssionsList={this.state.permissionAccess}
           handleSelectChange={this.handleSelectChange}
           handleSubmit={this.handleSubmit}
           addNewMemberDetails={this.state.addNewMemberDetails}
           handleInputChange={this.handleInputChange}
           handleCancelPermission={this.handleCancelPermission}
           imagePreviewUrl={this.state.invoicePreviewUrl}
           handleFileChange={this.handleInvoiceFileChange}
           addNewMemberModal={this.state.addNewMemberModal}
           handleAddNewMemberModal={this.handleAddNewMemberModal}
           addNewMemberModaltab={this.state.addNewMemberModaltab}
           AddNewMemberModaltabPrevious={this.AddNewMemberModaltabPrevious}
           AddNewMemberModaltabNext={this.AddNewMemberModaltabNext}
           handleSavePermissions={this.handleSavePermissions}
           countryCodeSelected ={this.state.countryCodeSelected}
  createJobRole={this.createJobRole}

            />
                
            <GivePermissionsModal
            handleChangePermissionAccess={this.handleChangePermissionAccess}
            activeEmployeeId={this.state.activeEmployeeId}
            permissionList={this.state.memberData}
            handleGivePermissionModal={this.handleGivePermissionModal}
            permissionMOdal={this.state.permissionModal}
            handleSaveGivenPermission={this.handleSaveGivenPermission}
            handleSavePermissions={this.handleSavePermissions}
            />

        

            <ProductionHouseInviteMemberDialog
                memberList={this.state.inviteMemberList}
                inviteMemberModal={this.state.inviteMemberModal}
                handleInviteMemberModal={this.handleInviteMemberModal}
                personaldetailmodelopen={this.personaldetailmodelopen}
                handleSearchstate={this.filterHousesmyjob}
                count={this.state.vendorInviteCount}
                page={this.state.currentpage}
               handlePageChange={this.handlePageChangeInviteVendor}
               handleViewinvoice={this.handleViewinvoice}

            />
             {this.state.isModelOpen && this.state.modelType === "manageAccessibility" ? <ManageAccessibilityModel open={this.state.isModelOpen} permssionsList={this.state.manageModelPermssionList} departmentsList={this.state.addNewMemberdepartmentList} selectedDept={this.state.manageModelSelectedDept} savePermissionsAPICall={this.savePermissionsAPICall} handleClose={this.handleModelClose} /> : ""}
        {this.state.isModelOpen && this.state.modelType === "addEmployee" ? <AddNewMember open={this.state.isModelOpen} handleClose={this.handleModelClose} countryCodeSelected={this.state.countryCodeSelected} departmentsList={this.state.addNewMemberdepartmentList} rolesList={this.state.rolesList} permssionsList={this.state.permssionsList} handleSaveEmployeeApiCall={this.handleSaveEmployeeApiCall}  /> : null}
      
        </div>
        )

    }
    getStepContent(step: number) {
        switch (step) {
            case 0:
                return(
                    

                    
                              //window.location.replace(`/AddNewProject?${step}`),
                              this.stepperfirstpage()
                                  
                                  
                                )
            case 1:
                return (
                    this.steppersecondpage()
                    
                )
            case 2:
                return (
                   this.stepperthirdpage()
                )
            default:
                return "Unknown step";
        }
    }
    ProjectsMainContentAddProject(sideBarStatus: boolean = this.state.isSideBarOpen) {

        return (
            <>
                <Box data-test-id="addNewProjectContainer" style={{ borderLeft: "1px solid #E3EAF4" }} >
                    <Loader loading={false}></Loader>
                    <Header
                        sideBarStatus={sideBarStatus}
                        dropDownStatus={this.state.isDropdownOpen}
                        handleSideBar={this.handleSideBar}
                        handleDropdown={this.handleDropdown}
                        navigate={this.props.navigation.navigate}
                    />
                    <Box style={webStyle.mainContentJob as React.CSSProperties}>
                        <Box style={webStyle.mainContent}>
                            <Box style={webStyle.mainContentHeader}>
                                <Box style={{ display: "flex", alignItems: "center", gap: "10px" }}  >
                                    <ArrowBackIcon data-test-id="backArrow" onClick={this.handlenavigateToProjectsPage} style={{
                                        background: "white",cursor:"pointer", border: "1px solid grey", borderRadius: "50%", marginRight: "10px", padding: "2px"
                                    }} />
                                <div>
                   {
                    this.state.EditProject=="editproject"?(
                        <Typography style={webStyle.headerTitle}>Edit Project</Typography>

                    ):(
<Typography style={webStyle.headerTitle}>Add New Project</Typography>
                    )
                   }

{
                    this.state.EditProject=="editproject"?(
                        <Typography style={{...webStyle.breadcrumbTesxt}}><span onClick={()=>this.props.navigation.navigate('Projects')} style={{cursor:'pointer',textDecoration:'underline',fontFamily:'urbanist',fontWeight:600,fontSize:'14px'}}>Home</span> <span  onClick={()=>this.props.navigation.navigate('Projects')}
                        style={{...webStyle.breadcrumbTesxt, cursor:"pointer",color: "#F5C42C" ,textDecoration:'underline',fontFamily:'urbanist',fontWeight:600,fontSize:'14px'}}>/ Projects</span> <span style={{ color: "#6D89AF",fontFamily:'urbanist',fontWeight:600,fontSize:'14px' }}>/ Edit Projects</span></Typography>

                    ):(
                        <Typography style={{...webStyle.breadcrumbTesxt}}><span onClick={()=>this.props.navigation.navigate('Projects')} style={{cursor:'pointer',textDecoration:'underline',fontFamily:'urbanist',fontWeight:600,fontSize:'14px'}}>Home</span> <span onClick={()=>this.props.navigation.navigate('Projects')}
                        style={{...webStyle.breadcrumbTesxt, cursor:"pointer",color: "#F5C42C" ,textDecoration:'underline',fontFamily:'urbanist',fontWeight:600,fontSize:'14px'}}>/ Projects</span> <span style={{ color: "#6D89AF",fontFamily:'urbanist',fontWeight:600,fontSize:'14px' }}>/ Add New Projects</span></Typography>
                    )
                   }
                                    

                                </div>
                                </Box>
                                <Box>
                                    {this.state.activeStepIndex === 1 && <CustomNextStepButtonBox data-test-id="newDepartmentButton" style={{ height: 48,background:"white",color:"black",border:"1px solid black" } as React.CSSProperties} className="button" onClick={this.handleaddNewDepartmentModal}>
                                        + Add New Department
                                    </CustomNextStepButtonBox>}
                                </Box>
                            </Box>
                            <Box style={{ position: "relative", backgroundColor: "white", marginTop: "24px", minHeight: "681px", borderRadius: "12px" }}>
                                <div style={{ display: "flex" }}>
                                  
                                    <Box sx={{ width: '60%', margin: "auto" }}>
                                        {
                                            this.state.EditProject=="addproject"?(
                                                <Stepdiv style={{ }} activeStep={this.state.activeStepIndex} alternativeLabel connector={<StepsConnector />}>
                                           
                                                {steps.map((label) => (
                                                    <Step key={label}>
                                                        <StepLabel className="stepLabelClass" StepIconComponent={this.StepIcon}>{label}</StepLabel>
                                                    </Step>
                                                ))}
                                            </Stepdiv>

                                            ):(
                                        null

                                            )
                                        }
                                       
                                    </Box>
                                </div>

                                <Box>
                                    {this.state.activeStepIndex === 0 && this.getStepContent(0)}
                                    {this.state.activeStepIndex === 1 && this.getStepContent(1)}
                                    {this.state.activeStepIndex === 2 && this.getStepContent(2)}
                                
                                </Box>
                                {this.state.activeStepIndex != 2 ?
                                    <div style={{ display: "flex", justifyContent: "space-between", bottom: "24px",width:"100%" }}>
                                        <Box style={{ width: 163, height: 48 ,display:'flex',justifyContent:'start'} as React.CSSProperties} className="button_box">
                                        {this.state.activeStepIndex > 0 ? 
                                            <button data-test-id="arrowBackButton" style={{height: "48px",
                                                backgroundColor:" #ececec",
                                                
                                                display: "flex",
                                                alignItems: "center",
                                                borderRadius: "20px",
                                                width: "100px",
                                                justifyContent: "center",color:"black" }}  onClick={this.handlePreviousStep}>
                                                <ArrowBackIosIcon />Prev
                                            </button>
                                         : <></>}
                                         </Box>
                                         {this.state.EditProject==="editproject"?(
                                           
                                           <Box style={{ width: 163, height: 48 } as React.CSSProperties} className="button_box">
                                           <CustomNextStepButtonBox data-test-id="nextStepButton" style={{ height: 48,fontWeight:600 } as React.CSSProperties} className="button"
                                            onClick={this.UpdateProject}
                                         
                                            >
                                               Save
                                           </CustomNextStepButtonBox>
                                       </Box>
                                         ):(
                                            <Box style={{ width: 163, height: 48 } as React.CSSProperties} className="button_box">
                                            <CustomNextStepButtonBox data-test-id="nextStepButton" style={{ height: 48,fontWeight:600 } as React.CSSProperties} className="button" onClick={() => this.handleNextStep(this.state.activeStepIndex + 1)}>
                                                Next
                                            </CustomNextStepButtonBox>
                                        </Box>
                                         )}


                                    </div>
                                :
                                <div style={{ display: "flex", justifyContent: "space-between",  bottom: "24px",width:"100%" }}>
                                <Box style={{ width: 163, height: 48,display:'flex',justifyContent:'start' ,alignItems:'start'} as React.CSSProperties} className="button_box">
                                    <button data-test-id="arrowBackButton" style={{height: "48px",
                                                backgroundColor:" #ececec",
                                                marginLeft: "10px",
                                                display: "flex",
                                                alignItems: "center",
                                                borderRadius: "20px",
                                                width: "100px",
                                                justifyContent: "center" }} onClick={this.handlePreviousStep}>
                                        <ArrowBackIosIcon />Prev
                                    </button>
                                 </Box>
                                <Box style={{ width: 163, height: 48, marginRight: "10px" } as React.CSSProperties} className="button_box">
                                    <CustomNextStepButtonBox onClick={this.handlePublishProject} data-test-id="nextStepButton" style={{ height: 48 } as React.CSSProperties} className="button">
                                        Publish Project
                                    </CustomNextStepButtonBox>
                                </Box>

                            </div>
                               
                             }
                            </Box>
                        </Box>
                        <Box style={{ position: this.state.isSideBarOpen ? 'absolute' : "sticky", bottom: 0, left: 0, right: 0, backgroundColor: 'white' }}>

                            <Footer />
                        </Box>
                    </Box>

                </Box>
            </>
        )

    }
    render() {
        return (
            
            <Container style={webStyle.parentDivAddProject}>
                {
                    this.state.isSideBarOpen ?
                        <Grid container spacing={0}>
                            <Grid item xs={3} style={webStyle.websidebarParentAddProject}>
                                {
                                    this.state.personaldetailmodel?(
                                        null
                                    ):(

                                        <SideBar
                                    
                                        navigate={this.props.navigation.navigate}
                                        handleSideBar={this.handleSideBar}
                                        userData={{ age: 25 }}
                                        activeMenuItem={"Projects"}
                                         />
                                    )
                                }
                               
                            </Grid>
                            <Grid item xs={9} md={9}>{this.ProjectsMainContentAddProject()}</Grid>
                        </Grid> :
                        <>
                        <Grid item xs={12} md={12}>{this.ProjectsMainContentAddProject()}</Grid>
                      
                        </>
                }
                  {
            this.state.personaldetailmodel?<PersonalDetailInviteVendor personaldetailmodelclose={this.personaldetailmodelclose}
            
            navigation={this.props.navigation} inviteMemberModal={this.state.personaldetailmodel}/>:null
        }
                 <CustomNotification navigation={(route: string) => this.props.navigation.navigate(route)} notification={this.state.emailLoginNotification} handleCloseNotification={this.handleEmailLoginCloseNotification} />
               
            </Container>
            
            
        );
    }
    
    // Customizable Area End

}

// Customizable Area Start
const webStyle = {

    mainContentJob: {
        width: "100%",
        backgroundColor: "#F8FAFE",
        boxSizing: "border-box",
        padding: "24px",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        height: "100%",
        justifyContent: "space-between"
    },
    parentDivAddProject: {
        maxWidth: "none",
        padding: "0px",
    },
    websidebarParentAddProject: {
        minHeight: "100vh",
        maxWidth: "23%"
    },
    
    breadcrumbTesxt: {
        color: "#F5C42C",
        fontSize: "14px",
        marginTop: "8px",
    },
    mainContentHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    departmentdesign:{width:"29.70%" ,padding: "14px 14px 12px 12px", height: "99px", borderRadius: "12px", border: "1px solid #F5C42C" },
    headerTitle: {
        color: " #17181D",
        fontFamily: "Urbanist",
        fontSize: "28px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "normal",
        letterSpacing: "0.311px",
    },
    departmentToggleDiv:{ display: "flex", alignItems: "center", justifyContent: "space-between", borderBottom: "1px solid #E3EAF4", paddingBottom: "12px" },
    mainContent: {
        backgroundColor: "#F8FAFE",
        //padding: "24px"
    },
    deparmentname:{ fontSize: "15px",fontWeight:600, fontFamily: "Urbanist" },
    inputContainer: {
 
    },
    departmentlevel:{ marginTop: "12px", display: "flex", alignItems: "center", justifyContent: "space-between", padding: "12px 0px" },
    iconButton: {
        margin: "0px",
        padding: "0px",
    },
    departmentlist:{fontWeight:600,width: '82px', height: '30px',
    borderRadius: '10px',
    border: '1px solid #e3eaf4',
    background: 'rgba(227,234,244,0.5)',fontFamily:'urbanist'},
    searchIcon: {
        color: "#6D89AF",
        fontSize: "22px",
        marginLeft: 14,
    },
    projectDiv:{
        marginLeft:"12px",
        borderRadius:"10px",
        background:"rgb(255, 240, 194)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px"
    },
    addEmployeeBox: {
        display: "flex",
        justifyContent: "center",
        width: "50%",
        alignItems: "center",
        height: "60px",
        border: "1px solid black",
        borderRadius: "14px"
    },
    addEmployeeInnerDiv: {
        display: "flex",
        gap: "10px",
        justifyContent: "center",
        alignItems: "center"
    }

};
// Customizable Area End
