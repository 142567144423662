import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

export interface IBalanceSheetProject {
  id: string;
  attributes: {
    project_name: string;
    current_balance: number | null;
    overall_budget: number | null;
    production_house: string;
    image: string | null;
    created_at: string;
    manage_balance_sheet: boolean;
    view_balance_sheet: boolean;
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isDropdownOpen: boolean;
  isSideBarOpen: boolean;
  balanceSheetsData: IBalanceSheetProject[];
  token: string;
  isLoading: boolean;
  balanceSheetSearchText: string;
  totalPages: number;
  balanceSheetCurrentpage: number;
  balanceSheetNotification: { type: string, open: boolean, message: string, route?: string };
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ForecastingandbudgetingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apigetBalanceSheetProjectsDataCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isDropdownOpen: false,
      isSideBarOpen: true,
      balanceSheetsData: [],
      token: "",
      isLoading: false,
      balanceSheetSearchText: "",
      totalPages: 0,
      balanceSheetCurrentpage: 1,
      balanceSheetNotification: { type: "", open: false, message: "" },
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.apigetBalanceSheetProjectsDataCallId === apiRequestCallId) {
        this.getBalanceSheetProjectsDataHandleResponse(responseJson);
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  getBalanceSheetProjectsDataHandleResponse = (responseJson: { data: IBalanceSheetProject[], meta: {total_count: number }, errors: { token: string }[] }) => {
    if (responseJson.data || this.state.balanceSheetSearchText) {
      if (responseJson.data?.length || this.state.balanceSheetSearchText)
        this.setState({ balanceSheetsData: responseJson.data, totalPages: responseJson.meta.total_count });
    } else if (responseJson.errors[0]?.token) {
      this.setState({ balanceSheetNotification: { open: true, type: "error", message: responseJson.errors[0].token, route: "LandingPage" } });
    }
    this.setState({ isLoading: false });
  }

  async componentDidMount() {
    const authToken = await getStorageData("token");
    const isBalanceSheetListSideBarOpen = await getStorageData("sidebarvalue");
    this.setState({ token: authToken, isSideBarOpen: JSON.parse(isBalanceSheetListSideBarOpen) }, () => {
      this.getBalanceSheetProjectList(configJSON.getBalanceSheetProjectListAPI + `?per_page=${configJSON.balanceSheetProjectListPerPage}&page=${this.state.balanceSheetCurrentpage}&search=${this.state.balanceSheetSearchText}`);
    });
  }

  getBalanceSheetProjectList = (endPoint: string) => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setState({ isLoading: true });
    this.apigetBalanceSheetProjectsDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleSearchData = async (event: any) => {
    this.setState({
      balanceSheetSearchText: event.target.value,
    });
    this.getBalanceSheetProjectList(configJSON.getBalanceSheetProjectListAPI + `?per_page=${configJSON.balanceSheetProjectListPerPage}&page=${this.state.balanceSheetCurrentpage}&search=${event.target.value}`);
  }

  handleSideBar = () => {
    setStorageData("sidebarvalue", (!this.state.isSideBarOpen).toString());
    this.setState({
      isSideBarOpen: !this.state.isSideBarOpen
    });
  };

  handleDropdown = () => {
    this.setState({
      isDropdownOpen: !this.state.isDropdownOpen
    });
  };

  handleHomeNavigation = (page: string) => {
    this.props.navigation.navigate(page)
  };

  handleNavigationManageSheet = (id: string) => {
    setStorageData("BalanceSheetId", id);
    this.props.navigation.navigate("ManageBalanceSheet");
  }

  handleNavigationViewSheet = (id: string) => {
    setStorageData("BalanceSheetId", id);
    this.props.navigation.navigate("ViewBalanceSheet");
  }

  handleBalanceSheetPageChange = (event: any, value: number) => {
    this.setState({
      balanceSheetCurrentpage: value,
    });
    this.getBalanceSheetProjectList(configJSON.getBalanceSheetProjectListAPI + `?per_page=${configJSON.balanceSheetProjectListPerPage}&page=${value}&search=${this.state.balanceSheetSearchText}`);
  }

  handleVendorProfileCloseNotification = () => {
    this.setState({ balanceSheetNotification: { type: "", open: false, message: "" } });
  }
  // Customizable Area End
}
