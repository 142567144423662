import React from "react";

import {
    // Customizable Area Start
    Grid,
    Avatar,
    IconButton,
    Divider,
    InputLabel,
    Typography,
    Box
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import GetAppIcon from '@material-ui/icons/GetApp';
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';
import { SignInInputLabel } from "../../email-account-login/src/CustomButton";
import { webStyle } from "./FullTeamVisibility.web";
import "./fullVisibility.css";
import OverviewController, { Props, configJSON } from "./OverviewController";
import { certificateIcon } from "./assets";
// Customizable Area End

export default class Overview extends OverviewController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {

        return (
            // Customizable Area Start
            <Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <InputLabel style={webStyle.vendorLabel}>{configJSON.overview}</InputLabel>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Divider className="divider_vendor" />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <SignInInputLabel className="sign_in_label_vendor_d">{this.props.type === "productionHouse" ? configJSON.productionHouseBio : configJSON.vendorBio}</SignInInputLabel>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="description_grid">
                    <Typography className="description">{this.props.type === "productionHouse" ? this.props.overviewData.profile?.data?.attributes.profile_bio?.data?.attributes.about_business : this.props.overviewData.about_vendore}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Divider className="divider_vendor" />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <SignInInputLabel className="sign_in_label_vendor_d doc_bottom">{configJSON.doc}</SignInInputLabel>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Box className="adhar_box">
                                <AssignmentIndOutlinedIcon style={{ width: "1.875rem", height: "1.875rem" } as React.CSSProperties} />
                                <Box className="adhar_inner_box">
                                    <Typography className="adhar_typo">{configJSON.adharNoText}</Typography>
                                    <Typography className="description">{this.props.overviewData.aadhar_card_number ? `${this.props.overviewData.aadhar_card_number.toString().slice(0, 4)} xxxx xxxx ${this.props.overviewData.aadhar_card_number.toString().slice(-4)}` : null}</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Box className="adhar_box">
                                <AssignmentIndOutlinedIcon style={{ width: "1.875rem", height: "1.875rem" } as React.CSSProperties} />
                                <Box className="adhar_inner_box">
                                    <Typography className="adhar_typo">{configJSON.panNoText}</Typography>
                                    <Typography className="description">{this.props.overviewData.pan_card_number ? `${this.props.overviewData.pan_card_number.toString().slice(0, 2)}xxxxxx${this.props.overviewData.pan_card_number.toString().slice(-2)}` : null}</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Box className="adhar_box">
                                <AssignmentIndOutlinedIcon style={{ width: "1.875rem", height: "1.875rem" } as React.CSSProperties} />
                                <Box className="adhar_inner_box">
                                    <Typography className="adhar_typo">{configJSON.gstNoText}</Typography>
                                    <Typography className="description">{this.props.overviewData.gst_number}</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Box className="adhar_box">
                                <AssignmentIndOutlinedIcon style={{ width: "1.875rem", height: "1.875rem" } as React.CSSProperties} />
                                <Box className="adhar_inner_box">
                                    <Typography className="adhar_typo">{configJSON.tanNoText}</Typography>
                                    <Typography className="description">{this.props.overviewData.tan_number ? `${this.props.overviewData.tan_number.toString().slice(0, 4)}xxxx${this.props.overviewData.tan_number.toString().slice(-3)}` : null}</Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Divider className="divider_vendor" />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <SignInInputLabel className="sign_in_label_vendor_d">{configJSON.certText}</SignInInputLabel>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={this.props.type === "productionHouse" ? { paddingBottom: "2%" } : {}}>
                    <Grid container spacing={2} className="cert_box">
                        {
                            this.props.overviewData.documents?.map((item: any, index: number) => {
                                return <Grid item key={`${index}_documentlist`} xs={6} sm={6} md={3} lg={3} xl={3}>
                                    <Box className="cert_inner_box">
                                        <Box className="cert_inner_tool">
                                            <Box style={{ backgroundColor: "rgba(245,196,44,0.09)", borderRadius: "8px", padding: "9% 12.3%" }}>
                                                <img style={{ width: "14.61px", height: "21px" } as React.CSSProperties} src={certificateIcon} alt="" />
                                            </Box>
                                            <IconButton data-test-id="pdf_download" onClick={() => this.handleDownloadPDF(item.path_url, item.filename)} className="download_icon" size="medium">
                                                <GetAppIcon fontSize="small" className="download_icon_color" />
                                            </IconButton>
                                        </Box>
                                        <Box className="project_name">
                                            <Typography noWrap className="file_name">{item.filename}</Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            })
                        }
                    </Grid>
                </Grid>
                {this.props.type === "vendor" && (
                    <>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Divider className="divider_vendor" />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <SignInInputLabel className="sign_in_label_vendor_d">{configJSON.gallery}</SignInInputLabel>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="gallery_box">
                            <Grid container spacing={2}>
                                {
                                    this.state.documentList.map((item: any, index: number) => {
                                        return <Grid item key={`${index}_overview_documentList`} xs={6} sm={6} md={2} lg={2} xl={2}>
                                            <Avatar className="avatar" variant="rounded" src="https://randomuser.me/api/portraits/men/78.jpg" ></Avatar>
                                        </Grid>
                                    })
                                }
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid >
            // Customizable Area End
        );
    }
}

// Customizable Area Start
// Customizable Area End