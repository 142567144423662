import React from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  TypographyProps,
  Link,
  AppBar,
  Tabs,
  Tab,
  Avatar
} from "@material-ui/core";
import SideBar from "../../../components/src/SideBar";
import Header from "../../../components/src/Header.web";
import Footer from "../../../components/src/Footer";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
//@ts-ignore
// @ts-ignore
// @ts-ignore
import Loader from "../../../components/src/Loader.web";
import HistoryListController, {
  Props
} from "./HistoryListController";
import Pagination from "@material-ui/lab/Pagination";
import CustomNotification from "../../../components/src/CustomNotification";
const images = require("./assets");

const theme = createTheme({
  palette: {
    primary: {
      main: "#F5C42C",
      contrastText: "#fff"
    }
  },
  overrides: {
    MuiSelect: {
      select: {
        "&:focus": {
          border: "1px solid #F5C42C",
        }
      }
    }
  }
});

export default class HistoryList extends HistoryListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderHistoryAllProjectsContent = () => {
    return <>
      <Grid container spacing={3} style={{ paddingLeft: "12px", paddingRight: "12px" }}>
        {this.state.historyAllProjectsData.length ? this.state.historyAllProjectsData.map((project: any, index: number) => (
          <Grid data-test-id={`${project.id}_${index}_projectlist`} item xs={12} sm={6} md={4} key={`${project.id}_${index}`}>
            <Box style={{ ...webStyle.historyParentBox, height: "175px", boxShadow: "0px 7px 18px rgba(24,39,42,0.07)" }}>
              <Box style={webStyle.historyImageContainer as React.CSSProperties}>
                <img style={webStyle.historyStudioImage as React.CSSProperties} src={project.attributes.image} />
                <Box style={webStyle.historyStudioDetailsMainBox as React.CSSProperties}>
                  {this.state.userType === "production_house_account" ? <>
                    <Box style={webStyle.StudioDetails}>
                      <Box>
                        <Box style={{ height: "24px", display: "flex", alignItems: "center", justifyContent: "center", background: "#42A200", borderRadius: "13px", paddingInline: "8px", boxSizing: "border-box" }}>
                          <Typography style={{ fontWeight: 700, color: "#ffffff", lineSpacing: "0.16666667px", fontSize: "10px", fontFamily: "Urbanist", lineHeight: "12px" } as React.CSSProperties}>{this.statusCovertToPascalCase(project.attributes.status)}</Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Typography style={{ fontWeight: 600, lineSpacing: "0.16666667px", color: "#17181d", fontSize: "10px", fontFamily: "Urbanist", lineHeight: "12px" } as React.CSSProperties}>{project.attributes.start_date} to {project.attributes.end_date}</Typography>
                      </Box>
                    </Box>
                    <Box style={{ ...webStyle.StudioDetails, marginTop: "13px" }}>
                      <Box style={{ maxWidth: "80%" }}>
                        <Typography style={{ maxWidth: "180px", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", fontWeight: 700, color: "#17181d", lineSpacing: "0.225px", fontSize: "18px", fontFamily: "Urbanist", lineHeight: "22px" } as React.CSSProperties}>{project.attributes.project_name}</Typography>
                      </Box>
                      <Box style={{ maxWidth: "180px", boxSizing: "border-box", textAlign: "end" }}>
                        <Typography style={{ fontWeight: 600, lineSpacing: "0.2px", color: "#17181d", fontSize: "12px", fontFamily: "Urbanist", lineHeight: "15px" } as React.CSSProperties}>Total Invoices</Typography>
                        <Typography style={{ marginTop: "1px", fontWeight: 600, lineSpacing: "0.26666668px", color: "#3389ff", fontSize: "16px", fontFamily: "Urbanist", lineHeight: "19px", display: "flex", alignItems: "center", justifyContent: "end" } as React.CSSProperties}>{project.attributes.invoice_count} <img style={{ paddingLeft: "1px" }} src={images.invoiceIcon} /></Typography>
                      </Box>
                    </Box>
                  </> : <>
                    <Box style={webStyle.StudioDetails}>
                      <Box style={{ maxWidth: "80%" }}>
                        <Typography style={{ maxWidth: "180px", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", fontWeight: 700, color: "#17181d", lineSpacing: "0.225px", fontSize: "18px", fontFamily: "Urbanist", lineHeight: "22px" } as React.CSSProperties}>{project.attributes.project_name}</Typography>
                      </Box>
                      <Box>
                        <Typography style={{ fontWeight: 600, lineSpacing: "0.16666667px", color: "#17181d", fontSize: "10px", fontFamily: "Urbanist", lineHeight: "12px" } as React.CSSProperties}>{project.attributes.start_date} to {project.attributes.end_date}</Typography>
                      </Box>
                    </Box>
                    <Box style={{ ...webStyle.StudioDetails, marginTop: "19px" }}>
                      <Box style={{ maxWidth: "180px", paddingRight: "5px", boxSizing: "border-box" }}>
                        <Typography style={{ fontWeight: 600, lineSpacing: "0.2px", color: "#17181d", fontSize: "12px", fontFamily: "Urbanist", lineHeight: "15px" } as React.CSSProperties}>Earning</Typography>
                        <Typography style={{ marginTop: "1px", fontWeight: 600, lineSpacing: "0.26666668px", color: "#17181d", fontSize: "16px", fontFamily: "Urbanist", lineHeight: "19px" } as React.CSSProperties}>₹{project.attributes.overall_budget || 0}</Typography>
                      </Box>
                      <Box>
                        <Box style={{ height: "24px", display: "flex", alignItems: "center", justifyContent: "center", background: "#17181d", minWidth: "68px", borderRadius: "13px", paddingInline: "7px", boxSizing: "border-box" }}>
                          <Typography style={{ fontWeight: 700, color: "#ffffff", lineSpacing: "0.16666667px", fontSize: "10px", fontFamily: "Urbanist", lineHeight: "12px" } as React.CSSProperties}>Invoices({project.attributes.invoice_count})</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </>}
                </Box>
              </Box>
            </Box>
          </Grid>
        ))
          : <Typography style={{ width: '100%', textAlign: 'center', marginTop: '30px', marginBottom: '30px', color: '#6d89af', fontFamily: 'Urbanist', fontSize: 14, fontWeight: 600 }}>No record available</Typography>
        }
      </Grid>
    </>
  }

  renderHistoryAllStudiosContent = () => {
    return <>
      <Grid container spacing={3} style={{ paddingLeft: "12px", paddingRight: "12px" }}>
        {this.state.historyAllStudiosData.length ? this.state.historyAllStudiosData.map((studio: any, index: number) => (
          <Grid data-test-id={`${studio.id}_studiolist`} item xs={12} sm={6} md={4} key={`${studio.id}_${index}`}>
            <Box style={{ ...webStyle.historyParentBox, border: "1px solid #e3eaf4", maxHeight: "147px" }}>
              <Box style={{ ...webStyle.historyCardParentBox, paddingBottom: this.state.userType === "production_house_account" ? "0px" : "12px" } as React.CSSProperties}>
                <Box
                  style={webStyle.historyInnerBox as React.CSSProperties}>
                  <Avatar
                    style={webStyle.historyAvatar}
                    src={this.getStudioText(studio.attributes, "image") || "https://www.littlethings.info/wp-content/uploads/2014/04/dummy-image-green-e1398449160839.jpg"}
                  >
                  </Avatar>
                  <Box style={webStyle.historyNameBox as React.CSSProperties}>
                    <Typography style={webStyle.historyStudioName as React.CSSProperties}>
                      {this.getStudioText(studio.attributes, "name")}
                    </Typography>
                    <Typography style={webStyle.historyPhName as React.CSSProperties}>
                      {this.getStudioText(studio.attributes, "address")}
                    </Typography>
                    <Typography style={webStyle.historyStudioAddress as React.CSSProperties}>
                      <span style={{ fontFamily: "Helvetica" }}>₹{this.getStudioText(studio.attributes, "perDayPrice")}</span> Day | <span style={{ fontFamily: "Helvetica" }}>₹{this.getStudioText(studio.attributes, "perHourPrice")}</span> Hr | {this.getStudioText(studio.attributes, "date")}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              {this.state.userType === "production_house_account" ? <>
                <Box style={{ margin: "12px 10.5px 10.5px 11.5px", borderTop: "1px solid #e3eaf4" }}></Box>
                <Box style={{ ...webStyle.historyCardParentBox, paddingTop: "0px", paddingBottom: "11px" } as React.CSSProperties}>
                  <Box
                    style={{ ...webStyle.historyInnerBox, width: "77%" } as React.CSSProperties}>
                    <Avatar
                      style={{ width: "36px", height: "36px", borderRadius: "50%" }}
                      src={studio.attributes.vendor.data.attributes.profile_image || "https://www.littlethings.info/wp-content/uploads/2014/04/dummy-image-green-e1398449160839.jpg"}
                    >
                    </Avatar>
                    <Box style={webStyle.historyNameBox as React.CSSProperties}>
                      <Typography style={webStyle.studioOwner as React.CSSProperties}>
                        {studio.attributes.vendor.data.attributes.full_name}
                      </Typography>
                      <Typography style={webStyle.studioOwnerDetail as React.CSSProperties}>
                        {studio.attributes.vendor.data.attributes.role}
                      </Typography>
                    </Box>
                  </Box>
                  <Box style={{ width: "23%", display: "flex", flexDirection: "column", alignItems: "end" } as React.CSSProperties}>
                    <Box style={{ height: "24px", borderRadius: "13px", background: "#17181d", lineHeight: "12px", color: "#ffffff", fontFamily: "Urbanist", fontSize: "10px", fontWeight: 700, display: "flex", justifyContent: "center", alignItems: "center", letterSpacing: "0.16666667px", paddingInline: "10.5px" }}>{studio.attributes.booking_type === "hour_wise" ? `${studio.attributes.total_hours} Hours` : `${studio.attributes.total_days} Days`}</Box>
                    <div style={{ paddingTop: "3px", lineHeight: "15px", color: "#42a200", fontSize: "12px", fontWeight: 400, textAlign: "center", fontFamily: "Helvetica-Bold", letterSpacing: "0.2px" }}>₹{studio.attributes.total_amount}</div>
                  </Box>
                </Box>
              </> : null}
            </Box>
          </Grid>
        ))
          : <Typography style={{ width: '100%', textAlign: 'center', marginTop: '30px', marginBottom: '30px', color: '#6d89af', fontFamily: 'Urbanist', fontSize: 14, fontWeight: 600 }}>No record available</Typography>
        }
      </Grid>
    </>
  }

  renderHistoryListMainContent = (sideBarStatus: boolean = this.state.isHistorySideBarOpen) => {
    return <>
      <Loader loading={this.state.isLoading}></Loader>
      <Grid style={webStyle.historyContentSetup} >
        <Header handleSideBar={this.handleSideBar}
          dropDownStatus={this.state.isHistoryDropdownOpen}
          navigate={this.props.navigation.navigate}
          sideBarStatus={sideBarStatus}
          handleDropdown={this.handleDropdown}
        />
        <Grid style={webStyle.historyMainContent as React.CSSProperties}>
          <Box sx={webStyle.historyBlockInfo}>
            <Box>
              <Typography variant="h3" style={webStyle.historyActiveBlock as TypographyProps}>History</Typography>
              <Typography variant="h6" style={webStyle.historyBlockPath as TypographyProps}>
                <span style={webStyle.historyHighlightedText}>
                  <Link data-test-id="homeLink" color="inherit" onClick={() => this.handleNavigation(this.state.userType === "production_house_account" ? "Projects" : "JobProfile")}>
                    Home
                  </Link>
                </span>
                {" "}/ History
              </Typography>
            </Box>
          </Box>
          <Grid item sm={12} xl={12} xs={12} md={12} lg={12}>
            <AppBar
              position="static"
              className="appbar app_bar"
              color="transparent"
            >
              <Tabs
                value={this.state.historyTabValue}
                data-test-id="tabChange"
                variant="scrollable"
                onChange={this.handleAppBarValue}
                TabIndicatorProps={{ style: { height: '2px', background: "orange" } as React.CSSProperties }}
                scrollButtons="on"
                className="tabs_main"
                aria-label="scrollable prevent tabs example"
              >
                <Tab className={this.state.historyTabValue == 0 ? "active_btn" : "non_active_btn"} label="Projects" />
                <Tab className={this.state.historyTabValue == 1 ? "active_btn" : "non_active_btn"} label="Studios" />
              </Tabs>
            </AppBar>
          </Grid>
          <Grid container spacing={3} style={{ marginTop: "24px", marginBottom: "12px" }}>
            {this.state.historyTabValue === 0 ? this.renderHistoryAllProjectsContent() : this.renderHistoryAllStudiosContent()}
          </Grid>
          {this.state.getCountForPagination[this.state.historyTabValue] ?
            (<Grid container spacing={2}>
              <Grid item xs={12}>
                <Pagination style={{ marginTop: "25px", justifyContent: "center", display: "flex" }} data-test-id="pagination" count={Math.ceil((this.state.getCountForPagination[this.state.historyTabValue]) / (this.state.historyTabValue === 0 ? 9 : 15))} page={this.state.currentStudiosPage} onChange={this.handlePageChange} />
              </Grid>
            </Grid>)
            : null}
        </Grid >
      </Grid >
      <Footer />
    </>
  }
  // Customizable Area End

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Container style={webStyle.historyParentDiv} >
          {
            this.state.isHistorySideBarOpen ? (
              <Grid spacing={0} container >
                <Grid item xs={3} style={webStyle.historySidebarParent} >
                  <SideBar
                    handleSideBar={this.handleSideBar}
                    userData={{ name: "user name" }}
                    activeMenuItem={"History"}
                    navigate={this.props.navigation.navigate}
                  />
                </Grid>
                <Grid item style={{ display: "flex", flexDirection: "column", flexBasis: "77.75%", maxWidth: "77.75%" }} xs={9} >
                  {this.renderHistoryListMainContent()}
                </Grid>
              </Grid>
            ) : (
              <Grid item style={{ display: "flex", flexDirection: "column" }} xs={12}>
                {this.renderHistoryListMainContent()}
              </Grid>
            )
          }
        </Container>
        <CustomNotification navigation={(route: string) => this.props.navigation.navigate(route)} notification={this.state.notification} handleCloseNotification={this.handleCloseNotification} />
      </ThemeProvider>
    );
  }
}

const webStyle = {
  historyCardParentBox: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    paddingLeft: '12px',
    paddingRight: '12px',
    justifyContent: "flex-start",
    paddingTop: "12px",
  },
  historyInnerBox: {
    alignItems: "center",
    display: "flex",
    columnGap: '7px',
    width: "100%",
    flexDirection: "row",
  },
  historyAvatar: {
    height: 59,
    borderRadius: "12px",
    width: 59,
  },
  historyNameBox: {
    flexDirection: "column",
    display: "flex",
    alignItems: "start",
    flex: "0 0 calc(100% - 68px)",
    maxWidth: "calc(100% - 68px)",
    paddingRight: "5px",
  },
  studioOwner: {
    lineHeight: "19px",
    color: "#17181d",
    fontFamily: "Urbanist",
    fontSize: "13px",
    fontWeight: 600,
    letterSpacing: "0.2px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: "100%"
  },
  studioOwnerDetail: {
    lineHeight: "16px",
    color: "#17181d",
    fontFamily: "Urbanist",
    fontSize: "11px",
    fontWeight: 500,
    letterSpacing: "0.2px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: "100%"
  },
  historyStudioName: {
    color: "#17181d",
    lineHeight: "19px",
    fontSize: "16px",
    fontFamily: "Urbanist",
    letterSpacing: "0.2px",
    fontWeight: 600,
    whiteSpace: "nowrap",
    overflow: "hidden",
    maxWidth: "100%",
    textOverflow: "ellipsis",
  },
  historyPhName: {
    color: "#17181d",
    lineHeight: "15px",
    fontSize: "12px",
    fontFamily: "Urbanist",
    letterSpacing: "0.15px",
    fontWeight: 500,
    whiteSpace: "nowrap",
    overflow: "hidden",
    maxWidth: "100%",
    textOverflow: "ellipsis",
    marginBottom: "3px",
    marginTop: "4px",
  },
  historyStudioAddress: {
    lineHeight: "15px",
    color: "#6d89af",
    fontFamily: "Urbanist",
    fontSize: "12px",
    fontWeight: 400,
    letterSpacing: "0.15px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: "100%"
  },
  historyParentBox: {
    borderRadius: "12px",
  },
  historyImageContainer: {
    width: "100%",
    height: "100%",
    position: "relative",
  },
  historyStudioImage: {
    top: "0",
    position: "absolute",
    width: "100%",
    height: "100%",
    left: "0",
    borderRadius: "12px",
    objectFit: "cover",
    backgroundColor:"grey"
  },
  historyStudioDetailsMainBox: {
    bottom: "5px",
    position: "absolute",
    left: "5px",
    right: "5px",
    height: 95,
    background: "#fff",
    opacity: 0.8290272,
    borderRadius: "10px",
    boxSizing: "border-box",
    padding: "12px 10px 13px",
  },
  StudioDetails: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
  },
  historyParentDiv: {
    maxWidth: "none",
    padding: "0px",
  },
  historySidebarParent: {
    maxWidth: "22.25%",
    minHeight: "100vh",
  },
  historyContentSetup: {
    flex: 1,
    borderLeft: "1px solid #E3EAF4",
  },
  historyMainContent: {
    width: "100%",
    backgroundColor: "#F8FAFE",
    boxSizing: "border-box",
    minHeight: "100vh",
    padding: "24px"
  },
  historyBlockInfo: {
    justifyContent: "space-between",
    flexWrap: "wrap",
    alignItems: "center",
    display: "flex",
  },
  historyHighlightedText: {
    fontFamily: "Urbanist",
    cursor: "pointer",
    color: "#F5C42C",
    textDecoration: "underline",
    fontSize: '14px',
    fontWeight: 600,
  },
  historyActiveBlock: {
    lineHeight: "34px",
    fontSize: "28px",
    color: "#17181D",
    fontWeight: "700",
    letterSpacing: "1px",
  },
  historyBlockPath: {
    fontSize: "14px",
    letterSpacing: "1px",
    color: "#6D89AF",
    marginBottom: "15px",
    fontWeight: 500,
    paddingBottom: "3px",
    lineHeight: "17px",
    paddingTop: "8px",
  }
};
