import React from "react";

import {
  Container,
  Box,
  Typography,
  // Customizable Area Start
  Grid,
  TypographyProps,
  Link,
  List,
  ListItem,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import TermsAndConditionController, {
  Props,
} from "./TermsAndConditionController";
import Header from "../../../components/src/Header.web";
import Footer from "../../../components/src/Footer";
import SideBar from "../../../components/src/SideBar";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Loader from "../../../components/src/Loader.web";
import CommonComponent from "../../forgot-password/src/CommonComponent";
import TermsAndConditionsData from "../../../components/src/TermsAndConditionsData";
const theme = createTheme({
  palette: {
    primary: {
      main: "#f5c42c",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class TermsAndCondition extends TermsAndConditionController {

  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  termsFontFamilyImportantDesign = (termsFont: any) => {
    if (termsFont) {
      termsFont.style.setProperty('font-family', 'Urbanist', 'important');
    }
  }

  renderTermsPageMainContent(
    termsSideBarStatus: boolean
  ) {
    return (<>
      <Loader loading={this.state.isLoading}></Loader>
      <Grid style={webStyle.contentTermsSetup}>
        <Header
          handleSideBar={this.handleTermsSideBar}
          dropDownStatus={this.state.isHeaderDropdownOpenVal}
          navigate={this.props.navigation.navigate}
          handleDropdown={this.handleHeaderDropdownVal}
          sideBarStatus={termsSideBarStatus}
        />
        <Grid style={webStyle.mainTermsContent as React.CSSProperties}>
          <Box>
            <Typography
              variant="h3"
              style={webStyle.activeTermsBlock as TypographyProps}
              ref={this.termsFontFamilyImportantDesign}
            >
              Terms & Conditions
            </Typography>
            <Typography
              variant="h6"
              style={webStyle.termsBlockPath as TypographyProps}
              ref={this.termsFontFamilyImportantDesign}
            >
              <span style={webStyle.highlightedRouteText}>
                <Link data-test-id="homeLink" color="inherit" onClick={this.handlePageHomeNavigation}>
                  Home
                </Link>
              </span>
              {" "}/ Terms & Conditions
            </Typography>
          </Box>
          <Grid container spacing={3} style={{ marginTop: "12px" }}>
            {this.state.accountType === "admin" ? <>
              {
                this.state.termsList.map((terms) => {
                  return <Grid item xs={12} key={terms.id}>
                    <Box sx={webStyle.cardContentWrapper}>
                      <Box>
                        <Typography
                          ref={this.termsFontFamilyImportantDesign}
                          style={webStyle.termsDetails as React.CSSProperties}>
                          {terms.title}
                        </Typography>
                      </Box>
                      <Box style={webStyle.seperateCardLine}></Box>
                      <Box style={webStyle.additionalTermsInfo}>
                        <Typography
                          ref={this.termsFontFamilyImportantDesign}
                          style={webStyle.alignTermsContent}>
                          {terms.description}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                })
              }
            </> : <Box>
             <TermsAndConditionsData data={this.state.tncDescription}/>
            </Box>}
          </Grid>
        </Grid>
      </Grid >
      <Footer />
    </>
    );
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container style={webStyle.parentTermsDiv}>
          {this.state.signupTnc ? <>
            <Grid container>
              <CommonComponent navigation={this.props.navigation} backButtonVisible={this.state.isBackButtonVisible} />
                 <TermsAndConditionsData data={this.state.tncDescription} />
            </Grid>
          </> : <>
            {this.state.isTermsSideBarOpen ?
              <Grid container spacing={0}>
                <Grid item xs={3} style={webStyle.sidebarTermsParent}>
                  <SideBar
                    activeMenuItem={"Terms & Conditions"}
                    handleSideBar={this.handleTermsSideBar}
                    navigate={this.props.navigation.navigate}
                    userData={{ age: 25 }} />
                </Grid>
                <Grid item xs={9} style={{ display: "flex", flexDirection: "column" }}>{this.renderTermsPageMainContent(this.state.isTermsSideBarOpen)}</Grid>
              </Grid> :
              <Grid item xs={12} style={{ display: "flex", flexDirection: "column" }}>
                {this.renderTermsPageMainContent(this.state.isTermsSideBarOpen)}
              </Grid>
            }
          </>
          }
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyle = {
  parentTermsDiv: {
    padding: "0px",
    maxWidth: "none"
  },
  sidebarTermsParent: {
    minHeight: "100vh",
    maxWidth: "25%"
  },
  contentTermsSetup: {
    borderLeft: "1px solid #E3EAF4",
    flex: 1,
  },
  mainTermsContent: {
    width: "100%",
    minHeight: "115vh",
    backgroundColor: "#f8fafe",
    boxSizing: "border-box",
    padding: '24px',
  },
  activeTermsBlock: {
    lineHeight: "34px",
    fontSize: "28px",
    fontWeight: "700",
    letterSpacing: "1px",
    color: "#17181d",
    marginBottom: "8px",
  },
  termsBlockPath: {
    fontSize: "14px",
    letterSpacing: "1px",
    color: "#6D89AF",
    fontWeight: "500",
  },
  highlightedRouteText: {
    color: '#f5c42c',
    fontSize: '14px',
    fontWeight: 600,
    fontFamily: 'Urbanist',
    cursor: 'pointer',
    textDecoration: 'underline'
  },
  cardContentWrapper: {
    padding: "20px",
    boxSizing: "border-box",
    border: "1px solid #E1E6EC",
    backgroundColor: "#fff",
    borderRadius: "14px",
  },
  vendorCardContentWrapper: {
    padding: "20px",
    boxSizing: "border-box",
    border: "1px solid #E1E6EC",
    backgroundColor: "#fff",
    borderRadius: "14px",
    margin: "12px",
  },
  termsDetails: {
    bottom: "12px",
    height: "22px",
    color: "#17181d",
    fontSize: "18px",
    fontWeight: 700,
    letterSpacing: "1px",
    display: "inline",
  },
  seperateCardLine: {
    border: "1px solid #E3EAF4",
    marginTop: "1.2%",
    marginBottom: "1.2%",
  },
  additionalTermsInfo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#6D89AF",
  },
  alignTermsContent: {
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
    lineHeight: "20px",
    fontSize: "14px",
  },
};
// Customizable Area End
