import React from "react";

import {
  Container,
  Box,
  Typography,
  // Customizable Area Start
  Grid,
  TypographyProps,
  Link,
  List,
  ListItem,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Contentmanagement3Controller, {
  Props,
} from "./Contentmanagement3Controller";
import Header from "../../../components/src/Header.web";
import Footer from "../../../components/src/Footer";
import SideBar from "../../../components/src/SideBar";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Loader from "../../../components/src/Loader.web";
import PrivacyPolicyContentData from "../../../components/src/PrivacyPolicyContentData";
const theme = createTheme({
  palette: {
    primary: {
      main: "#f5c42c",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class Contentmanagement3 extends Contentmanagement3Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  fontFamilyImportantDesign = (policyFont: any) => {
    if (policyFont) {
      policyFont.style.setProperty('font-family', 'Urbanist', 'important');
    }
  }

  renderMainContent(
    sideBarStatus: boolean
  ) {
    return (<>
      <Loader loading={this.state.isLoading}></Loader>
      <Grid style={webStyle.contentSetup}>
        <Header
          sideBarStatus={sideBarStatus}
          dropDownStatus={this.state.isDropdownOpenVal}
          handleSideBar={this.handleSideBar}
          handleDropdown={this.handleDropdownVal}
          navigate={this.props.navigation.navigate}
        />
        <Grid style={webStyle.mainContent as React.CSSProperties}>
          <Box>
            <Typography
              variant="h3"
              style={webStyle.activeBlock as TypographyProps}
              ref={this.fontFamilyImportantDesign}
            >
              Privacy Policy
            </Typography>
            <Typography
              variant="h6"
              style={webStyle.blockPath as TypographyProps}
              ref={this.fontFamilyImportantDesign}
            >
              <span style={webStyle.highlightedText}>
                <Link data-test-id="homeLink" color="inherit" onClick={this.handlePageHomeNavigation}>
                  Home
                </Link>
              </span>
              {" "}/ Privacy Policy
            </Typography>
          </Box>
          <Grid container spacing={3} style={{ marginTop: "12px" }}>
            {this.state.accountTypeInPP === "admin" ? <>
              {
                this.state.policyList.map((policy) => {
                  return <Grid item xs={12} key={policy.id}>
                    <Box sx={webStyle.cardWrapper}>
                      <Box>
                        <Typography ref={this.fontFamilyImportantDesign} style={webStyle.policyDetails as React.CSSProperties}>
                          {policy.title}
                        </Typography>
                      </Box>
                      <Box style={webStyle.seperateCard}></Box>
                      <Box style={webStyle.additionalInfo}>
                        <Typography ref={this.fontFamilyImportantDesign} style={webStyle.alignContent}>
                          {policy.description}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                })
              }
            </> : 
         <PrivacyPolicyContentData data={this.state.ppDescription} />
          }
          </Grid>
        </Grid>
      </Grid >
      <Footer />
    </>
    );
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container style={webStyle.parentDiv}>
          {
            this.state.isSideBarOpen ?
              <Grid container spacing={0}>
                <Grid item xs={3} style={webStyle.sidebarParent}>
                  <SideBar
                    navigate={this.props.navigation.navigate}
                    userData={{ age: 25 }}
                    activeMenuItem={"Privacy Policy"}
                    handleSideBar={this.handleSideBar} />
                </Grid>
                <Grid item xs={9} style={{ display: "flex", flexDirection: "column" }}>{this.renderMainContent(this.state.isSideBarOpen)}</Grid>
              </Grid> :
              <Grid item xs={12} style={{ display: "flex", flexDirection: "column" }}>
                {this.renderMainContent(this.state.isSideBarOpen)}
              </Grid>
          }
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyle = {
  parentDiv: {
    padding: "0px",
    maxWidth: "none"
  },
  sidebarParent: {
    minHeight: "100vh",
    maxWidth: "25%"
  },
  vendorPPCardContentWrapper: {
    boxSizing: "border-box",
    padding: "20px",
    backgroundColor: "#fff",
    border: "1px solid #E1E6EC",
    margin: "12px",
    borderRadius: "14px",
    width: "100%",
  },
  contentSetup: {
    borderLeft: "1px solid #E3EAF4",
    flex: 1,
  },
  card: {
    borderRadius: 8,
    padding: '2%',
    border: '1px solid lightgray',
    boxShadow: 'none'
  },
  projectcardParentBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    padding: '2%'
  },
  mainContent: {
    width: "100%",
    minHeight: "115vh",
    backgroundColor: "#f8fafe",
    boxSizing: "border-box",
    padding: '24px',
  },
  blockInfo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap"
  },
  activeBlock: {
    lineHeight: "34px",
    fontSize: "28px",
    fontWeight: "700",
    letterSpacing: "1px",
    color: "#17181d",
    marginBottom: "8px",
  },
  blockPath: {
    fontSize: "14px",
    letterSpacing: "1px",
    color: "#6D89AF",
    fontWeight: 500,
  },
  highlightedText: {
    color: '#f5c42c',
    fontSize: '14px',
    fontWeight: 600,
    fontFamily: 'Urbanist',
    cursor: 'pointer',
    textDecoration: 'underline'
  },
  cardWrapper: {
    padding: "20px",
    boxSizing: "border-box",
    border: "1px solid #E1E6EC",
    backgroundColor: "#fff",
    borderRadius: "14px",
  },
  policyDetails: {
    bottom: "12px",
    height: "22px",
    color: "#17181d",
    fontSize: "18px",
    fontWeight: 700,
    letterSpacing: "1px",
    display: "inline",
  },
  seperateCard: {
    border: "1px solid #E3EAF4",
    marginTop: "1.2%",
    marginBottom: "1.2%",
  },
  additionalInfo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#6D89AF",
  },
  alignContent: {
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
    lineHeight: "20px",
    fontSize: "14px",
  },
  vendorCardHeading: {
    fontSize: "20px",
    fontWeight: "bold",
    paddingBottom: "15px",
    fontFamily: "Urbanist",
  },
  vendorCardSubHeading: {
    fontSize: "18px",
    fontWeight: "bold",
    padding: "10px 0",
    textDecoration: "underline",
    fontFamily: "Urbanist",
  },
  vendorCardContentParagraph: {
    fontSize: "14px",
    padding:  "5px 0",
    fontFamily: "Urbanist",
  },
  termsHeadBox: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  termsLists: {
    fontSize: "14px",
    lineHeight: "1.75",
    padding:  "5px 0",
    fontFamily: "Urbanist",
    display:" list-item",
    margin: "0 16px",
    listStyle: "disc",
  },
  companyLinks: {
    color: "#f5c42c",
    fontWeight: "bold",
    letterSpacing: "0.5px",
  },
};
// Customizable Area End
