import React, {createRef} from "react";

import {
    // Customizable Area Start
    TypographyProps,
    Container,
    Grid,
    Box,Typography, Modal,
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import JobRoleRequestController, {
    Props,
    configJSON,
  } from "../../../blocks/fullteamvisibility/src/JobRoleRequestController.web";
import Header from "../../../components/src/Header.web";
import SideBar from "../../../components/src/SideBar";
import Footer from "../../../components/src/Footer";
import Loader from "../../../components/src/Loader.web";
import Pagination from "@material-ui/lab/Pagination";
import AllInvoiceProjectDetails from "../../../components/src/AllInvoiceProjectsDetails";
import JobRoleRequestModel from "../../../components/src/JobRoleRequestModel"
// Customizable Area End

export default class JobRoleRequest extends JobRoleRequestController {
    stepperRef: React.RefObject<any>;
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.stepperRef = createRef();
        // Customizable Area End
    }
   
    

    renderJobRoleRequest(isJobRoleideBarStatusPH: boolean  = this.state.isJobRoleRequestSideBarOpen) {
        return (
        <Grid style={webStyle.contentSetupAllinvoice}>
              <Loader loading={this.state.isLoading}></Loader>
            < Header
                navigate={this.props.navigation.navigate}
                sideBarStatus={isJobRoleideBarStatusPH}
                dropDownStatus={this.state.isJobRoleRequestHeaderDropdownOpenVal}
                handleDropdown={this.handleJobRoleHeaderDropdownVal}
                handleSideBar={this.handleJobRoleSideBar}  
            />
            <Grid style={webStyle.mainContentAllinvoice as React.CSSProperties}>
                <Box sx={webStyle.blockInfoAllinvoice}>
                    <Box sx={webStyle.headInfoAllinvoice}>
                        <Box>
                            <Box sx={webStyle.activeBlockAllinvoice}>
                                <Typography variant="h3" style={webStyle.activeBlockAllinvoice as TypographyProps}><div style={{ fontSize: "15px" }}></div>Job Role Requests</Typography>
                            </Box>
                            <Typography variant="h6" style={webStyle.blockPathAllinvoice as TypographyProps}><span style={webStyle.highlightedTextAllinvoice} data-test-id="home-navigation" >Home</span> / Job Role Requests</Typography>
                        </Box>
                        <Box sx={webStyle.jobRequestHeadButton} onClick={this.handleOpenAddJobRole}>
                            + Add Job Role
                        </Box>
                        <Modal
                                    open={this.state.open}
                                    aria-describedby="simple-modal-description"
                                    onClose={this.handleCloseJobRoleModel}
                                    aria-labelledby="simple-modal-title"
                                >
                                 <div style={{
                                    top: '50%',
                                    height: '95%',
                                    position: 'absolute',                                              
                                    borderRadius: '20px',
                                    left: '50%',
                                    backgroundColor: 'white',
                                    transform: 'translate(-50%, -50%)',
                                    overflow: 'auto',
                                    }}>
                                    <JobRoleRequestModel handleClose={this.handleCloseJobRoleModel}  jobRoleProjectDept={this.state.projectDepartment} jobRoleProjectName={this.state.projectName} jobRoleProjectRole={this.state.projectRole} projectChangeHandle={this.handleProjectName} projectDeptChangeHandle={this.handleProjectdepartment} projectDeptRoleHandle={this.handleProjectDeptRole}  projectSelectedValue={this?.state?.jobRoleInputs?.selectedProject} projectDeptSelectedValue={this?.state?.jobRoleInputs?.selectedProjectDept} projectDeptRoleSelectedValue={this?.state?.jobRoleInputs?.selectedProjectRole} roleReqSelectedValue={this.state.jobRoleInputs.roleRequirements} expReqSelectedValue={this.state.jobRoleInputs.expRequired} budgetSelectedValue={this.state.jobRoleInputs.roleBudget} noOfPositionSelectedValue={this.state.jobRoleInputs.noOfPos} roleDescriptionValue={this.state.jobRoleInputs.roleDescription} validateJobRole={this.state.validationStatusJobRole} addJobRoleSubmit={this.handleSubmitFormAddJobRole} selectedProjectVal={this.state.isProjectSelected} selectedDeptVal={this.state.isDepartmentSelected} selectedRoleVal={this.state.isRoleTypeSeleted} roleReqChange={this.handleChangeRoleRequirements} expReqChange={this.handleChangeExp} budgetRoleChange={this.handleChangeBudget} 
                                      noOfPosChange={this.handleChangePos} 
                                      handleRoleError={this.state.roleReqError} roleDescriptionChange={this.handleChangeRoleDes}  handleBudgetError={this.state.budgetError} hanldeExpError={this.state.expError}  
                                      handlenoOfPosError={this.state.noOfPosError} handleRoleDesError={this.state.roleDesError} handleSetEditJobRole={this.state.setEditJobRole}/>
                                </div>
                        </Modal>
                    </Box>
                    <Box>
                        <Typography variant="subtitle2" style={{ color: "rgb(109, 137, 175)" }}>All {this.state.totalJobRoleCount} Job Role Requests</Typography>
                    </Box>
                </Box>
                 <Box className="tabsContentAllInvoice" style={webStyle.tabsContentAllInvoice as React.CSSProperties}  >
                    {
                    <Grid container spacing={4}>
                    {this.state.jobRoleAllRequest.length === 0 ? (
                      <Typography style={{ width: '100%', textAlign: 'center', marginTop: '30px', marginBottom: '30px', color: '#6d89af', fontFamily: 'Urbanist', fontSize: 14, fontWeight: 600 }}>No record available</Typography>
                    ) : (
                      this.state.jobRoleAllRequest.map((ele: any, index: number | string) => (
                        <Grid xs={12} style={{ cursor: 'pointer' }} sm={6} md={this.state.isJobRoleRequestSideBarOpen ? 4 : 3} item onClick={() => this.navigateToJobRolesAndRequest(ele.id,ele.attributes.project_name)}>
                          <AllInvoiceProjectDetails labels={{ "invoicesText": configJSON.jobRoleText }} data={ele} isJobRole={true} />
                        </Grid>
                      ))
                    )}
                  </Grid>
                    }
                    </Box>
                    {this.state.count ?
                            (<Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Pagination style={webStyle.paginationWrapper} count={Math.ceil((this.state.count) / 9)} page={this.state.currentpage} onChange={this.handlePageChange} />
                                </Grid>
                            </Grid>)
                            : null}
                    </Grid>
            <Footer />
        </Grid>
        )
    }
    
    render() {
        return ( 
            <Container maxWidth="xl" className="user_detail_container">
                {
                    this.state.isJobRoleRequestSideBarOpen?
                        <Grid container spacing={0}>
                            <Grid style={webStyle.sideBarJobRoleRequest}  item xs={3}>
                                <SideBar
                                   userData={{ age: 25 }}
                                    activeMenuItem={"Job Role Requests"}
                                    navigate={this.props.navigation.navigate}
                                    handleSideBar={this.handleJobRoleSideBar}
                                   
                                    />
                            </Grid>
                            <Grid item xs={9}>{this.renderJobRoleRequest(this.state.isJobRoleRequestSideBarOpen)}</Grid>
                        </Grid> :
                        <Grid item xs={12}>
                            {
                                this.renderJobRoleRequest(this.state.isJobRoleRequestSideBarOpen)
                            }
                           </Grid>
                }
            </Container >
        );  }
}

// Customizable Area Start
const webStyle = {
   
    contentSetupAllinvoice: {
        borderLeft: "1px solid #E3EAF4",
    },
    
    sideBarJobRoleRequest: {
        minHeight: "100vh",
        maxWidth: "23%",
    },
    blockInfoAllinvoice: {

    },
    blockPathAllinvoice: {
        fontSize: "14px",
        letterSpacing: "1px",
        color: "#6D89AF",
        marginBottom: "24px",
        fontWeight: 500
      },
      highlightedTextAllinvoice: {
        color: "#F5C42C",
        textDecoration: 'underline',
        textDecorationColor: "#F5C42C",
        fontWeight: 600,
        cursor:"pointer"
      },
    activeBlockAllinvoice: {
        fontSize: "28px",
        fontWeight: "600",
        letterSpacing: "1px",
        display: "flex",
        flexDirection: "row",
        lineHeight: "33px",
        alignItems: 'center',
        marginBottom: '5px',   
      },
     
      mainContentAllinvoice: {
        width: "100%",
        backgroundColor: "#F8FAFE",
        boxSizing: "border-box",
        padding: "24px",
        height: "100%"
      },
      tabsContentAllInvoice: {
        paddingTop: "16px",
        // paddingLeft:"24px",
        display: "flex",
        gap: "1.5rem",
        flexWrap:"wrap",
        marginBottom: '15px'
    },
    headInfoAllinvoice: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: 15,
    },
    jobRequestHeadButton: {
        minWidth: '161px',
        borderRadius: '14px',
        background: '#17181d',
        color: '#ffffff',
        fontFamily: 'urbanist',
        fontSize: '15px',
        fontWeight: 600,
        letterSpacing: ' 0.25px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '15px 10px',   
        cursor: 'pointer', 
    },
    paginationWrapper: {
        marginTop: '20px',
        display: 'flex',
        justifyContent: 'center',
      }
};
// Customizable Area End