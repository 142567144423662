import React from "react";
// Customizable Area Start
import CountryCodeSelector from "../../country-code-selector/src/CountryCodeSelector.web";
import MobileAccountLoginController, {
  Props
} from "./MobileAccountLoginController";
import { Box, Grid, InputAdornment, Typography, styled, withStyles } from "@material-ui/core";
import { CustomButton, CustomInputlabelEmail, CustomTextField, SignInInputLabel } from "../../email-account-login/src/CustomButton";
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import CustomNotification from "../../../components/src/CustomNotification";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");

const CustomCSSButtonBox = styled(CustomButton)({
  '& .MuiButton-label': {
    fontSize: 16,
    color: '#ffffff',
    fontWeight: 600,
  }
});
const CustomCSSTextFieldpassword = withStyles({
  root: {
    borderColor: "#f5c42c",
    backgroundColor: 'white',
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#E3EAF4",
      },
      "&:hover fieldset": {
        borderColor: "#f5c42c",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#f5c42c",
        backgroundColor: 'rgba(245,196,44,0.04)',
      },
      borderRadius: "14px",
      color: '#323232',
      fontWeight: 400,
      "& .MuiOutlinedInput-input": {
        borderColor: '#e3eaf4',
        fontWeight: 500,
        lineHeight: '19px',
        color: '#17181D',
        fontSize: '16px',
        fontFamily: 'Urbanist',
      }
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#f5c42c"
    },
    "& .MuiInputBase-input::placeholder": {
      color: '#6d89af',
      fontWeight: 500,
      fontFamily: 'Urbanist',
    },
  }
})(CustomTextField);
// Customizable Area End

export default class MobileAccountLoginBlock extends MobileAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { navigation } = this.props;
    return (
      <>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <CustomInputlabelEmail className="email_lable" id="phonenumber" style={{ fontSize: "14px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px", paddingTop: '11px' }}>{configJSON.phoneNumberText}</CustomInputlabelEmail>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <CustomCSSTextFieldpassword data-test-id="phoneNumberInput" type={'text'} id="outlined-basic" variant="outlined" placeholder="Enter your phone number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" style={{ cursor: 'hand' }} className="phone_input_adornment_box">
                  <CountryCodeSelector
                    navigation={navigation}
                    id={"CountryCodeSelector"}
                    style={{
                      width: "100%",
                      option: (provided: any, state: any) => ({
                        ...provided,
                        fontWeight: 600,
                        color: "#17181D",
                        fontSize: "14px",
                      }),
                      singleValue: (provided: any, state: any) => ({
                        ...provided,
                        color: "#17181D",
                        fontSize: "14px",
                      }),
                    }}
                    disable={false}
                    allowPropChange={true}
                    value={this.state.countryCodeSelected}
                  />
                  <Typography style={{ marginLeft: '9px', color: '#17181d' }}> +{this.state.countryCodeSelected || "null"}</Typography>
                  <Box style={{ borderLeft: '1px solid #6d89af', height: '23px', marginLeft: '12px' } as React.CSSProperties}></Box>
                </InputAdornment>
              ),
            }}
            value={this.state.phoneNumber}
            onChange={(e: any) => this.handlePhoneNumberInput(e)}
            className="email_input"
            onFocus={() => {
              this.handleInputFocus("phonenumber");
            }}
            onBlur={() => {
              this.handleInputBlur("phonenumber");
            }}
          />
          {this.state.phoneNumberError.invalid ? <Typography style={{ color: 'red' }}>{configJSON.phoneNumberError}</Typography> : null}
          {this.state.phoneNumberError.require ? <Typography style={{ color: 'red' }}>{configJSON.phoneNumberErrorRequire}</Typography> : null}
        </Grid>
        <Grid xs={12} item sm={12} md={12} lg={12} className="password_grid">
          <SignInInputLabel style={{ fontWeight: 600, paddingBottom: '6px', paddingTop: "11px", color: "#6D89AF", fontSize: "14px", lineHeight: "18px" }} id="password">{configJSON.placeHolderPassword}</SignInInputLabel>
        </Grid>
        <Grid xs={12} sm={12} item md={12} lg={12}>
          <CustomCSSTextFieldpassword type={'text'} id="outlined-basic" variant="outlined" placeholder="Enter your password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" className="email_input_adornment_box">
                  <span onClick={this.handleShowPassword} data-test-id="passwordChange" style={{ cursor: 'pointer' }}>{this.state.visibiltyOfPassword
                    ? <VisibilityOutlinedIcon />
                    : <VisibilityOffOutlinedIcon />
                  }</span>
                </InputAdornment>
              )
            }}
            value={this.state.visibiltyOfPassword ? this.state.copyPassword : "*".repeat(this.state.passwordLogin.length)}
            data-test-id="password"
            onKeyDown={this.handleKey}
            onChange={(e: any) => this.handlePasswordInput(e)}
            className="email_input"
            onFocus={() => {
              this.handleInputFocus("password");
            }}
            onBlur={() => {
              this.handleInputBlur("password");
            }}
          />
          {this.state.passwordError.require ? <Typography style={{ color: 'red' }}>{configJSON.placeHolderPassword + configJSON.isRequiredError}</Typography> : null}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} className="sign_in_main_grid">
          <Grid item xs={7} sm={7} md={7} lg={7} xl={5}>
            <a href="/ForgotPassword" className="recover_password_link">{configJSON.recoverMsg}</a>
          </Grid>
          <Grid item xs={5} sm={5} md={5} lg={5} xl={5} className="button_box">
            <CustomCSSButtonBox data-test-id="sign_in_button" className="button" onClick={this.doPhoneNumberLogIn}>
              {configJSON.signInText}
            </CustomCSSButtonBox>
          </Grid>
        </Grid>
      </Grid>
      <CustomNotification navigation={(route: string) => this.props.navigation.navigate(route)} notification={this.state.emailLoginNotification} handleCloseNotification={this.handleEmailLoginCloseNotification} />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End
