import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ChangeEvent} from "react";
// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;

  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  isSideBarOpen: any;
  isDropdownOpen: boolean;
  isSideBarOpen1: boolean;
  TabValue: number;
  myJobData: any;
  appliedJobData: any;
  TabDetail: number;
  Taballjob: number;
  Tabmyjob: number;
  Tabappliedjob: number;
  allJobData:any;
  isLoading: boolean;
  allJobDetail:any;
  allJobDetailRatingandReview: any;
  count:number;
  count1:number;
  count2:number;
  currentpage2:number;
  currentpage: number;
  currentpage1:number;
  searchValue: string;
  searchValuemyjob: string;
  searchValueappliedjob: string;
  open:boolean;
  rateValue:number;
  comments:string;
  applyvalue:boolean;
  applyData:any;
  experience:any;
  department:any;
  RoleType:any;
  city:any;
  experiencevalue:number;
  cityvalue:string;
  departmentvalue:string;
  roletypevalue:string;
  reviewbleId:number;
  reviewbleType:string;
  id:number;
  vendorjobid:any;
  open1:boolean;
  invoiceselect:string;
  selectinvoicetype:string;
  selectinvoicename:string;
  selectinvoicedetail:string;
  selectproject:any;
  selectinvoiceno:string;
  selectinvoiceamount:string;
  image:string;
  modelType:string
  isModelOpen:boolean;
  raiseinvoicedetail:any;
  allinvoicecount:any;
  ratingvalidation:string;
  reviewvalidation:string;
  PopupMessage:boolean;
  messageshow:string;
  PopupMessage1:boolean;
  messageshow1:string;
  
  
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class JobProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  addReviewApiCallId:string="";
  applyJobApiCallId:string="";
  withdrawJobCallId:string="";
  sortapiCallId:string="";
  getMyjobcallid: string = "";
  getAppliedjobcallid: string = "";
  getalljobcallid:string="";
  getalljobDetailApiCalledId:string="";
  getexperienceApiCalledId:string="";
  getJobDetailRatingApiCalledId:string="";
  getInvoiceApiCalledId:string="";
  getdepartmentApiCalledId:string=""
  getroletypeApiCalledId:string=""
  getcityApiCalledId:string=""
  getsearchjobid:string="";
  getsearchappliedjobid:string="";
  getsearchmyjobid:string=""
  raiseinvoiceapicallid:string="";
  leaveProjectCallId:string="";
  getProjectinvoiceApiCalledId:string=""
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),

      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),

    ];

    // let boolean;
    // if( await getStorageData("sidebar") === "true") {
    //   boolean = true;
    // } else if (getStorageData("sidebar") === "false") {
    //   boolean = false;
    // }

    this.state = {
      isSideBarOpen: true,
      isSideBarOpen1: true,
      isDropdownOpen: false,
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      TabValue: 0,
      myJobData: [],
      appliedJobData: [],
      TabDetail: 0,
      Taballjob: 0,
      Tabmyjob: 1,
      Tabappliedjob: 2,
      allJobData:[],
      isLoading: false,
     allJobDetail:[],
     allJobDetailRatingandReview: [],
     count:0,
     count1:0,
     count2:0,
     currentpage2:1,
     currentpage: 1,
     currentpage1:1,
     searchValue: "",
     searchValuemyjob:'',
     searchValueappliedjob:'',
     open:false,
     rateValue:0,
     comments:"",
     applyvalue:true,
     applyData:[],
     experience:[],
     department:[],
     RoleType:[],
     city:[],
     experiencevalue:0,
     cityvalue:'',
     departmentvalue:'',
     roletypevalue:'',
     reviewbleId:0,
     reviewbleType:'',
     id:0,
     vendorjobid:getStorageData("vendorid"),
     open1:false,
     invoiceselect:'',
     selectinvoicetype:'',
     selectinvoicename:'',
     selectinvoicedetail:'',
     selectproject:"",
     selectinvoiceno:"",
     selectinvoiceamount:"",
     image:"",
     modelType:'',
    isModelOpen: false,
    raiseinvoicedetail:[],
    allinvoicecount:0,
    ratingvalidation:'',
    reviewvalidation:'',
    PopupMessage:false,
    PopupMessage1:false,
    messageshow:'',
    messageshow1:''
    

    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async receive(from: string, message: Message) {

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let msgvaluejob = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From:" + this.state.txtSavedValue + "To:" + msgvaluejob
      );

      this.setState({ txtSavedValue: msgvaluejob });
    }

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestvaluejob = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))

      const responseValuejob = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
         this.ResponseCallValue(apiRequestvaluejob,responseValuejob)
    }


  }
  
 
  ResponseCallValue=(apiRequestvalue:any,responseValue:any)=>{

    if (apiRequestvalue === this.getMyjobcallid) {
      this.getmyjobdatalist(responseValue)
    }


    if (apiRequestvalue === this.getAppliedjobcallid) {
      this.getapplieddatalist(responseValue)
    }

    if (apiRequestvalue === this.getalljobcallid) {
      this.getalljobdatalist(responseValue)
    }
    if (apiRequestvalue === this.getalljobDetailApiCalledId) {
     this.getallljobdetaildatallist(responseValue)
     
    }
    if (apiRequestvalue === this.getJobDetailRatingApiCalledId) {
      this.getalljobratingandreview(responseValue)
          
    }
    if(apiRequestvalue===this.addReviewApiCallId)
    {
      
      this.getaddReviewdatalist(responseValue)
    }
    if(apiRequestvalue===this.applyJobApiCallId)
    {
       this.applyJobdatalist(responseValue)
    }
    if(apiRequestvalue==this.getexperienceApiCalledId)
    {
      this.getexperiencListdata(responseValue)
    }
    if(apiRequestvalue===this.getdepartmentApiCalledId)
  {
     this.getdepartmentlistdata(responseValue)
  }
  if(apiRequestvalue===this.getroletypeApiCalledId)
  {
     this.getroletypedatalist(responseValue)
  }
  if(apiRequestvalue===this.getcityApiCalledId)
  {
     this.getcitydatalist(responseValue)
  }
  if(apiRequestvalue===this.sortapiCallId)
  {
     this.getsortapidatalist(responseValue)
  }
  if(apiRequestvalue===this.withdrawJobCallId)
  {
    
    this.getwithdrawjob(responseValue)
  }
 if(apiRequestvalue===this.raiseinvoiceapicallid)
  {
      this.raiseinvoiceresponsedataList(responseValue)
        
  }
  this.getsearchjobdata(apiRequestvalue,responseValue)
  this.Leaveprojectdatalist(apiRequestvalue,responseValue)
  this.getprojectdatainvoice(apiRequestvalue,responseValue)
  
}
getsearchjobdata=(apiRequestvalue:any,responseValue:any)=>{
  if(apiRequestvalue===this.getsearchjobid)
  {
    this.getsearchjobdatalist(responseValue)
  }
  if(apiRequestvalue===this.getsearchappliedjobid)
  {
    this.getapplieddatalist(responseValue)
  }
  if(apiRequestvalue===this.getsearchmyjobid)
  {
    this.getmyjobdatalist(responseValue)
  }
}
Leaveprojectdatalist=(apiRequestvalue:any,responseValue:any)=>{
  if(apiRequestvalue===this.leaveProjectCallId)
  {
       alert(responseValue.message)
       this.getMyjobList();       
  }
}

getprojectdatainvoice=async(apiRequestvalue:any,responseValue:any)=>{
  if(apiRequestvalue===this.getInvoiceApiCalledId)
  {  
    this.setState({allinvoicecount:responseValue.meta.total_invoices})
  }
}

raiseinvoiceresponsedataList = async (responseValue: { errors: { token: string }[], data: any }) => {
        
  this.handleModelOpen();
    if (responseValue.data) {
      
      this.setState({raiseinvoicedetail:responseValue.data})
     setStorageData("id",responseValue.data.id)
     setStorageData("typeofinvoice",responseValue?.data?.attributes?.type_of_invoice)
     this.handleClosemodel()
      this.setState({ modelType: "successModel" });
      
    } else if (responseValue.errors[0]) {
      this.setState({open1:false})
      alert(responseValue.errors[0])
    }

   


    this.setState({ isLoading: false });
  

};
getwithdrawjob =async(responseValue:any)=>{
  if(responseValue.errors)
  {
    this.setState({isLoading:false})
    alert(responseValue.errors)
  
  }
  else
  {
  this.setState({isLoading:false})
   //alert(" job withdraw Successfully ")
  
   this.setState({PopupMessage1:true,messageshow1:'job withdraw Successfully'})
   if(this.state.PopupMessage1===true)
  {
   this.getAppliedjobcallid=await this.apiCallvalue(configJSON.GET_Method, configJSON.getAppliedEndPoint + 1);

   //
  }
   window.scrollTo(0, 0);
  
   console.log("ttttttttttt",this.state.PopupMessage1)

  
  }
}
  getsortapidatalist(responseValue:any){
    if(responseValue.data)
    {this.setState({ allJobData: responseValue.data, isLoading: false,count:responseValue.meta.count })
    
      
    }
    else{
      this.setState({isLoading:false})
      alert(responseValue.errors[0].message)
    }
    
  }
  getcitydatalist(responseValue:any){
    this.setState({city:responseValue.data})
  }
  getroletypedatalist(responseValue:any){
    
    this.setState({RoleType:responseValue.data})
  }
  getdepartmentlistdata=(responseValue:any)=>{
    this.setState({department:responseValue.data})
  }
  getexperiencListdata=(responseValue:any)=>{
      this.setState({experience:responseValue.data})
  }
  applyJobdatalist=async(responseValue:any)=>{
    if(responseValue.data)
    {
    
    this.setState({isLoading:false,applyData:responseValue})
    this.setState({PopupMessage:true,messageshow:'Job Applied Successfully'})
    
    

    
    getStorageData("status",responseValue?.data?.attributes?.status)
    setStorageData("Jobrequestid",responseValue.data.id);
    this.getAppliedjobcallid=await this.apiCallvalue(configJSON.GET_Method, configJSON.getAppliedEndPoint + 1);
    this.getStudioDetails()
    }else{
      alert(responseValue.errors)
      this.setState({
        isLoading:false
      })
    }
  }
  getaddReviewdatalist=(responseValue:any)=>{
    if(responseValue.data)
    {
     
      
    this.setState({isLoading:false})
    this.getStudioRatingDetails()
    this.setState({rateValue:0,comments:''})
      
      
    }
    else{


      alert(responseValue.errors[0])

      


      

      this.setState({isLoading:false})
    
    }
  }
  getsearchjobdatalist=(responseValue:any)=>{
    this.setState({ allJobData: responseValue.data, isLoading: false, })
      this.setState({count:responseValue.meta.count})
  }
  getalljobratingandreview=(responseValue:any)=>{
    this.setState({allJobDetailRatingandReview:responseValue}) 
  }
  getallljobdetaildatallist=(responseValue:any)=>{
    this.setState({ allJobDetail: responseValue.data, isLoading: false, }) 
    this.setState({reviewbleId:responseValue.data?.attributes?.project_detail?.data?.attributes?.account_id,
    })
   
  }
  getalljobdatalist=(responseValue:any)=>{
    this.setState({ allJobData: responseValue.data, isLoading: false, })
      this.setState({count:responseValue.meta.count})
  }
  getmyjobdatalist=(responseValue:any)=>{
    this.setState({ myJobData: responseValue.data ,isLoading:false,count2:responseValue.meta.count})
    
  }

  getapplieddatalist=(responseValue:any)=>{
    if(responseValue.data)
    {
      
    this.setState({ id:responseValue.data.id,appliedJobData: responseValue.data ,isLoading:false,count1:responseValue.meta.count})
   
     
     
    }
  else{
       
      alert(responseValue.errors[0])
  }
  }
  handleOptionChange = (index: any, state: any) => {
    
    this.setState({experiencevalue:state})
    

    
  };
  handleOptionChangedepartment = (index: any, state: any) => {
    
    this.setState({departmentvalue:state})
    

    
  };
  handleOptionChangeroletype = (index: any, state: any) => {
    
    this.setState({roletypevalue:state})
    

    
  };
  handleOptionChangecity = (index: any, state: any) => {
    
    this.setState({cityvalue:state})
    

    
  };
 
  handlPopClose = () => {
    this.setState({ PopupMessage: false });
    console.log("xxxxxxxxxxxxxxxx",this.state.PopupMessage)
   
  }
  handlPopClose1 = () => {
    this.setState({ PopupMessage1: false });
    this.props.navigation.navigate("JobProfile");
    console.log("xxxxxxxxxxxxxxxx",this.state.PopupMessage)
   
  }
  handleModelOpen = () => {
    this.setState({ isModelOpen: true });
  }

  handleModelClose = () => {
    this.setState({ isModelOpen: false, modelType: "" });
  }

  apiCallvalue = async (method: string, endPoint: string) => {
    const authenticationToken = await getStorageData('token', false)
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": authenticationToken
    };
    this.setState({ isLoading: true });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };







  // Customizable Area Start

  async componentDidMount() {
console.log("llllllllllllll",9);

 
    
    
    this.getalljobList();
    
    
      this.getMyjobList();
    
    this.getStudioDetails();
    
     this.getAppliedjobList();
    
     this.getExperienceDetail();
     this.getDepartmentDetail();
     this.getStudioRatingDetails()
     this.getInvoicecount()
     this.getRoleTypeDetail();
     this.getCityDetail();
     let value=await getStorageData("vendorid")
     this.setState({vendorjobid:value})
     let data =await getStorageData("projectid")
     let isSideBarOpen=await getStorageData("sidebarvalue");
     this.setState({selectproject:data,isSideBarOpen:JSON.parse(isSideBarOpen)})
     
      
    window.scrollTo(0, 0);
  }

  

rateHandle=(e:any)=>{
if(e.target.value==='')
{
  this.setState({ratingvalidation:'Please give rating'})
}
else
{
  this.setState({ratingvalidation:''})

}
this.setState({rateValue:e.target.value})
}
 commentsHandle=(e:any)=>{
  if(e.target.value==='')
  {
    this.setState({reviewvalidation:'Please give review'})
  }
  else
  {
    this.setState({reviewvalidation:''})
  
  }
  this.setState({comments:e.target.value})
 }


 handleSideBar =  () => {
    this.setState((prevState) => ({
      isSideBarOpen: !prevState.isSideBarOpen
    }), async() => {
      localStorage.setItem("sidebarvalue",this.state.isSideBarOpen)
      
    });
  };



  handleSideBar1 = () => {

    this.setState({
      isSideBarOpen1: !(this.state.isSideBarOpen1)
    });
  };
  handleDropdown = () => {
    this.setState({
      isDropdownOpen: !this.state.isDropdownOpen
    });
  };

  getStudioDetails = async () => {

    let token = await getStorageData("token");
    let id = await getStorageData("id");
  
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getalljobDetailApiCalledId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getJobDetailApi + `=${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET_Method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getExperienceDetail = async () => {

    let token = await getStorageData("token");
    
  
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getexperienceApiCalledId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.experienceApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET_Method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getDepartmentDetail = async () => {

    let token = await getStorageData("token");
    
  
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getdepartmentApiCalledId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.departmentApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET_Method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  getRoleTypeDetail = async () => {

    let token = await getStorageData("token");
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getroletypeApiCalledId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.RoleTypeApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET_Method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getCityDetail = async () => {
    let token = await getStorageData("token");
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getcityApiCalledId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.cityApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET_Method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  handleNavigation = (page: string) => {
    this.props.navigation.navigate(page)
  };

  navigateToPHDetailPage = (id: any) => {
    setStorageData("phID", id);
    this.handleNavigation("VendorJobPHDetails");
  }

  handleChangeTab = (event: any, newValue: any) => {
    this.setState({ TabValue: newValue })
  };


  getalljobList = async () => {
    this.getalljobcallid = await this.apiCallvalue(configJSON.GET_Method, configJSON.getallJobEndPoint + 1);
  };
  filterHouses = async (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      currentpage: 1,
      searchValue: event.target.value,
    });
    if (event.target.value)
      this.getsearchjobid = await this.apiCallvalue(configJSON.GET_Method, configJSON.getallJobsearchEndPoint + `=${event.target.value}&page=1`);
    else
      this.getalljobcallid = await this.apiCallvalue(configJSON.GET_Method, configJSON.getallJobEndPoint + 1);
  }


  filterHousesmyjob = async (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      currentpage: 1,
      searchValuemyjob: event.target.value,
    });
    if (event.target.value)
      this.getsearchmyjobid = await this.apiCallvalue(configJSON.GET_Method, configJSON.getMyJobSearchEndPoint + `=${event.target.value}&page=1`);
    else
     this.getMyjobcallid=await this.apiCallvalue(configJSON.GET_Method,configJSON.getMyJobEndPoint + 1)
  }
  filterHousesappliedjob = async (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      currentpage: 1,
      searchValueappliedjob: event.target.value,
    });
    if (event.target.value)
      this.getsearchappliedjobid = await this.apiCallvalue(configJSON.GET_Method, configJSON.getAppliedSearchEndPoint + `=${event.target.value}&page=1`);
    else
    this.getAppliedjobcallid=await this.apiCallvalue(configJSON.GET_Method, configJSON.getAppliedEndPoint + 1)
  }
  getMyjobList = async () => {
   this.getMyjobcallid=await this.apiCallvalue(configJSON.GET_Method,configJSON.getMyJobEndPoint + 1)
  };

  getAppliedjobList = async () => {
    this.getAppliedjobcallid=await this.apiCallvalue(configJSON.GET_Method, configJSON.getAppliedEndPoint + 1)
  };
  
  navigateToallJobDetail = (id:any,accountid:any) => {
    // setStorageData("sidebar",this.state.isSideBarOpen.toString())
  
    this.getStudioDetails();
    this.getStudioRatingDetails();
    this.getalljobList()
    
    setStorageData("accountid",accountid);
    this.props.navigation.navigate("UserJobdetail");
    setStorageData("id", id)
  
    
    
  };
  navigateToMyJobDetail = (e:any,id:any,accountid:any,name:any) => {
    e.stopPropagation();
    this.getStudioDetails();
    this.getStudioRatingDetails();
    this.getInvoicecount()
    this.getalljobList()
    setStorageData("accountid",accountid);
    this.props.navigation.navigate("UserMyJobDetail");
    setStorageData("id", id)
  setStorageData("projectvalue",name)
    
    
  };
  navigateToAppliedJobDetail = (id:any,accountid:any,vendorid:any,jobrequest:any) => {
  
    this.getStudioDetails();
    this.getStudioRatingDetails();
    this.getalljobList()
    setStorageData("accountid",accountid);
    this.props.navigation.navigate("UserAppliedJobDetail");
    setStorageData("id", id)
    setStorageData("vendorid",vendorid)
    setStorageData("appliedtime",jobrequest)
  
    
    
  };
  getStudioRatingDetails = async () => {

    let token = await getStorageData("token");
    let accountid=await getStorageData("accountid");
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getJobDetailRatingApiCalledId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getJobDetailRatingEndPoint + `/${accountid}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET_Method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getInvoicecount = async () => {

    let token = await getStorageData("token");
    let projectname=await getStorageData("projectvalue");
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getInvoiceApiCalledId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getinvoicecountendpoint +`=${projectname}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET_Method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handlePageChange = async (event: any, value: number) => {
    if (this.state.searchValue) {
      this.getsearchjobid = await this.apiCallvalue(configJSON.GET_Method, configJSON.getallJobsearchEndPoint + `=${this.state.searchValue}&page=${value}`);
      window.scrollTo(0, 0);
    }
    else {
    
      this.getalljobcallid = await this.apiCallvalue(configJSON.GET_Method, configJSON.getallJobEndPoint + value);
      window.scrollTo(0, 0);
    }
    
    this.setState({ currentpage: value });

  };
  handlePageChange1 = async (event: any, value: number) => {
    
    if (this.state.searchValueappliedjob) {
      this.getsearchappliedjobid = await this.apiCallvalue(configJSON.GET_Method, configJSON.getAppliedSearchEndPoint + `=${this.state.searchValueappliedjob}&page=${value}`);
      window.scrollTo(0, 0);
    }
    else {
      this.getAppliedjobcallid = await this.apiCallvalue(configJSON.GET_Method, configJSON.getAppliedEndPoint + value);
      window.scrollTo(0, 0);
    
    }
    this.setState({ currentpage1: value });

  };
  handlePageChange2 = async (event: any, value: number) => {
    if (this.state.searchValuemyjob) {
      this.getsearchmyjobid = await this.apiCallvalue(configJSON.GET_Method, configJSON.getMyJobSearchEndPoint + `=${this.state.searchValuemyjob}&page=${value}`);
      window.scrollTo(0, 0);
    }
    else{
    this.getMyjobcallid=await this.apiCallvalue(configJSON.GET_Method,configJSON.getMyJobEndPoint + value)
    window.scrollTo(0, 0);
    }
    this.setState({ currentpage2: value });

  };
  handleOpen = () =>{
    
  this.setState({rateValue:0,comments:'',ratingvalidation:'',reviewvalidation:''})
  
     this.setState({open:true})
    
  }
  handleOpen1 = (e:any,id:any,name:any) =>{
    e.stopPropagation();
    setStorageData("projectid",id);
    setStorageData("projectname",name)
     this.setState({open1:true})
     
  }
  handleViewinvoice=async(e:any,name:any)=>{
    e.stopPropagation();
    if(name){
      window.location.replace(`/InvoiceList?projectname=${name}`);
    }else{
      window.location.replace('/InvoiceList')  
    }
   

  }
  handleClose = () => {
    this.setState({open:false})
   
  };
  handleClose1 = (e:any) => {
    e.stopPropagation();
  
    this.setState({open1:false})
  
  };
  handleClosemodel = () => {
    this.setState({ open1: false })

};
  addReviewApiCall = async() => {

    
    

  

    if(this.state.comments===''&&this.state.rateValue===0)
    {
      this.setState({ratingvalidation:"Please give rating",reviewvalidation:'Please give review'})
    }
    else if(this.state.rateValue===0)
    {
      this.setState({ratingvalidation:"Please give rating"})
    }
    else if(this.state.comments==='')
      {
        this.setState({reviewvalidation:'Please give review'})
        
        this.setState({rateValue:0})
        
      }
   
    else
    {
      this.setState({open:false})
          this.setState({isLoading:true})


          let token = await getStorageData("token");

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": token
    };
    const httpBody = {
      "review" : {
        "comment":this.state.comments,
        "given_star":this.state.rateValue,
        "reviewable_id":this.state.reviewbleId,
        "reviewable_type":"AccountBlock::Account"
      }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.addReviewApiCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addReviewApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    }
      
    }

      applyJobApiCall = async() => {
        let token = await getStorageData("token");
        this.setState({isLoading:true,applyvalue:false})
        const header = {
          "Content-Type": "application/json",
          "token": token
        };
        const httpBody = {
          "vendor_job_request": {
          
            "project_id": this.state.allJobDetail.attributes?.project_id,
            "job_role_id": this.state.allJobDetail.id
          }
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        
        this.applyJobApiCallId = requestMessage.messageId;
        
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.applyJobApiEndPoint
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.httpPostMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
          }

          sortApiCallForAllProduction = async () => {
            this.setState({open:false})
            
            const authenticationToken = await getStorageData('token', false)
              const header = {
                "Content-Type": configJSON.exampleApiContentType,
                "token": authenticationToken
              };
                
              
              const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
              );
              
              this.sortapiCallId = requestMessage.messageId;
              requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
              );
              if(this.state.experiencevalue===0&&this.state.cityvalue===''&&this.state.departmentvalue===''&&this.state.roletypevalue===''){
                requestMessage.addData(
                  getName(MessageEnum.RestAPIResponceEndPointMessage),
                  configJSON.sortJobapiEndPoint+`&[filter][city]=&page=1`
                  
                );
              }
              else if(this.state.experiencevalue===0)
              {
                requestMessage.addData(
                  getName(MessageEnum.RestAPIResponceEndPointMessage),
                  configJSON.sortJobapiEndPoint+`&[filter][city]=${this.state.cityvalue}&[filter][project_department]=${this.state.departmentvalue}&[filter][experience]=&[filter][role_name]=${this.state.roletypevalue}&page=1`
                  
                );
              }
              else{
                requestMessage.addData(
                  getName(MessageEnum.RestAPIResponceEndPointMessage),
                  configJSON.sortJobapiEndPoint+`[filter][city]=${this.state.cityvalue}&[filter][project_department]=${this.state.departmentvalue}&[filter][experience]=${this.state.experiencevalue}&[filter][role_name]=${this.state.roletypevalue}&page=1`
                  
                );
              }
              requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.GET_Method
              );
              runEngine.sendMessage(requestMessage.id, requestMessage);
              return requestMessage.messageId;
           
          };
          ClearDataValues = async () => {
            this.setState({experiencevalue:0})
            this.setState({departmentvalue:''})
            this.setState({roletypevalue:''})
            this.setState({cityvalue:''})
            
            this.componentDidMount()
          
            const authenticationToken = await getStorageData('token', false)
              const header = {
                "Content-Type": configJSON.exampleApiContentType,
                "token": authenticationToken
              };
                
              
              const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
              );
          
              this.sortapiCallId = requestMessage.messageId;
              requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
              )
                requestMessage.addData(
                  getName(MessageEnum.RestAPIResponceEndPointMessage),
                  configJSON.configJSON.getallJobEndPoint+`&page=1`
                  
                );
              
              requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.GET_Method
              );
              runEngine.sendMessage(requestMessage.id, requestMessage);
              return requestMessage.messageId;
           
          };
          withdrawJob = async(e:any,id:any)=>{
            e.stopPropagation();
         let token=   await getStorageData('token', false)
            const header = {
              "Content-Type": configJSON.dashboarContentType,
              token: token,
            };
            const requestMessage = new Message(
              getName(MessageEnum.RestAPIRequestMessage)
            );
            this.setState({isLoading:true});
            this.withdrawJobCallId = requestMessage.messageId;
            requestMessage.addData(
              getName(MessageEnum.RestAPIResponceEndPointMessage),
              configJSON.withdrawJobApiEndPoint1 +id
            );
        
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.studioUpdateApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
          }  
          handleChangeinvoice = (event: any) => {
            this.setState({invoiceselect:event.target.value});
        };
    
        handleChangeradio = (event: any) => {
          this.setState({selectinvoicetype:event.target.value})
      };
      handleChangeName = (event: any) => {
        this.setState({selectinvoicename:event.target.value})
    }
    handleChangeDetail = (event: any) => {
      this.setState({selectinvoicedetail:event.target.value})
  };
  handleChangeProject = async(event: any) => {
    const data=await getStorageData("projectid")
    this.setState({selectproject:data})
};
handleChangeNo = (event: any) => {
  this.setState({selectinvoiceno:event.target.value})
};
handleChangeAmount = (event: any) => {
  this.setState({selectinvoiceamount:event.target.value})
};
handleImage = async(e: any) => {
      this.setState({ image: e.target.files[0] });
     
    };


    raiseinvoiceData = async () => {
       

       
      const tokenvalue = await getStorageData("token",false);
      let data =await getStorageData("projectid")
      const header = {
        "token": tokenvalue
      };
      this.setState({isLoading:true})
      const formDatavalue = new FormData();
      formDatavalue.append("invoice[add_note]", this.state.selectinvoicedetail);
      formDatavalue.append("invoice[invoice_name]", this.state.selectinvoicename);
      formDatavalue.append("invoice[invoice_id]", this.state.selectinvoiceno);
      formDatavalue.append("invoice[standard_pay]", this.state.selectinvoicetype);
      formDatavalue.append("invoice[project_id]",data);
      formDatavalue.append("invoice[invoice_receipt]",this.state.image) 
      formDatavalue.append("invoice[type_of_invoice]", this.state.invoiceselect);
      formDatavalue.append("invoice[total_amount]", this.state.selectinvoiceamount);
      
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.raiseinvoiceapicallid = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
     
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.raiseinvoiceendpoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formDatavalue
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.raiseinvoicemethodtype

      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      
  }
  handledatavalue = (id:any) => {
    this.props.navigation.navigate("UserinvoiceDetail");
    setStorageData("id", id)   
    
  };
  leaveProject = async(projectId:any, jobId:any)=>{
    const authenticationToken = await getStorageData('token', false)
              const header = {
                "Content-Type": configJSON.exampleApiContentType,
                "token": authenticationToken
              };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.leaveProjectCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.leaveProjectApiEndPoint +"project_id="+projectId+"&job_role_id="+jobId
    );

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  configJSON.studioUpdateApiMethodType
);
runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  // Customizable Area End
}
