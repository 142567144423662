import React from "react";

import {
  Container,
  Box,
  Typography,
  // Customizable Area Start
  Grid,
  TypographyProps,
  Link,
  TextField,
  Select,
  MenuItem,
  TextareaAutosize,
  Divider,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import HelpCenterController, {
  Props,
} from "./HelpCenterController";
import Header from "../../../components/src/Header.web";
import Footer from "../../../components/src/Footer";
import SideBar from "../../../components/src/SideBar";
import { createTheme, styled, ThemeProvider, withStyles } from "@material-ui/core/styles";
import Tooltip from '@material-ui/core/Tooltip';
import { CustomButton, CustomInputlabelEmail } from "../../email-account-login/src/CustomButton";
import PhoneIcon from '@material-ui/icons/Phone';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import CustomNotification from "../../../components/src/CustomNotification";
import HCRequestSubmitSuccessModel from "./HCRequestSubmitSuccessModel.web";
import Loader from "../../../components/src/Loader.web";
const theme = createTheme({
  palette: {
    primary: {
      main: "#f5c42c",
      contrastText: "#fff",
    },
  },
});

const CustomHCCSSOfTextField = withStyles({
  root: {
    backgroundColor: 'white',
    borderColor: "#f5c42c",
    "& .MuiOutlinedInput-root": {
      "& fieldset":
        { borderColor: "#E3EAF4" },
      "&:hover fieldset":
        { borderColor: "#f5c42c" },
      "&.Mui-focused fieldset":
      {
        backgroundColor: 'rgba(245,196,44,0.04)',
        borderColor: "#f5c42c",
      },
      color: '#323232',
      borderRadius: "14px",
      fontWeight: 400,
      "& .MuiOutlinedInput-input":
      {
        color: '#17181D',
        fontWeight: 600,
        fontSize: '14px',
        borderColor: '#e3eaf4',
        lineHeight: '19px',
        fontFamily: 'Urbanist',
      }
    },
    "& .MuiInputBase-input::placeholder": {
      color: '#6d89af',
      fontWeight: 500,
      fontFamily: 'Urbanist',
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#f5c42c"
    },
  }
})(TextField);

const CustomButtonBox = styled(CustomButton)({
  '& .MuiButton-label': {
    fontWeight: 600,
    fontSize: 16,
    letterSpacing: '0.45714286px',
    lineHeight: '24px',
  }
});
// Customizable Area End

export default class HelpCenter extends HelpCenterController {

  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  helpCenterFontFamilyImportantDesign = (helpCenterFont: any) => {
    if (helpCenterFont) {
      helpCenterFont.style.setProperty('font-family', 'Urbanist', 'important');
    }
  }

  helpCenterRenderErrorMessage(errorType: boolean, errorMessage: string) {
    return <>{errorType ? <Typography style={{ color: 'red', fontFamily: 'Urbanist', fontSize: '13px', fontWeight: 600, lineHeight: '18px', letterSpacing: '0.4px' }}>{errorMessage}</Typography> : null}</>
  }

  renderHelpCenterPageMainContent(
    sideBarHelpCenterStatus: boolean
  ) {
    return (<>
      <Loader loading={this.state.isLoading} />
      <Grid style={webStyle.contentHelpCenterSetup}>
        <Header
          handleSideBar={this.handleHelpCenterSideBar}
          dropDownStatus={this.state.isHeaderDropdownOpenVal}
          navigate={this.props.navigation.navigate}
          handleDropdown={this.handleHeaderDropdownVal}
          sideBarStatus={sideBarHelpCenterStatus}
        />
        <Grid style={webStyle.mainHelpCenterContent as React.CSSProperties}>
          <Box style={{ marginInline: "24px" }}>
            <Typography
              variant="h3"
              style={webStyle.activeHelpCenterBlock as TypographyProps}
              ref={this.helpCenterFontFamilyImportantDesign}
            >
              Help Center
            </Typography>
            <Typography
              variant="h6"
              style={webStyle.helpCenterBlockPath as TypographyProps}
              ref={this.helpCenterFontFamilyImportantDesign}
            >
              <span style={webStyle.highlightedHelpCenterRouteText}>
                <Link data-test-id="homeLink" color="inherit" onClick={() => this.handlePageNavigation("JobProfile")}>
                  Home
                </Link>
              </span>
              {" "}/ Help Center
            </Typography>
          </Box>
          <Grid container style={{ marginTop: "12px", marginInline: "12px" }}>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Box style={{ margin: "12px", height: "408px", borderRadius: "12px", border: "1px solid #e3eaf4", background: "#ffffff" }}>

                <Grid item style={{ padding: "16px", paddingBottom: "0px" }} xs={12} sm={12} md={12} lg={12}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <CustomInputlabelEmail id="hcTopicInput" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px", paddingTop: "0px" }}>Select Topic</CustomInputlabelEmail>
                  </Grid>
                  <Grid className="roleDropdown" item xs={12} sm={12} md={12} lg={12}>
                    <Select
                      data-test-id="hcTopicInput"
                      id="demo-simple-select"
                      value={this.state.hcTopic || ""}
                      label="Age"
                      displayEmpty
                      style={{ height: "48px" }}
                      onChange={this.handleChangeHCTopicDetails}
                      onFocus={() => {
                        this.handleInputFocus("hcTopicInput");
                      }}
                      onBlur={() => {
                        this.handleInputBlur("hcTopicInput");
                      }}
                    >
                      <MenuItem value="">Select your reason</MenuItem>
                      {this.state.hcTopicList.length ? this.state.hcTopicList.map((topic: any) => {
                        return <MenuItem key={"topic" + topic.id} value={topic.id}>{topic.title}</MenuItem>
                      }) : null}
                    </Select>
                    {this.helpCenterRenderErrorMessage(this.state.hcTopicError.require, "Your topic is required")}
                  </Grid>
                </Grid>

                <Grid item style={{ padding: "16px", paddingBottom: "0px" }} xs={12} sm={12} md={12} lg={12}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <CustomInputlabelEmail id="hcEmailInput" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px", paddingTop: "0px" }}>Your email ID</CustomInputlabelEmail>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <CustomHCCSSOfTextField
                      onFocus={() => {
                        this.handleInputFocus("hcEmailInput");
                      }}
                      onBlur={() => {
                        this.handleInputBlur("hcEmailInput");
                      }}
                      data-test-id="hcEmailInput"
                      type={'text'}
                      className="email_input"
                      id="outlined-basic"
                      onChange={this.handleChangeHCEmailDetails}
                      variant="outlined"
                      value={this.state.hcEmail}
                      placeholder="Enter your email ID"
                    />
                    {this.helpCenterRenderErrorMessage(this.state.hcEmailError.invalid, "Invalid email ID")}
                    {this.helpCenterRenderErrorMessage(this.state.hcEmailError.require, "email ID is required")}
                  </Grid>
                </Grid>

                <Grid item style={{ padding: "16" }} xs={12} sm={12} md={12} lg={12}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <CustomInputlabelEmail id="hcCommentInput" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px", paddingTop: "0px" }}>Your Comment</CustomInputlabelEmail>
                  </Grid>
                  <TextareaAutosize
                    style={{ width: "100%", height: "90px", border: "1px solid #e3eaf4", borderRadius: "14px", padding: "15px 13px" }}
                    className="bookstudiotextarea helpCenterTextArea"
                    aria-label="minimum height"
                    minRows={3}
                    onFocus={() => {
                      this.handleInputFocus("hcCommentInput");
                    }}
                    onBlur={() => {
                      this.handleInputBlur("hcCommentInput");
                    }}
                    data-test-id="hcCommentInput"
                    placeholder="Enter your comment"
                    value={this.state.hcComment}
                    onChange={this.handleChangeHCCommentDetails}
                  />
                  {this.helpCenterRenderErrorMessage(this.state.hcCommentError.require, "Comment is required")}
                </Grid>

                <Grid container style={{ padding: "16px", display: "flex", justifyContent: "end" }}>
                  <CustomButtonBox onClick={this.handleFormSubmit} data-test-id="help_center_submit" style={{ borderRadius: "14px", height: 48, background: "#17181d", width: "178px", color: "#ffffff" } as React.CSSProperties}>
                    Submit
                  </CustomButtonBox>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Box style={{ margin: "12px", height: "408px", borderRadius: "12px", border: "1px solid #e3eaf4", background: "#ffffff" }}>
                <Grid container>
                  <Grid item>
                    <Typography style={{ paddingInline: "16px", paddingTop: "16px", paddingBottom: "8px", color: "#17181d", fontFamily: "Urbanist", fontSize: "16px", fontWeight: 600, lineHeight: "18px", letterSpacing: "0.24615385px" }}>Contact us on :</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                    <Divider style={{ margin: "0px 15px 14px 15px" }} />
                  </Grid>
                  <Grid style={{ display: "flex" }} item xs={12} sm={12} md={12} lg={12} xl={12} >
                    <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "0px 12px 16px 16px", borderRadius: "50%", backgroundColor: "rgba(245,196,44,0.12)", width: "50px", height: "50px" }}>
                      <PhoneIcon htmlColor="#f5c42c" style={{ width: "26px", height: "26px" }} />
                    </Box>
                    <Box>
                      <Tooltip title={this.state.firstPhoneCopy} placement="top-end" arrow>
                        <Typography data-test-id="firstPhoneNumber" onClick={() => this.copyPhoneNumber(this.state.contactDetails?.first_full_phone_number)} onMouseLeave={this.handleMouseLeave} style={{ lineHeight: "22px", color: "#f5c42c", fontFamily: "Urbanist", fontSize: "18px", fontWeight: 600, textDecoration: "underline", letterSpacing: "0.2769231px" }}>{this.state.contactDetails?.first_full_phone_number}</Typography>
                      </Tooltip>
                      <Tooltip title={this.state.firstPhoneCopy} placement="bottom-end" arrow>
                        <Typography data-test-id="secondPhoneNumber" onClick={() => this.copyPhoneNumber(this.state.contactDetails?.second_full_phone_number)} onMouseLeave={this.handleMouseLeave} style={{ paddingTop: "6px", lineHeight: "22px", color: "#f5c42c", fontFamily: "Urbanist", fontSize: "18px", fontWeight: 600, textDecoration: "underline", letterSpacing: "0.2769231px" }}>{this.state.contactDetails?.second_full_phone_number}</Typography>
                      </Tooltip>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item>
                    <Typography style={{ paddingInline: "16px", paddingTop: "16px", paddingBottom: "8px", color: "#17181d", fontFamily: "Urbanist", fontSize: "16px", fontWeight: 600, lineHeight: "18px", letterSpacing: "0.24615385px" }}>Email us on :</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                    <Divider style={{ margin: "0px 15px 14px 15px" }} />
                  </Grid>
                  <Grid style={{ display: "flex" }} item xs={12} sm={12} md={12} lg={12} xl={12} >
                    <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "0px 12px 16px 16px", borderRadius: "50%", backgroundColor: "rgba(245,196,44,0.12)", width: "50px", height: "50px" }}>
                      <MailOutlineIcon htmlColor="#f5c42c" style={{ width: "26px", height: "26px" }} />
                    </Box>
                    <Box>
                      <Typography data-test-id="firstEmail" onClick={() => this.openComposeEmail(this.state.contactDetails?.first_email)} style={{ lineHeight: "22px", color: "#f5c42c", fontFamily: "Urbanist", fontSize: "18px", fontWeight: 600, textDecoration: "underline", letterSpacing: "0.2769231px", cursor: "pointer" }}>{this.state.contactDetails?.first_email}</Typography>
                      <Typography data-test-id="secondEmail" onClick={() => this.openComposeEmail(this.state.contactDetails?.first_email)} style={{ paddingTop: "6px", lineHeight: "22px", color: "#f5c42c", fontFamily: "Urbanist", fontSize: "18px", fontWeight: 600, textDecoration: "underline", letterSpacing: "0.2769231px", cursor: "pointer" }}>{this.state.contactDetails?.second_email}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid >
      <Footer />
    </>
    );
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container style={webStyle.parentHelpCenterDiv}>
          {
            this.state.isHelpCenterSideBarOpen ?
              <Grid container spacing={0}>
                <Grid item xs={3} style={webStyle.sidebarHelpCenterParent}>
                  <SideBar
                    activeMenuItem={"Help Center"}
                    handleSideBar={this.handleHelpCenterSideBar}
                    navigate={this.props.navigation.navigate}
                    userData={{ age: 25 }} />
                </Grid>
                <Grid item xs={9} style={{ display: "flex", flexDirection: "column" }}>{this.renderHelpCenterPageMainContent(this.state.isHelpCenterSideBarOpen)}</Grid>
              </Grid> :
              <Grid item xs={12} style={{ display: "flex", flexDirection: "column" }}>
                {this.renderHelpCenterPageMainContent(this.state.isHelpCenterSideBarOpen)}
              </Grid>
          }
        </Container>
        {this.state.isHcModelOpen ? <HCRequestSubmitSuccessModel open={this.state.isHcModelOpen} id={this.props.id} navigation={this.props.navigation} handleClose={this.hcHandleClose} /> : null}
        <CustomNotification navigation={(route: string) => this.props.navigation.navigate(route)} notification={this.state.helpCenterNotification} handleCloseNotification={this.handleHelpCenterCloseNotification} />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyle = {
  parentHelpCenterDiv: {
    padding: "0px",
    maxWidth: "none"
  },
  sidebarHelpCenterParent: {
    minHeight: "100vh",
    maxWidth: "25%"
  },
  contentHelpCenterSetup: {
    borderLeft: "1px solid #E3EAF4",
    flex: 1,
  },
  mainHelpCenterContent: {
    width: "100%",
    minHeight: "100vh",
    backgroundColor: "#f8fafe",
    boxSizing: "border-box",
    paddingTop: '24px',
    paddingBottom: '24px',
  },
  activeHelpCenterBlock: {
    lineHeight: "34px",
    fontSize: "28px",
    fontWeight: "700",
    letterSpacing: "1px",
    color: "#17181d",
    marginBottom: "8px",
  },
  helpCenterBlockPath: {
    fontSize: "14px",
    letterSpacing: "1px",
    color: "#6D89AF",
    fontWeight: "500",
  },
  highlightedHelpCenterRouteText: {
    color: '#f5c42c',
    fontSize: '14px',
    fontWeight: 600,
    fontFamily: 'Urbanist',
    cursor: 'pointer',
    textDecoration: 'underline'
  },
};
// Customizable Area End
