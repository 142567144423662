import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { IBreadCrum } from "./BreadCrumController";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isSiderBarOpenValue: boolean;
  isDropDwnOpen: boolean;
  id: string;
  token: string;
  userPersonalDetail: any;
  isLoading: boolean;
  allProjects: [],
  upcomingProjects: [],
  ongoingProjects: [],
  completedProjects: [],
  rating: any;
  activeTab: string;
  bList: IBreadCrum[],
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class UserDetailController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUserpersonalDetailApiCalledId: string = "";
  putVendorDataApiCalledId: string = "";
  getVendorAllProjectsApiCalledId: string = "";
  getVendorUpcomingProjectsApiCalledId: string = "";
  getVendorOngoingProjectsApiCalledId: string = "";
  getVendorCompletedProjectsApiCalledId: string = "";
  getVendorRatingDataApiCalledId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isSiderBarOpenValue: true,
      isDropDwnOpen: false,
      id: "",
      token: "",
      userPersonalDetail: {
        activated: '',
        blocked: '',
        email: '',
        first_name: '',
        full_phone_number: '',
        last_name: '',
        country_code: '',
        phone_number: '',
        full_name: "",
      },
      isLoading: false,
      allProjects: [],
      upcomingProjects: [],
      ongoingProjects: [],
      completedProjects: [],
      rating: {},
      activeTab: "overview",
      bList: [
        {
          text: 'Home',
          href: 'Dashboard',
          clickable: true,
          page: 'Dashboard'
        },
        {
          text: 'Vendors',
          href: '/',
          clickable: false,
          page: ''
        },
        {
          text: 'Vendor Details',
          href: '/',
          clickable: false,
          page: ''
        }
      ],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    let token = await getStorageData("token");
    let id = await getStorageData("id");
    this.setState({ token: token, id: id }, async () => {
      this.getUserpersonalDetailApiCalledId = await this.getVendorDetailApiCall(configJSON.GET_Method, configJSON.getUserPersonalDetailApiEndPoint + this.state.id);
      this.getVendorAllProjectsApiCalledId = await this.getVendorDetailApiCall(configJSON.GET_Method, configJSON.getVendorAllProjects + this.state.id);
      this.getVendorUpcomingProjectsApiCalledId = await this.getVendorDetailApiCall(configJSON.GET_Method, configJSON.getVendorUpcomingProjects + this.state.id);
      this.getVendorOngoingProjectsApiCalledId = await this.getVendorDetailApiCall(configJSON.GET_Method, configJSON.getVendorOngoingProjects + this.state.id);
      this.getVendorCompletedProjectsApiCalledId = await this.getVendorDetailApiCall(configJSON.GET_Method, configJSON.getVendorCompletedProjects + this.state.id);
      this.getVendorRatingDataApiCalledId = await this.getVendorDetailApiCall(configJSON.GET_Method, configJSON.getVendorRating + this.state.id);
    });
    let isSiderBarOpenValue=await getStorageData("sidebarvalue");
    this.setState({isSiderBarOpenValue:JSON.parse(isSiderBarOpenValue)})
    window.scrollTo(0, 0);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (this.getUserpersonalDetailApiCalledId == apiRequestCallId) {
        if (responseJson.data) {
          this.setState({
            id: responseJson.data.id,
            userPersonalDetail: { ...this.state.userPersonalDetail, ...responseJson.data.attributes }
          });
        }
      } else if (this.putVendorDataApiCalledId === apiRequestCallId) {
        this.putVendorDataHandleResponse(responseJson);
      } else if (this.getVendorAllProjectsApiCalledId === apiRequestCallId) {
        this.getVendorDetailTabsDataHandleResponse(responseJson, "allProjects");
      } else if (this.getVendorUpcomingProjectsApiCalledId === apiRequestCallId) {
        this.getVendorDetailTabsDataHandleResponse(responseJson, "upcomingProjects");
      } else if (this.getVendorOngoingProjectsApiCalledId === apiRequestCallId) {
        this.getVendorDetailTabsDataHandleResponse(responseJson, "ongoingProjects");
      } else if (this.getVendorCompletedProjectsApiCalledId === apiRequestCallId) {
        this.getVendorDetailTabsDataHandleResponse(responseJson, "completedProjects");
      } else if (this.getVendorRatingDataApiCalledId === apiRequestCallId) {
        this.getVendorDetailRatingTabDataHandleResponse(responseJson);
      }
    }
  }
  // Customizable Area End

  // Customizable Area Start
  putVendorDataHandleResponse = (responseJson: { data: any }) => {
    if (responseJson.data) {
      this.setState({ userPersonalDetail: { ...this.state.userPersonalDetail, blocked: !this.state.userPersonalDetail.blocked } })
    }
    else {
      this.showAlert("Error", "Error");
    }
  }

  getVendorDetailTabsDataHandleResponse = (responseJson: { data: any }, stateName: string) => {
    if (responseJson.data) {
      if (stateName === "allProjects")
        this.setState({ allProjects: responseJson.data, isLoading: false });
      else if (stateName === "upcomingProjects")
        this.setState({ upcomingProjects: responseJson.data, isLoading: false });
      else if (stateName === "ongoingProjects")
        this.setState({ ongoingProjects: responseJson.data, isLoading: false });
      else if (stateName === "completedProjects")
        this.setState({ completedProjects: responseJson.data, isLoading: false });
    }
    else {
      this.setState({ isLoading: false });
      this.showAlert("Error", "Error");
    }
  }

  getVendorDetailRatingTabDataHandleResponse = (vendorResponseJson: any) => {
    if (vendorResponseJson.error) {
      this.setState({ isLoading: false });
      this.showAlert("Error", `For rating and review ${vendorResponseJson.error}`);
    } else {
      this.setState({
        rating: vendorResponseJson,
        isLoading: false
      });
    }
  }

  handleSideDropdownOpenVal = () => {
    this.setState({ isSiderBarOpenValue: !this.state.isSiderBarOpenValue },
      async() => {
        localStorage.setItem("sidebarvalue",this.state.isSiderBarOpenValue.toString())
        
      }
      );
  };

  handleDropdownValue = () => {
    this.setState({ isDropDwnOpen: !this.state.isDropDwnOpen });
  };

  handleChangeTabValue = (tabValue: string) => {
    this.setState({ activeTab: tabValue });
  };

  navigateToAllVendor = () => {
    this.props.navigation.navigate("FullTeamVisibility");
  }

  getVendorDetailApiCall = async (method: string, endPoint: string) => {
    this.setState({ isLoading: true });
    const headerValue = {
      "token": this.state.token,
      "Content-Type": configJSON.exampleApiContentType,
    };
    const getApiRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getApiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerValue)
    );
    getApiRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    getApiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(getApiRequestMessage.id, getApiRequestMessage);
    return getApiRequestMessage.messageId;
  };

  handleVendorBadgeVisibility = async (e: any, id: any, blockStatus: boolean, type?: string) => {
    e.stopPropagation();
    const token = await getStorageData("token", false);

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const attrs = {
      blocked: !blockStatus
    };
    this.putVendorDataApiCalledId = requestMessage.messageId;

    const data = {
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.putApiForAllVendorsEndPoint + id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.put_method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}