import React from "react";

import {
    // Customizable Area Start
    TypographyProps,
    Container,
    Grid,
    Box,Typography,Link
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import { arrowicon } from "./assets";
import MyJobDetail from "../../../components/src/MyJobDetail";
import JobProfileController,{ Props } from "./JobProfileController";
import Header from "../../../components/src/Header.web";
import SideBar from "../../../components/src/SideBar";
import Footer from "../../../components/src/Footer";
import Loader from "../../../components/src/Loader.web";
import Modal from '@material-ui/core/Modal';
import { createTheme, ThemeProvider} from "@material-ui/core/styles";
import Raiseinvoicefunctionalitymyjob from "../../../components/src/Raiseinvoicefunctioanlitymyjob";
import SuccessfullmodelRaiseInvoice from "./SuccessfullmodelRaiseInvoice.web";
const theme = createTheme({
    palette: {
        primary: {
            main: "#f5c42c",
            contrastText: "#fff",
        },
    },
});
// Customizable Area End

export default class UserMyJobDetail extends JobProfileController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    renderMainContentMyJobDetail(sideBarStatus: boolean) {
        return <Grid style={webStyle.contentSetupMyjob}>
              <Loader loading={this.state.isLoading}></Loader>
            <Header
             
                sideBarStatus={this.state.isSideBarOpen}
                dropDownStatus={this.state.isDropdownOpen}
                handleDropdown={this.handleDropdown}
                handleSideBar={this.handleSideBar}  
                navigate={this.props.navigation.navigate}
            />
            <Grid style={webStyle.mainContentMyjob as React.CSSProperties}>
                <Box sx={webStyle.blockInfoMyjob}>
                <Typography variant="h3" style={webStyle.activeBlockMainTitlemyjob as TypographyProps}>Project Details</Typography>
                <Typography variant="h6" style={webStyle.blockE2EPathmyjob as TypographyProps}>
                <span style={webStyle.highlightedTitleextmyjob}>
                  <Link data-test-id="homeLinkmyjob" color="inherit" onClick={() => this.handleNavigation("JobProfile")}>
                    Home
                  </Link>
                </span>
                <span style={webStyle.highlightedTitleextmyjob}>
                  <Link data-test-id="myjobdetail" color="inherit" onClick={() => this.handleNavigation("JobProfile")}>
                    {" "}/ My Jobs 
                  </Link>
                </span>
                {" "}/{" "}{this.state.allJobDetail?.attributes?.role}
              </Typography>
                </Box>
                <MyJobDetail navigateToPHDetailPage={this.navigateToPHDetailPage} ratingvalidation={this.state.ratingvalidation} reviewvalidation={this.state.reviewvalidation} data={this.state.allJobDetail} datavalue={this.state.allJobDetailRatingandReview} navigate={this.props.navigation.navigate}  open={this.state.open} handleOpen={this.handleOpen} handleClose={this.handleClose} rateHandle={this.rateHandle} ratevalue={this.state.rateValue} comments={this.state.comments} commentsHandle={this.commentsHandle} addReviewApiCall={this.addReviewApiCall} handleOpen1={this.handleOpen1} leaveProject={this.leaveProject} handleViewinvoice={this.handleViewinvoice} allinvoicecount={this.state.allinvoicecount}/>
            </Grid>
            <Footer />
        </Grid>
         
    }
    
    render() {
        return ( 
            <ThemeProvider theme={theme}>
            <Container maxWidth="xl" className="user_detail_container">
                {
                    this.state.isSideBarOpen?
                        <Grid container spacing={0}>
                            <Grid style={webStyle.sideBarMyjob}  item xs={3}>
                                <SideBar
                                   userData={{ age: 25 }}
                                    activeMenuItem={"Jobs"}
                                    handleSideBar={this.handleSideBar}
                                    navigate={this.props.navigation.navigate}
                                    />
                            </Grid>
                            <Grid item xs={9} style={{ flexBasis: "77.75%", maxWidth: "77.75%" }}>{this.renderMainContentMyJobDetail(this.state.isSideBarOpen)}</Grid>
                        </Grid> :
                        <Grid item xs={12}>
                            {
                                this.renderMainContentMyJobDetail(this.state.isSideBarOpen)
                            }
                           </Grid>
                }
            </Container >
            <Modal
                    open={this.state.open1}
                    onClose={(e: any) => this.handleClose1(e)} // This handles clicking anywhere outside the modal
                    aria-describedby="simple-modal-description"
                    aria-labelledby="simple-modal-title"
                >
                    <div style={{ // You can style your modal here
                        top: '50%',
                        position: 'absolute',
                        left: '50%',
                        backgroundColor: 'white',
                        borderRadius: '20px',
                        transform: 'translate(-50%, -50%)',
                    }}>
                        <Raiseinvoicefunctionalitymyjob data={""} handleClose1={this.handleClose1} invoiceselect={this.state.invoiceselect} handleChangeinvoice={this.handleChangeinvoice} handleChangeradio={this.handleChangeradio} selectinvoicetype={this.state.selectinvoicetype} handleChangeName={this.handleChangeName} selectinvoicename={this.state.selectinvoicename} selectinvoicedetail={this.state.selectinvoicedetail} handleChangeDetail={this.handleChangeDetail} selectproject={this.state.selectproject} handleChangeProject={this.handleChangeProject} handleChangeNo={this.handleChangeNo} selectinvoiceno={this.state.selectinvoiceno} handleChangeAmount={this.handleChangeAmount} selectinvoiceamount={this.state.selectinvoiceamount} handleImage={this.handleImage} image={this.state.image} raiseinvoiceData={this.raiseinvoiceData}/>
                    </div>
                </Modal>
                {this.state.isModelOpen &&this.state.modelType === "successModel" ? <SuccessfullmodelRaiseInvoice navigation={this.props.navigation} open={this.state.isModelOpen} handleClose={this.handleModelClose} handlevalue={this.handledatavalue} raiseinvoicedetail={this.state.raiseinvoicedetail} /> : null}
            </ThemeProvider>
        );  }
}

// Customizable Area Start
const webStyle = {
    contentSetupMyjob: {
        borderLeft: "1px solid #E3EAF4",
    },
    highlightedTitleextmyjob: {
        fontFamily: "Urbanist",
        color: "#F5C42C",
        textDecoration: "underline",
        cursor: "pointer",
        fontWeight: 600,
      },
      activeBlockMainTitlemyjob: {
        lineHeight: "34px",
        fontSize: "28px",
        color: "#17181D",
        fontWeight: "700",
        letterSpacing: "1px",
      },
      blockE2EPathmyjob: {
        fontSize: "14px",
        letterSpacing: "1px",
        fontWeight: 500,
        color: "#6D89AF",
        lineHeight: "17px",
        paddingTop: "8px",
      },
    containerMyjob: {
        paddingRight: '0px !important',
        paddingLeft: '0px !important',
    },
    sideBarMyjob: {
        minHeight: "100vh",
        maxWidth: "22.25%",
    },
    blockInfoMyjob: {
        paddingBottom:'24px'

    },
    blockPathMyjob: {
        fontSize: "14px",
        letterSpacing: "1px",
        color: "#6D89AF",
        marginBottom: "24px",
        fontWeight: 500
      },
      highlightedTextMyjob: {
        color: "#F5C42C",
        textDecoration: 'underline',
        textDecorationColor: "#F5C42C",
        fontWeight: 600,
        cursor:"pointer"
      },
    activeBlockMyjob: {
        fontSize: "28px",
        fontWeight: "600",
        letterSpacing: "1px",
        display: "flex",
        flexDirection: "row",
        lineHeight: "33px",
        alignItems: 'center',
        gap: '12px',
        marginBottom: '8px',   
      },
     
      mainContentMyjob: {
        width: "100%",
        backgroundColor: "#F8FAFE",
        boxSizing: "border-box",
        paddingLeft: "24px",
        paddingTop:'24px',
        paddingRight:'24px',
        paddingBottom:'24px'
      },
};
// Customizable Area End