import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  open: boolean;
  handleClose: any;
  errors: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class UploadCSVErrorMessagesModelController extends BlockComponent<
  Props, S, SS
> {
  constructor(props: Props) {
    super(props); 
    this.receive = this.receive.bind(this); 

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage)

      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start

      // Customizable Area End
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  // Customizable Area Start
  // Customizable Area End

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start

    // Customizable Area End
  }


  // Customizable Area Start

  // Customizable Area End
}
