import React from "react";

import {
    // Customizable Area Start
    Box,
    Typography
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { webStyle } from "./FullTeamVisibility.web";
import { calenderIcon, switchEnable, switchDisable } from "./assets";
import "./fullVisibility.css";
import CardFooterController, { Props } from "./CardFooterController";
export const configJSON = require("./config");

// Customizable Area End

export default class CardFooter extends CardFooterController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { item, id }: { item: any, id: string } = this.props;
        // Customizable Area End
        return (
            // Customizable Area Start
            <Box sx={webStyle.box}>
                <Typography className="calender_typo">
                    <img src={calenderIcon} style={{ marginRight: "3px" }} />
                    Joined on: {item.joinDate}
                </Typography>
                <Typography className="block">
                    {item && item.blocked ? <Typography className="block_color">{configJSON.blocked}</Typography> : <Typography className="block">{configJSON.block}</Typography>}
                    {item && item.blocked
                        ?
                        <img src={switchEnable}
                            className="block_box"
                            data-test-id="switch_En"
                            onClick={(e: any) => this.props.handleBadgeVisibility(e, id, item.blocked)}
                        />
                        :
                        <img src={switchDisable}
                            className="block_box"
                            data-test-id="switch_disable"
                            onClick={(e: any) => this.props.handleBadgeVisibility(e, id, item.blocked)}
                        />
                    }
                </Typography>
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
// Customizable Area End