import React, {createRef} from "react";

import {
    // Customizable Area Start
    TypographyProps,
    Container,
    Grid,
    Box,Typography, Link,
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import InvoiceDetailCard from "../../../components/src/InvoiceDetailCard";
import InvoiceListingDetailcard from "../../../components/src/InvoiceListingDetailcard";
import InvoiceBillingController, {
    Props,
    configJSON,
  } from "./InvoiceBillingController.web";
import Header from "../../../components/src/Header.web";
import SideBar from "../../../components/src/SideBar";
import Footer from "../../../components/src/Footer";
import Loader from "../../../components/src/Loader.web";
import { arrowicon } from "./assets";
// Customizable Area End

export default class InvoiceDetailPH extends InvoiceBillingController {
    stepperRef: React.RefObject<any>;
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.stepperRef = createRef();
        // Customizable Area End
    }
   
    

    renderMainInvoiceProfile(invoicesideBarStatusPH: boolean  = this.state.isinvoiceBillingSideBarOpen) {
        return (
        <Grid style={webStyle.contentSetupinvoiceProdHouse}>
              <Loader loading={this.state.isLoading}></Loader>
            < Header
                navigate={this.props.navigation.navigate}
                sideBarStatus={invoicesideBarStatusPH}
                dropDownStatus={this.state.isInvoiceHeaderDropdownOpenVal}
                handleDropdown={this.handleInvoiceHeaderDropdownVal}
                handleSideBar={this.handleInvoiceSideBar}  
            />
            <Grid style={webStyle.mainContentinvoiceProdHouse as React.CSSProperties}>
                <Box sx={webStyle.blockInfoinvoiceProdHouse}>
                    <Box sx={webStyle.activeBlockinvoiceProdHouseArrow}>
                        <div  data-test-id="invoice-page" onClick={()=> this.navigateToInvoiceBillingPage()}> 
                            <img src={arrowicon} alt="arrow icon"/>
                        </div>
                    </Box>
                    <Box>
                        <Typography variant="h3" style={webStyle.activeBlockinvoiceProdHouse as TypographyProps}>Invoice Details</Typography>
                        <Link variant="h6" style={webStyle.blockPathinvoiceProdHouse as TypographyProps} data-test-id="allInvoice" onClick={()=> this.handleNavigationPH("AllInvoiceRequests")}>
                            Home
                        </Link>    
                        <Link variant="h6" style={webStyle.blockPathinvoiceProdHouse as TypographyProps} data-test-id="invoicePage"  onClick={()=> this.handleNavigationPH("InvoiceBilling")}>
                            / Invoice Requests 
                        </Link> 
                        <Link variant="h6" style={webStyle.blockPathinvoiceProdHouse as TypographyProps}  data-test-id="invoiceBill" onClick={()=> this.handleNavigationPH("InvoiceBilling")}> / {this.state.projectName}</Link>
                        <span style={{ color: "#6D89AF",fontWeight:600 ,fontSize: "14px"}}> / Invoice Details</span>
                         
                    </Box>
                </Box>
                <InvoiceListingDetailcard data={this.state.invoiceDetailsData} handleOpen={this.handleOpen3PhInvoice} handleClose={this.handleClose3PhInvoice} open={this.state.open3} handleCloseHold={this.handleCloseHoldPH}  handleOpenHold={this.handleOpenHoldPH} openHold={this.state.openHold} RejectCommentvalue={this.state.RejectCommentValue} HandleComment={this.CommentsHandlePH}
             handlehiddenandshow={this.handlehiddenandshowPhInvoice} handleHideAndShowSecond={this.handlehiddenandshowSecondPipelinePhInvoice} handleHideAndShowCleared={this.handlehiddenandshowClearedPhInvoice} showAndHiddenSecondPipeline={this.state.showAndHiddenSecondPipeline} showAndHiddenCleared={this.state.showAndHiddenCleared} showandhidden={this.state.showandhidden} HoldCommentvalue={this.state.HoldCommentValue} HandleCommentHold={this.CommentsHandleHoldPH} rejectbuttonapi={this.prjectInvoiceDetailsRejected} getApprovebuttonapi={this.prjectInvoiceDetailsApproved} getHoldedbuttonapi={this.prjectInvoiceDetailsHolded} isInvoiceDetailPH={this.state.invoiceDetail}/>

            </Grid>
            <Footer />
        </Grid>
        )
    }
    
    render() {
        return ( 
            <Container maxWidth="xl" className="user_detail_container">
                {
                    this.state.isinvoiceBillingSideBarOpen?
                        <Grid container spacing={0}>
                            <Grid style={webStyle.sideBarinvoiceProdHouse}  item xs={3}>
                                <SideBar
                                   userData={{ age: 25 }}
                                    activeMenuItem={"Invoices Requests"}
                                    navigate={this.props.navigation.navigate}
                                    handleSideBar={this.handleInvoiceSideBar}
                                   
                                    />
                            </Grid>
                            <Grid item xs={9}>{this.renderMainInvoiceProfile(this.state.isinvoiceBillingSideBarOpen)}</Grid>
                        </Grid> :
                        <Grid item xs={12}>
                            {
                                this.renderMainInvoiceProfile(this.state.isinvoiceBillingSideBarOpen)
                            }
                           </Grid>
                }
            </Container >
        );  }
}

// Customizable Area Start
const webStyle = {
   
    contentSetupinvoiceProdHouse: {
        borderLeft: "1px solid #E3EAF4",
    },
    
    sideBarinvoiceProdHouse: {
        minHeight: "100vh",
        maxWidth: "23%",
    },
    blockInfoinvoiceProdHouse: {
        display: "flex",
        alignItems: "flex-start",
        gap: 15,
    },
    blockPathinvoiceProdHouse: {
        fontSize: "14px",
        letterSpacing: "1px",
        color: "rgb(245, 196, 44)",
        marginBottom: "24px",
        fontWeight: 500,
        cursor: 'pointer',
        textDecoration: 'underline'
      },
      highlightedTextinvoiceProdHouse: {
        color: "#F5C42C",
        textDecoration: 'underline',
        textDecorationColor: "#F5C42C",
        fontWeight: 600,
        cursor:"pointer"
      },
    activeBlockinvoiceProdHouse: {
        fontSize: "28px",
        fontWeight: "600",
        letterSpacing: "1px",
        display: "flex",
        flexDirection: "row",
        lineHeight: "33px",
        alignItems: 'center',
        gap: '12px',
        marginBottom: '8px',   
      },

      activeBlockinvoiceProdHouseArrow: {
        backgroundColor:' #fff',
        border: '1px solid #ddd',
        borderRadius: '50%',
        padding: '15px',   
        cursor: 'pointer',
      },
     
      mainContentinvoiceProdHouse: {
        width: "100%",
        backgroundColor: "#F8FAFE",
        boxSizing: "border-box",
        padding: "24px"
      },
};
// Customizable Area End