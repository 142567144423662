import React from "react";
import { Card, Box, CardMedia, CardContent, Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { JobCardImage, LocationIconjob, dotsIcon, headingicon, Bg_Copy } from "./assets";


interface MyProps {
    data: any;
}



const useStyles = makeStyles({
    cardDivWithOpenSideBar: () => ({
        width: 300,
        //height: 240,
        borderRadius: 14,
        padding: 8,
        border: "1px solid #E1E6EC",
        position: 'relative',
        '& .MuiCardContent-root': {
            padding: 0
        },
        "@media(max-width: 500px)": {

        },
        "@media(min-width: 1440px)": {
            width: '312px',
        },
        backgroundImage: `url(${Bg_Copy})`, // Assuming JobCardImage is the path to your image
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: 200,
    }),
    cardDivWithClosedSideBAr: () => ({
        width: 346,
        borderRadius: 14,
        padding: 8,
        border: "1px solid #E1E6EC",
        position: 'relative',
        '& .MuiCardContent-root': {
            padding: 0
        },
        "@media(max-width: 500px)": {

        },
    }),
    parentBoxValue: () => ({
        display: "flex",
        justifyContent: 'space-between',



    }),
    parentBox: () => ({
        height: "177px",
        borderRadius: "12px",
        boxShadow: "0px 7px 18px rgba(24,39,42,0.07)",
        cursor: "pointer",
        backgroundColor : "grey"
    }),
    imageContainer: () => ({
        height: "100%",
        width: "100%",
        position: "relative",
    }),
    studioImage: () => ({
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        objectFit: "cover",
        borderRadius: "12px"
    }),
    studioStatus: () => ({
        position: "absolute",
        top: "10px",
        right: "10px",
        textAlign: "end",
        height: "23px",
    }),
    studioDetailsMainBox: () => ({
        position: "absolute",
        bottom: "5px",
        right: "5px",
        left: "5px",
        background: "#fff",
        height: 95,
        borderRadius: "10px",
        opacity: 0.8290272,
        padding: 12,
        boxSizing: "border-box",
    }),
    studioDetails: () => ({
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    }),
    ParentSubheading1: () => ({
        display: "flex",
        gap: "5px",

    })
    ,
    backgroundImage: {
        // Adjust the height according to your requirement
    },

});

export default function StudioBookingCard(props: MyProps) {


    const classes = useStyles();
    

    return (
        <Box className={classes.parentBox}>
            <Box className={classes.imageContainer}>
                <img className={classes.studioImage} src={props.data.attributes.image || "https://www.littlethings.info/wp-content/uploads/2014/04/dummy-image-green-e1398449160839.jpg"} />
                <Box className={classes.studioStatus}>
                    {props.data.attributes.status === "Available" ? <Box style={{ minHeight: "23px", display: "flex", alignItems: "center", justifyContent: "center", background: "#3389ff", width: "73px", borderRadius: "12px" }}>
                        <Typography style={{ fontWeight: 700, color: "#ffffff", lineSpacing: "0.18181819px", fontSize: "10px", fontFamily: "Urbanist", lineHeight: "12px" } as React.CSSProperties}>{props.data.attributes.status.toUpperCase()}</Typography>
                    </Box> : <Box style={{ minHeight: "23px", display: "flex", alignItems: "center", justifyContent: "center", background: props.data.attributes.status === "Not Available" ? "#000000" : "#f5c42c", width: props.data.attributes.status === "Not Available" ? "92px" : "64px", borderRadius: "12px" }}>
                        <Typography style={{ fontWeight: 700, color: "#ffffff", lineSpacing: "0.18181819px", fontSize: "10px", fontFamily: "Urbanist", lineHeight: "12px" } as React.CSSProperties}>{props.data.attributes.status.toUpperCase()}</Typography>
                    </Box>}
                </Box>
                <Box className={classes.studioDetailsMainBox}>
                    <Box className={classes.studioDetails}>
                        <Box style={{ maxWidth: "80%" }}>
                            <Typography style={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", fontWeight: 500, color: "#17181d", lineSpacing: "0.10909091px", fontSize: "12px", fontFamily: "Urbanist", lineHeight: "14px" } as React.CSSProperties}>{props.data.attributes.address}, {props.data.attributes.city} ({props.data.attributes.state})</Typography>
                            <Typography style={{ marginTop: 2, fontSize: "16px", fontFamily: "Urbanist", lineHeight: "20px", lineSpacing: "0.2px", fontWeight: 700, display: "flex", alignItems: "center", color: "#17181d" } as React.CSSProperties}>{props.data.attributes.name}</Typography>
                        </Box>
                        <Box>
                            <div style={{ maxWidth: "81px", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", width: 43, fontWeight: 400, lineSpacing: "0.2px", color: "#42a200", fontSize: "12px", fontFamily: "Helvetica-Bold", lineHeight: "16px", textAlign: "end" } as React.CSSProperties}>₹{props.data.attributes.price_per_day}</div>
                            <Typography style={{ width: 44, fontWeight: 600, lineSpacing: "0.2px", color: "#42a200", fontSize: "12px", fontFamily: "Urbanist", lineHeight: "16px", textAlign: "end" } as React.CSSProperties}>Per Day</Typography>
                        </Box>
                    </Box>
                    <Box style={{ marginTop: 10.5, marginBottom: 6.5, borderTop: "1px solid #6d89af", opacity: 0.2916434 }}></Box>
                    <Box className={classes.studioDetails}>
                        <Box style={{ width: "calc(100% - 200px)", paddingRight: "5px", boxSizing: "border-box" }}>
                            <Typography style={{ width: "100%", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", fontWeight: 600, color: "#3389ff", lineSpacing: "0.12727273px", fontSize: "14px", fontFamily: "Urbanist", lineHeight: "17px", textDecoration: "underline" } as React.CSSProperties}>{props.data.attributes.production_house}</Typography>
                        </Box>
                        <Box>
                            <Typography style={{ fontWeight: 600, lineSpacing: "0.10909091px", color: "#17181d", fontSize: "12px", fontFamily: "Urbanist", lineHeight: "14px" } as React.CSSProperties}>Visiting Time {props.data.attributes.open_time} to {props.data.attributes.close_time}</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
