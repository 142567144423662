import React from "react";

import {
  // Customizable Area Start
  Grid,
  InputLabel,
  Divider,
  Typography,
  Box
  // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import { webStyle } from "./FullTeamVisibility.web";
import "./fullVisibility.css";
import Rating from '@material-ui/lab/Rating';
import LinearProgress from "@material-ui/core/LinearProgress";
import StarBorderIcon from '@material-ui/icons/StarBorder';
import AllVendorRatingController, { Props, configJSON } from "./AllVendorRatingController";
import AllVendorRatingCard from './AllVendorRatingCard.web';
// Customizable Area End

export default class AllVendorRating extends AllVendorRatingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { ratingData } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <Grid className="review_parent_grid">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <InputLabel style={webStyle.vendorLabel}>{configJSON.rating}</InputLabel>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Divider className="divider_review" />
        </Grid>
        <Grid container spacing={2} className="review_grid_container">
          <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
            <Box className="rating_review_box">
              <Box className="rating_box_parent background_color">
                <Box className="star_icon rating_size">
                  <Typography className="total_rating_typo">{ratingData.average_rating || 0}</Typography>
                  <Typography className="divide_num">{configJSON.divide}</Typography>
                </Box>
                <Box className="rating_text">
                  <Divider orientation="vertical" flexItem style={{ height: 25 } as React.CSSProperties} />
                </Box>
                <Rating
                  name="customized-empty"
                  defaultValue={ratingData.average_rating || 0}
                  size="small"
                  precision={0.5}
                  readOnly
                  emptyIcon={<StarBorderIcon htmlColor="#ffb400" style={{ width: "18px", height: "18px" } as React.CSSProperties} />}
                />
              </Box>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="user_detail_grid_container_rating background_color">
                <Typography className="rating_helper">{ratingData.review_rating_count}</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="user_detail_grid_container_rating background_color">
                <Divider className="divider_vendor_rating" />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="user_detail_grid_container_rating background_color">
                {
                  Object.entries(ratingData.rating_star || { 5: 0, 4: 0, 3: 0, 2: 0, 1: 0 }).reverse().map((item: any) => {
                    return <Box display="flex" key={`${item[0]}_ratingvalue`} alignItems="start" flexDirection="column" pb={1}>
                      <Box width="100%">
                        <LinearProgress variant="determinate" value={item[1] | 0} className="progress_bar_rating" style={{ backgroundColor: 'lightgrey', borderRadius: '10px' }} />
                      </Box>
                      <Box minWidth={35}>
                        <Typography variant="body2" className="rating_result">{`${item[0]} ${configJSON.startText}`}</Typography>
                      </Box>
                    </Box>
                  })
                }
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography className="rating_label">{configJSON.allReviews}</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {
                ratingData.reviews?.data?.length ? (ratingData.reviews.data.map((item: any, index: number) => {
                  return <Box key={`${index}_review_box_allProjectList`} style={{ padding: '2%' }} className="review_box">
                    <AllVendorRatingCard id="" item={item.attributes} type={this.props.type} navigation={this.props.navigation} />
                  </Box>
                })) : null
              }
            </Grid>
          </Grid>
        </Grid>
      </Grid >
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End