import React from 'react';
import {
    // Customizable Area Start
    Box,
    Typography,
    styled,
    Grid    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import Modal from '@material-ui/core/Modal';
import UploadCSVErrorMessagesModelController, { Props } from './UploadCSVErrorMessagesModelController';
import { CustomButton } from '../../email-account-login/src/CustomButton';
const images = require("./assets");

const StyledModal = styled(Modal)({
    position: 'fixed',
    zIndex: 1300,
    inset: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

const style = {
    width: 400,
    borderRadius: '20px',
    backgroundColor: 'white',
    boxShadow: 'none',
    minHeight: 209,
};

const CustomButtonBox = styled(CustomButton)({
    '& .MuiButton-label': {
        fontWeight: 600,
        fontSize: 12,
        letterSpacing: '0.34285715px',
        lineHeight: '14px',
    }
});

// Customizable Area End
export default class UploadCSVErrorMessagesModel extends UploadCSVErrorMessagesModelController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <StyledModal
                aria-labelledby="unstyled-modal-title"
                aria-describedby="unstyled-modal-description"
                open={this.props.open}
                onClose={this.props.handleClose}
            >
                <Box sx={style}>
                    <Grid container style={{ padding: "20px 24px 0px", color: '#17181d', fontFamily: 'Urbanist', fontSize: 20, fontWeight: 700, textAlign: 'end', letterSpacing: '0.2px' } as React.CSSProperties}>
                        <Grid item style={{ textAlign: "start", boxSizing: "border-box" }} xs={9}>
                            Error Messgaes
                        </Grid>
                        <Grid item xs={3}>
                            <img data-test-id="cross-icon" src={`${images.crossIcon}`} style={{ cursor: 'pointer' }} onClick={this.props.handleClose} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box style={{ padding: "16px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <Typography style={{ color: "#6d89af", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 500, lineHeight: "18px", letterSpacing: "0.21538462px", textAlign: "center" }}>Getting errors in some rows while uploading CSV file</Typography>
                            <Typography style={{ width: "330px", marginTop: "11px", color: "#6d89af", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 500, lineHeight: "18px", letterSpacing: "0.21538462px", textAlign: "start" }}> <div dangerouslySetInnerHTML={{ __html: this.props.errors }} /></Typography>
                        </Box>
                    </Grid>
                    <Grid container style={{ padding: "0px 16px 16px" }}>
                        <CustomButtonBox onClick={this.props.handleClose} data-test-id="ok_button" style={{ borderRadius: "14px", height: 48, background: "#17181d", width: "100%", color: "#ffffff" } as React.CSSProperties}>
                            OK
                        </CustomButtonBox>
                    </Grid>
                </Box>
            </StyledModal>
        )
        // Customizable Area End
    }
}