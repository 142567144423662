import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
const baseURL = require("../../../framework/src/config");
// Customizable Area Start
export interface ISubCategory {
  id: string,
  attributes: {
    name: string,
    category_id: number,
    budget_details:
    {
      data: {
        id?: string, attributes: {
          vendor_name?: string,
          contact_number?: number,
          no_of_pepole?: number,
          days_or_shift?: number,
          budget_type?: number,
          per_day_or_shift_charge?: number,
          cash_remont?: number,
          conveyance?: number,
          wip_cost?: number,
          actual_cost?: number,
        }
      } | null
    }
  }
}
export interface ICategory {
  id: string, attributes:
  {
    name: string, sub_categories:
    {
      data: ISubCategory[]
    }
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isDropdownOpen: boolean;
  isSideBarOpen: boolean;
  tabValue: number;
  isModelOpen: boolean;
  modelType: string;
  categories: ICategory[];
  editCategory: any;
  editDepartment: { id: number, name: string } | null;
  selectedDepartment: { id: number, name: string } | null;
  isLoading: boolean;
  refreshToken: boolean;
  balanceSheetProjectId: string;
  allProjectDepartments: { id: number, name: string }[];
  projectName: string;
  totalAmountByDept: string;
  uploadCsvErrors: string;
  manageBalanceSheetNotification: { type: string, open: boolean, message: string, route?: string };
  userAccountType:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ManageBalanceSheetController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  addDepartmentMessageId: string = "";
  getProjectDepartmentsMessageId: string = "";
  updateDepartmentMessageId: string = "";
  getProjectDetailsMessageId: string = "";
  getBudgetDetailsByDepartment: string = "";
  addCategoryMessageId: string = "";
  updateCategoryMessageId: string = "";
  addSubCategoryMessageId: string = "";
  deleteSubCategoryMessageId: string = "";
  addSubCategoryBudgetDetailsMessageId: string = "";
  uploadCSVFileMessageId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isDropdownOpen: false,
      isSideBarOpen: true,
      tabValue: 0,
      isModelOpen: false,
      modelType: "",
      categories: [],
      editCategory: null,
      editDepartment: null,
      selectedDepartment: null,
      isLoading: false,
      refreshToken: false,
      balanceSheetProjectId: "",
      allProjectDepartments: [],
      projectName: "",
      totalAmountByDept: "",
      uploadCsvErrors: "",
      manageBalanceSheetNotification: { type: "", open: false, message: "" },
      userAccountType:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.addDepartmentMessageId === apiRequestCallId) {
        this.addDepartmentHandleResponse(responseJson);
      } else if (this.getProjectDepartmentsMessageId === apiRequestCallId) {
        this.getProjectDepartmentsHandleResponse(responseJson);
      } else if (this.updateDepartmentMessageId === apiRequestCallId) {
        this.updateDepartmentHandleResponse(responseJson);
      } else if (this.getProjectDetailsMessageId === apiRequestCallId) {
        this.getProjectDetailsHandleResponse(responseJson);
      } else if (this.getBudgetDetailsByDepartment === apiRequestCallId) {
        this.getBudgetDetailsByDepartmentDataHandleResponse(responseJson);
      } else if (this.addCategoryMessageId === apiRequestCallId) {
        this.addCategoryHandleResponse(responseJson);
      } else if (this.updateCategoryMessageId === apiRequestCallId) {
        this.updateCategoryHandleResponse(responseJson);
      } else if (this.addSubCategoryMessageId === apiRequestCallId) {
        this.addSubCategoryHandleResponse(responseJson);
      } else if (this.deleteSubCategoryMessageId === apiRequestCallId) {
        this.deleteSubCategoryHandleResponse(responseJson);
      } else if (this.addSubCategoryBudgetDetailsMessageId === apiRequestCallId) {
        this.addSubCategoryBudgetDetailsHandleResponse(responseJson);
      } else if (this.uploadCSVFileMessageId === apiRequestCallId) {
        this.uploadCSVFileHandleResponse(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  uploadCSVFileHandleResponse = async (responseJson: { message: string, error: string | any[] }) => {
    this.handleModelClose();
    this.getBudgetDetailsByDepartment = await this.apiCall(configJSON.getAPIMethod, configJSON.getBudgetDetailsByDepartmentAPI + `${this.state.balanceSheetProjectId}&project_department_id=${this.state.selectedDepartment?.id}`);
    if (responseJson.message) {
      this.setState({ manageBalanceSheetNotification: { open: true, type: "success", message: responseJson.message } });
    } else if (typeof (responseJson.error) === "string") {
      this.setState({ manageBalanceSheetNotification: { open: true, type: "error", message: responseJson.error } });
    } else if (typeof (responseJson.error) !== "string") {
      let newError = "";
      responseJson.error.forEach(element => {
        newError = newError + element + "<br />"
      });
      this.setState({ modelType: "uploadcsvError", uploadCsvErrors: newError });
      this.handleModelOpen();
    }
    this.setState({ isLoading: false });
  }

  addSubCategoryBudgetDetailsHandleResponse = async (responseJson: { meta: { message: string }, error: { message: string } | string, data: { id: string, attributes: { wip_cost: string, actual_cost: string } }[] }) => {
    if (responseJson.data) {
      this.getBudgetDetailsByDepartment = await this.apiCall(configJSON.getAPIMethod, configJSON.getBudgetDetailsByDepartmentAPI + `${this.state.balanceSheetProjectId}&project_department_id=${this.state.selectedDepartment?.id}`);
      this.setState({ manageBalanceSheetNotification: { open: true, type: "success", message: responseJson.meta.message } });
    } else if (typeof (responseJson.error) !== "string" && responseJson.error.message) {
      this.setState({ manageBalanceSheetNotification: { open: true, type: "error", message: responseJson.error.message } });
    } else if (typeof (responseJson.error) === "string") {
      this.setState({ manageBalanceSheetNotification: { open: true, type: "error", message: responseJson.error } });
    }
    this.setState({ isLoading: false });
  }

  addSubCategoryHandleResponse = (responseJson: { data: ISubCategory }) => {
    if (responseJson.data) {
      const categoryWithNewSubCategory = this.state.categories.map((category: ICategory) => {
        if (category.id === responseJson.data.attributes.category_id.toString()) {
          const filteredSubCategories = category.attributes.sub_categories.data.filter((subCategory: ISubCategory) => subCategory.id);
          return { ...category, attributes: { ...category.attributes, sub_categories: { data: [...filteredSubCategories, responseJson.data] } } }
        } else return category
      });
      this.setState({ categories: categoryWithNewSubCategory });
      this.setState({ manageBalanceSheetNotification: { open: true, type: "success", message: "Subcategory added successfully" } });
    }
    this.setState({ isLoading: false });
  }

  deleteSubCategoryHandleResponse = async (responseJson: { success: boolean }) => {
    if (responseJson.success) {
      this.getBudgetDetailsByDepartment = await this.apiCall(configJSON.getAPIMethod, configJSON.getBudgetDetailsByDepartmentAPI + `${this.state.balanceSheetProjectId}&project_department_id=${this.state.selectedDepartment?.id}`);
      this.setState({ manageBalanceSheetNotification: { open: true, type: "success", message: "Subcategory deleted successfully" } });
    }
    this.setState({ isLoading: false });
  }

  getBudgetDetailsByDepartmentDataHandleResponse = (responseJson: {
    data: {
      attributes: {
        categories: {
          data: ICategory[]
        }
      }
    }, meta: { total_amount: string }
  }) => {
    if (responseJson.data) {
      this.setState({ categories: responseJson.data.attributes.categories.data, totalAmountByDept: responseJson.meta.total_amount });
    }
    this.setState({ isLoading: false, refreshToken: !this.state.refreshToken });
  }

  addCategoryHandleResponse = (responseJson: { meta: { message: string }, data: ICategory }) => {
    if (responseJson.data) {
      this.setState({ categories: [...this.state.categories, responseJson.data] });
      this.setState({ manageBalanceSheetNotification: { open: true, type: "success", message: responseJson.meta.message } });
    }
    this.setState({ isLoading: false });
  }

  updateCategoryHandleResponse = (responseJson: { data: ICategory }) => {
    if (responseJson.data) {
      const updatedCategories = this.state.categories.map((category: ICategory) => {
        if (category.id === responseJson.data.id) {
          return { ...category, attributes: { ...category.attributes, name: responseJson.data.attributes.name } }
        } else return category
      });
      this.setState({ categories: updatedCategories });
      this.setState({ manageBalanceSheetNotification: { open: true, type: "success", message: "Category updated successfully" } });
    }
    this.setState({ isLoading: false });
  }

  getProjectDetailsHandleResponse = (responseJson: { data: { attributes: { project_name: string } } }) => {
    if (responseJson.data) {
      this.setState({ projectName: responseJson.data.attributes.project_name });
    }
    this.setState({ isLoading: false });
  }

  addDepartmentHandleResponse = (responseJson: { data: { id: string, attributes: { name: string } } }) => {
    if (responseJson.data) {
      this.setState({ allProjectDepartments: [...this.state.allProjectDepartments, { id: Number(responseJson.data.id), name: responseJson.data.attributes.name }] });
      this.setState({ manageBalanceSheetNotification: { open: true, type: "success", message: "Department created successfully" } });
    }
    this.setState({ isLoading: false });
  }

  updateDepartmentHandleResponse = (responseJson: { data: { id: string, attributes: { name: string } } }) => {
    if (responseJson.data) {
      const updatedDepartments = this.state.allProjectDepartments.map((dept: { id: number, name: string }) => {
        if (dept.id.toString() === responseJson.data.id) {
          return { id: dept.id, name: responseJson.data.attributes.name }
        } else return dept
      });
      if (this.state.selectedDepartment?.id.toString() === responseJson.data.id) {
        this.setState({ selectedDepartment: { ...this.state.selectedDepartment, name: responseJson.data.attributes.name } })
      }
      this.setState({ allProjectDepartments: updatedDepartments });
      this.setState({ manageBalanceSheetNotification: { open: true, type: "success", message: "Department updated successfully" } });
    }
    this.setState({ isLoading: false });
  }

  getProjectDepartmentsHandleResponse = async (responseJson: { meta: { project_department: { id: number, name: string }[] } }) => {
    if (responseJson.meta?.project_department) {
      this.setState({ allProjectDepartments: responseJson.meta.project_department, selectedDepartment: responseJson.meta.project_department[0] });
      this.getBudgetDetailsByDepartment = await this.apiCall(configJSON.getAPIMethod, configJSON.getBudgetDetailsByDepartmentAPI + `${this.state.balanceSheetProjectId}&project_department_id=${responseJson.meta.project_department[0].id}`);
    }
    this.setState({ isLoading: false });
  }

  async componentDidMount() {
    const balanceSheetId = await getStorageData("BalanceSheetId");
    const userType = await getStorageData("account_type");
    const isManageBalanceSheetSideBarOpen = await getStorageData("sidebarvalue");
    this.setState({userAccountType:userType})
    this.setState({ balanceSheetProjectId: balanceSheetId, isSideBarOpen: JSON.parse(isManageBalanceSheetSideBarOpen) }, async () => {
      this.getProjectDepartmentsMessageId = await this.apiCall(configJSON.getAPIMethod, configJSON.getProjectsDepartmentAPI + this.state.balanceSheetProjectId);
      this.getProjectDetailsMessageId = await this.apiCall(configJSON.getAPIMethod, configJSON.getProjectDetailsAPI + this.state.balanceSheetProjectId);
    });
  }

  handleDropdown = () => {
    this.setState({
      isDropdownOpen: !this.state.isDropdownOpen
    });
  };

  handleSideBar = () => {
    setStorageData("sidebarvalue", (!this.state.isSideBarOpen).toString());
    this.setState({
      isSideBarOpen: !this.state.isSideBarOpen
    });
  };

  handleAppBarValue = (event: React.ChangeEvent<{}>, value: number) => {
    this.setState({
      tabValue: value
    })
  }

  handleAddDepartment = () => {
    this.setState({ modelType: "department" });
    this.handleModelOpen();
  }

  apiCall = async (method: string, endPoint: string, bodyData?: any, formData?: any) => {
    const authToken = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setState({ isLoading: true });
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      formData ? JSON.stringify({ token: authToken }) : JSON.stringify(header)
    );
    bodyData && requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    formData && requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  handleAddDepartmentAPICall = async (departmentName: string) => {
    this.addDepartmentMessageId = await this.apiCall(configJSON.postAPIMethod, configJSON.createDepartmentAPI + this.state.balanceSheetProjectId, { data: { department: { name: departmentName } } });
    this.handleModelClose();
  }

  handleUpdateDepartmentAPICall = async (departmentName: string, departmentId: number) => {
    this.updateDepartmentMessageId = await this.apiCall(configJSON.putAPIMethod, configJSON.editDepartmentAPI + departmentId, { data: { attributes: { name: departmentName } } });
    this.handleModelClose();
  }

  handleAddCategoryAPICall = async (categoryName: string) => {
    const requestData = {
      data: {
        attributes: {
          name: categoryName,
          project_id: this.state.balanceSheetProjectId,
          project_department_id: this.state.selectedDepartment?.id
        }
      }
    }
    this.addCategoryMessageId = await this.apiCall(configJSON.postAPIMethod, configJSON.createCategoryAPI, requestData);
    this.handleModelClose();
  }

  handleDeleteSubCategory = async (subCategoryId: string) => {
    this.deleteSubCategoryMessageId = await this.apiCall(configJSON.deleteAPIMethod, configJSON.deleteSubCategoryAPI + subCategoryId);
  }

  handleUpdateCategoryAPICall = async (categoryName: string, categoryId: number) => {
    this.updateCategoryMessageId = await this.apiCall(configJSON.patchAPIMethod, configJSON.editCategoryAPI + `${categoryId}?category_name=${categoryName}`);
    this.handleModelClose();
  }

  handleAddCategory = () => {
    this.setState({ modelType: "category" });
    this.handleModelOpen();
  }

  handleEditCategory = (id: string, categoryName: string, rows?: number) => {
    this.setState({ modelType: "category", editCategory: { id: id, categoryName: categoryName } });
    this.handleModelOpen();
  }

  handleSaveCategoryDetails = async (categoryIndex: number) => {
    const updatedBudgetDetails = this.state.categories[categoryIndex].attributes.sub_categories.data.filter((subCategory: ISubCategory) => subCategory.attributes.budget_details.data && !subCategory.attributes.budget_details.data.id);
    const mappedData = updatedBudgetDetails.map((data) => { return { ...data.attributes.budget_details.data?.attributes, project_id: this.state.balanceSheetProjectId, sub_category_id: data.id } })
    this.addSubCategoryBudgetDetailsMessageId = await this.apiCall(configJSON.postAPIMethod, configJSON.createBudgetDetails, { budjet_detaiils: mappedData });
  }

  handleAddRow = (categoryId: string) => {
    const updatedCategories = this.state.categories.map((category: ICategory) => {
      if (categoryId === category.id) {
        return { ...category, attributes: { ...category.attributes, sub_categories: { data: [...category.attributes.sub_categories.data, { id: "", attributes: { name: "", category_id: 0, budget_details: { data: null } } }] } } }
      } else return category
    })
    this.setState({ categories: updatedCategories });
  }

  handleSubcategorySave = async (event: any, categoryId: string, subCategoryName?: string) => {
    if (!subCategoryName && event.target.value)
      this.addSubCategoryMessageId = await this.apiCall(configJSON.postAPIMethod, configJSON.createSubCategoryAPI, { sub_category: { name: event.target.value }, category_id: categoryId });
  }

  handleChangeBudgetDetails = (event: { target: { value: string | number } }, categoryIndex: number, subCategoryIndex: number, propertyName: "vendor_name" | "contact_number" | "no_of_pepole" | "days_or_shift" | "budget_type" | "per_day_or_shift_charge" | "cash_remont" | "conveyance") => {
    let budgetDetails = this.state.categories;
    const budgetDetailData = budgetDetails[categoryIndex].attributes.sub_categories.data[subCategoryIndex].attributes.budget_details.data;
    if (budgetDetailData) {
      budgetDetails[categoryIndex].attributes.sub_categories.data[subCategoryIndex].attributes.budget_details.data = {
        attributes: {
          ...budgetDetailData.attributes,
          [propertyName]: event.target.value,
        }
      }
    } else {
      budgetDetails[categoryIndex].attributes.sub_categories.data[subCategoryIndex].attributes.budget_details.data = {
        attributes: {
          [propertyName]: event.target.value,
        }
      }
    }
    this.setState({ categories: budgetDetails });
  }

  handleModelOpen = () => {
    this.setState({ isModelOpen: true });
  }

  handleModelClose = () => {
    this.setState({ isModelOpen: false, modelType: "", editCategory: null, editDepartment: null });
  }

  getClassForSubCategoryMainBox = (index: number) => {
    return !((index + 1) % 4) && index !== 0 ? "category_main_box category_last_child" : "category_main_box"
  }

  getClassForLastElement = (index: number, rowsCount: number) => {
    const isLastElement = index + 1 === rowsCount;
    return isLastElement ? "category_both_child" : "category_first_child";
  }

  getClassForSubCategoryBox = (index: number, rowsCount: number) => {
    return !(index % 4) && index !== 1 ? `category_left_box ${this.getClassForLastElement(index, rowsCount)}` : "category_left_box"
  }

  handleIsShowLine = (index: number, rowsCount: number) => {
    return !((index + 1) % 4) && index !== 0 && (index + 1) !== rowsCount
  }

  handleSelectDepartment = async (event: any) => {
    const selectedDept = this.state.allProjectDepartments.find((dept: { id: number, name: string }) => dept.id === event.target.value);
    this.setState({ selectedDepartment: selectedDept || null });
    this.getBudgetDetailsByDepartment = await this.apiCall(configJSON.getAPIMethod, configJSON.getBudgetDetailsByDepartmentAPI + `${this.state.balanceSheetProjectId}&project_department_id=${event.target.value}`);
  }

  handleUpdateDepartment = () => {
    this.setState({ modelType: "department", editDepartment: this.state.selectedDepartment });
    this.handleModelOpen();
  }

  handleUploadCSV = () => {
    this.setState({ modelType: "uploadcsv" });
    this.handleModelOpen();
  }

  handleExportCSV = async () => {
    const authToken = await getStorageData("token");
    fetch(baseURL.baseURL + "/" + configJSON.exportCSVFileAPI + this.state.balanceSheetProjectId, {
      headers: { token: authToken }
    })
      .then(VendorManageProfileResponse => {
        VendorManageProfileResponse.blob().then(blob => {
          let photoUrl = window.URL.createObjectURL(blob);
          let aTag = document.createElement('a');
          aTag.href = photoUrl;
          aTag.download = "csv_file.csv";
          aTag.click();
        });
      });
  }

  handleFileUpload = async (file: any) => {
    const formData = new FormData();
    formData.append("csv_file", file);

    this.uploadCSVFileMessageId = await this.apiCall(configJSON.postAPIMethod, configJSON.uploadCSVFileAPI + this.state.balanceSheetProjectId, "", formData);
  }

  handleBalanceSheetNavigation = (page: string) => {
    this.props.navigation.navigate(page)
  };

  handleManageBalanceSheetCloseNotification = () => {
    this.setState({ manageBalanceSheetNotification: { type: "", open: false, message: "" } });
  }
  // Customizable Area End
}
