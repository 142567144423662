import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  id: string;
  // Customizable Area Start
  issidebaropenblock : boolean;
  isDropdownOpenBlock : boolean;
  accountListBread : any [];
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class AccountGroupsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      id: "0",
      issidebaropenblock :true,
      isDropdownOpenBlock : false,
      accountListBread : [
        {
          text : "Home",
          href : "/",
          clickable : true,
          page : "LandingPage"
        },
        {
          text : "Blocked Profile",
          href : "/",
          clickable : false,
          page : "AccountGroup"
        }
      ]
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleSideBarisDropdownOpenBlock = () => {
    this.setState({
      issidebaropenblock : !this.state.issidebaropenblock
    },
    async() => {
      localStorage.setItem("sidebarvalue",this.state.issidebaropenblock.toString())
      
    }
    )
  };
  async componentDidMount(): Promise<void> {
   
    let issidebaropenblock=await getStorageData("sidebarvalue");
    this.setState({issidebaropenblock:JSON.parse(issidebaropenblock)})
  }


  handleDropdownBlock = () => {
    this.setState({
      isDropdownOpenBlock: !this.state.isDropdownOpenBlock
    });
  };
  // Customizable Area End
}
