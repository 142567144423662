import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
interface MyProps {
    utcTime:any
   
}
const useStyles = makeStyles({
    timebox:{
        height: '14px',
        color: '#6d89af',
        fontFamily: 'Urbanist',
        fontSize: '12px',
        fontWeight: 600,
        display:'flex',
        justifyContent:'end',
        paddingRight:'16px'
    }
})
export default function DateTimeDifferece(props: MyProps) {
    const {utcTime} = props;
  const [differenceInSeconds, setDifferenceInSeconds] = useState<any>(0);
  const classes = useStyles();
 const  timeSince=(utcTime: string)=> {
    let seconds = Math.floor(
      (new Date().valueOf() - new Date(utcTime).valueOf()) / 1000
    );
    let interval = seconds / 31536000;
    if (interval > 1) {
      return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
  }

  return (
    <div>

    
      <Box className={classes.timebox}>{timeSince(utcTime)}</Box>
    </div>
  );
};


