import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from "moment";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  open: boolean;
  statesData: any[];
  handleClose: any;
  phList: any[];
  handleFilterAPICall: any;
  filterValues: any;
  storeFilterValues: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  locationValue: string;
  filterAttributes: any[];
  priceValue: number[];
  dateType: string;
  priceType: string;
  startDate: any;
  endDate: any;
  startTime: any;
  invalidDateTiming: boolean;
  endTime: any;
  openTime: any;
  closeTime: any;
  invalidTiming: boolean;
  phValue: any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class StudioBookingFilterModelController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      locationValue: "",
      filterAttributes: [{ name: "Location", active: true }, { name: "Production House", active: false }, { name: "Price", active: false }, { name: "Date", active: false }, { name: "Timing", active: false }],
      priceValue: [0, 0],
      dateType: "hour_wise",
      priceType: "price_per_day",
      startDate: null,
      endDate: null,
      startTime: null,
      invalidDateTiming: false,
      endTime: null,
      openTime: null,
      closeTime: null,
      invalidTiming: false,
      phValue: "",
      // Customizable Area End
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    this.setState({
      locationValue: this.props.filterValues?.locationValue || "",
      priceValue: this.props.filterValues?.priceValue || [0, 0],
      dateType: this.props.filterValues?.dateType || "hour_wise",
      priceType: this.props.filterValues?.priceType || "price_per_day",
      startDate: this.props.filterValues?.startDate || null,
      endDate: this.props.filterValues?.endDate || null,
      startTime: this.props.filterValues?.startTime || null,
      endTime: this.props.filterValues?.endTime || null,
      openTime: this.props.filterValues?.openTime || null,
      closeTime: this.props.filterValues?.closeTime || null,
      phValue: this.props.filterValues?.phValue || "",
    })
  }

  handleChange = (event: any) => {
    this.setState({ locationValue: event.target.value });
  };

  handleChangePHValue = (event: any) => {
    this.setState({ phValue: event.target.value });
  };

  handleChangePriceType = (event: any) => {
    this.setState({ priceType: event.target.value });
  }

  handleChangeDateType = (event: any) => {
    this.setState({ dateType: event.target.value }, () => {
      if (this.state.dateType === "hour_wise") {
        this.setState({ startDate: null, endDate: null });
      } else {
        this.setState({ startDate: null, endTime: null, startTime: null });
      }
    });
  };

  handleChangePriceRange = (event: any, newValue: number | number[]) => {
    this.setState({ priceValue: newValue as number[] });
  }

  handleSelectStartDate = (newDate: any) => {
    this.setState({ startDate: newDate });
  }

  handleSelectEndDate = (newDate: any) => {
    this.setState({ endDate: newDate });
  }

  handleSelectStartTime = (newTime: any) => {
    let diff = moment(this.state.endTime).diff(moment(newTime, "hh:mm a"));
    if (diff < 0) {
      this.setState({ invalidDateTiming: true });
    } else {
      this.setState({ invalidDateTiming: false });
    }
    this.setState({ startTime: newTime, invalidTiming: false });
  }

  handleSelectEndTime = (newTime: any) => {
    let diff = moment(newTime).diff(moment(this.state.startTime, "hh:mm a"));
    if (diff < 0) {
      this.setState({ invalidDateTiming: true });
    } else {
      this.setState({ invalidDateTiming: false });
    }
    this.setState({ endTime: newTime });
  }

  handleSelectOpenTime = (newTime: any) => {
    let diff = moment(this.state.closeTime).diff(moment(newTime, "hh:mm a"));
    if (diff < 0) {
      this.setState({ invalidTiming: true });
    } else {
      this.setState({ invalidTiming: false });
    }
    this.setState({ openTime: newTime });
  }

  handleSelectCloseTime = (newTime: any) => {
    let diff = moment(newTime).diff(moment(this.state.openTime, "hh:mm a"));
    if (diff < 0) {
      this.setState({ invalidTiming: true });
    } else {
      this.setState({ invalidTiming: false });
    }
    this.setState({ closeTime: newTime });
  }

  handleClearAllData = () => {
    this.setState({
      locationValue: "",
      priceValue: [0, 0],
      dateType: "hour_wise",
      priceType: "price_per_day",
      startDate: null,
      endDate: null,
      startTime: null,
      endTime: null,
      openTime: null,
      closeTime: null,
      phValue: "",
      invalidTiming: false,
      invalidDateTiming: false,
    });
    this.props.handleClose();
    this.props.handleFilterAPICall("");
    this.props.storeFilterValues("");
  }

  handleApplyFilter = () => {
    if (!this.state.invalidTiming && !this.state.invalidDateTiming) {
      const params = `&states=${this.state.locationValue}
                    &production_houses=${this.state.phValue}
                    &price_type=${this.state.priceType}
                    &min_price=${this.state.priceValue[0].toString() || ""}
                    &max_price=${this.state.priceValue[1].toString() || ""}
                    &open_time=${this.getFormattedDateForApplyFilter(this.state.openTime, 'hh:mm A')}
                    &close_time=${this.getFormattedDateForApplyFilter(this.state.closeTime, 'hh:mm A')}
                    &booking_type=${this.state.dateType}
                    &start_date=${this.getFormattedDateForApplyFilter(this.state.startDate, 'YYYY-MM-DD')}
                    &end_date=${this.getFormattedDateForApplyFilter(this.state.endDate, 'YYYY-MM-DD')}
                    &date=${this.getFormattedDateForApplyFilter(this.state.startDate, 'YYYY-MM-DD')}
                    &start_time=${this.getFormattedDateForApplyFilter(this.state.startTime, 'hh:mm A')}
                    &end_time=${this.getFormattedDateForApplyFilter(this.state.endTime, 'hh:mm A')}`

      this.props.handleFilterAPICall(params);
      this.props.handleClose();
    }
    this.props.storeFilterValues({
      locationValue: this.state.locationValue,
      priceValue: this.state.priceValue,
      dateType: this.state.dateType,
      priceType: this.state.priceType,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      startTime: this.state.startTime,
      invalidDateTiming: this.state.invalidDateTiming,
      endTime: this.state.endTime,
      openTime: this.state.openTime,
      closeTime: this.state.closeTime,
      invalidTiming: this.state.invalidTiming,
      phValue: this.state.phValue,
    });
  }

  getFormattedDateForApplyFilter = (value: any, format: any) => {
    return value ? moment(value).format(format) : ""
  }

  changeFilterAttribute = (name: string, oldFilterAttributes: any[]) => {
    if (!this.state.invalidTiming && !this.state.invalidDateTiming) {
      const newOldFilterAttributes = [...oldFilterAttributes];
      newOldFilterAttributes.forEach((attr: any) => {
        if (attr.name === name) {
          attr.active = true;
        } else {
          attr.active = false;
        }
      });
      this.setState({ filterAttributes: newOldFilterAttributes });
    }
  }

  // Customizable Area End
}
