import React from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  TypographyProps,
  Avatar, Divider, LinearProgress, IconButton
} from "@material-ui/core";
import SideBar from "../../../components/src/SideBar";
import Header from "../../../components/src/Header.web";
import Footer from "../../../components/src/Footer";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Loader from "../../../components/src/Loader.web";
import VendorJobPHDetailsController, {
  Props, configJSON
} from "./VendorJobPHDetailsController";
import SettingsPhoneIcon from '@material-ui/icons/SettingsPhone';
import PlaceOutlinedIcon from '@material-ui/icons/PlaceOutlined';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarOutlinedIcon from '@material-ui/icons/StarOutlined';
import { StyledBadge } from "./CardComponent.web";
import Rating from "@material-ui/lab/Rating";
import GetAppIcon from '@material-ui/icons/GetApp';
import CustomNotification from "../../../components/src/CustomNotification";
import { dummyImage } from "../../../components/src/assets";
const images = require("./assets");
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const themeStyle = createTheme({
  palette: {
    primary: {
      main: "#F5C42C",
      contrastText: "#fff"
    }
  },
  overrides: {
    MuiSelect: {
      select: {
        "&:focus": {
          border: "1px solid #F5C42C",
        }
      }
    }
  }
});

export default class VendorJobPHDetails extends VendorJobPHDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderVendorJobPHDetailsOverviewTabDocumentsContent = () => {
    return <>
      <Typography style={webStyle.vendorJobPHDetailsAllReviewsTitle as React.CSSProperties}>Certificates & Documents ----------------------------------------------------------------------------------</Typography>
      <Grid container style={{ marginTop: "8px", width: "100%", minHeight: "88px", borderRadius: "12px", border: "1px solid #e3eaf4", padding: 12, boxSizing: "border-box", color: "#17181d", fontFamily: "Urbanist", fontSize: "12px", fontWeight: 500, lineHeight: "16px" }}>
        <Grid container spacing={2} className="cert_box">
          {this.state.vendorJobPHDetailsData.documents.length ?
            this.state.vendorJobPHDetailsData.documents.map((document: any, index: number) => {
              return <Grid item key={`${index + document.id}_documentlist`} xs={6} sm={6} md={3} lg={3} xl={3}>
                <Box className="vendor_manage_profile_cert_box">
                  <Box className="cert_inner_tool">
                    <Box style={{ backgroundColor: "rgba(245,196,44,0.09)", borderRadius: "8px", padding: "9.9% 13.6%" }}>
                      <img style={{ width: "14.61px", height: "21px" } as React.CSSProperties} src={images.certificateIcon} alt="" />
                    </Box>
                    <IconButton data-test-id={`pdf_download_${document.id}`} onClick={() => this.vendorJobPHDetailsHandleDownloadPDF(document.path_url, document.filename)} className="download_icon" size="medium">
                      <GetAppIcon fontSize="small" className="download_icon_color" />
                    </IconButton>
                  </Box>
                  <Box className="project_name">
                    <Typography noWrap className="file_name">{document.filename}</Typography>
                  </Box>
                </Box>
              </Grid>
            }) : null
          }
        </Grid>
      </Grid>
    </>
  }

  renderReadMoreLess = () => {
    return <>
      {
        this.state.vendorJobPHDetailsData.profile_bio.data.attributes.about_business.length > 350
          ? <>
            <span>
              {this.state.vendorJobPHDetailsData.profile_bio.data.attributes.about_business.substr(0, this.state.bioReadMoreLess ? 350 : this.state.vendorJobPHDetailsData.profile_bio.data.attributes.about_business.length)}...
            </span>
            <span style={webStyle.vendorJobPHDetailsReadMoreText} onClick={this.handleBioRead}>
              {this.state.bioReadMoreLess ? " Read more" : " Read less"}
            </span>
          </> :
          this.state.vendorJobPHDetailsData.profile_bio.data.attributes.about_business
      }
    </>
  }

  renderVendorJobPHDetailsOverviewTabProfileBioContent = () => {
    return <>
      <Typography style={webStyle.vendorJobPHDetailsAllReviewsTitle as React.CSSProperties}>About Production House ----------------------------------------------------------------------------------</Typography>
      <div style={{ marginTop: "8px", width: "100%", minHeight: "88px", borderRadius: "12px", border: "1px solid #e3eaf4", padding: 12, boxSizing: "border-box", color: "#17181d", fontFamily: "Urbanist", fontSize: "12px", fontWeight: 600, lineHeight: "16px" }}>
        {this.state.vendorJobPHDetailsData.profile_bio.data ? this.renderReadMoreLess() : null}
      </div>
    </>
  }

  renderVendorJobPHDetailsOverviewTabContent = () => {
    return <>
      {this.state.vendorJobPHDetailsTabValue === 0 ? <Box style={{ background: "#ffffff", width: "calc(100% - 186px)", padding: "20px 24px 24px" }}>
        <Box>
          {this.renderVendorJobPHDetailsOverviewTabProfileBioContent()}
        </Box>
        <Box style={{ marginTop: "16px" }}>
          <Typography style={webStyle.vendorJobPHDetailsAllReviewsTitle as React.CSSProperties}>Official Details & Docs ----------------------------------------------------------------------------------</Typography>
          <Grid container style={{ marginTop: "8px", width: "100%", height: "123px", borderRadius: "12px", border: "1px solid #e3eaf4", padding: 14, display: "flex", flexDirection: "row" }}>
            <Grid container style={{ flexWrap: "nowrap" }}>
              <Grid item sm={6} md={6} lg={6} >
                <Typography style={{ color: "#6d89af", fontFamily: "Urbanist", fontSize: "12px", fontWeight: 500, lineHeight: "15px", letterSpacing: "0.2px" }}>Aadhaar Number</Typography>
                <Typography style={{ marginTop: "3px", color: "#18272a", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 600, lineHeight: "17px", letterSpacing: "0.2px" }}>{this.state.vendorJobPHDetailsData.account_detail.data.attributes?.aadhar_card_number ? `${this.state.vendorJobPHDetailsData.account_detail.data.attributes.aadhar_card_number.toString().slice(0, 4)} xxxx ${this.state.vendorJobPHDetailsData.account_detail.data.attributes.aadhar_card_number.toString().slice(-4)}` : null}</Typography>
              </Grid>
              <Grid item sm={6} md={6} lg={6}>
                <Typography style={{ color: "#6d89af", fontFamily: "Urbanist", fontSize: "12px", fontWeight: 500, lineHeight: "15px", letterSpacing: "0.2px" }}>GST Number</Typography>
                <Typography style={{ marginTop: "3px", color: "#18272a", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 600, lineHeight: "17px", letterSpacing: "0.2px" }}>{this.state.vendorJobPHDetailsData.account_detail.data.attributes?.gst_number}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Divider />
            </Grid>
            <Grid container style={{ flexWrap: "nowrap" }}>
              <Grid item sm={6} md={6} lg={6} >
                <Typography style={{ color: "#6d89af", fontFamily: "Urbanist", fontSize: "12px", fontWeight: 500, lineHeight: "15px", letterSpacing: "0.2px" }}>PAN Number</Typography>
                <Typography style={{ marginTop: "3px", color: "#18272a", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 600, lineHeight: "17px", letterSpacing: "0.2px" }}>{this.state.vendorJobPHDetailsData.account_detail.data.attributes?.pan_card_number ? `${this.state.vendorJobPHDetailsData.account_detail.data.attributes.pan_card_number.toString().slice(0, 2)}xxxxxx${this.state.vendorJobPHDetailsData.account_detail.data.attributes.pan_card_number.toString().slice(-2)}` : null}</Typography>
              </Grid>
              <Grid item sm={6} md={6} lg={6}>
                <Typography style={{ color: "#6d89af", fontFamily: "Urbanist", fontSize: "12px", fontWeight: 500, lineHeight: "15px", letterSpacing: "0.2px" }}>TAN Number</Typography>
                <Typography style={{ marginTop: "3px", color: "#18272a", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 600, lineHeight: "17px", letterSpacing: "0.2px" }}>{this.state.vendorJobPHDetailsData.account_detail.data.attributes?.tan_number ? `${this.state.vendorJobPHDetailsData.account_detail.data.attributes.tan_number.toString().slice(0, 4)}xxxx${this.state.vendorJobPHDetailsData.account_detail.data.attributes.tan_number.toString().slice(-3)}` : null}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box style={{ marginTop: "16px" }}>
          {this.renderVendorJobPHDetailsOverviewTabDocumentsContent()}
        </Box>
      </Box> : null}
    </>
  }

  renderVendorJobPHDetailsStudiosTabContent = () => {
    return <>
      {this.state.vendorJobPHDetailsTabValue === 2 ? <Box style={{ background: "#ffffff", width: "calc(100% - 186px)", padding: "24px", overflowY: "auto", height: "648px" }}>
        {this.state.vendorJobPHDetailsStudiosData.length ? this.state.vendorJobPHDetailsStudiosData.map((item: any) => {
          return <Grid key={item.id + "manageprofile_studios"} container style={webStyle.vendorJobPHDetailsProjectsCard as React.CSSProperties}>
            <Grid item style={{ display: "flex" }}>
              <Avatar
                style={{ borderRadius: "10px", width: 52, height: 52 }}
                src={item.attributes.image || dummyImage}
              >
              </Avatar>
              <Grid style={{ marginLeft: "10px" }}>
                <Typography style={{ marginTop: "1px", lineHeight: "17px", color: "#17181d", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 600, letterSpacing: "0.175px" }}>{item.attributes.name}</Typography>
                <Typography style={{ marginTop: "4px", lineHeight: "13px", color: "#6d89af", fontFamily: "Urbanist", fontSize: "11x", fontWeight: 600, letterSpacing: "0.1375px" }}>{item.attributes.city} ({item.attributes.state})</Typography>
                <Typography style={{ marginTop: "3px", lineHeight: "15px", color: "#f5c42c", fontFamily: "Urbanist", fontSize: "12px", fontWeight: 400, letterSpacing: "0.15px" }}><span style={{ fontFamily: "Helvetica-Bold" }}>₹{item.attributes.price_per_day}</span> Per Day</Typography>
              </Grid>
            </Grid>
          </Grid>
        }) : null}
      </Box> : null}
    </>
  }

  renderVendorJobPHDetailsProjectsTabContent = () => {
    return <>
      {this.state.vendorJobPHDetailsTabValue === 1 ? <Box style={{ background: "#ffffff", width: "calc(100% - 186px)", padding: "24px", overflowY: "auto", height: "648px" }}>
        {this.state.vendorJobPHDetailsProjectsData.length ? this.state.vendorJobPHDetailsProjectsData.map((item: any) => {
          return <Grid key={item.id + "manageprofile_projects"} container style={webStyle.vendorJobPHDetailsProjectsCard as React.CSSProperties}>
            <Grid item style={{ display: "flex" }}>
              <Avatar
                style={{ borderRadius: "10px", width: 52, height: 52 }}
                src={item.attributes.image || dummyImage}
              >
              </Avatar>
              <Grid style={{ marginLeft: "10px" }}>
                <Typography style={{ marginTop: "1px", lineHeight: "15px", color: this.getProjectStatusColor(item.attributes.status.toLowerCase()), fontFamily: "Urbanist", fontSize: "12px", fontWeight: 600, letterSpacing: "0.15px" }}>{this.covertToPascalCase(item.attributes.status)}</Typography>
                <Typography style={{ marginTop: "1px", lineHeight: "17px", color: "#17181d", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 600, letterSpacing: "0.175px" }}>{item.attributes.project_name}</Typography>
                <Typography style={{ marginTop: "5px", lineHeight: "12px", color: "#17181d", fontFamily: "Urbanist", fontSize: "10px", fontWeight: 600, letterSpacing: "0.125px" }}>{item.attributes.start_date} - {item.attributes.end_date}</Typography>
              </Grid>
            </Grid>
            <Grid item style={{ display: "flex" }}>
              <Box style={webStyle.vendorJobPHDetailsProjectDateText as React.CSSProperties}>
                {item.attributes.created_at}
              </Box>
            </Grid>
          </Grid>
        }) : null}
      </Box> : null}
    </>
  }

  renderVendorJobPHDetailsRatingAndReviewTabContent = () => {
    return <>
      <Box style={webStyle.vendorJobPHDetailsRatingStartMainBox}>
        <Box style={webStyle.vendorJobPHDetailsRatingStartBox} >
          <Box style={{ display: "flex", alignItems: "center" }}>
            <Box className="star_icon rating_size">
              <Typography className="total_rating_typo">{this.state.vendorJobPHDetailsReviewData.average_rating || 0}</Typography>
              <Typography className="divide_num">{configJSON.divide}</Typography>
            </Box>
            <Box style={{ marginLeft: "12px", marginRight: "10px" }} className="rating_text">
              <Divider orientation="vertical" flexItem style={{ height: 23 } as React.CSSProperties} />
            </Box>
            <Rating
              name="customized-empty"
              value={this.state.vendorJobPHDetailsReviewData.average_rating || 0}
              size="small"
              precision={0.5}
              readOnly
              emptyIcon={<StarBorderIcon htmlColor="#ffb400" style={{ width: "18px", height: "18px" } as React.CSSProperties} />}
            />
          </Box>
        </Box>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="user_detail_grid_container_rating">
          <Typography className="rating_helper">{this.state.vendorJobPHDetailsReviewData.message}</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
          <Divider style={{ marginTop: "12px", marginBottom: "15px" }} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ margin: "0px 12px 16px", display: "flex", justifyContent: "space-between" }}>
          {
            Object.entries(this.state.vendorJobPHDetailsReviewData.Given_Star || { 5: 0, 4: 0, 3: 0, 2: 0, 1: 0 }).reverse().map((item: any, index: number) => {
              return <Box style={{ width: "calc(20% - 8px)", display: "flex", flexDirection: "column" }} key={`${item[0]}_ratingvalue`}>
                <LinearProgress variant="determinate" value={item[1] | 0} className="progress_bar_rating" style={{ backgroundColor: '#e3eaf4', borderRadius: '30px', marginBottom: "5px" }} />
                <Typography style={{ height: "12px", color: "#17181d", fontFamily: "Urbanist", fontSize: "10px", fontWeight: 600, letterSpacing: "0.2px" }}>{item[0]}{" "}Star</Typography>
              </Box>
            })
          }
        </Grid>
      </Box>
      <Box style={{ display: "flex", alignItems: "center", margin: "0px 12px" }}>
        <Typography style={webStyle.vendorJobPHDetailsAllReviewsTitle as React.CSSProperties}>All Reviews --------------------------------------------------</Typography>
      </Box>
      <Grid style={{ margin: "0px 12px", maxHeight: "478px", overflowY: "auto", msOverflowStyle: "none", scrollbarWidth: "none" }} item xs={12} sm={12} md={12} lg={12} xl={12}>
        {
          this.state.vendorJobPHDetailsReviewData.reviews?.data?.length ? (this.state.vendorJobPHDetailsReviewData.reviews.data.map((phReviewData: any, phReviewIndex: number) => {
            return <Box key={`${phReviewIndex}_review_box_vendorJobPHAllProjectList`} style={{ padding: '12px' }} className="review_box">
              <Grid spacing={3} container>
                <Box
                  sx={webStyle.vendorJobPHDetailsProjectcardParentBox}
                  className="review_card_box">
                  <Grid
                    item
                    xs={12} md={2} sm={12} lg={2} xl={2}>
                    <Avatar
                      style={{ borderRadius: "12px", width: 36, height: 36 }}
                      src={phReviewData.attributes.account.image || dummyImage}>
                    </Avatar>
                  </Grid>
                  <Grid
                    item
                    xs={12} md={8} sm={12} lg={8} xl={8}>
                    <Typography style={{ lineHeight: "19px", color: "#17181d", fontFamily: "Urbanist", fontSize: "16px", fontWeight: 600, letterSpacing: "0.2px" }}>
                      {phReviewData.attributes.account.full_name}
                    </Typography>
                    <Typography style={{ marginTop: "1px", lineHeight: "12px", color: "#6d89af", fontFamily: "Urbanist", fontSize: "10px", fontWeight: 500, letterSpacing: "0.2px" }}>
                      {phReviewData.attributes.created_at}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    className="project_date"
                    xs={12} sm={12} md={2} lg={2} xl={2} >
                    <Box
                      style={webStyle.vendorJobPHDetailsStars}>
                      <StarOutlinedIcon
                        className="start_color" />
                      <Typography
                        style={webStyle.vendorJobPHDetailsStarColor}>
                        &nbsp;{phReviewData.attributes.given_star}
                      </Typography>
                    </Box>
                  </Grid>
                </Box>
                <Grid item
                  style={{ padding: "0px 12px 12px", marginTop: '-2%' }}
                  xs={12} sm={12} md={12} lg={12} xl={12} >
                  <Typography style={{ fontFamily: "Urbanist", color: "#17181d", fontSize: "12px", fontWeight: 500, lineHeight: "16px", letterSpacing: "0.2px" }}>
                    {phReviewData.attributes.comment}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          })) : null
        }
      </Grid>
    </>
  }

  renderVendorJobPHDetailsBasicDetailsContent = () => {
    return <>
      <Box style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", alignItems: "center" }}>
        <Box style={{ display: "flex", flexWrap: "wrap", margin: "16px 0 16px 16px" }}>
          <StyledBadge
            style={{ width: 75, height: 75 }}
            overlap="circular"
            badgeContent={<img src={images.checkedIcon} style={{ width: 24, height: 24 }} />}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <Avatar
              src={this.state.vendorJobPHDetailsData.profile_image || dummyImage}
              style={{
                border: "3px solid #f5c42c",
                width: 69,
                height: 69,
                marginRight: 1,
              }}
            >
            </Avatar>
          </StyledBadge>
          <Box
            style={webStyle.VendorJobPHDetailsLeftBox as React.CSSProperties}
          >
            <Typography
              style={{
                fontFamily: "Urbanist",
                color: "#17181d",
                fontSize: "24px",
                letterSpacing: "0.3px",
                fontWeight: 700,
                paddingTop: "9px",
                lineHeight: "29px",
                textAlign: "center",
              }}
            >
              {this.state.vendorJobPHDetailsData.account_detail.data.attributes?.full_name}
            </Typography>
            <Typography
              style={webStyle.vendorJobPHDetailsAddressText}
            >
              <PlaceOutlinedIcon style={{
                width: "22px",
                marginRight: 3,
                height: "22px",
                fontSize: "small",
              }} />
              {this.state.vendorJobPHDetailsData.city}, {this.state.vendorJobPHDetailsData.state}
            </Typography>
          </Box>
          <Box style={webStyle.VendorJobPHDetailsRightBox as React.CSSProperties}>
            <Typography style={webStyle.vendorJobPHDetailsRightSideBoxText}>
              <SettingsPhoneIcon style={{
                fontSize: "small",
                width: "20.09px",
                height: "20px",
                marginRight: 10.4,
                color: "#f5c42c",
              }} />
              +{this.state.vendorJobPHDetailsData.account_detail.data.attributes?.country_code}-{this.state.vendorJobPHDetailsData.account_detail.data.attributes?.phone_number}
            </Typography>
            <Typography style={{ ...webStyle.vendorJobPHDetailsRightSideBoxText, margin: "9px 0px" }}>
              <img style={{ marginRight: 10.4 }} src={`${images.forwardMailIcon}`} />
              {this.state.vendorJobPHDetailsData.account_detail.data.attributes?.email}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  }

  renderVendorJobPHDetailsMainContent = (sideBarStatus: boolean = this.state.isVendorJobPHDetailsSideBarOpen) => {
    return <>
      <Loader loading={this.state.isLoading}></Loader>
      <Grid style={webStyle.vendorJobPHDetailsWholePageContentSetup} >
        <Header handleSideBar={this.handleVendorJobPHDetailsSideBar}
          dropDownStatus={this.state.isVendorJobPHDetailsDropdownOpen}
          navigate={this.props.navigation.navigate}
          sideBarStatus={sideBarStatus}
          handleDropdown={this.handleDropdown}
        />
        <Grid style={webStyle.vendorJobPHDetailsDynamicMainContent as React.CSSProperties}>
          <Box sx={webStyle.vendorJobPHDetailsBlockInfoTitle}>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Box data-test-id="homeLink" onClick={this.handleBackNavigation} style={{ cursor: "pointer", marginRight: "12px", backgroundColor: "#ffffff", border: "1px solid #e1e6ec", borderRadius: "21px", padding: "7.81px" }}>
                <ArrowBackIcon htmlColor="#17181d" style={{
                  fontSize: "small",
                  width: "23.39px",
                  height: "23.39px",
                }}
                />
              </Box>
              <Typography variant="h3" style={webStyle.vendorJobPHDetailsActiveBlockMainTitle as TypographyProps}>Production House</Typography>
            </Box>
          </Box>
          <Grid style={{ marginTop: "24px", borderRadius: "12px", border: "1px solid #e3eaf4", background: "#ffffff" }} item sm={12} xl={12} xs={12} md={12} lg={12}>
            {this.renderVendorJobPHDetailsBasicDetailsContent()}
          </Grid>
          <Grid container style={{ width: "100%", marginTop: "24px", alignItems: "flex-start" }}>
            <Grid md={8} sm={12} item className="manageProfileResponsive" style={{ maxHeight: "730px", minHeight: "406px", borderRadius: "12px", border: "1px solid #e3eaf4" }}>
              <Box style={{ display: "flex" }}>
                <Box style={webStyle.vendorJobPHDetailsLefttabsMainBox}>
                  <Box data-test-id="manageProfileOverviewTab" onClick={() => this.handleTabValueChange(0)} style={{ borderBottom: "1px solid #e3eaf4", cursor: "pointer", width: "138px" }}>
                    <Typography style={{ ...webStyle.vendorJobPHDetailsLeftSideTabText, background: this.state.vendorJobPHDetailsTabValue === 0 ? "#ffffff" : "#f8fafe", color: this.state.vendorJobPHDetailsTabValue === 0 ? "#f5c42c" : "#17181d" }}>Overview</Typography>
                  </Box>
                  <Box data-test-id="manageProfileProjectsTab" onClick={() => this.handleTabValueChange(1)} style={{ borderBottom: "1px solid #e3eaf4", cursor: "pointer", width: "138px" }}>
                    <Typography style={{ ...webStyle.vendorJobPHDetailsLeftSideTabText, background: this.state.vendorJobPHDetailsTabValue === 1 ? "#ffffff" : "#f8fafe", color: this.state.vendorJobPHDetailsTabValue === 1 ? "#f5c42c" : "#17181d" }}>Projects</Typography>
                  </Box>
                  <Box data-test-id="manageProfileStudiosTab" onClick={() => this.handleTabValueChange(2)} style={{ borderBottom: "1px solid #e3eaf4", cursor: "pointer", width: "138px" }}>
                    <Typography style={{ ...webStyle.vendorJobPHDetailsLeftSideTabText, background: this.state.vendorJobPHDetailsTabValue === 2 ? "#ffffff" : "#f8fafe", color: this.state.vendorJobPHDetailsTabValue === 2 ? "#f5c42c" : "#17181d" }}>Studios</Typography>
                  </Box>
                </Box>
                {this.renderVendorJobPHDetailsOverviewTabContent()}
                {this.renderVendorJobPHDetailsProjectsTabContent()}
                {this.renderVendorJobPHDetailsStudiosTabContent()}
              </Box>
            </Grid>
            <Grid md={4} sm={12} item className="studioBookingResponsive" style={{ paddingLeft: "24px" }}>
              <Grid item style={{ width: "100%", maxHeight: "680px", minHeight: "625px", borderRadius: "12px", border: "1px solid #e3eaf4", background: "#ffffff" }}>
                {this.renderVendorJobPHDetailsRatingAndReviewTabContent()}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </>
  }
  // Customizable Area End

  render() {
    return (
      <ThemeProvider theme={themeStyle}>
        <Container style={webStyle.vendorJobPHDetailsParentContainer}>
          {
            this.state.isVendorJobPHDetailsSideBarOpen ? (
              <Grid spacing={0} container>
                <Grid xs={3} item style={webStyle.vendorJobPHDetailsSidebarParentGrid} >
                  <SideBar
                    handleSideBar={this.handleVendorJobPHDetailsSideBar}
                    userData={{ name: "Manage Profile" }}
                    activeMenuItem={"Manage Profile"}
                    navigate={this.props.navigation.navigate}
                  />
                </Grid>
                <Grid style={{ display: "flex", flexDirection: "column", flexBasis: "77.75%", maxWidth: "77.75%" }} item xs={9} >
                  {this.renderVendorJobPHDetailsMainContent()}
                </Grid>
              </Grid>
            ) : (
              <Grid style={{ display: "flex", flexDirection: "column" }} item xs={12}>
                {this.renderVendorJobPHDetailsMainContent()}
              </Grid>
            )
          }
        </Container>
        <CustomNotification navigation={this.handleNavigation} notification={this.state.notification} handleCloseNotification={this.handleCloseNotification} />
      </ThemeProvider>
    );
  }
}

const webStyle = {
  vendorJobPHDetailsLefttabsMainBox: {
    height: "100%",
    width: "138px",
    background: "#f8fafe",
  },
  vendorJobPHDetailsGalleryPhotoAddMoreText: {
    fontFamily: "Urbanist",
    lineHeight: "18px",
    color: "#f5c42c",
    fontSize: "14px",
    letterSpacing: "0.21538462px",
    marginTop: "2px",
    fontWeight: 600,
  },
  vendorJobPHDetailsReadMoreText: {
    lineHeight: "15px",
    color: "#f5c42c",
    cursor: "pointer",
    letterSpacing: "0.18461539px",
    fontFamily: "Urbanist",
    fontSize: "12px",
    fontWeight: 600,
    textDecoration: "underline",
  },
  vendorJobPHDetailsProjectsCard: {
    height: "76px",
    width: "100%",
    display: "flex",
    borderRadius: "12px",
    border: "1px solid #e3eaf4",
    padding: "12px",
    flexDirection: "row",
    marginBottom: "12px",
    background: "#ffffff",
    justifyContent: "space-between",
  },
  vendorJobPHDetailsLeftSideTabText: {
    fontSize: "14px",
    lineHeight: "17px",
    fontWeight: 600,
    fontFamily: "Urbanist",
    color: "#17181d",
    padding: "27px 16px 21px 16px",
  },
  vendorJobPHDetailsAllReviewsTitle: {
    color: "#6d89af",
    fontSize: "12px",
    fontWeight: 600,
    textOverflow: "clip",
    lineHeight: "19px",
    whiteSpace: "nowrap",
    fontFamily: "Urbanist",
    overflow: "hidden",
  },
  vendorJobPHDetailsStars: {
    display: "flex",
    padding: "0px 8px",
    background: "linear-gradient(225deg, #58A2FF 0%, #1777E2 100%)",
    borderRadius: "12px",
    alignItems: "center",
    justifyContent: 'space-evenly',
  },
  vendorJobPHDetailsProjectStatus: {
    padding: "0px 8px",
    background: "linear-gradient(225deg, #58A2FF 0%, #1777E2 100%)",
    borderRadius: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: 'space-evenly',
  },
  vendorJobPHDetailsStarColor: {
    color: 'white',
  },
  vendorJobPHDetailsProjectcardParentBox: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
    padding: '2%',
    alignItems: "flex-start",
  },
  vendorJobPHDetailsProjectStatusBtn: {
    height: "24px",
    width: "74px",
    background: "linear-gradient(225deg, #50b609 0%, #42a200 100%)",
    borderRadius: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  vendorJobPHDetailsProjectStatusText: {
    color: "#ffffff",
    lineHeight: "12px",
    fontSize: "10px",
    fontWeight: 600,
    fontFamily: "Urbanist",
  },
  vendorJobPHDetailsProjectDateText: {
    color: "#6d89af",
    lineHeight: "12px",
    fontSize: "10px",
    fontFamily: "Urbanist",
    letterSpacing: "0.125px",
    fontWeight: 500,
    padding: "2px",
  },
  vendorJobPHDetailsRightSideBoxText: {
    color: "#17181d",
    lineHeight: "14px",
    fontSize: "14px",
    fontFamily: "Urbanist",
    letterSpacing: "0.23333333px",
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
  },
  VendorJobPHDetailsRightBox: {
    display: "flex",
    paddingLeft: "24.5px",
    alignItems: "start",
    flexDirection: "column",
    paddingTop: "16px",
  },
  VendorJobPHDetailsLeftBox: {
    height: "79px",
    borderRight: "1px solid #e3eaf4",
    display: "flex",
    paddingRight: "24px",
    alignItems: "start",
    flexDirection: "column",
    marginLeft: "10px",
  },
  vendorJobPHDetailsAddressText: {
    color: "#6d89af",
    lineHeight: "17px",
    fontSize: "14px",
    fontFamily: "Urbanist",
    letterSpacing: "0.23333333px",
    margin: "4px 0px",
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
  },
  vendorJobPHDetailsPhName: {
    color: "#6d89af",
    lineHeight: "17px",
    fontSize: "14px",
    fontFamily: "Urbanist",
    letterSpacing: "0.23333333px",
    marginTop: "4px",
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
  },
  VendorJobPHDetailsBtn: {
    height: "48px",
    width: "152px",
    background: "#ffffff",
    borderRadius: "10px",
    marginRight: "10px",
    border: "1px solid #17181d",
  },
  vendorJobPHDetailsLogoutBtn: {
    height: "46px",
    width: "46px",
    background: "#ffffff",
    borderRadius: "10px",
    alignItems: "center",
    border: "1px solid #ff5656",
    justifyContent: "center",
    display: "flex",
    marginRight: "24px",
    cursor: "pointer",
  },
  VendorJobPHDetailsBtnText: {
    fontFamily: "Urbanist",
    color: "#17181d",
    fontWeight: 600,
    fontSize: "14px",
    textTransform: "none",
    letterSpacing: "0.4px",
    lineHeight: "17px",
  },
  vendorJobPHDetailsRatingStartMainBox: {
    width: "100%",
  },
  vendorJobPHDetailsRatingStartBox: {
    paddingRight: "12px",
    paddingLeft: "12px",
    display: "flex",
    paddingTop: "12px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  vendorJobPHDetailsRateNowText: {
    color: "#f5c42c",
    height: "14px",
    fontSize: "12px",
    fontFamily: "Urbanist",
    textDecoration: "underline",
    fontWeight: 600,
    display: "flex",
    letterSpacing: "0.18461539px",
    cursor: "pointer",
    alignItems: "center",
  },
  vendorJobPHDetailsParentContainer: {
    padding: "0px",
    maxWidth: "none",
  },
  vendorJobPHDetailsSidebarParentGrid: {
    minHeight: "100vh",
    maxWidth: "22.25%",
  },
  vendorJobPHDetailsWholePageContentSetup: {
    // minHeight: "90vh",
    borderLeft: "1px solid #E3EAF4",
    flex: 1,
  },
  vendorJobPHDetailsDynamicMainContent: {
    boxSizing: "border-box",
    width: "100%",
    minHeight: "100vh",
    backgroundColor: "#F8FAFE",
    padding: "24px",
  },
  vendorJobPHDetailsBlockInfoTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
  },
  vendorJobPHDetailsHighlightedTitleext: {
    color: "#F5C42C",
    fontFamily: "Urbanist",
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: 600,
    textDecoration: "underline",
  },
  vendorJobPHDetailsActiveBlockMainTitle: {
    color: "#17181D",
    lineHeight: "34px",
    fontSize: "28px",
    letterSpacing: "1px",
    fontWeight: "700",
  },
  vendorJobPHDetailsBlockE2EPath: {
    letterSpacing: "1px",
    fontSize: "14px",
    color: "#6D89AF",
    fontWeight: 500,
    paddingTop: "8px",
    lineHeight: "17px",
  }
};
