import React from "react";
import {Dialog, DialogTitle, Box, DialogContent, DialogActions, withStyles, styled } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { CustomButton } from "../../blocks/email-account-login/src/CustomButton";
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';


const useStyles = makeStyles({
    dialogParent: { width: 455, margin: "auto" },
    dialogHeader: { display: "flex", justifyContent: "space-between" },
    dialogContaentContainer: {
        margin: "30px 0px 42px 0px",
        display: "flex",
        justifyContent: "space-between",
        gap: "14px",
    },
    content:{ display: "flex", flexWrap: "wrap", gap: "10px" },
    btn:{ height: 48, width: "160px", float: "right" },
    tickIcon:{backgroundColor:"#F5C42C",borderRadius:"50%",
    position:"absolute",
    right:"10px",
    top:"5px",
    height:"15px",
    width:"15px"
    }
});



const CustomNextStepButtonBox = styled(CustomButton)({
    '& .MuiButton-label': {
        fontSize: 14,
        color: '#ffffff',
        fontWeight: 500,
        width: 163,
        height: 48,
    }
});

export default function UploadDefaultImageModal(props: any) {
    const classes = useStyles();

    return (
        <>
            <Dialog
                style={{ width: 650, margin: "auto" }}
                fullWidth={true}
                open={props.opendefaultImageModal}
                onClose={props.closeDefaultImagePopup}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" >
                    <Box className={classes.dialogHeader}>
                        <span></span>
                        <span> {"Upload Project Image"}</span>
                        <span onClick={props.closeDefaultImagePopup}><CloseIcon /></span>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box className={classes.content} >
                        {props.imageList.map((ele: any) => (
                            <div style={{ position: "relative" }}>
                                <CheckOutlinedIcon className={classes.tickIcon} style={{
                                    display: props.selectedImageIndex === ele.id ? "block" : "none",
                                }} />
                                <img key={ele.id} style={{
                                    borderRadius: "8px",
                                    border: props.selectedImageIndex === ele.id ? '1px solid #F5C42C' : 'none',
                                    cursor: 'pointer',
                                }} onClick={() => props.handleClickOnImage(ele.id)} width={125} height={70} src={ele.attributes.image} alt={ele.src} />
                            </div>
                        ))}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <CustomNextStepButtonBox className={`${classes.btn} button`} data-test-id="saveImage_button"  onClick={props.handleSaveDefaultImage}>
                        Save Image
                    </CustomNextStepButtonBox>

                </DialogActions>

            </Dialog>
        </>
    );
}
