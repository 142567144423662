import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ChangeEvent} from "react";
// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config");
// Customizable Area End



export interface Props {
    // Customizable Area Start
    navigation: any;
    id: string;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    loading: boolean;
    isJobRoleRequestSideBarOpen: boolean;
    isJobRoleRequestHeaderDropdownOpenVal: boolean;
    txtSavedValue: string;
    isLoading: boolean;
    jobRoleAllRequest: any[];
    projectName:any[];
    projectDepartment:any[];
    projectJobRoleRequest:any[];
    jobRoleInputs:any;
    validationStatusJobRole:any;
    projectRole:any[];
    totalPages: number
    count:number;
    totalJobRoleCount : number;
    vendorInviteCount : number;
    currentpage: number,
    open:boolean
    open2:boolean
    tabIndex:number
    jobRoleprojectName:string
    projectRequest:any[];
    allJobRoleRequestData:any[];
    requestProjectData:any[];
    requestProfileProjects:any[];
    requestedVendorData:any[];
    notification: { type: string, open: boolean, message: string, route?: string };
    JobRoleNotification: { type: string, open: boolean, message: string, route?: string };
    isProjectSelected:boolean
    isDepartmentSelected:boolean
    isRoleTypeSeleted:boolean
    roleReqError:any
    expError:any
    budgetError:any
    noOfPosError:any
    roleDesError:any
    setEditJobRole:boolean
    requestProfileData:any;
    requestProfileTabValue: number;
    bioReadMoreLessRequestPro: boolean;
    ratingAndReviewData:any;
    requestProfileRateValue:number;
    requestProfileCommentValue:string;
    requestRatingValidation:string;
    requestCommentValidation:string;
    inviteVendorModalJobRole:boolean;
    vendorList:any[];
    vendorListSearch:any[];
    manageProfileInviteVendor:any[];
    Status:string;
    searchValue: string;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: string | number;
    // Customizable Area End
}

export default class InviteVendorListPDController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
   inviteVendorListApiCallid:string="";
   inviteVendorListApiSearchid:string="";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            loading: false,
            isJobRoleRequestSideBarOpen: true,
            isJobRoleRequestHeaderDropdownOpenVal: false,
            txtSavedValue: "A",
            isLoading: false,
            jobRoleAllRequest: [],
            totalPages: 0,
            currentpage: 1,
            count:0,
            totalJobRoleCount:0,
            vendorInviteCount:0,
            projectName:[],
            projectDepartment:[],
            projectRole:[],
            open:false,
            open2:false,
            jobRoleInputs:{
                selectedProject:null,
                selectedProjectDept:null,
                selectedProjectRole:null,
                roleRequirements:[],
                expRequired:"",
                roleBudget:"",
                noOfPos:"",
                roleDescription:"",
                projectId:null,
                projectDeptId:null,
                roleID:null


            },
            roleReqError:{
                require:false
            },
            expError:{
                require:false
            },
            budgetError:{
                require:false
            },
            noOfPosError:{
                require:false
            },
            roleDesError:{
                require:false
            },
            validationStatusJobRole:{
                roleRequirements:{label:"Role Requirements" , valid:false , message :""},
                expRequired:{label:"Exp Required" , valid:false , message :""},
                roleBudget:{label:"Role Budget" , valid:false , message :""},
                noOfPos:{label:"No of Pos" , valid:false , message :""},
                roleDescription:{label:"Role Description" , valid:false , message :""}
            },
            tabIndex:0,
        
            projectJobRoleRequest:[],
            projectRequest:[],
            allJobRoleRequestData:[],
            requestProjectData:[],
            requestProfileProjects:[],
            requestedVendorData:[],
            notification: { type: "", open: false, message: "" },
            JobRoleNotification: { type: "", open: false, message: "" },
            isProjectSelected:true,
            isDepartmentSelected:true,
            isRoleTypeSeleted:true,
            setEditJobRole:false,
            requestProfileData: {
                id: "",
                profile_image: "",
                role: "",
                city: "",
                state: "",
                experience: "",
                gallary_photos: [],
                bank_detail: {
                  data: {
                    attributes: {
                      image: "",
                      bank_name: "",
                    }
                  }
                },
                account_detail: {
                  data: {
                    attributes: {
                      full_name: "",
                      country_code: "",
                      phone_number: "",
                      email: "",
                      aadhar_card_number: "",
                      gst_number: "",
                      pan_card_number: "",
                      tan_number: "",
                      account_number: "",
                      ifsc_code: "",
                      branch_name: "",
                    }
                  }
                },
                profile_bio: {
                  data: {
                    attributes: {
                      about_business: "",
                    }
                  },
                },
                documents: [],
              },
            requestProfileTabValue:0,
            bioReadMoreLessRequestPro:true,
            ratingAndReviewData:{},
            requestProfileRateValue:0,
            requestRatingValidation:'',
            requestCommentValidation:'',
            requestProfileCommentValue:'',
            inviteVendorModalJobRole:false,
            vendorList:[],
            vendorListSearch:[],
            manageProfileInviteVendor:[],
            Status:'',
            jobRoleprojectName:'',
            searchValue: "",
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
            let valueBalanceSheet = message.getData(getName(MessageEnum.AuthTokenDataMessage));


            this.setState({ txtSavedValue: valueBalanceSheet });
            this.showAlert(
                "Change Value",
                "From: " + this.state.txtSavedValue + " To: " + valueBalanceSheet
            );

        }
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

          if(apiRequestCallId===this.inviteVendorListApiCallid)    
          {
            this.setState({isLoading:false})
            console.log("invitevendorlisting................",responseJson)
            this.setState({vendorList:responseJson.data,count:responseJson.meta.count})
          }
  
        if(apiRequestCallId===this. inviteVendorListApiSearchid)
        {   
            this.setState({isLoading:false})
            console.log("invitevendorlisting................",responseJson)
            this.setState({vendorList:responseJson.data,count:responseJson.meta.count})
        }
        
            
        }

        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
      let isSideBarOpen=await getStorageData("sidebarvalue");
     this.setState({isJobRoleRequestSideBarOpen:JSON.parse(isSideBarOpen)})
        this.getInviteVendorList()
        const statusvalue= await getStorageData("statusPH")
        this.setState({Status:statusvalue})
        const projectNames=  await getStorageData("jobRoleproject_Name");
        this.setState({jobRoleprojectName:projectNames})
        

    }

 

    handlePageChange = async (event: any, value: number) => {
        if (this.state.searchValue) {
            this. inviteVendorListApiSearchid = await this.apiCall(configJSON.GET_Method, configJSON.invitevendorListEndPoint +`search=${this.state.searchValue}&per_page=9&page=${value}`);
            window.scrollTo(0, 0);
          }
         else{ 
        this.inviteVendorListApiCallid = await this.apiCall(configJSON.GET_Method, configJSON.invitevendorListEndPoint+`per_page=9&page=${value}`)
          window.scrollTo(0, 0);
         }
        this.setState({ currentpage: value });
      };


  

  
    handleJobRoleHeaderDropdownVal = () => {
        this.setState({
            isJobRoleRequestHeaderDropdownOpenVal: !this.state.isJobRoleRequestHeaderDropdownOpenVal
        })
    };
    handleJobRoleSideBar = () => {
        this.setState((prevState) => ({
            isJobRoleRequestSideBarOpen: !prevState.isJobRoleRequestSideBarOpen
        }), async () => {
            setStorageData("sidebarvalue", this.state.isJobRoleRequestSideBarOpen.toString())
        });
    };

   



    

   
    getInviteVendorList = async () => {
        this.inviteVendorListApiCallid = await this.apiCall(configJSON.GET_Method, configJSON.invitevendorListEndPoint+`per_page=9&page=1` )
    
      }


    apiCall = async (method: string, endPoint: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
          "Content-Type": configJSON.exampleApiContentType,
          "token": authenticationToken
        };
        this.setState({ isLoading: true });
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endPoint
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          method
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
      };
    

 





 
  

   
      handleNavigationRequestProfile= async (id:any,profileid:any)=>{

        setStorageData("dataid", id)
    
     
       setStorageData("profileid",profileid)
       
      
          this.props.navigation.navigate("InviteVendorProfilePD");
          
      }


  


      handleNavigation = (page: string) => {
        this.props.navigation.navigate(page)
    };




    filterHouses = async (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({
          currentpage: 1,
          searchValue: event.target.value,
        });
        if (event.target.value)
          this. inviteVendorListApiSearchid = await this.apiCall(configJSON.GET_Method, configJSON.invitevendorListEndPoint +`search=${event.target.value}&per_page=9&page=1`);
        else
        this.inviteVendorListApiCallid = await this.apiCall(configJSON.GET_Method, configJSON.invitevendorListEndPoint+`per_page=9&page=1` )
      }
 

 

  


  
    // Customizable Area End
}


