Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.btnExampleTitle = "Submit";
exports.invoiceValidation ="Please enter 6 Digit Invoice Number"
exports.enterInvoice="Invoice Number"
exports.invoiceNumber="Enter Invoice Number (for example 299206)"
exports.keyboardType="phone-pad"
exports.exampleAPiEndPoint = "https://nickcoingophase1v2-158847-ruby.b158847.stage.eastus.az.svc.builder.ai/bx_block_invoice/invoice/generate_invoice_pdf?invoice_number=";
exports.exampleAPiMethod = "GET";
exports.GET_Method = "GET";
exports.PUT_Method = "PUT";
exports.getInvoiceRequestDetailsApiEndPoint = "/bx_block_invoicebilling/invoices"
exports.getAllProjectInvoicesApiEndPoint = "/bx_block_invoicebilling/project_invoices"
exports.getAllInvoicesOnSortBasisApiEndPoint = "/bx_block_invoicebilling/project_invoices"
exports.getAllProjectInvoicesSearchApiEndPoint = "bx_block_invoicebilling/project_invoices?search"
exports.getallinvoicerequestlistEndPoint ="/bx_block_projecttasktracking2/user_projects"
exports.getInvoiceRaisedApiEndPoint= "/bx_block_invoicebilling/get_invoice_raised"
exports.getJobRolesApiEndPoint= "/bx_block_invoicebilling/get_job_role"
exports.getDepartmentListApiEndPoint="/bx_block_invoicebilling/get_department"
exports.getProjectListApiEndPoint="/bx_block_invoicebilling/get_project"
exports.getInvoicesAmountListApiEndPoint="/bx_block_invoicebilling/get_invoice_amount"
exports.getProjectInvoicesDeatilsApiEndPoint = "/bx_block_invoicebilling/invoices"
exports.putInvoicesRejectedApiCallId ="/bx_block_invoicebilling/invoices/handle_invoice"
exports.tabLabelsInvoice = [
  "Pipeline Employees",
  "Project Vendors",
]
exports.invoicesText="Invoice Requests";
// Customizable Area End