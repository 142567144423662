import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { ICategory } from "./ManageBalanceSheetController";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isDropdownOpen: boolean;
  isSideBarOpen: boolean;
  tabValue: number;
  isModelOpen: boolean;
  modelType: string;
  categories: any;
  editCategory: any;
  selectedDepartment: any;
  token: string;
  isLoading: boolean;
  balanceSheetId: string;
  allDepartmentsOfProject: { id: number, name: string }[];
  page: number;
  perPage: number;
  pageCount: number;
  projectName: string;
  totalAmountByDept: string;
  userAccountType:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ViewBalanceSheetController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apigetBudgetDetailsCallId: string = "";
  apigetProjectDepartmentsCallId: string = "";
  apigetProjectDetailsCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isDropdownOpen: false,
      isSideBarOpen: true,
      tabValue: 0,
      isModelOpen: false,
      modelType: "",
      categories: [],
      editCategory: null,
      selectedDepartment: "",
      token: "",
      isLoading: false,
      balanceSheetId: "",
      allDepartmentsOfProject: [],
      page: 1,
      perPage: 2,
      pageCount: 0,
      projectName: "",
      totalAmountByDept: "",
      userAccountType:""

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (this.apigetProjectDepartmentsCallId === apiRequestCallId) {
        this.getProjectDepartmentsHandleResponse(responseJson);
      } else if (this.apigetBudgetDetailsCallId === apiRequestCallId) {
        this.getBudgetDetailsByDepartmentDataHandleResponse(responseJson);
      } else if (this.apigetProjectDetailsCallId === apiRequestCallId) {
        this.getProjectDetailsDataHandleResponse(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getProjectDetailsDataHandleResponse = (responseJson: { data: { attributes: { project_name: string } } }) => {
    if (responseJson.data) {
      this.setState({ projectName: responseJson.data.attributes.project_name });
    }
    this.setState({ isLoading: false });
  }

  getProjectDepartmentsHandleResponse = async (responseJson: { meta: { project_department: { id: number, name: string }[] } }) => {
    if (responseJson.meta?.project_department) {
      this.setState({ allDepartmentsOfProject: responseJson.meta.project_department, selectedDepartment: responseJson.meta.project_department[1] });
      this.getBudgetDetails(`${this.state.balanceSheetId}&project_department_id=${this.state.selectedDepartment?.id}&page=${this.state.page}&per_page=${this.state.perPage}`);
    }
    this.setState({ isLoading: false });
  }

  async componentDidMount() {
    const authBSToken = await getStorageData("token");
    const balanceSheetProjectId = await getStorageData("BalanceSheetId");
    const userType = await getStorageData("account_type");
    const isViewBalanceSheetSideBarOpen = await getStorageData("sidebarvalue");
    this.setState({userAccountType:userType})
    this.setState({ token: authBSToken, balanceSheetId: balanceSheetProjectId, isSideBarOpen: JSON.parse(isViewBalanceSheetSideBarOpen) }, () => {
      this.getProjectDepartments();
      this.getProjectDetails();
    });
  }

  getProjectDetails = () => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: this.state.token
    };
    const requestProjectDetailsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setState({ isLoading: true });
    this.apigetProjectDetailsCallId = requestProjectDetailsMessage.messageId;
    requestProjectDetailsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProjectDetailsAPI + this.state.balanceSheetId
    );
    requestProjectDetailsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestProjectDetailsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );
    runEngine.sendMessage(requestProjectDetailsMessage.id, requestProjectDetailsMessage);
    return true;
  }

  getProjectDepartments = () => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setState({ isLoading: true });
    this.apigetProjectDepartmentsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProjectsDepartmentAPI + this.state.balanceSheetId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getBudgetDetailsByDepartmentDataHandleResponse = (responseJson: { data: ICategory[], meta: { total_page: number, total_amount: string } }) => {
    if (responseJson.data) {
      this.setState({ categories: responseJson.data, pageCount: responseJson.meta.total_page, totalAmountByDept: responseJson.meta.total_amount });
    }
    this.setState({ isLoading: false });
  }

  getBudgetDetails = (endPoint: string) => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setState({ isLoading: true });
    this.apigetBudgetDetailsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getBudgetDetailsWithPagination + endPoint);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleViewBalanceSheetDropdown = () => {
    this.setState({
      isDropdownOpen: !this.state.isDropdownOpen
    });
  };

  handleSelectDepartmentView = async (event: any) => {
    const selectedProjectDept = this.state.allDepartmentsOfProject.find((dept: { id: number, name: string }) => dept.id === event.target.value);
    this.setState({ selectedDepartment: selectedProjectDept || null });
    this.getBudgetDetails(`${this.state.balanceSheetId}&project_department_id=${event.target.value}&page=${this.state.page}&per_page=${this.state.perPage}`);
  }

  handleNavigation = (page: string) => {
    this.props.navigation.navigate(page)
  };

  handleAppBarValue = (event: React.ChangeEvent<{}>, value: number) => {
    this.setState({
      tabValue: value
    })
  }

  handleViewBalanceSheetSideBar = () => {
    setStorageData("sidebarvalue", (!this.state.isSideBarOpen).toString());
    this.setState({
      isSideBarOpen: !this.state.isSideBarOpen
    });
  };

  getClassForLastElement = (index: number, subCategoryRowCount: number) => {
    const isLastElement = index + 1 === subCategoryRowCount;
    return isLastElement ? "category_both_child" : "category_first_child";
  }

  handleIsShowLine = (index: number, subCategoryRowCount: number) => {
    return !((index + 1) % 4) && index !== 0 && (index + 1) !== subCategoryRowCount
  }

  handleIsViewLineShow = (index: number, subCategoryRowCount: number) => {
    return ((index + 1) % 4) && (index + 1) !== subCategoryRowCount
  }

  handleBudgetSheetPageChange = (event: any, value: number) => {
    this.setState({
      page: value,
    });
    this.getBudgetDetails(`${this.state.balanceSheetId}&project_department_id=${this.state.selectedDepartment?.id}&page=${value}&per_page=${this.state.perPage}`);
  }
  // Customizable Area End
}
