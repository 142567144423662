import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  isLoading: boolean;
  crewCountData: any[];
  crewCountNotification: { type: string, open: boolean, message: string, route?: string };
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CrewCountViewController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apigetCrewCountDataCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      isLoading: false,
      crewCountData: [],
      crewCountNotification: { type: "", open: false, message: "" },
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.apigetCrewCountDataCallId === apiRequestCallId) {
        this.getCrewCountDataHandleResponse(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getCrewCountDataHandleResponse = (responseJson: { data: any[], status: number, error: string }) => {
    if (responseJson.data?.length) {
      this.setState({ crewCountData: responseJson.data });
    } else if (responseJson.error) {
      this.setState({ crewCountNotification: { open: true, type: "error", message: responseJson.status + " " + responseJson.error } });
    }
    this.setState({ isLoading: false });
  }

  async componentDidMount() {
    const authToken = await getStorageData("token");
    const balanceSheetId = await getStorageData("BalanceSheetId");
    this.setState({ token: authToken }, () => {
      this.getCrewCountData(balanceSheetId);
    });
  }

  getCrewCountData = (id: string) => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: this.state.token
    };
    this.setState({ isLoading: true });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apigetCrewCountDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getBalanceSheetCrewCountAPI + id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleCrewCountNotification = () => {
    this.setState({ crewCountNotification: { type: "", open: false, message: "" } });
  }
  // Customizable Area End
}
