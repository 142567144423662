import React from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  TypographyProps,
  Link, Avatar, Button, Divider, LinearProgress, IconButton, Dialog, DialogTitle, DialogActions
} from "@material-ui/core";
import SideBar from "../../../components/src/SideBar";
import Header from "../../../components/src/Header.web";
import Footer from "../../../components/src/Footer";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Loader from "../../../components/src/Loader.web";
import ProductionHouseManageProfileController, {
  Props, configJSON
} from "./ProductionHouseManageProfileController";
import WorkOutlineTwoToneIcon from '@material-ui/icons/WorkOutlineTwoTone';
import SettingsPhoneIcon from '@material-ui/icons/SettingsPhone';
import PlaceOutlinedIcon from '@material-ui/icons/PlaceOutlined';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarOutlinedIcon from '@material-ui/icons/StarOutlined';
import { StyledBadge } from "../../fullteamvisibility/src/CardComponent.web";
import Rating from "@material-ui/lab/Rating";
import GetAppIcon from '@material-ui/icons/GetApp';
import CustomNotification from "../../../components/src/CustomNotification";
import { ProfileDummy} from "../../../components/src/assets";
import { dummyImage } from "../../invoicebilling/src/assets";
const images = require("./assets");
const baseURL = require("../../../framework/src/config");

const themeStyle = createTheme({
  palette: {
    primary: {
      main: "#F5C42C",
      contrastText: "#fff"
    }
  },
  overrides: {
    MuiSelect: {
      select: {
        "&:focus": {
          border: "1px solid #F5C42C",
        }
      }
    }
  }
});

export default class ProductionHouseManageProfile extends ProductionHouseManageProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderVendorManageProfileOverviewTabDocumentsContent = () => {
    return <>
      <Typography style={webStyle.productionHouseManageProfileAllReviewsTitle as React.CSSProperties}>Certificates & Documents ----------------------------------------------------------------------------------</Typography>
      <Grid container style={{ marginTop: "8px", width: "100%", minHeight: "88px", borderRadius: "12px", border: "1px solid #e3eaf4", padding: 12, boxSizing: "border-box", color: "#17181d", fontFamily: "Urbanist", fontSize: "12px", fontWeight: 500, lineHeight: "16px" }}>
        <Grid container spacing={2} className="cert_box">
          {this.state.productionHouseManageProfileData.documents.length ?
            this.state.productionHouseManageProfileData.documents.map((document: any, index: number) => {
              return <Grid item key={`${index + document.id}_documentlist`} xs={6} sm={6} md={3} lg={3} xl={3}>
                <Box className="vendor_manage_profile_cert_box">
                  <Box className="cert_inner_tool">
                    <Box style={{ backgroundColor: "rgba(245,196,44,0.09)", borderRadius: "8px", padding: "9.9% 13.6%" }}>
                      <img style={{ width: "14.61px", height: "21px" } as React.CSSProperties} src={images.certificateIcon} alt="" />
                    </Box>
                    <IconButton data-test-id={`pdf_download_${document.id}`} onClick={() => this.vendorManageProfileHandleDownloadPDF(document.path_url, document.filename)} className="download_icon" size="medium">
                      <GetAppIcon fontSize="small" className="download_icon_color" />
                    </IconButton>
                  </Box>
                  <Box className="project_name">
                    <Typography noWrap className="file_name">{document.filename}</Typography>
                  </Box>
                </Box>
              </Grid>
            }) : null
          }
        </Grid>
      </Grid>
    </>
  }

  renderReadMoreLess = () => {
    return <>
      {
          this.state.productionHouseManageProfileData.profile_bio.data.attributes.about_business
      }
    </>
  }

  renderVendorManageProfileOverviewTabProfileBioContent = () => {
    return <>
      <Typography style={webStyle.productionHouseManageProfileAllReviewsTitle as React.CSSProperties}>About Production House----------------------------------------------------------------------------------</Typography>
      <div style={{ marginTop: "8px", width: "100%", minHeight: "72px", borderRadius: "12px", border: "1px solid #e3eaf4", padding: 12, boxSizing: "border-box", color: "#17181d", fontFamily: "Urbanist", fontSize: "12px", fontWeight: 600, lineHeight: "16px" }}>
        {this.state.productionHouseManageProfileData.profile_bio.data ? this.renderReadMoreLess() : null}
      </div>
    </>
  }

  renderVendorManageProfileOverviewTabContent = () => {
    return <>
      {this.state.productionHouseManageProfileTabValue === 0 ? <Box style={{ background: "#ffffff", width: "calc(100% - 186px)", padding: "13px 24px 16px 24px" }}>
      <Box style={{ marginTop: "16px" }}>
          {this.renderVendorManageProfileOverviewTabProfileBioContent()}
        </Box>
        <Box>
          <Typography style={webStyle.productionHouseManageProfileAllReviewsTitle as React.CSSProperties}>Official Details & Docs ----------------------------------------------------------------------------------</Typography>
          <Grid container style={{ marginTop: "8px", width: "100%", height: "123px", borderRadius: "12px", border: "1px solid #e3eaf4", padding: 14, display: "flex", flexDirection: "row" }}>
            <Grid container style={{ flexWrap: "nowrap" }}>
              <Grid item sm={6} md={6} lg={6} >
                <Typography style={{ color: "#6d89af", fontFamily: "Urbanist", fontSize: "12px", fontWeight: 500, lineHeight: "15px", letterSpacing: "0.2px" }}>Aadhaar Number</Typography>
                <Typography style={{ marginTop: "3px", color: "#18272a", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 600, lineHeight: "17px", letterSpacing: "0.2px" }}>{this.state.productionHouseManageProfileData.account_detail.data.attributes?.aadhar_card_number ? `${this.state.productionHouseManageProfileData.account_detail.data.attributes.aadhar_card_number.toString().slice(0, 4)} xxxx ${this.state.productionHouseManageProfileData.account_detail.data.attributes.aadhar_card_number.toString().slice(-4)}` : null}</Typography>
              </Grid>
              <Grid item sm={6} md={6} lg={6}>
                <Typography style={{ color: "#6d89af", fontFamily: "Urbanist", fontSize: "12px", fontWeight: 500, lineHeight: "15px", letterSpacing: "0.2px" }}>GST Number</Typography>
                <Typography style={{ marginTop: "3px", color: "#18272a", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 600, lineHeight: "17px", letterSpacing: "0.2px" }}>{this.state.productionHouseManageProfileData.account_detail.data.attributes?.gst_number}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Divider />
            </Grid>
            <Grid container style={{ flexWrap: "nowrap" }}>
              <Grid item sm={6} md={6} lg={6} >
                <Typography style={{ color: "#6d89af", fontFamily: "Urbanist", fontSize: "12px", fontWeight: 500, lineHeight: "15px", letterSpacing: "0.2px" }}>PAN Number</Typography>
                <Typography style={{ marginTop: "3px", color: "#18272a", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 600, lineHeight: "17px", letterSpacing: "0.2px" }}>{this.state.productionHouseManageProfileData.account_detail.data.attributes?.pan_card_number ? `${this.state.productionHouseManageProfileData.account_detail.data.attributes.pan_card_number.toString().slice(0, 2)}xxxxxx${this.state.productionHouseManageProfileData.account_detail.data.attributes.pan_card_number.toString().slice(-2)}` : null}</Typography>
              </Grid>
              <Grid item sm={6} md={6} lg={6}>
                <Typography style={{ color: "#6d89af", fontFamily: "Urbanist", fontSize: "12px", fontWeight: 500, lineHeight: "15px", letterSpacing: "0.2px" }}>TAN Number</Typography>
                <Typography style={{ marginTop: "3px", color: "#18272a", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 600, lineHeight: "17px", letterSpacing: "0.2px" }}>{this.state.productionHouseManageProfileData.account_detail.data.attributes?.tan_number ? `${this.state.productionHouseManageProfileData.account_detail.data.attributes.tan_number.toString().slice(0, 4)}xxxx${this.state.productionHouseManageProfileData.account_detail.data.attributes.tan_number.toString().slice(-3)}` : null}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box style={{ marginTop: "16px" }}>
          {this.renderVendorManageProfileOverviewTabDocumentsContent()}
        </Box>
      </Box> : null}
    </>
  }

renderVendorManageProfileStudiosTabContent = () => {
    return <>
      {this.state.productionHouseManageProfileTabValue === 1 ? <Box style={{ background: "#ffffff", width: "calc(100% - 186px)", padding: "13px 24px 16px 24px", overflowY: "auto", height: "648px" }}>
        <Typography style={{ ...webStyle.productionHouseManageProfileAllReviewsTitle as React.CSSProperties, marginBottom: "3px" }}>{this.state.productionHouseManageProfileStudiosData.length ? `${this.state.productionHouseManageProfileStudiosData.length} Successful Studios` : "No Data Found"} ----------------------------------------------------------------------------------</Typography>
        {this.state.productionHouseManageProfileStudiosData.length ? this.state.productionHouseManageProfileStudiosData.map((item: any) => {
          return <Grid key={item.id + "manageprofile_projects"} container style={webStyle.projectsCardProductionHouse as React.CSSProperties}>
            <Grid item style={{ display: "flex" }}>
              <Avatar
                style={{ borderRadius: "12px", width: 64, height: 64 }}
                src={item.attributes.image ||dummyImage}
              >
              </Avatar>
              <Grid style={{ marginLeft: "12px" }}>
                <Typography style={{ lineHeight: "19px", color: "#113260", fontFamily: "Urbanist", fontSize: "16px", fontWeight: 600, letterSpacing: "0.2px" }}>{item.attributes.name}</Typography>
                <Typography style={{ marginTop: "3px", lineHeight: "19px", color: "#6d89af", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 500, letterSpacing: "0.23333333px" }}>{item.attributes.address}</Typography>
                <Typography style={{ marginTop: "3px", lineHeight: "19px", color: "rgb(245, 196, 44)", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 'bold', letterSpacing: "0.23333333px" }}>₹ {item.attributes.price_per_day} per day</Typography>
              </Grid>
            </Grid>
          </Grid>
        }) : null}
      </Box> : null}
    </>
  }

  renderVendorManageProfileProjectsTabContent = () => {
    return <>
      {this.state.productionHouseManageProfileTabValue === 2 ? <Box style={{ background: "#ffffff", width: "calc(100% - 186px)", padding: "13px 24px 16px 24px", overflowY: "auto", height: "648px" }}>
        <Typography style={{ ...webStyle.productionHouseManageProfileAllReviewsTitle as React.CSSProperties, marginBottom: "3px" }}>{this.state.productionHouseManageProfileProjectsData.length ? `${this.state.productionHouseManageProfileProjectsData.length} Successful Projects` : "No Data Found"} ----------------------------------------------------------------------------------</Typography>
        {this.state.productionHouseManageProfileProjectsData.length ? this.state.productionHouseManageProfileProjectsData.map((item: any) => {
          return <Grid key={item.id + "manageprofile_projects"} container style={webStyle.projectsCardProductionHouse as React.CSSProperties}>
            <Grid item style={{ display: "flex" }}>
              <Avatar
                style={{ borderRadius: "12px", width: 64, height: 64 }}
                src={item.attributes.image ||dummyImage}
              >
              </Avatar>
              <Grid style={{ marginLeft: "12px" }}>
              <Box style={webStyle.productionManageProfileProjectStatusBtn}>
                <span style={webStyle.productionHouseManageProfileProjectStatusText} >{this.covertToPascalCase(item.attributes.status)}</span>
              </Box>
              <Typography style={{ marginTop: "3px", lineHeight: "19px", color: "#6d89af", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 500, letterSpacing: "0.23333333px" }}>{item.attributes.project_name}</Typography>
                <Typography style={{ marginTop: "3px", lineHeight: "19px", color: "#6d89af", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 500, letterSpacing: "0.23333333px" }}>{item.attributes.start_date}-{item.attributes.end_date}</Typography>
              </Grid>
            </Grid>
            <Grid item style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
              <Box style={webStyle.productionHouseManageProfileProjectDateText as React.CSSProperties}>
                {item.attributes.created_at}
              </Box>
            </Grid>
          </Grid>
        }) : null}
      </Box> : null}
    </>
  }

  renderVendorManageProfileRatingAndReviewTabContent = () => {
    return <>
      <Box style={webStyle.productionHouseManageProfileRatingStartMainBox}>
        <Box style={webStyle.productionHouseManageProfileRatingStartBox} >
          <Box style={{ display: "flex", alignItems: "center" }}>
            <Box className="star_icon rating_size">
              <Typography className="total_rating_typo">{this.state.productionHouseManageProfileReviewData.average_rating || 0}</Typography>
              <Typography className="divide_num">{configJSON.divide}</Typography>
            </Box>
            <Box style={{ marginLeft: "12px", marginRight: "10px" }} className="rating_text">
              <Divider orientation="vertical" flexItem style={{ height: 23 } as React.CSSProperties} />
            </Box>
            <Rating
              name="customized-empty"
              value={this.state.productionHouseManageProfileReviewData.average_rating || 0}
              size="small"
              precision={0.5}
              readOnly
              emptyIcon={<StarBorderIcon htmlColor="#ffb400" style={{ width: "18px", height: "18px" } as React.CSSProperties} />}
            />
          </Box>
        </Box>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="user_detail_grid_container_rating">
          <Typography className="rating_helper">{this.state.productionHouseManageProfileReviewData.message}</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
          <Divider style={{ marginTop: "12px", marginBottom: "15px" }} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ margin: "0px 12px 16px", display: "flex", justifyContent: "space-between" }}>
          {
            Object.entries(this.state.productionHouseManageProfileReviewData.Given_Star || { 5: 0, 4: 0, 3: 0, 2: 0, 1: 0 }).reverse().map((item: any, index: number) => {
              return <Box style={{ width: "calc(20% - 8px)", display: "flex", flexDirection: "column" }} key={`${item[0]}_ratingvalue`}>
                <LinearProgress variant="determinate" value={item[1] | 0} className="progress_bar_rating" style={{ backgroundColor: '#e3eaf4', borderRadius: '30px', marginBottom: "5px" }} />
                <Typography style={{ height: "12px", color: "#17181d", fontFamily: "Urbanist", fontSize: "10px", fontWeight: 600, letterSpacing: "0.2px" }}>{item[0]}{" "}Star</Typography>
              </Box>
            })
          }
        </Grid>
      </Box>
      <Box style={{ display: "flex", alignItems: "center", margin: "0px 12px" }}>
        <Typography style={webStyle.productionHouseManageProfileAllReviewsTitle as React.CSSProperties}>All Reviews --------------------------------------------------</Typography>
      </Box>
      <Grid style={{ margin: "0px 12px", maxHeight: "478px", overflowY: "auto", msOverflowStyle: "none", scrollbarWidth: "none" }} item xs={12} sm={12} md={12} lg={12} xl={12}>
        {
          this.state.productionHouseManageProfileReviewData.reviews?.data?.length ? (this.state.productionHouseManageProfileReviewData.reviews.data.map((item: any, index: number) => {
            return <Box key={`${index}_review_box_allProjectList`} style={{ padding: '12px' }} className="review_box">
              <Grid container spacing={3}>
                <Box sx={webStyle.productionHouseManageProfileProjectcardParentBox} className="review_card_box">
                  <Grid item xs={12} md={2} sm={12} lg={2} xl={2}>
                    <Avatar style={{ borderRadius: "12px", width: 36, height: 36 }} src={item.attributes.account.image ||dummyImage}> </Avatar>
                  </Grid>
                  <Grid item xs={12} md={8} sm={12} lg={8} xl={8}>
                    <Typography
                      style={{ lineHeight: "19px", color: "#17181d", fontFamily: "Urbanist", fontSize: "16px", fontWeight: 600, letterSpacing: "0.2px" }}
                    >
                      {item.attributes.account.full_name}
                    </Typography>
                    <Typography
                      style={{ marginTop: "1px", lineHeight: "12px", color: "#6d89af", fontFamily: "Urbanist", fontSize: "10px", fontWeight: 500, letterSpacing: "0.2px" }}
                    >
                      {item.attributes.created_at}
                    </Typography>
                  </Grid>
                  <Grid item className="project_date" xs={12} sm={12} md={2} lg={2} xl={2} >
                    <Box style={webStyle.productionHouseManageProfileStars}>
                      <StarOutlinedIcon className="start_color" />
                      <Typography style={webStyle.productionHouseManageProfileStarColor}>&nbsp;{item.attributes.given_star}</Typography>
                    </Box>
                  </Grid>
                </Box>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{ padding: "0px 12px 12px", marginTop: '-2%' }}
                >
                  <Typography
                    style={{ color: "#17181d", fontFamily: "Urbanist", fontSize: "12px", fontWeight: 500, lineHeight: "16px", letterSpacing: "0.2px" }}
                  >
                    {item.attributes.comment}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          })) : null
        }
      </Grid>
    </>
  }

  renderVendorManageProfileBasicDetailsContent = () => {
    return <>
      <Box
        style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}
      >
        <Box
          style={{ display: "flex", margin: "16px 0 16px 16px", flexWrap: "wrap" }}
        >
          <StyledBadge
            anchorOrigin={{
              vertical: "bottom", horizontal: "right"
            }}
            overlap="circular"
            style={{
              width: 75, height: 75
            }}
            badgeContent={
              <img
                style={{ width: 24, height: 24 }}
                src={images.checkedIcon}
              />
            }
          >
            <Avatar
              style={{
                width: 69,
                border: "3px solid #f5c42c",
                marginRight: 1,
                height: 69,
              }}
              src={this.state.productionHouseManageProfileData.profile_image || ProfileDummy}
            >
            </Avatar>
          </StyledBadge>
          <Box style={webStyle.productionHouseManageProfileLeftBox as React.CSSProperties}>
            <Typography
              style={{
                lineHeight: "22px",
                color: "#17181d",
                fontFamily: "Urbanist",
                fontSize: "18px",
                fontWeight: 600,
                textAlign: "center",
                letterSpacing: "0.225px",
              }}
            >
              {this.state.productionHouseManageProfileData.account_detail.data.attributes?.full_name}
            </Typography>
            <Typography style={webStyle.productionHouseManageProfileAddressText}>
              <PlaceOutlinedIcon style={{
                fontSize: "small",
                width: "22px",
                height: "22px",
                marginRight: 2,
              }} />
              {this.state.productionHouseManageProfileData.city}, {this.state.productionHouseManageProfileData.state}
            </Typography>
          </Box>
          <Box style={webStyle.productionHouseManageProfileRightBox as React.CSSProperties}>
            <Typography style={webStyle.productionHouseManageProfileRightSideBoxText}>
              <SettingsPhoneIcon style={{
                fontSize: "small",
                width: "20.09px",
                height: "20px",
                marginRight: 10.4,
                color: "#f5c42c",
              }} />
              +{this.state.productionHouseManageProfileData.account_detail.data.attributes?.country_code}-{this.state.productionHouseManageProfileData.account_detail.data.attributes?.phone_number}
            </Typography>
            <Typography style={{ ...webStyle.productionHouseManageProfileRightSideBoxText, margin: "9px 0px" }}>
              <img style={{ marginRight: 10.4 }} src={`${images.forwardMailIcon}`} />
              {this.state.productionHouseManageProfileData.account_detail.data.attributes?.email}
            </Typography>
           {this.state.productionHouseManageProfileData.experience !== null ? 
           <Typography style={webStyle.productionHouseManageProfileRightSideBoxText}>
              <img style={{ marginRight: 10.4 }} src={`${images.bulbIcon}`} />
              {this.state.productionHouseManageProfileData.experience} yrs
            </Typography> :""}
          </Box>
        </Box>
        {this.state.isInviteMember ?
         <Box className="studioBookingBookStudioResponsive" style={{ display: "flex" }}>
         <Button onClick={() => this.handleNavigationProductionHouse("AddNewProject")} data-test-id="navigateToProfileSettings" style={webStyle.InviteMemberButtonProductionHouse}><span style={webStyle.InviteMemberBtnTextProductionHouse as React.CSSProperties}>Invite for Job</span></Button>
       </Box>
        : 
        <Box className="studioBookingBookStudioResponsive" style={{ display: "flex" }}>
          <Button onClick={() => this.handleNavigationProductionHouse("ProductionHouseProfileSettings")} data-test-id="navigateToProfileSettings" style={webStyle.productionHouseManageProfileBtn}><span style={webStyle.productionHouseManageProfileBtnText as React.CSSProperties}>Profile Settings</span></Button>
          <Box onClick={this.handleClickLogout} style={webStyle.productionHouseManageProfileLogoutBtn}><img src={images.logoutIcon} alt="" /></Box>
        </Box>
        }
      </Box>
      <Dialog
        open={this.state.isOpenDialog}
        onClose={this.handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Are you sure you want to Logout?'}
        </DialogTitle>
        <DialogActions>
          <Button onClick={this.handleDialogClose}>Cancel</Button>
          <Button data-test-id="signoutIcon" onClick={() => this.handleNavigationProductionHouse("LandingPage")} autoFocus>
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </>
  }

  renderVendorManageProfileMainContent = (sideBarStatus: boolean = this.state.isproductionHouseManageProfileSideBarOpen) => {
    return <>
      <Loader loading={this.state.isLoading}></Loader>
      <Grid style={webStyle.productionHouseManageProfileWholePageContentSetup} >
        <Header handleSideBar={this.handleProductionHouseManageProfileSideBar}
          dropDownStatus={this.state.isVendorManageProfileDropdownOpen}
          navigate={this.props.navigation.navigate}
          sideBarStatus={sideBarStatus}
          handleDropdown={this.handleDropdownProductionHouse}
        />
        <Grid style={webStyle.productionHouseManageProfileDynamicMainContent as React.CSSProperties}>
          <Box sx={webStyle.productionHouseManageProfileBlockInfoTitle}>
            <Box>
              <Typography variant="h3" style={webStyle.productionHouseManageProfileActiveBlockMainTitle as TypographyProps}>Manage Profile</Typography>
              <Typography variant="h6" style={webStyle.productionHouseManageProfileBlockE2EPath as TypographyProps}>
                <span style={webStyle.productionHouseManageProfileHighlightedTitleext}>
                  <Link data-test-id="homeLink" color="inherit" onClick={() => this.handleNavigationProductionHouse("Projects")}>
                    Home
                  </Link>
                </span>
                {" "}/ Manage Profile
              </Typography>
            </Box>
          </Box>
          <Grid style={{ marginTop: "24px", borderRadius: "12px", border: "1px solid #e3eaf4", background: "#ffffff" }} item sm={12} xl={12} xs={12} md={12} lg={12}>
            {this.renderVendorManageProfileBasicDetailsContent()}
          </Grid>
          <Grid container style={{ width: "100%", marginTop: "24px", alignItems: "flex-start" }}>
            <Grid md={8} sm={12} item className="manageProfileResponsive" style={{ maxHeight: "730px", minHeight: "406px", borderRadius: "12px", border: "1px solid #e3eaf4" }}>
              <Box style={{ display: "flex" }}>
                <Box style={webStyle.productionHouseManageProfileLefttabsMainBox}>
                  <Box data-test-id="manageProfileOverviewTab" onClick={() => this.handleTabValueChange(0)} style={{ borderBottom: "1px solid #e3eaf4", cursor: "pointer", width: "138px" }}>
                    <Typography style={{ ...webStyle.productionHouseProfileLeftSideTabText, background: this.state.productionHouseManageProfileTabValue === 0 ? "#ffffff" : "#f8fafe", color: this.state.productionHouseManageProfileTabValue === 0 ? "#f5c42c" : "#17181d" }}>Overview</Typography>
                  </Box>
                  <Box data-test-id="manageProfileGalleryTab" onClick={() => this.handleTabValueChange(1)} style={{ borderBottom: "1px solid #e3eaf4", cursor: "pointer", width: "138px" }}>
                    <Typography style={{ ...webStyle.productionHouseProfileLeftSideTabText, background: this.state.productionHouseManageProfileTabValue === 1 ? "#ffffff" : "#f8fafe", color: this.state.productionHouseManageProfileTabValue === 1 ? "#f5c42c" : "#17181d" }}>Studios</Typography>
                  </Box>
                  <Box data-test-id="manageProfileProjectsTab" onClick={() => this.handleTabValueChange(2)} style={{ borderBottom: "1px solid #e3eaf4", cursor: "pointer", width: "138px" }}>
                    <Typography style={{ ...webStyle.productionHouseProfileLeftSideTabText, background: this.state.productionHouseManageProfileTabValue === 2 ? "#ffffff" : "#f8fafe", color: this.state.productionHouseManageProfileTabValue === 2 ? "#f5c42c" : "#17181d" }}>Projects</Typography>
                  </Box>
                </Box>
                {this.renderVendorManageProfileOverviewTabContent()}
                {this.renderVendorManageProfileStudiosTabContent()}
                {this.renderVendorManageProfileProjectsTabContent()}
              </Box>
            </Grid>
            <Grid md={4} sm={12} item className="studioBookingResponsive" style={{ paddingLeft: "24px" }}>
            <Grid item style={{ width: "100%", maxHeight: "680px", minHeight: "625px", borderRadius: "12px", border: "1px solid #e3eaf4", background: "#ffffff" }}>
              {this.renderVendorManageProfileRatingAndReviewTabContent()}
            </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </>
  }
  // Customizable Area End

  render() {
    return (
      <ThemeProvider theme={themeStyle}>
        <Container style={webStyle.productionHouseManageProfileParentContainer}>
          {
            this.state.isproductionHouseManageProfileSideBarOpen ? (
              <Grid spacing={0} container>
                <Grid xs={3} item style={webStyle.productionHouseManageProfileSidebarParentGrid} >
                  <SideBar
                    handleSideBar={this.handleProductionHouseManageProfileSideBar}
                    userData={{ name: "Manage Profile" }}
                    activeMenuItem={"Manage Profile"}
                    navigate={this.props.navigation.navigate}
                  />
                </Grid>
                <Grid style={{ display: "flex", flexDirection: "column", flexBasis: "77.75%", maxWidth: "77.75%" }} item xs={9} >
                  {this.renderVendorManageProfileMainContent()}
                </Grid>
              </Grid>
            ) : (
              <Grid style={{ display: "flex", flexDirection: "column" }} item xs={12}>
                {this.renderVendorManageProfileMainContent()}
              </Grid>
            )
          }
        </Container>
        <CustomNotification navigation={(route: string) => this.props.navigation.navigate(route)} notification={this.state.notification} handleCloseNotification={this.handleCloseNotification} />
      </ThemeProvider>
    );
  }
}

const webStyle = {
  productionHouseManageProfileLefttabsMainBox: {
    height: "100%",
    width: "138px",
    background: "#f8fafe",
  },
  productionHouseManageProfileGalleryPhotoAddMoreText: {
    color: "#f5c42c",
    lineHeight: "18px",
    fontSize: "14px",
    fontFamily: "Urbanist",
    letterSpacing: "0.21538462px",
    fontWeight: 600,
    marginTop: "2px",
  },
  readMoreTextProductionHouse: {
    color: "#f5c42c",
    lineHeight: "15px",
    fontSize: "12px",
    fontFamily: "Urbanist",
    textDecoration: "underline",
    fontWeight: 600,
    cursor: "pointer",
    letterSpacing: "0.18461539px",
  },
  projectsCardProductionHouse: {
    border: "1px solid #e3eaf4",
    height: "84px",
    width: "100%",
    borderRadius: "12px",
    padding: "10px",
    background: "#ffffff",
    display: "flex",
    marginBottom: "17px",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  productionHouseProfileLeftSideTabText: {
    color: "#17181d",
    lineHeight: "17px",
    fontSize: "14px",
    fontFamily: "Urbanist",
    padding: "27px 16px 21px 16px",
    fontWeight: 600,
  },
  productionHouseManageProfileAllReviewsTitle: {
    fontFamily: "Urbanist",
    color: "#6d89af",
    fontWeight: 600,
    fontSize: "12px",
    whiteSpace: "nowrap",
    lineHeight: "19px",
    overflow: "hidden",
    textOverflow: "clip",
  },
  productionHouseManageProfileStars: {
    background: "linear-gradient(225deg, #58A2FF 0%, #1777E2 100%)",
    padding: "0px 8px",
    display: "flex",
    borderRadius: "12px",
    justifyContent: 'space-evenly',
    alignItems: "center",
  },
  productionHouseManageProfileProjectStatus: {
    background: "linear-gradient(225deg, #58A2FF 0%, #1777E2 100%)",
    padding: "0px 8px",
    display: "flex",
    borderRadius: "12px",
    justifyContent: 'space-evenly',
    alignItems: "center",
  },
  productionHouseManageProfileStarColor: {
    color: 'white',
  },
  productionHouseManageProfileProjectcardParentBox: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
    padding: '2%',
    alignItems: "flex-start",
  },
  productionManageProfileProjectStatusBtn: {
    height: "24px",
    width: "74px",
    background: "linear-gradient(225deg, #50b609 0%, #42a200 100%)",
    borderRadius: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  productionHouseManageProfileProjectStatusText: {
    color: "#ffffff",
    lineHeight: "12px",
    fontSize: "10px",
    fontWeight: 600,
    fontFamily: "Urbanist",
  },
  productionHouseManageProfileProjectDateText: {
    color: "#6d89af",
    lineHeight: "15px",
    fontSize: "12px",
    fontFamily: "Urbanist",
    letterSpacing: "0.24px",
    textAlign: "end",
    fontWeight: 500,
  },
  productionHouseManageProfileRightSideBoxText: {
    color: "#17181d",
    lineHeight: "14px",
    fontSize: "14px",
    fontFamily: "Urbanist",
    letterSpacing: "0.23333333px",
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
  },
  productionHouseManageProfileRightBox: {
    display: "flex",
    paddingLeft: "24.5px",
    alignItems: "start",
    flexDirection: "column",
  },
  productionHouseManageProfileLeftBox: {
    height: "79px",
    borderRight: "1px solid #e3eaf4",
    display: "flex",
    paddingRight: "24px",
    alignItems: "start",
    flexDirection: "column",
    marginLeft: "10px",
  },
  productionHouseManageProfileAddressText: {
    color: "#6d89af",
    lineHeight: "17px",
    fontSize: "14px",
    fontFamily: "Urbanist",
    letterSpacing: "0.23333333px",
    margin: "4px 0px",
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
  },
  productionHouseManageProfilePhName: {
    color: "#6d89af",
    lineHeight: "17px",
    fontSize: "14px",
    fontFamily: "Urbanist",
    letterSpacing: "0.23333333px",
    marginTop: "4px",
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
  },
  productionHouseManageProfileBtn: {
    height: "48px",
    width: "152px",
    background: "#ffffff",
    borderRadius: "10px",
    marginRight: "10px",
    border: "1px solid #17181d",
  },
  productionHouseManageProfileLogoutBtn: {
    height: "46px",
    width: "46px",
    background: "#ffffff",
    borderRadius: "10px",
    alignItems: "center",
    border: "1px solid #ff5656",
    justifyContent: "center",
    display: "flex",
    marginRight: "24px",
    cursor: "pointer",
  },
  productionHouseManageProfileBtnText: {
    fontFamily: "Urbanist",
    color: "#17181d",
    fontWeight: 600,
    fontSize: "14px",
    textTransform: "none",
    letterSpacing: "0.4px",
    lineHeight: "17px",
  },
  productionHouseManageProfileRatingStartMainBox: {
    width: "100%",
  },
  productionHouseManageProfileRatingStartBox: {
    paddingRight: "12px",
    paddingLeft: "12px",
    display: "flex",
    paddingTop: "12px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  productionHouseManageProfileRateNowText: {
    color: "#f5c42c",
    height: "14px",
    fontSize: "12px",
    fontFamily: "Urbanist",
    textDecoration: "underline",
    fontWeight: 600,
    display: "flex",
    letterSpacing: "0.18461539px",
    cursor: "pointer",
    alignItems: "center",
  },
  productionHouseManageProfileParentContainer: {
    padding: "0px",
    maxWidth: "none",
  },
  productionHouseManageProfileSidebarParentGrid: {
    minHeight: "100vh",
    maxWidth: "22.25%",
  },
  productionHouseManageProfileWholePageContentSetup: {
    // minHeight: "90vh",
    borderLeft: "1px solid #E3EAF4",
    flex: 1,
  },
  productionHouseManageProfileDynamicMainContent: {
    boxSizing: "border-box",
    width: "100%",
    minHeight: "100vh",
    backgroundColor: "#F8FAFE",
    padding: "24px",
  },
  productionHouseManageProfileBlockInfoTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
  },
  productionHouseManageProfileHighlightedTitleext: {
    color: "#F5C42C",
    fontFamily: "Urbanist",
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: 600,
    textDecoration: "underline",
  },
  productionHouseManageProfileActiveBlockMainTitle: {
    color: "#17181D",
    lineHeight: "34px",
    fontSize: "28px",
    letterSpacing: "1px",
    fontWeight: "700",
  },
  productionHouseManageProfileBlockE2EPath: {
    letterSpacing: "1px",
    fontSize: "14px",
    color: "#6D89AF",
    fontWeight: 500,
    paddingTop: "8px",
    lineHeight: "17px",
  },
  InviteMemberButtonProductionHouse: {
    height: "48px",
    width: "152px",
    background: "#17181D",
    borderRadius: "10px",
    marginRight: "10px",
    border: "1px solid #17181d",
  },
  InviteMemberBtnTextProductionHouse: {
    fontFamily: "Urbanist",
    color: "#ffffff",
    fontWeight: 600,
    fontSize: "14px",
    textTransform: "none",
    letterSpacing: "0.4px",
    lineHeight: "17px",
  },
};
