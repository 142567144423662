import React from "react";

//Customizable Area Start
import { Grid, InputAdornment, Typography } from "@material-ui/core";
import Modal from '@material-ui/core/Modal';
import NewPasswordController, { Props } from "./NewPasswordController";
import "./forgotPassword.css";
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import Popup from "./Popup.web";
import CommonComponent from "./CommonComponent";
import { CustomButton, SignInInputLabel } from "../../email-account-login/src/CustomButton";
import { CssTextField } from "../../email-account-login/src/EmailAccountLoginBlock.web";
import CustomNotification from "../../../components/src/CustomNotification";
export const configJSON = require("./config");
//Customizable Area End

export default class NewPassword extends NewPasswordController {
    constructor(props: Props) {
        super(props);
        //Customizable Area Start
        //Customizable Area End
    }
    // Customizable Area Start
    render() {
        return (
            <>
                <Grid container style={{ backgroundColor: "#F8FAFE" }}>
                    <CommonComponent navigation={this.props.navigation} />
                    <Grid item xs={1} sm={1} md={4} lg={4} xl={2} style={{ backgroundColor: '#F8FAFE' }}></Grid>
                    <Grid item xs={10} sm={10} md={4} lg={4} xl={7} className="main_baground" style={{ backgroundColor: '#F8FAFE' }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: '20%', textAlign: "center" }}>
                            <label style={{ lineHeight: "43px", color: "#17181d", fontFamily: "Urbanist", fontSize: "36px", fontWeight: 700, letterSpacing: "1.1076924px" }}>{configJSON.newPasswordText}</label>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                            <SignInInputLabel className="sing_in_text" style={{ fontSize: "14px", marginTop: "13px", marginBottom: "26px", fontWeight: 600, fontFamily: "Urbanist" }}>{configJSON.newText}</SignInInputLabel>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingBottom: "6px" }}>
                            <span style={this.state.isActiveNewPasswordInput ? { color: "#f5c42c", fontSize: "14px", fontWeight: 600, fontFamily: "urbanist" } : { color: "#6D89AF", fontSize: "14px", fontWeight: 600, fontFamily: "urbanist" }}>{configJSON.newTextPassword}</span>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <CssTextField
                                data-test-id="new_password"
                                placeholder="Enter your new password"
                                InputProps={{
                                    autoComplete: 'new-password',
                                    endAdornment: (
                                        <InputAdornment position="end" className="icon_container">
                                            <span onClick={this.handleClickShowNewPassword}>{this.state.visibiltyOfNewPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}</span>
                                        </InputAdornment>
                                    ),
                                }}
                                value={this.state.passwordDetails && this.state.passwordDetails.new_password}
                                name="new_password"
                                onFocus={this.handleFoucsNewPasswordInput}
                                onBlur={this.handleLeaveFoucsNewPasswordInput}
                                type={this.state.visibiltyOfNewPassword ? "text" : "password"} id="outlined-basic" variant="outlined" className="email_input" onChange={this.handleChangePasswordDetails} />
                            {this.state.validvariable && <Typography style={{ color: "red" }}>{this.state.msgvariable}</Typography>}
                            {
                                this.state.isErrorPassword && this.state.isErrorPassword.new_password && <>
                                    <Typography style={{ color: 'red', fontFamily: 'Urbanist', fontSize: '13px', fontWeight: 600, lineHeight: '18px', letterSpacing: '0.4px' }}>{configJSON.hintPass}</Typography>

                                </>
                            }
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: '18px', paddingBottom: "6px" }}>
                            <span style={this.state.isActiveConfirmPasswordInput ? { color: "#f5c42c", fontSize: "14px", fontWeight: 600, fontFamily: "urbanist" } : { color: "#6D89AF", fontSize: "14px", fontWeight: 600, fontFamily: "urbanist" }}>{configJSON.confirmPasswordText}</span>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <CssTextField type={this.state.visibilityOfConfirmPassword ? "text" : "password"} id="outlined-basic" variant="outlined"
                                placeholder="Enter your new password again"
                                data-test-id="confirm_password"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end" className="icon_container">
                                            <span onClick={this.handleClickShowConfirmPassword}>{this.state.visibilityOfConfirmPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}</span>
                                        </InputAdornment>
                                    ),
                                }}
                                name="confirm_new_password"
                                onFocus={this.handleFoucsConfirmPasswordInput}
                                onBlur={this.handleLeaveFoucsConfirmPasswordInput}
                                value={this.state.passwordDetails && this.state.passwordDetails.confirm_new_password}
                                className="email_input" onChange={this.handleChangePasswordDetails} />
                            {this.state.validvariable && <Typography style={{ color: "red" }}>{this.state.msgvariable}</Typography>}
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginBottom: '33%', marginTop: '32px' }}>
                            <CustomButton data-test-id="confirm_button" style={{ fontWeight: 600, fontSize: "16px" }} className="newPassword_button" onClick={this.updatePassword}>{configJSON.confirmButton}</CustomButton>
                        </Grid>
                    </Grid>
                    <Grid item xs={1} sm={1} md={4} lg={4} xl={2} style={{ backgroundColor: '#F8FAFE' }}></Grid>
                    <Modal
                        open={this.state.popupOpen}
                        data-test-id="modal_id"
                        onClose={() => { this.handleClose(false) }}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                        <Popup navigation={this.props.navigation} />
                    </Modal>
                </Grid>
                <CustomNotification navigation={(route: string) => this.props.navigation.navigate(route)} notification={this.state.npNotification} handleCloseNotification={this.handleNPCloseNotification} />
            </>
        );
    }
    // Customizable Area End
}

// Customizable Area Start
//Customizable Area End