import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { ChangeEvent } from "react";
// Customizable Area End

export const configJSON = require("./config");



export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  loading: boolean;
  invoice: string;
  invoiceError: string;
  invoicePdf: string;
  isinvoiceBillingSideBarOpen: boolean;
  isInvoiceHeaderDropdownOpenVal:boolean
  tabIndex:string|number;
  open : boolean
  selectstatus: string;
  selectstatus1: string;
  allcount:number;
  countApproved:number;
  countcomplete:number;
  countpending:number,
  countall:number,
  counthold:number;
  countreject:number;
  isLoading:boolean;
  open2:boolean;
  open3:boolean;
  showandhidden:boolean;
  showAndHiddenSecondPipeline:boolean;
  showAndHiddenCleared:boolean;
  txtSavedValue: string;
  invoiceAllRequest:any[];
  projectInvoiceAllRequest:any[];
  count:number;
  currentpage: number;
  projectName : string
  totalInvoiceCount : number;
  raiseinvoicetype :any;
  amountdatalist:any;
  departmentlist:any;
  jobroledatalist:any;
  jobrolevalue:string;
  amountvalue:string;
  departmentvalue:string;
  statusvalue:string,
  getprojectlistfilter:any;
  projectvalue:string;
  invoiceCount:number;
  searchValue:string;
  invoiceDetailsData:any[];
  openHold:boolean;
  RejectCommentValue:string;
  HoldCommentValue:string;
  invoiceDetail:boolean











  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string | number;
  // Customizable Area End
}

export default class InvoiceBillingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getInvoiceRequestApiCallId:string = "";
  getInvoiceRequestDetailsApiCallId:string = "";
  getAllInvoiceRequestListApiCallId: string = "";
  getAllProjectInvoicesListApiCallId : string ="";
  getInvoiceRaisedAPiCallId: string ="";
  getJobRolesApiCallId: string ="";
  getProjectDepartmentCallId:string="";
  getProjectListFilterCallId:string="";
  getInvoicesAmountListApiCallId:string="";
  getInvoiceDetailsDataApiCallId:string="";
  putInvoicesRejectedApiCallId:string="";
  putInvoicesHoldedApiCallId:string="";
  putInvoicesApprovedApiCallId:string="";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      invoice: "",
      invoiceError: "",
      invoicePdf: "",
      isinvoiceBillingSideBarOpen: true,
      isInvoiceHeaderDropdownOpenVal:false,
      tabIndex:0,
      open: false,
      selectstatus: "",
      selectstatus1: "",
      allcount:0,
      countApproved:0,
      countcomplete:0,
      countpending:0,
      countall:0,
      counthold:0,
      countreject:0,
      isLoading: false,
      open2:false,
      open3:false,
      showandhidden:true,
      showAndHiddenSecondPipeline:true,
      showAndHiddenCleared:true,
      txtSavedValue: "A",
      invoiceAllRequest : [],
      projectInvoiceAllRequest : [],
      count: 0,
      currentpage: 1,
      projectName:"",
      totalInvoiceCount:0,
      raiseinvoicetype:"",
      amountdatalist:[],
      departmentlist:[],
      jobroledatalist:[],
      jobrolevalue:"",
      amountvalue:'',
      departmentvalue:'',
      statusvalue:'',
      getprojectlistfilter:[],
      projectvalue:'',
      invoiceCount: 0,
      searchValue:"",
      invoiceDetailsData:[],
      openHold:false,
      RejectCommentValue:'',
      HoldCommentValue:'',
      invoiceDetail : true












      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let valueInvoicelist = message.getData(getName(MessageEnum.AuthTokenDataMessage));

    
      this.setState({ txtSavedValue: valueInvoicelist });
      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + valueInvoicelist
      );

    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallIdAllInvoice = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      if(apiRequestCallIdAllInvoice === this.getAllInvoiceRequestListApiCallId) {
        this.setState({
          invoiceAllRequest: responseJson.data, 
          count:responseJson.meta.projects_count,
          totalInvoiceCount:responseJson.meta.total_invoice_count,
          isLoading: false,
        })
      }
      else if(apiRequestCallIdAllInvoice === this.getAllProjectInvoicesListApiCallId) {
        this.setState({
          projectInvoiceAllRequest : responseJson.data,
          invoiceCount:responseJson?.meta?.total_invoices,
          isLoading: false,
        })
      }
      else if(apiRequestCallIdAllInvoice === this.getInvoiceRaisedAPiCallId) {
        this.setState({
          countApproved:responseJson.status_counts.approved,
          countcomplete:responseJson.status_counts.completed,
          counthold:responseJson.status_counts.holded,
          countpending:responseJson.status_counts.pending,
          countreject:responseJson.status_counts.rejected,
          countall:responseJson.all,
          raiseinvoicetype:responseJson.status_counts
        })
      }
      else if(apiRequestCallIdAllInvoice === this.getJobRolesApiCallId) {
        this.setState({
          jobroledatalist:responseJson.job_roles
        })
      }
      else if(apiRequestCallIdAllInvoice === this.getProjectDepartmentCallId) {
        this.setState({
          departmentlist:responseJson.departments
        })
      }
      else if(apiRequestCallIdAllInvoice === this.getProjectListFilterCallId) {
        this.setState({
          getprojectlistfilter:responseJson.projects
        })
      }
      else if(apiRequestCallIdAllInvoice === this.getInvoicesAmountListApiCallId) {
        this.setState({
          amountdatalist:responseJson.invoice_amounts
        })
      }
      else if(apiRequestCallIdAllInvoice === this.getInvoiceDetailsDataApiCallId) {
        this.setState({
          invoiceDetailsData:responseJson.data,
          isLoading: false,
        })
      }
      else if(apiRequestCallIdAllInvoice === this.putInvoicesRejectedApiCallId) {
        this.getPrjectInvoiceDetailstProductionHouse();
      }
      else if(apiRequestCallIdAllInvoice === this.putInvoicesHoldedApiCallId) {
        this.getPrjectInvoiceDetailstProductionHouse();
      }
      else if(apiRequestCallIdAllInvoice === this.putInvoicesApprovedApiCallId) {
        this.getPrjectInvoiceDetailstProductionHouse();
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await this.getmyallinvoiceList()
    await this.getInvoiceRaisedCount()
    await this.getJobRolesInvoiceReqPH()
    await this.getProjectDepartmentListPh()
    await this.getProjectListFilterProductionHouse()
    await this.getInvoicesAmountProductionHouse()
    await this.getPrjectInvoiceDetailstProductionHouse()
    const projectNames=  await getStorageData("project_name");
    this.setState({projectName:projectNames})
    this.handleChangeTab({},0)
    let isSideBarOpen=await getStorageData("sidebarvalue");
    this.setState({isinvoiceBillingSideBarOpen:JSON.parse(isSideBarOpen)})
  }

  handlePageChange = async (event: any, value: number) => {   
        this.getAllInvoiceRequestListApiCallId = await this.allInvoicelist(configJSON.GET_Method, configJSON.getallinvoicerequestlistEndPoint + `?page=${value}&per_page=9`);
        window.scrollTo(0, 0);
    this.setState({ currentpage: value });
};

  handleInvoiceSideBar = () => {
    this.setState((prevState) => ({
      isinvoiceBillingSideBarOpen: !prevState.isinvoiceBillingSideBarOpen
    }), async() => {
      setStorageData("sidebarvalue",this.state.isinvoiceBillingSideBarOpen.toString())
    });
  };

  handleChangeTab = async (event: any, newValue: any) => {
    this.setState({ tabIndex: newValue })
    const projectId = await getStorageData('project_id')
    if(this.state.tabIndex===0){
      this.getAllProjectInvoicesListApiCallId = await this.allProjectInvoiceList(configJSON.GET_Method,configJSON.getAllProjectInvoicesApiEndPoint + `?user_type=employee&project_id=${projectId}`)
    }
    else if(this.state.tabIndex===1){
      this.getAllProjectInvoicesListApiCallId = await this.allProjectInvoiceList(configJSON.GET_Method,configJSON.getAllProjectInvoicesApiEndPoint + `?user_type=vendor&project_id=${projectId}`)
    }
  };

  handleClose = () => {
    this.setState({ open: false })
};
handleOpen = () => {
  this.setState({ open: true })
}
handleOpen2 = () => {
  this.setState({ open2: true })
}

handleClose2 = () => {
  this.setState({ open2: false })
};

navigateToinvoiceDetailPH = (invoiceId:any , projectInvoice:any) => {
  this.props.navigation.navigate("InvoiceDetailPH");
  setStorageData('invoice_id',invoiceId)
  setStorageData('project_invoice',projectInvoice)
};

navigateToinvoicesRequest=(projectId:any,projectName:any)=>{
  this.props.navigation.navigate("InvoiceBilling")
  setStorageData("project_id", projectId)   
  setStorageData("project_name",projectName)
}
navigateToAllInvoicePage=()=>{
  this.props.navigation.navigate("AllInvoiceRequests")
}

navigateToInvoiceBillingPage=()=>{
  this.props.navigation.navigate("InvoiceBilling")
}

handleNavigationPH = (page: string) => {
  this.props.navigation.navigate(page)
};


ClearDataValues = async () => {
  this.setState({open:false})
  this.setState({selectstatus:''})
  this.setState({selectstatus1:""})
  this.handleChangeTab({},0)
};
handleOptionChange = (index: any, state: any) => {
  this.setState({ selectstatus: state })
};

handleOptionChange1 = (index: any, state: any) => {
  this.setState({ selectstatus1: state })
};

handleOpen3PhInvoice=()=>{
  this.setState({open3:true})
}

handleOptionChangejobroleProductionHouse = (index: any, job: any) => {
  this.setState({jobrolevalue:job})
};

handleOptionChangeamountProductionHouse = (index: any, Amount: any) => { 
  this.setState({amountvalue:Amount})
};

handleOptionChangeDepartmentProductionHouse = (index: any, department: any) => {  
  this.setState({departmentvalue:department})
};

handleOptionChangeStatusProductionHouse = (index: any, status: any) => {       
  this.setState({statusvalue:status})
};

handleOptionChangeprojectProductionHouse= (index: any, projectname: any) => {
  this.setState({projectvalue:projectname})
};

handleChangeapplyProductionHouse = async () => {
  const projectId = await getStorageData('project_id')
  this.setState({ open2: false })
  this.setState({
    currentpage: 1,
  })

  if(this.state.tabIndex===0){
    this.getAllProjectInvoicesListApiCallId = await this.allProjectInvoiceList(configJSON.GET_Method,configJSON.getAllProjectInvoicesApiEndPoint + `?department=${this.state.departmentvalue}&filter_type=filter&project_id=${projectId}&project=${this.state.projectvalue}&user_type=employee&amount=${this.state.amountvalue}&job_role=${this.state.jobrolevalue}&status=${this.state.statusvalue}`)
  }
  else if(this.state.tabIndex===1){
    this.getAllProjectInvoicesListApiCallId = await this.allProjectInvoiceList(configJSON.GET_Method,configJSON.getAllProjectInvoicesApiEndPoint + `?department=${this.state.departmentvalue}&filter_type=filter&project_id=${projectId}&project=${this.state.projectvalue}&user_type=vendor&amount=${this.state.amountvalue}&job_role=${this.state.jobrolevalue}&status=${this.state.statusvalue}`)
  }
}  

handleInvoiceSort=async ()=>{
  const projectId = await getStorageData('project_id')
  this.setState({ open: false })
  this.setState({
    currentpage: 1,
  })
  if(this.state.tabIndex===0){
    this.getAllProjectInvoicesListApiCallId = await this.allProjectInvoiceList(configJSON.GET_Method,configJSON.getAllInvoicesOnSortBasisApiEndPoint + `?user_type=employee&filter_type=sort_by&invoice_type=${this.state.selectstatus1}&status=${this.state.selectstatus}&project_id=${projectId}&page=1&per_page=9`)
  }
  else if(this.state.tabIndex===1){
    this.getAllProjectInvoicesListApiCallId = await this.allProjectInvoiceList(configJSON.GET_Method,configJSON.getAllInvoicesOnSortBasisApiEndPoint + `?user_type=vendor&filter_type=sort_by&invoice_type=${this.state.selectstatus1}&status=${this.state.selectstatus}&project_id=${projectId}&page=1&per_page=9`)
  }
}

handlePageChangeInvoices = async (event: any, value: number) => {
  const projectId = await getStorageData('project_id');
  let endpoint = '';
  
  if (this.state.selectstatus || this.state.selectstatus1) {
    endpoint = configJSON.getAllInvoicesOnSortBasisApiEndPoint + `?user_type=employee&filter_type=sort_by&invoice_type=${this.state.selectstatus1}&status=${this.state.selectstatus}&project_id=${projectId}&page=${value}&per_page=9`;
  } else if (this.state.jobrolevalue || this.state.amountvalue || this.state.statusvalue || this.state.departmentvalue || this.state.projectvalue) {
    endpoint = configJSON.getAllProjectInvoicesApiEndPoint + `?department=${this.state.departmentvalue}&filter_type=filter&project_id=${projectId}&project=${this.state.projectvalue}&user_type=employee&amount=${this.state.amountvalue}&job_role=${this.state.jobrolevalue}&status=${this.state.statusvalue}&page=${value}&per_page=9`;
  } else {
    endpoint = configJSON.getAllProjectInvoicesApiEndPoint + `?user_type=employee&project_id=${projectId}&page=${value}&per_page=9`;
  }
  if (this.state.tabIndex === 1) {
    endpoint = endpoint.replace('user_type=employee', 'user_type=vendor');
  }
  this.getAllProjectInvoicesListApiCallId = await this.allProjectInvoiceList(configJSON.GET_Method, endpoint);
  this.setState({ currentpage: value });
}

searchInvoices = async (event: ChangeEvent<HTMLInputElement>)=>{
  let searchvalueData =event.currentTarget.value
  const projectId = await getStorageData('project_id')
  this.setState({
    currentpage: 1,
    searchValue: searchvalueData,
});
if(this.state.tabIndex===0 || event.currentTarget.value){
  this.getAllProjectInvoicesListApiCallId = await this.allProjectInvoiceList(configJSON.GET_Method,configJSON.getAllProjectInvoicesSearchApiEndPoint + `=${this.state.searchValue}&user_type=employee&project_id=${projectId}&page=1&per_page=9`)
}
else if(this.state.tabIndex===1 || event.currentTarget.value){
  this.getAllProjectInvoicesListApiCallId = await this.allProjectInvoiceList(configJSON.GET_Method,configJSON.getAllProjectInvoicesSearchApiEndPoint + `=${this.state.searchValue}&user_type=vendor&project_id=${projectId}&page=1&per_page=9`)

}
}

ClearDataValuesfilter = async () => {
  this.setState({open2:false})
  this.setState({amountvalue:''})
  this.setState({departmentvalue:''})
  this.setState({projectvalue:''});
  this.setState({jobrolevalue:''})
  this.setState({statusvalue:''})
  this.handleChangeTab({},0)
};



handleClose3PhInvoice=()=>{
  this.setState({open3:false})
}

handlehiddenandshowPhInvoice = () => {
  this.setState({
    showandhidden: !(this.state.showandhidden)
  });
};

handlehiddenandshowSecondPipelinePhInvoice = () => {
    this.setState({
      showAndHiddenSecondPipeline: !(this.state.showAndHiddenSecondPipeline)
    });
};

handlehiddenandshowClearedPhInvoice = () => {
  this.setState({
    showAndHiddenCleared: !(this.state.showAndHiddenCleared)
  });
};

handleCloseHoldPH = () => {
  this.setState({ openHold: false })
  
};
handleOpenHoldPH = () => {
  this.setState({ openHold: true })
};

CommentsHandlePH=(e:any)=>{
  this.setState({RejectCommentValue:e.target?.value})
 }

 CommentsHandleHoldPH=(e:any)=>{
  this.setState({HoldCommentValue:e.target?.value})
 }

  handleInvoiceHeaderDropdownVal = () => {
    this.setState({
      isInvoiceHeaderDropdownOpenVal: !this.state.isInvoiceHeaderDropdownOpenVal
    })
  };

  getmyallinvoiceList = async () => {
    this.getAllInvoiceRequestListApiCallId = await this.allInvoicelist(configJSON.GET_Method, configJSON.getallinvoicerequestlistEndPoint + `?page=1&per_page=9`);
};

  allInvoicelist = async (method: string, endPoint: string) => {
    const authenticationToken = await getStorageData('token', false)
    const header = {
        "Content-Type": configJSON.exampleApiContentType,
        "token": authenticationToken
    };
      this.setState({isLoading:true})
    const requestMessageAllInvoice = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessageAllInvoice.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
    );

    requestMessageAllInvoice.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessageAllInvoice.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        method
    );
    runEngine.sendMessage(requestMessageAllInvoice.id, requestMessageAllInvoice);
    return requestMessageAllInvoice.messageId;
};

allProjectInvoiceList = async (method: string, endPoint: string) => {
  const authenticationToken = await getStorageData('token', false)
  const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": authenticationToken
  };
    this.setState({isLoading:true})
  const requestMessageProjectAllInvoice = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  requestMessageProjectAllInvoice.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
  );

  requestMessageProjectAllInvoice.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );
  requestMessageProjectAllInvoice.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
  );
  runEngine.sendMessage(requestMessageProjectAllInvoice.id, requestMessageProjectAllInvoice);
  return requestMessageProjectAllInvoice.messageId;
};

getInvoiceRaisedCount = async () => {
  const projectId = await getStorageData('project_id')
  const authenticationToken = await getStorageData('token', false)
  const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": authenticationToken
  };
    this.setState({isLoading:true})
  const requestMessageRaisedCount = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getInvoiceRaisedAPiCallId = requestMessageRaisedCount.messageId;
  requestMessageRaisedCount.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getInvoiceRaisedApiEndPoint + `?project_id=${projectId}`
  );

  requestMessageRaisedCount.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );
  requestMessageRaisedCount.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET_Method
  );
  runEngine.sendMessage(requestMessageRaisedCount.id, requestMessageRaisedCount);
  return requestMessageRaisedCount.messageId;
};

getJobRolesInvoiceReqPH = async () => {
  const projectId = await getStorageData('project_id')
  const authenticationToken = await getStorageData('token', false)
  const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": authenticationToken
  };
    this.setState({isLoading:true})
  const requestMessageGetJobRoles = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getJobRolesApiCallId = requestMessageGetJobRoles.messageId;
  requestMessageGetJobRoles.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getJobRolesApiEndPoint + `?project_id=${projectId}`
  );

  requestMessageGetJobRoles.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );
  requestMessageGetJobRoles.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET_Method
  );
  runEngine.sendMessage(requestMessageGetJobRoles.id, requestMessageGetJobRoles);
  return requestMessageGetJobRoles.messageId;
};

getProjectDepartmentListPh = async () => {
  const projectId = await getStorageData('project_id')
  const authenticationToken = await getStorageData('token', false)
  const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": authenticationToken
  };
    this.setState({isLoading:true})
  const requestMessageGetProjectDepartment = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getProjectDepartmentCallId = requestMessageGetProjectDepartment.messageId;
  requestMessageGetProjectDepartment.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getDepartmentListApiEndPoint + `?project_id=${projectId}`
  );

  requestMessageGetProjectDepartment.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );
  requestMessageGetProjectDepartment.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET_Method
  );
  runEngine.sendMessage(requestMessageGetProjectDepartment.id, requestMessageGetProjectDepartment);
  return requestMessageGetProjectDepartment.messageId;
};

getProjectListFilterProductionHouse = async () => {
  const authenticationToken = await getStorageData('token', false)
  const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": authenticationToken
  };
    this.setState({isLoading:true})
  const requestMessageGetProjectList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getProjectListFilterCallId = requestMessageGetProjectList.messageId;
  requestMessageGetProjectList.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getProjectListApiEndPoint
  );

  requestMessageGetProjectList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );
  requestMessageGetProjectList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET_Method
  );
  runEngine.sendMessage(requestMessageGetProjectList.id, requestMessageGetProjectList);
  return requestMessageGetProjectList.messageId;
};

getInvoicesAmountProductionHouse = async () => {
  const projectId = await getStorageData('project_id')
  const authenticationToken = await getStorageData('token', false)
  const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": authenticationToken
  };
    this.setState({isLoading:true})
  const requestMessageGetInvoicesAmount = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getInvoicesAmountListApiCallId = requestMessageGetInvoicesAmount.messageId;
  requestMessageGetInvoicesAmount.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getInvoicesAmountListApiEndPoint + `?project_id=${projectId}`
  );

  requestMessageGetInvoicesAmount.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );
  requestMessageGetInvoicesAmount.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET_Method
  );
  runEngine.sendMessage(requestMessageGetInvoicesAmount.id, requestMessageGetInvoicesAmount);
  return requestMessageGetInvoicesAmount.messageId;
};

getPrjectInvoiceDetailstProductionHouse = async () => {
  const invoiceIds= await getStorageData("invoice_id");
  const authenticationToken = await getStorageData('token', false)
  const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": authenticationToken
  };
    this.setState({isLoading:true})
  const requestMessageGetInvoicesAmount = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getInvoiceDetailsDataApiCallId = requestMessageGetInvoicesAmount.messageId;
  requestMessageGetInvoicesAmount.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getProjectInvoicesDeatilsApiEndPoint + `/${invoiceIds}`
  );

  requestMessageGetInvoicesAmount.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );
  requestMessageGetInvoicesAmount.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET_Method
  );
  runEngine.sendMessage(requestMessageGetInvoicesAmount.id, requestMessageGetInvoicesAmount);
  return requestMessageGetInvoicesAmount.messageId;
};

prjectInvoiceDetailsRejected = async () => {
  const invoiceIds= await getStorageData("invoice_id");
  const authenticationToken = await getStorageData('token', false)
  const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": authenticationToken
  };
    this.setState({isLoading:true})
  const requestMessageRejectedInvoice = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.putInvoicesRejectedApiCallId = requestMessageRejectedInvoice.messageId;
  requestMessageRejectedInvoice.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.putInvoicesRejectedApiCallId + `/${invoiceIds}?status=rejected&comment=${this.state.RejectCommentValue}`
  );

  requestMessageRejectedInvoice.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );
  requestMessageRejectedInvoice.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PUT_Method
  );
  runEngine.sendMessage(requestMessageRejectedInvoice.id, requestMessageRejectedInvoice);
  return requestMessageRejectedInvoice.messageId;
};

prjectInvoiceDetailsHolded = async () => {
  const invoiceIds= await getStorageData("invoice_id");
  const authenticationToken = await getStorageData('token', false)
  const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": authenticationToken
  };

  this.setState({isLoading:true})
  const requestMessageHoldedInvoice = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.putInvoicesHoldedApiCallId = requestMessageHoldedInvoice.messageId;
  requestMessageHoldedInvoice.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.putInvoicesRejectedApiCallId + `/${invoiceIds}?status=holded&comment=${this.state.HoldCommentValue}`
  );

  requestMessageHoldedInvoice.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );
  requestMessageHoldedInvoice.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PUT_Method
  );
  runEngine.sendMessage(requestMessageHoldedInvoice.id, requestMessageHoldedInvoice);
  return requestMessageHoldedInvoice.messageId;
};

prjectInvoiceDetailsApproved = async () => {
  const invoiceIds= await getStorageData("invoice_id");
  const authenticationToken = await getStorageData('token', false)
  const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": authenticationToken
  };
    this.setState({isLoading:true})
  const requestMessageRejectedInvoice = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.putInvoicesApprovedApiCallId = requestMessageRejectedInvoice.messageId;
  requestMessageRejectedInvoice.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.putInvoicesRejectedApiCallId + `/${invoiceIds}?status=approved`
  );

  requestMessageRejectedInvoice.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );
  requestMessageRejectedInvoice.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PUT_Method
  );
  runEngine.sendMessage(requestMessageRejectedInvoice.id, requestMessageRejectedInvoice);
  return requestMessageRejectedInvoice.messageId;
};

  // Customizable Area End
}
