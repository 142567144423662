Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.putApiMethod = "PUT";
exports.deleteApiMethod = "DELETE";

exports.groupsApiEndPoint = "account_groups/groups";
exports.tasksAccountsApiEndPoint = "roles_permissions/accounts/list_users";
exports.getAllBlockedProductionhouseProfilesAPIEndPoint = "account_block/all_blocked_phouse_profiles?per_page=9&page=";
exports.getAllBlockedVendorProfilesAPIEndPoint = "account_block/all_blocked_vendore_profiles?per_page=9&page=";
exports.getAllBlockedStudioProfilesAPIEndPoint = "account_block/all_blocked_studio?per_page=9&page=";
exports.putBlockedProductionhouseProfileAPIEndPoint = "account_block/block_production_house/";
exports.putBlockedVendorProfileAPIEndPoint = "account_block/block_vendor/";
exports.putBlockedStudioProfileAPIEndPoint = "bx_block_customizableformworkflow3/studios/block_studio/";
exports.getAllProductionhouseSearchDataApiEndPoint = "account_block/prodution_house_blocked_search?per_page=9&search=";
exports.getAllVendorSearchDataApiEndPoint = "account_block/blocked_vendors_serach?per_page=9&search=";
exports.getAllStudioSearchDataApiEndPoint = "account_block/blocked_studios_search?per_page=9&search=";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "* All fields are mandatory.";

exports.textClose = "Close";
exports.textSave = "Save";
exports.textUpdate = "Update";
exports.textAddGroup = "Add Group";
exports.textShowGroup = "Show Group";
exports.textEdit = "Edit";
exports.textDelete = "Delete";
exports.textAccounts = "Accounts";
exports.textAddAcc = "Add Acc.";
exports.textDeleteAcc = "Delete Acc.";
exports.textId = "Id";
exports.textStatus = "Status";
exports.textActions = "Actions";
exports.textAssign = "Assign";
exports.textAssignedTo = "Assigned To";
exports.textName = "Name";
exports.textCreatedAt = "Created At";
exports.textUpdatedAt = "Updated At";
exports.textSelectAccounts = "Select accounts";
exports.manageText = "Manage Blocked Profiles";
exports.allProductionList = "Production Houses";
exports.vendorText = "Vendors";
exports.studioText = "Studios";
exports.name = "Langke Zambo";
exports.location = "Bangalore India";
exports.four = "4";
exports.project = "Projects";
exports.studio = "Studios";
exports.booked = "Booked";
exports.available = "Available";
exports.ownedBy = "Owned By :";
exports.tSeries = "T-Series";
exports.price = "₹4,500";
exports.perDay = "Per Day";
exports.emailText = "jurrien@gmail.com";
exports.phoneText = "+91-7855011125";
// Customizable Area End
