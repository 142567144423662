import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  breadcrumList: IBreadCrum[]
  // Customizable Area End
}

  // Customizable Area Start
export interface IBreadCrum {
  text: string;
  href: string;
  clickable: boolean;
  page: string
}
  // Customizable Area End

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class BreadCrumController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
  }

  // Customizable Area Start
  handleNavigation = (page: string) => {
    this.props.navigation.navigate(page)
  };
  // Customizable Area End
}