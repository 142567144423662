import React from "react";

import {
  // Customizable Area Start
  Container,
  Box,
  Typography,
  Grid,
  TypographyProps,
  Link,
  AppBar,
  Tabs,
  Tab,
  Avatar,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import SideBar from "../../../components/src/SideBar";
import Header from "../../../components/src/Header.web";
import LocationController, { Props } from "./LocationController";
import Footer from "../../../components/src/Footer";
const images = require("./assets");
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

export default class Location extends LocationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderLiveTravellingTabContent = (travellingRecords: any[]) => {
    return <>
      <Grid container spacing={3} style={{ marginTop: "4px", paddingLeft: "12px", paddingRight: "12px" }}>
        {travellingRecords.length ? travellingRecords.map((record: any, index: number) => (
          <Grid data-test-id={`${record}_studiolist`} item xs={12} sm={6} md={4} key={record}>
            <Box style={{ height: "66px", borderRadius: "12px", border: "1px solid #e3eaf4" }}>
              <Box
                style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                <Box
                  style={{ display: "flex", margin: "12px 0 12px 12px" }}
                >
                  <Avatar style={{ borderRadius: "10px", width: 42, height: 42 }} src={"https://www.littlethings.info/wp-content/uploads/2014/04/dummy-image-green-e1398449160839.jpg"}> </Avatar>
                  <Box style={webStyle.locationVendorLiveTravellingLeftBox as React.CSSProperties}>
                    <Typography
                      style={{
                        lineHeight: "19px",
                        color: "#17181d",
                        fontFamily: "Urbanist",
                        fontSize: "16px",
                        fontWeight: 600,
                        textAlign: "center",
                        letterSpacing: "0.2px",
                        paddingTop: "2px",
                      }}
                    >
                      Pushpa 2
                    </Typography>
                    <Typography
                      style={{
                        lineHeight: "15px",
                        color: "#6d89af",
                        fontFamily: "Urbanist",
                        fontSize: "12px",
                        fontWeight: 500,
                        textAlign: "center",
                        letterSpacing: "0.15px",
                        paddingBottom: "2px",
                        paddingTop: "3px",
                        display: "flex",
                        alignItems: "center",
                      }}>
                      <img src={images.workHistoryIcon} style={{
                        fontSize: "small",
                        width: "16px",
                        height: "16px",
                        marginRight: 4,
                      }} />
                      05 Job Requests
                    </Typography>
                  </Box>
                </Box>
                <Box data-test-id={`moveRight_${index}`} onClick={() => this.handleLocationNavigation("WorkTravellingList")} style={{ marginRight: "14px", cursor: "pointer" }}><ChevronRightIcon fontSize="small" htmlColor="#6d89af" /></Box>
              </Box>
            </Box>
          </Grid>
        ))
          : <Typography style={{ width: '100%', textAlign: 'center', marginTop: '30px', marginBottom: '30px', color: '#6d89af', fontFamily: 'Urbanist', fontSize: 14, fontWeight: 600 }}>No record available</Typography>
        }
      </Grid>
    </>
  }

  renderMainLocationContent(sideBarStatus: boolean = this.state.isLocationSideBarOpen) {
    return (<Grid style={webStyle.locationContentSetup}>
      < Header
        dropDownStatus={this.state.isLocationDropdownOpen}
        sideBarStatus={sideBarStatus}
        handleDropdown={this.handleLocationDropdown}
        handleSideBar={this.handleLocationSideBar}
        navigate={this.props.navigation.navigate}
      />
      <Grid style={webStyle.locationDataContent as React.CSSProperties}>
        <Box sx={webStyle.locationBreadCrumbBox}>
          <Box>
            <Typography style={webStyle.locationCurrentBlockTitle as TypographyProps} variant="h3">Track for Work</Typography>
            <Typography style={webStyle.locationBlockPath as TypographyProps} variant="h6">
              <span style={webStyle.locationYellowHighlightedText}>
                <Link color="inherit" data-test-id="homeLink" onClick={() => this.handleLocationNavigation("JobProfile")}> Home</Link>
              </span>{" "}/ Track for Work
            </Typography>
          </Box>
        </Box>
        <Grid item lg={12} xl={12} xs={12} sm={12} md={12}>
          <AppBar position="static" color="transparent" className="appbar app_bar" >
            <Tabs
              value={this.state.tabLocationValue}
              variant="scrollable"
              data-test-id="tabChange"
              onChange={this.handleLocationAppBarValue}
              scrollButtons="on"
              TabIndicatorProps={{ style: { background: "orange", height: '2px' } as React.CSSProperties }}
              aria-label="scrollable prevent tabs example"
              className="tabs_main"
            >
              <Tab label="Live Travelling" className={this.state.tabLocationValue == 0 ? "active_btn" : "non_active_btn"} />
              <Tab label="Travel History" className={this.state.tabLocationValue == 1 ? "active_btn" : "non_active_btn"} />
            </Tabs>
          </AppBar>
        </Grid>
        {this.state.tabLocationValue === 0 ? this.renderLiveTravellingTabContent(this.state.liveTravellingList) : this.renderLiveTravellingTabContent(this.state.travelHistoryList)}
      </Grid >
      <Footer />
    </Grid >)
  }
  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container style={webStyle.locationParentContainerDiv}>
          {this.state.isLocationSideBarOpen ? (<Grid spacing={0} container>
            <Grid style={webStyle.locationSidebarParent} item xs={3}>
              <SideBar
                userData={{ age: 25 }}
                navigate={this.props.navigation.navigate}
                activeMenuItem={"Track for Work"}
                handleSideBar={this.handleLocationSideBar} />
            </Grid>
            <Grid item style={{ flexBasis: "77.75%", maxWidth: "77.75%" }} xs={9}>{this.renderMainLocationContent()}</Grid>
          </Grid>) :
            <Grid item xs={12}>{this.renderMainLocationContent()}</Grid>
          }
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  locationVendorLiveTravellingLeftBox: {
    marginLeft: "10px",
    display: "flex",
    alignItems: "start",
    flexDirection: "column",
    height: "42px",
  },
  locationParentContainerDiv: {
    padding: "0px",
    maxWidth: "none",
  },
  locationContentSetup: {
    borderLeft: "1px solid #E3EAF4",
    height: '100%',
  },
  locationSidebarParent: {
    maxWidth: "22.25%",
    minHeight: "100vh",
  },
  locationDataContent: {
    backgroundColor: "#F8FAFE",
    width: "100%",
    boxSizing: "border-box",
    padding: "24px",
    minHeight: "100vh",
  },
  locationYellowHighlightedText: {
    color: "#F5C42C",
    cursor: 'pointer',
    textDecoration: 'underline',
    fontFamily: 'Urbanist',
    fontSize: "14px",
    fontWeight: 600,
  },
  locationBreadCrumbBox: {
    alignItems: "center",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  locationBlockPath: {
    color: "#6D89AF",
    letterSpacing: "1px",
    fontSize: "14px",
    fontWeight: 500,
  },
  locationCurrentBlockTitle: {
    lineHeight: "34px",
    letterSpacing: "1px",
    fontWeight: 700,
    marginBottom: "8px",
    fontSize: "28px",
  },
};
// Customizable Area End
