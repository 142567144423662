
import React from "react";
import { Grid, TextField, InputLabel,Typography, Dialog, DialogTitle, Box, DialogContent, DialogActions, withStyles, styled } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { CustomButton } from "../../blocks/email-account-login/src/CustomButton";
import Select from "react-select";
import { departmentOptions } from "./utils";
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';



const useStyles = makeStyles({
    dialogParent: { width: 455, margin: "auto" },
    dialogHeader: { display: "flex", justifyContent: "space-between" },
    dialogContaentContainer: {
        margin: "30px 0px 42px 0px",
        display: "flex",
        justifyContent: "space-between",
        gap: "14px",
    },
    inputLabel: { fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#17181D", paddingBottom: "6px", paddingTop: '16px' },
    headerLabel: {
        color: ' #17181D',
        textAlign: 'center',
        fontFamily: 'Urbanist',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: 'normal',
        letterspacing: '0.2px',
    }
});
const CustomInputlabel = withStyles({
    root: {
        fontFamily: 'Urbanist',
        // color: '#f5c42c',
        fontWeight: 600,
        "@media (max-width : 1440px)": {
            fontSize: '14px'
        },
        "@media (min-width : 1441px)": {
            fontSize: '14px',
            lineHeight: "14px",
            paddingBottom: "6px",
            paddingTop: "14px"

        }
    },
})(InputLabel);
const CustomCSSOfTextField = withStyles({
    root: {
        width: "100%",
        backgroundColor: 'white',
        borderColor: "#f5c42c",
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#E3EAF4",

            },
            "&:hover fieldset": {
                borderColor: "#f5c42c"
            },
            "&.Mui-focused fieldset": {
                borderColor: "#f5c42c",
                backgroundColor: 'rgba(245,196,44,0.04)',
            },
            fontWeight: 400,
            color: '#323232',
            borderRadius: "14px",
            "& .MuiOutlinedInput-input": {
                fontWeight: 600,
                // color: '#17181D',
                lineHeight: '19px',
                borderColor: '#CCCCCC',
                fontSize: '14px',
            }
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#f5c42c"
        },
        "& .MuiInputBase-input::placeholder": {
            color: '#6d89af',
            fontWeight: 500,
            fontFamily: 'Urbanist',
        },
        '& .MuiInputBase-input[type="date"]': {
            textTransform: "uppercase",
            fontWeight: 500,
            color: "#6D89AF",
            '&::after': {
                color: 'red',
            },
        },
    }
})(TextField);

const CustomNextStepButtonBox = styled(CustomButton)({
    '& .MuiButton-label': {
        fontSize: 14,
        color: '#ffffff',
        fontWeight: 500,
        width: 163,
        height: 48,
    }
});

const CustomReactSelect = styled(Select)({
    '& .react-select__control': {
        borderRadius: "14px",
        height: "52px",
    },
    '& .react-select__control:hover': {
        borderColor: "#f5c42c"
    },
    '& .react-select__control--menu-is-open': {
        borderColor: "#f5c42c"
    },
    '& .react-select__placeholder': {
        color: "#6D89AF",
        fontFamily: "Urbanist",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "18px",
        letterSpacing: "0.2px",
    }
});

interface IDepartmentProps {
    handleSelectNewDepartmentModal: () => void
    selectNewDepartmentModal: boolean;
    departMentList:[{value:string,label:string}]
    selectedDepartment:{value:{value:string,label:string}, error:boolean,
    errMessage:string},
    handleClose:()=>void,
    handleSelectChange:(fieldName:string,value:string)=> void   
}

export default function SelectDepartment(props: IDepartmentProps) {
    const classes = useStyles();

    return (
        <>
            <Dialog
             scroll={'paper'}
                style={{ width: 455, margin: "auto" }}
                fullWidth={true}
                open={props.selectNewDepartmentModal}
                onClose={props.handleSelectNewDepartmentModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" >
                    <Box style={{ display: "flex", justifyContent: "space-between" }}>
                        <span></span>
                        <span className={classes.headerLabel} >
                            Select Department
                        </span>
                        <span data-test-id="addDepartmentCloseBtn" onClick={props.handleSelectNewDepartmentModal}><CloseIcon /></span>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <CustomInputlabel id="nameOfPH" className={classes.inputLabel}>Select Dept.</CustomInputlabel>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <CustomReactSelect
                            style={{zIndex:99}}
                            data-test-id="departmentId"
                            classNamePrefix="react-select"
                            value={props.selectedDepartment.value}
 onChange={(e:any)=>props.handleSelectChange(e,"selectedDept")}
                            options={departmentOptions}
                            placeholder={"Select Dept"}
                            components={{
                                IndicatorSeparator: null
                            }}
                            name="selectedState"
                        />
                           {
                                      props.selectedDepartment.error && (
                                        <Box className={"classes.errorBoxStyle"} style={{display:"flex",gap:"10px"}}>
                                          <ErrorOutlineOutlinedIcon className={"classes.errorIconStyle"} color="error" />
                                          <Typography style={{
                                            color: "#D53333",
                                            fontFamily: "Poppins",
                                            fontSize: "16px",
                                            fontWeight: 400,
                                          }}>
                                            {props.selectedDepartment.errMessage}
                                          </Typography>
                                        </Box>
                                      )
                                    }

                    </Grid>
                </DialogContent>
                <DialogActions style={{ padding: "0px 19px 24px" }}>
                    <CustomNextStepButtonBox data-test-id="department_button" style={{ height: 48 } as React.CSSProperties} className="button" onClick={props.handleClose}>
                        Save Department
                    </CustomNextStepButtonBox>
                </DialogActions>
            </Dialog>

        </>
    );
}
