import React, { useEffect, useState } from "react";
import { InputLabel, Typography, Switch, styled, Box, TextField, withStyles, Grid, InputAdornment, Modal, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { uploadProjectIcon, keyIcon, whiteCrossIcon } from "./assets";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { CustomButton } from "../../blocks/email-account-login/src/CustomButton";
import { Select } from "@material-ui/core";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector.web";
import { phoneValidate } from "../../framework/src/Utilities";

const StyledModal = styled(Modal)({
    position: 'fixed',
    zIndex: 1300,
    inset: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

const style = {
    maxWidth: 720,
    borderRadius: '20px',
    padding: '20px 24px 24px',
    backgroundColor: '#ffffff',
    boxShadow: 'none',
    maxHeight: 571,
    boxSizing: "border-box",
};

const CustomInputlabel = withStyles({
    root: {
        fontFamily: 'Urbanist',
        color: '#f5c42c',
        fontWeight: 600,
        "@media (max-width : 1440px)": {
            fontSize: '14px'
        },
        "@media (min-width : 1441px)": {
            fontSize: '14px',
            lineHeight: "14px",
            paddingBottom: "6px",
            paddingTop: "14px"

        },
    },
})(InputLabel);

const CustomCSSOfTextField = withStyles({
    root: {
        width: "100%",
        backgroundColor: 'white',
        borderColor: "#f5c42c",
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#E3EAF4",
            },
            "&:hover fieldset": {
                borderColor: "#f5c42c"
            },
            "&.Mui-focused fieldset": {
                borderColor: "#f5c42c",
                backgroundColor: 'rgba(245,196,44,0.04)',
            },
            fontWeight: 400,
            color: '#323232',
            borderRadius: "14px",
            "& .MuiOutlinedInput-input": {
                fontWeight: 600,
                lineHeight: '19px',
                borderColor: '#CCCCCC',
                fontSize: '14px',
                padding: "16.5px 14px",
            }
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#f5c42c"
        },
        "& .MuiInputBase-input::placeholder": {
            color: '#6d89af',
            fontWeight: 500,
            fontFamily: 'Urbanist',
        },
        '& .MuiInputBase-input[type="date"]': {
            textTransform: "uppercase",
            fontWeight: 500,
            color: "#6D89AF",
            '&::after': {
                color: 'red',
            },
        },
    }
})(TextField);

const CustomNextStepButtonBox = styled(CustomButton)({
    '& .MuiButton-label': {
        fontWeight: 600,
        fontSize: 14,
        color: '#ffffff',
        letterSpacing: '0.4px',
        lineHeight: '24px',
    }
});

const useStyles = makeStyles({
    permissionDiv: {
        boxSizing: "border-box",
        height: "48px",
        padding: "12px",
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        borderRadius: "14px",
    },
    dialogParent: { width: '720px', minHeight: '563px', margin: "0px auto", borderRadius: "20px" },
    dialogTitle: {
        lineHeight: "24px",
        color: "#17181d",
        fontFamily: "Urbanist",
        fontSize: "20px",
        fontWeight: 700,
        textAlign: "center",
        letterSpacing: "0.2px",
    },
    invoiceDiv: {
        borderRadius: "14px",
        border: "0.75px dashed #F5C42C",
        width: "125px",
        margin: "auto",
        height: "125px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",
    },
    invoiceLabel: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    customLabel: {
        fontSize: "13px",
        fontFamily: "Urbanist",
        fontWeight: 600,
        lineHeight: '18px',
        color: "#6D89AF",
        paddingBottom: "6px",
        paddingTop: '10px',
        letterSpacing: "0.2px"
    },
    imagePDiv: { display: "flex", justifyContent: "center", alignItems: "center" },
    seperator: {
        border: "1px solid #E3EAF4",
        width: "100%",
        margin: "22.5px 0px 22.5px 0px"
    },
    permissionsDiv: {
        cursor: "pointer",
        borderRadius: "14px",
        display: "flex",
        flexDirection: "column",
        border: "1px solid #17181D",
        padding: "12px"
    },
    permissionContainer: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between"
    },
    permissionListDiv: { display: "flex", alignItems: "center", gap: "7px", backgroundColor: "#E3EAF4", borderRadius: "12px", padding: "5px 8px", color: "#6D89AF" },
    dialogActionBtn: {
        display: "flex",
        alignItems: "end",
        justifyContent: "end",
        marginTop: "32px",
    },
    projectDiv: {
        borderRadius: "12px",
        background: "rgba(245,196,44,0.12)",
        display: "flex",
        alignItems: "center",
        width: "38px",
        height: "38px",
        justifyContent: "center",
        marginBottom: "12px",
    },

});

const AntSwitch = styled(Switch)(() => ({
    width: 25,
    height: 12.5,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: "7.5px",
            height: "7.5px",
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: '#F5C42C',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: "7.5px",
        height: "7.5px",
        borderRadius: 6,
        transitionDuration: "2s",
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: '#6D89AF',
        boxSizing: 'border-box',
    },
}));

interface IProps {
    departmentsList: any[];
    rolesList: any[];
    handleSaveEmployeeApiCall: any;
    permssionsList: any[];
    open: boolean;
    countryCodeSelected: any;
    handleClose: () => void;
    //showNotification: any;
}

export default function AddNewMember(props: IProps) {
    const classes = useStyles();
    const [modelType, setModelType] = useState<null | "permission">(null);
    const [permissions, setPermissions] = useState([...props.permssionsList]);
    const [permissionsUpdate, setPermissionsUpdate] = useState([...props.permssionsList]);
    const [image, setImage] = useState<any>(null);
    const [imageFileObj, setImageFileObj] = useState<any>(null);
    const [fullName, setFullName] = useState("");
    const [fullNameError, setFullNameError] = useState({ require: false });
    const [phoneNumber, setPhoneNumber] = useState("");
    const [tempCountryCode, setTempCountryCode] = useState(null);
    const [countryCodeSelected, setCountryCodeSelected] = useState(props.countryCodeSelected);
    const [phoneNumberError, setPhoneNumberError] = useState({ invalid: false, require: false });
    const [department, setDepartment] = useState("");
    const [departmentError, setDepartmentError] = useState({ require: false });
    const [role, setRole] = useState("");
    const [roleError, setRoleError] = useState({ require: false });

    useEffect(() => {
        setCountryCodeSelected(tempCountryCode ? tempCountryCode : props.countryCodeSelected);
        setTempCountryCode(null);
    }, [props.countryCodeSelected]);

    const handleChangeModelType = (type: null | "permission") => {
        setModelType(type);
        setPermissionsUpdate(permissions);
        setTempCountryCode(countryCodeSelected);
    }

    const handleSaveEmployee = () => {
        if (!fullName || !phoneNumber || !department || !role) {
            setFullNameError({ require: !Boolean(fullName) });
            setPhoneNumberError({ invalid: phoneNumberError.invalid, require: !Boolean(phoneNumber) });
            setDepartmentError({ require: !Boolean(department) });
            setRoleError({ require: !Boolean(role) });
            return false;
        } else if (phoneNumberError.invalid) {
            return false;
        } else if (!image) {
            // props.showNotification("error", true, "Please upload image");
            return false;
        }

        const permissionsSelected = permissions.filter((data: any) => data.selected);

        const formData = new FormData();
        formData.append("employee[photo]", imageFileObj);
        formData.append("employee[full_name]", fullName);
        formData.append("employee[full_phone_number]", countryCodeSelected + phoneNumber);
        formData.append("employee[project_department_id]", department);
        formData.append("employee[role_id]", role);
        formData.append("employee[permission_ids]", permissionsSelected.map((data: any) => data.id).toString());

        props.handleSaveEmployeeApiCall(formData);
    }

    const handleSavePermissions = () => {
        setPermissions(permissionsUpdate);
        handleChangeModelType(null);
    }

    const handleChange = (event: any, index: number, type?: string) => {
        let updatedPermissions = type === "cancel" ? [...permissions] : [...permissionsUpdate];
        const updateData = { ...updatedPermissions[index], selected: event.target.checked };
        updatedPermissions.splice(index, 1, updateData);
        type === "cancel" ? setPermissions(updatedPermissions) : setPermissionsUpdate(updatedPermissions);
    };

    const handleFileChange = (event: any) => {
        setImage(URL.createObjectURL(event.target.files[0]));
        setImageFileObj(event.target.files[0]);
    };

    const handlePhoneNumberInput = (event: any) => {
        const phoneNumberData = event.currentTarget?.value;
        setPhoneNumber(phoneNumberData);
        if (!phoneNumberData) {
            setPhoneNumberError({ invalid: false, require: true });
            return false;
        } else {
            setPhoneNumberError({ invalid: false, require: false });
        }
        const validatePhoneNumber = phoneValidate("phone number", phoneNumberData);
        if (!validatePhoneNumber.status) {
            setPhoneNumberError({ invalid: true, require: false });
        } else {
            if (countryCodeSelected === "91" && phoneNumberData.length !== 10) {
                setPhoneNumberError({ invalid: true, require: false });
            } else {
                setPhoneNumberError({ invalid: false, require: false });
            }
        }
    }

    const handleChangeFullNameInput = (event: any) => {
        const fullNameData = event.target.value;
        setFullName(fullNameData);
        if (!fullNameData) {
            setFullNameError({ require: true });
            return false;
        } else {
            setFullNameError({ require: false });
        }
    }

    const handleInputFocus = (labelName: string) => {
        const nameLabel = document.getElementById(labelName);
        if (nameLabel) {
            nameLabel.style.color = "#F5C42C";
        }
    };

    const handleInputBlur = (labelName: string) => {
        const nameLabel = document.getElementById(labelName);
        if (nameLabel) {
            nameLabel.style.color = "#6D89AF";
        }
    };

    const handleSelectRole = (event: any) => {
        const roleData = event.target.value;
        setRole(roleData);
        if (!roleData) {
            setRoleError({ require: true });
            return false;
        } else {
            setRoleError({ require: false });
        }
    }

    const handleSelectDepartment = (event: any) => {
        const deptData = event.target.value;
        setDepartment(deptData);
        if (!deptData) {
            setDepartmentError({ require: true });
            return false;
        } else {
            setDepartmentError({ require: false });
        }
    }

    const getCountryName = async (countryName: string) => {
        if (countryName === "IN" && phoneNumber.length && phoneNumber.length !== 10) {
            setPhoneNumberError({ invalid: true, require: false });
        }
    }

    const renderErrorMessage = (type: boolean, message: string) => {
        return <>
            {type ? <Typography style={{ color: 'red', fontFamily: 'Urbanist', fontSize: '13px', fontWeight: 600, lineHeight: '18px', letterSpacing: '0.4px' }}>{message}</Typography> : null}
        </>
    }

    return (
        <>
            <StyledModal
                aria-labelledby="unstyled-modal-title"
                aria-describedby="unstyled-modal-description"
                open={props.open}
                onClose={props.handleClose}
            >
                <Box sx={style}>
                    <Box style={{ paddingBottom: "16px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <span>{modelType == "permission" && <ArrowBackIcon htmlColor="#17181D" style={{ cursor: "pointer", width: "19px", height: "19px", padding: "7.5px", background: "#f8fafe", border: "1px solid #e1e6ec", borderRadius: "21px" }} onClick={() => handleChangeModelType(null)} />}</span>
                        <span className={classes.dialogTitle}> {!modelType ? "Add Employee" : "Give Access of"} </span>
                        <span onClick={() => !modelType ? props.handleClose() : handleChangeModelType(null)}><CloseIcon /></span>
                    </Box>
                    <Box className="hideScrollBar" style={{ maxHeight: "411px", overflowY: "scroll" }}>
                        {!modelType ?
                            <Grid container md={12}>
                                <Grid item md={12}>
                                    <Box className={classes.invoiceDiv} style={{ cursor: !image ? "pointer" : "unset" }}>
                                        {!image ? <label className={classes.invoiceLabel} htmlFor="invoiceId">
                                            <input id="invoiceId"
                                                type="file" accept="image/*" onChange={handleFileChange} style={{ display: "none" }} />
                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                <div className={classes.projectDiv}>
                                                    <img height={16} width={13} src={uploadProjectIcon} alt={"uploadIcon"} />
                                                </div>
                                                <span style={{ color: "#17181d", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 600, lineHeight: "18px", textAlign: "center", letterSpacing: "0.21538462px" }}>Upload Image</span>
                                            </div>
                                        </label>
                                            : <div className={classes.imagePDiv}>
                                                <img src={image} height={48} width={48} style={{ borderRadius: "8px" }} />
                                            </div>
                                        }
                                    </Box>
                                </Grid>
                                <Grid item container md={12}>
                                    <Grid item md={6} style={{ paddingRight: "24px" }}>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <CustomInputlabel id="fnInput" className={classes.customLabel}>Full Name</CustomInputlabel>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <CustomCSSOfTextField
                                                inputProps={{
                                                    'aria-label': 'nameOfPHInput',
                                                    type: 'text',
                                                    placeholder: 'Enter full name',
                                                    name: 'fullName',
                                                    onChange: handleChangeFullNameInput,
                                                    value: fullName,
                                                    required: true,
                                                }}
                                                onFocus={() => {
                                                    handleInputFocus("fnInput");
                                                }}
                                                onBlur={() => {
                                                    handleInputBlur("fnInput");
                                                }}
                                                variant="outlined"
                                                data-test-id="nameOfPHInput"
                                            />
                                            {renderErrorMessage(fullNameError.require, "Full name is required")}
                                        </Grid>
                                    </Grid>
                                    <Grid item md={6}>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <CustomInputlabel id="pnInput" className={classes.customLabel}>Phone Number</CustomInputlabel>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <CustomCSSOfTextField
                                                data-test-id="phoneNumberInput"
                                                type={'text'} id="outlined-basic" variant="outlined" placeholder="Enter Phone Number"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start" style={{ cursor: 'hand', marginRight: "22px" }} className="phone_input_adornment_box">
                                                            <CountryCodeSelector
                                                                navigation={"navigation"}
                                                                id={"CountryCodeSelector"}
                                                                style={{
                                                                    width: "100%",
                                                                    option: (provided: any, state: any) => ({
                                                                        ...provided,
                                                                        fontWeight: 600,
                                                                        color: "#17181D",
                                                                        fontSize: "14px",
                                                                    }),
                                                                    singleValue: (provided: any, state: any) => ({
                                                                        ...provided,
                                                                        color: "#17181D",
                                                                        fontSize: "14px",
                                                                    }),
                                                                }}
                                                                disable={false}
                                                                allowPropChange={true}
                                                                getCountryName={getCountryName}
                                                                value={countryCodeSelected}
                                                            />
                                                            <Typography style={{ marginLeft: '9px', color: '#17181d' }}> +{countryCodeSelected || "null"}</Typography>
                                                            <Box style={{ borderLeft: '1px solid #6d89af', height: '23px', marginLeft: '12px' } as React.CSSProperties}></Box>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                onFocus={() => {
                                                    handleInputFocus("pnInput");
                                                }}
                                                onBlur={() => {
                                                    handleInputBlur("pnInput");
                                                }}
                                                name="phoneNumber"
                                                value={phoneNumber}
                                                onChange={handlePhoneNumberInput}
                                                className="email_input"
                                            />
                                            {renderErrorMessage(phoneNumberError.require, "Phone number is required")}
                                            {renderErrorMessage(phoneNumberError.invalid, "Phone number is invalid")}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item container md={12}>
                                    <Grid item md={6} style={{ paddingRight: "24px" }}>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <CustomInputlabel id="deptDropdown" className={classes.customLabel} style={{ paddingTop: "24px" }}>Select Dept.</CustomInputlabel>
                                        </Grid>
                                        <Grid className="roleDropdown" item xs={12} sm={12} md={12} lg={12}>
                                            <Select
                                                data-test-id="role-select-dropdown"
                                                id="demo-simple-select"
                                                value={department}
                                                label="Age"
                                                displayEmpty
                                                onChange={handleSelectDepartment}
                                                onFocus={() => {
                                                    handleInputFocus("deptDropdown");
                                                }}
                                                onBlur={() => {
                                                    handleInputBlur("deptDropdown");
                                                }}
                                            >
                                                <MenuItem value={""}>Select Dept for this role</MenuItem>
                                                {props.departmentsList.map((dept: any) => {
                                                    return <MenuItem value={dept.id}>{dept.attributes.name}</MenuItem>
                                                })}
                                            </Select>
                                            {renderErrorMessage(departmentError.require, "Department is required")}
                                        </Grid>
                                    </Grid>
                                    <Grid item md={6}>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <CustomInputlabel id="roleDropdown" className={classes.customLabel} style={{ paddingTop: "24px" }}>Select Role</CustomInputlabel>
                                        </Grid>
                                        <Grid className="roleDropdown" item xs={12} sm={12} md={12} lg={12}>
                                            <Select
                                                data-test-id="role-select-dropdown"
                                                id="demo-simple-select"
                                                value={role}
                                                label="Age"
                                                displayEmpty
                                                onChange={handleSelectRole}
                                                onFocus={() => {
                                                    handleInputFocus("roleDropdown");
                                                }}
                                                onBlur={() => {
                                                    handleInputBlur("roleDropdown");
                                                }}
                                            >
                                                <MenuItem value={""}>Select Role</MenuItem>
                                                {props.rolesList.map((role: any) => {
                                                    return <MenuItem value={role.id}>{role.name}</MenuItem>
                                                })}
                                            </Select>
                                            {renderErrorMessage(roleError.require, "Role is required")}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <hr className={classes.seperator} />
                                <Grid container item md={12}>
                                    <Grid md={12} item className={classes.permissionsDiv}>
                                        <div onClick={() => handleChangeModelType("permission")} className={classes.permissionContainer}>
                                            <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
                                                <img width={20} height={20} src={keyIcon} alt="keyIcon" />
                                                <span style={{ color: "#17181d", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 600, lineHeight: "18px", letterSpacing: "0.2px" }}>Give Permissions</span>
                                            </div>
                                            <span><ArrowForwardIcon /></span>
                                        </div>
                                        {(permissions.some((ele: any) => ele?.selected == true)) && <hr style={{ width: "100%", border: "1px solid #e3eaf4" }} />}
                                        {permissions.length > 0 && <div>
                                            <div style={{ display: "flex", gap: "10px", width: "100%", flexWrap: "wrap" }}>
                                                {permissions.map((permissionObj: any, index: number) => {
                                                    return permissionObj.selected && <div className={classes.permissionListDiv}>
                                                        <span style={{ fontSize: "12px", fontWeight: 600, color: "#6D89AF" }}>{permissionObj.attributes.name}</span> <img src={whiteCrossIcon} onClick={() => handleChange({ target: { checked: false } }, index, "cancel")} width={15} height={15} alt={"whiteCrossIcon"} />
                                                    </div>
                                                })}
                                            </div>
                                        </div>}
                                    </Grid>
                                </Grid>
                            </Grid>
                            : <>
                                <Grid container spacing={3} className="hideScrollBar" style={{ marginTop: "12px", marginBottom: "12px", maxHeight: "272px", overflowY: "scroll" }}>
                                    {permissionsUpdate.length ? [...permissionsUpdate].map((permissionData: any, index: number) => {
                                        return <>
                                            <Grid key={`${permissionData.id}_manage_per`} sm={12} md={6} item style={{ padding: "12px" }} >
                                                <Box style={{ height: "48px", padding: "12px", boxSizing: "border-box", borderRadius: "14px", border: "1px solid #f5c42c", borderColor: permissionData.selected ? "#f5c42c" : "#e3eaf4", background: "rgba(245,196,44,0.04)", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                    <Typography style={{ paddingLeft: "2px", color: "#17181d", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 600, lineHeight: "16px" }}>{permissionData.attributes.name}</Typography>
                                                    <AntSwitch checked={permissionData.selected} onChange={(event) => handleChange(event, index)} inputProps={{ 'aria-label': 'ant design' }} />
                                                </Box>
                                            </Grid>
                                        </>
                                    }) : null}
                                </Grid>
                            </>
                        }
                    </Box>
                    <Box className={classes.dialogActionBtn}>
                        {!modelType ? <CustomNextStepButtonBox style={{ height: 48, background: "#17181d", width: "160px" } as React.CSSProperties} onClick={handleSaveEmployee}>
                            Save Employee
                        </CustomNextStepButtonBox>
                            : <CustomNextStepButtonBox style={{ height: 48, background: "#17181d", width: "210px" } as React.CSSProperties} onClick={handleSavePermissions}>
                                Save Permissions
                            </CustomNextStepButtonBox>}
                    </Box>
                </Box>
            </StyledModal >
        </>
    );
}
