import React, { ChangeEvent } from "react";
import {
  // Customizable Area Start
  Container,
  Box,
  Typography,
  Grid,
  TypographyProps,
  MenuItem,
  FormControl,
  ListItemText,
  Checkbox,
  CircularProgress
  // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import Select from "@material-ui/core/Select";
import Pagination from '@material-ui/lab/Pagination';
import SideBar from "../../../components/src/SideBar";
import Header from "../../../components/src/Header.web";
import Footer from "../../../components/src/Footer";
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import { arrowicon } from "./assets";
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import AllStudioViewController, { Props } from "./AllStudioViewController";
import AllStudioCard from "../../../components/src/AllStudioCard";
import AllStudioDetailCard from "../../../components/src/AllStudioDetailCard";
import Loader from "../../../components/src/Loader.web";
import "./fullVisibility.css";
const theme = createTheme({
  palette: {
    primary: {
      main: "#f5c42c",
      contrastText: "#fff",
    },
  },
});
const options = [
  { id: 1, name: "All Bookings(22)", value: "All" },
  { id: 2, name: "Available Studios(14)", value: "Available" },
  { id: 3, name: "Booked Studios(8)", value: "Booked" },

];
const SortsubDivp1 = styled(Box)({
  display: 'flex',
  gap: "10px",
  alignItems: "center",
  height: 80,
});
const SortDiv1 = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  position: 'relative',
  alignItems: "center",
  "@media(max-width: 600px)": {
    gridColumnGap: '0px',
    display: 'block'
  },
});
const SearchInput1 = styled('input')({
  fontSize: "15px",
  outline: "none",
  border: "none",
  width: "85%",
  padding: "10px 3px",
  backgroundColor: "#F8FAFE",
  "@media(max-width: 500px)": {
    fontSize: '12px',
    overflow: 'hidden',
    width: "70%",
    padding: "10px 0px",
  },
});
const SortSubDiv1 = styled(FormControl)({
  width: '167px',
  marginRight: "10px",
  '& .MuiSelect-select.MuiSelect-selectMenu': {
    paddingLeft: 10,
    color: "#17181d",
    fontFamily: "Urbanist",
    fontSize: "14px",
    fontWeight: 600,
    letterSpacing: "0.25454545px",
  },
  "& .MuiInput-underline::after": {
    borderBottom: 'unset'
  },
  "@media(max-width: 500px)": {
    marginRight: "0px",
  },
  "& .MuiInput-underline::before": {
    content: 'none'
  },
  "& .MuiInputLabel-animated.Mui-focused": {
    transform: 'unset',
    color: 'transparent'
  },
  "& .MuiSelect-icon": {
    marginRight: 10
  },
});
const SearchIcon1 = styled(SearchOutlinedIcon)({
  padding: "10px 0 10px 13px",
  "@media(max-width: 500px)": {
    padding: "10px 0 10px 8px",
    fontSize: '18px'
  },
});
const SelectBox1 = styled(Select)({
  padding: '5px 15px',
  borderRadius: '25px',
  border: '1px solid #e3eaf4',
  background: 'white',
  zIndex: 1,
  marginTop: 0,
  "& :focus": {
    background: 'white',
  },

});
// Customizable Area End
export default class AllStudio extends AllStudioViewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  webrenderMainContent1(sideBarStatus: boolean = this.state.isSideBarOpen) {

    const options = [
      { id: 1, name: `All Bookings(${this.state.Studiocount})`, value: "All" },
      { id: 2, name: `Available (${this.state.Available})`, value: "Available" },
      { id: 3, name: `Booked Studios(${this.state.Booked})`, value: "Booked" },
    
    ];
    return (
      <>
        <Loader loading={this.state.isLoading}></Loader>
        <Grid style={webStyle.contentSetup}>

        
            < Header
              sideBarStatus={sideBarStatus}
              dropDownStatus={this.state.isDropdownOpen}
              handleSideBar={this.handleSideBar}
              handleDropdown={this.handleDropdown}
              navigate={this.props.navigation.navigate}
            />

            <Grid style={webStyle.mainContent1 as React.CSSProperties}>

              <SortDiv1>
                <Box sx={webStyle.blockInfo1}>
                  <Typography variant="h3" style={webStyle.activeBlock1 as TypographyProps}>All Studios</Typography>
                  <Typography variant="h6" style={webStyle.blockPath1 as TypographyProps}><span data-test-id="homeLink" style={webStyle.highlightedText} onClick={() => this.handleNavigation("Dashboard")}>Home</span> / Studios</Typography>
                </Box>
                <SortsubDivp1>
                  <SortSubDiv1>

                    <SelectBox1
                      data-testid='multi-selected-drop-down1'
                      displayEmpty
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left"
                        }
                      }}

                      onChange={this.handleChange}
                      renderValue={(selected:any) => {
                        if (!selected) {
                          return "Sort By:All";
                        }
                        return `Sort By : ${selected}`;
                      }}
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                    // value={options.name}
                    >
                      {options.map((option) => (
                        <MenuItem key={option.id} value={option.value} >
                          <ListItemText primary={option.name} />
                          <Checkbox value={option.value}    checked={this.state.sortValue === option.value} style ={{
                      color: "#F5C42C"
                    }}  />
                        </MenuItem>
                      ))}
                    </SelectBox1>
                  </SortSubDiv1>
                  <Box sx={webStyle.headerSearchBoxdropdown}>
                    <SearchIcon1 style={webStyle.searchicon} />
                    <SearchInput1  placeholder={`Search from ${this.state.count} Studios...`}
                      style={{
                        fontSize: "14px", fontWeight: 500,
                        fontFamily: "Urbanist", letterSpacing: "0.25px"
                      }}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => this.filterHouses(event)} data-testid='search'/>
                  </Box>
                </SortsubDivp1>
              </SortDiv1>
              <Box sx={webStyle.headerSearchBox1} >
                {
                  this.state.allStudioData?.map((item: any, index: any) => (
                    <Grid item key={`${item}_studio_card`} xl={3} lg={4} md={6} sm={12} xs={12} spacing={2} onClick={() => this.navigateToStudioDetail(item.id)} data-testid='navigateuser' >
                      <Box sx={webStyle.headerSearchParentBox1}>
                        <AllStudioCard isSidebar={this.state.isSideBarOpen} data={item} handleBadgeVisibility={this.handleBadgeVisibility} />
                      </Box>
                    </Grid>
                  ))}
                </Box>
              {this.state.count ?
                ( <Grid container spacing={2}> 
                    <Grid item xs={12}>
                      <Pagination style={webStyle.paginationWrapper} data-test-id="pagination" count={Math.ceil((this.state.count) / 9)} page={this.state.currentpage} onChange={this.handlePageChange} />
                    </Grid>
                  </Grid>)
                : null}
              <Footer />
            </Grid>
          </Grid >
        </>
 ) }
        // Customizable Area End

        render() {
    return (
        // Customizable Area Start
        <ThemeProvider theme={theme}>
          <Container style={webStyle.webparentDiv1}>
            {
              this.state.isSideBarOpen ?
                <Grid container spacing={0}>
                  <Grid item xs={3} style={webStyle.websidebarParent1}>
                    <SideBar
                      navigate={this.props.navigation.navigate}
                      activeMenuItem={"All Studios"}
                      userData={{ age: 25 }}
                      handleSideBar={this.handleSideBar} />
                  </Grid>
                  <Grid item xs={9}>{this.webrenderMainContent1()}</Grid>
                </Grid> :
                <Grid item xs={12}>{this.webrenderMainContent1()}</Grid>
            }
          </Container>
        </ThemeProvider>
      // Customizable Area End
        );
  }
}

        // Customizable Area Start
        const webStyle = {
          seperateCard: {
          border: "1px solid #E3EAF4",
        marginLeft: "16px",
        marginRight: "16px",
        marginTop: "9px",
  },
        sortbyTitle: {
          lineHeight: "14px",
        color: "#6d89af",
        fontFamily: "Urbanist",
        fontSize: "12px",
        fontWeight: 600,
        paddingLeft: "16px",
        paddingRight: "16px",
  },
        selectListText: {
          lineHeight: "16px",
        color: "#17181d",
        fontFamily: "Urbanist",
        fontSize: "13px",
        fontWeight: 600,
  },
        headerSearchBoxdropdown: {
          display: "flex",
        border: '1px solid #e3eaf4',
        minWidth: "260px",
        borderRadius: "35px",
        alignItems: "center",
        backgroundColor: "#ffffff",
        overflowX: 'hidden',
        height: "44px",
        "@media screen and (max-width: 500px)": {
          minWidth: "100px",
        width: '160px',
        marginRight: "0px",
    },
  },
        webparentDiv1: {
          padding: "0px",
        maxWidth: "none"
  },
        searchicon: {
          marginRight: '6px',
        fill: '#6D89AF',
        fontSize: "22px"
  },
        contentSetup: {

          borderLeft: "1px solid #E3EAF4",
  },
        websidebarParent: {
          minHeight: "100vh",
  },
        websidebarParent1: {
          minHeight: "100vh",
        maxWidth: "23%"
  },

        mainContent1: {
          width: "100%",
        backgroundColor: "#F8FAFE",
        boxSizing: "border-box",
        padding: "30px"
  },
        mainContent: {
          width: "100%",
        backgroundColor: "#F8FAFE",
        boxSizing: "border-box",
        padding: "24px"
  },
        mainWrapper: {
          display: "flex",
        fontFamily: "Roboto-Medium",
        flexDirection: "column",
        align: "center",
        paddingBottom: "30px",
        background: "#fff",
  },
        highlightedText: {
          color: "#F5C42C",
        textDecoration: 'underline',
        textDecorationColor: "#F5C42C",
        fontWeight: 600
  },
        headerSearchBox1: {
          display: "flex",
        justifyContent: "start",
        align: "end",
        flexDirection: "row",
        flexWrap: "wrap",

  },

        headerSearchParentBox1: {
          display: 'flex',
        justifyContent: 'center',
        marginTop: '5px'
  },
        headerSearchParentBox: {
          display: 'flex',
        justifyContent: 'center',
        marginTop: '16px'
  },
        inputStyle: {
          borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
        width: "100%",
        height: "100px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
  },
        buttonStyle: {
          width: "100%",
        height: "45px",
        marginTop: "40px",
        border: "none",
        backgroundColor: "rgb(98, 0, 238)",
  },
        blockInfo1: {

        },
        blockInfo: {

        },
        activeBlock1: {
          lineHeight: "33px",
        fontSize: "28px",
        fontWeight: "600",
        letterSpacing: "1px",
        display: "flex",
        flexDirection: "row",
        align: 'center',
        gap: '12px',
        marginBottom: '8px'
  },
        activeBlock: {
          lineHeight: "33px",
        fontSize: "28px",
        fontWeight: "600",
        letterSpacing: "1px",
        display: "flex",
        flexDirection: "row",

        alignItems: 'center',
        gap: '12px',
        marginBottom: '8px'
  },
        blockPath1: {
          fontSize: "14px",
        letterSpacing: "1px",
        color: "#6D89AF",
        marginBottom: "5px",
        fontWeight: 500
  },
        blockPath: {
          fontSize: "14px",
        letterSpacing: "1px",
        color: "#6D89AF",
        marginBottom: "15px",
        fontWeight: 500
  },
  paginationWrapper: {
      marginTop: '20px',
      display: 'flex',
      justifyContent: 'center',
  }
};
// Customizable Area End
