import React from "react";
import {
    // Customizable Area Start
    Container,
    Box,
    Typography,
    Grid,
    TypographyProps,
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start

import SideBar from "../../../components/src/SideBar";
import Header from "../../../components/src/Header.web";
import Footer from "../../../components/src/Footer";
import { createTheme, ThemeProvider, makeStyles } from "@material-ui/core/styles";
import JobProfileController, { Props } from "./JobProfileController";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Loader from "../../../components/src/Loader.web";
import "./fullVisibility.css";
import LiveTravelCard from "../../../components/src/LiveTravelCard";
import TravelHistory from "../../../components/src/TravelHistory";
const theme = createTheme({
    palette: {
        primary: {
            main: "#f5c42c",
            contrastText: "#fff",
        },
    },
});
const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
});

// Customizable Area End
export default class TravellingList extends JobProfileController {


    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    jobMainContent1(sideBarStatus: boolean = this.state.isSideBarOpen) {

        return (
            <>
                <Loader loading={this.state.isLoading}></Loader>
                <Grid style={webStyle.contentSetupTravelling}>


                    < Header
                        handleSideBar={this.handleSideBar}
                        handleDropdown={this.handleDropdown}
                        navigate={this.props.navigation.navigate}
                        sideBarStatus={sideBarStatus}
                        dropDownStatus={this.state.isDropdownOpen}
                    />

                    <Grid style={webStyle.mainContent1 as React.CSSProperties}>
                        <Box sx={webStyle.blockInfoTravelling}>
                            <Box>
                                <Typography variant="h3" style={webStyle.activeBlockTravelling as TypographyProps}>Travelling List</Typography>
                                <Typography variant="h6" style={webStyle.blockPathTravelling as TypographyProps}><span style={webStyle.highlightedText} data-test-id="homeLink" onClick={() => this.handleNavigation("JobProfile")}>Home / My Jobs / Samsung Advertisment </span> / Travelling List</Typography>
                            </Box>
                            <Box sx={webStyle.ParentSearch}>
                        
                            + Make a Trip
                        
                            </Box>
                        </Box>

                        <Tabs
                            value={this.state.TabValue}
                            onChange={this.handleChangeTab}
                            indicatorColor="primary"
                            textColor="primary"

                        >
                            <Tab label="Live Travelling" style={{ textTransform: 'none' }} />


                            <Tab label="Travel History" style={{ textTransform: 'none' }} />
                            
                        </Tabs>

                        {this.state.TabValue == 0 && (


                            <Box sx={webStyle.headerSearchBoxTravelling} >
                                {[1,2,3,4,5,6,7,8,9]?.map(() => (
                                    <Grid  lg={4} md={6} sm={12} xs={12} >
                                        <Box sx={webStyle.headerSearchTravelling}>
                                            <LiveTravelCard data={""}/>
                                        </Box>
                                    </Grid>
                                ))}

                                
                            </Box>

                        )


                        } {this.state.TabValue == 1 && (

                            <Box sx={webStyle.headerSearchBoxTravelling} >
                                 {[1,2,3,4,5,6,7,8,9]?.map(() => (
                                    <Grid  lg={4} md={6} sm={12} xs={12} >
                                        <Box sx={webStyle.headerSearchTravelling}>
                                            <TravelHistory data={""}/>
                                        </Box>
                                    </Grid>
                                ))}

                            </Box>
                        )}

                        <Footer />
                    </Grid>
                </Grid >
            </>
        )
    }
    // Customizable Area End

    render() {
        return (

            <ThemeProvider theme={theme}>
                <Container style={webStyle.webparentDiv1}>
                    {
                        this.state.isSideBarOpen ?
                            <Grid container spacing={0}>
                                <Grid item xs={3} style={webStyle.websidebarParent1}>
                                    <SideBar
                                        userData={{ age: 25 }}
                                        activeMenuItem={"Jobs"}
                                        navigate={this.props.navigation.navigate}
                                        handleSideBar={this.handleSideBar} />
                                </Grid>
                                <Grid item xs={9}>{this.jobMainContent1()}</Grid>
                            </Grid> :
                            <Grid item xs={12}>{this.jobMainContent1()}</Grid>
                    }
                </Container>
            </ThemeProvider>

        );
    }
}

// Customizable Area Start
const webStyle = {

    
    webparentDiv1: {
        padding: "0px",
        maxWidth: "none",
        

    },
   
    
    ParentSearch: {
        display: "flex",
        gap: "10px",
        cursor: "pointer",
        color: "#ffffff",
        backgroundColor: "#17181D",
        textTransform: "none",
        width: "130px",
        border: "none",
        borderRadius: "14px",
        //position: "absolute",
        bottom: "16px",
        height: "28px",
        fontWeight: 600,
        fontSize: "15px",
        paddingTop: "10px",
        paddingBottom: "14px",
        marginBottom: "5px",
        fontFamily: "urbanist",
        paddingLeft:'10px',
        alignItems:'center'

    },
   
   
    websidebarParent1: {
        minHeight: "100vh",
        maxWidth: "23%"
    },


   
    contentSetupTravelling: {

        borderLeft: "1px solid #E3EAF4",
    },



    mainContent1: {
        boxSizing: "border-box",
        padding: "30px",
        width: "100%",
        backgroundColor: "#F8FAFE",
        
    },
    mainContent: {
        boxSizing: "border-box",
        width: "100%",
        backgroundColor: "#F8FAFE",
        padding: "24px"
    },

    highlightedText: {
        color: "#F5C42C",
        textDecoration: 'underline',
        textDecorationColor: "#F5C42C",
        fontWeight: 600
    },
    headerSearchBoxTravelling: {
        display: "flex",
        justifyContent: "start",
        align: "end",
        flexDirection: "row",
        flexWrap: "wrap",
        paddingBottom: '32px'

    },
    headerSearchTravelling: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '16px'
    },

  


    blockInfoTravelling: {
        display: 'flex',
        justifyContent: 'space-between'
    },

    activeBlockTravelling: {
        lineHeight: "33px",
        fontSize: "28px",
        fontWeight: "600",
        letterSpacing: "1px",
        display: "flex",
        flexDirection: "row",

        alignItems: 'center',
        gap: '12px',
        marginBottom: '8px'
    },

    blockPathTravelling: {
        fontSize: "14px",
        letterSpacing: "1px",
        color: "#6D89AF",
        marginBottom: "15px",
        fontWeight: 500
    },
    
};
// Customizable Area End
