import React from 'react';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch, { SwitchClassKey, SwitchProps } from '@material-ui/core/Switch';

interface Styles extends Partial<Record<SwitchClassKey, string>> {
    focusVisible?: string;
  }
  
  interface Props extends SwitchProps {
    classes: Styles;
  }

  const IOSSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width:38,
      height: 19,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      transform: 'translate(3px,2px)',
      '&$checked': {
        transform: 'translate(21px,2px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#F5C42C',
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#52d869',
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 12,
      height: 12,
    },
    track: {
      borderRadius: 20 / 2,
      
      backgroundColor: '#d1d8e3',
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  }),
)(({ classes, ...props }: Props) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});


export default function CustomSwitch
({...props}) {
  
  return (
    <FormGroup>
      
      <FormControlLabel
        control={<IOSSwitch checked={props.checked} onChange={props.handleChange} name="checkedB" />}
        label=""
      />
      
    </FormGroup>
  );
}