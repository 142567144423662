
import React from "react";
import { Grid, Typography, Box, styled, Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CustomButton } from "../../blocks/email-account-login/src/CustomButton";

const useStyles = makeStyles({
    headerTitle: {
        color: ' #17181D',
        textAlign: 'center',
        fontFamily: 'Urbanist',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: 'normal',
        letterspacing: '0.2px',
    },
});

const StyledModal = styled(Modal)({
    position: 'fixed',
    zIndex: 1300,
    inset: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

const style = {
    width: 375,
    borderRadius: '20px',
    backgroundColor: 'white',
    boxShadow: 'none',
};

const CustomButtonBox = styled(CustomButton)({
    '& .MuiButton-label': {
        fontWeight: 600,
        fontSize: 16,
        letterSpacing: '0.45714286px',
        lineHeight: '24px',
    }
});

export default function CustomModal({ ...props }) {
    const classes = useStyles();

    return (
        <>
            <StyledModal
                aria-labelledby="unstyled-modal-title"
                aria-describedby="unstyled-modal-description"
                open={props.open}
                onClose={props.handleClose}
            >
                <Box sx={style}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box style={{ padding: "24px 16px 18px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <Box style={{ width: 64, height: 64, background: "rgba(245,196,44,0.12)", borderRadius: "16px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                {props.icon}
                            </Box>
                            <Typography style={{ marginTop: "14px", color: "#17181d", fontFamily: "Urbanist", fontSize: "20px", fontWeight: 700, lineHeight: "24px", letterSpacing: "0.2px", textAlign: "center" }}>{props.title}</Typography>
                            <Typography style={{ width: "286px", marginTop: "11px", color: "#6d89af", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 500, lineHeight: "18px", letterSpacing: "0.21538462px", textAlign: "center" }}>{props.modelContent}</Typography>
                        </Box>
                    </Grid>
                    <Grid container style={{ padding: "16px" }}>
                        <CustomButtonBox data-test-id="sign_in_button" style={{ borderRadius: "14px", height: 48, background: "#17181d", width: "100%", color: "#ffffff" } as React.CSSProperties} onClick={props.handleButtonClick}>
                            {props.modelButtonText}
                        </CustomButtonBox>
                    </Grid>
                </Box>
            </StyledModal>
        </>
    );
}
