import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { setStorageData, getStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
}
interface S {
  token: string;
  isSideBarOpen: boolean;
  isDropdownOpen: boolean;
  values: string[];
  invoicesIndex: number;
  projectsIndex: number;
  dailyActiveUsersIndex: number;
  txtSavedValue: string;
  projecct_count: number;
  vendor_count: number;
  all_production_houses_count: number;
  studio_count: number;
  isLoading: boolean;
  dashboardGraphProjectsData: any;
  dashboardGraphInvoicesData: any;
  dashboardGraphUsersData: any;
}
interface SS {
  id: any;
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  getAllCountApiId: string = "";
  getDashboardGraphProjectsMessgaeId: string = "";
  getDashboardGraphInvoicesMessgaeId: string = "";
  getDashboardGraphUsersMessgaeId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      token: "",
      isSideBarOpen: true,
      isDropdownOpen: false,
      values: ["Weekly", "Monthly", "Yearly"],
      invoicesIndex: 0,
      projectsIndex: 0,
      dailyActiveUsersIndex: 0,
      txtSavedValue: "",
      projecct_count: 0,
      vendor_count: 0,
      all_production_houses_count: 0,
      studio_count: 0,
      isLoading: false,
      dashboardGraphProjectsData: {},
      dashboardGraphInvoicesData: {},
      dashboardGraphUsersData: {},
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    const dashboardAuthToken = await getStorageData("token");
    this.setState({ token: dashboardAuthToken }, () => {
      this.getAllStudioData();
      this.getAllGraphData();
    });
    let isSideBarOpen=await getStorageData("sidebarvalue");
    this.setState({isSideBarOpen:JSON.parse(isSideBarOpen)})
    window.scrollTo(0, 0);
  }


  async receive(from: string, message: Message) {

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
      this.showAlert(
        "Change  Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );
      this.setState({ txtSavedValue: value });
    }

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const countapiRequestId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      const countresponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
      if (countapiRequestId === this.getAllCountApiId) {
        const responseData = countresponse;
        this.setState({
          projecct_count: responseData.projecct_count,
          vendor_count: responseData.vendor_count,
          all_production_houses_count: responseData.all_production_houses_count,
          studio_count: responseData.studio_count,
          
        });
      } else if (countapiRequestId === this.getDashboardGraphProjectsMessgaeId) {
        this.getDashboardGraphProjectsDataHandleResponse(countresponse);
      } else if (countapiRequestId === this.getDashboardGraphInvoicesMessgaeId) {
        this.getDashboardGraphInvoicesDataHandleResponse(countresponse);
      } else if (countapiRequestId === this.getDashboardGraphUsersMessgaeId) {
        this.getDashboardGraphUsersDataHandleResponse(countresponse);
      }
    }
  }

  getDashboardGraphProjectsDataHandleResponse = (responseJson: { data: any; meta: any; errors: [{ message: string, token: string }] }) => {
    if (responseJson.data) {
      this.setState({ dashboardGraphProjectsData: Object.keys(responseJson.data).map((key) => ({ name: key, ...responseJson.data[key] })) })
    }
    else {
      this.showAlert("Error", responseJson.errors[0].message || responseJson.errors[0].token);
    }
  
  }

  getDashboardGraphInvoicesDataHandleResponse = (responseJson: { data: any; meta: any; errors: [{ message: string, token: string }] }) => {
    if (responseJson.data) {
      this.setState({ dashboardGraphInvoicesData: Object.keys(responseJson.data).map((key) => ({ name: key, ...responseJson.data[key] })) })
    }
    else {
      this.showAlert("Error", responseJson.errors[0].message || responseJson.errors[0].token);
    }
    
  }

  getDashboardGraphUsersDataHandleResponse = (responseJson: { data: any; meta: any; errors: [{ message: string, token: string }] }) => {
    if (responseJson.data) {
      this.setState({ dashboardGraphUsersData: Object.keys(responseJson.data).map((key) => ({ name: key, ...responseJson.data[key] })) })
    }
    else {
      this.showAlert("Error", responseJson.errors[0].message || responseJson.errors[0].token);
    }
  
  }

  apiCall = async (dashboardApiMethod: string, dashboardApiEndPoint: string) => {
    const dashboardGraphAPIHeader = {
      "Content-Type": configJSON.dashboarContentType,
      "token": this.state.token,
    };
    
    const dashboardAPIRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    dashboardAPIRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(dashboardGraphAPIHeader)
    );
    dashboardAPIRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      dashboardApiEndPoint
    );
    dashboardAPIRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      dashboardApiMethod
    );
    runEngine.sendMessage(dashboardAPIRequestMessage.id, dashboardAPIRequestMessage);
    return dashboardAPIRequestMessage.messageId;
  };

  getAllGraphData = async () => {
    this.getDashboardGraphProjectsMessgaeId = await this.apiCall(configJSON.dashboarApiMethodType, configJSON.getDashboardGraphProjectsAPI + "weekly");
    this.getDashboardGraphInvoicesMessgaeId = await this.apiCall(configJSON.dashboarApiMethodType, configJSON.getDashboardGraphInvoicesAPI + "weekly");
    this.getDashboardGraphUsersMessgaeId = await this.apiCall(configJSON.dashboarApiMethodType, configJSON.getDashboardGraphUsersAPI + "weekly");
  }

  handleSideBar = () => {
    this.setState({
      isSideBarOpen: !this.state.isSideBarOpen
    }
    , async() => {
      setStorageData("sidebarvalue",this.state.isSideBarOpen.toString())
    }
    );
  };

  handleDropdown = () => {
    this.setState({
      isDropdownOpen: !this.state.isDropdownOpen
    });
  };
  handleNavigation1 = (page: string) => {
    this.props.navigation.navigate(page)
  };

  handlePreviousClick = (index: keyof S) => {
    if (this.state[index] !== 0) {
      this.setState(({
        [index]: (this.state[index] as number) - 1
      } as unknown) as Pick<S, keyof S>, () => {
        this.getDashboardChartsDynamicData(index);
      });
    }
  };


  getAllStudioData = async () => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      "token": this.state.token
    }
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.getAllCountApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.countapiendpoint
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getDashboardChartsDynamicData = async (index: keyof S) => {
    if (index === "invoicesIndex") {
      this.getDashboardGraphInvoicesMessgaeId = await this.apiCall(configJSON.dashboarApiMethodType, configJSON.getDashboardGraphInvoicesAPI + this.state.values[this.state.invoicesIndex].toLocaleLowerCase());
    } else if (index === "projectsIndex") {
      this.getDashboardGraphProjectsMessgaeId = await this.apiCall(configJSON.dashboarApiMethodType, configJSON.getDashboardGraphProjectsAPI + this.state.values[this.state.projectsIndex].toLocaleLowerCase());
    } else if (index === "dailyActiveUsersIndex") {
      this.getDashboardGraphUsersMessgaeId = await this.apiCall(configJSON.dashboarApiMethodType, configJSON.getDashboardGraphUsersAPI + this.state.values[this.state.dailyActiveUsersIndex].toLocaleLowerCase());
    }
  }

  handleNextClick = (index: keyof S) => {
    if (this.state[index] === 2) {
      return false;
    }
    if (this.state[index] !== 2) {
      this.setState(({
        [index]: (this.state[index] as number) + 1
      } as unknown) as Pick<S, keyof S>, () => {
        this.getDashboardChartsDynamicData(index);
      });
    }
  };

  handleNavigation = (page: string, scrollId: string) => {
    this.props.navigation.navigate(page);
    setStorageData("scrollAnalyticsId", scrollId);
  }
}
