import React from "react";

import {
    // Customizable Area Start
    Avatar,
    Typography,
    Box,
    Grid
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import "./fullVisibility.css";
import { webStyle } from "./FullTeamVisibility.web";
import { webStyle_overview } from "./UserDetailCommon.web";
import StarOutlinedIcon from '@material-ui/icons/StarOutlined';
import AllVendorRatingCardController, { Props, configJSON } from "./AllVendorRatingCardController";
// Customizable Area End

export default class AllVendorRatingCard extends AllVendorRatingCardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { item }: { item: any } = this.props;
        // Customizable Area End
        return (
            // Customizable Area Start
            <Grid container spacing={3}>
                <Box sx={webStyle.projectcardParentBox} className="review_card_box">
                    <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                        <Avatar
                            className="rating_avatar"
                            src={item.account.image}
                        >
                        </Avatar>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8} className="project_card_inner">
                        <Typography className="project_name_typo">{item && item.account.full_name}</Typography>
                        <Typography className="project_date_typo">{item && (this.props.type === "productionHouse" ? `${item.role.name} | ${item.created_at}` : item.created_at)}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} lg={2} xl={2} className="project_date">
                        <Box style={webStyle_overview.stars}>
                            <StarOutlinedIcon className="start_color" />
                            <Typography style={webStyle_overview.star_color}>&nbsp;{item.given_star}</Typography>
                        </Box>
                    </Grid>
                </Box>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: 0, marginTop: '-2%' }}>
                    <Typography className="descriptopn_text">{item.comment}</Typography>
                </Grid>
            </Grid>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
// Customizable Area End