import React, { ChangeEvent } from "react";
import {
  // Customizable Area Start
  Container,
  Grid,
  Box,
  Typography,
  TypographyProps,
  TextField,
  InputAdornment,
  IconButton,
  Link,
  
  // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
// @ts-ignore
import ProjectIcon from "../../../components/src/Sidebar_Assets/projects1.svg"
// @ts-ignore
import StudioIcon from "../../../components/src/Sidebar_Assets/studios.svg"
import SideBar from "../../../components/src/SideBar";
import Header from "../../../components/src/Header.web";
import Footer from "../../../components/src/Footer";
import { switchEnable, switchDisable, dummyImgPHIcon } from "./assets";
import TodayOutlinedIcon from '@material-ui/icons/TodayOutlined';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import StarOutlinedIcon from '@material-ui/icons/StarOutlined';
import SearchIcon from "@material-ui/icons/Search";
import FullTeamVisibilityController, { Props, configJSON } from "./FullTeamVisibilityController";
import Loader from "../../../components/src/Loader.web";
import Pagination from '@material-ui/lab/Pagination';
import { setStorageData } from "../../../framework/src/Utilities";
import { Filtericon } from "../../../components/src/assets";
import Sortfunctionality from "../../../components/src/Sortfunctionality";
import Modal from '@material-ui/core/Modal';
const theme = createTheme({
  palette: {
    primary: {
      main: "#F5C42C",
      contrastText: "#fff"
    }
  },
  overrides: {
    MuiSelect: {
      select: {
        "&:focus": {
          border: "1px solid #F5C42C",
        }
      }
    }
  }
});
// Customizable Area End
export default class AllProductionHouses extends FullTeamVisibilityController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderMainContent(
    sideBarStatus: boolean = this.state.isSideBarOpen
  ) {
    return (
      <>
        <Loader loading={this.state.isLoading}></Loader>
        <Grid
          style={webStyle.contentSetup}
        >

          <Header handleSideBar={this.handleSideBar}

            dropDownStatus={this.state.isDropdownOpen}

            navigate={this.props.navigation.navigate}

            sideBarStatus={sideBarStatus}

            handleDropdown={this.handleDropdown}

          />
          <Grid style={webStyle.mainContent as React.CSSProperties}>
            <Box sx={webStyle.blockInfo}>
              <Box>
                <Typography variant="h3" style={webStyle.activeBlock as TypographyProps}>Production Houses</Typography>
                <Typography variant="h6" style={webStyle.blockPath as TypographyProps}>
                  <span style={webStyle.highlightedText}>
                    <Link data-test-id="homeLink" color="inherit" onClick={() => this.handleNavigation("Dashboard")}>
                      Home
                    </Link>
                  </span>
                  {" "}/ Production Houses
                </Typography>
              </Box>
              <Box sx={webStyle.ParentSearch}>
              <div>
        <Box style={webStyle.bellIconBackground} onClick={this.handleOpen}>
          <img src={Filtericon} style={webStyle.FilterDiv as React.CSSProperties} />
        </Box>

        <Modal
          open={this.state.open}
          onClose={this.handleClose} // This handles clicking anywhere outside the modal
          aria-describedby="simple-modal-description"
          aria-labelledby="simple-modal-title"
        >
          <div style={{ // You can style your modal here
            top: '50%',
            position: 'absolute',
              // Set your desired width
            left: '50%',
            backgroundColor: 'white',
            borderRadius:'20px',
            transform: 'translate(-50%, -50%)',
            
            
          }}>
            {/* Modal content goes here */}
            <Sortfunctionality ClearDataValues={this.ClearDataValues} selectedValues={this.state.selectedValues} handleClose={this.handleClose} handleRadioChange1={this.handleRadioChange1}  isBlocked={this.state.isBlocked} statevalue={this.state.state} cityvalue={this.state.city} handleValue={this.handleValue} handleRadioChange={this.handleRadioChange} selectedDate={this.state.selectedDate} handleDateChange={this.handleDateChange} toggleCheckbox={this.toggleCheckbox} statename={this.state.statename} stateindex={this.state.stateindex} handleOptionChange={this.handleOptionChange} sortApiCallForAllProduction={this.sortApiCallForAllProduction} handleSearchstate={this.handleSearch} handleSearchcity={this.handleSearchcity}/>
          </div>
        </Modal>
      </div>
              <Box sx={webStyle.houseSearchBox}>
                <TextField
                  data-test-id="houseSearchInput"
                  onChange={(event: ChangeEvent<HTMLInputElement>) => this.filterHouses(event)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton style={webStyle.iconButton}>
                          <SearchIcon style={webStyle.searchIcon} />
                        </IconButton>
                      </InputAdornment>
                    ),
                    style: {
                      borderRadius: "35px",
                      backgroundColor: "#F8FAFE",
                      padding: "0px",
                      height: "40px",
                      fontSize: "13px"
                    } as React.CSSProperties
                  }}
                  variant="outlined"
                  fullWidth
                  placeholder={`Search from ${this.state.productionHouseTotal} production houses`}
                />
              </Box>
            </Box>
          </Box>
          <Grid container spacing={3} style={{ marginTop: "12px" }}>
            {
              this.state.allProductionHouses?.map((house) => {
                return <Grid data-test-id={`${house.id}_listContainer`} item xs={12} sm={6} md={4} key={house.id} onClick={
                  () => {
                    setStorageData("id", house.id)
                    this.handleNavigation("ProductionHouseDetail")
                  }
                }>
                  <Box sx={webStyle.cardWrapper}>
                    <Box style={{ cursor: "pointer" }}>
                      <Box style={webStyle.imageContainer as React.CSSProperties}>
                        <img style={webStyle.houseImage as React.CSSProperties} src={house.attributes.profile.data?.attributes.profile_image || dummyImgPHIcon} />
                        <Box style={webStyle.houseDetails as React.CSSProperties}>
                          <Box>
                            <Typography style={{ fontWeight: 600, lineSpacing: "0.22857143px", fontSize: "16px", fontFamily: "Urbanist", lineHeight: "19px" } as React.CSSProperties}>{house.attributes.production_house_name}</Typography>
                            <Typography style={{ fontSize: "12px", fontFamily: "Urbanist", lineHeight: "14px", lineSpacing: "0.2px", fontWeight: 500, ...webStyle.alignContent } as React.CSSProperties}><LocationOnOutlinedIcon style={webStyle.locationIcon} />{house.attributes.production_house_address}</Typography>
                          </Box>
                          <Box style={webStyle.stars}>
                            <StarOutlinedIcon style={{ width: "12px" }} />
                            <span>&nbsp;{house.attributes.rating_and_review}</span>
                          </Box>
                        </Box>
                      </Box>
                      <Box style={webStyle.buttonArea as React.CSSProperties}>
                        <Box style={webStyle.cardButton as React.CSSProperties}>
                          <img src={ProjectIcon} alt="" style={webStyle.buttonIcon} />
                          {house.attributes.projects_count} Projects
                        </Box>
                        <Box style={webStyle.cardButton as React.CSSProperties}>
                          <img src={StudioIcon} alt="" style={webStyle.buttonIcon} />
                          {house.attributes.studios_count} Studios
                        </Box>
                      </Box>
                    </Box>
                    <Box style={webStyle.seperateCard}></Box>
                    <Box style={webStyle.additionalInfo}>
                      <Box>
                        <Typography className="calender_typo">
                          <TodayOutlinedIcon
                            style={{ width: "16px", marginRight: "3px" }}
                          />
                          {house.attributes.join_date}
                        </Typography>
                      </Box>
                      <Box
                        style={webStyle.alignContent}
                      >
                        <Typography style={webStyle.block as React.CSSProperties}>
                          {house.attributes.blocked ? <Typography className="block_color">Blocked</Typography> : <Typography className="block">Block</Typography>}
                          {house.attributes.blocked
                            ?
                            <img src={switchEnable}
                              style={webStyle.blockBox}
                              data-test-id={`${house.id}_switch_enable`}
                              onClick={(e: any) => this.handleBadgeVisibility(e, house.id, house.attributes.blocked, "productionhouse")}
                            />
                            :
                            <img src={switchDisable}
                              style={webStyle.blockBox}
                              data-test-id={`${house.id}_switch_disable`}
                              onClick={(e: any) => this.handleBadgeVisibility(e, house.id, house.attributes.blocked, "productionhouse")}
                            />
                          }
                        </Typography>
                      </Box>

                    </Box>

                  </Box>

                </Grid>

              })
            }

          </Grid>
          {this.state.productionHouseTotal ?
            (<Grid container spacing={2}>
              <Grid item xs={12}>
                <Pagination data-test-id="pagination" count={Math.ceil((this.state.productionHouseTotal) / 9)} page={this.state.currentpage} onChange={this.handlePageChange} />
              </Grid>
            </Grid>)
            : null}
        </Grid>

        <Footer />

      </Grid >
      </>
    );

  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider
        theme={theme}
      >

        <Container
          style={webStyle.parentDiv}
        >
          {
            this.state.isSideBarOpen ? (

              <Grid spacing={0} container >
                <Grid item xs={3}
                  style={webStyle.sidebarParent}
                >
                  <SideBar

                    handleSideBar={this.handleSideBar}

                    userData={{ name: "user name" }}

                    activeMenuItem={"All Production Houses"}

                    navigate={this.props.navigation.navigate}

                  />
                </Grid>

                <Grid
                  item xs={9}
                >

                  {this.renderMainContent()}

                </Grid>

              </Grid>

            )
              :
              (
                <Grid item xs={12}>

                  {this.renderMainContent()}

                </Grid>

              )
          }

        </Container>

      </ThemeProvider>
      // Customizable Area End
    )

  }
}

// Customizable Area Start
const webStyle = {
  mainContent: {
    width: "100%",
    minHeight: "100vh",
    backgroundColor: "#F8FAFE",
    boxSizing: "border-box",
    padding: "24px",
  },
  FilterDiv: {
    height: "15px",

  },
 bellIconBackground : {
    width: "44px",
    height: "44px",
    backgroundColor: "#F8FAFE",
    border: "1px solid #DADADA",
    borderRadius: "22px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    marginLeft: "10px"
  },
  contentSetup: {
    borderLeft: "1px solid #E3EAF4"
  },
  blockInfo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap"
  },
  highlightedText: {
    color: "#F5C42C",
    fontFamily: 'Urbanist',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  listItemText: {
    fontWeight: "bold",
    letterSpacing: "0.8px"
  },
  activeBlock: {
    lineHeight: "34px",
    fontSize: "28px",
    fontWeight: 700,
    letterSpacing: "1px",
    marginBottom: "8px",
  },
  cardButton: {
    border: '1px solid #e2eaf4',
    borderRadius: 10,
    backgroundColor: '#f8fafe',
    width: "47%",
    height: 38,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
    fontWeight: 700,
    color: 'black'
  },
  buttonArea: {
    display: 'flex',
    justifyContent: "space-between",
    flexWrap: "wrap",
    padding: "8px 0"
  },
  sidebarParent: {
    minHeight: "100vh",
    maxWidth: "23%"
  },
  parentDiv: {
    maxWidth: "none",
    padding: "0px",
  },
  blockPath: {
    fontSize: "14px",
    letterSpacing: "1px",
    color: "#6D89AF",

  },
  navigationInfo: {
    display: "flex",
    alignItems: "center"
  },
  buttonIcon: {
    width: "17px",
    height: "17px",
    marginRight: "4px",
  },
  cardWrapper: {
    padding: "9px",
    boxSizing: "border-box",
    border: "1px solid #E1E6EC",
    backgroundColor: "#fff",
    borderRadius: "14px",
  },
  activated: {

  },
  blockBox: {
    marginRight: "1%",
    cursor: "pointer",
  },
  block: {
    fontSize: "13px",
    display: "flex",
    columnGap: "1%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  blocked: {
    color: "red"
  },
  alignContent: {
    display: "flex",
    alignItems: "center"
  },
  seperateCard: {
    border: "1px solid #E3EAF4",
  },
  imageContainer: {
    height: "123px",
    width: "100%",
    position: "relative",
    borderRadius: "14px"
  },
  locationIcon: {
    color: "#fff",
    width: "14px",
    height: "14px",
  },
  houseImage: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "14px",
  },
  additionalInfo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#6D89AF",
    padding: "8px 0 0 0"
  },
  houseDetails: {
    display: "flex",
    justifyContent: "space-between",
    position: "absolute",
    alignItems: "center",
    bottom: "8px",
    right: "8px",
    left: "8px",
    color: "#fff"
  },
  stars: {
    padding: "0px 8px",
    background: "linear-gradient(225deg, #58A2FF 0%, #1777E2 100%)",
    borderRadius: "12px",
    display: "flex",
    alignItems: "center",
  },
  houseSearchBox: {
    minWidth: "260px",
  },
 ParentSearch : {
    display: "flex",
    gap: "10px"

  },
  iconButton: {
    margin: "0px",
    padding: "0px",
    marginLeft: "15px"
  },
  searchIcon: {
    color: "black",
    width: "20px",
    height: "20px"
  },
};
// Customizable Area End
