import React from 'react';
import {
    // Customizable Area Start
    Box,
    Typography,
    styled,
    Grid    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import Modal from '@material-ui/core/Modal';
import HCRequestSubmitSuccessModelController, { Props } from './HCRequestSubmitSuccessModelController';
import { CustomButton } from '../../email-account-login/src/CustomButton';
const images = require("./assets");

const StyledSuccessModal = styled(Modal)({
    zIndex: 1300,
    position: 'fixed',
    display: 'flex',
    inset: 0,
    justifyContent: 'center',
    alignItems: 'center',
});

const modelStyle = {
    borderRadius: '20px',
    backgroundColor: 'white',
    width: 343,
    height: 350,
    boxShadow: 'none',
};

const CustomModelButtonBox = styled(CustomButton)({
    '& .MuiButton-label': {
        lineHeight: '24px',
        fontWeight: 600,
        letterSpacing: '0.45714286px',
        fontSize: 16,
    }
});

// Customizable Area End
export default class HCRequestSubmitSuccessModel extends HCRequestSubmitSuccessModelController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <StyledSuccessModal
                open={this.props.open}
                aria-describedby="unstyled-modal-description"
                onClose={this.props.handleClose}
                aria-labelledby="unstyled-modal-title"
            >
                <Box sx={modelStyle}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box style={{ padding: "30px 16px 4px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <Box>
                                <img src={images.successModelImage} />
                            </Box>
                            <Typography style={{ marginTop: "20px", color: "#17181d", fontFamily: "Urbanist", fontSize: "20px", fontWeight: 700, lineHeight: "24px", letterSpacing: "0.3px", textAlign: "center" }}>Submitted Successfully</Typography>
                            <Typography style={{ width: "265px", marginTop: "12px", color: "#17181d", fontFamily: "Urbanist", fontSize: "13px", fontWeight: 500, lineHeight: "18px", letterSpacing: "0.3px", textAlign: "center" }}>Your query has been submitted successfully. Our team will be in touch with you in next 48 Hours.</Typography>
                        </Box>
                    </Grid>
                    <Grid container style={{ padding: "24px" }}>
                        <CustomModelButtonBox data-test-id="sign_in_button" style={{ borderRadius: "14px", height: 48, background: "#17181d", width: "295px", color: "#ffffff" } as React.CSSProperties} onClick={this.handleClickOkBtn}>
                            Okay
                        </CustomModelButtonBox>
                    </Grid>
                </Box>
            </StyledSuccessModal>
        )
        // Customizable Area End
    }
}