import { Box, Typography, Menu, MenuItem, Button, ListItemIcon, Divider, Dialog } from '@material-ui/core';
import React, { useState } from 'react';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import { dummyImage } from './assets';

const styles = {
    container: {
        padding: "12px",
        border: '1px solid #E3EAF4',
        borderRadius: '12px',
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        maxWidth: '324px',
        width: '100%'
    },
    detailsBox: {
        display: 'flex',
        flexDirection: 'row',
        gap: '8px'
    },
    imageContainer: {
        width: 'calc(20%)'
    },
    imageStyles: {
        height: '59px', width: '59px', borderRadius: "14px"
    },
    namecontainer: {
        width: 'calc(60%)',
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
    },
    menuContainer: {
        width: 'calc(20%)',
        textAlign: 'right'
    },
    nameBox: {
        color: '#17181D',
        fontFamily: 'Urbanist',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
    },
    teamLabelBox: {
        color: '#17181D',
        fontFamily: 'Urbanist',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
    },
    levelLabelBox: {
        color: '#3389FF',
        fontFamily: 'Urbanist',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
    },
    designationBox: {
        color: '#6D89AF',
        fontFamily: 'Urbanist',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
    },
    listIcon: {
        color: "#17181D",
        minWidth: '30px'
    },
    menuLabel: {
        color: "#17181D",
        fontFamily: 'Urbanist',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineheight: 'normal',
    },
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },

    accessText: {
        // color: '#17181D',
        fontFamily: 'Urbanist',
        fontSize: ' 12px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '16px',
        // textTransform: 'capitalize'
    },

}

interface IActiveEmployeeProps {
    handleSelectNewDepartmentModal: () => void,
    handleGivePermissionModal: (id?: any) => void,
    handleManageAccessibility:(permisson?:string,department?:string,id?:any)=>void,
    
    handleSMSModalToggle: () => void,
    activeEmployeeId: number | string | undefined,
    data: any,
    isInvited: boolean,
    handleEditRole: (employeeId: string | number) => void,
    handleDeleteJobRole: (employeeId: string | number) => void,
    handleDeleteJobRoleInactive:(employeeId: string | number) => void,
    departmentList: [{ value: string, label: string }],
    setEmp: (data:any)=>void;

}
// data : {[key:string]: any}

const ActiveEmployeeCard = (props: IActiveEmployeeProps) => {

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const permisssions = props?.data?.permissions?.filter((ele: any) => ele.checked == true)

    return (
        <Box sx={styles.container}>
            <Box sx={styles.detailsBox} >
                <Box sx={styles.imageContainer} >
                    <img
                        style={styles.imageStyles}
                        src={props.data.attributes.photo||dummyImage} />
                </Box>
                <Box sx={styles.namecontainer} >
                    <Typography style={styles.nameBox} >
                        {props.data.attributes.full_name}
                    </Typography>
                    <Box >
                        <Typography component='span' style={styles.teamLabelBox} >
                            {props?.data?.attributes?.project_department?.name} | 
                        </Typography>
                        <Typography component='span' style={styles.levelLabelBox} >
                            {" "}Level {props?.data?.attributes?.project_department?.level}
                        </Typography>
                    </Box>
                    <Typography style={styles.designationBox} >
                        {props?.data?.attributes?.role.name}
                    </Typography>
                </Box>
                <Box sx={styles.menuContainer} >
                    <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} style={{
                        padding: 0
                    }}>
                        <MoreHorizIcon />
                    </Button>
                </Box>
            </Box>
            <Divider style={{
                height: '1px', width: '100%'
            }} />
            <Box sx={styles.buttonsContainer}    >
            <Button style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                onClick={() => props.handleManageAccessibility(props?.data?.attributes?.permissions, props?.data?.attributes?.project_department, props?.data?.id)}
                >
                    {permisssions?.length ? <>
                        <Typography component='span' style={{ color: "#6D89AF", backgroundColor: "#E3EAF4", borderRadius: "12px", padding: "5px", ...styles.accessText }} >
                            {permisssions[0].label}
                        </Typography>
                        {permisssions?.length > 1 && <Typography component='span' style={{ marginLeft: "8px", color: "#6D89AF", backgroundColor: "#E3EAF4", borderRadius: "12px", padding: "5px", ...styles.accessText }} >
                            + {permisssions?.length - 1}
                        </Typography>}

                        <Typography component='span' >
                            <ChevronRightIcon style={{ color: "#6D89AF" }} />
                        </Typography>
                    </> : <>  <Typography component='span' style={{ color: '#F5C42C' }} >
                    <Box style={{padding: "4px 7px 3px 7px",
    borderRadius: "16px",
    background: "#f5c42c",
    lineHeight: '16px',
    color: "#ffffff",
    fontFamily: "SF Pro Text",
    fontSize: "13px",
    fontWeight: 600,
    letterSpacing: "-0.325px",
    marginRight: "4px",}}>{props.data.attributes.permissions.length}</Box> 
                    </Typography>
                        <Typography  onClick={() => props.handleManageAccessibility(props.data.attributes.permissions, props.data.attributes.project_department, props.data.id)}
                        component='span' style={{ color: '#17181D', ...styles.accessText }} >
                            Give Access
                        </Typography>
                        <Typography component='span' >
                            <ChevronRightIcon />
                        </Typography></>}
                </Button>
                <Button style={{
                    borderRadius: '15px',
                    border: '1px solid #E3EAF4',
                    background: 'rgba(227, 234, 244, 0.50)',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '0px 10px',
                    gap: '8px'
                }} >
                    <Typography component='span' onClick={()=>{
                        props.setEmp(props.data)
                        props.handleSMSModalToggle()
                        }} style={{ color: '#17181D', ...styles.accessText }} >
                        Send SMS
                    </Typography>
                    <Typography component='span' >
                        <SendOutlinedIcon style={{
                            height: '16px',
                            width: '16px'
                        }} />
                    </Typography>
                </Button>
            </Box>

            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {
                    props.data.attributes.activated?
                    (
                        <MenuItem onClick={() => props.handleDeleteJobRole(props.data.id)} style={{ margin: '4px' }}>
                        <ListItemIcon style={styles.listIcon} >
                            <DeleteOutlinedIcon fontSize="small" />
                        </ListItemIcon>
                        <Typography variant="inherit" style={styles.menuLabel}>Delete Job Role</Typography>
                    </MenuItem>


                    ):(
                        <Box>
                     
                    <MenuItem onClick={() => props.handleDeleteJobRoleInactive(props.data.id)} style={{ margin: '4px' }}>
                        <ListItemIcon style={styles.listIcon} >
                            <DeleteOutlinedIcon fontSize="small" />
                        </ListItemIcon>
                        <Typography variant="inherit" style={styles.menuLabel}>Delete Job Role</Typography>
                    </MenuItem>
                    </Box>

                    )
                }
              
              

            </Menu>
        </Box>
    );
};

export default ActiveEmployeeCard;