import React from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  TypographyProps,
  Link,
  AppBar,
  Tabs,
  Tab} from "@material-ui/core";
import SideBar from "../../../components/src/SideBar";

import Header from "../../../components/src/Header.web";
import Footer from "../../../components/src/Footer";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Loader from "../../../components/src/Loader.web";
import AllStudioBookingListPHController, {
  Props
} from "./AllStudioBookingListPHController";
import Modal from '@material-ui/core/Modal';
import "./studiosbooking.css";
import StudioBookingListPH from "../../../components/src/StudioBookingListPH";
import RequestStudioBookingPH from "../../../components/src/RequestStudioBookingPH"
import Pagination from "@material-ui/lab/Pagination";
import Alert, { AlertProps } from "@material-ui/lab/Alert";

import UpcomingStudioList from "../../../components/src/UpcomingStudioList";
import OngoingStudioList from "../../../components/src/OngoingStudioList"
import RequestCard from "../../../components/src/RequestCard"
import NOStudio from "../../../components/src/NoStudio";
import CreateNewStudio from "../../../components/src/CreateNewStudio.web";
const theme = createTheme({
  palette: {
    primary: {
      main: "#F5C42C",
      contrastText: "#fff"
    }
  },
  overrides: {
    MuiSelect: {
      select: {
        "&:focus": {
          border: "1px solid #F5C42C",
        }
      }
    }
  }
});

const CustomAlert = React.forwardRef<HTMLDivElement, AlertProps>(function CustomAlert(
  props,
  ref
) {
  return <Alert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default class AllStudioBookingListPH extends AllStudioBookingListPHController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  AllStudioListPH=()=>{
    return<>
    {this.state.tabValue === 0 && (
        <Grid container spacing={3} style={{ paddingLeft: "12px", paddingRight: "12px" }} >
          {this.state.allStudioListData.length?(this.state.allStudioListData&&this.state.allStudioListData.map((item:any,index:number) => (
            <Grid   item xs={12} sm={6} md={4}   key={`${item}_alljob_card`} onClick={() => this.navigateToStudioDetail(item.id)}>
              <StudioBookingListPH data={item} handleBadgeVisibility={this.handleBadgeVisibility} deletestudio={this.deletestudio} Editstudio={this.Editstudio}/>
            </Grid>
          ))):(
            <NOStudio handleOpen1={this.Addnewstudio}/>

          )
          
          }
        </Grid>)
      }
      </>
  }
  RequestListPH=()=>{
    return<>
       {this.state.tabValue === 1 && (
              <Grid container spacing={3} style={{ paddingLeft: "12px", paddingRight: "12px" }} >
                {this.state.requestStudioListData.length?(this.state.requestStudioListData&&this.state.requestStudioListData.map((item:any,index:number) => (
                  <Grid   item xs={12} sm={6} md={4}   key={`${item}_request_card`}>
                    <RequestStudioBookingPH data={item} handleOpenRequest={this.handleOpenRequest} Editstudio={this.Editstudio} deletestudio={this.deletestudio} />
                  </Grid>
                ))):(
                    <Typography style={{ width: '100%', textAlign: 'center', marginTop: '30px', marginBottom: '30px', color: '#6d89af', fontFamily: 'Urbanist', fontSize: 14, fontWeight: 600 }}>No record available</Typography>
                )
                
                }
              </Grid>)
            }
    </>
  }
  
  OngoingPH=()=>{
    return<>
      {this.state.tabValue === 2 && (
              <Grid container spacing={3} style={{ paddingLeft: "12px", paddingRight: "12px" }} >
                { this.state.OngoingStudioListData.length?(this.state.OngoingStudioListData&&this.state.OngoingStudioListData.map((item:any,index:number) => (
                  <Grid   item xs={12} sm={6} md={4}   key={`${item}_ Ongoing request_card`}>
                    <OngoingStudioList data={item} cancelbooking={this.cancelbooking} 
                    detailpage={this.navigateToStudioDetailUpcomingandOngoing}
                    />
                  </Grid>
                ))
                ):
                 <Typography style={{ width: '100%', textAlign: 'center', marginTop: '30px', marginBottom: '30px', color: '#6d89af', fontFamily: 'Urbanist', fontSize: 14, fontWeight: 600 }}>No record available</Typography>
            
                }
              </Grid>)
            }
    </>
  }
  UpcomingPH=()=>{
    return <>
    
    {this.state.tabValue === 3 && (
              <Grid container spacing={3} style={{ paddingLeft: "12px", paddingRight: "12px" }} >
                { this.state.UpcomingStudioListData.length?(this.state.UpcomingStudioListData&&this.state.UpcomingStudioListData?.map((item:any,index:number) => (
                  <Grid   item xs={12} sm={6} md={4}   key={`${item}_ Ongoing request_card`}>
                    <UpcomingStudioList data={item} cancelbooking={this.cancelbooking}   detailpage={this.navigateToStudioDetailUpcomingandOngoing}   />
                  </Grid>
                ))
                ):
                 <Typography style={{ width: '100%', textAlign: 'center', marginTop: '30px', marginBottom: '30px', color: '#6d89af', fontFamily: 'Urbanist', fontSize: 14, fontWeight: 600 }}>No record available</Typography>
            
                }
              </Grid>)
            }
    </>
  }
  renderAllStudiosBookingListMainContent = (sideBarStatus: boolean = this.state.isSideBarOpen) => {
    return <>
      <Loader loading={this.state.isLoading}></Loader>
      <Grid style={webStyle.contentSetupPH} >
        <Header handleSideBar={this.handleSideBar}
          dropDownStatus={this.state.isDropdownOpen}
          navigate={this.props.navigation.navigate}
          sideBarStatus={sideBarStatus}
          handleDropdown={this.handleDropdown}
        />
        <Grid style={webStyle.mainContentPH as React.CSSProperties}>
          <Box sx={webStyle.blockInfoPH}>
            <Box>
              <Typography variant="h3" style={webStyle.activeBlockPH as TypographyProps}>All Studios & Bookings</Typography>
              <Typography variant="h6" style={webStyle.blockPathPH as TypographyProps}>
                <span style={webStyle.highlightedTextPH}>
                  <Link data-test-id="homeLink" color="inherit" onClick={() => this.handleNavigation("Projects")}>
                    Home
                  </Link>
                </span>
                {" "}/ All Studios & Bookings
              </Typography>
            </Box>
           {
            this.state.allStudioListData.length?(
                <Box
                sx={webStyle.submitButtonPH}
                data-test-id="handleopen"

                onClick={this.Addnewstudio}
                 >
                + Add New Studio
            </Box>

            ):(null)
           }
                
          </Box>
          <Grid item sm={12} xl={12} xs={12} md={12} lg={12}>
            <AppBar
              position="static"
              className="appbar app_bar"
              color="transparent"
            >
              <Tabs
                value={this.state.tabValue}
                data-test-id="tabChange"
                variant="scrollable"
                onChange={this.handleAppBarValue}
                TabIndicatorProps={{ style: { height: '2px', background: "orange" } as React.CSSProperties }}
                scrollButtons="on"
                className="tabs_main"
                aria-label="scrollable prevent tabs example"
              >
                <Tab className={this.state.tabValue == 0 ? "active_btn" : "non_active_btn"} label="Studios" />
                <Tab className={this.state.tabValue == 1 ? "active_btn" : "non_active_btn"} label="Requests" />
                <Tab className={this.state.tabValue == 2 ? "active_btn" : "non_active_btn"} label="Ongoing Requests" />
                <Tab className={this.state.tabValue == 3 ? "active_btn" : "non_active_btn"} label="Upcoming Requests" />
              </Tabs>
            </AppBar>
          </Grid>
          <Grid container spacing={3} style={{ marginTop: "12px" }}>
            {this.AllStudioListPH()}
            {this.RequestListPH()}
            {
                this.OngoingPH()
            }
            {
                this.UpcomingPH()
            }
            
           
          </Grid>
          {this.state.getCountForPagination[this.state.tabValue] ?
            (<Grid container spacing={2}>
              <Grid item xs={12}>
                <Pagination style={webStyle.paginationWrapper} data-test-id="pagination" count={Math.ceil((this.state.getCountForPagination[this.state.tabValue]) / 9)} page={this.state.currentStudiosPage} onChange={this.handlePageChange} />
              </Grid>
            </Grid>)
            : null}
        </Grid >
        <Footer />
      </Grid >
    </>
  }
  // Customizable Area End

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Container style={webStyle.parentDivPH} >
          {
            this.state.isSideBarOpen ? (
              <Grid spacing={0} container >
                <Grid item xs={3} style={webStyle.sidebarParentPH} >
                  <SideBar
                    handleSideBar={this.handleSideBar}
                    userData={{ name: "user name" }}
                    activeMenuItem={"All Studios & Bookings"}
                    navigate={this.props.navigation.navigate}
                  />
                </Grid>
                <Grid item style={{ flexBasis: "77.75%", maxWidth: "77.75%" }} xs={9} >
                  {this.renderAllStudiosBookingListMainContent()}
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12}>
                {this.renderAllStudiosBookingListMainContent()}
              </Grid>
            )
          }
         
        </Container>
        
        <Modal
                                                    open={this.state.openRequest}
                                                    onClose={this.handleCloseRequest}
                                                    aria-describedby="simple-modal-description"
                                                    aria-labelledby="simple-modal-title"
                                                >
                                                    <div style={{ 
                                                        top: '50%',
                                                        position: 'absolute',                                              
                                                        left: '50%',
                                                        backgroundColor: 'white',
                                                        borderRadius: '20px',
                                                        transform: 'translate(-50%, -50%)',
                                                       width:'720px',
                                                       height:"360px"

                                                    }}>
                                            
                                                      
                                                     <RequestCard data={this.state.pendingRequestListData} handleClose={this.handleCloseRequest} count={this.state.requestcount} city={this.state.city} address={this.state.address} state={this.state.state} handleNavigationRequestProfile={this.handleNavigationRequestProfile}/>
                                                    </div>
                                                </Modal>

                                                <Modal
                                                    open={this.state.open1}
                                                    onClose={this.handleClose1} // This handles clicking anywhere outside the modal
                                                    aria-describedby="simple-modal-description"
                                                    aria-labelledby="simple-modal-title"
                                                >
                                                    <div style={{ // You can style your modal here
                                                        top: '50%',
                                                        position: 'absolute',                                              
                                                        left: '50%',
                                                        backgroundColor: '#ffffff',
                                                        borderRadius: '20px',
                                                        transform: 'translate(-50%, -50%)',


                                                    }}>
                                            
                                                       <CreateNewStudio  handleClose1={this.handleClose1} navigation={this.handleNavigation} getallStudioList={this.getallStudioList}   navigationvalue={this.props.navigation}/>
                                                    </div>
                                                </Modal>
      </ThemeProvider>
    );
  }
}

const webStyle = {
  parentDivPH: {
    padding: "0px",
    maxWidth: "none"
  },
  sidebarParentPH: {
    minHeight: "100vh",
    maxWidth: "22.25%",
  },
  contentSetupPH: {
    
    borderLeft: "1px solid #E3EAF4"
  },
  container: {
    marginTop: "12px"
  },
  mainContentPH: {
    width: "100%",
    minHeight: "100vh",
    backgroundColor: "#F8FAFE",
    boxSizing: "border-box",
    padding: "24px"
  },
  blockInfoPH: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap"
  },
  highlightedTextPH: {
    color: "#F5C42C",
    fontFamily: "Urbanist",
    cursor: "pointer",
    textDecoration: "underline"
  },
  bellIconBackgroundPH: {
    width: "42px",
    height: "42px",
    backgroundColor: "#F8FAFE",
    border: "1px solid #DADADA",
    borderRadius: "22px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    marginRight: "16px",
  },
  iconButtonPH: {
    margin: "0px",
    padding: "0px",
    marginLeft: "15px"
  },
  searchIconPH: {
    color: "#6d89af",
    width: "22px",
    height: "22px"
  },
  activeBlockPH: {
    fontSize: "28px",
    lineHeight: "34px",
    letterSpacing: "1px",
    color: "#17181D",
    fontWeight: "700",
  },
  blockPathPH: {
    letterSpacing: "1px",
    fontSize: "14px",
    marginBottom: "15px",
    color: "#6D89AF",
    paddingBottom: "3px",
    fontWeight: 500,
    paddingTop: "8px",
    lineHeight: "17px",
  },
  submitButtonPH: {
    width: '161px',
  
    borderRadius: '14px',
    background: '#17181d',
    color: ' #ffffff',
    fontFamily: 'urbanist',
    fontSize: '15px',
    fontWeight: 600,
    letterSpacing: ' 0.25px',
    alignItems: 'center',
    paddingTop: '15px',
    paddingBottom: '15px',
    display: 'flex',
    flexDirection: 'column-reverse',
    marginLeft: '208px',
    cursor:'pointer'

},
paginationWrapper: {
  marginTop: '20px',
  display: 'flex',
  justifyContent: 'center',
}
};
