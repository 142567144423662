Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "contentmanagement3";
exports.labelBodyText = "contentmanagement3 Body";

exports.getHelpCenterContactDetailsAPIEndPoint = "bx_block_content_management/contact_details";
exports.getHelpCenterContactTopicsAPIEndPoint = "bx_block_content_management/help_desk_topics";
exports.sendHelpCenterContactRequestAPIEndPoint = "bx_block_content_management/help_requests";
exports.getTncApiEndPoint = "bx_block_content_management/content_managements/terms_and_condition";
exports.getPPApiEndPoint = "bx_block_content_management/content_managements/privacy_policy";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End