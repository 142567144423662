import React from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  TypographyProps,
  Link,
  IconButton,
  InputAdornment,
  TextField,
  AppBar,
  Tabs,
  Tab
} from "@material-ui/core";
import SideBar from "../../../components/src/SideBar";
import Header from "../../../components/src/Header.web";
import Footer from "../../../components/src/Footer";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
//@ts-ignore
// @ts-ignore
// @ts-ignore
import Loader from "../../../components/src/Loader.web";
import StudiosBookingListController, {
  Props
} from "./StudiosBookingListController";
import { Filtericon } from "../../../components/src/assets";
import SearchIcon from "@material-ui/icons/Search";
import "./studiosbooking.css";
import StudioBookingCard from "../../../components/src/StudioBookingCard";
import StudioMyBookingsMyRequestsCard from "../../../components/src/StudioMyBookingsMyRequestsCard";
import Pagination from "@material-ui/lab/Pagination";
import StudioBookingFilterModel from "./StudioBookingFilterModel.web";
import CustomNotification from "../../../components/src/CustomNotification";

const theme = createTheme({
  palette: {
    primary: {
      main: "#F5C42C",
      contrastText: "#fff"
    }
  },
  overrides: {
    MuiSelect: {
      select: {
        "&:focus": {
          border: "1px solid #F5C42C",
        }
      }
    }
  }
});

export default class StudiosBookingList extends StudiosBookingListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderStudiosBookingListMainContent = (sideBarStatus: boolean = this.state.isSideBarOpen) => {
    return <>
      <Loader loading={this.state.isLoading}></Loader>
      <Grid style={webStyle.contentSetup} >
        <Header handleSideBar={this.handleSideBar}
          dropDownStatus={this.state.isDropdownOpen}
          navigate={this.props.navigation.navigate}
          sideBarStatus={sideBarStatus}
          handleDropdown={this.handleDropdown}
        />
        <Grid style={webStyle.mainContent as React.CSSProperties}>
          <Box sx={webStyle.blockInfo}>
            <Box>
              <Typography variant="h3" style={webStyle.activeBlock as TypographyProps}>Studios & Booking</Typography>
              <Typography variant="h6" style={webStyle.blockPath as TypographyProps}>
                <span style={webStyle.highlightedText}>
                  <Link data-test-id="homeLink" color="inherit" onClick={() => this.handleNavigation("JobProfile")}>
                    Home
                  </Link>
                </span>
                {" "}/ Studios & Booking
              </Typography>
            </Box>
            {
              this.state.tabValue === 0 ?
                <Box sx={{ display: "flex" }}>
                  <Box data-test-id="studiosFilter" onClick={this.openStudioFilter} style={webStyle.bellIconBackground}>
                    <img src={Filtericon} style={{ width: "14px", height: "16px" } as React.CSSProperties} />
                  </Box>
                  <Box sx={{ minWidth: "286px" }}>
                    <TextField
                      data-test-id="studiosSearchInput"
                      onChange={this.handleSearchStudios}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton style={webStyle.iconButton}>
                              <SearchIcon style={webStyle.searchIcon} />
                            </IconButton>
                          </InputAdornment>
                        ),
                        style: {
                          borderRadius: "22px",
                          backgroundColor: "#F8FAFE",
                          padding: "0px",
                          height: "44px",
                          fontSize: "14px",
                          color: "#6d89af",
                        } as React.CSSProperties
                      }}
                      variant="outlined"
                      fullWidth
                      placeholder="Search studios"
                    />
                  </Box>
                </Box> : null}
          </Box>
          <Grid item sm={12} xl={12} xs={12} md={12} lg={12}>
            <AppBar
              position="static"
              className="appbar app_bar"
              color="transparent"
            >
              <Tabs
                value={this.state.tabValue}
                data-test-id="tabChange"
                variant="scrollable"
                onChange={this.handleAppBarValue}
                TabIndicatorProps={{ style: { height: '2px', background: "orange" } as React.CSSProperties }}
                scrollButtons="on"
                className="tabs_main"
                aria-label="scrollable prevent tabs example"
              >
                <Tab className={this.state.tabValue == 0 ? "active_btn" : "non_active_btn"} label="All Studios" />
                <Tab className={this.state.tabValue == 1 ? "active_btn" : "non_active_btn"} label="My Bookings" />
                <Tab className={this.state.tabValue == 2 ? "active_btn" : "non_active_btn"} label="My Requests" />
              </Tabs>
            </AppBar>
          </Grid>
          <Grid container spacing={3} style={{ marginTop: "12px" }}>
            {this.state.tabValue === 0 && (
              <Grid container spacing={3} style={{ paddingLeft: "12px", paddingRight: "12px" }}>
                {this.state.allStudiosData.length ? this.state.allStudiosData.map((studio: any, index: number) => (
                  <Grid onClick={() => this.navigateToDetailPage(studio.id)} data-test-id={`${studio.id}_studiolist`} item xs={12} sm={6} md={4} key={studio.id}>
                    <StudioBookingCard data={studio} />
                  </Grid>
                ))
                  : <Typography style={{ width: '100%', textAlign: 'center', marginTop: '30px', marginBottom: '30px', color: '#6d89af', fontFamily: 'Urbanist', fontSize: 14, fontWeight: 600 }}>No record available</Typography>
                }
              </Grid>)
            }
            {this.state.tabValue === 1 && (
              <Grid container spacing={3} style={{ paddingLeft: "12px", paddingRight: "12px" }}>
                {this.state.myBookingsData.length ? this.state.myBookingsData.map((studio: any, index: number) => (
                  <Grid data-test-id={`${studio.id}_studiolist`} item xs={12} sm={6} md={4} key={studio.id}>
                    <StudioMyBookingsMyRequestsCard getFormattedDate={this.getFormattedDate} type="myBookings" data={studio} cancelBookingAPICall={this.cancelBookingAPICall} leaveStudioAPICall={this.leaveStudioAPICall} />
                  </Grid>
                ))
                  : <Typography style={{ width: '100%', textAlign: 'center', marginTop: '30px', marginBottom: '30px', color: '#6d89af', fontFamily: 'Urbanist', fontSize: 14, fontWeight: 600 }}>No record available</Typography>
                }
              </Grid>)
            }
            {this.state.tabValue === 2 && (
              <Grid container spacing={3} style={{ paddingLeft: "12px", paddingRight: "12px" }}>
                {this.state.myRequestsData.length ? this.state.myRequestsData.map((studio: any, index: number) => (
                  <Grid data-test-id={`${studio.id}_studiolist`} item xs={12} sm={6} md={4} key={studio.id}>
                    <StudioMyBookingsMyRequestsCard getFormattedDate={this.getFormattedDate} type="myRequests" data={studio} withdrawRequestAPICall={this.withdrawRequestAPICall} requestAgainAPICall={this.requestAgainAPICall} />
                  </Grid>
                ))
                  : <Typography style={{ width: '100%', textAlign: 'center', marginTop: '30px', marginBottom: '30px', color: '#6d89af', fontFamily: 'Urbanist', fontSize: 14, fontWeight: 600 }}>No record available</Typography>
                }
              </Grid>)
            }
          </Grid>
          {this.state.getCountForPagination[this.state.tabValue] ?
            (<Grid container spacing={2}>
              <Grid item xs={12}>
                <Pagination style={{ marginTop: "30px", justifyContent: "center", display: "flex" }} data-test-id="pagination" count={Math.ceil((this.state.getCountForPagination[this.state.tabValue]) / 9)} page={this.state.currentStudiosPage} onChange={this.handlePageChange} />
              </Grid>
            </Grid>)
            : null}
        </Grid >
      </Grid >
      <Footer />
    </>
  }
  // Customizable Area End

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Container style={webStyle.parentDiv} >
          {
            this.state.isSideBarOpen ? (
              <Grid spacing={0} container >
                <Grid item xs={3} style={webStyle.sidebarParent} >
                  <SideBar
                    handleSideBar={this.handleSideBar}
                    userData={{ name: "user name" }}
                    activeMenuItem={"Studios & Booking"}
                    navigate={this.props.navigation.navigate}
                  />
                </Grid>
                <Grid item style={{ display: "flex", flexDirection: "column", flexBasis: "77.75%", maxWidth: "77.75%" }} xs={9} >
                  {this.renderStudiosBookingListMainContent()}
                </Grid>
              </Grid>
            ) : (
              <Grid item style={{ display: "flex", flexDirection: "column" }} xs={12}>
                {this.renderStudiosBookingListMainContent()}
              </Grid>
            )
          }
          {this.state.isModelOpen ? <StudioBookingFilterModel navigation="" filterValues={this.state.filterValues} storeFilterValues={this.handleStoreFilterValues} handleClose={this.handleModelClose} id="" open={this.state.isModelOpen} phList={this.state.phList} statesData={this.state.statesData} handleFilterAPICall={this.handleFilterAPICall} /> : null}
        </Container>
        <CustomNotification navigation={(route: string) => this.props.navigation.navigate(route)} notification={this.state.notification} handleCloseNotification={this.handleCloseNotification} />
      </ThemeProvider>
    );
  }
}

const webStyle = {
  parentDiv: {
    padding: "0px",
    maxWidth: "none"
  },
  sidebarParent: {
    minHeight: "100vh",
    maxWidth: "22.25%",
  },
  contentSetup: {
    // minHeight: "90vh",
    borderLeft: "1px solid #E3EAF4",
    flex: 1,
  },
  container: {
    marginTop: "12px"
  },
  mainContent: {
    width: "100%",
    minHeight: "100vh",
    backgroundColor: "#F8FAFE",
    boxSizing: "border-box",
    padding: "24px"
  },
  blockInfo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap"
  },
  highlightedText: {
    fontSize: 14,
    color: "#F5C42C",
    fontWeight: 600,
    fontFamily: "Urbanist",
    cursor: "pointer",
    textDecoration: "underline"
  },
  bellIconBackground: {
    width: "42px",
    height: "42px",
    backgroundColor: "#F8FAFE",
    border: "1px solid #DADADA",
    borderRadius: "22px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    marginRight: "16px",
  },
  iconButton: {
    margin: "0px",
    padding: "0px",
    marginLeft: "15px"
  },
  searchIcon: {
    color: "#6d89af",
    width: "22px",
    height: "22px"
  },
  activeBlock: {
    fontSize: "28px",
    lineHeight: "34px",
    letterSpacing: "1px",
    color: "#17181D",
    fontWeight: "700",
  },
  blockPath: {
    letterSpacing: "1px",
    fontSize: "14px",
    marginBottom: "15px",
    color: "#6D89AF",
    paddingBottom: "3px",
    fontWeight: 500,
    paddingTop: "8px",
    lineHeight: "17px",
  }
};
