import React from "react";

import {
    // Customizable Area Start
    Container,
    Grid,
    Box,Typography,TypographyProps,
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import AllStudioViewController, { Props } from "./AllStudioViewController";
import Header from "../../../components/src/Header.web";
import SideBar from "../../../components/src/SideBar";
import Footer from "../../../components/src/Footer";
import { arrowicon } from "./assets";
import AllStudioDetailCard from "../../../components/src/AllStudioDetailCard";
// Customizable Area End

export default class UserStudioDetail extends AllStudioViewController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    renderMainContentAllStudioProfile(sideBarStatus: boolean) {
        return <Grid style={webStyle.contentSetup}>
            < Header
                sideBarStatus={this.state.isSideBarOpen}
                dropDownStatus={this.state.isDropdownOpen}
                handleSideBar={this.handleSideBar}
                handleDropdown={this.handleDropdown}
                navigate={this.props.navigation.navigate}
            />
            <Grid style={webStyle.mainContent as React.CSSProperties}>
                <Box sx={webStyle.blockInfo}>
                    <Box sx={webStyle.activeBlock}>
                        <Typography variant="h3" style={webStyle.activeBlock as TypographyProps}><div style={{ fontSize: "15px" }} onClick={()=> this.handleNavigation("AllStudioView")}> <img src={arrowicon} alt="arrow icon" /> </div>Studio Details</Typography>
                    </Box>
                    <Typography variant="h6" style={webStyle.blockPath as TypographyProps}><span style={webStyle.highlightedText} onClick={() => this.handleNavigation("Dashboard")}>Home</span> / Studios / Studio Details</Typography>
                </Box>
                <AllStudioDetailCard data={this.state.allStudioDetailData} datavalue={this.state.allStudioDetailRatingandReview} handleStudioBadgeVisibility={this.handleStudioBadgeVisibility}/>
            </Grid>
            <Footer />
        </Grid>
    }
    
    render() {
        return ( 
            <Container maxWidth="xl" className="user_detail_container">
                {
                    this.state.isSideBarOpen?
                        <Grid container spacing={0}>
                            <Grid item xs={3} style={webStyle.sideBar1}>
                                <SideBar
                                    activeMenuItem={"All Studios"}
                                    handleSideBar={this.handleSideBar}
                                    navigate={this.props.navigation.navigate}
                                    userData={{ age: 25 }}
                                    />
                            </Grid>
                            <Grid item xs={9}>{this.renderMainContentAllStudioProfile(this.state.isSideBarOpen)}</Grid>
                        </Grid> :
                        <Grid item xs={12}>
                            {
                                this.renderMainContentAllStudioProfile(this.state.isSideBarOpen)
                            }

                        </Grid>
                }
            </Container >
        );
    }
}

// Customizable Area Start
const webStyle = {
    sideBar1: {
        minHeight: "100vh",
        maxWidth: "23%",
    },
    contentSetup: {
        borderLeft: "1px solid #E3EAF4",
    },
    container: {
        paddingRight: '0px !important',
        paddingLeft: '0px !important',
    },
    blockInfo: {

    },
    blockPath: {
        fontSize: "14px",
        letterSpacing: "1px",
        color: "#6D89AF",
        marginBottom: "15px",
        fontWeight: 500
      },
      highlightedText: {
        color: "#F5C42C",
        textDecoration: 'underline',
        textDecorationColor: "#F5C42C",
        fontWeight: 600
      },
    activeBlock: {
        fontSize: "28px",
        fontWeight: "600",
        letterSpacing: "1px",
        display: "flex",
        flexDirection: "row",
        lineHeight: "33px",
        alignItems: 'center',
        gap: '12px',
        marginBottom: '8px',   
      },
     
      mainContent: {
        width: "100%",
        backgroundColor: "#F8FAFE",
        boxSizing: "border-box",
        padding: "24px"
      },
};
// Customizable Area End