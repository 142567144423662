import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ChangeEvent } from "react";
// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;

    // Customizable Area Start
    // Customizable Area End
}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    isSideBarOpen: boolean;
    isDropdownOpen: any;
    isSideBarOpen1: boolean;
    count: number;
    allinvoiceData: any;
    getprojectlistfilter:any;
    currentpage: number;
    searchValue: string;
    open: boolean;
    open1:boolean;
    open2:boolean;
    open3:boolean;
    selectstatus: string;
    selectstatus1: string;
    approvecount:number;
    showandhidden:boolean;
    pendingcount:number;
    completedcount:number;
    allcount:number;
    isLoading:boolean;
    invoicedetail:any;
    onhold:any;
    rejected:any;
    allprojectData:any;
    image:string
    invoiceselect:string;
    selectproject:any;
    selectinvoicetype:string;
    selectinvoicename:string;
    selectinvoicedetail:string;
    selectinvoiceno:string;
    selectinvoiceamount:string;
    modelType:string;
    isModelOpen: boolean;
    raiseinvoiceDatavalue:any;
    raiseinvoicedetail:any;
    jobroledatalist:any;
   departmentlist:any;
    amountdatalist:any;
  raiseinvoicetype:any;
  jobrolevalue:string;
  departmentvalue:string;
  statusvalue:string;
  amountvalue:string;
  countApproved:number;
  countcomplete:number;
  counthold:number;
  countreject:number;
  countall:number;
  countpending:number;
  projectvalue:string;
  ProjectName:any;
  
  
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class JobProfileController extends BlockComponent<
    Props,
    S,
    SS
> {

    getInvoiceListApiCalledId: string = "";
    getAppliedinvoicecallid: string = "";
    getalljobcallid: string = "";
    getInvoiceSearchApiId: string = "";
    countinvoiceCallId:string=""
    getinvoiceDetailApiCalledId:string="";
    getProjectdataid:string="";
    raiseinvoiceapicallid:string="";
    getjobroleListapiid:string=""
    getdepartmentListapiid:string=""
    getamountListapiid:string=""
    getraiseinvoiceapiid:string=""
    getProjectinvoiceApiCalledId:string=""
    getprojectlistcallid:string=""
    getFilterapplycallid:string="";
    reclaimInvoiceCallTd: string = "";

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),

            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),

        ];

        this.state = {
            isSideBarOpen: true,
            isSideBarOpen1: true,
            isDropdownOpen: false,
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            count: 0,
            showandhidden:true,
            allinvoiceData: [],
            getprojectlistfilter:[],
            allprojectData:[],
            currentpage: 1,
            searchValue: "",
            open: false,
            open1:false,
            open2:false,
            open3:false,
            selectstatus: '',
            selectstatus1: '',
            approvecount:0,
            pendingcount:0,
            completedcount:0,
            allcount:0,
            isLoading:false,
            invoicedetail:[],
            onhold:0,
            rejected:0,
            image:"",
            invoiceselect:"",
            selectproject:0,
            selectinvoicetype:'',
            selectinvoicename:"",
            selectinvoicedetail:'',
            selectinvoiceno:'',
            selectinvoiceamount:'',
            modelType:'',
            isModelOpen: false,
            raiseinvoiceDatavalue:[],
            raiseinvoicedetail:[],
            jobroledatalist:[],
            departmentlist:[],
            amountdatalist:[],
            raiseinvoicetype:null,
            jobrolevalue:'',
  departmentvalue:'',
  statusvalue:'',
  amountvalue:'',
  countApproved:0,
  countcomplete:0,
  counthold:0,
  countreject:0,
  countall:0,
  countpending:0,
  projectvalue:'',
  ProjectName:''
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    async receive(from: string, message: Message) {

        if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
            let msgvalue = message.getData(getName(MessageEnum.AuthTokenDataMessage));

            this.showAlert(
                "Change Value",
                "From:" + this.state.txtSavedValue + "To:" + msgvalue
            );

            this.setState({ txtSavedValue: msgvalue });
        }

        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestvalue = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))

            const responseValue = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))

            if (apiRequestvalue === this.getInvoiceListApiCalledId) {
                
             
                this.setState({ allinvoiceData: responseValue.data ,count:responseValue.meta.total_invoices})   

                this.setState({ 
                        
                        isLoading:false
                })
            }
           else if (apiRequestvalue === this.getInvoiceSearchApiId) {
            
                this.setState({ allinvoiceData: responseValue.data })
                this.setState({
                     count: responseValue.meta.total_invoices,
                     isLoading:false,
                    
                })
            }
          
           else if(apiRequestvalue===this.getAppliedinvoicecallid)
            {
                 this.setState({
                    allinvoiceData: responseValue.data ,
                    count:responseValue.meta.total_invoices,
                    isLoading:false
                 })        
                  }
           else if(apiRequestvalue===this.getinvoiceDetailApiCalledId)
           {
               if(responseValue.data)
               {
                this.setState({invoicedetail:responseValue.data})
               }
               this.setState({isLoading: false});
           }
            else if(apiRequestvalue===this.getProjectdataid)
            {
                this.setState({ allprojectData: responseValue.data })    
            }
            else if(apiRequestvalue===this.raiseinvoiceapicallid)
            {
                this.raiseinvoiceresponsedataList(responseValue)
                  
            }
          
           else{
           this.apiresponsefunctionality(apiRequestvalue,responseValue)
           }
        }
    }
    apiresponsefunctionality=async(apiRequestvalue:any,responseValue:any)=>{

        if(apiRequestvalue===this.getraiseinvoiceapiid)
        {
         this.getraiseinvoicedatalist(responseValue)
        }
        else if(apiRequestvalue===this.getProjectinvoiceApiCalledId){
         console.log("getProjectinvoiceApiCalledId responseValue",responseValue)
         this.setState({allinvoiceData:responseValue.data})
         this.setState({ 
                     count:responseValue.meta.total_invoices,
             isLoading:false
     })}
     else if(apiRequestvalue===this.getjobroleListapiid)
     {
      this.getjobroledatalist(responseValue)
     }
     else if(apiRequestvalue===this.getdepartmentListapiid)
     {
      this.getdepartmentdatalist(responseValue)
     }
     else if(apiRequestvalue===this.getamountListapiid)
     {
      this.getamountdatalist(responseValue)
     }
    else if(apiRequestvalue===this.getprojectlistcallid)
        {
          console.log("project project",responseValue)
          this.setState({
             getprojectlistfilter:responseValue.projects
          })
        

        }
        else if(apiRequestvalue===this.getFilterapplycallid)
        {
           this.setState({ allinvoiceData: responseValue.data ,count:responseValue.meta.total_invoices})   
           this.setState({ 
                   isLoading:false
           })
        }
        else if(apiRequestvalue===this.reclaimInvoiceCallTd)
        {
          if(responseValue.data) {
            this.handleOpen3();
          }
          this.setState({isLoading: false});
        }
    }


    

    getjobroledatalist=async(responseValue:any)=>{
        this.setState({jobroledatalist:responseValue.job_roles})
    }
    getdepartmentdatalist=async(responseValue:any)=>{
        this.setState({departmentlist:responseValue.departments})
 }
 getamountdatalist=async(responseValue:any)=>{
    
        this.setState({amountdatalist:responseValue.invoice_amounts})
    
}
getraiseinvoicedatalist=async(responseValue:any)=>{
    this.setState({
        countApproved:responseValue.status_counts.approved,
        countcomplete:responseValue.status_counts.completed,
        counthold:responseValue.status_counts.holded,
        countpending:responseValue.status_counts.pending,
        countreject:responseValue.status_counts.rejected,
       countall:responseValue.all,
       count:responseValue.all,
        raiseinvoicetype:responseValue.status_counts
    })
}
    raiseinvoiceresponsedataList = async (responseValue: { errors: { token: string }[], data: any }) => {
        
        this.handleModelOpen();
          if (responseValue.data) {
            
            this.setState({raiseinvoicedetail:responseValue.data})
           setStorageData("id",responseValue.data.id)
           setStorageData("typeofinvoice",responseValue?.data?.attributes?.type_of_invoice)
           this.handleClose1()
           console.log("id",responseValue.data.id)
            this.getInvoiceListApiCalledId = await this.apiCallinvoicelist(configJSON.GET_Method, configJSON.getallinvoicelistEndPoint +`&page=1`);
            this.setState({ modelType: "successModel" });
            
          } else if (responseValue.errors[0]) {
            this.setState({open1:false})
            alert(responseValue.errors[0])
          }
      
         
      
      
          this.setState({ isLoading: false });
    
      };


      
  handleModelOpen = () => {
    this.setState({ isModelOpen: true });
  }
  handlehiddenandshow = () => {
    this.setState({
      showandhidden: !(this.state.showandhidden)
    });
    console.log("dddddd",this.state.showandhidden)
  };

  handleModelClose = () => {
    this.setState({ isModelOpen: false, modelType: "" });
  }

    txtInputWebProps1 = {
        onChangeText: (text: string) => {
            this.setState({ txtInputValue: text });
        },
        secureTextEntry: false,
    };
    setInputValue1 = (text: string) => {
        this.setState({ txtInputValue: text });
    };
    txtInputMobileProps1 = {
        ...this.txtInputWebProps1,
        autoCompleteType: "email",
        keyboardType: "email-address",
    }
    doButtonPressed1() {
        let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
        msg.addData(
            getName(MessageEnum.AuthTokenDataMessage),
            this.state.txtInputValue
        );
        this.send(msg);
    }


    setEnableField1 = () => {
        this.setState({ enableField: !this.state.enableField });
    };

    // Customizable Area Start

     handleChangeinvoice = (event: any) => {
        this.setState({invoiceselect:event.target.value});
    };
    handleChangeProject = (event: any) => {
        this.setState({selectproject:event.target.value});
    };

     handleChangeradio = (event: any) => {
        this.setState({selectinvoicetype:event.target.value})
    };
    handleChangeName = (event: any) => {
        this.setState({selectinvoicename:event.target.value})
    };
    handleChangeDetail = (event: any) => {
        this.setState({selectinvoicedetail:event.target.value})
    };
    handleChangeNo = (event: any) => {
        this.setState({selectinvoiceno:event.target.value})
    };
    handleChangeAmount = (event: any) => {
      const numericValue = event.target.value.replace(/[^0-9]/g, '');
        this.setState({selectinvoiceamount:numericValue})
    };
    async componentDidMount() {
       // setStorageData("sidebarvalue",false)
        const urlParams = new URLSearchParams(window.location.search);
        console.log("urlParams",urlParams)
        const projectName = urlParams.get('projectname')
        this.getInviceDataByName()
        if(projectName===null)
        {
            this.getmyinvoiceList()

        }
        
        this.countValues()
        this.getinvoiceDetails();
        this.getProjectForTask();
         this.getinvoiceraisemytask()
         this.getinvoiceamountlistmytask();
         this.getdepartmentlistmytask();
         this.getjobrolelistmytask()
         this.getprojectlistmyinvoice()
        window.scrollTo(0, 0);
        let isSideBarOpen=await getStorageData("sidebarvalue");
        this.setState({isSideBarOpen:JSON.parse(isSideBarOpen)})
        
    }

    handleImage = async(e: any) => {
        this.setState({ image: e.target.files[0] });
       
      };
    handleOpen = () => {
        this.setState({ open: true })
    
    }
    handleClose = () => {
        this.setState({ open: false })
        
    };
    handleOpen1 = () => {
        this.setState({ open1: true })
    }
    handleClose1 = () => {
        this.setState({ open1: false })

    };
  
    handleOpen2 = () => {
        this.setState({ open2: true })
    }
    handleClose2 = () => {
        this.setState({ open2: false })

    };
    handleOpen3=()=>{
        this.setState({open3:true})
        console.log("open......",this.state.open3)
    }
    handleClose3=()=>{
        this.setState({open3:false})
    }

    handleSideBar =  () => {
        this.setState((prevState) => ({
          isSideBarOpen: !prevState.isSideBarOpen
        }), async() => {
          setStorageData("sidebarvalue",this.state.isSideBarOpen.toString())
          
        });
      };

    navigateToinvoiceDetail = (id:any) => {
        this.getinvoiceDetails()
        this.props.navigation.navigate("UserinvoiceDetail");
        setStorageData("id", id)   
        
      };
      handledatavalue = (id:any) => {
        this.getinvoiceDetails()
        this.props.navigation.navigate("UserinvoiceDetail");
        setStorageData("id", id)   
        
      };
    handleSideBar1 = () => {

        this.setState({
            isSideBarOpen1: !(this.state.isSideBarOpen1)
        });
    };
    handleDropdown = () => {
        this.setState({
            isDropdownOpen: !this.state.isDropdownOpen
        });
    };







    handleNavigation = (page: string) => {
        this.props.navigation.navigate(page)
    };

    getmyinvoiceList = async () => {

        this.getInvoiceListApiCalledId = await this.apiCallinvoicelist(configJSON.GET_Method, configJSON.getallinvoicelistEndPoint + `&page=1`);
    };



    handlePageChange = async (event: any, value: number) => {
        if (this.state.searchValue) {
            this.getInvoiceSearchApiId = await this.apiCallinvoicelist(configJSON.GET_Method, configJSON.getinvoicesearchEndPoint + `=${this.state.searchValue}&page=${value}&per_page=9`);
            window.scrollTo(0, 0);
        }
        else if(this.state.selectstatus||this.state.selectstatus1)
        {
            this.getAppliedinvoicecallid = await this.apiCallinvoicelist(configJSON.GET_Method, configJSON.sortapiinvoiceendpoint + `&invoice_type=${this.state.selectstatus1}&status=${this.state.selectstatus}&page=${value}&per_page=9`);
            window.scrollTo(0, 0);
        }
        
        else if (this.state.jobrolevalue||this.state.amountvalue||this.state.statusvalue||this.state.departmentvalue||this.state.projectvalue)
        {
           this.getFilterapplycallid= await this.apiCallinvoicelist(configJSON.GET_Method, configJSON.getfilterendpointmyinvoice +`&job_role=${this.state.jobrolevalue}&project=${this.state.projectvalue}&department=${this.state.departmentvalue}&amount=${this.state.amountvalue}&status=${this.state.statusvalue}&per_page=9&page=${value}`);
        }
        else if(this.state.ProjectName)
        {
            this.getProjectinvoiceApiCalledId = await this.apiCallinvoicelist(configJSON.GET_Method, configJSON.getprojectinvoiceendpoint + `project=${this.state.ProjectName}&page=${value}&per_page=9`);
      
        
        }
        else {

            this.getInvoiceListApiCalledId = await this.apiCallinvoicelist(configJSON.GET_Method, configJSON.getallinvoicelistEndPoint +`&page=${value}`);
            window.scrollTo(0, 0);
        }

        this.setState({ currentpage: value });

    };
    filterHouses = async (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            currentpage: 1,
            searchValue: event.target.value,
        });
        if (event.target.value)
            this.getInvoiceSearchApiId = await this.apiCallinvoicelist(configJSON.GET_Method, configJSON.getinvoicesearchEndPoint + `=${event.target.value}&page=1&per_page=9`);
       
        else
            this.getInvoiceListApiCalledId = await this.apiCallinvoicelist(configJSON.GET_Method, configJSON.getallinvoicelistEndPoint +`&page=1`);
    }
    apiCallinvoicelist = async (method: string, endPoint: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
          this.setState({isLoading:true})
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };



    handleOptionChange = (index: any, state: any) => {
        this.setState({ selectstatus: state })
    };

    handleOptionChange1 = (index: any, state: any) => {
        this.setState({ selectstatus1: state })
    };
   
    
   
    countValues = async () => {
        this.setState({ open: false })

        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };

        this.setState({isLoading:true})
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.countinvoiceCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.countapiinvoiceendpoint

        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.GET_Method
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;

    };
    ClearDataValues = async () => {
        this.setState({open:false})
        this.setState({selectstatus:''})
        this.setState({selectstatus1:""})
       this.getmyinvoiceList();
      };
      ClearDataValuesfilter = async () => {
        this.setState({open2:false})
        this.setState({amountvalue:''})
        this.setState({departmentvalue:''})
        this.setState({projectvalue:''});
        this.setState({jobrolevalue:''})
        this.setState({statusvalue:''})
       this.getmyinvoiceList();
      };
      getinvoiceDetails = async () => {

        let token = await getStorageData("token");
        let id = await getStorageData("id");
      
        this.setState({ isLoading: true });
        const header = {
          "Content-Type": configJSON.exampleApiContentType,
          "token": token
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getinvoiceDetailApiCalledId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getInvoicedetailendpoint + `/${id}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.GET_Method
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
      };
      getProjectForTask = async () => {

        let token = await getStorageData("token");
        let id = await getStorageData("userid");
        const header = {
          "Content-Type": configJSON.exampleApiContentType,
          "token": token
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getProjectdataid = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getProjectTaskApiEndPoint + `/${id}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.GET_Method
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
      };

      raiseinvoiceData = async () => {
        const token = await getStorageData("token",false);   
        const header = {
          "token": token
        };
        this.setState({isLoading:true})
        const formData = new FormData();
        formData.append("invoice[invoice_receipt]",this.state.image) 
        formData.append("invoice[add_note]", this.state.selectinvoicedetail);
        formData.append("invoice[type_of_invoice]", this.state.invoiceselect);
        formData.append("invoice[invoice_name]", this.state.selectinvoicename);
        formData.append("invoice[invoice_id]", this.state.selectinvoiceno);
        formData.append("invoice[total_amount]", this.state.selectinvoiceamount);
        formData.append("invoice[standard_pay]", this.state.selectinvoicetype);
        formData.append("invoice[project_id]",this.state.selectproject);
        
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.raiseinvoiceapicallid = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
       
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.raiseinvoiceendpoint
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          formData
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.raiseinvoicemethodtype

        );
        runEngine.sendMessage(requestMessage.id, requestMessage);   
    }   
    getinvoiceraisemytask = async () => {

        let token = await getStorageData("token");
    
        const header = {
          "Content-Type": configJSON.exampleApiContentType,
          "token": token
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getraiseinvoiceapiid = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.countraiseinvoiceendpoint
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.GET_Method
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      };
      getinvoiceamountlistmytask = async () => {
      
        let token = await getStorageData("token");
        
        const header = {
          "Content-Type": configJSON.exampleApiContentType,
          "token": token
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getamountListapiid = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getamountListendpoint
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.GET_Method
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      };
      getdepartmentlistmytask = async () => {
      
        let token = await getStorageData("token");
        
        const header = {
          "Content-Type": configJSON.exampleApiContentType,
          "token": token
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getdepartmentListapiid = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getdepartmentListendpoint
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.GET_Method
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      };
      getjobrolelistmytask = async () => {
      
        let token = await getStorageData("token");
        
        const header = {
          "Content-Type": configJSON.exampleApiContentType,
          "token": token
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getjobroleListapiid = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getJobroleListendPoint
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.GET_Method
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      };
      getprojectlistmyinvoice = async () => {
      
        let token = await getStorageData("token");
        
        const header = {
          "Content-Type": configJSON.exampleApiContentType,
          "token": token
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getprojectlistcallid = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getprojectlistendpoint
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.GET_Method
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      };
      handleOptionChangejobrole = (index: any, job: any) => {
    
        this.setState({jobrolevalue:job})
        
        
      
        
      };
      handleOptionChangedepartment = (index: any, department: any) => {
          
        this.setState({departmentvalue:department})
        
        
      
        
      };
      handleOptionChangeamount = (index: any, Amount: any) => {
          
        this.setState({amountvalue:Amount})
        
        
      
        
      };
      handleOptionChangeproject= (index: any, projectname: any) => {
          
        this.setState({projectvalue:projectname})
        
        
      
        
      };
      handleOptionChangestatus = (index: any, status: any) => {
          
        this.setState({statusvalue:status})
        
        
    
      };

      getInviceDataByName=async()=>{
        const urlParams = new URLSearchParams(window.location.search);
        console.log("urlParams",urlParams)
        const projectName = urlParams.get('projectname')

        this.setState({ProjectName:projectName})
        if(projectName){
            this.getProjectinvoiceApiCalledId = await this.apiCallinvoicelist(configJSON.GET_Method, configJSON.getprojectinvoiceendpoint + `project=${projectName}&page=1&per_page=9`);
      
        }

      }

     
      handleChange = async () => {
        this.setState({ open: false })

        
        this.setState({
          
          currentpage: 1,
      
        })
        
          

            this.getAppliedinvoicecallid = await this.apiCallinvoicelist(configJSON.GET_Method, configJSON.sortapiinvoiceendpoint + `&invoice_type=${this.state.selectstatus1}&status=${this.state.selectstatus}&page=1&per_page=9`);
      
          
          
      };
      handleChangeapply = async () => {
        this.setState({ open2: false })

    
        
        this.setState({
          
          currentpage: 1,
      
        })
      
         this.getFilterapplycallid= await this.apiCallinvoicelist(configJSON.GET_Method, configJSON.getfilterendpointmyinvoice +`&job_role=${this.state.jobrolevalue}&project=${this.state.projectvalue}&department=${this.state.departmentvalue}&amount=${this.state.amountvalue}&status=${this.state.statusvalue}&per_page=9&page=1`);
      
    }   

    reclaimInvoiceAPICall = async () => {
      const id = await getStorageData("id");
      setStorageData("reclaimModelType", "successReclaim");
      this.reclaimInvoiceCallTd= await this.apiCallinvoicelist(configJSON.put_method, configJSON.reclaimInvoiceEndPoint + `${this.state.invoicedetail.attributes.project_department_id}?invoice_id=${id}`);
    }

    viewStatusUpdateDetails = async () => {
      this.getinvoiceDetails();
    }
    // Customizable Area End
}
