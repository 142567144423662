import React from "react";

import {
  // Customizable Area Start
  Container,
  Box,
  Typography,
  Grid,
  TypographyProps,
  Link,
  AppBar,
  Tabs,
  Tab,
  Select,
  MenuItem,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import FormControl from '@material-ui/core/FormControl';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import SideBar from "../../../components/src/SideBar";
import Header from "../../../components/src/Header.web";
import "./forecastingandbudgeting.css";
import ViewBalanceSheetController, { Props } from "./ViewBalanceSheetController";
import CrewCountView from "./CrewCountView.web";
import SummaryView from "./SummaryView.web";
import Footer from "../../../components/src/Footer";
import { ICategory, ISubCategory } from "./ManageBalanceSheetController";
import Pagination from "@material-ui/lab/Pagination/Pagination";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

export default class ViewBalanceSheet extends ViewBalanceSheetController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderSubCategoryInputsRows(subCategory: any, index: number, subCategoryRowsCount: number) {
    return <Box key={subCategory.id} className={!((index + 1) % 4) && index !== 0 ? "category_main_box category_last_child" : "category_main_box"} style={{ alignItems: 'center', paddingLeft: '12px', display: 'flex', justifyContent: 'space-between', paddingRight: 20 }}>
      <Box style={{ width: 202 }}>
        <Grid container>
          <Box className={!(index % 4) && index !== 1 ? `category_left_box ${this.getClassForLastElement(index, subCategoryRowsCount)}` : "category_left_box"} style={webStyle.categoryLeftBox as React.CSSProperties}>
            <Typography style={{ ...webStyle.categoryLeftBoxText, paddingLeft: 8 } as React.CSSProperties}>{subCategory.attributes.name}</Typography>
          </Box>
          <span style={{ ...webStyle.categoryLeftBoxText, paddingLeft: 12, fontWeight: 600, display: 'flex', alignItems: 'center' } as React.CSSProperties} >{subCategory.attributes.budget_details.data?.attributes.vendor_name}</span>
        </Grid>
      </Box>
      <Box key="contact" style={{ width: 63 }} className="categoryInput">
        <Typography style={{ ...webStyle.categoryLeftBoxText, fontWeight: 600 } as React.CSSProperties}>{subCategory.attributes.budget_details.data?.attributes.contact_number}</Typography>
      </Box>
      <Box key="peoplecount" style={{ width: 71 }} className="categoryInput">
        <Typography style={{ ...webStyle.categoryLeftBoxText, fontWeight: 600 } as React.CSSProperties}>{subCategory.attributes.budget_details.data?.attributes.no_of_pepole}</Typography>
      </Box>
      <Box key="charges" style={{ width: 92 }} className="categoryInput">
        <Typography style={{ ...webStyle.categoryLeftBoxText, fontWeight: 600 } as React.CSSProperties}>{subCategory.attributes.budget_details.data?.attributes.per_day_or_shift_charge}</Typography>
      </Box>
      <Box key="shift" style={{ width: 81 }} className="categoryInput">
        <Typography style={{ ...webStyle.categoryLeftBoxText, fontWeight: 600 } as React.CSSProperties}>{subCategory.attributes.budget_details.data?.attributes.days_or_shift}</Typography>
      </Box>
      <Box key="conveyance" style={{ width: 66 }} className="categoryInput">
        <Typography style={{ ...webStyle.categoryLeftBoxText, fontWeight: 600 } as React.CSSProperties}>{subCategory.attributes.budget_details.data?.attributes.conveyance}</Typography>
      </Box>
      <Box key="wipcost" style={{ width: 48 }} className="categoryInput">
        <Typography style={{ ...webStyle.categoryLeftBoxText, fontWeight: 600 } as React.CSSProperties}>{subCategory.attributes.budget_details.data?.attributes.wip_cost}</Typography>
      </Box>
      <Box key="cashrequirement" style={{ width: 99 }} className="categoryInput">
        <Typography style={{ ...webStyle.categoryLeftBoxText, fontWeight: 600 } as React.CSSProperties}>{subCategory.attributes.budget_details.data?.attributes.cash_remont}</Typography>
      </Box>
      <Box key="actualcost" style={{ width: 48 }} className="categoryInput">
        <Typography style={{ ...webStyle.categoryLeftBoxText, fontWeight: 600 } as React.CSSProperties}>{subCategory.attributes.budget_details.data?.attributes.actual_cost}</Typography>
      </Box>
    </Box>
  }
  renderCategoriesContent() {
    return <>
      {this.state.categories.length ? this.state.categories.map((category: ICategory, categoryIndex: number) => {
        return <React.Fragment key={categoryIndex + "view categories"}>
          <Typography key={`${categoryIndex}_view_typo`} style={webStyle.categoryNameTitle}>{category.attributes.name}</Typography>
          <Box key={`${categoryIndex}_view_box`} style={{ marginBottom: 28 }}>
            {category.attributes.sub_categories.data.map((subCategory: ISubCategory, index: number) => {
              return (<React.Fragment key={index + "view categoriesRows"}>
                {this.renderSubCategoryInputsRows(subCategory, index, category.attributes.sub_categories.data.length)}
                {this.handleIsShowLine(index, category.attributes.sub_categories.data.length) ? <Box style={{ border: '1px solid #e3eaf4', width: '98%', margin: '7 12 7 12' }}></Box> : null}
                {this.handleIsViewLineShow(index, category.attributes.sub_categories.data.length) ? <Box style={{ border: '1px solid #e3eaf4', width: '98%', margin: '0 12 0 12' }}></Box> : null}
              </React.Fragment>)
            })}
          </Box>
        </React.Fragment>
      }) :
        <Typography style={{ width: '100%', textAlign: 'center', marginTop: '30px', color: '#6d89af', fontFamily: 'Urbanist', fontSize: 14, fontWeight: 600 }}>No record available</Typography>
      }
    </>
  }

  renderViewBudgetSheetContent() {
    return <>
      <Grid container style={{ alignItems: "center", margin: "15px 0" }} justifyContent="space-between">
        <Grid item style={webStyle.departmentHeadingText} >
          {this.state.selectedDepartment.name}
        </Grid>
        <Grid item>
          <Box>
            <FormControl className="departmentDropdown">
              <Select
                id="demo-select-small"
                labelId="demo-select-small-label"
                label="Age"
                value={this.state.selectedDepartment ? this.state.selectedDepartment.id : ""}
                onChange={this.handleSelectDepartmentView}
              >
                {this.state.allDepartmentsOfProject.map((department: { id: number, name: string }, index: number) => {
                  return <MenuItem key={`${index}_department_name`} value={department.id}>{department.name}</MenuItem>
                })}
              </Select>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
      <Box style={webStyle.departmentSheetBox as React.CSSProperties}>
        <Box style={{ width: '100%', overflowX: 'auto' }}>
          <Box style={{ width: '1070', minHeight: '248px', }}>
            <Grid container justifyContent="space-between" style={{ color: '#17181d', fontFamily: 'Urbanist', fontSize: '12px', fontWeight: 700, alignItems: "center", borderBottom: '1px solid #e3eaf4', paddingRight: 20, }}>
              <Grid item> <span style={webStyle.columnName}> Details </span> <span style={{ paddingLeft: 7 }}>Vendor Name</span></Grid>
              <Grid item style={{ maxWidth: 99 }}>Contact No.</Grid>
              <Grid item style={{ maxWidth: 99 }}>No. of people</Grid>
              <Grid item style={{ maxWidth: 99 }}>Per day/shift charges</Grid>
              <Grid item style={{ maxWidth: 99 }}>Day/shift</Grid>
              <Grid item style={{ maxWidth: 99 }}>Conveyance</Grid>
              <Grid item style={{ maxWidth: 99 }}>WIP Cost</Grid>
              <Grid item style={{ maxWidth: 99 }}>Cash Requirement</Grid>
              <Grid item style={{ maxWidth: 99 }}>Actual Cost</Grid>
            </Grid>
            <Box style={{ width: '100%', overflowX: 'auto' }}>
              <Grid style={{ marginBottom: 12 }}>
                {this.renderCategoriesContent()}
              </Grid>
            </Box>
          </Box>
        </Box>
        <Box style={webStyle.totalAmountText as React.CSSProperties}>
          <Box style={{ border: '1px solid #e3eaf4', width: '100%' }}></Box>
          <Typography style={{ margin: '14px 0', color: '#18272a', fontFamily: 'Urbanist', fontSize: 14, fontWeight: 500, lineHeight: '16px' }}>Total Amount: <span style={{ fontFamily: 'Helvetica-Bold', fontSize: 16, fontWeight: 400, color: '#18272a', lineHeight: '16px', marginLeft: 7 }}>₹{this.state.totalAmountByDept}</span></Typography>
          <Box style={{ border: '1px solid #e3eaf4', width: '100%' }}></Box>
          {this.state.categories.length ? <Grid item xs={12}>
            <Pagination style={{ margin: "20px 0", color: '#18272a', fontFamily: 'Urbanist', fontSize: 14, fontWeight: 500, lineHeight: '16px' }} data-test-id="pagination" count={this.state.pageCount} page={this.state.page} onChange={this.handleBudgetSheetPageChange} />
          </Grid> : null}
        </Box>
      </Box>
    </>
  }

  renderMainViewBalanceSheetContent(sideBarStatus: boolean = this.state.isSideBarOpen) {
    return (<>
      <Grid style={webStyle.contentSetup}>
        < Header
          dropDownStatus={this.state.isDropdownOpen}
          sideBarStatus={sideBarStatus}
          handleDropdown={this.handleViewBalanceSheetDropdown}
          handleSideBar={this.handleViewBalanceSheetSideBar}
          navigate={this.props.navigation.navigate}
        />
        <Grid style={webStyle.mainDataContent as React.CSSProperties}>
          <Box sx={webStyle.breadCrumbBox}>
            <Box>
              <Typography style={webStyle.currentBlockTitle as TypographyProps} variant="h3">{this.state.projectName}</Typography>
              <Typography style={webStyle.blockPath as TypographyProps} variant="h6">
                <span style={webStyle.yellowHighlightedText}>
                  <Link color="inherit" data-test-id="homeLink" onClick={
                    this.state.userAccountType === "production_house_account" ?
                    () => this.handleNavigation("Projects") :  () => this.handleNavigation("JobProfile")
                    }>
                      Home /
                  </Link>
                  <Link color="inherit" data-test-id="balanceSheetsLink" onClick={
                     this.state.userAccountType === "production_house_account" ?
                    () => this.handleNavigation("BalanceSheet") :  () => this.handleNavigation("Forecastingandbudgeting")}>
                      {" "}Balance Sheets</Link>
                </span>
                {" "}/ {this.state.projectName}
              </Typography>
            </Box>
          </Box>
          <Grid item lg={12} xl={12} xs={12} sm={12} md={12}>
            <AppBar className="appbar app_bar" position="static" color="transparent">
              <Tabs
                value={this.state.tabValue}
                data-test-id="tabChange"
                variant="scrollable"
                onChange={this.handleAppBarValue}
                TabIndicatorProps={{ style: { background: "orange", height: '2px' } as React.CSSProperties }}
                scrollButtons="on"
                className="tabs_main"
                aria-label="scrollable prevent tabs example"
              >
                <Tab label="Budget" className={this.state.tabValue == 0 ? "active_btn" : "non_active_btn"} />
                <Tab label="Crew Count" className={this.state.tabValue == 1 ? "active_btn" : "non_active_btn"} />
                <Tab label="Summary" className={this.state.tabValue == 2 ? "active_btn" : "non_active_btn"} />
              </Tabs>
            </AppBar>
          </Grid>
          {this.state.tabValue === 0 ? this.renderViewBudgetSheetContent() : null}
          {this.state.tabValue === 1 ? <CrewCountView id={""} navigation={this.props.navigation} /> : null}
          {this.state.tabValue === 2 ? <SummaryView id={""} navigation={this.props.navigation} /> : null}
        </Grid >
      </Grid >
      <Footer />
    </>)
  }
  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container style={webStyle.parentContainerDiv}>
          {this.state.isSideBarOpen ? (<Grid spacing={0} container>
            <Grid style={webStyle.viewSidebarParent} item xs={3}>
              <SideBar
                userData={{ age: 25 }}
                navigate={this.props.navigation.navigate}
                activeMenuItem={"Balance Sheets"}
                handleSideBar={this.handleViewBalanceSheetSideBar} />
            </Grid>
            <Grid item style={{ display: "flex", flexDirection: "column", flexBasis: "77.75%", maxWidth: "77.75%" }} xs={9}>{this.renderMainViewBalanceSheetContent()}</Grid>
          </Grid>) :
            <Grid item xs={12} style={{ display: "flex", flexDirection: "column" }}>{this.renderMainViewBalanceSheetContent()}</Grid>
          }
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  departmentHeadingText: {
    color: "#17181d",
    fontFamily: "Urbanist",
    fontSize: 16,
    fontWeight: 700,
    lineHeight: "24px",
    letterSpacing: "0.24615385px",
    display: "flex",
  },
  categoryLeftBoxText: {
    color: '#17181d',
    fontFamily: 'Urbanist',
    fontSize: 11,
    fontWeight: 700,
    lineHeight: '13px',
  },
  categoryLeftBox: {
    width: 107,
    background: 'rgb(225, 230, 236, 0.3)',
    height: 31,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  categoryNameTitle: {
    height: 14,
    color: '#6d89af',
    fontFamily: 'Urbanist',
    fontSize: 12,
    fontWeight: 600,
    margin: '12px 0 12px 12px'
  },
  totalAmountText: {
    height: 109,
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  columnName: {
    background: '#e1e6ec',
    borderRadius: '7px',
    padding: 11.5,
    margin: '2px 0 0 2px',
    width: 92,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    display: "inline-block",
  },
  parentContainerDiv: {
    padding: "0px",
    maxWidth: "none",
  },
  departmentSheetBox: {
    backgroundColor: "#ffffff",
    borderRadius: "12px",
    width: '100%',
    border: '1px solid #e3eaf4',
    display: 'flex',
    flexDirection: 'column',
  },
  contentSetup: {
    borderLeft: "1px solid #E3EAF4",
    height: '100%',
    flex: 1,
  },
  viewSidebarParent: {
    maxWidth: "22.25%",
    minHeight: "100vh",
  },
  mainDataContent: {
    minHeight: "100vh",
    width: "100%",
    boxSizing: "border-box",
    padding: "24px",
    backgroundColor: "#F8FAFE",
  },
  yellowHighlightedText: {
    fontFamily: 'Urbanist',
    cursor: 'pointer',
    textDecoration: 'underline',
    color: "#F5C42C",
    fontSize: "14px",
    fontWeight: 600,
  },
  breadCrumbBox: {
    justifyContent: "space-between",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  blockPath: {
    fontSize: "14px",
    letterSpacing: "1px",
    color: "#6D89AF",
    fontWeight: 500,
  },
  currentBlockTitle: {
    fontSize: "28px",
    letterSpacing: "1px",
    fontWeight: 700,
    marginBottom: "8px",
    lineHeight: "34px",
  },
};
// Customizable Area End
