import React, { useState } from 'react';
import { Box, Grid, List, ListItem, ListItemText, Divider, Radio, Button, FormControlLabel, styled, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RadioGroup from '@material-ui/core/RadioGroup';
import CloseIcon from '@material-ui/icons/Close';
import { Filtericon } from "./assets";
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';

interface MyProps {
    handleClose: any
    statevalue: any
    handleValue: any
    cityvalue: any
    designationvalue: any
    toggleCheckbox: any;
    selectedValues: any;
    toggleCheckboxdesignation: any;
    selectedValuesRole: any;
    handleChangeFun: any;
    value: any
    handleRadioChange:any;
    handleRadioChange1:any;
    isBlocked:boolean;
    statename:any;
    handleOptionChange:any;
    stateindex:any;
    sortApiCallForAllVendors:any;
    ClearDataValues:any
handleSearchstate:any
handleSearchcity:any

}

const TableGrid = styled(Grid)({
    '& .MuiGrid-root::-webkit-scrollbar': {
        display: 'none',

    },
    '& .MuiGrid-root': {
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none',
        "@media screen and (max-width: 500px)": {
        
            paddingLeft:'10px'
        }
    },
    '& .MuiFormControlLabel-label::-webkit-scrollbar': {
        display: 'none',
    },
    '& .MuiFormControlLabel-label': {
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none',
    },
})
const CustomFormControlLabel = styled(FormControlLabel)({
    '& .MuiFormControlLabel-label': {
        textAlign: 'left',
        color: '#17181d',
        fontWeight: '500',
        fontSize: "14px",
        height: 44,
        overflowY: 'scroll',
        display:'flex',
        alignItems:'center'
    },
    '& .MuiRadio-colorSecondary.Mui-checked': {
        color: '#F5C42C'
    },
    '& .MuiList-padding': {
        paddingTop: 0,
        paddingBottom: 0,
    },
    '&.MuiTypography-body1': {


        fontWeight: 700,

    }
});
const SearchIcon1 = styled(SearchOutlinedIcon)({
    padding: "10px 0 10px 13px",
    "@media(max-width: 500px)": {
        padding: "10px 0 10px 8px",
        fontSize: '18px'
    },
});
const SearchInput1 = styled('input')({
    fontSize: "15px",
    outline: "none",
    border: "none",
    width: "85%",
    padding: "10px 3px",
    backgroundColor: "#F8FAFE",
    "@media(max-width: 500px)": {
        fontSize: '12px',
        overflow: 'hidden',
        width: "70%",
        padding: "10px 0px",
    },
});
const CustomFormControlLabel1 = styled(FormControlLabel)({
    '& .MuiFormControlLabel-label': {
        textAlign: 'left',
        color: '#17181d',
        fontWeight: '500',
        fontSize: "14px",
        height: 44,
        overflowY: 'scroll',
        display:'flex',
        alignItems:'center'
    },
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
        color: '#F5C42C',
        borderColor: 'black'
    },
    '& .MuiIconButton-colorSecondary': {
        borderColor: 'black'
    }

});


const useStyles = makeStyles({
    ParentDiv: {

        
        border: '10px 1px solid #d8d8d8',
        textAlign: 'center',
        width: 700,
        minHeight:'499px',
        "@media screen and (max-width: 500px)": {
        
            width: '400px',
            marginRight: "0px",
        
        }
       
    }
    ,
    root: {
        width: 300,
        "@media screen and (max-width: 500px)": {
        
            width:200,
            paddingLeft:'10px'
        }

    },
    HeaderDiv: {
        fontSize: "20px",
        textAlign: 'center',
        fontWeight: "bold",
        borderBottom: "1px solid #E3EAF4",
        paddingTop: "20px",
        paddingBottom: "20px",
        display: 'flex',
        justifyContent: 'end',
        gap: '295px',
        paddingRight: '20px',
        fontFamily: 'urbanist',



    },
    MainDiv: {
        display: 'flex',
        borderRight: "1px solid #d8d8d8",
        borderBottom: "1px solid #d8d8d8",
    },
    SideDiv: {
        minWidth: "140px",
        borderRight: "1px solid #d8d8d8",
        marginRight: '10px',
        backgroundColor: '#f8fafe',

        '& .MuiList-padding': {
            paddingTop: '0px',
            paddingBottom: '0px',
        }
    },
    main: {
        paddingBottom: "40px",
        paddingRight: '20px'
    },
    SearchDiv: {
        display: "flex",
        border: '1px solid  #6D89AF',
        width: "286px",
        borderRadius: "35px",
        alignItems: "center",
        backgroundColor: "#ffffff",
        overflowX: 'hidden',
        height: "44px",
        marginBottom: '6px',
        "@media screen and (max-width: 500px)": {
            minWidth: "100px",
            width: '216px',
            marginRight: "0px",
            height:'40px'
        }
    },
    TableDiv: {
        marginTop: "16px",
        display: 'flex',
        gridRowGap: '10px',

    },
    buttonGroupDiv: {
        paddingTop: "10px",
        overflowY: 'auto',
        height: '204px'
    },

    FooterDiv: {

        paddingTop: "20px",
        paddingBottom: '20px',
        justifyContent: 'end',
        paddingRight: '10px',
        display: 'flex',
        gap: '5px'


    },
    list1: {
        display: "flex",
        gap: '10px'
    },
    list2: {
        display: "flex",
        gap: '10px'
    },
    list3: {
        display: "flex",
        gap: '10px'
    },
    list4: {
        display: "flex",
        gap: '10px'
    },
    button1: {
        border: '1px solid #d8d8d8',
        height: '43px',
        width: '156px',
        '&.MuiTypography-body1': {

            fontSize: '10px'
        },
    },
    button2: {
        border: '1px solid #d8d8d8',
        height: '45px',
        width: '171px',
        '&.MuiTypography-body1': {

            fontSize: '10px'
        },
    }
    ,
    cityDiv: {
        paddingTop: "10px",
        "@media screen and (max-width: 500px)": {
        
            display:'flex',
            flexDirection:'column'
        }
    },

    button3: {
        cursor: "pointer",
        color: "black",
        backgroundColor: "#ffffff",
        textTransform: "none",
        width: "165px",
        borderRadius: "14px",
        bottom: "16px",
        height: "48px",
        fontWeight: 600,
        fontSize: "14px",
        paddingTop: "10px",
        paddingBottom: "14px",
        marginBottom: "5px",
        fontFamily: "urbanist",
        border: '1px solid #17181d',
    },
    button4: {
        border: '1px solid #d8d8d8',
        width: '156px',
        fontWeight: 600,
        fontSize: '15px',
        borderRadius: "15px",
        height: "43px",
        color: "white",
        backgroundColor: 'black'



    },
    ParentMain: {
        display: 'flex',
        justifyContent: 'center',
        borderRadius: '15px',
        
    }
    ,
    activeText: {
        color: '#f5c42c',
        fontWeight: 600,
        cursor: "pointer",
        fontSize: "14px",
        fontFamily: "urbanist",
        lineHeight: "19px",
        backgroundColor: "#ffffff",
        borderColor: '#979797',
        '&.MuiListItem-gutters': {
            paddingLeft: '16px',
            paddingRight: '16px',
            paddingTop: '16px',
            paddingBottom: '16px',
        }

    },
    inactiveText: {
        color: "#17181d",
        cursor: "pointer",
        fontWeight: 600,
        fontSize: "14px",
        fontFamily: "urbanist",
        lineHeight: "19px",
        backgroundColor: "#f8fafe",
        borderColor: '#979797',
        '&.MuiListItem-gutters': {
            paddingLeft: '16px',
            paddingRight: '16px',
            paddingTop: '16px',
            paddingBottom: '16px',
        }
    },
    submitButton: {
        cursor: "pointer",
        color: "#ffffff",
        backgroundColor: "#17181D",
        textTransform: "none",
        width: "165px",
        border: "none",
        borderRadius: "14px",
        bottom: "16px",
        height: "48px",
        fontWeight: 600,
        fontSize: "14px",
        paddingTop: "10px",
        paddingBottom: "14px",
        marginBottom: "5px",
        fontFamily: "Urbanist",
    },
    searchicon: {
        marginRight: '6px',
        fill: '#6D89AF',
        fontSize: "14px",
        paddingTop: '14px',
    },
    selectedButton: () => ({
        border: '1px solid yellow',
        borderRadius: '12px'
    }),
    unselectedButton: () => ({
        borderRadius: '12px'
    }),
    stateDiv: () => ({
        "@media screen and (max-width: 500px)": {
        
            display:'flex',
            flexDirection:'column'
        }

    }),
    filterDiv: ({}),
    filterDivvalue: ({})

});



export default function SortFunctionalityVendor(props: MyProps) {
    const { handleClose, statevalue, handleValue, handleOptionChange,cityvalue, designationvalue, toggleCheckbox, selectedValues, toggleCheckboxdesignation, selectedValuesRole,handleSearchstate, handleChangeFun,handleSearchcity, value, handleRadioChange,handleRadioChange1,ClearDataValues,sortApiCallForAllVendors,isBlocked ,stateindex,statename} = props;
    const classes = useStyles();
    const [selectedButtonvalue, setSelectedButtonvalue] = useState(null);

    const handleButtonClick = (index: any) => {
        setSelectedButtonvalue(index);
    };
    const [state, setState] = useState(true);
    const [city, setCity] = useState(false)
    const [status, setStatus] = useState(false)
    const [experience, setExperience] = useState(false);
    const [designation, setDesignation] = useState(false);
    const buttonData = [
        "Maharashtra",
        "Madhya Pradesh",
        "Delhi",
        "Gujrat",
        "Uttar Pradesh",
        "Jammu & Kashmir",
        "Sikkim",
        "Rajasthan",
        "Tamil Nadu",
        "Manipur",
        "Chattisgrah",
        "Kerala",
    ]
    const buttonData1 = [
        "Banglore",
        "Mumbai",
        "Indore",
        "Ujjain",
        "Agra",
        "Ratlam",
        "pune",
        "Chandigarh",
        "Ahmedabad",
        "Kolkata",
        "Delhi",
        "Jaipur",

    ]
    const handleclick = () => {
        setState(true)
        setCity(false)
        setStatus(false)
        setDesignation(false);
        setExperience(false);
    };
    const handleclick1 = () => {
        setState(false)
        setCity(true)
        setStatus(false)
        setDesignation(false);
        setExperience(false)
    };
    const handleclick2 = () => {
        setState(false)
        setCity(false)
        setStatus(true)
        setDesignation(false);
        setExperience(false);
    };
    const handleclick3 = () => {
        setDesignation(true);
        setCity(false);
        setState(false);
        setStatus(false);
        setExperience(false);
    }
    const handleclick4 = () => {
        setDesignation(false);
        setCity(false);
        setState(false);
        setStatus(false);
        setExperience(true);
    }
    const [selectedValue, setSelectedValue] = useState('');

    const handleChange = (event: any) => {
        setSelectedValue(event.target.value);
    };
    // const [value, setValue] = React.useState([20, 37]);

    // const handleChangevalue = (event: any, newValue: any) => {
    //     setValue(newValue);
    //     //console.log(value[0], value[1])
    // };
    //const [selectedValues, setSelectedValues] = useState<string[]>([]);
    //onst [selectedValuesRole, setSelectedValuesRole] = useState<string[]>([]);
    // const toggleCheckbox = (value: string) => {

    //     if (selectedValues.includes(value)) {
    //         setSelectedValues(selectedValues.filter((val) => val !== value));
    //     } else {
    //         setSelectedValues([...selectedValues, value]);

    //     }
    // };
    //const [isBlocked, setIsBlocked] = useState(false);

   
    console.log("44444444", selectedValues)
    console.log("222222222", selectedValuesRole)
    // const toggleCheckboxdesignation = (value: string) => {
    //     if (selectedValuesRole.includes(value)) {
    //         setSelectedValuesRole(selectedValuesRole.filter((val) => val !== value));
    //     } else {
    //         setSelectedValuesRole([...selectedValuesRole, value]);
    //     }
    // };

    const [selectedOption, setSelectedOption] = useState(null);
  



    return (
        <Grid className={classes.ParentMain}>
            <Grid className={classes.ParentDiv}>
                <Grid className={classes.HeaderDiv}>
                    <Grid className={classes.filterDivvalue}>
                        Filter
                    </Grid>
                    <Grid className={classes.filterDiv}>
                        <CloseIcon onClick={handleClose} />
                    </Grid>
                </Grid>
                <Grid className={classes.MainDiv}>
                    <Grid className={classes.SideDiv}>
                        <List>
                            <ListItem className={state ? classes.activeText : classes.inactiveText} onClick={handleclick}>
                                <ListItemText primary="State" />
                            </ListItem>
                            <Divider />
                            <ListItem className={city ? classes.activeText : classes.inactiveText} onClick={handleclick1}>
                                <ListItemText primary="City" />
                            </ListItem>
                            <Divider />
                            <ListItem className={designation ? classes.activeText : classes.inactiveText} onClick={handleclick3}>
                                <ListItemText primary="Designation" />
                            </ListItem>
                            <Divider />
                            <ListItem className={experience ? classes.activeText : classes.inactiveText} onClick={handleclick4}>
                                <ListItemText primary="Experience" />
                            </ListItem>
                            <Divider />
                            <ListItem className={status ? classes.activeText : classes.inactiveText} onClick={handleclick2}>
                                <ListItemText primary="Status" />
                            </ListItem>
                            <Divider />
                        </List>
                    </Grid>

                    <Grid className={classes.main} >

                        <TableGrid className={classes.TableDiv}>
                            {state === true ? (
                                <Grid>
                                    <Grid className={classes.SearchDiv}>
                                        <SearchIcon1 className={classes.searchicon} />
                                        <SearchInput1 placeholder={"search state"}
                                            style={{
                                                fontSize: "14px", fontWeight: 500,
                                                fontFamily: "Urbanist", letterSpacing: "0.25px", color: '#6D89AF'
                                               
                                            }}
                                            onChange={(event:any)=>handleSearchstate(event)}
                                             />
                                    </Grid>
                                    <Grid className={classes.buttonGroupDiv}>
                                        <RadioGroup value={selectedValue} onChange={handleChange} >
                                            <Grid container spacing={1} xs={12} className={classes.stateDiv}>
                                                {statevalue.map((state: any, index: number) => (
                                                    <Grid item xs={4}>
                                                        <Button 
                                                            key={index}
                                                            className={classes.button1}
                                                            style={{
                                                                border: stateindex === index ? '1px solid yellow' : '1px solid #e3eaf4',
                                                                borderRadius: '16px'
                                                            }}
                                                            onClick={() => handleOptionChange(index, state)}
                                                            onChange={() => handleValue(state.iso2)}
                                                        >
                                                            <CustomFormControlLabel
                                                                value={state.name}
                                                                label={state.name}
                                                                control={<Radio checked={stateindex === index} />}
                                                                labelPlacement="start"
                                                                style={{ textTransform: 'none', display: 'flex', width: '100%', justifyContent: 'space-between', borderColor: "red" }}

                                                            />
                                                            {console.log("ssssss",statename)}
                                                        </Button>
                                                    </Grid>

                                                ))}
                                            </Grid>
                                        </RadioGroup>
                                    </Grid>
                                </Grid>
                            ) : city === true ? (
                                <Grid>
                                    <Grid className={classes.SearchDiv}>
                                        <SearchIcon1 className={classes.searchicon} />
                                        <SearchInput1 placeholder={"search city"}
                                            style={{
                                                fontSize: "14px", fontWeight: 500,
                                                fontFamily: "Urbanist", letterSpacing: "0.25px"
                                            }}
                                            onChange={(event:any)=>handleSearchcity(event)}
                                             />
                                    </Grid>
                                    <Grid className={classes.buttonGroupDiv}>
                                        <Grid container spacing={1} xs={12} className={classes.cityDiv}>

                                            {

                                                cityvalue?.map((city: any, index: number) => (
                                                    <Grid item xs={4}>
                                                        <Button
                                                            key={index}
                                                            className={`${classes.button1} ${selectedValues.includes(city.name) ? classes.selectedButton : classes.unselectedButton}`}
                                                            onChange={() => toggleCheckbox(city.name)}
                                                        >
                                                            <CustomFormControlLabel1
                                                                control={
                                                                    <Checkbox
                                                                        checked={selectedValues.includes(city.name)}
                                                                        onChange={() => toggleCheckbox(city.name)}
                                                                    />
                                                                }
                                                                label={city.name}
                                                                style={{
                                                                    textTransform: 'none',
                                                                    display: 'flex',
                                                                    width: '100%',
                                                                    justifyContent: 'space-between',
                                                                    color: selectedValues.includes(city.name) ? 'yellow' : 'black',

                                                                }}
                                                                labelPlacement="start"
                                                            />
                                                        </Button>

                                                    </Grid>
                                                ))}
                                        </Grid>
                                    </Grid>
                                </Grid>


                            ) : designation === true ? (

                                <Grid className={classes.buttonGroupDiv}>
                                    <Grid container spacing={1} xs={12} className={classes.cityDiv}>

                                        {

                                            designationvalue?.map((city: any, index: number) => (
                                                <Grid item xs={4}>
                                                    <Button
                                                        key={index}
                                                        className={`${classes.button1} ${selectedValuesRole.includes(city.name) ? classes.selectedButton : classes.unselectedButton}`}
                                                        onClick={() => toggleCheckboxdesignation(city.name)}
                                                    >
                                                        <CustomFormControlLabel1
                                                            control={
                                                                <Checkbox
                                                                    checked={selectedValuesRole.includes(city.name)}
                                                                    onChange={() => toggleCheckboxdesignation(city.name)}
                                                                />
                                                            }
                                                            label={city.name}
                                                            style={{
                                                                textTransform: 'none',
                                                                display: 'flex',
                                                                width: '100%',
                                                                justifyContent: 'space-between',
                                                                color: selectedValuesRole.includes(city.name) ? 'yellow' : 'black',

                                                            }}
                                                            labelPlacement="start"
                                                        />
                                                    </Button>

                                                </Grid>
                                            ))}
                                    </Grid>
                                </Grid>

                            ) : experience === true ? (
                                <Box>
                                    <div className={classes.root}>
                                        <Typography id="range-slider" gutterBottom>
                                            Experience
                                        </Typography>
                                        <Slider
                                            value={value}
                                            onChange={handleChangeFun}
                                            valueLabelDisplay="auto"
                                            aria-labelledby="range-slider"
                                        />
                                    </div>                              </Box>

                            ) : (
                         <Box style={{display:"flex",gap:
                         "20px"}}>
                                 <Box style={{ paddingTop: '60px' }}>
             Block
        <Radio
          value={isBlocked ? 'Blocked' : 'Unblocked'}
          checked={isBlocked}
          onChange={handleRadioChange}
        />
        
      </Box>
       <Box style={{ paddingTop: '60px' }}>
       Unblock
       <Radio
         value={isBlocked ? 'Blocked' : 'Unblocked'}
          checked={!isBlocked}
          onChange={handleRadioChange1}
            />

</Box>
 {console.log(isBlocked,"bbbbbbbbbbbbbbbbbbbbb")}
</Box>
                            )



                            }

                        </TableGrid>
                    </Grid>

                </Grid>
                <Grid className={classes.FooterDiv}>
                    <button
                        className={classes.button3}
                        onClick={ClearDataValues}
                        >
                        Clear All
                    </button>
                    <button
                        className={classes.submitButton}
                       onClick={sortApiCallForAllVendors}
                    >
                        Apply
                    </button>
                </Grid>
            </Grid>
        </Grid>

    );
}
