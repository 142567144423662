import React from "react";

import {
    // Customizable Area Start
    TypographyProps,
    Container,
    Grid,
    Box,Typography,
    Link
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start

import InvoiceListingDetailcard from "../../../components/src/InvoiceListingDetailcard";

import Header from "../../../components/src/Header.web";
import SideBar from "../../../components/src/SideBar";
import Footer from "../../../components/src/Footer";
import TaskListController,{ Props } from "./TaskListController";
import { arrowicon } from "../../fullteamvisibility/src/assets";

// Customizable Area End

export default class InvoiceListingDetail extends TaskListController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    renderMainInvoiceDetail(sideBarStatus: boolean) {
        return <Grid style={webStyle.contentSetupinvoiceDetail}>
              
            < Header
              navigate={this.props.navigation.navigate}
                sideBarStatus={this.state.isSideBarOpen}
                dropDownStatus={this.state.isDropdownOpen}
                handleDropdown={this.handleDropdown}
                handleSideBar={this.handleSideBar}  
            />
            <Grid style={webStyle.mainContentinvoiceDetail as React.CSSProperties}>
                <Box sx={webStyle.blockInfoinvoiceDetailscreen}>
                    <Box sx={webStyle.activeBlockinvoiceDetailscreen}>
                        <Typography variant="h3" style={webStyle.activeBlockinvoiceDetailscreen as TypographyProps}>Invoice Details</Typography>
                    </Box>
                    <Typography variant="h6" style={webStyle.blockE2EPathvalue as TypographyProps}>
                <span style={webStyle.highlightedTitleextvalue}>
                  <Link data-test-id="homeLink" color="inherit" onClick={() => this.handleNavigation("JobProfile")}>
                    Home
                  </Link>
                </span>
                <span style={webStyle.highlightedTitleextvalue}>
                  <Link data-test-id="studiosBookingList" color="inherit" onClick={() => this.handleNavigation("InvoiceListing")}>
                    {" "}/ Invoices
                  </Link>
                </span>
                {" "}/{" "}{this.state.invoicedetaillist?.attributes?.project?.name}
              </Typography>
                </Box>
            <InvoiceListingDetailcard data={this.state.invoicedetaillist} handleClose={this.handleCloseReject} handleOpen={this.handleOpenReject} open={this.state.openReject} handleCloseHold={this.handleCloseHold}  handleOpenHold={this.handleOpenHold} openHold={this.state.openHold} RejectCommentvalue={this.state.RejectCommentValue} HandleComment={this.CommentsHandle} rejectbuttonapi={this.getRejectbuttonapi}
             handlehiddenandshow={this.handlehiddenandshow} showandhidden={this.state.showandhidden} handleHideAndShowSecond={this.handlehiddenandshowSecondPipelineVendorInvoice} handleHideAndShowCleared={this.handlehiddenandshowClearedVendorInvoice} showAndHiddenSecondPipeline={this.state.showAndHiddenSecondPipeline} showAndHiddenCleared={this.state.showAndHiddenCleared} getApprovebuttonapi={this.getApprovebuttonapi} HoldCommentvalue={this.state.HoldCommentValue} HandleCommentHold={this.CommentsHandleHold} getHoldedbuttonapi={this.getHoldedbuttonapi} />
            </Grid>
            <Footer />
        </Grid>
    }
    
    render() {
        return ( 
            <Container maxWidth="xl" className="user_detail_container">
                {
                    this.state.isSideBarOpen?
                        <Grid container spacing={0}>
                            <Grid style={webStyle.sideBarinvoiceDetail}  item xs={3}>
                                <SideBar
                                   userData={{ age: 25 }}
                                    activeMenuItem={"My Tasks"}
                                    navigate={this.props.navigation.navigate}
                                    handleSideBar={this.handleSideBar}
                                   
                                    />
                            </Grid>
                            <Grid item xs={9} style={{ flexBasis: "77.75%", maxWidth: "77.75%" }}>{this.renderMainInvoiceDetail(this.state.isSideBarOpen)}</Grid>
                        </Grid> :
                        <Grid item xs={12}>
                            {
                                this.renderMainInvoiceDetail(this.state.isSideBarOpen)
                            }
                           </Grid>
                }
            </Container >
        );  }
}

// Customizable Area Start
const webStyle = {
   
    contentSetupinvoiceDetail: {
        borderLeft: "1px solid #E3EAF4",
    },
    sideBarinvoiceDetail: {
        minHeight: "100vh",
        maxWidth: "22.25%",
    },
    blockInfoinvoiceDetailscreen: {
    },
    blockPathinvoiceDetail: {
        fontSize: "14px",
        letterSpacing: "1px",
        color: "#6D89AF",
        marginBottom: "24px",
        fontWeight: 500
      },
      highlightedTextinvoiceDetail: {
        color: "#F5C42C",
        textDecoration: 'underline',
        textDecorationColor: "#F5C42C",
        fontWeight: 600,
        cursor:"pointer"
      },
    activeBlockinvoiceDetailscreen: {
        fontSize: "28px",
        fontWeight: "6700",
        letterSpacing: "1px",
        display: "flex",
        flexDirection: "row",
        lineHeight: "34px",
        alignItems: 'center',
      },
     
      mainContentinvoiceDetail: {
        width: "100%",
        backgroundColor: "#F8FAFE",
        boxSizing: "border-box",
        padding: "24px"
      },
      blockE2EPathvalue: {
        fontSize: "14px",
        letterSpacing: "1px",
        fontWeight: 500,
        color: "#6D89AF",
        lineHeight: "17px",
        paddingTop: "8px",
      },
      highlightedTitleextvalue: {
        fontFamily: "Urbanist",
        color: "#F5C42C",
        textDecoration: "underline",
        cursor: "pointer",
        fontWeight: 600,
      },
};
// Customizable Area End