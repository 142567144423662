import React, { useState } from 'react';
import { Box, Grid, List, ListItem, ListItemText, Divider, TextareaAutosize, Radio, Button, FormControlLabel, styled, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RadioGroup from '@material-ui/core/RadioGroup';
import CloseIcon from '@material-ui/icons/Close';
import { Filtericon, Raiseinvoicecertificate, uploadinvoice } from "./assets";
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import exp from 'constants';
interface MyProps {
    data: any;
    handleClose1: any;
    handleImage:any;
    handleChangeinvoice:any;
    invoiceselect:any;
    handleChangeProject:any;
    selectproject:any
    handleChangeradio:any;
    selectinvoicetype:any;
    selectinvoicename:any;
    handleChangeName:any;
    handleChangeDetail:any;
    selectinvoicedetail:any
    handleChangeNo:any;
    selectinvoiceno:any;
    handleChangeAmount:any;
    selectinvoiceamount:any;
    raiseinvoiceData:any;
    image:any;
}
const ProjectSelect = styled(Select)({
    '& ul': {
        height: 65
    }
})
const TableGrid = styled(Grid)({
    '& .MuiGrid-root::-webkit-scrollbar': {
        display: 'none',
    },
    '& .MuiGrid-root': {
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none',
        "@media screen and (max-width: 500px)": {

            paddingLeft: '10px'
        }
    },
    '& .MuiFormControlLabel-label::-webkit-scrollbar': {
        display: 'none',
    },
    '& .MuiFormControlLabel-label': {
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none',
    },
})
const CustomFormControlLabel = styled(FormControlLabel)({
    '& .MuiFormControlLabel-label': {
        textAlign: 'left',
        color: 'black',
        fontWeight: 600,
        fontSize: '14px',
        fontFamily:'urbanist'
        //overflowY: 'scroll'
    },
    '& .MuiRadio-colorSecondary.Mui-checked': {
        color: 'yellow',

    },
    '& .MuiList-padding': {
        paddingTop: 0,
        paddingBottom: 0,
    },
    '&.MuiTypography-body1': {


        fontWeight: 700,
        fontFamily:'urbanist'

    }
});
const SearchIcon1 = styled(SearchOutlinedIcon)({
    padding: "10px 0 10px 13px",
    "@media(max-width: 500px)": {
        padding: "10px 0 10px 8px",
        fontSize: '18px'
    },
});
const SearchInput1 = styled('input')({
    fontSize: "15px",
    outline: "none",
    border: "none",
    width: "85%",
    padding: "10px 3px",
    backgroundColor: "#F8FAFE",
    "@media(max-width: 500px)": {
        fontSize: '12px',
        overflow: 'hidden',
        width: "70%",
        padding: "10px 0px",
    },
});
const RadioBox = styled(Box)({
    '& .Mui-checked .MuiSvgIcon-root': {
        fill: '#F5C42C!important',
    },
    '& .Mui-checked + .MuiTypography-root': {
        color: '#F5C42C!important',
    }
    
});

const CustomFormControlLabel1 = styled(FormControlLabel)({
    '& .MuiFormControlLabel-label': {
        color: '#17181d',
        fontWeight: '600',
        fontSize: "14px",
    },
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
        color: '#F5C42C',
        fontSize: "15px",
    },
    '& .MuiIconButton-colorSecondary': {
        //color: '#f50057',
    }
});

const PatentGrid = styled(Grid)({
    '& input::placeholder': {
        color: '#6d89af',
        fontFamily: 'Urbanist',
        fontSize: '14px',
        fontWeight: 500,
        letterSpacing: '0.28px'
    },

    '& textarea::placeholder':{
        color: '#6d89af',
        fontFamily: 'Urbanist',
        fontSize: '14px',
        fontWeight: 500,
        letterSpacing: '0.28px'
    },
    "& .MuiInput-underline::after": {
        borderBottom: 'unset'
    },
    "@media(max-width: 500px)": {
        marginRight: "0px",
    },
    "& .MuiInput-underline::before": {
        content: 'none'
    },
    "& .MuiInputLabel-animated.Mui-focused": {
        transform: 'unset',
        color: 'transparent'
    },

})


const useStyles = makeStyles({
    ParentMain: {


        
    },
    ParentDiv: {

        border: '10px 1px solid #d8d8d8',

        width: 630,
        minHeight: '499px',
        "@media screen and (max-width: 500px)": {

            width: '400px',
            marginRight: "0px",

        }


    },
    HeaderDiv: {
        fontSize: "20px",
        textAlign: 'center',
        fontWeight: 700,
        paddingTop: "20px",
        paddingBottom: "10px",
        display: 'flex',
        justifyContent: 'end',
        gap: '186px',
        paddingRight: '20px'


    },
    ViewDiv: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    paymentmodediv: {
        paddingTop: "10px",
        paddingBottom: "10px",
    },
    LeftView: {
        paddingLeft: "24px"
    },
    InputTypeLabel: {
        color: '#6d89af',
        fontFamily: 'urbanist',
        fontSize: '13px',
        fontWeight: 600,
        lineHeight: '18px',
        letterSpacing: '0.2px',
        paddingBottom: '5px'
    },
    RightView: {
        paddingRight: "24px"
    },
    filterDiv: {
        fontSize: "20px",
        fontWeight: 700,
        fontFamily: "urbanist",
        color: '#17181d',
        paddingBottom: "10px"
    },
    button: {
        display: 'block',

    },
    formControl: {
        display: 'flex',
        justifyContent: 'space-between',
        minWidth: 120,
        paddingTop: "8px",
        fontFamily:'urbanist'
    },
    formControl1: {
        display: 'flex',
        justifyContent: 'space-between',
        minWidth: 120,
        paddingTop: "8px"
    },
    formControl2: {
        display: 'flex',
        justifyContent: 'space-between',
        minWidth: 120,
        paddingTop: "8px"
    },
    selectinputtype: {
        width: '270px',

        borderRadius: '14px',
        border: '1px solid #e3eaf4',
        background: ' #ffffff',
    },
    selectserviceandlabel: {

    },
    selectservice: {
        width: '272px',

        borderRadius: '14px',
        border: '1px solid #e3eaf4',
        background: ' #ffffff',
    },
    selectservicelabel: {
        color: '#6d89af',
        fontFamily: 'urbanist',
        fontSize: '13px',
        fontWeight: 600,
        lineHeight: '18px',
        letterSpacing: '0.2px',
        paddingBottom: '5px'

    },
    textselectype: {
        color: '#17181d',
        fontFamily: 'Urbanist',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '18px',
        paddingLeft: "13px",

        "&.MuiSelect-selectMenu" :{
           
            fontFamily: 'urbanist'
        },
        '& .MuiSelect-select.MuiSelect-select': {
        
            fontFamily: 'urbanist'
        }
    },
    radioparent: {

    },
    Invoicenameandlabel: {
        paddingTop: '10px'
    },
    Invoicenoandlabel: {
        paddingTop: '50px'
    },
    InvoicAmountandlabel: { paddingTop: "12px" },
    Invoicelabel: {
        color: '#6d89af',
        fontFamily: 'urbanist',
        fontSize: '13px',
        fontWeight: 600,
        lineHeight: '18px',
        letterSpacing: '0.2px',
        paddingBottom: "5px"
    },
    invoiceheadingandsubheading: {},
    invoiceuploadicon: {
        width: '38px',
        height: '38px',
        borderRadius: '12px',
        background: 'rgba(245,196,44,0.12)',
        display: 'flex', justifyContent: 'center',
        alignItems: 'center'
    },
    uploadicon: {
        height: '18px',
        width: "18px",
    },
    invoiceheading: {
        color: '#17181d',
        fontFamily: 'Urbanist',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '18px',
        textAlign: 'center',
        letterSpacing: '0.21538462px',
    },
    invoicesubheading: {
        color: '#6d89af',
        fontFamily: 'Urbanist',
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '18px',
        letterSpacing: '0.18461539px',
        textAlign: 'center',
    },
    Invoicename: {
        borderRadius: '14px',
        border: ' 1px solid #e3eaf4',
        background: '#ffffff',
        width: '260px',
        paddingTop: '5px',
        paddingLeft: '13px',
        position: 'relative'
    },
    InvoiceNote: {
        borderRadius: '14px',
        border: ' 1px solid #e3eaf4',
        background: '#ffffff',
        width: '276px',
        paddingTop: '5px',
        paddingLeft: '13px',
        height: '70px',
        resize: 'none',
        color:'#17181D',
        fontFamily: ' Urbanist',
        fontSize: '14px',
        fontWeight: 500,
        letterSpacing: '0.28px',
        '&:focus-visible': {
            outline: 'none'
        }
    },
    Invoicetextfiled: {
        color: '#6d89af',
        fontFamily: ' Urbanist',
        fontSize: '14px',
        fontWeight: 500,
        letterSpacing: '0.28px'
    },
    uploadinvoicereceiptdiv: {
        marginTop: '30px',
        width: '272px',
        height: '70px',
        borderRadius: '14px',
        border: '0.75px dashed #f5c42c',
        background: 'rgba(245,196,44,0.04)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    submitButton: {
        cursor: "pointer",
        color: "#ffffff",
        backgroundColor: "#17181D",
        textTransform: "none",
        width: "165px",
        border: "none",
        borderRadius: "14px",
        //position: "absolute",
        bottom: "16px",
        height: "48px",
        fontWeight: 600,
        fontSize: "14px",
        paddingTop: "10px",
        paddingBottom: "14px",
        marginBottom: "5px",
        fontFamily: "urbanist",
        position: 'absolute',
        right: '0px',
        marginRight: '24px'
    },
    IMAGEBOX:{
        width:'117px',
        height:"47px",
        background:'#ffffff',
        borderRadius:"12px",
        display: 'flex',
        alignItems: 'center',
        justifyContent:'center'
    },
    uploadimage:{
        height:"30px",
        width:'27px'
    },

})




export default function Raiseinvoicefunctionality(props: MyProps) {
    const { data, image,handleClose1,handleImage,handleChangeinvoice,invoiceselect,handleChangeProject,selectproject,raiseinvoiceData
   ,handleChangeradio,selectinvoicetype ,handleChangeName,selectinvoicename,selectinvoicedetail,handleChangeDetail,selectinvoiceno,handleChangeNo,handleChangeAmount,selectinvoiceamount} = props

    const classes = useStyles();
    const [selectValue, setSelectValue] = React.useState('standard_pay');
    const [selectValueservice, setSelectValueservice] = React.useState("None")
    const [selectValueproject, setSelectValueproject] = React.useState('standard_pay');
    const [open, setOpen] = React.useState(false);
    const [openproject, setOpenproject] = React.useState(false);
    const [openservice, setOpenservice] = React.useState(false);
    const [value, setValue] = React.useState('female');

   

    const handleChange = (event: any) => {
        setSelectValue(event.target.value);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleChangeservice = (event: any) => {
        setSelectValueservice(event.target.value);
    };

    const handleCloseservice = () => {
        setOpenservice(false);
    };

    const handleOpenservice = () => {
        setOpenservice(true);
    };
    // const handleChangeproject = (event: any) => {
    //     setSelectValueproject(event.target.value);
    // };

    const handleCloseproject = () => {
        setOpenproject(false);
    };

    const handleOpenproject = () => {
        setOpenproject(true);
    };
    const options = [
        { value: '', label: 'None' },
        { value: 'standard_pay', label: 'Food & Stay' },
        { value: 'cash_invoice', label: 'Travel' },
        
    ];





    return (
        <PatentGrid className={classes.ParentMain}>
            <Grid className={classes.ParentDiv}>
                <Grid className={classes.HeaderDiv}>
                    <Grid className={classes.filterDiv}>
                        Raise Your Invoice
                    </Grid>
                    <Grid className={classes.filterDiv}>
                        <CloseIcon onClick={(e:any)=>handleClose1(e)} style={{ color: "#6d89af" }} />
                    </Grid>

                </Grid>
                <Grid className={classes.ViewDiv}>
                    <Grid className={classes.LeftView}>
                        <Box className={classes.InputTypeLabel}>
                            Select Invoice Type
                        </Box>
                        <Box className={classes.selectinputtype}>

                            <FormControl className={classes.formControl}>

                                <Select
                                    labelId="demo-controlled-open-select-label"
                                    id="demo-controlled-open-select"
                                    open={open}
                                    onClose={handleClose}
                                    onOpen={handleOpen}
                                    value={invoiceselect}
                                    onChange={handleChangeinvoice}
                                    className={classes.textselectype}
                                >
                                    <MenuItem value="" className={classes.textselectype}>
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={"standard_pay"} className={classes.textselectype}>Standard Pay</MenuItem>
                                    <MenuItem value={"cash_invoice"} className={classes.textselectype}>Cash Invoice</MenuItem>
                                    <MenuItem value={"special_payment"} className={classes.textselectype}>Special Invoice</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <RadioBox className={classes.paymentmodediv}>
                        <RadioGroup aria-label="gender" name="gender1" value={selectinvoicetype} onChange={handleChangeradio} style={{ display: 'flex', flexDirection: 'revert' }} className={classes.radioparent}>
                                <CustomFormControlLabel1
                                    value="one_time"
                                    control={<Radio style={{ width: "20px", height: "20px", color: value === 'one_time' ? '#f5c42c' : '#17181d', fontFamily: 'urbanist', fontWeight: 600, fontSize: '14px' }} />}
                                    label="One Time"
                                    style={{ color: value === 'one_time' ? '#f5c42c' : '#17181d', fontFamily: 'urbanist', fontWeight: 600, fontSize: '14px' }}
                                />
                                <CustomFormControlLabel1
                                    value="montly_wise"
                                    control={<Radio style={{ width: "20px", height: "20px", color: value === 'monthly_wise' ? ' #f5c42c' : '#17181d' }} />}
                                    label="Monthly Wise"
                                    style={{ color: value === 'monthly_wise' ? ' #f5c42c' : '#17181d', fontFamily: 'urbanist', fontWeight: 600, fontSize: '14px' }}
                                />
                            </RadioGroup>
                        </RadioBox>
                        <Box className={classes.selectserviceandlabel}>
                            <Box className={classes.selectservicelabel}>
                                Select Service
                            </Box>
                            <Box className={classes.selectservice}>
                                <FormControl className={classes.formControl1}>

                                    <Select

                                        open={openservice}
                                        onClose={handleCloseservice}
                                        onOpen={handleOpenservice}
                                        value={selectValueservice}
                                        onChange={handleChangeservice}
                                        className={classes.textselectype}

                                    >
                                        <MenuItem value="None" className={classes.textselectype}>
                                            Select Service
                                        </MenuItem>
                                        <MenuItem value={"standard_pay"} className={classes.textselectype}>Food & Stay</MenuItem>
                                        <MenuItem value={"cash_invoice"} className={classes.textselectype}>Travel</MenuItem>
                                        
                                    </Select>
                                </FormControl>
                            </Box>

                        </Box>
                        <Box className={classes.Invoicenameandlabel}>
                            <Box className={classes.Invoicelabel}>
                                Invoice name
                            </Box>
                            <Box className={classes.Invoicename}>
                                <TextField
                                    placeholder='Enter Invoice name'
                                    id="demo-helper-text-aligned"
                                    className={classes.Invoicetextfiled}
                                    value={selectinvoicename}
                                   onChange={handleChangeName}
                                />
                            </Box>
                        </Box>
                        <Box className={classes.Invoicenameandlabel}>
                            <Box className={classes.Invoicelabel}>
                                Add Note
                            </Box>

                            <textarea placeholder="Enter Invoice details" className={classes.InvoiceNote}  value={selectinvoicedetail}
                                   onChange={handleChangeDetail} />

                        </Box>


                    </Grid>
                    <Grid className={classes.RightView}>
                        <Box className={classes.InputTypeLabel}>
                            Select Project
                        </Box>
                        <Box className={classes.selectinputtype}>
                        <FormControl className={classes.formControl2}>
        <ProjectSelect
            labelId="demo-controlled-open-select-label"
            id="demo-controlled-open-select"
            open={openproject}
            onClose={handleCloseproject}
            onOpen={handleOpenproject}
            value={selectproject}
            onChange={handleChangeProject}
            className={classes.textselectype}
        >
                
            {data.map((option: any) => (
                <MenuItem key={option.id} value={option.id} className={classes.textselectype}>
                    {option.attributes.project_name}
                </MenuItem>
            ))}
        </ProjectSelect>
    </FormControl>
                        </Box>
                        <Box className={classes.Invoicenoandlabel}>
                            <Box className={classes.Invoicelabel}>
                                Invoice No.
                            </Box>
                            <Box className={classes.Invoicename}>
                                <TextField
                                    placeholder='Enter Invoice No.'
                                    id="demo-helper-text-aligned"
                                    value={selectinvoiceno}
                                   onChange={handleChangeNo}
                                />
                            </Box>
                        </Box>
                        <Box className={classes.InvoicAmountandlabel}>
                            <Box className={classes.Invoicelabel}>
                                Total Amount
                            </Box>
                            <Box className={classes.Invoicename}>
                                <Box style={{ position: 'absolute', top: 10, left: 10, borderRight: '1px solid #e3eaf4', paddingRight: '10px' }}>₹</Box>
                                <TextField
                                    style={{ paddingLeft: 40 }}
                                    placeholder='Enter total amount'
                                    id="demo-helper-text-aligned"
                                    value={selectinvoiceamount}
                                    onChange={handleChangeAmount}

                                />
                            </Box>
                        </Box>
                        {
                            image?(
                                <Box className={classes.uploadinvoicereceiptdiv}>
                                <Box style={{display:'flex',gap:"20px",alignItems:'center'}}>
                                  <Box className={classes.invoiceuploadicon}>
                            <input
                          type="file"
                          accept='image/*'
                          
                          style={{
                            opacity: 0,
                            position: 'absolute',
                            width: '30px',
                            height: '30px',
                          }}
                          onChange={(e: any) =>handleImage(e)}
                        />

                      <img src={uploadinvoice} alt="" className={classes.uploadicon} />
                        </Box>   
                                 <Box style={{display:'flex',flexDirection:"column",justifyContent:'center',alignItems:'center'}}>
                                 <Box className={classes.IMAGEBOX}>
                                 <img src={Raiseinvoicecertificate} alt="" className={classes.uploadimage} />
                                 </Box>
                                 <Box style={{fontSize:'11px',fontWeight:600,color:'#17181D',fontFamily:'urbanist'}}>{image && image.name}</Box>
                                 </Box>
                                 </Box>
                           
                        </Box>
                            ):(
                                <Box className={classes.uploadinvoicereceiptdiv}>
                            <Box className={classes.invoiceuploadicon}>
                            <input
                          type="file"
                          accept='image/*'
                          
                          style={{
                            opacity: 0,
                            position: 'absolute',
                            width: '30px',
                            height: '30px',
                          }}
                          onChange={(e: any) =>handleImage(e)}
                        />
                                <img src={uploadinvoice} alt="" className={classes.uploadicon} />
                            </Box>
                            <Box className={classes.invoiceheadingandsubheading}>
                                <Box className={classes.invoiceheading}>
                                    Attach Invoice receipt
                                </Box>
                                <Box className={classes.invoicesubheading}>
                                    (.png, .jpg, .jpeg, .pdf)
                                </Box>
                            </Box>
                        </Box>
                            )
                        }




                        <button
                            className={classes.submitButton}
                     onClick={raiseinvoiceData}

                        >
                            Send Request
                        </button>

                    </Grid>
                </Grid>
            </Grid>
        </PatentGrid>

    );
}
