import React, { ChangeEvent } from "react";

import {
  // Customizable Area Start
  Container,
  Box,
  Typography,
  Grid,
  TypographyProps,
  Link,
  AppBar,
  Tabs,
  Tab,
  TextField,
  InputAdornment,
  IconButton,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import SideBar from "../../../components/src/SideBar";
import Header from "../../../components/src/Header.web";
import WorkTravellingListController, { Props } from "./WorkTravellingListController";
import Footer from "../../../components/src/Footer";
import PlaceRoundedIcon from '@material-ui/icons/PlaceRounded';
import SearchIcon from "@material-ui/icons/Search";
import { CustomButton } from "../../email-account-login/src/CustomButton";
const images = require("./assets");

const theme = createTheme({
  palette: {
    primary: {
      main: "#F5C42C",
      contrastText: "#fff"
    }
  },
  overrides: {
    MuiSelect: {
      select: {
        "&:focus": {
          border: "1px solid #F5C42C",
        }
      }
    }
  }
});

const CustomMakeATripButtonBox = styled(CustomButton)({
  '& .MuiButton-label': {
    lineHeight: '18px',
    fontWeight: 600,
    letterSpacing: '0.25px',
    fontSize: 15,
  }
});
// Customizable Area End

export default class WorkTravellingList extends WorkTravellingListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderLiveTravellingTabContent = (travellingRecords: any[], type: string) => {
    return <>
      <Grid item xs={12} sm={6} md={4} style={{ padding: "16px 16px 0px 0px" }}>
        <TextField
          data-test-id="travelSearchInput"
          onChange={(event: ChangeEvent<HTMLInputElement>) => this.filterTravelList(event)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton style={webStyle.travelListIconButton}>
                  <SearchIcon style={webStyle.travelListSearchIcon} />
                </IconButton>
              </InputAdornment>
            ),
            style: {
              borderRadius: "35px",
              backgroundColor: "#F8FAFE",
              padding: "0px",
              height: "40px",
              fontSize: "13px"
            } as React.CSSProperties
          }}
          variant="outlined"
          fullWidth
          placeholder={`Search member`}
        />
      </Grid>
      <Grid container spacing={3} style={{ marginTop: "4px" }}>
        {travellingRecords.length ? travellingRecords.map((record: any, index: number) => (
          <Grid data-test-id={`${record}_studiolist`} item xs={12} sm={6} md={4} key={record}>
            <Box style={{ height: "147px", borderRadius: "12px", border: "1px solid #e3eaf4", background: "#ffffff", display: "flex" }}>
              <Box style={{ height: "74px", margin: "37px 0px 14px 17px", borderLeft: "1px solid #e3eaf4" }}>
                <Box>
                  <Box style={{ width: "11px", height: "11px", borderRadius: "50%", backgroundColor: "#f5c42c", marginTop: "-11px", marginLeft: "-5.8px" }}></Box>
                  <Box style={{ marginTop: "70px", marginLeft: "-8.8px" }}>
                    <PlaceRoundedIcon htmlColor="#6d89af" fontSize="small" style={{ width: "0.83em" }} />
                  </Box>
                </Box>
              </Box>
              <Box style={{ width: "calc(100% - 23px)", display: "flex", justifyContent: 'space-between', flexDirection: "column", padding: "14px 12px 14px 7px" }}>
                <Box style={webStyle.travelListAddressandTime}>
                  <Box >
                    <Box >
                      <Box style={webStyle.travelListStartDestination}>
                        Start Destination
                      </Box>
                      <Box style={webStyle.travelListAddressDestination as React.CSSProperties}>
                        Vadodara, Gujrat
                      </Box>
                    </Box>
                  </Box>
                  <Box style={webStyle.travelListTimeandday as React.CSSProperties}>
                    Today | 10:04AM
                  </Box>
                </Box>
                <Box style={{ borderTop: "1px dashed #e3eaf4", marginTop: "25px" }}>
                  <Box style={webStyle.travelListNameandpositionprofile}>
                    <Box>
                      <img src={"https://www.littlethings.info/wp-content/uploads/2014/04/dummy-image-green-e1398449160839.jpg"} alt="" style={webStyle.travelListJobcardimage} />
                    </Box>
                    <Box style={webStyle.travelListPipelineDiv}>
                      Pipeline Staff
                    </Box>
                    <Box style={webStyle.travelListPositionandname}>
                      <Box style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: "6px 12px" }}>
                        <Box style={webStyle.travelListNamediv}>
                          Sara Scholz
                        </Box>
                        <Box style={webStyle.travelListPosition}>
                          Assistant Director
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box style={{ marginTop: "7px", display: 'flex', justifyContent: 'space-between' }}>
                  <Box>
                    <Box >
                      <Box style={webStyle.travelListStartDestination}>
                        End Destination
                      </Box>
                      <Box style={webStyle.travelListAddressDestination as React.CSSProperties}>
                        Nashik, Maharashtra
                      </Box>
                    </Box>
                  </Box>
                  {type === "liveTravel" ? <Box data-test-id={`moveToTravelTrip_${index}`} onClick={this.handleTrackATrip} style={webStyle.travelListTrackandTrackIcon}>
                    <Box style={webStyle.travelListTrack}>
                      Track
                    </Box>
                    <Box style={webStyle.travelListTrackIcon}>
                      <img src={images.Trackicon} alt="" style={webStyle.travelListDotvalue} />
                    </Box>
                  </Box> :
                    <Box data-test-id={`moveToTravelTrip_${index}`} onClick={this.handleTrackATrip} style={webStyle.travelListTotalKmsText}>
                      546 Kms
                    </Box>}
                </Box>
              </Box>
            </Box>
          </Grid>
        ))
          : <Typography style={{ width: '100%', textAlign: 'center', marginTop: '30px', marginBottom: '30px', color: '#6d89af', fontFamily: 'Urbanist', fontSize: 14, fontWeight: 600 }}>No record available</Typography>
        }
      </Grid >
    </>
  }

  renderMainWorkTravellingListContent(sideBarStatus: boolean = this.state.isTravelListSideBarOpen) {
    return (<Grid style={webStyle.travelListContentSetup}>
      < Header
        dropDownStatus={this.state.isTravelListDropdownOpen}
        sideBarStatus={sideBarStatus}
        handleDropdown={this.handleWorkTravellingListDropdown}
        handleSideBar={this.handleWorkTravellingListSideBar}
        navigate={this.props.navigation.navigate}
      />
      <Grid style={webStyle.travelListMainDataContent as React.CSSProperties}>
        <Box sx={webStyle.travelListBreadCrumbBox}>
          <Box>
            <Typography style={webStyle.currentBlockTravelListTitle as TypographyProps} variant="h3">Travelling List</Typography>
            <Typography style={webStyle.blockTravelListPath as TypographyProps} variant="h6">
              <span style={webStyle.travelListYellowHighlightedText}>
                <Link color="inherit" data-test-id="homeLink" onClick={() => this.handleTravelListNavigation("JobProfile")}> Home</Link>
              </span>
              <span style={webStyle.travelListYellowHighlightedText}>
                <Link color="inherit" data-test-id="trackForWork" onClick={() => this.handleTravelListNavigation("Location")}>{" "}/ Track for Work </Link>
              </span> {" "} / Pushpa 2
            </Typography>
          </Box>
        </Box>
        <Grid item lg={12} xl={12} xs={12} sm={12} md={12}>
          <AppBar position="static" className="appbar app_bar" color="transparent">
            <Tabs
              data-test-id="tabChange"
              value={this.state.tabTravelListValue}
              onChange={this.handleTravelListAppBarValue}
              variant="scrollable"
              scrollButtons="on"
              TabIndicatorProps={{ style: { background: "orange", height: '2px' } as React.CSSProperties }}
              aria-label="scrollable prevent tabs example"
              className="tabs_main"
            >
              <Tab
                label="Live Travelling"
                className={this.state.tabTravelListValue == 0 ? "active_btn" : "non_active_btn"}
              />
              <Tab
                label="Travel History"
                className={this.state.tabTravelListValue == 1 ? "active_btn" : "non_active_btn"}
              />
            </Tabs>
          </AppBar>
        </Grid>
        {this.state.tabTravelListValue === 0 ? this.renderLiveTravellingTabContent(this.state.liveWorkTravellingList, "liveTravel") : this.renderLiveTravellingTabContent(this.state.travelHistoryList, "travelHistory")}
      </Grid >
      <Footer />
    </Grid >)
  }
  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container style={webStyle.travelListParentContainerDiv}>
          {this.state.isTravelListSideBarOpen ? (<Grid spacing={0} container>
            <Grid style={webStyle.travelListViewSidebarParent} item xs={3}>
              <SideBar
                userData={{ age: 25 }}
                navigate={this.props.navigation.navigate}
                activeMenuItem={"Track for Work"}
                handleSideBar={this.handleWorkTravellingListSideBar} />
            </Grid>
            <Grid item style={{ flexBasis: "77.75%", maxWidth: "77.75%" }} xs={9}>{this.renderMainWorkTravellingListContent()}</Grid>
          </Grid>) :
            <Grid item xs={12}>{this.renderMainWorkTravellingListContent()}</Grid>
          }
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  travelListIconButton: {
    padding: "0px",
    margin: "0px",
    marginLeft: "15px",
  },
  travelListSearchIcon: {
    width: "20px",
    height: "20px",
    color: "black",
  },
  travelListJobcardimage: {
    width: '29px',
    height: '29px',
    border: "3px solid #e3eaf4",
    borderRadius: '10px',
  },
  travelListDotvalue: {
    width: '15px',
    height: '15px',
  },
  travelListAddressandTime: {
    justifyContent: 'space-between',
    display: 'flex',
  },
  travelListStartDestination: {
    fontFamily: 'Urbanist',
    color: '#6d89af',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: "15px",
    letterSpacing: '0.2px',
  },
  travelListTimeandday: {
    fontFamily: 'Urbanist',
    color: '#6d89af',
    fontWeight: 600,
    fontSize: '10px',
    letterSpacing: '0.2px',
    textAlign: 'right',
    paddingTop: "4px",
    lineHeight: "12px",
  },
  travelListAddressDestination: {
    fontFamily: 'Urbanist',
    color: ' #17181d',
    fontWeight: 600,
    fontSize: '16px',
    letterSpacing: ' 0.2px',
    textAlign: 'center',
    marginTop: "1px",
    lineHeight: "19px",
  },
  travelListPipelineDiv: {
    fontFamily: 'Urbanist',
    color: '#6d89af',
    fontWeight: 600,
    fontSize: '10px',
    width: '84px',
    letterSpacing: ' 0.18181819px',
    border: '1px solid #e3eaf4',
    borderRadius: '12px',
    alignItems: 'center',
    background: '#ffffff',
    paddingBottom: '7px',
    paddingLeft: '12px',
    paddingTop: '6px',
  },
  travelListPositionandname: {
    height: '37px',
    width: ' 97px',
    background: '#F1F4F9',
    alignItems: 'center',
    borderRadius: '8px',
  },
  travelListTrackandTrackIcon: {
    gap: '5px',
    display: 'flex',
    cursor: "pointer",
    alignItems: 'end',
    paddingBottom: "3px",
  },
  travelListTotalKmsText: {
    gap: '5px',
    display: 'flex',
    paddingBottom: "3px",
    alignItems: 'end',
    fontFamily: 'Urbanist',
    color: '#3389ff',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: "17px",
    letterSpacing: '0.21538462px',
    cursor: "pointer",
  },
  travelListTrack: {
    fontFamily: 'Urbanist',
    color: '#f5c42c',
    fontWeight: 600,
    fontSize: '13px',
    paddingTop: '5px',
    letterSpacing: '0.2px',
    textDecoration: "underline",
    lineHeight: "16px",
  },
  travelListTrackIcon: {
    paddingTop: '5px'
  },
  travelListNameandpositionprofile: {
    marginTop: "-20px",
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
  },
  travelListNamediv: {
    fontFamily: ' urbanist',
    color: ' #17181d',
    letterSpacing: '0.2px',
    fontWeight: 600,
    fontSize: '11px',
  },
  travelListPosition: {
    fontFamily: 'Urbanist',
    color: ' #6d89af',
    fontWeight: 500,
    fontSize: '9px',
    paddingTop: "1px",
    letterSpacing: '0.2px',
  },
  travelListParentContainerDiv: {
    maxWidth: "none",
    padding: "0px",
  },
  travelListContentSetup: {
    height: '100%',
    borderLeft: "1px solid #E3EAF4",
  },
  travelListViewSidebarParent: {
    minHeight: "100vh",
    maxWidth: "22.25%",
  },
  travelListMainDataContent: {
    boxSizing: "border-box",
    minHeight: "100vh",
    backgroundColor: "#F8FAFE",
    padding: "24px",
    width: "100%",
  },
  travelListYellowHighlightedText: {
    cursor: 'pointer',
    color: "#F5C42C",
    textDecoration: 'underline',
    fontFamily: 'Urbanist',
    fontWeight: 600,
    fontSize: "14px",
  },
  travelListBreadCrumbBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
  },
  blockTravelListPath: {
    color: "#6D89AF",
    fontSize: "14px",
    letterSpacing: "1px",
    fontWeight: 500,
  },
  currentBlockTravelListTitle: {
    lineHeight: "34px",
    letterSpacing: "1px",
    fontWeight: 700,
    fontSize: "28px",
    marginBottom: "8px",
  },
};
// Customizable Area End
