import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

import { ChangeEvent, ReactNode } from "react";

import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;

  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  selectedOptions: string;
  isSideBarOpen: boolean;
  isDropdownOpen: boolean;
  isSideBarOpen1: boolean;
  allproject: boolean;
  projectdetails: boolean;
  allStudioData: any;
  allStudioSearch: string;
  currentpage: number;
  filteredHousesList: any[] | null;
  allStudioDetailData: any;
  searchValue: string;
  sortValue: string;
  allStudioDatavalue: boolean;
  allStudioDetailRatingandReview: any;
  Id: any;
  count: number;
  isLoading: boolean;
  blocked:boolean;
  Available:number;
  Booked:number;
  Studiocount:number;
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AllStudioViewController extends BlockComponent<
  Props,
  S,
  SS
> {
  putStudioDataApiCalledId:string="";
  getAllStudioApiId: string = "";
  getAllStudioApiId1: string = "";
  getAllStudioId: string = "";
  getStudioDetailApiCalledId: string = "";
  getAllStudiosearchDataApiCalledId: string = "";
  getAllStudiosortDataApiCalledId: string = ""
  getStudioDetailRatingApiCalledId: string = "";
  putAllStudiosApiCalledId:string="";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),

      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),

    ];

    this.state = {
      isSideBarOpen: true,
      isSideBarOpen1: true,
      isDropdownOpen: false,
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      allproject: true,
      projectdetails: false,
      selectedOptions: "",
      allStudioData: [],
      allStudioDetailData: null,
      filteredHousesList: null,
      allStudioSearch: "",
      currentpage: 1,
      searchValue: "",
      sortValue: "All",
      allStudioDatavalue: true,
      allStudioDetailRatingandReview: null,
      Id: "",
      count: 0,
      isLoading: false,
      blocked:false,
      Available:0,
      Booked:0,
      Studiocount:0
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))

      const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))

      this.apiCallValue(apiRequestId, response)



    }

  }

  txtInputWebProps1 = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps1 = {
    ...this.txtInputWebProps1,
    autoCompleteType: "email",
    keyboardType: "email-address",
  }
  doButtonPressed1() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }
  setInputValue1 = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField1 = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount() {
    this.getAllStudioData();
    this.getStudioDetails();
    this.getStudioRatingDetails();
    window.scrollTo(0, 0);
    let isSideBarOpen=await getStorageData("sidebarvalue");
    this.setState({isSideBarOpen:JSON.parse(isSideBarOpen)})
  }
  handlePageChange = async (event: any, value: number) => {
    if (this.state.searchValue) {
      this.getAllStudiosearchDataApiCalledId = await this.apiCall(configJSON.GET_Method, configJSON.getAllStudioSearchDataApiEndPoint + `=${this.state.searchValue}&page=${value}`);
      window.scrollTo(0, 0);
    }
    else if (this.state.sortValue)
    {
      this.getAllStudiosortDataApiCalledId = await this.apiCall(configJSON.GET_Method, configJSON.getAllStudioSortDataApiEndPoint + `=${this.state.sortValue}&page=${value}`)
      window.scrollTo(0, 0);    
    }
    else {
      this.getAllStudioApiId = await this.apiCall(configJSON.GET_Method, configJSON.allStudioApiEndPoint + value);
      window.scrollTo(0, 0);
    }
    this.setState({ currentpage: value });
  };

  apiCall = async (method: string, endPoint: string) => {
    const authenticationToken = await getStorageData('token', false)
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": authenticationToken
    };
    this.setState({ isLoading: true });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };


  getAllStudioData = async () => {
    this.getAllStudioApiId = await this.apiCall(configJSON.GET_Method, configJSON.allStudioApiEndPoint + 1)

  }

  getStudioDetails = async () => {

    let token = await getStorageData("token");
    let id = await getStorageData("id");
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getStudioDetailApiCalledId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStudioDetailApiEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET_Method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getStudioRatingDetails = async () => {

    let token = await getStorageData("token");
    let id = await getStorageData("id");
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getStudioDetailRatingApiCalledId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStudioDetailRatingApiEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET_Method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleSideBar = () => {
    this.setState({
      isSideBarOpen: !(this.state.isSideBarOpen)
    },
    async() => {
      setStorageData("sidebarvalue",this.state.isSideBarOpen.toString())
   }
    
    );
  };
  navigateToStudioDetail = (id: any) => {
    this.getStudioDetails()
    this.props.navigation.navigate("UserStudioDetail");
    setStorageData("id", id)
  };

  handleSideBar1 = () => {

    this.setState({
      isSideBarOpen1: !(this.state.isSideBarOpen1)
    });
  };
  handleDropdown = () => {
    this.setState({
      isDropdownOpen: !this.state.isDropdownOpen
    }
    );
  };

  handleChange = async (e: ChangeEvent<{ value: any; }>, child: ReactNode) => {

    let value = e.target.value === "" ? "All" : e.target.value;
    this.setState({
      sortValue: value,
      currentpage: 1,

    })
    if (value)
      this.getAllStudiosortDataApiCalledId = await this.apiCall(configJSON.GET_Method, configJSON.getAllStudioSortDataApiEndPoint + `=${value}&page=1`)

  };
  handleClick = () => {

    this.setState({ allproject: !(this.state.allproject) });

  }
  setAllStudioData(res: any) {
    this.setState({
      allStudioData: res.data,
      filteredHousesList: res.data.slice(9 * (this.state.currentpage - 1), 9 * this.state.currentpage),
    })
  }



  setAllStudioDetailData(res: any) {
    this.setState({
      allStudioDetailRatingandReview: res
    })

  }
  getStudioDataList(res: any) {
    this.setState({
      count: res.meta.studios,
      Studiocount:res.meta.studios,
      allStudioData: res.data,
      Available:res.meta.available_studio,
      Booked:res.meta.booked_studio,
      isLoading: false
    })

  }
  getStudioDataDetail(res: any) {
    if (res.data) {

      this.setState({
        allStudioDetailData: res.data
      })

    }

  }

  getStudioDataSearch(res: any) {
    this.setState({
      count: res.meta.studio_count,
      allStudioData: res.data,
      isLoading: false
    })
  }

  apiCallValue(apiRequestId: any, response: any) {
    if (apiRequestId === this.getAllStudioApiId) {
      this.getStudioDataList(response)
    }

    else if (this.getStudioDetailApiCalledId == apiRequestId) {
      this.getStudioDataDetail(response)

    }

    else if (apiRequestId == this.getAllStudiosearchDataApiCalledId) {
      this.getStudioDataSearch(response)


    }
    else if (apiRequestId == this.getAllStudiosortDataApiCalledId) {

      this.getStudioDataSort(response)

    }

    else if (apiRequestId == this.getStudioDetailRatingApiCalledId) {
      if (response) {

        this.setAllStudioDetailData(response)
      }

    }
    else if (apiRequestId === this.putAllStudiosApiCalledId) {
      this.putAllStudiosDataHandleResponse(response);
    }
    else if(apiRequestId ===this.putStudioDataApiCalledId){
      if(response){
        this.putStudioDataHandleResponse(response);
      }
    }
    

  }
  getStudioDataSort(response: any) {

    if (this.state.sortValue === "All") {
      this.setState({ count: this.state.Studiocount })
    }
    else if (this.state.sortValue === "Available") {
      this.setState({ count: this.state.Available })
    
    }
    else if(this.state.sortValue === "Booked")
    {
      this.setState({count:this.state.Booked});
    }
    
    this.setState({
      allStudioData:response.data,
      isLoading: false,
    })

  }
  sortApiCallForAllStudios = async () => {
    this.getAllStudiosortDataApiCalledId = await this.apiCall(configJSON.GET_Method, configJSON.getAllStudioSortDataApiEndPoint + `=${this.state.sortValue}&page=1`)
   
  };

  putStudioDataHandleResponse = (response: { data: any }) => {
    if (response.data) {
      this.setState({
        allStudioDetailData: response.data,
        isLoading: false,
      
      })
    }
    else {
      this.setState({ isLoading: false });
      this.showAlert("Error", "Error");
    }
  }

  putAllStudiosDataHandleResponse = (responseJson: { data: { id: number } }) => {
    if (responseJson.data) {
      const currentList = this.state.allStudioData ? [...this.state.allStudioData] : [];
      const id = responseJson.data.id;
      const fIndex = currentList.findIndex((item: any) => item.id == id);
      if (fIndex !== -1) {
        let obj = currentList[fIndex];
        let attributes = obj && obj.attributes;
        attributes = {
          ...attributes,
          blocked: attributes && !attributes.blocked
        }
        obj = {
          ...obj,
          attributes
        }
        currentList[fIndex] = obj;
        this.setState({
          allStudioData: currentList,
          isLoading: false,
        })
      }
    }
    else {
      this.setState({ isLoading: false });
      this.showAlert("Error", "error");
    }
  }
  filterHouses = async (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      currentpage: 1,
      searchValue: event.target.value,
    });
    if (event.target.value)
      this.getAllStudiosearchDataApiCalledId = await this.apiCall(configJSON.GET_Method, configJSON.getAllStudioSearchDataApiEndPoint + `=${event.target.value}&page=1`);
    else
      this.getAllStudioApiId = await this.apiCall(configJSON.GET_Method, configJSON.allStudioApiEndPoint + 1);
  }

  handleBadgeVisibility = async (e: any, id: any, blockStatus: boolean, type?: string) => {
    e.stopPropagation();
    const token = await getStorageData("token", false);
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const attrs = { blocked: !blockStatus }
    if (type === "studios") {
      this.setState({ isLoading: true });
      this.putAllStudiosApiCalledId = requestMessage.messageId;
    }
    const data = {
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.putAllStudioBlockApiEndPoint + id 
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.put_method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleStudioBadgeVisibility = async (e: any, id: any, blockStatus: boolean, type?: string) => {
    e.stopPropagation();
    const token = await getStorageData("token", false);

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const attrs = {
      blocked: !blockStatus
    };
    this.putStudioDataApiCalledId = requestMessage.messageId;

    const data = {
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
   
   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.put_method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.putAllStudioBlockApiEndPoint + id
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleNavigation = (page: string) => {
    this.props.navigation.navigate(page)
  };
  // Customizable Area End
}
