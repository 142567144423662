import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
import moment from "moment";

// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    
    id: string;
    navigation: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    
   address:string;
   state:string;
   requestid:any;
   studioDetailTabValue: number;
   notification: { type: string, open: boolean, message: string, route?: string };
   vendorManageProfileData:any
   bioReadMoreLess:boolean;
   vendorManageProfileProjectsData:any;
   modelType:string;
   studioBookingId: string;
   studioDetailReviewData:any;
   open:boolean;
   reviewvalidation:string;
   ratingvalidation:string;
   rateValue:number;
   comments:string;
   rewableId:number;
   open1:boolean;
   statuspending:any;
   studiotype:any;
   isSideBarOpen: boolean;
   isLoading: boolean;
   isDropdownOpen: boolean;
   tabValue: number;
   allStudioListData: any;
   requestStudioListData: any;
   UpcomingStudioListData: any;
   OngoingStudioListData: any;
   pendingRequestListData: any;
   isModelOpen: boolean;
   statesData: { id: number, name: string, iso2: string }[];
   phList: any[];
   filterValues: any;
   filteredParams: any;
   openRequest: boolean;
   getCountForPagination: any;
   currentStudiosPage: number;
   requestcount:any;
  city:string;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class AllStudioBookingListPHController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getMyBookingsMessageId: string = "";
    getallStudiocallid: string = "";
    getrequestStudiocallid: string = "";
    getOngoingrequestStudiocallid: string = ""
    getUpcomingrequestStudiocallid: string = "";
    getRequestPendingApiCalledId: string = "";
    getProjectListApiCalledId:string="";
    getManageprofileListApiCalledId:string="";
    getRatingListApicalledid:string=""
    acceptApiCallId:string=""
    rejectApiCallId:string=""
    addReviewApiCallId:string=""
    putupdatestudioapiid:string="";
    cancelbookingcallid:string="";
    deletebookingcallid:string=""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceDataMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.RestAPIResponceErrorMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            
            vendorManageProfileData: {
                id: "",
                profile_image: "",
                city: "",
                state: "",
                experience: "",
                role: "",
                gallary_photos: [],
               
                account_detail: {
                  data: {
                    attributes: {
                      full_name: "",
                      country_code: "",
                      phone_number: "",
                      email: "",
                      aadhar_card_number: "",
                      gst_number: "",
                      pan_card_number: "",
                      tan_number: "",
                      account_number: "",
                      ifsc_code: "",
                      branch_name: "",
                    }
                  }

                },
                bank_detail: {
                    data: {
                      attributes: {
                        image: "",
                        bank_name: "",
                      }
                    }
                  },
                profile_bio: {
                  data: {
                    attributes: {
                      about_business: "",
                    }
                  },
                },
                documents: [],
              },
             
              studioDetailReviewData:{},
              open:false,
              reviewvalidation:"",
              ratingvalidation:"",
              rateValue:0,
              comments:"",
              rewableId:0,
              bioReadMoreLess:true,
              modelType:'',
              studioBookingId: '',
              open1:false,
              statuspending:null,
              studiotype:"createType",
              isSideBarOpen: true,
            isLoading: false,
            isDropdownOpen: false,
            tabValue: 0,
            allStudioListData: [],
            pendingRequestListData: [],
            isModelOpen: false,
            statesData: [],
            phList: [],
            filterValues: "",
            filteredParams: "",
            requestStudioListData: [],
            UpcomingStudioListData: [],
            OngoingStudioListData: [],
            openRequest: false,
            getCountForPagination: {
                0: 0,
                1: 0,
                2: 0,
                3: 0
            },
            currentStudiosPage: 1,
            requestcount:null,
            city:'',
            address:'',
            state:'',
            requestid:'',
            studioDetailTabValue: 0,
            vendorManageProfileProjectsData:[],
            notification: { type: "", open: false, message: "" },
   

            // Customizable Area End
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (this.getallStudiocallid === apiRequestCallId) {
                this.setState({ allStudioListData: responseJson.data })
                this.setState({ getCountForPagination: { ...this.state.getCountForPagination, 0: responseJson.meta.total_studios } });
            }
            if (this.getrequestStudiocallid === apiRequestCallId) {
                this.setState({ requestStudioListData: responseJson.data })
                this.setState({ getCountForPagination: { ...this.state.getCountForPagination, 1: responseJson.meta.total_studio_count } });
            }
            if (this.getOngoingrequestStudiocallid === apiRequestCallId) {
                this.setState({ OngoingStudioListData: responseJson.data })
                this.setState({ getCountForPagination: { ...this.state.getCountForPagination, 2: responseJson.meta.total_booking_count } });
            }
            if (this.getUpcomingrequestStudiocallid === apiRequestCallId) {
                this.setState({ UpcomingStudioListData: responseJson.data })
                this.setState({ getCountForPagination: { ...this.state.getCountForPagination, 3: responseJson.meta.total_booking_count } });
            }
            if (this.getRequestPendingApiCalledId === apiRequestCallId) {  
                this.setState({ pendingRequestListData: responseJson.data })
                this.setState({requestcount:responseJson.meta.total_request_count})
            }
            this.requestProfileResponseData(apiRequestCallId,responseJson)
           
        }
        // Customizable Area End
    }
    requestProfileResponseData=(apiRequestCallId:any,responseJson:any)=>{
        if(this.acceptApiCallId===apiRequestCallId)
        {
            this.setState({isLoading:false})
            this.props.navigation.navigate("AllStudioBookingListPH")
        }
        if(this.rejectApiCallId===apiRequestCallId)
        {
            
            this.setState({isLoading:false})
            this.props.navigation.navigate("AllStudioBookingListPH")
        }
        if(this.getProjectListApiCalledId===apiRequestCallId)
        { 
            this.getVendorManageProfileProjectsDataHandleResponse(responseJson);
            
        }
        if(this.getManageprofileListApiCalledId===apiRequestCallId)
        {
            this.getVendorManageProfileDataHandleResponse(responseJson);
        }
        if(this.getRatingListApicalledid===apiRequestCallId)
        {
            this.getStudioDetailReviewDataHandleResponse(responseJson)
        }
        if(this.addReviewApiCallId===apiRequestCallId)
        {
            this.getaddReviewdatalist(responseJson)
        }
        if(this.putupdatestudioapiid===apiRequestCallId)
        {
            this.setState({ isLoading: false });
            this.getallStudioList()
        }
        if(this.cancelbookingcallid===apiRequestCallId)
        {
            console.log("ddddd",responseJson)
            this.props.navigation.navigate("AllStudioBookingListPH")
            this.setState({isLoading:false,tabValue:3})
            this.getUpcomingRequestStudioList();
        }
        if(this.deletebookingcallid===apiRequestCallId)
        {
            this.props.navigation.navigate("AllStudioBookingListPH")
            this.setState({isLoading:false})
            this.getallStudioList()
        }
    }

    // Customizable Area Start
    getVendorManageProfileDataHandleResponse = (vendorManageProfileDataResponse: { errors: { token: string }[], data: any }) => {
        if (vendorManageProfileDataResponse.data) {
          this.setState({ vendorManageProfileData: vendorManageProfileDataResponse.data.attributes });
        } else if (vendorManageProfileDataResponse.errors[0].token) {
          this.setState({ notification: { open: true, type: "error", message: vendorManageProfileDataResponse.errors[0].token, route: "LandingPage" } });
        }
        this.setState({ isLoading: false });
      }
      vendorManageProfileHandleDownloadPDF = (pdfURL: any, fileName: string) => {
        fetch(pdfURL)
          .then(VendorManageProfileResponse => {
            VendorManageProfileResponse.blob().then(blob => {
              let photoUrl = window.URL.createObjectURL(blob);
              let aTag = document.createElement('a');
              aTag.href = photoUrl;
              aTag.download = fileName;
              aTag.click();
            });
          });
      }
      commentsHandle=(e:any)=>{
        if(e.target.value==='')
        {
          this.setState({reviewvalidation:'Please give review'})
        }
        else
        {
          this.setState({reviewvalidation:''})
        
        }
        this.setState({comments:e.target.value})
       }
      handleBioRead = () => {
        this.setState({ bioReadMoreLess: !this.state.bioReadMoreLess });
      }
    handlePageChange = async (event: any, value: number) => {
        if (this.state.tabValue === 0) {
        
            this.getallStudiocallid = await this.apiCall(configJSON.validationApiMethodType, configJSON.getallStudioListPHEndPoint+`?page=${value}&per_page=9`);
        } else if (this.state.tabValue === 1) {
            this.getrequestStudiocallid = await this.apiCall(configJSON.validationApiMethodType, configJSON.getrequestStudioListPHEndPoint + `?page=${value}&per_page=9`);
        } else if (this.state.tabValue === 2) {
            this.getOngoingrequestStudiocallid = await this.apiCall(configJSON.validationApiMethodType, configJSON.getongingStudioListPHEndPoint + `?page=${value}&per_page=9`);
        }
        else if (this.state.tabValue === 3) {
            this.getUpcomingrequestStudiocallid = await this.apiCall(configJSON.validationApiMethodType, configJSON.getupcomingStudioListPHEndPoint + `?page=${value}&per_page=9`);
        }
        this.setState({ currentStudiosPage: value });
    }
    handleRatingModelOpen = () => {
        this.handleModelOpen();
        this.setState({ modelType: "ratingModel" });
      }

      handleSideBar = () => {
        this.setState({
          isSideBarOpen: !this.state.isSideBarOpen
        },
        async() => {
          localStorage.setItem("sidebarvalue",this.state.isSideBarOpen.toString())
    
        }
    
    
        );
      };
      
  handleModelClose = () => {
    this.setState({ isModelOpen: false, modelType: "" });
  }

    getVendorManageProfileProjectsDataHandleResponse = (vendorManageProfileDataResponseJson: { errors: { token: string }[], data: any[] }) => {
        if (vendorManageProfileDataResponseJson.data) {
          this.setState({ vendorManageProfileProjectsData: vendorManageProfileDataResponseJson.data });
          console.log("projectlisiting...................",this.state.vendorManageProfileProjectsData)
        } else if (vendorManageProfileDataResponseJson.errors[0].token) {
          this.setState({ notification: { open: true, type: "error", message: vendorManageProfileDataResponseJson.errors[0].token, route: "LandingPage" } });
        }
        this.setState({ isLoading: false });
      }
    handleDropdown = () => {
        this.setState({
            isDropdownOpen: !this.state.isDropdownOpen
        });
    };

    handleNavigation = (page: string) => {
        this.props.navigation.navigate(page)
    };

    handleAppBarValue = (event: React.ChangeEvent<{}>, value: number) => {
        this.setState({
            tabValue: value,
        });
        
        this.handlePageChange({}, 1);
        
    }


    async componentDidMount() {
      let isSideBarOpen=await getStorageData("sidebarvalue");
     this.setState({isSideBarOpen:JSON.parse(isSideBarOpen)})
      const statuspending=await getStorageData("statusrequest")
      this.setState({statuspending:statuspending})
        this.getallStudioList()
        this.getrequestStudioList();
        this.getOngoingRequestStudioList();
        this.getUpcomingRequestStudioList();
        this.getProjectListing()
         this.getManageProfileListing()
         let rewableid=await getStorageData("studioid")
         let rewableidNumber = parseInt(rewableid, 10); 
         this.setState({ rewableId: rewableidNumber });
         this.getRatingListing()
    }



    getFormattedDate = (date: any) => {
        return moment.utc(date).format("DD MMM, YYYY");
    }



    handleModelOpen = () => {
        this.setState({ isModelOpen: true });
    }

    handleClose = () => {
        this.setState({open:false})
       
      };

    handleCloseNotification = () => {
        this.setState({ notification: { type: "", open: false, message: "" } });
      }
      handleOpen = () =>{
    
        this.setState({rateValue:0,comments:'',ratingvalidation:'',reviewvalidation:''})
        
           this.setState({open:true})
          
        }
        rateHandle=(e:any)=>{
            if(e.target.value==='')
            {
              this.setState({ratingvalidation:'Please give rating'})
            }
            else
            {
              this.setState({ratingvalidation:''})
            
            }
            this.setState({rateValue:e.target.value})
           }

    handleNavigationRequestProfile= async (id:any,studioid:any,profileid:any,status:any)=>{

      setStorageData("dataid", id)
     setStorageData("statusrequest",status)
     console.log("status...",status)
     await setStorageData("studioid",studioid)
   
     console.log("studioid...",studioid)
     setStorageData("profileid",profileid)
     
    
        this.props.navigation.navigate("RequestProfileDetailPH");
        this.getProjectListing();
    }
    handleOpenRequest = (id: number,address:string,city:string,state:string) => {
        this.setState({ openRequest: true })
        setStorageData("id", id)
        console.log("id........", id)
        this.getRequestPending()
        this.setState({city:city,address:address,state:state})
    }
    handleCloseRequest = () => {
        this.setState({ openRequest: false })

    };
    covertToPascalCase = (text: string, trimSpace = false) => {
        return text.split(' ').map((t) => t[0].toUpperCase() + t.slice(1).toLowerCase()).join(trimSpace ? '' : ' ')
      }

    handleStoreFilterValues = (newFilterValues: any) => {
        this.setState({ filterValues: newFilterValues });
    }
    getaddReviewdatalist=(responseValue:any)=>{
        if(responseValue.data)
        {
         
          
        this.setState({isLoading:false})
        this.getRatingListing()
        this.setState({rateValue:0,comments:''})
          
          
        }
        else{
    
    
          alert(responseValue.errors[0])
    
          
    
    
          
    
          this.setState({isLoading:false})
        
        }
      }

    navigateToDetailPage = (studioId: string) => {
        setStorageData("studioId", studioId);
        this.props.navigation.navigate("StudiosBookingDetail");
    }

    Editstudio=async(id:any)=>{
     console.log("idstudio............",id)
     await setStorageData("editstudio",id)
     await setStorageData("studiotype","editstudio")

     
     this.handleOpen1()
    }
    Addnewstudio=async()=>{
      await setStorageData("studiotype","createstudio")
      this.handleOpen1()
    }

    async componentWillUnmount() {
        setStorageData("studioBookingViewStatus", "false");
    }
    apiCall = async (method: string, endPoint: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };

    getStudioDetailReviewDataHandleResponse = (studioBookingDetailResponseJson: { errors: { token: string }[], average_rating: number, rating_star: any, review_rating_count: string, reviews: { data: any[] } }) => {
        
          this.setState({ studioDetailReviewData: studioBookingDetailResponseJson });
        
        this.setState({ isLoading: false });
      }

    getallStudioList = async () => {
        this.getallStudiocallid = await this.apiCall(configJSON.validationApiMethodType, configJSON.getallStudioListPHEndPoint+`?per_page=9&page=1`);
    };
    getrequestStudioList = async () => {
        this.getrequestStudiocallid = await this.apiCall(configJSON.validationApiMethodType, configJSON.getrequestStudioListPHEndPoint+`?per_page=9&page=1`);
    };
    getOngoingRequestStudioList = async () => {
        this.getOngoingrequestStudiocallid = await this.apiCall(configJSON.validationApiMethodType, configJSON.getongingStudioListPHEndPoint+`?per_page=9&page=1`);
    };
    getUpcomingRequestStudioList = async () => {
        this.getUpcomingrequestStudiocallid = await this.apiCall(configJSON.validationApiMethodType, configJSON.getupcomingStudioListPHEndPoint+`?per_page=9&page=1`);
    };
    getRequestPending = async () => {

        let token = await getStorageData("token");
        let id = await getStorageData("id");
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getRequestPendingApiCalledId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getPendingRequestApiEndPoint + `${id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );


        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    handleOpen1 = () => {
        this.setState({ open1: true })
    }
    handleClose1 = () => {
        this.setState({ open1: false })

    };

    acceptApiCall = async() => {
        let token = await getStorageData("token");
        let dataid=await getStorageData("dataid")
        const header = {
          "Content-Type": "application/json",
          "token": token
        };
        const httpBody = {
            "data": {
                "attributes": {
                    "status": "accepted"
                }
            }
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        
        this.acceptApiCallId = requestMessage.messageId;
        
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.acceptstudiorequestApiEndPoint+`/${dataid}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.updatePasswordAPiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
          }


          RejectApiCall = async() => {
            let token = await getStorageData("token");
            let dataid=await getStorageData("dataid")
            const header = {
              "Content-Type": "application/json",
              "token": token
            };
            const httpBody = {
                "data": {
                    "attributes": {
                        "status": "rejected"
                    }
                }
            };
            const requestMessage = new Message(
              getName(MessageEnum.RestAPIRequestMessage)
            );
            this.setState({isLoading:true})
            this.rejectApiCallId = requestMessage.messageId;
            
            requestMessage.addData(
              getName(MessageEnum.RestAPIResponceEndPointMessage),
              configJSON.rejectstudiorequestApiEndPoint+`/${dataid}`
            );
            requestMessage.addData(
              getName(MessageEnum.RestAPIRequestHeaderMessage),
              JSON.stringify(header)
            );
            requestMessage.addData(
              getName(MessageEnum.RestAPIRequestBodyMessage),
              JSON.stringify(httpBody)
            );
            requestMessage.addData(
              getName(MessageEnum.RestAPIRequestMethodMessage),
              configJSON.updatePasswordAPiMethod
            );
            runEngine.sendMessage(requestMessage.id, requestMessage);
              }
    
          handleTabValueChange = (tabVal: number) => {
            this.setState({ studioDetailTabValue: tabVal });
          }
        

          getProjectListing = async () => {

            let token = await getStorageData("token");
            let id = await getStorageData("profileid");
            const header = {
                "Content-Type": configJSON.exampleApiContentType,
                "token": token
            };
            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );
            this.getProjectListApiCalledId = requestMessage.messageId;
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.getProjectListApiEndPoint + `/${id}`
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.validationApiMethodType
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
    
    
            runEngine.sendMessage(requestMessage.id, requestMessage);
        };


        getManageProfileListing = async () => {

            let token = await getStorageData("token");
            let id = await getStorageData("profileid");
            const header = {
                "Content-Type": configJSON.exampleApiContentType,
                "token": token
            };
            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );
            this.getManageprofileListApiCalledId = requestMessage.messageId;
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.getPHManageProfileDetailsAPIEndPoint+`/${id}`
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.validationApiMethodType
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
    
    
            runEngine.sendMessage(requestMessage.id, requestMessage);
        };

        getRatingListing = async () => {

            let token = await getStorageData("token");

            const header = {
                "Content-Type": configJSON.exampleApiContentType,
                "token": token
            };
            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );
            this.getRatingListApicalledid = requestMessage.messageId;
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.getStudioDetailReviewDataAPIEndPoint+`/${this.state.rewableId}`
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.validationApiMethodType
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
    
    
            runEngine.sendMessage(requestMessage.id, requestMessage);
        };
        addReviewApiCallrequestProfile = async() => {

            if(this.state.comments===''&&this.state.rateValue===0)
            {
              this.setState({ratingvalidation:"Please give rating",reviewvalidation:'Please give review'})
            }
            else if(this.state.rateValue===0)
            {
              this.setState({ratingvalidation:"Please give rating"})
            }
            else if(this.state.comments==='')
              {
                this.setState({reviewvalidation:'Please give review'})
                   this.setState({rateValue:0}) 
              }
           
            else
            {
              this.setState({open:false})
                  this.setState({isLoading:true})
                  let token = await getStorageData("token");
                  
            const header = {
              "Content-Type": configJSON.exampleApiContentType,
              "token": token
            };
            const httpBody = {
              "review" : {
                "comment":this.state.comments,
                "given_star":this.state.rateValue,
                "reviewable_id": this.state.rewableId,
                "reviewable_type":"BxBlockCustomizableformworkflow3::Studio"
              }
            };
            const requestMessage = new Message(
              getName(MessageEnum.RestAPIRequestMessage)
            );
            
            this.addReviewApiCallId = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
              );
            
            requestMessage.addData(
              getName(MessageEnum.RestAPIResponceEndPointMessage),
              configJSON.addReviewApiEndPoint
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.manageprofilemethodtype
              );
           
            requestMessage.addData(
              getName(MessageEnum.RestAPIRequestBodyMessage),
              JSON.stringify(httpBody)
            );
            
            runEngine.sendMessage(requestMessage.id, requestMessage);
            }
              
            }
            handleBadgeVisibility = async (e: any, id: any, booking_permission: boolean, type?: string) => {
                e.stopPropagation();
                const token = await getStorageData("token", false);
                const header = {
                  "Content-Type": configJSON.exampleApiContentType,
                  "token": token
                };
            
                const requestMessage = new Message(
                  getName(MessageEnum.RestAPIRequestMessage)
                );
                const attrs = { booking_permission: !booking_permission }
                if (type === "studios") {
                  this.setState({ isLoading: true });
                  this.putupdatestudioapiid = requestMessage.messageId;
                }
                const data = {
                  attributes: attrs,
                };
            
                const httpBody = {
                  data: data,
                };
                requestMessage.addData(
                  getName(MessageEnum.RestAPIRequestHeaderMessage),
                  JSON.stringify(header)
                );
                requestMessage.addData(
                  getName(MessageEnum.RestAPIRequestBodyMessage),
                  JSON.stringify(httpBody)
                );
                requestMessage.addData(
                  getName(MessageEnum.RestAPIResponceEndPointMessage),
                  configJSON.putAllStudioBookingApiEndPoint + id 
                );
                requestMessage.addData(
                  getName(MessageEnum.RestAPIRequestMethodMessage),
                  configJSON.updatePasswordAPiMethod
                );
                runEngine.sendMessage(requestMessage.id, requestMessage);
              }
              cancelbooking = async(id:any) => {
                let token = await getStorageData("token");
                
                const header = {
                  "Content-Type": "application/json",
                  "token": token
                };
                const httpBody = {
                    "data": {
                        "attributes": {
                            "status": "cancelled"
                        }
                    }
                };
                this.setState({isLoading:true})
                const requestMessage = new Message(
                  getName(MessageEnum.RestAPIRequestMessage)
                );
                
                this.cancelbookingcallid = requestMessage.messageId;
                
                requestMessage.addData(
                  getName(MessageEnum.RestAPIResponceEndPointMessage),
                  configJSON.cancelbookingApiEndPoint+`/${id}`
                );
                requestMessage.addData(
                  getName(MessageEnum.RestAPIRequestHeaderMessage),
                  JSON.stringify(header)
                );
                requestMessage.addData(
                  getName(MessageEnum.RestAPIRequestBodyMessage),
                  JSON.stringify(httpBody)
                );
                requestMessage.addData(
                  getName(MessageEnum.RestAPIRequestMethodMessage),
                  configJSON.updatePasswordAPiMethod
                );
                runEngine.sendMessage(requestMessage.id, requestMessage);
                  }

                  deletestudio = async(id:any) => {
                    let token = await getStorageData("token");

                    const header = {
                        "Content-Type": configJSON.exampleApiContentType,
                        "token": token
                    };
                    const requestMessage = new Message(
                        getName(MessageEnum.RestAPIRequestMessage)
                    );
                    this.deletebookingcallid = requestMessage.messageId;
                    requestMessage.addData(
                        getName(MessageEnum.RestAPIResponceEndPointMessage),
                        configJSON.deletestudioApiEndPoint+`/${id}`
                    );
                    requestMessage.addData(
                        getName(MessageEnum.RestAPIRequestMethodMessage),
                        configJSON.deleteAPIMethod
                    );
                    requestMessage.addData(
                        getName(MessageEnum.RestAPIRequestHeaderMessage),
                        JSON.stringify(header)
                    );
            
            
                    runEngine.sendMessage(requestMessage.id, requestMessage);
                      }
                      navigateToStudioDetail = (id: any) => {
                      
                        this.props.navigation.navigate("AllStudioDetailPH");
                        setStorageData("studioidph", id)
                      };

                      navigateToStudioDetailUpcomingandOngoing = (id: any,bookingid:any) => {
                      
                        this.props.navigation.navigate("StudioDetailPH");
                        setStorageData("detailstudioid", id)
                        setStorageData("bookingid",bookingid)
                      };
                    
                    
    
    // Customizable Area End
}

