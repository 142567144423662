import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { IPresignUrlMedia, IImageUrlMedia, ImageUrlMedia } from "./types";
import { Platform } from "react-native";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  countDown: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

let dummyData: ({ name: string | null; imageUrl?: ImageUrlMedia; imageObj: Blob; id?: number | null } | IImageUrlMedia)[];
let dummyBulkUploadData: { presigned_url: string; status: string; id: number; fileObj?: Blob; }[];
let count = 0;
export default class Uploadmedia3Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),

      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      countDown: 0,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start
  apiUploadMediaPresignUrlCallId: string = "";
  apiFileUploadPresignUrlCallId: string = "";
  apiBulkUploadCallId: string = "";
  apiDeleteUploadMediaCallId: string = "";
  // Customizable Area End
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.apiUploadMediaPresignUrlCallId) {
        this.apiUploadMediaPresignUrlHandleResponse(responseJson);
      } else if (apiRequestCallId === this.apiBulkUploadCallId) {
        this.bulkUploadHandleResponse(responseJson);
      } else if (apiRequestCallId === this.apiDeleteUploadMediaCallId) {
        this.deleteUploadMediaHandleResponse(responseJson);
      } else if (apiRequestCallId === this.apiFileUploadPresignUrlCallId) {
        count = count + 1;
        if (count === dummyBulkUploadData.length) {
          this.bulkUpload(dummyBulkUploadData);
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  apiUploadMediaPresignUrlHandleResponse = (responseJson:
    { data: [{ presigned_url: string, id: number }]; errors: [{ token: string }] }) => {
    if (responseJson.data) {
      const bulkUploadData = responseJson.data.map((element: { presigned_url: string, id: number }) => {
        return {
          presigned_url: element.presigned_url,
          status: "approved",
          id: element.id,
        };
      });
      dummyBulkUploadData = bulkUploadData;
      if (Platform.OS == "web") {
        this.fileUploadOnPresignedUrl(bulkUploadData.map((element: { presigned_url: string; status: string; id: number }, index: number) => {
          return element ? { presigned_url: element.presigned_url, status: element.status, id: element.id, fileObj: dummyData[index].imageObj } : { presigned_url: "", status: "", id: 0 }
        }));
      } else {
        this.fileUploadOnPresignedUrlApp(bulkUploadData);
      }
    } else if (responseJson.errors[0].token) {
      this.showAlert("Error", responseJson.errors[0].token);
    }
  }

  bulkUploadHandleResponse = async (responseJson: {
    status: string;
    table: { success: boolean; errors: string };
  }) => {
    if (responseJson.status === "ok") {
      this.showAlert("Sucess", "Successfully Created");
      await this.goBackNavigation();
    } else if (!responseJson.table.success) {
      this.showAlert("Error", responseJson.table.errors);
    }
  };

  deleteUploadMediaHandleResponse = async (responseJson: {
    meta: {
      message: string;
      error: string;
      errors: string[];
    }
  }) => {
    if (responseJson.meta.message) {
      runEngine.debugLog("Success Message", responseJson.meta.message);
    } else if (
      responseJson.meta.error || responseJson.meta.errors
    ) {
      this.showAlert(
        "Error",
        responseJson.meta.error || responseJson.meta.errors.toString()
      );
      await this.goBackNavigation();
    }
  };

  goBackNavigation = async () => {
    setTimeout(() => {
      this.props.navigation.goBack();
    }, 3000);
  }

  mediaPresignurl = async (imagesAndDocs: IPresignUrlMedia[], createdProjectId: number, imageUrlsAndDocUrls: ({ name: string | null; imageUrl?: ImageUrlMedia; imageObj: Blob; id?: number | null; } | IImageUrlMedia)[]) => {
    dummyData = imageUrlsAndDocUrls;
    count = 0;
    if (imagesAndDocs) this.createPreSignUrl(imagesAndDocs, createdProjectId);
    else this.props.navigation.goBack();
  };

  createPreSignUrl = async (imagesAndDocs: IPresignUrlMedia[], createdProjectId: number) => {
    let token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUploadMediaPresignUrlCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.uploadMediaPresignUrlAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        media: {
          imageable_type: "BxBlockProjectportfolio::Projectportfolio",
          imageable_id: createdProjectId,
          meta: imagesAndDocs,
        },
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  fileUploadOnPresignedUrlApp = (
    medias: {
      presigned_url: string;
      status: string;
      id: number;
    }[]
  ) => {
  };

  fileUploadOnPresignedUrl = async (
    medias: { presigned_url: string; status: string; id: number, fileObj?: Blob }[]
  ) => {
    let token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiFileUploadPresignUrlCallId = requestMessage.messageId;

    medias.forEach((element) => {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        element.presigned_url
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        element.fileObj
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.putAPiEndMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    });
  };

  bulkUpload = async (
    medias: { presigned_url: string; status: string; id: number }[]
  ) => {
    let token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiBulkUploadCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.bulkUploadAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ media: medias })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  deleteUploadMedia = async (mediaId: number) => {
    let token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteUploadMediaCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteUploadMediaAPIEndPoint + mediaId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  // Customizable Area End
}
