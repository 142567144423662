import React, { useState } from 'react';
import {
    Box,
    Typography,
    styled,
    withStyles,
    Grid,
    InputLabel,
    Input
} from "@material-ui/core";

import Modal from '@material-ui/core/Modal';
import { CustomButton } from '../../blocks/email-account-login/src/CustomButton';
const images = require("./assets");

const CustomInputlabel = withStyles({
    root: {
        fontFamily: 'Urbanist',
        color: '#6D89AF',
        fontWeight: 600,
        letterSpacing: '0.2px',
        fontSize: '13px',
        lineHeight: "18px",
        paddingBottom: "6px",
        paddingTop: "20px"
    },
})(InputLabel);

const StyledModal = styled(Modal)({
    position: 'fixed',
    zIndex: 1300,
    inset: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

const style = {
    width: 343,
    borderRadius: '20px',
    padding: '24px',
    backgroundColor: 'white',
    boxShadow: 'none',
    // height: 192,
};

const CustomButtonBox = styled(CustomButton)({
    '& .MuiButton-label': {
        fontWeight: 600,
        fontSize: 14,
        color: '#ffffff',
        letterSpacing: '0.4px',
        lineHeight: '24px',
    }
});

function BudgetSheetUploadCSVModel({ ...props }) {
    const [uploadFileError, setUploadFileError] = useState(false);
    const [uploadFile, setUploadFile] = useState();

    const handleInputFileSelect = (event: any) => {
        if (event.target.files) {
            setUploadFile(event.target.files[0]);
        }
        if (!event.target.files) {
            setUploadFileError(true);
        } else setUploadFileError(false);
    };

    const handleUploadFile = () => {
        if (!uploadFile) {
            setUploadFileError(true);
            return false;
        }
        props.handleFileUpload(uploadFile);
    }

    return (
        <StyledModal
            aria-labelledby="unstyled-modal-title"
            aria-describedby="unstyled-modal-description"
            open={props.open}
            onClose={props.handleClose}
        >
            <Box sx={style}>
                <Grid container style={{ height: 24, color: '#17181d', fontFamily: 'Urbanist', fontSize: 20, fontWeight: 700, textAlign: 'end', letterSpacing: '0.2px' } as React.CSSProperties}>
                    <Grid xs={9}>
                        Upload CSV file
                    </Grid>
                    <Grid xs={3}>
                        <img style={{ cursor: 'pointer' }} src={`${images.crossIcon}`} onClick={props.handleClose} />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <CustomInputlabel>Upload CSV</CustomInputlabel>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Input
                        required
                        data-test-id={"projectDocuments"}
                        type="file"
                        inputProps={{ accept: '.csv' }}
                        name="projectDocuments"
                        disableUnderline={true}
                        onChange={handleInputFileSelect}
                    />
                    {uploadFileError && <Typography style={{ color: 'red', fontFamily: 'Urbanist', fontSize: '12px', fontWeight: 500, lineHeight: '16px', letterSpacing: '0.17142858px' }}>Please select a file</Typography>}
                </Grid>
                <Grid item style={{ marginTop: 28 }}>
                    <CustomButtonBox data-test-id="sign_in_button" style={{ height: 48, background: "#17181d" } as React.CSSProperties} onClick={handleUploadFile}>
                        Upload
                    </CustomButtonBox>
                </Grid>
            </Box>
        </StyledModal>
    )
}

export default BudgetSheetUploadCSVModel;