import React from "react";

import {
    // Customizable Area Start
    Grid,
    InputLabel,
    Divider,
    AppBar,
    Tabs,
    Tab,
    Card
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import { webStyle } from "./FullTeamVisibility.web";
import "./fullVisibility.css";
import AllProjectsCardController, { Props, configJSON } from "./AllProjectsCardController";
import ProjectCard from "./ProjectCard.web";
import { webStyle_card } from "./ProductionHouseDetail.web";
// Customizable Area End

export default class AllProjectsCard extends AllProjectsCardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {

        return (
            // Customizable Area Start
            <Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <InputLabel style={webStyle_card.productionHouseLabel}>{configJSON.project}</InputLabel>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Divider style={{ marginTop: '3%', backgroundColor: "#e3eaf4" }} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <AppBar position="static" className="appbar app_bar" color="transparent">
                        <Tabs
                            value={this.state.tabValue}
                            onChange={this.handleAppBarValue}
                            variant="scrollable"
                            scrollButtons="on"
                            TabIndicatorProps={{ style: { background: "orange", height: '2px' } as React.CSSProperties}}
                            aria-label="scrollable prevent tabs example"
                        >
                            <Tab className={this.state.tabValue == 0 ? "active_but" : "non_active_but"} label={`${configJSON.allPText} ${this.props.allProjects.length | 0}`} />
                            <Tab className={this.state.tabValue == 1 ? "active_but" : "non_active_but"} label={`${configJSON.onGoing} ${this.props.ongoingProjects.length | 0}`} />
                            <Tab className={this.state.tabValue == 2 ? "active_but" : "non_active_but"} label={`${configJSON.upComing} ${this.props.upcomingProjects.length | 0}`} />
                            <Tab className={this.state.tabValue == 3 ? "active_but" : "non_active_but"} label={`${configJSON.completedProject} ${this.props.completedProjects.length | 0}`} />
                        </Tabs>
                    </AppBar>
                </Grid>
                {
                    this.state.tabValue == 0 &&
                    <Grid container spacing={2} className="description_grid" style={{ marginBottom: '1%' }}>
                        {
                            this.props.allProjects.map((item: any, index: number) => {
                                return <Grid item key={`${index}_allprojectlist`} xs={12} sm={12} md={6} lg={6} xl={6} data-test-id="card_parent_onclick">
                                    <Card style={webStyle.card}>
                                        <ProjectCard id={item.id} navigation={this.props.navigation} item={item.attributes} />
                                    </Card>
                                </Grid>
                            })}
                    </Grid>
                }
                {
                    this.state.tabValue == 1 &&
                    <Grid container spacing={2} className="description_grid" style={{ marginBottom: '1%' }}>
                        {
                            this.props.ongoingProjects.map((item: any, index: number) => {
                                return <Grid item key={`${index}_allprojectlist`} xs={12} sm={12} md={6} lg={6} xl={6} data-test-id="card_parent_onclick">
                                    <Card style={webStyle.card}>
                                        <ProjectCard id={item.id} navigation={this.props.navigation} item={item.attributes} />
                                    </Card>
                                </Grid>
                            })}
                    </Grid>
                }
                {
                    this.state.tabValue == 2 &&
                    <Grid container spacing={2} className="description_grid" style={{ marginBottom: '1%' }}>
                        {
                            this.props.upcomingProjects.map((item: any, index: number) => {
                                return <Grid item key={`${index}_allprojectlist`} xs={12} sm={12} md={6} lg={6} xl={6} data-test-id="card_parent_onclick">
                                    <Card style={webStyle.card}>
                                        <ProjectCard id={item.id} navigation={this.props.navigation} item={item.attributes} />
                                    </Card>
                                </Grid>
                            })}
                    </Grid>
                }
                {
                    this.state.tabValue == 3 &&
                    <Grid container spacing={2} className="description_grid" style={{ marginBottom: '1%' }}>
                        {
                            this.props.completedProjects.map((item: any, index: number) => {
                                return <Grid item key={`${index}_allprojectlist`} xs={12} sm={12} md={6} lg={6} xl={6} data-test-id="card_parent_onclick">
                                    <Card style={webStyle.card}>
                                        <ProjectCard id={item.id} navigation={this.props.navigation} item={item.attributes} />
                                    </Card>
                                </Grid>
                            })}
                    </Grid>
                }
            </Grid >
            // Customizable Area End
        );
    }
}

// Customizable Area Start
// Customizable Area End