import React from "react";
import { Card, Box, CardMedia, CardContent, Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { JobCardImage, LocationIconjob, dotsIcon, headingicon,TrackLocation ,doticon, MaskCopy,Trackicon } from "./assets";


interface MyProps {
    data: any;
}



const useStyles = makeStyles({
    cardDivWithOpenSideBar: () => ({
        width: 300,
        //height: 240,
        borderRadius: 14,
        padding: 8,
        border: "1px solid #E1E6EC",
        position: 'relative',
        '& .MuiCardContent-root': {
            padding: 0
        },
        "@media(max-width: 500px)": {

        },
        "@media(max-width: 1300px)": {
            width: '270px',
        },
    }),
    cardDivWithClosedSideBAr: () => ({
        width: 346,
        borderRadius: 14,
        padding: 8,
        border: "1px solid #E1E6EC",
        position: 'relative',
        '& .MuiCardContent-root': {
            padding: 0
        },
        "@media(max-width: 500px)": {

        },
    }),
    parentBoxValue: () => ({
        display: "flex",
        justifyContent: 'space-between',



    }),
    parentBox: () => ({}),
    ParentSubheading1: () => ({
        display: "flex",
        gap: "5px",

    })
    ,
    headingDept: () => ({
        display: 'flex',
        justifyContent: 'space-between',
    })
    ,
    positionDiv: () => ({
        display: 'flex',
        gap: '5px',
        borderRadius: '15px',
        backgroundColor: '#f8fafe',
        borderColor: '#979797',
        height: 31,
        width: 101,
        alignItems: 'center'
    }),
    iconposition: () => ({

    }),
    DeptDiv: () => ({
        fontSize: '13px',
        fontFamily: 'urbanist',
        color: '#17181D',
        fontWeight: 500,
        lineHeight: '16px',

    }),
    Dept: () => ({}),
    mainDiv: () => ({
        paddingTop: '4px',
        lineHeight: '19px',
        fontSize: '16px',
        fontWeight: 600,
        fontFamily: 'urbanist',
        color: '#17181D',
        display: 'flex',
        gap: '4px'
    }),
    YrsDiv: () => ({
        display: 'flex',
        gap: '4px',
        paddingTop: '6px',
        '& .MuiBox-root': {
            fontSize: '13px',
            fontWeight: '600px',

            color: '#6d89af',
        }
    }),
    Yrstext: () => ({
        paddingTop: '6px',
        fontSize: '13px',
        fontWeight: 600,
        color: '#6d89af',
        fontFamily: 'urbanist',
        '& .MuiBox-root': {
            fontSize: '13px',
            fontWeight: '600px',
        }
    }),
    jobiconyear: () => ({
        height: '16px',
        width: '16px'
    }),
    doticon: () => ({
        height: '24px',
        width: '24px'
    }),
    rupeeicon: () => ({
        paddingTop: '2px',
        height: '16px',
        width: '16px'
    }),
    headingMain: () => ({}),
    rupeesDiv: () => ({
        display: 'flex',
        gap: '4px',
        borderBottom: '1px solid #e3eaf4',
        paddingBottom: '11px',
        '& .MuiBox-root': {
            display: 'flex',
            gap: '4px',
            fontSize: '14px',
            color: '#6d89af',
            fontWeight: 400,
            fontFamily: 'Helvetica',
            paddingTop: '5px',

        }

    }),
    jobcardimage: () => ({

        height: '40px',
        width: '40px',
        borderRadius: '10px'

    }),
    footerDiv: () => ({
        display: "flex"
    }),
    addressDiv: () => ({
        paddingTop: '13px'
    }),
    address1: () => ({
        fontSize: '14px',
        fontFamily: 'urbanist',
        fontWeight: 600,
        color: '#17181D',
    }),
    address2: () => ({
        display: 'flex',
        gap: '4px'

    }),
    ParentSubheading2: () => ({
        fontSize: '11px',
        fontFamily: 'urbanist',
        fontWeight: 500,
        color: '#6d89af',
        paddingTop: '14px'

    }),
    locationDiv: () => ({
        fontSize: '12px',
        fontWeight: 600,
        fontFamily: 'urbanist',
        color: '#6d89af',


    }),
    ParentBottomDiv: () => ({
        display: 'flex',
        justifyContent: 'space-between'
    }),
    Div2: () => ({
        paddingTop: '12.5px'
    }),
    rupeetext: () => ({
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '19px',
        fontFamily: 'urbanist'
    }),
    button3: {
        cursor: "pointer",
        color: "black",
        backgroundColor: "#ffffff",
        textTransform: "none",
        width: "152px",
        borderRadius: "14px",
        //position: "absolute",
        bottom: "16px",
        height: "38px",
        fontWeight: 600,
        fontSize: "12px",
        paddingTop: "10px",
        paddingBottom: "14px",
        marginBottom: "5px",
        fontFamily: "urbanist",
        border: '1px solid #17181d'
    },
    submitButton: {
        cursor: "pointer",
        color: "#ffffff",
        backgroundColor: "#17181D",
        textTransform: "none",
        width: "152px",
        border: "none",
        borderRadius: "14px",
        //position: "absolute",
        bottom: "16px",
        height: "38px",
        fontWeight: 600,
        fontSize: "12px",
        paddingTop: "10px",
        paddingBottom: "14px",
        marginBottom: "5px",
        fontFamily: "urbanist",
    },
    dotanddestination: {
        display: 'flex',
        gap: '5px',
        alignItems: 'center'
    },
    dotdiv: {},
    DestinationParent: {},
    dotvalue: {
        height: '11px',
        width: '11px'
    },
    addressandTime: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    startDestination: {
        color: '#6d89af',
        fontFamily: 'Urbanist',
        fontSize: '12px',
        fontWeight: 500,
        letterSpacing: '0.2px',
    },
    timeandday: {
        color: '#6d89af',
        fontFamily: 'Urbanist',
        fontSize: '10px',
        fontWeight: 600,
        textAlign: 'right',
        letterSpacing: '0.2px',
    },
    addressDestination: {
        color: ' #17181d',
        fontFamily: 'Urbanist',
        fontSize: '16px',
        fontWeight: 600,
        textAlign: 'center',
        letterSpacing: ' 0.2px'
    },
    pipelineDiv: {
        color: '#6d89af',
        fontFamily: 'Urbanist',
    fontSize: '10px',
     fontWeight: 600,
   letterSpacing:' 0.18181819px',
   width: '84px',
  borderRadius: '12px',
  border: '1px solid #e3eaf4',
  background: '#ffffff',
  alignItems:'center',
  paddingTop:'6px',
  paddingLeft:'12px',
  paddingBottom:'7px'
  
    },
    positionandname:{
        width:' 97px',
  height: '37px',
  borderRadius: '8px',
  background: 'rgba(227,234,244,0.5)',
  alignItems:'center',
  paddingTop:'5px',
  paddingLeft:'5px'
  
    },
    TrackandTrackIcon:{
        display:'flex',
        gap:'5px',
        alignItems:'center'
    },
    Track:{
        color: '#f5c42c',
        fontFamily: 'Urbanist',
        fontSize: '13px',
        fontWeight: 600,
        letterSpacing: '0.2px',
        paddingTop:'5px'
        
    },
    TrackIcon:{
        paddingTop:'5px'
    },
    nameandpositionprofile:{
        display:'flex',
        justifyContent:'space-between',
        paddingTop:'11px',
        alignItems:'center',
        paddingLeft:'10px',
        paddingBottom:'11px'
    },
    namediv:{
        color:' #17181d',
  fontFamily:' urbanist',
  fontSize: '11px',
  fontWeight: 600,
  letterSpacing: '0.2px',
    },
    position:{
        color:' #6d89af',
        fontFamily: 'Urbanist',
        fontSize: '9px',
        fontWeight: 500,
        letterSpacing: '0.2px',
    }

});

export default function MyJobCard(props: MyProps) {

    const classes = useStyles();

    return (
        <Box className={classes.parentBox}>
            <Card className={classes.cardDivWithOpenSideBar}>
                <Box className={classes.addressandTime}>
                    <Box className={classes.dotanddestination}>
                        <Box className={classes.dotdiv}>
                            <img src={doticon} alt="" className={classes.dotvalue} />
                        </Box>
                        <Box className={classes.DestinationParent}>
                            <Box className={classes.startDestination}>
                                Start Destination
                            </Box>
                            <Box className={classes.addressDestination}>
                                Vadodara, Gujrat
                            </Box>
                        </Box>
                        <Box>

                        </Box>


                    </Box>
                    <Box className={classes.timeandday}>
                        Today | 10:04AM
                    </Box>

                </Box>
                <Box className={classes.nameandpositionprofile}>
                    <Box>
                        <img src={MaskCopy} alt="" className={classes.jobcardimage} />
                    </Box>
                    <Box className={classes.pipelineDiv}>
                        Pipeline Staff
                    </Box>
                    <Box className={classes.positionandname}>
                        <Box className={classes.namediv}>
                        Sara Scholz
                        </Box>
                        <Box className={classes.position}>
                        Assistant Director
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.addressandTime}>
                    <Box className={classes.dotanddestination}>
                        <Box className={classes.dotdiv}>
                            <img src={TrackLocation} alt="" className={classes.dotvalue} />
                        </Box>
                        <Box className={classes.DestinationParent}>
                            <Box className={classes.startDestination}>
                                End Destination
                            </Box>
                            <Box className={classes.addressDestination}>
                            Nashik, Maharashtra
                            </Box>
                        </Box>
                        <Box>

                        </Box>


                    </Box>
                    <Box className={classes.TrackandTrackIcon}>
                        <Box className={classes.Track}>
                        Track 
                        </Box>
                        <Box className={classes.TrackIcon}>
                        <img src={Trackicon} alt="" className={classes.dotvalue} />
                            </Box>
                    </Box>

                </Box>
            </Card>
        </Box>
    );
}
