import React, { useState } from 'react';
import {
    Box,
    Typography,
    TextField,
    styled,
    withStyles,
    Grid,
    InputLabel
} from "@material-ui/core";

import Modal from '@material-ui/core/Modal';
import { CustomButton } from '../../blocks/email-account-login/src/CustomButton';
const images = require("./assets");

const CustomInputlabel = withStyles({
    root: {
        fontFamily: 'Urbanist',
        color: '#6D89AF',
        fontWeight: 600,
        letterSpacing: '0.2px',
        fontSize: '13px',
        lineHeight: "18px",
        paddingBottom: "6px",
        paddingTop: "20px"
    },
})(InputLabel);

const CssTextField = withStyles({
    root: {
        borderColor: "#f5c42c",
        "& .MuiInput-underline:after": {
            borderBottomColor: "#f5c42c"
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#E3EAF4",
            },
            "&:hover fieldset": {
                borderColor: "#f5c42c",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#f5c42c",
                backgroundColor: 'rgba(245,196,44,0.04)',
            },
            borderRadius: "14px",
            fontWeight: 400,
            color: '#323232',
            height: 48,
            "& .MuiOutlinedInput-input": {
                borderColor: 'rgba(245,196,44,0.04)',
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '18px',
                color: '#17181D',
                padding: '0px 14px',
                fontFamily: 'Urbanist',
            }
        },
        "& .MuiInputBase-input::placeholder": {
            color: '#6d89af',
            fontWeight: 500
        },
    }
})(TextField);

const StyledModal = styled(Modal)({
    position: 'fixed',
    zIndex: 1300,
    inset: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

const style = {
    width: 343,
    borderRadius: '20px',
    padding: '24px',
    backgroundColor: 'white',
    boxShadow: 'none',
    // height: 192,
};

const CustomButtonBox = styled(CustomButton)({
    '& .MuiButton-label': {
        fontWeight: 600,
        fontSize: 14,
        color: '#ffffff',
        letterSpacing: '0.4px',
        lineHeight: '24px',
    }
});

function BudgetSheetModel({ ...props }) {
    const [departmentError, setDepartmentError] = useState(false);
    const [departmentName, setDepartmentName] = useState(props.editDepartmentData?.name || "");

    const handleInputDepartmentName = (event: any) => {
        setDepartmentName(event.target.value);
        if (!event.target.value) {
            setDepartmentError(true);
        } else setDepartmentError(false);
    };

    const handleInputFocus = (labelName: string) => {
        const nameLabel = document.getElementById(labelName);
        if (nameLabel) {
            nameLabel.style.color = "#F5C42C";
        }
    }

    const handleInputBlur = (labelName: string) => {
        const nameLabel = document.getElementById(labelName);
        if (nameLabel) {
            nameLabel.style.color = "#6D89AF";
        }
    }

    const handleSaveDepartment = () => {
        if (!departmentName) {
            setDepartmentError(true);
            return false;
        }
        if (props.editDepartmentData?.name) {
            props.handleUpdateDepartment(departmentName, props.editDepartmentData.id);
        } else {
            props.handleAddDepartment(departmentName);
        }
    }

    return (
        <StyledModal
            aria-labelledby="unstyled-modal-title"
            aria-describedby="unstyled-modal-description"
            open={props.open}
            onClose={props.handleClose}
        >
            <Box sx={style}>
                <Grid container style={{ height: 24, color: '#17181d', fontFamily: 'Urbanist', fontSize: 20, fontWeight: 700, textAlign: 'end', letterSpacing: '0.2px' } as React.CSSProperties}>
                    <Grid xs={9}>
                        {props.editDepartmentData ? "Update Dept." : "Add New Dept."}
                    </Grid>
                    <Grid xs={3}>
                        <img src={`${images.crossIcon}`} style={{ cursor: 'pointer' }} onClick={props.handleClose} />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <CustomInputlabel id={props.editDepartmentData ? "departmentnameedit" : "departmentname"}>Department Name</CustomInputlabel>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <CssTextField data-test-id="email_id" defaultValue={departmentName} type="text" id={props.editDepartmentData ? "departmentnameedit" : "departmentname"} onChange={(e: any) => { handleInputDepartmentName(e) }} variant="outlined" className="email_input" placeholder="Enter department name" style={{
                        color: "red"
                    }} onFocus={() => {
                        handleInputFocus(props.editDepartmentData ? "departmentnameedit" : "departmentname");
                    }}
                        onBlur={() => {
                            handleInputBlur(props.editDepartmentData ? "departmentnameedit" : "departmentname");
                        }} />
                    {departmentError && <Typography style={{ color: 'red', fontFamily: 'Urbanist', fontSize: '14px', fontWeight: 600, lineHeight: '18px', letterSpacing: '0.2px' }}>Department name is required</Typography>}
                </Grid>
                <Grid item style={{ marginTop: 28 }}>
                    <CustomButtonBox data-test-id="sign_in_button" style={{ height: 48, background: "#17181d" } as React.CSSProperties} onClick={handleSaveDepartment}>
                        {props.editDepartmentData ? 'Update' : 'Save'}  Department
                    </CustomButtonBox>
                </Grid>
            </Box>
        </StyledModal>
    )
}

export default BudgetSheetModel;