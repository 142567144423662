import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Chip from "@material-ui/core/Chip";
import { makeStyles,withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Downshift from "downshift";
import { CustomTextField } from "../../blocks/email-account-login/src/CustomButton";
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  chip: {
    //margin: theme.spacing(0.5, 0.25)
  }
}));
const CustomCSSOfTextField = withStyles({
    root: {
      //backgroundColor: 'white',
      borderColor: "#f5c42c",
      "& .MuiOutlinedInput-root": {
        "& fieldset": { borderColor: "#E3EAF4" },
        "&:hover fieldset": { borderColor: "#f5c42c" },
        "&.Mui-focused fieldset": {
          backgroundColor: 'rgba(245,196,44,0.04)',
          borderColor: "#f5c42c",
        },
        borderRadius: "14px",
        fontWeight: 400,
        color: '#323232',
        "& .MuiOutlinedInput-input": {
          color: '#6D89AF',
          fontWeight: 600,
          fontSize: '14px',
          borderColor: '#e3eaf4',
          lineHeight: '19px',
        }
      },
      "& .MuiOutlinedInput-root.Mui-disabled": {
        "& fieldset": {
          border: "none"
        },
        "&:hover fieldset": {
          border: "none"
        },
        "&.Mui-focused fieldset": {
          border: "none",
          backgroundColor: '#f8fafe'
        },
        border: "none",
        backgroundColor: '#f8fafe',
        color: '#18272a',
        borderRadius: "14px",
        "& .MuiOutlinedInput-input": {
          fontWeight: 600,
          color: '#18272a',
          lineHeight: '19px',
          border: "none",
          fontSize: '14px',
        }
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "#f5c42c"
      },
      "& .MuiInputBase-input::placeholder": {
        color: '#6d89af',
        fontWeight: 500,
        fontFamily: 'Urbanist',
        height:"18px",
        overflow:"hidden"
      },
     "& .MuiChip-root":{
        backgroundColor:'#E3EAF4',
        color:'#6d89af',
        fontFamily: 'Urbanist',
     },
     '&.chipbox .MuiInputBase-multiline': {
        flexWrap: 'wrap',
        gap: 10,
        minHeight: 55
     },
     '& .imagebox':{
        fontSize:'10px'
     }
    }
  })(CustomTextField);

export default function TagsInput({ ...props }) {
  const classes = useStyles();
  const { selectedTags, placeholder, tags, ...other } = props;
  const [inputValue, setInputValue] = React.useState<string>("");
  console.log("props123 studiotype API && --> ",props.studiotype != "" && props.studiotype);
  console.log("props123 studiotype API props --> ", props.studiotype );
  
  // const [selectedItem, setSelectedItem] = React.useState<any>([]);
  // useEffect(() => {
  //   setSelectedItem(tags);
  // }, [tags]);
  
  // useEffect(() => {
  //   selectedTags(selectedItem);
  // }, [selectedItem, selectedTags]);

  function handleKeyDown(event:any) {
    if (event.key === "Enter") {
      const newSelectedItem = [...tags];
      const duplicatedValues = newSelectedItem.indexOf(
        (event?.target?.value?.trim()  as any)
      );

      if (duplicatedValues !== -1) {
        setInputValue("");
        return;
      }
      if (!event.target.value.replace(/\s/g, "").length) return;

      newSelectedItem.push(event.target.value.trim());
      selectedTags(newSelectedItem)
      // setSelectedItem(newSelectedItem);
      setInputValue("");
    }
    if (
      tags.length &&
      !inputValue.length &&
      event.key === "Backspace"
    ) {
      selectedTags(tags.slice(0, tags.length - 1));
    }
  }
  function handleChange(item:any) {
    let newSelectedItem = [...tags];
    if (newSelectedItem.indexOf(item) === -1) {
      newSelectedItem = [...newSelectedItem, item];
    }
    setInputValue("");
    selectedTags(newSelectedItem);
  }

  const handleDelete = (item:string) => () => {
    const newSelectedItem = [...tags];
    newSelectedItem.splice(newSelectedItem.indexOf(item), 1);
    selectedTags(newSelectedItem);
  };
  function handleInputChange(event:any) {
    setInputValue(event.target.value);
  }
  return (
    <React.Fragment>
      
      <Downshift
        id="downshift-multiple"
        inputValue={inputValue}
        onChange={handleChange}
        selectedItem={tags}
      >
        {({ getInputProps }) => {
          const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
            onKeyDown: handleKeyDown,
            placeholder
          });
          return (
            <div>
              <CustomCSSOfTextField
              className="chipbox"
              multiline
                InputProps={{
                  startAdornment: tags.map((item:any) => (
                    <Chip
                      key={item}
                      tabIndex={-1}
                      label={item}
                      className={classes.chip}
                      onDelete={handleDelete(item)}
                       deleteIcon={<CloseIcon className="imagebox"/>}
                    />
                  )),
                  onBlur,
                  onChange: event => {
                    handleInputChange(event);
                    onChange(event);
                  },
                  onFocus
                }}
                {...other}
                {...inputProps}
              />
            </div>
          );
        }}
      </Downshift>
    </React.Fragment>
  );
}
TagsInput.defaultProps = {
  tags: []
};
TagsInput.propTypes = {
  selectedTags: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string)
};

