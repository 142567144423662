Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Analytics";
exports.labelBodyText = "Analytics Body";

exports.getAnalyticsProjectsAPI = "bx_block_projecttasktracking2/analytics_projects?type=";
exports.getAnalyticsInvoicesAPI = "bx_block_invoicebilling/analytics_invoice?type=";
exports.getAnalyticsUsersAPI = "account_block/analytics_users?type=";
exports.getAnalyticsBalanceSheetAPI = "bx_block_categories/budget_details/project_budget_sheet?start_year=";
exports.viewDetailedAnalyticProjectsAPI = "bx_block_projecttasktracking2/view_detailed_analytic_projects?type=";
exports.viewDetailedAnalyticInvoicesAPI = "bx_block_invoicebilling/view_detailed_analytic_invoices?type=";
exports.btnExampleTitle = "CLICK ME";
// Customizable Area End