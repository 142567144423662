import React, { useState, useEffect } from "react";
import { Grid, Link, Box, Typography, Divider, Badge, Avatar } from '@material-ui/core';
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import { styled } from '@material-ui/core/styles';
import { RightIcon, RightmarkIcon, LocationIconjob, jobpositionicon, jobyrs, rupeeiconjob, JobProfileDetail, DateIconView, checkedImage, iconjobdetail } from "./assets";
import Rating from '@material-ui/lab/Rating';
import LinearProgress from '@material-ui/core/LinearProgress';
import VendorReviewersComponent from "./VendorReviewers";
import ReviewerComponent from "./Reviewer";
import VendorReviewerComponent from "./VendorReviewers";
import { getStorageData, setStorageData } from "../../framework/src/Utilities";
import Modal from '@material-ui/core/Modal';
import RateNowCard from "./RateNowCard";
import StarBorderIcon from '@material-ui/icons/StarBorder';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { ActivityIndicator } from "react-native";
interface MyProps {
    data: any;
    datavalue: any;
    withdrawJob: any;
    vendorjobid: any
    open: boolean,
    handleClose: any;
    handleOpen: any;
    addReviewApiCall: any;
    rateHandle: any
    ratevalue: any;
    comments: any;
    commentsHandle: any,
    ratingvalidation: string;
    reviewvalidation: string;
    navigateToPHDetailPage: any;
}

export const StyledBadge = withStyles(() =>
    createStyles({
        badge: {
            color: "#44b700",
            "&::after": {
                position: "absolute",
                right: -3,
                top: 13,
                padding: "0 4px",
                width: "100%",
                height: "100%",
                borderRadius: "50%",
                content: '""'
            }
        },
        "@keyframes ripple": {
            "0%": {
                transform: "scale(.8)",
                opacity: 1
            },
            "100%": {
                transform: "scale(2.4)",
                opacity: 0
            }
        }
    })
)(Badge);

const useStyles = makeStyles({
    jobiconyear: () => ({}),
    LocationIcon: () => ({}),
    ParentDiv: () => ({
    }),
    icon: {},
    HeadingParentDiv: () => ({
        display: "flex",
        justifyContent: 'space-between',
        "@media screen and (max-width: 500px)": {
            display: 'flex',
            flexDirection: 'column',
            gridRowGap: '10px'
        }
    }),
    Headingname: () => ({
        display: 'flex',
        flexDirection: 'column'
    }),
    positionDiv: () => ({
        display: 'flex',
        gap: '5px',
        alignItems: 'center',
        borderRadius: '18px',
        border: '1px solid',
        borderColor: ' #e3eaf4',
        backgroundColor: '#ffffff',
        width: "150px",
        height: '36px',
    }),
    DeptDiv: () => ({
        fontSize: '16px',
        color: '#6f7c8e',
        fontFamily: 'urbanist',
        fontWeight: 600,
        paddingBottom: '12px'
    }),
    expDiv: () => ({
        fontSize: '16px',
        color: '#6d89af',
        fontWeight: 600,
        lineHeight: '19px',
        fontFamily: 'urbanist'
    }),
    Headingaddress: () => ({}),
    Positiontext: () => ({
        fontSize: '24px',
        color: '17181D',
        fontFamily: 'Urbanist',
        fontWeight: 700,
        lineHeight: '29px',
        paddingBottom: '8px',
        letterSpacing: "0.26666668px",
    }),
    CityandStateDiv: () => ({
        display: 'flex',
        paddingTop: '10px',
        alignItems: 'center',
        paddingBottom: '10px'
    }),
    CityandState: () => ({
        fontFamily: 'urbanist',
        fontWeight: 600,
        fontSize: '16px',
        color: '17181D'
    }),
    DayDiv: () => ({
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
        justifyContent: "end",
    }),
    DayText: () => ({
        fontFamily: 'urbanist',
        fontWeight: 600,
        fontSize: '16px',
        color: '17181D'
    }),
    expicon: () => ({
        height: "16px",
        width: '16px',
    }),
    expandrupeesdiv: () => ({
        display: 'flex',
        gap: '18px'
    }),
    experience: () => ({
        display: 'flex',
        gap: '5px',
        alignItems: 'center'
    }),
    rupees: () => ({
        display: 'flex',
        gap: '5px',
        alignItems: 'center'
    }),
    rupeesDiv: () => ({
        color: '#6d89af',
        fontFamily: 'Helvetica',
        fontSize: ' 16px',
        fontWeight: 400,
        lineHeight: '19px',
    }),
    iconposition: {
        height: '20px',
        width: '20px',
        paddingLeft: '24px'
    },
    submitButton: {
        cursor: "pointer",
        color: "#17181d",
        backgroundColor: "#ffffff",
        textTransform: "none",
        paddingLeft: '18.5px',
        paddingRight: '18.5px',
        border: "1px solid",
        borderRadius: "10px",
        bottom: "16px",
        height: "38px",
        fontWeight: 600,
        fontSize: "12px",
        marginBottom: "5px",
        fontFamily: "urbanist",
        display: 'flex',
        alignItems: 'center',
    },
    button3: {
        cursor: "pointer",
        color: "black",
        textTransform: "none",
        bottom: "16px",
        fontWeight: 600,
        fontSize: "12px",
        marginBottom: "5px",
        fontFamily: "urbanist",
        display: 'flex',
        alignItems: 'center'
    },
    rightmarkText: {
        fontSize: '12px',
        color: '#42a200',
        fontWeight: 600,
        fontFamily: 'urbanist',
        lineHeight: '16px',
        letterSpacing: '0.25px',
        paddingLeft: "2px",
    },
    ProfileDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        //maxWidth: '690px',
        "@media screen and (max-width: 500px)": {
            display: 'flex',
            flexDirection: 'column',
            gridRowGap: '20px'
        },
        "@media screen and (max-width: 1490px)": {
            display: 'flex',
            justifyContent: 'space-between',
        }
    },
    ProfileImageAndOtherInformation: {
        display: 'flex',
        gap: '16px'
    },
    OtherInformation: {},
    ProfileImage: {},
    HeadingText: {
        color: '#17181d',
        fontFamily: 'Urbanist',
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: "19px",
        paddingRight: "19px",
    },
    HeadingInformation: {
        display: 'flex',
        paddingBottom: '6px',
        alignItems: "baseline",
    },
    OnGoingText: {
        background: '#F5C42C',
        color: '#ffffff',
        fontWeight: 700,
        fontSize: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: "12px",
        lletterSpacing: "0.18181819px",
        fontFamily: "Urbanist",
        padding: "5px 10px",
    },
    allPictureLink: {
        paddingLeft: "2px",
        color: '#3389FF',
        textDecoration: 'underline',
        textDecorationColor: '#3389FF',
        display: '-webkit-box',
        '-webkit-line-clamp': 1,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: 170,
        fontFamily: 'Urbanist',
        fontSize: '14px'
    },
    PostedByInformation: {
        color: '#6d89af',
        fontFamily: 'urbanist',
        fontSize: '14px',
        fontWeight: 600,
        display: 'flex'
    },
    dateimage: {
    },
    DateInformation: {
        display: 'flex',
        gap: '4px',
        paddingTop: '10px',
        alignItems: 'center'
    },
    DateInf: {
        color: '#17181d',
        fontFamily: 'Urbanist',
        fontSize: '14px',
        fontWeight: 600
    },
    RatingandReview: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'first baseline',
        paddingLeft: "25px",
        boxSizing: "border-box",
        width: "100%",
        //flex: '1 1 0%',
        //paddingRight:'24px',
        // width: '40%',
        "@media screen and (max-width: 959px)": {
            paddingLeft: "0px",
            paddingTop: "24px",
        },
    },
    skillDiv: {
        paddingTop: '16px',
        //maxWidth: '690px',
        paddingBottom: '16px',
        "@media screen and (max-width: 500px)": {
            maxWidth: '230px',
            alignItems: 'center',
            paddingLeft: '58px'
        },
        "@media screen and (max-width: 1400px)": {
            //maxWidth: '590px',
        }
    },
    skillValue: {
        margin: "0px",
        border: '1px solid #e3eaf4',
        borderRadius: '12px',
        paddingTop: '14px',
        paddingBottom: '14px',
        paddingLeft: '14px',
        width: '100%',
        backgroundColor: '#ffffff',
        "@media screen and (max-width: 500px)": {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: '#ffffff',
        },
        "@media screen and (max-width: 1490px)": {
            backgroundColor: '#ffffff',
            //maxWidth: '540px',
            //width:'580px',
            //width:'95%'
        }
    },
    firstp: {
        display: 'flex',
        alignItems: 'center',
        gap: '5px'
    },
    heading1: {
        color: '#17181d',
        fontFamily: 'Urbanist',
        fontSize: '14px',
        fontWeight: 600,
        letterSpacing: '0.2px'
    },
    JobDescriptionHeading: {
        color: '#6d89af',
        fontFamily: 'Urbanist',
        fontSize: '12px',
        fontWeight: 600,
        lineHeight: '19px',
        paddingBottom: '8px'
    },
    aboutproductionhouse: {
        color: '#17181d',
        fontFamily: 'Urbanist',
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '16px',
        marginTop: "16px",
        "@media screen and (max-width: 500px)": {
            maxWidth: '350px',
        }
    },
    JobDescriptionText: {
        color: '#17181d',
        fontFamily: 'Urbanist',
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '16px',
        //width: '690px',
        //width:'95%',
        borderRadius: '12px',
        border: '1px solid #e3eaf4',
        paddingLeft: '10px',
        paddingTop: '10px',
        paddingBottom: '10px',
        backgroundColor: '#ffffff',
        "@media screen and (max-width: 1490px)": {
            //maxWidth: '540px',
        },
        "@media (min-width >=769px) and (max-width < 1024px)": {
            //maxWidth: '540px',
            //width:'580px',
            //width:'95%'
        },
        "@media screen and (max-width: 768px)": {
            maxWidth: '100%',
        },
    },
    Aboutvalue: {
        borderRadius: '12px',
        border: '1px solid #e3eaf4',
        paddingLeft: '10px',
        paddingTop: '10px',
        paddingBottom: '10px',
        backgroundColor: '#ffffff',
        //width: '690px',
        "@media screen and (max-width: 500px)": {
            maxWidth: '350px',
        },
        "@media screen and (max-width: 1600px)": {
            //maxWidth: '540px',
            //width:'580px',
            //width:'95%'
        }
    },
    AiParentDiv: {
        display: 'flex',
        gap: '10px',
        alignItems: 'center'
    },
    ProductionDiv: {
        paddingTop: '24px'
    },
    AiText: {
        color: '#17181d',
        fontFamily: 'Urbanist',
        fontSize: '18px',
        fontWeight: 600,
        textAlign: 'center',
        letterSpacing: '0.225px',
        paddingBottom: '2px'
    },
    LocationDiv: {
        display: 'flex'
    },
    LocationText: {
        color: '#6d89af',
        fontFamily: 'Urbanist',
        fontSize: '14px',
        fontWeight: 500,
        letterSpacing: ' 0.23333333px'
    },
    AiPicturetext: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start'
    },
    LeftandRightDetail: {
        paddingTop: '23px',
        display: 'flex',
        "@media screen and (max-width: 500px)": {
            display: 'flex',
            flexDirection: 'column'
        }
    },
    starandrating: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    RatingandReviewHeading: {
        color: '#17181d',
        fontFamily: 'Urbanist',
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '24px',
        textAlign: 'center',
        letterSpacing: ' 0.2769231px',
        paddingBottom: '16px'
    },
    ratingheading: {
        color: '#6d89af',
        fontFamily: 'Urbanist',
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '16px',
        letterSpacing: '0.2px',
        borderBottom: '1px solid #e3eaf4',
        paddingBottom: '12.5px'
    },
    starParent: {
        border: '1px solid #e3eaf4',
        borderRadius: '12px',
        paddingTop: '12px',
        paddingInline: '12px',
        width: '93%',
        background: 'rgb(255, 255, 255) none repeat scroll 0% 0%'
    }
    ,
    staricon: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    RateNow: {
        color: '#f5c42c',
        fontFamily: 'Urbanist',
        fontSize: '12px',
        fontWeight: 600,
        letterSpacing: '0.18461539px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    },
    ratingstar: {
        fontSize: '10px',
        fontWeight: 500,
        lineHeight: '16px',
        color: "#17181D",
        paddingTop: '5px',
        paddingBottom: '16px'

    },
    ReviewHeading: {
        paddingBottom: '8px',
        color: "#6d89af",
        fontFamily: "Urbanist",
        fontSize: "12px",
        fontWeight: 600,
        lineHeight: "19px",
        whiteSpace: "nowrap",
        textOverflow: "clip",
        overflow: "hidden",
    },
    ReviewParentVendor: {},
    rupeesicon: {},
    rupeeicon: {},
    positionicon: {},
    Locationicon: {
        paddingRight: "4px",
    },
    DetailLeftSide: {
        //flex:1
        // width: '70%'
    },
    jobcardimage: {
        height: '73px',
        width: '73px',
        borderRadius: '10px'
    },
    DateIcon: {},
    JobDescripation: {},
    AiIcon: {},
    ratingborder: {
        borderBottom: "1px solid",
        borderBottomColor: "#D8D8D8",
        marginBottom: "10px"
    }
});

export default function JobDetail(props: MyProps) {
    const { data, datavalue, withdrawJob, vendorjobid, open,
        handleClose,
        handleOpen,
        addReviewApiCall,
        rateHandle,
        ratevalue,
        comments,
        commentsHandle, ratingvalidation, reviewvalidation,
        navigateToPHDetailPage
    } = props;
    const classes = useStyles();
    const ratings = [
        { stars: 5, value: 80 },
        { stars: 4, value: 60 },
        { stars: 3, value: 40 },
        { stars: 2, value: 20 },
        { stars: 1, value: 10 },
    ];
    let value = getStorageData("vendorid");
    const averageRatingInteger = parseFloat(datavalue?.average_rating);
    const [date, setDate] = useState('');
    useEffect(() => {
        const fetchData = async () => {
            const data = await getStorageData("appliedtime");
            setDate(data);
        };
        fetchData();
    }, []);
    const formatDate = (date: any) => {
        if (!date) return ''; // Handle the case where date is not provided

        // Create a new Date object if the provided date is a string
        const formattedDate = new Date(date);

        // Use Intl.DateTimeFormat to format the date
        const options: any = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            timeZoneName: 'short',
        };

        return new Intl.DateTimeFormat('en-US', options).format(formattedDate);
    };

    return (
        <Grid className={classes.ParentDiv}>
            <Grid className={classes.HeadingParentDiv}>
                <Grid className={classes.Headingname}>
                    <Grid className={classes.Positiontext}>
                        {data?.attributes?.role}
                    </Grid>
                    <Grid className={classes.DeptDiv}>
                        Dept | {data?.attributes?.project_department_name}
                    </Grid>
                    <Grid className={classes.expandrupeesdiv}>
                        <Grid className={classes.experience}>
                            <Grid className={classes.expicon}>
                                <img src={jobyrs} alt="" className={classes.jobiconyear} />
                            </Grid>
                            <Grid className={classes.expDiv}>
                                {data?.attributes?.exp_required} Yrs
                            </Grid>
                        </Grid>
                        <Grid className={classes.rupees}>
                            <Grid className={classes.rupeesicon}>
                                <img src={rupeeiconjob} alt="" className={classes.rupeeicon} />
                            </Grid>
                            <Grid className={classes.rupeesDiv}>
                                ₹{data?.attributes?.budget_of_role}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className={classes.Headingaddress}>
                    <Grid className={classes.CityandStateDiv}>
                        <Grid className={classes.Locationicon}>
                            <img src={iconjobdetail} style={{ padding: "1.5px 3px" }} alt="" className={classes.jobiconyear} />
                        </Grid>
                        <Grid className={classes.CityandState}>
                            {`${data?.attributes?.city}, ${data?.attributes?.state}`}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container md={12} sm={12} className={classes.LeftandRightDetail}>
                <Grid md={8} sm={12} className={classes.DetailLeftSide}>
                    <Grid className={classes.ProfileDiv}>
                        <Grid className={classes.ProfileImageAndOtherInformation}>
                            <Grid className={classes.ProfileImage}>
                                <img src={data?.attributes?.project_detail?.data?.attributes?.image} alt="" className={classes.jobcardimage} />
                            </Grid>
                            <Grid className={classes.OtherInformation}>
                                <Grid className={classes.HeadingInformation}>
                                    <Grid className={classes.HeadingText}>
                                        {data?.attributes?.project_detail?.data?.attributes?.project_name}
                                    </Grid>
                                    <Grid className={classes.OnGoingText}>
                                        {data?.attributes?.project_detail?.data?.attributes?.status.toUpperCase()}
                                    </Grid>
                                </Grid>
                                <Grid className={classes.PostedByInformation}>
                                    Posted By: <Link href="#" className={classes.allPictureLink}> {data?.attributes?.project_detail?.data?.attributes?.production_house}</Link>
                                </Grid>
                                <Grid className={classes.DateInformation}>
                                    <Grid className={classes.DateIcon}>
                                        <img src={DateIconView} alt="" className={classes.dateimage} />
                                    </Grid>
                                    <Grid className={classes.DateInf}>
                                        {`${data?.attributes?.project_detail?.data?.attributes?.start_date}-${data?.attributes?.project_detail?.data?.attributes?.end_date}`}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Box style={{ display: 'flex', gap: '12px', alignItems: "center" }}>
                            <Box
                                className={classes.button3}  >
                                <Box>
                                    <img src={RightmarkIcon} alt="" className={classes.rupeeicon} />
                                </Box>
                                <Box className={classes.rightmarkText}>
                                    {`Applied ${data?.attributes?.days_count} days ago`}
                                </Box>
                            </Box>
                            <Box
                                className={classes.submitButton} onClick={(e: any) => withdrawJob(e, vendorjobid)}
                            >
                                Withdraw Job Request
                            </Box>
                        </Box>
                    </Grid>
                    <Grid className={classes.skillDiv}>
                        <Grid container spacing={2} className={classes.skillValue}>
                            {data?.attributes?.job_role_requirment?.map((job: any) => (
                                <Grid
                                    className={classes.firstp}
                                    item
                                    md={3}
                                >
                                    <Box className={classes.icon}>
                                        <img src={RightIcon} alt="icon" />
                                    </Box>
                                    <Box className={classes.heading1}>{job}</Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid className={classes.JobDescripation}>
                        <Grid style={{ display: 'flex', width: '100%', gap: '5px' }}>
                            <Grid className={classes.JobDescriptionHeading}>
                                Job Description
                            </Grid>
                            <Box style={{
                                height: '0px',
                                border: '1px dashed #e3eaf4', width: "82%", marginTop: '8px'
                            }}></Box>
                        </Grid>
                        <Grid className={classes.JobDescriptionText}>
                            {data?.attributes?.role_description}
                        </Grid>
                    </Grid>
                    <Grid className={classes.ProductionDiv}>
                        <Grid style={{ display: 'flex', width: '100%', gap: '4px' }}>
                            <Grid className={classes.JobDescriptionHeading}>
                                About Production House
                            </Grid>
                            <Box style={{
                                height: '0px',
                                border: '1px dashed #e3eaf4', width: "75%", marginTop: '8px'
                            }}></Box>
                        </Grid>
                        <Grid className={classes.Aboutvalue}>
                            <Grid style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <Grid className={classes.AiParentDiv}>
                                    <Grid className={classes.AiIcon}>
                                        <StyledBadge
                                            overlap="circular"
                                            anchorOrigin={{
                                                vertical: "bottom",
                                                horizontal: "right"
                                            }}
                                            badgeContent={
                                                <img src={checkedImage} />
                                            }
                                        >
                                            <Avatar
                                                style={{
                                                    border: "2px solid #f5c42c",
                                                    width: 48,
                                                    height: 48,
                                                    marginRight: 1
                                                }}
                                                src={data?.attributes?.production_house_image}
                                            >
                                            </Avatar>
                                        </StyledBadge>
                                    </Grid>
                                    <Grid className={classes.AiPicturetext}>
                                        <Grid className={classes.AiText}>
                                            {data?.attributes?.project_detail?.data?.attributes?.production_house}
                                        </Grid>
                                        <Grid className={classes.LocationDiv}>
                                            <Grid className={classes.LocationIcon}>
                                                <img src={LocationIconjob} alt="" className={classes.jobiconyear} />
                                            </Grid>
                                            <Grid className={classes.LocationText}>
                                                Bangalore, India
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid>
                                    <Box onClick={() => navigateToPHDetailPage(data?.attributes?.ph_profile?.data?.id)} style={{ cursor: "pointer", marginRight: "12px", backgroundColor: "rgba(225,230,236,0.32921782)", borderRadius: "15px", padding: "6px" }}>
                                        <ArrowForwardIcon htmlColor="#6D89AF" style={{
                                            fontSize: "small",
                                            width: "14px",
                                            height: "14px",
                                        }}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid className={classes.aboutproductionhouse}>
                                {data?.attributes?.about_production_house?.data?.attributes?.about_business}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid md={4} sm={12} className={classes.RatingandReview}>
                    <Grid className={classes.RatingandReviewHeading}>
                        Ratings & Review
                    </Grid>
                    <Grid className={classes.starParent}>
                        <Grid className={classes.starandrating}>
                            <Grid style={{ display: 'flex' }}>
                                <Grid style={{ display: 'flex' }}>
                                    {datavalue.length == 0 ? (
                                        <ActivityIndicator />
                                    ) : (
                                        <Box className="star_icon rating_size">
                                            <Typography className="total_rating_typo">{datavalue.average_rating || 0}</Typography>
                                            <Typography className="divide_num">/5</Typography>
                                        </Box>
                                    )}
                                    <Box style={{ marginLeft: "11px", marginRight: "10px" }} className="rating_text">
                                        <Divider orientation="vertical" flexItem style={{ height: 23, marginTop: '5px' } as React.CSSProperties} />
                                    </Box>
                                    <Rating
                                        name="customized-empty"
                                        value={averageRatingInteger || 0}
                                        size="small"
                                        precision={0.5}
                                        readOnly
                                        style={{ marginTop: '5px' }}
                                        emptyIcon={<StarBorderIcon htmlColor="#ffb400" style={{ width: "18px", height: "18px" } as React.CSSProperties} />}
                                    />
                                </Grid>
                            </Grid>
                            <div>
                                <Grid className={classes.RateNow} onClick={handleOpen}>
                                    Rate Now
                                    <ArrowForwardIcon style={{
                                        fontSize: "small",
                                        width: "14px",
                                        height: "14px",
                                        marginLeft: 1,
                                    }}
                                    />
                                </Grid>
                                <Modal
                                    open={open}
                                    onClose={handleClose}
                                    aria-describedby="simple-modal-description"
                                    aria-labelledby="simple-modal-title"
                                >
                                    <div style={{
                                        top: '50%',
                                        position: 'absolute',
                                        left: '50%',
                                        backgroundColor: 'white',
                                        borderRadius: '20px',
                                        transform: 'translate(-50%, -50%)',
                                        height: '474px',
                                        width: '375px'
                                    }}>
                                        <RateNowCard ratingvalidation={ratingvalidation} reviewvalidation={reviewvalidation} name={data?.attributes?.project_detail?.data?.attributes?.production_house} image={data?.attributes?.production_house_image} ratevalue={ratevalue} rateHandle={rateHandle} comments={comments} commentsHandle={commentsHandle} addReviewApiCall={addReviewApiCall} />
                                    </div>
                                </Modal>
                            </div>
                        </Grid>
                        <Grid className={classes.ratingheading}>{datavalue?.review_rating_count}</Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{
                            margin: "0px 0px 16px", display: "flex", justifyContent: "space-between", paddingTop: '16px'
                        }}>
                            {
                                Object.entries(datavalue?.rating_star || { 5: 0, 4: 0, 3: 0, 2: 0, 1: 0 }).reverse().map((item: any, index: number) => {
                                    return <Box style={{ width: "calc(20% - 8px)", display: "flex", flexDirection: "column" }} key={`${item[0]}_ratingvalue`}>
                                        <LinearProgress variant="determinate" value={item[1] | 0} className="progress_bar_rating" style={{ backgroundColor: '#e3eaf4', borderRadius: '30px', marginBottom: "5px" }} />
                                        <Typography style={{ height: "12px", color: "#17181d", fontFamily: "Urbanist", fontSize: "10px", fontWeight: 600, letterSpacing: "0.2px" }}>{item[0]}{" "}Star</Typography>
                                    </Box>
                                })
                            }
                        </Grid>
                        <Grid className={classes.ReviewParentVendor}>
                            <Box style={{ display: "flex", alignItems: "center" }}>
                                <Typography className={classes.ReviewHeading}>All Reviews --------------------------------------------------</Typography>
                            </Box>
                            <Grid style={{ maxHeight: "478px", overflowY: "auto", msOverflowStyle: "none", scrollbarWidth: "none" }}>
                                {datavalue?.reviews?.data.map((reviews: any, index: any) => (
                                    <VendorReviewerComponent key={index} data={reviews} />
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid >
    );
}
