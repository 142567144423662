import React from "react";
// Customizable Area Start
import { Grid, Box, AppBar, Tabs, Tab, Typography } from "@material-ui/core";
import { corner_img, logo, main } from "./assets";
import "./landingPage.css";
import EmailAccountLoginBlock from "../../email-account-login/src/EmailAccountLoginBlock.web";
import { SignInInputLabel } from "../../email-account-login/src/CustomButton";
import MobileAccountLoginBlock from "../../mobile-account-login/src/MobileAccountLoginBlock.web";
// Customizable Area End

import LandingPageController, {
  Props,
  configJSON
} from "./LandingPageController";
import LandingPageCreateAccountModel from "../../../components/src/LandingPageCreateAccountModel";

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  render() {
    return (<>
      <Grid container style={{overflow:'hidden'}}>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ position: 'relative', overflow: 'hidden' }}>
        <img src={main}             style={{
              width: '50%',
              height: '100%',
              position: 'fixed',
              top: 0,
              left: 0,
              objectFit: 'cover',
            }}
  />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}           style={{ overflowY: 'auto', height: '100vh', position: 'relative' }}
>
          <Box className="main_box">
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <img src={logo} className="main_img logo_img" />
            </Grid>
            <img src={corner_img} className="corner_img" />
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <label
                style={{ fontFamily: "Urbanist" }}
                className="welcome_back_text"
              >
                {configJSON.welcomeText}
              </label>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <SignInInputLabel
                style={{
                  marginTop: "1%",
                  fontSize: "14px",
                  lineHeight: "21px",
                  fontFamily: "Urbanist",
                  color: "#6D89AF",
                  fontWeight: 600,
                  maxWidth: "100%",
                  width: '100%',
                  paddingBottom: "14px"
                }}
              >
                {configJSON.signInText}
              </SignInInputLabel>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} />
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <AppBar position="static" className="appbar app_bar" color="transparent">
                <Tabs
                  value={this.state.tabValue}
                  onChange={this.handleAppBarValue}
                  variant="scrollable"
                  scrollButtons="on"
                  TabIndicatorProps={{ style: { background: "orange", height: '2px' } as React.CSSProperties }}
                  aria-label="scrollable prevent tabs example"
                >
                  <Tab style={{ fontFamily: 'Urbanist' }} className={this.state.tabValue === 0 ? "active_but" : "non_active_but"} label={configJSON.emailLogin} />
                  <Tab style={{ fontFamily: 'Urbanist' }} className={this.state.tabValue === 1 ? "active_but" : "non_active_but"} label={configJSON.mobileLogin} />
                </Tabs>
              </AppBar>
            </Grid>
            <Grid item xs={12} sm={12} lg={12} className="email_box_ipad">
              {this.state.tabValue === 0 ?
                <EmailAccountLoginBlock
                  navigation={this.props.navigation}
                  id={""}
                /> :
                <MobileAccountLoginBlock
                  navigation={this.props.navigation}
                  id={""}
                />
              }
              <Box>
                <Box style={{ display: "flex", marginTop: '32px', marginBottom: '22px', alignItems: 'center', justifyContent: 'space-between' }}>
                  <span style={{ width: '43%', height: 0, display: 'block', border: '1px solid #e3eaf4' } as React.CSSProperties}></span>
                  <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#6d89af', fontFamily: 'Urbanist', fontSize: '12px', fontWeight: 600, lineHeight: '18px', letterSpacing: '0.4px', width: '36px', height: '36px', borderRadius: '18px', border: '1px solid #e3eaf4', background: 'rgba(225,230,236,0.25)' } as React.CSSProperties}>OR</span>
                  <span style={{ width: '43%', height: 0, display: 'block', border: '1px solid #e3eaf4' } as React.CSSProperties}></span>
                </Box>
                <Typography style={{ color: '#6d89af', fontFamily: 'Urbanist', fontSize: '14px', fontWeight: 500, lineHeight: '18px', textAlign: 'center', letterSpacing: '0.4px' }}>If you don’t have an account? <span onClick={this.handleModelOpen} style={{ color: '#17181D', textDecoration: "underline", cursor: 'pointer' }}> Create a New Account </span> </Typography>
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      {this.state.isModelOpen ? <LandingPageCreateAccountModel open={this.state.isModelOpen} handleClose={this.handleModelClose} handleNavigation={this.handleNavigation} /> : null}
    </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End
