import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
interface IPolicy {
  id: string;
  title: string;
  description: string;
}// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isSideBarOpen: boolean;
  isDropdownOpenVal: boolean;
  policyList: IPolicy[];
  accountTypeInPP: string;
  ppDescription: any;
  isLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Contentmanagement3Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getVendorPPCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isSideBarOpen: true,
      isDropdownOpenVal: false,
      policyList: [
        {
          id: "1",
          title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam urna ipsum, tincidunt ut feugiat in, placerat in libero. Donec non maximus ex, non consequat nibh. Curabitur at laoreet dolor, congue imperdiet orci. Ut vestibulum tincidunt libero, et tempor lectus luctus ut. Suspendisse in leo porttitor, aliquam mi sed, vestibulum quam. Sed facilisis facilisis elementum. Maecenas nec tempus felis. Aliquam erat volutpat. Aliquam erat volutpat.",
        },
        {
          id: "2",
          title: "Consectetur adipiscing elit.",
          description: "Donec dignissim justo leo, in condimentum eros imperdiet sit amet. Nulla sit amet erat accumsan, molestie tortor quis, hendrerit ipsum. Integer aliquet, sapien in tincidunt commodo, justo justo scelerisque risus, ac placerat lectus odio id mi. Nullam gravida tristique risus, a auctor urna porttitor viverra. In eleifend dui elit, at aliquet diam egestas quis. Interdum et malesuada fames ac ante ipsum primis in faucibus. Cras quis nibh et erat iaculis congue."
        },
        {
          id: "3",
          title: "Amet, consectetur adipiscing elit.",
          description: "Commodo, justo justo scelerisque risus, ac placerat lectus odio id mi. Nullam gravida tristique risus, a auctor urna porttitor viverra. In eleifend dui elit, at aliquet diam egestas quis. Interdum et malesuada fames ac ante ipsum primis in faucibus. Cras quis nibh et erat iaculis congue. Aliquam at tortor viverra, varius neque sed, molestie erat. "
        }
      ],
      accountTypeInPP: "",
      ppDescription: "",
      isLoading: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const privacyPolicyApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const privacyPolicyResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (privacyPolicyApiRequestCallId === this.getVendorPPCallId) {
        this.getPPDataHandleResponse(privacyPolicyResponseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getPPDataHandleResponse = async (ppDataResponseJson: { data: any }) => {
    if (ppDataResponseJson.data) {
      this.setState({ ppDescription: ppDataResponseJson.data.description });
    }
    this.setState({ isLoading: false });
  }

  handlePageHomeNavigation = () => {
    if (this.state.accountTypeInPP === "admin") {
      this.props.navigation.navigate("Dashboard");
    } else if (this.state.accountTypeInPP === "vendor_account") {
      this.props.navigation.navigate("JobProfile");
    } else if (this.state.accountTypeInPP === "production_house_account") {
      this.props.navigation.navigate("Projects");
    }
  };

  apiCall = async (ppApiMethod: string, ppApiEndPoint: string) => {
    const ppApiHeader = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const ppApiRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setState({ isLoading: true });
    ppApiRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      ppApiEndPoint
    );
    ppApiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(ppApiHeader)
    );
    ppApiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      ppApiMethod
    );
    runEngine.sendMessage(ppApiRequestMessage.id, ppApiRequestMessage);
    return ppApiRequestMessage.messageId;
  }

  async componentDidMount(): Promise<void> {
    const accountType = await getStorageData('account_type');
    this.getVendorPPCallId = await this.apiCall(configJSON.validationApiMethodType, configJSON.getPPApiEndPoint);
    this.setState({ accountTypeInPP: accountType });
    window.scrollTo(0, 0);
    let isSideBarOpen=await getStorageData("sidebarvalue");
    this.setState({isSideBarOpen:JSON.parse(isSideBarOpen)})
  }

  handleSideBar = () => {
    this.setState({
      isSideBarOpen: !this.state.isSideBarOpen
    },
     async() => {
      setStorageData("sidebarvalue",this.state.isSideBarOpen.toString())
    }
    );
  };

  handleDropdownVal = () => {
    this.setState({
      isDropdownOpenVal: !this.state.isDropdownOpenVal
    })
  };
  // Customizable Area End
}
