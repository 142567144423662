import React from "react";

import {
    // Customizable Area Start
    Container,
    Box,
    InputLabel,
    AppBar,
    Tabs,
    Tab,
    Grid,
    OutlinedInput,
    Divider,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import SearchIcon from '@material-ui/icons/Search';
import {
    withStyles
} from "@material-ui/core/styles";
import AllStudio from "./AllStudio.web";
import "../../fullteamvisibility/src/fullVisibility.css";
import BreadCrum from "../../fullteamvisibility/src/BreadCrum.web";
import CardComponent from "../../fullteamvisibility/src/CardComponent.web";
import CardFooter from "../../fullteamvisibility/src/CardFooter.web";
import ManageProfileController, { Props } from "./ManageProfileController";
export const configJSON = require("./config");
import ProductionHouseCard from "./ProductionHousecard.web";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import Loader from "../../../components/src/Loader";
// Customizable Area End

export default class ManageProfile extends ManageProfileController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <Container maxWidth="xl">
                <Loader loading={this.state.isLoading} />
                <Grid key="container" container spacing={2}>
                    <Grid key="main_title" item xs={8} sm={8} md={8} lg={8} xl={8} style={webStyle.mainGrid} className="background_color">
                        <InputLabel style={webStyle.mainTitle}>{configJSON.manageText}</InputLabel>
                        <Box sx={webStyle.breadCrumBox}>
                            <BreadCrum navigation={this.props.navigation} id="" breadcrumList={this.state.accountListBread} />
                        </Box>
                    </Grid>
                    <Grid key="search_bar" item xs={4} sm={4} md={4} lg={4} xl={4} style={webStyle.mainGrid}>
                        <SearchInput
                            id="component-outlined"
                            startAdornment={
                                <SearchIcon style={webStyle.searchIcon} />
                            }
                            value={this.state.searchInputText}
                            data-test-id="searchInput"
                            placeholder={`Search from ${this.state.numberOfTotalItem[this.state.tabValueAccount] | 0} Profiles...`}
                            onChange={(event) => this.handleSearch(event)}
                            fullWidth />
                    </Grid>
                    <Grid key="all_tabs" item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <AppBar position="static" className="account_appbar app_bar" color="transparent">
                            <Tabs
                                value={this.state.tabValueAccount}
                                onChange={this.handleAppBarAccountValue}
                                variant="scrollable"
                                scrollButtons="on"
                                TabIndicatorProps={{ style: { background: "#f5c42c", height: '3px' } as React.CSSProperties}}
                                aria-label="scrollable prevent tabs example"
                            >
                                <Tab className={this.state.tabValueAccount == 0 ? "active_but account_tab_but" : " account_tab_but non_active_but"} label={`${configJSON.allProductionList} (${this.state.numberOfTotalItem[0]})`} />
                                <Tab className={this.state.tabValueAccount == 1 ? "active_but account_tab_but" : "non_active_but account_tab_but"} label={`${configJSON.vendorText} (${this.state.numberOfTotalItem[1]})`} />
                                <Tab className={this.state.tabValueAccount == 2 ? "active_but account_tab_but" : "non_active_but account_tab_but"} label={`${configJSON.studioText} (${this.state.numberOfTotalItem[2]})`} />
                            </Tabs>
                        </AppBar>
                    </Grid>
                    {
                        this.state.tabValueAccount == 0 &&
                        this.state.allProductionBlockList && this.state.allProductionBlockList.map((item: any, index: number) => {
                            return <Grid item xs={12} sm={6} md={4} key={item.id}>
                                <Box sx={webStyle.cardWrapper}>
                                    <ProductionHouseCard productionHouseData={item.attributes} navigation={this.props.navigation} id="" />
                                    <CardFooter id={item.id} item={{ blocked: item.attributes.blocked, joinDate: item.attributes.join_date }} handleBadgeVisibility={this.unBlockBlockedProfile} navigation={this.props.navigation} />
                                </Box>
                            </Grid>
                        })
                    }
                    {
                        this.state.tabValueAccount == 1 &&
                        this.state.allVendorBlockList && this.state.allVendorBlockList.map((item: any, index: number) => {
                            return <Grid item xs={12} sm={6} md={6} lg={4} xl={3} data-test-id="card_parent_onclick">
                                <Box sx={webStyle.cardWrapper}>
                                    <CardComponent item={{ ...item.attributes, profile_image: item.attributes.profile?.data?.attributes.profile_image, position: item.attributes.role, city: item.attributes.profile?.data?.attributes.city, state: item.attributes.profile?.data?.attributes.state }} navigation={this.props.navigation} id={item.id} />
                                    <Divider style={{ marginBottom: '3%', marginTop: '3%' }} />
                                    <CardFooter navigation={this.props.navigation} id={item.id} item={{ blocked: item.attributes.blocked, joinDate: item.attributes.join_date }} handleBadgeVisibility={this.unBlockBlockedProfile} />
                                </Box>
                            </Grid>
                        })
                    }
                    {
                        this.state.tabValueAccount == 2 &&
                        this.state.allStudioBlockList && this.state.allStudioBlockList.map((item: any, index: number) => {
                            return <Grid item xs={12} sm={6} md={6} lg={4} xl={3} data-test-id="card_parent_onclick">
                                <Box sx={webStyle.cardWrapper}>
                                    <AllStudio id={item.id} navigation={this.props.navigation} item={item.attributes} handleBadgeVisibility={this.unBlockBlockedProfile} />
                                </Box>
                            </Grid>
                        })
                    }

                    {this.state.numberOfTotalItem[this.state.tabValueAccount] ?
                        (<Grid container style={webStyle.pagination}>
                            <Grid item xs={12}>
                                <Pagination data-test-id="pagination" count={Math.ceil((this.state.numberOfTotalItem[this.state.tabValueAccount]) / 9)} page={this.state.currentpage} onChange={this.handlePageChange} />
                            </Grid>
                        </Grid>)
                        : null}
                </Grid>
            </Container >
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const SearchInput = withStyles({
    root: {
        borderRadius: "22px",
        fontFamily: 'Urbanist',
        backgroundColor: "#ffffff",
        height: "44px",
        border: "1px solid #e3eaf4",
        boxShadow: "none",
        color: "#6d89af",
    }
})(OutlinedInput);
const webStyle = {
    cardWrapper: {
        padding: "9px",
        boxSizing: "border-box",
        border: "1px solid #E1E6EC",
        backgroundColor: "#fff",
        borderRadius: "14px",
    },
    mainGrid: {
        marginTop: "2.5%"
    },
    searchIcon: {
        color: '#6d89af'
    },
    pagination: {
        margin: "10px 2px 18px 2px"
    },
    mainTitle: {
        color: 'black',
        fontWeight: 600,
        fontSize: '28px',
        fontFamily: 'Urbanist'
    },
    breadCrumBox: {
        display: 'flex',
        flexdirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        columnGap: '0.5%'
    }
}
// Customizable Area End