import React from "react";

// Customizable Area Start
import { Container, Box, Typography, Grid, Link, TypographyProps, Card, CardContent, LinearProgress, Button } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { nextIcon, previousIcon } from "./assets"
import { Bar, BarChart, CartesianGrid, Rectangle, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import SideBar from "../../../components/src/SideBar";
import Header from "../../../components/src/Header.web";
import Footer from "../../../components/src/Footer";
import Loader from "../../../components/src/Loader.web";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import VendorAnalyticsController, { Props } from "./VendorAnalyticsController";
import CustomNotification from "../../../components/src/CustomNotification";

interface AnalyticsChartsData {
  color: string;
  name: string;
  border?: string;
}
// Bar customization
//istanbul ignore next//
const CustomBar = ({ ...props }) => {
  const { x, y, height, width } = props;
  return (
    <Rectangle
      x={x}
      y={y}
      width={width}
      height={height}
      fill={props.color}
      radius={[6, 6, 6, 6]} />
  );
};
//istanbul ignore next//
const VendorAnalyticsLegends = ({ ...props }) => {
  return (<div style={webStyle.vendorAnalyticsLengends as React.CSSProperties} className="hide-scrollbar">{
    props.legends.map((vendorAnalyticsLegendInfo: AnalyticsChartsData) => {
      return (<div onClick={() => props.handleChangeGraphBar(vendorAnalyticsLegendInfo.name)} key={`${vendorAnalyticsLegendInfo.color}_color`} style={{ ...webStyle.legendStyle, border: vendorAnalyticsLegendInfo.border === "none" ? "none" : "1px solid", borderColor: vendorAnalyticsLegendInfo.border } as React.CSSProperties}>
        <div style={{ ...webStyle.legendDot, backgroundColor: `${vendorAnalyticsLegendInfo.color}` }}></div>
        {vendorAnalyticsLegendInfo.name}
      </div>)
    })}
  </div>
  )
}
// Bar Chart
//istanbul ignore next//
export const VendorAnalyticsBarChart = ({ ...props }) => {
  return (
    <>
      <ResponsiveContainer width="100%" height={250}>
        <BarChart
          margin={{ top: 13, left: -25 }} barGap={props.barGap} data={props.data}
        >
          <CartesianGrid strokeDasharray="3" vertical={false} />
          <XAxis dataKey="name" tick={{ fontFamily: "Urbanist", fontSize: 10, color: "#6d89af", fontWeight: 700 }} interval={0} />
          <YAxis tick={{ fontFamily: "Urbanist", fontSize: 10, color: "#6d89af", fontWeight: 700 }} />
          <Tooltip />
          {props.bars.map((vendorAnalyticsBarData: AnalyticsChartsData) => {
            return (
              <Bar dataKey={vendorAnalyticsBarData.name} barSize={props.bars.length === 1 ? 24 : 4} shape={<CustomBar type={props.type} color={vendorAnalyticsBarData.color} width={props.bars.length === 1 ? 24 : 4} padding={2} />} key={vendorAnalyticsBarData.color} />
            )
          })}
        </BarChart>
      </ResponsiveContainer>
      {props.bars.length !== 1 ? <Box style={{ justifyContent: "end", display: "flex" }}>
        <Button style={webStyle.vendorAnalyticsButtonStyle as React.CSSProperties} onClick={props.navigate}>
          <Typography style={webStyle.vendorAnalyticsButtontext as TypographyProps}>View Detailed Report</Typography>
          <ArrowForwardIcon style={webStyle.vendorAnalyticsArrowStyle} />
        </Button>
      </Box> : null}
    </>
  )
}
// Customizable Area End

export default class VendorAnalytics extends VendorAnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  vendorAnalyticsRenderMainContent(vendorAnalyticsSideBarStatus: boolean = this.state.isVendorAnalyticsSideBarOpen) {
    return <>
      <Loader loading={this.state.isLoading}></Loader>
      <Grid style={webStyle.analyticsContentSetup}>
        < Header
          dropDownStatus={this.state.isVendorAnalyticsDropdownOpen}
          handleDropdown={this.vendorAnalyticsHandleDropdown}
          sideBarStatus={vendorAnalyticsSideBarStatus}
          navigate={this.props.navigation.navigate}
          handleSideBar={this.vendorAnalyticsHandleSideBar}
        />
        <Grid style={webStyle.vendorAnalyticsMainContent as React.CSSProperties}>
          <Box>
            <Typography variant="h3" style={webStyle.vendorAnalyticsActiveBlock as TypographyProps}>Analytics</Typography>
            <Typography variant="h6" style={webStyle.vendorAnalyticsBlockPath as TypographyProps}>
              <span style={webStyle.vendorAnalyticsHighlightedText}>
                <Link data-test-id="homeLink" color="inherit" onClick={() => this.vendorAnalyticsHandleNavigation("JobProfile")}>
                  Home
                </Link>
              </span>{" "}/ Analytics
            </Typography>
          </Box>
          <Grid container spacing={3}>
            <Grid item lg={6} sm={12} xs={12}>
              <Grid container>
                <Grid style={{ marginBottom: "24px" }} className="vendor_analytics_invoice" id="invoice" item lg={12} sm={12} xs={12}>
                  <Card >
                    <CardContent style={{ paddingBottom: "12px" }}>
                      <div style={webStyle.vendorAnalyticsChartInfo}>
                        <Typography style={webStyle.chartName as TypographyProps}>Invoices</Typography>
                        <div style={webStyle.slider}>
                          <div style={{ ...webStyle.updateCurrentPeriod, cursor: !this.state.vendorInvoicesIndex ? "none" : "pointer" }} data-test-id="Handle-Previous-Click-1" onClick={() => { this.vendorAnalyticsHandlePreviousClick("vendorInvoicesIndex") }}><img style={{ opacity: !this.state.vendorInvoicesIndex ? "0.4" : "unset" }} src={previousIcon} alt="previous" /></div>
                          <div style={webStyle.currentPeriod as React.CSSProperties}>{this.state.values[this.state.vendorInvoicesIndex]}</div>
                          <div style={{ ...webStyle.updateCurrentPeriod, cursor: !this.state.vendorInvoicesIndex ? "none" : "pointer" }} data-test-id="Handle-Next-Click-1" onClick={() => { this.vendorAnalyticsHandleNextClick("vendorInvoicesIndex") }}><img src={nextIcon} style={{ opacity: this.state.vendorInvoicesIndex === 2 ? "0.4" : "unset" }} alt="next" /></div>
                        </div>
                      </div>
                      <Box style={webStyle.chartLineStyle} />
                      <VendorAnalyticsLegends
                        handleChangeGraphBar={this.handleChangeInvoiceGraphBar}
                        legends={[
                          {
                            name: "Rejected", color: "#FF5C5C", border: this.getInvoicesLegendsBorder("rejected", "#FF5C5C")
                          },
                          {
                            name: "Pending", color: "#F7B500", border: this.getInvoicesLegendsBorder("pending", "#F7B500")
                          },
                          {
                            name: "Approved", color: "#3389FF", border: this.getInvoicesLegendsBorder("approved", "#3389FF")
                          },
                          {
                            name: "Completed", color: "#42A200", border: this.getInvoicesLegendsBorder("completed", "#42A200")
                          }
                        ]} />
                      <div>
                        <VendorAnalyticsBarChart
                          data={this.state.vendorAnalyticsInvoicesData}
                          barGap={"4%"}
                          navigate={() => this.vendorAnalyticsHandleNavigation("VendorDetailedAnalytics", "invoice")}
                          bars={this.state.vendorInvoicesFilteredBars}
                        />
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid className="vendor_analytics_invoice" id="project" lg={12} sm={12} xs={12} item>
                  <Card>
                    <CardContent style={{ paddingBottom: "12px" }}>
                      <div
                        style={webStyle.vendorAnalyticsChartInfo}
                      >
                        <Typography style={webStyle.chartName as TypographyProps}>Projects</Typography>
                        <div style={webStyle.slider}>
                          <div style={{ ...webStyle.updateCurrentPeriod, cursor: !this.state.vendorProjectsIndex ? "none" : "pointer" }} data-test-id="Handle-Previous-Click-2" onClick={() => { this.vendorAnalyticsHandlePreviousClick("vendorProjectsIndex") }}><img style={{ opacity: !this.state.vendorProjectsIndex ? "0.4" : "unset" }} src={previousIcon} alt="previous" /></div>
                          <div style={webStyle.currentPeriod as React.CSSProperties}>{this.state.values[this.state.vendorProjectsIndex]}</div>
                          <div style={{ ...webStyle.updateCurrentPeriod, cursor: this.state.vendorProjectsIndex === 2 ? "none" : "pointer" }} data-test-id="Handle-Next-Click-2" onClick={() => { this.vendorAnalyticsHandleNextClick("vendorProjectsIndex") }}><img style={{ opacity: this.state.vendorProjectsIndex === 2 ? "0.4" : "unset" }} src={nextIcon} alt="next" /></div>
                        </div>
                      </div>
                      <Box style={webStyle.chartLineStyle} />
                      <VendorAnalyticsLegends
                        handleChangeGraphBar={this.handleChangeProjectGraphBar}
                        legends={[
                          {
                            name: "Ongoing", color: "#F7B500", border: this.getProjectsLegendsBorder("Ongoing", "#F7B500")
                          },
                          {
                            name: "Upcoming", color: "#3389FF", border: this.getProjectsLegendsBorder("Upcoming", "#3389FF")
                          },
                          {
                            name: "Completed", color: "#f5c42c", border: this.getProjectsLegendsBorder("Completed", "#f5c42c")
                          },
                        ]} />
                      <div>
                        <VendorAnalyticsBarChart
                          data={this.state.vendorAnalyticsProjectsData}
                          barGap={"8%"}
                          bars={this.state.vendorProjectsFilteredBars}
                          navigate={() => this.vendorAnalyticsHandleNavigation("VendorDetailedAnalytics", "project")} />
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="vendor_analytics_invoice" id="balance_sheet" lg={6} sm={12} xs={12} item>
              <Card>
                <CardContent style={{ paddingBottom: "12px" }}>
                  <div
                    style={webStyle.vendorAnalyticsChartInfo}
                  >
                    <Typography style={webStyle.chartName as TypographyProps}>Balance Sheet</Typography>
                    <div style={webStyle.slider}>
                      <div style={webStyle.updateCurrentPeriod} data-test-id="Handle-Previous-Click-3" onClick={() => { this.vendorAnalyticsHandleBalanceSheetChartPreviousClick() }}><img src={previousIcon} alt="previous" /></div>
                      <div style={webStyle.currentPeriod as React.CSSProperties}>{this.state.balanceSheetValues.text}</div>
                      <div style={webStyle.updateCurrentPeriod} data-test-id="Handle-Next-Click-3" onClick={() => { this.vendorAnalyticsHandleBalanceSheetChartNextClick() }}><img src={nextIcon} alt="next" /></div>
                    </div>
                  </div>
                  <Box style={{ ...webStyle.chartLineStyle, marginBottom: '20px' }} />
                  <div style={{ overflow: "hidden" }}>
                    <Box style={{ width: "100%", height: "648px", whiteSpace: "nowrap", overflowY: "auto", paddingRight: "24px", boxSizing: "content-box" }}>
                      {this.state.analyticsBalanceSheetsData.length ? this.state.analyticsBalanceSheetsData.map((item: any, index: number) => {
                        return <>
                          <Box>
                            <Typography style={{ lineHeight: "14px", color: "#17181d", fontFamily: "Urbanist", fontSize: "12px", fontWeight: 600 }}>{item.project_name}</Typography>
                            <Box display="flex" key={`${item.project_name}_balance_sheet_line`} alignItems="center" justifyContent="space-between" flexDirection="row">
                              <Box width="80%">
                                <LinearProgress variant="determinate" value={Number(item.invoice_total_amount) | 0} className="progress_bar_rating" style={{ backgroundColor: '#e3eaf4', borderRadius: '10px' }} />
                              </Box>
                              <Box minWidth={40}>
                                <p style={{ margin: 0, lineHeight: "18px", color: "#17181d", fontFamily: "Helvetica-Bold", fontSize: "15px", fontWeight: 400 }}>₹{item.invoice_total_amount}</p>
                              </Box>
                            </Box>
                            <div style={{ lineHeight: "12px", color: "#6d89af", fontFamily: "Urbanist", fontSize: "10px", fontWeight: 600 }}>Overall Budget: <span style={{ fontFamily: "Helvetica-Bold" }}>₹{item.overall_budget}</span></div>
                          </Box>
                          <Box style={{ borderBottom: "1px dashed #e3eaf4", marginBottom: "10px", marginTop: "11px", color: "#e3eaf4" }} />
                        </>
                      }) :
                        <Typography style={{ width: '100%', textAlign: 'center', marginTop: '30px', color: '#6d89af', fontFamily: 'Urbanist', fontSize: 14, fontWeight: 600 }}>No record available</Typography>
                      }
                    </Box>
                    <Box style={{ display: "flex", justifyContent: "end", marginTop: "27px" }}>
                      <Button data-test-id="balanceSheetReport" style={webStyle.vendorAnalyticsButtonStyle as React.CSSProperties} onClick={() => this.vendorAnalyticsHandleNavigation("Forecastingandbudgeting")}>
                        <Typography style={webStyle.vendorAnalyticsButtontext as TypographyProps}>View Detailed Report</Typography>
                        <ArrowForwardIcon style={webStyle.vendorAnalyticsArrowStyle} />
                      </Button>
                    </Box>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Footer />
      </Grid >
    </>
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme} >
        <Container style={webStyle.parentDiv}>
          {
            this.state.isVendorAnalyticsSideBarOpen ? (
              <Grid container spacing={0}>
                <Grid xs={3} item style={webStyle.vendorAnalyticsSidebarParent}>
                  <SideBar
                    handleSideBar={this.vendorAnalyticsHandleSideBar}
                    navigate={this.props.navigation.navigate}
                    userData={{ age: 10 }}
                    activeMenuItem={"Analytics"} />
                </Grid>
                <Grid item xs={9} style={{ flexBasis: "77.75%", maxWidth: "77.75%" }}>{this.vendorAnalyticsRenderMainContent()}</Grid>
              </Grid>) :
              <Grid item xs={12}>{this.vendorAnalyticsRenderMainContent()}</Grid>
          }
        </Container>
        <CustomNotification navigation={(route: string) => this.props.navigation.navigate(route)} notification={this.state.vendorAnalyticsNotification} handleCloseNotification={this.handleVendorAnalyticsCloseNotification} />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff"
    }
  },
  typography: {
    h6: {
      fontWeight: 500
    },
    subtitle1: {
      margin: "20px 0px"
    }
  }
});

const webStyle = {
  parentDiv: {
    maxWidth: "none",
    padding: "0px",
  },
  vendorAnalyticsSidebarParent: {
    maxWidth: "22.25%",
    minHeight: "100vh",
  },
  vendorAnalyticsChartInfo: {
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
  },
  slider: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
  },
  chartName: {
    lineHeight: "19px",
    fontWeight: 700,
    fontSize: "16px",
    color: "#17181d",
    fontFamily: 'Urbanist',
  },
  currentPeriod: {
    padding: "5.9px 14px",
    borderRadius: "16px",
    border: "1px solid #E3EAF4",
    fontWeight: 700,
    margin: "0px 5px",
    display: "flex",
    fontSize: "11px",
    justifyContent: "center",
    alignItems: "center",
    color: "#17181D",
    lineHeight: "13px",
    latterSpacing: '0.18px',
    fontFamily: "Urbanist",
  },
  updateCurrentPeriod: {
    width: "24.8px",
    height: "24.8px",
    border: "1px solid #e3eaf4",
    cursor: "pointer",
    borderRadius: "16px",
    justifyContent: "center",
    display: "flex",
    backgroundColor: "#ffffff",
    alignItems: "center",
  },
  chartLineStyle: {
    color: "#e3eaf4",
    marginTop: "14px",
    borderBottom: "1px solid #e3eaf4",
  },
  analyticsContentSetup: {
    borderLeft: "1px solid #E3EAF4",
    // minHeight: "100vh",
  },
  vendorAnalyticsMainContent: {
    boxSizing: "border-box",
    width: "100%",
    backgroundColor: "#F8FAFE",
    minHeight: "100vh",
    padding: "24px",
  },
  vendorAnalyticsHighlightedText: {
    color: "#F5C42C",
    fontFamily: 'Urbanist',
    cursor: 'pointer',
    textDecoration: 'underline',
    fontWeight: 600,
    fontSize: "14px",
  },
  vendorAnalyticsActiveBlock: {
    letterSpacing: "1px",
    lineHeight: "34px",
    fontSize: "28px",
    fontWeight: 700,
    marginBottom: "8px",
  },
  vendorAnalyticsBlockPath: {
    letterSpacing: "1px",
    color: "#6D89AF",
    fontSize: "14px",
    marginBottom: "18px",
    fontWeight: 500,
  },
  vendorAnalyticsLengends: {
    alignItems: "center",
    justifyContent: "flex-start",
    display: "flex",
    overflow: "hidden",
    overflowX: "auto",
  },
  legendStyle: {
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "15px",
    display: "flex",
    backgroundColor: "#F8FAFE",
    margin: "1px 5px 0px 0px",
    padding: "2px 10px 2px 6px",
    whiteSpace: "nowrap",
    fontSize: "10px",
    fontFamily: "Urbanist",
    fontWeight: 500,
    color: "#17181d",
    lineHeight: "24px",
  },
  legendDot: {
    height: "6px",
    width: "6px",
    margin: "0 3px",
    backgroundColor: "#3EB489",
    borderRadius: "20px",
  },
  vendorAnalyticsButtonStyle: {
    width: "144px",
    borderRadius: "9px",
    backgroundColor: "#17181d",
    alignItems: "center",
    display: "flex",
    textTransform: "none",
    justifyContent: "center",
  },
  vendorAnalyticsButtontext: {
    lineHeight: "12px",
    color: "#ffffff",
    fontSize: "10px",
    fontFamily: "urbanist",
    fontWeight: "600",
  },
  vendorAnalyticsArrowStyle: {
    height: "14px",
    color: "#ffffff",
    lineHeight: "12px",
    width: "14px",
  },
};
// Customizable Area End
