import React from "react";
import { Dialog, DialogTitle, Box, DialogContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import {
    DefaulIcon
} from "./assets";
import CollectionsOutlinedIcon from '@material-ui/icons/CollectionsOutlined';


const useStyles = makeStyles({
    dialogParent: { width: 455, margin: "auto" },
    dialogHeader: { display: "flex", justifyContent: "space-between" },
    dialogContaentContainer: {
        margin: "30px 0px 42px 0px",
        display: "flex",
        justifyContent: "space-between",
        gap: "14px",
    },
    uploadOptionsDiv:{
        width:"49%",
        height: "95px",
        borderRadius: "14px",
        border: "1px solid #E3EAF4",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        alignItems: "center",
        justifyContent: "center",
    }
});

export default function UploadImageModal(props: any) {
    const classes = useStyles();

    return (
        <>
            <Dialog
                className={classes.dialogParent}
                fullWidth={true}
                open={props.openUploadProjectImageModal}
                onClose={props.handleCloseUploadProjectImageModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" >
                    <Box className={classes.dialogHeader}>
                        <span></span>
                        <span style={{fontWeight:600}}> {"Upload Project Image"}</span>
                        <span onClick={props.handleCloseUploadProjectImageModal}><CloseIcon /></span>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box
                        className={classes.dialogContaentContainer}
                    >
                        <Box
                           className={classes.uploadOptionsDiv}
                        >
                            <label htmlFor="fileTest">
                            <CollectionsOutlinedIcon style={{width:"29px",height:"29px",cursor: "pointer",color:"#F5C42C"}}/>
                                <input id="fileTest"  onChange={props.handleFileChange} accept="image/*" type="file" style={{ display: "none" }} />
                            </label>


                            <span style={{fontWeight:600}}>{"Gallery"}</span>
                        </Box>

                        <Box
                            onClick={props.handleDefaultImagePopup}
                           className={classes.uploadOptionsDiv}
                        >
                           <img  style={{width:"29px",height:"29px",cursor: "pointer",color:"#F5C42C"}} src={DefaulIcon} alt="defaultImg" />
                            <span  style={{fontWeight:600}}>{"Default"}</span>
                        </Box>
                    </Box>
                </DialogContent>

            </Dialog>
        </>
    );
}
