//Customizable Area Start
import "./forgotPassword.css";
import React from 'react';
type otpProps = {
    id : string;
    onKeyUp : any;
    className? : any;
    otpValue?:string;
    validateOtp?:any
}
class OtpInput extends React.Component<otpProps>{
    

   

    render(): React.ReactNode {
        const {id,onKeyUp,otpValue,validateOtp} = this.props;
        return(
           <div  className = "otp" style = {webStyle.root}>
              <input className = "otp_input_common"
              style = {{
                border : '1px solid rgb(227, 234, 244)' ,fontSize:"36px",height:"76px"
              }}
              id = {id}
              onKeyUp = {onKeyUp}
              type = "text"
              value={otpValue}
              maxLength={1}
              onChange = {validateOtp}
              />
           </div>
        );
    }
}
export default OtpInput;

const webStyle = {
  root : {
    width : '6.125rem',
    height:"76px"
  }
};
//Customizable Area End