
import React from "react";

//Customizable Area Start
import { Container, Grid, Typography } from "@material-ui/core";
import ForgotPasswordController, { Props } from "./ForgotPasswordController";
export const configJSON = require("./config");
import "./forgotPassword.css";
import { CustomInputlabelEmail, SignInInputLabel, CustomButton } from "../../email-account-login/src/CustomButton";
import { CssTextField } from "../../email-account-login/src/EmailAccountLoginBlock.web";
import CommonComponent from "./CommonComponent";
import CustomNotification from "../../../components/src/CustomNotification";
//Customizable Area End

export default class ForgotPassword extends ForgotPasswordController {
    constructor(props: Props) {
        super(props);
        //Customizable Area Start
        //Customizable Area End
    }
    // Customizable Area Start
    render() {
        return (
            <>
                <Grid container>
                    <CommonComponent navigation={this.props.navigation} />
                    <Grid item xs={1} sm={1} md={4} lg={4} xl={2} className="main_baground" style={{ backgroundColor: '#f6f8fc' }}></Grid>
                    <Grid item xs={10} sm={10} md={4} lg={4} xl={8} style={{ backgroundColor: '#f6f8fc' }}>
                        <Container data-test-id="forgotPasswordId" style={{ backgroundColor: '#f6f8fc' }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: '175px', textAlign: "center" }}>
                                <Typography style={{ lineHeight: "43px", color: "#17181d", fontFamily: "Urbanist", fontSize: "36px", fontWeight: 700, letterSpacing: "1.1076924px" }}>{configJSON.recoverPasswordText}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                <SignInInputLabel className="sing_in_text" style={{ fontSize: "14px", width: "368px", lineHeight: "21px" }}>{configJSON.recoverPasswordHelperText}</SignInInputLabel>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <CustomInputlabelEmail className="email_recover" style={{}}>{configJSON.emailInputLabel}</CustomInputlabelEmail>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <CssTextField type="text" id="outlined-basic" placeholder="Enter your email ID" variant="outlined" className="email_inp" value={this.state.recoveryEmail} onChange={this.handleRecoveryEmailInput} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginBottom: '33%' }}>
                                <CustomButton data-test-id="otp_button" style={{ fontSize: "16px", fontWeight: 600 }} className="otp_button" onClick={this.handleRecoveryEmailSubmit}>{configJSON.getOtp}</CustomButton>
                            </Grid>
                        </Container>
                    </Grid>
                    <Grid item xs={1} sm={1} md={4} lg={4} xl={2} className="main_baground" style={{ backgroundColor: '#f6f8fc' }}></Grid>
                </Grid>
                <CustomNotification navigation={(route: string) => this.props.navigation.navigate(route)} notification={this.state.fpNotification} handleCloseNotification={this.handleFPCloseNotification} />
            </>
        );
    }
    // Customizable Area End
}

// Customizable Area Start
//Customizable Area End