import React from "react";

import {
    // Customizable Area Start
    Avatar,
    Typography,
    Box,
    Grid
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import "./fullVisibility.css";
import { webStyle } from "./FullTeamVisibility.web";
import ProjectCardController, { Props, configJSON } from "./ProjectCardController";
// Customizable Area End

export default class ProjectCard extends ProjectCardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { item }: { item: any } = this.props;
        // Customizable Area End
        return (
            // Customizable Area Start
            <Grid container spacing={2}>
                <Box sx={webStyle.projectcardParentBox} className="project_card_box">
                    <Grid className="project_img">
                        <img
                            className="project_avatar"
                            src={item.image}
                        />
                    </Grid>
                    <Grid className="project_card_inner">
                        <div className="project_card_inner_meta">
                            <Typography className={`${item.status == 'completed' ? 'complete_typo' : item.status == 'Ongoing' ? 'ongoing_typo' : 'upcoming_typo'}`}>{item.status}</Typography>
                            <Typography className="project_date_typo">{item.start_date}</Typography>
                        </div>
                        <Typography className="project_name_typo">{item.project_name}</Typography>
                        <Typography className="project_duration_typo">{`${item.start_date} - ${item.end_date}`}</Typography>
                    </Grid>
                </Box>
            </Grid>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
// Customizable Area End