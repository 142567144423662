import React from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
  DirectionsRenderer
} from "react-google-maps";
import {
  // Customizable Area Start
  Container,
  Box,
  Typography,
  Grid,
  TypographyProps,
  Link,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import SideBar from "../../../components/src/SideBar";
import Header from "../../../components/src/Header.web";
import MapTravellingController, { Props } from "./MapTravellingController";
import Footer from "../../../components/src/Footer";
import { CustomInputlabelEmail, CustomTextField } from "../../email-account-login/src/CustomButton";
const images = require("./assets");

const theme = createTheme({
  palette: {
    primary: {
      main: "#F5C42C",
      contrastText: "#fff"
    }
  },
  overrides: {
    MuiSelect: {
      select: {
        "&:focus": {
          border: "1px solid #F5C42C",
        }
      }
    }
  }
});

const MapContainer = withScriptjs(withGoogleMap(({ travelTab, origins, destinations, handleMouseOver, handleMouseExit, showInfoWindow, directions, updateMapZoom, updateMapCenter, onMapLoad, currentPositions, defaultZoom, defaultCenter }: any) => {
  return (
    <GoogleMap
      defaultZoom={defaultZoom}
      onZoomChanged={updateMapZoom.bind(this)}
      onCenterChanged={updateMapCenter.bind(this)}
      onTilesLoaded={onMapLoad}
      defaultCenter={defaultCenter}
      defaultOptions={{ ...MAP_SETTINGS.DEFAULT_MAP_OPTIONS }}
    >
      {travelTab === 0 && origins.length ? origins.map(({ coordinates: { lat, lng }, id }: any) => (
        <Marker key={id}
          icon={{
            url: images.markerIcon,
          }}
          position={{ lat, lng }}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseExit}>
          {showInfoWindow && (
            <InfoWindow>
              <img style={{ width: "36px", height: "36px", borderRadius: "8px" }} src={"https://i.pinimg.com/originals/09/9e/60/099e600bcfa057bf1c9ecdcce0ad529c.jpg"} />
            </InfoWindow>
          )}
        </Marker>
      )) : null}
      {destinations.length ? destinations.map(({ coordinates: { lat, lng }, id }: any) => (
        <Marker
          key={id}
          position={{ lat, lng }}
          icon={{
            url: images.endDestinationMarkerIcon,
          }}>
          {travelTab === 1 ? (
            <InfoWindow>
              <div style={{ width: "149px", height: "42px", borderRadius: "14px", display: "flex", padding: "3px", boxSizing: "border-box" }} >
                <img style={{ width: "30px", height: "30px", borderRadius: "8px", border: "3px solid #f0f4f9" }} src={"https://i.pinimg.com/originals/09/9e/60/099e600bcfa057bf1c9ecdcce0ad529c.jpg"} />
                <div style={{ paddingLeft: "5px", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography style={{ lineHeight: "15px", color: "#17181d", fontFamily: "Urbanist", fontSize: "12px", fontWeight: 600, letterSpacing: "0.21818182px" }}>Sara Scholz</Typography>
                  <Typography style={{ lineHeight: "12px", color: "#6d89af", fontFamily: "Urbanist", fontSize: "10px", marginTop: "1px", fontWeight: 600, letterSpacing: "0.22222222px" }}>Assistant Director</Typography>
                </div>
              </div>
            </InfoWindow>
          ) : null}
        </Marker>
      )) : null}
      {travelTab === 0 && currentPositions.length ? currentPositions.map(({ coordinates: { lat, lng }, id }: any) => (
        <Marker key={id}
          icon=" "
          position={{ lat, lng }}>
          <InfoWindow>
            <img style={{ width: "36px", height: "36px", borderRadius: "8px" }} src={"https://i.pinimg.com/originals/09/9e/60/099e600bcfa057bf1c9ecdcce0ad529c.jpg"} />
          </InfoWindow>
        </Marker>
      )) : null}
      {travelTab === 0 && directions !== null && (
        <DirectionsRenderer
          directions={directions}
          options={{
            polylineOptions: {
              strokeColor: '#f5c42c',
              strokeWeight: 4,
            },
            suppressMarkers: true,
          }}
        />
      )}
    </GoogleMap>
  )
}));

export const MAP_SETTINGS = {
  DEFAULT_MAP_OPTIONS: {
    scrollwheel: true,
    mapTypeControl: false,
    fullscreenControl: false,
    streetViewControl: false,
    disableDefaultUI: true,
  },
  DEFAULT_CENTER: { lat: 57, lng: 20 },
  DEFAULT_ZOOM: 12,
  PIXEL_OFFSET: {
    X: 0,
    Y: 20,
  },
  DIRECTIONS_OPTIONS: { suppressMarkers: true, preserveViewport: true },
}

const CustomCSSOfTextField = withStyles({
  root: {
    backgroundColor: 'white',
    borderColor: "#f5c42c",
    "& .MuiOutlinedInput-root": {
      "& fieldset": { borderColor: "#E3EAF4" },
      "&:hover fieldset": { borderColor: "#f5c42c" },
      "&.Mui-focused fieldset": {
        backgroundColor: 'rgba(245,196,44,0.04)',
        borderColor: "#f5c42c",
      },
      borderRadius: "14px",
      fontWeight: 400,
      color: '#323232',
      "& .MuiOutlinedInput-input": {
        color: '#17181D',
        fontWeight: 600,
        fontSize: '14px',
        borderColor: '#e3eaf4',
        lineHeight: '19px',
        fontFamily: 'Urbanist',
      }
    },
    "& .MuiOutlinedInput-root.Mui-disabled": {
      "& fieldset": {
        border: "1px solid #e3eaf4",
        borderRadius: "11px",
      },
      "&:hover fieldset": {
        borderColor: "#e3eaf4",
        borderRadius: "11px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#e3eaf4",
        borderRadius: "11px",
        backgroundColor: '#ecf2fa'
      },
      backgroundColor: '#ecf2fa',
      color: '#17181d',
      borderRadius: "11px",
      "& .MuiOutlinedInput-input": {
        fontWeight: 600,
        color: '#17181d',
        lineHeight: '18px',
        borderRadius: "11px",
        fontSize: '13px',
        fontFamily: 'Urbanist',
      }
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#f5c42c"
    },
    "& .MuiInputBase-input::placeholder": {
      color: '#6d89af',
      fontWeight: 500,
      fontFamily: 'Urbanist',
    },
  }
})(CustomTextField);
// Customizable Area End

export default class MapTravelling extends MapTravellingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderMapTravellingContent = () => {
    const API_KEY = "AIzaSyDS3lSh1DVsIjUheUfsyk7UdUBKNKJkEVg";
    return <>
      <Grid container style={{ marginBottom: '24px' }}>
        <Grid style={{ display: "flex" }} item xs={12} sm={12} md={9} xl={9}>
          <Grid item style={{ padding: "16px 16px 0px 0px" }} xs={12} sm={12} md={5} lg={5}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <CustomInputlabelEmail id="mapStartDestinationInput" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px", paddingTop: "0px" }}>Enter Start Destination</CustomInputlabelEmail>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <CustomCSSOfTextField
                data-test-id="mapStartDestinationInput"
                type={'text'}
                className="email_input"
                id="outlined-basic"
                disabled
                variant="outlined"
                value={this.state.startDestination}
                placeholder="Enter Start Destination"
              />
            </Grid>
          </Grid>
          <Grid style={{ paddingTop: "40px", paddingInline: "8px", height: "22px", color: "#17181d", fontFamily: "Urbanist", fontSize: "18px", fontWeight: 700, letterSpacing: "0.2px" }}>To</Grid>
          <Grid item style={{ padding: "16px", paddingBottom: "0px" }} xs={12} sm={12} md={5} lg={5}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <CustomInputlabelEmail id="mapEndDestinationInput" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px", paddingTop: "0px" }}>Enter End Destination</CustomInputlabelEmail>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <CustomCSSOfTextField
                data-test-id="mapEndDestinationInput"
                type={'text'}
                className="email_input"
                id="outlined-basic"
                disabled
                variant="outlined"
                value={this.state.endDestination}
                placeholder="Enter End Destination"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box style={{ height: "517px", borderRadius: "20px", background: "#d8d8d8" }}>
        <MapContainer
          travelTab={this.state.travelTab}
          googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${API_KEY}`}
          loadingElement={<Box height="100%" />}
          containerElement={<Box height="100%" />}
          showInfoWindow={this.state.showInfoWindow}
          handleMouseOver={this.handleMouseOver}
          handleMouseExit={this.handleMouseExit}
          mapElement={<Box height="100%" style={{ borderRadius: "20px", border: "1px solid #979797", }} />}
          origins={this.state.startDestinationDetails} destinations={this.state.endDestinationDetails}
          directions={this.state.directions}
          updateMapZoom={this.updateMapZoom}
          updateMapCenter={this.updateMapCenter}
          onMapLoad={this.onMapLoad}
          currentPositions={this.state.currentLocationDetails}
          defaultZoom={this.state.defaultZoom}
          defaultCenter={this.state.defaultCenter}
        />
      </Box>
    </>
  }

  renderMainMapTravellingContent(sideBarStatus: boolean = this.state.isSideBarOpen) {
    return (<Grid style={webStyle.mapContentSetup}>
      < Header
        dropDownStatus={this.state.isDropdownOpen}
        sideBarStatus={sideBarStatus}
        handleDropdown={this.handleMapTravellingDropdown}
        navigate={this.props.navigation.navigate}
        handleSideBar={this.handleMapTravellingSideBar}
      />
      <Grid style={webStyle.mapDataContent as React.CSSProperties}>
        <Box sx={webStyle.mapBreadCrumbBox}>
          <Box>
            <Typography style={webStyle.mapCurrentBlockTitle as TypographyProps} variant="h3">Travelling List</Typography>
            <Typography style={webStyle.mapBlockPath as TypographyProps} variant="h6">
              <span style={webStyle.mapYellowHighlightedText}>
                <Link color="inherit" data-test-id="homeLink" onClick={() => this.handleNavigation("JobProfile")}>
                  Home
                </Link>
              </span>
              <span style={webStyle.mapYellowHighlightedText}>
                <Link color="inherit" data-test-id="trackForWork" onClick={() => this.handleNavigation("Location")}>
                  {" "}/ Track for Work
                </Link>
              </span>
              <span style={webStyle.mapYellowHighlightedText}>
                <Link color="inherit" data-test-id="workTravellingList" onClick={() => this.handleNavigation("WorkTravellingList")}>
                  {" "}/ Pushpa 2
                </Link>
              </span> {" "} / Track
            </Typography>
          </Box>
        </Box>
        {this.renderMapTravellingContent()}
      </Grid >
      <Footer />
    </Grid >)
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container style={webStyle.mapParentContainerDiv}>
          {this.state.isSideBarOpen ? (<Grid spacing={0} container>
            <Grid style={webStyle.mapSidebarParent} item xs={3}>
              <SideBar
                userData={{ age: 25 }}
                navigate={this.props.navigation.navigate}
                activeMenuItem={"Track for Work"}
                handleSideBar={this.handleMapTravellingSideBar} />
            </Grid>
            <Grid item style={{ flexBasis: "77.75%", maxWidth: "77.75%" }} xs={9}>{this.renderMainMapTravellingContent()}</Grid>
          </Grid>) :
            <Grid item xs={12}>{this.renderMainMapTravellingContent()}</Grid>
          }
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mapParentContainerDiv: {
    padding: "0px", maxWidth: "none",
  },
  mapContentSetup: {
    height: '100%',
    borderLeft: "1px solid #E3EAF4",
  },
  mapSidebarParent: {
    minHeight: "100vh",
    maxWidth: "22.25%",
  },
  mapDataContent: {
    width: "100%",
    backgroundColor: "#F8FAFE",
    boxSizing: "border-box",
    padding: "24px",
    minHeight: "100vh",
  },
  mapYellowHighlightedText: {
    cursor: 'pointer',
    fontFamily: 'Urbanist',
    color: "#F5C42C",
    textDecoration: 'underline',
    fontSize: "14px",
    fontWeight: 600,
  },
  mapBreadCrumbBox: {
    alignItems: "center",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  mapBlockPath: {
    color: "#6D89AF",
    fontSize: "14px",
    letterSpacing: "1px",
    fontWeight: 500,
  },
  mapCurrentBlockTitle: {
    letterSpacing: "1px",
    fontSize: "28px",
    marginBottom: "8px",
    lineHeight: "34px",
    fontWeight: 700,
  },
};
// Customizable Area End