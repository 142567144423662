import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import React from "react";
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  projectMemberList: any[];
  isLoading: boolean;
  isModelOpen: boolean;
  currentPage: number;
  totalCount: number;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ProjectMemberListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  layoutRef: any = null;
  projectMemberListTitle: string = "Project Members";
  projectMemberListBreadCrumb: any[] = [
    { title: "Home", link: "Projects" },
    { title: "Project Members" },
  ];
  getProjectMemberListApiCalledId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      projectMemberList: [],
      isLoading: false,
      isModelOpen: false,
      currentPage: 1,
      totalCount: 0,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.layoutRef = React.createRef();
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.getProjectMemberListApiCalledId === apiRequestCallId) {
        this.getProjectMemberListHandleResponse(responseJson);
      }
    }
  }
  // Customizable Area End

  // Customizable Area Start
  getProjectMemberListHandleResponse = (pmListResponseJson: { meta: { total_count: number }, data: any, errors: { token: string }[] }) => {
    if (pmListResponseJson.data) {
      this.setState({
        projectMemberList: pmListResponseJson.data, totalCount: pmListResponseJson.meta.total_count
      });
    } else if (pmListResponseJson.errors[0].token) {
      this.layoutRef.current.handleChangeNotification("error", true, pmListResponseJson.errors[0].token, "LandingPage");
    }
    this.setState({ isLoading: false });
  }

  async componentDidMount() {
    this.getProjectMemberListApiCalledId = await this.apiCall(configJSON.GET_Method, configJSON.getProjectMembersListApiEndPoint + 1);
  }

  apiCall = async (pmListMethod: string, pmListEndPoint: string) => {
    const pmListAutoToken = await getStorageData("token");
    const pmListHeader = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": pmListAutoToken,
    };
    this.setState({ isLoading: true });
    const pmListRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    pmListRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(pmListHeader)
    );
    pmListRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      pmListEndPoint
    );
    pmListRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      pmListMethod
    );
    runEngine.sendMessage(pmListRequestMessage.id, pmListRequestMessage);
    return pmListRequestMessage.messageId;
  };

  handleModelOpen = () => {
    this.setState({ isModelOpen: true });
  }

  handleModelClose = () => {
    this.setState({ isModelOpen: false });
  }

  handleArrowRightClick = (projectID: string, projectName: string) => {
    setStorageData("projectDetails", JSON.stringify({ id: projectID, name: projectName }));
    this.props.navigation.navigate("ProjectMembersJobRole");
  }

  handlePageChange = async (event: any, value: number) => {
    this.setState({ currentPage: value });
    this.getProjectMemberListApiCalledId = await this.apiCall(configJSON.GET_Method, configJSON.getProjectMembersListApiEndPoint + value);
  }
  // Customizable Area End
}