Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "forecastingandbudgeting";
exports.labelBodyText = "forecastingandbudgeting Body";

exports.getBalanceSheetProjectListAPI = "/bx_block_projecttasktracking2/user_projects";
exports.getBalanceSheetCrewCountAPI = "bx_block_categories/budget_details/crew_count?project_id=";
exports.createDepartmentAPI = "bx_block_projecttasktracking2/project_departments?project_id=";
exports.getProjectsDepartmentAPI = "bx_block_categories/budget_details/get_project_departments?project_id=";
exports.editDepartmentAPI = "bx_block_categories/edit_department/";
exports.getProjectDetailsAPI = "bx_block_projecttasktracking2/projects/";
exports.getBudgetDetailsByDepartmentAPI = "bx_block_categories/budget_details/show_budget_details?project_id=";
exports.createCategoryAPI = "bx_block_categories/categories/create_category";
exports.editCategoryAPI = "categories//categories/";
exports.createSubCategoryAPI = "bx_block_categories/sub_categories/create_sub_category";
exports.deleteSubCategoryAPI = "categories//sub_categories/";
exports.createBudgetDetails = "bx_block_categories/budget_details/create_budget_detail";
exports.getBudgetDetailsWithPagination = "bx_block_categories/budget_details/show_categories?project_id=";
exports.getCategoriesByDepartments = "bx_block_categories/budget_details/project_departments_categories?project[project_department_ids]=";
exports.createBudgetSummaryAPI = "bx_block_categories/budget_details/create_budget_summary";
exports.getBudgetSummaryAPI = "bx_block_categories/budget_details/budget_summaries?project_id=";
exports.updateBudgetSummaryAPI = "bx_block_categories/update_budget_summary/";
exports.uploadCSVFileAPI = "bx_block_categories/budget_details/import_csv?project_id=";
exports.exportCSVFileAPI = "bx_block_categories/budget_details/export_csv?project_id=";
exports.getBalanceSheetApiEndPoint = "bx_block_projecttasktracking2/user_projects"
exports.balanceSheetProjectListPerPage = 9;
exports.btnExampleTitle = "CLICK ME";
exports.getAPIMethod = "GET";
exports.postAPIMethod = "POST";
exports.putAPIMethod = "PUT";
exports.patchAPIMethod = "PATCH";
exports.deleteAPIMethod = "DELETE";
// Customizable Area End