import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, isEmail, setStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isHelpCenterSideBarOpen: boolean;
  isHeaderDropdownOpenVal: boolean;
  hcTopic: any;
  hcEmail: string;
  hcComment: string;
  hcTopicError: any;
  hcEmailError: any;
  hcCommentError: any;
  hcTopicList: any[];
  isLoading: boolean;
  contactDetails: any;
  helpCenterNotification: { type: string, open: boolean, message: string, route?: string };
  firstPhoneCopy: string;
  isHcModelOpen: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class HelpCenterController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getHelpCenterContactDetailsMessageId: string = "";
  getHelpCenterTopicsMessageId: string = "";
  sendHelpCenterRequestMessageId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isHelpCenterSideBarOpen: true,
      isHeaderDropdownOpenVal: false,
      hcTopic: "",
      hcEmail: "",
      hcComment: "",
      hcTopicError: { require: false },
      hcEmailError: { require: false, invalid: false },
      hcCommentError: { require: false },
      hcTopicList: [],
      isLoading: false,
      contactDetails: null,
      helpCenterNotification: { open: false, type: "", message: "" },
      firstPhoneCopy: "Copy",
      isHcModelOpen: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const helpCenterApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const helpCenterResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (helpCenterApiRequestCallId === this.getHelpCenterContactDetailsMessageId) {
        this.handleGetContactDetailsApiResponse(helpCenterResponseJson);
      } else if (helpCenterApiRequestCallId === this.getHelpCenterTopicsMessageId) {
        this.handleGetTopicssApiResponse(helpCenterResponseJson);
      } else if (helpCenterApiRequestCallId === this.sendHelpCenterRequestMessageId) {
        this.handleSubmitRequestApiResponse(helpCenterResponseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleGetContactDetailsApiResponse = (helpCenterResponseJson: { data: any, errors: [{ token: string }] }) => {
    if (helpCenterResponseJson.data) {
      this.setState({ contactDetails: helpCenterResponseJson.data });
    } else if (helpCenterResponseJson.errors[0].token) {
      this.setState({ helpCenterNotification: { open: true, type: "error", message: helpCenterResponseJson.errors[0].token, route: "LandingPage" } });
    }
    this.setState({ isLoading: false });
  }

  handleGetTopicssApiResponse = (helpCenterResponseJson: { data: { id: number, title: string }[] }) => {
    if (helpCenterResponseJson.data) {
      this.setState({ hcTopicList: helpCenterResponseJson.data });
    }
    this.setState({ isLoading: false });
  }

  handleSubmitRequestApiResponse = (helpCenterResponseJson: { data: any, errors: string }) => {
    if (helpCenterResponseJson.data) {
      this.hcHandleOpen();
      this.setState({ hcTopic: "", hcEmail: "", hcComment: "" });
    } else if (helpCenterResponseJson.errors) {
      this.setState({ helpCenterNotification: { open: true, type: "error", message: helpCenterResponseJson.errors } });
    }
    this.setState({ isLoading: false });
  }

  handlePageNavigation = (page: string) => {
    this.props.navigation.navigate(page)
  };

  apiCall = async (helpCenterMethod: string, helpCenterEndPoint: string, helpCenterBodyData?: any) => {
    const hcAuthToken = await getStorageData("token");
    const helpCenterHeader = {
      "Content-Type": configJSON.validationApiContentType,
      token: hcAuthToken
    };
    const helpCenterRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setState({ isLoading: true });
    helpCenterRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      helpCenterEndPoint
    );
    helpCenterRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(helpCenterHeader)
    );
    helpCenterBodyData && helpCenterRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(helpCenterBodyData)
    );
    helpCenterRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      helpCenterMethod
    );
    runEngine.sendMessage(helpCenterRequestMessage.id, helpCenterRequestMessage);
    return helpCenterRequestMessage.messageId;
  }

  async componentDidMount(): Promise<void> {
    window.scrollTo(0, 0);
    const isHelpCenterSideBarOpen = await getStorageData("sidebarvalue");
    this.setState({ isHelpCenterSideBarOpen: JSON.parse(isHelpCenterSideBarOpen) });
    this.getHelpCenterContactDetailsMessageId = await this.apiCall(configJSON.validationApiMethodType, configJSON.getHelpCenterContactDetailsAPIEndPoint);
    this.getHelpCenterTopicsMessageId = await this.apiCall(configJSON.validationApiMethodType, configJSON.getHelpCenterContactTopicsAPIEndPoint);
  }

  handleHeaderDropdownVal = () => {
    this.setState({
      isHeaderDropdownOpenVal: !this.state.isHeaderDropdownOpenVal
    })
  };

  handleHelpCenterSideBar = () => {
    setStorageData("sidebarvalue", (!this.state.isHelpCenterSideBarOpen).toString())
    this.setState({
      isHelpCenterSideBarOpen: !this.state.isHelpCenterSideBarOpen
    });
  };

  handleInputFocus = (labelName: string) => {
    const inputLabel = document.getElementById(labelName);
    if (inputLabel) {
      inputLabel.style.color = "#F5C42C";
    }
  };

  handleInputBlur = (labelName: string) => {
    const inputLabel = document.getElementById(labelName);
    if (inputLabel) {
      inputLabel.style.color = "#6D89AF";
    }
  };

  handleChangeHCTopicDetails = (event: any) => {
    const newValue = event.target.value;
    this.setState({ hcTopic: newValue });
    if (!newValue) {
      this.setState({ hcTopicError: { require: true } });
      return false;
    } else {
      this.setState({ hcTopicError: { require: false } });
    }
  }

  handleChangeHCEmailDetails = (event: any) => {
    const newValue = event.target.value;
    this.setState({ hcEmail: newValue });
    if (!newValue) {
      this.setState({ hcEmailError: { invalid: false, require: true } });
      return false;
    } else {
      this.setState({ hcEmailError: { invalid: false, require: false } });
    }
    const hcEmail = isEmail("email", newValue);
    !hcEmail.status
      ?
      this.setState({ hcEmailError: { invalid: true, require: false } })
      : this.setState({ hcEmailError: { invalid: false, require: false } });
  }

  handleChangeHCCommentDetails = (event: any) => {
    const newValue = event.target.value;
    this.setState({ hcComment: newValue });
    if (!newValue) {
      this.setState({ hcCommentError: { require: true } });
      return false;
    } else {
      this.setState({ hcCommentError: { require: false } });
    }
  }

  openComposeEmail = (mailId: string) => {
    window.open(`mailto:${mailId || ""}`);
  }

  copyPhoneNumber = (phoneNumber: string) => {
    this.setState({ firstPhoneCopy: "Copied" });
    navigator.clipboard.writeText(phoneNumber || "")
  }

  handleMouseLeave = () => {
    this.setState({ firstPhoneCopy: "Copy" });
  }

  handleFormSubmit = async () => {
    if (!this.state.hcEmail || !this.state.hcTopic || !this.state.hcComment) {
      this.setState({
        hcEmailError: { ...this.state.hcEmailError, require: !Boolean(this.state.hcEmail) },
        hcTopicError: { require: !Boolean(this.state.hcTopic) },
        hcCommentError: { require: !Boolean(this.state.hcComment) },
      });
      return false;
    } else if (this.state.hcEmailError.invalid) {
      this.setState({ helpCenterNotification: { type: 'warning', open: true, message: "Invalid email" } });
      return false;
    }
    const bodyData = {
      help_request: {
        help_desk_topic_id: this.state.hcTopic,
        email: this.state.hcEmail,
        comment: this.state.hcComment
      }
    };
    this.sendHelpCenterRequestMessageId = await this.apiCall(configJSON.exampleAPiMethod, configJSON.sendHelpCenterContactRequestAPIEndPoint, bodyData);
  }

  handleHelpCenterCloseNotification = () => {
    this.setState({ helpCenterNotification: { type: "", open: false, message: "" } });
  }

  hcHandleOpen = () => {
    this.setState({ isHcModelOpen: true });
  }

  hcHandleClose = () => {
    this.setState({ isHcModelOpen: false });
  }
  // Customizable Area End
}
