import React from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  TypographyProps,
  Link, Avatar, Divider, LinearProgress
} from "@material-ui/core";
import SideBar from "../../../components/src/SideBar";
import HambergerUpComingandOngoing from "../../../components/src/HambergerUpComingandOngoing";
import Header from "../../../components/src/Header.web";
import Footer from "../../../components/src/Footer";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
//@ts-ignore
// @ts-ignore
// @ts-ignore
import Loader from "../../../components/src/Loader.web";
import StudioDetailPHController, {
  Props, configJSON
} from "./StudioDetailPHController"
import BusinessIcon from '@material-ui/icons/Business';
import SettingsPhoneIcon from '@material-ui/icons/SettingsPhone';
import PlaceOutlinedIcon from '@material-ui/icons/PlaceOutlined';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import StarOutlinedIcon from '@material-ui/icons/StarOutlined';
import { StyledBadge } from "../../fullteamvisibility/src/CardComponent.web";
import Rating from "@material-ui/lab/Rating";
import BookStudioModel from "./BookStudioModel.web";
import BookStudioSuccessModel from "./BookStudioSuccessModel.web";
import StudioDetailRatingModel from "./StudioDetailRatingModel.web";
import CustomNotification from "../../../components/src/CustomNotification";
import { arrowdown } from "../../../components/src/assets";
const images = require("./assets");

const themeStyle = createTheme({
  palette: {
    primary: {
      main: "#F5C42C",
      contrastText: "#fff"
    }
  },
  overrides: {
    MuiSelect: {
      select: {
        "&:focus": {
          border: "1px solid #F5C42C",
        }
      }
    }
  }
});

export default class StudioDetailPH extends StudioDetailPHController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  UpcomingStudioPart=()=>{
    return<>
    {
                (this.state.UpcomingStudioListData&&this.state.UpcomingStudioListData?.map((item:any,index:number) => (
              
                    this.state.studioid==item.id?
                    (
                      <Box sx={webStyle.upcomingParent}>
                      <Box style={webStyle.timeupcoming}>{`Booking starts in ${ item?.attributes?.time_difference?.time}`}</Box>
                  {    item?.attributes?.booking_type==="hour_wise"?(
                        <Box sx={webStyle.UpcomingstudiosubtaskrequestPH}>
                        <Box sx={webStyle.UpcomingStudiostartingdateandtimePH}>
                            <Box sx={webStyle.starttimePH}>
                               {this.formatDate(item?.attributes?.date)}
                            </Box>
                            <Box style={webStyle.UpcomingStudioopentimePH} >
                            {item?.attributes?.start_time}
        
                            </Box>
                        </Box>
                        
                            
                        <Box sx={webStyle.daysandmoneyPH1}>
                            <Box sx={webStyle.daystyle}>
                            <Box sx={webStyle.LeftDivPH}>
                                <Box sx={webStyle.rectangle1PHUpcomingStudio}>
                                    <Box sx={webStyle.smlrectangle1PHUpComingStudio}>
        
                                    </Box>
                                </Box>
        
                            </Box>
                            <Box sx={webStyle.daydivPHStudioUpcoming}>
                            {item?.attributes?.total_hours} HOURS
        
                            </Box>
                            <Box sx={webStyle.RightDivPHStudioUpcoming}>
                        <Box sx={webStyle.rectangle2PHStudioUpcoming}>
                                    <Box sx={webStyle.smlrectangle2PH}></Box>
                                </Box>
                        </Box>
                            </Box>
                            <Box sx={webStyle.moneydivPHUpcomingStudio}>
                                ₹{item?.attributes?.total_amount}
                            </Box>
        
                        
                      
                        </Box>
                        
                        <Box sx={webStyle.enddataandtimePHUpcomingStudio}>
                            <Box sx={webStyle.enddatePHUpcomingStudio}>
                            {this.formatDate(item?.attributes?.date)}
                            </Box>
                            <Box sx={webStyle.enddatetimePHUpcomingStudio}>
                            {item?.attributes?.end_time}
                            </Box>
                        </Box>
        
        
                    </Box>

                    ):(
                        <Box sx={webStyle.UpcomingstudiosubtaskrequestPH}>
                        <Box sx={webStyle.UpcomingStudiostartingdateandtimePH}>
                            <Box style={{
                                color: '#17181d',
                                fontSize: '12px',
                                fontWeight: 600,
                                textAlign: 'right',
                                letterSpacing: '0.2px',
                                fontFamily: 'Urbanist',
                                paddingBottom:'4px'
                            }}>
                                 {this.formatDate(item?.attributes?.start_date)}
                            </Box>
                            <Box sx={webStyle.UpcomingStudioopentimePH} >
                            {item?.attributes?.open_time}

                            </Box>
                        </Box>
                        
                            
                        <Box sx={webStyle.daysandmoneyPH1}>
                            <Box style={{display:'flex', position: 'relative'}}>
                            <Box sx={webStyle.LeftDivPH1}>
                                <Box sx={webStyle.rectangle1PHUpcomingStudio}>
                                    <Box sx={webStyle.smlrectangle1PHUpComingStudio}>
        
                                    </Box>
                                </Box>
        
                              
                            </Box>
                            <Box sx={webStyle.daydivPHStudioUpcoming}>
                            {item?.attributes?.total_days} DAYS
        
                            </Box>
                            <Box sx={webStyle.RightDivPHStudioUpcoming}>
                        <Box sx={webStyle.rectangle2PHStudioUpcoming}>
                                    <Box sx={webStyle.smlrectangle2PH}></Box>
                                </Box>
                        </Box>
                            </Box>
                            <Box sx={webStyle.moneydivPHUpcomingStudio}>
                                ₹{item?.attributes?.total_amount}
                            </Box>
        
                        
                      
                        </Box>
                        
                        <Box sx={webStyle.enddataandtimePHUpcomingStudio}>
                            <Box sx={webStyle.enddatePHUpcomingStudio}>
                            {this.formatDate(item?.attributes?.end_date)}
                            </Box>
                            <Box sx={webStyle.enddatetimePHUpcomingStudio}>
                            {item?.attributes?.close_time}
                            </Box>
                        </Box>
        
        
                    </Box>
                    )
              }
                            <Box style={{borderBottom:'1px solid #E3EAF4',marginLeft:'12px',marginRight:'12px'}}></Box>
                       <Box style={{backgroundColor:'E1E6EC',height:'38px',marginLeft:'12px',marginRight:'12px',borderRadius:'12px',marginTop:'12px',display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                        <Box  style={{fontFamily:'urbanist',fontSize:'13px',fontWeight:600,marginLeft:'10px'}}>Booked by:</Box>
                        <Box style={{height:'30px',width:'30px',borderRadius:'18px',border:'1px solid #E1E6EC',marginTop:"24px",display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'#FFFFFF'}} data-test-id="requestprofilenavigate"  onClick={()=>this.handleNavigationRequestProfile(item?.id,item?.attributes?.studio_id,item?.attributes?.vendor?.data?.attributes?.profile?.data?.id,item?.attributes?.status)}>
                          <img src={arrowdown}/>
                        </Box>
                        <Box style={{display:'flex',gap:'5px',alignItems:'center',justifyContent:'center'}}>
                          <Box style={{width:'22px',height:'22px',borderRadius:'18px'}} >
                         <img src={item?.attributes?.vendor?.data?.attributes?.profile_image||"https://www.littlethings.info/wp-content/uploads/2014/04/dummy-image-green-e1398449160839.jpg"} style={{width:'22px',height:'22px',borderRadius:'18px'}}/>
                          </Box>
                          <Box style={{fontFamily:'urbanist',fontSize:'13px',fontWeight:600,marginRight:'10px'}}>{item?.attributes?.vendor?.data?.attributes?.full_name}</Box>
                        </Box>
                        
                        </Box>     
        
                    </Box>  
                    ):(
                    null
                    )
                    
                    
                    
                    

                  
          
                )))
                
              }
    </>

  }
  OngoingStudioPart=()=>{
    return<>
     {
                (this.state.OngoingStudioListData&&this.state.OngoingStudioListData?.map((item:any,index:number) => (
              
                    this.state.studioid==item.id?
                    (
                      <Box style={{  border: '1px solid #e3eaf4',borderRadius:'12px',paddingTop:'12px',paddingBottom:'12px',marginBottom:'15px'}}>

                      <Box style={{color:'#f7b500',fontFamily:'urbanist',fontWeight:600,fontSize:'14px',display:'flex',justifyContent:'center'}}>{`Booking will be end in ${ item?.attributes?.time_difference?.time}`}</Box>
                  {    item?.attributes?.booking_type==="hour_wise"?(
                        <Box sx={webStyle.subtaskrequestPH}>
                        <Box sx={webStyle.startingdateandtimePH}>
                            <Box style={{
                                color: '#17181d',
                                fontFamily: 'Urbanist',
                                fontSize: '12px',
                                fontWeight: 600,
                                textAlign: 'right',
                                letterSpacing: '0.2px',
                                paddingBottom:'4px'
                            }}>
                               {this.formatDate(item?.attributes?.date)}
                            </Box>
                            <Box style={webStyle.opentimePH} >
                            {item?.attributes?.start_time}
        
                            </Box>
                        </Box>
                        
                            
                        <Box sx={webStyle.daysandmoneyPH}>
                            <Box style={{ position: 'relative',display:'flex',}}>
                            <Box sx={webStyle.LeftDivPH1}>
                                <Box sx={webStyle.rectangle1PH}>
                                    <Box sx={webStyle.smlrectangle1PH}>
        
                                    </Box>
                                </Box>
        
                              
                            </Box>
                            <Box sx={webStyle.daydivPH}>
                            {item?.attributes?.total_hours} HOURS
        
                            </Box>
                            <Box sx={webStyle.RightDivPH}>
                        <Box sx={webStyle.rectangle2PH}>
                                    <Box sx={webStyle.smlrectangle2PH}></Box>
                                </Box>
                        </Box>
                            </Box>
                            <Box sx={webStyle.moneydivPH}>
                                ₹{item?.attributes?.total_amount}
                            </Box>
        
                        
                      
                        </Box>
                        
                        <Box sx={webStyle.enddataandtimePH}>
                            <Box sx={webStyle.enddatePH}>
                            {this.formatDate(item?.attributes?.date)}
                            </Box>
                            <Box sx={webStyle.enddatetimePH}>
                            {item?.attributes?.end_time}
                            </Box>
                        </Box>
        
        
                    </Box>

                    ):(
                        <Box sx={webStyle.subtaskrequestPH}>
                        <Box sx={webStyle.startingdateandtimePH}>
                            <Box style={{
                                color: '#17181d',
                                fontFamily: 'Urbanist',
                                fontSize: '12px',
                                fontWeight: 600,
                                textAlign: 'right',
                                letterSpacing: '0.2px',
                                paddingBottom:'4px'
                            }}>
                                 {this.formatDate(item?.attributes?.start_date)}
                            </Box>
                            <Box sx={webStyle.opentimePH} >
                            {item?.attributes?.open_time}

                            </Box>
                        </Box>
                        
                            
                        <Box sx={webStyle.daysandmoneyPH}>
                            <Box style={{display:'flex', position: 'relative'}}>
                            <Box sx={webStyle.LeftDivPH}>
                                <Box sx={webStyle.rectangle1PH}>
                                    <Box sx={webStyle.smlrectangle1PH}>
        
                                    </Box>
                                </Box>
        
                               
                            </Box>
                            <Box sx={webStyle.daydivPH}>
                            {item?.attributes?.total_days} DAYS
        
                            </Box>
                            <Box sx={webStyle.RightDivPH}>
                        <Box sx={webStyle.rectangle2PH}>
                                    <Box sx={webStyle.smlrectangle2PH}></Box>
                                </Box>
                        </Box>
                            </Box>
                            <Box sx={webStyle.moneydivPH}>
                                ₹{item?.attributes?.total_amount}
                            </Box>
        
                        
                      
                        </Box>
                        
                        <Box sx={webStyle.enddataandtimePH}>
                            <Box sx={webStyle.enddatePH}>
                            {this.formatDate(item?.attributes?.end_date)}
                            </Box>
                            <Box sx={webStyle.enddatetimePH}>
                            {item?.attributes?.close_time}
                            </Box>
                        </Box>
        
        
                    </Box>
                    )
              }
                            <Box style={webStyle.lowerpartPH}></Box>
                       <Box style={webStyle.BookedPH}>
                        <Box  style={webStyle.mainBooked}>Booked by:</Box>
                        <Box style={webStyle.arrowdesignPH}
                       onClick={()=>this.handleNavigationRequestProfile(item?.id,item?.attributes?.studio_id,item?.attributes?.vendor?.data?.attributes?.profile?.data?.id,item?.attributes?.status)}>
                          <img src={arrowdown}/>
                        </Box>
                        <Box style={webStyle.imageanddetail}>
                          <Box style={{width:'22px',height:'22px',borderRadius:'18px'}} >
                         <img src={item?.attributes?.vendor?.data?.attributes?.profile_image||"https://www.littlethings.info/wp-content/uploads/2014/04/dummy-image-green-e1398449160839.jpg"} style={{width:'22px',height:'22px',borderRadius:'18px'}}/>
                          </Box>
                          <Box style={webStyle.vendornamePH}>{item?.attributes?.vendor?.data?.attributes?.full_name}</Box>
                        </Box>
                        
                        </Box>     
        
                    </Box>  
                    ):(
                    null
                    )
                    
                    
                    
                    

                  
          
                )))
                
              }
    </>
  }
  RatingandReviewPart=()=>{
    return<>
      <Grid style={webStyle.RatingParentPH}>
                <Box style={webStyle.ratingStartMainBoxPH}>
                  <Box style={webStyle.ratingStartBoxPH} >
                    <Box style={webStyle.Ratingaverage}>
                      <Box className="star_icon rating_size">
                        <Typography className="total_rating_typo">{this.state.studioDetailReviewData.average_rating || 0}</Typography>
                        <Typography className="divide_num">{configJSON.divide}</Typography>
                      </Box>
                      <Box style={webStyle.DividerBox} className="rating_text">
                        <Divider orientation="vertical" flexItem style={{ height: 23 } as React.CSSProperties} />
                      </Box>
                      <Rating
                        name="customized-empty"
                        value={this.state.studioDetailReviewData.average_rating || 0}
                        size="small"
                        precision={0.5}
                        readOnly
                        emptyIcon={<StarBorderIcon htmlColor="#ffb400" style={webStyle.StarBorderPH as React.CSSProperties} />}
                      />
                    </Box>
                    <Box>
                      {/* <Typography style={webStyle.rateNowTextPH} onClick={this.handleRatingModelOpen}>
                        Rate Now
                        <ArrowForwardIcon style={webStyle.ARROWICON}
                        />
                      </Typography> */}
                    </Box>
                  </Box>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="user_detail_grid_container_rating">
                    <Typography className="rating_helper">{this.state.studioDetailReviewData.review_rating_count}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="user_detail_grid_container_rating background_color">
                    <Divider style={{  marginBottom: "15px",marginTop: "12px", }} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ margin: "0px 12px 16px", display: "flex", justifyContent: "space-between" }}>
                    {
                      Object.entries(this.state.studioDetailReviewData.rating_star || { 5: 0, 4: 0, 3: 0, 2: 0, 1: 0 }).reverse().map((item: any, index: number) => {
                        return <Box sx={webStyle.ratingstarBoxPH} key={`${item[0]}_ratingvalue`}>
                          <LinearProgress variant="determinate" value={item[1] | 0} className="progress_bar_rating" style={webStyle.LinearProgressBox} />
                          <Typography style={webStyle.starBox}>{item[0]}{" "}Star</Typography>
                        </Box>
                      })
                    }
                  </Grid>
                </Box>
                <Box style={webStyle.reviewParentBox}>
                  <Typography style={webStyle.allReviewsTitlePH as React.CSSProperties}>All Reviews --------------------------------------------------</Typography>
                </Box>
                <Grid style={webStyle.reviewBox as React.CSSProperties} item xs={12} sm={12} md={12} lg={12} xl={12}>
                  {
                    this.state.studioDetailReviewData.reviews?.data?.length ? (this.state.studioDetailReviewData.reviews.data.map((item: any, index: number) => {
                      return <Box key={`${index}_review_box_allProjectList`} style={{ padding: '12px' }} className="review_box">
                        <Grid container spacing={3}>
                          <Box sx={webStyle.projectcardParentBoxPH} className="review_card_box">
                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                              <Avatar
                                style={{ borderRadius: "12px", width: 36, height: 36 }}
                                src={
                                    item.attributes.account.image ||
                                     "https://www.littlethings.info/wp-content/uploads/2014/04/dummy-image-green-e1398449160839.jpg"}
                              >
                              </Avatar>
                            </Grid>
                            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                              <Typography style={webStyle.reviewnameBox}>
                                {item.attributes.account.full_name}
                                </Typography>
                              <Typography style={webStyle.createBox}>
                                {item.attributes.created_at}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2} className="project_date">
                              <Box style={webStyle.starsPH}>
                                <StarOutlinedIcon className="start_color" />
                                <Typography style={webStyle.star_colorPH as React.CSSProperties}>
                                    &nbsp;{item.attributes.given_star}
                                    </Typography>
                              </Box>
                            </Grid>
                          </Box>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={webStyle.commentsBox}>
                            <Typography style={webStyle.commentsBoxvalue}>
                                {
                            item.attributes.comment}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    })) : null
                  }
                </Grid>
              </Grid>
    </>
  }
  GellaryPart=()=>{
    return<>
      {this.state.studioDetailTabValue === 1 ? <Box style={{ width: "100%", margin: "15px" }}>
                  <Box style={{ display: "flex", flexDirection: "row", gap: "13px", flexWrap: "wrap" }}>
                    {this.state.studioDetailsData.photos ? this.state.studioDetailsData.photos.map((item: any) => {
                      return <img key={item.id + "gallery"}
                        className="studioGalleryImages"
                        src={item.path_url || "https://www.littlethings.info/wp-content/uploads/2014/04/dummy-image-green-e1398449160839.jpg"}
                      />
                    })
                      : <Typography style={{ width: '100%', textAlign: 'center', marginTop: '30px', marginBottom: '30px', color: '#6d89af', fontFamily: 'Urbanist', fontSize: 14, fontWeight: 600 }}>No photos available</Typography>
                    }
                  </Box>
                </Box> : null}
    </>
  }
  renderStudioDetailMainContent = (sideBarStatus: boolean = this.state.isStudioBookingDetailSideBarOpen) => {
    return <>
      <Loader loading={this.state.isLoading}></Loader>
      <Grid style={webStyle.wholePageContentSetupPH} >
        <Header handleSideBar={this.handleStudioDetailPHPageSideBar}
          dropDownStatus={this.state.isDropdownOpen}
          navigate={this.props.navigation.navigate}
          sideBarStatus={sideBarStatus}
          handleDropdown={this.handleDropdownStudioDetailPH}
        />
        <Grid style={webStyle.dynamicMainContentPH as React.CSSProperties}>
          <Box sx={webStyle.blockInfoTitlePH}>
            <Box>
              <Typography variant="h3" style={webStyle.activeBlockMainTitlePH as TypographyProps}>Studio Details</Typography>
              <Typography variant="h6" style={webStyle.blockE2EPathPH as TypographyProps}>
                <span style={webStyle.highlightedTitleextPH}>
                  <Link data-test-id="homeLink" color="inherit" onClick={() => this.handleNavigation("JobProfile")}>
                    Home
                  </Link>
                </span>
                <span style={webStyle.highlightedTitleextPH}>
                                    <Link data-test-id="studiosBookingList" color="inherit"  onClick={() => this.handleNavigation("AllStudioBookingListPH")}>
                                        {" "}/ Studios & Requests /
                                    </Link>
                                </span>
                {" "}{this.state.studioDetailsData.name}
              </Typography>
            </Box>
          </Box>
          <Grid style={webStyle.StyleParentBox} item sm={12} xl={12} xs={12} md={12} lg={12}>
            <Box sx={webStyle.StyleBadgeBox}>
              <Box sx={webStyle.StyleBadgeBoxvalue}>
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                  }}
                  style={{ height: 75 , width: 75,}}
                  badgeContent={
                    <img style={{ width: 24, height: 24 }} src={images.checkedIcon} />
                  }
                >
                  <Avatar
                    style={webStyle.AvtarBox}
                    src={this.state.studioDetailsData.image||"https://www.littlethings.info/wp-content/uploads/2014/04/dummy-image-green-e1398449160839.jpg"}
                  >

                  </Avatar>
                </StyledBadge>
                <Box style={webStyle.studioDetailsLeftBoxPH as React.CSSProperties}>
                  <Typography
                    style={{
                      height: "22px",
                      color: "#17181d",
                      fontFamily: "Urbanist",
                      fontSize: "18px",
                      fontWeight: 600,
                      textAlign: "center",
                      letterSpacing: "0.225px",
                    }}
                  >
                    {this.state.studioDetailsData.name}
                  
                  </Typography>
                  <div style={webStyle.phNameAddressBoxPH} >
                    <Typography style={webStyle.phNamePH}>
                      <BusinessIcon style={{
                        fontSize: "small",
                        width: "22px",
                        height: "22px",
                        marginRight: 3,
                      }} />
                     {this.state.studioDetailsData.production_house}
                    </Typography>
                    <Typography style={webStyle.addressTextPH}>
                      <PlaceOutlinedIcon style={{
                        fontSize: "small",
                        width: "22px",
                        height: "22px",
                        marginRight: 2,
                      }} />
                      {this.state.studioDetailsData.city}, {this.state.studioDetailsData.state}
                      
                    </Typography>
                  </div>
                  <Box style={{ ...webStyle.studioStatusBtnPH, background: this.getStatusBG(this.state.studioDetailsData.status, "bg"), width: this.getStatusBG(this.state.studioDetailsData.status, "wid") }}>
                    <span style={webStyle.studioStatusTextPH} >
                        {this.state.studioDetailsData.status?.toUpperCase()}
                        
                        </span>
                  </Box>
                </Box>
                <Box style={webStyle.studioDetailsRightBoxPH as React.CSSProperties}>
                  <Typography style={webStyle.rightSideBoxTextPH}>
                    <SettingsPhoneIcon style={{
                      fontSize: "small",
                      width: "20.09px",
                      height: "20px",
                      marginRight: 10.4,
                      color: "#f5c42c",
                    }} />
                    +{this.state.studioDetailsData.country_code}-{this.state.studioDetailsData.phone_number}
                    
                  </Typography>
                  <Typography style={{ ...webStyle.rightSideBoxTextPH, margin: "9px 0px" }}>
                    <img style={{ marginRight: 10.4 }} src={`${images.forwardMailIcon}`} />
                    {this.state.studioDetailsData.email}
                  </Typography>
                  <Typography style={webStyle.rightSideBoxTextPH}>
                    <img style={{ marginRight: 10.4 }} src={`${images.studioTimingIcon}`} />
                    {this.state.studioDetailsData.open_time} to {this.state.studioDetailsData.close_time}
                
                  </Typography>
                </Box>
              </Box>
             
              <Box>
                <HambergerUpComingandOngoing data={this.state.studioDetailsDataid} cancelbooking={this.cancelbooking}/>
              </Box>
            </Box>
          </Grid>
          <Grid container style={{ width: "100%", marginTop: "24px", alignItems: "flex-start" }}>
            <Grid md={8} sm={12} item style={{ maxHeight: "630px", minHeight: "406px", borderRadius: "12px", border: "1px solid #e3eaf4" }}>
              <Box style={{ display: "flex" }}>
                <Box style={webStyle.lefttabsMainBoxPH}>
                  <Box data-test-id="studioDetailOverviewTab" onClick={() => this.handleTabValueChange(0)} style={{ borderBottom: "1px solid #e3eaf4", cursor: "pointer", width: "138px" }}>
                    <Typography style={{ ...webStyle.leftSideTabTextPH, background: this.state.studioDetailTabValue === 0 ? "#ffffff" : "#f8fafe", color: this.state.studioDetailTabValue === 0 ? "#f5c42c" : "#17181d" }}>Overview</Typography>
                  </Box>
                  <Box data-test-id="studioDetailGalleryTab" onClick={() => this.handleTabValueChange(1)} style={{ borderBottom: "1px solid #e3eaf4", cursor: "pointer", width: "138px" }}>
                    <Typography style={{ ...webStyle.leftSideTabTextPH, background: this.state.studioDetailTabValue === 1 ? "#ffffff" : "#f8fafe", color: this.state.studioDetailTabValue === 1 ? "#f5c42c" : "#17181d" }}>Gallery</Typography>
                  </Box>
                </Box>
                {this.state.studioDetailTabValue === 0 ? <Box style={{ width: "calc(100% - 186px)", margin: "13px 24px 16px 24px" }}>
                  <Box>
                    <Typography style={webStyle.allReviewsTitlePH as React.CSSProperties}>Price Details --------------------------------------------------------------------------------------------------------------------</Typography>
                    <Grid container style={{ marginTop: "8px", width: "100%", height: "87px", borderRadius: "12px", border: "1px solid #e3eaf4", padding: 6, display: "flex", flexDirection: "column" }}>
                      <Grid item sm={6} md={6} lg={6} style={{ height: "59px", margin: "8px", borderRadius: "10px", background: "#e1e6ec", padding: "10px", boxSizing: "border-box" }}>
                        <Typography style={{ color: "#17181d", fontFamily: "Urbanist", fontSize: "12px", fontWeight: 600, lineHeight: "16px", letterSpacing: "0.2px" }}>Per day</Typography>
                        <div style={{ marginTop: "4px", color: "#42a200", fontFamily: "Helvetica-Bold", fontSize: "18px", fontWeight: 400, lineHeight: "19px", letterSpacing: "0.16363636px" }}>
                            ₹
                            {this.state.studioDetailsData.price_per_day}
                      
                            </div>
                      </Grid>
                      <Grid item sm={6} md={6} lg={6} style={{ height: "59px", margin: "8px", borderRadius: "10px", background: "#e1e6ec", padding: "10px", boxSizing: "border-box" }}>
                        <Typography style={{ color: "#17181d", fontFamily: "Urbanist", fontSize: "12px", fontWeight: 600, lineHeight: "16px", letterSpacing: "0.2px" }}>Per hour</Typography>
                        <div style={{ marginTop: "4px", color: "#42a200", fontFamily: "Helvetica-Bold", fontSize: "18px", fontWeight: 400, lineHeight: "19px", letterSpacing: "0.16363636px" }}>
                            ₹
                            {this.state.studioDetailsData.price_per_hour}
                            
                            </div>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box style={{ marginTop: "16px" }}>
                    <Typography style={webStyle.allReviewsTitlePH as React.CSSProperties}>Studio for --------------------------------------------------------------------------------------------------------------------</Typography>
                    <Grid container style={{ marginTop: "8px", width: "100%", minHeight: "86px", borderRadius: "12px", border: "1px solid #e3eaf4", padding: 16, boxSizing: "border-box", display: "flex", flexDirection: "column" }}>
                      <Grid container spacing={3}>
                        {this.state.studioDetailsData.studio_type?.map((studio: any) => (
                          <Grid
                            key={`studiofor_${studio}`}
                            style={webStyle.KeyStyleBox}
                            item
                            md={4}
                          >
                            <Box>
                              <img src={images.RightIcon} alt="icon" />
                            </Box>
                            <Box sx={webStyle.StudioBoxvalue}>{studio}</Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </Box>
                  <Box style={{ marginTop: "16px" }}>
                    <Typography style={webStyle.allReviewsTitlePH as React.CSSProperties}>About Studio --------------------------------------------------------------------------------------------------------------------</Typography>
                    <Grid container style={{ marginTop: "8px", width: "100%", minHeight: "88px", borderRadius: "12px", border: "1px solid #e3eaf4", padding: 12, boxSizing: "border-box", color: "#17181d", fontFamily: "Urbanist", fontSize: "12px", fontWeight: 500, lineHeight: "16px" }}>
                      {this.state.studioDetailsData.description}
                    </Grid>
                  </Box>
                </Box> : null}
              {
                this.GellaryPart()
              }
              </Box>
            </Grid>
            <Grid md={4} sm={12} item className="studioBookingResponsive" style={{ paddingLeft: "24px" }}>
              {
                this.UpcomingStudioPart()
              }
              {
                this.OngoingStudioPart()
              }
            
            {
              this.RatingandReviewPart()
            }
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </>
  }
  // Customizable Area End

  render() {
    return (
      <ThemeProvider theme={themeStyle}>
        <Container style={webStyle.parentContainerPH}>
          {
            this.state.isStudioBookingDetailSideBarOpen ? (
              <Grid spacing={0} container>
                <Grid xs={3} item style={webStyle.sidebarParentGridPH} >
                  <SideBar
                   activeMenuItem={"All Studios & Bookings"}
                   navigate={this.props.navigation.navigate}
                    handleSideBar={this.handleStudioDetailPHPageSideBar}
                    userData={{ name: "AllStudioDetailPH" }}
                   
                  />
                </Grid>
                <Grid style={{ display: "flex", flexDirection: "column", flexBasis: "77.75%", maxWidth: "77.75%" }} item xs={9} >
                  {this.renderStudioDetailMainContent()}
                </Grid>
              </Grid>
            ) : (
              <Grid item style={{ display: "flex", flexDirection: "column" }} xs={12}>
                {this.renderStudioDetailMainContent()}
              </Grid>
            )
          }
          {this.state.isModelOpen && this.state.modelType === "" ? <BookStudioModel navigation={this.props.navigation} id={this.state.studioBookingId} open={this.state.isModelOpen} handleClose={this.handleModelClose} sendBookingRequest={this.sendBookingRequest} pricePerDay={this.state.studioDetailsData.price_per_day} pricePerHour={this.state.studioDetailsData.price_per_hour} /> : null}
          {this.state.isModelOpen && this.state.modelType === "successModel" ? <BookStudioSuccessModel navigation={this.props.navigation} id={this.state.studioBookingId} open={this.state.isModelOpen} handleClose={this.handleModelClose} studioName={this.state.studioDetailsData.name} /> : null}
          {this.state.isModelOpen && this.state.modelType === "ratingModel" ? <StudioDetailRatingModel navigation={this.props.navigation} id={this.state.studioBookingId} open={this.state.isModelOpen} handleClose={this.handleModelClose} studioName={this.state.studioDetailsData.name} studioImage={this.state.studioDetailsData.image} handleGiveRatingAPICall={this.handleGiveRatingAPICall} /> : null}
        </Container>
        <CustomNotification navigation={(route: string) => this.props.navigation.navigate(route)} notification={this.state.notification} handleCloseNotification={this.handleCloseNotification} />
      </ThemeProvider>
    );
  }
}

const webStyle = {
  rectangle1PH:{

    width: '15px',
    height: '15px',
    borderRadius: '6px',
    background: '#fff2c8',
    display:'flex',

    justifyContent:'center',
    alignItems:'center',
    marginTop:'-7px'
},
rectangle1PHUpcomingStudio:{
  justifyContent:'center',
  alignItems:'center',
  marginTop:'-7px',
  width: '15px',
    height: '15px',
    borderRadius: '6px',
    background: '#fff2c8',
    display:'flex',
},
enddatetimePH:{
  color: '#6d89af',
fontFamily: 'Urbanist',
fontSize: '10px',
fontWeight: 500,
textAlign: 'right',
letterSpacing: '0.2px'

},
enddatetimePHUpcomingStudio:{
  fontSize: '10px',
fontWeight: 500,
textAlign: 'right',
letterSpacing: '0.2px',
color: '#6d89af',
fontFamily: 'Urbanist',
},
RightDivPH:{
  width: '45px',
  border: '1px dashed rgb(225, 230, 236)',
  //top: '346px',
  // left: '429px',
  height:'0px',
  marginTop:'10px'
},
RightDivPHStudioUpcoming:{
  height:'0px',
  marginTop:'10px',
  width: '45px',
  border: '1px dashed rgb(225, 230, 236)',
},
smlrectangle2PH:{
  
  width: '8px',
  height: '8px',
  borderRadius: '26px',
  background: '#f5c42c',
},
enddataandtimePH:{
  display:'flex',
  flexDirection:'column',
  justifyContent:'center',
  alignItems:'center'
},
enddataandtimePHUpcomingStudio:{
  justifyContent:'center',
  alignItems:'center',
  display:'flex',
  flexDirection:'column',
},
RatingParentPH:{ width: "100%", maxHeight: "630px", minHeight: "400px", borderRadius: "12px", border: "1px solid #e3eaf4", background: "#ffffff" },
smlrectangle1PH:{
  
  top: '4px',
  width: '8px',
  height: '8px',
  borderRadius: '26px',
  background: '#f5c42c',
},
smlrectangle1PHUpComingStudio:{
  height: '8px',
  borderRadius: '26px',
  top: '4px',
  width: '8px',
  background: '#f5c42c',
},
Ratingaverage:{ display: "flex", alignItems: "center" },
DividerBox:{ marginLeft: "12px", marginRight: "10px" },
enddatePH:{
  color: "#17181d",
  fontFamily: 'Urbanist',
  fontSize: '12px',
  fontWeight: 600,
  textAlign: 'right',
  letterSpacing:' 0.2px',
  paddingBottom:'4px',
},
enddatePHUpcomingStudio:{
  fontSize: '12px',
  fontWeight: 600,
  textAlign: 'right',
  letterSpacing:' 0.2px',
  paddingBottom:'4px',
  color: "#17181d",
  fontFamily: 'Urbanist',
},
StarBorderPH:{ width: "18px", height: "18px" },
  lefttabsMainBoxPH: {
    width: "138px",
    height: "100%",
    background: "#f8fafe",
  },
  ARROWICON:{
    fontSize: "small",
    width: "14px",
    height: "14px",
    marginLeft: 1,
  },
  ratingstarBoxPH:{ width: "calc(20% - 8px)", display: "flex", flexDirection: "column" },
  LinearProgressBox:{ backgroundColor: '#e3eaf4', borderRadius: '30px', marginBottom: "5px" },
  starBox:{ height: "12px", color: "#17181d", fontFamily: "Urbanist", fontSize: "10px", fontWeight: 600, letterSpacing: "0.2px" },
  rectangle2PH:{
    //position: 'absolute',
    //right: '-2px',
    //top: '-7px',
    width: '15px',
    height: '15px',
    borderRadius: '6px',
    background: '#fff2c8',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    position: 'absolute',
    top:4,
    right: 0
},
rectangle2PHStudioUpcoming:{
  width: '15px',
  height: '15px',
  borderRadius: '6px',
  background: '#fff2c8',
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  position: 'absolute',
  top:4,
  right: 0
},
  startingdateandtimePH:{
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center'
    

},
UpcomingStudiostartingdateandtimePH:{
  display:'flex',
  flexDirection:'column',
  justifyContent:'center',
  alignItems:'center'
},

reviewParentBox:{ display: "flex", alignItems: "center", margin: "0px 12px" },
upcomingParent:{
  border: '1px solid #e3eaf4',
  borderRadius:'12px',
  paddingTop:'12px',
  paddingBottom:'12px',
  marginBottom:'15px'
},
reviewBox:{ margin: "0px 12px", maxHeight: "478px", overflowY: "auto", msOverflowStyle: "none", scrollbarWidth: "none" },
timeupcoming:{
  color:'#3389FF',
  fontFamily:'urbanist',
  fontWeight:600,
  fontSize:'14px',
  display:'flex',
  justifyContent:'center'
},
reviewnameBox:{ lineHeight: "19px", color: "#17181d", fontFamily: "Urbanist", fontSize: "16px", fontWeight: 600, letterSpacing: "0.2px" },
lowerpartPH:{borderBottom:'1px solid #E3EAF4',marginLeft:'12px',marginRight:'12px'},
imageanddetail:{display:'flex',gap:'5px',alignItems:'center',justifyContent:'center'},
BookedPH:{backgroundColor:'E1E6EC',height:'38px',marginLeft:'12px',marginRight:'12px',borderRadius:'12px',marginTop:'12px',display:'flex',justifyContent:'space-between',alignItems:'center'},
daystyle:{display:'flex',
 position: 'relative'},
 mainBooked:{fontFamily:'urbanist',fontSize:'13px',fontWeight:600,marginLeft:'10px'},
 vendornamePH:{fontFamily:'urbanist',fontSize:'13px',fontWeight:600,marginRight:'10px'},
 createBox:{ marginTop: "1px", lineHeight: "12px", color: "#6d89af", fontFamily: "Urbanist", fontSize: "10px", fontWeight: 500, letterSpacing: "0.2px" },
starttimePH:{
  color: '#17181d',
  fontFamily: 'Urbanist',
  fontSize: '12px',
  fontWeight: 600,
  textAlign: 'right',
  letterSpacing: '0.2px',
  paddingBottom:'4px'
},
commentsBox:{ padding: "0px 12px 12px", marginTop: '-2%' },
commentsBoxvalue:{ color: "#17181d", fontFamily: "Urbanist", fontSize: "12px", fontWeight: 500, lineHeight: "16px", letterSpacing: "0.2px" },
arrowdesignPH:{height:'30px',width:'30px',borderRadius:'18px',border:'1px solid #E1E6EC',marginTop:"24px",display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'#FFFFFF'},
  leftSideTabTextPH: {
    lineHeight: "17px",
    color: "#17181d",
    fontFamily: "Urbanist",
    fontSize: "14px",
    fontWeight: 600,
    padding: "27px 16px 21px 16px",
  },
  StyleParentBox:{ marginTop: "24px", borderRadius: "12px", border: "1px solid #e3eaf4", background: "#ffffff" },
  opentimePH:{
    fontFamily: 'Urbanist',
  fontSize: '10px',
  fontWeight: 500,
  color:' #6d89af',
 
  letterSpacing: '0.2px'
},
UpcomingStudioopentimePH:{
  fontFamily: 'Urbanist',
  fontSize: '10px',
  fontWeight: 500,
  color:' #6d89af',
 
  letterSpacing: '0.2px'
},
StyleBadgeBox:{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" },
StyleBadgeBoxvalue:{ display: "flex", margin: "16px 0 16px 16px", flexWrap: "wrap" },
AvtarBox:{
  border: "3px solid #f5c42c",
  width: 69,
  height: 69,
  marginRight: 1
},
KeyStyleBox:{ display: 'flex', gap: '4px', alignItems: "center" },
daysandmoneyPH:{
  display:'flex',
  flexDirection:'column',
  justifyContent:'center',
  alignItems:'center'
},
daysandmoneyPH1:{
  display:'flex',
  flexDirection:'column',
  justifyContent:'center',
  alignItems:'center'
},
StudioBoxvalue:{ fontWeight: 'bold', fontSize: "12px" },
daydivPH:{
  width: '58px',
  height: '24px',
  borderRadius: '13px',
  background: '#17181d',
  color: '#ffffff',
fontFamily: 'Urbanist',
fontSize: '10px',
fontWeight: 700,
letterSpacing: '0.16666667px',
display:'flex',
justifyContent:'center',
alignItems:'center',
marginBottom:'4px'
},
daydivPHStudioUpcoming:{
  color: '#ffffff',
  fontFamily: 'Urbanist',
  fontSize: '10px',
  fontWeight: 700,
  letterSpacing: '0.16666667px',
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  marginBottom:'4px',
  width: '58px',
  height: '24px',
  borderRadius: '13px',
  background: '#17181d',
},
moneydivPH:{
  color: '#42a200',
  fontFamily: "Helvetica-Bold",
  fontSize: '12px',
  fontWeight: 400,
  textAlign: 'center',
  letterSpacing: '0.2px',
},
moneydivPHUpcomingStudio:{
  fontSize: '12px',
  fontWeight: 400,
  textAlign: 'center',
  letterSpacing: '0.2px',
  color: '#42a200',
  fontFamily: "Helvetica-Bold",
},
LeftDivPH:{
  height:'0px',
  marginTop:'10px',
 display:'flex',
  width: '45px',
  border: '1px dashed rgb(225, 230, 236)',
 

},
LeftDivPH1:{
  width: '45px',
  border: '1px dashed rgb(225, 230, 236)',
 display:'flex',
 height:'0px',
  marginTop:'10px',
},
  allReviewsTitlePH: {
    color: "#6d89af",
    fontFamily: "Urbanist",
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "19px",
    whiteSpace: "nowrap",
    textOverflow: "clip",
    overflow: "hidden",
  },
   NotAvailabeDivPH: {
    height: "46px",
    width: "100%",
    background: "#ffffff",
    borderRadius: "10px",
    alignItems: "center",
    border: "1px solid #ff5656",
    justifyContent: "center",
    display: "flex",
    marginRight: "24px",
    cursor: "pointer",
    color:'#ff5656',
    fontFamily:'urbanist',
    fontWeight:600,
    paddingInline:'10px',
    fontSize:'12px'
  },
  starsPH: {
    padding: "0px 8px",
    background: "linear-gradient(225deg, #58A2FF 0%, #1777E2 100%)",
    borderRadius: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: 'space-evenly'
  },
  star_colorPH: {
    lineHeight: "17px",
    color: "#ffffff",
    fontFamily: "Urbanist",
    fontSize: "14px",
    fontWeight: 600,
    textAlign: "center",
  },
  projectcardParentBoxPH: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    padding: '2%'
  },
  studioStatusBtnPH: {
    width: "73px",
    height: "23px",
    borderRadius: "12px",
    background: "#3389ff",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  studioStatusTextPH: {
    height: "12px",
    color: "#ffffff",
    fontFamily: "Urbanist",
    fontSize: "10px",
    fontWeight: 700,
    letterSpacing: "0.18181819px",
  },
  phNameAddressBoxPH: {
    display: "flex",
  },
  rightSideBoxTextPH: {
    lineHeight: "14px",
    color: "#17181d",
    fontFamily: "Urbanist",
    fontSize: "14px",
    fontWeight: 600,
    letterSpacing: "0.23333333px",
    alignItems: "center",
    display: "flex",
  },
  studioDetailsRightBoxPH: {
    paddingLeft: "24.5px",
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
  },
  studioDetailsLeftBoxPH: {
    borderRight: "1px solid #e3eaf4",
    height: "79px",
    paddingRight: "24px",
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    marginLeft: "10px",
  },
  addressTextPH: {
    lineHeight: "17px",
    color: "#6d89af",
    fontFamily: "Urbanist",
    fontSize: "14px",
    fontWeight: 500,
    letterSpacing: "0.23333333px",
    alignItems: "center",
    display: "flex",
    margin: "6px 0px",
    paddingLeft: "11.5px",
  },
  phNamePH: {
    lineHeight: "17px",
    color: "#17181d",
    fontFamily: "Urbanist",
    fontSize: "14px",
    fontWeight: 500,
    textDecoration: "underline",
    letterSpacing: "0.23333333px",
    alignItems: "center",
    display: "flex",
    margin: "6px 0px",
    paddingRight: "11.5px",
    borderRight: "1px solid #e3eaf4",
    height: "18px",
  },
  bookStudioBtnPH: {
    width: "159px",
    height: "48px",
    borderRadius: "14px",
    background: "#17181d",
    marginRight: "24px",
  },
  bookStudioBtnText: {
    color: "#ffffff",
    fontFamily: "Urbanist",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: "0.45714286px",
    textTransform: "none",
  },
  ratingStartMainBoxPH: {
    width: "100%",
  },
  ratingStartBoxPH: {
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  rateNowTextPH: {
    height: "14px",
    color: "#f5c42c",
    fontFamily: "Urbanist",
    fontSize: "12px",
    fontWeight: 600,
    textDecoration: "underline",
    letterSpacing: "0.18461539px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  parentContainerPH: {
    maxWidth: "none",
    padding: "0px",
  },
  sidebarParentGridPH: {
    maxWidth: "22.25%",
    minHeight: "100vh",
  },
  wholePageContentSetupPH: {
    borderLeft: "1px solid #E3EAF4",
    // minHeight: "90vh",
    flex: 1,
  },
  dynamicMainContentPH: {
    minHeight: "100vh",
    width: "100%",
    boxSizing: "border-box",
    padding: "24px",
    backgroundColor: "#F8FAFE",
  },
  blockInfoTitlePH: {
    justifyContent: "space-between",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  highlightedTitleextPH: {
    fontWeight: 600,
    fontFamily: "Urbanist",
    fontSize: 14,
    color: "#F5C42C",
    textDecoration: "underline",
    cursor: "pointer",
  },
  activeBlockMainTitlePH: {
    lineHeight: "34px",
    fontSize: "28px",
    color: "#17181D",
    fontWeight: "700",
    letterSpacing: "1px",
  },
  blockE2EPathPH: {
    fontSize: "14px",
    letterSpacing: "1px",
    fontWeight: 500,
    color: "#6D89AF",
    lineHeight: "17px",
    paddingTop: "8px",
  },
  subtaskrequestPH: {
    //width: '341px',
    height: '54px',
  
    background: '#f8fafe',
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: '12px',
    paddingRight: '12px'
},
UpcomingstudiosubtaskrequestPH: {
  
  height: '54px',
  paddingLeft: '12px',
  paddingRight: '12px',
  background: '#f8fafe',
  display: 'flex',
  justifyContent: 'space-between',
},
};
