import React from "react";

import {
  // Customizable Area Start
  Box,
  Typography,
  Grid,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import "./forecastingandbudgeting.css";
import CrewCountViewController, { Props } from "./CrewCountViewController";
import Loader from "../../../components/src/Loader.web";
import CustomNotification from "../../../components/src/CustomNotification";
import { dummyImage } from "../../../components/src/assets";
// Customizable Area End

export default class CrewCountView extends CrewCountViewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderCrewCountBox = (crewCountData: any) => {
    return (
      <Box style={webStyle.mainBox}>
        <Grid container justifyContent="space-between">
          <Grid item><Typography style={webStyle.boxTitle}>{crewCountData.category_name}</Typography></Grid>
          <Grid item><Typography style={webStyle.boxMembersCount as React.CSSProperties}>{crewCountData.member_count} members</Typography></Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: 8, maxHeight: 262, overflowX: 'auto' }}>
          {crewCountData.members.map((member: any, index: number) => {
            return <Grid key={`${index}_crecount_data`} item xs={12} sm={12} md={12} lg={6}>
              <Grid container>
                <Grid item xs={2} sm={2} md={2} lg={3}><img style={{ height: 52, width: 52, borderRadius: 13 } as React.CSSProperties} src={member[0]?.image || dummyImage} /></Grid>
                <Grid item xs={10} sm={10} md={10} lg={9} style={{ paddingLeft: 6, boxSizing: 'border-box' }}>
                  <Typography style={webStyle.productionMemberName}>{member[0]?.full_name}</Typography>
                  <Typography style={webStyle.productionMemberDesignation}>{member[0]?.designation}</Typography>
                </Grid>
              </Grid>
            </Grid>
          })}
        </Grid>
      </Box>
    )
  }
  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <>
        <Loader loading={this.state.isLoading}></Loader>
        <Grid container style={{ marginTop: 12 }}>
          {this.state.crewCountData.map((crewcount: any, index: number) => {
            return (<Grid key={`${index}_crewcountdata`} style={{ padding: index % 2 === 0 ? '12px 11.3px 12px 0' : '12px 0 12px 11.3px' }} item sm={12} md={6} >
              {this.renderCrewCountBox(crewcount.attributes)}
            </Grid>)
          })}
        </Grid>
        <CustomNotification navigation={(route: string) => this.props.navigation.navigate(route)} notification={this.state.crewCountNotification} handleCloseNotification={this.handleCrewCountNotification} />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainBox: {
    height: 284,
    borderRadius: 12,
    border: '1px solid #e3eaf4',
    background: '#ffffff',
    padding: 16,
  },
  boxTitle: {
    lineHeight: '19px',
    color: '#17181d',
    fontFamily: 'Urbanist',
    fontSize: 16,
    fontWeight: 700,
  },
  boxMembersCount: {
    lineHeight: '18px',
    color: '#6f7c8e',
    fontFamily: 'Urbanist',
    fontSize: 14,
    fontWeight: 600,
  },
  productionMemberName: {
    lineHeight: '19px',
    color: '#17181d',
    fontFamily: 'Urbanist',
    fontSize: '16px',
    fontWeight: 600,
  },
  productionMemberDesignation: {
    lineHeight: '14px',
    color: '#6d89af',
    fontFamily: 'Urbanist',
    fontSize: '12px',
    fontWeight: 500,
    marginTop: 6,
  }
};
// Customizable Area End
