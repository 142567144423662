Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";

//API CONSTANTS
exports.getAllProjectsAPIEndPoint = "bx_block_projectportfolio/projectportfolios";
exports.getMyProjectsAPIEndPoint = "bx_block_projectportfolio/my_project";
exports.createProjectEndPoint = "bx_block_projectportfolio/projectportfolios";
exports.viewProjectsAPIEndPoint = "bx_block_projectportfolio/projectportfolios/";
exports.deleteProjectAPIEndPoint = "bx_block_projectportfolio/projectportfolios/";

exports.postAPiEndMethod = "POST"
exports.getAPiEndMethod = "GET"
exports.putAPiEndMethod = "PUT"
exports.patchAPiEndMethod = "PATCH"
exports.deleteAPiEndMethod = "DELETE"
//API CONSTANTS

//Labels
exports.labelTitleText = "Project Portfolio";
exports.labelAllProjectText = "All Project";
exports.labelMyProjectText = "My Project";
exports.labelProjectNameText = "Project Name";
exports.labelStartDateNameText = "Start Date";
exports.labelEndDateNameText = "End Date";
exports.labelEditBtnText = "Edit";
exports.labelDeleteBtnText = "Delete";
exports.labelConfirmBtnText = "Yes";
exports.labelRejectBtnBtnText = "No";
exports.labelSubmitBtnBtnText = "Submit";
exports.labelAddProjectText = "Add Project";
exports.labelEditProjectText = "Edit Project";
exports.labelViewProjectText = "View Project Details";
exports.labelSearchInputText = "Search Project";
exports.labelsCreateFormFields = {
  projectName: { label: "Project Name", placeHolder: "Enter Project Name" },
  projectDescription: { label: "Project Description", placeHolder: "Enter Project Description" },
  clientDetails: { label: "Client Details", placeHolder: "Enter Client Details" },
  teamSize: { label: "Team Size", placeHolder: "Enter Team Size" },
  projectUrl: { label: "Project URL", placeHolder: "Enter Team Size" },
  developerEmail: { label: "Email", placeHolder: "Enter Email" },
  developerFullName: { label: "Full Name", placeHolder: "Enter Full Name" },
  phoneNumber: { label: "Phone Number", placeHolder: "Enter Phone Number" },
  startDate: { label: "Start Date", placeHolder: "Enter Start Date" },
  endDate: { label: "End Date", placeHolder: "Enter End Date" },
  projectImages: { label: "Project Images", placeHolder: "" },
  projectDocuments: { label: "Project Documents", placeHolder: "" },
}

exports.deleteConfirmDialogTitle = "Delete Project Confirmation";
exports.deleteConfirmDialogDetailText = "Are you sure you want to delete this project?";
//Labels

// Customizable Area End