import React from "react";
import { Card, Box, CardMedia, CardContent, Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { JobCardImage, LocationIconjob, dotsIcon, headingicon, Bg_Copy, leaveproject, editstudio,  BookingOpen, BookingClose, RequestBook, MytaskProfile, studiodelete } from "./assets";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreHoriz";
import Menu from "@material-ui/core/Menu";
interface MyProps {
    data: any;
    handleOpenRequest:any;
    deletestudio:any;
    Editstudio:any
}



const useStyles = makeStyles({
    cardDivWithOpenSideBar: () => ({
        width: 300,
        //height: 240,
        borderRadius: 14,
        padding: 8,
        border: "1px solid #E1E6EC",
        position: 'relative',
        '& .MuiCardContent-root': {
            padding: 0
        },
        "@media(max-width: 500px)": {

        },
        "@media(min-width: 1440px)": {
            width: '312px',
        },
        backgroundImage: `url(${Bg_Copy})`, // Assuming JobCardImage is the path to your image
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: 200,
    }),
    cardDivWithClosedSideBAr: () => ({
        width: 346,
        borderRadius: 14,
        padding: 8,
        border: "1px solid #E1E6EC",
        position: 'relative',
        '& .MuiCardContent-root': {
            padding: 0
        },
        "@media(max-width: 500px)": {

        },
    }),
    parentBoxValue: () => ({
        display: "flex",
        justifyContent: 'space-between',



    }),
    parentBox: () => ({
        //height: "177px",
        borderRadius: "12px",
        boxShadow: "0px 7px 18px rgba(24,39,42,0.07)",
        cursor: "pointer",
        paddingBottom:'12px'
        //display:'flex',
       // justifyContent:'space-between'
    }),
    imageContainer: () => ({
        height: "100%",
        width: "100%",
        position: "relative",
    }),
    studioImage: () => ({
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        objectFit: "cover",
        borderRadius: "12px"
    }),
    studioStatus: () => ({
        position: "absolute",
        top: "10px",
        right: "10px",
        textAlign: "end",
        height: "23px",
    }),
    studioDetailsMainBox: () => ({
        position: "absolute",
        bottom: "5px",
        right: "5px",
        left: "5px",
        background: "#fff",
        height: 95,
        borderRadius: "10px",
        opacity: 0.8290272,
        padding: 12,
        boxSizing: "border-box",
    }),
    studioDetails: () => ({
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    }),
    ParentSubheading1: () => ({
        display: "flex",
        gap: "5px",

    })
    ,
    backgroundImage: {
        // Adjust the height according to your requirement
    },
    humbergerparent:{
        display:'flex',
        paddingLeft:'23px',
        alignItems:'center',
        gap:'10px',
        paddingBottom:'16px',
      },
      humbergerParentIcon:{
       
      },  humbergericon:{
        height:'16px',
        width:'16px',
        cursor:'pointer'
      },
      humbergerLabel:{
        color: '#17181d',
  fontFamily: 'Urbanist',
  fontSize: '14px',
  fontWeight: 600,
  cursor:'pointer'

      },
      ProfileInformationandhumberger:{

        display:'flex',
        justifyContent:"space-between",
        paddingLeft:'12px',
        paddingRight:'12px',
        paddingTop:'12px',
        width:'100%'
      },
      ProfileimageandName:{
        display:'flex',
        gap:'5px'
      },
      Profile:{
       
      },
      NameandAddress:{
        display:'flex',
        flexDirection:'column',
        gridRowGap:'4px'
      },
      Name:{
        color: '#17181d',
        fontFamily: 'Urbanist',
        fontSize: '16px',
        fontWeight: 600,
        letterSpacing: '0.2px'
      },
      cityandstate:{
        color: '#17181d',
        fontFamily: 'Urbanist',
        fontSize: '12px',
        fontWeight: 500,
        letterSpacing: '0.15px',
      },
      paymoney:{
        color: '#6d89af',
  fontFamily: 'Helvetica',
  fontSize: '12px',
  fontWeight: 400,
  letterSpacing: '0.15px',

      },
      Profilediv:{
        height: '59px',
        width: '59px',
        borderRadius: '12px',
        boxShadow: '0px 7px 18px rgba(24,39,42,0.07)'
      }


});

export default function RequestStudioBookingPH(props: MyProps) {
    const {data,handleOpenRequest,Editstudio,deletestudio}=props

    const classes = useStyles();
    const ITEM_HEIGHT = 48;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openhumberger = Boolean(anchorEl);
    const handleClick = (event:any) => {
        event.stopPropagation();
      setAnchorEl(event.currentTarget);
    };
    const handleClosehumberger = () => {
        
        setAnchorEl(null);
      };
      const options = [
        
        { label: "Edit Studio", icon: editstudio },
        { label: "Remove Studio", icon: studiodelete }
      ];
      const handleOptionClick = (event:any, label:string) => {
        event.stopPropagation();
        handleClosehumberger(); // Close the dropdown
      
        // Perform different actions based on the clicked label
        if(label === 'Edit Studio')
        {
            Editstudio(data?.id)
        }
        else if (label === 'Remove Studio') {
            deletestudio(data?.id)
        } 
        
      };
      
    return (
        <Box className={classes.parentBox}>
            <Box className={classes.ProfileInformationandhumberger}>
            <Box className={classes.ProfileimageandName}>
                <Box className={classes.Profile}>
                  <img src={data.attributes.image||MytaskProfile} className={classes.Profilediv}/>
                </Box>
                <Box className={classes.NameandAddress}>
                    <Box className={classes.Name}>
                    {data?.attributes?.name}
                    </Box>
                    <Box className={classes.cityandstate}>
                    {data.attributes.address}, {data.attributes.city} ({data.attributes.state})
                        </Box>
                        <Box className={classes.paymoney}>
                        ₹{data?.attributes?.price_per_day} Day | ₹{data?.attributes?.price_per_hour} Hr
                        </Box>

                </Box>
            </Box>
            <Box style={{paddingRight:"12px"}   }>
    
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={openhumberger ? "long-menu" : undefined}
        aria-expanded={openhumberger ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        style={{color:'#6D89AF'}}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={openhumberger}
        onClose={handleClosehumberger}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
            left:'489px'
          },
        }}
      >
        {options.map((option, index) => (
        <div key={index} onClick={(e) => handleOptionClick(e, option.label)} >
           <Box className={classes.humbergerparent}>
            <Box className={classes.humbergerParentIcon}>
          <img src={option.icon} alt="" className={classes.humbergericon} />
          </Box>
          <Box className={classes.humbergerLabel}>
          {option.label}
          </Box>
          </Box> 
        </div>
      ))}
      </Menu>
    
    </Box> 
    
            </Box>
            <Box style={{borderBottom:"1px solid #e3eaf4",marginLeft:'12px',marginRight:'12px',paddingTop:'12px'}}></Box>
            <Box style={{marginLeft:'12px',marginRight:'12px',height:'36px', background: 'rgba(245,196,44,0.07)',display:'flex',justifyContent:'center',alignItems:'center',borderRadius:'9px',marginTop:'12px',cursor:'pointer'}} onClick={()=>handleOpenRequest(data.id,data.attributes.address,data.attributes.city,data.attributes.state)}>
                <Typography style={{ color: '#f5c42c',
  fontFamily: 'Urbanist',
  fontSize: '12px',
  fontWeight: 600,cursor:'pointer'}}>{data?.attributes?.request_count} Requests</Typography>
                </Box>
          
        </Box>
    );
}
