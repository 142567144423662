import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isStudioBookingDetailSideBarOpenPH: boolean;
  isLoading: boolean;
  isDropdownOpenPH: boolean;
  studioDetailsDataPH: any;
  studioDetailsDataid:any;
  studioDetailReviewData: any;
  studioDetailTabValue: number;
  isModelOpen: boolean;
  studioBookingId: string;
  modelType: string;
  notification: { type: string, open: boolean, message: string, route?: string };
  open1:boolean;

  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class AllStudioDetailPHController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getStudioDetailsMessageId: string = "";
  getStudioDetailReviewDataMessageId: string = "";
  bookStudioMessageId: string = "";
  giveRatingMessageId: string = "";
  deletebookingcallid :string="";
  putupdatestudiodetailapiid:string=""
  getallStudiocallid:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isStudioBookingDetailSideBarOpenPH: true,
      isLoading: false,
      isDropdownOpenPH: false,
      studioDetailsDataPH: {},
      studioDetailsDataid:[],
      studioDetailReviewData: {},
      studioDetailTabValue: 0,
      isModelOpen: false,
      studioBookingId: "",
      modelType: "",
      notification: { type: "", open: false, message: "" },
      open1:false
      // Customizable Area End
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const ApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const studioResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.getStudioDetailsMessageId === ApiRequestCallId) {
        this.getStudioDetailsDataResponse(studioResponseJson);
      } else if (this.getStudioDetailReviewDataMessageId === ApiRequestCallId) {
        console.log("rating....",studioResponseJson)
        this.getStudioDetailReviewDataResponse(studioResponseJson);
      } else if (this.bookStudioMessageId === ApiRequestCallId) {
        this.bookStudioResponse(studioResponseJson);
      } 
      else if(this.getallStudiocallid===ApiRequestCallId)
      {
        console.log("response........",studioResponseJson)
        this.setState({ isLoading: false});
        this.props.navigation.navigate("AllStudioBookingListPH")
      }
      this.apiResponseDataStudiodetailPage(ApiRequestCallId,studioResponseJson)
    }
    // Customizable Area End
  }

  // Customizable Area Start
  apiResponseDataStudiodetailPage=async(ApiRequestCallId:any,studioResponseJson:any)=>{
     if (this.giveRatingMessageId === ApiRequestCallId) {
      this.giveRatingResponse(studioResponseJson);
    }
    else if(this.deletebookingcallid===ApiRequestCallId)
    {
        this.props.navigation.navigate("AllStudioBookingListPH")
        this.setState({isLoading:false})
        
    }
    else if(this.putupdatestudiodetailapiid==ApiRequestCallId)
    {
      const studioId = await getStorageData("studioidph");
      this.getStudioDetailsMessageId = await this.apiCallvalue(configJSON.validationApiMethodType, configJSON.getStudioDetailsAPIEndPoint + studioId);
    }
  }
  getStudioDetailsDataResponse = (studioBookingDetailResponseJson: { errors: { token: string }[], data: any }) => {
    if (studioBookingDetailResponseJson.data) {
        console.log("eeeee",studioBookingDetailResponseJson)
      this.setState({ studioDetailsDataPH: studioBookingDetailResponseJson.data.attributes ,studioDetailsDataid:studioBookingDetailResponseJson.data});
    }
    this.setState({ isLoading: false });
  }
  getallStudioList = async () => {
    this.getallStudiocallid = await this.apiCallvalue(configJSON.validationApiMethodType, configJSON.getallStudioListPHEndPoint+`?per_page=9&page=1`);
};

  getStudioDetailReviewDataResponse = (studioBookingDetailResponseJson: { errors: { token: string }[], average_rating: number, rating_star: any, review_rating_count: string, reviews: { data: any[] } }) => {
    if (studioBookingDetailResponseJson.reviews?.data) {
      this.setState({ studioDetailReviewData: studioBookingDetailResponseJson });
    } else if (studioBookingDetailResponseJson.errors[0].token) {
      this.setState({ notification: { open: true, type: "error", message: studioBookingDetailResponseJson.errors[0].token, route: "LandingPage" } });
    }
    this.setState({ isLoading: false });
  }

  bookStudioResponse = (studioBookingDetailResponseJson: { errors: any, data: any }) => {
    if (studioBookingDetailResponseJson.data) {
      this.handleModelOpenPH();
      this.setState({ modelType: "successModel" });
    } else if (typeof (studioBookingDetailResponseJson.errors.message) === "string") {
      this.setState({ notification: { open: true, type: "error", message: studioBookingDetailResponseJson.errors.message } });
    } else if (studioBookingDetailResponseJson.errors.length && studioBookingDetailResponseJson.errors[0].token) {
      this.setState({ notification: { open: true, type: "error", message: studioBookingDetailResponseJson.errors[0].token, route: "LandingPage" } });
    }
    this.setState({ isLoading: false });
  }

  giveRatingResponse = async (studioBookingDetailResponseJson: { errors: { token: string }[], data: any }) => {
    if (studioBookingDetailResponseJson.data) {
      this.handleModelClosePH();
      this.getStudioDetailReviewDataMessageId = await this.apiCallvalue(configJSON.validationApiMethodType, configJSON.getStudioDetailReviewDataAPIEndPoint + this.state.studioBookingId);
    } else if (studioBookingDetailResponseJson.errors[0].token) {
      this.setState({ notification: { open: true, type: "error", message: studioBookingDetailResponseJson.errors[0].token, route: "LandingPage" } });
    }
    this.setState({ isLoading: false });
  }

  handleStudioDetailPageSideBarPH = () => {
    setStorageData("sidebarvalue", (!this.state.isStudioBookingDetailSideBarOpenPH).toString())
    this.setState({
      isStudioBookingDetailSideBarOpenPH: !this.state.isStudioBookingDetailSideBarOpenPH
    });
  };

  handleDropdownPH = () => {
    this.setState({
      isDropdownOpenPH: !this.state.isDropdownOpenPH
    });
  };

  handleNavigation = (page: string) => {
    this.props.navigation.navigate(page)
  };

  Editstudio=async(id:any)=>{
    console.log("idstudio............",id)
    await setStorageData("editstudio",id)
    await setStorageData("studiotype","editstudio")
    await setStorageData("imagedatavalue","previousimage")

    
    this.handleOpen1()
   }
  async componentDidMount() {
    const studioId = await getStorageData("studioidph");
    const isSideBarOpen = await getStorageData("sidebarvalue");
    this.setState({ studioBookingId: studioId, isStudioBookingDetailSideBarOpenPH: JSON.parse(isSideBarOpen) });
    this.getStudioDetailsMessageId = await this.apiCallvalue(configJSON.validationApiMethodType, configJSON.getStudioDetailsAPIEndPoint + studioId);
    this.getStudioDetailReviewDataMessageId = await this.apiCallvalue(configJSON.validationApiMethodType, configJSON.getStudioDetailReviewDataAPIEndPoint + studioId);
  }

  apiCallvalue = async (studioBookingDetailMethod: string, studioBookingDetailEndPoint: string, studioBookingDetailBodyData?: any) => {
    const authToken = await getStorageData("token");
    const studioBookingDetailHeader = {
      "Content-Type": configJSON.contentTypeApiUpdateUser,
      token: authToken
    };
    const studioBookingDetailRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setState({ isLoading: true });
    studioBookingDetailRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      studioBookingDetailEndPoint
    );
    studioBookingDetailRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(studioBookingDetailHeader)
    );
   
    studioBookingDetailRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      studioBookingDetailMethod
    );
    studioBookingDetailBodyData && studioBookingDetailRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(studioBookingDetailBodyData)
    );
    runEngine.sendMessage(studioBookingDetailRequestMessage.id, studioBookingDetailRequestMessage);
    return studioBookingDetailRequestMessage.messageId;
  }

  handleModelOpenPH = () => {
    this.setState({ isModelOpen: true });
  }
  handleOpen1 = () => {
    this.setState({ open1: true })
}
handleClose1 = () => {
    this.setState({ open1: false })

};
  handleModelClosePH = () => {
    this.setState({ isModelOpen: false, modelType: "" });
  }

  handleTabValueChangePH = (tabVal: number) => {
    this.setState({ studioDetailTabValue: tabVal });
  }

  handleBookStudioModelOpen = () => {
    this.handleModelOpenPH();
  }

  sendBookingRequestPH = async (bookingData: any) => {
    this.bookStudioMessageId = await this.apiCallvalue(configJSON.manageprofilemethodtype, configJSON.bookStudioAPIEndPoint, bookingData);
  }

  handleRatingModelOpen = () => {
    this.handleModelOpenPH();
    this.setState({ modelType: "ratingModel" });
  }

  handleGiveRatingAPICallPH = async (ratingData: any) => {
    if (!ratingData.review.given_star) {
      this.setState({ notification: { open: true, message: "Please add rating", type: "error" } });
      return false;
    } else if (!ratingData.review.comment) {
      this.setState({ notification: { open: true, message: "Please add review", type: "error" } });
      return false;
    }
    this.giveRatingMessageId = await this.apiCallvalue(configJSON.manageprofilemethodtype, configJSON.giveRatingAPIEndPoint, ratingData);
  }

  handleCloseNotification = () => {
    this.setState({ notification: { type: "", open: false, message: "" } });
  }

  getStatusBGPH = (status: string, type: "bg" | "wid") => {
    if (status === "Available") {
      return type == "bg" ? "#3389ff" : "73px"
    } else if (status === "Not Available") {
      return type == "bg" ? "#000000" : "92px"
    } else if (status === "Booked") {
      return type == "bg" ? "#f5c42c" : "64px"
    }
  }
  deletestudioPH = async(id:any) => {
    let token = await getStorageData("token");
    
    const header = {
        "Content-Type": configJSON.exampleApiContentType,
        "token": token
    };
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deletebookingcallid = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.deletestudioApiEndPoint+`/${id}`
    );
   
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPIMethod
  );

    runEngine.sendMessage(requestMessage.id, requestMessage);
      }

      handleBadgeVisibilityPH = async (e: any, id: any, booking_permission: boolean, type?: string) => {
        e.stopPropagation();
        const token = await getStorageData("token", false);
        const header = {
          "Content-Type": configJSON.exampleApiContentType,
          "token": token
        };
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        const attrs = { booking_permission: !booking_permission }
        if (type === "studios") {
          this.setState({ isLoading: true });
          this.putupdatestudiodetailapiid = requestMessage.messageId;
        }
        const data = {
          attributes: attrs,
        };
    
        const httpBody = {
          data: data,
        };
        
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.putAllStudioBookingApiEndPoint + id 
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
      
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.updatePasswordAPiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }
  // Customizable Area End
}
