import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { IProject, IProjectAttributes } from "./types";
import { ChangeEvent, KeyboardEvent } from "react";
// Customizable Area End
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
const { NavigationActions, StackActions } = require("react-navigation");

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isModalVisible: boolean;
  filterValue: string;
  isDeleteModalOpen: boolean;
  projects: IProject[];
  token: string;
  searchInputText: string;
  deleteProjectID: string;
  isFilterMenuOpen: Element | null;
  isDialogOpen: boolean;
  loading: boolean;
  pageNumber: number | null;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProjectPortfolioController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetProjectsListCallId: string | null = "";
  apiDeleteProjectsListCallId: string | null = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    // Customizable Area End

    this.state = {
      isModalVisible: false,
      filterValue: "All Projects",
      isDeleteModalOpen: false,
      projects: [],
      token: "",
      searchInputText: "",
      deleteProjectID: "",
      isFilterMenuOpen: null,
      isDialogOpen: false,
      loading: false,
      pageNumber: 1,
      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  handleResponseMessage = (input: {
    responseJson: { message: string; error: string; errors: string[] };
    errorJson?: string[];
    onSuccess: () => void;
    onFail: () => void;
  }) => {
    const { responseJson, onSuccess, onFail, errorJson } = input;

    if (responseJson && !responseJson.error && !responseJson.errors) {
      onSuccess();
    }

    if (responseJson.error || errorJson || responseJson.errors) {
      onFail();
    }
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorJson = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      switch (apiRequestCallId) {
        case this.apiGetProjectsListCallId: {
          this.getProjectListHandleResponse(responseJson, errorJson);
          break;
        }
        case this.apiDeleteProjectsListCallId: {
          this.deleteProjectHandleResponse(responseJson, errorJson);
          break;
        }
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    let token = await getStorageData("token");
    this.setState({ token: token });
    this.getProjectsListData("");
    window.addEventListener("scroll", this.handleScroll);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  getProjectListHandleResponse = (
    responseJson: {
      data: { data: [{ id: string; attributes: IProjectAttributes }] };
      total_page: number;
      message: string;
      error: string;
      errors: string[];
    },
    errorJson: string[]
  ) => {
    this.handleResponseMessage({
      responseJson,
      errorJson,
      onSuccess: async () => {
        let meta_userId = await getStorageData("userId");
        if (!responseJson.data.data.length) {
          this.setState({ pageNumber: null, loading: false });
          return;
        }
        const projectsData = responseJson.data.data.map(
          (element: { id: string; attributes: IProjectAttributes }) => {
            return {
              projectId: element.id,
              project_name: element.attributes.project_name,
              start_date: element.attributes.project.data
                ? element.attributes.project.data.attributes.start_date
                : "",
              end_date: element.attributes.project.data
                ? element.attributes.project.data.attributes.end_date
                : "",
              isMyProject:
                element.attributes.account_id.toString() === meta_userId,
            };
          }
        );
        this.setState({
          loading: false,
          projects:
            this.state.pageNumber === 1
              ? projectsData
              : [...this.state.projects, ...projectsData],
        });
      },
      onFail: () => {
        this.setState({ loading: false });
        this.showAlert("Error", responseJson.error);
      },
    });
  };

  deleteProjectHandleResponse = (
    responseJson: { message: string; error: string; errors: string[] },
    errorJson: string[]
  ) => {
    this.handleResponseMessage({
      responseJson,
      errorJson,
      onSuccess: async () => {
        this.getProjectsListData("");
        this.setState({ loading: false });
        if (responseJson.message) {
          this.showAlert("Success", responseJson.message);
        } else {
          this.showAlert("Error", responseJson.error);
        }
      },
      onFail: () => { },
    });
  };

  handleScroll = () => {
    if (
      this.state.pageNumber &&
      document.scrollingElement &&
      document.scrollingElement.scrollTop &&
      document.scrollingElement.scrollHeight -
      document.scrollingElement.scrollTop <=
      document.scrollingElement.clientHeight
    ) {
      this.setState({ pageNumber: this.state.pageNumber + 1 });
      this.getProjectsListData(this.state.searchInputText);
    }
  };

  getProjectsListData = async (searchText: string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    this.setState({ loading: true });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetProjectsListCallId = requestMessage.messageId;

    const getAPIEndPoint =
      this.state.filterValue === "All Projects"
        ? configJSON.getAllProjectsAPIEndPoint +
        "?page=" +
        this.state.pageNumber +
        "&search_key=" +
        searchText
        : configJSON.getMyProjectsAPIEndPoint +
        "?page=" +
        this.state.pageNumber +
        "&search_key=" +
        searchText;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  deleteProject = async () => {
    this.setState({ isDeleteModalOpen: false, loading: true, pageNumber: 1 });
    this.handleCloseDialog("dialog");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteProjectsListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteProjectAPIEndPoint + this.state.deleteProjectID
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleOpenDialog = (type: string, event?: React.MouseEvent<HTMLElement>) => {
    if (type === "filter" && event)
      this.setState({ isFilterMenuOpen: event.currentTarget });
    else if (type === "dialog") this.setState({ isDialogOpen: true });
  };

  handleCloseDialog = (type: string) => {
    if (type === "filter") this.setState({ isFilterMenuOpen: null });
    else if (type === "dialog") this.setState({ isDialogOpen: false });
  };

  handleSelectFilterValue = (type: string) => {
    this.setState(
      {
        filterValue: type === "all" ? "All Projects" : "My Projects",
        pageNumber: 1,
      },
      () => {
        this.getProjectsListData("");
      }
    );
    this.handleCloseDialog("filter");
  };

  handleClickDeleteProject = (projectId: string) => {
    this.setState({ deleteProjectID: projectId });
    this.handleOpenDialog("dialog");
  };

  handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchInputText: event.target.value, pageNumber: 1 });
  };

  handleSearchInputKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      this.getProjectsListData(this.state.searchInputText);
    }
  };

  handleSearch(text: string) {
    if (text.length >= 3) {
      this.getProjectsListData(text);
    } else {
      this.getProjectsListData("");
    }
    this.setState({ searchInputText: text });
  }

  changeApi(type: string) {
    this.setState(
      {
        filterValue: type,
        isModalVisible: false,
        projects: [],
        searchInputText: "",
        pageNumber: 1,
      },
      () => {
        this.getProjectsListData("");
      }
    );
  }

  goBack() {
    const message: Message = new Message(getName(MessageEnum.NavigationEmailLogInMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  openFilterMenu() {
    this.setState({ isModalVisible: true });
  }

  deleteModal(projectID: string) {
    this.setState({ isDeleteModalOpen: true, deleteProjectID: projectID });
  }

  closeDeleteModal() {
    this.setState({ isDeleteModalOpen: false });
  }

  filterModal() {
    this.setState({ isModalVisible: false });
  }

  naviagteToCreatePage = () => {
    const message: Message = new Message(
      getName(MessageEnum.CreateProjectMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  naviagteToEditPage = (projectId: string) => {
    const message: Message = new Message(
      getName(MessageEnum.CreateProjectMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.PropsData), projectId);
    this.send(message);
  };

  naviagteToViewPage = (projectId: string) => {
    const message: Message = new Message(
      getName(MessageEnum.ViewProjectMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.PropsData), projectId);
    this.send(message);
  };
  loadMoreData = () => {
    if (this.state.pageNumber !== null) {
      this.setState(
        {
          pageNumber: Number(this.state.pageNumber) + 1,
        },
        () => this.getProjectsListData("")
      );
    }
  };
  logOutFunction = () => {
    removeStorageData("token");
    this.props.navigation.dispatch(
      StackActions.reset({
        index: 0,
        actions: [
          NavigationActions.navigate({
            routeName: "EmailAccountLoginScreen",
          }),
        ],
      })
    );
  };
  // Customizable Area End
}
