import React from "react";
import { Card, Box, CardMedia, CardContent, Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { studioimg, callicon, msgicon, studiovisitingicon, studiobblockon, studioblockoff, locationIcon ,switchEnable,switchDisable} from "./assets";
import { styled } from '@material-ui/core/styles';
import { fontSize } from "../../blocks/email-account-login/src/EmailAccountLoginBlock.web";

interface MyProps {
    isSidebar: any;
    data: any;
    handleBadgeVisibility:any;
}



const useStyles = makeStyles({
    cardDivWithOpenSideBar: () => ({
        width: 270,
        
        borderRadius: 14,
        padding: 8,
        border: "1px solid #E1E6EC",
        position: 'relative',
        '& .MuiCardContent-root': {
            padding: 0
        },
        "@media(max-width: 500px)": {
            width: 256
        },
        "@media(min-width: 1440px)": {
            width: '314px',
        },
    }),
    cardDivWithClosedSideBAr: () => ({
        width: 346,
        //height: 240,
        borderRadius: 14,
        padding: 8,
        marginTop: 16,
        border: "1px solid #E1E6EC",
        position: 'relative',
        '& .MuiCardContent-root': {
            padding: 0
        },
        "@media(max-width: 500px)": {
            width: 256
        },
    }),
    cardImage: {
        width: '100%',
        height: '123px',
        borderRadius: '12px',
    },
    cardImageDiv: {
        position: 'relative',
    },
    overlay: {
        position: 'absolute',
        borderRadius: '12px',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundImage: "linear-gradient(180deg, rgba(0,0,0,0.15) 0%, rgba(0,0,0,0.65) 100%)"
    },
    cardTopContent1: {
        display: 'flex',
        justifyContent: 'space-between',
        position: 'absolute',
        top: 65,
        paddingInline: 12,
        width: '88%',
        alignItems: 'end'
    },
    cardTopContent2: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: 10,
        paddingBottom: 12,
        borderBottom: '2px solid #e3eaf4'
    },
    cardTopContent3: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: 10,
    },
    ratingBox: {
        background: '#F5C42C',
        width: 61,
        height: 22,
        color: 'white',
        fontWeight: 700,
        fontSize: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: "10px",
        letterSpacing:"0.18181819px",
        fontFamily:"urbanist"
    },
    moreprojectBox1: {
        border: '1px solid #e2eaf4',
        borderRadius: 10,
        backgroundColor: '#f8fafe',
        width: 156,
        height: 38,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 14,
        fontWeight: 700,
        color: 'black',
    },
    lessprojectBox1: {
        border: '1px solid #e2eaf4',
        borderRadius: 10,
        backgroundColor: '#f8fafe',
        width: 125,
        height: 38,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 14,
        fontWeight: 700,
        color: 'black',
    },
    projectBox2: {
        fontSize: 12,
        color: 'black',
        fontWeight: 700,
        paddingLeft:"7px"
    },
    
    PostBox:{
        marginBottom:"4px",
        display: 'flex',
        alignItems: 'center',
        gap: 5
  },
    dateText: {
        fontSize: 10,
        color: '#6d89af',
        fontWeight: 700,
    },
    projectBox3: {
        fontSize: 12,
        color: '#6d89af',
        fontWeight: 700,
        display: 'flex',
        alignItems: 'center',
        gap: 5,
        paddingTop: "4px"
    },
    icon: {
        marginRight: 4,
        height:"14px",
        width:"14px"
    },
    iconemail:{
        height:"20px",
        width:"20px"
    },
    iconcall:{
        height:"20px",
        width:"20px"
    },
    allPictureLink:{
        color:'rgb(51, 137, 255)',
        textDecoration:'underline',
        textDecorationColor:'rgb(51, 137, 255)',
        display: '-webkit-box',
        '-webkit-line-clamp': 1,
        '-webkit-box-orient': 'vertical',  
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: 180
      },
    msgcontent: {
    },
    callcontent: {
    },
    msg: {
        display: 'flex',
        gap: '8px',
    },
    call: {
        display: 'flex',
        gap: '10px',
    },
    mail: {
        fontWeight: 'bold',
        fontSize: "14px",
        fontFamily:"urbanist"
    },
    callnumber: {
        fontWeight: 'bold',
        fontSize: "14px",
        fontFamily:"urbanist"
    },
  
    moneytag: {},
    moneysubtag: {},
    daytag: {},
    cardsubcontent: {
        gridRowGap: '5px',
    },
    moneyDiv: {
        fontSize: "12px",
        color: "#42A200",
        lineHeight: "16px"
    },
    headingTagless: {
        fontSize: '16px',
        color: '#FFFFFF',
        paddingBottom: "3px",
        paddingTop: "15px",
        fontFamily:"urbanist"
    },
    headingTagmore: {
        fontSize: '21px',
        color: 'FFFFFF',
        paddingBottom: "3px",
        paddingTop: "15px",
        fontFamily:"urbanist"
    },
    ParentDiv:{
        paddingBottom:"10px"
    },
    projectgroup:{
        
    }
});

export default function AllStudioCard(props: MyProps) {
    const { isSidebar, data ,handleBadgeVisibility} = props;
    const classes = useStyles({ isSidebar });
    return (
        
        <Card className={isSidebar ? classes.cardDivWithOpenSideBar : classes.cardDivWithClosedSideBAr}>
            <div className={classes.cardImageDiv}>
                <CardMedia
                    className={classes.cardImage}
                    component="img"
                    alt="Image"
                    image={data.attributes.image}

                />
                <div className={classes.overlay}></div>
            </div>
            <CardContent>
                <Box className={classes.cardTopContent1}>
                    <Box>
                        <Typography variant="h5" component="h5" className={data ? classes.headingTagless : classes.headingTagmore}>
                            {data.attributes.name}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p" style={{ color: "white", display: 'flex', alignItems: 'center', fontSize: "12px",fontFamily:"urbanist" }}>
                            <img src={locationIcon} alt="" className={classes.icon} />
                            {data.attributes.address}
                        </Typography>
                    </Box>
                    <Box className={classes.ratingBox}>
                        {data.attributes.status}

                    </Box>
                </Box>
                <Box className={classes.cardTopContent2}>
                    <Box className={classes.cardsubcontent}>
                        <Box className={classes.msg}>
                            <img src={msgicon} alt="" className={classes.iconemail} />
                            <Typography className={classes.mail}>{data.attributes.email}</Typography>
                        </Box>
                        <Box className={classes.call}>
                            <img src={callicon} alt="" className={classes.iconcall} />
                            <Typography className={classes.callnumber}>{data.attributes.contact_number}</Typography>

                        </Box>

                    </Box>

                    <Box className={classes.moneyDiv}>
                        <Typography style={{ fontSize: "12px", fontWeight: 600 }}>₹{data.attributes.price_per_day}</Typography>
                        <Typography style={{ fontSize: "12px", fontWeight: 600 }}>Per Day</Typography>
                    </Box>
                </Box>
                <Box className={classes.cardTopContent3}>
                    <Box className={classes.projectBox2}>
                        <Box className={classes.PostBox}>
                            Owned By: <Link href="#" className={classes.allPictureLink}>{data.attributes.production_house}</Link>
                        </Box>
                        <Box className={classes.projectBox3}>
                            <img src={studiovisitingicon} alt="" className={classes.icon} />
                            <Typography className={classes.dateText}>Visiting Time {data.attributes.open_time} to {data.attributes.close_time} </Typography>
                        </Box>
                    </Box>
                    <Box
                          style={webStyle.alignContent}
                        >
                          <Typography style={webStyle.block as React.CSSProperties}>
                            {data.attributes.blocked ? <Typography className="block_color">Blocked</Typography> : <Typography className="block">Block</Typography>}
                            {data.attributes.blocked
                              ?
                              <img src={switchEnable}
                                style={webStyle.blockBox}
                                data-test-id={`${data.id}_switch_enable`}
                                onClick={(e: any) => handleBadgeVisibility(e, data.id, data.attributes.blocked, "studios")}
                              />
                              :
                              <img src={switchDisable}
                                style={webStyle.blockBox}
                                data-test-id={`${data.id}_switch_disable`}
                                onClick={(e: any) => handleBadgeVisibility(e, data.id, data.attributes.blocked, "studios")}
                              />
                              
                            }
                          </Typography>
                        </Box>
                </Box>
            </CardContent>
        </Card>
    
    );
}
const webStyle = {
    alignContent: {
        display: "flex",
        alignItems: "center"
      },
      blockBox: {
        marginRight: "1%",
        cursor: "pointer",
      },
      block: {
        fontSize: "13px",
        display: "flex",
        columnGap: "1%",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
      },

}