import React from 'react';
import {
    // Customizable Area Start
    Box,
    Typography,
    styled,
    Grid,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Slider
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import Modal from '@material-ui/core/Modal';
import StudioBookingFilterModelController, { Props } from './StudioBookingFilterModelController';
import { CustomButton } from '../../email-account-login/src/CustomButton';
import {
    DatePicker,
    TimePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
const images = require("./assets");

const StyledModal = styled(Modal)({
    position: 'fixed',
    zIndex: 1300,
    inset: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

const style = {
    width: 620,
    borderRadius: '20px',
    backgroundColor: 'white',
    boxShadow: 'none',
    // maxHeight: "90%",
    // overflowY: "auto",
    // height: 192,
};

const CustomButtonBox = styled(CustomButton)({
    '& .MuiButton-label': {
        fontWeight: 600,
        fontSize: 12,
        letterSpacing: '0.34285715px',
        lineHeight: '14px',
    }
});
// Customizable Area End
export default class StudioBookingFilterModel extends StudioBookingFilterModelController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderLocationFilterContent() {
        return <>
            {this.state.filterAttributes[0].active ? <FormControl>
                <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={this.state.locationValue}
                    style={{ overflow: "hidden" }}
                    onChange={this.handleChange}
                >
                    <Grid container style={{ height: "398px", overflow: "auto", width: "calc(100% + 53px)", paddingRight: "55px" } as React.CSSProperties}>
                        {this.props.statesData.length ? this.props.statesData.map((state: { id: number, name: string, iso2: string }, index: number) => {
                            return <>
                                <Grid style={{ padding: "5px" }} item xs={6} sm={6} md={6} lg={6}>
                                    <FormControlLabel style={{ borderColor: this.state.locationValue === state.name ? "#f5c42c" : "#e3eaf4" }} className="studioFilterLabel" value={state.name} control={<Radio />} label={state.name} labelPlacement="start" />
                                </Grid>
                            </>
                        }) : null}
                    </Grid>
                </RadioGroup>
            </FormControl> : null}
        </>
    }

    renderPHFilterContent() {
        return <>
            {this.state.filterAttributes[1].active ? <FormControl>
                <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={this.state.phValue}
                    style={{ overflow: "hidden" }}
                    onChange={this.handleChangePHValue}
                >
                    <Grid container style={{ height: "398px", overflow: "auto", width: "calc(100% + 53px)", paddingRight: "55px" } as React.CSSProperties}>
                        {this.props.phList.length ? this.props.phList.map((ph: any, index: number) => {
                            return <>
                                <Grid style={{ padding: "5px" }} item xs={6} sm={6} md={6} lg={6}>
                                    <FormControlLabel style={{ borderColor: this.state.phValue === ph.attributes.production_house_name ? "#f5c42c" : "#e3eaf4" }} className="studioFilterLabel" value={ph.attributes.production_house_name} control={<Radio />} label={ph.attributes.production_house_name} labelPlacement="start" />
                                </Grid>
                            </>
                        }) : null}
                    </Grid>
                </RadioGroup>
            </FormControl> : null}
        </>
    }

    renderPriceFilterContent() {
        return <>
            {this.state.filterAttributes[2].active ?
                <Box>
                    <Box>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={this.state.priceType}
                            onChange={this.handleChangePriceType}
                        >
                            <Typography style={{ textAlign: "left", marginLeft: "8px", marginBottom: "2px", lineHeight: "19px", color: "#17181d", fontFamily: "Urbanist", fontSize: "16px", fontWeight: 700, letterSpacing: "0.16px" }}>Select price type</Typography>
                            <Grid container>
                                <Grid style={{ padding: "5px" }} item xs={6} sm={6} md={6} lg={6}>
                                    <FormControlLabel style={{ width: "200px", borderColor: this.state.priceType === "price_per_day" ? "#f5c42c" : "#e3eaf4" }} className="studioFilterLabel" value="price_per_day" control={<Radio />} label="Per day" labelPlacement="start" />
                                </Grid>
                                <Grid style={{ padding: "5px" }} item xs={6} sm={6} md={6} lg={6}>
                                    <FormControlLabel style={{ width: "200px", borderColor: this.state.priceType === "price_per_hour" ? "#f5c42c" : "#e3eaf4" }} className="studioFilterLabel" value="price_per_hour" control={<Radio />} label="Per hour" labelPlacement="start" />
                                </Grid>
                            </Grid>
                        </RadioGroup>
                    </Box>
                    <Typography style={{ textAlign: "left", marginTop: 10, marginBottom: "7px", lineHeight: "19px", color: "#17181d", fontFamily: "Urbanist", fontSize: "16px", fontWeight: 700, letterSpacing: "0.16px", marginLeft: "8px" }}>Select price range</Typography>
                    <Slider
                        max={10000} min={0}
                        value={this.state.priceValue}
                        style={{ width: 300, marginLeft: 10 }}
                        onChange={this.handleChangePriceRange}
                        valueLabelDisplay="auto"
                        aria-labelledby="range-slider"
                    />
                </Box>
                : null}
        </>
    }

    renderDateFilterContent() {
        return <>
            {this.state.filterAttributes[3].active ?
                <Box style={{ marginTop: 0, textAlign: "center" }}>
                    <Box>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={this.state.dateType}
                            onChange={this.handleChangeDateType}
                        >
                            <Typography style={{ textAlign: "left", marginLeft: "8px", marginBottom: "2px", lineHeight: "19px", color: "#17181d", fontFamily: "Urbanist", fontSize: "16px", fontWeight: 700, letterSpacing: "0.16px" }}>Select date type</Typography>
                            <Grid container>
                                <Grid style={{ padding: "5px" }} item xs={6} sm={6} md={6} lg={6}>
                                    <FormControlLabel style={{ width: "200px", borderColor: this.state.dateType === "day_wise" ? "#f5c42c" : "#e3eaf4" }} className="studioFilterLabel" value="day_wise" control={<Radio />} label="Day Wise" labelPlacement="start" />
                                </Grid>
                                <Grid style={{ padding: "5px" }} item xs={6} sm={6} md={6} lg={6}>
                                    <FormControlLabel style={{ width: "200px", borderColor: this.state.dateType === "hour_wise" ? "#f5c42c" : "#e3eaf4" }} className="studioFilterLabel" value="hour_wise" control={<Radio />} label="Hour wise" labelPlacement="start" />
                                </Grid>
                            </Grid>
                        </RadioGroup>
                    </Box>
                    <Box style={{ padding: "8px" }}>
                        {this.state.dateType === "hour_wise" ? <>
                            <Box style={{ alignItems: "baseline", display: "flex", flexDirection: "column" }} >
                                <Typography style={{ textAlign: "left", marginBottom: "7px", lineHeight: "19px", color: "#17181d", fontFamily: "Urbanist", fontSize: "16px", fontWeight: 700, letterSpacing: "0.16px" }}>Select date</Typography>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        data-test-id="txtInputAvailableDate"
                                        placeholder="DD/MM/YY"
                                        format={"dd/MM/yy"}
                                        className="studioFilterStartTime"
                                        style={{ width: "150px", height: 48, border: "1px solid #e3eaf4", borderRadius: "12px", paddingLeft: "5px", justifyContent: "center" } as React.CSSProperties}
                                        minDate={new Date()}
                                        // maxDate={"01/01/25"}
                                        value={this.state.startDate}
                                        onChange={this.handleSelectStartDate}
                                        animateYearScrolling
                                    />
                                </MuiPickersUtilsProvider>
                            </Box>
                            <Box style={{ marginTop: "10px", alignItems: "baseline", display: "flex", flexDirection: "column" }}>
                                <Typography style={{ textAlign: "left", marginBottom: "7px", lineHeight: "19px", color: "#17181d", fontFamily: "Urbanist", fontSize: "16px", fontWeight: 700, letterSpacing: "0.16px" }}>Select start and end time</Typography>
                                <Box>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <TimePicker
                                            data-test-id="txtInputAvaialblefrom"
                                            placeholder="hh:mm"
                                            format={"hh:mm"}
                                            className='studioFilterStartTime'
                                            value={this.state.startTime}
                                            style={{ width: "150px", height: 48, marginRight: "10px", border: "1px solid #e3eaf4", borderRadius: "12px", paddingLeft: "5px", justifyContent: "center" } as React.CSSProperties}
                                            onChange={this.handleSelectStartTime}
                                        />
                                    </MuiPickersUtilsProvider>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <TimePicker
                                            data-test-id="txtInputAvailableTo"
                                            placeholder="hh:mm"
                                            format={"hh:mm"}
                                            className='studioFilterStartTime'
                                            value={this.state.endTime}
                                            style={{ width: "150px", height: 48, border: "1px solid #e3eaf4", borderRadius: "12px", paddingLeft: "5px", justifyContent: "center" } as React.CSSProperties}
                                            onChange={this.handleSelectEndTime}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Box>
                                {this.state.invalidDateTiming ? <Typography style={{ textAlign: "left", marginTop: "4px", lineHeight: "19px", color: "red", fontFamily: "Urbanist", fontSize: "16px", fontWeight: 700, letterSpacing: "0.16px" }}>Please select a valid slot</Typography> : null}
                            </Box>
                        </> :
                            <>
                                <Box style={{ alignItems: "baseline", display: "flex", flexDirection: "column" }} >
                                    <Typography style={{ textAlign: "left", marginBottom: "7px", lineHeight: "19px", color: "#17181d", fontFamily: "Urbanist", fontSize: "16px", fontWeight: 700, letterSpacing: "0.16px" }}>Select start date</Typography>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            data-test-id="txtInputAvailableDate"
                                            placeholder="DD/MM/YY"
                                            format={"dd/MM/yy"}
                                            className="studioFilterStartTime"
                                            style={{ width: "150px", height: 48, border: "1px solid #e3eaf4", borderRadius: "12px", paddingLeft: "5px", justifyContent: "center" } as React.CSSProperties}
                                            minDate={new Date()}
                                            maxDate={this.state.endDate}
                                            value={this.state.startDate}
                                            onChange={this.handleSelectStartDate}
                                            animateYearScrolling
                                        />
                                    </MuiPickersUtilsProvider>
                                </Box>
                                <Box style={{ marginTop: "10px", alignItems: "baseline", display: "flex", flexDirection: "column" }} >
                                    <Typography style={{ textAlign: "left", marginBottom: "7px", lineHeight: "19px", color: "#17181d", fontFamily: "Urbanist", fontSize: "16px", fontWeight: 700, letterSpacing: "0.16px" }}>Select end date</Typography>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            data-test-id="txtInputAvailableDate"
                                            placeholder="DD/MM/YY"
                                            format={"dd/MM/yy"}
                                            className="studioFilterStartTime"
                                            style={{ width: "150px", height: 48, border: "1px solid #e3eaf4", borderRadius: "12px", paddingLeft: "5px", justifyContent: "center" } as React.CSSProperties}
                                            minDate={this.state.startDate}
                                            value={this.state.endDate}
                                            onChange={this.handleSelectEndDate}
                                            animateYearScrolling
                                            disabled={!this.state.startDate}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Box>
                            </>}
                    </Box>
                </Box>
                : null}
        </>
    }

    renderTimingFilterContent() {
        return <>
            {this.state.filterAttributes[4].active ?
                <Box style={{ marginTop: "10px", alignItems: "baseline", display: "flex", flexDirection: "column" }}>
                    <Typography style={{ textAlign: "left", marginBottom: "7px", lineHeight: "19px", color: "#17181d", fontFamily: "Urbanist", fontSize: "16px", fontWeight: 700, letterSpacing: "0.16px" }}>Select open and close time</Typography>
                    <Box>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <TimePicker
                                data-test-id="txtInputAvaialblefrom"
                                placeholder="hh:mm"
                                format={"hh:mm"}
                                className='studioFilterStartTime'
                                value={this.state.openTime}
                                style={{ width: "150px", height: 48, marginRight: "10px", border: "1px solid #e3eaf4", borderRadius: "12px", paddingLeft: "5px", justifyContent: "center" } as React.CSSProperties}
                                onChange={this.handleSelectOpenTime}
                            />
                        </MuiPickersUtilsProvider>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <TimePicker
                                data-test-id="txtInputAvailableTo"
                                placeholder="hh:mm"
                                format={"hh:mm"}
                                className='studioFilterStartTime'
                                value={this.state.closeTime}
                                style={{ width: "150px", height: 48, border: "1px solid #e3eaf4", borderRadius: "12px", paddingLeft: "5px", justifyContent: "center" } as React.CSSProperties }
                                onChange={this.handleSelectCloseTime}
                            />
                        </MuiPickersUtilsProvider>
                    </Box>
                    {this.state.invalidTiming ? <Typography style={{ textAlign: "left", marginTop: "4px", lineHeight: "19px", color: "red", fontFamily: "Urbanist", fontSize: "16px", fontWeight: 700, letterSpacing: "0.16px" }}>Please select a valid slot</Typography> : null}
                </Box>
                : null}
        </>
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <StyledModal
                aria-labelledby="unstyled-modal-title"
                aria-describedby="unstyled-modal-description"
                open={this.props.open}
                onClose={this.props.handleClose}
            >
                <Box sx={style}>
                    <Grid container style={{ padding: "20px 24px 16px", color: '#17181d', fontFamily: 'Urbanist', fontSize: 20, fontWeight: 700, textAlign: 'end', letterSpacing: '0.2px' } as React.CSSProperties}>
                        <Grid style={{ textAlign: "center", paddingLeft: "115px", boxSizing: "border-box" }} xs={9}>
                            Filter
                        </Grid>
                        <Grid xs={3}>
                            <img src={`${images.crossIcon}`} style={{ cursor: 'pointer' }} onClick={this.props.handleClose} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box style={{ border: "1px solid #e3eaf4" }}></Box>
                        <Box style={{ display: "flex" }}>
                            <Box style={{ width: "200px", borderRight: "1px solid #e3eaf4", height: "450px" } as React.CSSProperties}>
                                {this.state.filterAttributes.map((filterAttr, index) => {
                                    return <Box data-test-id={`studioFilterAttr_${filterAttr.name}`} onClick={() => this.changeFilterAttribute(filterAttr.name, this.state.filterAttributes)} style={{ padding: "18px 12px", lineHeight: "18px", color: filterAttr.active ? "#f5c42c" : "#17181d", fontFamily: "Urbanist", fontSize: "15px", fontWeight: 600, borderBottom: this.state.filterAttributes.length !== (index + 1) ? "1px solid #e3eaf4" : "none" }}>
                                        {filterAttr.name}
                                    </Box>
                                })}
                            </Box>
                            <Box style={{ padding: "24px 19px", width: "100%" }}>
                                {this.renderLocationFilterContent()}
                                {this.renderPHFilterContent()}
                                {this.renderPriceFilterContent()}
                                {this.renderDateFilterContent()}
                                {this.renderTimingFilterContent()}
                            </Box>
                        </Box>
                        <Box style={{ border: "1px solid #e3eaf4" }}></Box>
                    </Grid>
                    <Grid container style={{ padding: "24px", justifyContent: "end" }}>
                        <Grid item>
                            <CustomButtonBox data-test-id="sign_in_button" style={{ borderRadius: "15px", height: 48, background: "#ffffff", width: "160px", border: "1px solid #17181d", color: "#17181d", marginRight: "16px" } as React.CSSProperties} onClick={this.handleClearAllData}>
                                Clear All
                            </CustomButtonBox>
                        </Grid>
                        <Grid item>
                            <CustomButtonBox data-test-id="sign_in_button" style={{ borderRadius: "15px", height: 48, background: "#17181d", width: "160px", color: "#ffffff" } as React.CSSProperties} onClick={this.handleApplyFilter}>
                                Apply
                            </CustomButtonBox>
                        </Grid>
                    </Grid>
                </Box>
            </StyledModal>
        )
        // Customizable Area End
    }
}