import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData} from "../../../framework/src/Utilities";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  // Customizable Area Start
  open: boolean;
  handleClose: any;
  handlevalue:any;
  raiseinvoicedetail:any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  studioBookTypeTask: string;
  typeofinvoiceTask:any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class SuccessfullmodelRaiseInvoiceController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      studioBookTypeTask: "per_day",
      typeofinvoiceTask:  getStorageData('typeofraiseinvoice')
      // Customizable Area End
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {

   const typeofinvoicevalueTask=getStorageData("typeofinvoice");
   this.setState({typeofinvoiceTask:typeofinvoicevalueTask})
}

  // Customizable Area End
}
