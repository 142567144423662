import React from "react";

import {
    // Customizable Area Start
    Box,
    Typography,
    Divider,
    Button
    // Customizable Area End
} from "@material-ui/core";
import ProductionHouseController, { configJSON, Props } from "./ProductionHouseController";

// Customizable Area Start
import "./account.css";
import StarOutlinedIcon from '@material-ui/icons/StarOutlined';
import LayersOutlinedIcon from '@material-ui/icons/LayersOutlined';
import { studioIcon, dummyImgIcon } from "./assets";
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
// Customizable Area End

export default class ProductionHouseCard extends ProductionHouseController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    customButtonProject = (projectsCount: number) => {
        return <div style={webStyle.buttonIcon as React.CSSProperties}>
            <LayersOutlinedIcon style={{ width: "17px", height: "17px" } as React.CSSProperties} />
            <Typography style={webStyle.buttonText as React.CSSProperties}>{projectsCount}</Typography>
            <Typography style={webStyle.buttonText as React.CSSProperties}>{configJSON.project}</Typography>
        </div>
    };

    customButtonStudio = (studiosCount: number) => {
        return <div style={webStyle.buttonIcon as React.CSSProperties}>
            <img src={studioIcon} alt="studio" style={{ width: "13px", height: "13px" } as React.CSSProperties} />
            <Typography style={webStyle.buttonText as React.CSSProperties}>{studiosCount}</Typography>
            <Typography style={webStyle.buttonText as React.CSSProperties}>{configJSON.studio}</Typography>
        </div>
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        const pHouseData = this.props.productionHouseData;
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <Box>
                    <Box style={webStyle.imageContainer as React.CSSProperties}>
                        <img style={webStyle.houseImage as React.CSSProperties} src={pHouseData.profile.data?.attributes.profile_image || dummyImgIcon} />
                        <Box style={webStyle.houseDetails as React.CSSProperties}>
                            <Box>
                                <Typography style={{ fontWeight: 600, lineHeight: "19px", fontSize: "16px", letterSpacing: "0.23px" }}>{pHouseData.production_house_name}</Typography>
                                <Typography style={webStyle.alignContent as React.CSSProperties}><LocationOnOutlinedIcon style={webStyle.locationIcon as React.CSSProperties} />{pHouseData.production_house_address}</Typography>
                            </Box>
                            <Box style={webStyle.stars}>
                                <StarOutlinedIcon style={{ width: "12px" }} />
                                <span>&nbsp;{pHouseData.rating_and_review | 0}</span>
                            </Box>
                        </Box>
                    </Box>
                    <Box style={webStyle.buttonArea as React.CSSProperties}>
                        <Button
                            startIcon={this.customButtonProject(pHouseData.projects_count)}
                            style={webStyle.cardButton as React.CSSProperties}
                            variant="outlined"
                        >
                        </Button>
                        <Button
                            startIcon={this.customButtonStudio(pHouseData.studios_count)}
                            style={webStyle.cardButton as React.CSSProperties}
                            variant="outlined"
                        >
                        </Button>
                    </Box>
                </Box>
                <Divider style={{ marginBottom: '3%', marginTop: '2%' }} />
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    imageContainer: {
        height: "123px",
        width: "100%",
        position: "relative",
        borderRadius: "14px"
    },
    locationIcon: {
        width: "12px",
        height: "14px",
        color: "#ffffff",
        fontFamily: "Urbanist",
        fontSize: "12px",
        fontWeight: 500,
        textAlign: "center",
        letterSpacing: "0.2px"
    },
    houseImage: {
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        objectFit: "cover",
        borderRadius: "14px",
    },
    houseDetails: {
        display: "flex",
        justifyContent: "space-between",
        position: "absolute",
        alignItems: "center",
        bottom: "8px",
        right: "8px",
        left: "8px",
        color: "#fff"
    },
    stars: {
        padding: "0px 8px",
        background: "linear-gradient(225deg, #58A2FF 0%, #1777E2 100%)",
        borderRadius: "12px",
        display: "flex",
        alignItems: "center",
    },
    alignContent: {
        display: "flex",
        alignItems: "center",
        color: "#ffffff",
        fontFamily: "Urbanist",
        fontSize: "12px",
        fontWeight: "500",
        textAlign: "center",
        letterSpacing: "0.2px",
        lineHeight: "14px",
    },
    buttonArea: {
        display: 'flex',
        justifyContent: "space-between",
        flexWrap: "wrap",
        padding: "8px 0"
    },
    buttonIcon: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        columnGap: "4px",
        marginLeft: "4px",
        marginRight: "-8px",
    },
    buttonText: {
        color: "#17181d",
        fontFamily: "Urbanist",
        fontSize: "14px",
        fontWeight: "600",
        letterSpacing: "0.0875px",
        marginTop: 2,
    },
    cardButton: {
        textTransform: "none",
        borderRadius: "10px",
        backgroundColor: "#F8FAFE",
        border: "1px solid #E3EAF4",
        width: "48%",
        fontSize: "14px",
        height: "38px !important",
        fontWeight: "600"
    },
}
// Customizable Area End