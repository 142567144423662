import React, { useState } from 'react';
import {
    Box,
    Typography,
    styled,
    Grid,
} from "@material-ui/core";

import Modal from '@material-ui/core/Modal';
import { CustomButton } from '../../blocks/email-account-login/src/CustomButton';
import VerifyOtpInputBox from './VerifyOtpInputBox';
const images = require("./assets");

const StyledModal = styled(Modal)({
    position: 'fixed',
    zIndex: 1300,
    inset: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

const style = {
    width: 343,
    borderRadius: '20px',
    padding: '16px',
    backgroundColor: 'white',
    boxShadow: 'none',
    // height: 192,
};

const CustomButtonBox = styled(CustomButton)({
    '& .MuiButton-label': {
        fontWeight: 600,
        fontSize: 16,
        color: '#ffffff',
        letterSpacing: '0.4px',
        lineHeight: '24px',
    }
});

function VerifyOtpModel({ ...props }) {
    const [otp1, setOtp1] = useState("");
    const [otp2, setOtp2] = useState("");
    const [otp3, setOtp3] = useState("");
    const [otp4, setOtp4] = useState("");

    const move = (e: any, prev: string, current: string, next: string) => {
        let length = (document.getElementById(current) as HTMLInputElement)?.value?.length;
        let maxLength = document.getElementById(current)?.getAttribute("maxLength");

        let otp1 = (document.getElementById("text1") as HTMLInputElement)?.value || '';
        let otp2 = (document.getElementById("text2") as HTMLInputElement)?.value || '';
        let otp3 = (document.getElementById("text3") as HTMLInputElement)?.value || '';
        let otp4 = (document.getElementById("text4") as HTMLInputElement)?.value || '';

        if (String(length) == maxLength) {
            if (next !== "") {
                document.getElementById(next)?.focus()
            }
        }

        if (e?.key === "Backspace") {
            if (prev !== "") {
                document.getElementById(prev)?.focus()
            }
        }

        setOtp1(otp1);
        setOtp2(otp2);
        setOtp3(otp3);
        setOtp4(otp4);
    };

    const verifyOtp = async () => {
        if (!otp1 || !otp2 || !otp3 || !otp4) {
            return false;
        }

        let attr = {
            pin: `${otp1}${otp2}${otp3}${otp4}`,
            verify_phone_or_email: props.type,
        }
        const data = {
            attributes: attr,
        };
        const httpBody = {
            data: data
        };
        props.verifyOtpAPICall(httpBody);
    }

    const requestAgain = () => {
        if (!props.disabledResend) {
            setOtp1("");
            setOtp2("");
            setOtp3("");
            setOtp4("");
            props.requestAgainAPICall()
            props.setIsDisabled(true)
            setTimeout(() => {
                props.setIsDisabled(false)
              }, 30000)
          }
    }

    return (
        <StyledModal
            aria-labelledby="unstyled-modal-title"
            aria-describedby="unstyled-modal-description"
            open={props.open}
            onClose={props.handleClose}
        >
            <Box sx={style}>
                <Grid container style={{ height: 24, color: '#17181d', fontFamily: 'Urbanist', fontSize: 20, fontWeight: 700, textAlign: 'end', letterSpacing: '0.2px' } as React.CSSProperties}>
                    <Grid xs={8}>
                        Verify OTP
                    </Grid>
                    <Grid xs={4}>
                        <img src={`${images.crossIcon}`} style={{ cursor: 'pointer' }} onClick={props.handleClose} />
                    </Grid>
                </Grid>
                <Grid container justifyContent='center'>
                    <Typography style={{ width: '256px', marginBottom: '24px', marginTop: '12px', color: '#18272a', fontFamily: 'Urbanist', fontSize: '13px', fontWeight: 500, lineHeight: '18px', textAlign: 'center', letterSpacing: '0.4px' }}>We’ve sent the 4 digit verification code to {props.type === "email" ? "email" : "phone number"} <span style={{ color: '#f5c42c' }}> {props.phoneNumber} </span> </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '13px' }}>
                    <Grid xs={3} sm={3} md={3} lg={3} xl={3} item>
                        <VerifyOtpInputBox className="otp_input_verify_model" otpInputValue={otp1} updateOtpInoutValue={(value: string) => setOtp1(value)} data-test-id="otp_input_text" id="text1" onKeyUp={(e: any) => move(e, "", "text1", "text2")} />
                    </Grid>
                    <Grid xs={3} sm={3} md={3} lg={3} xl={3} item>
                        <VerifyOtpInputBox className="otp_input_verify_model" otpInputValue={otp2} updateOtpInoutValue={(value: string) => setOtp2(value)} data-test-id="otp_input_text_two" id="text2" onKeyUp={(e: any) => move(e, "text1", "text2", "text3")} />
                    </Grid>
                    <Grid xs={3} sm={3} md={3} lg={3} xl={3} item>
                        <VerifyOtpInputBox className="otp_input_verify_model" otpInputValue={otp3} updateOtpInoutValue={(value: string) => setOtp3(value)} id="text3" data-test-id="otp_input_text_three" onKeyUp={(e: any) => move(e, "text2", "text3", "text4")} />
                    </Grid>
                    <Grid xs={3} sm={3} md={3} lg={3} xl={3} item>
                        <VerifyOtpInputBox className="otp_input_verify_model" otpInputValue={otp4} updateOtpInoutValue={(value: string) => setOtp4(value)} id="text4" data-test-id="otp_input_text_four" onKeyUp={(e: any) => move(e, "text3", "text4", "")} />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingTop: '24px' }}>
                    <Typography style={{ fontFamily: 'Urbanist', color: '#6d89af', fontSize: '13px', fontWeight: 500, lineHeight: '19px', letterSpacing: '0.3px' }}>Didn't receive code?</Typography>
                    <a className="anchor" onClick={requestAgain} style={{
                        fontSize: "13px",
                        fontWeight: 600,
                        fontFamily: "urbanist",
                        cursor: props.disabledResend ? 'not-allowed' : 'pointer',
                        pointerEvents: props.disabledResend ? 'none' : 'auto',
                        color: props.disabledResend ? 'gray' : 'inherit'}}>
                    Request Again
                </a>
                </Grid>
                <Grid item style={{ marginTop: 32 }}>
                    <CustomButtonBox data-test-id="sign_in_button" style={{ height: 48, background: "#17181d" } as React.CSSProperties} onClick={verifyOtp}>
                        Confirm OTP
                    </CustomButtonBox>
                </Grid>
            </Box>
        </StyledModal>
    )
}

export default VerifyOtpModel;