import React, { useState } from 'react';
import {
    Box,
    Typography,
    styled,
    Grid,
} from "@material-ui/core";

import Modal from '@material-ui/core/Modal';
import { CustomButton } from '../../blocks/email-account-login/src/CustomButton';
const images = require("./assets");

const StyledModal = styled(Modal)({
    position: 'fixed',
    zIndex: 1300,
    inset: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

const style = {
    width: 375,
    borderRadius: '20px',
    padding: '16px',
    backgroundColor: '#ffffff',
    boxShadow: 'none',
    // height: 303,
    boxSizing: 'border-box',
};

const CustomButtonBox = styled(CustomButton)({
    '& .MuiButton-label': {
        fontWeight: 600,
        fontSize: 16,
        color: '#ffffff',
        letterSpacing: '0.4px',
        lineHeight: '24px',
        fontFamily: 'Urbanist',
    }
});

function LandingPageCreateAccountModel({ ...props }) {
    const [type, setType] = useState("");

    const handleInputFocus = (userType: string) => {
        setType(userType);
    };

    const handleCreateAccount = () => {
        if (type === 'vendor') {
            props.handleNavigation("EmailAccountRegistration");
        } else if (type === "ph") {
            props.handleNavigation("ProductionHouseSignUp");
        }
    }

    return (
        <StyledModal
            aria-labelledby="unstyled-modal-title"
            aria-describedby="unstyled-modal-description"
            open={props.open}
            onClose={props.handleClose}
        >
            <Box sx={style}>
                <Grid container style={{ height: 24, color: '#17181d', fontFamily: 'Urbanist', fontSize: 20, fontWeight: 700, textAlign: 'end', letterSpacing: '0.3px', marginTop: 2 } as React.CSSProperties}>
                    <Grid xs={9}>
                        Create an account as a
                    </Grid>
                    <Grid xs={3}>
                        <img src={`${images.crossIcon}`} style={{ cursor: 'pointer' }} onClick={props.handleClose} />
                    </Grid>
                </Grid>
                <Grid container justifyContent='center'>
                    <Typography style={{ width: '256px', marginBottom: '24px', marginTop: '10px', color: '#6d89af', fontFamily: 'Urbanist', fontSize: '13px', fontWeight: 600, lineHeight: '18px', textAlign: 'center', letterSpacing: '0.4px' }}>Select your role and continue to register your account in ai pictures </Typography>
                </Grid>
                <Grid container xs={12} sm={12} md={12} lg={12} justifyContent='space-between'>
                    <Grid
                        onClick={() => {
                            handleInputFocus("ph");
                        }}
                        item style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '164px', height: '95px', borderRadius: '14px', border: type === 'ph' ? '1px solid #f5c42c' : '1px solid #e3eaf4', background: 'rgba(245,196,44,0.04)' } as React.CSSProperties}>
                        <Grid>{type === 'ph' ? <img src={`${images.yellowPHIcon}`} /> : <img src={`${images.PHIcon}`} />}</Grid>
                        <Grid style={{ marginTop: '8px', color: type === 'ph' ? '#f5c42c' : '#6d89af', fontFamily: 'Urbanist', fontSize: '15px', fontWeight: 700, lineHeight: '19px', textAlign: 'center', letterSpacing: '0.28125px' }}>Production House</Grid>
                    </Grid>
                    <Grid
                        onClick={() => {
                            handleInputFocus("vendor");
                        }}
                        item style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '164px', height: '95px', borderRadius: '14px', border: type === 'vendor' ? '1px solid #f5c42c' : '1px solid #e3eaf4', background: 'rgba(245,196,44,0.04)' } as React.CSSProperties}>
                        <Grid >{type === 'vendor' ? <img src={`${images.yellowVendorLandingPageIcon}`} /> : <img src={`${images.vendorLandingPageIcon}`} />}</Grid>
                        <Grid style={{ marginTop: '8px', color: type === 'vendor' ? '#f5c42c' : '#6d89af', fontFamily: 'Urbanist', fontSize: '15px', fontWeight: 700, lineHeight: '19px', textAlign: 'center', letterSpacing: '0.28125px' }}>Vendor</Grid>
                    </Grid>
                </Grid>
                <Grid item style={{ marginTop: 32 }}>
                    <CustomButtonBox data-test-id="continue_button" style={{ height: 48, background: "#17181d" } as React.CSSProperties} onClick={() => handleCreateAccount()}>
                        Continue
                    </CustomButtonBox>
                </Grid>
            </Box>
        </StyledModal>
    )
}

export default LandingPageCreateAccountModel;