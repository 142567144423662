import React from "react";
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { styled } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import { CardMedia } from '@material-ui/core';
import { reviewimg } from "./assets";
import Reviewer from "./Reviewer";
import LinearProgress from '@material-ui/core/LinearProgress';

interface MyProps {
  data: any;
}

const useStyles = makeStyles((theme) => ({
  ratingdiv: {
    width: '200px',
    border: '2px solid #E1E6EC',
    borderRadius: '14px',
    padding: '20px 10px',
    marginLeft: "10px",
    marginBottom: "5px",
    backgroundColor: "#F8FAFE",
    '@media(max-width: 600px)': {
      margin: '0 auto',
      width: '89%'
    },

  },
  root: {
    display: 'flex',
    gap: '11px',
    width: '100%',
    marginTop: '15px',
    '@media(max-width: 600px)': {
      flexDirection: 'column',
      gridRowGap: '11px',
    },

    // '& > * + *': {
    //   marginTop: theme.spacing(1),
    // },
  },
  Review: {
    display: 'flex',
    flexDirection: 'column',
  },
  ratinghead: {
    width: "100%"
  },
  Reviewheading: {

    fontFamily: 'urbanist',
    lineHeight: '19px',
    fontSize: '14px',
    fontWeight: 500,
    color: '#6D89AF',
    paddingBottom: '12px',
    '@media(max-width: 600px)': {
      width: '95%',
      margin: '0 auto'
    },
  },
  ReviewList: {},
  ratingheading: {
    fontSize: '12px',
    lineHeight: '16px',
    color: "#6D89AF",
    marginBottom: '10px'
  }
  ,
  ratingborder: {

    borderBottom: "1px solid",
    borderBottomColor: "#D8D8D8",
    marginBottom: "10px"
  }
  ,
  ratingstar: {

    fontSize: '10px',
    fontWeight: 500,
    lineHeight: '16px',
    paddingTop: "5px",
    paddingBottom: "10px",
    color: "#17181D"
  },
  starandrating: {
    display: 'flex',

  },
  staricon: {

    "& .MuiRating-root": {
      color: '#ffb400',
      cursor: 'pointer',
      display: 'inline-flex',
      position: 'relative',
      fontSize: "20px",
      textAlign: 'left',

    }
  }
}));

const CustomLinearProgress = styled(LinearProgress)({
  '& .MuiLinearProgress-barColorPrimary': {
    backgroundColor: '#F7B500', // Yellow color
  },
  '& .MuiLinearProgress-bar': {
    backgroundColor: '#F7B500', // Yellow color
  },

});

export default function RatingComponent(props: MyProps) {
  const { data } = props;
  const classes = useStyles();

  const ratings = [
    { stars: 5, value: 80 },
    { stars: 4, value: 60 },
    { stars: 3, value: 40 },
    { stars: 2, value: 20 },
    { stars: 1, value: 10 },
  ];

  return (
    <Box className={classes.ratinghead}>
      <div className={classes.root}>
        <Box className={classes.ratingdiv}>
          <Box className={classes.starandrating}>
            <span style={{ fontSize: '32px', fontWeight: 900, lineHeight: '38px', display: "flex", borderRight: "1px solid", paddingRight: '5px', borderColor: '#D8D8D8' }}>{data?.average_rating} <span style={{ fontSize: '18px', fontWeight: 900, lineHeight: "38px" }}>/5</span></span>
            <Box className={classes.staricon}>
            {/* <Rating name="read-only" value={4.5} readOnly /> */}
            <Rating name="half-rating-read" defaultValue={data?.average_rating} precision={0.5} readOnly />
            </Box>

          </Box>
          <Box className={classes.ratingheading}>{data?.review_rating_count}</Box>
          <Box className={classes.ratingborder}></Box>
          {Object.keys(data.rating_star)
            .reverse() 
            .map((rating: any, index: number) => (
              <React.Fragment key={index}>
                <CustomLinearProgress variant="determinate" value={data.rating_star[rating]} />
                <Box className={classes.ratingstar}>{rating} star</Box>
              </React.Fragment>
            ))}
        </Box>
        <Box className={classes.ReviewList}>
          <Box className={classes.Review}>
            <Box className={classes.Reviewheading}>
              All Reviews
            </Box>
            {data?.reviews?.data.map((reviews: any, index: any) => (
              <Reviewer key={index} data={reviews} />
            ))}
          </Box>
        </Box>
      </div>
    </Box>
  );
}
