Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";
exports.welcomeText = "Welcome back!";
exports.btnExampleTitle = "CLICK ME";
exports.signInText = "Sign in to your account by using your registered email ID and phone number";
exports.emailLogin = "Email ID";
exports.mobileLogin = "Phone Number";
exports.createProjectAPI="/bx_block_projecttasktracking2/projects";
exports.updateProjectAPI="/bx_block_projecttasktracking2/projects/"
exports.defaultImageAPI="/bx_block_admin/project_default_images"
exports.vendorsListAPI="/account_block/active_vendors"
exports.vendorsListApIsearch="/account_block/active_vendors?search"
exports.rolesListAPI="/bx_block_roles_permissions/roles/";
exports.permissionsListAPI="/bx_block_roles_permissions/permissions"
exports.departmentListApi="/bx_block_projecttasktracking2/project_departments"
exports.dashboarApiMethodType="GET"
exports.projectsAPI="/bx_block_projecttasktracking2/project_departments"
exports.publishProjectAPI="/bx_block_projecttasktracking2/projects/publish_project/"
exports.employeeAPI="/bx_block_projecttasktracking2/employees?"

  exports.memberData = [
    {
      id: 1,
      image: 'https://images.unsplash.com/photo-1544005313-94ddf0286df2?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8cGVyc29ufGVufDB8fDB8fHww',
      name: 'Deependra Bardhan',
      department: 'Production Team',
      level: 'Lvl 3',
      designation: 'Sr. Production Manager',
      phoneNumber: '1234567890',
      role: 'Production Manager',
    },
    {
      id: 2,
      image: 'https://people.com/thmb/4mbGaFdH-OEx-3HzIALxKaexUcA=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc():focal(638x439:640x441)/scarlett-johansson-1-0a6183353c3e47cfa78b7f206f429a73.jpg',
      name: 'Scarlet Johansson',
      department: 'Sales',
      level: 'Lvl 2',
      designation: 'Head of Sales Executive',
      phoneNumber: '9876543210',
      role: 'Head of Sales',
    },
    {
      id: 3,
      image: 'https://assets.vogue.com/photos/646e1e64406cf236781d4012/master/w_1600%2Cc_limit/GettyImages-1492700205.jpg',
      name: 'Jane Smith',
      department: 'Marketing',
      level: 'Lvl 1',
      designation: 'Marketing Coordinator',
      phoneNumber: '5551234567',
      role: 'Marketing Coordinator',
    },
    {
      id: 4,
      image: 'https://assets.vogue.com/photos/646e1e64406cf236781d4012/master/w_1600%2Cc_limit/GettyImages-1492700205.jpg',
      name: 'John Cena',
      department: 'Marketing',
      level: 'Lvl 1',
      designation: 'Marketing Coordinator',
      phoneNumber: '5551234567',
      role: 'Marketing Coordinator',
    },
    {
      id: 5,
      image: 'https://assets.vogue.com/photos/646e1e64406cf236781d4012/master/w_1600%2Cc_limit/GettyImages-1492700205.jpg',
      name: 'Virat Kohli',
      department: 'Marketing',
      level: 'Lvl 1',
      designation: 'Marketing Coordinator',
      phoneNumber: '5551234567',
      role: 'Marketing Coordinator',
    },
    {
      id: 6,
      image: 'https://assets.vogue.com/photos/646e1e64406cf236781d4012/master/w_1600%2Cc_limit/GettyImages-1492700205.jpg',
      name: 'Gautam Gambhir',
      department: 'Marketing',
      level: 'Lvl 1',
      designation: 'Marketing Coordinator',
      phoneNumber: '5551234567',
      role: 'Marketing Coordinator',
    },
  ];
  exports.departmentList = [
    { label: 'Production', value: 'Production' },
    { label: 'Sales', value: 'Sales' },
    { label: 'Marketing', value: 'Marketing' },
    { label: 'Human Resources', value: 'HR' },
    { label: 'Finance', value: 'Finance' },
    { label: 'Engineering', value: 'Engineering' },
    { label: 'Customer Support', value: 'CustomerSupport' },
    { label: 'Research and Development', value: 'R&D' },
    { label: 'Information Technology', value: 'IT' },
    { label: 'Legal', value: 'Legal' },
  ];
  
  exports.permissions= [
    { id : 1,
            label: "Add And Manage Dept",
            checked: false
    },
    {
      id:2,
            label: "View Balance Sheet",
            checked: false
    },
    {
      id:3,
            label: "Manage Balance Sheet",
            checked: false
    },
    {
      id:4,
            label: "Manage Projects",
            checked: false
    },
    {
      id:5,
            label: "Manage Invoices",
            checked: false
    },
    {
      id:6,
            label: "Add And Manage Vendors",
            checked: false
    },
    {
      id:7,
            label: "Track Standard Pay",
            checked: false
    }
]

exports.MemberListEndpoint = "/bx_block_fullteamvisibility/members_list"
exports.TeamListEndpoint = "/bx_block_fullteamvisibility/teams";
exports.DepartmentListEndpoint = "/bx_block_fullteamvisibility/departments"
exports.OrgDataEndpoint = "/bx_block_fullteamvisibility/organizations";
exports.GET_Method = "GET";
exports.DELETE_Method = "DELETE";
exports.PATCH_Method = "PATCH"
exports.AllProductionHouses = "account_block/all_production_houses?per_page=9&page=";
exports.AllProductionHousesSearchAPIEndPoint = "account_block/search_production_house?per_page=9&search=";
exports.getProductionHouseAllProjects = "account_block/production_house_all_projects?production_house_id=";
exports.getProductionHouseUpcomingProjects = "account_block/production_house_upcoming_project?production_house_id=";
exports.getProductionHouseOngoingProjects = "account_block/production_house_ongoing_project?production_house_id=";
exports.getProductionHouseCompletedProjects = "account_block/production_house_complete_project?production_house_id=";
exports.getProductionHouseStudios = "account_block/get_production_house_studios?production_house_id=";
exports.getProductionHouseRating = "bx_block_reviews/reviews/production_house_review_rating/";
exports.getAllVendorDataApiEndPoint = "account_block/all_vendors?per_page=9&page=";
exports.getAllVendorDataApiEndPoint1 = "account_block/all_vendors?per_page=9";
exports.getVendorAllProjects = "account_block/all_vendor_projects?vendor_id=";
exports.getVendorUpcomingProjects = "account_block/upcoming_vendor_projects?vendor_id=";
exports.getVendorOngoingProjects = "account_block/ongoing_vendor_projects?vendor_id=";
exports.getVendorCompletedProjects = "account_block/complete_vendor_project?vendor_id=";
exports.getVendorRating = "bx_block_reviews/reviews/vendor_review_rating/";
exports.getallJobRoleRequestEndPoint ="/bx_block_projecttasktracking2/user_projects"
exports.getAllProjectNameEndPoint = "/bx_block_projecttasktracking2/projects"
exports.getAllProjectDepartmentEndPoint = "bx_block_projecttasktracking2/project_departments/pipeline_departments"
exports.getProjectDeptRoleEndPoint = "bx_block_roles_permissions/roles"
exports.block = "Block";
exports.blocked = "Blocked"
exports.putApiForAllVendorsEndPoint = "account_block/block_vendor/";
exports.putApiForAllProductionHousesEndPoint = "account_block/block_production_house/";
exports.put_method = "PUT";
exports.position = "Assistant Director";
exports.location = "Surat (Gujrat)";
exports.exp = "8 Yrs of Exps.";
exports.date = "Joined on: 12 Jan, 2023";
exports.allVendorText = "All Vendors";
exports.home = "Home";
exports.vendor = "Vendors";
exports.vendorDetails = "Vendor Details";
exports.productionHouseDetails = "Production House Details";
exports.overview = "Overview";
exports.project = "Projects";
exports.studio = "Studios";
exports.rating = "Rating & Reviews";
exports.vendorBio = "Vendor Bio";
exports.productionHouseBio = "About Production House";
exports.doc = "Official Details & Docs";
exports.description = "A Award wining DP & filmmaker and shoots thoughtful india in various cities big and small as well as in tea plantation, rainforests and various rural area's with various cinema's";
exports.getUserPersonalDetailApiEndPoint = "account_block/get_vendor/";
exports.getproductionHouseDetailApiEndPoint = "account_block/get_production_house/";

exports.getStudioDetailApiEndPoint = "bx_block_customizableformworkflow3/studios/";
exports.getStudioDetailRatingApiEndPoint = "bx_block_reviews/reviews/studio_rating_review/"
exports.getProjectDetailApiEndPoint = "bx_block_projecttasktracking2/projects/";
exports.getProjectDetailApiContentType = "application/json";
exports.getUserPersonalDetailApiEndPoint = "account_block/get_vendor/";
exports.getproductionHouseDetailApiEndPoint = "account_block/get_production_house/";
exports.getStudioDetailApiEndPoint = "bx_block_customizableformworkflow3/studios/";
exports.getStudioDetailRatingApiEndPoint = "bx_block_reviews/reviews/studio_rating_review/"
exports.getProjectDetailApiEndPoint = "bx_block_projecttasktracking2/projects/";
exports.getProjectDetailApiContentType = "application/json";
exports.getProjectDetailApiMethodType = "GET";
exports.getdepartmentProjectDetailApiEndPoint = "bx_block_projecttasktracking2/project_departments?project_id=";
exports.getdepartmentProjectDetailApiContentType = "application/jason";
exports.getdepartmentProjectDetailApiMethodType = "GET";
exports.getmemberProjectDetailApiEndPoint = "account_block/accounts/project_members/";
exports.getmemberProjectDetailApiContentType = "appliction/jason";
exports.getmemberProjectDetailApiMethodType = "GET";
exports.adharNoText = "Aadhar Number";
exports.adharNo = "7856 XXXX XXXX 1124";
exports.panNoText = "Pan Number";
exports.panNo = "COXXXXXX8E";
exports.gstNoText = "GST Number";
exports.gstNo = "07AABCU9603R1ZP";
exports.tanNoText = "Tan Number";
exports.tanNo = "HYDDxxxx92E";
exports.certText = "Certificates & Documents";
exports.gallery = "Gallery";
exports.general = "General";
exports.four = "4";
exports.project_name = "Project_com.pdf";
exports.overview_vp = "overview";
exports.project_vp = "project";
exports.studioProductionHouse = "studio";
exports.rating_vp = "rating";
exports.allPText = "All Projects";
exports.onGoing = "Ongoing Projects";
exports.upComing = "Upcoming Projects";
exports.completedProject = "Completed Projects";
exports.allReviews = "All Reviews";
exports.divide = "/5";
exports.startText = "start";
exports.getAllVendorSearchDataApiEndPoint = "account_block/search_vendors?search=";
exports.allStudioApiContentType = "application/json";
exports.allStudioApiMethodType = "GET";
exports.allStudioApiEndPoint = "bx_block_customizableformworkflow3/studios?per_page=9&page=";
exports.allStudioApiContentType1 = "application/json";
exports.allStudioApiMethodType1 = "GET";
exports.allStudioApiEndPoint1 = "bx_block_customizableformworkflow3/studios/search?query"
exports.allProjectApiContentType = "application/json";
exports.allProjectApiMethodType = "GET";
exports.allProjectApiEndPoint = "bx_block_projecttasktracking2/all_projects?per_page=9&page=";
exports.getAllProjectSearchDataApiEndPoint = "bx_block_projecttasktracking2/search_projects?per_page=9&search";
exports.getAllProjectSortDataApiEndPoint = "bx_block_projecttasktracking2/search_projects?per_page=9&status"
exports.allProjectSearchApiContentType = "application/json";
exports.allProjectSearchApiMethodType = "GET";
exports.getAllStudioSearchDataApiEndPoint = "bx_block_customizableformworkflow3/studios/search?&per_page=9&search";
exports.getAllStudioSortDataApiEndPoint = "bx_block_customizableformworkflow3/studios/search?per_page=9&status";
exports.putAllStudioBlockApiEndPoint = "bx_block_customizableformworkflow3/studios/block_studio/"
exports.StateEndPoint = "https://api.countrystatecity.in/v1/countries/in/states"
exports.CityEndPoint = "https://api.countrystatecity.in/v1/countries/in/states/"
exports.designationEndpoint = "bx_block_roles_permissions/roles/"
exports.allvendorSortDataApiEndPoint = "account_block/search_vendors?per_page=10&state"
exports.allproductionsortDataApiEndPoint = "account_block/search_production_house/?per_page=10&state"
exports.getAllProductionDataApiEndPoint1 = "account_block/all_production_houses?per_page=9"
exports.getMyJobEndPoint = "bx_block_roles_permissions/my_jobs?per_page=9&page="
exports.getAppliedEndPoint = "bx_block_roles_permissions/applied_jobs?per_page=9&page="
exports.getJobDetailApi = "bx_block_roles_permissions/job_details?job_role_id="
exports.getallJobEndPoint = "bx_block_roles_permissions/job_roles"
exports.getJobDetailApi = "bx_block_roles_permissions/job_details?job_role_id"
exports.getallJobEndPoint = "bx_block_roles_permissions/job_roles?per_page=9&page="
exports.getJobDetailRatingEndPoint = "bx_block_reviews/reviews/production_house_review_rating"
exports.getallJobsearchEndPoint = "bx_block_roles_permissions/job_roles?per_page=9&page=&search";
exports.getinvoicesearchEndPoint = "bx_block_invoicebilling/my_invoices?search"
exports.getallinvoicelistEndPoint = "bx_block_invoicebilling/my_invoices?per_page=9"
exports.addReviewApiEndPoint = "bx_block_reviews/reviews"
exports.httpPostMethod = "POST"
exports.applyJobApiEndPoint = "bx_block_roles_permissions/vendor_job_requests"
exports.departmentApiEndPoint = "bx_block_roles_permissions/get_department_role_expriance_for_jr_filter?filter=project_department";
exports.experienceApiEndPoint = "bx_block_roles_permissions/get_department_role_expriance_for_jr_filter?filter=experience"
exports.RoleTypeApiEndPoint = "bx_block_roles_permissions/get_department_role_expriance_for_jr_filter?filter=role_name"
exports.cityApiEndPoint = "bx_block_roles_permissions/get_department_role_expriance_for_jr_filter?filter=city"
exports.sortJobapiEndPoint = "bx_block_roles_permissions/filter_job_roles?&per_page=9&"
exports.sortapiinvoiceendpoint = "bx_block_invoicebilling/my_invoices?filter_type=sort_by"
exports.countapiinvoiceendpoint = "bx_block_invoicebilling/short_by_invoice?"
exports.getInvoiceListendpoint = "bx_block_invoicebilling/invoices?per_page=9"
exports.getInvoicedetailendpoint = "bx_block_invoicebilling/invoices"
exports.withdrawJobApiEndPoint1 = "bx_block_roles_permissions/withdrow_request_by_vendors/"
exports.studioUpdateApiMethodType = "PUT"
exports.getProjectTaskApiEndPoint = "bx_block_profile/profile_projects"
exports.raiseinvoiceendpoint = "bx_block_invoicebilling/invoices"
exports.raiseinvoicemethodtype = "POST";
exports.leaveProjectApiEndPoint = "bx_block_roles_permissions/leave_project?"
exports.getprojectinvoiceendpoint = "bx_block_invoicebilling/my_invoices?"
exports.getamountListendpoint = "bx_block_invoicebilling/get_invoice_amount"
exports.getdepartmentListendpoint = "bx_block_invoicebilling/get_department"
exports.getJobroleListendPoint = "bx_block_invoicebilling/get_job_role"
exports.countraiseinvoiceendpoint = "bx_block_invoicebilling/get_invoice_raised"
exports.getprojectlistendpoint = "bx_block_invoicebilling/get_project"
exports.getfilterendpointmyinvoice = "bx_block_invoicebilling/my_invoices?filter_type=filter"
exports.getinvoicecountendpoint = "bx_block_invoicebilling/my_invoices?project"
exports.reclaimInvoiceEndPoint = "bx_block_invoicebilling/reclaim_invoice/";
exports.getVendorJobPHDetailsDetailsAPIEndPoint = "profile/profiles/";
exports.getVendorJobPHDetailsProjectsAPIEndPoint = "bx_block_profile/profile_projects/";
exports.getVendorJobPHDetailsStudiosAPIEndPoint = "bx_block_profile/profile_ph_studios/";
exports.getVendorJobPHDetailsReviewDataAPIEndPoint = "bx_block_profile/profile_rating?profile_id=";

exports.projectMemberInfoModelTitle = "Project Members Info";
exports.projectMemberInfoModelContent = "You can see all members like pipeline staff, special staff and vendors of ongoing and upcoming projects only.";
exports.projectMemberInfoModelButtonText = "OK";
exports.jobRoleText="Job Requests";
exports.getProjectMembersListApiEndPoint = "bx_block_projecttasktracking2/user_projects?per_page=9&page=";
exports.getPipelineEmployeeListApiEndPoint = "bx_block_projecttasktracking2/employees?project_id=";
exports.getProjectVendorApiEndPoint = "bx_block_roles_permissions/all_job_roles"
exports.reomveFromJobApiEndPoint = "bx_block_roles_permissions/remove_from_job"
exports.getPipelineEmployeePermissionListApiEndPoint = "bx_block_roles_permissions/permissions";
exports.getPipelineEmployeeDepartmentListApiEndPoint = "bx_block_projecttasktracking2/project_departments/pipeline_departments";
exports.managePipelineEmployeeAccessibilityApiEndPoint = "bx_block_projecttasktracking2/employees/";
exports.jobRoleManagePipelineEmployeeAccessibilityApiEndPoint = "bx_block_projecttasktracking2/employees/update_job_role/";
exports.getPipelineEmployeeRoleListApiEndPoint = "bx_block_roles_permissions/roles";
exports.addPipelineEmployeeApiEndPoint = "bx_block_projecttasktracking2/employees/create_job_role?project_id=";
exports.simpleRemovePipelineEmployeeFromProjectApiEndPoint = "bx_block_projecttasktracking2/employees/";
exports.jobRoleRemovePipelineEmployeeFromProjectApiEndPoint = "bx_block_projecttasktracking2/employees/delete_job_role/";
exports.getAllProjectJobRoleRequestEndPoint = "bx_block_roles_permissions/all_job_role_request_for_pending_project"
exports.getAllProjectRequestEndPoint = "bx_block_roles_permissions/get_job_role_requested_vendors/"
exports.getJobRoleRequestResponseEndPoint = "bx_block_roles_permissions/all_job_roles"
exports.postAddJobRoleEndPoint = "bx_block_roles_permissions/job_roles"
exports.deleteJobRoleRequest = "bx_block_roles_permissions/job_roles/"
exports.editJobRoleRequest = "bx_block_roles_permissions/job_roles/"
exports.acceptUpdateVendorEndPoint = "bx_block_roles_permissions/vendor_job_requests/"
exports.getRequestProfileEndPoint = "profile/profiles/"
exports.getInviteVendorEndpoint = "profile/profiles"
exports.getRatingAndReviewEndPoint = "bx_block_profile/profile_rating"
exports.postRatingAndReviewEndPoint = "bx_block_reviews/reviews"
exports.getProfileDataRequestEndPoint = "bx_block_profile/profile_projects"
exports.getRequestVendorEndPoint ="bx_block_roles_permissions/get_job_role_requested_vendors"
exports.getInviteVendorEndPoint = "account_block/active_vendors"
exports.projectMemberProjectVendorApiEndPoint = "profile/profiles"
exports.postInviteVendorEndPoint = "bx_block_projecttasktracking2/employees?project_id"
exports.deleteFormJobEndPoint = "bx_block_projecttasktracking2/employees"
exports.getRatingAndReviewEndPoint ="bx_block_profile/profile_rating"
exports.divide = "/5";
exports.tabLabelsJobRole = [
  "All Requests",
  "All Job Role",
]
exports.piplinealineAllEmployeeEndPoint="bx_block_projecttasktracking2/employees?project_id="  

exports.getProjectDetailApiEndPoint="bx_block_projecttasktracking2/projects"

exports.deletedallemployeeEndPoint="bx_block_projecttasktracking2/employees"
exports.deletedallemployeeEndPointactive="/bx_block_projecttasktracking2/employees/delete_job_role"

exports.updatedepartmentendpoint="bx_block_projecttasktracking2/project_departments/update_departments/"
exports.postSMSInvite="bx_block_phone_verification/phone_verifications/send_sms_invite"

// Customizable Area End