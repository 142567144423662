export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const crossIcon = require("../assets/crossIcon.svg");
export const checkedIcon = require("../assets/checkedIcon.png");
export const forwardMailIcon = require("../assets/forwardMail.svg");
export const studioTimingIcon = require("../assets/studioTiming.svg");
export const RightIcon = require("../assets/RightIcon.svg");
export const RequestSendIcon = require("../assets/request_send.png");
export const logoutIcon = require("../assets/logout.svg");
export const bulbIcon = require("../assets/bulb.svg");
export const certificateIcon = require("../assets/certificateIcon.png");
export const verifiedCircularIcon = require("../assets/verified_icon.svg");
export const visibilityOffIcon = require("../assets/visibility_off.svg");
export const visibilityIcon = require("../assets/visibility.svg");