import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isSideBarOpen: boolean;
  isDropdownOpen: boolean;
  travelTab: number;
  startDestination: string;
  endDestination: string;
  showInfoWindow: boolean;
  directions: any;
  defaultZoom: number;
  defaultCenter: { lat: number, lng: number };
  startDestinationDetails: any;
  endDestinationDetails: any;
  currentLocationDetails: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MapTravellingController extends BlockComponent<Props, S, SS> {
  getCityApiCallId: any;
  updateDefaultCityApiCallId: any;
  googleMapAPIId: any;
  directionsService: any;
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];

    // Customizable Area Start
    this.state = {
      isSideBarOpen: true,
      isDropdownOpen: false,
      travelTab: 0,
      startDestination: "Surat, Gujarat, India",
      endDestination: "Satellite, Ahmedabad, India",
      showInfoWindow: false,
      directions: null,
      defaultZoom: 12,
      defaultCenter: { lat: 20.5937, lng: 78.9629 },
      startDestinationDetails: [
        {
          id: 2,
          title: 'Surat, Gujarat, India',
          coordinates: { lat: 21.228125, lng: 72.833771 },
          src:
            'https://d3aux7tjp119y2.cloudfront.net/images/oscar-ekholm-DC50Oj2m4CY-unsplash-CMSTemplate.width-1650_5AWSVQc.jpg',
        },
      ],
      endDestinationDetails: [
        {
          id: 1,
          title: 'Satellite, Ahmedabad, India',
          coordinates: { lat: 23.030357, lng: 72.517845 },
          src:
            'https://i.pinimg.com/originals/09/9e/60/099e600bcfa057bf1c9ecdcce0ad529c.jpg',
        },
      ],
      currentLocationDetails: [
        {
          id: 3,
          title: 'Baroda, Gujarat, India',
          coordinates: { lat: 22.310696, lng: 73.192635 },
          src:
            'https://d3aux7tjp119y2.cloudfront.net/images/oscar-ekholm-DC50Oj2m4CY-unsplash-CMSTemplate.width-1650_5AWSVQc.jpg',
        },
      ],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    const travelTab = await getStorageData("travelTab");
    const isSideBarOpen = await getStorageData("sidebarvalue");
    this.setState({ travelTab: Number(travelTab), isSideBarOpen: JSON.parse(isSideBarOpen) });
  }

  handleMapTravellingSideBar = () => {
    setStorageData("sidebarvalue", (!this.state.isSideBarOpen).toString())
    this.setState({
      isSideBarOpen: !this.state.isSideBarOpen
    });
  };

  handleMapTravellingDropdown = () => {
    this.setState({
      isDropdownOpen: !this.state.isDropdownOpen
    });
  };

  handleNavigation = (page: string) => {
    this.props.navigation.navigate(page)
  };

  handleMouseOver = (event: any) => {
    this.setState({
      showInfoWindow: true
    });
  };

  handleMouseExit = (event: any) => {
    this.setState({
      showInfoWindow: false
    });
  };

  onMapLoad = (map: any) => {
    this.directionsService = new (window as any).google.maps.DirectionsService();
    this.changeDirection(this.state.startDestinationDetails[0].coordinates, this.state.endDestinationDetails[0].coordinates);
  }

  changeDirection = (origin: any, destination: any) => {
    this.directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: (window as any).google.maps.TravelMode.DRIVING,
      },
      (result: any, status: any) => {
        if (status === (window as any).google.maps.DirectionsStatus.OK) {
          this.setState({
            directions: result
          });
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  };

  updateMapZoom = (zoom: any) => {
    this.setState({ defaultZoom: zoom })
  }

  updateMapCenter = (coords: any) => {
    this.setState({ defaultCenter: coords });
  }
  // Customizable Area End
}
