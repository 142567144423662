function validateGSTNumber(gstNumber: string) {
    const regex: RegExp = /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    return regex.test(gstNumber);
}

function validateTanNumber(gstNumber: string) {
    const regex: RegExp = /^[A-Z]{4}\d{5}[A-Z]{1}$/;
    return regex.test(gstNumber);
}

function validatePanNumber(gstNumber: string) {
    const regex: RegExp = /^[A-Z]{5}\d{4}[A-Z]{1}$/;
    return regex.test(gstNumber);
}

export { validateGSTNumber, validateTanNumber, validatePanNumber };