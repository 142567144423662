export const corner_img = require("../assets/cornerImg.png");
export const logo = require("../assets/logo.png");
export const main = require("../assets/main.png");
export const RightmarkIcon=require('../../../components/src/assets/RightmarkIcon.svg');
export const LocationIcon=require('../../../components/src/assets/Locationdetail.svg');
export const GalleryIcon=require('../../../components/src/assets/Gellary.svg');
export const deafaultImagesIcon =require("../assets/default icon.svg");
export const cameraIcon =require("../assets/camera icon.svg");
export const uploadIcon=require("../assets/upload icon.svg")
export const addNewMember=require("../assets/add memebr icon.svg")
export const inviteIcon=require("../assets/invite icon.svg")
export const keyIcon=require("../assets/key icon.svg")
export const balckTickIcon=require("../assets/check_circle_black_24dp (7) copy.svg")
export const uploadProjectIcon=require("../assets/upload_black_24dp (1).png")
export const emailIcon = require("../assets/emailIcon.png");
export const calenderIcon = require("../assets/calender.png");
export const expIcon = require("../assets/exp.png");
export const phoneIcon = require("../assets/phone.png");
export const checkedIcon = require("../assets/checkedIcon.png");
export const switchEnable = require("../assets/blockEnable.png");
export const switchDisable = require("../assets/blockDisabled.png")
export const arrowicon = require("../assets/ArrowLeft.svg");
export const studiosIcon = require("../assets/studios.svg");
export const studiosYellowIcon = require("../assets/studios_yellow.svg");
export const certificateIcon = require("../assets/certificateIcon.png");
export const reviewIcon = require("../assets/review.svg");
export const reviewYellowIcon = require("../assets/review_yellow.svg");
export const dummyImgPHIcon = "https://www.littlethings.info/wp-content/uploads/2014/04/dummy-image-green-e1398449160839.jpg";
export const forwardMailIcon = require("../assets/forwardMail.svg");
export const RequestSendIcon = require("../assets/request_send.png");
export const groupIcon = require("../assets/group.svg");
export const deleteIcon = require("../assets/delete_icon.png")
export const editIcon = require("../assets/edit_icon.png")
export const paymentIcon = require("../assets/payments_icon.png")
export const peopleIcon = require("../assets/people_icon.png")
export const bagIcon = require("../assets/bag_icon.svg");
export const forwardIcon =require("../assets/forword_arrow.svg");
export const imageIcon = require("../assets/image_icon.png");
export const outgoingMail = require("../assets/outgoing_mail_yellow.svg");
export const tipsUpdates = require("../assets/tips_and_updates_black.svg");
export const certificate = require("../assets/certificate.svg");
export const locationIcon = require("../assets/IconLocation.svg");
export const arrowYellowIcon = require("../assets/arrow_left_yellow.svg");
export const downloadIcon = require("../assets/downloadinvoice.png");
export const threeDots = require("../assets/3dotsIcon.svg");
export const lockIcon = require("../assets/lock_black.png");
export const phoneIconYellow = require("../assets/settings_phone_yellow.png");
