import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  issidebaropenblock: boolean,
  handleDropdownBlock: () => void,
  isDropdownOpenBlock: boolean,
  handleSideBarisDropdownOpenBlock: () => void
  // Customizable Area End
}

export interface S {
  id: string;
  // Customizable Area Start
  token: string;
  isLoading: boolean;
  searchInputText: string;
  accountListBread: any[];
  tabValueAccount: number;
  allProductionBlockList: any[];
  allVendorBlockList: any[];
  allStudioBlockList: any[];
  numberOfTotalItem: any;
  currentpage: number;
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ManageProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  allBlockedProductionhouseProfilesMessageId: string = "";
  allBlockedVendorProfilesMessageId: string = "";
  allBlockedStudioProfilesMessageId: string = "";
  putBlockedProductionhouseProfileMessageId: string = "";
  putBlockedVendorProfileMessageId: string = "";
  putBlockedStudioProfileMessageId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      isLoading: false,
      searchInputText: "",
      id: "0",
      tabValueAccount: 0,
      allProductionBlockList: [],
      allVendorBlockList: [],
      allStudioBlockList: [],
      accountListBread: [{
        text: "Home",
        href: "Dashboard",
        clickable: true,
        page: "Dashboard"
      },
      {
        text: "Blocked Profile",
        href: "/",
        clickable: false,
        page: "AccountGroup"
      }],
      numberOfTotalItem: {},
      currentpage: 1,
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.allBlockedProductionhouseProfilesMessageId) {
        this.getAllBlockedProductionhouseProfilesDataHandleResponse(responseJson);
      }
      else if (apiRequestCallId === this.allBlockedVendorProfilesMessageId) {
        this.getAllBlockedVendorProfilesDataHandleResponse(responseJson);
      }
      else if (apiRequestCallId === this.allBlockedStudioProfilesMessageId) {
        this.getAllBlockedStudioProfilesDataHandleResponse(responseJson);
      }
      else if (apiRequestCallId === this.putBlockedProductionhouseProfileMessageId) {
        this.putBlockedProductionhouseProfileDataHandleResponse(responseJson);
      }
      else if (apiRequestCallId === this.putBlockedVendorProfileMessageId) {
        this.putBlockedVendorProfileDataHandleResponse(responseJson);
      }
      else if (apiRequestCallId === this.putBlockedStudioProfileMessageId) {
        this.putBlockedStudioProfileDataHandleResponse(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.scrollToTop();
    const authenticationToken = await getStorageData('token');
    this.setState({
      token: authenticationToken,
    }, () => this.getAllBlockedData()
    );
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  }

  getAllBlockedProductionhouseProfilesDataHandleResponse = (responseJson: { data: any[]; meta: any; errors: [{ message: string }] }) => {
    this.scrollToTop();
    if (responseJson.data) {
      this.setState({
        allProductionBlockList: responseJson.data,
        numberOfTotalItem: { ...this.state.numberOfTotalItem, 0: responseJson.meta.total },
        isLoading: false,
      })
    }
    else {
      this.setState({ isLoading: false });
      this.showAlert("Error", responseJson.errors[0].message);
    }
  }

  getAllBlockedVendorProfilesDataHandleResponse = (responseJson: { data: any[]; meta: any; errors: [{ message: string }], error: string, status: number }) => {
    this.scrollToTop();
    if (responseJson.data) {
      this.setState({
        allVendorBlockList: responseJson.data,
        numberOfTotalItem: { ...this.state.numberOfTotalItem, 1: responseJson.meta.total },
        isLoading: false,
      });
    }
    else {
      this.setState({ isLoading: false });
      responseJson.errors && this.showAlert("Error", responseJson.errors[0].message);
      (responseJson.error && responseJson.status === 500) && this.showAlert("Error", responseJson.error);
    }
  }

  getAllBlockedStudioProfilesDataHandleResponse = (responseJson: { data: any[]; meta: any; errors: [{ message: string }] }) => {
    this.scrollToTop();
    if (responseJson.data) {
      this.setState({
        allStudioBlockList: responseJson.data,
        numberOfTotalItem: { ...this.state.numberOfTotalItem, 2: responseJson.meta.total },
        isLoading: false,
      });
    }
    else {
      this.setState({ isLoading: false });
      this.showAlert("Error", responseJson.errors[0].message);
    }
  }

  putBlockedProductionhouseProfileDataHandleResponse = (responseJson: { data: any; errors: [{ message: string }] }) => {
    if (responseJson.data) {
      const filteredProductionHouse = this.state.allProductionBlockList.filter((productionHouse) => productionHouse.id !== responseJson.data.id);
      this.setState({
        allProductionBlockList: filteredProductionHouse,
        numberOfTotalItem: { ...this.state.numberOfTotalItem, 0: this.state.numberOfTotalItem[0] - 1 },
        isLoading: false,
      })
    }
    else {
      this.setState({ isLoading: false });
      this.showAlert("Error", responseJson.errors[0].message);
    }
  }

  putBlockedVendorProfileDataHandleResponse = (responseJson: { data: any; errors: [{ message: string }] }) => {
    if (responseJson.data) {
      const filteredVendor = this.state.allVendorBlockList.filter((vendor) => vendor.id !== responseJson.data.id);
      this.setState({
        allVendorBlockList: filteredVendor,
        numberOfTotalItem: { ...this.state.numberOfTotalItem, 1: this.state.numberOfTotalItem[1] - 1 },
        isLoading: false,
      })
    }
    else {
      this.setState({ isLoading: false });
      this.showAlert("Error", responseJson.errors[0].message);
    }
  }

  putBlockedStudioProfileDataHandleResponse = (responseJson: { data: any; errors: [{ message: string }] }) => {
    if (responseJson.data) {
      const filteredStudio = this.state.allStudioBlockList.filter((studio) => studio.id !== responseJson.data.id);
      this.setState({
        allStudioBlockList: filteredStudio,
        numberOfTotalItem: { ...this.state.numberOfTotalItem, 2: this.state.numberOfTotalItem[2] - 1 },
        isLoading: false,
      })
    }
    else {
      this.setState({ isLoading: false });
      this.showAlert("Error", responseJson.errors[0].message);
    }
  }

  apiCall = async (method: string, endPoint: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.state.token,
    };
    this.setState({ isLoading: true });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getAllBlockedData = async () => {
    this.allBlockedProductionhouseProfilesMessageId = await this.apiCall(configJSON.getApiMethod, configJSON.getAllBlockedProductionhouseProfilesAPIEndPoint + 1);
    this.allBlockedVendorProfilesMessageId = await this.apiCall(configJSON.getApiMethod, configJSON.getAllBlockedVendorProfilesAPIEndPoint + 1);
    this.allBlockedStudioProfilesMessageId = await this.apiCall(configJSON.getApiMethod, configJSON.getAllBlockedStudioProfilesAPIEndPoint + 1);
  }

  handlePageChange = async (event: any, value: number) => {
    this.setState({
      currentpage: value,
    });
    if (this.state.tabValueAccount === 0 && this.state.searchInputText) {
      this.allBlockedProductionhouseProfilesMessageId = await this.apiCall(configJSON.getApiMethod, configJSON.getAllProductionhouseSearchDataApiEndPoint + this.state.searchInputText + "&page=" + value);
    } else if (this.state.tabValueAccount === 1 && this.state.searchInputText) {
      this.allBlockedVendorProfilesMessageId = await this.apiCall(configJSON.getApiMethod, configJSON.getAllVendorSearchDataApiEndPoint + this.state.searchInputText + "&page=" + value);
    } else if (this.state.tabValueAccount === 2 && this.state.searchInputText) {
      this.allBlockedStudioProfilesMessageId = await this.apiCall(configJSON.getApiMethod, configJSON.getAllStudioSearchDataApiEndPoint + this.state.searchInputText + "&page=" + value);
    } else if (this.state.tabValueAccount === 0) {
      this.allBlockedProductionhouseProfilesMessageId = await this.apiCall(configJSON.getApiMethod, configJSON.getAllBlockedProductionhouseProfilesAPIEndPoint + value);
    } else if (this.state.tabValueAccount === 1) {
      this.allBlockedVendorProfilesMessageId = await this.apiCall(configJSON.getApiMethod, configJSON.getAllBlockedVendorProfilesAPIEndPoint + value);
    } else if (this.state.tabValueAccount === 2) {
      this.allBlockedStudioProfilesMessageId = await this.apiCall(configJSON.getApiMethod, configJSON.getAllBlockedStudioProfilesAPIEndPoint + value);
    }
  };

  handleAppBarAccountValue = (event: React.ChangeEvent<{}>, value: number) => {
    this.setState({ searchInputText: "" }, async () => {
      if (this.state.tabValueAccount === 0) {
        this.allBlockedProductionhouseProfilesMessageId = await this.apiCall(configJSON.getApiMethod, configJSON.getAllBlockedProductionhouseProfilesAPIEndPoint + 1);
      } else if (this.state.tabValueAccount === 1) {
        this.allBlockedVendorProfilesMessageId = await this.apiCall(configJSON.getApiMethod, configJSON.getAllBlockedVendorProfilesAPIEndPoint + 1);
      } else if (this.state.tabValueAccount === 2) {
        this.allBlockedStudioProfilesMessageId = await this.apiCall(configJSON.getApiMethod, configJSON.getAllBlockedStudioProfilesAPIEndPoint + 1);
      }
    })
    this.setState({
      tabValueAccount: value,
      currentpage: 1,
      searchInputText: "",
    });
  };

  handleSearch = async (event: any) => {
    this.setState({
      searchInputText: event.target.value,
      currentpage: 1,
    }, () => {
      this.debounceLog();
    });
  };

  debounce = (callback: any, delay: any) => {
    let timer: any;
    return (...args: any) => {
      clearTimeout(timer);
      timer = setTimeout(() => callback(...args), delay)
    }
  }

  debounceLog = this.debounce(async () => {
    if (this.state.searchInputText.length === 0) {
      if (this.state.tabValueAccount === 0) {
        this.allBlockedProductionhouseProfilesMessageId = await this.apiCall(configJSON.getApiMethod, configJSON.getAllBlockedProductionhouseProfilesAPIEndPoint + 1);
      } else if (this.state.tabValueAccount === 1) {
        this.allBlockedVendorProfilesMessageId = await this.apiCall(configJSON.getApiMethod, configJSON.getAllBlockedVendorProfilesAPIEndPoint + 1);
      } else if (this.state.tabValueAccount === 2) {
        this.allBlockedStudioProfilesMessageId = await this.apiCall(configJSON.getApiMethod, configJSON.getAllBlockedStudioProfilesAPIEndPoint + 1);
      }
      return false;
    }

    if (this.state.tabValueAccount === 0) {
      this.allBlockedProductionhouseProfilesMessageId = await this.apiCall(configJSON.getApiMethod, configJSON.getAllProductionhouseSearchDataApiEndPoint + this.state.searchInputText + "&page=1");
    } else if (this.state.tabValueAccount === 1) {
      this.allBlockedVendorProfilesMessageId = await this.apiCall(configJSON.getApiMethod, configJSON.getAllVendorSearchDataApiEndPoint + this.state.searchInputText + "&page=1");
    } else if (this.state.tabValueAccount === 2) {
      this.allBlockedStudioProfilesMessageId = await this.apiCall(configJSON.getApiMethod, configJSON.getAllStudioSearchDataApiEndPoint + this.state.searchInputText + "&page=1");
    }
  }, 500);

  unBlockBlockedProfile = async (e: any, id: any, blockStatus: boolean) => {
    e.stopPropagation();
    if (this.state.tabValueAccount === 0) {
      this.putBlockedProductionhouseProfileMessageId = await this.handleBadgeVisibility(configJSON.putApiMethod, configJSON.putBlockedProductionhouseProfileAPIEndPoint, id, blockStatus)
    } else if (this.state.tabValueAccount === 1) {
      this.putBlockedVendorProfileMessageId = await this.handleBadgeVisibility(configJSON.putApiMethod, configJSON.putBlockedVendorProfileAPIEndPoint, id, blockStatus)
    } else if (this.state.tabValueAccount === 2) {
      this.putBlockedStudioProfileMessageId = await this.handleBadgeVisibility(configJSON.putApiMethod, configJSON.putBlockedStudioProfileAPIEndPoint, id, blockStatus)
    }
  }

  handleBadgeVisibility = async (method: string, endPoint: string, id: any, blockStatus: boolean) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.state.token,
    };
    this.setState({ isLoading: true });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const data = {
      attributes: { blocked: !blockStatus }
    };
    const httpBody = {
      data: data,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint + id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
  // Customizable Area End
}
