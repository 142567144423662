import React, { useState } from 'react';
import { Box, Grid, List, ListItem, ListItemText, Divider, Radio, Button, FormControlLabel, styled, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RadioGroup from '@material-ui/core/RadioGroup';
import CloseIcon from '@material-ui/icons/Close';
import { Filtericon } from "./assets";
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import TextField from '@material-ui/core/TextField';
interface MyProps {
    handleClose: any;
    statevalue: any;
    cityvalue: any;
    handleValue: any;
    handleRadioChange1:any;
    isBlocked:boolean;
    handleRadioChange:any;
    handleDateChange:any;
    selectedDate:string;
    selectedValues:any;
    toggleCheckbox:any;
    statename:string;
    stateindex:any;
 handleOptionChange:any;
 sortApiCallForAllProduction:any;
 ClearDataValues:any;
 handleSearchstate:any
 handleSearchcity:any
    
}
const TableGrid = styled(Grid)({
    '& .MuiGrid-root::-webkit-scrollbar': {
        display: 'none',
    },
    '& .MuiGrid-root': {
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none',
        "@media screen and (max-width: 500px)": {
        
            paddingLeft:'10px'
        }
    },
    '& .MuiFormControlLabel-label::-webkit-scrollbar': {
        display: 'none',
    },
    '& .MuiFormControlLabel-label': {
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none',
    },
})
const CustomFormControlLabel = styled(FormControlLabel)({
    '& .MuiFormControlLabel-label': {
        textAlign: 'left',
        color: 'black',
        fontWeight: '500px',
        fontSize: '14px',
        height: 44,
        overflowY: 'scroll'
    },
    '& .MuiRadio-colorSecondary.Mui-checked': {
        color: '#F5C42C',

    },
    '& .MuiList-padding': {
        paddingTop: 0,
        paddingBottom: 0,
    },
    '&.MuiTypography-body1': {


        fontWeight: 700,

    }
});
const SearchIcon1 = styled(SearchOutlinedIcon)({
    padding: "10px 0 10px 13px",
    "@media(max-width: 500px)": {
        padding: "10px 0 10px 8px",
        fontSize: '18px'
    },
});
const SearchInput1 = styled('input')({
    fontSize: "15px",
    outline: "none",
    border: "none",
    width: "85%",
    padding: "10px 3px",
    backgroundColor: "#F8FAFE",
    "@media(max-width: 500px)": {
        fontSize: '12px',
        overflow: 'hidden',
        width: "70%",
        padding: "10px 0px",
    },
});
const CustomFormControlLabel1 = styled(FormControlLabel)({
    '& .MuiFormControlLabel-label': {
        textAlign: 'left',
        color: '#17181d',
        fontWeight: '500',
        fontSize: "14px",
        height: 44,
        overflowY: 'scroll'
    },
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
        color: '#F5C42C'
    },
    '& .MuiIconButton-colorSecondary': {
        //color: '#f50057',
    }

});


const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {

        width: 200,
    },
    ParentDiv: {

        border: '10px 1px solid #d8d8d8',
        textAlign: 'center',
        width: 700,
        minHeight:'499px',
        "@media screen and (max-width: 500px)": {
        
            width: '400px',
            marginRight: "0px",
        
        }


    }
    ,
    filterDiv: {
        fontSize: "20px",
        fontWeight: 700,
        fontFamily: "urbanist"
    }
    ,
    HeaderDiv: {
        fontSize: "20px",
        textAlign: 'center',
        fontWeight: 700,
        borderBottom: "1px solid #E3EAF4",
        paddingTop: "20px",
        paddingBottom: "20px",
        display: 'flex',
        justifyContent: 'end',
        gap: '295px',
        paddingRight: '20px'


    },
    MainDiv: {
        display: 'flex',
        borderRight: "1px solid #d8d8d8",
        borderBottom: "1px solid #d8d8d8",
    },
    SideDiv: {
        minWidth: "140px",
        borderRight: "1px solid #d8d8d8",
        marginRight: '10px',
        backgroundColor: '#f8fafe',

        '& .MuiList-padding': {
            paddingTop: '0px',
            paddingBottom: '0px',
        }
    },
    main: {
        paddingBottom: "40px",
        paddingRight: '20px',
        minHeight:'288px'
    },
    stateDiv: () => ({
        "@media screen and (max-width: 500px)": {
        
            display:'flex',
            flexDirection:'column'
        }

    }),
    SearchDiv: {
        display: "flex",
        border: '1px solid  #6D89AF',
        width: "286px",
        borderRadius: "35px",
        alignItems: "center",
        backgroundColor: "#ffffff",
        overflowX: 'hidden',
        height: "44px",
        marginBottom: '6px',
        "@media screen and (max-width: 500px)": {
            minWidth: "100px",
            width: '216px',
            marginRight: "0px",
            height:'40px'
        }
    },
    TableDiv: {
        marginTop: "16px",
        display: 'flex',
        gridRowGap: '10px'
    },
    FooterDiv: {
        paddingTop: "20px",
        paddingBottom: '20px',
        justifyContent: 'end',
        paddingRight: '10px',
        display: 'flex',
        gap: '5px'

    },
    list1: {
        display: "flex",
        gap: '10px'
    },
    list2: {
        display: "flex",
        gap: '10px'
    },
    list3: {
        display: "flex",
        gap: '10px'
    },
    list4: {
        display: "flex",
        gap: '10px'
    },
    button1: {
        border: '1px solid #d8d8d8',
        height: '43px',
        width: '156px',
        '&.MuiTypography-body1': {

            fontSize: '10px'
        },
    },
    button2: {
        border: '1px solid #d8d8d8',
        height: '45px',
        width: '171px',
        '&.MuiTypography-body1': {

            fontSize: '10px'
        },
    }
    ,
    cityDiv: {
        paddingTop: "10px",
        "@media screen and (max-width: 500px)": {
        
            display:'flex',
            flexDirection:'column'
        }
    },

    button3: {
        cursor: "pointer",
        color: "black",
        backgroundColor: "#ffffff",
        textTransform: "none",
        width: "165px",
        borderRadius: "14px",
        //position: "absolute",
        bottom: "16px",
        height: "48px",
        fontWeight: 600,
        fontSize: "14px",
        paddingTop: "10px",
        paddingBottom: "14px",
        marginBottom: "5px",
        fontFamily: "urbanist",
        border: '1px solid #17181d'



    },
    button4: {
        border: '1px solid #d8d8d8',
        width: '156px',
        fontWeight: 600,
        fontSize: '15px',
        borderRadius: "15px",
        height: "43px",
        color: "white",
        backgroundColor: 'black'



    },
    ParentMain: {
        display: 'flex',
        justifyContent: 'center',
        //marginTop: '20px'
    }
    ,
    activeText: {
        color: '#f5c42c',
        fontWeight: 600,
        cursor: "pointer",
        fontSize: "14px",
        fontFamily: "urbanist",
        lineHeight: "19px",
        backgroundColor: "#ffffff",
        borderColor: '#979797',
        '&.MuiListItem-gutters': {
            paddingLeft: '16px',
            paddingRight: '16px',
            paddingTop: '16px',
            paddingBottom: '16px',
        }

    },
    buttonGroupDiv: {
        paddingTop: "10px",
        overflowY: 'auto',
        height: '204px'
    },
    inactiveText: {
        color: "#17181d",
        cursor: "pointer",
        fontWeight: 600,
        fontSize: "14px",
        fontFamily: "urbanist",
        lineHeight: "19px",
        backgroundColor: "#f8fafe",
        borderColor: '#979797',
        '&.MuiListItem-gutters': {
            paddingLeft: '16px',
            paddingRight: '16px',
            paddingTop: '16px',
            paddingBottom: '16px',
        }
    },
    submitButton: {
        cursor: "pointer",
        color: "#ffffff",
        backgroundColor: "#17181D",
        textTransform: "none",
        width: "165px",
        border: "none",
        borderRadius: "14px",
        //position: "absolute",
        bottom: "16px",
        height: "48px",
        fontWeight: 600,
        fontSize: "14px",
        paddingTop: "10px",
        paddingBottom: "14px",
        marginBottom: "5px",
        fontFamily: "urbanist",
    },
    selectedButton: {
        border: '1px solid #F5C42C',
        borderRadius: '12px'
    },
    unselectedButton: {
        borderRadius: '12px'
    },
    searchicon: {
        marginRight: '6px',
        fill: '#6D89AF',
        fontSize: "14px",
        paddingTop: '14px',
    }

});



export default function Sortfunctionality(props: MyProps) {
    const { handleClose,handleSearchcity,ClearDataValues,sortApiCallForAllProduction,handleSearchstate, statevalue, cityvalue, handleValue,handleRadioChange1,selectedDate ,isBlocked, handleRadioChange,handleDateChange,toggleCheckbox,selectedValues,statename,stateindex,handleOptionChange} = props;

    const [selectedButton, setSelectedButton] = useState<number>(0);
    const classes = useStyles();
    //const [selectedDate, setSelectedDate] = useState(''); // State to store the selected date
  
  

    const handleButtonClick = (index: any) => {
        setSelectedButton(index);
    };
    const [state, setState] = useState(true);
    const [city, setCity] = useState(false)
    const [status, setStatus] = useState(false)
    const [startDate, setStartDate] = useState(false)
    const buttonData = [
        "Maharashtra",
        "Madhya Pradesh",
        "Delhi",
        "Gujrat",
        "Uttar Pradesh",
        "Jammu & Kashmir",
        "Sikkim",
        "Rajasthan",
        "Tamil Nadu",
        "Manipur",
        "Chattisgrah",
        "Kerala",
    ]
    const buttonData1 = [
        "Banglore",
        "Mumbai",
        "Indore",
        "Ujjain",
        "Agra",
        "Ratlam",
        "pune",
        "Chandigarh",
        "Ahmedabad",
        "Kolkata",
        "Delhi",
        "Jaipur",

    ]
    const handleclick = () => {
        setState(true)
        setCity(false)
        setStatus(false)
        setStartDate(false)
    };
    const handleclick1 = () => {
        setState(false)
        setCity(true)
        setStatus(false)
        setStartDate(false)
    };
    const handleclick2 = () => {
        setState(false)
        setCity(false)
        setStatus(true)
        setStartDate(false)
    };
    const handleclick3 = () => {
        setStartDate(true);
        setCity(false);
        setState(false);
        setStatus(false)
    }
    const [selectedValue, setSelectedValue] = useState('');

    const handleChange = (event: any) => {
        setSelectedValue(event.target.value);
    };
    console.log('dddddd',selectedDate)
    


    return (
        <Grid className={classes.ParentMain}>
            <Grid className={classes.ParentDiv}>
                <Grid className={classes.HeaderDiv}>
                    <Grid className={classes.filterDiv}>
                        Filter
                    </Grid>
                    <Grid className={classes.filterDiv}>
                        <CloseIcon onClick={handleClose} />
                    </Grid>
                </Grid>
                <Grid className={classes.MainDiv}>
                    <Grid className={classes.SideDiv}>
                        <List style={{ fontSize: '14px' }}>
                            <ListItem className={state ? classes.activeText : classes.inactiveText} onClick={handleclick}>
                                <ListItemText primary="State" />
                            </ListItem>
                            <Divider />
                            <ListItem className={city ? classes.activeText : classes.inactiveText} onClick={handleclick1}>
                                <ListItemText primary="City" />
                            </ListItem>
                            <Divider />
                            <ListItem className={startDate ? classes.activeText : classes.inactiveText} onClick={handleclick3}>
                                <ListItemText primary="Start Date" />
                            </ListItem>
                            <Divider />
                            <ListItem className={status ? classes.activeText : classes.inactiveText} onClick={handleclick2}>
                                <ListItemText primary="Status" />
                            </ListItem>
                            <Divider />
                        </List>
                    </Grid>

                    <Grid className={classes.main} >

                        <TableGrid className={classes.TableDiv}>
                            {state === true ? (
                                 <Grid>
                                 <Grid className={classes.SearchDiv}>
                                   <SearchIcon1 className={classes.searchicon} />
                                   <SearchInput1
                                     placeholder="search state"
                                     style={{
                                       fontSize: '14px',
                                       fontWeight: 500,
                                       fontFamily: 'Urbanist',
                                       letterSpacing: '0.25px',
                                       color: '#6D89AF',
                                     }}

                                     onChange={(event:any)=>handleSearchstate(event)}
                                   />
                                 </Grid>
                                 <Grid style={{ paddingTop: '16px' }} className={classes.buttonGroupDiv}>
                                   <RadioGroup value={selectedValue} onChange={handleChange}>
                                     <Grid container spacing={1} xs={12} className={classes.stateDiv}>
                                       {statevalue.map((state:any, index:any) => (
                                         <Grid item xs={4} key={index}>
                                           <Button
                                             className={classes.button1}
                                             style={{
                                               border:
                                                 stateindex === index
                                                   ? '1px solid #F5C42C'
                                                   : '1px solid #e3eaf4',
                                               borderRadius: '16px',
                                             }}
                                             onClick={() => handleOptionChange(index, state)}
                                             onChange={() => handleValue(state.iso2)}
                                           >
                                             <CustomFormControlLabel
                                               value={state.name}
                                               label={state.name}
                                               control={<Radio checked={stateindex === index} />}
                                               labelPlacement="start"
                                               style={{
                                                 textTransform: 'none',
                                                 display: 'flex',
                                                 width: '100%',
                                                 justifyContent: 'space-between',
                                                 borderColor: 'red',
                                               }}
                                             />
                                           </Button>
                                         </Grid>
                                       ))}
                                     </Grid>
                                   </RadioGroup>
                                 </Grid>
                               </Grid>
                            ) : city === true ? (
                                <Grid>
                                    <Grid className={classes.SearchDiv}>
                                        <SearchIcon1 className={classes.searchicon} />
                                        <SearchInput1 placeholder={"search city"}
                                            style={{
                                                fontSize: "14px", fontWeight: 500,
                                                fontFamily: "Urbanist", letterSpacing: "0.25px"
                                            }}
                                            onChange={(event:any)=>handleSearchcity(event)}
                                            />
                                    </Grid>
                                    <Grid className={classes.buttonGroupDiv}>
                                        <Grid container spacing={1} xs={12} className={classes.cityDiv}>

                                            {

                                                cityvalue?.map((city: any, index: number) => (
                                                    <Grid item xs={4}>
                                                        <Button
                                                            key={index}
                                                            className={`${classes.button1} ${selectedValues.includes(city.name) ? classes.selectedButton : classes.unselectedButton}`}
                                                            onChange={() => toggleCheckbox(city.name)}
                                                        >
                                                            <CustomFormControlLabel1
                                                                control={
                                                                    <Checkbox
                                                                        checked={selectedValues.includes(city.name)}
                                                                        onChange={() => toggleCheckbox(city.name)}
                                                                    />
                                                                }
                                                                label={city.name}
                                                                style={{
                                                                    textTransform: 'none',
                                                                    display: 'flex',
                                                                    width: '100%',
                                                                    justifyContent: 'space-between',
                                                                    color: selectedValues.includes(city.name) ? '#F5C42C' : 'black',

                                                                }}
                                                                labelPlacement="start"
                                                            />
                                                        </Button>

                                                    </Grid>
                                                ))}
                                        </Grid>
                                    </Grid>
                                </Grid>

                            ) : startDate == true ? (


                                <form className={classes.container} noValidate>
                                    <TextField
                                        id="date"
                                        label="Start date"
                                        type="date"
                                        defaultValue="yy-mm-dd"
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={handleDateChange}
                                    />
                                </form>


                            ) : (
                                <Box style={{
                                    display: "flex", gap:
                                        "10px"
                                }}>
                                    <Box style={{ paddingTop: '60px' }}>
                                        Block
                                        <Radio
                                            value={isBlocked ? 'Blocked' : 'Unblocked'}
                                            checked={isBlocked}
                                            onChange={handleRadioChange}
                                        />

                                    </Box>
                                    <Box style={{ paddingTop: '60px' }}>
                                        Unblock
                                        <Radio
                                            value={isBlocked ? 'Blocked' : 'Unblocked'}
                                            checked={!isBlocked}
                                            onChange={handleRadioChange1}
                                        />

                                    </Box>
                                    </Box>

                                    )



                            }

                                </TableGrid>
                    </Grid>

                </Grid>
                <Grid className={classes.FooterDiv}>
                    <button
                        className={classes.button3}  onClick={ClearDataValues} >
                        Clear All
                    </button>
                    <button
                        className={classes.submitButton}
                        onClick={sortApiCallForAllProduction}

                    >
                        Apply
                    </button>
                </Grid>
            </Grid>
        </Grid>

    );
}
