import React from "react";
import { Card, Box, CardMedia, CardContent, Typography, Link, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { pushpaImage, clock, iconC, location, OverviewIcon, LocationIcon, VendorIcon, departicon, VendorDetailimg, workicon, OverviewIconactive, VendorIconactive, departiconactive } from "./assets";
import { useState } from "react";
import VendorsCard from "../src/VendorsCard"
import DepartCard from "./DepartCard";
interface MyProps {
  data: any;
  datavalue:any;
  datamember:any;
}

const useStyles = makeStyles({
  card: {
    width: 330,
    borderRadius: 14,
    padding: 8,
   
    border: "1px solid #E1E6EC",
    position: 'relative',
    boxShadow: 'none',
    '& .MuiCardContent-root': {
      padding: 0
    },
    '@media(max-width: 900px)': {
      width: 'unset',
      maxWidth: '330px',
      margin: 'auto'
    },
  },
  detailbox: {
    width: 702,
    minHeight:361,
    borderRadius: 14,
    border: "1px solid #E1E6EC",
    backgroundColor:"#ffffff",
    '@media(max-width: 900px)': {
      width: 'unset',
      maxWidth: '600px',
      marginTop: '20px',
      backgroundColor:"#ffffff",
      minHeight:361
    },
    "@media(min-width: 1440px)": {
      width: '702px',
      backgroundColor:"#ffffff",
      minHeight:361
    },
  },
  cardImage: {
    width: '100%',
    borderRadius: '12px',
    height:"130px"
  },
  cardImageDiv: {
    position: 'relative',
  },
  overlay: {
    position: 'absolute',
    borderRadius: '12px',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundImage: "linear-gradient(180deg, rgba(0,0,0,0.15) 0%, rgba(0,0,0,0.65) 100%)"
  },
  cardTopContent1: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'absolute',
    top: 65,
    paddingInline: 12,
    width: '88%',
    alignItems: 'end'
  },
  cardTopContent2: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 16,
    flexDirection: "column",
  },
  cardTopContent3: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 15,
    paddingBottom: 8,
    marginInline: '7px'
  },
  ratingBox: {
    background: '#F5C42C',
    width: 68,
    height: 23,
    color: 'white',
    fontWeight: 700,
    fontSize: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: "12px"
  },
  projectBox1: {
    border: '1px solid #e2eaf4',
    borderRadius: 10,
    backgroundColor: '#f8fafe',
    width: 156,
    height: 38,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
    fontWeight: 700,
    color: 'black',
  },
  projectBox2: {
    fontSize: 14,
    color: 'black',
    fontWeight: 700,
  },
  dateText: {
    fontSize: 12,
    color: '#6d89af',
    fontWeight: 700,
  },
  projectBox3: {
    fontSize: 13,
    color: '#6d89af',
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
    gap: 4,
  },
  icon1: {
    marginLeft: '14px',
    height:"24px",
    width:"24px"
  },
  vendorIcon: {
    marginLeft: "10px",
    height:"24px",
    width:"24px",
  },
  departmentIcon: {
    height:"24px",
    width:"24px",
    marginLeft: "11.9px",
  },
  overviewIcon: {
    width: "14px",
    height: "14px",
  },
  calendarIcon: {
    width: "12px",
    height: "13.33px",
  },
  watchIcon: {
    width: "14.17px",
    height: "14.17px",
  },
  overviewLocationIcon: {
    width: "55px",
    height: "55px",
  },
  cardcontent1: {
    paddingBottom: '8px',
    paddingLeft: '12px',
    fontFamily:"urbanist"
  },
  cardcontent2: {
    cursor: "pointer",
    '&:hover': {
      color: "yellow",
    },
  },
  cardcontent3: {
    display: 'flex',
    gridColumnGap: '15px',
    background: '#ececee66',
    borderRadius: '12px',
    height: '52px',
    marginInline: '8px',
    alignItems: 'center'
  },
  vendors: {
    color: "inherit",
  },
  vendoractive: {
    color: 'rgb(245, 196, 44)',
  },
  departments: {
    marginLeft: "100px",
    color: "#6d89af",
  },
  carddepart5: {
    display: 'flex',
    gridColumnGap: '15px',
    background: '#ececee66',
    borderRadius: '12px',
    height: '52px',
    marginInline: '8px',
    alignItems: 'center'
  },
  parentbox: {
    display: "flex",
    flexDirection: "row",
    gridColumnGap: '25px',
    justifyContent: 'center',
    alignItems: "flex-start",
    marginTop: '2.3%',
    '@media(max-width: 900px)': {
      display: 'block'
    },
  },
  vendor: {
    cursor: 'pointer',

  },
  department: {
    cursor: 'pointer',
  },
  overviewdetails: {
    padding: '30px',
  },
  overviewTitle: {
    lineHeight: "34px",
    color: "#17181d",
    fontFamily: "urbanist",
    fontSize: "28px",
    fontWeight: 700,
    letterSpacing: "0.31111112px",
  },
  overviewSubTitle: {
    lineHeight: "19px",
    color: "#6d89af",
    fontFamily: "urbanist",
    fontSize: "16px",
    fontWeight: 600,
  },
  overviewText: {
    color: "#17181d",
    fontFamily: "urbanist",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "19px",
    marginBottom: "2px",
    textOverflow: 'none',

  },
  overviewTextTwo: {
    color: "#17181d",
    fontFamily: "urbanist",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "18px",
  },
  projectoverview: {
    borderTop: "1px solid #E1E6EC",
    borderBottom: "1px solid #E1E6EC",
    marginTop: "15px",
  },
  productiondemo: {
    border: "1px solid #E1E6EC",
    borderRadius: "12px",
    display: 'flex',
    padding: '10px',
    paddingLeft: '16px',
    marginTop: "7px",
  },
  departmentdetails: {
    display: "flex",
    justifyContent: "start",
    alignItems: "start",
    flexDirection: "column",
    flexWrap: "wrap",
    padding: '30px',
    paddingBottom: '15px',
  },
  departmentItemsdetails: {
    columnCount: 2,
    columnGap: 15,
    width: "100%",
    marginTop: "3%",
  },
  subheading: {
    marginTop: '14px',
  },
  subcontent: {
    fontWeight: 'bold',
    marginTop: '10px',
    fontSize: '14px',
    marginBottom: '20px',
    
  },
  projectLocation: {
    marginTop: "15px",
  },
  productionheading: {
    marginLeft: '16px',
  },
  overviews: {
    display: "flex",
    FlexDirection: "row",
    gridColumnGap: '15px',
    alignItems: 'center',
    background: '#f5c42c14',
    borderRadius: '12px',
    height: '52px',
    marginInline: '8px'
  },
  headingv: {
    display: 'block',
    width: '100%',
    fontSize: "28px",
    padding: "0px 0px 16px",
    borderBottom: "1px solid #E3EAF4",
    margin: "0",
    lineHeight: "34px",
    fontWeight: 700,
    fontFamily:"urbanist"
  },
  vendordetails: {
    display: "flex",
    justifyContent: "start",
    alignItems: "start",
    flexDirection: "row",
    flexWrap: "wrap",
    padding: '30px'
  },
  activeText: {
    color: '#F5C42C',
    cursor: "pointer",
    fontWeight: 700,
    fontFamily:"urbanist"
  },
  inactiveText: {
    color: "inherit",
    cursor: "pointer",
    fontWeight: 700,
    fontFamily:"urbanist"

  },
  generaltabbox: {
    gridRowGap: '12px',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 14,
    borderBottom: '1px solid #e3eaf4',
    flexDirection: "column",
  }
  ,
  innertabbox: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    paddingRight: '15px',
  },
  allPictureLink: {
    color: 'rgb(51, 137, 255)',
    textDecoration: 'underline',
  },
  addressBox:{
    fontFamily:"urbanist",
    fontSize:"12px"

  }
});

export default function ProjectDetailCard(props: MyProps) {
  const { data ,datavalue,datamember} = props;
  
  const classes = useStyles();
  const [overview, setOverview] = useState(true);
  const [departments, setDepartpments] = useState(false);
  const [vendors, setVendors] = useState(false);

  const handleclick = () => {
    setOverview(true);
    setDepartpments(false);
    setVendors(false);
  };
  const handleclick1 = () => {
    setOverview(false);
    setDepartpments(false);
    setVendors(true);
  };
  const handleclick2 = () => {
    setOverview(false);
    setDepartpments(true);
    setVendors(false);
  };
  return (
    <Grid className={classes.parentbox}>
      
      <Card className={classes.card}>
        <div className={classes.cardImageDiv}>
          <CardMedia
            className={classes.cardImage}
            component="img"
            alt="Image"
            image={data?.attributes?.image}
          />
          <div className={classes.overlay}></div>
        </div>
        <CardContent>
          <Grid className={classes.cardTopContent1}>
            <Grid>
              <Typography variant="h5" component="h5" style={{ color: "white" ,fontFamily:"urbanist",fontSize:"16px"}}>
                {data?.attributes?.project_name}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p" style={{ color: "white", display: 'flex', alignItems: 'center', gap: '5px' }}>
                <img src={location} alt="location icon" className={classes.overviewIcon} />
                <Box className={classes.addressBox}>
                {data?.attributes?.address}
                </Box>
              </Typography>
            </Grid>
            <Grid className={classes.ratingBox}>
              {data?.attributes?.status}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}className={classes.cardTopContent2}>
            <Grid className={classes.cardcontent1}>
              General</Grid>
            <Grid className={classes.generaltabbox}    >
              <Grid className={overview ? classes.overviews : classes.carddepart5} onClick={handleclick} >
                <img src={overview ? OverviewIconactive : OverviewIcon} alt="overviewicon icon" className={classes.icon1} />
                <Grid
               
                  className={overview ? classes.activeText : classes.inactiveText}
                >
                  Overview
                </Grid>
              </Grid>
              <Grid className={vendors ? classes.overviews : classes.carddepart5}   onClick={handleclick1}>
                <img src={vendors ? VendorIconactive : VendorIcon} alt="Vendoricon icon" className={classes.vendorIcon} />
                <Grid className={classes.innertabbox}>
                  <Grid
                  
                    className={vendors ? classes.activeText : classes.inactiveText}
                  >
                    All Vendors
                  </Grid>
                  <Grid className={vendors ? classes.vendoractive : classes.vendors}>{data?.attributes?.vendors_count}</Grid>
                </Grid>
              </Grid>
              <Grid className={departments ? classes.overviews : classes.carddepart5}  onClick={handleclick2}>
                <img src={departments ? departiconactive : departicon} alt="departmenticon icon" className={classes.departmentIcon} />
                <Grid className={classes.innertabbox}>
                  <Grid
                   
                    className={departments ? classes.activeText : classes.inactiveText}
                  >
                    All Departments
                  </Grid>
                  <Grid className={departments ? classes.vendoractive : classes.vendors}>{data?.attributes?.department_count}</Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid className={classes.cardTopContent3}>
            <Grid className={classes.projectBox2}>
              <Grid style={{ paddingBottom: "5px", maxWidth: 200,fontFamily:"Urbanist" }}>
                Posted By <Link href="#" className={classes.allPictureLink}>{data?.attributes?.production_house}</Link>
              </Grid>
              <Grid className={classes.projectBox3}>
                <img src={iconC} alt="" className={classes.calendarIcon} />
                <Typography className={classes.dateText}>{data?.attributes?.start_date} - {data?.attributes?.end_date}</Typography>
              </Grid>
            </Grid>
            <Grid className={classes.projectBox3}>
              <img src={clock} alt="" className={classes.watchIcon} />
              {data?.attributes?.total_days} days
            </Grid>
          </Grid>
        </CardContent>
      </Card>
  
      <Grid className={classes.detailbox}>
        {
          overview === true ? (
            <Grid className={classes.overviewdetails}>
              <Grid>
                <Typography className={classes.overviewTitle}>Overview</Typography>
              </Grid>

              <Grid className={classes.projectoverview}>
                <Grid className={classes.subheading}>
                  <Typography className={classes.overviewSubTitle}>Project Overview</Typography>
                </Grid>
                <Grid className={classes.subcontent}>
                  <Typography className={classes.overviewText}>{data?.attributes?.description}</Typography>
                </Grid>
              </Grid>
              <Grid className={classes.projectLocation}>
                <Typography className={classes.overviewSubTitle}>Project Location</Typography>
              </Grid>
              <Grid className={classes.productiondemo}>
                <img src={LocationIcon} alt="departmenticon icon" className={classes.overviewLocationIcon} />
                <Grid>
                  <Grid className={classes.productionheading}>
                    <Typography className={classes.overviewText}>{data?.attributes?.production_house}</Typography>
                  </Grid>
                  <Box className={classes.productionheading}>
                    <Typography className={classes.overviewTextTwo}>{data?.attributes?.address}</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          ) : vendors === true ? (
            <div>
              <Grid className={classes.vendordetails}>
                <h1 className={classes.headingv}>All Vendors</h1>
                {datamember?.map((item:any,index:any) => (
                  <Grid item md={6} sm={6} xs={12}>
                    <VendorsCard data={""} serialnumber={index+1} datavalue={item} />
                  </Grid>
                ))}
              </Grid>
            </div>
          ) : (
            <Grid className={classes.departmentdetails}>
              <h1 className={classes.headingv}>All Departments</h1>
              <Grid className={classes.departmentItemsdetails}>
                {datavalue?.map((item: any, index: any) => (
                  <Grid item>
                    <DepartCard data={item} serialnumber={index+1} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )
        }
      </Grid>
    </Grid>
  );
}
