import {withStyles , TextField} from '@material-ui/core';

export const CustomTextFieldInput = (emailInputHeightPass:any , borderRadius : any , borderColor : any , color :any) => withStyles({
    root: {
      "& .MuiOutlinedInput-root": {
        borderRadius: borderRadius,
        fontWeight: 400,
        color: color,
      }
    }
  })(TextField);