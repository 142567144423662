
import React from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  TypographyProps,
  Link,
  AppBar,
  Tabs,
  Tab,
  } from "@material-ui/core";
import SideBar from "../../../components/src/SideBar";
import Header from "../../../components/src/Header.web";
import Footer from "../../../components/src/Footer";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Loader from "../../../components/src/Loader.web";
import "./studiosbooking.css";
import Pagination from "@material-ui/lab/Pagination";
import Alert, { AlertProps } from "@material-ui/lab/Alert";
import ProductionHouseDashboardController,{Props} from "./ProductionHouseDashboardController.web";
import ProductionHouseProjectCard from "../../../components/src/ProductionHouseProjectCard";
import NOProjects from "../../../components/src/NoProjects";
const theme = createTheme({
  palette: {
    primary: {
      main: "#F5C42C",
      contrastText: "#fff"
    }
  },
  overrides: {
    MuiSelect: {
      select: {
        "&:focus": {
          border: "1px solid #F5C42C",
        }
      }
    }
  }
});

const CustomAlert = React.forwardRef<HTMLDivElement, AlertProps>(function CustomAlert(
  props,
  ref
) {
  return <Alert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default class ProductionHouseDashboard extends ProductionHouseDashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  AllProjectListPH=()=>{
    return<>
     {this.state.tabValueProject === 0 && (
              <Grid container spacing={3} style={{ paddingLeft: "12px", paddingRight: "12px" }} >
               { this.state.projectsList?.length  ?<>{
                                                this.state.projectsList?.map((ele:any,index:number) => {                                                    
                                                     return (
                                                        <Grid key={index} xs={12}  sm={6}  md={4} item  onClick={()=>this.navigateToallJobDetail(ele?.id)} >
 
                                                            <ProductionHouseProjectCard  data={ele} navigateToallinvoiceRequest={this.navigateToallinvoiceRequest}/>
 
                                                      </Grid>)
                                             })
                                              }</> : <NOProjects clickHandler={this.handleNavigateToAddNewProject} />
                                            }
              </Grid>)
            }
      </>
  }
  OngoingProjectPH=()=>{
    return<>
       {this.state.tabValueProject === 1 && (
             <Grid container spacing={3} style={{ paddingLeft: "12px", paddingRight: "12px" }} >
             { this.state.OnGoingProjectList?.length  ?<>{
                                              this.state.OnGoingProjectList?.map((ele:any,index:number) => {                                                    
                                                   return (
                                                      <Grid   data-test-id="ongoing" key={index} xs={12}  sm={6} md={4} item  onClick={()=>this.navigateToallJobDetail(ele?.id)} >

                                                          <ProductionHouseProjectCard  data={ele} navigateToallinvoiceRequest={this.navigateToallinvoiceRequest} />

                                                    </Grid>)
                                           })
                                            }</> : <NOProjects clickHandler={this.handleNavigateToAddNewProject} />
                                          }
            </Grid>)
          }
            
    </>
  }
  
  UpcomingProjectPH=()=>{
    return<>
     {this.state.tabValueProject === 2 && (
             <Grid container spacing={3} style={{ paddingLeft: "12px", paddingRight: "12px" }} >
             { this.state.UpComingProjectList?.length  ?<>{
                                              this.state.UpComingProjectList?.map((ele:any,index:number) => {                                                    
                                                   return (
                                                      <Grid  data-test-id="upcoming" key={index} xs={12}  sm={6}  md={4} item  onClick={()=>this.navigateToallJobDetail(ele?.id)} >

                                                          <ProductionHouseProjectCard  data={ele} navigateToallinvoiceRequest={this.navigateToallinvoiceRequest}/>

                                                    </Grid>)
                                           })
                                            }</> : <NOProjects clickHandler={this.handleNavigateToAddNewProject} />
                                          }
            </Grid>)
          }
            
    </>
  }

  renderAllProjectListMainContent = (sideBarStatus: boolean = this.state.isSideBarOpen) => {
    return <>
      <Loader loading={this.state.isLoading}></Loader>
      <Grid style={webStyle.contentSetupProjectPH} >
        <Header handleSideBar={this.handleSideBar}
          dropDownStatus={this.state.isDropdownOpen}
          handleDropdown={this.handleDropdown}
          navigate={this.props.navigation.navigate}
          sideBarStatus={sideBarStatus}
        />
        <Grid style={webStyle.mainContentProjectPH as React.CSSProperties}>
          <Box sx={webStyle.blockInfoProjectPH}>
            <Box>
              <Typography variant="h3" style={webStyle.activeBlockProjectPH as TypographyProps}>Projects</Typography>
              <Typography variant="h6" style={webStyle.blockPathProjectPH as TypographyProps}>
                <span style={webStyle.highlightedTextProjectPH}>
                  <Link data-test-id="homeLink" color="inherit" onClick={() => this.handleNavigation("Projects")}>
                    Home
                  </Link>
                </span>
                {" "}/ Projects
              </Typography>
            </Box>
           {
            this.state.projectsList?.length?(
                <Box
                sx={webStyle.submitButtonProjectPH}
                data-test-id="handleopen"

onClick={this.handleNavigateToAddNewProject}
                 >
                + Add New Project
            </Box>

            ):(null)
           }
          
                
          </Box>
          <Grid item sm={12} xl={12} xs={12} md={12} lg={12}>
            <AppBar
              
              color="transparent"
              position="static"
              className="appbar app_bar"
            >
              <Tabs
               
                TabIndicatorProps={{ style: { height: '2px', background: "orange" } as React.CSSProperties }}
                scrollButtons="on"
                className="tabs_main"
                aria-label="scrollable prevent tabs example"
                value={this.state.tabValueProject}
                data-test-id="tabChange"
                variant="scrollable"
                onChange={this.handleAppBarValue}
              >
                <Tab className={this.state.tabValueProject == 0 ? "active_btn" : "non_active_btn"} label="All Projects" />
                <Tab className={this.state.tabValueProject == 1 ? "active_btn" : "non_active_btn"} label="Ongoing" />
                <Tab className={this.state.tabValueProject == 2 ? "active_btn" : "non_active_btn"} label="Upcoming" />
                
              </Tabs>
            </AppBar>
          </Grid>
          <Grid container spacing={3} style={{ marginTop: "12px" }}>
            {this.AllProjectListPH()}
            {this.OngoingProjectPH()}
            {
                this.UpcomingProjectPH()
            }
            
            
           
          </Grid>
          {this.state.getCountForPagination[this.state.tabValueProject] ?
            (<Grid container spacing={2}>
              <Grid item xs={12}>
                <Pagination style={webStyle.paginationWrapper} data-test-id="pagination" count={Math.ceil((this.state.getCountForPagination[this.state.tabValueProject]) / 9)} page={this.state.currentStudiosPage} onChange={this.handlePageChange} />
              </Grid>
            </Grid>)
            : null}
        </Grid >
        <Footer />
      </Grid >
     
    </>
  }
  // Customizable Area End

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Container style={webStyle.parentDivProjectPH} >
          {
            this.state.isSideBarOpen ? (
              <Grid spacing={0} container >
                <Grid item xs={3} style={webStyle.sidebarParentProjectPH} >
                  <SideBar
                    activeMenuItem={"Projects"}
                    navigate={this.props.navigation.navigate}
                    handleSideBar={this.handleSideBar}
                    userData={{ name: "user name" }}
                  
                  />
                </Grid>
                <Grid item style={{ maxWidth: "77.75%",flexBasis: "77.75%" }} xs={9} >
                  {this.renderAllProjectListMainContent()}
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12}>
                {this.renderAllProjectListMainContent()}
              </Grid>
            )
          }
         
        </Container>
        
     
      </ThemeProvider>
    );
  }
}

const webStyle = {
  parentDivProjectPH: {
    padding: "0px",
    maxWidth: "none"
  },
  sidebarParentProjectPH: {
    minHeight: "100vh",
    maxWidth: "22.25%",
  },
  contentSetupProjectPH: {
    
    borderLeft: "1px solid #E3EAF4"
  },
  container: {
    marginTop: "12px"
  },
  mainContentProjectPH: {
    width: "100%",
    minHeight: "100vh",
    backgroundColor: "#F8FAFE",
    boxSizing: "border-box",
    padding: "24px"
  },
  blockInfoProjectPH: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap"
  },
  highlightedTextProjectPH: {
    color: "#F5C42C",
    fontFamily: "Urbanist",
    cursor: "pointer",
    textDecoration: "underline"
  },
 
 
  

  activeBlockProjectPH: {
    fontSize: "28px",
    lineHeight: "34px",
    letterSpacing: "1px",
    color: "#17181D",
    fontWeight: "700",
  },
  blockPathProjectPH: {
    letterSpacing: "1px",
    fontSize: "14px",
    marginBottom: "15px",
    color: "#6D89AF",
    paddingBottom: "3px",
    fontWeight: 500,
    paddingTop: "8px",
    lineHeight: "17px",
  },
  submitButtonProjectPH: {
    width: '161px',
  
    borderRadius: '14px',
    background: '#17181d',
    color: ' #ffffff',
    fontFamily: 'urbanist',
    fontSize: '15px',
    fontWeight: 600,
    letterSpacing: ' 0.25px',
    alignItems: 'center',
    paddingTop: '15px',
    paddingBottom: '15px',
    display: 'flex',
    flexDirection: 'column-reverse',
    marginLeft: '208px',
    cursor:'pointer'
},
  paginationWrapper: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'center',
  }
};
