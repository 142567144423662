import React from "react";

// Customizable Area Start
import { Container, Box, Typography, Grid, Link, TypographyProps, Card, CardContent } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { nextIcon, previousIcon } from "./assets"
import SideBar from "../../../components/src/SideBar";
import Header from "../../../components/src/Header.web";
import Footer from "../../../components/src/Footer";
import Loader from "../../../components/src/Loader.web";
import VendorDetailedAnalyticsController, { Props } from "./VendorDetailedAnalyticsController";
import { VendorAnalyticsBarChart } from "./VendorAnalytics.web";
import CustomNotification from "../../../components/src/CustomNotification";
// Customizable Area End

export default class VendorDetailedAnalytics extends VendorDetailedAnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  vendorDetailedAnalyticsRenderGraphs() {
    return <>
      <Grid container>
        {(this.state.vendorAnalyticsDetailedReportType === "project" ? this.state.vendorProjectsFilteredBars : this.state.vendorInvoicesFilteredBars).map((barChartData: any, index: any) => {
          return <Grid style={{ padding: "12px", boxSizing: "border-box" }} className="vendor_analytics_invoice" id="invoice" item lg={6} sm={12} xs={12}>
            <Card >
              <CardContent style={{ paddingBottom: "12px" }}>
                <div style={webStyle.vendorDetailedAnalyticsChartInfo}>
                  <div style={webStyle.vendorDetailedAnalyticsLegendStyle as React.CSSProperties}>
                    <div style={{ ...webStyle.vendorDetailedAnalyticsLegendDot, backgroundColor: `${barChartData.bars[0].color}` }}></div>
                    {barChartData.title}
                  </div>
                  <div style={webStyle.vendorDetailedAnalyticsSlider}>
                    <div style={{ ...webStyle.updateVendorDetailedAnalyticsCurrentPeriod, cursor: !barChartData.index ? "none" : "pointer" }} data-test-id={`Handle-Previous-Click-${index}`} onClick={() => { this.vendorDetailedAnalyticsHandlePreviousClick(barChartData.index, this.state.vendorAnalyticsDetailedReportType === "project" ? "vendorProjectsFilteredBars" : "vendorInvoicesFilteredBars", index) }}><img style={{ opacity: !barChartData.index ? "0.4" : "unset" }} src={previousIcon} alt="previous" /></div>
                    <div style={webStyle.vendorDetailedAnalyticsCurrentPeriod as React.CSSProperties}>{this.state.values[barChartData.index]}</div>
                    <div style={{ ...webStyle.updateVendorDetailedAnalyticsCurrentPeriod, cursor: barChartData.index === 2 ? "none" : "pointer" }} data-test-id={`Handle-Next-Click-${index}`} onClick={() => { this.vendorDetailedAnalyticsHandleNextClick(barChartData.index, this.state.vendorAnalyticsDetailedReportType === "project" ? "vendorProjectsFilteredBars" : "vendorInvoicesFilteredBars", index) }}><img style={{ opacity: barChartData.index === 2 ? "0.4" : "unset" }} src={nextIcon} alt="next" /></div>
                  </div>
                </div>
                <Box style={webStyle.vendorDetailedAnalyticsChartLineStyle} />
                <div>
                  <VendorAnalyticsBarChart
                    data={barChartData.data}
                    barGap={"4%"}
                    navigate={this.props.navigation.navigate}
                    bars={barChartData.bars}
                  />
                </div>
              </CardContent>
            </Card>
          </Grid>
        })}
      </Grid>
    </>
  }

  vendorDetailedAnalyticsRenderMainContent(vendorDetailedAnalyticsSideBarStatus: boolean = this.state.isVendorDetailedAnalyticsSideBarOpen) {
    return <>
      <Loader loading={this.state.isLoading}></Loader>
      <Grid style={webStyle.vendorDetailedAnalyticsContentSetup}>
        < Header
          dropDownStatus={this.state.isVendorDetailedAnalyticsDropdownOpen}
          handleDropdown={this.vendorDetailedAnalyticsHandleDropdown}
          sideBarStatus={vendorDetailedAnalyticsSideBarStatus}
          navigate={this.props.navigation.navigate}
          handleSideBar={this.vendorDetailedAnalyticsHandleSideBar}
        />
        <Grid style={webStyle.vendorDetailedAnalyticsMainContent as React.CSSProperties}>
          <Box>
            <Typography variant="h3" style={webStyle.vendorDeyailedAnalyticsActiveBlock as TypographyProps}>{this.state.vendorAnalyticsDetailedReportType === "project" ? "Project Analytics" : "Invoice Analytics"}</Typography>
            <Typography variant="h6" style={webStyle.vendorDetailedAnalyticsBlockPath as TypographyProps}>
              <span style={webStyle.vendorDetailedAnalyticsHighlightedText}>
                <Link data-test-id="homeLink" color="inherit" onClick={() => this.vendorDetailedAnalyticsHandleNavigation("JobProfile")}>
                  Home
                </Link>
                <span>{" "}/</span>
                <Link data-test-id="analyticsLink" color="inherit" onClick={() => this.vendorDetailedAnalyticsHandleNavigation("VendorAnalytics")}>
                  Analytics
                </Link>
              </span>{" "}/ {this.state.vendorAnalyticsDetailedReportType === "project" ? "Project Analytics" : "Invoice Analytics"}
            </Typography>
          </Box>
          <Grid container spacing={3}>
            {this.vendorDetailedAnalyticsRenderGraphs()}
          </Grid>
        </Grid>
        <Footer />
      </Grid >
    </>
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme} >
        <Container style={webStyle.vendorDetailedAnalyticsParentDiv}>
          {
            this.state.isVendorDetailedAnalyticsSideBarOpen ? (
              <Grid container spacing={0}>
                <Grid xs={3} item style={webStyle.vendorDetailedAnalyticsSidebarParent}>
                  <SideBar
                    handleSideBar={this.vendorDetailedAnalyticsHandleSideBar}
                    navigate={this.props.navigation.navigate}
                    userData={{ age: 10 }}
                    activeMenuItem={"Analytics"} />
                </Grid>
                <Grid item xs={9} style={{ flexBasis: "77.75%", maxWidth: "77.75%" }}>{this.vendorDetailedAnalyticsRenderMainContent()}</Grid>
              </Grid>) :
              <Grid item xs={12}>{this.vendorDetailedAnalyticsRenderMainContent()}</Grid>
          }
        </Container>
        <CustomNotification navigation={(route: string) => this.props.navigation.navigate(route)} notification={this.state.vendorDetailedAnalyticsNotification} handleCloseNotification={this.handleVendorDetailedAnalyticsCloseNotification} />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff"
    }
  },
  typography: {
    h6: {
      fontWeight: 500
    },
    subtitle1: {
      margin: "20px 0px"
    }
  }
});

const webStyle = {
  vendorDetailedAnalyticsParentDiv: {
    padding: "0px",
    maxWidth: "none",
  },
  vendorDetailedAnalyticsSidebarParent: {
    minHeight: "100vh",
    maxWidth: "22.25%",
  },
  vendorDetailedAnalyticsChartInfo: {
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center",
  },
  vendorDetailedAnalyticsSlider: {
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center",
  },
  vendorDetailedAnalyticsCurrentPeriod: {
    borderRadius: "16px",
    padding: "5.9px 14px",
    fontWeight: 700,
    border: "1px solid #E3EAF4",
    display: "flex",
    margin: "0px 5px",
    justifyContent: "center",
    fontSize: "11px",
    color: "#17181D",
    alignItems: "center",
    fontFamily: "Urbanist",
    latterSpacing: '0.18px',
    lineHeight: "13px",
  },
  updateVendorDetailedAnalyticsCurrentPeriod: {
    height: "24.8px",
    width: "24.8px",
    cursor: "pointer",
    border: "1px solid #e3eaf4",
    justifyContent: "center",
    borderRadius: "16px",
    alignItems: "center",
    backgroundColor: "#ffffff",
    display: "flex",
  },
  vendorDetailedAnalyticsChartLineStyle: {
    borderBottom: "1px solid #e3eaf4",
    marginTop: "14px",
    color: "#e3eaf4",
  },
  vendorDetailedAnalyticsContentSetup: {
    // minHeight: "100vh",
    borderLeft: "1px solid #E3EAF4",
  },
  vendorDetailedAnalyticsMainContent: {
    width: "100%",
    boxSizing: "border-box",
    padding: "24px",
    minHeight: "100vh",
    backgroundColor: "#F8FAFE",
  },
  vendorDetailedAnalyticsHighlightedText: {
    fontFamily: 'Urbanist',
    color: "#F5C42C",
    textDecoration: 'underline',
    cursor: 'pointer',
    fontSize: "14px",
    fontWeight: 600,
  },
  vendorDeyailedAnalyticsActiveBlock: {
    lineHeight: "34px",
    letterSpacing: "1px",
    marginBottom: "8px",
    fontWeight: 700,
    fontSize: "28px",
  },
  vendorDetailedAnalyticsBlockPath: {
    color: "#6D89AF",
    letterSpacing: "1px",
    fontWeight: 500,
    marginBottom: "18px",
    fontSize: "14px",
  },
  vendorDetailedAnalyticsLengends: {
    justifyContent: "flex-start",
    alignItems: "center",
    overflowX: "auto",
    overflow: "hidden",
    display: "flex",
  },
  vendorDetailedAnalyticsLegendStyle: {
    justifyContent: "center",
    alignItems: "center",
    fontSize: "16px",
    display: "flex",
    color: "#17181d",
    fontWeight: 700,
    fontFamily: "Urbanist",
    lineHeight: "19px",
  },
  vendorDetailedAnalyticsLegendDot: {
    width: "10px",
    height: "10px",
    margin: "0 5px",
    borderRadius: "12px",
    backgroundColor: "#3EB489",
  },
  vendorDetailedAnalyticsButtonStyle: {
    borderRadius: "9px",
    width: "144px",
    alignItems: "center",
    backgroundColor: "#17181d",
    justifyContent: "center",
    textTransform: "none",
    display: "flex",
  },
  vendorDetailedAnalyticsButtontext: {
    color: "#ffffff",
    lineHeight: "12px",
    fontWeight: "600",
    fontFamily: "urbanist",
    fontSize: "10px",
  },
  vendorDetailedAnalyticsArrowStyle: {
    color: "#ffffff",
    height: "14px",
    width: "14px",
    lineHeight: "12px",
  },
};
// Customizable Area End
