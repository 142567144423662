import React from "react";

import {
  // Customizable Area Start
  Grid, Typography, withStyles
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import "./fullVisibility.css";
import BreadCrumController, { IBreadCrum, Props } from "./BreadCrumController";
export const configJSON = require("./config");

// Customizable Area End

export default class BreadCrum extends BreadCrumController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
        <Grid>
            <Grid item xs = {12} sm = {12} md = {12} lg = {12} xl = {12} className = "acc_breadcrum">
            <Breadcrumbs separator="/" aria-label="breadcrumb" className="bread-crum">
                {
                  this.props.breadcrumList && this.props.breadcrumList.map((item: IBreadCrum, index: number) => {
                    if(item.clickable){
                      return <Link data-test-id = "bread_crum_link" key={`${index}_breadcrumList1`} color="inherit" style={webStyle.homeTypo} onClick={() => this.handleNavigation(item.page)}>
                      {item.text}
                    </Link>
                    }
                    return <Typography key={`${index}_breadcrumList2`} className="non_clickable_text">{item.text}</Typography>
                  })
                }
              </Breadcrumbs>
            </Grid>
        </Grid>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  homeTypo: {
    color: '#f5c530',
    fontSize: '14px',
    fontWeight: 600,
    fontFamily: 'Urbanist',
    cursor: 'pointer',
    textDecoration: 'underline',
  }
}
// Customizable Area End