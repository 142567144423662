import React from "react";
import {
  Container,
  Grid,
  Box,
  Button,
  Typography,
  Card,
  CardContent,
  TypographyProps,
  Link,
  styled,
  CircularProgress

} from "@material-ui/core";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Rectangle,
  Tooltip,
  ResponsiveContainer
} from "recharts";
import SideBar from "../../../components/src/SideBar";
import Header from "../../../components/src/Header.web";
import Footer from "../../../components/src/Footer";
//@ts-ignore
 import { ReactComponent as Vendors } from "../assets/vendors.svg";
//@ts-ignore
 import { ReactComponent as Production } from "../assets/production.svg";
//@ts-ignore
import { ReactComponent as Projects } from "../assets/projects.svg";
//@ts-ignore
import { ReactComponent as Studios } from "../assets/studios.svg";
//@ts-ignore
 import { ReactComponent as Navigate } from "../assets/navigate.svg";
//@ts-ignore
 import { ReactComponent as Next } from "../assets/next.svg";
//@ts-ignore
 import { ReactComponent as Previous } from "../assets/previous.svg";
import DashboardController, {
  Props,
} from "./DashboardController.web";
import Loader from "../../../components/src/Loader.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#F5C42C",
      contrastText: "#fff"
    }
  },

  overrides: {
    MuiSelect: {
      select: {
        "&:focus": {
          border: "1px solid #F5C42C",
        }
      }
    }

  }
})
// Create navigation Block
//istanbul ignore next//
export const NavigationBlock = ({ ...props }) => {

  return (

    <Card style={webStyle.cardContentCover}>
      <CardContent style={webStyle.cardContent}>
        <div style={webStyle.navigationInfo}>
          <div style={webStyle.navigationIcon}>{props.blockLogo}</div>
          <span style={webStyle.itemCount as React.CSSProperties}>{props.count}</span>
        </div>
        <div style={webStyle.navigateTo}>
          <BlockTypography>{props.blockName}</BlockTypography>
          <div style={webStyle.forwardIcon} onClick={() => props.handleNavigation1("AllProductionHouses")} data-test-id="navigationvalue"><Navigate /></div>
          
        </div>
      </CardContent>
    </Card>
  )
}
export const NavigationBlock1 = ({ ...props }) => {
  return (
    <Card style={webStyle.cardContentCover}>
      <CardContent style={webStyle.cardContent}>
        <div style={webStyle.navigationInfo}>
          <div style={webStyle.navigationIcon}>{props.blockLogo}</div>
          <span style={webStyle.itemCount as React.CSSProperties}>{props.count}</span>
        </div>
        <div style={webStyle.navigateTo}>
          <BlockTypography>{props.blockName}</BlockTypography>
          <div style={webStyle.forwardIcon} onClick={() => props.handleNavigation1("FullTeamVisibility")} data-test-id="navigation-2"><Navigate /></div>
        </div>
      </CardContent>
    </Card>
  )
}
export const NavigationBlock2 = ({ ...props }) => {
  return (
    <Card style={webStyle.cardContentCover}>
      <CardContent style={webStyle.cardContent}>
        <div style={webStyle.navigationInfo}>
          <div style={webStyle.navigationIcon}>{props.blockLogo}</div>
          <span style={webStyle.itemCount as React.CSSProperties}>{props.count}</span>
        </div>
        <div style={webStyle.navigateTo}>
          <BlockTypography>{props.blockName}</BlockTypography>
          <div style={webStyle.forwardIcon} onClick={() => props.handleNavigation1("AllProjectView")} data-test-id="navigation-3"><Navigate /></div>
        </div>
      </CardContent>
    </Card>
  )
}
export const NavigationBlock3 = ({ ...props }) => {
  return (
    <Card style={webStyle.cardContentCover}>
      <CardContent style={webStyle.cardContent}>
        <div style={webStyle.navigationInfo}>
          <div style={webStyle.navigationIcon}>{props.blockLogo}</div>
          <span style={webStyle.itemCount as React.CSSProperties}>{props.count}</span>
        </div>
        <div style={webStyle.navigateTo}>
          <BlockTypography>{props.blockName}</BlockTypography>
          <div style={webStyle.forwardIcon} onClick={() => props.handleNavigation1("AllStudioView")} data-test-id="navigation-4"><Navigate /></div>
        </div>
      </CardContent>
    </Card>
  )
}
// Bar customization
//istanbul ignore next//
const CustomBar = ({ ...props }) => {
  const { x, y, height } = props;

  return (
    <Rectangle
      x={x}
      y={y}
      // y={y - props.padding}
      width={4}
      height={height}
      fill={props.color}
      radius={[30, 30, 0, 0]} // Set the border radius for the top corners
    />
  );
};
// Bar Legends
interface ChartsData {
  name: string;
  color: string;
}
//istanbul ignore next//
const Legends = ({ ...props }) => {
  return (
    <div className="hide-scrollbar" style={webStyle.lengends as React.CSSProperties}>{
      props.legends.map((legendInfo: ChartsData) => {
        return (
          <div style={webStyle.legendStyle as React.CSSProperties} key={legendInfo.color}>
            <div style={{ ...webStyle.legendDot, backgroundColor: `${legendInfo.color}` }}></div>
            {legendInfo.name}
          </div>
        )
      })}
    </div>
  )
}
// Bar Chart
//istanbul ignore next//
const Chart = ({ ...props }) => {
  return (
    <>
      <ResponsiveContainer width="100%" height={250}>
        <BarChart
          data={props.data}
          barGap={props.barGap}
          margin={{
            left: -25,
            top: 13
          }}
        >
          <CartesianGrid strokeDasharray="3" vertical={false} />
          <XAxis dataKey="name" tick={{ fontSize: 10 }} interval={0} />
          <YAxis tick={{ fontSize: 10 }} />
          <Tooltip />
          {
            props.bars.map((barData: ChartsData) => {
              return (
                <Bar dataKey={barData.name} barSize={4} shape={<CustomBar color={barData.color} padding={2} />} key={barData.color} />
              )
            })
          }
        </BarChart>
      </ResponsiveContainer>
      <Button style={webStyle.buttonStyle as React.CSSProperties} onClick={props.handleNavigation}>
        <Typography style={webStyle.buttontext as TypographyProps}>View Detailed Report</Typography>
        <ArrowForwardIcon style={webStyle.arrowStyle} />
      </Button>
    </>
  )
}

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
  }

  renderMainContent(sideBarStatus: boolean = this.state.isSideBarOpen) {
    return <>
      {/* <Loader loading={this.state.isLoading} /> */}
      <Grid style={webStyle.contentSetup}>
        < Header
          sideBarStatus={sideBarStatus}
          dropDownStatus={this.state.isDropdownOpen}
          handleSideBar={this.handleSideBar}
          handleDropdown={this.handleDropdown}
          navigate={this.props.navigation.navigate}
        />
        <Grid style={webStyle.mainContent as React.CSSProperties}>
          <Box sx={webStyle.blockInfo}>
            <Typography variant="h3" style={webStyle.activeBlock as TypographyProps}>Dashboard</Typography>
            <Typography variant="h6" style={webStyle.blockPath as TypographyProps}>
              <span style={webStyle.highlightedText}>
                <Link data-test-id="homeLink" color="inherit">
                  Home
                </Link>
              </span>
              {" "}/ Dashboard
            </Typography>


          </Box>
          {
          
              <>

                <Grid container spacing={2} style={{ borderBottom: "1px solid", borderColor: "#E3EAF4", paddingBottom: "24px" }}>

                  <Grid item lg={3} md={6} sm={12} xs={12} data-test-id="navigationvalue">
                    <NavigationBlock blockName={"Total Production Houses"} 
                     blockLogo={<Production fill={"#F5C42C"} />} 
                    count={this.state.all_production_houses_count} handleNavigation1={this.handleNavigation1} />
                  </Grid>
                  <Grid item lg={3} md={6} sm={12} xs={12} data-test-id="navigation-1">
                    <NavigationBlock1 blockName={"Total Vendors"}
                     blockLogo={<Vendors fill={"#F5C42C"} />}
                      count={this.state.vendor_count} handleNavigation1={this.handleNavigation1} />
                  </Grid>
                  <Grid item lg={3} md={6} sm={12} xs={12} data-test-id="navigation-2">
                    <NavigationBlock2 blockName={"Total Projects"}
                     blockLogo={<Projects fill={"#F5C42C"} />} 
                     count={this.state.projecct_count} handleNavigation1={this.handleNavigation1} />
                  </Grid>
                  <Grid item lg={3} md={6} sm={12} xs={12} data-test-id="navigation-3">
                    <NavigationBlock3 blockName={"Total Studios"} 
                     blockLogo={<Studios fill={"#F5C42C"} />} 
                    count={this.state.studio_count} handleNavigation1={this.handleNavigation1} />
                  </Grid>
                </Grid>
                <span style={webStyle.lineStyle}></span>
                <Box sx={webStyle.chartSectionHeading}>
                  <Typography style={webStyle.analytics as TypographyProps}>Analytics</Typography>
                  <Typography data-test-id="viewAllGraph" style={webStyle.viewAll as TypographyProps} onClick={() => this.handleNavigation("Analytics", "invoice")}>View All</Typography>
                </Box>
                <Grid container spacing={3}>
                  <Grid item lg={4} sm={12} xs={12}>
                    <Card style={webStyle.cardContentCover}>
                      <ParentCardContent>
                        <div style={webStyle.chartInfo}>
                          <Typography style={webStyle.chartName as TypographyProps}>Invoices</Typography>
                          <div style={webStyle.slider}>
                            <div style={webStyle.updateCurrentPeriod} data-test-id="Handle-Previous-Click-1" onClick={() => { this.handlePreviousClick("invoicesIndex") }}> 
                             <Previous />
                            </div>
                            <div style={webStyle.currentPeriod as React.CSSProperties}>{this.state.values[this.state.invoicesIndex]}</div>
                            <div style={webStyle.updateCurrentPeriod} data-test-id="Handle-Next-Click-1" onClick={() => { this.handleNextClick("invoicesIndex") }}>
                               <Next />
                              </div>
                          </div>
                        </div>
                        <hr style={webStyle.chartLineStyle} />
                        <Legends legends={[
                          {
                            name: "Rejected",
                            color: "#FF5C5C"
                          },
                          {
                            name: "Pending",
                            color: "#F7B500"
                          },
                          {
                            name: "Approved",
                            color: "#3389FF"
                          },
                          {
                            name: "Completed",
                            color: "#42A200"
                          }
                        ]} />
                        <div>
                          <Chart
                            data={this.state.dashboardGraphInvoicesData}
                            barGap={"4%"}
                            handleNavigation={() => this.handleNavigation("Analytics", "invoice")}
                            data-test-id="invoicesGraph"
                            bars={[
                              { name: "pending", color: "#F7B500" },
                              { name: "rejected", color: "#FF5C5C" },
                              { name: "approved", color: "#3389FF" },
                              { name: "completed", color: "#42A200" },
                            ]}
                          />
                        </div>
                      </ParentCardContent>
                    </Card>
                  </Grid>
                  <Grid lg={4} sm={12} xs={12} item>
                    <Card
                      style={webStyle.cardContentCover}
                    >
                      <ParentCardContent>
                        <div
                          style={webStyle.chartInfo}
                        >
                          <Typography style={webStyle.chartName as TypographyProps}>Projects</Typography>
                          <div style={webStyle.slider}>
                            <div style={webStyle.updateCurrentPeriod} data-test-id="Handle-Previous-Click-2" onClick={() => { this.handlePreviousClick("projectsIndex") }}>
                               <Previous />
                               </div>
                            <div style={webStyle.currentPeriod as React.CSSProperties}>{this.state.values[this.state.projectsIndex]}</div>
                            <div style={webStyle.updateCurrentPeriod} data-test-id="Handle-Next-Click-2" onClick={() => { this.handleNextClick("projectsIndex") }}>
                              <Next />
                              </div>
                          </div>
                        </div>
                        <hr style={webStyle.chartLineStyle} />
                        <Legends legends={[
                          {
                            name: "Ongoing",
                            color: "#F7B500"
                          },
                          {
                            name: "Upcoming",
                            color: "#3389FF"
                          },
                          {
                            name: "Completed",
                            color: "#42A200"
                          },
                        ]} />
                        <div>
                          <Chart
                            data={this.state.dashboardGraphProjectsData}
                            barGap={"8%"}
                            bars={[
                              {
                                name: "Ongoing",
                                color: "#F7B500"
                              },
                              {
                                name: "Upcoming",
                                color: "#3389FF"
                              },
                              {
                                name: "Completed",
                                color: "#42A200"
                              },
                            ]}
                            handleNavigation={() => this.handleNavigation("Analytics", "project")}
                            data-test-id="projectsGraph"
                          />
                        </div>
                      </ParentCardContent>
                    </Card>
                  </Grid>
                  <Grid item lg={4} sm={12} xs={12}>
                    <Card style={webStyle.cardContentCover}>
                      <ParentCardContent>
                        <div style={webStyle.chartInfo}>
                          <Typography style={{ ...webStyle.chartName, letterSpacing: "0.00938em" } as React.CSSProperties}>Daily Active Users</Typography>
                          <div style={webStyle.slider}>
                            <div style={webStyle.updateCurrentPeriod as React.CSSProperties} data-test-id="Handle-Previous-Click-3" onClick={() => { this.handlePreviousClick("dailyActiveUsersIndex") }}> 
                            <Previous />
                            </div>
                            <div style={webStyle.currentPeriod as React.CSSProperties}>{this.state.values[this.state.dailyActiveUsersIndex]}</div>
                            <div style={webStyle.updateCurrentPeriod} data-test-id="Handle-Next-Click-3" onClick={() => { this.handleNextClick("dailyActiveUsersIndex") }}>
                              <Next />
                              </div>
                          </div>
                        </div>
                        <hr style={webStyle.chartLineStyle} />
                        <Legends legends={[
                          {
                            name: "All Users",
                            color: "#F5C42C"
                          },
                          {
                            name: "Active Users",
                            color: "#3389FF"
                          },
                          {
                            name: "Inactive Users",
                            color: "#FF5C5C"
                          },
                        ]} />
                        <div>
                          <Chart
                            data={this.state.dashboardGraphUsersData}
                            barGap={"8%"}
                            bars={[
                              {
                                name: "all users",
                                color: "#F5C42C"
                              },
                              {
                                name: "active",
                                color: "#3389FF"
                              },
                              {
                                name: "inactive",
                                color: "#FF5C5C"
                              },
                            ]}
                            handleNavigation={() => this.handleNavigation("Analytics", "allUser")}
                            data-test-id="allUsersGraph"
                          />
                        </div>
                      </ParentCardContent>
                    </Card>
                  </Grid>
                </Grid>
              </>
          }
        </Grid>
        <Footer />
      </Grid >
    </>
  }

  render() {
    return (
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <Container style={webStyle.parentDiv}>
          {
            this.state.isSideBarOpen ?
              <Grid container spacing={0}>
                <Grid item xs={3} style={webStyle.sidebarParent}>
                  <SideBar
                    navigate={this.props.navigation.navigate}
                    userData={{ age: 25 }}
                    activeMenuItem={"Dashboard"}
                    handleSideBar={this.handleSideBar} />
                </Grid>
                <Grid item xs={9}>{this.renderMainContent()}</Grid>
              </Grid> :
              <Grid item xs={12}>{this.renderMainContent()}</Grid>
          }
        </Container>
      </ThemeProvider>
    );
  }
}
const ParentCardContent = styled(CardContent)({
  '& .recharts-text.recharts-cartesian-axis-tick-value': {
    fill: '#6D89AF',
    fontWeight: 700,
    fontSize: 10,
  }
})
const BlockTypography = styled(Typography)({
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "15px",
  color: "17181D",
  '@media(max-width: 1439px)': {
    fontSize: '13px'
  }
})
const webStyle = {
  parentDiv: {
    padding: "0px",
    maxWidth: "none"
  },
  sidebarParent: {
    minHeight: "100vh",
    maxWidth: "23%"
  },
  contentSetup: {
    // minHeight: "100vh",
    borderLeft: "1px solid #E3EAF4",
  },
  mainContent: {
    width: "100%",
    minHeight: "100vh",
    backgroundColor: "#F8FAFE",
    boxSizing: "border-box",
    padding: "24px"
  },
  highlightedText: {
    color: "#F5C42C",
    fontFamily: 'Urbanist',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  blockInfo: {
    // padding: "15px",navigationCard
  },
  activeBlock: {
    lineHeight: "34px",
    fontSize: "28px",
    fontWeight: 700,
    letterSpacing: "1px",
    marginBottom: "8px"
  },
  blockPath: {
    fontSize: "14px",
    letterSpacing: "1px",
    color: "#6D89AF",
    marginBottom: "18px",
    fontWeight: 500
  },
  navigationInfo: {
    display: "flex",
    alignItems: "center",
  },
  navigationIcon: {
    height: "45px",
    width: "45px",
    backgroundColor: "rgb(245, 242, 231)",
    borderRadius: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "12px",
  },
  itemCount: {
    fontWeight: "700",
    fontSize: "32px"
  },
  navigateTo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "10px",
  },
  blockName: {
    fontWeight: 700,
    fontSize: "13px",
    lineHeight: "15px",
    color: "17181D"
  },
  cardContent: {
    padding: "16px 12px 16px 14px",
  },
  cardContentCover: {
    borderRadius: "14px",
    border: "1px solid #E1E6EC",
  },
  forwardIcon: {
    height: "26px",
    width: "26px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#E1E6EC",
    cursor: "pointer",
  },
  lineStyle: {
    borderBottom: "1px solid #E3EAF4",
  },
  chartLineStyle: {
    borderBottom: "1px solid #D8D8D8",
    marginTop: "14px"
  },
  chartSectionHeading: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    marginBottom: "10px",
    paddingTop: "22px",
  },
  analytics: {
    fontWeight: "600",
    fontSize: "20px",
    letterSpacing: "1px",
  },
  viewAll: {
    fontWeight: "600",
    fontSize: "14px",
    textDecoration: "underline",
    color: "#3389FF",
    cursor: "pointer"
  },

  chartInfo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  slider: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  chartName: {
    fontWeight: 900,
    fontSize: "16px",
    letterSpacing: "1px",
    lineHeight: "19px",
    fontFamily: 'Urbanist',
    color: "#17181D"
  },
  currentPeriod: {
    border: "1px solid #E3EAF4",
    borderRadius: "20px",
    padding: "5px 10px",
    margin: "0px 5px",
    fontWeight: 700,
    fontSize: "11px",
    letterSpacing: "1px",
    width: "38px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    lineHeight: "13px",
    color: "#17181D",
    fontFamily: "urbanist",
    latterSpacing: '0.18px'

  },
  updateCurrentPeriod: {
    border: "1px solid #E3EAF4",
    height: "25px",
    width: "25px",
    borderRadius: "50%",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  lengends: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    overflowX: "auto",
    overflow: "hidden"
  },
  legendStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "0.5px solid #E3EAF4",
    backgroundColor: "#F8FAFE",
    borderRadius: "15px",
    padding: "2px 4px",
    margin: "1px 1px 0 0",
    fontSize: "9px",
    whiteSpace: "nowrap",
    fontWeight: 900,
    fontFamily: "Urbanist",
    lineHeight: "12px"
  },
  legendDot: {
    width: "7px",
    height: "7px",
    borderRadius: "50%",
    backgroundColor: "#3EB489",
    margin: "0 3px",
  },
  buttonStyle: {
    backgroundColor: "#a5c5f14f",
    borderRadius: "9px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "none",
    gap: "5px",

  },
  arrowStyle: {
    color: "#3389FF",
    height: "14px",
    width: "14px",
    lineHeight: "12px"
  },
  buttontext: {
    color: "#3389FF",
    fontSize: "10px",
    lineHeight: "12px",
    fontWeight: "500",
    fontFamily: "urbanist",
  }
}
