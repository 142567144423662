import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;

  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  isSideBarOpen: any;
  isDropdownOpen: boolean;
  isSideBarOpen1: boolean;
  TabValue: number;
  myJobData: any;
  appliedJobData: any;
  TabDetail: number;
  Taballjob: number;
  Tabmyjob: number;
  Tabappliedjob: number;
  allJobData:any;
  isLoading: boolean;
  allProjectDetail:any;
  allJobDetailRatingandReview: any;
  count:number;
  count1:number;
  count2:number;
  currentpage2:number;
  currentpage: number;
  currentpage1:number;
  searchValue: string;
  open:boolean;
  rateValue:number;
  comments:string;
  applyvalue:boolean;
  applyData:any;
  experience:any;
  department:any;
  RoleType:any;
  city:any;
  experiencevalue:number;
  cityvalue:string;
  departmentvalue:string;
  roletypevalue:string;
  reviewbleId:number;
  reviewbleType:string;
  id:number;
  vendorjobid:any;
  open1:boolean;
  invoiceselect:string;
  selectinvoicetype:string;
  selectinvoicename:string;
  selectinvoicedetail:string;
  selectproject:any;
  selectinvoiceno:string;
  selectinvoiceamount:string;
  image:string;
  modelType:string
  isModelOpen:boolean;
  raiseinvoicedetail:any;
  allinvoicecount:any;
  ratingvalidation:string;
  reviewvalidation:string;
  PopupMessage:boolean;
  messageshow:string;
  PopupMessage1:boolean;
  messageshow1:string;
  
  
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class JobProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
    getPrjectDetailApiCalledId:string=""
    deleteprojectdatacallid:string=""
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),

      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),

    ];

    this.state = {
      isSideBarOpen: true,
      isSideBarOpen1: true,
      isDropdownOpen: false,
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      TabValue: 0,
      myJobData: [],
      appliedJobData: [],
      TabDetail: 0,
      Taballjob: 0,
      Tabmyjob: 1,
      Tabappliedjob: 2,
      allJobData:[],
      isLoading: false,
     allProjectDetail:[],
     allJobDetailRatingandReview: [],
     count:0,
     count1:0,
     count2:0,
     currentpage2:1,
     currentpage: 1,
     currentpage1:1,
     searchValue: "",
     open:false,
     rateValue:0,
     comments:"",
     applyvalue:true,
     applyData:[],
     experience:[],
     department:[],
     RoleType:[],
     city:[],
     experiencevalue:0,
     cityvalue:'',
     departmentvalue:'',
     roletypevalue:'',
     reviewbleId:0,
     reviewbleType:'',
     id:0,
     vendorjobid:getStorageData("vendorid"),
     open1:false,
     invoiceselect:'',
     selectinvoicetype:'',
     selectinvoicename:'',
     selectinvoicedetail:'',
     selectproject:"",
     selectinvoiceno:"",
     selectinvoiceamount:"",
     image:"",
     modelType:'',
    isModelOpen: false,
    raiseinvoicedetail:[],
    allinvoicecount:0,
    ratingvalidation:'',
    reviewvalidation:'',
    PopupMessage:false,
    PopupMessage1:false,
    messageshow:'',
    messageshow1:''
    

    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async receive(from: string, message: Message) {

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let msgvaluejob = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From:" + this.state.txtSavedValue + "To:" + msgvaluejob
      );

      this.setState({ txtSavedValue: msgvaluejob });
    }

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestvaluejob = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))

      const responseValuejob = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
      if(apiRequestvaluejob===this.getPrjectDetailApiCalledId)
      {
        console.log("response.....",responseValuejob)
        this.setState({allProjectDetail:responseValuejob.data})
      }
      else if(apiRequestvaluejob===this.deleteprojectdatacallid)
      {
        this.props.navigation.navigate("Projects")
      }

    }
  

  }
  
 
 




 
 
 


 
 






  // Customizable Area Start

  async componentDidMount() {

this.getProjectDetailsPH()
     let isSideBarOpen=await getStorageData("sidebarvalue");
     this.setState({isSideBarOpen:JSON.parse(isSideBarOpen)})
     
      
    window.scrollTo(0, 0);
  }

  




 handleSideBar =  () => {
    this.setState((prevState) => ({
      isSideBarOpen: !prevState.isSideBarOpen
    }), async() => {
      localStorage.setItem("sidebarvalue",this.state.isSideBarOpen)
      
    });
  };



  handleSideBar1 = () => {

    this.setState({
      isSideBarOpen1: !(this.state.isSideBarOpen1)
    });
  };
  handleDropdown = () => {
    this.setState({
      isDropdownOpen: !this.state.isDropdownOpen
    });
  };

 
 



  handleNavigation = (page: string) => {
    this.props.navigation.navigate(page)
  };

  navigateToPHDetailPage = (id: any) => {
    setStorageData("phID", id);
    this.handleNavigation("VendorJobPHDetails");
  }

  handleChangeTab = (event: any, newValue: any) => {
    this.setState({ TabValue: newValue })
  };


  

  
  navigateToallJobDetail = () => {
    
  
    
    this.props.navigation.navigate("ProjectDetailPH");

  
    
    
  };
  
 
  jobRoleNavigation=async(projectname:string,status:string)=>{
    await setStorageData("namePH",projectname)
    await setStorageData("statusPH",status)
    this.props.navigation.navigate("ProjectDetailJobRoleandRequest")
  }

  EmployeeeNavigation=()=>{
    this.props.navigation.navigate("AllEmployeeListPH")
  }
  handleArrowRightClick = (projectID: string, projectName: string,status:string) => {
    setStorageData("projectDetails", JSON.stringify({ id: projectID, name: projectName }));
    setStorageData("statusPH",status)
    this.props.navigation.navigate("AllEmployeeListPH");
  }
  handleArrowRightClickDepartment=async(id:string,status:string,name:string)=>{
     await setStorageData("projectPHID",id)
     await setStorageData("statusPH",status)
     await setStorageData("namePH",name)
    this.props.navigation.navigate("AllDepartmentListPH");
  }
 
  handleOpen = () =>{
    
  this.setState({rateValue:0,comments:'',ratingvalidation:'',reviewvalidation:''})
  
     this.setState({open:true})
    
  }
  handleOpen1 = (e:any,id:any,name:any) =>{
    e.stopPropagation();
    setStorageData("projectid",id);
    setStorageData("projectname",name)
     this.setState({open1:true})
     
  }
  handleViewinvoice=async(e:any,name:any)=>{
    e.stopPropagation();
    if(name){
      window.location.replace(`/InvoiceList?projectname=${name}`);
    }else{
      window.location.replace('/InvoiceList')  
    }
   

  }
  handleClose = () => {
    this.setState({open:false})
   
  };
  handleClose1 = (e:any) => {
    e.stopPropagation();
  
    this.setState({open1:false})
  
  };
  handleClosemodel = () => {
    this.setState({ open1: false })

};
 

     

     
  getProjectDetailsPH = async () => {

    let token = await getStorageData("token");
    let id = await getStorageData("projectidPH");
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPrjectDetailApiCalledId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProjectDetailApiEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
  };   
        
       
  EditProject=async(id:any)=>{
    console.log("idstudio............",id)
    await setStorageData("editproject",id)
    await setStorageData("projecttype","editproject")
    this.props.navigation.navigate("AddNewProject")

    
    
   }
 
  deleteProjectPH = async(id:any) => {
    let token = await getStorageData("token");
    
    const header = {
        "Content-Type": configJSON.exampleApiContentType,
        "token": token
    };
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteprojectdatacallid = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.deleteprojectApiEndPoint+`/${id}`
    );
   
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPIMethod
  );

    runEngine.sendMessage(requestMessage.id, requestMessage);
      }

      navigateToallinvoiceRequest = async(e:any,id:any) => {
        e.stopPropagation();
      
        await setStorageData("project_id",id)
        this.props.navigation.navigate("InvoiceBilling");
    
      
        
        
       };

 
  // Customizable Area End
}
