import React from "react";
import {
    Container,
    Grid,
    Box,
    Typography,
    TypographyProps,
    Link, Avatar, Button, Divider, LinearProgress, IconButton
} from "@material-ui/core";
import SideBar from "../../../components/src/SideBar";
import Modal from '@material-ui/core/Modal';
import Header from "../../../components/src/Header.web";
import GetAppIcon from '@material-ui/icons/GetApp';
import WorkOutlineTwoToneIcon from '@material-ui/icons/WorkOutlineTwoTone';
import Footer from "../../../components/src/Footer";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
//@ts-ignore
// @ts-ignore
// @ts-ignore
import Loader from "../../../components/src/Loader.web";

import SettingsPhoneIcon from '@material-ui/icons/SettingsPhone';
import PlaceOutlinedIcon from '@material-ui/icons/PlaceOutlined';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import StarOutlinedIcon from '@material-ui/icons/StarOutlined';
import { StyledBadge } from "../../fullteamvisibility/src/CardComponent.web";
import Rating from "@material-ui/lab/Rating";

import Alert, { AlertProps } from "@material-ui/lab/Alert";
import CustomNotification from "../../../components/src/CustomNotification";
import { dummyImage } from "../../../components/src/assets";
import RateNowCard from "../../../components/src/RateNowCard";
import RequestProfileJobRoleProjectPHController ,{ Props, configJSON} from "./RequestProfileJobRoleProjectPHController";
import StudioDetailRatingModel from "../../user-profile-basic/src/StudioDetailRatingModel.web";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const images = require("./assets");

const themeStyle = createTheme({
    palette: {
        primary: {
            main: "#F5C42C",
            contrastText: "#fff"
        }
    },
    overrides: {
        MuiSelect: {
            select: {
                "&:focus": {
                    border: "1px solid #F5C42C",
                }
            }
        }
    }
});

const CustomAlert = React.forwardRef<HTMLDivElement, AlertProps>(function CustomAlert(
    props,
    ref
) {
    return <Alert elevation={6} ref={ref} variant="filled" {...props} />;
});
export default class RequestProfileDetailPH extends RequestProfileJobRoleProjectPHController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    OverviewDetailrequestProfilePH = () => {
        return <>
            {this.state.studioDetailTabValue === 0 ? <Box style={{ background: "#ffffff", width: "calc(100% - 186px)", padding: "13px 24px 16px 24px" }}>
                <Box>
                    <Typography style={webStyle.vendorManageProfileAllReviewsTitleRequestProfile as React.CSSProperties}>Official Details & Docs ----------------------------------------------------------------------------------</Typography>
                    <Grid container style={{ borderRadius: "12px", border: "1px solid #e3eaf4", padding: 14, display: "flex", flexDirection: "row", marginTop: "8px", width: "100%", height: "123px" }}>
                        <Grid container style={{ flexWrap: "nowrap" }}>
                            <Grid item sm={6} md={6} lg={6} >
                                <Typography style={{ fontWeight: 500, lineHeight: "15px", letterSpacing: "0.2px", color: "#6d89af", fontFamily: "Urbanist", fontSize: "12px" }}>Aadhaar Number</Typography>
                                <Typography style={{ marginTop: "3px", color: "#18272a", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 600, lineHeight: "17px", letterSpacing: "0.2px" }}>{this.state.vendorManageProfileData.account_detail.data.attributes?.aadhar_card_number ? `${this.state.vendorManageProfileData.account_detail.data.attributes.aadhar_card_number.toString().slice(0, 4)} xxxx xxxx ${this.state.vendorManageProfileData.account_detail.data.attributes.aadhar_card_number.toString().slice(-4)}` : null}</Typography>
                            </Grid>
                            <Grid item sm={6} md={6} lg={6}>
                                <Typography style={{ fontWeight: 500, lineHeight: "15px", letterSpacing: "0.2px", color: "#6d89af", fontFamily: "Urbanist", fontSize: "12px", }}>GST Number</Typography>
                                <Typography style={{ fontSize: "14px", fontWeight: 600, lineHeight: "17px", letterSpacing: "0.2px", marginTop: "3px", color: "#18272a", fontFamily: "Urbanist", }}>{this.state.vendorManageProfileData.account_detail.data.attributes?.gst_number}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Divider />
                        </Grid>
                        <Grid container style={{ flexWrap: "nowrap" }}>
                            <Grid item sm={6} md={6} lg={6} >
                                <Typography style={{ fontSize: "12px", fontWeight: 500, lineHeight: "15px", letterSpacing: "0.2px", color: "#6d89af", fontFamily: "Urbanist", }}>Pan Number</Typography>
                                <Typography style={{ fontSize: "14px", fontWeight: 600, lineHeight: "17px", letterSpacing: "0.2px", marginTop: "3px", color: "#18272a", fontFamily: "Urbanist", }}>{this.state.vendorManageProfileData.account_detail.data.attributes?.pan_card_number ? `${this.state.vendorManageProfileData.account_detail.data.attributes.pan_card_number.toString().slice(0, 2)}xxxxxx${this.state.vendorManageProfileData.account_detail.data.attributes.pan_card_number.toString().slice(-2)}` : null}</Typography>
                            </Grid>
                            <Grid item sm={6} md={6} lg={6}>
                                <Typography style={{ fontWeight: 500, lineHeight: "15px", letterSpacing: "0.2px", color: "#6d89af", fontFamily: "Urbanist", fontSize: "12px", }}>Tan Number</Typography>
                                <Typography style={{ marginTop: "3px", color: "#18272a", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 600, lineHeight: "17px", letterSpacing: "0.2px" }}>{this.state.vendorManageProfileData.account_detail.data.attributes?.tan_number ? `${this.state.vendorManageProfileData.account_detail.data.attributes.tan_number.toString().slice(0, 4)}xxxx${this.state.vendorManageProfileData.account_detail.data.attributes.tan_number.toString().slice(-3)}` : null}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
                <Box style={{ marginTop: "16px" }}>
                    <Typography style={webStyle.vendorManageProfileAllReviewsTitleRequestProfile as React.CSSProperties}>Bio ----------------------------------------------------------------------------------</Typography>
                    <div style={{ marginTop: "8px", width: "100%", minHeight: "72px", borderRadius: "12px", border: "1px solid #e3eaf4", padding: 12, boxSizing: "border-box", color: "#17181d", fontFamily: "Urbanist", fontSize: "12px", fontWeight: 600, lineHeight: "16px" }}>
                        {this.state.vendorManageProfileData.profile_bio.data ? this.renderReadMoreLess() : null}
                    </div>
                </Box>
                <Box style={{ marginTop: "16px" }}>
                    <Typography style={webStyle.vendorManageProfileAllReviewsTitleRequestProfile as React.CSSProperties}>Certificates & Documents ----------------------------------------------------------------------------------</Typography>
                    <Grid container style={{ borderRadius: "12px", border: "1px solid #e3eaf4", padding: 12, boxSizing: "border-box", color: "#17181d", fontFamily: "Urbanist", fontSize: "12px", fontWeight: 500, lineHeight: "16px", marginTop: "8px", width: "100%", minHeight: "88px", }}>
                        <Grid container spacing={2} className="cert_box">
                            {this.state.vendorManageProfileData.documents.length ?
                                this.state.vendorManageProfileData.documents.map((document: any, index: number) => {
                                    return <Grid item key={`${index + document.id}_documentlist`} xs={6} sm={6} md={3} lg={3} xl={3}>
                                        <Box className="vendor_manage_profile_cert_box">
                                            <Box className="cert_inner_tool">
                                                <Box style={{ borderRadius: "8px", padding: "9.9% 13.6%", backgroundColor: "rgba(245,196,44,0.09)", }}>
                                                    <img style={{ height: "21px", width: "14.61px", } as React.CSSProperties} src={images.certificateIcon} alt="" />
                                                </Box>
                                                <IconButton data-test-id={`pdf_download_${document.id}`} onClick={() => this.vendorManageProfileHandleDownloadPDF(document.path_url, document.filename)}  size="medium" className="download_icon" >
                                                    <GetAppIcon className="download_icon_color"  fontSize="small" />
                                                </IconButton>
                                            </Box>
                                            <Box className="project_name">
                                                <Typography noWrap className="file_name">{document.filename}</Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                }) : null
                            }
                        </Grid>
                    </Grid>
                </Box>

            </Box> : null}
        </>
    }
    GellaryrequestProfilePH = () => {
        return <>
            {this.state.studioDetailTabValue === 1 ? <Box style={{ background: "#ffffff", width: "100%", padding: "15px", height: "594px" }}>
                <Box style={{ display: "flex", flexDirection: "row", gap: "13px", flexWrap: "wrap", overflowY: "auto", maxHeight: "594px" }}>

                    {this.state.vendorManageProfileData.gallary_photos.length ? this.state.vendorManageProfileData.gallary_photos.map((item: any) => {
                        return <img key={item.id + "gallery"}
                            className="studioGalleryImages"
                            src={item.path_url || "https://www.littlethings.info/wp-content/uploads/2014/04/dummy-image-green-e1398449160839.jpg"}
                        />
                    }) : null}
                </Box>
            </Box> : null}
        </>
    }
    ProjectrequestProfilePH = () => {
        return <>
            {this.state.studioDetailTabValue === 2 ? <Box style={{ background: "#ffffff", width: "calc(100% - 186px)", padding: "13px 24px 16px 24px", overflowY: "auto", height: "648px" }}>
                <Typography style={{ ...webStyle.vendorManageProfileAllReviewsTitleRequestProfile as React.CSSProperties, marginBottom: "3px" }}>{this.state.vendorManageProfileProjectsData.length ? `${this.state.vendorManageProfileProjectsData.length} Successful Projects` : "No Data Found"} ----------------------------------------------------------------------------------</Typography>
                {this.state.vendorManageProfileProjectsData.length ? this.state.vendorManageProfileProjectsData.map((item: any) => {
                    return <Grid key={item.id + "manageprofile_projects"} container style={webStyle.projectsCard as React.CSSProperties}>
                        <Grid item style={webStyle.imageDiv}>
                            <Avatar
                                style={webStyle.imagesection}
                                src={item.attributes.image || dummyImage}
                            >
                            </Avatar>
                            <Grid style={webStyle.imageandnameproductionhouse}>
                                <Typography style={webStyle.Projectname}>{item.attributes.project_name}</Typography>
                                <Typography style={webStyle.jobrole}>Job Role: {item.attributes.job_role_name}</Typography>
                                <Typography style={webStyle.organizeDiv}>Organized By: {item.attributes.production_house}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                            <Box style={webStyle.vendorManageProfileProjectStatusBtnProfilerequest}>
                                <span style={webStyle.vendorManageProfileProjectStatusTextRequestProfile} >{this.covertToPascalCase(item.attributes.status)}</span>
                            </Box>
                            <Box style={webStyle.vendorManageProfileProjectDateTextcreated as React.CSSProperties}>
                                {item.attributes.created_at}
                            </Box>
                        </Grid>
                    </Grid>
                }) : null}
            </Box> : null}
        </>
    }
    RequestProfileProjectDetail() {
        let projectDetail;
        if (this.state.Status === "ongoing") {
            projectDetail = "Ongoing Projects";
        } else if (this.state.Status === "upcoming") {
            projectDetail = "Upcoming Projects";
        } else {
            projectDetail = "All Projects";
        }
    
        return projectDetail;
    }
    renderRequestProfleMainContent = (sideBarStatus: boolean = this.state.isSideBarOpen) => {
        return <>
            <Loader loading={this.state.isLoading}></Loader>
            <Grid style={webStyle.wholePageContentSetupRequestProfile} >
                <Header handleSideBar={this.handleSideBar}
                    dropDownStatus={this.state.isDropdownOpen}
                    navigate={this.props.navigation.navigate}
                    sideBarStatus={sideBarStatus}
                    handleDropdown={this.handleDropdown}
                />
                <Grid style={webStyle.dynamicMainContentRequestProfile as React.CSSProperties}>
                    <Box sx={webStyle.blockInfoTitleRequestProfile}>
                    <ArrowBackIcon data-test-id="backArrow" onClick={() => this.handleNavigation("ProjectDetailJobRoleandRequest")} style={{
                                        background: "white",cursor:"pointer", border: "1px solid grey", borderRadius: "50%", marginRight: "10px", padding: "2px"
                                    }} />
                        <Box>
                            <Typography variant="h3" style={webStyle.activeBlockMainTitleRequestProfile as TypographyProps}>Request - Profile</Typography>
                            <Typography variant="h6" style={webStyle.blockE2EPathRequestProfile as TypographyProps}>
                                <span style={webStyle.highlightedTitleextRequestProfile}>
                                    <Link data-test-id="homeLink" color="inherit" onClick={() => this.handleNavigation("Projects")}>
                                        Home /
                                    </Link>
                                </span>
                                <span style={webStyle.highlightedTitleextRequestProfile}>
                                    <Link data-test-id="studiosBookingList" color="inherit"  onClick={() => this.handleNavigation("ProjectDetailPH")}>
                                    {this.RequestProfileProjectDetail()}/{this.state.ProjectName}
                                    </Link>
                                </span>
                                <span style={webStyle.highlightedTitleextRequestProfile}>
                                    <Link data-test-id="studiosBookingList" color="inherit"  onClick={() => this.handleNavigation("ProjectDetailJobRoleandRequest")}>
                                    / All Job Role & Requests /
                                    </Link>
                                </span>
                                {this.state.vendorManageProfileData.account_detail.data.attributes?.full_name}
                            </Typography>
                        </Box>
                    </Box>
                    <Grid style={{ border: "1px solid #e3eaf4", background: "#ffffff", marginTop: "24px", borderRadius: "12px", }} item sm={12} xl={12} xs={12} md={12} lg={12}>
                        <Box style={{ alignItems: "center", display: "flex", justifyContent: "space-between" }}>
                            <Box style={{ display: "flex", margin: "16px 0 16px 16px" }}>
                                <StyledBadge
                                    overlap="circular"
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right"
                                    }}
                                    style={{ width: 75, height: 75 }}
                                    badgeContent={
                                        <img style={{ height: 24, width: 24, }} src={images.checkedIcon} />
                                    }
                                >
                                    <Avatar
                                        style={{
                                            width: 69,
                                            height: 69,
                                            border: "3px solid #f5c42c",

                                            marginRight: 1
                                        }}
                                        src={this.state.vendorManageProfileData.profile_image || "https://www.littlethings.info/wp-content/uploads/2014/04/dummy-image-green-e1398449160839.jpg"}
                                    >

                                    </Avatar>
                                </StyledBadge>
                                <Box style={webStyle.VendorManageProfileLeftBox as React.CSSProperties}>
                                    <Typography
                                        style={{
                                            fontWeight: 600,
                                            textAlign: "center",
                                            letterSpacing: "0.225px",
                                            lineHeight: "22px",
                                            color: "#17181d",
                                            fontFamily: "Urbanist",
                                            fontSize: "18px",

                                        }}
                                    >
                                        {this.state.vendorManageProfileData.account_detail.data.attributes?.full_name}
                                    </Typography>
                                    <Typography style={webStyle.vendorManageProfilePhNameRequestProfile}>
                                        <WorkOutlineTwoToneIcon style={{
                                            fontSize: "small",
                                            width: "22px",
                                            height: "22px",
                                            marginRight: 3,
                                        }} />
                                        {this.state.vendorManageProfileData.role}
                                    </Typography>
                                    <Typography style={webStyle.vendorManageProfileAddressTextRequestProfile}>
                                        <PlaceOutlinedIcon style={{
                                            fontSize: "small",
                                            width: "22px",
                                            height: "22px",
                                            marginRight: 2,
                                        }} />
                                        {this.state.vendorManageProfileData.city}, {this.state.vendorManageProfileData.state}
                                    </Typography>
                                </Box>
                                <Box style={webStyle.VendorManageProfileRightBoxRequestProfile as React.CSSProperties}>
                                    <Typography style={webStyle.vendorManageProfileRightSideBoxTextRequestProfile}>
                                        <SettingsPhoneIcon style={{
                                            fontSize: "small",
                                            width: "20.09px",
                                            height: "20px",
                                            marginRight: 10.4,
                                            color: "#f5c42c",
                                        }} />
                                        +{this.state.vendorManageProfileData.account_detail.data.attributes?.country_code}-{this.state.vendorManageProfileData.account_detail.data.attributes?.phone_number}
                                    </Typography>
                                    <Typography style={{ ...webStyle.vendorManageProfileRightSideBoxTextRequestProfile, margin: "9px 0px" }}>
                                        <img style={{ marginRight: 10.4 }} src={`${images.forwardMailIcon}`} />
                                        {this.state.vendorManageProfileData.account_detail.data.attributes?.email}
                                    </Typography>
                                    <Typography style={webStyle.vendorManageProfileRightSideBoxTextRequestProfile}>
                                        <img style={{ marginRight: 10.4 }} src={`${images.bulbIcon}`} />
                                        {this.state.vendorManageProfileData.experience} yrs
                                    </Typography>
                                </Box>
                            </Box>
                            <Box>
                                
                                      <Button
                                     
                                        style={webStyle.bookStudioBtnRejectRequestProfile}><span style={webStyle.bookStudioBtnTextRejectRequestProfile as React.CSSProperties} onClick={this.deletefromJobInviteVendorRequestprofile}>Remove from Job</span></Button>
                                <Button
                                   
                                    style={webStyle.bookStudioBtn}><span style={webStyle.bookStudioBtnTextAcceptRequestProfile as React.CSSProperties}>View Invoices</span></Button>
                                
                                   
                               
                            </Box>
                        </Box>
                    </Grid>
                    <Grid container style={{ width: "100%", marginTop: "24px", alignItems: "flex-start" }}>
                        <Grid item style={{ marginRight: "21px", width: "calc(67.3% - 21px)", maxHeight: "630px", minHeight: "406px", borderRadius: "12px", border: "1px solid #e3eaf4" }}>
                            <Box style={{ display: "flex" }}>
                                <Box style={webStyle.lefttabsMainBox}>
                                    <Box data-test-id="studioDetailOverviewTab" onClick={() => this.handleTabValueChange(0)} style={{ borderBottom: "1px solid #e3eaf4", cursor: "pointer", width: "138px" }}>
                                        <Typography style={{ ...webStyle.leftSideTabTextRequestProfile, background: this.state.studioDetailTabValue === 0 ? "#ffffff" : "#f8fafe", color: this.state.studioDetailTabValue === 0 ? "#f5c42c" : "#17181d" }}>Overview</Typography>
                                    </Box>
                                    <Box data-test-id="studioDetailGalleryTab" onClick={() => this.handleTabValueChange(1)} style={{ borderBottom: "1px solid #e3eaf4", cursor: "pointer", width: "138px" }}>
                                        <Typography style={{ ...webStyle.leftSideTabTextRequestProfile, background: this.state.studioDetailTabValue === 1 ? "#ffffff" : "#f8fafe", color: this.state.studioDetailTabValue === 1 ? "#f5c42c" : "#17181d" }}>Gallery</Typography>
                                    </Box>
                                    <Box data-test-id="studioDetailProjectTab" onClick={() => this.handleTabValueChange(2)} style={{ borderBottom: "1px solid #e3eaf4", cursor: "pointer", width: "138px" }}>
                                        <Typography style={{ ...webStyle.leftSideTabTextRequestProfile, background: this.state.studioDetailTabValue === 2 ? "#ffffff" : "#f8fafe", color: this.state.studioDetailTabValue === 2 ? "#f5c42c" : "#17181d" }}>Projects</Typography>
                                    </Box>
                                </Box>
                                {
                                    this.OverviewDetailrequestProfilePH()
                                }

                                {
                                    this.GellaryrequestProfilePH()
                                }
                                {
                                    this.ProjectrequestProfilePH()
                                }

                            </Box>
                        </Grid>
                        <Grid item style={{ width: "32.7%", maxHeight: "630px", minHeight: "400px", borderRadius: "12px", border: "1px solid #e3eaf4", background: "#ffffff" }}>
                            <Box style={webStyle.ratingStartMainBoxRequestProfile}>
                                <Box style={webStyle.ratingStartBoxRequestProfile} >
                                    <Box style={{ display: "flex", alignItems: "center" }}>
                                        <Box className="star_icon rating_size">
                                            <Typography className="total_rating_typo">
                                                {this.state.studioDetailReviewData.average_rating || 0}
                                            </Typography>
                                            <Typography className="divide_num">
                                                {configJSON.divide}
                                            </Typography>
                                        </Box>
                                        <Box style={webStyle.ratingreadonlyDiv} className="rating_text">
                                            <Divider orientation="vertical" flexItem style={webStyle.DividerDivrating as React.CSSProperties} />
                                        </Box>
                                        <Rating
                                            name="customized-empty"
                                            value={this.state.studioDetailReviewData.average_rating || 0}

                                            size="small"
                                            precision={0.5}
                                            readOnly
                                            emptyIcon={<StarBorderIcon htmlColor="#ffb400" style={webStyle.StarborderDiv as React.CSSProperties} />}
                                        />
                                    </Box>
                                    <div>
                                        <Grid style={{
                                            color: '#f5c42c',
                                            fontFamily: 'Urbanist',
                                            fontSize: '12px',
                                            fontWeight: 600,
                                            letterSpacing: '0.18461539px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            cursor: 'pointer'
                                        }}
                                            onClick={this.handleOpen}>
                                            Rate Now
                                            <ArrowForwardIcon style={{
                                                fontSize: "small",
                                                width: "14px",
                                                height: "14px",
                                                marginLeft: 1,
                                            }}
                                            />

                                        </Grid>

                                        <Modal
                                            open={this.state.open}
                                            onClose={this.handleClose} 
                                            aria-describedby="simple-modal-description"
                                            aria-labelledby="simple-modal-title"
                                        >
                                            <div style={{ 
                                                top: '50%',
                                                position: 'absolute',
                                                left: '50%',
                                                backgroundColor: 'white',
                                                borderRadius: '20px',
                                                transform: 'translate(-50%, -50%)',
                                                height: '474px',
                                                width: '375px'

                                            }}>
                                                
                                                <RateNowCard reviewvalidation={this.state.reviewvalidation} ratingvalidation={this.state.ratingvalidation} name={this.state.vendorManageProfileData.account_detail.data.attributes?.full_name} image={this.state.vendorManageProfileData.profile_image} ratevalue={this.state.rateValue} rateHandle={this.rateHandle} comments={this.state.comments} commentsHandle={this.commentsHandle} addReviewApiCall={this.addReviewApiCallrequestProfile} />
                                            </div>
                                        </Modal>
                                    </div>

                                </Box>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="user_detail_grid_container_rating">
                                    <Typography className="rating_helper">
                                        {this.state.studioDetailReviewData.review_rating_count}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="user_detail_grid_container_rating background_color">
                                    <Divider style={webStyle.DividerDiv} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={webStyle.ratingandreview}>
                                    {
                                        Object.entries(this.state.studioDetailReviewData.rating_star || { 5: 0, 4: 0, 3: 0, 2: 0, 1: 0 }).reverse().map((item: any, index: number) => {
                                            return <Box style={webStyle.reviewdata as React.CSSProperties} key={`${item[0]}_ratingvalue`}>
                                                <LinearProgress variant="determinate" value={item[1] | 0} className="progress_bar_rating" style={{ backgroundColor: '#e3eaf4', borderRadius: '30px', marginBottom: "5px" }} />
                                                <Typography style={{ height: "12px", color: "#17181d", fontFamily: "Urbanist", fontSize: "10px", fontWeight: 600, letterSpacing: "0.2px" }}>{item[0]}{" "}Star</Typography>
                                            </Box>
                                        })
                                    }
                                </Grid>
                            </Box>
                            <Box style={{ display: "flex", alignItems: "center", margin: "0px 12px" }}>
                                <Typography style={webStyle.allReviewsTitleRequestProfile as React.CSSProperties}>All Reviews --------------------------------------------------</Typography>
                            </Box>
                            <Grid style={webStyle.reviewDiv  as React.CSSProperties} item xs={12} sm={12} md={12} lg={12} xl={12}>
                                {
                                    this.state.studioDetailReviewData.reviews?.data?.length ? (this.state.studioDetailReviewData.reviews.data.map((item: any, index: number) => {
                                        return <Box key={`${index}_review_box_allProjectList`} style={{ padding: '12px' }} className="review_box">
                                            <Grid container spacing={3}>
                                                <Box sx={webStyle.projectcardParentBoxRequestProfile} className="review_card_box">
                                                    <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                                                        <Avatar
                                                            style={{ borderRadius: "12px", width: 36, height: 36 }}
                                                            src={item.attributes.account.image || "https://www.littlethings.info/wp-content/uploads/2014/04/dummy-image-green-e1398449160839.jpg"}
                                                        >
                                                        </Avatar>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                                        <Typography style={webStyle.fullnametext}>{item.attributes.account.full_name}</Typography>
                                                        <Typography style={webStyle.createdDate}>{item.attributes.created_at}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={2} lg={2} xl={2} className="project_date">
                                                        <Box style={webStyle.starsRequestProfile}>
                                                            <StarOutlinedIcon className="start_color" />
                                                            <Typography style={webStyle.star_colorRequestProfile}>&nbsp;{item.attributes.given_star}</Typography>
                                                        </Box>
                                                    </Grid>
                                                </Box>
                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={webStyle.commentsParent}>
                                                    <Typography style={{ color: "#17181d", fontFamily: "Urbanist", fontSize: "12px", fontWeight: 500, lineHeight: "16px", letterSpacing: "0.2px" }}>{item.attributes.comment}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    })) : null
                                }

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Footer />
        </>
    }
    renderReadMoreLess = () => {
        return <>
            {
                this.state.vendorManageProfileData.profile_bio.data.attributes.about_business.length > 100
                    ? <>
                        <span>
                            {this.state.vendorManageProfileData.profile_bio.data.attributes.about_business.substr(0, this.state.bioReadMoreLess ? 176 : this.state.vendorManageProfileData.profile_bio.data.attributes.about_business.length)}...
                        </span>
                        <span style={webStyle.readMoreTextRequestProfile} onClick={this.handleBioRead}>
                            {this.state.bioReadMoreLess ? " Read more" : " Read less"}
                        </span>
                    </> :
                    this.state.vendorManageProfileData.profile_bio.data.attributes.about_business
            }
        </>
    }
    // Customizable Area End

    render() {
        return (
            <ThemeProvider theme={themeStyle}>
                <Container style={webStyle.parentContainerRequestProfile}>
                    {
                        this.state.isSideBarOpen ? (
                            <Grid spacing={0} container>
                                <Grid xs={3} item style={webStyle.sidebarParentGrid} >
                                    <SideBar
                                        activeMenuItem={"Projects"}
                                        navigate={this.props.navigation.navigate}
                                        handleSideBar={this.handleSideBar}
                                        userData={{ name: "StudiosBookingList" }}

                                    />
                                </Grid>
                                <Grid style={{ display: "flex", flexDirection: "column", flexBasis: "77.75%", maxWidth: "77.75%" }} item xs={9} >
                                    {this.renderRequestProfleMainContent()}
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid item style={{ display: "flex", flexDirection: "column" }} xs={12}>
                                {this.renderRequestProfleMainContent()}
                            </Grid>
                        )
                    }

                </Container>
                {this.state.isModelOpen && this.state.modelType === "ratingModel" ? <StudioDetailRatingModel navigation={this.props.navigation} id={this.state.studioBookingId} open={this.state.isModelOpen} handleClose={this.handleModelClose} studioName={this.state.vendorManageProfileData.account_detail.data.attributes?.full_name} studioImage={this.state.vendorManageProfileData.profile_image} handleGiveRatingAPICall={this.getRatingListing} /> : null}
                <CustomNotification navigation={(route: string) => this.props.navigation.navigate(route)} notification={this.state.notification} handleCloseNotification={this.handleCloseNotification} />
            </ThemeProvider>
        );
    }
}

const webStyle = {
    lefttabsMainBox: {
        width: "138px",
        height: "100%",
        background: "#f8fafe",
    },
    VendorManageProfileLeftBox: {
        height: "79px",
        borderRight: "1px solid #e3eaf4",
        display: "flex",
        paddingRight: "24px",
        alignItems: "start",
        flexDirection: "column",
        marginLeft: "10px",
    },
    VendorManageProfileRightBoxRequestProfile: {
        display: "flex",
        paddingLeft: "24.5px",
        alignItems: "start",
        flexDirection: "column",
    },
    vendorManageProfileAddressTextRequestProfile: {
        color: "#6d89af",
        lineHeight: "17px",
        fontSize: "14px",
        fontFamily: "Urbanist",
        letterSpacing: "0.23333333px",
        margin: "4px 0px",
        fontWeight: 500,
        display: "flex",
        alignItems: "center",
    },
    vendorManageProfileRightSideBoxTextRequestProfile: {
        color: "#17181d",
        lineHeight: "14px",
        fontSize: "14px",
        fontFamily: "Urbanist",
        letterSpacing: "0.23333333px",
        fontWeight: 600,
        display: "flex",
        alignItems: "center",
    },
    vendorManageProfilePhNameRequestProfile: {
        color: "#6d89af",
        lineHeight: "17px",
        fontSize: "14px",
        fontFamily: "Urbanist",
        letterSpacing: "0.23333333px",
        marginTop: "4px",
        fontWeight: 500,
        display: "flex",
        alignItems: "center",
    },
    vendorManageProfileProjectStatusTextRequestProfile: {
        color: "#ffffff",
        lineHeight: "12px",
        fontSize: "10px",
        fontWeight: 600,
        fontFamily: "Urbanist",
    },
    vendorManageProfileProjectStatusBtnProfilerequest: {
        height: "24px",
        width: "74px",
        background: "linear-gradient(225deg, #50b609 0%, #42a200 100%)",
        borderRadius: "12px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    vendorManageProfileProjectDateTextcreated: {
        color: "#6d89af",
        lineHeight: "15px",
        fontSize: "12px",
        fontFamily: "Urbanist",
        letterSpacing: "0.24px",
        textAlign: "end",
        fontWeight: 500,
    },
    projectsCard: {
        border: "1px solid #e3eaf4",
        height: "84px",
        width: "100%",
        borderRadius: "12px",
        padding: "10px",
        background: "#ffffff",
        display: "flex",
        marginBottom: "17px",
        justifyContent: "space-between",
        flexDirection: "row",
    },
    imageDiv: {
        display: "flex"
    },
    imagesection: {
        borderRadius: "12px", width: 64, height: 64
    },
    imageandnameproductionhouse: { marginLeft: "12px" },
    Projectname: { lineHeight: "19px", color: "#113260", fontFamily: "Urbanist", fontSize: "16px", fontWeight: 600, letterSpacing: "0.2px" },
    jobrole: { marginTop: "3px", lineHeight: "19px", color: "#6d89af", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 500, letterSpacing: "0.23333333px" },
    organizeDiv: { marginTop: "3px", lineHeight: "19px", color: "#6d89af", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 500, letterSpacing: "0.23333333px" },
    DividerDiv: {
        marginTop: "12px", marginBottom: "15px"
    },
  reviewdata: 
  { width: "calc(20% - 8px)",
   display: "flex",
    flexDirection: "column" }
    , ratingandreview: { margin: "0px 12px 16px", display: "flex", justifyContent: "space-between" }
    ,
    reviewDiv:{ margin: "0px 12px", maxHeight: "478px", overflowY: "auto", msOverflowStyle: "none", scrollbarWidth: "none" },
   
   commentsParent:{ padding: "0px 12px 12px", marginTop: '-2%' },
   readMoreTextRequestProfile: {
        color: "#f5c42c",
        lineHeight: "15px",
        fontSize: "12px",
        fontFamily: "Urbanist",
        textDecoration: "underline",
        fontWeight: 600,
        cursor: "pointer",
        letterSpacing: "0.18461539px",
    },
    createdDate:{ marginTop: "1px", lineHeight: "12px", color: "#6d89af", fontFamily: "Urbanist", fontSize: "10px", fontWeight: 500, letterSpacing: "0.2px" },

    leftSideTabTextRequestProfile: {
        lineHeight: "17px",
        color: "#17181d",
        fontFamily: "Urbanist",
        fontSize: "14px",
        fontWeight: 600,
        padding: "27px 16px 21px 16px",
    },
    fullnametext:{ lineHeight: "19px", color: "#17181d", fontFamily: "Urbanist", fontSize: "16px", fontWeight: 600, letterSpacing: "0.2px" },
    vendorManageProfileAllReviewsTitleRequestProfile: {
        fontFamily: "Urbanist",
        color: "#6d89af",
        fontWeight: 600,
        fontSize: "12px",
        whiteSpace: "nowrap",
        lineHeight: "19px",
        overflow: "hidden",
        textOverflow: "clip",
    },
    allReviewsTitleRequestProfile: {
        color: "#6d89af",
        fontFamily: "Urbanist",
        fontSize: "12px",
        fontWeight: 600,
        lineHeight: "19px",
        whiteSpace: "nowrap",
        textOverflow: "clip",
        overflow: "hidden",
    },
    starsRequestProfile: {
        padding: "0px 8px",
        background: "linear-gradient(225deg, #58A2FF 0%, #1777E2 100%)",
        borderRadius: "12px",
        display: "flex",
        alignItems: "center",
        justifyContent: 'space-evenly'
    },
    star_colorRequestProfile: {
        color: 'white'
    },
    projectcardParentBoxRequestProfile: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "space-between",
        padding: '2%'
    },
   
    
    rightSideBoxText: {
        lineHeight: "14px",
        color: "#17181d",
        fontFamily: "Urbanist",
        fontSize: "14px",
        fontWeight: 600,
        letterSpacing: "0.23333333px",
        alignItems: "center",
        display: "flex",
    },
    studioDetailsRightBox: {
        paddingLeft: "24.5px",
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
    },
   
   
    bookStudioBtnRejectRequestProfile: {
        width: "159px",
        height: "48px",
        borderRadius: "14px",
        background: "#ffffff",
        marginRight: "24px",
        border: '1px solid #FF5C5C'
    },
    bookStudioBtn: {
        width: "159px",
        height: "48px",
        borderRadius: "14px",
        background: "#17181d",
        marginRight: "24px",
    },
    bookStudioBtnTextRejectRequestProfile: {
        color: "#FF5C5C",
        fontFamily: "Urbanist",
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: "24px",
        letterSpacing: "0.4px",
        textTransform: "none",
    },
    bookStudioBtnTextAcceptRequestProfile: {
        fontFamily: "Urbanist",
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: "24px",
        textTransform: "none",

        color: "#ffffff",

    },

    ratingStartMainBoxRequestProfile: {
        width: "100%",
    },
    ratingStartBoxRequestProfile: {
        paddingLeft: "12px",
        paddingRight: "12px",
        paddingTop: "12px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    
    parentContainerRequestProfile: {
        maxWidth: "none",
        padding: "0px",
    },
    ratingreadonlyDiv:{ marginLeft: "12px", marginRight: "10px" },
    sidebarParentGrid: {
        maxWidth: "22.25%",
        minHeight: "100vh",
    },
    StarborderDiv:{ width: "18px", height: "18px" },
    DividerDivrating:{ height: 23 },
    wholePageContentSetupRequestProfile: {
        borderLeft: "1px solid #E3EAF4",
        // minHeight: "90vh",
        flex: 1,
    },
    dynamicMainContentRequestProfile: {
        minHeight: "100vh",
        width: "100%",
        boxSizing: "border-box",
        padding: "24px",
        backgroundColor: "#F8FAFE",
    },
    blockInfoTitleRequestProfile: {
        //justifyContent: "space-between",
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
    },
    highlightedTitleextRequestProfile: {
        fontFamily: "Urbanist",
        color: "#F5C42C",
        textDecoration: "underline",
        cursor: "pointer",
    },
    activeBlockMainTitleRequestProfile: {
        lineHeight: "34px",
        fontSize: "28px",
        color: "#17181D",
        fontWeight: "700",
        letterSpacing: "1px",
    },
    blockE2EPathRequestProfile: {
        fontSize: "14px",
        letterSpacing: "1px",
        fontWeight: 500,
        color: "#6D89AF",
        lineHeight: "17px",
        paddingTop: "8px",
    }
};
