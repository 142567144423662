import React from "react";
import { Card, Box, CardMedia, CardContent, Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { JobCardImage, LocationIconjob, rupeeIconJobBlack, dotsIcon, headingicon, leaveproject, jobyrs, arrowinvoice ,dummyImage } from "./assets";
import Raiseinvoicefunctionality from "./Raiseinvoicefunctionality";
import Modal from '@material-ui/core/Modal';
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreHoriz";
interface MyProps {
    data: any;
    handleNavigationRequestProfile:any;
    deletefromJobInviteVendor:any;

}



const useStyles = makeStyles({
    cardDivWithOpenSideBar: () => ({
        //height: 240,
        borderRadius: 14,
        padding: 8,
        border: "1px solid #E1E6EC",
        position: 'relative',
        '& .MuiCardContent-root': {
            padding: 0
        },
        "@media(max-width: 500px)": {

        },
        "@media(max-width: 1300px)": {
        
        },
    }),
    cardDivWithClosedSideBAr: () => ({
        width: 346,
        borderRadius: 14,
        padding: 8,
        border: "1px solid #E1E6EC",
        position: 'relative',
        '& .MuiCardContent-root': {
            padding: 0
        },
        "@media(max-width: 500px)": {

        },
    }),
    parentBoxValue: () => ({
        display: "flex",
        justifyContent: 'space-between',



    }),

 
    parentBox:() =>({

        width:'100%',
        
        

    }),

    ParentSubheading1: () => ({
        display: "flex",
        gap: "5px",

    })
    ,
    headingDept: () => ({
        display: 'flex',
        justifyContent: 'space-between',
    })
    ,
    positionDiv: () => ({
        display: 'flex',
        gap: '5px',
        borderRadius: '15px',
        backgroundColor: '#f8fafe',
        borderColor: '#979797',
        height: 31,
        width: 101,
        alignItems: 'center'
    }),
    iconposition: () => ({

    }),
    DeptDiv: () => ({
        fontSize: '13px',
        fontFamily: 'urbanist',
        color: '#17181D',
        fontWeight: 500,
        lineHeight: '16px',

    }),
    Dept: () => ({}),
    mainDiv: () => ({
        paddingTop: '4px',
        lineHeight: '19px',
        fontSize: '14px',
        fontWeight: 600,
        fontFamily: 'urbanist',
        color: '#17181D',
        display: 'flex',
        gap: '4px'
    }),
    YrsDiv: () => ({
        display: 'flex',
        gap: '4px',
        paddingTop: '6px',
        '& .MuiBox-root': {
            fontSize: '13px',
            fontWeight: '600px',

            color: '#6d89af',
        }
    }),
    Yrstext: () => ({
        paddingTop: '6px',
        fontSize: '13px',
        fontWeight: 600,
        color: '#6d89af',
        fontFamily: 'urbanist',
        '& .MuiBox-root': {
            fontSize: '13px',
            fontWeight: '600px',
        }
    }),
    jobiconyear: () => ({
        height: '16px',
        width: '16px'
    }),
    doticon: () => ({
        height: '24px',
        width: '24px'
    }),
    rupeeicon: () => ({
        paddingTop: '2px',
        height: '16px',
        width: '16px'
    }),
    headingMain:()=>({}),
    rupeesDiv: () => ({
        display: 'flex',
        gap: '4px',
        borderBottom: '1px solid #e3eaf4',
        paddingBottom: '11px',
        '& .MuiBox-root': {
            display: 'flex',
            gap: '4px',
            fontSize: '14px',
            color: '#6d89af',
            fontWeight: 400,
            fontFamily: 'Helvetica',
            paddingTop: '5px',

        }

    }),
    jobcardimage: () => ({
       
        height:'54px',
        width:'54px',
        borderRadius: '10px'

    }),
    footerDiv: () => ({
        display: "flex"
    }),
    addressDiv: () => ({
        paddingTop: '13px',
        paddingLeft: "6px",
    }),
    address1: () => ({
        fontSize: '14px',
        fontFamily: 'urbanist',
        fontWeight: 600,
        color: '#17181D',
    }),
    address2: () => ({
        display: 'flex',
        gap: '4px',
        paddingTop: "3px",
    }),
    ParentSubheading2: () => ({
        fontSize: '11px',
        fontFamily: 'urbanist',
        fontWeight: 500,
        color: '#6d89af',
        marginTop: '14px',
        display:'flex',
        height:'26px',
        width:'26px',
        alignItems:'center',
        justifyContent:'center',
        backgroundColor:'#E1E6EC',
        borderRadius:'18px',
        cursor:'pointer'



    }),
    locationDiv: () => ({
        fontSize: '12px',
        fontWeight: 600,
        fontFamily: 'urbanist',
        color: '#6d89af',


    }),
    ParentBottomDiv: () => ({
        display: 'flex',
        justifyContent: 'space-between'
    }),
    Div2: () => ({
        paddingTop: '12.5px'
    }),
    rupeetext: () => ({
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '17px',
        fontFamily: 'Helvetica'
    }),
    button3: {
        cursor: "pointer",
        color: "black",
        backgroundColor: "#ffffff",
        textTransform: "none",
        width: "152px",
        borderRadius: "14px",
        //position: "absolute",
        bottom: "16px",
        height: "38px",
        fontWeight: 600,
        fontSize: "12px",
        paddingTop: "10px",
        paddingBottom: "14px",
        marginBottom: "5px",
        fontFamily: "urbanist",
        border: '1px solid #17181d'
    },
    submitButton: {
        cursor: "pointer",
        color: "#ffffff",
        backgroundColor: "#17181D",
        textTransform: "none",
        width: "152px",
        border: "none",
        borderRadius: "14px",
        //position: "absolute",
        bottom: "16px",
        height: "38px",
        fontWeight: 600,
        fontSize: "12px",
        paddingTop: "10px",
        paddingBottom: "14px",
        marginBottom: "5px",
        fontFamily: "urbanist",
    },
    humbergerparent:{
        display:'flex',
        paddingLeft:'12px',
        alignItems:'center',
        gap:'10px',
      },
      humbergerParentIcon:{
       
      },
      humbergericon:{
        height:'16px',
        width:'16px',
      },
      humbergerLabel:{
        color: '#17181d',
  fontFamily: 'Urbanist',
  fontSize: '14px',
  fontWeight: 600,

      }

});

export default function MyJobCard(props: MyProps) {

    const { data ,handleNavigationRequestProfile,deletefromJobInviteVendor} = props
    const classes = useStyles();
  
    
    return (
        <Box className={classes.parentBox}>
            <Card className={classes.cardDivWithOpenSideBar}>

                <Box  style={{
                    display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid',
                    paddingBottom: '10px',
                    borderColor: '#e3eaf4'
                }}>
                    <Box className={classes.ParentSubheading1}>
                        <Box style={{ paddingTop: '10px',}}>
                            <img src={data?.attributes?.profile_image|| dummyImage} alt="" className={classes.jobcardimage} />
                        </Box>
                        <Box className={classes.addressDiv}>
                            <Box style={{fontFamily:'urbanist',fontSize:'12px'}}>
                                Dept | {data?.attributes?.department}
                            </Box>
                            <Box className={classes.address1}>
                            {data?.attributes?.account?.full_name}
                            </Box>
                            <Box className={classes.address2}>
                                <Box>
                                    <img src={jobyrs} alt="" className={classes.jobiconyear} />
                                </Box>
                                <Box className={classes.locationDiv}>
                               {/* { `${data?.attributes?.city}, ${data?.attributes?.state}`} */}
                               {data?.attributes?.job_role?.data?.attributes?.role}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={classes.ParentSubheading2} onClick={()=>handleNavigationRequestProfile(data?.id,data?.attributes?.job_role_id,data?.attributes?.account?.profile_id)}>
                    <img src={arrowinvoice} alt="" style={{width:'10px',height:'10px'}}/>
                    </Box>
                </Box>
                {/* <Box className={classes.ParentBottomDiv}>
                    <Box style={{ paddingTop: '12.5px' }} >
                        <Box className={classes.DeptDiv}>
                            Dept | {data?.attributes?.project_department_name}
                        </Box>
                        <Box className={classes.headingMain}>
                            <Box className={classes.mainDiv}>
                                <Box>
                                    <img src={headingicon} alt="" className={classes.rupeeicon} />
                                </Box>
                                {data?.attributes?.role}
                            </Box>
                        </Box>
                    </Box>
                    <Box className={classes.Div2} >
                        <Box className={classes.DeptDiv}>
                            Amount of Role
                        </Box>
                        <Box className={classes.mainDiv}>
                            <Box>
                                <img src={rupeeIconJobBlack} alt="" className={classes.rupeeicon} />
                            </Box>
                            <Box className={classes.rupeetext}>₹{data?.attributes?.budget_of_role}</Box>
                        </Box>
                    </Box>
                </Box> */}

                <Box  style={{ display: 'flex', justifyContent:'space-between',gap:'5px', paddingTop: '13px' }}>
                   
                    
                    <Box style={{paddingLeft:'24px',display:'flex',justifyContent:'center',alignItems:'center',fontSize:'12px',fontWeight:500,color:'#6d89af'}} onClick={()=>deletefromJobInviteVendor(data.id)}>
                    Remove From Job
                    </Box>
                            

                    <button 
                        className={classes.submitButton}
                        

                    >
                        View Invoices
                    </button>
                </Box>
            </Card>
        </Box>
    );
}
