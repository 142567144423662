import React from "react";
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { invoiceCard,arrowForward,pathIcon, workIcon, InvoiceProfile, dummyImage} from "./assets";
interface MyProps {
  data: any;
  labels:any;
}

const useStyles = makeStyles(
    {   
        profileWrapper: {
            position: 'relative',
        },
        profileWrapperBox: {
            padding: '12px 14px',
        },
        profileBg: {
            width: '100%',
            position: 'absolute',
            height: '140px',
        },
        profileTop: {
            position: 'relative',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            paddingBottom: 6,
        },
        profileCard: {
            display: "flex",
            alignItems: "center",
            gap: 5,
        },
        profileImg: {
            height: 42,
            width: 42,
            borderRadius: 12,
            "& img": {
                height: '100%',
                width: '100%',
                borderRadius: 12,
            }
        },
        profileContent: {
            "& h6": {
                fontSize: '16px'
            },
            "& p": {
                fontSize: '12px'
            }
        },
        approvedLink: {
            '& p': {
                fontSize: '11px',
                fontWeight: 600,
                color: '#3389ff',
            }
        },
        rejectedLink: {
            '& p': {
                fontSize: '11px',
                fontWeight: 600,
                color: '#FF5C5C',
            }
        },
        clearedLink: {
            '& p': {
                fontSize: '11px',
                fontWeight: 600,
                color: '#42A200',
            }
        },
        holdLink: {
            '& p': {
                fontSize: '11px',
                fontWeight: 600,
                color: '#F7B500',
            }
        },
        invoiceHead: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingBottom: '6px',
            "& p": {
                color: '#6d89af',
                fontSize: 12,
                fontWeight: 600,
                lineHeight: '19px'
            }
        },
        postDetails: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            position: 'relative',
            gap: '5px',
            paddingBottom: 14,
            '& p': {
                color: '#6d89af',
                fontSize: '11px',
                fontWeight: 500,
            },
            '& img': {
                height: 16,
            }
        },
        profileBottom: {
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderTop: '1px dashed #E1E6EC',
            padding: '12px 0',
            '& h6': {
                color: '#17181d',
                fontSize: 14,
                fontWeight: 400,
            }
        },
        arrowForward: {
            height: '26px',
            width: '26px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#E1E6EC',
            borderRadius: '50%',
            cursor: 'pointer',
        },
        midPayButton: {
            position: "absolute",
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center',
            gap: 4,
            borderRadius: 12,
            backgroundColor: '#58A2FF',
            background: 'linear-gradient(225deg, #58a2ff 0%, #1777e2 100%)',
            left: '50%',
            top: '50%',
            transform: 'translateX(-50%)',
            color: '#fff',
            padding: '5px 11px',
            zIndex: 1,
            '& p': {
             fontSize: 10,
             fontWeight: 'bold',
            }
        },
        midStandardButton: {
            position: "absolute",
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center',
            gap: 4,
            borderRadius: 12,
            backgroundColor: '#F4F1FF',
            left: '50%',
            top: '50%',
            transform: 'translateX(-50%)',
            color: '#8765FF',
            padding: '5px 11px',
            zIndex: 1,
            '& p': {
             fontSize: 10,
             fontWeight: 'bold',
            }
        },
        midCashButton: {
            position: "absolute",
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center',
            gap: 4,
            borderRadius: 12,
            backgroundColor: '#EFFFE3',
            left: '50%',
            top: '50%',
            transform: 'translateX(-50%)',
            color: '#42A200',
            padding: '5px 11px',
            zIndex: 1,
            '& p': {
             fontSize: 10,
             fontWeight: 'bold',
            }
        },
    }
 );

export default function InvoiceRequestDashboard(props: MyProps) {
  const classes = useStyles();
  const { data } = props
  const formatTypeOfInvoice = (type_of_invoice:any) => {
    const formattedString = type_of_invoice.replace(/_/g, ' ')
      .toLowerCase()
      .replace(/(?:^|\s)\S/g, (char :any) => char.toUpperCase());
  
    return formattedString;
  };
  const formatStatus=(str:any)=>{
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return (
     <>
                <Box className={classes.invoiceHead}>
                </Box>
                <Box className={classes.profileWrapper}>
                    <img src={invoiceCard} alt="Invoice" className={classes.profileBg} />
                    <Box className={classes.profileWrapperBox}>
                        <Box>
                            <Box className={classes.profileTop}>
                                <Box className={classes.profileCard}>
                                    <Box className={classes.profileImg}>
                                        <img src={data?.attributes?.account?.image || dummyImage}/>
                                    </Box>
                                    <Box className={classes.profileContent}>
                                        <Typography variant="h6">{data?.attributes?.invoice_name}</Typography>
                                        <Typography>Dept | {data?.attributes?.project_department?.name}</Typography>
                                    </Box>
                                </Box>      
                                <Box className={data?.attributes?.status === 'rejected' ? classes.rejectedLink :
                                                (data?.attributes?.status === 'holded' || data?.attributes?.status === 'pending')  ? classes.holdLink :
                                                data?.attributes?.status === 'cleared' ? classes.clearedLink : classes.approvedLink }>
                                    <Typography>{formatStatus(data?.attributes?.status)}</Typography>
                                </Box>          
                            </Box>
                            <Box className={classes.postDetails}>
                                <img src={workIcon} alt="Work"/>
                                <Typography>{data?.attributes?.role}</Typography>
                            </Box>
                            {(data?.attributes?.type_of_invoice === "cash_invoice" || data?.attributes?.type_of_invoice === "standard_pay") ? (
                            <Box className={data?.attributes?.type_of_invoice ==="cash_invoice" ? classes.midCashButton : classes.midStandardButton}>
                            <Typography>{formatTypeOfInvoice(data?.attributes?.type_of_invoice)}</Typography>
                            </Box> 
                            ) : (
                             <Box className={classes.midPayButton}>
                             <img src={pathIcon} alt="Path"/>
                             <Typography>{formatTypeOfInvoice(data?.attributes?.type_of_invoice)}</Typography>
                         </Box> 
                            )}
                        </Box>
                        <Box className={classes.profileBottom}>
                            <Typography variant="h6">
                                ₹{data?.attributes?.total_amount}
                            </Typography>
                            <Box className={classes.arrowForward}>
                                <img src={arrowForward} alt="Arrow"/>
                            </Box>
                        </Box>
                    </Box>
                </Box>
     </>
  );
}
