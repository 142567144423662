import {withStyles , Button , InputLabel} from '@material-ui/core';
import { CustomTextFieldInput } from './CustomTextFieldInput';
export let emailInputHeightPass = typeof window !== 'undefined' && window.innerWidth > 1440 ? "150px" : '52px';
export const CustomButton = withStyles({
    root: {
      "& .MuiButton-label": {
        justifyContent: "center"
      },
      fontFamily: 'Urbanist',
      fontWeight: 700,
      lineHeight: '3rem',
      color: '#FFFFFF',
      width : '100%',
      textTransform: 'none',
      boxShadow: 'none',
      borderRadius: '14px',
      cursor: 'hand',
      "&.MuiButton-contained": {
        backgroundColor: 'black',
        color: '#FFFFFF',
        '& :hover': {
          background: 'black'
        }
      },
      "&.MuiButton-outlined": {
        "& : hover": {
          backgroundColor: 'black'
        }
      },
      "& : hover": {
        boxShadow: 'none',
      },
    }
  })(Button);

  export const WelcomInputLabel = withStyles({
    root: {
      fontFamily: 'Urbanist',
      lineHeight: '21px',
      letterSpacing: '1.1076px',
    }
  })(InputLabel);
  export const SignInInputLabel = withStyles({
    root: {
      fontFamily: 'Urbanist',
      color: '#6d89af',
    "@media screen and (min-width: 1441px)":{
      lineHeight : '17px',
      fontSize : "14px",
      width:"330px",
      textAlign:"start"
   },
   "@media (min-width >=1024px) and (max-width < 1440px)":{
      lineHeight : '21px',
      fontSize : '50px'
   },
   "@media (min-width >=768px) and (max-width < 1024px)":{
      lineHeight : '21px',
      height : '52px',
      fontSize : '100px'
   },
   "@media (min-width >=375px) and (max-width <=425px)":{
      lineHeight : '21px',
      fontSize : '14px'
   },
   "@media(max-width : 420)":{
      lineHeight : '21px',
      fontSize : '14px'
   }
  },
  })(InputLabel);
  export const CustomInputlabelEmail = withStyles({
    root: {
      fontFamily: 'Urbanist',
      color: '#f5c42c',
      fontWeight : 600,
    "@media (max-width : 1440px)":{
        fontSize : '14px'
    },
    "@media (min-width : 1441px)":{
        fontSize : '14px',
        lineHeight:"14px",
        paddingBottom:"6px",
        paddingTop:"14px"

    }
  },
  })(InputLabel);

  export const CustomTextField = CustomTextFieldInput(emailInputHeightPass,"14px",'#e3eaf4','#323232');
