import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  TypographyProps,
  Link,
  TextField,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import SideBar from "../../../components/src/SideBar";
import Header from "../../../components/src/Header.web";
import Footer from "../../../components/src/Footer";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import "./forecastingandbudgeting.css";
import { IBalanceSheetProject } from "./ForecastingandbudgetingController";
import SearchIcon from "@material-ui/icons/Search";
import Loader from "../../../components/src/Loader.web";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import { dummyImage } from "../../../components/src/assets";

const images = require("./assets");

const theme = createTheme({
  palette: {
    primary: {
      main: "#F5C42C",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  overrides: {
    MuiSelect: {
      select: {
        "&:focus": {
          border: "1px solid #F5C42C",
        }
      }
    }
  }
});
// Customizable Area End

import ForecastingandbudgetingController, {
  Props,
  configJSON,
} from "./ForecastingandbudgetingController";
import CustomNotification from "../../../components/src/CustomNotification";

export default class Forecastingandbudgeting extends ForecastingandbudgetingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderMainContent(sideBarStatus: boolean = this.state.isSideBarOpen) {
    return <>
      <Loader loading={this.state.isLoading}></Loader>
      <Grid style={webStyle.contentSetup}>
        < Header
          sideBarStatus={sideBarStatus}
          dropDownStatus={this.state.isDropdownOpen}
          handleSideBar={this.handleSideBar}
          handleDropdown={this.handleDropdown}
          navigate={this.props.navigation.navigate}
        />
        <Grid style={webStyle.mainContent as React.CSSProperties}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
            <Box>
              <Typography variant="h3" style={webStyle.activeBlock as TypographyProps}>Balance Sheets</Typography>
              <Typography variant="h6" style={{ letterSpacing: '0.15555556px', fontWeight: 500, lineHeight: '17px', fontSize: "14px", color: "#6D89AF" }}>
                <span style={{ color: "#F5C42C", fontFamily: 'Urbanist', cursor: 'pointer', letterSpacing: '0.15555556px', fontWeight: 600, lineHeight: '17px', textDecoration: 'underline', }}>
                  <Link data-test-id="homeLink" color="inherit" onClick={() => this.handleHomeNavigation("JobProfile")}>
                    Home
                  </Link>
                </span> / Balance Sheets
              </Typography>
            </Box>
            <Box sx={{ minWidth: "286px" }}>
              <TextField
                data-test-id="BalanceSheetSearchInput"
                onChange={(event) => this.handleSearchData(event)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton style={{ margin: "0px", padding: "0px", marginLeft: "15px" }}>
                        <SearchIcon style={{ color: "black", width: "20px", height: "20px" } as React.CSSProperties} />
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: {
                    borderRadius: "35px",
                    backgroundColor: "#F8FAFE",
                    padding: "0px",
                    height: "44px",
                    fontSize: "13px"
                  } as React.CSSProperties
                }}
                variant="outlined"
                fullWidth
                placeholder={`Search balance sheet`}
              />
            </Box>
          </Box>
          <Grid container spacing={3} style={{ marginTop: "12px" }}>
            {this.state.balanceSheetsData.length ? <>
              {this.state.balanceSheetsData.map((sheetData: IBalanceSheetProject) => {
                return <Grid data-test-id={`${sheetData.id}_listContainer`} item xs={12} sm={6} md={4} key={sheetData.id}>
                  <Box sx={webStyle.cardWrapper}>
                    <Box>
                      <Grid container spacing={2}>
                        <Box sx={webStyle.projectcardParentBox} className="project_card_box">
                          <Grid className="project_img">
                            <img
                              className="project_avatar"
                              src={sheetData.attributes.image || dummyImage}
                            />
                          </Grid>
                          <Grid className="project_card_inner">
                            <div className="project_card_inner_meta">
                              <Typography className="project_name_typo">{sheetData.attributes.project_name}</Typography>
                              <Typography className="project_date_typo">{sheetData.attributes.created_at}</Typography>
                            </div>
                            <Typography className="project_duration_typo">{sheetData.attributes.production_house}</Typography>
                          </Grid>
                        </Box>
                      </Grid>
                      <Box style={webStyle.buttonArea as React.CSSProperties}>
                        <Box style={webStyle.cardButton as React.CSSProperties}>
                          <Typography style={{ color: "#6d89af", fontFamily: "Urbanist", fontSize: "12px", fontWeight: 500, lineHeight: "16px", letterSpacing: "0.2px" }}>Current Balance</Typography>
                          <span style={{color: "#17181d", fontFamily: "Helvetica-Bold", fontSize: "18px", fontWeight: 400, lineHeight: "19px", letterSpacing: "0.16363636px"}}>₹{sheetData.attributes.current_balance || "00.00"}</span>
                        </Box>
                        <Box style={webStyle.cardButton as React.CSSProperties}>
                          <Typography style={{ color: "#6d89af", fontFamily: "Urbanist", fontSize: "12px", fontWeight: 500, lineHeight: "16px", letterSpacing: "0.2px" }}>Overall Budget</Typography>
                          <span style={{color: "#17181d", fontFamily: "Helvetica-Bold", fontSize: "18px", fontWeight: 400, lineHeight: "19px", letterSpacing: "0.16363636px"}}>₹{sheetData.attributes.overall_budget || "00.00"}</span>
                        </Box>
                      </Box>
                    </Box>
                    <Box style={webStyle.seperateCard}></Box>
                    <Box style={webStyle.additionalInfo}>
                      {sheetData.attributes.manage_balance_sheet ?
                        <>
                          <Box>
                            <img src={images.successMarkIcon} alt="success_mark" style={{ width: "17px", marginRight: "6px" }} />
                          </Box>
                          <Box style={{ width: "65%" }}>
                            <Typography className="permission_msg">
                              You've permission to view & manage the balance sheet
                            </Typography>
                          </Box>
                        </>
                        :
                        <>
                          <Box>
                            <InfoOutlinedIcon style={{ width: "17px", height: "17px", color: "orange", marginRight: "6px" } as React.CSSProperties} />
                          </Box>
                          <Box style={{ width: "62%" }}>
                            <Typography className="permission_msg">
                              You've only permission to view the balance sheet
                            </Typography>
                          </Box>
                        </>
                      }
                      <Box
                        style={webStyle.alignContent}
                      >
                        {sheetData.attributes.manage_balance_sheet ? <Box data-test-id={`manageSheetBtn_${sheetData.id}`} onClick={() => this.handleNavigationManageSheet(sheetData.id)} style={webStyle.mangeSheetBtn as React.CSSProperties}>
                          Manage Sheet
                        </Box> :
                          <Box data-test-id={`viewSheetBtn_${sheetData.id}`} onClick={() => this.handleNavigationViewSheet(sheetData.id)} style={webStyle.mangeSheetBtn as React.CSSProperties}>
                            View Sheet
                          </Box>}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              })}
              <Grid container style={{ margin: "8px 2px", justifyContent: "center", display: "grid" }}>
                <Grid item xs={12}>
                  <Pagination data-test-id="pagination" count={Math.ceil(this.state.totalPages / configJSON.balanceSheetProjectListPerPage)} page={this.state.balanceSheetCurrentpage} onChange={this.handleBalanceSheetPageChange} />
                </Grid>
              </Grid>
            </>
              : <Typography style={{ width: '100%', textAlign: 'center', marginTop: '30px', color: '#6d89af', fontFamily: 'Urbanist', fontSize: 14, fontWeight: 600 }}>No record available</Typography>
            }
          </Grid>
        </Grid>
      </Grid >
      <Footer />
    </>
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container style={webStyle.parentDiv}>
          {this.state.isSideBarOpen ?
            <Grid container spacing={0}>
              <Grid item xs={3} style={webStyle.sidebarParent}>
                <SideBar
                  navigate={this.props.navigation.navigate}
                  userData={{ age: 25 }}
                  activeMenuItem={"Balance Sheets"}
                  handleSideBar={this.handleSideBar} />
              </Grid>
              <Grid item style={{ display: "flex", flexDirection: "column", flexBasis: "77.75%", maxWidth: "77.75%" }} xs={9}>{this.renderMainContent()}</Grid>
            </Grid> :
            <Grid item style={{ display: "flex", flexDirection: "column" }} xs={12}>{this.renderMainContent()}</Grid>
          }
        </Container>
        <CustomNotification navigation={(route: string) => this.props.navigation.navigate(route)} notification={this.state.balanceSheetNotification} handleCloseNotification={this.handleVendorProfileCloseNotification} />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  parentDiv: {
    padding: "0px",
    maxWidth: "none"
  },
  sidebarParent: {
    minHeight: "100vh",
    maxWidth: "22.25%"
  },
  contentSetup: {
    borderLeft: "1px solid #E3EAF4",
    height: '100%',
    flex: 1,
  },
  mainContent: {
    width: "100%",
    minHeight: "100vh",
    backgroundColor: "#F8FAFE",
    boxSizing: "border-box",
    padding: "24px"
  },
  activeBlock: {
    lineHeight: "34px",
    fontSize: "28px",
    fontWeight: 700,
    letterSpacing: "1px",
    marginBottom: "8px"
  },
  cardWrapper: {
    padding: "9px",
    boxSizing: "border-box",
    border: "1px solid #E1E6EC",
    backgroundColor: "#fff",
    borderRadius: "14px",
  },
  cardButton: {
    borderRadius: 10,
    backgroundColor: '#e1e6ec',
    width: "47%",
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 17,
    fontWeight: 700,
    color: '#17181d',
    boxSizing: "border-box",
    padding: "7px 11px",
  },
  buttonArea: {
    display: 'flex',
    justifyContent: "space-between",
    flexWrap: "wrap",
    padding: "12px 0"
  },
  seperateCard: {
    border: "1px solid #E3EAF4",
  },
  additionalInfo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#6D89AF",
    padding: "8px 0 0 0"
  },
  alignContent: {
    display: "flex",
    width: "43%",
    alignItems: "center",
    justifyContent: "right",
  },
  mangeSheetBtn: {
    color: "#FFF",
    backgroundColor: "#000",
    padding: "11px 15px",
    fontSize: "13px",
    fontFamily: "Urbanist",
    borderRadius: "11px",
    textAlign: "center",
    cursor: "pointer",
    width: 85,
  },
  projectcardParentBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    padding: '2%'
  },
};
// Customizable Area End
