import React, {createRef} from "react";

import {
    // Customizable Area Start
    TypographyProps,
    Container,
    Grid,
    Box,Typography,Link
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import { arrowicon } from "./assets";
import InvoiceDetailCard from "../../../components/src/InvoiceDetailCard";
import InvoiceListController,{ Props } from "./InvoiceListController";
import Header from "../../../components/src/Header.web";
import SideBar from "../../../components/src/SideBar";
import Footer from "../../../components/src/Footer";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

export default class UserinvoiceDetail extends InvoiceListController {
    stepperRef: React.RefObject<any>;
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.stepperRef = createRef();
        // Customizable Area End
    }
   
    

    renderMainInvoiceProfile(sideBarStatus: boolean) {
        return <Grid style={webStyle.contentSetupinvoice}>
              <Loader loading={this.state.isLoading}></Loader>
            < Header
              navigate={this.props.navigation.navigate}
                sideBarStatus={this.state.isSideBarOpen}
                dropDownStatus={this.state.isDropdownOpen}
                handleDropdown={this.handleDropdown}
                handleSideBar={this.handleSideBar}  
            />
            <Grid style={webStyle.mainContentinvoiceDetail as React.CSSProperties}>
                <Box sx={webStyle.blockInfoinvoiceDetail}>
                    <Box>
                        <Typography style={webStyle.activeBlockinvoiceDetail as React.CSSProperties}>Invoice Details</Typography>
                    </Box>
                    <Typography variant="h6" style={webStyle.blockE2EPath as TypographyProps}>
                <span style={webStyle.highlightedTitleext}>
                  <Link data-test-id="homeLink" color="inherit" onClick={() => this.handleNavigation("JobProfile")}>
                    Home
                  </Link>
                </span>
                <span style={webStyle.highlightedTitleext}>
                  <Link data-test-id="homeLink1" color="inherit" onClick={() => this.handleNavigation("InvoiceList")}>
                    {" "}/ Invoices
                  </Link>
                </span>
                {" "}/{" "}{this.state.invoicedetail?.attributes?.project?.name}
              </Typography>
                </Box>
                <InvoiceDetailCard viewStatusUpdateDetails={this.viewStatusUpdateDetails} reclaimInvoiceAPICall={this.reclaimInvoiceAPICall} data={this.state.invoicedetail} handleOpen={this.handleOpen3} handleClose={this.handleClose3} open={this.state.open3} handlehiddenandshow={this.handlehiddenandshow} showandhidden={this.state.showandhidden}
                />
            </Grid>
            <Footer />
        </Grid>
    }
    
    render() {
        
        return ( 
            <Container maxWidth="xl" className="user_detail_container">
                {
                    this.state.isSideBarOpen?
                        <Grid container spacing={0}>
                            <Grid style={webStyle.sideBarinvoice}  item xs={3}>
                                <SideBar
                                   userData={{ age: 25 }}
                                    activeMenuItem={"All Studios"}
                                    navigate={this.props.navigation.navigate}
                                    handleSideBar={this.handleSideBar}
                                   
                                    />
                            </Grid>
                            <Grid item xs={9} style={{ flexBasis: "77.75%", maxWidth: "77.75%" }} >{this.renderMainInvoiceProfile(this.state.isSideBarOpen)}</Grid>
                        </Grid> :
                        <Grid item xs={12}>
                            {
                                this.renderMainInvoiceProfile(this.state.isSideBarOpen)
                            }
                           </Grid>
                }
            </Container >
        );  }
}

// Customizable Area Start
const webStyle = {
    blockE2EPath: {
        fontSize: "14px",
        letterSpacing: "1px",
        fontWeight: 500,
        color: "#6D89AF",
        lineHeight: "17px",
      },
      highlightedTitleext: {
        fontFamily: "Urbanist",
        color: "#F5C42C",
        textDecoration: "underline",
        cursor: "pointer",
        fontWeight: 600,
      },

    contentSetupinvoice: {
        borderLeft: "1px solid #E3EAF4",
    }, blockInfoinvoiceDetail: {

    }, activeBlockinvoiceDetail: {
        fontSize: "28px",
        fontWeight: "700",
        letterSpacing: "1px",
        display: "flex",
        flexDirection: "row",
        lineHeight: "34px",
        alignItems: 'center',
        marginBottom: '8px',   
      },
    
    sideBarinvoice: {
        minHeight: "100vh",
        maxWidth: "22.25%"
    },
    blockInfoinvoice: {

    },
    blockPathinvoice: {
        fontSize: "14px",
        letterSpacing: "1px",
        color: "#6D89AF",
        marginBottom: "24px",
        fontWeight: 500
      },
      highlightedTextinvoice: {
        color: "#F5C42C",
        textDecoration: 'underline',
        textDecorationColor: "#F5C42C",
        fontWeight: 600,
        cursor:"pointer"
      },
    activeBlockinvoice: {
        fontSize: "28px",
        fontWeight: "600",
        letterSpacing: "1px",
        display: "flex",
        flexDirection: "row",
        lineHeight: "33px",
        alignItems: 'center',
        gap: '12px',
        marginBottom: '8px',   
      },

      mainContentinvoiceDetail: {
        width: "100%",
        backgroundColor: "#F8FAFE",
        boxSizing: "border-box",
        padding: "24px"
      },
     
      mainContentinvoice: {
        width: "100%",
        backgroundColor: "#F8FAFE",
        boxSizing: "border-box",
        padding: "24px"
      },
};



// Customizable Area End