import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

interface S {
    // Customizable Area Start
    // Customizable Area End
  }
  
  interface SS {
    // Customizable Area Start
  
    // Customizable Area End
  }
  
  // Customizable Area Start
  // Customizable Area End
  
  export default class OtpScreenController extends BlockComponent<
    Props,
    S,
    SS
  > {
    // Customizable Area Start
    
  
    //Properties from config
    
    // Customizable Area End
  
    constructor(props: Props) {
      super(props);
  
      this.state = {
         
      };
      // Customizable Area End
    }
  
  async componentDidMount() {
    super.componentDidMount();
  }

  handlePopupMsgSubmit = () => {
    this.props.navigation.navigate("LandingPage")
  };
  // Customizable Area End
}