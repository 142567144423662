
import React from "react";

//Customizable Area Start
import { Grid } from "@material-ui/core";
import TopNav from "./TopNav.web";
import ForgotPasswordController , {Props} from "./ForgotPasswordController";
//Customizable Area End

export default class CommonComponent extends ForgotPasswordController {
    constructor(props: Props) {
        super(props);
        //Customizable Area Start
        //Customizable Area End
    }
    // Customizable Area Start
    render() {
        return (
                 <TopNav navigation={this.props.navigation} backButtonVisible={this.props.backButtonVisible}/>
        );
    }
    // Customizable Area End
}

// Customizable Area Start
//Customizable Area End