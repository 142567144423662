import React from "react";

import {
  // Customizable Area Start
  Button,
  Typography,
  Container,
  Box,
  InputLabel,
  Input,
  ImageListItem,
  ImageList,
  IconButton,
  // Customizable Area End
} from "@material-ui/core";
import CancelIcon from '@material-ui/icons/Cancel';

// Customizable Area Start
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 800,
    },
  },
});

const RedTextTypography = withStyles({
  root: {
    color: "#FF0000",
    marginTop: 2,
    fontSize: 'small'
  }
})(Typography);

// Customizable Area End

import CreateEditProjectPortfolioController, {
  Props,
  configJSON,
} from "./CreateEditProjectPortfolioController";
import Loader from "../../../components/src/Loader";
import { backIcon } from "./assets";

export default class CreateEditProjectPortfolio extends CreateEditProjectPortfolioController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Loader loading={this.state.loading} />
        <Container maxWidth={"sm"}>
          <div style={webStyle.headingLeft}>
            <Button
              disabled={this.state.isDisabled}
              id="back-positioned-button"
              data-test-id="backButon"
              onClick={() => this.goBack()}
            >
              <img
                src={backIcon}
                alt=""
                style={webStyle.backIcon}
              />
            </Button>
            <Typography data-test-id="titleMain" variant="h5">{this.state.projectId ? configJSON.labelEditProjectText : configJSON.labelAddProjectText}</Typography>
          </div>
          <Box sx={webStyle.mainWrapper}>
            <Box sx={webStyle.inputStyle}>
              <InputLabel id="project-name">
                {configJSON.labelsCreateFormFields.projectName.label}
              </InputLabel>
              <Input
                required
                data-test-id={"projectName"}
                type="text"
                name="projectName"
                placeholder={configJSON.labelsCreateFormFields.projectName.placeholder}
                fullWidth={true}
                disableUnderline={true}
                value={this.state.formValues.projectName}
                onChange={this.handleInputChange}
              />
              {this.state.formError.projectNameError &&
                <RedTextTypography>{this.state.formError.projectNameError}</RedTextTypography>
              }
            </Box>
            <Box sx={webStyle.inputStyle}>
              <InputLabel id="project-description">
                {configJSON.labelsCreateFormFields.projectDescription.label}
              </InputLabel>
              <Input
                required
                data-test-id={"projectDescription"}
                type="text"
                name="projectDescription"
                placeholder={configJSON.labelsCreateFormFields.projectDescription.placeholder}
                fullWidth={true}
                disableUnderline={true}
                value={this.state.formValues.projectDescription}
                onChange={this.handleInputChange}
              />
              {this.state.formError.projectDescriptionError &&
                <RedTextTypography>{this.state.formError.projectDescriptionError}</RedTextTypography>
              }
            </Box>
            <Box sx={webStyle.inputStyle}>
              <InputLabel id="client-details">
                {configJSON.labelsCreateFormFields.clientDetails.label}
              </InputLabel>
              <Input
                required
                data-test-id={"clientDetails"}
                type="text"
                name="clientDetails"
                placeholder={configJSON.labelsCreateFormFields.clientDetails.placeholder}
                fullWidth={true}
                disableUnderline={true}
                value={this.state.formValues.clientDetails}
                onChange={this.handleInputChange}
              />
              {this.state.formError.clientDetailsError &&
                <RedTextTypography>{this.state.formError.clientDetailsError}</RedTextTypography>
              }
            </Box>
            <Box sx={webStyle.inputStyle}>
              <InputLabel id="email">
                {configJSON.labelsCreateFormFields.developerEmail.label}
              </InputLabel>
              <Input
                required
                data-test-id={"developerEmail"}
                type="email"
                inputProps={{ pattern: '[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$' }}
                name="developerEmail"
                placeholder={configJSON.labelsCreateFormFields.developerEmail.placeholder}
                fullWidth={true}
                disableUnderline={true}
                value={this.state.formValues.developerEmail}
                onChange={this.handleInputChange}
              />
              {this.state.formError.developerEmailError &&
                <RedTextTypography>{this.state.formError.developerEmailError}</RedTextTypography>
              }
            </Box>
            <Box sx={webStyle.inputStyle}>
              <InputLabel id="full-name">
                {configJSON.labelsCreateFormFields.developerFullName.label}
              </InputLabel>
              <Input
                required
                data-test-id={"developerFullName"}
                type="text"
                name="developerFullName"
                placeholder={configJSON.labelsCreateFormFields.developerFullName.placeholder}
                fullWidth={true}
                disableUnderline={true}
                value={this.state.formValues.developerFullName}
                onChange={this.handleInputChange}
              />
              {this.state.formError.developerFullNameError &&
                <RedTextTypography>{this.state.formError.developerFullNameError}</RedTextTypography>
              }
            </Box>
            <Box sx={webStyle.inputStyle}>
              <InputLabel id="phone-number">
                {configJSON.labelsCreateFormFields.phoneNumber.label}
              </InputLabel>
              <Input
                required
                data-test-id={"phoneNumber"}
                type="text"
                name="phoneNumber"
                inputProps={{ minLength: 10, maxLength: 10, pattern: "^[0-9]+$" }}
                placeholder={configJSON.labelsCreateFormFields.phoneNumber.placeholder}
                fullWidth={true}
                disableUnderline={true}
                value={this.state.formValues.phoneNumber}
                onChange={this.handleInputChange}
              />
              {this.state.formError.phoneNumberError &&
                <RedTextTypography>{this.state.formError.phoneNumberError}</RedTextTypography>
              }
            </Box>
            <Box sx={webStyle.inputStyle}>
              <InputLabel id="team-size">
                {configJSON.labelsCreateFormFields.teamSize.label}
              </InputLabel>
              <Input
                required
                data-test-id={"teamSize"}
                type="text"
                inputProps={{ pattern: "^[0-9]+$" }}
                name="teamSize"
                placeholder={configJSON.labelsCreateFormFields.teamSize.placeholder}
                fullWidth={true}
                disableUnderline={true}
                value={this.state.formValues.teamSize}
                onChange={this.handleInputChange}
              />
              {this.state.formError.teamSizeError &&
                <RedTextTypography>{this.state.formError.teamSizeError}</RedTextTypography>
              }
            </Box>
            <Box sx={webStyle.inputStyle}>
              <InputLabel id="project-utl">
                {configJSON.labelsCreateFormFields.projectUrl.label}
              </InputLabel>
              <Input
                required
                data-test-id={"projectUrl"}
                type="text"
                name="projectUrl"
                placeholder={configJSON.labelsCreateFormFields.projectUrl.placeholder}
                fullWidth={true}
                disableUnderline={true}
                value={this.state.formValues.projectUrl}
                onChange={this.handleInputChange}
              />
              {this.state.formError.projectUrlError &&
                <RedTextTypography>{this.state.formError.projectUrlError}</RedTextTypography>
              }
            </Box>
            <Box sx={webStyle.inputStyle}>
              <InputLabel id="start-date">
                {configJSON.labelsCreateFormFields.startDate.label}
              </InputLabel>
              <Input
                required
                data-test-id={"startDate"}
                type="date"
                inputProps={{ min: this.state.currentDate, max: this.state.formValues.endDate, format: "dd-mm-yyyy" }}
                name="startDate"
                placeholder={configJSON.labelsCreateFormFields.startDate.placeholder}
                fullWidth={true}
                disableUnderline={true}
                value={this.state.formValues.startDate}
                onChange={this.handleInputChange}
              />
              {this.state.formError.startDateError &&
                <RedTextTypography>{this.state.formError.startDateError}</RedTextTypography>
              }
            </Box>
            <Box sx={webStyle.inputStyle}>
              <InputLabel id="end-date">
                {configJSON.labelsCreateFormFields.endDate.label}
              </InputLabel>
              <Input
                required
                data-test-id={"endDate"}
                type="date"
                inputProps={{ min: this.state.formValues.startDate, format: "dd-mm-yyyy" }}
                name="endDate"
                placeholder={configJSON.labelsCreateFormFields.endDate.placeholder}
                fullWidth={true}
                disableUnderline={true}
                disabled={typeof (this.state.formValues.startDate) !== "string"}
                value={this.state.formValues.endDate}
                onChange={this.handleInputChange}
              />
              {this.state.formError.endDateError &&
                <RedTextTypography>{this.state.formError.endDateError}</RedTextTypography>
              }
            </Box>
            <Box sx={webStyle.inputStyle}>
              <InputLabel id="project-images">
                {configJSON.labelsCreateFormFields.projectImages.label}
              </InputLabel>
              <Input
                required
                data-test-id={"projectImages"}
                type="file"
                inputProps={{ multiple: true, accept: 'image/*' }}
                name="projectImages"
                disableUnderline={true}
                onChange={this.handleSelectFiles}
              />
            </Box>
            <ImageList cols={4} rowHeight={100}>
              {this.state.formValues.projectImagesUrl.map((item, index) => (
                <React.Fragment key={item.name + "fragment"}>
                  <ImageListItem style={{ width: 100 }} key={item.name}>
                    <img
                      src={item.imageUrl as string} alt="not fount" width={"100px"}
                    />
                  </ImageListItem>
                  <div
                    key={item.name + "div"}
                  >
                    <CancelIcon data-test-id={`imagesListBtn${index}`} onClick={() => this.handleRemoveImage(item.name)} />
                  </div>
                </React.Fragment>
              ))}
            </ImageList>
            <Box sx={webStyle.inputStyle}>
              <InputLabel id="project-documents">
                {configJSON.labelsCreateFormFields.projectDocuments.label}
              </InputLabel>
              <Input
                required
                data-test-id={"projectDocuments"}
                type="file"
                inputProps={{ multiple: true, accept: '.xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .pdf' }}
                name="projectDocuments"
                disableUnderline={true}
                onChange={this.handleSelectDocFiles}
              />
            </Box>
            <Box style={{ width: '100%' }}>
              {this.state.formValues.projectDocumentsUrl.map((item, index) => (
                <div key={item.name + index} style={{ display: 'flex' }}>
                  <div>
                    {item.name}
                  </div>
                  <div>
                    <IconButton
                      data-test-id={`documentsList${index}`}
                      style={{ color: 'black' }}
                      aria-label="Remove"
                      onClick={() => this.handleRemoveDoc(item.name)}
                    >
                      <CancelIcon />
                    </IconButton>
                  </div>
                </div>
              ))}
            </Box>
            <Box
              data-test-id="btnSubmitProjectData"
              onClick={this.handleSubmit}
              component="button"
              sx={webStyle.buttonStyle}
            >
              <Button disabled={this.state.isDisabled} color={"primary"} >{this.state.projectId ? "Save" : "Submit"}</Button>
            </Box>
          </Box>
        </Container>

      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.33)",
    width: "100%",
    height: 45,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginBottom: 30,
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  rightContent: {
    width: "100%",
    borderRadius: 12,
  },
  headingCont: {
    width: "90%",
    margin: "auto",
    padding: "20px 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  backIcon: {
    width: 40,
    height: 40,
    marginLeft: 15,
    cursor: "pointer",
  },
  headingLeft: {
    width: "100%",
    margin: "auto",
    display: "flex",
    alignItems: "center",
  },
  titleText: {
    fontSize: 22,
    fontFamily: 'sofia_pro_regular',
    color: "#1e1f20",
  },
  editButton: {
    background: "rgb(98, 0, 238)",
    display: "flex",
    padding: "5px",
    fontSize: "14px",
    alignItems: "center",
    fontFamily: 'sofia_pro_regular',
    marginRight: "5px",
    borderRadius: "4px",
    color: "white",
    border: "none",
  },
};
// Customizable Area End
