import { Box, Typography, styled,Menu, MenuItem, Button, ListItemIcon, Divider, Dialog } from '@material-ui/core';
import React, { useState } from 'react';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import { CustomButton } from '../../blocks/email-account-login/src/CustomButton';
import {AddFilesIcon, StudioAddimage} from "../src/assets"

const styles = {
    
}
const CustomNextStepButtonBox = styled(CustomButton)({
    '& .MuiButton-label': {
        fontSize: 14,
        color: '#ffffff',
        fontWeight: 500,
        width: 163,
        height: 48,
        cursor:'pointer'
    }
});
interface IActiveEmployeeProps {
     handleOpen1:()=>void

}
// data : {[key:string]: any}

const NOStudio = (props: IActiveEmployeeProps) => {


    return (
        <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginTop: "24px" }}>
            <img src={StudioAddimage} style={{width:"250px",height:"175px"}}/>
            <Typography variant='h6' style={{fontWeight:700}}>
                Explore By
            </Typography>
            <Typography  style={{color:"#6D89AF"}} >
            No Studios added yet, please add new studio

            </Typography>
            <Typography  style={{color: "#6D89AF"}}>
            to manage your production
            </Typography>
            <CustomNextStepButtonBox 
             onClick={props.handleOpen1}
             data-test-id="next_button" style={{ height: 48,width:"200px",background:"black",marginTop:"20px"} as React.CSSProperties}  >
                + Add Studio
            </CustomNextStepButtonBox>
        </div>
    )
};

export default NOStudio;