import React from "react";

import {
  // Customizable Area Start
  Box,
  Typography,
  Divider,
  Button
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import "./account.css";
import AllStudioController, { configJSON, Props } from "./AllStudioController";
import { emailicon, phoneicon, dummyImgIcon } from "./assets";
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import TodayOutlinedIcon from '@material-ui/icons/TodayOutlined';
import { switchEnable } from "../../fullteamvisibility/src/assets";
// Customizable Area End

export default class AllStudio extends AllStudioController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { item }: { item: any } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Box>
          <Box style={webStyle.studioImageContainer as React.CSSProperties}>
            <img
              style={webStyle.studioImage as React.CSSProperties}
              src={item.image || dummyImgIcon}
            />
            <Box style={webStyle.studioDetails as React.CSSProperties}>
              <Box>
                <Typography style={{ fontWeight: 600, lineHeight: "19px", fontSize: "16px", letterSpacing: "0.23px" }}>{item.name}</Typography>
                <Typography style={webStyle.alignContent as React.CSSProperties}><LocationOnOutlinedIcon style={webStyle.locationIcon as React.CSSProperties} />{item.address}</Typography>
              </Box>
              {item.status.toLowerCase() === "booked"
                ? <Button style={{ ...webStyle.bookedButton, backgroundColor: '#f5c42c' } as React.CSSProperties} variant="contained">
                  {configJSON.booked}
                </Button>
                : <Button style={{ ...webStyle.bookedButton, backgroundColor: '#3389ff' } as React.CSSProperties} variant="contained">
                  {configJSON.available}
                </Button>
              }
            </Box>
          </Box>
          <Box style={webStyle.buttonArea}>
            <Box className="account_button_box">
              <Box sx={webStyle.priceTypoBox}>
                <Typography style={webStyle.account_email_typo}>
                  <img src={emailicon} style={webStyle.account_icon} />
                  {item.email}
                </Typography>
                <Typography style={webStyle.account_phone_typo}>
                  <img src={phoneicon} style={webStyle.account_icon} />
                  {item && (`+${item.country_code}-${item.phone_number}`)}
                </Typography>
              </Box>
              <Box
                sx={webStyle.priceTypoBox}>
                <Typography
                  style={webStyle.priceTypo}
                  className="typo_font_important"
                >{item.price_per_day}
                </Typography>
                <Typography
                  style={webStyle.priceTypo}
                  className="typo_font_important">{configJSON.perDay}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Divider style={{ marginBottom: '1%', marginTop: '1%' }} />
        <Box style={webStyle.additionalInfo}>
          <Box>
            <Typography style={webStyle.ownedTypo}>
              {configJSON.ownedBy} <span style={webStyle.tSeriesTypo}>{item.production_house}</span>
            </Typography>
            <Typography style={webStyle.visitingTime}>
              <TodayOutlinedIcon style={{ width: "16px", paddingRight: 1 }} />
              Visiting Time {item.open_time} to {item.close_time}
            </Typography>
          </Box>
          <Box
            style={{ display: "flex", alignItems: "center" }}
          >
            <Typography style={webStyle.block as React.CSSProperties}>
              <Typography style={webStyle.blockColor as React.CSSProperties}>
                Blocked
              </Typography>
              <img src={switchEnable}
                style={webStyle.blockBox}
                data-test-id="unBlockStudio"
                onClick={(e: any) => this.props.handleBadgeVisibility(e, this.props.id, item.blocked)}
              />
            </Typography>
          </Box>
        </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  visitingTime: {
    display: "flex",
    alignItems: "center",
    fontSize: "12px",
    color: "#6d89af",
    fontFamily: "Urbanist",
    fontWeight: 600,
    lineHeight: "16px",
    letterSpacing: "0.2px"
  },
  ownedTypo: {
    fontSize: '12px',
    color: '#17181d',
    fontWeight: 600,
    fontFamily: 'Urbanist'
  },
  tSeriesTypo: {
    color: '#64a5ff',
    fontSize: '12px',
    fontWeight: 600,
    fontFamily: 'Urbanist',
    textDecoration: 'underline'
  },
  bookedButton: {
    borderRadius: '12px',
    width: "4.25rem",
    height: "1.438rem",
    fontWeight: 700,
    color: "#ffffff",
    fontFamily: 'Urbanist',
    fontSize: '10px',
    textAlign: "center",
    letterSpacing: "0.18181819px"
  },
  priceTypoBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItem: 'flex-end',
    justifyContent: 'space-evenly'
  },
  priceTypo: {
    color: '#42a200',
    fontSize: '12px',
    fontFamily: "Helvetica-Bold",
    fontWeight: 400,
    lineHeight: "16px",
    letterSpacing: "0.2px",
  },
  account_icon: {
    fontSize: "20px",
    fontWeight: 600,
    marginRight: "2%"
  },
  account_email_typo: {
    fontWeight: 600,
    display: "flex",
    fontSize: "14px",
    fontFamily: "Urbanist",
    gap: '5%',
    marginBottom: "8px",
  },
  account_phone_typo: {
    marginTop: "1%",
    fontWeight: 600,
    display: "flex",
    justifycontent: "flex-start",
    columnGap: "1%",
    fontSize: "14px",
    gap: '5%',
    fontFamily: "Urbanist"
  },
  studioImageContainer: {
    height: "123px",
    width: "100%",
    position: "relative",
    borderRadius: "14px"
  },
  locationIcon: {
    width: "12px",
    height: "14px",
    color: "#ffffff",
    fontFamily: "Urbanist",
    fontSize: "12px",
    fontWeight: 500,
    textAlign: "center",
    letterSpacing: "0.2px"
  },
  studioImage: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "14px",
  },
  studioDetails: {
    display: "flex",
    justifyContent: "space-between",
    position: "absolute",
    alignItems: "center",
    bottom: "8px",
    right: "8px",
    left: "8px",
    color: "#fff"
  },
  alignContent: {
    display: "flex",
    alignItems: "center",
    color: "#ffffff",
    fontFamily: "Urbanist",
    fontSize: "12px",
    fontWeight: "500",
    textAlign: "center",
    letterSpacing: "0.2px",
    lineHeight: "14px",
  },
  buttonArea: {
    padding: "8px 0",
  },
  additionalInfo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#6D89AF",
    padding: "8px 0 0 0"
  },
  block: {
    fontSize: "13px",
    display: "flex",
    columnGap: "1%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  blockColor: {
    color: "#f70052",
    fontSize: "13px",
    fontFamily: "Urbanist",
    fontWeight: "500",
  },
  blockBox: {
    marginRight: "1%",
    cursor: "pointer",
  },
}
// Customizable Area End