import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import DocumentPicker, {
  DocumentPickerResponse,
} from "react-native-document-picker";
import { getStorageData, isEmail } from "../../../framework/src/Utilities";
import { IImageUrl, IPresignUrl, ImageUrl } from "./types";
import moment from "moment";
import { ChangeEvent } from "react";
import { Platform } from "react-native";
import UploadMedia3 from "../../uploadmedia3/src/Uploadmedia3";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  loading: boolean;
  currentDate: string;
  isDisabled: boolean;
  projectId: string;
  projectDataId: string;
  formValues: {
    projectName: string;
    projectDescription: string;
    clientDetails: string;
    teamSize: string;
    projectUrl: string;
    developerEmail: string;
    developerFullName: string;
    phoneNumber: string;
    startDate: Date;
    endDate: Date;
    // web events
    projectImages: IPresignUrl[];
    projectDocuments: IPresignUrl[];
    projectImagesUrl: {
      name: string;
      imageUrl?: ImageUrl;
      imageObj: Blob;
      id?: number | null;
    }[];
    projectDocumentsUrl: {
      name: string;
      imageUrl?: ImageUrl;
      imageObj: Blob;
      id?: number | null;
    }[];
  };
  imageUrl: IImageUrl[];
  documentUrl: IImageUrl[];
  images: IPresignUrl[];
  documents: IPresignUrl[];
  formError: {
    projectNameError: string;
    projectDescriptionError: string;
    clientDetailsError: string;
    teamSizeError: string;
    projectUrlError: string;
    developerEmailError: string;
    developerFullNameError: string;
    phoneNumberError: string;
    startDateError: string;
    endDateError: string;
  };
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CreateEditProjectPortfolioController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  apiCreateProjectCallId: string = "";
  apiGetProjectbyIDListCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area Start
      // Customizable Area End
    ];
    // Customizable Area End

    this.state = {
      // Customizable Area Start
      token: "",
      loading: false,
      currentDate: "",
      isDisabled: false,
      projectId: "",
      projectDataId: "",
      formValues: {
        projectName: "",
        projectDescription: "",
        clientDetails: "",
        teamSize: "",
        projectUrl: "",
        startDate: new Date(),
        endDate: new Date(),
        developerEmail: "",
        developerFullName: "",
        phoneNumber: "",

        // web events
        projectImages: [],
        projectDocuments: [],
        projectImagesUrl: [],
        projectDocumentsUrl: [],
      },
      images: [],
      documents: [],
      imageUrl: [],
      documentUrl: [],
      formError: {
        projectNameError: "",
        projectDescriptionError: "",
        clientDetailsError: "",
        teamSizeError: "",
        projectUrlError: "",
        developerEmailError: "",
        developerFullNameError: "",
        phoneNumberError: "",
        startDateError: "",
        endDateError: "",
      },

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const responseData = message.getData(getName(MessageEnum.PropsData));
      responseData && this.setState({ projectId: responseData });

      responseData && this.getProjectbyID(responseData);
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.apiCreateProjectCallId) {
        this.createProjectHandleResponse(responseJson);
      } else if (apiRequestCallId === this.apiGetProjectbyIDListCallId) {
        if (responseJson.data) {
          const getProjectData = responseJson.data.data;
          this.setState({
            formValues: {
              projectName: getProjectData.attributes.project_name,
              projectDescription:
                getProjectData.attributes.project.data.attributes.description,
              developerEmail: getProjectData.attributes.client_account.email,
              developerFullName: getProjectData.attributes.client_account.full_name,
              phoneNumber: getProjectData.attributes.client_account.full_phone_number,
              clientDetails: getProjectData.attributes.client_details,
              teamSize: JSON.stringify(getProjectData.attributes.team_size),
              projectUrl: getProjectData.attributes.project.data.attributes.url,
              startDate: getProjectData.attributes.project.data.attributes.start_date.split("-").reverse().join("-"),
              endDate: getProjectData.attributes.project.data.attributes.end_date.split("-").reverse().join("-"),

              //web events
              projectImages: getProjectData.attributes.images.data.map(
                (image: {
                  id: number;
                  attributes: {
                    file_name: string;
                    file_size: number;
                    category: string;
                  };
                }) => {
                  return {
                    file_name: image.attributes.file_name,
                    file_size: image.attributes.file_size,
                    category: image.attributes.category,
                    id: image.id,
                  };
                }
              ),
              projectDocuments: getProjectData.attributes.documents.data.map(
                (document: {
                  id: number;
                  attributes: {
                    file_name: string;
                    file_size: number;
                    category: string;
                  };
                }) => {
                  return {
                    file_name: document.attributes.file_name,
                    file_size: document.attributes.file_size,
                    category: document.attributes.category,
                    id: document.id,
                  };
                }
              ),
              projectImagesUrl: getProjectData.attributes.images.data.map(
                (image: {
                  id: number;
                  attributes: { file_name: string; public_url: string };
                }) => {
                  return {
                    name: image.attributes.file_name,
                    imageUrl: image.attributes.public_url,
                    id: image.id,
                  };
                }
              ),
              projectDocumentsUrl: getProjectData.attributes.documents.data.map(
                (document: {
                  id: number;
                  attributes: { file_name: string; public_url: string };
                }) => {
                  return {
                    name: document.attributes.file_name,
                    imageUrl: document.attributes.public_url,
                    id: document.id,
                  };
                }
              ),
            },
            loading: false,
            images: getProjectData.attributes.images.data.map(
              (image: {
                id: number;
                attributes: {
                  file_name: string;
                  file_size: number;
                  category: string;
                };
              }) => {
                return {
                  file_name: image.attributes.file_name,
                  file_size: image.attributes.file_size,
                  category: image.attributes.category,
                  id: image.id,
                };
              }
            ),
            documents: getProjectData.attributes.documents.data.map(
              (document: {
                id: number;
                attributes: {
                  file_name: string;
                  file_size: number;
                  category: string;
                };
              }) => {
                return {
                  file_name: document.attributes.file_name,
                  file_size: document.attributes.file_size,
                  category: document.attributes.category,
                  id: document.id,
                };
              }
            ),
            imageUrl: getProjectData.attributes.images.data.map(
              (image: {
                id: number;
                attributes: { file_name: string; public_url: string };
              }) => {
                return {
                  name: image.attributes.file_name,
                  uri: image.attributes.public_url,
                  id: image.id,
                };
              }
            ),
            documentUrl: getProjectData.attributes.documents.data.map(
              (document: {
                id: number;
                attributes: { file_name: string; public_url: string };
              }) => {
                return {
                  name: document.attributes.file_name,
                  uri: document.attributes.public_url,
                  id: document.id,
                };
              }
            ),
            projectDataId: getProjectData.id,
          });
        } else {
          this.setState({ loading: false });
          this.showAlert("Error", responseJson.errors);
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    // Customizable Area Start
    let token = await getStorageData("token");
    this.setState({ token: token });
    let date = new Date();
    this.setState({ currentDate: date.toISOString().split("T")[0] });
    // Customizable Area End
  }

  // Customizable Area Start
  createProjectHandleResponse = (responseJson: {
    data: number;
    message: string;
    error: string;
    errors: string[];
  }) => {
    if (responseJson.message || (this.state.projectId && responseJson.data)) {
      let uploadMediaBlock = new UploadMedia3(this.props);
      let imagesAndDocs: IPresignUrl[] = [];
      let imageUrlsAndDocUrls: (IImageUrl | {
        name: string;
        imageUrl?: ImageUrl;
        imageObj: Blob;
        id?: number | null;
      })[] = [];
      if (Platform.OS == "web") {
        imagesAndDocs = [
          ...this.state.formValues.projectImages,
          ...this.state.formValues.projectDocuments,
        ];
        imageUrlsAndDocUrls = [
          ...this.state.formValues.projectImagesUrl,
          ...this.state.formValues.projectDocumentsUrl,
        ];
      } else {
        imagesAndDocs = [
          ...this.state.images,
          ...this.state.documents,
        ];
        imageUrlsAndDocUrls = [
          ...this.state.imageUrl,
          ...this.state.documentUrl,
        ];
      }
      if (!imagesAndDocs.filter((element) => !element.id).length) {
        this.showAlert("Success", "Updated Successfully!");
        this.setState({ loading: false });
        uploadMediaBlock.goBackNavigation();
        return false;
      }
      uploadMediaBlock.mediaPresignurl(
        imagesAndDocs.filter((element) => !element.id),
        responseJson.data,
        imageUrlsAndDocUrls.filter((element) => !element.id)
      );
      this.setState({ loading: false });
    } else if (responseJson.error || responseJson.errors) {
      this.setState({ loading: false, isDisabled: false });
      this.showAlert(
        "Error",
        responseJson.error || responseJson.errors.toString()
      );
    }
  };

  handleTitle(text: string) {
    this.setState({
      formValues: { ...this.state.formValues, projectName: text },
      formError: { ...this.state.formError, projectNameError: "" },
    });
  }
  handleProjectDescription(text: string) {
    this.setState({
      formValues: { ...this.state.formValues, projectDescription: text },
      formError: { ...this.state.formError, projectDescriptionError: "" },
    });
  }
  handleClientDetails(text: string) {
    this.setState({
      formValues: { ...this.state.formValues, clientDetails: text },
      formError: { ...this.state.formError, clientDetailsError: "" },
    });
  }
  handleTeamSize(text: string) {
    this.setState({
      formValues: { ...this.state.formValues, teamSize: text },
      formError: { ...this.state.formError, teamSizeError: "" },
    });
  }
  handleProjecturl(text: string) {
    this.setState({
      formValues: { ...this.state.formValues, projectUrl: text },
      formError: { ...this.state.formError, projectUrlError: "" },
    });
  }

  handleStartDate(date: Date) {
    this.setState({
      formValues: { ...this.state.formValues, startDate: date },
    });
  }
  handleEndDate(date: Date) {
    this.setState({ formValues: { ...this.state.formValues, endDate: date } });
  }
  handleEmail(text: string) {
    let regex = isEmail("email", text);
    if (regex.status === false) {
      this.setState({
        formValues: { ...this.state.formValues, developerEmail: text },
        formError: {
          ...this.state.formError,
          developerEmailError: "Please enter valid email",
        },
      });
      return false;
    } else {
      this.setState({
        formValues: { ...this.state.formValues, developerEmail: text },
        formError: { ...this.state.formError, developerEmailError: "" },
      });
      return true;
    }
  }
  handleFullName(text: string) {
    this.setState({
      formValues: { ...this.state.formValues, developerFullName: text },
      formError: { ...this.state.formError, developerFullNameError: "" },
    });
  }
  handlePhoneNumber(text: string) {
    this.setState({
      formValues: { ...this.state.formValues, phoneNumber: text },
      formError: { ...this.state.formError, phoneNumberError: "" },
    });
  }
  imagePicker = async () => {
    try {
      const imageResults = await DocumentPicker.pickMultiple({
        type: [DocumentPicker.types.images],
      });
      let imgData: IPresignUrl[] = this.state.images;
      let newImageUrls: IImageUrl[] = this.state.imageUrl;

      imageResults.forEach((item: DocumentPickerResponse) => {
        imgData.push({
          file_name: item.name,
          file_size: item.size ? item.size : 0,
          category: "image",
        });
      });
      imageResults.forEach((item: DocumentPickerResponse) => {
        newImageUrls.push({
          name: item.name,
          type: item.type,
          uri: item.uri,
        });
      });
      this.setState({ images: imgData, imageUrl: newImageUrls });
    } catch (error) { }
  };
  docPicker = async () => {
    try {
      const documentResults = await DocumentPicker.pickMultiple({
        type: [DocumentPicker.types.allFiles],
      });
      let documentData: IPresignUrl[] = this.state.documents;
      let newDocumentUrls: IImageUrl[] = this.state.documentUrl;
      documentResults.forEach((item: DocumentPickerResponse) => {
        documentData.push({
          file_name: item.name,
          file_size: item.size ? item.size : 0,
          category: "document",
        });
      });
      documentResults.forEach((item: DocumentPickerResponse) => {
        newDocumentUrls.push({
          name: item.name,
          type: item.type,
          uri: item.uri,
        });
      });
      this.setState({ documents: documentData, documentUrl: newDocumentUrls });
    } catch (error) { }
  };

  submitData = async () => {
    if (this.state.formValues.projectName == "")
      this.setState({
        formError: {
          ...this.state.formError,
          projectNameError: "Please enter project name ",
        },
      });
    if (this.state.formValues.projectDescription == "")
      this.setState({
        formError: {
          ...this.state.formError,
          projectDescriptionError: "Please Enter Project Description",
        },
      });
    if (this.state.formValues.clientDetails == "")
      this.setState({
        formError: {
          ...this.state.formError,
          clientDetailsError: "Please Enter Client Details ",
        },
      });
    if (this.state.formValues.teamSize == "")
      this.setState({
        formError: {
          ...this.state.formError,
          teamSizeError: "Please Enter Team Size",
        },
      });
    if (this.state.formValues.projectUrl == "")
      this.setState({
        formError: {
          ...this.state.formError,
          projectUrlError: "Please Enter Project Cose",
        },
      });
    if (this.state.formValues.developerFullName == "")
      this.setState({
        formError: {
          ...this.state.formError,
          developerFullNameError: "Please Enter Full Name",
        },
      });
    if (this.state.formValues.phoneNumber == "")
      this.setState({
        formError: {
          ...this.state.formError,
          phoneNumberError: "Please Enter Phone Number",
        },
      });
    if (this.state.formValues.developerEmail == "")
      this.setState({
        formError: {
          ...this.state.formError,
          developerEmailError: "Please Enter Email",
        },
      });
    this.createProjectPortfolioAPI();
  };

  removeImage = (itemImage: IImageUrl) => {
    let finalData = this.state.imageUrl.filter(
      (elementImage) => elementImage.uri !== itemImage.uri
    );
    if (this.state.projectId && itemImage.id) {
      let uploadMediaBlock = new UploadMedia3(this.props);
      uploadMediaBlock.deleteUploadMedia(itemImage.id);
    }
    this.setState({
      imageUrl: finalData,
    });
  };

  removeDoc = (itemDoc: IImageUrl) => {
    let finalData = this.state.documentUrl.filter(
      (elementDoc) => elementDoc.uri !== itemDoc.uri
    );
    if (this.state.projectId && itemDoc.id) {
      let uploadMediaBlock = new UploadMedia3(this.props);
      uploadMediaBlock.deleteUploadMedia(itemDoc.id);
    }
    this.setState({
      documentUrl: finalData,
    });
  };
  isDateValid(date: Date) {
    let validDate1 = moment(date.toString(), "DD-MM-YYYY", true).isValid();
    if (validDate1) {
      return date;
    } else {
      return moment(date).format("DD/MM/YYYY");
    }
  }

  createProjectPortfolioAPI = async () => {

    let newProjectData = {
      project_portfolio: {
        project_name: this.state.formValues.projectName,
        start_date: this.isDateValid(
          this.state.formValues.startDate
        ) as unknown as string,
        end_date: this.isDateValid(
          this.state.formValues.endDate
        ) as unknown as string,
        url: this.state.formValues.projectUrl,
        description: this.state.formValues.projectDescription,
        email: this.state.formValues.developerEmail,
        full_phone_number: this.state.formValues.phoneNumber,
        full_name: this.state.formValues.developerFullName,
        project_portfolio_attributes: {
          id: this.state.projectDataId,
          project_name: this.state.formValues.projectName,
          team_size: this.state.formValues.teamSize,
          client_details: this.state.formValues.clientDetails,
        },
      },
    };
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };
    this.setState({ loading: true });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCreateProjectCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.state.projectId
        ? `${configJSON.createProjectEndPoint}/${this.state.projectId}`
        : configJSON.createProjectEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(newProjectData)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      this.state.projectId
        ? configJSON.putAPiEndMethod
        : configJSON.postAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getProjectbyID = async (projectID: string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    this.setState({ loading: true });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetProjectbyIDListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteProjectAPIEndPoint + projectID
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  // web events
  handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      formValues: {
        ...this.state.formValues,
        [event.target.name]: event.target.value,
      },
    });
    if (event.target.validity.valueMissing) {
      this.setState({
        formError: {
          ...this.state.formError,
          [event.target.name + "Error"]: "Field is required",
        },
      });
    } else if (event.target.validity.patternMismatch) {
      this.setState({
        formError: {
          ...this.state.formError,
          [event.target.name + "Error"]:
            event.target.name === "developerEmail"
              ? "Email is invalid"
              : "Only numbers allowed",
        },
      });
    } else if (
      event.target.validity.tooShort ||
      event.target.validity.tooLong
    ) {
      this.setState({
        formError: {
          ...this.state.formError,
          [event.target.name + "Error"]: "Phone number must be in 1 to 10",
        },
      });
    } else if (event.target.validity.valid) {
      this.setState({
        formError: {
          ...this.state.formError,
          [event.target.name + "Error"]: "",
        },
      });
    }
  };

  handleSelectFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length) {
      for (let singaleFile of files) {
        if (singaleFile.type.includes("image") && singaleFile instanceof File) {
          const newImages: IPresignUrl[] = [];
          const newImageUrls: {
            name: string;
            imageUrl: ImageUrl;
            imageObj: Blob;
          }[] = [];
          let fileReader = new FileReader();
          fileReader.readAsDataURL(singaleFile);
          fileReader.onload = (event) => {
            if (event.target) {
              newImageUrls.push({
                name: singaleFile.name,
                imageUrl: event.target.result,
                imageObj: singaleFile,
              });
              newImages.push({
                file_name: singaleFile.name,
                file_size: singaleFile.size,
                category: "image",
              });
              this.setState({
                formValues: {
                  ...this.state.formValues,
                  projectImagesUrl: [
                    ...this.state.formValues.projectImagesUrl,
                    ...newImageUrls,
                  ],
                  projectImages: [
                    ...this.state.formValues.projectImages,
                    ...newImages,
                  ],
                },
              });
            }
          };
        } else {
          this.showAlert("Error", "File type is not allowed!");
        }
      }
    }
  };

  handleSelectDocFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length) {
      for (let singaleFile of files) {
        if (
          ["application", "text"].includes(
            singaleFile.type.split("/")[0]
          ) &&
          singaleFile instanceof File
        ) {
          const newDocuments: IPresignUrl[] = [];
          const newDocumentUrls: {
            name: string;
            imageUrl: ImageUrl;
            imageObj: Blob;
          }[] = [];
          let fileReader = new FileReader();
          fileReader.readAsDataURL(singaleFile);
          fileReader.onload = (event) => {
            if (event.target) {
              newDocumentUrls.push({
                name: singaleFile.name,
                imageUrl: event.target.result,
                imageObj: singaleFile,
              });
              newDocuments.push({
                file_name: singaleFile.name,
                file_size: singaleFile.size,
                category: "document",
              });
              this.setState({
                formValues: {
                  ...this.state.formValues,
                  projectDocumentsUrl: [
                    ...this.state.formValues.projectDocumentsUrl,
                    ...newDocumentUrls,
                  ],
                  projectDocuments: [
                    ...this.state.formValues.projectDocuments,
                    ...newDocuments,
                  ],
                },
              });
            }
          };
        } else {
          this.showAlert("Error", "File type is not allowed!");
        }
      }
    }
  };

  handleRemoveImage = (imageName: string) => {
    const filteredImageUrl = this.state.formValues.projectImagesUrl.filter(
      (element) => element.name !== imageName
    );
    let filteredImage;
    if (this.state.projectId) {
      filteredImage = this.state.formValues.projectImages.filter(
        (element: IPresignUrl) => element.file_name !== imageName
      );
      const removedImg = this.state.formValues.projectImages.find(
        (element) => element.file_name === imageName
      );
      if (removedImg && removedImg.id) {
        let uploadMediaBlock = new UploadMedia3(this.props);
        uploadMediaBlock.deleteUploadMedia(removedImg.id);
      }
    } else {
      filteredImage = this.state.formValues.projectImages.filter(
        (element: IPresignUrl) => element.file_name !== imageName
      );
    }
    this.setState({
      formValues: {
        ...this.state.formValues,
        projectImagesUrl: filteredImageUrl,
        projectImages: filteredImage,
      },
    });
  };

  handleRemoveDoc = (documentName: string) => {
    const filteredDocUrl = this.state.formValues.projectDocumentsUrl.filter(
      (element) => element.name !== documentName
    );
    let filteredDoc;
    if (this.state.projectId) {
      filteredDoc = this.state.formValues.projectDocuments.filter(
        (element: IPresignUrl) => element.file_name !== documentName
      );
      const removedDoc = this.state.formValues.projectDocuments.find(
        (element) => element.file_name === documentName
      );
      if (removedDoc && removedDoc.id) {
        let uploadMediaBlock = new UploadMedia3(this.props);
        uploadMediaBlock.deleteUploadMedia(removedDoc.id);
      }
    } else {
      filteredDoc = this.state.formValues.projectDocuments.filter(
        (element: IPresignUrl) => element.file_name !== documentName
      );
    }
    this.setState({
      formValues: {
        ...this.state.formValues,
        projectDocumentsUrl: filteredDocUrl,
        projectDocuments: filteredDoc,
      },
    });
  };

  handleSubmit = async () => {
    const isFormValues = Object.values(this.state.formValues).every(
      (element) => Object.values(element).length
    );
    const isFormErrors = Object.values(this.state.formError).some(
      (element) => element
    );
    if (!isFormValues || isFormErrors) {
      this.showAlert("Error", "Please enter valid information");
      return false;
    }
    this.setState({ isDisabled: true });
    if (!this.state.isDisabled)
      this.createProjectPortfolioAPI();
  };
  goBack() {
    this.props.navigation.goBack();
  }
  // Customizable Area End
}
