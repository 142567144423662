import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  visibiltyOfNewPassword: boolean;
  visibilityOfConfirmPassword: boolean;
  validvariable: boolean;
  msgvariable: string;
  popupOpen: boolean;
  passwordDetails: any;
  isErrorPassword: any;
  isActiveNewPasswordInput: boolean;
  isActiveConfirmPasswordInput: boolean;
  npNotification: { type: string, open: boolean, message: string };
  // Customizable Area End
}

interface SS {
  // Customizable Area Start

  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class OtpScreenController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  newPasswordUpdateApiCalled: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.SessionResponseToken)
    ];
    this.state = {
      // Customizable Area End
      visibiltyOfNewPassword: false,
      visibilityOfConfirmPassword: false,
      validvariable: true,
      popupOpen: false,
      msgvariable: '',
      passwordDetails: {
        old_password: '',
        new_password: '',
        confirm_new_password: '',
      },
      isErrorPassword: {
        old_password: false,
        new_password: false,
        confirm_new_password: false,
      },
      isActiveNewPasswordInput: false,
      isActiveConfirmPasswordInput: false,
      npNotification: { type: "", open: false, message: "" },
    };
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
  }

  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (this.newPasswordUpdateApiCalled === apiRequestCallId) {
        if (responseJson) {
          if (responseJson?.errors && Array.isArray(responseJson.errors) && Array(responseJson.errors).length > 0) {
            this.setState({ npNotification: { type: "error", open: true, message: responseJson.errors[0].token } });
          } else {
            this.handleClose(true);
          }
        }
      }

    }
  }

  handleClickShowNewPassword = () => {
    this.state.passwordDetails && this.state.passwordDetails.new_password &&
      this.setState({
        visibiltyOfNewPassword: !this.state.visibiltyOfNewPassword
      })
  };

  handleClickShowConfirmPassword = () => {
    this.state.passwordDetails && this.state.passwordDetails.confirm_new_password &&
      this.setState({
        visibilityOfConfirmPassword: !this.state.visibilityOfConfirmPassword
      })
  };

  handleClose = (val: boolean) => {
    this.setState({
      popupOpen: val
    })
  };

  handleFoucsNewPasswordInput = () => {
    this.setState({ isActiveNewPasswordInput: true });
  }

  handleLeaveFoucsNewPasswordInput = () => {
    this.setState({ isActiveNewPasswordInput: false });
  }

  handleFoucsConfirmPasswordInput = () => {
    this.setState({ isActiveConfirmPasswordInput: true });
  }

  handleLeaveFoucsConfirmPasswordInput = () => {
    this.setState({ isActiveConfirmPasswordInput: false });
  }

  handleChangePasswordDetails = (e: any) => {

    if (e.target.value === '') {
      this.setState({ msgvariable: "Please fill out this field" })
      this.setState({ validvariable: true })


    }
    else {
      this.setState({ msgvariable: "" })
      this.setState({ validvariable: false })

    }

    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
    if (regex.test(e.target.value) || e.target.value.includes('')) {

      this.setState({
        passwordDetails: { ...this.state.passwordDetails, [e.target.name]: e.target.value, },
        isErrorPassword: { ...this.state.isErrorPassword, [e.target.name]: false }

      });
      this.setState({ msgvariable: "" });
    }
    if (regex.test(e.target.value) == false) {
      this.setState({
        isErrorPassword: { ...this.state.isErrorPassword, [e.target.name]: true }
      });
    }
  };

  handleNPCloseNotification = () => {
    this.setState({ npNotification: { type: "", open: false, message: "" } });
  }

  updatePassword = async () => {

    if (this.state.passwordDetails?.new_password != this.state.passwordDetails?.confirm_new_password) {
      this.setState({ npNotification: { type: "error", open: true, message: "New password and confirm new password did not match" } });
      return;
    } else if (this.state.isErrorPassword && (this.state.isErrorPassword.new_password || this.state.isErrorPassword.confirm_new_password_)) {
      return false;
    } else {
      if (this.state.validvariable === true) {
        this.setState({ msgvariable: "Please fill out this field" })
        return
      }
      const authenticationToken = await getStorageData('token', false)
      const header = {
        "Content-Type": configJSON.forgotPasswordAPiContentType,
        "token": authenticationToken
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      //GO TO REQUEST STATE
      this.newPasswordUpdateApiCalled = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.newPasswordUpdateApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      let attr = {
        password: this.state.passwordDetails && this.state.passwordDetails.new_password,
      }

      const data = {
        verify_phone_or_email: configJSON.firstInputAutoCompleteType,
        attributes: attr,
      };

      const httpBody = {
        data: data
      };

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPutMethod
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }
  // Customizable Area End
}