Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.putApiMethod = "PUT";
exports.deleteApiMethod = "DELETE";

exports.groupsApiEndPoint = "account_groups/groups";
exports.tasksListApiEndPoint = "tasks/task_lists";
exports.tasksApiEndPoint = "tasks/tasks";
exports.tasksAccountsApiEndPoint = "roles_permissions/accounts/list_users";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.selectTaskPriority = "Select task prority";
exports.selectTaskStatus = "Select task status";
exports.selectAccount = "Select account";
exports.selectGroup = "Select group";
exports.selectTasks = "Select tasks";
exports.textTitle = "Title";
exports.textDescription = "Description";
exports.textAdd = "Add";
exports.textTasks = "Tasks";
exports.textTaskList = "Task Lists";
exports.textEdit = "Edit";
exports.textDelete = "Delete";
exports.textSave = "Save";
exports.textClose = "Close";
exports.textAccounts = "Accounts";
exports.textGroups = "Groups";
exports.textAddTask = "Add Task";
exports.textAddTaskList = "Add Task List";
exports.textShowTaskList = "Show Task List";
exports.textShowTask = "Show Task";
exports.textId = "Id";
exports.textPriority = "Priority";
exports.textStatus = "Status";
exports.textActions = "Actions";
exports.textAssign = "Assign";
exports.textAssignedTo = "Assigned To";
exports.textName = "Name";
exports.textCreatedAt = "Created At";
exports.textUpdatedAt = "Updated At";
exports.textOr = "OR";
exports.getprojectlistendpoint="bx_block_projecttasktracking2/my_task?"
exports.exampleApiContentType = "application/json";
exports.getInvoiceListEndPoint="bx_block_invoicebilling/project_invoices?&project_id"
exports.countraiseinvoiceendpoint="bx_block_invoicebilling/get_invoice_raised?project_id"
exports.sortbymytaskendpoint="bx_block_invoicebilling/project_invoices?filter_type=sort_by"
exports.searchmytaskendpoint='bx_block_invoicebilling/project_invoices?'
exports.getamountListendpoint="bx_block_invoicebilling/get_invoice_amount?project_id"
exports.getdepartmentListendpoint="bx_block_invoicebilling/get_department?project_id"
exports.getJobroleListendPoint="bx_block_invoicebilling/get_job_role?project_id"
exports.getAppliedinvoiceendpoint="bx_block_invoicebilling/project_invoices?filter_type=filter"
exports.myinvoicedetailendpoint="bx_block_invoicebilling/invoices"
exports.raiseinvoiceendpoint="bx_block_invoicebilling/invoices"
exports.raiseinvoicemethodtype = "POST"
exports.Rejectstatusendpoint="bx_block_invoicebilling/invoices/handle_invoice"
exports.Rejectmethodtype="PUT"
exports.historyAllProjectsAPIEndPoint="account_block/vendor_history?type=project";
exports.historyAllStudiosAPIEndPoint="account_block/vendor_history?type=studio";
exports.phHistoryAllProjectsAPIEndPoint="account_block/production_house_history?type=project";
exports.phHistoryAllStudiosAPIEndPoint="account_block/production_house_history?type=studio";
// Customizable Area End
