import React, {createRef} from "react";

import {
    // Customizable Area Start
    TypographyProps,
    Container,
    Grid,
    Box,Typography,
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import InvoiceBillingController, {
    Props,
    configJSON,
  } from "./InvoiceBillingController.web";
import Header from "../../../components/src/Header.web";
import SideBar from "../../../components/src/SideBar";
import Footer from "../../../components/src/Footer";
import Loader from "../../../components/src/Loader.web";
import Pagination from "@material-ui/lab/Pagination";
import AllInvoiceProjectDetails from "../../../components/src/AllInvoiceProjectsDetails";
// Customizable Area End

export default class AllInvoiceRequests extends InvoiceBillingController {
    stepperRef: React.RefObject<any>;
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.stepperRef = createRef();
        // Customizable Area End
    }
   
    

    renderAllInvoiceRequests(invoicesideBarStatusPH: boolean  = this.state.isinvoiceBillingSideBarOpen) {
        return (
        <Grid style={webStyle.contentSetupAllinvoice}>
              <Loader loading={this.state.isLoading}></Loader>
            < Header
                navigate={this.props.navigation.navigate}
                sideBarStatus={invoicesideBarStatusPH}
                dropDownStatus={this.state.isInvoiceHeaderDropdownOpenVal}
                handleDropdown={this.handleInvoiceHeaderDropdownVal}
                handleSideBar={this.handleInvoiceSideBar}  
            />
            <Grid style={webStyle.mainContentAllinvoiceRequest as React.CSSProperties}>
                <Box sx={webStyle.blockInfoAllinvoice}>
                    <Box sx={webStyle.activeBlockAllinvoiceRequest}>
                        <Typography variant="h3" style={webStyle.activeBlockAllinvoiceRequest as TypographyProps}><div style={{ fontSize: "15px" }}></div>Invoices Requests</Typography>
                    </Box>
                    <Typography variant="h6" style={webStyle.blockPathAllinvoiceRequest as TypographyProps}><span style={webStyle.highlightedTextAllinvoiceRequest} data-test-id="home-navigation" >Home</span> / Invoices Requests</Typography>
                    <Box>
                        <Typography variant="subtitle2" style={{ color: "rgb(109, 137, 175)" }}>All {this.state.totalInvoiceCount} Invoices Requests</Typography>
                    </Box>
                </Box>
                 <Box className="tabsContentAllInvoice" style={webStyle.tabsContentAllInvoiceRequest as React.CSSProperties}  >
                    {
                    <Grid container spacing={4}>
                    {this.state.invoiceAllRequest.length === 0 ? (
                      <Typography style={{ width: '100%', textAlign: 'center', marginTop: '30px', marginBottom: '30px', color: '#6d89af', fontFamily: 'Urbanist', fontSize: 14, fontWeight: 600 }}>No record available</Typography>
                    ) : (
                      this.state.invoiceAllRequest.map((ele: any, index: number | string) => (
                        <Grid xs={12} style={{ cursor: 'pointer' }} sm={6} md={this.state.isinvoiceBillingSideBarOpen ? 4 : 3} item onClick={() => this.navigateToinvoicesRequest(ele.id, ele.attributes.project_name)}>
                          <AllInvoiceProjectDetails labels={{ "invoicesText": configJSON.invoicesText }} data={ele} />
                        </Grid>
                      ))
                    )}
                  </Grid>
                    }
                    </Box>
                    {this.state.count ?
                            (<Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Pagination style={webStyle.paginationWrapperInvoice} count={Math.ceil((this.state.count) / 9)} page={this.state.currentpage} onChange={this.handlePageChange} />
                                </Grid>
                            </Grid>)
                            : null}
                    </Grid>
            <Footer />
        </Grid>
        )
    }
    
    render() {
        return ( 
            <Container maxWidth="xl" className="user_detail_container">
                {
                    this.state.isinvoiceBillingSideBarOpen?
                        <Grid container spacing={0}>
                            <Grid style={webStyle.sideBarAllinvoiceRequests}  item xs={3}>
                                <SideBar
                                   userData={{ age: 25 }}
                                    activeMenuItem={"Invoices Requests"}
                                    navigate={this.props.navigation.navigate}
                                    handleSideBar={this.handleInvoiceSideBar}
                                   
                                    />
                            </Grid>
                            <Grid item xs={9}>{this.renderAllInvoiceRequests(this.state.isinvoiceBillingSideBarOpen)}</Grid>
                        </Grid> :
                        <Grid item xs={12}>
                            {
                                this.renderAllInvoiceRequests(this.state.isinvoiceBillingSideBarOpen)
                            }
                           </Grid>
                }
            </Container >
        );  }
}

// Customizable Area Start
const webStyle = {
   
    contentSetupAllinvoice: {
        borderLeft: "1px solid #E3EAF4",
    },
    
    sideBarAllinvoiceRequests: {
        minHeight: "100vh",
        maxWidth: "23%",
    },
    blockInfoAllinvoice: {

    },
    blockPathAllinvoiceRequest: {
        fontSize: "14px",
        letterSpacing: "1px",
        color: "#6D89AF",
        marginBottom: "24px",
        fontWeight: 500
      },
      highlightedTextAllinvoiceRequest: {
        color: "#F5C42C",
        textDecoration: 'underline',
        textDecorationColor: "#F5C42C",
        fontWeight: 600,
        cursor:"pointer"
      },
    activeBlockAllinvoiceRequest: {
        fontSize: "28px",
        fontWeight: "600",
        letterSpacing: "1px",
        display: "flex",
        flexDirection: "row",
        lineHeight: "33px",
        alignItems: 'center',
        marginBottom: '8px',   
      },
     
      mainContentAllinvoiceRequest: {
        width: "100%",
        backgroundColor: "#F8FAFE",
        boxSizing: "border-box",
        padding: "24px",
        height: "100%"
      },
      tabsContentAllInvoiceRequest: {
        paddingTop: "16px",
        // paddingLeft:"24px",
        display: "flex",
        gap: "1.5rem",
        flexWrap:"wrap",
        marginBottom: '15px'
    },
    paginationWrapperInvoice: {
        marginTop: '20px',
        display: 'flex',
        justifyContent: 'center',
    }
};
// Customizable Area End