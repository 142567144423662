import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area StartBookStudioSuccessModelController
  open: boolean;
  handleClose: any;
  studioName: string;
  studioImage: string;
  handleGiveRatingAPICall: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  studioBookType: string;
  rating: any;
  review: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class StudioDetailRatingModelController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      studioBookType: "per_day",
      rating: 0,
      review: "",
      // Customizable Area End
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleChangeRating = (event: any, newValue: any) => {
    this.setState({ rating: newValue });
  }

  handleChangeReview = (event: any) => {
    this.setState({ review: event.target.value });
  }

  handleGiveRating = () => {
    const ratingData = {
      review: {
        comment: this.state.review,
        given_star: this.state.rating,
        reviewable_id: this.props.id,
        reviewable_type: "BxBlockCustomizableformworkflow3::Studio"
      }
    };
    this.props.handleGiveRatingAPICall(ratingData);
  }
  // Customizable Area End
}
