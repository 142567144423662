import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ITask, ITaskList } from "./types";
import { ChangeEvent,  } from "react";
import { getStorageData, setStorageData} from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  id: number;
  // Customizable Area Start
  name: string;
  editMode: boolean;
  token: string;
  taskLists: ITaskList[];
  tasksData: ITask[];
  selectedTasks: ITask[];
  isVisibleModal: boolean;
  dropdownTasks: boolean;
  isDropdownOpen: boolean;
  isSideBarOpen: boolean;
  isSideBarOpen1: boolean;
  showandhidden:boolean;
  showAndHiddenSecondPipeline:boolean;
  showAndHiddenCleared:boolean;
  open: boolean;
  open1:boolean;
  openReject:boolean
  openHold:boolean;
  imageTask:string
  openraiseinvoice:boolean
  txtSavedValue:string;
  allinvoiceData:any
  invoiceDataList:any
  invoicedetaillist:any
  count: number;
  currentpage:number;
  currentpageMyTask:number;
  countpending:number;
  countcomplete:number;
  countreject:number;
  counthold:number;
  countApproved:number;
  countall:number;
  countMyTask:number;
  selectstatus: string;
  searchValue: string;
  searchValueMyTask:string;
  raiseinvoicetype:any;
  SelectStatus:any;
  amountdatalist:any
  departmentlist:any
  jobroledatalist:any
  jobrolevalue:string;
  departmentvalue:string;
  statusvalue:string;
  amountvalue:string;
  invoiceselectTask:string;
    selectprojectTask:any;
    selectinvoicetypeTask:string;
    selectinvoicenameTask:string;
    selectinvoicedetailTask:string;
    selectinvoicenoTask:string;
    selectinvoiceamountTask:string;
    isModelOpen: boolean;
    modelType:string;
    raiseinvoicedetail:any;
    RejectCommentValue:string;
    HoldCommentValue:string;
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class TaskListController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getTaskListsApiCallId = "";
  postTaskListApiCallId = "";
  putTaskListApiCallId = "";
  deleteTaskListApiCallId = "";
  getTasksApiCallId = "";
  getprojectlistapiid="";
  getInvoiceCallid: string ="";
  getraiseinvoiceapiid:string="";
  getsortbyinvoicecallid:string="";
  getsearchinvoicecallid:string="";
  getProjectsearchcallid:string=""
  getamountListapiid:string="";
  getdepartmentListapiid:string="";
  getjobroleListapiid:string="";
  getappliedinvoicevalue:string="";
  getinvoiceDetailApiCalledId:string="";
  raiseinvoiceapicallid:string="";
  Rejectbuttoncallid:string="";
  Approvedbuttoncallid:string="";
  Holdbuttoncallid:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      id: 0,
      name: "",
      editMode: false,
      token: "",
      taskLists: [],
      tasksData: [],
      selectedTasks: [],
      isVisibleModal: false,
      dropdownTasks: false,
      isDropdownOpen: false,
      isSideBarOpen: true,
      isSideBarOpen1: true,
      showandhidden:true,
      showAndHiddenSecondPipeline:true,
      showAndHiddenCleared:true,
      open: false,
      open1:false,
      openReject:false,
      openHold:false,
      txtSavedValue:'',
      allinvoiceData:[],
      invoiceDataList:[],
      invoicedetaillist:null,
      count: 0,
      currentpage:1,
      currentpageMyTask:1,
      countpending:0,
      countcomplete:0,
      countreject:0,
      counthold:0,
      countApproved:0,
      countall:0,
      countMyTask:0,
      selectstatus: '',
      searchValue: '',
      searchValueMyTask:'',
      raiseinvoicetype:null,
      SelectStatus:'',
      amountdatalist:[],
      departmentlist:[],
      jobroledatalist:[],
      jobrolevalue:'',
      departmentvalue:'',
      amountvalue:'',
      statusvalue:'',
  openraiseinvoice:false,
  imageTask:'',
  invoiceselectTask:"",
  selectprojectTask:0,
  selectinvoicetypeTask:'',
  selectinvoicenameTask:"",
  selectinvoicedetailTask:'',
  selectinvoicenoTask:'',
  selectinvoiceamountTask:'',
  isModelOpen: false,
  modelType:'',
  raiseinvoicedetail:[],
  RejectCommentValue:'',
  HoldCommentValue:'',
      
      

      
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getprojectlistmytask()
    this.getInvoiceListing()
    this.getToken();
  
    this.getinvoiceraisemytask()
    this.getinvoiceamountlistmytask()
    this.getdepartmentlistmytask();
    this.getjobrolelistmytask();
    this.getinvoiceDetails();
    let isSideBarOpen=await getStorageData("sidebarvalue");
    this.setState({isSideBarOpen:JSON.parse(isSideBarOpen)})
  }

 
  async receive(from: string, message: Message) {

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
        let msgvalueTask = message.getData(getName(MessageEnum.AuthTokenDataMessage));

        this.showAlert(
            "Change Value",
            "From:" + this.state.txtSavedValue + "To:" + msgvalueTask
        );

        this.setState({ txtSavedValue: msgvalueTask });
    }

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
        const apiRequestvalueTask = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))

        const responseValueTask = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))

        if (apiRequestvalueTask === this.getprojectlistapiid) {
            this.setState({ allinvoiceData: responseValueTask.data })    
            this.setState({countMyTask:responseValueTask.meta.total_project_count})
        }
        if(apiRequestvalueTask===this.getProjectsearchcallid)
      {
        this.setState({ allinvoiceData: responseValueTask.data })    
            this.setState({countMyTask:responseValueTask.meta.total_project_count})

      }
      if (apiRequestvalueTask === this.getInvoiceCallid) {
          this.setState({count:this.state.countall})
          this.setState({invoiceDataList:responseValueTask.data})
        
      }
      if(apiRequestvalueTask===this.getraiseinvoiceapiid)
      {
      
        this.setState({
           countApproved:responseValueTask.status_counts.approved,
           countcomplete:responseValueTask.status_counts.completed,
           counthold:responseValueTask.status_counts.holded,
           countpending:responseValueTask.status_counts.pending,
           countreject:responseValueTask.status_counts.rejected,
          countall:responseValueTask.all,
          count:responseValueTask.all,
          raiseinvoicetype:responseValueTask.status_counts

        })


      }
      if(apiRequestvalueTask===this.getsortbyinvoicecallid)
      {
          this.sortapiresponseList(apiRequestvalueTask,responseValueTask)
      }
      if(apiRequestvalueTask===this.getsearchinvoicecallid)
      {
        this.setState({invoiceDataList:responseValueTask.data})
        this.setState({count:responseValueTask.meta.total_invoices})
      }
      this.mytaskapiresponse(apiRequestvalueTask,responseValueTask)

      }}
      mytaskapiresponse=async(apiRequestvalueTask:any,responseValueTask:any)=>{
        if(apiRequestvalueTask===this.getamountListapiid)
        {
           console.log("amount",responseValueTask.invoice_amounts)
           this.setState({amountdatalist:responseValueTask.invoice_amounts})
        }
        if(apiRequestvalueTask===this.getdepartmentListapiid)
        {
          console.log("department",responseValueTask.departments)
          this.setState({departmentlist:responseValueTask.departments})
        }
        if(apiRequestvalueTask===this.getjobroleListapiid)
        {
          console.log("jobrole",responseValueTask.job_roles)
          this.setState({jobroledatalist:responseValueTask.job_roles})
        }
        if(apiRequestvalueTask===this.getappliedinvoicevalue)
        {
          console.log("ddddd",responseValueTask)
          this.setState({invoiceDataList:responseValueTask.data,
            count:responseValueTask.meta.total_invoices})
        }
       if(apiRequestvalueTask===this.getinvoiceDetailApiCalledId)
       {
         this.setState({
          invoicedetaillist:responseValueTask.data
  
         })
       }
        if(apiRequestvalueTask===this.raiseinvoiceapicallid)
       {
           this.raiseinvoiceresponsedataList(responseValueTask)
             
       }
       if(apiRequestvalueTask===this.Rejectbuttoncallid)
       {
        this.getinvoiceDetails();
        this.handleCloseReject();
       }
       if(apiRequestvalueTask===this.Holdbuttoncallid)
       {
        this.getinvoiceDetails();
        this.handleCloseHold();
       }
       if(apiRequestvalueTask===this.Approvedbuttoncallid)
       {
        console.log("approve....",responseValueTask)
        this.getinvoiceDetails()
       }
      }
      sortapiresponseList=async(apiRequestvalueTask:any,responseValueTask:any)=>{
       
        
        console.log("rrrrrrrrrrrrrr",responseValueTask)
        this.setState({ invoiceDataList: responseValueTask.data })   
        this.setState({count:responseValueTask.meta.total_invoices})
      }
      raiseinvoiceresponsedataList = async (responseValue: { errors: { token: string }[], data: any }) => {
        
        this.handleModelOpen();
          if (responseValue.data) {
            
            this.setState({raiseinvoicedetail:responseValue.data})
           setStorageData("invoiceid",responseValue.data.id)
           setStorageData("typeofinvoice",responseValue?.data?.attributes?.type_of_invoice)
           this.handleClose1()
           let id = await getStorageData("id");
           this.getInvoiceCallid = await this.apiCallinvoice(configJSON.getApiMethod, configJSON.getInvoiceListEndPoint+`=${id}&page=1&per_page=9`)
            this.setState({ modelType: "successModel" });
            
          } else if (responseValue.errors[0]) {
            this.setState({open1:false})
            alert(responseValue.errors[0])
          }
      
         
      
      
          
    
      };


  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  isStringNullOrBlank = (str: string) => {
    return str === null || str.length === 0;
  };

  hideModal = () => {
    this.setState({ isVisibleModal: !this.state.isVisibleModal });
  };

  showAddModal = () => {
    this.getTasks(this.state.token);
    this.setState({
      name: "",
      dropdownTasks: false,
      isVisibleModal: !this.state.isVisibleModal,
      editMode: false,
    });
  };

  handleInputName = (name: string) => {
    this.setState({ name });
  };

  expandTasksView = () => {
    this.setState({ dropdownTasks: !this.state.dropdownTasks });
  };

  showEditModal = () => {
    this.setState({
      isVisibleModal: !this.state.isVisibleModal,
      editMode: true,
    });
  };

  handleEditSelect = (item: ITaskList) => {
    this.getTasks(this.state.token);
    this.setState({
      id: item.id,
      name: item.attributes.name,
      selectedTasks: item.attributes.tasks,
      dropdownTasks: false,
    });
    this.showEditModal();
  };

  handleTasksSelect = (dataId: string) => {
    let newData = this.state.tasksData.map((task: ITask) => {
      if (task.id === dataId) {
        return { ...task, isSelected: !task.isSelected };
      }
      return task;
    });
    this.setState({ tasksData: newData });
  };

  // Function to fetch task list from the API
  getTaskLists = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTaskListsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.tasksListApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Function to add new task list and send it to API
  addTaskList = () => {
    if (this.isStringNullOrBlank(this.state.name)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory,
        ""
      );
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
      };
      let taskIds: string[] = [];
      this.state.tasksData.map((task: ITask) => {
        if (task.isSelected) {
          taskIds.push(task.id);
        }
      });
      const httpBody = {
        name: this.state.name,
        task_ids: taskIds,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.postTaskListApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.tasksListApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  // Function to edit task list and send it to API
  editTaskList = (tasklistId: number) => {
    if (this.isStringNullOrBlank(this.state.name)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory,
        ""
      );
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
      };
      let taskIds: string[] = [];
      this.state.tasksData.map((task: ITask) => {
        if (task.isSelected) {
          taskIds.push(task.id);
        }
      });
      const httpBody = {
        name: this.state.name,
        task_ids: taskIds,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.putTaskListApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.tasksListApiEndPoint + "/" + `${tasklistId}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.putApiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  // Function to delete task list and send it to API
  deleteTaskList = (tasklistId: number) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteTaskListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.tasksListApiEndPoint + "/" + `${tasklistId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Function to fetch tasks from the API
  getTasks = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTasksApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.tasksApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleModelOpen = () => {
    this.setState({ isModelOpen: true });
  }
  handleOpen1 = () => {
    console.log("rrrr", this.state.open)
    this.setState({ open1: true })
    console.log("rrrr", this.state.open)
};
handleOpenRaiseinvoice=()=>{

  this.setState({openraiseinvoice:true})
}
handleModelClose = () => {
  this.setState({ isModelOpen: false, modelType: "" });
}
handledatavalue = (id:any) => {
  this.getinvoiceDetails()
  this.props.navigation.navigate("InvoiceListingDetail");
  setStorageData("id", id)   
  
};
handleNavigation = (page: string) => {
  this.props.navigation.navigate(page)
};

handleClose1 = () => {
    this.setState({ open1: false })
    console.log("onclose", this.state.open)
};
handleCloseRaiseinvoice = () => {
  this.setState({ openraiseinvoice: false })
  
};
handleCloseReject = () => {
  this.setState({ openReject: false })
  
};
handleOpenReject = () => {
  this.setState({ openReject: true })
  
};
handleCloseHold = () => {
  this.setState({ openHold: false })
  
};
handleOpenHold = () => {
  this.setState({ openHold: true })
  
};
handleImage = async(e: any) => {
  this.setState({ imageTask: e.target.files[0] });
 
};
handleSideBar = () => {
  this.setState({
      isSideBarOpen: !(this.state.isSideBarOpen)
  } , async() => {
    setStorageData("sidebarvalue",this.state.isSideBarOpen.toString())
    
  });
};
handlehiddenandshow = () => {
  this.setState({
    showandhidden: !(this.state.showandhidden)
  });
};

handlehiddenandshowSecondPipelineVendorInvoice = () => {
  this.setState({
    showAndHiddenSecondPipeline: !(this.state.showAndHiddenSecondPipeline)
  });
};

handlehiddenandshowClearedVendorInvoice = () => {
  this.setState({
    showAndHiddenCleared: !(this.state.showAndHiddenCleared)
  });
};

handleDropdown = () => {
  this.setState({
      isDropdownOpen: !this.state.isDropdownOpen
  });

};
handleOpen = () => {
  console.log("rrrr", this.state.open)
  this.setState({ open: true })
  console.log("rrrr", this.state.open)
}
handleClose = () => {
  this.setState({ open: false })
  console.log("onclose", this.state.open)
};
navigaetoProject=(id:any,invoicecount:any)=>{
  this.getInvoiceListing()
  console.log("idddddddd",id)
  this.props.navigation.navigate("InvoiceListing");
  setStorageData("id", id)
  setStorageData("count", invoicecount)
  console.log("cocccccccc",invoicecount)
}
getInvoicedetail=(id:any)=>{
  setStorageData("invoiceid",id);
  this.props.navigation.navigate("InvoiceListingDetail");
}

getinvoiceDetails = async () => {

  let token = await getStorageData("token");
  let id = await getStorageData("invoiceid");

  
  const header = {
    "Content-Type": configJSON.exampleApiContentType,
    "token": token
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getinvoiceDetailApiCalledId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.myinvoicedetailendpoint + `/${id}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.getApiMethod
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );


  runEngine.sendMessage(requestMessage.id, requestMessage);
};
CommentsHandle=(e:any)=>{
  this.setState({RejectCommentValue:e.target.value})
  console.log("comments.................",e.target.value,this.state.RejectCommentValue)
 }
 CommentsHandleHold=(e:any)=>{
  this.setState({HoldCommentValue:e.target.value})
  console.log("comments.................",e.target.value,this.state.HoldCommentValue)
 }
getRejectbuttonapi = async () => {

  let token = await getStorageData("token");
  let id = await getStorageData("invoiceid");

  
  const header = {
    "Content-Type": configJSON.exampleApiContentType,
    "token": token
  };
  
 

  const httpBody = {
      status: "rejected",
      comment: this.state.RejectCommentValue
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.Rejectbuttoncallid = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.Rejectstatusendpoint + `/${id}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.Rejectmethodtype
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );


  runEngine.sendMessage(requestMessage.id, requestMessage);
};
getHoldedbuttonapi = async () => {

  let token = await getStorageData("token");
  let id = await getStorageData("invoiceid");

  
  const header = {
    "Content-Type": configJSON.exampleApiContentType,
    "token": token
  };
  
 

  const httpBody = {
      status: "holded",
      comment: this.state.HoldCommentValue
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.Holdbuttoncallid = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.Rejectstatusendpoint + `/${id}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.Rejectmethodtype
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );


  runEngine.sendMessage(requestMessage.id, requestMessage);
};
getApprovebuttonapi = async () => {

  let token = await getStorageData("token");
  let id = await getStorageData("invoiceid");

  
  const header = {
    "Content-Type": configJSON.exampleApiContentType,
    "token": token
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.Approvedbuttoncallid = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.Rejectstatusendpoint + `/${id}?status=approved`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.Rejectmethodtype
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );


  runEngine.sendMessage(requestMessage.id, requestMessage);
};
 
getprojectlistmytask = async () => {

  this.getprojectlistapiid=await this.MYTaskapiCall(configJSON.getApiMethod,configJSON.getprojectlistendpoint+`page=1&per_page=9`)
};
getInvoiceListing = async () => {
  let id = await getStorageData("id");
  this.getInvoiceCallid = await this.apiCallinvoice(configJSON.getApiMethod, configJSON.getInvoiceListEndPoint+`=${id}&page=1&per_page=9`)
};
apiCallinvoice = async (methodvalue: string, endPointvalue: string) => {
  const authenticationToken = await getStorageData('token', false)
  const header = {
    "Content-Type": configJSON.exampleApiContentType,
    "token": authenticationToken
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPointvalue
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    methodvalue
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
};
MYTaskapiCall = async (method: string, endPoint: string) => {
  const authenticationToken = await getStorageData('token', false)
  const header = {
    "Content-Type": configJSON.exampleApiContentType,
    "token": authenticationToken
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    method
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
};
handlePageChange = async (event: any, value: number) => {
  let id = await getStorageData("id");
  if(this.state.searchValue)
  {
    this.getsearchinvoicecallid = await this.apiCallinvoice(configJSON.getApiMethod, configJSON.searchmytaskendpoint + `search=${this.state.searchValue}&page=${value}&per_page=9&project_id=${id}`);
  }
  else if (this.state.jobrolevalue||this.state.amountvalue||this.state.statusvalue||this.state.departmentvalue)
  {
     this.getappliedinvoicevalue= await this.apiCallinvoice(configJSON.getApiMethod,configJSON.getAppliedinvoiceendpoint+`&project_id=${id}&job_role=${this.state.jobrolevalue}&amount=${this.state.amountvalue}&department=${this.state.departmentvalue}&status=${this.state.statusvalue}&page=${value}&per_page=9`)
  }
  else if(this.state.selectstatus||this.state.selectstatus==='all')
  {
    if(this.state.selectstatus==="all")
    {
      this.getsortbyinvoicecallid = await this.apiCallinvoice(configJSON.getApiMethod, configJSON.sortbymytaskendpoint + `&status=${""}&page=${value}&per_page=9&project_id=${id}`)
      window.scrollTo(0, 0);
    }
    else{
      this.getsortbyinvoicecallid = await this.apiCallinvoice(configJSON.getApiMethod, configJSON.sortbymytaskendpoint + `&status=${this.state.selectstatus}&page=${value}&per_page=9&project_id=${id}`)
      window.scrollTo(0, 0);
    }
   
  }
  else{
  this.getInvoiceCallid = await this.apiCallinvoice(configJSON.getApiMethod, configJSON.getInvoiceListEndPoint+`=${id}&page=${value}&per_page=9`)
      window.scrollTo(0, 0);
  }
  
  console.log("qqqqq",value)
  this.setState({ currentpage: value });

};
handlePageChangeMyTask = async (event: any, value: number) => {
  if(this.state.searchValueMyTask)
  {
    this.getProjectsearchcallid = await this.MYTaskapiCall(configJSON.getApiMethod,configJSON.getprojectlistendpoint+`search=${this.state.searchValueMyTask}&page=${value}&per_page=9`)
    window.scrollTo(0, 0);
  }
  
  else{
    this.getprojectlistapiid=await this.MYTaskapiCall(configJSON.getApiMethod,configJSON.getprojectlistendpoint+`page=${value}&per_page=9`)
      window.scrollTo(0, 0);
  }
  
  this.setState({ currentpageMyTask: value });
}
getinvoiceraisemytask = async () => {

  let token = await getStorageData("token");
  let id=await getStorageData("id")
  const header = {
    "Content-Type": configJSON.exampleApiContentType,
    "token": token
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getraiseinvoiceapiid = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.countraiseinvoiceendpoint+`=${id}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.getApiMethod
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
};
getinvoiceamountlistmytask = async () => {

  let token = await getStorageData("token");
  let id=await getStorageData("id")
  const header = {
    "Content-Type": configJSON.exampleApiContentType,
    "token": token
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getamountListapiid = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getamountListendpoint+`=${id}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.getApiMethod
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
};
getdepartmentlistmytask = async () => {

  let token = await getStorageData("token");
  let id=await getStorageData("id")
  const header = {
    "Content-Type": configJSON.exampleApiContentType,
    "token": token
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getdepartmentListapiid = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getdepartmentListendpoint+`=${id}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.getApiMethod
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
};
getjobrolelistmytask = async () => {

  let token = await getStorageData("token");
  let id=await getStorageData("id")
  const header = {
    "Content-Type": configJSON.exampleApiContentType,
    "token": token
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getjobroleListapiid = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getJobroleListendPoint+`=${id}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.getApiMethod
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
};
handleOptionChange = (index: any, state: any) => {
  this.setState({ selectstatus: state })
  
};
handleChange = async () => {
  this.handleClose();
let id=await getStorageData("id")
  
  this.setState({
    
    currentpage: 1,

  })
  if (this.state.selectstatus||this.state.selectstatus==="all"){
    if(this.state.selectstatus==="all")
    {
      this.getsortbyinvoicecallid = await this.apiCallinvoice(configJSON.getApiMethod, configJSON.sortbymytaskendpoint + `&status=${""}&page=1&per_page=9&project_id=${id}`)

    }
    else 
    {
    this.getsortbyinvoicecallid = await this.apiCallinvoice(configJSON.getApiMethod, configJSON.sortbymytaskendpoint + `&status=${this.state.selectstatus}&page=1&per_page=9&project_id=${id}`)
    }
  }
     
    

};
ClearDataValues = async () => {
  
  this.setState({selectstatus:''})
  this.setState({currentpage:1})
  this.handleClose()

  this.getInvoiceListing();
  
 
};
ClearDataValuesfilter = async () => {
  
  this.setState({jobrolevalue:''})
  this.setState({departmentvalue:''})
  this.setState({statusvalue:''})
  this.setState({amountvalue:''})
  this.setState({currentpage:1})
  this.handleClose1()

  this.getInvoiceListing();
  
 
};
filterHouses = async (event: ChangeEvent<HTMLInputElement>) => {
 
  this.setState({
      currentpage: 1,
      searchValue: event.target.value,
  });
  
  if (event.target.value)
  {
      this.getsearchinvoicecallid = await this.apiCallinvoice(configJSON.getApiMethod, configJSON.searchmytaskendpoint + `search=${event.target.value}&page=1&per_page=9&project_id=${await getStorageData("id")}`);
  }
  else{
    this.getInvoiceCallid = await this.apiCallinvoice(configJSON.getApiMethod, configJSON.getInvoiceListEndPoint+`=${await getStorageData("id")}&page=1&per_page=9`)
        window.scrollTo(0, 0);
    }
}
filterHousesMyTask = async (event: any) => {
 
  this.setState({
    currentpageMyTask: 1,
      searchValueMyTask: event.target.value,
  });
  
  if (event.target.value)
  {
    this.getProjectsearchcallid = await this.MYTaskapiCall(configJSON.getApiMethod,configJSON.getprojectlistendpoint+`search=${event.target.value}&page=1&per_page=9`)
    window.scrollTo(0, 0);
  }
  else{
    this.getprojectlistapiid=await this.MYTaskapiCall(configJSON.getApiMethod,configJSON.getprojectlistendpoint+`page=1&per_page=9`)
    window.scrollTo(0, 0);
    }
}
handleOptionChange1 = (index: any, state: any) => {
  this.setState({SelectStatus:state.name})
  
};

handleOptionChangejobrole = (index: any, state: any) => {
    
  this.setState({jobrolevalue:state})
  
  

  
};
handleOptionChangedepartment = (index: any, state: any) => {
    
  this.setState({departmentvalue:state})
  console.log("jobrole..",this.state.departmentvalue)
  

  
};
handleOptionChangeamount = (index: any, state: any) => {
    
  this.setState({amountvalue:state})
  
  

  
};
handleOptionChangestatus = (index: any, state: any) => {
    
  this.setState({statusvalue:state})

  

  
};
handleChangeapply = async () => {
  this.handleClose1();
let id=await getStorageData("id")
  
  this.setState({
    
    currentpage: 1,

  })

   this.getappliedinvoicevalue= await this.apiCallinvoice(configJSON.getApiMethod,configJSON.getAppliedinvoiceendpoint+`&project_id=${id}&job_role=${this.state.jobrolevalue}&amount=${this.state.amountvalue}&department=${this.state.departmentvalue}&status=${this.state.statusvalue}&page=1&per_page=9`)


     
}

handleChangeinvoiceTask = (event: any) => {
  this.setState({invoiceselectTask:event.target.value});
};
handleChangeProjectTask = (event: any) => {
  this.setState({selectprojectTask:event.target.value});
};

handleChangeradioTask = (event: any) => {
  this.setState({selectinvoicetypeTask:event.target.value})
};
handleChangeNameTask = (event: any) => {
  this.setState({selectinvoicenameTask:event.target.value})
};
handleChangeDetailTask = (event: any) => {
  this.setState({selectinvoicedetailTask:event.target.value})
};
handleChangeNoTask = (event: any) => {
  this.setState({selectinvoicenoTask:event.target.value})
};
handleChangeAmountTask = (event: any) => {
  const numericValue = event.target.value.replace(/[^0-9]/g, '');
  this.setState({selectinvoiceamountTask:numericValue})
};
raiseinvoiceDataTask = async () => {
  this.setState({openraiseinvoice:false})
  const token = await getStorageData("token",false);   
  const header = {
    "token": token
  };
  
  const formData = new FormData();
  formData.append("invoice[invoice_receipt]",this.state.imageTask) 
  formData.append("invoice[add_note]", this.state.selectinvoicedetailTask);
  formData.append("invoice[type_of_invoice]", this.state.invoiceselectTask);
  formData.append("invoice[invoice_name]", this.state.selectinvoicenameTask);
  formData.append("invoice[invoice_id]", this.state.selectinvoicenoTask);
  formData.append("invoice[total_amount]", this.state.selectinvoiceamountTask);
  formData.append("invoice[standard_pay]", this.state.selectinvoicetypeTask);
  formData.append("invoice[project_id]",this.state.selectprojectTask)
  
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.raiseinvoiceapicallid = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
 
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.raiseinvoiceendpoint
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    formData
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.raiseinvoicemethodtype

  );
  runEngine.sendMessage(requestMessage.id, requestMessage);   
}  

  // Customizable Area End
}
