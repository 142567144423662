export const restrictText = (data: string, limit: number) => {
  if (data.length > limit) {
    return data.slice(0, limit) + '...'
  } else return data
}

export const departmentOptions = [
  { label: 'PRODUCTION TEAM', value: 'PRODUCTION TEAM' },
  { label: 'DIRECTION TEAM', value: 'DIRECTION TEAM' },
  { label: 'ART TEAM', value: 'ART TEAM' },

];