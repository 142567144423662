import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  isLoading: boolean;
  balanceSheetViewProjectId: string;
  balanceSheetViewSummaryData: any;
  total: string;
  grandTotal: string;
  summaryViewNotification: { type: string, open: boolean, message: string, route?: string };
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SummaryViewController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetBudgetSummaryViewMessageId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      isLoading: false,
      balanceSheetViewProjectId: "",
      balanceSheetViewSummaryData: [],
      total: "",
      grandTotal: "",
      summaryViewNotification: { type: "", open: false, message: "" },
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const summaryViewApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const summaryViewResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (this.apiGetBudgetSummaryViewMessageId === summaryViewApiRequestCallId) {
        this.getBudgetSummaryViewHandleResponse(summaryViewResponseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getBudgetSummaryViewHandleResponse = (summaryViewResponseJson: { meta: { total: string }, data: { id: string, attributes: { project_department_ids: number[], categories_ids: number[] } }[], errors: { token: string }[], error: { message: string } }) => {
    if (summaryViewResponseJson.data) {
      this.setState({ balanceSheetViewSummaryData: summaryViewResponseJson.data, total: summaryViewResponseJson.meta.total, grandTotal: summaryViewResponseJson.meta.total });
    } else if (summaryViewResponseJson.errors) {
      this.setState({ summaryViewNotification: { open: true, type: "error", message: summaryViewResponseJson.errors[0].token, route: "LandingPage" } });
    } else if (summaryViewResponseJson.error) {
      this.setState({ summaryViewNotification: { open: true, type: "error", message: summaryViewResponseJson.error.message } });
    }
    this.setState({ isLoading: false });
  }

  async componentDidMount() {
    const authSVToken = await getStorageData("token");
    const balanceSheetViewProjectId = await getStorageData("BalanceSheetId");
    this.setState({ token: authSVToken, balanceSheetViewProjectId: balanceSheetViewProjectId }, () => {
      this.getBudgetSummaryViewData();
    });
  }

  getBudgetSummaryViewData = () => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token
    };
    const summaryViewRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setState({ isLoading: true });
    this.apiGetBudgetSummaryViewMessageId = summaryViewRequestMessage.messageId;
    summaryViewRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getBudgetSummaryAPI + this.state.balanceSheetViewProjectId
    );
    summaryViewRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    summaryViewRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );
    runEngine.sendMessage(summaryViewRequestMessage.id, summaryViewRequestMessage);
    return true;
  }

  handleSummaryViewCloseNotification = () => {
    this.setState({ summaryViewNotification: { type: "", open: false, message: "" } });
  }
  // Customizable Area End
}
