import React from "react";

import {
  // Customizable Area Start
  Container,
  Grid,
  Typography,
  Box,
  ThemeProvider,
  createTheme,
  Link,
  TypographyProps,
  // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import ProjectMemberLayoutController, { Props } from "./ProjectMemberLayoutController";
import "./fullVisibility.css";
import Loader from "../../../components/src/Loader.web";
import SideBar from "../../../components/src/SideBar";
import Header from "../../../components/src/Header.web";
import Footer from "../../../components/src/Footer";
import CustomNotification from "../../../components/src/CustomNotification";

const theme = createTheme({
  palette: {
    primary: {
      main: "#F5C42C",
      contrastText: "#fff"
    }
  },
  overrides: {
    MuiSelect: {
      select: {
        "&:focus": {
          border: "1px solid #F5C42C",
        }
      }
    }
  }
});
// Customizable Area End

export default class ProjectMemberLayout extends ProjectMemberLayoutController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderProjectMemberLayoutMainContent(
    sideBarStatus: boolean = this.state.isProjectMemberLayoutSideBarOpen
  ) {
    return (
      <>
        <Loader loading={this.props.isLoading}></Loader>
        <Grid style={webStyle.contentSetup}>
          <Header
            handleSideBar={this.handleProjectMemberLayoutSideBar}
            dropDownStatus={this.state.isProjectMemberLayoutDropDwnOpen}
            navigate={this.props.navigation.navigate}
            sideBarStatus={sideBarStatus}
            handleDropdown={this.handleProjectMemberLayoutDropdownValue}
          />
          <Grid style={webStyle.mainContent as React.CSSProperties}>
            <Box sx={webStyle.blockInfo}>
              <Box>
                <Typography variant="h3" style={webStyle.activeBlock as TypographyProps}>{this.props.projectMemberLayoutTitle}</Typography>
                <Typography variant="h6" style={webStyle.blockPath as TypographyProps}>
                  {this.props.projectMemberLayoutBreadCrumb.map((breadCrumb: any, index: number) => {
                    return <React.Fragment key={breadCrumb.title + "breadCrumb"}>
                      {breadCrumb.link ?
                        <span style={webStyle.highlightedText}>
                          <Link data-test-id={`${breadCrumb.title}_homeLink`} color="inherit" onClick={() => this.handleNavigation(breadCrumb.link)}>
                            {!index ? `${breadCrumb.title}` : ` / ${breadCrumb.title}`}
                          </Link>
                        </span>
                        : <> {" "}/ {breadCrumb.title} </>
                      }
                    </React.Fragment>
                  })}
                </Typography>
              </Box>
              <Box>
                {this.props?.renderProjectMemberLayoutTopRightContent()}
              </Box>
            </Box>
            <Box>
              {this.props.renderPageContent()}
            </Box>
          </Grid>
        </Grid >
        <Footer />
      </>
    );

  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container style={webStyle.parentDiv} >
          {this.state.isProjectMemberLayoutSideBarOpen ? (
            <Grid spacing={0} container >
              <Grid item xs={3} style={webStyle.sidebarParent} >
                <SideBar
                  handleSideBar={this.handleProjectMemberLayoutSideBar}
                  userData={{ name: "user name" }}
                  activeMenuItem={"Project Members"}
                  navigate={this.props.navigation.navigate}
                />
              </Grid>
              <Grid item xs={9} style={{ display: "flex", flexDirection: "column", flexBasis: "77.75%", maxWidth: "77.75%" }}>
                {this.renderProjectMemberLayoutMainContent()}
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={12} style={{ display: "flex", flexDirection: "column" }}>
              {this.renderProjectMemberLayoutMainContent()}
            </Grid>
          )}
        </Container>
        <CustomNotification data-test-id="customNotification" navigation={(route: string) => this.props.navigation.navigate(route)} notification={this.state.notification} handleCloseNotification={this.handleCloseNotification} />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  sidebarParent: {
    minHeight: "100vh",
    maxWidth: "22.25%",
  },
  parentDiv: {
    maxWidth: "none",
    padding: "0px",
  },
  blockInfo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap"
  },
  activeBlock: {
    lineHeight: "34px",
    fontSize: "28px",
    fontWeight: 700,
    letterSpacing: "0.31111112px",
    marginBottom: "8px",
    color: "#17181d",
    fontFamily: "Urbanist"
  },
  highlightedText: {
    color: "#F5C42C",
    cursor: 'pointer',
    textDecoration: 'underline',
    fontWeight: 600,
  },
  contentSetup: {
    borderLeft: "1px solid #E3EAF4",
    flex: 1,
  },
  blockPath: {
    fontSize: "14px",
    fontWeight: 500,
    letterSpacing: "0.15555556px",
    color: "#6D89AF",
    fontFamily: "Urbanist",
    lineHeight: "16.8px",
  },
  mainContent: {
    width: "100%",
    minHeight: "100vh",
    backgroundColor: "#F8FAFE",
    boxSizing: "border-box",
    padding: "24px",
  },
};
// Customizable Area End