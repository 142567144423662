import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import React from "react";
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isVendorJobPHDetailsSideBarOpen: boolean;
  isLoading: boolean;
  isVendorJobPHDetailsDropdownOpen: boolean;
  vendorJobPHDetailsData: any;
  vendorJobPHDetailsReviewData: any;
  vendorJobPHDetailsProjectsData: any;
  vendorJobPHDetailsStudiosData: any;
  vendorJobPHDetailsTabValue: number;
  phProfileId: string;
  notification: { type: string, open: boolean, message: string, route?: string };
  bioReadMoreLess: boolean;
  isModelOpen: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class VendorJobPHDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getVendorJobPHDetailsDataMessageId: string = "";
  getVendorJobPHDetailsReviewDataMessageId: string = "";
  getVendorJobPHDetailsProjectsDataMessageId: string = "";
  getVendorJobPHDetailsStudiosDataMessageId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isVendorJobPHDetailsSideBarOpen: true,
      isLoading: false,
      isVendorJobPHDetailsDropdownOpen: false,
      vendorJobPHDetailsData: {
        profile_image: "",
        id: "",
        state: "",
        city: "",
        documents: [],
        profile_bio: {
          data: {
            attributes: {
              about_business: "",
            }
          },
        },
        account_detail: {
          data: {
            attributes: {
              country_code: "",
              full_name: "",
              email: "",
              phone_number: "",
              gst_number: "",
              aadhar_card_number: "",
              tan_number: "",
              pan_card_number: "",
            }
          }
        },
      },
      vendorJobPHDetailsReviewData: {},
      vendorJobPHDetailsProjectsData: [],
      vendorJobPHDetailsStudiosData: [],
      vendorJobPHDetailsTabValue: 0,
      phProfileId: "",
      notification: { type: "", open: false, message: "" },
      bioReadMoreLess: true,
      isModelOpen: false,
      // Customizable Area End
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const vendorJobPHDetailsApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const vendorJobPHDetailsResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.getVendorJobPHDetailsDataMessageId === vendorJobPHDetailsApiRequestCallId) {
        this.getVendorJobPHDetailsDataHandleResponse(vendorJobPHDetailsResponseJson);
      } else if (this.getVendorJobPHDetailsReviewDataMessageId === vendorJobPHDetailsApiRequestCallId) {
        this.getVendorJobPHDetailsReviewDataHandleResponse(vendorJobPHDetailsResponseJson);
      } else if (this.getVendorJobPHDetailsProjectsDataMessageId === vendorJobPHDetailsApiRequestCallId) {
        this.getVendorJobPHDetailsProjectsDataHandleResponse(vendorJobPHDetailsResponseJson);
      } else if (this.getVendorJobPHDetailsStudiosDataMessageId === vendorJobPHDetailsApiRequestCallId) {
        this.getVendorJobPHDetailsStudiosDataHandleResponse(vendorJobPHDetailsResponseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getVendorJobPHDetailsDataHandleResponse = (vendorJobPHDetailsDataResponseJson: { errors: { token: string }[], data: any }) => {
    if (vendorJobPHDetailsDataResponseJson.data) {
      this.setState({ vendorJobPHDetailsData: vendorJobPHDetailsDataResponseJson.data.attributes });
    } else if (vendorJobPHDetailsDataResponseJson.errors[0].token) {
      this.setState({ notification: { open: true, type: "error", message: vendorJobPHDetailsDataResponseJson.errors[0].token, route: "LandingPage" } });
    }
    this.setState({ isLoading: false });
  }

  getVendorJobPHDetailsProjectsDataHandleResponse = (vendorJobPHDetailsDataResponseJson: { errors: { token: string }[], data: any[] }) => {
    if (vendorJobPHDetailsDataResponseJson.data) {
      this.setState({ vendorJobPHDetailsProjectsData: vendorJobPHDetailsDataResponseJson.data });
    } else if (vendorJobPHDetailsDataResponseJson.errors[0].token) {
      this.setState({ notification: { open: true, type: "error", message: vendorJobPHDetailsDataResponseJson.errors[0].token, route: "LandingPage" } });
    }
    this.setState({ isLoading: false });
  }

  getVendorJobPHDetailsStudiosDataHandleResponse = (vendorJobPHDetailsDataResponseJson: { errors: { token: string }[], data: any[] }) => {
    if (vendorJobPHDetailsDataResponseJson.data) {
      this.setState({ vendorJobPHDetailsStudiosData: vendorJobPHDetailsDataResponseJson.data });
    } else if (vendorJobPHDetailsDataResponseJson.errors[0].token) {
      this.setState({ notification: { open: true, type: "error", message: vendorJobPHDetailsDataResponseJson.errors[0].token, route: "LandingPage" } });
    }
    this.setState({ isLoading: false });
  }

  getVendorJobPHDetailsReviewDataHandleResponse = (vendorJobPHDetailsDataResponseJson: { errors: { token: string }[], average_rating: number, rating_star: any, review_rating_count: string, reviews: { data: any[] } }) => {
    if (vendorJobPHDetailsDataResponseJson.reviews?.data) {
      this.setState({ vendorJobPHDetailsReviewData: vendorJobPHDetailsDataResponseJson });
    } else if (vendorJobPHDetailsDataResponseJson.errors[0].token) {
      this.setState({ notification: { open: true, type: "error", message: vendorJobPHDetailsDataResponseJson.errors[0].token, route: "LandingPage" } });
    }
    this.setState({ isLoading: false });
  }

  handleVendorJobPHDetailsSideBar = () => {
    setStorageData("sidebarvalue", (!this.state.isVendorJobPHDetailsSideBarOpen).toString())
    this.setState({
      isVendorJobPHDetailsSideBarOpen: !this.state.isVendorJobPHDetailsSideBarOpen
    });
  };

  handleDropdown = () => {
    this.setState({
      isVendorJobPHDetailsDropdownOpen: !this.state.isVendorJobPHDetailsDropdownOpen
    });
  };

  handleNavigation = (page: string) => {
    this.props.navigation.navigate(page)
  };

  handleBackNavigation = () => {
    this.props.navigation.goBack();
  }

  async componentDidMount() {
    const phProfileId = await getStorageData("phID");
    this.getVendorJobPHDetailsDataMessageId = await this.apiCall(configJSON.GET_Method, configJSON.getVendorJobPHDetailsDetailsAPIEndPoint + phProfileId);
    this.getVendorJobPHDetailsProjectsDataMessageId = await this.apiCall(configJSON.GET_Method, configJSON.getVendorJobPHDetailsProjectsAPIEndPoint + phProfileId);
    this.getVendorJobPHDetailsStudiosDataMessageId = await this.apiCall(configJSON.GET_Method, configJSON.getVendorJobPHDetailsStudiosAPIEndPoint + phProfileId);
    this.getVendorJobPHDetailsReviewDataMessageId = await this.apiCall(configJSON.GET_Method, configJSON.getVendorJobPHDetailsReviewDataAPIEndPoint + phProfileId);
    const isVendorJobPHDetailsSideBarOpen = await getStorageData("sidebarvalue");
    this.setState({ phProfileId: phProfileId, isVendorJobPHDetailsSideBarOpen: JSON.parse(isVendorJobPHDetailsSideBarOpen) });
  }

  apiCall = async (vendorJobPHDetailsMethod: string, vendorJobPHDetailsEndPoint: string) => {
    const authToken = await getStorageData("token");
    const vendorJobPHDetailsHeader = {
      "Content-Type": configJSON.exampleApiContentType,
      token: authToken
    };
    const vendorJobPHDetailsRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setState({ isLoading: true });
    vendorJobPHDetailsRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      vendorJobPHDetailsEndPoint
    );
    vendorJobPHDetailsRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(vendorJobPHDetailsHeader)
    );
    vendorJobPHDetailsRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      vendorJobPHDetailsMethod
    );
    runEngine.sendMessage(vendorJobPHDetailsRequestMessage.id, vendorJobPHDetailsRequestMessage);
    return vendorJobPHDetailsRequestMessage.messageId;
  }

  handleTabValueChange = (tabVal: number) => {
    this.setState({ vendorJobPHDetailsTabValue: tabVal });
  }

  handleCloseNotification = () => {
    this.setState({
      notification: { open: false, type: "", message: "" }
    });
  }

  covertToPascalCase = (statusText: string, statusTrimSpace = false) => {
    return statusText.split(' ').map((t) => t[0].toUpperCase() + t.slice(1).toLowerCase()).join(statusTrimSpace ? '' : ' ')
  }

  handleBioRead = () => {
    this.setState({
      bioReadMoreLess: !this.state.bioReadMoreLess
    });
  }

  vendorJobPHDetailsHandleDownloadPDF = (pdfURL: any, fileName: string) => {
    fetch(pdfURL)
      .then(VendorJobPHDetailsResponse => {
        VendorJobPHDetailsResponse.blob().then(blob => {
          let photoUrl = window.URL.createObjectURL(blob);
          let aTag = document.createElement('a');
          aTag.href = photoUrl;
          aTag.download = fileName;
          aTag.click();
        });
      });
  }

  getProjectStatusColor = (color: string) => {
    if (color === "completed") {
      return "#42A200"
    } else if (color === "upcoming") {
      return "#3389FF"
    } else if (color === "ongoing") {
      return "#F7B500"
    } else {
      return "#17181D"
    }
  }
  // Customizable Area End
}
