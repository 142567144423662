
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start


// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    // Customizable Area Start
    id: string;
    navigation: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    tabValueProject: number;
    allStudioListData: any;
    requestStudioListData: any;
    UpcomingStudioListData: any;
    OngoingStudioListData: any;
    pendingRequestListData: any;
    isSideBarOpen: boolean;
    isLoading: boolean;
    isDropdownOpen: boolean;
    statesData: { id: number, name: string, iso2: string }[];
    phList: any[];
    getCountForPagination: any;
    currentStudiosPage: number;
   studioDetailTabValue: number;
   notification: { type: string, open: boolean, message: string, route?: string };
 
   bioReadMoreLess:boolean;
   vendorManageProfileProjectsData:any;
   modelType:string;
   studioBookingId: string;
   studioDetailReviewData:any;
   open:boolean;
   reviewvalidation:string;
   ratingvalidation:string;
   rateValue:number;
   comments:string;
   rewableId:number;
   open1:boolean;
   statuspending:any;
   studiotype:any;
   projectsList:any;
  OnGoingProjectList:any;
  UpComingProjectList:any



    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class ProductionHouseDashboardController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
  
    getallProjectcallid: string = "";
    getongoingProjectcallid: string = "";
    getUpcomingProjectcallid: string = ""

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceDataMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.RestAPIResponceErrorMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            isSideBarOpen: true,
            isLoading: false,
            isDropdownOpen: false,
            tabValueProject: 0,
            allStudioListData: [],
            pendingRequestListData: [],
            statesData: [],
            phList: [],
            requestStudioListData: [],
            UpcomingStudioListData: [],
            OngoingStudioListData: [],
            getCountForPagination: {
                0: 0,
                1: 0,
                2: 0,
                3: 0
            },
            currentStudiosPage: 1,
            studioDetailTabValue: 0,
            vendorManageProfileProjectsData:[],
            notification: { type: "", open: false, message: "" },
          
             
              studioDetailReviewData:{},
              open:false,
              reviewvalidation:"",
              ratingvalidation:"",
              rateValue:0,
              comments:"",
              rewableId:0,
              bioReadMoreLess:true,
              modelType:'',
              studioBookingId: '',
              open1:false,
              statuspending:null,
              studiotype:"createType",
              projectsList:[],
              OnGoingProjectList:[],
              UpComingProjectList:[]
   

            // Customizable Area End
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (this.getallProjectcallid === apiRequestCallId) {
                this.setState({ projectsList: responseJson.data })
                 this.setState({ getCountForPagination: { ...this.state.getCountForPagination, 0: responseJson.meta.project_count } });
            }
            if (this.getongoingProjectcallid === apiRequestCallId) {
                this.setState({ OnGoingProjectList: responseJson.data })
                this.setState({ getCountForPagination: { ...this.state.getCountForPagination, 1: responseJson.meta.ongoing } });
            }
            if (this.getUpcomingProjectcallid === apiRequestCallId) {
                this.setState({ UpComingProjectList: responseJson.data })
                this.setState({ getCountForPagination: { ...this.state.getCountForPagination, 2: responseJson.meta.upcoming } });
            }
          
           
           
        }
        // Customizable Area End
    }
 
    // Customizable Area Start
 
     
   
    handlePageChange = async (event: any, value: number) => {
        if (this.state.tabValueProject === 0) {
        
            this.getallProjectcallid = await this.apiCall(configJSON.validationApiMethodType, configJSON.getallProjectPHEndPoint+`?page=${value}&per_page=9`);
        } else if (this.state.tabValueProject === 1) {
            this.getongoingProjectcallid = await this.apiCall(configJSON.validationApiMethodType, configJSON.getOngoingProjectEndPoint + `?page=${value}&per_page=9`);
        } else if (this.state.tabValueProject === 2) {
            this.getUpcomingProjectcallid = await this.apiCall(configJSON.validationApiMethodType, configJSON.getUpcomingProjectEndPoint + `?page=${value}&per_page=9`);
        }
       
        this.setState({ currentStudiosPage: value });
    }
  

    handleSideBar = () => {
        
        this.setState({
            isSideBarOpen: !this.state.isSideBarOpen
          },
          async() => {
            localStorage.setItem("sidebarvalue",this.state.isSideBarOpen.toString())
      
          }
      
      
          );
    };
      


    
    handleDropdown = () => {
        this.setState({
            isDropdownOpen: !this.state.isDropdownOpen
        });
    };

    handleNavigation = (page: string) => {
        this.props.navigation.navigate(page)
    };

    handleAppBarValue = (event: React.ChangeEvent<{}>, value: number) => {
        this.setState({
            tabValueProject: value,
        });
        
        this.handlePageChange({}, 1);
        
    }


    async componentDidMount() {
      const statuspending=await getStorageData("statusrequest")
      this.setState({statuspending:statuspending})
        this.getallStudioList()
        this.getrequestStudioList();
        this.getOngoingRequestStudioList();
        
     
         let rewableid=await getStorageData("studioid")
         let rewableidNumber = parseInt(rewableid, 10); 
         this.setState({ rewableId: rewableidNumber });
         let isSideBarOpen=await getStorageData("sidebarvalue");
         this.setState({isSideBarOpen:JSON.parse(isSideBarOpen)})
         
    }



   



    

   

    handleCloseNotification = () => {
        this.setState({ notification: { type: "", open: false, message: "" } });
      }
      handleOpen = () =>{
    
        this.setState({rateValue:0,comments:'',ratingvalidation:'',reviewvalidation:''})
        
           this.setState({open:true})
          
        }
      

   
   
  

   
    navigateToDetailPage = (studioId: string) => {
        setStorageData("studioId", studioId);
        this.props.navigation.navigate("StudiosBookingDetail");
    }

   
   
    async componentWillUnmount() {
        setStorageData("studioBookingViewStatus", "false");
    }
    apiCall = async (method: string, endPoint: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };

  

    getallStudioList = async () => {
        this.getallProjectcallid = await this.apiCall(configJSON.validationApiMethodType, configJSON.getallProjectPHEndPoint+`?per_page=9&page=1`);
    };
    getrequestStudioList = async () => {
        this.getongoingProjectcallid = await this.apiCall(configJSON.validationApiMethodType, configJSON.getOngoingProjectEndPoint+`?per_page=9&page=1`);
    };
    getOngoingRequestStudioList = async () => {
        this.getUpcomingProjectcallid = await this.apiCall(configJSON.validationApiMethodType, configJSON.getUpcomingProjectEndPoint+`?per_page=9&page=1`);
    };
   
   
    handleOpen1 = () => {
        this.setState({ open1: true })
    }
    handleClose1 = () => {
        this.setState({ open1: false })

    };

 


          
    
         

      

       

      
      
             

                
                    

                     
                    
                      handleNavigateToAddNewProject=async()=>{
                               localStorage.removeItem('projectData')

                               await setStorageData("projecttype","addproject")

                          await setStorageData("navigatevalue",false)
                          localStorage.setItem('valueactiveindex',JSON.stringify(1));

                              this.props.navigation.navigate("AddNewProject");
                               }
                        
                               navigateToallJobDetail = async(id:any) => {
                                        
                                      
                                        await setStorageData("projectidPH",id)
                                        this.props.navigation.navigate("ProjectDetailPH");
                                    
                                      
                                        
                                        
                                       };

                                       navigateToallinvoiceRequest = async(e:any,id:any) => {
                                        e.stopPropagation();
                                      
                                        await setStorageData("project_id",id)
                                        this.props.navigation.navigate("InvoiceBilling");
                                    
                                      
                                        
                                        
                                       };
    // Customizable Area End
}

