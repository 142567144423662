export const logo = require("./Sidebar_Assets/Group.png")
export const footer = require("./Footer_Assets/footer.png")
export const Image1 = require("./assets/card1image.png");
export const location = require("./assets/locationIcon.svg")
export const review = require("./assets/reviewIcon.svg")
export const projecticon = require("./assets/projectIcon.svg")
export const studio = require("./assets/studioIcon.svg")
export const iconC = require("./assets/CIcon.svg")
export const BlockIcon = require("./assets/BlockIcon.svg")
export const pushpaImage = require("./assets/Pushpa2.png")
export const clock = require("./assets/clockicon.svg")
export const Depticon = require("./assets/depticon.svg")
export const Vendoricon = require("./assets/vendoricon.svg")
export const OverviewIcon = require("./assets/OverviewIcon.svg");
export const LocationIcon = require("./assets/Locationdetail.svg");
export const VendorIcon = require("./assets/VendorIcon.svg");
export const departicon = require("./assets/DepartMentIcons.svg");
export const VendorDetailimg = require("./assets/VendorImage.png");
export const workicon = require("./assets/WorkIcon.svg");
export const imgvendor = require("./assets/VendorImage.png");
export const OverviewIconactive = require("./assets/OverviewIconactive.svg");
export const VendorIconactive = require("./assets/VendorIconactive.svg");
export const studioimg = require("./assets/AllStudioimg1.png");
export const callicon = require("./assets/callicon.svg");
export const msgicon = require("./assets/msgicon.svg");
export const studiovisitingicon = require("./assets/studiovisitingicon.svg");
export const studioblockoff = require("./assets/studioblockoff.svg");
export const studiobblockon = require("./assets/studioblockon.svg");
export const locationIcon = require("./assets/locationIcon.svg");
export const departiconactive = require("./assets/DepartMentIconsactive.svg");
export const RightIcon = require("./assets/RightIcon.svg");
export const studioDETAILimg = require("./assets/studioDETAILimg.png");
export const Review = require("./assets/Review.svg");
export const Reviewact = require("./assets/Reviewact.svg");
export const Gellary = require("./assets/Gellary.svg");
export const Gellaryact = require("./assets/Gellaryact.svg");
export const gellaryimage = require("./assets/gellaryimage.png");
export const reviewimg = require("./assets/reviewimg.png");
export const downloadicon = require("./assets/downloadicon.svg");
export const certificateicon = require("./assets/certificateicon.png");
export const switchEnable = require("./assets/blockEnable.png");
export const switchDisable = require("./assets/blockDisabled.png");
export const Filtericon= require("./assets/filter.png");
export const whiteLogoIcon = require("./assets/white_logo.png");
export const crossIcon = require("./assets/crossIcon.svg");
export const workHistoryIcon = require("./assets/work_history.svg");
export const workHistoryDark = require("./assets/work_history_dark.svg")
export const uploadFile = require("./assets/upload_file.svg");
export const receiptIcon = require("./assets/receipt.svg");
export const leaderboardIcon = require("./assets/leaderboard.svg");
export const historyIcon = require("./assets/history.svg");
export const exploreIcon = require("./assets/explore.svg");
export const contactSupportIcon = require("./assets/contact_support.svg");
export const cameraOutdoorIcon = require("./assets/camera_outdoor.svg");
export const layersIcon = require("./assets/layers_black_24dp.svg");
export const layersVendorSidebarIcon = require("./assets/layers_vendor_sidebar.svg");
export const jobpositionicon= require("./assets/JobPositionIcon.svg");
export const jobPositionBlackIcon= require("./assets/JobPositionIcon_black.svg");
export const jobyrs=require("./assets/jobyrs.svg");
export const rupeeiconjob= require("./assets/rupeeiconjob.svg");
export const rupeeIconJobBlack = require("./assets/rupeeiconjob_black.svg");
export const JobCardImage = require("./assets/JobCardImage.png");
export const LocationIconjob =require("./assets/LocationIcon.svg");
export const dotsIcon=require("./assets/3dotsIcon.svg");
export const headingicon=require("./assets/headigIcon.svg");
export const RightmarkIcon=require('./assets/RightmarkIcon.svg')
export const PHIcon = require("./assets/PH_icon.svg");
export const vendorLandingPageIcon = require("./assets/vendor_landing_page_icon.svg");
export const yellowPHIcon = require("./assets/yellow_PH_icon.svg");
export const yellowVendorLandingPageIcon = require("./assets/yellow_vendor_landing_page_icon.svg");
export const JobProfileDetail=require('./assets/JobProfileDetail.png')
export const DateIconView =require('./assets/DateIconView.svg');
export const AiPicture =require('./assets/AiPicture.svg');
export const Bg_Copy=require('./assets/Bg_Copy_16.png')
export const TravelllingListicon =require('./assets/TravelllingListicon.svg');
export const doticon=require('./assets/dot.png');
export const Travelingarrow =require("./assets/Travelingarrow.svg");
export const MaskCopy= require("./assets/MaskCopy.png");
export const Trackicon= require("./assets/Trackicon.png");
export const TrackLocation=require("./assets/TrackLocation.png")
export const Shape =require('./assets/Shape.png')
export const InvoiceProfile =require('./assets/InvoiceProfile.png')
export const RectangleCopy =require('./assets/RectangleCopy.png')
export const Path=require('./assets/Path.png');
export const arrowinvoice=require('./assets/arrowinvoice.png')
export const checkedImage = require("./assets/checkedIcon.png");
export const invoiceBg=require("./assets/invoiceBg.png")
export const receiptinvoice=require("./assets/receipt.png");
export const downloadinvoice=require("./assets/downloadinvoice.png");
export const printicon=require("./assets/printicon.png")
export  const Arrowinvoice=require("./assets/Arrowinvoice.png")
export const Specialpayicon=require("./assets/Specialpayicon.png")
export const RightInvoice=require("./assets/RightInvoice.png")
export const arrowabove=require("./assets/arrowabove.png")
export const checkcircal=require("./assets/checkcircal.png")
export const hourglass=require("./assets/hourglass.png")
export const profileInvoice=require("./assets/profileInvoice.png")
export const BankIcon=require("./assets/BankIcon.png");
export const daterange=require("./assets/daterange.png");
export const cashinvoicereceipt=require("./assets/cashinvoicereceipt.png")
export const uploadinvoice=require("./assets/uploadinvoice.png")
export const MytaskProfile=require("./assets/MytaskProfile.png");
export  const mytaskreceipt=require("./assets/mytaskreceipt.png");
export  const profileIcon=require("./Header_Assets/profile.svg");
export const vendorProfileIcon = require("./assets/vendorProfile.svg");
export const leaveproject=require("./assets/leaveproject.png");
export const raiseinvoice=require("./assets/raiseinvoice.png");
export const  balancesheet=require("./assets/balancesheet.png");
export const  analyticsIcon=require("./Sidebar_Assets/analytics1.svg");
export const rolesDownwordArrowIcon=require("./assets/south_black_24dp.svg");
export const projectInvoiceIcon =require("./assets/Group 7.png");
export const pendingicon=require("./assets/Shape (10).png")
export const holdicon=require("./assets/holdicon.png")
export const Raiseinvoicecertificate=require("./assets/certificate__1__copy.png")
export const msghold=require("./assets/msghold.png")
export const blockicon=require("./assets/blockicon.png")
export const rejectedicon=require("./assets/rejectedicon.png")
export const chatimg=require("./assets/chatimg.png")
export const reclaiminvoice=require("./assets/Reclaiminvoiceimg.png");
export const GalleryIcon=require("./assets/Gellary.svg");
export const DefaultIcon=require("./assets/default icon.svg");
export const cameraIcon=require("./assets/camera icon.svg");
export const uploadIcon =require("./assets/upload icon.svg")
export const keyIcon =require("./assets/key icon.svg");
export const whiteCrossIcon=require("./assets/Logo White.svg")
export const SMSicon = require("./assets/SMSIcon.svg");
export const removePersonIcon = require("./assets/removePerson.svg");
export const arrowdown=require("./assets/arrowdown.png")
export const invoiceCard = require("./assets/invoice_card.png")
export const arrowForward = require("./assets/arrow_forward.png");
export const pathIcon = require("./assets/path_icon.png");
export const workIcon = require("./assets/work_icon.png");

export const Balancesheeticon=require("./assets/Balancesheet.svg")
export const standardpayicon=require("./assets/standardpayicon.png")
export const notificationicon=require("./assets/NotificationIcon.png")
export const Tringle=require("./assets/Triangle.png")
export const studiodelete=require("./assets/deletestudio.png");
export const editstudio=require("./assets/editstudio.png")
export const BookingOpen=require("./assets/BookingOpen.png")
export const BookingClose=require("./assets/BookingClose.png");
export const RequestBook=require("./assets/RequestBook.png")
export const CancelStudio=require("./assets/CancelStudio.png");
export const Requesticon=require('./assets/RequestDownload.png')
export const AddFilesIcon=require("./assets/Add files-cuate Copy.png");
export const DefaulIcon=require("./assets/auto_awesome_motion_black_24dp.png");
export const Profileimage=require("./assets/Profileimage.png")
export const uploadProjectIcon=require("./assets/upload_black_24dp (1).png");
export const invoiceIcon=require("./assets/invoice_icon.png")

export const Crosssape=require("./assets/crosssape.png")

export const iconjobdetail=require("./assets/iconjobdetail.png")

export const dayicon=require("./assets/dayicon.png")
export const RequestSendIcon = require("./assets/request_send.png");
export const StudioAddimage=require("./assets/StudioAddimage.png");
export const yellowswitch=require("./assets/yellowswitch.png");
export const greyswitch=require("./assets/greyswitch.png");
export const AllDepartment=require("./assets/AllDepartment.png");
export const Allemployementimage=require("./assets/Allemloyeejobrole.png");
export const arrowPH=require("./assets/arrowPH.png")

export const Dummyicon=require("./assets/Dummyicon.png");
export const checkeddeprtment=require("./assets/checkcircal.png");
export const uncheckdepartment=require("./assets/uncheckbutton.png")
export const employeerequest=require("./assets/employeerequest.png")
export const dummyImage=require("./assets/dummy.png")
export const bankIconImage= require("./assets/bank.jpg")
export const ProfileDummy=require('./assets/ProfileDummy.jpg')
