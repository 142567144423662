import React from "react";
import { Card, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
interface MyProps {
    data: any;
    serialnumber:number
}

const useStyles = makeStyles({
    card: () => ({
        width: "100%",
        height: 48,
        borderRadius: 14,
        marginBottom: "15px",
        padding: 8,
        alignItems: "center",
        border: "1px solid #e3eaf4",
        position: 'relative',
        display: 'flex',
        background: "#ffffff",
        boxSizing: 'border-box',
        boxShadow: "none",
        '& .MuiCardContent-root': {
            padding: 0
        },
        "@media(max-width: 500px)": {
            width: 256
        },
        "@media(min-width: 1440px)": {
            width: '290px',
        },
    }),
    carddiv: () => ({
        margin: '0px',
    }),
    cardsub1: () => ({
        display: 'flex',
        gridColumnGap: '10px',
        justifyContent: 'start',
        marginTop: '5px',
        marginLeft: '5px',
    }),
    imgdiv: () => ({
        width: 75,
        height: 75,
    }),
    InfoDiv: () => ({
        gridRowGap: '10px',
    }),
    heading1: () => ({
        fontWeight: 'bold',
        fontSize: '14px',
        marginTop: '10px',
        marginLeft: '15px',
        color: "#17181D",
        fontFamily: "urbanist",
        lineHeight: '8px'
    }),
    numberDiv: () => ({
        border: "right",
        padding: '10px  10px',
        borderRight: '1px solid #e3eaf4',
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '8px',
        fontFamily: 'Urbanist',
    }),
    cardhead: () => ({
        display: "flex"
    })
})
export default function DepartCard(props: MyProps) {
    const { data ,serialnumber} = props;
    const classes = useStyles("");
    
    return (
        <Box className={classes.carddiv}>
            <Card className={classes.card}>
                <Box className={classes.cardhead}>
                    <Box className={classes.numberDiv}>
                       {serialnumber}
                    </Box>
                    <Box className={classes.heading1}>
                        {data.attributes.name}
                    </Box>
                </Box>
            </Card>
        </Box>
    );
}
