import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ChangeEvent, ReactNode } from "react";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedmsg: string;
  enableField: boolean;
  selectedOptions: string[];

  // Customizable Area Start
  isSideBarOpen: boolean;
  isDropdownOpen: boolean;
  isSideBarOpen1: boolean;
  allproject: boolean;
  projectdetails: boolean;
  allProjectData: any;
  allStudioSearch: string;
  filteredHousesList: any[] | null;
  currentpage: number;
  searchValue: string;
  allProjectDatavalue: boolean;
  allProjectDetailData: any;
  Id: number,
  allProjectDetaildepartmentData: any,
  allProjectDetailmemberdata: any,
  departmentId: number
  memberId: number;
  projectcount: number
  isLoading: boolean;
  upcoming: number;
  ongoing: number;
  completedproject: number;
  sortValue: string;
  count: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AllProjectViewController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllProjectApiId: string = "";
  getAllStudioApiId1: string = "";
  getAllStudioId: string = "";
  getProjectDetailApiCalledId: string = "";
  getAllProjectsearchDataApiCalledId: string = "";
  getAllProjectSortDataApiCalledId: string = ""
  getdepartmentProjectDetailApiCalledId: string = "";
  getmemberProjectDetailApiCalledId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedmsg: "A",
      enableField: false,
      // Customizable Area Start
      isSideBarOpen: true,
      isSideBarOpen1: true,
      isDropdownOpen: false,
      allproject: true,
      projectdetails: false,
      selectedOptions: [],
      allProjectData: [],
      allStudioSearch: "",
      filteredHousesList: null,
      currentpage: 1,
      searchValue: "",
      allProjectDatavalue: true,
      allProjectDetailData: null,
      allProjectDetaildepartmentData: null,
      allProjectDetailmemberdata: null,
      Id: 1,
      departmentId: 1,
      memberId: 1,
      projectcount: 0,
      isLoading: false,
      upcoming: 0,
      ongoing: 0,
      completedproject: 0,
      sortValue: "All",
      count: 0

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let msg = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedmsg + " To: " + msg
      );

      this.setState({ txtSavedmsg: msg });
    }

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestIdAllproject = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))

      const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
      if (apiRequestIdAllproject === this.getAllProjectApiId) {
        this.getAllProjectList(response)
        
      }
      else if (apiRequestIdAllproject == this.getAllProjectsearchDataApiCalledId) {
                   this.getProjectSearchCard(response)
      }
      else if (apiRequestIdAllproject == this.getAllProjectSortDataApiCalledId) {

        this.getAllProjectSortCard(response)

      }
      else if (this.getProjectDetailApiCalledId == apiRequestIdAllproject) {

        this.getAllprojectDetailValue(response)
        
      }
      else if (this.getdepartmentProjectDetailApiCalledId == apiRequestIdAllproject) {
        this.getdepartmentProjectList(response)
       
      }

      else if (this.getmemberProjectDetailApiCalledId == apiRequestIdAllproject) {
        this.getmemberprojectlist(response)
      }

    }
  }

  async componentDidMount() {
    window.scrollTo(0, 0);

    this.getAllProjectData();

    this.getProjectDetails();
    this.getdepartmentProjectDetails();
    this.getmemberProjectDetails();
    let isSideBarOpen=await getStorageData("sidebarvalue");
    this.setState({isSideBarOpen:JSON.parse(isSideBarOpen)})
  }
  handlePageChange = async (event: any, value: number) => {
    if (this.state.searchValue)
    {
      this.getAllProjectsearchDataApiCalledId = await this.apiCall(configJSON.GET_Method, configJSON.getAllProjectSearchDataApiEndPoint + `=${this.state.searchValue}&page=${value}`);
      window.scrollTo(0, 0);
    }
    else if (this.state.sortValue)
    {
      this.getAllProjectSortDataApiCalledId = await this.apiCall(configJSON.GET_Method, configJSON.getAllProjectSortDataApiEndPoint + `=${this.state.sortValue}&page=${value}`)
      window.scrollTo(0, 0);    
    }
    else
    {
      this.getAllProjectApiId = await this.apiCall(configJSON.GET_Method, configJSON.allProjectApiEndPoint + value);
      window.scrollTo(0, 0);
    }
    this.setState({ currentpage: value });
  };

  getAllProjectData = async () => {
    this.getAllProjectApiId = await this.apiCall(configJSON.GET_Method, configJSON.allProjectApiEndPoint + 1)
  }
    
  getAllProjectList=(res:any)=>{
    this.setState({
      projectcount: res.meta.count,
      count: res.meta.count,
      upcoming: res.meta.upcoming_project,
      ongoing: res.meta.ongoing_project,
      completedproject: res.meta.completed_project,
      allProjectData: res.data,
      isLoading: false
    })
  }
   getProjectSearchCard=(res:any)=>{
    this.setState({
      count: res?.meta?.project_count,
      allProjectData: res.data,
      isLoading: false
    })
   }
   getAllProjectSortCard=(res:any)=>{
       
    if (this.state.sortValue === "All") {
      this.setState({ count: this.state.projectcount })
    }
    else if (this.state.sortValue === "Upcoming") {
      this.setState({ count: this.state.upcoming })
    
    }
    else if(this.state.sortValue === "Ongoing")
    {
      this.setState({count:this.state.ongoing});
    }
    else if(this.state.sortValue==="Completed")
    {
      this.setState({count:this.state.completedproject})
    }
    this.setState({
      allProjectData:res.data,
      isLoading: false,
    })
   }

   getAllprojectDetailValue=(res:any)=>{
    if (res.data) {

      this.setState({
        Id: res.data.id,
        allProjectDetailData: res.data
      })

    }
   }

   getdepartmentProjectList=(res:any)=>{

    if (res.data) {
      this.setState({
        allProjectDetaildepartmentData: res.data,
      })

    }
   }
   getmemberprojectlist=(res:any)=>{
    if (res.data) {
      this.setState({
        memberId: res.id,
        allProjectDetailmemberdata: res.data
      })

    }
   }
  getAllProjectSort = async () => {
    this.getAllProjectSortDataApiCalledId = await this.apiCall(configJSON.GET_Method, configJSON.getAllProjectSortDataApiEndPoint + `=${this.state.sortValue}&page=1`)
  }

  getProjectDetails = async () => {

    let token = await getStorageData("token");
    let id = await getStorageData("id");
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProjectDetailApiCalledId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProjectDetailApiEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET_Method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getdepartmentProjectDetails = async () => {

    let token = await getStorageData("token");
    let id = await getStorageData("id");
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getdepartmentProjectDetailApiCalledId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getdepartmentProjectDetailApiEndPoint + `${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET_Method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getmemberProjectDetails = async () => {

    let token = await getStorageData("token");
    let id = await getStorageData("id");
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getmemberProjectDetailApiCalledId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getmemberProjectDetailApiEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET_Method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
  };



  handleSideBar = () => {
    this.setState({
      isSideBarOpen: !(this.state.isSideBarOpen)
    }
    , async() => {
      setStorageData("sidebarvalue",this.state.isSideBarOpen.toString())
    }
    );
  };
  handleDropdown = () => {
    this.setState({
      isDropdownOpen: !this.state.isDropdownOpen
    });
  };
  handleChange = async (e: ChangeEvent<{ value: any; }>, child: ReactNode) => {

    let value = e.target.value === "" ? "All" : e.target.value;
    this.setState({
      sortValue: value,
      currentpage: 1,

    })
    if (value)
      this.getAllProjectSortDataApiCalledId = await this.apiCall(configJSON.GET_Method, configJSON.getAllProjectSortDataApiEndPoint + `=${value}&page=1`)

  };

  navigateToProjectDetail = (id: any) => {
    this.getProjectDetails()
    this.props.navigation.navigate("UserProjectDetail");
    setStorageData("id", id)
  };
  handleClick = () => {

    this.setState({ allproject: !(this.state.allproject) });

  }
  doButtonPressed1() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }
  setInputValue1 = (text: string) => {
    this.setState({ txtInputValue: text });
  };
  setEnableField1 = () => {
    this.setState({ enableField: !this.state.enableField });
  };
  setAllProjectData(res: any) {
    this.setState({
      allProjectData: res.data,

    })
  }
  

  apiCall = async (method: string, endPoint: string) => {
    const authenticationToken = await getStorageData('token', false)
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": authenticationToken
    };
    this.setState({ isLoading: true });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  filterHouses = async (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      currentpage: 1,
      searchValue: event.target.value,
    });
    if (event.target.value)
      this.getAllProjectsearchDataApiCalledId = await this.apiCall(configJSON.GET_Method, configJSON.getAllProjectSearchDataApiEndPoint + `=${event.target.value}&page=1`);
    else
      this.getAllProjectApiId = await this.apiCall(configJSON.GET_Method, configJSON.allProjectApiEndPoint + 1);
  }

  handleNavigation = (page: string) => {
    this.props.navigation.navigate(page)
  };
  // Customizable Area End
}
