import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");


export interface Props {
    // Customizable Area Start
    navigation: any;
    id: string;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    loading: boolean;
    isJobRoleRequestSideBarOpen: boolean;
    isJobRoleRequestHeaderDropdownOpenVal: boolean;
    txtSavedValue: string;
    isLoading: boolean;
    jobRoleAllRequest: any[];
    projectName:any[];
    projectDepartment:any[];
    projectJobRoleRequest:any[];
    jobRoleInputs:any;
    validationStatusJobRole:any;
    projectRole:any[];
    totalPages: number
    count:number;
    totalJobRoleCount : number;
    currentpage: number,
    open:boolean
    open2:boolean
    tabIndex:number
    jobRoleprojectName:string
    projectRequest:any[];
    allJobRoleRequestData:any[];
    requestProjectData:any[];
    notification: { type: string, open: boolean, message: string, route?: string };
    JobRoleNotification: { type: string, open: boolean, message: string, route?: string };
    isProjectSelected:boolean
    isDepartmentSelected:boolean
    isRoleTypeSeleted:boolean
    roleReqError:any
    expError:any
    budgetError:any
    noOfPosError:any
    roleDesError:any
    setEditJobRole:boolean
    requestedVendorData:any[];
    Status:string;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: string | number;
    // Customizable Area End
}

export default class ProjectDetailRequestController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getJobRoleApiCallId: string = "";
    getProjectNameApiCallId : string ="";
    getProjectDepartmentCallId :string = "";
    getProjectDeptRoleApiCallId :string ="";
    getAllProjectJobRoleRequestCallID : string ="";
    getProjectRequestCallId :string ="";
    getAllJobRoleRequestCallID :string = "";
    addJobRoleApiCallId:string ="";
    deleteJobRoleApiCallId:string ="";
    editJobRoleApiCallId:string="";
    requestVendorApiCallId:string="";
    declineVendorRequestCallId:string="";
    acceptVendorRequestCallId:string="";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            loading: false,
            isJobRoleRequestSideBarOpen: true,
            isJobRoleRequestHeaderDropdownOpenVal: false,
            txtSavedValue: "A",
            isLoading: false,
            jobRoleAllRequest: [],
            totalPages: 0,
            currentpage: 1,
            count:0,
            totalJobRoleCount:0,
            projectName:[],
            projectDepartment:[],
            projectRole:[],
            open:false,
            open2:false,
            jobRoleInputs:{
                selectedProject:null,
                selectedProjectDept:null,
                selectedProjectRole:null,
                roleRequirements:[],
                expRequired:"",
                roleBudget:"",
                noOfPos:"",
                roleDescription:"",
                projectId:null,
                projectDeptId:null,
                roleID:null


            },
            roleReqError:{
                require:false
            },
            expError:{
                require:false
            },
            budgetError:{
                require:false
            },
            noOfPosError:{
                require:false
            },
            roleDesError:{
                require:false
            },
            validationStatusJobRole:{
                roleRequirements:{label:"Role Requirements" , valid:false , message :""},
                expRequired:{label:"Exp Required" , valid:false , message :""},
                roleBudget:{label:"Role Budget" , valid:false , message :""},
                noOfPos:{label:"No of Pos" , valid:false , message :""},
                roleDescription:{label:"Role Description" , valid:false , message :""}
            },
            tabIndex:0,
            jobRoleprojectName:'',
            projectJobRoleRequest:[],
            projectRequest:[],
            allJobRoleRequestData:[],
            requestProjectData:[],
            notification: { type: "", open: false, message: "" },
            JobRoleNotification: { type: "", open: false, message: "" },
            isProjectSelected:true,
            isDepartmentSelected:true,
            isRoleTypeSeleted:true,
            setEditJobRole:false,
            requestedVendorData:[],
            Status:"",
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
            let valueBalanceSheet = message.getData(getName(MessageEnum.AuthTokenDataMessage));


            this.setState({ txtSavedValue: valueBalanceSheet });
            this.showAlert(
                "Change Value",
                "From: " + this.state.txtSavedValue + " To: " + valueBalanceSheet
            );

        }
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            const apiHandlers = {
                [this.getJobRoleApiCallId]: this.handleJobRoleApiResponse,
                [this.getProjectNameApiCallId]: this.getProjectNameData,
                [this.getProjectDepartmentCallId]: this.getProjectDepartment,
                [this.getProjectDeptRoleApiCallId]: this.getProjectDepartmentRole,
                [this.getAllProjectJobRoleRequestCallID]: this.handleAllProjectJobRoleResponse,
                [this.getAllJobRoleRequestCallID]: this.getAllJobRoleResponse,
                [this.addJobRoleApiCallId]: this.handleAddJobRoleResponse,
                [this.editJobRoleApiCallId]: this.handleEditJobRoleResponse,
                [this.deleteJobRoleApiCallId]: this.handleDeleteJobRoleResponse,
                [this.requestVendorApiCallId] : this.handleRequestedVendorResponse,
                [this.declineVendorRequestCallId]:this.handleDeclineUpdateVendorRequest,
                [this.acceptVendorRequestCallId]:this.handleAcceptUpdateVendorRequest,
            };
        
            const handler = apiHandlers[apiRequestCallId];
            if (handler) {
                handler.call(this, responseJson);
            }
            
        }

        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
         this.getMyAllJobRole();
         this.getMyAllProjectName();
         this.getMyJobRoleAndRequest();
         this.getRequestedVendorRequestJobRoleData();
        let isSideBarOpen = await getStorageData("sidebarvalue");
        this.setState({ isJobRoleRequestSideBarOpen: JSON.parse(isSideBarOpen) })
        const projectNames=  await getStorageData("jobRoleproject_Name");
        this.setState({jobRoleprojectName:projectNames})
        const statusvalue= await getStorageData("statusPH")
        this.setState({Status:statusvalue})
    }

    handleJobRoleSideBar = () => {
        this.setState((prevState) => ({
            isJobRoleRequestSideBarOpen: !prevState.isJobRoleRequestSideBarOpen
        }), async () => {
            setStorageData("sidebarvalue", this.state.isJobRoleRequestSideBarOpen.toString())
        });
    };

    handleJobRoleHeaderDropdownVal = () => {
        this.setState({
            isJobRoleRequestHeaderDropdownOpenVal: !this.state.isJobRoleRequestHeaderDropdownOpenVal
        })
    };

    handleJobRoleApiResponse(responseJson:any) {
        this.setState({
            jobRoleAllRequest: responseJson.data, 
            count: responseJson.meta.projects_count,
            totalJobRoleCount: responseJson.meta.total_invoice_count,
            isLoading: false,
        });
    }

    handleAllProjectJobRoleResponse(responseJson:any) {
        this.setState({
            projectJobRoleRequest: responseJson.data,
            //requestProjectData: responseJson.data[0]?.attributes?.vendor_job_requests?.data,
            isLoading: false,
        });
    }

    handleAddJobRoleResponse(responseJson:any) {
        if (responseJson) {
            this.handleCloseJobRoleModel();
            this.setState({
                isLoading: false,
            });
        }
    }
    handleDeclineUpdateVendorRequest(responseJson:any) {
        if (responseJson) {
            this.getRequestedVendorRequestJobRoleData();
            this.setState({
                isLoading: false,
                requestedVendorData:[]
            });
        }
    }

  handleAcceptUpdateVendorRequest(responseJson: any, callback?: () => void) {
    if (responseJson) {
        this.getMyJobRoleAndRequest();
        this.setState({
            isLoading: false,
        }, () => {
            if (callback) {
                callback();
            }
        });
    }
}

    handleEditJobRoleResponse(responseJson:any) {
        if (responseJson) {
            this.handleCloseJobRoleModel1();
            this.getMyJobRoleAndRequest();
            this.setState({
                isLoading: false,
            });
        }
    }

    handleDeleteJobRoleResponse(responseJson:any) {
        if (responseJson) {
            this.getMyJobRoleAndRequest();
            this.setState({
                isLoading: false,
            });
        }
    }

   
    navigateToAllJobRolePage=(navigate:string)=>{
        this.props.navigation.navigate(navigate)
    }

    navigateToRequestPage=(job_role_id:number)=>{
        setStorageData("jobRoleId", job_role_id);  
        this.props.navigation.navigate("Request",)
    }

    getMyAllJobRole = async () => {
        this.getJobRoleApiCallId = await this.allJobRoleRequest(configJSON.GET_Method, configJSON.getallJobRoleRequestEndPoint + `?page=1&per_page=9`);
    };

    getMyAllProjectName = async () => {
        this.getProjectNameApiCallId = await this.allProjectName(configJSON.GET_Method, configJSON.getAllProjectNameEndPoint);
    };

    getMyAllprojectDeptRole =async () =>{
        this.getProjectDeptRoleApiCallId = await this.allProjectRoleType(configJSON.GET_Method, configJSON.getProjectDeptRoleEndPoint)
    }

    getMyAllProjectDepartment =async () =>{
        this.getProjectDepartmentCallId =await this.allProjectDepartment(configJSON.GET_Method, configJSON.getAllProjectDepartmentEndPoint + `?project_id=${this.state.jobRoleInputs.projectId}`)
    }

    getMyJobRoleAndRequest = async () => {
        const projectJobId=  await getStorageData("jobRoleproject_Id");
        this.getAllProjectJobRoleRequestCallID = await this.allProjectJobRoleRequest(configJSON.GET_Method , configJSON.getAllProjectJobRoleRequestEndPoint + `?project_id=${projectJobId}`);
    }
    postMyAddJobRoleFormData =async () =>{
        this.addJobRoleApiCallId = await this.addJobRoleApi(configJSON.httpPostMethod,configJSON.postAddJobRoleEndPoint)
    }

    editMyJobRoleFormData = async () =>{
        let editJobId = await getStorageData("editJobRoleId");
        this.editJobRoleApiCallId = await this.jobRoleRequestEdit("PUT","bx_block_roles_permissions/job_roles/" + `${editJobId}`)
    }

    deleteJobRoleRequest =async (id:any) =>{
        this.deleteJobRoleApiCallId = await this.jobRoleRequestDelete(configJSON.DELETE_Method,configJSON.deleteJobRoleRequest + `/${id}`)
    }

    editJobRoleRequest = async(id:any) =>{
        setStorageData("editJobRoleId", id);  
        this.setState({ setEditJobRole: true });
        this.setState({open2:false})
        for(let i=0;i<=this.state.projectJobRoleRequest.length;i++){
             if(this.state.projectJobRoleRequest[i]?.id === id){
                this.setState({jobRoleInputs:{
                selectedProject:this.state.projectJobRoleRequest[i].attributes.project_name,
                selectedProjectDept:this.state.projectJobRoleRequest[i].attributes.project_department,
                selectedProjectRole:this.state.projectJobRoleRequest[i].attributes.role,
                roleRequirements:this.state.projectJobRoleRequest[i].attributes.job_role_requirment.map((item:any)=>item),
                expRequired:this.state.projectJobRoleRequest[i].attributes.exp_required,
                roleBudget:this.state.projectJobRoleRequest[i].attributes.budget_of_role,
                noOfPos:this.state.projectJobRoleRequest[i].attributes.number_of_positions,
                roleDescription:this.state.projectJobRoleRequest[i].attributes.role_description
    }})
}}}

    handlePageChange = async (event: any, value: number) => {
        this.getJobRoleApiCallId = await this.allJobRoleRequest(configJSON.GET_Method, configJSON.getallJobRoleRequestEndPoint + `?page=${value}&per_page=9`);
        window.scrollTo(0, 0);
        this.setState({ currentpage: value });
    };


    handleOpenAddJobRole=()=>{
        this.setState({
            open:true
        })
    }
    navigateToJobRolesAndRequest=(jobRoleprojectId:any,jobRoleprojectName:any)=>{
        this.props.navigation.navigate("JobRolesAndRequest")
        setStorageData("jobRoleproject_Id", jobRoleprojectId);   
        setStorageData("jobRoleproject_Name",jobRoleprojectName);
      }
      

    handleRequestedVendorResponse(responseJson:any){
        if(responseJson.data){
            this.setState({
                requestedVendorData:responseJson.data
            })
        }
        this.setState({ isLoading: false });
    }
   

    handleCloseJobRoleModel = () => {
        this.setState({
            open: false,
            jobRoleInputs: {
                ...this.state.jobRoleInputs,
                selectedProject: null,
                selectedProjectDept:null,
                selectedProjectRole:null,
                roleRequirements:[],
                expRequired:"",
                roleBudget:"",
                noOfPos:"",
                roleDescription:"",
            },
            projectDepartment: [],
            projectRole: [],
            isProjectSelected: true,
            isDepartmentSelected: true,
            isRoleTypeSeleted: true ,
            roleReqError:{require:false},
            expError:{require:false},
            budgetError:{require:false},
            noOfPosError:{require:false},
            roleDesError:{require:false}
        });
    }

    handleCloseJobRoleModel1 =()=>{
        this.setState({
            setEditJobRole:false
        })
    }

    handleJobRoleEditAndDelete = (id: any) => {
        this.setState(prevState => ({
            open2: prevState.open2 === id ? null : id
        }));
    }


    getProjectNameData=(responseJson:any)=>{
        const fetchedProject = responseJson?.data?.map((item: { id: number, attributes: { project_name: string } }) => ({
            id: item.id,
            name: item.attributes.project_name,
        }));
        this.setState({
             projectName:fetchedProject,
             isLoading:false
         });
    }

    getProjectDepartment=(responseJson:any)=>{
        const projectDepartmentResponse = responseJson?.data?.map((item :{ id: number, attributes: { name: string } })=>({
            department:item.attributes.name,
            dept_id:item.id,
        }));
        this.setState({
            projectDepartment:projectDepartmentResponse,
            isLoading:false
        }) 
    }

    getProjectDepartmentRole=(responseJson:any)=>{
        const projectDeptRoleRes = responseJson?.data?.map((item : {id:number , name:string})=>({
            deptRole:item.name,
            role_id:item.id
        }));
        this.setState({
            projectRole:projectDeptRoleRes,
            isLoading:false
        })
    }

    getAllJobRoleResponse=(responseJson:{ errors: { token: string }[], data: any })=>{
        if(responseJson.data){
            this.setState({
                allJobRoleRequestData:responseJson.data
            })
        }
        else if({ notification: { open: true, type: "error", message: responseJson.errors[0].token, route: "Request" } }){
            this.setState({ notification: { open: true, type: "error", message: responseJson.errors[0].token, route: "Request" } });
        }
        this.setState({ isLoading: false });
    }

    handleProjectName = (selectedOption:any) => {
        this.setState((prevState) => ({
            jobRoleInputs: {
                ...prevState.jobRoleInputs,
                selectedProject: selectedOption,
                selectedProjectDept: "",
                projectId: selectedOption.value,
                selectedProjectRole: ""
            },
            isProjectSelected:true
        }), () => {
            this.getMyAllProjectDepartment();
        });
    };
    
    handleProjectdepartment = (selectedOption:any) => {
        this.setState((prevState) => ({
            jobRoleInputs: {
                ...prevState.jobRoleInputs,
                selectedProjectDept: selectedOption,
                projectDeptId: selectedOption.value
            },
            isDepartmentSelected:true
        }), () => {
            this.getMyAllprojectDeptRole();
        });
    };
    

    handleProjectDeptRole =(selectedOption:any) =>{
        this.setState((prevState)=>({
            jobRoleInputs:{
            ...prevState.jobRoleInputs,    
            selectedProjectRole : selectedOption,
            roleID : selectedOption.value
            },
            isRoleTypeSeleted:true
        }),()=>{
            this.getMyAllprojectDeptRole()
        })
    }

    handleSubmitFormAddJobRole=()=>{
        if(!this.state.jobRoleInputs.selectedProject || !this.state.jobRoleInputs.selectedProjectDept ||!this.state.jobRoleInputs.selectedProjectRole || !this.state.jobRoleInputs.roleRequirements || !this.state.jobRoleInputs.expRequired || !this.state.jobRoleInputs.roleBudget || !this.state.jobRoleInputs.noOfPos || !this.state.jobRoleInputs.roleDescription){
        this.validateJobRoleRequest();
        return false
    }
                 
      !this.state.setEditJobRole  ? this.postMyAddJobRoleFormData() : this.editMyJobRoleFormData()
     }

    validateJobRoleRequest=()=>{
        if (!this.state.jobRoleInputs.selectedProject) {
            this.setState({ isProjectSelected: false });
        }
        
        if (!this.state.jobRoleInputs.selectedProjectDept) {
            this.setState({ isDepartmentSelected: false });
        }
        
        if (!this.state.jobRoleInputs.selectedProjectRole) {
            this.setState({ isRoleTypeSeleted: false });
        }
        
        if (this.state.jobRoleInputs.roleRequirements.length<=0) {
            this.setState({ roleReqError: { require: true } });
        }
        
        if (!this.state.jobRoleInputs.expRequired) {
            this.setState({ expError: { require: true } });
        }
        
        if (!this.state.jobRoleInputs.roleBudget) {
            this.setState({ budgetError: { require: true } });
        }
        
        if (!this.state.jobRoleInputs.noOfPos) {
            this.setState({ noOfPosError: { require: true } });
        }
        
        if (!this.state.jobRoleInputs.roleDescription) {
            this.setState({ roleDesError: { require: true } });
        }
    }

    

    handleChangeRoleRequirements = (items:any) => {
        const eachvalue=items
        if (items.length<=0) {
            this.setState({
                roleReqError:{
                    require:false
                }
            })
            return true;
        } else {
            this.setState((prevState)=>({
                jobRoleInputs:{
                ...prevState.jobRoleInputs,    
                roleRequirements : eachvalue,
                },
                roleReqError:{
                    require:false
        }
            }))
        }
    }

    handleChangeExp=(event:any) =>{
        const expReqData = event?.target.value;
        const regex= /^[0-9\b]+$/
        if(expReqData === '' || regex.test(expReqData)){
            this.setState((prevState)=>({
                jobRoleInputs:{
                ...prevState.jobRoleInputs,    
                expRequired : expReqData
                },
            }))
        }
        if (!expReqData) {
            this.setState({
                expError:{
                    require:true
                }
            })
            return false;
        } else {
            this.setState({
                expError:{
                    require:false
                }
            })
        }
    }

    handleChangeBudget=(event:any)=>{
        const budgetData = event?.target.value;
        const regexBudget= /^[0-9\b]+$/
        if(budgetData === '' || regexBudget.test(budgetData)){
        this.setState((prevState)=>({
            jobRoleInputs:{
            ...prevState.jobRoleInputs,    
            roleBudget : budgetData
            },
        }))
    }
        if (!budgetData) {
            this.setState({
                budgetError:{
                    require:true
                }
            })
            return false;
        } else {
            this.setState({
                budgetError:{
                    require:false
                }
            })
        }
    }

    handleChangePos=(event:any)=>{
        const noOfPosData= event.target.value
        const regexPos= /^[0-9\b]+$/
        if(noOfPosData === '' || regexPos.test(noOfPosData)){
        this.setState((prevState)=>({
            jobRoleInputs:{
            ...prevState.jobRoleInputs,    
            noOfPos : noOfPosData
            },
        }))
    }
        if (!noOfPosData) {
            this.setState({
                noOfPosError:{
                    require:true
                }
            })
            return false;
        } else {
            this.setState({
                noOfPosError:{
                    require:false
                }
            })
        }
    }

    handleChangeRoleDes=(event:any)=>{
        const roleDesData= event?.target.value
        this.setState((prevState)=>({
            jobRoleInputs:{
            ...prevState.jobRoleInputs,    
            roleDescription : roleDesData
            },
        }))
        if (!roleDesData) {
            this.setState({
                roleDesError:{
                    require:true
                }
            })
            return false;
        } else {
            this.setState({
                roleDesError:{
                    require:false
                }
            })
        }

    }

    allJobRoleRequest = async (method: string, endPoint: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageJobRoleRequestAll = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageJobRoleRequestAll.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        requestMessageJobRoleRequestAll.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageJobRoleRequestAll.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(requestMessageJobRoleRequestAll.id, requestMessageJobRoleRequestAll);
        return requestMessageJobRoleRequestAll.messageId;
    };

    allProjectName = async (method: string, endPoint: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageJobRoleRequestProjectName = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageJobRoleRequestProjectName.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        requestMessageJobRoleRequestProjectName.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageJobRoleRequestProjectName.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(requestMessageJobRoleRequestProjectName.id, requestMessageJobRoleRequestProjectName);
        return requestMessageJobRoleRequestProjectName.messageId;
    };

    allProjectDepartment = async (method: string, endPoint: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageJobRoleRequestProjectDept = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageJobRoleRequestProjectDept.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        requestMessageJobRoleRequestProjectDept.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageJobRoleRequestProjectDept.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(requestMessageJobRoleRequestProjectDept.id, requestMessageJobRoleRequestProjectDept);
        return requestMessageJobRoleRequestProjectDept.messageId;
    };

    allProjectRoleType = async (method: string, endPoint: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageJobRoleRequestRoleType = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageJobRoleRequestRoleType.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        requestMessageJobRoleRequestRoleType.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageJobRoleRequestRoleType.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(requestMessageJobRoleRequestRoleType.id, requestMessageJobRoleRequestRoleType);
        return requestMessageJobRoleRequestRoleType.messageId;
    };

    allProjectJobRoleRequest = async (method: string, endPoint: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageJobRoleRequestProject = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageJobRoleRequestProject.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        requestMessageJobRoleRequestProject.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageJobRoleRequestProject.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(requestMessageJobRoleRequestProject.id, requestMessageJobRoleRequestProject);
        return requestMessageJobRoleRequestProject.messageId;
    };

    allJobRole = async (method: string, endPoint: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageAllJobRoleRequestRole = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageAllJobRoleRequestRole.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        requestMessageAllJobRoleRequestRole.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageAllJobRoleRequestRole.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(requestMessageAllJobRoleRequestRole.id, requestMessageAllJobRoleRequestRole);
        return requestMessageAllJobRoleRequestRole.messageId;
    };

    addJobRoleApi = async (method: string, endPoint: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageJobRoleRequestProject = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageJobRoleRequestProject.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        const job_roleData = {
            job_role:{
            role_description: this.state.jobRoleInputs.roleDescription,
            exp_required: this.state.jobRoleInputs.expRequired,
            budget_of_role: this.state.jobRoleInputs.roleBudget,
            number_of_positions:this.state.jobRoleInputs.noOfPos,
            project_id:this.state.jobRoleInputs.projectId,
            project_department_id :this.state.jobRoleInputs.projectDeptId,
            role_id:this.state.jobRoleInputs.roleID,
            job_role_requirment: this.state.jobRoleInputs.roleRequirements
            }
            }

        requestMessageJobRoleRequestProject.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(job_roleData)
        );
        requestMessageJobRoleRequestProject.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            header
        );
        requestMessageJobRoleRequestProject.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(requestMessageJobRoleRequestProject.id, requestMessageJobRoleRequestProject);
        return requestMessageJobRoleRequestProject.messageId;
    };

    jobRoleRequestDelete = async (method: string, endPoint: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageJobRoleRequestRole = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageJobRoleRequestRole.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        requestMessageJobRoleRequestRole.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageJobRoleRequestRole.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(requestMessageJobRoleRequestRole.id, requestMessageJobRoleRequestRole);
        return requestMessageJobRoleRequestRole.messageId;
    };

    jobRoleRequestEdit = async (method: string, endPoint: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageJobRoleRequestRole = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageJobRoleRequestRole.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        const job_roleData_edit = {
            job_role:{
            role_description: this.state.jobRoleInputs.roleDescription,
            exp_required: this.state.jobRoleInputs.expRequired,
            budget_of_role: this.state.jobRoleInputs.roleBudget,
            number_of_positions:this.state.jobRoleInputs.noOfPos,
            project_id:this.state.jobRoleInputs.projectId,
            project_department_id :this.state.jobRoleInputs.projectDeptId,
            role_id:this.state.jobRoleInputs.roleID,
            job_role_requirment: this.state.jobRoleInputs.roleRequirements
            }
            }

            requestMessageJobRoleRequestRole.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(job_roleData_edit)
        );

        

        requestMessageJobRoleRequestRole.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageJobRoleRequestRole.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(requestMessageJobRoleRequestRole.id, requestMessageJobRoleRequestRole);
        return requestMessageJobRoleRequestRole.messageId;
    };

    getRequestedVendorRequestJobRoleData= async()=>{
        let requestVendorId = await getStorageData("jobRoleId");
        this.requestVendorApiCallId = await this.getJobRoleRequestedVendor(configJSON.GET_Method , configJSON.getRequestVendorEndPoint + `/${requestVendorId}`)
    }


    getJobRoleRequestedVendor = async (method: string, endPoint: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageJobRoleRequestRating = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageJobRoleRequestRating.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        requestMessageJobRoleRequestRating.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageJobRoleRequestRating.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(requestMessageJobRoleRequestRating.id, requestMessageJobRoleRequestRating);
        return requestMessageJobRoleRequestRating.messageId;
    };
    declineUpdateVendorRequest = async (method: string, endPoint: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageJobRoleRequestDecline = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageJobRoleRequestDecline.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        const vendorJobRequest = {
            vendor_job_request:{
                status: "decline",
            }
        }

        requestMessageJobRoleRequestDecline.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(vendorJobRequest)
        );

        requestMessageJobRoleRequestDecline.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageJobRoleRequestDecline.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(requestMessageJobRoleRequestDecline.id, requestMessageJobRoleRequestDecline);
        return requestMessageJobRoleRequestDecline.messageId;
    };

    declineVendorRequest = async(id:any)=>{
        this.declineVendorRequestCallId = await this.declineUpdateVendorRequest(configJSON.PATCH_Method,configJSON.acceptUpdateVendorEndPoint + `/${id}`)
    }
    acceptVendorRequest =async(id:any)=>{
        this.acceptVendorRequestCallId = await this.acceptUpdateVendorRequest(configJSON.PATCH_Method,configJSON.acceptUpdateVendorEndPoint + `/${id}`)
    }
    acceptUpdateVendorRequest = async (method: string, endPoint: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageJobRoleRequestAccept = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageJobRoleRequestAccept.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        const vendorJobRequest = {
            vendor_job_request:{
                status: "accept",
            }
        }
        requestMessageJobRoleRequestAccept.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(vendorJobRequest)
        );

        requestMessageJobRoleRequestAccept.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageJobRoleRequestAccept.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(requestMessageJobRoleRequestAccept.id, requestMessageJobRoleRequestAccept);
        return requestMessageJobRoleRequestAccept.messageId;
    };


    // Customizable Area End
}
