import React from "react";
import {
  Container,
  Grid,
  Box,
  Button,
  Typography,
  TypographyProps,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from "@material-ui/core";
import { Formik, Form, Field, ErrorMessage } from "formik";
import SideBar from "../../../components/src/SideBar";
import Header from "../../../components/src/Header.web";
import Footer from "../../../components/src/Footer";
import CustomSwitch from "../../../components/src/CustomSwitch";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
//@ts-ignore
import { ReactComponent as Profile } from "../assets/profile.svg";
// @ts-ignore
import { ReactComponent as Settings } from "../assets/settings.svg";
// @ts-ignore
import { ReactComponent as Signout } from "../assets/power.svg";
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import Loader from "../../../components/src/Loader.web";
const theme = createTheme({
  palette: {
    primary: {
      main: "#F5C42C",
      contrastText: "#fff"
    }
  },

  overrides: {
    MuiSelect: {
      select: {
        "&:focus": {
          border: "1px solid #F5C42C",
        }
      }
    }

  }
})
import UserProfileBasicController, {
  Props
} from "./UserProfileBasicController";

import { ProfileDummy } from "../../../components/src/assets";

export default class UserSettings extends UserProfileBasicController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  renderMainContent(sideBarStatus: boolean = this.state.isSideBarOpen) {
    return (
      <Grid style={webStyle.contentSetup}>
        <Loader loading={this.state.isLoading}></Loader>
        <Header
          dropDownStatus={this.state.isDropdownOpen}
          sideBarStatus={sideBarStatus}
          handleSideBar={this.handleSideBar}
          navigate={this.props.navigation.navigate}
          handleDropdown={this.handleDropdown}
        />
        <Grid style={webStyle.mainContent as React.CSSProperties}>
          <Box sx={webStyle.blockInfo}>
            <Typography
              style={webStyle.activeBlock as TypographyProps}
              variant="h3"
            >
              Profile
            </Typography>
            <Typography
              variant="h6"
              style={webStyle.blockPath as TypographyProps}
            >
              <span style={webStyle.highlightedText}>Home</span> / Profile
            </Typography>
          </Box>
          <Grid container style={{ marginTop: "12px" }} spacing={3}>
            <Grid item lg={4} md={4} sm={12} xs={12} >
              <Box
                style={webStyle.blockStyle as React.CSSProperties}
              >
                <List>

                  <ListItem>
                    <Box
                      sx={webStyle.userDataInList}
                    >
                      <Box
                        sx={webStyle.userImageBoxInList}
                      >
                        
                         
                         <img
                          src={
                            this.state.setImage||ProfileDummy
                          }style={{height:100,width:100} as React.CSSProperties}
                         
                          />
                       
                      </Box>

                      <Box>

                        <Box
                          sx={webStyle.userNameInList}
                        >
                          {this.state.setNamevalue}
                        
                        </Box>

                        <Box
                          sx={webStyle.userEmailInList}
                        >
                          {this.state.setEmailvalue}
                        </Box>
                      </Box>
                    </Box>
                  </ListItem>
                  <Box style={webStyle.seperateDropdown} />
                  <Box style={webStyle.listName}>Manage</Box>
                  <ListItem
                    style={
                      {
                        ...webStyle.inactiveLink,
                        marginBottom: "16px"
                      } as React.CSSProperties
                    }
                    onClick={() =>
                      this.props.navigation.navigate("UserProfileBasicBlock")
                    }
                    data-test-id="list-item"
                  >
                    <ListItemAvatar style={webStyle.dropdownIcon}>
                      <Profile />
                    </ListItemAvatar>
                    <ListItemText
                      primary="Manage Profile"
                      primaryTypographyProps={{
                        style: webStyle.listItemText as React.CSSProperties
                      }}
                      style={webStyle.listText as React.CSSProperties}

                    />
                  </ListItem>
                  <ListItem
                    style={webStyle.activeLink as React.CSSProperties}
                    data-test-id="list-item-settings"
                  >
                    <ListItemAvatar style={webStyle.dropdownIcon}>
                      <Settings fill={"#F5C42C"} />
                    </ListItemAvatar>
                    <ListItemText
                      primary="Settings"
                      primaryTypographyProps={{
                        style: webStyle.listItemText as React.CSSProperties
                      }}
                      style={webStyle.listText as React.CSSProperties}
                    />
                  </ListItem>
                  <Box style={webStyle.seperateDropdown} />
                  <ListItem>
                    <Button
                      fullWidth
                      startIcon={<Signout />}
                      style={webStyle.signout as React.CSSProperties}
                      variant="outlined" onClick={() =>  this.props.navigation.navigate("LandingPage")}
                    >
                      Sign Out
                    </Button>
                  </ListItem>
                </List>
              </Box>
            </Grid>
            <Grid item md={8} xs={12} sm={12} lg={8}>
              <Box
                style={
                  {
                    ...webStyle.blockStyle,
                    height: "100%",
                    padding: "30px"
                  } as React.CSSProperties
                }
              >
                <Box>
                  <Typography style={webStyle.pageTitle as React.CSSProperties}>
                    Change Password
                  </Typography>
                  <Typography
                    style={webStyle.pageDescription as React.CSSProperties}
                  >
                    Update your new password by confirm your old password.
                  </Typography>
                </Box>
                <Box style={webStyle.seperateMainBlock} />

                <Formik
                  initialValues={{
                    oldPassword: "",
                    newPassword: "",
                    confirmPassword: ""
                  }}
                  onSubmit={values => {

                  }}
                  validateOnBlur
                  validateOnChange
                >
                  <Form
                    style={webStyle.formStyle as React.CSSProperties}
                    translate=""
                    autoComplete="off"
                  >
                    <Box>
                      <Box>
                        <label
                          htmlFor="oldPassword"
                          id="oldPassword-label"
                          className="form-label"
                          data-test-id="oldPassword-label"
                          style={webStyle.formLabel as React.CSSProperties}
                        >
                          Your Old Password
                        </label>
                        <Box style={webStyle.fieldBox as React.CSSProperties}>
                          <Field
                            type={
                              "text"
                            }
                            id="oldPassword"
                            name="oldPassword"
                            data-test-id="oldPassword-input"
                            placeholder="Enter your old password"
                            style={webStyle.formInput as React.CSSProperties}
                            className="form-input-feild"
                            onFocus={() => {
                              this.handleInputFocus("oldPassword-label");
                            }}
                            onBlur={() => {
                              this.handleInputBlur("oldPassword-label");
                            }}
                            onChange={(e: any) => this.setpasswordOfEmail(e)}
                            onKeyDown={this.handleKey}
                            value={this.state.showOldPassword?this.state.copyPassword:"*".repeat(this.state.passwordLogin.length)}
                          error/>
                          <Box
                            style={webStyle.visiblityBox as React.CSSProperties}
                            data-test-id="visiblity-1"
                            onClick={() =>
                              this.handlePasswordVisiblity("showOldPassword")
                            }
                          >
                            {this.state.showOldPassword? (
                              <VisibilityOutlinedIcon />
                            ) : (
                              <VisibilityOffOutlinedIcon />
                            )}
                          </Box>
                        </Box>
                        <Typography style={{color:"red",fontSize:"14px"}}>{this.state.validateoldPassword}</Typography>
                        <ErrorMessage
                          name="oldPassword"
                          component="Box"
                          className="formErrorStyle"
                        />
                      </Box>
                      <Box>
                        <label
                          className="form-label"
                          htmlFor="newpassword"
                          id="newpassword-label"
                          data-test-id="newpassword-label"
                          style={webStyle.formLabel as React.CSSProperties}
                        >
                          Your New Password
                        </label>
                        <Box style={webStyle.fieldBox as React.CSSProperties}>
                          <Field
                            type={
                              "text"
                            }
                            id="newpassword"
                            name="newPassword"
                            data-test-id="newpassword-input"
                            placeholder="Enter your new password"
                            style={webStyle.formInput as React.CSSProperties}
                            className="form-input-feild"
                            onFocus={() => {
                              this.handleInputFocus("newpassword-label");
                            }}
                            onBlur={() => {
                              this.handleInputBlur("newpassword-label");
                            }}
                            onChange={(e: any) => this.setpasswordOfEmail1(e)}
                            onKeyDown={this.handleKey1}
                            value={this.state.showNewPassword?this.state.copyPassword1:"*".repeat(this.state.passwordLogin1.length)}
                          />

                          <Box
                            style={webStyle.visiblityBox as React.CSSProperties}
                            data-test-id="visiblity-2"
                            onClick={() =>
                              this.handlePasswordVisiblity("showNewPassword")
                            }
                          >
                            {this.state.showNewPassword ? (
                              <VisibilityOutlinedIcon />
                            ) : (
                              <VisibilityOffOutlinedIcon />
                            )}
                          </Box>
                        </Box>
                        <Typography style={{color:"red",fontSize:'14px'}}>{this.state.validateNewPassword}</Typography>
                        <ErrorMessage
                          name="newPassword"
                          component="Box"
                          className="formErrorStyle"
                        />
                      </Box>
                      <Box>
                        <label
                          className="form-label"
                          htmlFor="confirmpassword"
                          id="confirmpassword-label"
                          data-test-id="confirmpassword-label"
                          style={webStyle.formLabel as React.CSSProperties}
                        >
                          Confirm Your New Password
                        </label>
                        <Box style={webStyle.fieldBox as React.CSSProperties}>
                          <Field
                            type={
                              "text"
                            }
                            id="confirmpassword"
                            name="confirmPassword"
                            data-test-id="confirmpassword-input"
                            placeholder="Enter your confirm password"
                            style={webStyle.formInput as React.CSSProperties}



                            className="form-input-feild"

                            onFocus={() => {
                              this.handleInputFocus("confirmpassword-label");
                            }}
                            onBlur={() => {
                              this.handleInputBlur("confirmpassword-label");
                            }}
                            onChange={(e: any) => this.setpasswordOfEmail2(e)}
                            onKeyDown={this.handleKey2}
                            value={this.state.showConfirmPassword?this.state.copyPassword2:"*".repeat(this.state.passwordLogin2.length)}
                          />
                          <Box
                            style={webStyle.visiblityBox as React.CSSProperties}
                            data-test-id="visiblity-3"
                            onClick={() =>
                              this.handlePasswordVisiblity(
                                "showConfirmPassword"
                              )
                            }
                          >
                            {this.state.showConfirmPassword ? (
                              <VisibilityOutlinedIcon />
                            ) : (
                              <VisibilityOffOutlinedIcon />
                            )}
                          </Box>
                        </Box>
                        <Typography style={{color:"red",fontSize:'14px'}}>{this.state.validateConfirmPassword}</Typography>
                        <ErrorMessage
                          name="confirmPassword"
                          component="Box"
                          className="formErrorStyle"
                        />
                      </Box>
                    </Box>
                    <button
                      className="form-submit-button"
                      type="submit"
                      style={webStyle.submitButton as React.CSSProperties} onClick={this.doEmailLogIn}
                    >
                      Update Password
                    </button>
                  </Form>
                </Formik>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item lg={4} md={4} xs={12} sm={12} />
            <Grid item lg={8} md={8} xs={12} sm={12}>
              <Box
                style={
                  {
                    ...webStyle.notifications,
                    ...webStyle.blockStyle,
                    padding: "26px"
                  } as React.CSSProperties
                }
              >
                <Box>
                  <Typography
                    style={webStyle.notificationHeading as React.CSSProperties}
                  >
                    Notifications
                  </Typography>
                  <Typography style={webStyle.notificationText}>
                    Manage alerts of push notifications
                  </Typography>
                </Box>
                <Box>
                  <CustomSwitch
                    checked={this.state.isNotificationsChecked}
                    handleChange={this.handleChange}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Footer />
      </Grid>
    );
  }
  render() {
    return (
      <ThemeProvider theme={theme}>
        <Container style={webStyle.parentDiv}>
          {this.state.isSideBarOpen ? (
            <Grid container spacing={0}>
              <Grid item xs={3} style={webStyle.sidebarParent}>
                <SideBar
                  handleSideBar={this.handleSideBar}
                  navigate={this.props.navigation.navigate}
                  activeMenuItem={""}
                  userData={{ age: 25 }}
                />
              </Grid>
              <Grid item xs={9}>
                {this.renderMainContent()}
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={12}>
              {this.renderMainContent()}
            </Grid>
          )}
        </Container>
      </ThemeProvider>
    );
  }
}

const webStyle = {

  sidebarParent: {
    minHeight: "100vh",
    //maxWidth: "23%",
  },
  parentDiv: {
    padding: "0px",
    maxWidth: "none"
  },
  mainContent: {
    width: "100%",
    minHeight: "100vh",
    backgroundColor: "#F8FAFE",
    boxSizing: "border-box",
    padding: "24px"
  },
  contentSetup: {
    // minHeight: "90vh",
    borderLeft: "1px solid #E3EAF4"
  },
  highlightedText: {
    color: "#F5C42C",
    fontFamily: 'Urbanist',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  blockInfo: {
    // padding: "15px",
  },
  listItemText: {
    fontWeight: 500,
    letterSpacing: "0.8px",
    lineHeight: "19px",
    fontSize: "16px"
  },
  blockPath: {
    fontSize: "14px",
    letterSpacing: "1px",
    color: "#6D89AF",
    marginBottom: "15px",
    fontWeight: 500,
    paddingBottom: "3px",
    lineHeight: "17px",
    paddingTop: "8px"
  },
  activeBlock: {
    lineHeight: "34px",
    fontSize: "28px",
    fontWeight: "700",
    letterSpacing: "1px",
    color: "#17181D"

  },
  userImageBoxInList: {
    width: "90px",
    height: "90px",
    borderRadius: "50%",
    overflow: "hidden",
    margin: "0 auto",
    position: "relative",
    marginBottom: "10px"
  },
  navigationInfo: {
    display: "flex",
    alignItems: "center"
  },
  userDataInList: {
    width: "100%",
    textAlign: "center"
  },
  userEmailInList: {
    color: "#6D89AF",
    fontSize: "18px",
    lineHeight: "21px"
  },
  userImage: {

    height: "100%",
    objectFit: "cover",
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
  },
  seperateDropdown: {

    border: "0.5px solid #E3EAF4",
    width: "90%",
    margin: "20px auto 0 auto"
  },
  userNameInList: {

    fontWeight: "600",
    fontSize: "22px",
    lineHeight: "26px"
  },
  dropdownIcon: {

    minWidth: "0",
    marginRight: "10px"
  },
  seperateMainBlock: {

    border: "0.5px solid #E3EAF4",
    width: "100%",
    margin: "0px 0"

  },
  signout: {

    textTransform: "none",
    borderRadius: "20.5px",
    backgroundColor: "#F8FAFE",
    color: "#6D89AF",
    marginTop: "14px",
    paddingTop: "14px",
    paddingBottom: "14px",

    fontFamily: "Urbanist",
    fontSize: "14px",
    lineHeight: "17px",
    fontWeight: 500,
    borderColor: "#E3EAF4"

  },
  listText: {

    margin: "0",

    padding: "0"
  },
  inactiveLink: {
    margin: "0 auto",
    cursor: "pointer",
    height: "50px",
    backgroundColor: "#ececee66",
    borderRadius: "12px",
    width: "90%",

  },
  activeLink: {
    cursor: "pointer",
    height: "50px",
    color: "#F5C42C",
    backgroundColor: "#faf2d8",
    borderRadius: "12px",
    width: "90%",
    margin: "0 auto"
  },
  listName: {
    fontSize: "14px",
    color: "#6D89AF",
    margin: "13px 16px"
  },
  blockStyle: {
    backgroundColor: "#fff",
    border: "1px solid #E1E6EC",
    borderRadius: "16px",
    boxSizing: "border-box"
  },
  pageTitle: {
    fontSize: "28px",
    lineHeight: "34px",
    fontWeight: "700",
    letterSpacing: "1.2px",
    color: "#17181D",
    paddingBottom: '6px'
  },
  pageDescription: {
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#6D89AF",
    fontFamily: "Urbanist",
    paddingBottom: "18px"
  },
  submitButton: {
    cursor: "pointer",
    color: "#ffffff",
    backgroundColor: "#17181D",
    textTransform: "none",
    width: "250px",
    border: "none",
    borderRadius: "14px",
    position: "revert",
    bottom: "16px",
    left: "16px",
    height: "52px",
    fontWeight: "500",
    fontSize: "14px",
    paddingTop: "14px",
    paddingBottom: "14px",
    marginTop: "30px",
    fontFamily:"Urbanist"
  },
  formStyle: {},
  formLabel: {
    color: "#6D89AF",
    lineHeight: "18px",
    fontSize: "14px",
    display: "block",
    margin: "20px 0 6px 0",
    fontWeight: "600",
    letterSpacing: "0.43px",
    fontFamily: "urbanist"
  },
  formInput: {
    borderColor: "#E3EAF4",
    width: "100%",
    border: "1px solid #E3EAF4",
    borderRadius: "14px",
    height: "50px",
    padding: "0 9px",
    fontWeight: "600",
    fontSize: "16px",
    paddingTop:"5px",
  },
  fieldBox: {
    position: "relative"
  },
  visiblityBox: {
    height: "20px",
    width: "20px",
    position: "absolute",
    top: "13px",
    right: "13px",
    cursor: "pointer"
    // borderRadius: "50%",
  },
  notifications: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    alignItems: "center"
  },
  notificationHeading: {
    lineHeight: "34px",
    fontWeight: "700",
    fontSize: "28px",
    color: "#17181D",
    letterSpacing: "1px",
    paddingLeft: '17px'
  },
  notificationText: {
    color: "#6D89AF",
    fontSize: "16px",
    lineHeight: "22px",
    marginTop: "6px",
    fontWeight: 500,
    paddingLeft: '17px'
  }
};
