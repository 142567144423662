import React from 'react'
const images = require("./assets")
import {
    Typography
} from "@material-ui/core";
function Footer() {
    return (
        <footer style={{ width: "100%" }}>
            <div style={webStyle.footerBlock as React.CSSProperties}>
                <Typography style={{fontSize:"14px",width:"335px",lineHeight:"17px"}}>Copyright &copy; 2023-24 <strong style={webStyle.footerBoldText}>AI Pictures</strong>. All rights reserved.</Typography>
                <img style={webStyle.footerLogo as React.CSSProperties} src={`${images.footer}`} />
            </div>
        </footer>
    )
}

export default Footer;

const webStyle = {
    footerBlock: {
        height: "70px",
        borderTop: "1px solid #E3EAF4",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        color: "#8298b7",
        paddingInline: "24px",
        flexWrap: "wrap",
        background: "#fff", // You can set the background color if needed
    },
    footerBoldText: {
        textDecoration: "underline",
        color:"black",
        fontSize:"14px",
    },
    footerLogo:{
        
    }
  
}