import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import React from "react";
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isVendorManageProfileSideBarOpen: boolean;
  isLoading: boolean;
  isVendorManageProfileDropdownOpen: boolean;
  vendorManageProfileData: any;
  vendorManageProfileReviewData: any;
  vendorManageProfileProjectsData: any;
  vendorManageProfileTabValue: number;
  profileId: string;
  vendorId: string;
  notification: { type: string, open: boolean, message: string, route?: string };
  bioReadMoreLess: boolean;
  isModelOpen: boolean;
  isOpenDialog: boolean;
  isInviteMember : boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class VendorManageProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getVendorManageProfileDataMessageId: string = "";
  getVendorManageProfileReviewDataMessageId: string = "";
  getVendorManageProfileProjectsDataMessageId: string = "";
  addMoreGalleryPhotosMessageId: string = "";
  fileUpload: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.fileUpload = React.createRef();
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isVendorManageProfileSideBarOpen: true,
      isLoading: false,
      isVendorManageProfileDropdownOpen: false,
      vendorManageProfileData: {
        id: "",
        profile_image: "",
        role: "",
        city: "",
        state: "",
        experience: "",
        gallary_photos: [],
        bank_detail: {
          data: {
            attributes: {
              image: "",
              bank_name: "",
            }
          }
        },
        account_detail: {
          data: {
            attributes: {
              full_name: "",
              country_code: "",
              phone_number: "",
              email: "",
              aadhar_card_number: "",
              gst_number: "",
              pan_card_number: "",
              tan_number: "",
              account_number: "",
              ifsc_code: "",
              branch_name: "",
            }
          }
        },
        profile_bio: {
          data: {
            attributes: {
              about_business: "",
            }
          },
        },
        documents: [],
      },
      vendorManageProfileReviewData: {},
      vendorManageProfileProjectsData: [],
      vendorManageProfileTabValue: 0,
      profileId: "",
      vendorId: "",
      notification: { type: "", open: false, message: "" },
      bioReadMoreLess: true,
      isModelOpen: false,
      isOpenDialog: false,
      isInviteMember : false
      // Customizable Area End
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const vendorManageProfileApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const vendorManageProfileResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.getVendorManageProfileDataMessageId === vendorManageProfileApiRequestCallId) {
        this.getVendorManageProfileDataHandleResponse(vendorManageProfileResponseJson);
      } else if (this.getVendorManageProfileReviewDataMessageId === vendorManageProfileApiRequestCallId) {
        this.getVendorManageProfileReviewDataHandleResponse(vendorManageProfileResponseJson);
      } else if (this.getVendorManageProfileProjectsDataMessageId === vendorManageProfileApiRequestCallId) {
        this.getVendorManageProfileProjectsDataHandleResponse(vendorManageProfileResponseJson);
      } else if (this.addMoreGalleryPhotosMessageId === vendorManageProfileApiRequestCallId) {
        this.getVendorManageProfileDataHandleResponse(vendorManageProfileResponseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getVendorManageProfileDataHandleResponse = (vendorManageProfileDataResponseJson: { errors: { token: string }[], data: any }) => {
    if (vendorManageProfileDataResponseJson.data) {
      this.setState({ vendorManageProfileData: vendorManageProfileDataResponseJson.data.attributes });
    } else if (vendorManageProfileDataResponseJson.errors[0].token) {
      this.setState({ notification: { open: true, type: "error", message: vendorManageProfileDataResponseJson.errors[0].token, route: "LandingPage" } });
    }
    this.setState({ isLoading: false });
  }

  getVendorManageProfileProjectsDataHandleResponse = (vendorManageProfileDataResponseJson: { errors: { token: string }[], data: any[] }) => {
    if (vendorManageProfileDataResponseJson.data) {
      this.setState({ vendorManageProfileProjectsData: vendorManageProfileDataResponseJson.data });
    } else if (vendorManageProfileDataResponseJson.errors[0].token) {
      this.setState({ notification: { open: true, type: "error", message: vendorManageProfileDataResponseJson.errors[0].token, route: "LandingPage" } });
    }
    this.setState({ isLoading: false });
  }

  getVendorManageProfileReviewDataHandleResponse = (vendorManageProfileDataResponseJson: { errors: { token: string }[], average_rating: number, rating_star: any, review_rating_count: string, reviews: { data: any[] } }) => {
    if (vendorManageProfileDataResponseJson.reviews?.data) {
      this.setState({ vendorManageProfileReviewData: vendorManageProfileDataResponseJson });
    } else if (vendorManageProfileDataResponseJson.errors[0].token) {
      this.setState({ notification: { open: true, type: "error", message: vendorManageProfileDataResponseJson.errors[0].token, route: "LandingPage" } });
    }
    this.setState({ isLoading: false });
  }

  handleVendorManageProfileSideBar = () => {
    setStorageData("sidebarvalue", (!this.state.isVendorManageProfileSideBarOpen).toString())
    this.setState({
      isVendorManageProfileSideBarOpen: !this.state.isVendorManageProfileSideBarOpen
    });
  };

  handleDropdown = () => {
    this.setState({
      isVendorManageProfileDropdownOpen: !this.state.isVendorManageProfileDropdownOpen
    });
  };

  handleNavigation = (page: string) => {
    this.props.navigation.navigate(page)
  };

  async componentDidMount() {
    const currentUrl = window.location.href;
    const currentPageURL = currentUrl.split('/').pop();
    if(currentPageURL === 'InviteMember' ){
      this.setState({
        isInviteMember : true
      })
    }
    const profileId = await getStorageData("userid");
    const vendorId = await getStorageData("userId");
    const isVendorManageProfileSideBarOpen = await getStorageData("sidebarvalue");
    this.setState({ profileId: profileId, vendorId: vendorId, isVendorManageProfileSideBarOpen: JSON.parse(isVendorManageProfileSideBarOpen) });
    this.getVendorManageProfileDataMessageId = await this.apiCall(configJSON.validationApiMethodType, configJSON.getVendorManageProfileDetailsAPIEndPoint + profileId);
    this.getVendorManageProfileProjectsDataMessageId = await this.apiCall(configJSON.validationApiMethodType, configJSON.getVendorManageProfileProjectsAPIEndPoint + profileId);
    this.getVendorManageProfileReviewDataMessageId = await this.apiCall(configJSON.validationApiMethodType, configJSON.getVendorManageProfileReviewDataAPIEndPoint + profileId);
  }

  apiCall = async (vendorManageProfileMethod: string, vendorManageProfileEndPoint: string, vendorManageProfileBodyData?: any, vendorManageProfileFormData?: any) => {
    const authToken = await getStorageData("token");
    const vendorManageProfileHeader = {
      "Content-Type": configJSON.contentTypeApiUpdateUser,
      token: authToken
    };
    const vendorManageProfileRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setState({ isLoading: true });
    vendorManageProfileRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      vendorManageProfileEndPoint
    );
    vendorManageProfileRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(vendorManageProfileFormData ? { token: authToken } : vendorManageProfileHeader)
    );
    vendorManageProfileBodyData && vendorManageProfileRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(vendorManageProfileBodyData)
    );
    vendorManageProfileFormData && vendorManageProfileRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      vendorManageProfileFormData
    );
    vendorManageProfileRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      vendorManageProfileMethod
    );
    runEngine.sendMessage(vendorManageProfileRequestMessage.id, vendorManageProfileRequestMessage);
    return vendorManageProfileRequestMessage.messageId;
  }

  handleTabValueChange = (tabVal: number) => {
    this.setState({ vendorManageProfileTabValue: tabVal });
  }

  handleCloseNotification = () => {
    this.setState({ notification: { type: "", open: false, message: "" } });
  }

  handleBioRead = () => {
    this.setState({ bioReadMoreLess: !this.state.bioReadMoreLess });
  }

  covertToPascalCase = (text: string, trimSpace = false) => {
    return text.split(' ').map((t) => t[0].toUpperCase() + t.slice(1).toLowerCase()).join(trimSpace ? '' : ' ')
  }

  handleAddMorePhotos = async (event: any) => {
    if (event.target.files && event.target.files[0]) {
      const formData = new FormData();
      for (let file of event.target.files) {
        formData.append("profile[photos_gallaries][]", file);
      }
      this.addMoreGalleryPhotosMessageId = await this.apiCall(configJSON.apiUpdateUserType, configJSON.vendorManageProfileAddGalleryPhotosAPIEndPoint + this.state.profileId, null, formData);
    }
  }

  handleClickAddMore = () => {
    this.fileUpload.current.click();
  }

  vendorManageProfileHandleDownloadPDF = (pdfURL: any, fileName: string) => {
    fetch(pdfURL)
      .then(VendorManageProfileResponse => {
        VendorManageProfileResponse.blob().then(blob => {
          let photoUrl = window.URL.createObjectURL(blob);
          let aTag = document.createElement('a');
          aTag.href = photoUrl;
          aTag.download = fileName;
          aTag.click();
        });
      });
  }

  handleClickLogout = () => {
    this.setState({ isOpenDialog: true });
  }

  handleDialogClose = () => {
    this.setState({ isOpenDialog: false });
  }
  // Customizable Area End
}
