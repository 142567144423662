import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import firebase from "firebase";

// Customizable Area Start
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  isSideBarOpen: boolean;
  isDropdownOpen: boolean;
  values: string[];
  invoicesIndex: number;
  projectsIndex: number;
  dailyActiveUsersIndex: number;
  token: string;
  isLoading: boolean;
  analyticsProjectsData: any;
  analyticsInvoicesData: any;
  analyticsUsersData: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AnalyticsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getAnalyticsProjectsMessgaeId: string = "";
  getAnalyticsInvoicesMessgaeId: string = "";
  getAnalyticsUsersMessgaeId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess)
      // Customizable Area Start
      , getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      txtInputValue: "",
      isSideBarOpen: true,
      isDropdownOpen: false,
      values: ["Weekly", "Monthly", "Yearly"],
      invoicesIndex: 0,
      projectsIndex: 0,
      dailyActiveUsersIndex: 0,
      token: "",
      isLoading: false,
      analyticsProjectsData: {},
      analyticsInvoicesData: {},
      analyticsUsersData: {},
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
    if (firebase.apps.length !== 0) {
      const defaultAnalytics = firebase.app().analytics();
      defaultAnalytics.logEvent("Analytics::Web::Load");
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getAnalyticsProjectsMessgaeId) {
        this.getAnalyticsProjectsDataHandleResponse(responseJson);
      } else if (apiRequestCallId === this.getAnalyticsInvoicesMessgaeId) {
        this.getAnalyticsInvoicesDataHandleResponse(responseJson);
      } else if (apiRequestCallId === this.getAnalyticsUsersMessgaeId) {
        this.getAnalyticsUsersDataHandleResponse(responseJson);
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  async doButtonPressed() {
    const defaultAnalytics = firebase.app().analytics();
    defaultAnalytics.logEvent("Analytics::Web::button_clicked");
  }

  // Customizable Area Start
  getAnalyticsProjectsDataHandleResponse = (responseJson: { data: any; meta: any; errors: [{ message: string }] }) => {
    if (responseJson.data) {
      this.setState({ analyticsProjectsData: Object.keys(responseJson.data).map((key) => ({ name: key, ...responseJson.data[key] })) })
    }
   
    this.setState({ isLoading: false });
  }

  getAnalyticsInvoicesDataHandleResponse = (responseJson: { data: any; meta: any; errors: [{ message: string }] }) => {
    if (responseJson.data) {
      this.setState({ analyticsInvoicesData: Object.keys(responseJson.data).map((key) => ({ name: key, ...responseJson.data[key] })) })
    }
    else {
      this.showAlert("Error", responseJson.errors[0].message);
    }
    this.setState({ isLoading: false });
  }

  getAnalyticsUsersDataHandleResponse = (responseJson: { data: any; meta: any; errors: [{ message: string }] }) => {
    if (responseJson.data) {
      this.setState({ analyticsUsersData: Object.keys(responseJson.data).map((key) => ({ name: key, ...responseJson.data[key] })) })
    }
    else {
      this.showAlert("Error", responseJson.errors[0].message);
    }
    this.setState({ isLoading: false });
  }

  async componentDidMount(): Promise<void> {
    const scrollId = await getStorageData("scrollAnalyticsId")
    if (scrollId) {
      this.scrollToElement(scrollId);
    } else {
      window.scrollTo(0, 0);
    }
    const analyticsAuthToken = await getStorageData("token");
    this.setState({ token: analyticsAuthToken }, () => {
      this.getAllAnalyticsData();
    });
    let isSideBarOpen=await getStorageData("sidebarvalue");
    this.setState({isSideBarOpen:JSON.parse(isSideBarOpen)})
  }

  apiCall = async (analyticsApiMethod: string, analyticsApiEndPoint: string) => {
    const analyticsHeader = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token,
    };
    this.setState({ isLoading: true });
    const analyticsRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    analyticsRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(analyticsHeader)
    );
    analyticsRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      analyticsApiEndPoint
    );
    analyticsRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      analyticsApiMethod
    );
    runEngine.sendMessage(analyticsRequestMessage.id, analyticsRequestMessage);
    return analyticsRequestMessage.messageId;
  };

  getAllAnalyticsData = async () => {
    this.getAnalyticsProjectsMessgaeId = await this.apiCall(configJSON.validationApiMethodType, configJSON.getAnalyticsProjectsAPI + "weekly");
    this.getAnalyticsInvoicesMessgaeId = await this.apiCall(configJSON.validationApiMethodType, configJSON.getAnalyticsInvoicesAPI + "weekly");
    this.getAnalyticsUsersMessgaeId = await this.apiCall(configJSON.validationApiMethodType, configJSON.getAnalyticsUsersAPI + "weekly");
  }

  handleDropdown = () => {
    this.setState({ isDropdownOpen: !this.state.isDropdownOpen });
  };

  handleNextClick = (index: keyof S) => {
    if (this.state[index] === 2) { return false; }
    if (this.state[index] !== 2) {
      this.setState(({
        [index]: (this.state[index] as number) + 1
      } as unknown) as Pick<S, keyof S>, () => {
        this.getChartsDynamicData(index);
      });
    }
  };

  handleSideBar = () => {
    this.setState({ isSideBarOpen: !this.state.isSideBarOpen },
      async() => {
        localStorage.setItem("sidebarvalue",this.state.isSideBarOpen.toString())
        
      }
      );
  };

  handlePreviousClick = (index: keyof S) => {
    if (this.state[index] !== 0) {
      this.setState(({
        [index]: (this.state[index] as number) - 1
      } as unknown) as Pick<S, keyof S>, () => {
        this.getChartsDynamicData(index);
      });
    }
  };

  getChartsDynamicData = async (index: keyof S) => {
    if (index === "invoicesIndex") {
      this.getAnalyticsInvoicesMessgaeId = await this.apiCall(configJSON.validationApiMethodType, configJSON.getAnalyticsInvoicesAPI + this.state.values[this.state.invoicesIndex].toLocaleLowerCase());
    } else if (index === "projectsIndex") {
      this.getAnalyticsProjectsMessgaeId = await this.apiCall(configJSON.validationApiMethodType, configJSON.getAnalyticsProjectsAPI + this.state.values[this.state.projectsIndex].toLocaleLowerCase());
    } else if (index === "dailyActiveUsersIndex") {
      this.getAnalyticsUsersMessgaeId = await this.apiCall(configJSON.validationApiMethodType, configJSON.getAnalyticsUsersAPI + this.state.values[this.state.dailyActiveUsersIndex].toLocaleLowerCase());
    }
  }

  handleNavigation = (page: string) => {
    this.props.navigation.navigate(page)
  };

  scrollToElement = (id: string) => {
    const container = document.getElementById(id);
    container && container.scrollIntoView({ behavior: 'smooth' });
  };

  async componentWillUnmount(): Promise<void> {
    removeStorageData("scrollAnalyticsId");
  }
  // Customizable Area End
}
