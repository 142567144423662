import { Message } from "../../../framework/src/Message";

import { BlockComponent } from "../../../framework/src/BlockComponent";

import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {

    getName

} from "../../../framework/src/Messages/MessageEnum";

import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start

import moment from "moment";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {

    navigation: any;

    id: string;

    // Customizable Area Start

    // Customizable Area End

}

interface S {

    // Customizable Area Start

    isSideBarOpen: boolean;

    isLoading: boolean;

    isDropdownOpen: boolean;

    tabValue: number;

   

   

    

   


    isModelOpen: boolean;

    statesData: { id: number, name: string, iso2: string }[];

    phList: any[];

    filterValues: any;

    filteredParams: any;

    openRequest: boolean;

    getCountForPagination: any;

    currentStudiosPage: number;

    requestcount:any;

   city:string;

   address:string;

   state:string;

   requestid:any;

   studioDetailTabValue: number;
   inviteVendorAccountId:number;

   notification: { type: string, open: boolean, message: string, route?: string };

   vendorManageProfileData:any

   bioReadMoreLess:boolean;

   vendorManageProfileProjectsData:any;

   modelType:string;

   studioBookingId: string;

   studioDetailReviewData:any;

   open:boolean;

   reviewvalidation:string;

   ratingvalidation:string;

   rateValue:number;

   comments:string;

   rewableId:number;

   open1:boolean;

   statuspending:any;

   studiotype:any;

   ProjectName:string;

   Status:string;

   vendorInvitedToJobRole:boolean;
   vendorInvitedToJobRoleId:number;



    // Customizable Area End

}

interface SS {

    // Customizable Area Start

    id: any;

    // Customizable Area End

}

export default class InviteVendorProfilePDController extends BlockComponent<

    Props,

    S,

    SS

> {

    // Customizable Area Start

    getMyBookingsMessageId: string = "";
    postInviteVendorApiCallId:string="";
   

    getRequestPendingApiCalledId: string = "";

    getProjectListApiCalledId:string="";

    getManageprofileListApiCalledId:string="";

    getRatingListApicalledid:string=""

    

  

    addReviewApiCallId:string=""

  

    

  
    removeFromJobApiCallId:string=""
    // Customizable Area End

    constructor(props: Props) {

        super(props);

        this.receive = this.receive.bind(this);

        // Customizable Area Start

        this.subScribedMessages = [

            // Customizable Area Start

            getName(MessageEnum.RestAPIResponceMessage),

            getName(MessageEnum.RestAPIResponceDataMessage),

            getName(MessageEnum.RestAPIResponceSuccessMessage),

            getName(MessageEnum.RestAPIResponceErrorMessage),

            // Customizable Area End

        ];

        this.state = {

            // Customizable Area Start

            isSideBarOpen: true,

            isLoading: false,

            isDropdownOpen: false,

            tabValue: 0,

        

    

            isModelOpen: false,

            statesData: [],

            phList: [],

            filterValues: "",

            filteredParams: "",

      

    

  
            vendorInvitedToJobRole:false,
            vendorInvitedToJobRoleId:0,
            openRequest: false,

            getCountForPagination: {

                0: 0,

                1: 0,

                2: 0,

                3: 0

            },

            currentStudiosPage: 1,

            requestcount:null,

            city:'',

            address:'',

            state:'',

            requestid:'',

            studioDetailTabValue: 0,
            inviteVendorAccountId:0,

            vendorManageProfileProjectsData:[],

            notification: { type: "", open: false, message: "" },

            vendorManageProfileData: {

                id: "",

                profile_image: "",

                city: "",

                state: "",

                experience: "",

                role: "",

                gallary_photos: [],

               

                account_detail: {

                  data: {

                    attributes: {

                      full_name: "",

                      country_code: "",

                      phone_number: "",

                      email: "",

                      aadhar_card_number: "",

                      gst_number: "",

                      pan_card_number: "",

                      tan_number: "",

                      account_number: "",

                      ifsc_code: "",

                      branch_name: "",

                    }

                  }

                },

                bank_detail: {

                    data: {

                      attributes: {

                        image: "",

                        bank_name: "",

                      }

                    }

                  },

                profile_bio: {

                  data: {

                    attributes: {

                      about_business: "",

                    }

                  },

                },

                documents: [],

              },

             

              studioDetailReviewData:{},

              open:false,

              reviewvalidation:"",

              ratingvalidation:"",

              rateValue:0,

              comments:"",

              rewableId:0,

              bioReadMoreLess:true,

              modelType:'',

              studioBookingId: '',

              open1:false,

              statuspending:null,

              studiotype:"createType",

              ProjectName:'',

              Status:''

   

            // Customizable Area End

        };

        // Customizable Area End

        runEngine.attachBuildingBlock(this, this.subScribedMessages);

    }

    async receive(from: string, message: Message) {

        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(

                getName(MessageEnum.RestAPIResponceDataMessage)

            );

            const responseJson = message.getData(

                getName(MessageEnum.RestAPIResponceSuccessMessage)

            );
            if(this.postInviteVendorApiCallId===apiRequestCallId) {
              this.handlePostInviteVendorResponse(responseJson)
            }
          if(this.removeFromJobApiCallId===apiRequestCallId)
          {
            this.handleRemoveFromJobResponse(responseJson)
          }
            this.requestProfileResponseData(apiRequestCallId,responseJson)

           

        }

        // Customizable Area End

    }

    requestProfileResponseData=(apiRequestCallId:any,responseJson:any)=>{

        

      

        if(this.getProjectListApiCalledId===apiRequestCallId)

        { 

            this.getVendorManageProfileProjectsDataHandleResponse(responseJson);

            

        }

        if(this.getManageprofileListApiCalledId===apiRequestCallId)

        {

            this.getVendorManageProfileDataHandleResponse(responseJson);

        }

        if(this.getRatingListApicalledid===apiRequestCallId)

        {

            this.getStudioDetailReviewDataHandleResponse(responseJson)

        }

        if(this.addReviewApiCallId===apiRequestCallId)

        {

            this.getaddReviewdatalist(responseJson)

        }

       

        

        

    }

    // Customizable Area Start

    handlePostInviteVendorResponse(responseJson:any){
      
          this.getManageProfileListing()
          this.setState({
              isLoading: false,
          }); 
      
  }
    getVendorManageProfileDataHandleResponse = (vendorManageProfileDataResponse: { errors: { token: string }[], data: any }) => {

        if (vendorManageProfileDataResponse.data) {

          this.setState({ vendorManageProfileData: vendorManageProfileDataResponse.data.attributes,
            vendorInvitedToJobRole : vendorManageProfileDataResponse.data.attributes.vendor_invited_to_job_role,
                 vendorInvitedToJobRoleId : vendorManageProfileDataResponse.data.attributes?.vendor_job_request_id,
                 inviteVendorAccountId:vendorManageProfileDataResponse.data.attributes.account_id,
          });

        } else if (vendorManageProfileDataResponse.errors[0].token) {

          this.setState({ notification: { open: true, type: "error", message: vendorManageProfileDataResponse.errors[0].token, route: "LandingPage" } });

        }

        this.setState({ isLoading: false });

      }

      vendorManageProfileHandleDownloadPDF = (pdfURL: any, fileName: string) => {

        fetch(pdfURL)

          .then(VendorManageProfileResponse => {

            VendorManageProfileResponse.blob().then(blob => {

              let photoUrl = window.URL.createObjectURL(blob);

              let aTag = document.createElement('a');

              aTag.href = photoUrl;

              aTag.download = fileName;

              aTag.click();

            });

          });

      }

      commentsHandle=(e:any)=>{

        if(e.target.value==='')

        {

          this.setState({reviewvalidation:'Please give review'})

        }

        else

        {

          this.setState({reviewvalidation:''})

        

        }

        this.setState({comments:e.target.value})

       }

      handleBioRead = () => {

        this.setState({ bioReadMoreLess: !this.state.bioReadMoreLess });

      }

    
    handleRatingModelOpen = () => {

        this.handleModelOpen();

        this.setState({ modelType: "ratingModel" });

      }

      handleSideBar = () => {
        this.setState({
          isSideBarOpen: !this.state.isSideBarOpen
        },
        async() => {
          localStorage.setItem("sidebarvalue",this.state.isSideBarOpen.toString())
    
        }
    
    
        );
      };

      

  handleModelClose = () => {

    this.setState({ isModelOpen: false, modelType: "" });

  }

    getVendorManageProfileProjectsDataHandleResponse = (vendorManageProfileDataResponseJson: { errors: { token: string }[], data: any[] }) => {

        if (vendorManageProfileDataResponseJson.data) {

          this.setState({ vendorManageProfileProjectsData: vendorManageProfileDataResponseJson.data });

        

        } else if (vendorManageProfileDataResponseJson.errors[0].token) {

          this.setState({ notification: { open: true, type: "error", message: vendorManageProfileDataResponseJson.errors[0].token, route: "LandingPage" } });

        }

        this.setState({ isLoading: false });

      }

    handleDropdown = () => {

        this.setState({

            isDropdownOpen: !this.state.isDropdownOpen

        });

    };

    handleNavigation = (page: string) => {

        this.props.navigation.navigate(page)

    };

    handleAppBarValue = (event: React.ChangeEvent<{}>, value: number) => {

        this.setState({

            tabValue: value,

        });

        

        

        

    }


    async componentDidMount() {

      let isSideBarOpen=await getStorageData("sidebarvalue");
      this.setState({isSideBarOpen:JSON.parse(isSideBarOpen)})

        

        this.getProjectListing()

         this.getManageProfileListing()

         let rewableid=await getStorageData("studioid")

         let rewableidNumber = parseInt(rewableid, 10); 

         this.setState({ rewableId: rewableidNumber });

         this.getRatingListing()

         const projectname=await getStorageData("namePH")

         this.setState({ProjectName:projectname})

         const statusvalue= await getStorageData("statusPH")

         this.setState({Status:statusvalue})

    }



    getFormattedDate = (date: any) => {

        return moment.utc(date).format("DD MMM, YYYY");

    }



    handleModelOpen = () => {

        this.setState({ isModelOpen: true });

    }

    handleClose = () => {

        this.setState({open:false})

       

      };

    handleCloseNotification = () => {

        this.setState({ notification: { type: "", open: false, message: "" } });

      }

      handleOpen = () =>{

    

        this.setState({rateValue:0,comments:'',ratingvalidation:'',reviewvalidation:''})

        

           this.setState({open:true})

          

        }

        rateHandle=(e:any)=>{

            if(e.target.value==='')

            {

              this.setState({ratingvalidation:'Please give rating'})

            }

            else

            {

              this.setState({ratingvalidation:''})

            

            }

            this.setState({rateValue:e.target.value})

           }

    handleNavigationRequestProfile= async (id:any,studioid:any,profileid:any,status:any)=>{

      setStorageData("dataid", id)

     setStorageData("statusrequest",status)

     

     await setStorageData("studioid",studioid)

   

    

     setStorageData("profileid",profileid)

     

    

        this.props.navigation.navigate("RequestProfileDetailPH");

        this.getProjectListing();

    }

    handleOpenRequest = (id: number,address:string,city:string,state:string) => {

        this.setState({ openRequest: true })

        setStorageData("id", id)

      

        this.getRequestPending()

        this.setState({city:city,address:address,state:state})

    }

    handleCloseRequest = () => {

        this.setState({ openRequest: false })

    };

    covertToPascalCase = (text: string, trimSpace = false) => {

        return text.split(' ').map((t) => t[0].toUpperCase() + t.slice(1).toLowerCase()).join(trimSpace ? '' : ' ')

      }

    handleStoreFilterValues = (newFilterValues: any) => {

        this.setState({ filterValues: newFilterValues });

    }

    getaddReviewdatalist=(responseValue:any)=>{

        if(responseValue.data)

        {

         

          

        this.setState({isLoading:false})

        this.getRatingListing()

        this.setState({rateValue:0,comments:''})

          

          

        }

        else{

    

    

          alert(responseValue.errors[0])

    

          

    

    

          

    

          this.setState({isLoading:false})

        

        }

      }

    navigateToDetailPage = (studioId: string) => {

        setStorageData("studioId", studioId);

        this.props.navigation.navigate("StudiosBookingDetail");

    }

    Editstudio=async(id:any)=>{

    

     await setStorageData("editstudio",id)

     await setStorageData("studiotype","editstudio")

     

     this.handleOpen1()

    }

    Addnewstudio=async()=>{

      await setStorageData("studiotype","createstudio")

      this.handleOpen1()

    }

    async componentWillUnmount() {

        setStorageData("studioBookingViewStatus", "false");

    }

    apiCall = async (method: string, endPoint: string) => {

        const authenticationToken = await getStorageData('token', false)

        const header = {

            "Content-Type": configJSON.exampleApiContentType,

            "token": authenticationToken

        };

        const requestMessage = new Message(

            getName(MessageEnum.RestAPIRequestMessage)

        );

        requestMessage.addData(

            getName(MessageEnum.RestAPIResponceEndPointMessage),

            endPoint

        );

        requestMessage.addData(

            getName(MessageEnum.RestAPIRequestHeaderMessage),

            JSON.stringify(header)

        );

        requestMessage.addData(

            getName(MessageEnum.RestAPIRequestMethodMessage),

            method

        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return requestMessage.messageId;

    };

    handleRemoveFromJobResponse(responseJson:any){
      if(responseJson){
        this.getManageProfileListing()
        this.setState({
            isLoading: false,
        }); 
    }
  }
    getStudioDetailReviewDataHandleResponse = (studioBookingDetailResponseJson: { errors: { token: string }[], average_rating: number, rating_star: any, review_rating_count: string, reviews: { data: any[] } }) => {

        

          this.setState({ studioDetailReviewData: studioBookingDetailResponseJson });

        

        this.setState({ isLoading: false });

      }



    getRequestPending = async () => {

        let token = await getStorageData("token");

        let id = await getStorageData("id");

        const header = {

            "Content-Type": configJSON.exampleApiContentType,

            "token": token

        };

        const requestMessage = new Message(

            getName(MessageEnum.RestAPIRequestMessage)

        );

        this.getRequestPendingApiCalledId = requestMessage.messageId;

        requestMessage.addData(

            getName(MessageEnum.RestAPIResponceEndPointMessage),

            configJSON.getPendingRequestApiEndPoint + `${id}`

        );

        requestMessage.addData(

            getName(MessageEnum.RestAPIRequestMethodMessage),

            configJSON.validationApiMethodType

        );

        requestMessage.addData(

            getName(MessageEnum.RestAPIRequestHeaderMessage),

            JSON.stringify(header)

        );


        runEngine.sendMessage(requestMessage.id, requestMessage);

    };

    handleOpen1 = () => {

        this.setState({ open1: true })

    }

    handleClose1 = () => {

        this.setState({ open1: false })

    };

   


        
    

          handleTabValueChange = (tabVal: number) => {

            this.setState({ studioDetailTabValue: tabVal });

          }

        

          getProjectListing = async () => {

            let token = await getStorageData("token");

            let id = await getStorageData("profileid");

            const header = {

                "Content-Type": configJSON.exampleApiContentType,

                "token": token

            };

            const requestMessage = new Message(

                getName(MessageEnum.RestAPIRequestMessage)

            );

            this.getProjectListApiCalledId = requestMessage.messageId;

            requestMessage.addData(

                getName(MessageEnum.RestAPIResponceEndPointMessage),

                configJSON.getProjectListApiEndPoint + `/${id}`

            );

            requestMessage.addData(

                getName(MessageEnum.RestAPIRequestMethodMessage),

                configJSON.validationApiMethodType

            );

            requestMessage.addData(

                getName(MessageEnum.RestAPIRequestHeaderMessage),

                JSON.stringify(header)

            );

    

    

            runEngine.sendMessage(requestMessage.id, requestMessage);

        };


        getManageProfileListing = async () => {

            let token = await getStorageData("token");

            let id = await getStorageData("profileid");
            let jobroleid=await getStorageData("jobroleidvendor");
            const header = {

                "Content-Type": configJSON.exampleApiContentType,

                "token": token

            };

            const requestMessage = new Message(

                getName(MessageEnum.RestAPIRequestMessage)

            );

            this.getManageprofileListApiCalledId = requestMessage.messageId;

            requestMessage.addData(

                getName(MessageEnum.RestAPIResponceEndPointMessage),

                configJSON.getPHManageProfileDetailsAPIEndPoint+`/${id}?job_role_id=${jobroleid}`

            );

            requestMessage.addData(

                getName(MessageEnum.RestAPIRequestMethodMessage),

                configJSON.validationApiMethodType

            );

            requestMessage.addData(

                getName(MessageEnum.RestAPIRequestHeaderMessage),

                JSON.stringify(header)

            );

    

    

            runEngine.sendMessage(requestMessage.id, requestMessage);

        };

        getRatingListing = async () => {

            let token = await getStorageData("token");

            const header = {

                "Content-Type": configJSON.exampleApiContentType,

                "token": token

            };

            const requestMessage = new Message(

                getName(MessageEnum.RestAPIRequestMessage)

            );

            this.getRatingListApicalledid = requestMessage.messageId;

            requestMessage.addData(

                getName(MessageEnum.RestAPIResponceEndPointMessage),

                configJSON.getStudioDetailReviewDataAPIEndPoint+`/${this.state.rewableId}`

            );

            requestMessage.addData(

                getName(MessageEnum.RestAPIRequestMethodMessage),

                configJSON.validationApiMethodType

            );

            requestMessage.addData(

                getName(MessageEnum.RestAPIRequestHeaderMessage),

                JSON.stringify(header)

            );

    

    

            runEngine.sendMessage(requestMessage.id, requestMessage);

        };

        addReviewApiCallrequestProfile = async() => {

            if(this.state.comments===''&&this.state.rateValue===0)

            {

              this.setState({ratingvalidation:"Please give rating",reviewvalidation:'Please give review'})

            }

            else if(this.state.rateValue===0)

            {

              this.setState({ratingvalidation:"Please give rating"})

            }

            else if(this.state.comments==='')

              {

                this.setState({reviewvalidation:'Please give review'})

                   this.setState({rateValue:0}) 

              }

           

            else

            {

              this.setState({open:false})

                  this.setState({isLoading:true})

                  let token = await getStorageData("token");

                  

            const header = {

              "Content-Type": configJSON.exampleApiContentType,

              "token": token

            };

            const httpBody = {

              "review" : {

                "comment":this.state.comments,

                "given_star":this.state.rateValue,

                "reviewable_id": this.state.rewableId,

                "reviewable_type":"BxBlockCustomizableformworkflow3::Studio"

              }

            };

            const requestMessage = new Message(

              getName(MessageEnum.RestAPIRequestMessage)

            );

            

            this.addReviewApiCallId = requestMessage.messageId;

            requestMessage.addData(

                getName(MessageEnum.RestAPIRequestHeaderMessage),

                JSON.stringify(header)

              );

            

            requestMessage.addData(

              getName(MessageEnum.RestAPIResponceEndPointMessage),

              configJSON.addReviewApiEndPoint

            );

            requestMessage.addData(

                getName(MessageEnum.RestAPIRequestMethodMessage),

                configJSON.manageprofilemethodtype

              );

           

            requestMessage.addData(

              getName(MessageEnum.RestAPIRequestBodyMessage),

              JSON.stringify(httpBody)

            );

            

            runEngine.sendMessage(requestMessage.id, requestMessage);

            }

              

            }

          
            

              

                   

                      navigateToStudioDetailUpcomingandOngoing = (id: any,bookingid:any) => {

                      

                        this.props.navigation.navigate("StudioDetailPH");

                        setStorageData("detailstudioid", id)

                        setStorageData("bookingid",bookingid)

                      };

                      deletefromJobInviteVendor = async()=>{
                        this.removeFromJobApiCallId = await this.removeFromJobInviteVendor(configJSON.DELETE_Method,configJSON.removeforjobEndpointdelete + `/${this.state.vendorInvitedToJobRoleId}`)
                    }

                    

                      postInviteforJob = async () => {
        
                        const projectJobId= await getStorageData("projectidPH");
                        let jobroleid=await getStorageData("jobroleidvendor");
                        this.setState({open:false})
                        const authenticationToken = await getStorageData('token', false)
                        const header = {
                            "Content-Type": configJSON.exampleApiContentType,
                            "token": authenticationToken
                        };
                        this.setState({ isLoading: true })
                        const requestMessageInviteForJob = new Message(
                            getName(MessageEnum.RestAPIRequestMessage)
                        );
                        this.postInviteVendorApiCallId = requestMessageInviteForJob.messageId;
                
                        requestMessageInviteForJob.addData(
                            getName(MessageEnum.RestAPIResponceEndPointMessage),
                            configJSON.invitevendorListEntPointpost
                        );
                
                        const httpBody = {
                            "vendor_job_request":{
                                "account_id":this.state.inviteVendorAccountId,
                                "project_id":projectJobId,
                                "job_role_id":jobroleid
                            }
                        };
                
                        requestMessageInviteForJob.addData(
                        getName(MessageEnum.RestAPIRequestBodyMessage),
                        JSON.stringify(httpBody)
                        );
                
                        requestMessageInviteForJob.addData(
                            getName(MessageEnum.RestAPIRequestHeaderMessage),
                            JSON.stringify(header)
                        );
                        requestMessageInviteForJob.addData(
                            getName(MessageEnum.RestAPIRequestMethodMessage),
                            configJSON.httpPostMethod
                        );
                        runEngine.sendMessage(requestMessageInviteForJob.id, requestMessageInviteForJob);
                        return requestMessageInviteForJob.messageId;
                    };
                
                    removeFromJobInviteVendor = async (method: string, endPoint: string) => {
                        const authenticationToken = await getStorageData('token', false)
                        const header = {
                            "Content-Type": configJSON.exampleApiContentType,
                            "token": authenticationToken
                        };
                        this.setState({ isLoading: true })
                        const requestMessageManageProfileremoveFromJob = new Message(
                            getName(MessageEnum.RestAPIRequestMessage)
                        );
                
                        requestMessageManageProfileremoveFromJob.addData(
                            getName(MessageEnum.RestAPIResponceEndPointMessage),
                            endPoint
                        );
                
                        requestMessageManageProfileremoveFromJob.addData(
                            getName(MessageEnum.RestAPIRequestHeaderMessage),
                            JSON.stringify(header)
                        );
                        requestMessageManageProfileremoveFromJob.addData(
                            getName(MessageEnum.RestAPIRequestMethodMessage),
                            method
                        );
                        runEngine.sendMessage(requestMessageManageProfileremoveFromJob.id, requestMessageManageProfileremoveFromJob);
                        return requestMessageManageProfileremoveFromJob.messageId;
                    };

    

    // Customizable Area End

}

