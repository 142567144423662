Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.deleteAPIMethod = "DELETE";
exports.labelBodyText = "dashboard Body";
exports.countapiendpoint = "bx_block_dashboard/dashboard_count"
exports.getProjectDetailApiEndPoint="bx_block_projecttasktracking2/projects"
exports.deleteprojectApiEndPoint="bx_block_projecttasktracking2/projects"
exports.getDashboardGraphProjectsAPI = "bx_block_projecttasktracking2/analytics_projects?type=";
exports.getDashboardGraphInvoicesAPI = "bx_block_invoicebilling/analytics_invoice?type=";
exports.getDashboardGraphUsersAPI = "account_block/analytics_users?type=";
exports.getProjectsAPI="/bx_block_projecttasktracking2/"
exports.addNewProjectText="Add New Project";
exports.currentBalanceText="Current Balance";
exports.invoicesText="Invoices";
exports.rolesText="Roles";
exports.tabLabels = [
    "All Projects",
    "Ongoing",
    "Upcoming"
]
exports.dummyProductionDashboarddata = [
    {
        image: "https://www.usnews.com/object/image/0000018c-5eff-dc6c-aded-ffff3d4a0000/https-cars-dms-usnews-com-static-uploads-images-auto-custom-15417-original-2024-acura-integra-type-s-angular-front-1.jpg?update-time=1695055174000&size=responsiveGallery",
        balance: 20,
        projectName: "Project One",
        roles: 5,
        date: "2023-02-10",
        current_balance: "$3,200",
        invoices: 4,
        status: "ongoing"
    },
    {
        image: "https://www.usnews.com/object/image/0000018c-5eff-dc6c-aded-ffff3d4a0000/https-cars-dms-usnews-com-static-uploads-images-auto-custom-15417-original-2024-acura-integra-type-s-angular-front-1.jpg?update-time=1695055174000&size=responsiveGallery",
        balance: 20,
        projectName: "Project Two",
        roles: 8,
        date: "2023-03-22",
        current_balance: "$5,600",
        invoices: 6,
        status: "upcoming"
    },
    {
        image: "https://www.usnews.com/object/image/0000018c-5eff-dc6c-aded-ffff3d4a0000/https-cars-dms-usnews-com-static-uploads-images-auto-custom-15417-original-2024-acura-integra-type-s-angular-front-1.jpg?update-time=1695055174000&size=responsiveGallery",
        balance: 20,
        projectName: "Project Three",
        roles: 10,
        date: "2023-04-05",
        current_balance: "$7,000",
        invoices: 9,
        status: "ongoing"
    },
    {
        image: "https://www.usnews.com/object/image/0000018c-5eff-dc6c-aded-ffff3d4a0000/https-cars-dms-usnews-com-static-uploads-images-auto-custom-15417-original-2024-acura-integra-type-s-angular-front-1.jpg?update-time=1695055174000&size=responsiveGallery",
        balance: 20,
        projectName: "Project Four",
        roles: 6,
        date: "2023-05-18",
        current_balance: "$4,800",
        invoices: 5,
        status: "upcoming"
    },
    {
        image: "https://www.usnews.com/object/image/0000018c-5eff-dc6c-aded-ffff3d4a0000/https-cars-dms-usnews-com-static-uploads-images-auto-custom-15417-original-2024-acura-integra-type-s-angular-front-1.jpg?update-time=1695055174000&size=responsiveGallery",
        balance: 20,
        projectName: "Project Five",
        roles: 12,
        date: "2023-06-30",
        current_balance: "$9,200",
        invoices: 11,
        status: "ongoing"
    },
    {
        image: "https://www.usnews.com/object/image/0000018c-5eff-dc6c-aded-ffff3d4a0000/https-cars-dms-usnews-com-static-uploads-images-auto-custom-15417-original-2024-acura-integra-type-s-angular-front-1.jpg?update-time=1695055174000&size=responsiveGallery",
        balance: 20,
        projectName: "Project Six",
        roles: 7,
        date: "2023-07-12",
        current_balance: "$5,000",
        invoices: 8,
        status: "ongoing"
    }
];
exports.ACCOUNT_TYPE_EMAIL = "EmailAccount";
exports.ACCOUNT_TYPE_SOCIAL = "SocialAccount";
exports.ACCOUNT_TYPE_PHONE = "SmsAccount";

exports.contentTypeApiUpdateUser = "application/json";
exports.apiEndPointUpdateUser = "profile/profile";
exports.apiUpdateUserType = "PUT";

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.contenttypeApiValidateMobileNo = "application/json";
exports.endPointApiValidateMobileNo = "profile/change_phone_validation";
exports.callTypeApiValidateMobileNo = "POST";

exports.endPointApiGetUserProfile = "profile/profile";
exports.contentTypeApiGetUserProfile = "application/json";
exports.methodTypeApiGetUserProfile = "GET";

// Customizable Area Start
exports.placeHolderEmail = "Email";
exports.labelHeader =
  "This is your profile, Here you can see and update your personal information.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelArea = "Area";
exports.labelMobile = "Mobile";
exports.labelEmail = "Email";
exports.labelCurrentPassword = "Current password";
exports.labelNewPassword = "New Password";
exports.labelRePassword = "Re-Type Password";
exports.btnTextCancelPasswordChange = "Cancel";
exports.btnTextSaveChanges = "Save Changes";
exports.btnTextChangePassword = "Change Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Phone number is not valid.";
exports.errorTitle = "Error";
exports.errorBothPasswordsNotSame = "Passwords must match.";
exports.errorCurrentNewPasswordMatch = "New password cannot match current password.";
exports.errorCurrentPasswordNotValid = "Current password not valid.";
exports.errorNewPasswordNotValid = "New password not valid.";
exports.errorReTypePasswordNotValid = "Re-type password not valid.";
exports.hintCountryCode = "Select Country";
exports.errorBlankField = "can't be blank";
exports.errorEmailNotValid = "Email not valid.";
exports.profileUpdateEndPoint = "admin_profile";
exports.manageApiContentType = "application/json";
exports.UpdatePasswordEndPoint = "account_block/update_password?token";
exports.updatePasswordAPiMethod = "PUT";
exports.manageprofilemethodtype = "POST";
exports.exampleApiContentType = "application/json";
exports.deleteAPIMethod = "DELETE";

exports.getAllStudiosAPIEndPoint = "bx_block_customizableformworkflow3/studios";
exports.searchAllStudiosAPIEndPoint = "bx_block_customizableformworkflow3/studios/search?search=";
exports.getMyBookingsAPIEndPoint = "bx_block_customizableformworkflow3/studio_bookings/vendor_confirmed_bookings";
exports.getMyRequestsAPIEndPoint = "bx_block_customizableformworkflow3/studio_bookings/vendor_booking_requests";
exports.cancelBookingAPIEndPoint = "bx_block_customizableformworkflow3/studio_bookings/cancel_request/";
exports.leaveStudioAPIEndPoint = "bx_block_customizableformworkflow3/studio_bookings/leave_studio/";
exports.withdrawRequestAPIEndPoint = "bx_block_customizableformworkflow3/studio_bookings/";
exports.requestAgainAPIEndPoint = "bx_block_customizableformworkflow3/studio_bookings/request_again/";
exports.getStateAPIEndPoint = "https://api.countrystatecity.in/v1/countries/in/states"
exports.getCityAPIEndPoint="https://api.countrystatecity.in/v1/countries/in/states/"
exports.getPHListAPIEndPoint = "account_block/production_house_list";
exports.getFilteredDataAPIEndPoint = "bx_block_customizableformworkflow3/studios/studios_for_booking";
exports.divide = "/5";
exports.getStudioDetailsAPIEndPoint = "bx_block_customizableformworkflow3/studios/";
exports.getStudioDetailReviewDataAPIEndPoint = "bx_block_reviews/reviews/studio_rating_review/";
exports.bookStudioAPIEndPoint = "bx_block_customizableformworkflow3/studio_bookings";
exports.giveRatingAPIEndPoint = "bx_block_reviews/reviews";
exports.getVendorManageProfileReviewDataAPIEndPoint = "bx_block_profile/profile_rating?profile_id=";
exports.getVendorManageProfileDetailsAPIEndPoint = "profile/profiles/";
exports.getVendorManageProfileProjectsAPIEndPoint = "bx_block_profile/profile_projects/";
exports.vendorManageProfileUpdateProfileAPIEndPoint = "profile/profiles";
exports.vendorManageProfileAddGalleryPhotosAPIEndPoint = "bx_block_profile/add_photos_to_gallary/";
exports.vendorManageProfileSaveUpdatedProfileDetailsAPIEndPoint = "bx_block_profile/update_profile_rjs/";
exports.getVendorProfileRolesApiEndPoint = "bx_block_roles_permissions/roles/";
exports.getVendorProfileDeptsApiEndPoint = "bx_block_roles_permissions/departments/";
exports.getVendorProfileOtpApiEndPoint = "account_block/accounts/get_otp";
exports.VendorProfileVerifyOtpApiEndPoint = "account_block/accounts/verifiy_phone_and_email";
exports.getVendorProfileBankListApiEndPoint = "bx_block_categories/bank_names";
exports.deleteDocumentsApiEndPoint = "delete_documents/";
exports.resetPasswordApiEndPoint = "account_block/update_password";
exports.getallProjectPHEndPoint="bx_block_projecttasktracking2/projects"
exports.getOngoingProjectEndPoint="bx_block_projecttasktracking2/ongoing_project"
exports.getUpcomingProjectEndPoint="bx_block_projecttasktracking2/upcoming_project"
exports.getupcomingStudioListPHEndPoint="bx_block_customizableformworkflow3/studio_bookings/ph_studios_upcoming_bookings"
exports.getPendingRequestApiEndPoint="bx_block_customizableformworkflow3/studio_bookings/studio_pending_requests?studio_id="
exports.acceptstudiorequestApiEndPoint="bx_block_customizableformworkflow3/studio_bookings/accept_request"
exports.rejectstudiorequestApiEndPoint="bx_block_customizableformworkflow3/studio_bookings/reject_request"
exports.getProjectListApiEndPoint="bx_block_profile/profile_projects"
exports.getPHManageProfileDetailsAPIEndPoint = "profile/profiles/";
exports.addReviewApiEndPoint="bx_block_reviews/reviews"
exports.httpPostMethod="POST"
exports.putAllStudioBookingApiEndPoint="bx_block_customizableformworkflow3/studios/studio_booking_permission/"
exports.cancelbookingApiEndPoint="bx_block_customizableformworkflow3/studio_bookings/cancel_request"
exports.deletestudioApiEndPoint="bx_block_customizableformworkflow3/studios"
exports.getAllProjectJobRoleRequestEndPoint = "bx_block_roles_permissions/all_job_role_request_for_pending_project"
exports.methodtypestudio=""
exports.GET_Method = "GET";
exports.deleteJobRoleRequest = "bx_block_roles_permissions/job_roles/"
exports.DELETE_Method = "DELETE";
exports.getAllJobRoleRequestEndPoint="bx_block_roles_permissions/all_job_roles"
exports.getPipelineEmployeeListApiEndPoint = "bx_block_projecttasktracking2/employees?project_id=";
exports.getPipelineEmployeePermissionListApiEndPoint = "bx_block_roles_permissions/permissions";
exports.getPipelineEmployeeDepartmentListApiEndPoint = "bx_block_projecttasktracking2/project_departments/pipeline_departments?project_id=";
exports.managePipelineEmployeeAccessibilityApiEndPoint = "bx_block_projecttasktracking2/employees/";
exports.put_method = "PUT";
exports.DELETE_Method = "DELETE";
exports.getPipelineEmployeeRoleListApiEndPoint = "bx_block_roles_permissions/roles";
exports.addPipelineEmployeeApiEndPoint = "bx_block_projecttasktracking2/employees/create_job_role?project_id=";
exports.httpPostMethod = "POST"

exports.simpleRemovePipelineEmployeeFromProjectApiEndPoint = "bx_block_projecttasktracking2/employees/";
exports.jobRoleRemovePipelineEmployeeFromProjectApiEndPoint = "bx_block_projecttasktracking2/employees/delete_job_role/";
exports.jobRoleManagePipelineEmployeeAccessibilityApiEndPoint = "bx_block_projecttasktracking2/employees/update_job_role/";
exports.projectsAPI="/bx_block_projecttasktracking2/project_departments"

exports.invitevendorListEndPoint="/account_block/active_vendors?"

exports.getAllProjectNameEndPoint = "/bx_block_projecttasktracking2/projects"
exports.getAllProjectDepartmentEndPoint = "bx_block_projecttasktracking2/project_departments/pipeline_departments"
exports.getProjectDeptRoleEndPoint = "bx_block_roles_permissions/roles"
exports.postAddJobRoleEndPoint = "bx_block_roles_permissions/job_roles"
exports.getallJobRoleRequestEndPoint ="/bx_block_projecttasktracking2/user_projects"
exports.getRequestVendorEndPoint ="bx_block_roles_permissions/get_job_role_requested_vendors"
exports.PATCH_Method = "PATCH"
exports.acceptUpdateVendorEndPoint = "bx_block_roles_permissions/vendor_job_requests/"


exports.updatedepartmentendpoint="bx_block_projecttasktracking2/project_departments/update_departments/"

exports.invitevendorListEndPoint="/account_block/active_vendors?"

exports.postInviteVendorEndPoint = "bx_block_projecttasktracking2/employees?project_id"
exports.deleteFormJobEndPoint = "bx_block_projecttasktracking2/employees"
exports.invitevendorListEntPointpost="/bx_block_roles_permissions/vendor_job_requests"
exports.removeforjobEndpointdelete="/bx_block_roles_permissions/remove_from_job/"

// Customizable Area End