import React from "react";

import {
  // Customizable Area Start
  Container,
  Grid,
  Typography,
  Divider,
  Card,
  InputLabel,
  Box,
  Button
  // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import LayersOutlinedIcon from '@material-ui/icons/LayersOutlined';
import UserDetailController, { Props, configJSON } from "./UserDetailController";
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import BreadCrum from "./BreadCrum.web";
import CardComponent from "./CardComponent.web";
import CardFooter from "./CardFooter.web";
import { webStyle } from "./FullTeamVisibility.web";
import StarOutlinedIcon from '@material-ui/icons/StarOutlined';
import "./fullVisibility.css";
import Overview from "./Overview.web";
import AllProjectsCard from "./AllProjectsCard.web";
import AllVendorRating from "./AllVendorRating.web";
import { reviewIcon, reviewYellowIcon } from "./assets";
// Customizable Area End

export default class UserDetailCommon extends UserDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  CustomButtonRating = () => {
    return <div className="rating_box">
      <img src={this.state.activeTab == `${configJSON.rating_vp}` ? reviewYellowIcon : reviewIcon} alt="" style={{ padding: "2px", width: "20px", height: "20px" } as React.CSSProperties} />
      <Typography style={{ ...webStyle_overview.projectText, marginLeft: "2px" }}>
        {configJSON.rating}
      </Typography>
    </div>
  };

  CustomButtonProject = () => {
    return <div className="rating_box">
      <LayersOutlinedIcon style={{ width: "24px", height: "24px" } as React.CSSProperties} />
      <Typography style={webStyle_overview.projectText}>{configJSON.project}</Typography>
    </div>
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Grid container spacing={0} className="user_detail_grid_container">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: '2%' }} className="overview_grid_container">
          <InputLabel style={webStyle.vendorLabel}><ArrowBackOutlinedIcon className="arrow_icon_vendor" onClick={this.navigateToAllVendor} />{configJSON.vendorDetails}</InputLabel>
          <BreadCrum navigation={this.props.navigation} id="" breadcrumList={this.state.bList} />
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5} xl={5} className="single_profile_card padding_value overview_grid_container">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} data-test-id="card_parent_onclick">
            <Card style={webStyle.card}>
              <Box style={{ margin: "2%" }}>
                <CardComponent id="" navigation={this.props.navigation} item={this.state.userPersonalDetail} />
              </Box>
              <Box
                style={{ ...webStyle_overview.seperateCard, margin: "0% 2% 2%" }}
              ></Box>
              <Typography className="general">{configJSON.general}</Typography>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="tabs_name">
                <Button
                  variant="contained"
                  fullWidth
                  className="tab"
                  data-test-id="tab"
                  startIcon={<DescriptionOutlinedIcon fontSize="inherit" style={{ width: '24px', height: "24px" } as React.CSSProperties} />}
                  onClick={() => this.handleChangeTabValue('overview')}
                  style={this.state.activeTab == `${configJSON.overview_vp}` ? webStyle_overview.activeText : webStyle_overview.inactiveText}
                >
                  {configJSON.overview}
                </Button>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="star_icon tabs_name">
                <Button
                  fullWidth
                  variant="contained"
                  startIcon={this.CustomButtonProject()}
                  className="rating_but"
                  data-test-id="project_tab_id"
                  onClick={() => this.handleChangeTabValue('project')}
                  style={this.state.activeTab == `${configJSON.project_vp}` ? webStyle_overview.activeText : webStyle_overview.inactiveText}
                >
                  {this.state.userPersonalDetail.projects_count}
                </Button>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="star_icon tabs_name" style={{ marginLeft: "2px" }}>
                <Button style={this.state.activeTab == `${configJSON.rating_vp}` ? webStyle_overview.activeText : webStyle_overview.inactiveText}
                  startIcon={this.CustomButtonRating()}
                  fullWidth
                  data-test-id="rating__tab_id"
                  onClick={() => this.handleChangeTabValue('rating')}
                  className="rating_but"
                >
                  <Box style={webStyle_overview.stars}>
                    <StarOutlinedIcon style={{ width: 12, height: 12 } as React.CSSProperties} className="start_color" />
                    <span style={webStyle_overview.star_color}>&nbsp;{this.state.userPersonalDetail.rating_and_review}</span>
                  </Box>
                </Button>
              </Grid>
              <Box
                style={{ ...webStyle_overview.seperateCard, margin: "0% 3% 3%" }}
              ></Box>
              <Box style={{ margin: "2% 2% 0.5% 2%" }}>
                <CardFooter key={this.state.userPersonalDetail.blocked} item={{ blocked: this.state.userPersonalDetail.blocked, joinDate: this.state.userPersonalDetail.join_date }} id={this.state.id} navigation={this.props.navigation} handleBadgeVisibility={this.handleVendorBadgeVisibility} />
              </Box>
            </Card>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={7} lg={7} xl={7} className="single_profile_card detail_box overview_grid_container">
          {this.state.activeTab == `${configJSON.overview_vp}` &&
            <Box className="deatil_box_inner">
              <Overview navigation={this.props.navigation} type="vendor" id={this.state.id} overviewData={this.state.userPersonalDetail} />
            </Box>
          }
          {
            this.state.activeTab == `${configJSON.project_vp}` &&
            <Box className="deatil_box_inner">
              <AllProjectsCard id="" navigation={this.props.navigation} allProjects={this.state.allProjects} upcomingProjects={this.state.upcomingProjects} ongoingProjects={this.state.ongoingProjects} completedProjects={this.state.completedProjects} />
            </Box>
          }
          {
            this.state.activeTab == `${configJSON.rating_vp}` &&
            <Box className="detail_box_inner" style={{ marginTop: '4%', paddingLeft: '3%', paddingRight: '3%' }}>
              <AllVendorRating navigation={this.props.navigation} ratingData={this.state.rating} id={this.state.id} type="vendor" />
            </Box>
          }
        </Grid>
      </Grid>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyle_overview = {
  seperateCard: {
    border: "1px solid #E3EAF4",
  },
  activeText: {
    color: '#f5c42c',
    cursor: "pointer",
    backgroundColor: '#f5c42c14',
    boxShadow: 'none',
    padding: '3%',
    borderRadius: '10px',
    lineHeight: '19px',
    fontFamily: 'Urbanist',
    fontSize: '16px',
    fontWeight: 600,
  },
  projectText: {
    lineHeight: '19px',
    fontFamily: 'Urbanist',
    fontSize: '16px',
    fontWeight: 600,
  },
  inactiveText: {
    color: "#17181d",
    cursor: "pointer",
    boxShadow: 'none',
    padding: '3%',
    borderRadius: '10px',
    backgroundColor: '#ececee66',
    lineHeight: '19px',
    fontFamily: 'Urbanist',
    fontSize: '16px',
    fontWeight: 600,
  },
  overviews: {
    display: "flex",
    FlexDirection: "row",
    gridColumnGap: '15px',
    alignItems: 'center',
    background: '#f5c42c14',
    borderRadius: '12px',
    height: '52px',
    marginInline: '8px'
  },
  end_icon: {
    textAlign: 'right',
    width: '12px'
  },
  stars: {
    padding: "0px 8px",
    background: "linear-gradient(225deg, #58A2FF 0%, #1777E2 100%)",
    borderRadius: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: 'space-evenly'
  },
  star_color: {
    color: 'white'
  }
}
// Customizable Area End