import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { confirmPasswordValidate, isEmail, passwordValidate, phoneValidate, setStorageData } from "../../../framework/src/Utilities";
import { validateGSTNumber, validatePanNumber, validateTanNumber } from "../../../components/src/ValidationRegex";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  isLoading: boolean;
  activeStep: number;
  isDisabled:boolean
  storeCountryCode: string,
  isPhoneEmailVerified: { phone: boolean, email: boolean };
  verifyPhoneEmail: "phone" | "email" | "";
  token: string;
  isModelOpen: boolean;
  notification: { type: string, open: boolean, message: string };
  countryCodeSelected: string;
  countryNameSelected: string;
  nameOfPH: string;
  nameOfPHError: { require: boolean };
  addressOfPH: string;
  addressOfPHError: { require: boolean };
  emailOfPH: string;
  emailOfPHError: { invalid: boolean, require: boolean };
  phoneOfPH: string;
  phoneOfPHError: { invalid: boolean, require: boolean };
  gstOfPH: string;
  gstOfPHError: { invalid: boolean, require: boolean };
  tanOfPH: string;
  tanOfPHError: { invalid: boolean, require: boolean };
  aadharOfPH: string;
  aadharOfPHError: { invalid: string, require: boolean };
  panOfPH: string;
  panOfPHError: { invalid: boolean, require: boolean };
  passwordOfPH: string;
  isPasswordOfPHVisible: boolean;
  passwordOfPHError: { invalid: boolean, require: boolean };
  confirmPasswordOfPH: string;
  isConfirmPasswordOfPHVisible: boolean;
  confirmPasswordOfPHError: { match: boolean, require: boolean };
  isTncOfPH: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ProductionHouseSignUpController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getOtpApiCallId: string = "";
  verifyOtpApiCallId: string = "";
  signUpPHApiCallId: string = "";
  updateSignUpPHApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      isLoading: false,
      isDisabled:false,
      activeStep: 0,
      storeCountryCode: "",
      isPhoneEmailVerified: { phone: false, email: false },
      verifyPhoneEmail: "",
      token: "",
      isModelOpen: false,
      notification: { type: "", open: false, message: "" },
      countryCodeSelected: "",
      countryNameSelected: "",
      nameOfPH: "",
      nameOfPHError: { require: false },
      addressOfPH: "",
      addressOfPHError: { require: false },
      emailOfPH: "",
      emailOfPHError: { invalid: false, require: false },
      phoneOfPH: "",
      phoneOfPHError: { invalid: false, require: false },
      gstOfPH: "",
      gstOfPHError: { invalid: false, require: false },
      tanOfPH: "",
      tanOfPHError: { invalid: false, require: false },
      aadharOfPH: "",
      aadharOfPHError: { invalid: "", require: false },
      panOfPH: "",
      panOfPHError: { invalid: false, require: false },
      passwordOfPH: "",
      isPasswordOfPHVisible: false,
      passwordOfPHError: { invalid: false, require: false },
      confirmPasswordOfPH: "",
      isConfirmPasswordOfPHVisible: false,
      confirmPasswordOfPHError: { require: false, match: false },
      isTncOfPH: false,
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getOtpApiCallId) {
          this.handleGetOtpApiResponse(responseJson);
        } else if (apiRequestCallId === this.verifyOtpApiCallId) {
          this.handleVerifyOtpApiResponse(responseJson);
        } else if (apiRequestCallId === this.signUpPHApiCallId) {
          this.handleSignUpPHApiResponse(responseJson);
        } else if (apiRequestCallId === this.updateSignUpPHApiCallId) {
          this.handleUpdateSignUpPHApiResponse(responseJson);
        }
      }
    }

    this.getCountryCodeOfPH(message);

    // Customizable Area End
  }

  // Customizable Area Start
  getCountryCodeOfPH = (message: Message) => {
    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      let selectedCountryCode = message.getData(getName(MessageEnum.CountyCodeDataMessage));

      if (selectedCountryCode !== undefined && !this.state.storeCountryCode) {
        this.setState({
          countryCodeSelected:
            selectedCountryCode.indexOf("+") > 0
              ? selectedCountryCode.split("+")[1]
              : selectedCountryCode
        }, () => {
          if (this.state.countryCodeSelected === "91" && this.state.phoneOfPH.length && this.state.phoneOfPH.length !== 10) {
            this.setState({ phoneOfPHError: { invalid: true, require: false } })
          }
        });
      } else {
        this.setState({ storeCountryCode: "" });
      }
    }
  }

  handleGetOtpApiResponse = (responseJsonData: { message: string, token: string }) => {
    if (responseJsonData.message) {
      this.setState({ notification: { open: true, type: 'error', message: responseJsonData.message } })
    } else if (responseJsonData.token) {
      this.setState({ token: responseJsonData.token });
      !this.state.isModelOpen && this.handleModelOpen();
    }
    this.setState({ isLoading: false });
  }

  handleVerifyOtpApiResponse = (responseJson: { errors: { token: string, pin: string }[], success: boolean, activated: boolean, full_phone_number: string, email: string, meta: { message: string, token: string } }) => {
    if (responseJson.success) {
      this.setState({ notification: { type: 'success', open: true, message: responseJson.meta.message }, token: responseJson.meta.token });
      if (Boolean(responseJson.email)) {
        this.setState({ isPhoneEmailVerified: { ...this.state.isPhoneEmailVerified, email: Boolean(responseJson.email) } });
      } else if (Boolean(responseJson.full_phone_number)) {
        this.setState({ isPhoneEmailVerified: { ...this.state.isPhoneEmailVerified, phone: Boolean(responseJson.full_phone_number) } });
      }
      this.handleClose();
    } else if (responseJson.errors) {
      this.setState({ notification: { open: true, type: 'error', message: responseJson.errors[0].token ? responseJson.errors[0].token : responseJson.errors[0].pin } });
    }
    this.setState({ isLoading: false });
  }

  handleSignUpPHApiResponse = (responseJson: { data: { id: string }, meta: { token: string }, errors: string }) => {
    if (responseJson.errors) {
      this.setState({ notification: { open: true, type: "error", message: responseJson.errors } })
    } else if (responseJson.data) {
      this.setState({ token: responseJson.meta.token });
    }
    this.setState({ isLoading: false });
  }

  handleUpdateSignUpPHApiResponse = (responseJson: { error: string, data: { id: string }, meta: { token: string }, errors: { token: string }[] }) => {
    if (responseJson.data) {
      this.setState({ token: responseJson.meta.token });
      this.props.navigation.navigate("LandingPage");
    } else if (responseJson.errors[0].token) {
      this.setState({ notification: { open: true, type: "error", message: responseJson.errors[0].token } })
    } else if (responseJson.error) {
      this.setState({ notification: { open: true, type: "error", message: responseJson.error } })
    }
    this.setState({ isLoading: false });
  }

  apiCall = async (apiMethod: string, apiEndPoint: string, httpBodyOfPH?: any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token,
    };
    this.setState({ isLoading: true });
    const requestMessageOfPH = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageOfPH.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessageOfPH.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageOfPH.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      apiMethod
    );
    if (httpBodyOfPH) {
      requestMessageOfPH.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBodyOfPH)
      );
    }
    runEngine.sendMessage(requestMessageOfPH.id, requestMessageOfPH);
    return requestMessageOfPH.messageId;
  }

  handleChangeNameOfPHInput = (event: any) => {
    const nameOfPH = event.target.value;
    this.setState({ nameOfPH: nameOfPH });
    if (!nameOfPH) {
      this.setState({ nameOfPHError: { require: true } });
      return false;
    } else {
      this.setState({ nameOfPHError: { require: false } });
    }
  }

  handleChangeAddressOfPHInput = (event: any) => {
    const addressOfPH = event.target.value;
    this.setState({ addressOfPH: addressOfPH });
    if (!addressOfPH) {
      this.setState({ addressOfPHError: { require: true } });
      return false;
    } else {
      this.setState({ addressOfPHError: { require: false } });
    }
  }

  handleChangeEmailOfPHInput = (event: any) => {
    const emailOfPH = event.target.value;
    this.setState({ emailOfPH: emailOfPH });
    if (!emailOfPH) {
      this.setState({ emailOfPHError: { invalid: false, require: true } });
      return false;
    } else {
      this.setState({ emailOfPHError: { invalid: false, require: false } });
    }
    const validateEmailOfPH = isEmail("email", emailOfPH);
    !validateEmailOfPH.status
      ?
      this.setState({ emailOfPHError: { invalid: true, require: false } })
      : this.setState({ emailOfPHError: { invalid: false, require: false } });
  }

  handleChangeGstOfPHInput = (event: any) => {
    const gstOfPH = event.target.value.toUpperCase();
    this.setState({ gstOfPH: gstOfPH });
    if (!gstOfPH) {
      this.setState({ gstOfPHError: { invalid: false, require: true } });
      return false;
    } else {
      this.setState({ gstOfPHError: { invalid: false, require: false } });
    }
    const validGSTNumber = validateGSTNumber(gstOfPH);
    !validGSTNumber
      ? this.setState({ gstOfPHError: { invalid: true, require: false } })
      : this.setState({ gstOfPHError: { invalid: false, require: false } });
  }

  handleChangeTanOfPHInput = (event: any) => {
    const tanOfPH = event.target.value.toUpperCase();
    this.setState({ tanOfPH: tanOfPH });
    if (!tanOfPH) {
      this.setState({ tanOfPHError: { invalid: false, require: true } });
      return false;
    } else {
      this.setState({ tanOfPHError: { invalid: false, require: false } });
    }
    const validTanNumber = validateTanNumber(tanOfPH);
    !validTanNumber
      ? this.setState({ tanOfPHError: { invalid: true, require: false } })
      : this.setState({ tanOfPHError: { invalid: false, require: false } });
  }

  handleChangeAadharOfPHInput = (event: any) => {
    const aadharOfPH = event.target.value;
    if (!isNaN(aadharOfPH)) {
      this.setState({ aadharOfPH: aadharOfPH });
      if (!aadharOfPH) {
        this.setState({ aadharOfPHError: { invalid: "", require: true } });
        return false;
      } else {
        this.setState({ aadharOfPHError: { invalid: "", require: false } });
      }
    } else {
      this.setState({ aadharOfPHError: { invalid: "numeric", require: false } });
      return false;
    }
    const validAadharNumber = aadharOfPH.length === 12;
    !validAadharNumber
      ? this.setState({ aadharOfPHError: { invalid: "length", require: false } })
      : this.setState({ aadharOfPHError: { invalid: "", require: false } });
  }

  handleChangePanOfPHInput = (event: any) => {
    const panOfPH = event.target.value.toUpperCase();
    this.setState({ panOfPH: panOfPH });
    if (!panOfPH) {
      this.setState({ panOfPHError: { invalid: false, require: true } });
      return false;
    } else {
      this.setState({ panOfPHError: { invalid: false, require: false } });
    }
    const validPanNumber = validatePanNumber(panOfPH);
    !validPanNumber
      ? this.setState({ panOfPHError: { invalid: true, require: false } })
      : this.setState({ panOfPHError: { invalid: false, require: false } });
  }

  handleClose = () => {
    this.setState({ isModelOpen: false });
  }

  handleModelOpen = () => {
    this.setState({ isModelOpen: true });
  }

  getOtpApiCall = async () => {
    this.getOtpApiCallId = await this.apiCall(configJSON.postApiMethod, configJSON.getOtpApiEndPoint, {
      data: {
        account: {
          user_type: "production_house",
          verify_phone_or_email: this.state.verifyPhoneEmail === "email" ? "email" : "phone",
          country_name: this.state.countryNameSelected,
          [this.state.verifyPhoneEmail === "email" ? "email" : "full_phone_number"]: this.state.verifyPhoneEmail === "email" ? this.state.emailOfPH : this.state.countryCodeSelected + this.state.phoneOfPH,
        }
      }
    });
  }

  handleVerifyEmailOfPH = () => {
    if (!this.state.emailOfPH) {
      this.setState({ notification: { type: 'warning', open: true, message: "Please enter email to verify" } });
      return false;
    } else if (this.state.emailOfPHError.invalid || this.state.emailOfPH.includes(" ")) {
      this.setState({ notification: { type: 'warning', open: true, message: "Please enter valid email to verify" } });
      return false;
    }
    this.setState({ verifyPhoneEmail: "email" }, () => {
      this.getOtpApiCall();
      this.setState({ isDisabled: true });
      setTimeout(() => {
        this.setState({ isDisabled: false });
      }, 30000);
    });
  }
  

  handleVerifyPhoneOfPH = () => {
    if (!this.state.phoneOfPH) {
      this.setState({ notification: { type: 'warning', open: true, message: "Please enter phone number to verify" } });
      return false;
    } else if (this.state.phoneOfPHError.invalid) {
      this.setState({ notification: { type: 'warning', open: true, message: "Please enter valid phone number to verify" } });
      return false;
    }
    this.setState({ verifyPhoneEmail: "phone" }, () => {
      this.getOtpApiCall();
      this.setState({ isDisabled: true });
      setTimeout(() => {
        this.setState({ isDisabled: false });
      }, 30000);
    });
  }

  handleMoveOnNextPage = () => {
    if (!this.state.activeStep) {
      if (!this.state.nameOfPH || !this.state.addressOfPH || !this.state.emailOfPH || !this.state.phoneOfPH || this.state.emailOfPHError.invalid || this.state.phoneOfPHError.invalid) {
        this.setState({ nameOfPHError: { require: !Boolean(this.state.nameOfPH) }, addressOfPHError: { require: !Boolean(this.state.addressOfPH) }, emailOfPHError: { invalid: this.state.emailOfPHError.invalid, require: !Boolean(this.state.emailOfPH) }, phoneOfPHError: { invalid: this.state.phoneOfPHError.invalid, require: !Boolean(this.state.phoneOfPH) } });
        return false;
      } else if (!this.state.isPhoneEmailVerified.email || !this.state.isPhoneEmailVerified.phone) {
        this.setState({ notification: { type: 'warning', open: true, message: "Please verify email and phone number" } });
        return false;
      }
      this.registerPHApiCall();
    } else if (this.state.activeStep === 1) {
      // if (!this.state.gstOfPH || !this.state.tanOfPH || !this.state.aadharOfPH || !this.state.panOfPH) {
      //   this.setState({ gstOfPHError: { ...this.state.gstOfPHError, require: !Boolean(this.state.gstOfPH) }, tanOfPHError: { ...this.state.tanOfPHError, require: !Boolean(this.state.tanOfPH) }, aadharOfPHError: { ...this.state.aadharOfPHError, require: !Boolean(this.state.aadharOfPH) }, panOfPHError: { ...this.state.panOfPHError, require: !Boolean(this.state.panOfPH) } });
      //   return false;
      // } else if (this.state.gstOfPHError.invalid) {
      //   this.setState({ notification: { type: 'warning', open: true, message: "Please enter valid GST number" } });
      //   return false;
      // } else if (this.state.tanOfPHError.invalid) {
      //   this.setState({ notification: { type: 'warning', open: true, message: "Please enter valid Tan number" } });
      //   return false;
      // } else if (this.state.aadharOfPHError.invalid) {
      //   this.setState({ notification: { type: 'warning', open: true, message: "Please enter valid Addhar card number" } });
      //   return false;
      // } else if (this.state.panOfPHError.invalid) {
      //   this.setState({ notification: { type: 'warning', open: true, message: "Please enter valid Pan card number" } });
      //   return false;
      // }
    }
    this.setState({ activeStep: this.state.activeStep + 1 });
  }

  registerPHApiCall = async () => {
    const httpBody = {
      data: {
        account: {
          user_type: "production_house",
          email: this.state.emailOfPH,
          full_phone_number: this.state.countryCodeSelected + this.state.phoneOfPH,
          production_house_name: this.state.nameOfPH,
          production_house_address: this.state.addressOfPH,
        }
      }
    }
    this.signUpPHApiCallId = await this.apiCall(configJSON.postApiMethod, configJSON.signUpPHApiEndPoint, httpBody);
  }

  verifyOtpAPICall = async (httpBody: any) => {
    this.verifyOtpApiCallId = await this.apiCall(configJSON.postApiMethod, configJSON.verifyOtpApiEndPoint, httpBody);
  }

  handleCloseNotification = () => {
    this.setState({ notification: { type: "", open: false, message: "" } });
  }

  handleChangePhoneOfPHInput = (event: any) => {
    const phoneOfPH = event.currentTarget?.value;
    this.setState({ phoneOfPH: phoneOfPH });
    if (!phoneOfPH) {
      this.setState({
        phoneOfPHError: { invalid: false, require: true }
      });
      return false;
    } else {
      this.setState({
        phoneOfPHError: { invalid: false, require: false }
      });
    }
    const validatePhoneOfPH = phoneValidate("phone number", phoneOfPH);
    if (!validatePhoneOfPH.status) {
      this.setState({ phoneOfPHError: { invalid: true, require: false } })
    } else {
      if (this.state.countryCodeSelected === "91" && phoneOfPH.length !== 10) {
        this.setState({ phoneOfPHError: { invalid: true, require: false } })
      } else {
        this.setState({ phoneOfPHError: { invalid: false, require: false } })
      }
    }
  }

  handleStepBack = () => {
    this.setState({ storeCountryCode: this.state.countryCodeSelected, activeStep: this.state.activeStep - 1 });
  }

  handleInputFocus = (labelName: string) => {
    const inputLabel = document.getElementById(labelName);
    if (inputLabel) {
      inputLabel.style.color = "#F5C42C";
    }
  };

  handleInputBlur = (labelName: string) => {
    const inputLabel = document.getElementById(labelName);
    if (inputLabel) {
      inputLabel.style.color = "#6D89AF";
    }
  };

  getSelectedCountryName = async (countryName: string) => {
    this.setState({ countryNameSelected: countryName });
  }

  handlePHPasswordVisibilityChange = (type: string) => {
    if (type === "password") {
      this.setState({ isPasswordOfPHVisible: !this.state.isPasswordOfPHVisible });
    } else if (type === "confirmPassword") {
      this.setState({ isConfirmPasswordOfPHVisible: !this.state.isConfirmPasswordOfPHVisible });
    }
  }

  handleChangePasswordOfPHInput = (event: any) => {
    const passwordOfPH = event.target.value;
    this.setState({ passwordOfPH: event.target.value }, () => {
      this.validateConfirmPassword();
    });

    if (!passwordOfPH) {
      this.setState({ passwordOfPHError: { invalid: false, require: true } });
      return false;
    } else {
      this.setState({ passwordOfPHError: { invalid: false, require: false } });
      const validPassword = passwordValidate("password", passwordOfPH);
      !validPassword.status
        ?
        this.setState({ passwordOfPHError: { invalid: true, require: false } })
        : this.setState({ passwordOfPHError: { invalid: false, require: false } });
    }
  }

  validateConfirmPassword = () => {
    if (this.state.confirmPasswordOfPH) {
      const validCPasswordOfPH = confirmPasswordValidate("confirm password", this.state.confirmPasswordOfPH, "password", this.state.passwordOfPH);
      !validCPasswordOfPH.status
        ?
        this.setState({ confirmPasswordOfPHError: { require: false, match: true } })
        : this.setState({ confirmPasswordOfPHError: { require: false, match: false } });
    }
  }

  handleChangeConfirmPasswordOfPHInput = (event: any) => {
    const confirmPasswordOfPH = event.target.value;
    this.setState({ confirmPasswordOfPH: confirmPasswordOfPH });
    if (!confirmPasswordOfPH) {
      this.setState({ confirmPasswordOfPHError: { require: true, match: false } });
      return false;
    } else {
      this.setState({ confirmPasswordOfPHError: { require: false, match: false } });
      const validCPasswordOfPH = confirmPasswordValidate("confirm password", confirmPasswordOfPH, "password", this.state.passwordOfPH);
      !validCPasswordOfPH.status
        ?
        this.setState({ confirmPasswordOfPHError: { require: false, match: true } })
        : this.setState({ confirmPasswordOfPHError: { require: false, match: false } });
    }
  }

  handleChangeTncOfPH = (event: any) => {
    this.setState({ isTncOfPH: event.target.checked });
  }

  handleSignUpForPH = async () => {
    if (!this.state.passwordOfPH || !this.state.confirmPasswordOfPH || this.state.passwordOfPHError.invalid || this.state.confirmPasswordOfPHError.match) {
      this.setState({ passwordOfPHError: { ...this.state.passwordOfPHError, require: !Boolean(this.state.passwordOfPH) }, confirmPasswordOfPHError: { ...this.state.confirmPasswordOfPHError, require: !Boolean(this.state.confirmPasswordOfPH) } });
      return false;
    } else if (!this.state.isTncOfPH) {
      this.setState({ notification: { type: 'warning', open: true, message: "Please check terms and conditions." } });
      return false;
    }

    const httpBody = {
      data: {
        account: {
          gst_number: this.state.gstOfPH,
          tan_number: this.state.tanOfPH,
          aadhar_card_number: this.state.aadharOfPH,
          pan_card_number: this.state.panOfPH,
          password: this.state.passwordOfPH,
        }
      }
    }
    this.updateSignUpPHApiCallId = await this.apiCall(configJSON.patchApiMethod, configJSON.updateSignUpPHApiEndPoint, httpBody);
  }

  handleTncNavigation = () => {
    setStorageData("signupTnc", "true");
    window.open("/TermsAndCondition",'_blank')
  }
  // Customizable Area End
}
