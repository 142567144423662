import React from "react";

import {
  // Customizable Area Start
  Container,
  Grid,
  Typography,
  Card,
  InputLabel,
  Box,
  Button,
  // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import LayersOutlinedIcon from '@material-ui/icons/LayersOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import ProductionHouseDetailController, { Props, configJSON } from "./ProductionHouseDetailController";
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import BreadCrum from "./BreadCrum.web";
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import { switchEnable, switchDisable, studiosYellowIcon, studiosIcon, reviewIcon, reviewYellowIcon, dummyImgPHIcon } from "./assets";
import TodayOutlinedIcon from '@material-ui/icons/TodayOutlined';
import { webStyle } from "./FullTeamVisibility.web";
import StarOutlinedIcon from '@material-ui/icons/StarOutlined';
import "./fullVisibility.css";
import AllProjectsCard from "./AllProjectsCard.web";
import Loader from "../../../components/src/Loader.web";
import SideBar from "../../../components/src/SideBar";
import Header from "../../../components/src/Header.web";
import Footer from "../../../components/src/Footer";
import AllStudiosCard from "./AllStudiosCard.web";
import AllVendorRating from "./AllVendorRating.web";
import Overview from "./Overview.web";
import { webStyle_overview } from "./UserDetailCommon.web";
// Customizable Area End

export default class ProductionHouseDetail extends ProductionHouseDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleButtonRating = () => {
    return <div className="rating_box">
      <img
        src={this.state.activeTab == `${configJSON.rating_vp}` ? reviewYellowIcon : reviewIcon}
        alt=""
        style={{ width: "16px", height: "16px", padding: "2px" } as React.CSSProperties}
      />
      <Typography style={{ marginLeft: "2px" }}>{configJSON.rating}</Typography>
    </div>
  };

  handleButtonProject = () => {
    return <div className="rating_box">
      <LayersOutlinedIcon fontSize="small" />
      <Typography>{configJSON.project}</Typography>
    </div>
  };

  handleButtonStudio = () => {
    return <div className="rating_box">
      <img
        src={this.state.activeTab == `${configJSON.studioProductionHouse}` ? studiosYellowIcon : studiosIcon}
        alt=""
        style={{ fontSize: "small", width: "20px", height: "20px" } as React.CSSProperties}
      />
      <Typography>{configJSON.studio}</Typography>
    </div>
  };

  renderMainContentAllProductionHouseProfile(sideBarStatus: boolean) {
    return <Grid style={webStyle_main.mainContent}>
      < Header
        sideBarStatus={sideBarStatus}
        dropDownStatus={this.state.isDropDwnOpen}
        handleSideBar={this.handleSideDropdownOpenVal}
        handleDropdown={this.handleDropdownValue}
        navigate={this.props.navigation.navigate}
      />
      <Grid style={webStyle.mainContent as React.CSSProperties}>
        {this.renderMainContentProductionHouseDetail()}
      </Grid>
      <Footer />
    </Grid >
  }

  renderMainContentProductionHouseDetail() {
    return (
      <Grid container spacing={0} style={{ padding: '24px' }} className="user_detail_grid_container">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="overview_grid_container">
          <InputLabel style={webStyle_card.productionHouseLabel}><ArrowBackOutlinedIcon style={{ fontSize: "100%", cursor: "pointer" }} data-test-id="navigateToListPage" onClick={this.navigateToAllProductionHouse} />{configJSON.productionHouseDetails}</InputLabel>
          <Box sx={webStyle_main.allProductionHouseTextBox}>
            <BreadCrum navigation={this.props.navigation} id="" breadcrumList={this.state.bList} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4} className="single_profile_card padding_value overview_grid_container">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} data-test-id="card_parent_onclick">
            <Card style={webStyle.card}>
              <Box style={{ cursor: "pointer" }}>
                <Box style={webStyle_card.imageContainer as React.CSSProperties}>
                  <img style={webStyle_card.houseImage as React.CSSProperties} src={this.state.productionHouseDetail.profile.data?.attributes.profile_image || dummyImgPHIcon} />
                  <Box style={webStyle_card.houseDetails as React.CSSProperties}>
                    <Box>
                      <Typography style={{ fontWeight: 600, lineSpacing: "0.22857143px", fontSize: "16px", fontFamily: "Urbanist", lineHeight: "19px" } as React.CSSProperties}>{this.state.productionHouseDetail.production_house_name}</Typography>
                      <Typography style={{ fontSize: "12px", fontFamily: "Urbanist", lineHeight: "14px", lineSpacing: "0.2px", fontWeight: 500, ...webStyle_card.alignContent } as React.CSSProperties}><LocationOnOutlinedIcon style={webStyle_card.locationIcon} />{this.state.productionHouseDetail.production_house_address}</Typography>
                    </Box>
                  </Box>
                </Box>
                <Box style={webStyle_card.buttonArea as React.CSSProperties}>
                  <Box style={{ width: "40%", ...webStyle_card.cardButton as React.CSSProperties }}>
                    <Box sx={{ width: { xs: "50%", md: this.state.isSiderBarOpenValue ? "95%" : "70%" }, ...webStyle_card.emailWrapper }}>
                      <PhoneOutlinedIcon style={webStyle_card.emailSvgIcon as React.CSSProperties} />
                      {this.state.productionHouseDetail.phone_number}
                    </Box>
                  </Box>
                  <Box style={webStyle_card.verticalLine as React.CSSProperties}></Box>
                  <Box style={{ width: "55%", ...webStyle_card.cardButton as React.CSSProperties }}>
                    <Box sx={{ width: { xs: "70%", md: "80%" }, ...webStyle_card.emailWrapper }}>
                      <EmailOutlinedIcon style={webStyle_card.emailSvgIcon as React.CSSProperties} />
                      {this.state.productionHouseDetail.email}
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box style={{ ...webStyle_card.seperateCard, margin: "0% 2% 2%" }}></Box>
              <Typography className="general">
                {configJSON.general}
              </Typography>
              <Grid className="tabs_name" xs={12} sm={12} md={12} lg={12} xl={12} item>
                <Button
                  className="tab"
                  variant="contained"
                  startIcon={<DescriptionOutlinedIcon />}
                  fullWidth
                  data-test-id="tab"
                  style={
                    this.state.activeTab == `${configJSON.overview_vp}`
                      ? webStyle_overview.activeText
                      : webStyle_overview.inactiveText
                  }
                  onClick={() => this.handleChangeTabValue('overview')}
                >
                  {configJSON.overview}
                </Button>
              </Grid>
              <Grid className="star_icon tabs_name" item xs={12} sm={12} md={12} lg={12} xl={12} >
                <Button
                  variant="contained"
                  fullWidth
                  className="rating_but"
                  startIcon={this.handleButtonProject()}
                  onClick={
                    () => this.handleChangeTabValue('project')
                  }
                  style={
                    this.state.activeTab == `${configJSON.project_vp}`
                      ? webStyle_overview.activeText
                      : webStyle_overview.inactiveText
                  }
                  data-test-id="project_tab_id"
                >
                  {this.state.productionHouseDetail.projects_count}
                </Button>
              </Grid>
              <Grid className="star_icon tabs_name" item xs={12} sm={12} md={12} lg={12} xl={12} >
                <Button
                  variant="contained"
                  fullWidth
                  className="rating_but"
                  startIcon={this.handleButtonStudio()}
                  onClick={
                    () => this.handleChangeTabValue('studio')
                  }
                  style={
                    this.state.activeTab == `${configJSON.studioProductionHouse}`
                      ? webStyle_overview.activeText
                      : webStyle_overview.inactiveText
                  }
                  data-test-id="studio_tab_id"
                >
                  {this.state.productionHouseDetail.studios_count}
                </Button>
              </Grid>
              <Grid className="star_icon tabs_name" xs={12} sm={12} md={12} lg={12} xl={12} item style={{ marginLeft: "2px" }} >
                <Button
                  style={
                    this.state.activeTab == `${configJSON.rating_vp}`
                      ? webStyle_overview.activeText
                      : webStyle_overview.inactiveText
                  }
                  fullWidth
                  startIcon={this.handleButtonRating()}
                  onClick={
                    () => this.handleChangeTabValue('rating')
                  }
                  className="rating_but"
                  data-test-id="btn_rating"
                >
                  <Box style={webStyle_overview.stars}>
                    <StarOutlinedIcon style={{ width: "12px", color: "white" }} />
                    <span style={{ color: 'white' }}>
                      &nbsp;{this.state.productionHouseDetail.rating_and_review}
                    </span>
                  </Box>
                </Button>
              </Grid>
              <Box style={{ ...webStyle_card.seperateCard, margin: "0% 2% 2%" }}></Box>
              <Box style={webStyle_card.additionalInfo}>
                <Box>
                  <Typography className="calender_typo">
                    <TodayOutlinedIcon style={{ width: "16px", marginRight: "3px" }} />{this.state.productionHouseDetail.join_date}
                  </Typography>
                </Box>
                <Box
                  style={webStyle_card.alignContent}
                >
                  <Typography style={webStyle_card.block as React.CSSProperties}>
                    {this.state.productionHouseDetail.blocked ? <Typography className="block_color">Blocked</Typography> : <Typography style={webStyle_card.block as React.CSSProperties}>Block</Typography>}
                    {this.state.productionHouseDetail.blocked
                      ?
                      <img src={switchEnable}
                        style={webStyle_card.blockBox}
                        data-test-id={`${this.state.id}_switch_enable`}
                        onClick={(e: any) => this.handleProductionHouseBadgeVisibility(e, this.state.id, this.state.productionHouseDetail.blocked, "productionhouse")}
                      />
                      :
                      <img src={switchDisable}
                        style={webStyle_card.blockBox}
                        data-test-id={`${this.state.id}_switch_disable`}
                        onClick={(e: any) => this.handleProductionHouseBadgeVisibility(e, this.state.id, this.state.productionHouseDetail.blocked, "productionhouse")}
                      />
                    }
                  </Typography>
                </Box>
              </Box>
            </Card>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8} className="single_profile_card detail_box overview_grid_container">
          {this.state.activeTab == `${configJSON.overview_vp}` &&
            <Box className="deatil_box_inner">
              <Overview navigation={this.props.navigation} type="productionHouse" id={this.state.id} overviewData={{ ...this.state.productionHouseDetail, documents: this.state.productionHouseDetail.profile?.data?.attributes.documents }} />
            </Box>
          }
          {
            this.state.activeTab == `${configJSON.studioProductionHouse}` &&
            <Box className="deatil_box_inner">
              <AllStudiosCard id={this.state.id} navigation={this.props.navigation} studios={this.state.studios} />
            </Box>
          }
          {
            this.state.activeTab == `${configJSON.project_vp}` &&
            <Box className="deatil_box_inner">
              <AllProjectsCard id={this.state.id} navigation={this.props.navigation} allProjects={this.state.allProjects} upcomingProjects={this.state.upcomingProjects} ongoingProjects={this.state.ongoingProjects} completedProjects={this.state.completedProjects} />
            </Box>
          }
          {
            this.state.activeTab == `${configJSON.rating_vp}` &&
            <Box className="detail_box_inner" style={{ marginTop: '4%', paddingLeft: '3%', paddingRight: '3%' }}>
              <AllVendorRating id={this.state.id} type="productionHouse" ratingData={this.state.rating} navigation={this.props.navigation} />
            </Box>
          }
        </Grid>
      </Grid >
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <Loader loading={this.state.isLoading}></Loader>
        <Container maxWidth="xl" className="user_detail_container">
          {
            this.state.isSiderBarOpenValue ?
              <Grid container spacing={0}>
                <Grid item xs={3} style={webStyle_main.sideBar}>
                  <SideBar
                    navigate={this.props.navigation.navigate}
                    userData={{ name: "user name" }}
                    activeMenuItem={"All Production Houses"}
                    handleSideBar={this.handleSideDropdownOpenVal} />
                </Grid>
                <Grid item xs={9}>{this.renderMainContentAllProductionHouseProfile(this.state.isSiderBarOpenValue)}</Grid>
              </Grid> :
              <Grid item xs={12}>
                < Header
                  sideBarStatus={this.state.isSiderBarOpenValue}
                  dropDownStatus={this.state.isDropDwnOpen}
                  handleSideBar={this.handleSideDropdownOpenVal}
                  handleDropdown={this.handleDropdownValue}
                  navigate={this.props.navigation.navigate}
                />
                {this.renderMainContentProductionHouseDetail()}
              </Grid>
          }
        </Container >

      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle_main = {
  mainContent: {
    borderLeft: "1px solid #E3EAF4",
  },
  sideBar: {
    minHeight: "100vh",
    //maxWidth: "23%",
  },
  allProductionHouseTextBox: {
    display: 'flex',
    flexdirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    columnGap: '0.5%'
  },
};
export const webStyle_rating = {
  avtar: {
    width: "40px",
    borderRadius: "12px",
    height: "40px",
  }
}
export const webStyle_card = {
  imageContainer: {
    height: "123px",
    width: "100%",
    borderRadius: "12px",
    position: "relative",
  },
  emailWrapper: {
    position: "relative",
    display: "block",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  houseImage: {
    left: "0",
    borderRadius: "12px",
    position: "absolute",
    height: "100%",
    width: "100%",
    top: "0",
    objectFit: "cover",
  },
  houseDetails: {
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
    right: "8px",
    bottom: "8px",
    left: "8px",
    color: "#fff",
    position: "absolute",
  },
  alignContent: {
    alignItems: "center",
    display: "flex",
  },
  seperateCard: {
    border: "1px solid #E3EAF4",
  },
  verticalLine: {
    borderLeft: "1px solid #E3EAF4",
    marginTop: "12px",
    height: "20px",
  },
  locationIcon: {
    width: "14px",
    height: "14px",
    color: "#fff",
  },
  cardButton: {
    display: 'flex',
    height: 38,
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: "Urbanist",
    color: "#17181d",
    fontWeight: 600,
    fontSize: "14px",
    letterSpacing: "0.2px",
    textDecoration: "underline",
  },
  buttonArea: {
    justifyContent: "space-between",
    display: 'flex',
    padding: "8px 0",
    flexWrap: "wrap",
  },
  buttonIcon: {
    marginRight: "4px",
    height: "17px",
    width: "17px",
  },
  emailSvgIcon: {
    height: "17px",
    width: "17px",
    position: "relative",
    top: "5px",
    marginRight: "4px",
  },
  additionalInfo: {
    justifyContent: "space-between",
    display: "flex",
    color: "#6D89AF",
    alignItems: "center",
    padding: "2%",
  },
  blockBox: {
    marginRight: "1%",
    cursor: "pointer",
  },
  block: {
    fontSize: "14px",
    display: "flex",
    columnGap: "1%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    color: "#6d89af",
    fontFamily: "Urbanist",
    fontWeight: "600",
    lineHeight: "16px",
    letterSpacing: "0.23333333px"
  },
  blockColor: {
    color: "#f70052",
    fontSize: "14px",
    fontFamily: "Urbanist",
    fontWeight: "600",
    lineHeight: "16px",
    letterSpacing: "0.23333333px"
  },
  productionHouseLabel: {
    color: '#17181d',
    fontWeight: 700,
    fontSize: '28px',
    fontFamily: 'Urbanist',
    display: 'flex',
    columnGap: '1%',
    letterSpacing: '0.31111112px',
    lineHeight: '34px',
  },
}
// Customizable Area End