import React from "react";
import { Card, Box, CardMedia, CardContent, Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { JobCardImage, LocationIconjob, rupeeIconJobBlack, dotsIcon, headingicon, leaveproject , dummyImage } from "./assets";
import Raiseinvoicefunctionality from "./Raiseinvoicefunctionality";
import Modal from '@material-ui/core/Modal';
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreHoriz";
interface MyProps {
    data: any;
    handleClose:any;
    open:any
    handleOpen1:any;
    leaveProject:any
    handleViewinvoice:any;

}



const useStyles = makeStyles({
    cardDivWithOpenSideBar: () => ({
        //height: 240,
        borderRadius: 14,
        padding: 8,
        border: "1px solid #E1E6EC",
        position: 'relative',
        '& .MuiCardContent-root': {
            padding: 0
        },
        "@media(max-width: 500px)": {

        },
        "@media(max-width: 1300px)": {
        
        },
    }),
    cardDivWithClosedSideBAr: () => ({
        width: 346,
        borderRadius: 14,
        padding: 8,
        border: "1px solid #E1E6EC",
        position: 'relative',
        '& .MuiCardContent-root': {
            padding: 0
        },
        "@media(max-width: 500px)": {

        },
    }),
    parentBoxValue: () => ({
        display: "flex",
        justifyContent: 'space-between',



    }),

 
    parentBox:() =>({

        width:'100%',
        
        

    }),

    ParentSubheading1: () => ({
        display: "flex",
        gap: "5px",

    })
    ,
    headingDept: () => ({
        display: 'flex',
        justifyContent: 'space-between',
    })
    ,
    positionDiv: () => ({
        display: 'flex',
        gap: '5px',
        borderRadius: '15px',
        backgroundColor: '#f8fafe',
        borderColor: '#979797',
        height: 31,
        width: 101,
        alignItems: 'center'
    }),
    iconposition: () => ({

    }),
    DeptDiv: () => ({
        fontSize: '13px',
        fontFamily: 'urbanist',
        color: '#17181D',
        fontWeight: 500,
        lineHeight: '16px',

    }),
    Dept: () => ({}),
    mainDiv: () => ({
        paddingTop: '4px',
        lineHeight: '19px',
        fontSize: '14px',
        fontWeight: 600,
        fontFamily: 'urbanist',
        color: '#17181D',
        display: 'flex',
        gap: '4px'
    }),
    YrsDiv: () => ({
        display: 'flex',
        gap: '4px',
        paddingTop: '6px',
        '& .MuiBox-root': {
            fontSize: '13px',
            fontWeight: '600px',

            color: '#6d89af',
        }
    }),
    Yrstext: () => ({
        paddingTop: '6px',
        fontSize: '13px',
        fontWeight: 600,
        color: '#6d89af',
        fontFamily: 'urbanist',
        '& .MuiBox-root': {
            fontSize: '13px',
            fontWeight: '600px',
        }
    }),
    jobiconyear: () => ({
        height: '16px',
        width: '16px'
    }),
    doticon: () => ({
        height: '24px',
        width: '24px'
    }),
    rupeeicon: () => ({
        paddingTop: '2px',
        height: '16px',
        width: '16px'
    }),
    headingMain:()=>({}),
    rupeesDiv: () => ({
        display: 'flex',
        gap: '4px',
        borderBottom: '1px solid #e3eaf4',
        paddingBottom: '11px',
        '& .MuiBox-root': {
            display: 'flex',
            gap: '4px',
            fontSize: '14px',
            color: '#6d89af',
            fontWeight: 400,
            fontFamily: 'Helvetica',
            paddingTop: '5px',

        }

    }),
    jobcardimage: () => ({
       
        height:'40px',
        width:'40px',
        borderRadius: '10px'

    }),
    footerDiv: () => ({
        display: "flex"
    }),
    addressDiv: () => ({
        paddingTop: '13px',
        paddingLeft: "6px",
    }),
    address1: () => ({
        fontSize: '14px',
        fontFamily: 'urbanist',
        fontWeight: 600,
        color: '#17181D',
    }),
    address2: () => ({
        display: 'flex',
        gap: '4px',
        paddingTop: "3px",
    }),
    ParentSubheading2: () => ({
        fontSize: '11px',
        fontFamily: 'urbanist',
        fontWeight: 500,
        color: '#6d89af',
        paddingTop: '14px'

    }),
    locationDiv: () => ({
        fontSize: '12px',
        fontWeight: 600,
        fontFamily: 'urbanist',
        color: '#6d89af',


    }),
    ParentBottomDiv: () => ({
        display: 'flex',
        justifyContent: 'space-between'
    }),
    Div2: () => ({
        paddingTop: '12.5px'
    }),
    rupeetext: () => ({
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '17px',
        fontFamily: 'Helvetica'
    }),
    button3: {
        cursor: "pointer",
        color: "black",
        backgroundColor: "#ffffff",
        textTransform: "none",
        width: "152px",
        borderRadius: "14px",
        //position: "absolute",
        bottom: "16px",
        height: "38px",
        fontWeight: 600,
        fontSize: "12px",
        paddingTop: "10px",
        paddingBottom: "14px",
        marginBottom: "5px",
        fontFamily: "urbanist",
        border: '1px solid #17181d'
    },
    submitButton: {
        cursor: "pointer",
        color: "#ffffff",
        backgroundColor: "#17181D",
        textTransform: "none",
        width: "152px",
        border: "none",
        borderRadius: "14px",
        //position: "absolute",
        bottom: "16px",
        height: "38px",
        fontWeight: 600,
        fontSize: "12px",
        paddingTop: "10px",
        paddingBottom: "14px",
        marginBottom: "5px",
        fontFamily: "urbanist",
    },
    humbergerparent:{
        display:'flex',
        paddingLeft:'12px',
        alignItems:'center',
        gap:'10px',
      },
      humbergerParentIcon:{
       
      },
      humbergericon:{
        height:'16px',
        width:'16px',
      },
      humbergerLabel:{
        color: '#17181d',
  fontFamily: 'Urbanist',
  fontSize: '14px',
  fontWeight: 600,

      }

});

export default function MyJobCard(props: MyProps) {

    const { data  ,handleClose,open,handleOpen1,leaveProject,handleViewinvoice} = props
    const classes = useStyles();
    const ITEM_HEIGHT = 48;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openhumberger = Boolean(anchorEl);
    const options = [
        { label: "Leave Project", icon: leaveproject }
      ];
    const handleClick = (event:any) => {
        event.stopPropagation();
      setAnchorEl(event.currentTarget);
    };
    const handleClosehumberger = () => {
        setAnchorEl(null);
      };
      const handleOptionClick = (event:any, label:string) => {
        event.stopPropagation();
        handleClosehumberger(); // Close the dropdown
      
        // Perform different actions based on the clicked label
           if (label === 'Leave Project') {
            leaveProject(data?.attributes?.project_id,data?.id)
        } 
      };
    return (
        <Box className={classes.parentBox}>
            <Card className={classes.cardDivWithOpenSideBar}>

                <Box  style={{
                    display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid',
                    paddingBottom: '10px',
                    borderColor: '#e3eaf4'
                }}>
                    <Box className={classes.ParentSubheading1}>
                        <Box style={{ paddingTop: '10px',}}>
                            <img src={data?.attributes?.project?.data?.attributes?.image|| dummyImage} alt="" className={classes.jobcardimage} />
                        </Box>
                        <Box className={classes.addressDiv}>
                            <Box className={classes.address1}>
                                {data?.attributes?.project?.data?.attributes?.project_name}
                            </Box>
                            {data?.attributes?.city && data?.attributes?.state && 
                            <Box className={classes.address2}>
                                <Box>
                                    <img src={LocationIconjob} alt="" className={classes.jobiconyear} />
                                </Box>
                                <Box className={classes.locationDiv}>
                               { `${data?.attributes?.city}, ${data?.attributes?.state}`}
                                </Box>
                            </Box>}
                        </Box>
                    </Box>
                    <Box className={classes.ParentSubheading2}>
                    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={openhumberger ? "long-menu" : undefined}
        aria-expanded={openhumberger ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon htmlColor="#6D89AF"/>
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={openhumberger}
        onClose={handleClosehumberger}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "154px",
            display: "flex",
            alignItems: "center",
            height: "48px"
          },
        }}
      >
        {options.map((option, index) => (
        <div key={index} onClick={(e) => handleOptionClick(e, option.label)}>
            <Box className={classes.humbergerparent}>
               <Box className={classes.humbergerParentIcon}>
                    <img src={option.icon} alt="" className={classes.humbergericon} />
               </Box>
               <Box className={classes.humbergerLabel}>
                    {option.label}
                </Box>
            </Box> 
        </div>
      ))}
      </Menu>
    </div>
                    </Box>
                </Box>
                <Box className={classes.ParentBottomDiv}>
                    <Box style={{ paddingTop: '12.5px' }} >
                        <Box className={classes.DeptDiv}>
                            Dept | {data?.attributes?.project_department_name}
                        </Box>
                        <Box className={classes.headingMain}>
                            <Box className={classes.mainDiv}>
                                <Box>
                                    <img src={headingicon} alt="" className={classes.rupeeicon} />
                                </Box>
                                {data?.attributes?.role}
                            </Box>
                        </Box>
                    </Box>
                    <Box className={classes.Div2} >
                        <Box className={classes.DeptDiv}>
                            Amount of Role
                        </Box>
                        <Box className={classes.mainDiv}>
                            <Box>
                                <img src={rupeeIconJobBlack} alt="" className={classes.rupeeicon} />
                            </Box>
                            <Box className={classes.rupeetext}>₹{data?.attributes?.budget_of_role}</Box>
                        </Box>
                    </Box>
                </Box>

                <Box  style={{ display: 'flex', justifyContent:'space-between',gap:'5px', paddingTop: '13px' }}>
                   
                    <div>
                    <button
                        className={classes.button3}  onClick={(e:any)=>handleOpen1(e,data.attributes.project.data?.id,data.attributes.project.data.attributes?.project_name)} >
                        Raise Invoice
                    </button>
                            </div>

                    <button 
                        className={classes.submitButton}
                        onClick={(e:any)=>handleViewinvoice(e,data.attributes.project.data?.attributes.project_name)}

                    >
                        View Invoices
                    </button>
                </Box>
            </Card>
        </Box>
    );
}
