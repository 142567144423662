import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { isEmail, confirmPasswordValidate, passwordValidate, phoneValidate, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  fullName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  isPasswordVisible: boolean;
  isConfirmPasswordVisible: boolean;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  countryNameSelected: string;
  phone: string;
  phoneNumberError: { invalid: boolean, require: boolean };
  fullNameError: { require: boolean };
  emailError: { invalid: boolean, require: boolean };
  roleError: { require: boolean };
  deptError: { require: boolean };
  setPassError: { require: boolean, invalid: boolean };
  confirmPassError: { require: boolean, match: boolean };
  isTnc: boolean;
  isModelOpen: boolean;
  isPhoneVerified: boolean;
  isEmailVerified: boolean;
  notification: { type: string, open: boolean, message: string };
  rolesData: any;
  departmentsData: any;
  yourRole: string;
  selectedDept: string;
  isLoading: boolean;
  isDisabled:boolean
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  verifyOtpApiCallId: string = "";
  getOtpApiCallId: string = "";
  getRolesCallId: string = "";
  getDeptsCallId: string = "";
  signUpVendorApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      fullName: "",
      email: "",
      password: "",
      reTypePassword: "",
      isPasswordVisible: false,
      isConfirmPasswordVisible: false,
      otpAuthToken: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      countryNameSelected: "",
      phone: "",
      phoneNumberError: { invalid: false, require: false },
      fullNameError: { require: false },
      emailError: { invalid: false, require: false },
      roleError: { require: false },
      deptError: { require: false },
      setPassError: { require: false, invalid: false },
      confirmPassError: { require: false, match: false },
      isTnc: false,
      isModelOpen: false,
      isPhoneVerified: false,
      isEmailVerified: false,
      notification: { type: "", open: false, message: "" },
      rolesData: [],
      departmentsData: [],
      yourRole: "",
      selectedDept: "",
      isLoading: false,
      isDisabled:false,
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getOtpApiCallId) {
          this.handleGetOtpApiResponse(responseJson);
        } else if (apiRequestCallId === this.verifyOtpApiCallId) {
          this.handleVerifyOtpApiResponse(responseJson);
        } else if (apiRequestCallId === this.getRolesCallId) {
          this.handleGetRolesApiResponse(responseJson);
        } else if (apiRequestCallId === this.getDeptsCallId) {
          this.handleGetDeptsApiResponse(responseJson);
        } else if (apiRequestCallId === this.signUpVendorApiCallId) {
          this.handleSignUpVendorApiResponse(responseJson);
        }
      }
    }

    this.getTokenAndCountryCodeSelected(message);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getRolesAndDepts();
  }

  getTokenAndCountryCodeSelected = (message: Message) => {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ otpAuthToken: otpAuthTkn });
        runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }

    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          countryCodeSelected:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode
        }, () => {
          if (this.state.countryCodeSelected === "91" && this.state.phone.length && this.state.phone.length !== 10) {
            this.setState({ phoneNumberError: { invalid: true, require: false } })
          }
        });
      }
    }
  }

  handleGetOtpApiResponse = (responseJson: { message: string, token: string }) => {
    if (responseJson.message) {
      this.setState({ notification: { open: true, type: 'error', message: responseJson.message } })
    } else if (responseJson.token) {
      this.setState({ otpAuthToken: responseJson.token });
      !this.state.isModelOpen && this.handleOpen();
    }
    this.setState({ isLoading: false });
  }

  handleVerifyOtpApiResponse = (responseJson: { errors: { token: string, pin: string }[], success: boolean, activated: boolean, email: string, meta: { message: string, token: string } }) => {
    if (responseJson.success) {
      this.setState({ isPhoneVerified: responseJson.activated, isEmailVerified: Boolean(responseJson.email), notification: { type: 'success', open: true, message: responseJson.meta.message }, otpAuthToken: responseJson.meta.token });
      this.handleClose();
    } else if (responseJson.errors) {
      this.setState({ notification: { open: true, type: 'error', message: responseJson.errors[0].token ? responseJson.errors[0].token : responseJson.errors[0].pin } });
    }
    this.setState({ isLoading: false });
  }

  handleGetRolesApiResponse = (responseJson: { data: any }) => {
    if (responseJson.data) {
      this.setState({ rolesData: responseJson.data });
    }
    this.setState({ isLoading: false });
  }

  handleGetDeptsApiResponse = (responseJson: { data: any }) => {
    if (responseJson.data) {
      this.setState({ departmentsData: responseJson.data });
    }
    this.setState({ isLoading: false });
  }

  handleSignUpVendorApiResponse = (responseJson: { data: any, errors: string, meta: { token: string } }) => {
    if (responseJson.data) {
      this.setState({ otpAuthToken: responseJson.meta.token, notification: { open: true, type: "success", message: "Account created successfully" } });
      this.props.navigation.navigate("LandingPage");
    } else if (responseJson.errors) {
      this.setState({ notification: { open: true, type: "error", message: responseJson.errors } })
    }
    this.setState({ isLoading: false });
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  getRolesAndDepts = async () => {
    this.getRolesCallId = await this.apiCall(configJSON.getApiMethod, configJSON.getRolesApiEndPoint);
    this.getDeptsCallId = await this.apiCall(configJSON.getApiMethod, configJSON.getDeptsApiEndPoint);
  }

  handlePhoneNumberInput = (event: any) => {
    const phoneNumber = event.currentTarget?.value;
    this.setState({ phone: phoneNumber });
    if (!phoneNumber) {
      this.setState({
        phoneNumberError: { invalid: false, require: true }
      });
      return false;
    } else {
      this.setState({
        phoneNumberError: { invalid: false, require: false }
      });
    }
    const validatePhoneNumber = phoneValidate("phone number", phoneNumber);
    if (!validatePhoneNumber.status) {
      this.setState({ phoneNumberError: { invalid: true, require: false } })
    } else {
      if (this.state.countryCodeSelected === "91" && phoneNumber.length !== 10) {
        this.setState({ phoneNumberError: { invalid: true, require: false } })
      } else {
        this.setState({ phoneNumberError: { invalid: false, require: false } })
      }
    }
  }

  handleChangeFullNameInput = (event: any) => {
    const fullName = event.target.value;
    this.setState({ fullName: fullName });
    if (!fullName) {
      this.setState({ fullNameError: { require: true } });
      return false;
    } else {
      this.setState({ fullNameError: { require: false } });
    }
    
  }

  handleChangeEmailInput = (event: any) => {
    const email = event.target.value;
    this.setState({ email: email });
    if (!email) {
      this.setState({ emailError: { invalid: false, require: true } });
      return false;
    } else {
      this.setState({ emailError: { invalid: false, require: false } });
    }
    const validEmail = isEmail("email", email);
    !validEmail.status
      ?
      this.setState({ emailError: { invalid: true, require: false } })
      : this.setState({ emailError: { invalid: false, require: false } });
  }

  validateConfirmPassword = () => {
    if (this.state.reTypePassword) {
      const validCPasswordOfPH = confirmPasswordValidate("confirm password", this.state.reTypePassword, "password", this.state.password);
      !validCPasswordOfPH.status
        ?
        this.setState({ confirmPassError: { require: false, match: true } })
        : this.setState({ confirmPassError: { require: false, match: false } });
    }
  }

  handleChangeSetPasswordInput = (event: any) => {
    const password = event.target.value;
    this.setState({ password: event.target.value }, () => {
      this.validateConfirmPassword();
    });

    if (!password) {
      this.setState({ setPassError: { invalid: false, require: true } });
      return false;
    } else {
      this.setState({ setPassError: { invalid: false, require: false } });
      const validPassword = passwordValidate("password", password);
      !validPassword.status
        ?
        this.setState({ setPassError: { invalid: true, require: false } })
        : this.setState({ setPassError: { invalid: false, require: false } });
    }
  }

  handleChangeConfirmPasswordInput = (event: any) => {
    const confirmPassword = event.target.value;
    this.setState({ reTypePassword: confirmPassword });
    if (!confirmPassword) {
      this.setState({ confirmPassError: { require: true, match: false } });
      return false;
    } else {
      this.setState({ confirmPassError: { require: false, match: false } });
      const validPassword = confirmPasswordValidate("confirm password", confirmPassword, "password", this.state.password);
      !validPassword.status
        ?
        this.setState({ confirmPassError: { require: false, match: true } })
        : this.setState({ confirmPassError: { require: false, match: false } });
    }
  }

  handleInputFocus = (labelName: string) => {
    const nameLabel = document.getElementById(labelName);
    if (nameLabel) {
      nameLabel.style.color = "#F5C42C";
    }
  };

  handleInputBlur = (labelName: string) => {
    const nameLabel = document.getElementById(labelName);
    if (nameLabel) {
      nameLabel.style.color = "#6D89AF";
    }
  };

  handleChange = (event: any) => {
    this.setState({ isTnc: event.target.checked });
  };

  handleClose = () => {
    this.setState({ isModelOpen: false });
  }

  handleOpen = () => {
    this.setState({ isModelOpen: true });
  }

  handleCloseNotification = () => {
    this.setState({ notification: { type: "", open: false, message: "" } });
  }

  apiCall = async (method: string, endPoint: string, httpBody?: any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.otpAuthToken,
    };
    this.setState({ isLoading: true });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    if (httpBody) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
    }
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  verifyOtpAPICall = async (httpBody: any) => {
    this.verifyOtpApiCallId = await this.apiCall(configJSON.postApiMethod, configJSON.verifyOtpApiEndPoint, httpBody);
  }

  handleGetOTPAPICall = async () => {
    if (!this.state.phone) {
      this.setState({ notification: { type: 'warning', open: true, message: "Please enter your phone number and verify to register your account." } });
      return false;
    } else if (!this.state.isTnc) {
      this.setState({ notification: { type: 'warning', open: true, message: "Please check terms and conditions." } });
      return false;
    } else if (this.state.phoneNumberError.invalid) {
      this.setState({ notification: { type: 'warning', open: true, message: "Please enter valid phone number" } });
      return false;
    }

    this.getOtpApiCall();
    this.setState({ isDisabled: true });
    setTimeout(() => {
      this.setState({ isDisabled: false });
    }, 30000);
  }

  handleSignUpNow = async () => {
    if (!this.state.fullName || !this.state.email || this.state.emailError.invalid || !this.state.yourRole || !this.state.selectedDept || !this.state.password || this.state.setPassError.invalid || !this.state.reTypePassword || this.state.confirmPassError.match) {
      this.setState({
        fullNameError: { require: !Boolean(this.state.fullName) },
        emailError: { ...this.state.emailError, require: !Boolean(this.state.email) },
        roleError: { require: !Boolean(this.state.yourRole) },
        deptError: { require: !Boolean(this.state.selectedDept) },
        setPassError: { ...this.state.setPassError, require: !Boolean(this.state.password) },
        confirmPassError: { ...this.state.confirmPassError, require: !Boolean(this.state.reTypePassword) },
      });
      return false;
    }

    const httpBody = {
      data: {
        account: {
          user_type: "vendor",
          full_phone_number: this.state.countryCodeSelected + this.state.phone,
          full_name: this.state.fullName,
          email: this.state.email,
          password: this.state.password,
          role_id: Number(this.state.yourRole),
          department_id: Number(this.state.selectedDept)
        }
      }
    }
    this.signUpVendorApiCallId = await this.apiCall(configJSON.postApiMethod, configJSON.signUpVendorApiEndPoint, httpBody);
  }

  getOtpApiCall = async () => {
    this.getOtpApiCallId = await this.apiCall(configJSON.postApiMethod, configJSON.getOtpApiEndPoint, {
      data: {
        account: {
          user_type: "vendor",
          verify_phone_or_email: this.state.isPhoneVerified ? "email" : "phone",
          country_name: this.state.countryNameSelected,
          [this.state.isPhoneVerified ? "email" : "full_phone_number"]: this.state.isPhoneVerified ? this.state.email : this.state.countryCodeSelected + this.state.phone,
        }
      }
    });
  }

  getCountryName = async (countryName: string) => {
    this.setState({ countryNameSelected: countryName });
  }

  handleSelectRoleValue = (event: any) => {
    const role = event.target.value;
    this.setState({ yourRole: role });
    if (!role) {
      this.setState({ roleError: { require: true } });
      return false;
    } else {
      this.setState({ roleError: { require: false } });
    }
  }

  handleSelectDeptValue = (event: any) => {
    const dept = event.target.value;
    this.setState({ selectedDept: dept });
    if (!dept) {
      this.setState({ deptError: { require: true } });
      return false;
    } else {
      this.setState({ deptError: { require: false } });
    }
  }

  handleVerifyEmail = () => {
    if (!this.state.email) {
      this.setState({ notification: { type: 'warning', open: true, message: "Please enter email to verify" } });
      return false;
    } else if (this.state.emailError.invalid || this.state.email.includes(" ")) {
      this.setState({ notification: { type: 'warning', open: true, message: "Please enter valid email to verify" } });
      return false;
    }

    this.getOtpApiCall();
    this.setState({ isDisabled: true });
    setTimeout(() => {
      this.setState({ isDisabled: false });
    }, 30000);
  }

  handleVisibilityChange = (type: string) => {
    if (type === "password") {
      this.setState({ isPasswordVisible: !this.state.isPasswordVisible });
    } else if (type === "confirmPassword") {
      this.setState({ isConfirmPasswordVisible: !this.state.isConfirmPasswordVisible });
    }
  }

  handleTncNavigation = () => {
    setStorageData("signupTnc", "true");
    window.open("/TermsAndCondition",'_blank')
  }
  // Customizable Area End
}
