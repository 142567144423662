import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
interface ITerms {
  id: string;
  title: string;
  description: string;
}// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isTermsSideBarOpen: boolean;
  isHeaderDropdownOpenVal: boolean;
  accountType: string;
  tncDescription: any;
  termsList: ITerms[];
  isLoading: boolean;
  signupTnc: string;
  isBackButtonVisible:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TermsAndConditionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getVendorTncCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isTermsSideBarOpen: true,
      isHeaderDropdownOpenVal: false,
      accountType: "",
      tncDescription: "",
      isBackButtonVisible:true,
      termsList: [
        {
          id: "1",
          title: "Lorem Ipsum is simply dummy text of the printing",
          description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.",
        },
        {
          id: "2",
          title: "Contrary to popular belief, Lorem Ipsum is not simply random text",
          description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
        },
        {
          id: "3",
          title: "There are many variations of passages of Lorem Ipsum available",
          description: `Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of 'de Finibus Bonorum et Malorum' (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, 'Lorem ipsum dolor sit amet..', comes from a line in section 1.10.32. 
          The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.`
        }
      ],
      isLoading: false,
      signupTnc: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const termsAndConditionsApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const termsAndConditionsResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (termsAndConditionsApiRequestCallId === this.getVendorTncCallId) {
        this.getTncDataHandleResponse(termsAndConditionsResponseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getTncDataHandleResponse = async (tncDataResponseJson: { data: any }) => {
    if (tncDataResponseJson.data) {
      this.setState({ tncDescription: tncDataResponseJson.data.description });
    }
    this.setState({ isLoading: false });
  }

  handlePageHomeNavigation = () => {
    if (this.state.accountType === "admin") {
      this.props.navigation.navigate("Dashboard");
    } else if (this.state.accountType === "vendor_account") {
      this.props.navigation.navigate("JobProfile");
    } else if (this.state.accountType === "production_house_account") {
      this.props.navigation.navigate("Projects");
    }
  };

  apiCall = async (tncApiMethod: string, tncApiEndPoint: string) => {
    const tncApiHeader = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const tncApiRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setState({ isLoading: true });
    tncApiRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      tncApiEndPoint
    );
    tncApiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(tncApiHeader)
    );
    tncApiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      tncApiMethod
    );
    runEngine.sendMessage(tncApiRequestMessage.id, tncApiRequestMessage);
    return tncApiRequestMessage.messageId;
  }

  async componentDidMount(): Promise<void> {
    const accountType = await getStorageData('account_type');
    this.getVendorTncCallId = await this.apiCall(configJSON.validationApiMethodType, configJSON.getTncApiEndPoint);
    this.setState({ accountType: accountType });
    window.scrollTo(0, 0);
    const signupTnc = await getStorageData("signupTnc");
    let isTermsSideBarOpen=await getStorageData("sidebarvalue");
    this.setState({isTermsSideBarOpen:JSON.parse(isTermsSideBarOpen), signupTnc: signupTnc})
  }

  handleHeaderDropdownVal = () => {
    this.setState({
      isHeaderDropdownOpenVal: !this.state.isHeaderDropdownOpenVal
    })
  };

  handleTermsSideBar = () => {
    this.setState({
      isTermsSideBarOpen: !this.state.isTermsSideBarOpen
    } , async() => {
      setStorageData("sidebarvalue",this.state.isTermsSideBarOpen.toString())
    });
  };

  async componentWillUnmount(): Promise<void> {
    removeStorageData("signupTnc");
  }
  // Customizable Area End
}
