Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.ACCOUNT_TYPE_EMAIL = "EmailAccount";
exports.ACCOUNT_TYPE_SOCIAL = "SocialAccount";
exports.ACCOUNT_TYPE_PHONE = "SmsAccount";

exports.contentTypeApiUpdateUser = "application/json";
exports.apiEndPointUpdateUser = "profile/profile";
exports.apiUpdateUserType = "PUT";

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.contenttypeApiValidateMobileNo = "application/json";
exports.endPointApiValidateMobileNo = "profile/change_phone_validation";
exports.callTypeApiValidateMobileNo = "POST";

exports.endPointApiGetUserProfile = "profile/profile";
exports.contentTypeApiGetUserProfile = "application/json";
exports.methodTypeApiGetUserProfile = "GET";

// Customizable Area Start
exports.placeHolderEmail = "Email";
exports.labelHeader =
  "This is your profile, Here you can see and update your personal information.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelArea = "Area";
exports.labelMobile = "Mobile";
exports.labelEmail = "Email";
exports.labelCurrentPassword = "Current password";
exports.labelNewPassword = "New Password";
exports.labelRePassword = "Re-Type Password";
exports.btnTextCancelPasswordChange = "Cancel";
exports.btnTextSaveChanges = "Save Changes";
exports.btnTextChangePassword = "Change Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Phone number is not valid.";
exports.errorTitle = "Error";
exports.errorBothPasswordsNotSame = "Passwords must match.";
exports.errorCurrentNewPasswordMatch = "New password cannot match current password.";
exports.errorCurrentPasswordNotValid = "Current password not valid.";
exports.errorNewPasswordNotValid = "New password not valid.";
exports.errorReTypePasswordNotValid = "Re-type password not valid.";
exports.hintCountryCode = "Select Country";
exports.errorBlankField = "can't be blank";
exports.errorEmailNotValid = "Email not valid.";
exports.profileUpdateEndPoint = "admin_profile";
exports.manageApiContentType = "application/json";
exports.UpdatePasswordEndPoint = "account_block/update_password?token";
exports.updatePasswordAPiMethod = "PUT";
exports.manageprofilemethodtype = "POST";
exports.exampleApiContentType = "application/json";
exports.deleteAPIMethod = "DELETE";

exports.getAllStudiosAPIEndPoint = "bx_block_customizableformworkflow3/studios";
exports.searchAllStudiosAPIEndPoint = "bx_block_customizableformworkflow3/studios/search?search=";
exports.getMyBookingsAPIEndPoint = "bx_block_customizableformworkflow3/studio_bookings/vendor_confirmed_bookings";
exports.getMyRequestsAPIEndPoint = "bx_block_customizableformworkflow3/studio_bookings/vendor_booking_requests";
exports.cancelBookingAPIEndPoint = "bx_block_customizableformworkflow3/studio_bookings/cancel_request/";
exports.leaveStudioAPIEndPoint = "bx_block_customizableformworkflow3/studio_bookings/leave_studio/";
exports.withdrawRequestAPIEndPoint = "bx_block_customizableformworkflow3/studio_bookings/";
exports.requestAgainAPIEndPoint = "bx_block_customizableformworkflow3/studio_bookings/request_again/";
exports.getStateAPIEndPoint = "https://api.countrystatecity.in/v1/countries/in/states"
exports.getCityAPIEndPoint="https://api.countrystatecity.in/v1/countries/in/states/"
exports.getPHListAPIEndPoint = "account_block/production_house_list";
exports.getFilteredDataAPIEndPoint = "bx_block_customizableformworkflow3/studios/studios_for_booking";
exports.divide = "/5";
exports.getStudioDetailsAPIEndPoint = "bx_block_customizableformworkflow3/studios/";
exports.getStudioDetailReviewDataAPIEndPoint = "bx_block_reviews/reviews/studio_rating_review/";
exports.bookStudioAPIEndPoint = "bx_block_customizableformworkflow3/studio_bookings";
exports.giveRatingAPIEndPoint = "bx_block_reviews/reviews";
exports.getVendorManageProfileReviewDataAPIEndPoint = "bx_block_profile/profile_rating?profile_id=";
exports.getVendorManageProfileDetailsAPIEndPoint = "profile/profiles/";
exports.getVendorManageProfileProjectsAPIEndPoint = "bx_block_profile/profile_projects/";
exports.vendorManageProfileUpdateProfileAPIEndPoint = "profile/profiles";
exports.vendorManageProfileAddGalleryPhotosAPIEndPoint = "bx_block_profile/add_photos_to_gallary/";
exports.vendorManageProfileSaveUpdatedProfileDetailsAPIEndPoint = "bx_block_profile/update_profile_rjs/";
exports.getVendorProfileRolesApiEndPoint = "bx_block_roles_permissions/roles/";
exports.getVendorProfileDeptsApiEndPoint = "bx_block_roles_permissions/departments/";
exports.getVendorProfileOtpApiEndPoint = "account_block/accounts/get_otp";
exports.VendorProfileVerifyOtpApiEndPoint = "account_block/accounts/verifiy_phone_and_email";
exports.getVendorProfileBankListApiEndPoint = "bx_block_categories/bank_names";
exports.deleteDocumentsApiEndPoint = "delete_documents/";
exports.resetPasswordApiEndPoint = "account_block/update_password";
exports.getallStudioListPHEndPoint="bx_block_customizableformworkflow3/studios/production_house_studios"
exports.getrequestStudioListPHEndPoint="bx_block_customizableformworkflow3/studio_bookings/ph_requested_studios"
exports.getongingStudioListPHEndPoint="bx_block_customizableformworkflow3/studio_bookings/ph_studios_ongoing_bookings"
exports.getupcomingStudioListPHEndPoint="bx_block_customizableformworkflow3/studio_bookings/ph_studios_upcoming_bookings"
exports.getPendingRequestApiEndPoint="bx_block_customizableformworkflow3/studio_bookings/studio_pending_requests?studio_id="
exports.acceptstudiorequestApiEndPoint="bx_block_customizableformworkflow3/studio_bookings/accept_request"
exports.rejectstudiorequestApiEndPoint="bx_block_customizableformworkflow3/studio_bookings/reject_request"
exports.getProjectListApiEndPoint="bx_block_profile/profile_projects"
exports.getPHManageProfileDetailsAPIEndPoint = "profile/profiles/";
exports.addReviewApiEndPoint="bx_block_reviews/reviews"
exports.httpPostMethod="POST"
exports.putAllStudioBookingApiEndPoint="bx_block_customizableformworkflow3/studios/studio_booking_permission/"
exports.cancelbookingApiEndPoint="bx_block_customizableformworkflow3/studio_bookings/cancel_request"
exports.deletestudioApiEndPoint="bx_block_customizableformworkflow3/studios"
exports.getVendorManageProfileStudioDataApiEndPoint ="bx_block_profile/profile_ph_studios/"
exports.methodtypestudio=""
// Customizable Area End

