import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { setStorageData, getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  otp1: string;
  otp2: string;
  otp3: string;
  otp4: string;
  emailid: string;
  otpValue : string;
  otpValue1:string;
  otpValue2:string;
  otpValue3:string;
  isLoading:boolean;
  otpScreenNotification: { type: string, open: boolean, message: string };
  // Customizable Area End
}

interface SS {
  // Customizable Area Start

  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class OtpScreenController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  verifyOtpApiCallId: any;
  requestEmailOtpCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.SessionResponseToken)
      // Customizable Area End
    ];
    this.state = {
      // Customizable Area Start
      otp1: '',
      otp2: '',
      otp3: '',
      otp4: '',
      emailid: '',
      otpValue : '',
      otpValue1:'',
      otpValue2:'',
      otpValue3:'',
      isLoading:false,
      otpScreenNotification: { type: "", open: false, message: "" },
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    const recoveremail = await getStorageData("recoveremail");
    this.setState({ emailid: recoveremail });
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (this.verifyOtpApiCallId === apiRequestCallId) {
        if (responseJson) {
          if (responseJson.errors) {
            this.setState({ otpScreenNotification: { open: true, type: "error", message: responseJson.errors[0].pin } });
          } else {
            setStorageData('token', responseJson.token)
            this.setState({ otpScreenNotification: { open: true, type: "success", message: "OTP verified successfully!"} });
            this.props.navigation.navigate("NewPassword")
          }
        }
      }
      if (this.requestEmailOtpCallId === apiRequestCallId) {
        if (responseJson) {
          if (responseJson.errors) {
            this.setState({ otpScreenNotification: { open: true, type: "error", message: responseJson.errors[0].email } });
          } else {
            setStorageData("token", responseJson.meta.token)
              this.setState({isLoading:false})
          }
        }
      }

    }
    // Customizable Area End
  }

  // Customizable Area Start
  move = (e: any, prev: string, current: string, next: string) => {
    let length = (document.getElementById(current) as HTMLInputElement)?.value?.length;
    let maxLength = document.getElementById(current)?.getAttribute("maxLength");

    let otp1 = (document.getElementById("text1") as HTMLInputElement)?.value || '';
    let otp2 = (document.getElementById("text2") as HTMLInputElement)?.value || '';
    let otp3 = (document.getElementById("text3") as HTMLInputElement)?.value || '';
    let otp4 = (document.getElementById("text4") as HTMLInputElement)?.value || '';

    if (String(length) == maxLength) {
      if (next !== "") {
        document.getElementById(next)?.focus()
      }
    }

    if (e?.key === "Backspace") {
      if (prev !== "") {
        document.getElementById(prev)?.focus()
      }
    }

    this.setState({
      otp1, otp2, otp3, otp4
    })
  };

  handleNavigation = () => {
    this.props.navigation.navigate("NewPassword");
  };

  handleOtpScreenCloseNotification = () => {
    this.setState({ otpScreenNotification: { type: "", open: false, message: "" } });
  }

  verifyOtp = async () => {
    let { otp1, otp2, otp3, otp4 } = this.state;
    if (!otp1 || !otp2 || !otp3 || !otp4) {
      this.setState({ otpScreenNotification: { open: true, type: "warning", message: "Please enter the OTP"} });
      return false;
    }
    const authenticationToken = await getStorageData('token', false)
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
      "token": authenticationToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.verifyOtpApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getOtpEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    let attr = {
      pin: `${otp1}${otp2}${otp3}${otp4}`
    }

    const data = {
      verify_phone_or_email: configJSON.firstInputAutoCompleteType,
      attributes: attr,
    };

    const httpBody = {
      data: data
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  goToOtpAfterEmailValidation() {
    this.setState({isLoading:true})
     this.setState({otpValue:'',otpValue1:'',otpValue2:'',otpValue3:""})
    this.componentDidMount();
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.requestEmailOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgotEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    let attr = {
      email: this.state.emailid
    }

    const data = {
      verify_phone_or_email: configJSON.firstInputAutoCompleteType,
      attributes: attr,
    };

    const httpBody = {
      data: data
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  validateOtp = (e:any) => {
    const regex = /\b[0-9]\b/;
    if(regex.test(e.target.value) || e.target.value == ""){
       
      // this.setState({otpValue:e.target.value})
      this.setState({otpValue:e.target.value})
       
    }
 }
 validateOtp1 = (e:any) => {
  const regex = /\b[0-9]\b/;
  if(regex.test(e.target.value) || e.target.value == ""){
     
    // this.setState({otpValue:e.target.value})
    this.setState({otpValue1:e.target.value})
     
  }
}
validateOtp2 = (e:any) => {
  const regex = /\b[0-9]\b/;
  if(regex.test(e.target.value) || e.target.value == ""){
     
    // this.setState({otpValue:e.target.value})
    this.setState({otpValue2:e.target.value})
     
  }
}
validateOtp3 = (e:any) => {
  const regex = /\b[0-9]\b/;
  if(regex.test(e.target.value) || e.target.value == ""){
     
    // this.setState({otpValue:e.target.value})
    this.setState({otpValue3:e.target.value})
     
  }
}

  // Customizable Area End
}