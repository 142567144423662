import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  styled,
  Grid,
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';

import { CustomButton } from '../../blocks/email-account-login/src/CustomButton';
import { reclaiminvoice } from './assets';
import { getStorageData, removeStorageData } from '../../framework/src/Utilities';
const images = require("./assets");

const StyledModal = styled(Modal)({
  position: 'fixed',
  zIndex: 1300,
  inset: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const style = {
  width: 375,
  borderRadius: '20px',
  backgroundColor: 'white',
  boxShadow: 'none',
  minHeight: 274,
};

const CustomButtonBox = styled(CustomButton)({
  '& .MuiButton-label': {
    fontWeight: 600,
    fontSize: 12,
    letterSpacing: '0.34285715px',
    lineHeight: '14px',
  }
});

interface MyProps {
  data: any;
  open: any;
  handleClose: any
  reclaimInvoiceAPICall: any;
  viewStatusUpdateDetails: any;
}

export default function ReclaimInvoiceModel(props: MyProps) {
  const { data, handleClose, open, reclaimInvoiceAPICall, viewStatusUpdateDetails } = props;

  const classes = useStyles();
  const [modelType, SetModelType] = useState("");

  useEffect(() => {
    async function fetchData() {
      const successReclaim = await getStorageData("reclaimModelType");
      SetModelType(successReclaim);
    }
    fetchData();
  }, []);

  const reclaimInvoiceNow = () => {
    handleClose();
    reclaimInvoiceAPICall();
  }

  const viewStatus = () => {
    removeStorageData("reclaimModelType");
    handleClose();
    viewStatusUpdateDetails();
  }

  return (

    <Box sx={style}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Box style={{ flexDirection: "column", alignItems: "center", padding: "24px 16px 21px", display: "flex", }}>
          <Box style={{ width: 64, height: 64, background: "rgba(245,196,44,0.12)", borderRadius: "16px", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <img style={{ height: "36px", width: "36px" }} src={modelType === "successReclaim" ? images.RequestSendIcon : reclaiminvoice} alt="Request Icon" />
          </Box>
          <Typography style={{ marginTop: "14px", color: "#17181d", fontFamily: "Urbanist", fontSize: "20px", fontWeight: 700, lineHeight: "24px", letterSpacing: "0.2px", textAlign: "center" }}>{modelType === "successReclaim" ? "Reclaim Successfully" : "Reclaim Invoice?"}</Typography>
          <Typography style={{ color: "#6d89af", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 500, lineHeight: "18px", letterSpacing: "0.21538462px", textAlign: "center", width: "286px", marginTop: "11px" }}>
            {modelType === "successReclaim" ? "Your request for reclaiming of special pay invoice has been send successfully." :
              `This invoice has been rejected by the pipleine
            employees. Are you sure to want to reclaim
            this invoice one more time?`}
          </Typography>
        </Box>
      </Grid>
      <Grid container style={{ padding: "16px", display: 'flexe', justifyContent: 'space-between' }}>
        {modelType === "successReclaim" ? <button className={classes.viewStatusBtn} onClick={viewStatus} >
          View Status
        </button> : <>
          <button
            className={classes.button3} onClick={handleClose} >
            Not Now
          </button>
          <button
            className={classes.submitButton}
            onClick={reclaimInvoiceNow}
          >
            Reclaim Now
          </button>
        </>}
      </Grid>
    </Box>
  );
}
const useStyles = makeStyles({
  button3: {
    cursor: "pointer",
    color: "black",
    backgroundColor: "#ffffff",
    textTransform: "none",
    width: "165px",
    borderRadius: "14px",
    //position: "absolute",
    bottom: "16px",
    height: "48px",
    fontWeight: 600,
    fontSize: "14px",
    paddingTop: "10px",
    paddingBottom: "14px",
    marginBottom: "5px",
    fontFamily: "urbanist",
    border: '1px solid #17181d'



  },
  viewStatusBtn: {
    cursor: "pointer",
    color: "#ffffff",
    backgroundColor: "#17181D",
    textTransform: "none",
    width: "100%",
    border: "none",
    borderRadius: "14px",
    bottom: "16px",
    height: "48px",
    fontWeight: 600,
    fontSize: "14px",
    paddingTop: "10px",
    paddingBottom: "14px",
    marginBottom: "5px",
    fontFamily: "urbanist",
  },
  submitButton: {
    cursor: "pointer",
    color: "#ffffff",
    backgroundColor: "#17181D",
    textTransform: "none",
    width: "165px",
    border: "none",
    borderRadius: "14px",
    //position: "absolute",
    bottom: "16px",
    height: "48px",
    fontWeight: 600,
    fontSize: "14px",
    paddingTop: "10px",
    paddingBottom: "14px",
    marginBottom: "5px",
    fontFamily: "urbanist",
  },
})