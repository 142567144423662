import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { IBreadCrum } from "./BreadCrumController";
interface ProductionHouse {
  user_type: string;
  full_name: null;
  full_phone_number: string;
  country_code: number;
  phone_number: number;
  email: string;
  role_id: null;
  activated: boolean;
  blocked: boolean;
  production_house_name: string;
  production_house_address: string;
  rating_and_review: number;
  join_date: string;
  projects_count: number;
  studios_count: number;
  gst_number: string,
  tan_number: string,
  aadhar_card_number: string,
  pan_card_number: string,
  profile: { data: { attributes: { profile_image: string; documents: any[]; profile_bio: { data: { attributes: { about_business: string } } } } } };
}
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isSiderBarOpenValue: boolean;
  isDropDwnOpen: boolean;
  id: string;
  token: string;
  productionHouseDetail: ProductionHouse;
  allProjects: any;
  upcomingProjects: any;
  ongoingProjects: any;
  completedProjects: any;
  studios: any;
  rating: any;
  activeTab: string;
  bList: IBreadCrum[],
  documentList: any[],
  isLoading: boolean,
  ratingValue: number[],
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ProductionHouseDetailController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getproductionHouseDetailApiCalledId: string = "";
  putProductionHouseDataApiCalledId: string = "";
  getProductionHouseAllProjectsApiCalledId: string = "";
  getProductionHouseUpcomingProjectsApiCalledId: string = "";
  getProductionHouseOngoingProjectsApiCalledId: string = "";
  getProductionHouseCompletedProjectsApiCalledId: string = "";
  getProductionHouseStudiosApiCalledId: string = "";
  getProductionHouseRatingApiCalledId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isSiderBarOpenValue: true,
      isDropDwnOpen: false,
      id: "",
      token: "",
      productionHouseDetail: {
        user_type: "",
        full_name: null,
        full_phone_number: "",
        country_code: 0,
        phone_number: 0,
        email: "",
        role_id: null,
        activated: false,
        blocked: false,
        production_house_name: "",
        production_house_address: "",
        rating_and_review: 0,
        join_date: "",
        projects_count: 0,
        studios_count: 0,
        gst_number: "",
        tan_number: "",
        aadhar_card_number: "",
        pan_card_number: "",
        profile: { data: { attributes: { profile_image: "", documents: [], profile_bio: { data: { attributes: { about_business: "" } } } } } }
      },
      allProjects: [],
      upcomingProjects: [],
      ongoingProjects: [],
      completedProjects: [],
      studios: [],
      rating: {},
      activeTab: "overview",
      bList: [
        {
          text: 'Home',
          href: 'Dashboard',
          clickable: true,
          page: 'Dashboard'
        },
        {
          text: 'Production Houses',
          href: '/',
          clickable: false,
          page: ''
        },
        {
          text: 'Production House Details',
          href: '/',
          clickable: false,
          page: ''
        }
      ],
      documentList: ["", "", ""],
      isLoading: false,
      ratingValue: [50, 10, 20, 90]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    let token = await getStorageData("token");
    let id = await getStorageData("id");
    this.setState({ token: token, id: id }, async () => {
      this.getproductionHouseDetailApiCalledId = await this.apiCall(configJSON.GET_Method, configJSON.getproductionHouseDetailApiEndPoint + this.state.id);
      this.getProductionHouseAllProjectsApiCalledId = await this.apiCall(configJSON.GET_Method, configJSON.getProductionHouseAllProjects + this.state.id);
      this.getProductionHouseUpcomingProjectsApiCalledId = await this.apiCall(configJSON.GET_Method, configJSON.getProductionHouseUpcomingProjects + this.state.id);
      this.getProductionHouseOngoingProjectsApiCalledId = await this.apiCall(configJSON.GET_Method, configJSON.getProductionHouseOngoingProjects + this.state.id);
      this.getProductionHouseCompletedProjectsApiCalledId = await this.apiCall(configJSON.GET_Method, configJSON.getProductionHouseCompletedProjects + this.state.id);
      this.getProductionHouseStudiosApiCalledId = await this.apiCall(configJSON.GET_Method, configJSON.getProductionHouseStudios + this.state.id);
      this.getProductionHouseRatingApiCalledId = await this.apiCall(configJSON.GET_Method, configJSON.getProductionHouseRating + this.state.id);
    });
    window.scrollTo(0, 0);
    let isSiderBarOpenValue=await getStorageData("sidebarvalue");
    this.setState({isSiderBarOpenValue:JSON.parse(isSiderBarOpenValue)})
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.getproductionHouseDetailApiCalledId === apiRequestCallId) {
        this.getProductionHouseDetailHandleResponse(responseJson);
      } else if (this.putProductionHouseDataApiCalledId === apiRequestCallId) {
        this.putProductionHouseDataHandleResponse(responseJson);
      } else if (this.getProductionHouseAllProjectsApiCalledId === apiRequestCallId) {
        this.getProductionHouseDetailTabsDataHandleResponse(responseJson, "allProjects");
      } else if (this.getProductionHouseUpcomingProjectsApiCalledId === apiRequestCallId) {
        this.getProductionHouseDetailTabsDataHandleResponse(responseJson, "upcomingProjects");
      } else if (this.getProductionHouseOngoingProjectsApiCalledId === apiRequestCallId) {
        this.getProductionHouseDetailTabsDataHandleResponse(responseJson, "ongoingProjects");
      } else if (this.getProductionHouseCompletedProjectsApiCalledId === apiRequestCallId) {
        this.getProductionHouseDetailTabsDataHandleResponse(responseJson, "completedProjects");
      } else if (this.getProductionHouseStudiosApiCalledId === apiRequestCallId) {
        this.getProductionHouseDetailTabsDataHandleResponse(responseJson, "studios");
      } else if (this.getProductionHouseRatingApiCalledId === apiRequestCallId) {
        this.getProductionHouseDetailRatingTabDataHandleResponse(responseJson);
      }
    }
  }
  // Customizable Area End

  // Customizable Area Start
  getProductionHouseDetailHandleResponse = (responseJson: { data: any }) => {
    if (responseJson.data) {
      this.setState({
        productionHouseDetail: { ...responseJson.data.attributes },
        isLoading: false,
      });
    }
  }

  putProductionHouseDataHandleResponse = (responseJson: { data: any }) => {
    if (responseJson.data) {
      this.setState({
        productionHouseDetail: { ...this.state.productionHouseDetail, blocked: !this.state.productionHouseDetail.blocked },
        isLoading: false,
      })
    }
    else {
      this.setState({ isLoading: false });
      this.showAlert("Error", "Error");
    }
  }

  getProductionHouseDetailTabsDataHandleResponse = (responseJson: { data: any }, stateName: string) => {
    if (responseJson.data) {
      if (stateName === "allProjects")
        this.setState({ allProjects: responseJson.data, isLoading: false });
      else if (stateName === "upcomingProjects")
        this.setState({ upcomingProjects: responseJson.data, isLoading: false });
      else if (stateName === "ongoingProjects")
        this.setState({ ongoingProjects: responseJson.data, isLoading: false });
      else if (stateName === "completedProjects")
        this.setState({ completedProjects: responseJson.data, isLoading: false });
      else if (stateName === "studios")
        this.setState({ studios: responseJson.data, isLoading: false });
    }
    else {
      this.setState({ isLoading: false });
      this.showAlert("Error", "Error");
    }
  }

  getProductionHouseDetailRatingTabDataHandleResponse = (responseJson: any) => {
    if (responseJson.error) {
      this.showAlert("Error", `For rating and review ${responseJson.error}`);
      this.setState({ isLoading: false });
    } else {
      this.setState({ rating: responseJson, isLoading: false });
    }
  }

  handleSideDropdownOpenVal = () => {
    this.setState({
      isSiderBarOpenValue: !this.state.isSiderBarOpenValue
    },
    async() => {
      localStorage.setItem("sidebarvalue",this.state.isSiderBarOpenValue.toString())
      
    }
    )
  };

  handleDropdownValue = () => {
    this.setState({
      isDropDwnOpen: !this.state.isDropDwnOpen
    })
  };

  handleChangeTabValue = (tabs: string) => {
    this.setState({
      activeTab: tabs
    })
  };

  navigateToAllProductionHouse = () => {
    this.props.navigation.navigate("AllProductionHouses")
  }

  handleProductionHouseBadgeVisibility = async (e: any, id: any, blockStatus: boolean, type?: string) => {
    e.stopPropagation();
    const token = await getStorageData("token", false);

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const attrs = {
      blocked: !blockStatus
    };
    this.putProductionHouseDataApiCalledId = requestMessage.messageId;

    const data = {
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.putApiForAllProductionHousesEndPoint + id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.put_method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  apiCall = async (method: string, endPoint: string) => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": this.state.token,
    };
    this.setState({ isLoading: true });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  // Customizable Area End
}