import React from "react";

import {
  Button,
  Typography,
  // Customizable Area Start
  Grid, Box, Input,
  Card, CardContent, CardActions,
  MenuItem, Menu,
  Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { backIcon, filterIcon } from "./assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 800,
    },
  },
});
// Customizable Area End

import ProjectPortfolioController, {
  Props,
  configJSON,
} from "./ProjectPortfolioController";
import Loader from "../../../components/src/Loader";
import { IProject } from "./types";

export default class ProjectPortfolio extends ProjectPortfolioController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  displayProjects = (project: IProject) => {
    return (
      <Card style={{ boxShadow: '1px 2px 3px 2px silver' }}>
        <CardContent data-test-id={`projectDetail${project.projectId}`} onClick={() => this.naviagteToViewPage(project.projectId)}
        >
          <Typography gutterBottom variant="h6" component="div">
            {configJSON.labelProjectNameText}: {project.project_name}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {configJSON.labelStartDateNameText}: {project.start_date}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {configJSON.labelEndDateNameText}: {project.end_date}
          </Typography>
        </CardContent>
        {(this.state.filterValue === "My Projects" || project.isMyProject) && <CardActions style={{ justifyContent: 'end' }}>
          <Button data-test-id={`editButton${project.projectId}`} style={webStyle.editButton} size="small" onClick={() => this.naviagteToEditPage(project.projectId)}>{configJSON.labelEditBtnText}</Button>
          <Button data-test-id={`deleteButton${project.projectId}`} style={webStyle.editButton} size="small" onClick={() => this.handleClickDeleteProject(project.projectId)}>{configJSON.labelDeleteBtnText}</Button>
        </CardActions>}
      </Card>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Loader loading={this.state.loading} />
        <div>
          <Grid container style={webStyle.rightContent}>
            <Grid item xs={12} style={webStyle.headingCont}>
              <div style={webStyle.headingLeft}>
                <Button
                  id="back-positioned-button"
                  data-test-id="btnBack"
                  onClick={() => this.goBack()}
                  style={webStyle.backToLogin}
                >
                  <img
                    src={backIcon}
                    alt=""
                    style={webStyle.filterIcon}
                  />
                  <span >
                    Back to Login
                  </span>
                </Button>
                <Typography variant="h5" style={webStyle.titleText}>
                  {`${configJSON.labelTitleText} (${this.state.filterValue})`}
                </Typography>
                <Button
                  id="demo-positioned-button"
                  data-test-id="btnFilter"
                  // aria-controls={this.state.isFilterMenuOpen ? 'demo-positioned-menu' : undefined}
                  aria-haspopup="true"
                  // aria-expanded={this.state.isFilterMenuOpen ? 'true' : undefined}
                  onClick={(event) => this.handleOpenDialog('filter', event)}
                >
                  <img
                    src={filterIcon}
                    alt=""
                    style={webStyle.filterIcon}
                  />
                </Button>
                <Menu
                  id="demo-positioned-menu"
                  data-test-id="filterMenu"
                  aria-labelledby="demo-positioned-button"
                  anchorEl={this.state.isFilterMenuOpen}
                  open={Boolean(this.state.isFilterMenuOpen)}
                  onClose={() => this.handleCloseDialog('filter')}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <MenuItem data-test-id="menu1" onClick={() => this.handleSelectFilterValue('all')}>{configJSON.labelAllProjectText}</MenuItem>
                  <MenuItem data-test-id="menu2" onClick={() => this.handleSelectFilterValue('my')}>{configJSON.labelMyProjectText}</MenuItem>
                </Menu>
              </div>
            </Grid>
          </Grid>
          <Grid style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
            <Box sx={webStyle.inputStyle}>
              <Input
                required
                data-test-id="searchInputText"
                type="text"
                name="searchInputText"
                placeholder={configJSON.labelSearchInputText}
                disableUnderline={true}
                value={this.state.searchInputText}
                onChange={this.handleSearchInputChange}
                onKeyDown={this.handleSearchInputKeyDown}
              />
            </Box>
            <Box
              data-test-id="btnAddProject"
              onClick={this.naviagteToCreatePage}
              sx={webStyle.buttonStyle}
            >
              <Button color={"primary"} >{configJSON.labelAddProjectText}</Button>
            </Box>
          </Grid>
          <Grid data-test-id="projectList" container style={webStyle.projectContainer}>
            {this.state.projects.map((project) => {
              return <Grid
                item
                key={project.projectId}
                md={4}
                sm={6}
                xs={12}
                style={webStyle.eachProjectDetails}
              >
                {this.displayProjects(project)}
              </Grid>
            })}
          </Grid>
        </div>

        <Dialog
          open={this.state.isDialogOpen}
          data-test-id="closeDialog"
          onClose={() => this.handleCloseDialog('dialog')}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {configJSON.deleteConfirmDialogTitle}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {configJSON.deleteConfirmDialogDetailText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button data-test-id="deleteDialogBtn" autoFocus onClick={this.deleteProject}>
              {configJSON.labelConfirmBtnText}
            </Button>
            <Button data-test-id="closeDialogBtn" onClick={() => this.handleCloseDialog('dialog')} autoFocus>
              {configJSON.labelRejectBtnBtnText}
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "60%",
    height: "45px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "25%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
    textAlign: 'center',
  },
  rightContent: {
    width: "100%",
    borderRadius: 12,
  },
  headingCont: {
    width: "90%",
    margin: "auto",
    padding: "20px 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  headingLeft: {
    width: "100%",
    margin: "auto",
    display: "flex",
    alignItems: "center",
  },
  titleText: {
    fontSize: 22,
    fontFamily: 'sofia_pro_regular',
    color: "#1e1f20",
  },
  filterIcon: {
    width: 40,
    height: 40,
    cursor: "pointer",
  },
  backToLogin: {
    background: "rgb(98, 0, 238)",
    marginLeft: 15,
    cursor: "pointer",
    color: "#fff",
    marginRight: "20px",
  },
  projectContainer: {
    margin: "10px auto",
  },
  eachProjectDetails: {
    marginTop: 20,
    padding: 10,
  },
  editButton: {
    background: "rgb(98, 0, 238)",
    display: "flex",
    padding: "5px",
    fontSize: "14px",
    alignItems: "center",
    fontFamily: 'sofia_pro_regular',
    marginRight: "5px",
    borderRadius: "4px",
    color: "white",
    border: "none",
  },
};
// Customizable Area End
