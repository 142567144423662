import React from 'react';
import { Box, CardMedia } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { reviewimg } from "./assets";
import StarOutlinedIcon from '@material-ui/icons/StarOutlined';

interface MyProps {
    data: any;
    key:number;
  }
const useStyles = makeStyles({
    Review1: {
        //width: '378px',
        border: '2px solid #E1E6EC',
        borderRadius: '14px',
        marginBottom: '10px',
        marginRight: "15px",
        "@media (min-width: 600px) and (max-width: 1440px)": {
            width: "unset"
        },
        '@media(max-width: 600px)': {
            width: '95%',
            margin: '0 auto',
            marginBottom: '10px'
        },
    },

    starView: {
        padding: "0px 8px",
        background: "linear-gradient(225deg, #58A2FF 0%, #1777E2 100%)",
        borderRadius: "12px",
        display: "flex",
        alignItems: "center",
        justifyContent: 'space-evenly',
        height:"22px",
        marginTop:"12px"

    },
    starrate: {
        color: "white"
    },

    Reviewicon: {
        height: '36px',
        width: '36px',
        marginInline: '10px',
        marginTop: '5px',
        '& .MuiCardMedia-media': {
            borderRadius:"14px"
        }
    },
    reviewbirth: {
        fontSize: '11px',
        paddingTop: '5px',
        lineHeight: '13px',
        color: '#6D89AF',
        fontWeight: 500
    },
    reviewname: {
    display:"flex",
    gridColumnGap:"115px",
       '@media(max-width: 600px)': {
        gridColumnGap:"unset",
        justifyContent: 'space-between',
        width: '78%'
       },
    },
    namereview: {
        fontWeight: 'bold',
        paddingTop: '8px',
        fontSize: '14px',
        color: '#1718D',
        lineHeight: '17px'
    },
    reviewabout: {
        fontSize: '11px',
        fontWeight: 400,
        paddingTop: '5px',
        paddingBottom: '5px',
        marginInline: '10px',
        lineHeight: '16px',
        color: '17181D'
    },
    ReviewInformation:
    {
        display: "flex",
        marginBottom:"10px"
    }
    ,
    reviewnameandstar: {
        display: "flex",
       flexDirection:"column",
    

    }


});

export default function ReviewerComponent(props: MyProps){
    const classes = useStyles();
   const {data,key}=props;
    const reviewsData = [
        {
            name: 'Sanjay Saxena',
            role: 'Cameraman',
            date: '12 Jun. 2022',
            about: 'Consectetur adipisicing elit. sed do eiusmod tempor incididunt ut labore et dolore magna alique.Ut enim ad minim veniam,quis nostrud',
        },
        // Add more review objects as needed
    ];

    return (
      
        <div>
              {console.log("xxxxxxxxxxxxssss",data)}
            {/* {reviewsData.map((review, index) => ( */}
                <Box className={classes.Review1} >
                    <Box className={classes.ReviewInformation}>
                        <Box className={classes.Reviewicon}>
                            <CardMedia
                                component="img"
                                alt="Image"
                                image={data?.attributes?.account?.image}
                            />
                        </Box>
                        <Box className={classes.reviewname}>
                            <Box className={classes.reviewnameandstar}>
                                <Box className={classes.namereview}>
                                {data?.attributes?.account?.full_name}
                                </Box>
                                <Box className={classes.reviewbirth}>
                                    {`${data?.attributes?.role?.name} | ${data?.attributes?.created_at}`}
                                </Box>
                            </Box>

                            <Box className={classes.starView}>
                                <StarOutlinedIcon className="start_color" />
                                <span className={classes.starrate}>{data?.attributes?.given_star}</span>
                            </Box>

                        </Box>
                    </Box>
                    <Box className={classes.reviewabout}>
                       {data?.attributes?.comment}
                    </Box>
                </Box>
            {/* ))} */}
        </div>
    );
};


