import { BlockComponent } from "../../../framework/src/BlockComponent";


// Customizable Area Start
import React from "react"
type ActiveEmployeeId = number | string | undefined;


import { IState,State } from "country-state-city";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    type: string;
    overviewData: any;
    // Customizable Area End
}

  export interface ICity {
    name: string
    countryCode: string
    stateCode: string
    latitude: string | null | undefined
    longitude: string
    }
  
  export interface Timezone {
  zoneName: string
  gmtOffset: number
  gmtOffsetName: string
  abbreviation: string
  tzName: string
  }

 export  interface Employee {
    id: number;
    image: string;
    name: string;
    department: string;
    level: string;
    designation: string;
    phoneNumber: string;
    role: string;
    permissions?:any
}
interface Department {
  value: string;
  name: string;
  error: boolean;
  errMessage: string;
}
export interface SelectDepartment{
  value: { value: string; label: string };
  error: boolean;
  errMessage: string;
}
export interface Permission {
  id: string;
  type: string;
  attributes: {
      id: number;
      name: string;
      slug: string;
      status: boolean;
  };
}
export interface IndianState {
  name: string;
  isoCode: string;
  countryCode: string;
  latitude: string;
  longitude: string;
}
export interface ProjectDepartment {
  id: string;
  type: string;
  attributes: {
      name: string;
      pipeline_department: boolean;
      level: any; 
      project_id: number;
  };
}
export interface ProjectDefaultImage {
  id: string;
  type: string;
  attributes: {
      name: string;
      description: string;
      image: string;
  };
}

interface S {
    // Customizable Area Start
    projectId:any;
    isSideBarOpen:boolean;
    isDropdownOpen:boolean;
    activeStepIndex:number;
    stepOneInputs:any;
    openUploadProjectImageModal:boolean;
    addNewDepartmentModal:boolean;
    addNewMemberModal:boolean;
    addNewMemberModaltab:number;
    projectImage:any;
    imagePreviewUrlDepartment:string; 
    opendefaultImageModal:boolean;
    
    invoiceFile:any;
    invoicePreviewUrlDepartment:string;
    
  
    addNewMemberDetailsDepartment:any;
    selectNewDepartmentModalDepartment : boolean,
    
    
    activeEmployeeId:ActiveEmployeeId,
    
    newDepartmentName:Department,
    selectDepartMent: SelectDepartment,
    permissionAccessDepartment: Permission[],
    stateList: IState[];
    cityList: any[];
    departmentList:ProjectDepartment[];
    searchQuery:string;
    defaultImagesListDepartment:ProjectDefaultImage[];
   
    activeEmployeeList:any[];
    rolesList:any[];
    addNewMemberdepartmentList:any[];
    countryCodeSelected:string;
    status:string;
    ProjectName:string;
    checkedDepartments:any;
    dummydepartments:any;
    emailLoginNotification: { type: string, open: boolean, message: string, route?: string };
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class AllDepartmentListPHController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    videoRef: React.RefObject<HTMLVideoElement>;
    canvasRef: React.RefObject<HTMLVideoElement>;
    streamRef: MediaStream | null;
    createProjectId:string="";
    allDepartmentsID:string="";
    createDepartmentIdDepartment:string="";
    updateDepartmentsId:string="";
    defaultImagesID:string="";
    allEmployeeListID:string="";
    vendorListID:string="";
    rolesListID:string="";
    departmentListID:string="";
    permissionsListID:string="";
    updatedepartmentcallid:string=""
    savePermissionID:string="";
    publishProjectID:string="";
    searchDebounceTimer:any=null
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
          getName(MessageEnum.RestAPIResponceMessage),
          getName(MessageEnum.NavigationPayLoadMessage),
          getName(MessageEnum.ReciveUserCredentials),
          getName(MessageEnum.CountryCodeMessage)
      ];
        this.receive = this.receive.bind(this);

        this.state = {
            // Customizable Area Start
            projectId:null,
           isSideBarOpen:true,
           isDropdownOpen:false,
           activeStepIndex:0,
           stepOneInputs:{
            projectName:"",
            projectStartDate:"",
            projectEndDate:"",
            selectedState:{name:"Select State",value:"Select State",countryCode:"",isoCode:""},
            selectedCity:{name:"Select City",value:"city"},
            projectDescription:"",
            projectAddress:""
           },
           openUploadProjectImageModal:false,
           addNewDepartmentModal:false,
           addNewMemberModal:false,
           addNewMemberModaltab:1,
           projectImage:"",
           imagePreviewUrlDepartment:"",
           opendefaultImageModal:false,
           defaultImagesListDepartment:[],
           
         
           invoiceFile:{},
           invoicePreviewUrlDepartment:"",
          
        
          addNewMemberDetailsDepartment:{
            fullName: { value: '', error: false, errorMessage: '' },
            phoneNumber: { value: '', error: false, errorMessage: '' },
            selectedDept: { value: { label: 'Select Dept', value: 'Select Dept' }, error: false, errorMessage: '' },
            selectedRole: { value: { label: 'Select Role', value: 'Select Role' }, error: false, errorMessage: '' },
          },
          selectNewDepartmentModalDepartment : false,
          
        
          activeEmployeeId:-1,
          
       
          newDepartmentName:{
            value:"",
            name:"departmentName",
            error:false,
            errMessage:""
          },
          selectDepartMent:{
            value:{value:"Select Dept",label:"Select Dept"},
            error:false,
            errMessage:""
          },
          permissionAccessDepartment:[],
          stateList:[],
          cityList:[],
          departmentList:[],
          searchQuery:"",
          activeEmployeeList:[],
          rolesList:[],
          addNewMemberdepartmentList:[],
          countryCodeSelected:'',
          status:"",
          ProjectName:"",
          checkedDepartments:[],
          dummydepartments:[],
          emailLoginNotification: { type: "", open: false, message: "" },
            // Customizable Area End
        };
        // Customizable Area Start
      this.videoRef = React.createRef();
      this.canvasRef = React.createRef();
      this.streamRef = null;

    runEngine.attachBuildingBlock(this, this.subScribedMessages);
        // Customizable Area End
    }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallIdDepartment = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJsonDepartment = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
       if (apiRequestCallIdDepartment === this.allDepartmentsID) {
        console.log("response......",responseJsonDepartment)
        this.setState({ departmentList: responseJsonDepartment.data })
      } else if (apiRequestCallIdDepartment === this.createDepartmentIdDepartment) {
        this.getAllDepartmentList()
      } else if (apiRequestCallIdDepartment === this.defaultImagesID) {
        this.setState({ defaultImagesListDepartment: responseJsonDepartment.data })
      }
    
      else if (apiRequestCallIdDepartment === this.rolesListID) {
        const prepareRolesList = responseJsonDepartment.data.map((ele: any) => ({ value: ele.name, label: ele.name, id: ele.id }))
        this.setState({ rolesList: prepareRolesList })
      }
      else if(apiRequestCallIdDepartment===this.updatedepartmentcallid){
        if(responseJsonDepartment.data)
        {
        console.log("response....",responseJsonDepartment)
        this.getDepartmentListPH()
        }
        else{
          this.setState({ emailLoginNotification: { open: true, type: "error", message: responseJsonDepartment.errors.message } });
        }
      }
      else if (apiRequestCallIdDepartment === this.departmentListID) {
        const prepareDepartmentList = responseJsonDepartment.data.map((ele: any) => ({ id: ele.id, value: ele.attributes.name, label: ele.attributes.name }))
        this.setState({ addNewMemberdepartmentList: prepareDepartmentList })
      }
      else if (apiRequestCallIdDepartment === this.permissionsListID) {
        this.setState({ permissionAccessDepartment: responseJsonDepartment.data })

      } else if (apiRequestCallIdDepartment === this.savePermissionID) {
        this.setState({ invoicePreviewUrlDepartment:"", addNewMemberDetailsDepartment:{
          fullName: { value: '', error: false, errorMessage: '' },
          phoneNumber: { value: '', error: false, errorMessage: '' },
          selectedDept: { value: { label: 'Select Dept', value: 'Select Dept' }, error: false, errorMessage: '' },
          selectedRole: { value: { label: 'Select Role', value: 'Select Role' }, error: false, errorMessage: '' },
    },
    permissionAccessDepartment:[]
  }, ()=>{
   
    
    })
      }


    }
    
    // Customizable Area End
  }

  async componentDidMount() {
    
    const projectPH= await getStorageData("projectidPH");
    console.log("projectid....",projectPH)
    this.setState({projectId:projectPH})
    this.setState({ stateList: State.getStatesOfCountry("IN") }, () => {
    })
    let isSideBarOpen = await getStorageData("sidebarvalue");
    this.setState({ isSideBarOpen: JSON.parse(isSideBarOpen) })
    this.getAllDepartmentList()
    const statusvalue=await getStorageData("statusPH")
    console.log("status.......",statusvalue)
    this.setState({status:statusvalue})
    const projectname=await getStorageData("namePH")
    this.setState({ProjectName:projectname})


  }


  togglePipelineDepartmentPH = (id:any) :void=> {
    this.setState((prevState:any) => {
      
      const departmentIndexPH = prevState.departmentList.findIndex((dept:{id:string}) => dept.id === id);
      const updatedDepartmentListPH = [...prevState.departmentList];
     
      if (departmentIndexPH !== -1) {
        const updatedDepartment = {
          ...updatedDepartmentListPH[departmentIndexPH],
          attributes: {
            ...updatedDepartmentListPH[departmentIndexPH].attributes,
            pipeline_department: !updatedDepartmentListPH[departmentIndexPH].attributes.pipeline_department
          }
        };
        updatedDepartmentListPH[departmentIndexPH] = updatedDepartment;

        
        const checkedDepartmentsPH = updatedDepartment.attributes.pipeline_department ?
          [...prevState.checkedDepartments, { id: updatedDepartment.id, name: updatedDepartment.attributes.name, level: updatedDepartment.attributes.level }] :
          prevState.checkedDepartments.filter((dept: { id: string; }) => dept.id !== updatedDepartment.id);

        return { departmentList: updatedDepartmentListPH, checkedDepartmentsPH };
      }

      return prevState;
    });
    
    this.setState({dummydepartments:this.state.checkedDepartments});
    this.updateDepartments()
  };
  


  handleDepartmentLevelChange = (event:any, departmentId:string) => {
    const { value } = event.target;
    this.setState(prevState => ({
        departmentList: prevState.departmentList.map(department => {
            if (department.id === departmentId) {
                return {
                    ...department,
                    attributes: {
                        ...department.attributes,
                        level: value,
                    },
                };
            }
            return department;
        }),
    }), () => {
        
        setStorageData('departmentListprojectDetail', JSON.stringify(this.state.departmentList));
    });
};



  
handleEmailLoginCloseNotification = () => {
  this.setState({ emailLoginNotification: { type: "", open: false, message: "" } });
}
  
  handleSideBar = () => {
    this.setState({
      isSideBarOpen: !this.state.isSideBarOpen
    },
      async () => {
        localStorage.setItem("sidebarvalue", this.state.isSideBarOpen.toString())
      }
    );
  };

  handleDropdown = () => {
    this.setState({
      isDropdownOpen: !this.state.isDropdownOpen
    });
  };





  getAllDepartmentList = async () => {
    const authenticationToken = await getStorageData('token', false)
    const header = {
      "token": authenticationToken,
      "Content-Type": "application/json"

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.allDepartmentsID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.projectsAPI}?project_id=${this.state.projectId}`);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateDepartments = async () => {
    const authenticationToken = await getStorageData('token', false)
    const header = {
      "token": authenticationToken,
      "Content-Type": "application/json"
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updatedepartmentcallid = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updatedepartmentendpoint+this.state.projectId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    const departmentData = this.state.departmentList.map((ele: any) => ({
      id: ele.id,
      name: ele.attributes.name,
      pipeline_department: ele.attributes.pipeline_department,
      level: ele.attributes.level
    }))
    let httpBody = {
      departments: departmentData
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  handleaddNewDepartmentModalPH = () => {
    this.updateDepartments()
    this.setState({
      addNewDepartmentModal: !this.state.addNewDepartmentModal
    })
  }

  handleaddNewDepartmentChangePH = (event: any) => {
    const {value } = event.target;
  
    const isValid = value.trim().length > 0;
    this.setState({
      newDepartmentName: {
        name:event.target.value,
        value: value,
        error: isValid ? false : true,
        errMessage: isValid ? "" : "This field is required",
      },
    });
  };

  handleCloseAddNewDepartmentModalPH = () => {
    const { newDepartmentName, addNewDepartmentModal } = this.state;
    const isEmpty = newDepartmentName.value.trim() === "";
    this.setState({
      addNewDepartmentModal: isEmpty ? addNewDepartmentModal : !addNewDepartmentModal,
      newDepartmentName: {
        name: newDepartmentName.value,
        value: isEmpty ? '' : newDepartmentName.value,
        error: isEmpty,
        errMessage: isEmpty ? 'This field is required' : '',
      },
    }, () => {
      if (this.state.newDepartmentName.value.length > 1) {
        this.createNewDepartmentPH()
      }
    });
  };
  handlenavigateToProjectsPagePH=()=>{
    this.props.navigation.navigate("ProjectDetailPH")
  }
  
  createNewDepartmentPH = async () => {
    const authenticationTokenDepartment = await getStorageData('token', false)
    const header = {
      "token": authenticationTokenDepartment,
      "Content-Type": "application/json"
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createDepartmentIdDepartment = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.projectsAPI}?project_id=`+this.state.projectId
    );

   
    let httpBody = {
      data:{
        department:{
          name: this.state.newDepartmentName.value
        }
      }
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSelectNewDepartmentModalDepartment = () => {
    this.setState({
      selectNewDepartmentModalDepartment: !this.state.selectNewDepartmentModalDepartment
    })
  }

  handleCloseSelectNewDepartmentPH = () => {
    const { selectDepartMent } = this.state;
    if (selectDepartMent.value.value === "Select Dept") {
      this.setState({
        selectDepartMent: {
          ...selectDepartMent,
          error: true,
          errMessage: "Select Department",
        }
      });
    } else {
      this.setState({
        selectNewDepartmentModalDepartment: !this.state.selectNewDepartmentModalDepartment
      });
    }
  }
  
  getDepartmentListPH = async () => {
    const authenticationToken = await getStorageData('token', false)
    const header = {
      "token": authenticationToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.departmentListID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.departmentListApi}?project_id=${this.state.projectId}`);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

    // Customizable Area End
}