import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import * as Yup from "yup";
import { getStorageData,setStorageData} from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  firstName: any;
  lastName: any;
  email: any;
  phoneNumber: any;
  currentCountryCode: any;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;

  edtEmailEnabled: boolean;
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;

  currentPasswordText: any;
  newPasswordText: any;
  reTypePasswordText: any;

  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;

  saveButtonDisable: boolean;
  isSideBarOpen: boolean;
  isDropdownOpen: boolean;
  showNewPassword: boolean;
  showOldPassword: boolean;
  showConfirmPassword: boolean;
  isNotificationsChecked: boolean;
  visibiltyOfPassword: boolean;
  copyPassword: string;
  passwordLogin: string;
  copyPassword1: string;
  passwordLogin1: string;
  copyPassword2: string;
  passwordLogin2: string;
  setName:string;
  validateName:string;
  validateEmail:string;
  setEmail:string;
  setEmailvalue:string;
  setNamevalue:string;
  txtSavedValue:string;
  validateoldPassword:string;
  validateNewPassword:string;
  validateConfirmPassword:string;
  isLoading: boolean;
  image:any;
  setImage:any;
  
  // Customizable Area End

}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class UserProfileBasicController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  labelFirstName: string;
  lastName: string;
  labelArea: string;
  labelMobile: string;
  labelEmail: string;
  labelCurrentPassword: string;
  labelNewPassword: string;
  labelRePassword: string;
  btnTextCancelPasswordChange: string;
  btnTextSaveChanges: string;
  labelHeader: any;
  btnTextChangePassword: string;
  
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  apiCallMessageUpdateProfileRequestId: any;
  validationApiCallId: string = "";
  postManageProfileApi:string="";
  postManageProfileApi1:string="";
  UpdatePasswordApiId:string="";
  apiChangePhoneValidation: any;
  registrationAndLoginType: string = "";
  authToken: any;
  uniqueSessionRequesterId: any;
  userProfileGetApiCallId: any;
  userAttr: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
   
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      currentCountryCode: configJSON.hintCountryCode,
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enableNewPasswordField: true,

      edtEmailEnabled: true,
      llDoChangePwdContainerVisible: false,
      llChangePwdDummyShowContainerVisible: false,

      currentPasswordText: "",
      newPasswordText: "",
      reTypePasswordText: "",

      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,
      isSideBarOpen: true,
      isDropdownOpen: false,
      showNewPassword: false,
      showOldPassword: false,
      showConfirmPassword: false,
      isNotificationsChecked: true,
      visibiltyOfPassword: false,
      copyPassword: "",
      passwordLogin: "",
      copyPassword1: "",
      passwordLogin1: "",
      copyPassword2: "",
      passwordLogin2: "",
      setName:"",
      validateName:"",
      validateEmail:"",
      setEmail:"",
      setEmailvalue:"",
      setNamevalue:"",
      txtSavedValue:"",
      validateoldPassword:"",
      validateNewPassword:"",
      validateConfirmPassword:"",
      isLoading: false,
      image: getStorageData("image"),
      setImage:null,

    };

    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");
    

    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelArea = configJSON.labelArea;
    this.labelMobile = configJSON.labelMobile;
    this.labelEmail = configJSON.labelEmail;
    this.labelCurrentPassword = configJSON.labelCurrentPassword;
    this.labelNewPassword = configJSON.labelNewPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.btnTextCancelPasswordChange = configJSON.btnTextCancelPasswordChange;
    this.btnTextSaveChanges = configJSON.btnTextSaveChanges;
    this.labelHeader = configJSON.labelHeader;
    this.btnTextChangePassword = configJSON.btnTextChangePassword;
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: String, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );   
    }

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))

      const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
      if (apiRequestId === this.postManageProfileApi) {
        this.setState({isLoading:false})
        setStorageData("image", response.profile_image);
        window.location.reload()
          this.props.navigation.navigate('UserProfileBasicBlock')
        
      }
      if (apiRequestId === this.postManageProfileApi1) {
        this.setState({isLoading:false})

       setStorageData("full_name",response.full_name);
       setStorageData("email",response.email);
       window.location.reload()
        
      }
    else if(apiRequestId===this.UpdatePasswordApiId)
    {
          if(response.data)
          {
            this.setState({isLoading:false})
            alert(response.meta.message) 
          
            
          }
          else{
            this.setState({isLoading:false})
            alert(response.errors[0].message)
          }

    }
    }
    // Customizable Area End
  }

  validateMobileAndThenUpdateUserProfile() {
    let countryCode: any = this.state.currentCountryCode;
    let mobileNo: any = this.state.phoneNumber;

    let error: any = "";

    error = this.validateCountryCodeAndPhoneNumber(countryCode, mobileNo);

    if (error) {
      this.showAlert(configJSON.errorTitle, error);

      return;
    }

    if (this.userAttr) {
      const countryCodeOld = this.userAttr.country_code;
      const mobileNoOld = this.userAttr.phone_number;

      if (
        Number.parseInt(countryCode) === Number.parseInt(countryCodeOld) ||
        countryCode === configJSON.hintCountryCode
      ) {
        countryCode = null;
      }

      if (
        Number.parseInt(this.state.phoneNumber) === Number.parseInt(mobileNoOld)
      ) {
        mobileNo = null;
      }
    }

    if (mobileNo && countryCode) {
      this.validateMobileOnServer(
        this.state.currentCountryCode,
        this.state.phoneNumber
      );
    } else {
      this.validateAndUpdateProfile();
    }
  }

  validateEmail(email: string) {
    let error = null;

    if (!this.isValidEmail(email)) {
      error = configJSON.errorEmailNotValid;
    }

    return error;
  }

  validateLastName(lastName: String) {
    return !this.isNonNullAndEmpty(lastName)
      ? "Last name " + configJSON.errorBlankField
      : null;
  }

  validateFirstName(firstName: String) {
    return !this.isNonNullAndEmpty(firstName)
      ? "First name " + configJSON.errorBlankField
      : null;
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (
        !this.isNonNullAndEmpty(String(countryCode)) ||
        configJSON.hintCountryCode === countryCode
      ) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (
      this.isNonNullAndEmpty(countryCode) &&
      configJSON.hintCountryCode !== countryCode
    ) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  validateAndUpdateProfile() {
    let firstName = this.state.firstName;
    let lastName = this.state.lastName;
    let countryCode: any = this.state.currentCountryCode;

    let mobileNo = this.state.phoneNumber;
    let email = this.state.email;

    let currentPwd = this.state.currentPasswordText;
    let newPwd = this.state.newPasswordText;
    let reTypePwd = this.state.reTypePasswordText;

    const errorFirstName = this.validateFirstName(firstName);
    const errorLastName = this.validateLastName(lastName);

    const errorMobileNo = this.validateCountryCodeAndPhoneNumber(
      countryCode,
      mobileNo
    );
    const errorEmail = this.validateEmail(email);

    const errorCurrentPwd = this.validateCurrentPwd(currentPwd);
    const errorNewPwd = this.validatePassword(newPwd);
    const errorRetypePwd = this.validateRePassword(reTypePwd);

    let isValidForSignUp: boolean = true;

    if (errorFirstName != null) {
      this.showAlert(configJSON.errorTitle, errorFirstName);
      return false;
    } else if (errorLastName != null) {
      this.showAlert(configJSON.errorTitle, errorLastName);
      return false;
    }

    if (configJSON.ACCOUNT_TYPE_EMAIL === this.registrationAndLoginType) {
      if (errorMobileNo !== null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);
        return false;
      }
    } else if (
      configJSON.ACCOUNT_TYPE_SOCIAL === this.registrationAndLoginType
    ) {
      if (errorMobileNo != null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);
        return false;
      }
    } else if (
      configJSON.ACCOUNT_TYPE_PHONE === this.registrationAndLoginType
    ) {
      if (errorEmail != null) {
        this.showAlert(configJSON.errorTitle, errorEmail);

        return false;
      }
    } else {
      if (errorMobileNo != null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);

        return false;
      } else if (errorEmail != null) {
        this.showAlert(configJSON.errorTitle, errorEmail);

        return false;
      }
    }

    if (
      configJSON.ACCOUNT_TYPE_SOCIAL !== this.registrationAndLoginType &&
      this.state.llDoChangePwdContainerVisible
    ) {
      if (errorCurrentPwd != null) {
        this.showAlert(configJSON.errorTitle, errorCurrentPwd);
        return false;
      } else if (errorNewPwd != null) {
        this.showAlert(configJSON.errorTitle, errorNewPwd);
        return false;
      } else if (errorRetypePwd != null) {
        this.showAlert(configJSON.errorTitle, errorRetypePwd);
        return false;
      } else if (newPwd !== reTypePwd) {
        this.showAlert(
          configJSON.errorTitle,
          configJSON.errorBothPasswordsNotSame
        );
        return false;
      } else if (currentPwd === newPwd) {
        this.showAlert(
          configJSON.errorTitle,
          configJSON.errorCurrentNewPasswordMatch
        );
        return false;
      }
    }

    //Call update API
    if (this.userAttr) {
      let firstNameOld = this.userAttr.first_name;
      let lastNameOld = this.userAttr.last_name;
      let countryCodeOld = this.userAttr.country_code + "";
      let mobileNoOld = this.userAttr.phone_number + "";
      let emailOld = this.userAttr.email;
      this.registrationAndLoginType = this.userAttr.type;

      if (this.isNonNullAndEmpty(firstName) && firstName === firstNameOld) {
        firstName = null;
      }

      if (this.isNonNullAndEmpty(lastName) && lastName === lastNameOld) {
        lastName = null;
      }

      if (
        this.isNonNullAndEmpty(countryCode) &&
        countryCode === countryCodeOld
      ) {
        countryCode = null;
      }

      if (this.isNonNullAndEmpty(mobileNo) && mobileNo === mobileNoOld) {
        mobileNo = null;
      }

      if (countryCode != null || mobileNo != null) {
        if (countryCode == null) {
          countryCode = countryCodeOld;
        }

        if (mobileNo == null) {
          mobileNo = mobileNoOld;
        }
      }

      if (this.isNonNullAndEmpty(email) && email === emailOld) {
        email = null;
      }
    }

    if (
      this.isNonNullAndEmpty(firstName) ||
      this.isNonNullAndEmpty(lastName) ||
      this.isNonNullAndEmpty(countryCode) ||
      this.isNonNullAndEmpty(mobileNo) ||
      this.isNonNullAndEmpty(email) ||
      (this.isNonNullAndEmpty(currentPwd) && this.isNonNullAndEmpty(newPwd))
    ) {
      const header = {
        "Content-Type": configJSON.contentTypeApiUpdateUser,
        token: this.authToken
      };

      let data: any = {
        first_name: this.state.firstName,
        last_name: this.state.lastName
      };

      if (this.state.edtMobileNoEnabled) {
        if (
          configJSON.hintCountryCode !== countryCode &&
          this.isNonNullAndEmpty(String(countryCode)) &&
          this.isNonNullAndEmpty(String(mobileNo))
        ) {
          data = {
            ...data,
            ...{ new_phone_number: String(countryCode) + String(mobileNo) }
          };
        }
      }

      if (this.isNonNullAndEmpty(email)) {
        data = { ...data, ...{ new_email: email } };
      }

      if (
        this.isNonNullAndEmpty(currentPwd) &&
        this.isNonNullAndEmpty(newPwd)
      ) {
        data = {
          ...data,
          ...{ current_password: currentPwd, new_password: newPwd }
        };
      }

      const httpBody = {
        data: data
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiCallMessageUpdateProfileRequestId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiEndPointUpdateUser
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiUpdateUserType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  validateCurrentPwd(currentPwd: any) {
    if (!this.isNonNullAndEmpty(currentPwd)) {
      return configJSON.errorCurrentPasswordNotValid;
    } else {
      return null;
    }
  }

  validatePassword(newPwd: any) {
    if (!this.passwordReg.test(newPwd)) {
      return configJSON.errorNewPasswordNotValid;
    } else {
      return null;
    }
  }

  validateRePassword(reTypePwd: any) {
    if (!this.passwordReg.test(reTypePwd)) {
      return configJSON.errorReTypePasswordNotValid;
    } else {
      return null;
    }
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateMobileOnServer(countryCode: any, mobileNo: any) {
    const header = {
      "Content-Type": configJSON.contenttypeApiValidateMobileNo,
      token: this.authToken
    };

    const data = {
      new_phone_number: countryCode + mobileNo
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiChangePhoneValidation = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiValidateMobileNo
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callTypeApiValidateMobileNo
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  enableDisableEditPassword(isEditable: boolean) {
    if (configJSON.ACCOUNT_TYPE_SOCIAL === this.registrationAndLoginType) {
      this.setState({
        edtEmailEnabled: false,
        llDoChangePwdContainerVisible: false,
        llChangePwdDummyShowContainerVisible: false
      });
    } else {
      if (isEditable) {
        this.setState({
          llDoChangePwdContainerVisible: true,
          llChangePwdDummyShowContainerVisible: false
        });
      } else {
        this.setState({
          llDoChangePwdContainerVisible: false,
          llChangePwdDummyShowContainerVisible: true,
          currentPasswordText: "",
          newPasswordText: "",
          reTypePasswordText: ""
        });
      }
    }
  }

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  requestSessionData() {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.uniqueSessionRequesterId = msg.messageId;
    this.send(msg);
  }

  getUserProfile() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userProfileGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetUserProfile
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: this.authToken
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  // Customizable Area Start
  manageProfilevalue =async()=>{
   

    const token = await getStorageData("token",false);
    
    const header = {
      "token": token
    };
    

    this.setState({isLoading:true})
    
    const formData = new FormData();
    formData.append("profile[photo]",this.state.image,this.state.image.length);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postManageProfileApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
   
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileUpdateEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.manageprofilemethodtype
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
}
  manageProfileData = async () => {
    if(this.state.setName==='')
    {
      this.setState({validateName:"Name is required"})
      
    }
    else if (this.state.setEmail === '') {
      this.setState({ validateEmail: 'Email is required' });
    } else if (!this.isValidEmailValidation(this.state.setEmail)) {
      this.setState({ validateEmail: 'Invalid email format' });
    }
    else{
   
    const token = await getStorageData("token",false);
    
    const header = {
      "token": token
    };
    this.setState({isLoading:true})
    
    const formData = new FormData();
    formData.append('account[full_name]', this.state.setName);
    formData.append('account[email]', this.state.setEmail)
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postManageProfileApi1 = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
   
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileUpdateEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.manageprofilemethodtype
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    }
}

  setpasswordOfEmail = (event: any) => {
    if(event.target.value==="")
    {
      this.setState({validateoldPassword:" Old Password is required"})
    }
    else if(!this.isPasswordValid(this.state.copyPassword)){
      this.setState({validateoldPassword:"Password must be at least 8 characters long and include at least one lowercase letter, one uppercase letter, and one digit."})
    }
    else{
      this.setState({validateoldPassword:""})
    }
    const { value } = event.target;
    const { showOldPassword, copyPassword } = this.state;
    if (!showOldPassword) {
      const a = copyPassword + value.split("*").join("");
      this.setState({ copyPassword: a });
    } else {
      this.setState({ copyPassword: value });

    }
    this.setState({ passwordLogin: value });
  

  };

  generateUniqueName = () => {
    const timestamp = new Date().getTime();
    return `updated_image_${timestamp}.png`;
  };

  handleImage = async(e: any) => {
    this.setState({ image: e.target.files[0] });
    this.manageProfilevalue();
   
  };

  handleName = (e: any) => {
    if(e.target.value==='') {
      this.setState({validateName:"Name is required"})
    }
    else{
      this.setState({validateName:''})
    }
    this.setState({setName:e.target.value})
    


  };
   isPasswordValid=(password:string) =>{
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    return passwordRegex.test(password);
  }
  handleEmail= (e: any) => {
    if (e.target.value === '') {
      this.setState({ validateEmail: 'Email is required' });
    } else if (!this.isValidEmailValidation(this.state.setEmail)) {
      this.setState({ validateEmail: 'Invalid email format' });
    }
    else{
      this.setState({validateEmail:""})
    }
    this.setState({setEmail:e.target.value})
    

  };
  setpasswordOfEmail1 = (event: any) => {
    if(event.target.value==="")
    {
      this.setState({validateNewPassword:" New Password is required"})
    }
    else if(!this.isPasswordValid(this.state.copyPassword1)){
      this.setState({validateNewPassword:"Password must be at least 8 characters long and include at least one lowercase letter, one uppercase letter, and one digit."})
    }
    else{
      this.setState({validateNewPassword:""})
    }
   
    const { value } = event.target;
    const { showNewPassword, copyPassword1 } = this.state;
    if (!showNewPassword) {
      const a = copyPassword1 + value.split("*").join("");
      this.setState({ copyPassword1: a });
    } else {
      this.setState({ copyPassword1: value });

    }
    this.setState({ passwordLogin1: value });

  };

  setpasswordOfEmail2 = (event: any) => {

        this.setState({validateConfirmPassword:""})
      
    const { value } = event.target;
    const { showConfirmPassword, copyPassword2 } = this.state;
    if (!showConfirmPassword) {
      const a = copyPassword2 + value.split("*").join("");
      this.setState({ copyPassword2: a });
    } else {
      this.setState({ copyPassword2: value });

    }
    this.setState({ passwordLogin2: value });

  };
  handleKey = (event: any) => {
    const { showOldPassword, copyPassword } = this.state;
    const keyCode = event.keyCode || event.which;
      if (keyCode == 8) {
      const caretPosition = event.target.selectionStart;
      if (!showOldPassword) {
        let a = copyPassword.split("")
        a.splice(caretPosition - 1, 1)
        this.setState({ copyPassword: a.join("") })

      }
    }
  }
  isValidEmailValidation = (email:string) => {
  
    const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return emailPattern.test(email);
  };
  handleKey1 = (event: any) => {
    const { showNewPassword, copyPassword1 } = this.state;
    const keyCode = event.keyCode || event.which;
      if (keyCode == 8) {
      const caretPosition = event.target.selectionStart;
      if (!showNewPassword) {
        let a = copyPassword1.split("")
        a.splice(caretPosition - 1, 1)
        this.setState({ copyPassword1: a.join("") })

      }
    }
  }

  handleKey2 = (event: any) => {
    const { showConfirmPassword, copyPassword2 } = this.state;
    const keyCode = event.keyCode || event.which;
      if (keyCode == 8) {
      const caretPosition = event.target.selectionStart;
      if (!showConfirmPassword) {
        let a = copyPassword2.split("")
        a.splice(caretPosition - 1, 1)
        this.setState({ copyPassword2: a.join("") })

      }
    }
  }
  handleSideBar = () => {
    this.setState({
      isSideBarOpen: !this.state.isSideBarOpen
    },
    async() => {
      setStorageData("sidebarvalue",this.state.isSideBarOpen.toString())
      
    }
    
    
    );
  };
  handleInputFocus = (labelName: string) => {
    const nameLabel = document.getElementById(labelName);
    if (nameLabel) {
      nameLabel.style.color = "#F5C42C";
    }
  };
  handleInputBlur = (labelName: string) => {
    const nameLabel = document.getElementById(labelName);
    if (nameLabel) {
      nameLabel.style.color = "#6D89AF";
    }
  };
   
  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      isNotificationsChecked: event.target.checked
    });
  };
  handlePasswordVisiblity = (value: keyof S) => {
    this.setState(({
      [value]: !this.state[value]
    } as unknown) as Pick<S, keyof S>);
  };
  handleDropdown = () => {
    this.setState({
      isDropdownOpen: !this.state.isDropdownOpen
    });
  };
  profileValidationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required")
  });
  settingsValidationSchema = Yup.object().shape({
    oldPassword: Yup.string().required("Old password is required"),
    newPassword: Yup.string()
      .required("New password is required")
      .min(8, "Password must be at least 8 characters")
      .max(16, "Password must not exceed 16 characters")
      .matches(
        /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[/d]).*$/,
        "Password must contain at least one capital letter, one special character, and one number"
      ),
    confirmPassword: Yup.string()
      .required("Confirm password is required")
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
  });

  doEmailLogIn =async () => {
    if(this.state.copyPassword==='')
    {
      this.setState({validateoldPassword:"Old Password is required"})
    }
    else if(this.state.copyPassword1==='')
    {
      this.setState({validateNewPassword:"New Password is required"})
    }
    else if(this.state.copyPassword1!==this.state.copyPassword2)
    {
      this.setState({validateConfirmPassword:"Passwords do not match"})
    }
    else{
    
    const token = await getStorageData("token",false);
    const header = {
      "Content-Type": configJSON.manageApiContentType,
      "token": token
    };
    this.setState({isLoading:true})
    const attrs = {
      current_password: this.state.copyPassword,
      password: this.state.copyPassword1
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.UpdatePasswordApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.UpdatePasswordEndPoint+`=${token}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updatePasswordAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }
  async componentDidMount() {
    
    const currentUserEmail = await getStorageData('email');
      this.setState({setEmailvalue:currentUserEmail})
    const currentUserName = await getStorageData('full_name')
     this.setState({setNamevalue:currentUserName})
   
     const image=await getStorageData('image');
     this.setState({setImage:image})
     let isSideBarOpen=await getStorageData("sidebarvalue");
     this.setState({isSideBarOpen:JSON.parse(isSideBarOpen)})
  }
  
  

  // Customizable Area End

}
