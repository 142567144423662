//Customizable Area Start
import React from "react"; 
import { Button, Grid, IconButton, Divider } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { logo } from "./assets";
import "./forgotPassword.css";
import ForgotPasswordController from "./ForgotPasswordController";
export const configJSON = require("./config");

export default class TopNav extends ForgotPasswordController {
    render() {
        return (
            <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style = {{ borderBottom : '1px solid #E3EAF4', paddingInline: "7%"}} className="nav_main_grid">
                    <Grid item xs={6} sm={4} md={4} lg={4} xl={4} className="back_button_grid">
                       {!this.props.backButtonVisible ?  <Button variant="outlined" className="backButton" style={{height:"48px",width:"100px",fontSize:"14px"} as React.CSSProperties} data-test-id = "top_nav_but" onClick = {() => {this.props.navigation && this.props.navigation.navigate("LandingPage")}}>
                            <IconButton className="icon_button">
                                <ArrowBackIcon className="arrow_icon" style={{height:"20px",width:"20px"} as React.CSSProperties} />
                            </IconButton>
                            <span className="back_button_text">
                            {configJSON.backButtonText}
                            </span>
                        </Button>: ""}
                    </Grid>
                    <Grid item xs={6} sm={8} md={8} lg={8} xl={8} className="logo_grid">
                        <img src={logo} className="logo" />
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}
//Customizable Area End