import React, { useEffect } from "react";
import { Card, Box, CardMedia, CardContent, Typography, Link, Grid, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { RightIcon, Review, Reviewact, gellaryimage, Gellary, Gellaryact, studioDETAILimg, callicon, msgicon, certificateicon, switchEnable, switchDisable, downloadicon, studiobblockon, clock, iconC, location, OverviewIcon, LocationIcon, VendorIcon, departicon, VendorDetailimg, workicon, OverviewIconactive, VendorIconactive, departiconactive } from "./assets";
import { useState } from "react";
import GetAppIcon from '@material-ui/icons/GetApp';
import Rating from "../src/Rating";
import StarOutlinedIcon from '@material-ui/icons/StarOutlined';
import Sortfunctionality from "./Sortfunctionality";
import { BorderColor } from "@material-ui/icons";
interface MyProps {
    data: any;
    datavalue: any;
    handleStudioBadgeVisibility: any
    // dataitem: any;

}

const useStyles = makeStyles({
    card: {
        width: 330,
        height: 505,
        borderRadius: 14,
        padding: 8,
        border: "1px solid #E1E6EC",
        position: 'relative',
        boxShadow: 'none',
        '& .MuiCardContent-root': {
            padding: 0
        },
        '@media(max-width: 900px)': {
            width: 'unset',
            maxWidth: '330px',
            margin: 'auto'
        },
    },
    detailbox: {
        width: 600,
        borderRadius: 14,
        border: "1px solid #E1E6EC",
        '@media(max-width: 900px)': {
            width: 'unset',
            maxWidth: '600px',
            marginTop: '20px',
        },
        "@media(min-width: 1440px)": {
            width: '702px',
        },
    },
    cardImage: {
        width: '100%',
        height: '130px',
        borderRadius: '12px',
    },
    cardImageDiv: {
        position: 'relative',
    },
    overlay: {
        position: 'absolute',
        borderRadius: '12px',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundImage: "linear-gradient(180deg, rgba(0,0,0,0.15) 0%, rgba(0,0,0,0.65) 100%)"
    },
    cardTopContent1: {
        display: 'flex',
        justifyContent: 'space-between',
        position: 'absolute',
        top: 65,
        paddingInline: 12,
        width: '88%',
        alignItems: 'end'
    },
    cardTopContent2: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: 16,
        flexDirection: "column",
    },
    cardTopContent3: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: 15,
        paddingBottom: 17,
    },
    ratingBox: {
        background: '#F5C42C',
        width: 68,
        height: 23,
        color: 'white',
        fontWeight: 700,
        fontSize: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: "12px",
        fontFamily:"urbanist"
    },
    projectBox1: {
        border: '1px solid #e2eaf4',
        borderRadius: 10,
        backgroundColor: '#f8fafe',
        width: 156,
        height: 38,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 14,
        fontWeight: 700,
        color: 'black',
    },
    projectBox2: {
        fontSize: 12,
        color: 'black',
        fontWeight: 700,
    },
    dateText: {
        fontSize: 12,
        color: '#6d89af',
        fontWeight: 700,
        marginTop: "2px"
    },
    projectBox3: {
        fontSize: 12,
        color: '#6d89af',
        fontWeight: 700,
        display: 'flex',
        alignItems: 'center',
        gap: 5,
    },
    icon1: {
        marginLeft: '14px',
        height:"24px",
        width:"24px"
    },
    overviewTitle: {
        lineHeight: "34px",
        color: "#17181d",
        fontFamily: "Urbanist",
        fontSize: "28px",
        fontWeight: 700,
        letterSpacing: "0.31111112px",
        marginBottom: "12px",
    },
    galleryIcon: {
        height:"24px",
        width:"24px",
        marginLeft: 14,
    },
    icon: {
    },
    iconemail: {
        height:"20px",
        width:"20px"
    },
    iconcall:{
        height:"20px",
        width:"20px"  
    },

    downloadIcon: {
        border: "1px solid #e3eaf4",
        backgroundColor: "#f8fafe",
        padding: "6px",
        alignSelf: "start",
        margin: "1px",
    },
    downloadIconColor: {
        color: " #000",
        width: "0.7em",
        height: "0.7em",
    },
    cardcontent1: {
        paddingBottom: '8px',
        paddingLeft: '12px',
        fontSize: '14px',
        color: '#6D89AF',
        fontWeight: 500,
    },
    cardcontent2: {
        cursor: "pointer",
        '&:hover': {
            color: "yellow",
        },
    },

    cardcontent3: {
        display: 'flex',
        gridColumnGap: '15px',
        background: '#ececee66',
        borderRadius: '12px',
        height: '52px',
        marginInline: '8px',
        alignItems: 'center'
    },
    vendors: {
        color: "inherit",

    },
    vendoractive: {
        color: 'rgb(245, 196, 44)',
    },
    departments: {
        marginLeft: "100px",
        color: "#6d89af",

    },
    carddepart5: {
        display: 'flex',
        gridColumnGap: '15px',
        background: 'rgba(236, 236, 238, 0.4)',
        borderRadius: '12px',
        height: '52px',
        alignItems: 'center',
        BorderColor:'#E1E6EC'
    },
    parentbox: {
        display: "flex",
        flexDirection: "row",
        gridColumnGap: '20px',
        justifyContent: 'center',
        '@media(max-width: 1160px)': {
            flexDirection: 'column',
            gridRowGap: '20px',
        },
    },
    vendor: {
        cursor: 'pointer',

    },


    department: {
        cursor: 'pointer',

    }
    ,
    overviewdetails: {

    },
    Headingoverview: {
        marginLeft: "30px",
        marginTop: "30px",
    },
    projectoverview: {
        borderTop: "1px solid #E1E6EC",
        borderBottom: "1px solid #E1E6EC",
        marginInline: '30px',
        '@media (max-width: 600px)': {
            marginInline: "20px"
        }
    },
    studiooverview: {
        marginInline: '30px',
        '@media (max-width: 600px)': {
            marginInline: "20px"
        },
        borderBottom: "1px solid #E1E6EC",
    },
    productiondemo: {
        border: "1px solid #E1E6EC",
        borderRadius: "12px",
        display: 'flex',
        marginInline: '30px',
        paddingLeft: '16px',
        marginBottom: '30px'
    },
    departmentdetails: {
        alignItems: "start",
        flexDirection: "row",
        flexWrap: "wrap"
    },
    subheading: {
        marginTop: '14px',
        color: '#6d89af',
        fontWeight: 600,
        fontFamily: "Urbanist",
        fontSize: "16px",
        lineHeight: "19px",
    },
    subheadingcerti: {
        marginTop: '16px',
        color: '#6d89af',
        fontWeight: 600,
        fontFamily: "Urbanist",
        fontSize: "16px",
        lineHeight: "19px",
    },
    subcontent: {
        fontWeight: 'bold',
        gap: "16px",
        fontSize: '18px',
        marginBottom: '20px',
        display: 'flex',
        '@media (max-width: 600px)': {
            gap: "8px"
        }
    },
    projectLocation: {
        marginLeft: '30px',
        color: '#6d89af',
    },
    productionheading: {
        marginLeft: '20px',
        marginTop: '15px',
        fontWeight: 'bold',
    },
    productioncontent: {
        marginLeft: '20px',
        marginTop: '3px',
        fontSize: '15px',
    },

    overviews: {
        display: "flex",
        FlexDirection: "row",
        gridColumnGap: '15px',
        alignItems: 'center',
        background: '#f5c42c14',
        borderRadius: '12px',
        height: '52px',

    },
    headingv: {
        display: 'block',
        fontSize: "25px",
        padding: "25px 30px 16px",
        borderBottom: "1px solid #d8d8d8",
        margin: "0",
    },
    vendordetails: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "start",
        flexDirection: "row",
        flexWrap: "wrap",
        padding: '30px',
        gap: '12px',
        '@media (max-width: 1440px)': {
            justifyContent: "start",
        }
    },
   activeText : {
        color: '#f5c42c',
        fontWeight: 600,
        cursor: "pointer",
        fontSize: "16px",
        fontFamily: "Urbanist",
        lineHeight: "19px",
    },
    inactiveText: {
        color: "#17181d",
        cursor: "pointer",
        fontWeight: 600,
        fontSize: "16px",
        fontFamily: "Urbanist",
        lineHeight: "19px",
    },
    generaltabbox: {
        gridRowGap: '12px',
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: 8,
        paddingBottom: 16,
        borderBottom: '1px solid #e3eaf4',
        flexDirection: "column",
    }
    ,
    innertabbox: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        paddingRight: '15px',
    },
    allPictureLink: {
        color: 'rgb(51, 137, 255)',
        textDecoration: 'underline',
    },
    content1day: {
        lineHeight: "16px",
        color: "#17181d",
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: 600,
        letterSpacing: "0.26666668px",
    },
    content2hours: {
        fontSize: "14px",
        fontWeight: 500
    },
    content1: {
        display: "flex",
        FlexDirection: "row",
        gridColumnGap: '13px',
        alignItems: 'center',
        background: '#E1E6EC',
        borderRadius: '12px',
        height: '54px',
        paddingLeft: "17px",
        justifyContent: 'start',
        width: '200px',
        marginTop: "12px",
        '@media (max-width: 600px)': {
            width: '180px',
        }
    },
    content2: {
        display: "flex",
        FlexDirection: "row",
        gridColumnGap: '13px',
        alignItems: 'center',
        background: '#E1E6EC',
        borderRadius: '12px',
        height: '54px',
        marginInline: '8px',
        justifyContent: 'start',
        width: '200px',
        marginTop: "12px",
        fontSize: '20px',
        paddingLeft: "17px",
        '@media (max-width: 600px)': {
            width: '180px',
            marginInline: '0px',
        }
    },
    content2sub: {
        color: "#42A200",
        fontSize: "14px"
    },
    content1sub: {
        color: "#42a200",
        fontFamily: "Helvetica-Bold",
        fontSize: "20px",
        fontWeight: 400,
        lineHeight: "19px",
        letterSpacing: "0.18181819px",
    },
    typestudio: {
        marginTop: '12px',
        marginBottom: '20px',
    },
    list1: {
        display: 'flex',
        gap: '60px',
        paddingBottom: "10px",
        '@media(max-width: 600px)': {
            flexDirection: 'column',
            gridRowGap: '20px',
        },
    },
    property1: {
        gridRowGap: '40px'
    },
    firstp: {
        display: 'flex',
        gap: '5px'
    },
    heading1: {
        fontWeight: 'bold',
        fontSize: "12px"
    },
    list2: {
        display: 'flex',
        gap: '84px',
        paddingBottom: '20px',

        '@media(max-width: 600px)': {
            flexDirection: 'column',
            gridRowGap: '20px',
            marginTop: 10
        },
    },
    certificateParent: {
        borderBottom: '1px solid #e3eaf4',
        marginInline: '30px',
        '@media(max-width: 600px)': {
            marginInline: "20px"
        },
    },
    certificate: {
        border: "1px solid #e3eaf4",
        borderRadius: "12px",
        padding: "4%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "20px",
        marginTop: "10px",
    },
    certificateSub1: {
        display: 'flex',
        width: "100%",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
    },
    certificateSub2: {
        paddingLeft: "5px"
    },
    certificateSub3: {
        fontSize: '14px',
        display: 'flex',
        justifyContent: 'center',
        color: '#17181D',
        marginTop: "10px"
    },
    ceriticateheading: {},
    certificateDiv: {
        display: "flex",
        gridColumnGap: "14px",
        marginTop: "6px",
        '@media(max-width: 600px)': {
            flexDirection: 'column',
            alignItems: "center"
        },
    },
    aboutParent: {
        marginInline: '30px',
    },
    about: {
        fontWeight: 'bold',
        fontSize: '14px',
        marginTop: '10px',
        marginBottom: '30px',
    },
    cardTopContentinfo: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: 10,
        paddingBottom: 12,
        borderBottom: '1px solid #e3eaf4'
    },
    cardsubcontent: {
        gridRowGap: '5px',
    },
    moneyDiv: {
        fontSize: "13px",
        color: "#42A200",
        fontWeight: 500
    },
    msgcontent: {
    },
    callcontent: {
    },
    msg: {
        display: 'flex',
        gap: '5px',
    },
    call: {
        display: 'flex',
        gap: '5px',
        paddingTop: "13px"
    },
    mail: {
        fontWeight: 'bold',
        fontSize: "14px",
        fontFamily:"urbanist"
    },
    callnumber: {
        fontWeight: 'bold',
        fontSize: "14px",
        fontFamily:"urbanist"
    },
    imgdiv: {
        height: '120px',
        width: '120px',
        "@media(max-width: 1439px)": {
            height: 'unset',
            width: '170px',
        },

    },
    starView: {
        padding: "0px 8px",
        background: "linear-gradient(225deg, #58A2FF 0%, #1777E2 100%)",
        borderRadius: "12px",
        display: "flex",
        alignItems: "center",
        justifyContent: 'space-evenly'
    },
    starrate: {
        color: "white"
    },

});

export default function AllStudioDetailCard(props: MyProps) {
    const { data, datavalue, handleStudioBadgeVisibility } = props;
    const classes = useStyles();
    const [overview, setOverview] = useState(true);
    const [departments, setDepartpments] = useState(false);
    const [vendors, setVendors] = useState(false);
    const [blockvalue, setBlockvalue] = useState(data?.attributes?.blocked)

    
    const handleclick = () => {
        setOverview(true);
        setDepartpments(false);
        setVendors(false);
    };
    const handleclick1 = () => {
        setOverview(false);
        setDepartpments(false);
        setVendors(true);
    };
    const handleclick2 = () => {
        setOverview(false);
        setDepartpments(true);
        setVendors(false);
    };
    const handleBlock = (e: any) => {
        setBlockvalue(!blockvalue)
        console.log("33333333333333", !blockvalue)
        // console.log("22222222", dataitem?.attributes?.blocked)
        handleStudioBadgeVisibility(e, data?.id, data.attributes.blocked, "studios")
    };
    return (
        <Box className={classes.parentbox}>
            <Card className={classes.card}>
                <div className={classes.cardImageDiv}>
                    <CardMedia
                        className={classes.cardImage}
                        component="img"
                        alt="Image"
                        image={data?.attributes?.image}
                    />
                    <div className={classes.overlay}></div>
                </div>
                <CardContent>
                    <Box className={classes.cardTopContent1}>
                        <Box>
                            <Typography variant="h5" component="h5" style={{ color: "#FFFFFF", fontSize: "16px", lineHeight: "19px", paddingBottom: "3px", paddingTop: "15px", fontFamily:"urbanist" }}>
                                {data?.attributes?.name}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p" style={{ color: "white", display: 'flex', alignItems: 'center', gap: '5px', fontSize: "12px", fontWeight: 500 , fontFamily:"urbanist"}}>
                                <img src={location} alt="location icon" className={classes.icon} />
                                {data?.attributes?.address}
                            </Typography>
                        </Box>
                        <Box className={classes.ratingBox}>
                            {data?.attributes?.status}

                        </Box>
                    </Box>
                    <Box className={classes.cardTopContentinfo}>
                        <Box className={classes.cardsubcontent}>
                            <Box className={classes.msg}>
                                <img src={msgicon} alt="" className={classes.iconemail} />
                                <Typography className={classes.mail}>{data?.attributes?.email}</Typography>
                            </Box>
                            <Box className={classes.call}>
                                <img src={callicon} alt="" className={classes.iconcall} />
                                <Typography className={classes.callnumber}>+{data?.attributes?.contact_number}</Typography>
                            </Box>
                        </Box>
                        <Box className={classes.moneyDiv}>
                            <Typography style={{ fontSize: "13px", fontWeight: 600 }}>₹{data?.attributes?.price_per_day}</Typography>
                            <Typography style={{ fontSize: "13px", fontWeight: 600 }}>Per Day</Typography>
                        </Box>
                    </Box>
                    <Box className={classes.cardTopContent2}>
                        <Box className={classes.cardcontent1}>
                            General</Box>
                        <Box className={classes.generaltabbox} >
                            <Box className={overview ? classes.overviews : classes.carddepart5}  onClick={handleclick}>
                                <img src={overview ? OverviewIconactive : OverviewIcon} alt="overviewicon icon" className={classes.icon1} />
                                <Box
                                   
                                    className={overview ? classes.activeText : classes.inactiveText}
                                >
                                    Overview
                                </Box>
                            </Box>
                            <Box className={vendors ? classes.overviews : classes.carddepart5}    onClick={handleclick1}>
                                <img src={vendors ? Gellaryact : Gellary} alt="Vendoricon icon" className={classes.galleryIcon} />
                                <Box className={classes.innertabbox}>
                                    <Box
                                     
                                        className={vendors ? classes.activeText : classes.inactiveText}
                                    >
                                        Gallery
                                    </Box>
                                </Box>

                            </Box>
                            <Box className={departments ? classes.overviews : classes.carddepart5} onClick={handleclick2}>
                                <img src={departments ? Reviewact : Review} alt="departmenticon icon" className={classes.galleryIcon} />
                                <Box className={classes.innertabbox}>
                                    <Box
                                        
                                        className={departments ? classes.activeText : classes.inactiveText}
                                    >
                                        Rating & Reviews
                                    </Box>
                                    <Box className={classes.starView}>
                                        <StarOutlinedIcon className="start_color" />
                                        <span className={classes.starrate}>{datavalue?.average_rating}</span>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={classes.cardTopContent3}>
                        <Box className={classes.projectBox2}>
                            <Box style={{ marginBottom: "2px" }}>
                                Owned By <Link href="#" className={classes.allPictureLink}>{data?.attributes?.production_house}</Link>
                            </Box>
                            <Box className={classes.projectBox3}>
                                {console.log("cd", datavalue)}
                                <img src={iconC} alt="" className={classes.icon} style={{ marginTop: "2px" }} />
                                <Typography className={classes.dateText}>Visiting Time {data?.attributes?.open_time} to {data?.attributes?.close_time}</Typography>
                            </Box>
                        </Box>
                        <Box
                            style={webStyle.alignContent}
                        >
                            <Typography style={webStyle.block as React.CSSProperties}>
                                {data?.attributes?.blocked ? <Typography className="block_color">Blocked{data?.attributes?.blocked}</Typography> : <Typography style={webStyle.block as React.CSSProperties}>Block</Typography>}
                                {data?.attributes?.blocked
                                    ?
                                    <img src={switchEnable}
                                        style={webStyle.blockBox}
                                        data-test-id={`${data?.id}_switch_enable`}
                                        onClick={(e: any) =>  handleStudioBadgeVisibility(e, data?.id, data.attributes.blocked, "studios")}
                                    />
                                    :
                                    <img src={switchDisable}
                                        style={webStyle.blockBox}
                                        data-test-id={`${data?.id}_switch_disable`}
                                        onClick={(e: any) =>  handleStudioBadgeVisibility(e, data?.id, data.attributes.blocked, "studios")}
                                    />
                                }
                            </Typography>
                        </Box>
                    </Box>
                </CardContent>
            </Card>

            <Box className={classes.detailbox}>
                {overview === true ? (
                    <Box className={classes.overviewdetails}>
                        <Box className={classes.Headingoverview}>
                            <Typography className={classes.overviewTitle}>Overview</Typography>
                        </Box>

                        <Box className={classes.projectoverview}>
                            <Box className={classes.subheading}>
                                Studio Cost Details
                            </Box>
                            <Box className={classes.subcontent}>
                                <Box className={classes.content1}>
                                    <span className={classes.content1day}>Per Day </span> <span className={classes.content1sub}>₹{data?.attributes?.price_per_day}</span>
                                </Box>
                                <Box className={classes.content2}>
                                    <span className={classes.content1day}>Per Hour</span> <span className={classes.content1sub}>₹{data?.attributes?.price_per_hour}</span>
                                </Box>
                            </Box>
                        </Box>
                        <Box className={classes.studiooverview}>
                            <Box className={classes.subheading}>
                                Studio for
                            </Box>
                            <Box className={classes.typestudio}>
                                <Grid container spacing={3}>
                                    {data?.attributes?.studio_type?.map((studio: any) => (
                                        <Grid
                                            className={classes.firstp}
                                            item
                                            md={4}

                                        >
                                            <Box className={classes.icon}>
                                                <img src={RightIcon} alt="icon" />
                                            </Box>
                                            <Box className={classes.heading1}>{studio}</Box>

                                        </Grid>

                                    ))}


                                </Grid>
                            </Box>
                        </Box>
                        <Box className={classes.certificateParent}>
                            <Box className={classes.subheadingcerti}>
                                Certificates & Documents
                            </Box>
                            <Box className={classes.certificateDiv}>
                                {data?.attributes?.certificates?.map((certificate: any, index: number) => (
                                    <Grid item xs={6} sm={6} md={3} lg={3} xl={3} key={index}>
                                        <Box className={classes.certificate}>
                                            <Box className={classes.certificateSub1}>
                                                <Box
                                                    style={{
                                                        backgroundColor: certificate.backgroundColor,
                                                        borderRadius: "8px",
                                                        padding: "10.2% 14%",
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: "14.61px", height: "21px" } as React.CSSProperties}
                                                        src={certificate.path_url}
                                                        alt=""
                                                    />
                                                </Box>
                                                <IconButton className={classes.downloadIcon} size="medium" onClick={() => {
                                                    fetch(certificate.path_url).then((response) => response.blob()).then((blob) => {
                                                        const blobUrl = URL.createObjectURL(blob);
                                                        const link = document.createElement("a");
                                                        link.href = blobUrl;
                                                        link.download = "image";
                                                        link.click();
                                                    });
                                                }}>
                                                    <GetAppIcon
                                                        fontSize="small"
                                                        className={classes.downloadIconColor}
                                                    />
                                                </IconButton>
                                            </Box>
                                            <Box className={classes.certificateSub3}>
                                                <span className={classes.ceriticateheading}>
                                                    {certificate.name}
                                                </span>
                                            </Box>
                                        </Box>
                                    </Grid>
                                ))}


                            </Box>
                        </Box>
                        <Box className={classes.aboutParent}>
                            <Box className={classes.subheadingcerti}>
                                About Studio
                            </Box>
                            <Box className={classes.about}>
                                {data?.attributes?.description}
                            </Box>
                        </Box>
                    </Box>
                ) : vendors === true ? (
                    <div>
                        <h1 className={classes.headingv}>Gallery</h1>
                        <Box className={classes.vendordetails}>
                            {data?.attributes?.photos?.map((da: any) => (
                                <Grid item>
                                    <Box className={classes.imgdiv}>
                                        <CardMedia
                                            component="img"
                                            alt="Image"
                                            image={da.path_url}
                                        />
                                    </Box>
                                </Grid>

                            ))}
                        </Box>
                    </div>
                ) : (
                    <div>
                        <Box className={classes.departmentdetails}>
                            <h1 className={classes.headingv}>Rating & Reviews</h1>
                            
                             <Rating data={datavalue} />
                    

                        </Box>
                    </div>
                )
                }

            </Box>

        </Box>
    ); 
}
const webStyle = {
    alignContent: {
        display: "flex",
        alignItems: "center"
    },
    blockBox: {
        marginRight: "1%",
        cursor: "pointer",
    },
    block: {
        fontSize: "13px",
        display: "flex",
        columnGap: "1%",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
    },

}