import React from "react";
import { Card, Box, CardMedia, CardContent, Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { pushpaImage, Depticon, Vendoricon, clock, iconC, location } from "./assets";
import { styled } from '@material-ui/core/styles';

interface MyProps {
  data: any;
  isSidebar:any;
}



const useStyles = makeStyles({
  cardDivWithOpenSideBar: () => ({
    width: 270,
    //height: 240,
    borderRadius: 14,
    padding: 8,
    border: "1px solid #E1E6EC",
    position: 'relative',
    '& .MuiCardContent-root': {
      padding: 0
    },
    "@media(max-width: 500px)": {
      width: 256
    },
    "@media(min-width: 1440px)": {
      width: '312px',
    },
  }),
  cardDivWithClosedSideBAr: () => ({
    width: 346,
    borderRadius: 14,
    padding: 8,
    border: "1px solid #E1E6EC",
    position: 'relative',
    '& .MuiCardContent-root': {
      padding: 0
    },
    "@media(max-width: 500px)": {
      width: 256
    },
  }),
  cardImage: {
    width: '100%',
    height: '123px',
    borderRadius: '12px',
  },
  cardImageDiv: {
    position: 'relative',
  },
  overlay: {
    position: 'absolute',
    borderRadius: '12px',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundImage: "linear-gradient(180deg, rgba(0,0,0,0.15) 0%, rgba(0,0,0,0.65) 100%)"
  },
  cardTopContent1: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'absolute',
    top: 65,
    paddingInline: 12,
    width: '88%',
    alignItems: 'end'
  },
  cardTopContent2: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 10,
    paddingBottom: 12,
    borderBottom: '2px solid #e3eaf4'
  },
  cardTopContent3: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 15,
    
  },
  ratingBox: {
    background: '#F5C42C',
    
    color: 'white',
    fontWeight: 700,
    fontSize: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: "12px",
    letterSpacing:"0.18px",
    padding:"6px 10px",
    fontFamily:"urbanist",
  },
  moreprojectBox1: {
    border: '1px solid #e2eaf4',
    borderRadius: 10,
    backgroundColor: '#f8fafe',
    width: 156,
    height: 38,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
    fontWeight: 700,
    color: 'black',
  },
  lessprojectBox1: {
    border: '1px solid #e2eaf4',
    borderRadius: 10,
    backgroundColor: '#f8fafe',
    width: 125,
    height: 38,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
    fontWeight: 700,
    color: 'black',
    fontFamily:"urbanist"
  },
  projectBox2: {
    fontSize: 12,
    color: 'black',
    fontWeight: 700,
    paddingLeft:"7px"
  },
  PostBox:{
        marginBottom:"4px",
        display: 'flex',
        alignItems: 'center',
        gap: 5,
        fontSize:'12px',
        fontFamily:'urbanist'
  },
  dateText: {
    fontSize: 12,
    color: '#6d89af',
    fontWeight: 700,
  },
  projectBox3: {
    fontSize: 13,
    color: '#6d89af',
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
    gap: 0,
    fontFamily:"urbanist"
  },
  icon: {
    marginRight: 4,
    height:"17px",
    width:"17px"
  },
  iconaddress: {
    marginRight: 4,
    height:"14px",
    width:"14px"
  },
  allPictureLink:{
    color:'rgb(51, 137, 255)',
    textDecoration:'underline',
    textDecorationColor:'rgb(51, 137, 255)',
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',  
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 180,
    fontFamily:'urbanist',
    fontSize:'12px'
  },
  cardTitle:{
    fontSize:"16px",
    lineHeight:"19px",
    marginBottom:"5px",
    fontFamily:"urbanist",
    color: "white",
    fontWeight:600
  },
  cardLocation:{
    fontSize:"12px",
    lineHeight:'14px',
    color: "white",
     display: 'flex', 
     alignItems: 'center',
     fontWeight: 500,
     fontFamily:"urbanist",
  },
  parentBox:{
      paddingBottom:"10px"
  }
});

export default function ProjectCard(props: MyProps) {

  const {isSidebar, data } = props;
  const classes = useStyles({ isSidebar});

  return (
    <Box className={classes.parentBox}>
    <Card className={isSidebar ? classes.cardDivWithOpenSideBar : classes.cardDivWithClosedSideBAr}>
      <div className={classes.cardImageDiv}>
        <CardMedia
          className={classes.cardImage}
          component="img"
          alt="Image"
          image={data.attributes.image}
        />
        <div className={classes.overlay}></div>
      </div>
      <CardContent>
        <Box className={classes.cardTopContent1}>
          <Box>
            <Typography variant="h5" component="h5" className={classes.cardTitle}>
              {data.attributes.project_name}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p"  className={classes.cardLocation}>  
              <img src={location} alt="location icon" className={classes.iconaddress} />
                {data.attributes.address}
            </Typography>
          </Box>
          <Box className={classes.ratingBox}>
            {data.attributes.status}

          </Box>
        </Box>
        <Box className={classes.cardTopContent2}>
          <Box className={isSidebar ? classes.lessprojectBox1 : classes.moreprojectBox1}>
            <img src={Vendoricon} alt="" className={classes.icon} />
            {data.attributes.vendors_count} Vendors
          </Box>
          <Box className={isSidebar ? classes.lessprojectBox1 : classes.moreprojectBox1}>
            <img src={Depticon} alt="" className={classes.icon} />
            {data.attributes.department_count} Depts.
          </Box>
        </Box>
        <Box className={classes.cardTopContent3}>
          <Box className={classes.projectBox2}>
            <Box className={classes.PostBox}>
              Posted By: <Link href="#" className={classes.allPictureLink}>{data.attributes.production_house}</Link>
            </Box>
            <Box className={classes.projectBox3}>
              <img src={iconC} alt="" className={classes.icon} />
              <Typography className={classes.dateText}>{data.attributes.start_date} - {data.attributes.end_date}</Typography>
            </Box>
          </Box>
          <Box className={classes.projectBox3}>

            <img src={clock} alt="" className={classes.icon} />
            {data.attributes.total_days} days
          </Box>
        </Box>
      </CardContent>
    </Card>
    </Box>
  );
}
