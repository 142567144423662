import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  isLoading: boolean,
  projectMemberLayoutTitle: string;
  projectMemberLayoutBreadCrumb: any[];
  renderProjectMemberLayoutTopRightContent: any;
  renderPageContent: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isProjectMemberLayoutSideBarOpen: boolean;
  isProjectMemberLayoutDropDwnOpen: boolean;
  notification: { type: string, open: boolean, message: string, route?: string };
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ProjectPHLayoutController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isProjectMemberLayoutSideBarOpen: true,
      isProjectMemberLayoutDropDwnOpen: false,
      notification: { type: "", open: false, message: "" },
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    window.scrollTo(0, 0);
    let isProjectMemberSideBarOpen = await getStorageData("sidebarvalue");
    this.setState({ isProjectMemberLayoutSideBarOpen: JSON.parse(isProjectMemberSideBarOpen) })
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
  }
  // Customizable Area End

  // Customizable Area Start
  handleProjectMemberLayoutSideBar = () => {
    this.setState({
      isProjectMemberLayoutSideBarOpen: !this.state.isProjectMemberLayoutSideBarOpen
    }, () => {
      setStorageData("sidebarvalue", this.state.isProjectMemberLayoutSideBarOpen.toString())
    })
  };

  handleNavigation = (page: string) => {
    this.props.navigation.navigate(page)
  };

  handleProjectMemberLayoutDropdownValue = () => {
    this.setState({
      isProjectMemberLayoutDropDwnOpen: !this.state.isProjectMemberLayoutDropDwnOpen
    })
  };

  handleChangeNotification = (notiType: string, notiOpen: boolean, notiMessage: string, notiRoute?: string) => {
    this.setState({ notification: { type: notiType, open: notiOpen, message: notiMessage, route: notiRoute || "" } });
  }

  handleCloseNotification = () => {
    this.setState({ notification: { type: "", open: false, message: "" } });
  }
  // Customizable Area End
}