import React, { useState } from 'react';
import { Box, Typography, Grid, List, ListItem, ListItemText, Avatar, Divider, Radio, Button, FormControlLabel, styled, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RadioGroup from '@material-ui/core/RadioGroup';
import CloseIcon from '@material-ui/icons/Close';
import { Filtericon } from "./assets";
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import TextField from '@material-ui/core/TextField';
import Rating from '@material-ui/lab/Rating';
import Badge from "@material-ui/core/Badge";
import { checkedImage , dummyImage } from "./assets";
import StarBorderIcon from '@material-ui/icons/StarBorder';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import {
    withStyles,
    createStyles,

} from "@material-ui/core/styles"
interface MyProps {
    image: any;
    ratevalue:any;
    rateHandle:any;
    commentsHandle:any;
    comments:any;
    addReviewApiCall:any;
    ratingvalidation:any;
    reviewvalidation:any;
    name:any;
}

const useStyles = makeStyles({
    ParentMain: {
        //marginTop: '20px'
    },
    HeadingRateandReview: {
        fontSize: '20px',
        fontWeight: 700,
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: '10px'
    },
    AiIcon: {
        display: 'flex',
        justifyContent: "center",
        paddingTop: '16px',
        paddingBottom: '16px'
    },
    AiText: {
        color: '#17181d',
        fontFamily: 'Urbanist',
        fontSize: '18px',
        fontWeight: 600,
        letterSpacing: '0.225px',
        paddingBottom: '2px',
        display: 'flex',
        justifyContent: "center"
    },
    ratingDiv: {
        display: 'flex',
        justifyContent: 'center',
        width: '200px'
    },
    submitButton: {
        cursor: "pointer",
        color: "#ffffff",
        backgroundColor: "#17181D",
        textTransform: "none",
        width: "160px",
        border: "none",
        borderRadius: "14px",
        //position: "absolute",
        bottom: "16px",
        height: "48px",
        fontWeight: 600,
        fontSize: "14px",
        paddingTop: "10px",
        paddingBottom: "14px",
        marginBottom: "5px",
        fontFamily: "urbanist",
    },
});
export const StyledBadge = withStyles(() =>
    createStyles({
        badge: {
            color: "#44b700",
            "&::after": {
                position: "absolute",
                right: -3,
                top: 13,
                padding: "0 4px",
                width: "100%",
                height: "100%",
                borderRadius: "50%",
                content: '""'
            }
        },
        "@keyframes ripple": {
            "0%": {
                transform: "scale(.8)",
                opacity: 1
            },
            "100%": {
                transform: "scale(2.4)",
                opacity: 0
            }
        }
    })
)(Badge);


export default function RateNowCard(props: MyProps) {
    const { image,rateHandle,ratevalue,name,reviewvalidation,ratingvalidation ,commentsHandle,comments,addReviewApiCall} = props;
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    return (
        <Box className={classes.ParentMain}>
            <Typography style={{ paddingTop: "24px", color: "#17181d", fontFamily: "Urbanist", fontSize: "21px", fontWeight: 700, lineHeight: "28px", letterSpacing: "0.2px", textAlign: "center" }}>Rate & Review</Typography>
            <Box className={classes.AiIcon}>
                <StyledBadge
                    overlap="circular"
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    badgeContent={
                        <img src={checkedImage} />
                    }
                >
                    <Avatar
                        style={webStyle.avatar}
                        src={image || dummyImage }
                    >
                    </Avatar>
                </StyledBadge>
            </Box>
            <Typography style={{ marginBottom: "16px", color: "#17181d", fontFamily: "Urbanist", fontSize: "16px", fontWeight: 700, lineHeight: "24px", letterSpacing: "0.2px", textAlign: "center" }}> {name}</Typography>
           
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
                <Box style={{ width: "calc(100% - 48px)", border: "1px solid #e3eaf4", margin: "0px 24px", height: "45px", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "13px" }}>
                    <Rating
                        name="customized-empty"
                        value={ratevalue}
                        size="medium"
                        precision={1}
                        onChange={rateHandle}
                        emptyIcon={<StarBorderIcon htmlColor="#ffb400" style={{ width: "24px", height: "24px" } as React.CSSProperties} />}
                    />
                </Box>
            </Box>
            <Typography style={{ color: "red", fontSize: '14px', paddingLeft: '25px' }}>{ratingvalidation}</Typography>
            <Box style={{ padding: "10px 24px 8px" }}>
                <Typography style={{ color: "#6d89af", fontFamily: "Urbanist", fontSize: "13px", fontWeight: 600, lineHeight: "18px", letterSpacing: "0.2px", marginBottom: "6px" }}>Review</Typography>
                <TextareaAutosize
                    style={{ width: "100%", border: "1px solid #e3eaf4", borderRadius: "14px", padding: "15px 13px", height: "90px" }}
                    className="bookstudiotextarea"
                    aria-label="minimum height"
                    minRows={3}
                    placeholder="Enter your comment..."
                    value={comments}
                    onChange={commentsHandle}
                />
            </Box>
            <Typography style={{ color: "red", fontSize: '14px', paddingLeft: '25px' }}>{reviewvalidation}</Typography>
            <Box style={{ display: 'flex', justifyContent: 'center', paddingTop: '5px' }}>
                <button className={classes.submitButton} onClick={addReviewApiCall}>
                    Submit
                </button>
            </Box>
        </Box>
    );
}
const webStyle = {
    avatar: {
        border: "3px solid #f5c42c",
        width: 62,
        height: 62,
        marginRight: 1
    },
}