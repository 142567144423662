import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isHistorySideBarOpen: boolean;
  isLoading: boolean;
  isHistoryDropdownOpen: boolean;
  historyTabValue: number;
  historyAllStudiosData: any;
  historyAllProjectsData: any;
  currentStudiosPage: number;
  getCountForPagination: any;
  notification: { type: string, open: boolean, message: string, route?: string };
  userType: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class StudiosBookingListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getHistoryAllStudiosDataMessageId: string = "";
  getHistoryAllProjectsDataMessageId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isHistorySideBarOpen: true,
      isLoading: false,
      isHistoryDropdownOpen: false,
      historyTabValue: 0,
      historyAllStudiosData: [],
      historyAllProjectsData: [],
      currentStudiosPage: 1,
      getCountForPagination: {
        0: 0,
        1: 0,
      },
      notification: { type: "", open: false, message: "" },
      userType: "",
      // Customizable Area End
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.getHistoryAllStudiosDataMessageId === apiRequestCallId) {
        this.getHistoryAllStudiosDataHandleResponse(responseJson);
      } else if (this.getHistoryAllProjectsDataMessageId === apiRequestCallId) {
        this.getHistoryAllProjectsDataHandleResponse(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getHistoryAllProjectsDataHandleResponse = async (responseJson: { meta: { total: number, message: string }, errors: { token: string }[], data: any[] }) => {
    if (responseJson.data) {
      this.setState({ historyAllProjectsData: responseJson.data });
      this.setState({ getCountForPagination: { ...this.state.getCountForPagination, 0: responseJson.meta.total } });
    } else if (responseJson.errors[0].token) {
      this.setState({ notification: { open: true, type: "error", message: responseJson.errors[0].token, route: "LandingPage" } });
    }
    this.setState({ isLoading: false });
  }

  getHistoryAllStudiosDataHandleResponse = (responseJson: { meta: { total: number, message: string }, errors: { token: string }[], data: any[] }) => {
    if (responseJson.data) {
      this.setState({ historyAllStudiosData: responseJson.data });
      this.setState({ getCountForPagination: { ...this.state.getCountForPagination, 1: responseJson.meta.total } });
    } else if (responseJson.errors[0].token) {
      this.setState({ notification: { open: true, type: "error", message: responseJson.errors[0].token, route: "LandingPage" } });
    }
    this.setState({ isLoading: false });
  }

  handleSideBar = () => {
    setStorageData("sidebarvalue", (!this.state.isHistorySideBarOpen).toString())
    this.setState({
      isHistorySideBarOpen: !this.state.isHistorySideBarOpen
    });
  };

  handleDropdown = () => {
    this.setState({
      isHistoryDropdownOpen: !this.state.isHistoryDropdownOpen
    });
  };

  handleNavigation = (page: string) => {
    this.props.navigation.navigate(page)
  };

  handleAppBarValue = (event: React.ChangeEvent<{}>, value: number) => {
    this.setState({
      historyTabValue: value,
    }, () => {
      this.handlePageChange({}, 1);
    });
  }

  async componentDidMount() {
    const userType = await getStorageData("account_type");

    this.getHistoryAllProjectsDataMessageId = await this.apiCall(configJSON.getApiMethod, userType === "production_house_account" ? configJSON.phHistoryAllProjectsAPIEndPoint + `&page=${this.state.currentStudiosPage}&per_page=9` : configJSON.historyAllProjectsAPIEndPoint + `&page=${this.state.currentStudiosPage}&per_page=9`);
    this.getHistoryAllStudiosDataMessageId = await this.apiCall(configJSON.getApiMethod, userType === "production_house_account" ? configJSON.phHistoryAllStudiosAPIEndPoint + `&page=${this.state.currentStudiosPage}&per_page=9` : configJSON.historyAllStudiosAPIEndPoint + `&page=${this.state.currentStudiosPage}&per_page=9`);

    const isSideBarOpen = await getStorageData("sidebarvalue");
    this.setState({ isHistorySideBarOpen: JSON.parse(isSideBarOpen), userType: userType });
  }

  apiCall = async (historyMethod: string, historyEndPoint: string) => {
    const authToken = await getStorageData("token");
    const historyHeader = {
      "Content-Type": configJSON.apiContentType,
      token: authToken
    };
    const historyRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setState({ isLoading: true });
    historyRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      historyEndPoint
    );
    historyRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(historyHeader)
    );
    historyRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      historyMethod
    );
    runEngine.sendMessage(historyRequestMessage.id, historyRequestMessage);
    return historyRequestMessage.messageId;
  }

  handlePageChange = async (event: any, value: number) => {
    if (this.state.historyTabValue === 0) {
      this.getHistoryAllProjectsDataMessageId = await this.apiCall(configJSON.getApiMethod, this.state.userType === "production_house_account" ? configJSON.phHistoryAllProjectsAPIEndPoint + `&page=${value}&per_page=9` : configJSON.historyAllProjectsAPIEndPoint + `&page=${value}&per_page=9`);
    } else if (this.state.historyTabValue === 1) {
      this.getHistoryAllStudiosDataMessageId = await this.apiCall(configJSON.getApiMethod, this.state.userType === "production_house_account" ? configJSON.phHistoryAllStudiosAPIEndPoint + `&page=${value}&per_page=9` : configJSON.historyAllStudiosAPIEndPoint + `&page=${value}&per_page=9`);
    }
    this.setState({ currentStudiosPage: value });
  }

  handleCloseNotification = () => {
    this.setState({ notification: { type: "", open: false, message: "" } });
  }

  statusCovertToPascalCase = (statusText: string, statusTrimSpace = false) => {
    return statusText.split(' ').map((t) => t[0].toUpperCase() + t.slice(1).toLowerCase()).join(statusTrimSpace ? '' : ' ')
  }

  getStudioText = (data: any, type: string) => {
    if (type === "image") {
      return this.getValueBasedOnType(data.studio_image, data.image);
    } else if (type === "name") {
      return this.getValueBasedOnType(data.studio?.name, data.name);
    } else if (type === "address") {
      return this.getValueBasedOnType(`${data.studio?.address}, ${data.studio?.city} (${data.studio?.state})`, `${data.address} (${data.city})`);
    } else if (type === "perDayPrice") {
      return this.getValueBasedOnType(data.studio?.price_per_day, data.price_per_day);
    } else if (type === "perHourPrice") {
      return this.getValueBasedOnType(data.studio?.price_per_hour, data.price_per_hour);
    } else if (type === "date") {
      const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
      let date = new Date(data.start_date);
      return this.getValueBasedOnType(`${date.getDate()} ${months[date.getMonth() + 1]}, ${date.getFullYear()}`, "21 Jun, 2023");
    }
  }

  getValueBasedOnType = (phValue: any, vendorValue: any) => {
    return this.state.userType === "production_house_account" ? phValue : vendorValue;
  }
  // Customizable Area End
}
