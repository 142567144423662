import React, { useState } from 'react';
import { Box, Grid, List, ListItem, ListItemText, Divider, Radio, Button, FormControlLabel, styled, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RadioGroup from '@material-ui/core/RadioGroup';
import CloseIcon from '@material-ui/icons/Close';
import { Filtericon } from "./assets";
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import TextField from '@material-ui/core/TextField';
import exp from 'constants';
interface MyProps {
    data: any;
    handleClose: any;
    selectstatus: string;
    handleOptionChange: any;
    selectstatus1:string;
    handleOptionChange1:any;
    pendingcount:any;
    approvecount:any;
    completedcount:any;
    count:any;
    getAppliedinvoiceList:any;
    allcount:any;
    ClearDataValues:any;
    onhold:any;
    rejected:any;

}
const TableGrid = styled(Grid)({
    '& .MuiGrid-root::-webkit-scrollbar': {
        display: 'none',
    },
    '& .MuiGrid-root': {
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none',
        "@media screen and (max-width: 500px)": {

            paddingLeft: '10px'
        }
    },
    '& .MuiFormControlLabel-label::-webkit-scrollbar': {
        display: 'none',
    },
    '& .MuiFormControlLabel-label': {
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none',
    },
})
const CustomFormControlLabel = styled(FormControlLabel)({
    '& .MuiFormControlLabel-label': {
        textAlign: 'left',
        color: 'black',
        fontWeight: 600,
        fontSize: '14px',
        //overflowY: 'scroll'
    },
    '& .Mui-checked + .MuiFormControlLabel-label': {
        color: '#F5C42C'
    },
    '& .MuiRadio-colorSecondary.Mui-checked': {
        color: '#f5c42c',

    },
    '& .MuiList-padding': {
        paddingTop: 0,
        paddingBottom: 0,
    },
    '&.MuiTypography-body1': {


        fontWeight: 700,

    }
});
const SearchIcon1 = styled(SearchOutlinedIcon)({
    padding: "10px 0 10px 13px",
    "@media(max-width: 500px)": {
        padding: "10px 0 10px 8px",
        fontSize: '18px'
    },
});
const SearchInput1 = styled('input')({
    fontSize: "15px",
    outline: "none",
    border: "none",
    width: "85%",
    padding: "10px 3px",
    backgroundColor: "#F8FAFE",
    "@media(max-width: 500px)": {
        fontSize: '12px',
        overflow: 'hidden',
        width: "70%",
        padding: "10px 0px",
    },
});
const CustomFormControlLabel1 = styled(FormControlLabel)({
    '& .MuiFormControlLabel-label': {
        textAlign: 'left',
        color: '#17181d',
        fontWeight: '500',
        fontSize: "14px",
        height: 44,
        overflowY: 'scroll'
    },
    '& .Mui-checked + .MuiFormControlLabel-label': {
        color: '#F5C42C'
    },
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
        color: '#F5C42C'
    },
    '& .MuiIconButton-colorSecondary': {
        //color: '#f50057',
    }

});


const useStyles = makeStyles({
    ParentMain: {


        //marginTop: '20px'
    },
    ParentDiv: {

        border: '10px 1px solid #d8d8d8',
        textAlign: 'center',
        width: 630,
        minHeight: '499px',
        "@media screen and (max-width: 500px)": {

            width: '400px',
            marginRight: "0px",

        }


    },
    HeaderDiv: {
        fontSize: "20px",
        textAlign: 'center',
        fontWeight: 700,
        paddingTop: "20px",
        paddingBottom: "10px",
        display: 'flex',
        justifyContent: 'end',
        gap: '295px',
        paddingRight: '20px'


    },
    filterDiv: {
        fontSize: "20px",
        fontWeight: 700,
        fontFamily: "urbanist",
        color: '#17181d'
    },
    InvoiceStatus: {
        paddingBottom: '24px'
    },
    InvoiceType: {},
    InvoiceHeading: {
        color: '#17181d',
        fontFamily: 'Urbanist',
        fontSize: '16px',
        fontWeight: 700,
        letterSpacing: '0.16px',
        paddingLeft: '24px',
        display: 'flex'
    },
    buttonGroupDiv: {
        paddingTop: "10px",

    },
    stateDiv: () => ({
        "@media screen and (max-width: 500px)": {

            display: 'flex',
            flexDirection: 'column'
        }

    }),
    button1: {
        border: '1px solid #d8d8d8',
        height: '48px',
        width: '266px',
        '&.MuiTypography-body1': {

            fontSize: '10px'
        },
    },
    FooterDiv: {

        paddingBottom: '14px',
        justifyContent: 'end',
        paddingRight: '10px',
        display: 'flex',
        gap: '5px',
        paddingTop: '20px'

    },
    button3: {
        cursor: "pointer",
        color: "black",
        backgroundColor: "#ffffff",
        textTransform: "none",
        width: "165px",
        borderRadius: "14px",
        //position: "absolute",
        bottom: "16px",
        height: "48px",
        fontWeight: 600,
        fontSize: "14px",
        paddingTop: "10px",
        paddingBottom: "14px",
        marginBottom: "5px",
        fontFamily: "urbanist",
        border: '1px solid #17181d'



    },
    submitButton: {
        cursor: "pointer",
        color: "#ffffff",
        backgroundColor: "#17181D",
        textTransform: "none",
        width: "165px",
        border: "none",
        borderRadius: "14px",
        bottom: "16px",
        height: "48px",
        fontWeight: 600,
        fontSize: "14px",
        paddingTop: "10px",
        paddingBottom: "14px",
        marginBottom: "5px",
        fontFamily: "urbanist",
    },
})




export default function InvoiceSortFunctionality(props: MyProps) {
    const { handleClose,onhold,rejected, allcount,ClearDataValues,data,count,getAppliedinvoiceList, handleOptionChange, selectstatus ,handleOptionChange1,selectstatus1,pendingcount,approvecount,completedcount} = props
    const [selectedValue, setSelectedValue] = useState('');

    const handleChange = (event: any) => {
        setSelectedValue(event.target.value);
    };
    const classes = useStyles();
    const buttonData1 = [
        { id: 1, name: `All (${count})`, value: '' },
        { id: 2, name: `Pending (${pendingcount})`, value: 'pending' },
        { id: 3, name: `Approved (${approvecount})`, value: 'approved' },
        { id: 4, name: `On Hold (${onhold})`, value: 'holded' },
        { id: 5, name: `Completed (${completedcount})`, value: 'completed' },
        { id: 6, name: `Rejected (${rejected})`, value: 'rejected' }


    ]
    const buttonData2 = [
        { id: 1, name: `All (${count})` , value: '' },
        {id:2,name:"Standard Pay Invoice",value:'standard_pay'},
        {id:3,name:"Cash Invoice",value:'cash_invoice'},
        {id:4,name:"Special Invoice",value:'special_payment'}
    ]



    return (
        <Grid className={classes.ParentMain}>
            <Grid className={classes.ParentDiv}>
                <Grid className={classes.HeaderDiv}>
                    <Grid className={classes.filterDiv}>
                        Sort By
                    </Grid>
                    <Grid className={classes.filterDiv}>
                        <CloseIcon onClick={handleClose} style={{ color: "#6d89af" }} />
                    </Grid>
                </Grid>
                <Grid className={classes.InvoiceStatus}>
                    <Grid className={classes.InvoiceHeading}>
                        Invoice Status
                    </Grid>
                    <Grid style={{ paddingTop: "16px", paddingLeft: '15px' }} className={classes.buttonGroupDiv}>
                        <RadioGroup value={selectedValue} onChange={handleChange}>
                            <Grid container spacing={3} xs={12} className={classes.stateDiv}>
                                {buttonData1.map((button, index) => (
                                    <Grid item xs={6} key={index}>
                                        <Button
                                            className={classes.button1}
                                            style={{
                                                border: selectstatus === button.value ? '1px solid #f5c42c' : '1px solid #e3eaf4',
                                                borderRadius: '16px'
                                            }}
                                            onClick={() => handleOptionChange(index, button.value)}
                                        >
                                            <CustomFormControlLabel
                                                value={button.value}
                                                label={button.name}
                                                control={<Radio checked={selectstatus === button.value} />}
                                                labelPlacement="start"
                                                style={{
                                                    textTransform: 'none',
                                                    display: 'flex',
                                                    width: '100%',
                                                    justifyContent: 'space-between',
                                                    borderColor: "red"
                                                }}
                                            />
                                        </Button>
                                    </Grid>
                                ))}
                            </Grid>
                        </RadioGroup>
                    </Grid>



                </Grid>
                <Grid className={classes.InvoiceType}>
                    <Grid className={classes.InvoiceHeading}>
                        Invoice Type
                    </Grid>
                    <Grid style={{ paddingTop: "16px", paddingLeft: '15px' }} className={classes.buttonGroupDiv}>
                        <RadioGroup value={selectedValue} onChange={handleChange}>
                            <Grid container spacing={3} xs={12} className={classes.stateDiv}>
                            {buttonData2.map((button, index) => (
                                    <Grid item xs={6} key={index}>
                                        <Button
                                            className={classes.button1}
                                            style={{
                                                border: selectstatus1 === button.value ? '1px solid #f5c42c' : '1px solid #e3eaf4',
                                                borderRadius: '16px'
                                            }}
                                            onClick={() => handleOptionChange1(index, button.value)}
                                        >
                                            <CustomFormControlLabel
                                                value={button.value}
                                                label={button.name}
                                                control={<Radio checked={selectstatus1 === button.value} />}
                                                labelPlacement="start"
                                                style={{
                                                    textTransform: 'none',
                                                    display: 'flex',
                                                    width: '100%',
                                                    justifyContent: 'space-between',
                                                    borderColor: "red"
                                                }}
                                            />
                                        </Button>
                                    </Grid>
                                ))}
                            </Grid>
                        </RadioGroup>
                    </Grid>



                </Grid>
                <Grid className={classes.FooterDiv}>
                    <button
                        className={classes.button3} onClick={ClearDataValues}>
                        Clear
                    </button>
                    <button
                        className={classes.submitButton} onClick={getAppliedinvoiceList}>
                        Apply
                    </button>
                </Grid>


            </Grid>
        </Grid>

    );
}
