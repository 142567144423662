
import React from "react";
import { Grid, TextField, InputLabel, Switch, Dialog, DialogTitle, Box, DialogContent, DialogActions, withStyles, styled } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';


ArrowBackIcon
import { CustomButton } from "../../blocks/email-account-login/src/CustomButton";


const useStyles = makeStyles({
    dialogParent: { width: 795, margin: "0px auto" },
    permissionDiv: {
        boxSizing: "border-box",
        height: "48px",
        padding: "12px",
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        borderRadius: "14px",
    },

});
const CustomInputlabel = withStyles({
    root: {
        fontFamily: 'Urbanist',
        color: '#f5c42c',
        fontWeight: 600,
        "@media (max-width : 1440px)": {
            fontSize: '14px'
        },
        "@media (min-width : 1441px)": {
            fontSize: '14px',
            lineHeight: "14px",
            paddingBottom: "6px",
            paddingTop: "14px"

        }
    },
})(InputLabel);
const CustomCSSOfTextField = withStyles({
    root: {
        width: "100%",
        backgroundColor: 'white',
        borderColor: "#f5c42c",
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#E3EAF4",

            },
            "&:hover fieldset": {
                borderColor: "#f5c42c"
            },
            "&.Mui-focused fieldset": {
                borderColor: "#f5c42c",
                backgroundColor: 'rgba(245,196,44,0.04)',
            },
            fontWeight: 400,
            color: '#323232',
            borderRadius: "14px",
            "& .MuiOutlinedInput-input": {
                fontWeight: 600,
                // color: '#17181D',
                lineHeight: '19px',
                borderColor: '#CCCCCC',
                fontSize: '14px',
            }
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#f5c42c"
        },
        "& .MuiInputBase-input::placeholder": {
            color: '#6d89af',
            fontWeight: 500,
            fontFamily: 'Urbanist',
        },
        '& .MuiInputBase-input[type="date"]': {
            textTransform: "uppercase",
            fontWeight: 500,
            color: "#6D89AF",
            '&::after': {
                color: 'red',
            },
        },
    }
})(TextField);

const CustomNextStepButtonBox = styled(CustomButton)({
    '& .MuiButton-label': {
        fontSize: 14,
        color: '#ffffff',
        fontWeight: 500,
        width: 163,
        height: 48,
    }
});
const CustomSwitch = withStyles((theme) => ({
    root: {
        width: 28,
        height: 16,
        padding: 0,
        display: "flex",
        borderRadius: "24px",
    },
    switchBase: {
        padding: 2,
        color: "white",

        "&$checked": {
            transform: "translateX(12px)",
            color: theme.palette.common.white,
            "& + $track": {
                opacity: 1,
                backgroundColor: "black",
                borderColor: "black",
            },
        },
    },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: "none",
    },
    track: {
        border: `1px solid black`,
        borderRadius: "14px",
        opacity: 1,
        backgroundColor: "black",
    },
    checked: {},
}))(Switch);

export default function GivePermissionsModal(props: any) {
    const classes = useStyles();
    //   const {addNewMemberModaltab}=props
    const activeEmployeePermissions = props?.permissionList?.filter((permission: any) =>
        permission.id === props.activeEmployeeId
    ).map((permission: any) =>
        permission?.permissions?.map((ele: any) => ele)
    )[0];

    return (
        <>
            <Dialog
                className={classes.dialogParent}
                fullWidth={true}
                open={props.permissionMOdal}
                onClose={props.handleGivePermissionModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" >
                    <Box style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>

                        </span>
                        {<span>Permissions</span>}

                        <span data-test-id="addDepartmentCloseBtn" onClick={props.handleGivePermissionModal}><CloseIcon /></span>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    {
                        <>
                            <Grid container md={12} style={{ width: "100%", display: "grid", gridTemplateColumns: " repeat(2, 1fr)", gap: "16px" }} >

                                {activeEmployeePermissions?.map((permission: any, index: number) => (
                                    <div className={classes.permissionDiv} style={{ border: `1px solid ${permission.checked ? "#F5C42C" : "#E3EAF4"}` }}>
                                        <span>{permission.label}</span>
                                        <CustomSwitch
                                            name={permission.label}
                                            checked={permission.checked}
                                            onClick={() => { props.handleChangePermissionAccess(props.activeEmployeeId, permission.id) }}
                                        />
                                    </div>
                                ))}

                            </Grid>
                        </>

                    }
                </DialogContent>
                <DialogActions style={{ padding: "0px 19px 24px" }}>
                    <CustomNextStepButtonBox data-test-id="next_button" className={"${classes.dialogActionBtn} button"} onClick={() => props.handleSaveGivenPermission(props?.data?.id)}>
                        Save Permissions
                    </CustomNextStepButtonBox>
                </DialogActions>
            </Dialog>

        </>
    );
}
