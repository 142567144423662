import React, { useState } from 'react';
import { Box, Grid, List, ListItem, ListItemText, Divider, Radio, Button, FormControlLabel, styled, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RadioGroup from '@material-ui/core/RadioGroup';
import CloseIcon from '@material-ui/icons/Close';
import { Filtericon } from "./assets";
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
interface MyProps {
    handleClose:any;
    data:any;
    raiseinvoicetype:any;
    handleOptionChange1:any;
    jobrolevalue:any;
    amountdatalist:any
    departmentlist:any;
    jobroledatalist:any;
    statusvalue:any;
    amountvalue:any;
    departmentvalue:any;
    handleOptionChangeamount:any;
    handleOptionChangeproject:any;
    handleOptionChangestatus:any;
    handleOptionChangedepartment:any;
    getprojectlistfilter:any;
    projectvalue:any;
    handleChangeapply:any;
    ClearDataValuesfilter:any;
    
    
}

const TableGrid = styled(Grid)({
    '& .MuiGrid-root::-webkit-scrollbar': {
        display: 'none',
        width:'100%'
    },
    '& .MuiGrid-root': {
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none',
        width:"100%"
    },
    '& .MuiFormControlLabel-label::-webkit-scrollbar': {
        display: 'none',
    },
    '& .MuiFormControlLabel-label': {
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none',
    },
})
const CustomFormControlLabel = styled(FormControlLabel)({
    '& .MuiFormControlLabel-label': {
        textAlign: 'left',
        color: '#17181d',
        fontWeight: '500',
        fontSize: "14px",
        height: 44,
        display:'flex',
        alignItems:'center',
        overflowY: 'scroll'
    },
    '& .Mui-checked + .MuiFormControlLabel-label': {
        color: '#F5C42C'
    },
    '& .MuiRadio-colorSecondary.Mui-checked': {
        color: '#F5C42C'
    },
    '& .MuiTypography.MuiFormControlLabel-label': {
        color: '#F5C42C'
    },
    '& .MuiList-padding': {
        paddingTop: 0,
        paddingBottom: 0,
    },
    '&.MuiTypography-body1': {


        fontWeight: 700,

    }
});
const SearchIcon1 = styled(SearchOutlinedIcon)({
    padding: "10px 0 10px 13px",
    "@media(max-width: 500px)": {
        padding: "10px 0 10px 8px",
        fontSize: '18px'
    },
});
const SearchInput1 = styled('input')({
    fontSize: "15px",
    outline: "none",
    border: "none",
    width: "85%",
    padding: "10px 3px",
    backgroundColor: "#F8FAFE",
    "@media(max-width: 500px)": {
        fontSize: '12px',
        overflow: 'hidden',
        width: "70%",
        padding: "10px 0px",
    },
});
const CustomFormControlLabel1 = styled(FormControlLabel)({
    '& .MuiFormControlLabel-label': {
        textAlign: 'left',
        color: '#17181d',
        fontWeight: '500',
        fontSize: "14px"
    },
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
        color: '#F5C42C',
        borderColor:'black'
    },
    '& .MuiIconButton-colorSecondary': {
        borderColor:'black'
    }

});


const useStyles = makeStyles({
    ParentDiv: {

        width: "720px",
        border: '10px 1px solid #d8d8d8',
        textAlign: 'center',
    }
    ,
    HeaderDiv: {
        fontSize: "20px",
        textAlign: 'center',
        fontWeight: "bold",
        borderBottom: "1px solid #E3EAF4",
        paddingTop: "20px",
        paddingBottom: "20px",
        display: 'flex',
        justifyContent: 'end',
        gap: '295px',
        paddingRight: '20px',
        fontFamily: 'urbanist',



    },
    MainDiv: {
        display: 'flex',
        borderRight: "1px solid #d8d8d8",
        borderBottom: "1px solid #d8d8d8",
    },
    SideDiv: {
        minWidth: "140px",
        borderRight: "1px solid #d8d8d8",
        marginRight: '10px',
        backgroundColor: '#f8fafe',

        '& .MuiList-padding': {
            paddingTop: '0px',
            paddingBottom: '0px',
        }
    },
    main: {
        paddingBottom: "58px",
        paddingRight: '20px',
        width:'100%'
    },
    SearchDiv: {
        display: "flex",
        border: '1px solid  #6D89AF',
        width: "286px",
        borderRadius: "35px",
        alignItems: "center",
        backgroundColor: "#ffffff",
        overflowX: 'hidden',
        height: "44px",
        marginBottom: '6px',
        "@media screen and (max-width: 500px)": {
            minWidth: "100px",
            width: '160px',
            marginRight: "0px",
        }
    },
    TableDiv: {
        marginTop: "16px",
        display: 'flex',
        gridRowGap: '10px',

    },
    buttonGroupDiv: {
        paddingTop: "10px",
        overflowY: 'auto',
        height: '204px'
    },

    FooterDiv: {

        paddingTop: "20px",
        paddingBottom: '20px',
        justifyContent: 'end',
        paddingRight: '10px',
        display: 'flex',
        gap: '5px'


    },
    list1: {
        display: "flex",
        gap: '10px'
    },
    list2: {
        display: "flex",
        gap: '10px'
    },
    list3: {
        display: "flex",
        gap: '10px'
    },
    list4: {
        display: "flex",
        gap: '10px'
    },
    button1: {
        border: '1px solid #d8d8d8',
        height: '43px',
        width: '156px',
        '&.MuiTypography-body1': {

            fontSize: '10px'
        },
    },
    button2: {
        border: '1px solid #d8d8d8',
        height: '45px',
        width: '171px',
        '&.MuiTypography-body1': {

            fontSize: '10px'
        },
    }
    ,
    cityDiv: {
        paddingTop: "10px"
    },

    button3: {
        cursor: "pointer",
        color: "black",
        backgroundColor: "#ffffff",
        textTransform: "none",
        width: "165px",
        borderRadius: "14px",
        bottom: "16px",
        height: "48px",
        fontWeight: 600,
        fontSize: "14px",
        paddingTop: "10px",
        paddingBottom: "14px",
        marginBottom: "5px",
        fontFamily: "urbanist",
        border: '1px solid #17181d',
    },
    button4: {
        border: '1px solid #d8d8d8',
        width: '156px',
        fontWeight:600,
        fontSize: '15px',
        borderRadius: "15px",
        height: "43px",
        color: "white",
        backgroundColor: 'black'



    },
    ParentMain: {
        display: 'flex',
        justifyContent: 'center',
        borderRadius: '15px',
    }
    ,
    activeText: {
        color: '#f5c42c',
        fontWeight: 600,
        cursor: "pointer",
        fontSize: "14px",
        fontFamily: "urbanist",
        lineHeight: "19px",
        backgroundColor: "#ffffff",
        borderColor: '#979797',
        '&.MuiListItem-gutters': {
            paddingLeft: '16px',
            paddingRight: '16px',
            paddingTop: '16px',
            paddingBottom: '16px',
        }

    },
    inactiveText: {
        color: "#17181d",
        cursor: "pointer",
        fontWeight: 600,
        fontSize: "14px",
        fontFamily: "urbanist",
        lineHeight: "19px",
        backgroundColor: "#f8fafe",
        borderColor: '#979797',
        '&.MuiListItem-gutters': {
            paddingLeft: '16px',
            paddingRight: '16px',
            paddingTop: '16px',
            paddingBottom: '16px',
        }
    },
    submitButton: {
        cursor: "pointer",
        color: "#ffffff",
        backgroundColor: "#17181D",
        textTransform: "none",
        width: "165px",
        border: "none",
        borderRadius: "14px",
        bottom: "16px",
        height: "48px",
        fontWeight: 600,
        fontSize: "14px",
        paddingTop: "10px",
        paddingBottom: "14px",
        marginBottom: "5px",
        fontFamily: "Urbanist",
    },
    searchicon: {
        marginRight: '6px',
        fill: '#6D89AF',
        fontSize: "14px",
        paddingTop: '14px',
    },
    selectedButton:()=>( {
        border: '1px solid yellow',
        borderRadius: '12px' 
    }),
    unselectedButton:()=>( {
        borderRadius: '12px'
    }),
   stateDiv :() => ( {

    }),
    filterDiv:({}),
    filterDivvalue:({})

});



export default function Filtermytask(props: MyProps) {
    const { handleClose,jobroledatalist,handleOptionChangeamount,handleOptionChangeproject,projectvalue,handleOptionChangedepartment,handleOptionChangestatus,statusvalue,departmentvalue,amountvalue,getprojectlistfilter,data,raiseinvoicetype,handleOptionChange1,jobrolevalue,amountdatalist,departmentlist,handleChangeapply,ClearDataValuesfilter} = props;
    const classes = useStyles();
    const [selectedButtonvalue, setSelectedButtonvalue] = useState(null);

    const handleButtonClick = (index:any) => {
        setSelectedButtonvalue(index);
    };
    const [state, setState] = useState(true);
    const [city, setCity] = useState(false)
    const [status, setStatus] = useState(false)
    const [experience, setExperience] = useState(false);
    const [designation, setDesignation] = useState(false);
    const buttonData = [
        "Maharashtra",
        "Madhya Pradesh",
        "Delhi",
        "Gujrat",
        "Uttar Pradesh",
        "Jammu & Kashmir",
        "Sikkim",
        "Rajasthan",
        "Tamil Nadu",
        "Manipur",
        "Chattisgrah",
        "Kerala",
    ]
    const buttonData1 = [
        "Banglore",
        "Mumbai",
        "Indore",
        "Ujjain",
        "Agra",
        "Ratlam",
        "pune",
        "Chandigarh",
        "Ahmedabad",
        "Kolkata",
        "Delhi",
        "Jaipur",

    ]
    const handleclick = () => {
        setState(true)
        setCity(false)
        setStatus(false)
        setDesignation(false);
        setExperience(false);
    };
    const handleclick1 = () => {
        setState(false)
        setCity(true)
        setStatus(false)
        setDesignation(false);
        setExperience(false)
    };
    const handleclick2 = () => {
        setState(false)
        setCity(false)
        setStatus(true)
        setDesignation(false);
        setExperience(false);
    };
    const handleclick3 = () => {
        setDesignation(true);
        setCity(false);
        setState(false);
        setStatus(false);
        setExperience(false);
    }
    const handleclick4 = () => {
        setDesignation(false);
        setCity(false);
        setState(false);
        setStatus(false);
        setExperience(true);
    }
    const [selectedValue, setSelectedValue] = useState('');

    const handleChange = (event:any) => {
        setSelectedValue(event.target.value);
    };

    const [selectedValues, setSelectedValues] = useState<string[]>([]);
    const [selectedValuesRole,setSelectedValuesRole]=useState<string[]>([]);
    const toggleCheckbox = (value:string) => {
        if (selectedValues.includes(value)) {
            setSelectedValues(selectedValues.filter((val) => val !== value));
        } else {
            setSelectedValues([...selectedValues, value]);
        }
    };
    const toggleCheckboxdesignation = (value:string) => {
        if (selectedValuesRole.includes(value)) {
            setSelectedValuesRole(selectedValuesRole.filter((val) => val !== value));
        } else {
            setSelectedValuesRole([...selectedValuesRole, value]);
        }
    };
    
    const [selectedOption, setSelectedOption] = useState(null);
    const handleOptionChange = (index:any,state:any) => {
        setSelectedOption(index);
        console.log("22222",state.iso2)
        
        
    };

    return (
        <Grid className={classes.ParentMain}>
            <Grid className={classes.ParentDiv}>
                <Grid className={classes.HeaderDiv}>
                    <Grid className={classes.filterDivvalue}>
                        Filter
                    </Grid>
                    <Grid className={classes.filterDiv}>
                        <CloseIcon onClick={handleClose} />
                    </Grid>
                </Grid>
                <Grid className={classes.MainDiv}>
                    <Grid className={classes.SideDiv}>
                        <List>
                            <ListItem className={state ? classes.activeText : classes.inactiveText} onClick={handleclick}>
                                <ListItemText primary="Job Role" />
                            </ListItem>
                            <Divider />
                            <ListItem className={city ? classes.activeText : classes.inactiveText} onClick={handleclick1}>
                                <ListItemText primary="Deparment" />
                            </ListItem>
                            <Divider />
                            <ListItem className={designation ? classes.activeText : classes.inactiveText} onClick={handleclick3}>
                                <ListItemText primary="Project" />
                            </ListItem>
                            <Divider />
                            <ListItem className={experience ? classes.activeText : classes.inactiveText} onClick={handleclick4}>
                                <ListItemText primary="Invoice Amount" />
                            </ListItem>
                            <Divider />
                            <ListItem className={status ? classes.activeText : classes.inactiveText} onClick={handleclick2}>
                                <ListItemText primary="Invoice Raised" />
                            </ListItem>
                            <Divider />
                        </List>
                    </Grid>

                    <Grid className={classes.main} >

                        <TableGrid className={classes.TableDiv}>
                            {state === true ? (
                                <Grid >
                                   
                                <Grid className={classes.buttonGroupDiv}>
                                    <RadioGroup value={selectedValue} onChange={handleChange} >
                                        <Grid container spacing={1} xs={12} className={classes.stateDiv}>
                                        {jobroledatalist.map((state: any, index: number) => (
                                                <Grid item xs={4} key={index}>
                                                    <Button
                                                        
                                                        className={classes.button1}
                                                        style={{
                                                            border: jobrolevalue === state ? '1px solid #f5c42c' : '1px solid #e3eaf4',
                                                            borderRadius: '16px'
                                                        }}
                                                        onClick={() => handleOptionChange1(index, state)}
                                                    
                                                    >
                                                        <CustomFormControlLabel
                                                            value={state}
                                                            label={state}
                                                            control={<Radio checked={jobrolevalue === state} />}
                                                            labelPlacement="start"

                                                          

                                                            style={{ textTransform: 'none', display: 'flex', width: '100%', justifyContent: 'space-between',color: jobrolevalue === state ? 'yellow' : 'transparent', }}

                                                        />
                                                    </Button>
                                                </Grid>

                                            ))}      
                                            </Grid>
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                            ) : city === true ? (
                                <Grid>
                                   
                                   <Grid className={classes.buttonGroupDiv}>
                                        <RadioGroup value={selectedValue} onChange={handleChange} >
                                            <Grid container spacing={1} xs={12} className={classes.stateDiv}>
                                            {departmentlist?.map((state:any, index:number) => (
  <Grid item xs={4} key={index}>
    <Button
      className={classes.button1}
      style={{
        border: departmentvalue === state ? '1px solid #f5c42c' : '1px solid #e3eaf4',
        borderRadius: '16px'
      }}
      onClick={() => {
        handleOptionChangedepartment(index, state);
        
      }}
    >
      <CustomFormControlLabel
        value={state}
        label={state}
        control={<Radio checked={departmentvalue === state} />}
        labelPlacement="start"
        style={{
          textTransform: 'none',
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          
        }}
      />
    </Button>
  </Grid>
))}
                                            </Grid>
                                        </RadioGroup>
                                    </Grid>
                                </Grid>


                            ) : designation === true ? (
                                <Grid className={classes.buttonGroupDiv}>
                                <Grid container spacing={1} xs={12} className={classes.cityDiv}>

                                {getprojectlistfilter?.map((state:any, index:number) => (
<Grid item xs={4} key={index}>
<Button
  className={classes.button1}
  style={{
    border: projectvalue === state ? '1px solid #f5c42c' : '1px solid #e3eaf4',
    borderRadius: '16px'
  }}
  onClick={() => {
    handleOptionChangeproject(index, state);
    
  }}
>
  <CustomFormControlLabel
    value={state}
    label={state}
    control={<Radio checked={projectvalue === state} />}
    labelPlacement="start"
    style={{
      textTransform: 'none',
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
    
    }}
  />
</Button>
</Grid>
))}
                                </Grid>
                                </Grid>

                            ) : experience === true ? (
                                <Grid className={classes.buttonGroupDiv}>
                                <Grid container spacing={1} xs={12} className={classes.cityDiv}>

                                {amountdatalist.map((state:any, index:number) => (
<Grid item xs={4} key={index}>
<Button
  className={classes.button1}
  style={{
    border: amountvalue === state ? '1px solid #f5c42c' : '1px solid #e3eaf4',
    borderRadius: '16px'
  }}
  onClick={() => {
    handleOptionChangeamount(index, state);
    
  }}
>
  <CustomFormControlLabel
    value={state}
    label={state}
    control={<Radio checked={amountvalue === state} />}
    labelPlacement="start"
    style={{
      textTransform: 'none',
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      
    }}
  />
</Button>
</Grid>
))}
                                </Grid>
                                </Grid>

                            ) : (
                                <Grid>
                                   
                                <Grid className={classes.buttonGroupDiv}>
                               <Grid container spacing={1} xs={12} className={classes.cityDiv}>
                               {Object?.entries(raiseinvoicetype).map(([status, count], index) => (
<Grid item xs={4} key={index}>
<Button
 className={classes.button1}
 style={{
   border: statusvalue === status ? '1px solid #f5c42c' : '1px solid #e3eaf4',
   borderRadius: '16px'
 }}
 onClick={() => {
   handleOptionChangestatus(index, status);
   //handleValue(count); // Assuming you want to handle the count as well
 }}
>
 <CustomFormControlLabel
   value={status}
   label={`${status} (${count})`}
   control={<Radio checked={statusvalue === status} />}
   labelPlacement="start"
   style={{
     textTransform: 'none',
     display: 'flex',
     width: '100%',
     justifyContent: 'space-between',
    
   }}
 />
</Button>
</Grid>
))}

                               </Grid>
                               </Grid>
                           </Grid>

                            )



                            }

                        </TableGrid>
                    </Grid>

                </Grid>
                <Grid className={classes.FooterDiv}>
                    <button
                        className={classes.button3} onClick={ClearDataValuesfilter} >
                        Clear All
                    </button>
                    <button
                        className={classes.submitButton}
                     onClick={handleChangeapply}
                    >
                        Apply
                    </button>
                </Grid>
            </Grid>
        </Grid>

    );
}
