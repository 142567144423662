import React from "react";

import {
  Grid,
  Typography,
  Box,
  AppBar,
  Tabs,
  Tab,
  Menu,
  MenuItem,
  // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import ProjectMembersJobRoleController, { Props } from "./ProjectMembersJobRoleController";
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import "./fullVisibility.css";
import ProjectMemberLayout from "./ProjectMemberLayout.web";
import ManageAccessibilityModel from "../../../components/src/ManageAccessibilityModel";
import AddNewEmployeeModal from "../../../components/src/AddNewEmployeeModal";
import Pagination from "@material-ui/lab/Pagination";
import {dummyImage} from "../../../components/src/assets";
import {bagIcon} from "./assets"
const images = require("./assets");
// Customizable Area End

export default class ProjectMembersJobRole extends ProjectMembersJobRoleController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderProjectMembersJobRoleTopRightContent() {
    return <>
    {this.state.tabValue==0 ?
      <Box style={webStyle.AddEmpBoxLayout} onClick={() => this.handleModelOpen("addEmployee")}>
        + Add Employee
      </Box>:
      ""
      }
    </>
  }

  renderProjectMembersJobRoleMainContent() {
    return <>
      <Grid item sm={12} xl={12} xs={12} md={12} lg={12} style={{ paddingTop: "18px" }}>
        <AppBar
          position="static"
          className="appbar app_bar"
          color="transparent"
        >
          <Tabs
            value={this.state.tabValue}
            data-test-id="tabChange"
            variant="scrollable"
            onChange={this.handleAppBarValue}
            TabIndicatorProps={{ style: { height: '3px', background: "#f5c42c" } as React.CSSProperties }}
            scrollButtons="on"
            className="tabs_main"
            aria-label="scrollable prevent tabs example"
          >
            <Tab className={this.state.tabValue == 0 ? "active_btn" : "non_active_btn"} label="Pipeline Employees" />
            <Tab className={this.state.tabValue == 1 ? "active_btn" : "non_active_btn"} label="Project Vendors" />
          </Tabs>
        </AppBar>
      </Grid>
      <Grid container spacing={3} style={{ marginTop: "12px" }}>
        {this.state.tabValue === 0 ? <>
          {this.state.projectMembersJobRole.length ? this.state.projectMembersJobRole.map((employee: any) => {
            return <Grid item xs={12} sm={6} md={4} key={`${employee.id}_projectmemberlist`}>
              <Box style={webStyle.listBox as React.CSSProperties}>
                <Box style={webStyle.uparBox}>
                  <Box style={{ display: "flex" }}>
                    <img src={employee.attributes.photo || dummyImage} style={webStyle.imageStyle} />
                    <Box>
                      <Typography style={webStyle.empName}>{employee.attributes.full_name}</Typography>
                      <Typography style={webStyle.depName}>{employee.attributes.project_department?.name || ""} | <span style={{ color: "#3389FF" }}>Lvl {employee.attributes.project_department?.level || 0}</span></Typography>
                      <Typography style={webStyle.designation}>{employee.attributes.role.name}</Typography>
                    </Box>
                  </Box>
                  <Box>
                    <MoreHorizIcon data-test-id={`${employee.id}_MoreHorizIcon`} onClick={(e: any) => this.handleMenuOpen(e, employee.id)} htmlColor="#6D89AF" style={webStyle.moreIcon} />
                    <Menu
                      id="account-menu"
                      className="removeFromProjectMenu"
                      anchorEl={this.state.anchorEl}
                      open={Boolean(this.state.anchorEl)}
                      onClose={this.handleMenuClose}
                      MenuListProps={{
                        'aria-labelledby': 'account-button',
                      }}
                      PaperProps={{
                        style: {
                          display: "flex",
                          alignItems: "center",
                          width: "200px",
                          height: "53px",
                          justifyContent: "space-between",
                          boxShadow: "0px 5px 12px rgba(23,24,29,0.07)",
                          boxSizing: "border-box",
                        },
                      }}
                      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                      <MenuItem onClick={this.handleRemoveFromProject}>
                        <DeleteOutlineOutlinedIcon htmlColor="#17181D" style={{ width: "19px", height: "19px", marginRight: "9px" }} />
                        Remove From Project
                      </MenuItem>
                    </Menu>
                  </Box>
                </Box>
                <Box style={{ borderTop: "1px solid #e3eaf4", marginTop: "11.5", marginBottom: "9.5" }}></Box>
                <Box style={webStyle.lowerBox}>
                  <Typography style={webStyle.permissionText}><Box style={webStyle.numberBox}>{employee.attributes.permissions.length}</Box> Permissions</Typography>
                  <Typography data-test-id={`${employee.id}_manage`} onClick={() => this.handleManageAccessibility(employee.attributes.permissions, employee.attributes.project_department, employee.id)} style={webStyle.manageText}>Manage
                    <KeyboardArrowRightIcon htmlColor="#17181D" style={webStyle.rightArrow} />
                  </Typography>
                </Box>
              </Box>
            </Grid>
          })
            : <Typography style={{ width: '100%', textAlign: 'center', marginTop: '30px', marginBottom: '30px', color: '#6d89af', fontFamily: 'Urbanist', fontSize: 14, fontWeight: 600 }}>No record available</Typography>}
        </>
          :  <>
           {this.state.projectVendorData.length ? this.state.projectVendorData.map((item: any) => {
            return <Grid item xs={12} sm={6} md={4}>
              <Box style={webStyle.roleListBox as React.CSSProperties}>
                <Box style={webStyle.uparBox}>
                  <Box style={{ display: "flex" }}>
                    <img src={item.attributes.profile_image || dummyImage} style={webStyle.roleImageStyle} />
                    <Box>
                      <Typography style={webStyle.depName}>Dept | {item.attributes.department}</Typography>
                      <Typography style={webStyle.empName}>{item.attributes.account.full_name}</Typography>
                      <Typography style={webStyle.designationRole}><img src={bagIcon} alt="bag" style={webStyle.roleRemoveContentIc}/> {item.attributes.role} | {item.attributes.experience}Yrs</Typography>
                    </Box>
                  </Box>
                  <Box style={webStyle.moreRoleSection}>
                    <ArrowForwardIcon htmlColor="#6D89AF" style={webStyle.moreRoleIcon} onClick={()=>this.navigateToProjectVendorManageProfile(item.attributes.account.profile_id,item.attributes.account_id,item.id)}/>
                  </Box>
                </Box>
                <Box style={webStyle.memberRoleJobWrapper}>
                  <Box style={webStyle.memberRoleJobRemove}>
                    <Typography style={webStyle.memberJobRemoveText as React.CSSProperties} onClick={()=>this.removeFromJobProjectMemberProjectVendor(item.id)}>Remove from Job</Typography>
                  </Box>     
                </Box>
              </Box>
            </Grid>
           }) :
          <Typography style={{ width: '100%', textAlign: 'center', marginTop: '30px', marginBottom: '30px', color: '#6d89af', fontFamily: 'Urbanist', fontSize: 14, fontWeight: 600 }}>No record available</Typography>}
          </>
          }
      </Grid >
      {this.state.tabValue === 0 ?
        (<Grid container spacing={2}>
          {this.state.totalCountPMJR > 0 && 
          <Grid item xs={12}>
            <Pagination
              style={{ marginTop: "30px", justifyContent: "center", display: "flex" }}
              data-test-id="pagination" count={Math.ceil(this.state.totalCountPMJR / 9)}
              page={this.state.currentPage} onChange={this.handlePageChange}
            />
          </Grid>}
        </Grid>)
        : (<Grid container spacing={2}>
          <Grid item xs={12}>
            {this.state.totalCountProjectVendor > 0 && 
            <Pagination
            style={{ marginTop: "30px", justifyContent: "center", display: "flex" }}
            data-test-id="pagination" count={Math.ceil(this.state.totalCountProjectVendor / 9)}
            page={this.state.currentPage} onChange={this.handlePageChangeProjectVendor}
          />
          }
            
          </Grid>
        </Grid>)}
    </>
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <ProjectMemberLayout
          data-test-id="ProjectMemberLayout"
          ref={this.layoutRef}
          navigation={this.props.navigation}
          id={this.props.navigation}
          projectMemberLayoutTitle={this.projectMembersJobRoleTitle}
          isLoading={this.state.isLoading}
          renderProjectMemberLayoutTopRightContent={() => this.renderProjectMembersJobRoleTopRightContent()}
          projectMemberLayoutBreadCrumb={this.state.projectMembersJobRoleBreadCrumb}
          renderPageContent={() => this.renderProjectMembersJobRoleMainContent()}
        />
        {this.state.isModelOpen && this.state.modelType === "manageAccessibility" ? <ManageAccessibilityModel open={this.state.isModelOpen} permssionsList={this.state.manageModelPermssionList} departmentsList={this.state.departmentsList} selectedDept={this.state.manageModelSelectedDept} savePermissionsAPICall={this.savePermissionsAPICall} handleClose={this.handleModelClose} /> : ""}
        {this.state.isModelOpen && this.state.modelType === "addEmployee" ? <AddNewEmployeeModal open={this.state.isModelOpen} handleClose={this.handleModelClose} countryCodeSelected={this.state.countryCodeSelected} departmentsList={this.state.departmentsList} rolesList={this.state.rolesList} permssionsList={this.state.permssionsList} handleSaveEmployeeApiCall={this.handleSaveEmployeeApiCall} showNotification={this.layoutRef.current.handleChangeNotification} /> : null}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

export const webStyle = {
  AddEmpBoxLayout: {
    width: "210px",
    borderRadius: "14px",
    height: "48px",
    background: "#17181d",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    color: "#ffffff",
    fontFamily: "Urbanist",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: "0.45714286px",
  },
  listBox: {
    height: "130px",
    borderRadius: "12px",
    border: "1px solid #e3eaf4",
    background: "#ffffff",
    padding: "12px 10px 14px 12px",
    boxSizing: "border-box",
  },
  uparBox: {
    display: "flex",
    alignItems: "start",
    justifyContent: "space-between",
  },
  lowerBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  imageStyle: {
    width: "59px",
    height: "59px",
    borderRadius: "14px",
    marginRight: "8px",
  },
  empName: {
    lineHeight: "19px",
    color: "#17181d",
    fontFamily: "Urbanist",
    fontSize: "16px",
    fontWeight: 600,
    letterSpacing: "0.2px",
  },
  depName: {
    lineHeight: "17px",
    color: "#17181d",
    fontFamily: "Urbanist",
    fontSize: "14px",
    fontWeight: 500,
    paddingTop: "4px",
  },
  designation: {
    lineHeight: "15px",
    color: "#6d89af",
    fontFamily: "Urbanist",
    fontSize: "12px",
    fontWeight: 500,
    paddingTop: "4px",
  },
  groupIconStyle: {
    width: "16px",
    height: "16px",
    paddingRight: "4px",
  },
  moreIcon: {
    width: "24px",
    height: "24px",
    cursor: "pointer",
  },
  permissionText: {
    color: "#f5c42c",
    fontFamily: "Urbanist",
    fontSize: "13px",
    fontWeight: 600,
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
  },
  numberBox: {
    padding: "4px 7px 3px 7px",
    borderRadius: "16px",
    background: "#f5c42c",
    lineHeight: '16px',
    color: "#ffffff",
    fontFamily: "SF Pro Text",
    fontSize: "13px",
    fontWeight: 600,
    letterSpacing: "-0.325px",
    marginRight: "4px",
  },
  manageText: {
    color: "#17181d",
    fontFamily: "Urbanist",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "16px",
    textDecoration: "underline",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },
  rightArrow: {
    width: "22px",
    height: "22px",
    marginLeft: "4px",
  },
  memberRoleHeadButton: {
    minWidth: '161px',
    borderRadius: '10px',
    background: '#17181d',
    color: '#fff',
    fontFamily: 'urbanist',
    fontSize: '12px',
    fontWeight: 'bold',
    letterSpacing: ' 0.25px',
    textAlign: 'center',
    padding: '11px 10px',   
    cursor: 'pointer', 
  },
  memberRoleJobWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '8px',
    borderTop: '1px solid #E3EAF4',
    margin: '8px 0 0',
    padding: '10px 0 0',
  },
  memberRoleJobRemove:{
    display: "flex",
    justifyContent: "center",
    width: "50%",
    alignItems: "center",
  },
  memberJobRemoveText: {
    width: '50%',
    textAlign: 'center',
    color: '#6D89AF',
    fontWeight: 'bold',
    fontSize: '12px',
    cursor: 'pointer',
  },
  roleImageStyle: {
    width: "54px",
    height: "54px",
    borderRadius: "14px",
    marginRight: "8px",
  },
  roleListBox: {
    borderRadius: "12px",
    border: "1px solid #e3eaf4",
    background: "#ffffff",
    padding: "12px",
  },
  designationRole: {
    lineHeight: "14px",
    color: "#6d89af",
    display: "flex",
    alignItems: "center",
    gap: "2px",
    fontSize: "12px",
    letterSpacing: "0.2px",
    fontWeight: 500,
    paddingTop: 0,
  },
  moreRoleSection: {
    backgroundColor: '#E1E6EC',
    borderRadius: '50%',
    height: '26px',
    width: '26px',
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  moreRoleIcon: {
    width: "14px",
    height: "14px",
  },
  roleRemoveContentIc: {
    height: "16px",
    width: "16px",
  }
}
// Customizable Area End