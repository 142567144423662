import React from "react";
import {
  Container,
  Grid,
  Box,
  Button,
  Typography,
  TypographyProps,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from "@material-ui/core";
import { Formik, Form, Field, ErrorMessage } from "formik";
import SideBar from "../../../components/src/SideBar";
import Header from "../../../components/src/Header.web";
import EditIcon from "@material-ui/icons/Edit";
import Footer from "../../../components/src/Footer";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
//@ts-ignore
import { ReactComponent as Profile } from "../assets/profile.svg";
// @ts-ignore
import { ReactComponent as Settings } from "../assets/settings.svg";
// @ts-ignore
import { ReactComponent as Signout } from "../assets/power.svg";
import Loader from "../../../components/src/Loader.web";
import UserProfileBasicController, {
  Props
} from "./UserProfileBasicController";
import { dummyImage } from "../../../components/src/assets";
const theme = createTheme({
  palette: {
    primary: {
      main: "#F5C42C",
      contrastText: "#fff"
    }
  },

  overrides: {
    MuiSelect: {
      select: {
        "&:focus": {
          border: "1px solid #F5C42C"
        }
      }
    }
  }
});
export default class UserProfileBasicBlock extends UserProfileBasicController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  renderMainContent(sideBarStatus: boolean = this.state.isSideBarOpen) {
    return (
      <Grid style={webStyle.contentSetup}>
        <Loader loading={this.state.isLoading} />
        <Header
          sideBarStatus={sideBarStatus}
          dropDownStatus={this.state.isDropdownOpen}
          handleSideBar={this.handleSideBar}
          handleDropdown={this.handleDropdown}
          navigate={this.props.navigation.navigate}
        />
        <Grid style={webStyle.mainContent as React.CSSProperties}>
          <Box sx={webStyle.blockInfo}>
            <Typography
              variant="h3"
              style={webStyle.activeBlock as TypographyProps}
            >
              Profile
            </Typography>
            <Typography
              variant="h6"
              style={webStyle.blockPath as TypographyProps}
            >
              <span style={webStyle.highlightedText}>Home</span> / Profile{" "}
            </Typography>
          </Box>
          <Grid container spacing={3} style={webStyle.container}>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <Box style={webStyle.blockStyle as React.CSSProperties}>
                <List>
                  <ListItem>
                    <Box sx={webStyle.userDataInList}>
                      <EditIcon
                        style={{
                          position: "relative",
                          top: "87px",
                          zIndex: 11,
                          left: "30px",
                          cursor: "pointer",
                          background: "white",
                          borderRadius: "50%",
                          padding: "4px",
                          fontSize: "15px"
                        }}
                      />
                      <Box sx={webStyle.userImageBoxInList}>
                        <input
                          type="file"
                          style={{
                            opacity: 0,
                            width: "20px",
                            position: "relative",
                            top: "65px",
                            zIndex: 11,
                            left: "30px"
                          }}
                          onChange={(e: any) => this.handleImage(e)}
                        />
                        <img

                          src={
                            this.state.setImage||dummyImage
                          } style={{ height: 90, width: 90, position: 'relative', top: -21 } as React.CSSProperties}

                        />
                      </Box>

                      <Box>

                        <Box sx={webStyle.userNameInList}>{this.state.setNamevalue}</Box>
                        <Box sx={webStyle.userEmailInList}>
                          {this.state.setEmailvalue}
                        </Box>
                      </Box>
                    </Box>
                  </ListItem>
                  <Box style={webStyle.seperateDropdown} />
                  <Box style={webStyle.listName}>Manage</Box>
                  <ListItem
                    style={
                      {
                        ...webStyle.activeLink,
                        marginBottom: "16px"
                      } as React.CSSProperties
                    }
                    onClick={() =>
                      this.props.navigation.navigate("UserProfileBasicBlock")
                    }
                    data-test-id="list-item"
                  >
                    <ListItemAvatar style={webStyle.dropdownIcon}>
                      <Profile fill={"#F5C42C"} />
                    </ListItemAvatar>
                    <ListItemText
                      primary="Manage Profile"
                      primaryTypographyProps={{
                        style: webStyle.listItemText as React.CSSProperties
                      }}
                      style={webStyle.listText as React.CSSProperties}
                    />
                  </ListItem>
                  <ListItem
                    style={webStyle.inactiveLink as React.CSSProperties}
                    onClick={() =>
                      this.props.navigation.navigate("UserSettings")
                    }
                  >
                    <ListItemAvatar style={webStyle.dropdownIcon}>
                      <Settings />
                    </ListItemAvatar>
                    <ListItemText
                      primary="Settings"
                      primaryTypographyProps={{
                        style: webStyle.listItemText as React.CSSProperties
                      }}
                      style={webStyle.listText as React.CSSProperties}
                    />
                  </ListItem>
                  <Box style={webStyle.seperateDropdown} />
                  <ListItem>
                    <Button
                      variant="outlined"
                      startIcon={<Signout />}
                      style={webStyle.signout as React.CSSProperties}
                      fullWidth
                      onClick={() =>
                        this.props.navigation.navigate("LandingPage")
                      }
                    >
                      Sign Out
                    </Button>
                  </ListItem>
                </List>
              </Box>
            </Grid>
            <Grid item lg={8} md={8} xs={12} sm={12}>
              <Box
                style={
                  {
                    ...webStyle.blockStyle,
                    padding: "30px",
                    height: "100%"
                  } as React.CSSProperties
                }
              >
                <Box>
                  <Typography style={webStyle.pageTitle as React.CSSProperties}>
                    Manage your profile
                  </Typography>
                  <Typography
                    style={webStyle.pageDescription as React.CSSProperties}
                  >
                    Manage your personal information, by entering your name and
                    email ID and update your profile
                  </Typography>
                </Box>
                <Box style={webStyle.seperateMainBlock} />

                <Formik
                  initialValues={{
                    name: "",
                    email: ""
                  }}
                  onSubmit={values => {
                    // Handle form submission
                  }}
                  // validationSchema={this.manageProfileData}
                >
                  <Form
                    style={webStyle.formStyle as React.CSSProperties}
                    translate=""
                    noValidate
                    autoComplete="off"
                  >
                    <Box>
                      <Box>
                        <label
                          className="form-label"
                          htmlFor="name"
                          id="fullNameLabel"
                          data-test-id="nameLabel"
                          style={webStyle.formLabel as React.CSSProperties}
                        >
                          Your Full Name
                        </label>
                        <Field
                          type="text"
                          id="name"
                          name="name"
                          data-test-id="name"
                          placeholder="Enter your full name"
                          style={webStyle.formInput as React.CSSProperties}
                          className="form-input-feild"
                          onFocus={() => {
                            this.handleInputFocus("fullNameLabel");
                          }}
                          onBlur={() => {
                            this.handleInputBlur("fullNameLabel");
                          }}
                          value={this.state.setName}
                          onChange={(e: any) => this.handleName(e)}
                        />

                        <Typography style={{ color: "red" }}>
                          {this.state.validateName}
                        </Typography>

                        <ErrorMessage
                          name="name"
                          component="Box"
                          className="formErrorStyle"
                        />
                      </Box>

                      <Box>
                        <label
                          className="form-label"
                          htmlFor="email"
                          id="emailId"
                          data-test-id="emailLabel"
                          style={webStyle.formLabel as React.CSSProperties}
                        >
                          Email ID
                        </label>
                        <Field
                          type="email"
                          id="email"
                          name="email"
                          data-test-id="email"
                          placeholder="enter your email id"
                          style={webStyle.formInput as React.CSSProperties}
                          className="form-input-feild"
                          onFocus={() => {
                            this.handleInputFocus("emailId");
                          }}
                          onBlur={() => {
                            this.handleInputBlur("emailId");
                          }}
                          value={this.state.setEmail}
                          onChange={(e: any) => this.handleEmail(e)}
                        />
                        <Typography style={{ color: "red" }}>
                          {this.state.validateEmail}
                        </Typography>
                        <ErrorMessage
                          name="email"
                          component="Box"
                          className="formErrorStyle"
                        />
                      </Box>
                    </Box>
                    <button
                      className="form-submit-button"
                      type="submit"
                      style={webStyle.submitButton as React.CSSProperties}
                      onClick={this.manageProfileData}
                    >
                      Update Profile
                    </button>
                  </Form>
                </Formik>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Footer />
      </Grid>
    );
  }
  render() {
    return (
      <ThemeProvider theme={theme}>
        <Container style={webStyle.parentDiv}>
          {this.state.isSideBarOpen ? (
            <Grid container spacing={0}>
              <Grid item xs={3} style={webStyle.sidebarParent}>
                <SideBar
                  navigate={this.props.navigation.navigate}
                  userData={{ age: 25 }}
                  activeMenuItem={""}
                  handleSideBar={this.handleSideBar}
                />
              </Grid>
              <Grid item xs={9}>
                {this.renderMainContent()}
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={12}>
              {this.renderMainContent()}
            </Grid>
          )}
        </Container>
      </ThemeProvider>
    );
  }
}

const webStyle = {
  parentDiv: {
    padding: "0px",
    maxWidth: "none"
  },
  sidebarParent: {
    minHeight: "100vh",
    maxWidth: "23%"
  },
  contentSetup: {
    // minHeight: "90vh",
    borderLeft: "1px solid #E3EAF4"
  },
  container: {
    marginTop: "12px"
  },
  mainContent: {
    width: "100%",
    minHeight: "100vh",
    backgroundColor: "#F8FAFE",
    boxSizing: "border-box",
    padding: "24px"
  },
  blockInfo: {
    // padding: "15px",
  },
  highlightedText: {
    color: "#F5C42C",
    fontFamily: "Urbanist",
    cursor: "pointer",
    textDecoration: "underline"
  },
  listItemText: {
    fontWeight: 500,
    letterSpacing: "0.8px",
    fontSize: "16px",
    lineHeight: "19px"
  },
  activeBlock: {
    lineHeight: "34px",
    fontSize: "28px",
    fontWeight: "700",
    letterSpacing: "1px",
    color: "#17181D"
  },
  blockPath: {
    fontSize: "14px",
    letterSpacing: "1px",
    color: "#6D89AF",
    marginBottom: "15px",
    fontWeight: 500,
    paddingBottom: "3px",
    lineHeight: "17px",
    paddingTop: "8px"
  },
  navigationInfo: {
    display: "flex",
    alignItems: "center"
  },
  userImageBoxInList: {
    width: "90px",
    height: "90px",
    borderRadius: "50%",
    overflow: "hidden",
    margin: "0 auto",
    position: "relative",
    marginBottom: "10px"
  },
  userDataInList: {
    width: "100%",
    textAlign: "center"
  },
  userImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
    top: "0",
    left: "0"
  },
  userEmailInList: {
    color: "#6D89AF",
    fontSize: "18px",
    lineHeight: "21px"
  },
  userNameInList: {
    fontWeight: "600",
    fontSize: "22px",
    lineHeight: "26px"
  },
  seperateDropdown: {
    border: "0.5px solid #E3EAF4",
    width: "90%",
    margin: "20px auto 0 auto"
  },
  seperateMainBlock: {
    border: "0.5px solid #E3EAF4",
    width: "100%",
    margin: "15px 0"
  },
  dropdownIcon: {
    minWidth: "0",
    marginRight: "10px"
  },
  listText: {
    margin: "0",
    padding: "0"
  },
  signout: {
    textTransform: "none",
    borderRadius: "20.5px",
    backgroundColor: "#F8FAFE",
    color: "#6D89AF",
    marginTop: "14px",
    paddingTop: "14px",
    paddingBottom: "14px",
    fontFamily: "Urbanist",
    fontSize: "14px",
    lineHeight: "17px",
    fontWeight: 500,
    borderColor: "#E3EAF4"
  },
  activeLink: {
    cursor: "pointer",
    height: "50px",
    color: "#F5C42C",
    background: "#f5c42c14",
    borderRadius: "12px",
    width: "90%",
    margin: "0 auto"
  },
  inactiveLink: {
    cursor: "pointer",
    height: "50px",
    background: "#ececee66",
    borderRadius: "12px",
    width: "90%",
    margin: "0 auto"
  },
  blockStyle: {
    backgroundColor: "#fff",
    border: "1px solid #E1E6EC",
    borderRadius: "16px",
    position: "relative",
    boxSizing: "border-box"
  },
  listName: {
    fontSize: "14px",
    color: "#6D89AF",
    margin: "13px 16px"
  },
  pageTitle: {
    fontSize: "28px",
    lineHeight: "34px",
    fontWeight: "700",
    letterSpacing: "1.2px"
  },
  pageDescription: {
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#6D89AF",
    maxWidth: "500px",
    paddingTop: "8px",
    margin: "0px"
  },
  submitButton: {
    cursor: "pointer",
    color: "#ffffff",
    backgroundColor: "#17181D",
    textTransform: "none",
    width: "250px",
    border: "none",
    borderRadius: "14px",
    position: "absolute",
    bottom: "16px",
    height: "52px",
    fontWeight: "500",
    fontSize: "14px",
    paddingTop: "14px",
    paddingBottom: "14px",
    marginBottom: "13px",
    fontFamily: "Urbanist"
  },
  formStyle: {},
  formLabel: {
    color: "#6D89AF",
    lineHeight: "18px",
    fontSize: "14px",
    display: "block",
    margin: "20px 0 5px 0",
    fontWeight: "600"
    // letterSpacing: "1px"
  },
  formInput: {
    borderColor: "#E3EAF4",
    width: "100%",
    border: "1px solid #E3EAF4",
    borderRadius: "14px",
    height: "50px",
    padding: "0 9px",
    fontWeight: "600",
    fontSize: "16px"
  }
};
