import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { IBreadCrum } from "./BreadCrumController";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleBadgeVisibility: (e: any, id: any, blockstatus: boolean) => void,
  getApiCallForAllVendors: (pageNumber: number) => void,
  allVendorsDataValue: any[],
  issidebaropen: boolean,
  isDropdownOpenVal: boolean,
  handleSideBarisDropdownOpenVal: () => void,
  handleDropdownVal: () => void,
  updateAllvendorDataValue: (data: any, total: number) => void,
  vendorListTotal: number;
  isLoading: boolean;
  updateLoadingStateValue: (value: boolean) => void,

  
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  currentpage: number;
  list: IBreadCrum[];
  loaderDisable: boolean;
  searchValue: string;
  open:boolean;
  state:any[]|null;
  statesearch:any[]|null;
  countryState:string;
  city:any[]|null;
  citysearch:any[]|null;
  designation:any[]|null;
  selectedValues:any[];
  selectedValuesRole:any[];
  value:any[];
  isBlocked:boolean;
  statename:string;
  stateindex:any[]|null;
  min:any;
  max:any;
  count:number;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class AllvendorContentController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllVendorsearchDataApiCalledId: string = "";
  StateSearchApi:string="";
  CitySearchApi:string="";
  designationApi:string="";
  sortVendorApi:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
  
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      currentpage: 1,
      list: [
        {
          text: 'Home',
          href: 'Dashboard',
          clickable: true,
          page: 'Dashboard'
        },
        {
          text: 'Vendors',
          href: '/',
          clickable: false,
          page: ''
        }
      ],
      loaderDisable: false,
      searchValue: "",
      open:false,
      state:null,
      statesearch:null,
      countryState:"",
      city:null,
      citysearch:null,
      designation:null,
      selectedValues:[],
      selectedValuesRole:[],
      value:[0,50],
      isBlocked:false,
      statename:'',
      stateindex:null,
      min:0,
      max:50,
      count:0
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    if (this.state.searchValue.length == 0) {
      this.props.getApiCallForAllVendors(1);
      this.searchStateApi()
      this.designationApiFunction();
      
    }
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (this.getAllVendorsearchDataApiCalledId == apiRequestCallId) {
        if (responseJson.data) {
          this.props.updateAllvendorDataValue(responseJson.data, responseJson.meta.total);
        }
        else {
          alert(responseJson.errors[0].message);
          this.props.updateLoadingStateValue(false);
        }
      }
      if(this.StateSearchApi== apiRequestCallId)
      {
        console.log(responseJson,"rrrrrrr")
        this.setState({state:responseJson})
        this.setState({statesearch:responseJson})
        console.log("555555555",this.state.state)
      }
      if(this.CitySearchApi== apiRequestCallId)
      {
        this.setState({city:responseJson})
        this.setState({citysearch:responseJson})
      }
      if(this.designationApi==apiRequestCallId)
      {
        this.setState({designation:responseJson.data})
      }
      if(this.sortVendorApi==apiRequestCallId)
      {
        this.props.updateAllvendorDataValue(responseJson.data, responseJson.meta.total);
      }
      
    }
  }

  navigateToHome = () => {
    this.props.navigation.navigate("LandingPage")
  };
  handleValue=(value:string)=>{
    const statevalue=value
    const XCSCAPIKEY = "Z1dmZFJDWjlMSjl0M082ZUUyU3JESkJCQTdZeTd6UEVINXdreTdjWQ=="
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "X-CSCAPI-KEY": XCSCAPIKEY 
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.CitySearchApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.CityEndPoint+`${statevalue}/cities`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET_Method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    
    
  }
  navigateToVendor = () => {
    this.props.navigation.navigate("FullTeamVisibility")
  };

  navigateToUserDetails = () => {
    this.props.navigation.navigate("UserDetailCommon")
  };

  navigateToUserDetail = (id: any) => {
    this.props.navigation.navigate("UserDetail");
    setStorageData("id", id)
  };

  handlePageChange = (event: any, value: number) => {
    this.setState({
      currentpage: value,
    });
    if (this.state.searchValue)
    {
      this.searchApiCallForAllVendors(value);
      window.scrollTo(0, 0);
    }
    else
    {
      this.props.getApiCallForAllVendors(value);
      window.scrollTo(0, 0);
    }
  };

  getSearchValue = (e: any) => {
    this.setState({
      searchValue: e.target.value,
      currentpage: 1,
    })
    if (e.target.value.length == 0) {
      this.props.getApiCallForAllVendors(1);
    } else this.debounceLog();
  };

  debounce = (callback: any, delay: any) => {
    let timer: any;
    return (...args: any) => {
      clearTimeout(timer);
      timer = setTimeout(() => callback(...args), delay)
    }
  }

  debounceLog = this.debounce(() => this.searchApiCallForAllVendors(1), 500);

  searchApiCallForAllVendors = async (pageNumber: number) => {
    const token = await getStorageData("token", false);
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.props.updateLoadingStateValue(true);
    this.getAllVendorsearchDataApiCalledId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllVendorSearchDataApiEndPoint + this.state.searchValue + "&per_page=9&page=" + pageNumber
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET_Method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleOpen = () =>{
    console.log("rrrr",this.state.open)
     this.setState({open:true})
     console.log("rrrr",this.state.open)
  }
  handleClose = () => {
    this.setState({open:false})
    console.log("onclose",this.state.open)
  };
  handleSearchvendor = (event:any) => {
    const updatedList: any[] | null = this.state.statesearch && (this.state.statesearch.filter((state) => {
      return state.name.toLowerCase().includes(event.target.value.toLowerCase())
    }) ?? null);
    this.setState({
      state: updatedList
    })
  };
  handleSearchcityvendor = (event:any) => {
    const updatedList: any[] | null = this.state.citysearch && (this.state.citysearch.filter((city) => {
      return city.name.toLowerCase().includes(event.target.value.toLowerCase())
    }) ?? null);
    this.setState({
      city: updatedList
    })
  };
  searchStateApi = async () => {
    const XCSCAPIKEY = "Z1dmZFJDWjlMSjl0M082ZUUyU3JESkJCQTdZeTd6UEVINXdreTdjWQ=="
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "X-CSCAPI-KEY": XCSCAPIKEY 
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.StateSearchApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.StateEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET_Method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  designationApiFunction = async () => {
  
    const header = {
      "Content-Type": configJSON.exampleApiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.designationApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.designationEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET_Method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  toggleCheckbox = (value:any) => {
  
    if (this.state.selectedValues.includes(value)) {
      this.setState({
        selectedValues: this.state.selectedValues.filter((val) => val !== value),
      });
    } else {
      this.setState({
        selectedValues: [...this.state.selectedValues, value],
      });
    }
  
  }
  toggleCheckboxdesignation = (value: string) => {
    console.log("ggggggg",value)
    if (this.state.selectedValuesRole.includes(value)) {
      this.setState({
        selectedValuesRole:this.state.selectedValuesRole.filter((val) => val !== value),
      });
    } else {
      this.setState({
        selectedValuesRole: [...this.state.selectedValuesRole, value],
      });
    }
    console.log("0000000000000",this.state.selectedValuesRole.join(", "))
};
 handlechange = (event:any, newValue:any) => {
  this.setState({value:newValue})
  console.log("AAAAAAAAAA",newValue[0])
  this.setState({min:newValue[0]})
  this.setState({max:newValue[1]});
};
 handleRadioChange = () => {
  this.setState({isBlocked:true}) // Toggle the value between true and false
};
 handleRadioChange1 = () => {
  this.setState({isBlocked:false,
                  count:1
  }); // Toggle the value between true and false
};
sortApiCallForAllVendors = async () => {
  this.setState({open:false})
  const cityname=this.state.selectedValues.join(", ");
  const role=this.state.selectedValuesRole.join(", ");
  const authenticationToken = await getStorageData('token', false)
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": authenticationToken
    };
      
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sortVendorApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    if(this.state.stateindex===null&&role===''&&this.state.isBlocked===false&&this.state.count===0&&this.state.min===0&&this.state.max===50){
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getAllVendorDataApiEndPoint1+`&page=1`
        
      );
    }
    else if(this.state.min===0&&this.state.max===50)
    {
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.allvendorSortDataApiEndPoint+`=${this.state.statename}&cities=${cityname}&experience_min=&experience_max=&roles=${role}&blocked=${this.state.isBlocked}&page=1`
      
    );
    }
    else{
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.allvendorSortDataApiEndPoint+`=${this.state.statename}&cities=${cityname}&experience_min=${this.state.min}&experience_max=${this.state.max}&roles=${role}&blocked=${this.state.isBlocked}&page=1`
        
      );
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET_Method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
 
};
 handleOptionChange = (index: any, state: any) => {
  this.setState({statename:state.name})
  this.setState({stateindex:index})
  //this.setState({selectedValues:[]})
  console.log("22222", state.name)
};
ClearDataValues = async () => {
  //this.setState({open:false})
  this.setState({stateindex:null})
  this.setState({selectedValues:[]})
  this.setState({selectedValuesRole:[], value:[0,50],min:0,max:50})
  this.setState({isBlocked:false})
  this.componentDidMount()

  const authenticationToken = await getStorageData('token', false)
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": authenticationToken
    };
      
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sortVendorApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getAllVendorDataApiEndPoint1+`&page=1`
        
      );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET_Method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
 
};

  // Customizable Area End
}