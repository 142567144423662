import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  balanceSheetProjectId: string;
  isLoading: boolean;
  summaryData: any;
  total: string;
  grandTotal: string;
  departmentsData: any;
  selectedDepartments: any;
  summaryHeading: string;
  categoriesData: any;
  selectedCategories: any;
  updateSummaryId: string;
  addEditRow: string;
  summaryCreateNotification: { type: string, open: boolean, message: string, route?: string };
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SummaryCreateController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetProjectDepartmentsMessageId: string = "";
  apiGetCategoriesByDepartmentsMessageId: string = "";
  apiCreateBudgetSummaryMessageId: string = "";
  apiGetBudgetSummaryMessageId: string = "";
  apiUpdateBudgetSummaryMessageId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      balanceSheetProjectId: "",
      isLoading: false,
      summaryData: [],
      total: "",
      grandTotal: "",
      departmentsData: [],
      selectedDepartments: [],
      summaryHeading: "",
      categoriesData: [],
      selectedCategories: [],
      updateSummaryId: "",
      addEditRow: "",
      summaryCreateNotification: { type: "", open: false, message: "" },
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (this.apiGetProjectDepartmentsMessageId === apiRequestCallId) {
        this.getProjectDepartmentsDataHandleResponse(responseJson);
      } else if (this.apiGetCategoriesByDepartmentsMessageId === apiRequestCallId) {
        this.getCategoriesByDepartmentsHandleResponse(responseJson);
      } else if (this.apiCreateBudgetSummaryMessageId === apiRequestCallId) {
        this.createBudgetSummaryHandleResponse(responseJson);
      } else if (this.apiGetBudgetSummaryMessageId === apiRequestCallId) {
        this.getBudgetSummaryHandleResponse(responseJson);
      } else if (this.apiUpdateBudgetSummaryMessageId === apiRequestCallId) {
        this.updateBudgetSummaryHandleResponse(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getProjectDepartmentsDataHandleResponse = (responseJson: { meta: { project_department: { id: number, name: string }[] }, errors: { token: string }[], error: { message: string } }) => {
    if (responseJson.meta?.project_department) {
      this.setState({ departmentsData: responseJson.meta.project_department });
    } else if (responseJson.errors) {
      this.setState({ summaryCreateNotification: { open: true, type: "error", message: responseJson.errors[0].token, route: "LandingPage" } });
    } else if (responseJson.error) {
      this.setState({ summaryCreateNotification: { open: true, type: "error", message: responseJson.error.message } });
    }
    this.setState({ isLoading: false });
  }

  getCategoriesByDepartmentsHandleResponse = (responseJson: { data: { categories: { id: number, name: string }[] }, errors: { token: string }[], error: { message: string } }) => {
    if (responseJson.data) {
      this.setState({ categoriesData: responseJson.data.categories }, () => {
        if (this.state.addEditRow === "add") {
          this.setState({ selectedCategories: this.state.categoriesData.map((category: { id: number, name: string }) => category.id) });
        }
      });
    } else if (responseJson.errors) {
      this.setState({ summaryCreateNotification: { open: true, type: "error", message: responseJson.errors[0].token, route: "LandingPage" } });
    } else if (responseJson.error) {
      this.setState({ summaryCreateNotification: { open: true, type: "error", message: responseJson.error.message } });
    }
    this.setState({ isLoading: false });
  }

  createBudgetSummaryHandleResponse = (responseJson: { data: { id: string, attributes: { categories_ids: number[] } }, errors: { token: string }[], error: { message: string } }) => {
    if (responseJson.data) {
      this.setState({ addEditRow: "" });
      this.getBudgetSummary();
      this.setState({ summaryCreateNotification: { open: true, type: "success", message: "Summary created successfully" } });
    } else if (responseJson.errors) {
      this.setState({ summaryCreateNotification: { open: true, type: "error", message: responseJson.errors[0].token, route: "LandingPage" } });
    } else if (responseJson.error) {
      this.setState({ summaryCreateNotification: { open: true, type: "error", message: responseJson.error.message } });
    }
    this.setState({ isLoading: false });
  }

  updateBudgetSummaryHandleResponse = (responseJson: { data: { id: string, attributes: { categories_ids: number[] } }, errors: { token: string }[], error: { message: string } }) => {
    if (responseJson.data) {
      this.setState({ addEditRow: "" });
      this.getBudgetSummary();
      this.setState({ summaryCreateNotification: { open: true, type: "success", message: "Summary updated successfully" } });
    } else if (responseJson.errors) {
      this.setState({ summaryCreateNotification: { open: true, type: "error", message: responseJson.errors[0].token, route: "LandingPage" } });
    } else if (responseJson.error) {
      this.setState({ summaryCreateNotification: { open: true, type: "error", message: responseJson.error.message } });
    }
    this.setState({ isLoading: false });
  }

  getBudgetSummaryHandleResponse = (responseJson: { meta: { total: string }, data: { id: string, attributes: { project_department_ids: number[], categories_ids: number[] } }[], errors: { token: string }[], error: { message: string } }) => {
    if (responseJson.data) {
      this.setState({ summaryData: responseJson.data, total: responseJson.meta.total, grandTotal: responseJson.meta.total });
    } else if (responseJson.errors) {
      this.setState({ summaryCreateNotification: { open: true, type: "error", message: responseJson.errors[0].token, route: "LandingPage" } });
    } else if (responseJson.error) {
      this.setState({ summaryCreateNotification: { open: true, type: "error", message: responseJson.error.message } });
    }
    this.setState({ isLoading: false });
  }

  async componentDidMount() {
    const authSCToken = await getStorageData("token");
    const balanceSheetProjectId = await getStorageData("BalanceSheetId");
    this.setState({ token: authSCToken, balanceSheetProjectId: balanceSheetProjectId }, () => {
      this.getBudgetSummary();
      this.getProjectDepartments();
    });
  }

  getBudgetSummary = () => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setState({ isLoading: true });
    this.apiGetBudgetSummaryMessageId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getBudgetSummaryAPI + this.state.balanceSheetProjectId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  createBudgetSummary = () => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setState({ isLoading: true });
    this.apiCreateBudgetSummaryMessageId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createBudgetSummaryAPI
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        data: {
          project_id: this.state.balanceSheetProjectId,
          project_department_ids: this.state.selectedDepartments,
          categories_ids: this.state.selectedCategories,
          summary_name: this.state.summaryHeading
        }
      })
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  updateBudgetSummary = () => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token
    };
    const updateSummaryRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setState({ isLoading: true });
    this.apiUpdateBudgetSummaryMessageId = updateSummaryRequestMessage.messageId;
    updateSummaryRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateBudgetSummaryAPI + this.state.addEditRow
    );
    updateSummaryRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    updateSummaryRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        data: {
          project_id: this.state.balanceSheetProjectId,
          project_department_ids: this.state.selectedDepartments,
          categories_ids: this.state.selectedCategories,
          summary_name: this.state.summaryHeading
        }
      })
    );
    updateSummaryRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putAPIMethod
    );
    runEngine.sendMessage(updateSummaryRequestMessage.id, updateSummaryRequestMessage);
    return true;
  }

  getProjectDepartments = () => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setState({ isLoading: true });
    this.apiGetProjectDepartmentsMessageId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProjectsDepartmentAPI + this.state.balanceSheetProjectId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleChangeHeading = (event: any) => {
    this.setState({ summaryHeading: event.target.value });
  }

  handleSelectDepartment = (event: any) => {
    const {
      target: { value }
    } = event;
    this.setState({ selectedDepartments: typeof value === "string" ? value.split(",") : value });
  }

  handleSelectCategory = (event: any) => {
    const {
      target: { value }
    } = event;
    this.setState({ selectedCategories: typeof value === "string" ? value.split(",") : value });
  }

  handleSummaryInputFocus = (labelName: string) => {
    const nameLabel = document.getElementById(labelName);
    if (nameLabel) {
      nameLabel.style.color = "#F5C42C";
    }
  };

  getCategoriesByDepartments = () => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setState({ isLoading: true });
    this.apiGetCategoriesByDepartmentsMessageId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategoriesByDepartments + `${this.state.selectedDepartments.toString()}&project[project_id]=${this.state.balanceSheetProjectId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleSummaryInputBlur = (labelName: string) => {
    const nameLabel = document.getElementById(labelName);
    if (nameLabel) {
      nameLabel.style.color = "#6D89AF";
    }

    if (labelName === "departmentDropdown") {
      this.getCategoriesByDepartments();
    }
  };

  handleAddRow = () => {
    this.setState({ addEditRow: "add", selectedCategories: [], selectedDepartments: [], summaryHeading: "" });
  }

  handleSaveSummary = () => {
    if (!this.state.summaryHeading) {
      this.setState({ summaryCreateNotification: { open: true, type: "error", message: "Please add summary heading" } });
      return false;
    }
    if (this.state.addEditRow === "add") {
      this.createBudgetSummary();
    } else if (this.state.addEditRow) {
      this.updateBudgetSummary();
    }
  }

  handleBackToSummary = () => {
    this.setState({ addEditRow: "" });
  }

  handleOpenEditSummary = (editSummaryId: string) => {
    const editSummaryData = this.state.summaryData.find((summary: any) => summary.id === editSummaryId);
    this.setState({ addEditRow: editSummaryId, selectedDepartments: editSummaryData.attributes.project_department_ids, selectedCategories: editSummaryData.attributes.categories_ids, summaryHeading: editSummaryData.attributes.summary_name }, () => {
      this.getCategoriesByDepartments();
    });
  }

  handleSummaryCreateCloseNotification = () => {
    this.setState({ summaryCreateNotification: { type: "", open: false, message: "" } });
  }
  // Customizable Area End
}
