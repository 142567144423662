
import React from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  TypographyProps,
  Link,
  AppBar,
  Tabs,
  Tab,
  Modal,
  } from "@material-ui/core";
import SideBar from "../../../components/src/SideBar";
import Header from "../../../components/src/Header.web";
import Footer from "../../../components/src/Footer";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Loader from "../../../components/src/Loader.web";
import "./studiosbooking.css";
import Alert, { AlertProps } from "@material-ui/lab/Alert";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ProjectDetailJobRoleandRequestController,{Props} from "./ProjectDetailJobRoleandRequestController";
import AllJobRoleCard from "../../../components/src/AllJobRoleCard";
import { bagIcon, deleteIcon, editIcon, paymentIcon, peopleIcon, threeDots } from "./assets";
import JobRoleRequestModel from "../../../components/src/JobRoleRequestModel";
const theme = createTheme({
  palette: {
    primary: {
      main: "#F5C42C",
      contrastText: "#fff"
    }
  },
  overrides: {
    MuiSelect: {
      select: {
        "&:focus": {
          border: "1px solid #F5C42C",
        }
      }
    }
  }
});

const CustomAlert = React.forwardRef<HTMLDivElement, AlertProps>(function CustomAlert(
  props,
  ref
) {
  return <Alert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default class ProjectDetailJobRoleandRequest extends ProjectDetailJobRoleandRequestController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderJobRoleGrid(data:any){
    return <>
    {this.state.tabValue === 0 && (
          <Box className="tabsContentJobRole" style={webStyle.tabsContentJobRole as React.CSSProperties}  >
             <Grid container spacing={4}>
                {data.length ? (
                    data.map((item:any)=>(                    
                        <Grid xs={12}  sm={6}  md={4} item style={webStyle.jobRoleReqWrapperJobRolePH}>
                        
                            <Box style={webStyle.jobRoleReqWrapperBoxJobRolePH}>
                                <Box style={webStyle.jobRoleReqMoreInfoJobRolePH as React.CSSProperties}>

                                    <Box>
                                        <Typography variant="h6" style={webStyle.roleReqWrapperDegsJobRolePH}>Dept | {item.attributes.project_department}</Typography>
                                        <Typography variant="h5" style={webStyle.roleReqWrapperHeadJobRolePH}>{item.attributes.role}</Typography>
                                    </Box>
                                    <Box>
                                        <img src={threeDots} alt="payment" style={webStyle.jobRoleReqDropdownIconJobRolePH} data-test-id="editJobRolehamburger" onClick={()=>this.handleJobRoleEditAndDelete(item.id)}/>
                                        {this.state.open2 === item.id &&<Box style={webStyle.jobRoleReqDropdown as React.CSSProperties}>
                                        <Box style={webStyle.jobRoleReqDropdownList}>
                                            <img src={editIcon} alt="Edit"/>
                                            <Typography style={webStyle.jobRoleReqDropdownListTextProject as React.CSSProperties} data-test-id="editJobRole" 
                                            onClick={()=>this.editJobRoleRequest(item.id)}
                                            >Edit Job Role</Typography>
                                        </Box>
                                            <Box style={webStyle.jobRoleReqDropdownList}>
                                                <img src={deleteIcon} alt="Delete"/>
                                                <Typography style={webStyle.jobRoleReqDropdownListTextProject as React.CSSProperties} data-test-id="deleteButtontest" 
                                                onClick={()=>this.deleteJobRoleRequest(item.id)}
                                                    >Delete Job Role</Typography>
                                            </Box>
                                        </Box>}
                                    </Box>
                                </Box>
                                <Box sx={webStyle.roleReqSectionProjectDeatil}>
                                    <Box style={{ width: '50%'}}>
                                        <Box style={webStyle.roleReqContentProjectDeatils}>
                                            <img src={bagIcon} alt="bag" style={webStyle.roleReqContentIconBg}/>
                                            <Typography style={webStyle.roleReqContentParaProjectDetails}>{item.attributes.exp_required} Yrs</Typography>
                                        </Box>
                                        <Box style={webStyle.roleReqContentProjectDeatils}>
                                            <img src={paymentIcon} alt="payment"/>
                                            <Typography style={webStyle.roleReqContentParaProjectDetails}>₹{item.attributes.budget_of_role}</Typography>
                                        </Box>
                                    </Box>
                                    <Box style={webStyle.roleReqContentProjectDeatils}>
                                        <img src={peopleIcon} alt="payment"/>
                                        <Typography style={webStyle.roleReqContentParaProjectDetails}>{item.attributes.number_of_positions} Positions</Typography>
                                    </Box>
                                </Box>
                                <Box style={webStyle.jobRequestInviteVendorProjectDetails}>
                                <Typography style={webStyle.jobRequestInviteText as React.CSSProperties} onClick={()=>this.InviteVendorListNavigation(item.id)}
                                    >Invite Vendors</Typography>
                                    <Box style={webStyle.jobRequestHeadButtonProjectDetails as React.CSSProperties} data-test-id="requestPageNav" onClick={()=>this.navigateToRequestPage(item.id)}>
                                        {item.attributes.vendor_job_requests_count} Requests
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    ))
                ): (
                <Typography
                    style={{
                    width: '100%',
                    textAlign: 'center',
                    fontFamily: 'Urbanist',
                    marginTop: '30px',
                    marginBottom: '30px',
                    color: '#6d89af',
                    fontSize: 14,
                    fontWeight: 600,
                    }}
                >
                    No record available
                </Typography>
                )} 
            </Grid>   
      </Box>
     )}
     </>
   }
  OngoingProjectPH=()=>{
    return<>
    {this.state.tabValue === 1 && (
            <Grid container spacing={3} style={{ paddingLeft: "12px", paddingRight: "12px" }} >
              {this.state.alljobrolelist.length?( this.state.alljobrolelist?.map((item:any,index:any) => (
                <Grid   item xs={12} sm={6} md={4}   >
                <AllJobRoleCard data={item} handleNavigationRequestProfile={this.handleNavigationRequestProfile}  deletefromJobInviteVendor={this.deletefromJobInviteVendor}/>
                </Grid>
              ))
              ):(
              
               <Typography style={{ width: '100%', textAlign: 'center', marginTop: '30px', marginBottom: '30px', color: '#6d89af', fontFamily: 'Urbanist', fontSize: 14, fontWeight: 600 }}>No record available</Typography>
              )
              }
            </Grid>)
          }
  </>
  }
  
  JobRoleHeading() {
    const jobRole = this.state.alljobrolelist[0]?.attributes?.job_role?.data;
    let headingText;
  
    if (jobRole && jobRole.attributes && jobRole.attributes.project) {
      const projectStatus = jobRole.attributes.project.data.attributes.status;
  
      if (projectStatus === "ongoing") {
        headingText = "Ongoing Projects";
      } else if (projectStatus === "upcoming") {
        headingText = "Upcoming Projects";
      } else {
        headingText = "All Projects";
      }
    } else {
      headingText = "All Projects";
    }
    return headingText;
  }
 renderAllJobRolePHListMainContent = (sideBarStatus: boolean = this.state.isSideBarOpen) => {
    return <>
      <Loader loading={this.state.isLoading}></Loader>
      <Grid style={webStyle.contentSetupJobRolePH} >
        <Header handleSideBar={this.handleSideBar}
         sideBarStatus={sideBarStatus}
         handleDropdown={this.handleDropdown}
          dropDownStatus={this.state.isDropdownOpen}
          navigate={this.props.navigation.navigate}
         
        />
        <Grid style={webStyle.mainContentJobRolePH as React.CSSProperties}>
        <Box style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
          <Box sx={webStyle.blockInfoJobRolePH}>
            <Box>
          <ArrowBackIcon data-test-id="backArrow" onClick={() => this.handleNavigation("ProjectDetailPH")} style={{background: "white",cursor:"pointer", border: "1px solid grey", borderRadius: "50%", marginRight: "10px", padding: "2px"}} />
            </Box>
            <Box>
              <Box>
                <Box>
              <Typography variant="h3" style={webStyle.activeBlockJobRolePH as TypographyProps}>All Requests & Employees</Typography>
              </Box>
              <Box>
              <Typography variant="h6" style={webStyle.blockPathJobRolePH as TypographyProps}>
                <span style={webStyle.highlightedTextJobRolePH}>
                  <Link data-test-id="homeLink" color="inherit" onClick={() => this.handleNavigation("Projects")}>
                    Home
                  </Link>
                </span>
                <span style={webStyle.highlightedTextJobRolePH}>
                  <Link data-test-id="homeLink1" color="inherit" onClick={() => this.handleNavigation("ProjectDetailPH")}>
                     {" "}/ {this.JobRoleHeading()}/{ ""}{this.state.alljobrolelist[0]?.attributes?.job_role?.data?.attributes?.project?.data?.attributes?.project_name}
                  </Link>
                </span>
                <span>
                {" "} All Requests & Employees</span>
              </Typography>
              </Box>
              </Box>
            </Box>
          </Box>
          <Box>
          <Box
            sx={webStyle.submitButtonJobRolePH}
            onClick={this.handleOpenAddJobRole}
            data-test-id="handleopen">
              + Add Job Role
            </Box>
            <Modal
              open={this.state.open}
              onClose={this.handleCloseJobRoleModel}
              aria-describedby="simple-modal-description"
              aria-labelledby="simple-modal-title"
          >
            <div style={{
              top: '50%',
              position: 'absolute',                                              
              left: '50%',
              backgroundColor: 'white',
              borderRadius: '20px',
              transform: 'translate(-50%, -50%)',
              height: '95%',
              overflow: 'auto',
              }}>
            <JobRoleRequestModel handleClose={this.handleCloseJobRoleModel} jobRoleProjectName={this.state.projectName} jobRoleProjectDept={this.state.projectDepartment} jobRoleProjectRole={this.state.projectRole} projectChangeHandle={this.handleProjectName} projectDeptChangeHandle={this.handleProjectdepartment} projectDeptRoleHandle={this.handleProjectDeptRole}  projectSelectedValue={this?.state?.jobRoleInputs?.selectedProject} projectDeptSelectedValue={this?.state?.jobRoleInputs?.selectedProjectDept} projectDeptRoleSelectedValue={this?.state?.jobRoleInputs?.selectedProjectRole} roleReqSelectedValue={this.state.jobRoleInputs.roleRequirements} expReqSelectedValue={this.state.jobRoleInputs.expRequired} budgetSelectedValue={this.state.jobRoleInputs.roleBudget} noOfPositionSelectedValue={this.state.jobRoleInputs.noOfPos} roleDescriptionValue={this.state.jobRoleInputs.roleDescription} validateJobRole={this.state.validationStatusJobRole} addJobRoleSubmit={this.handleSubmitFormAddJobRole} selectedProjectVal={this.state.isProjectSelected} selectedDeptVal={this.state.isDepartmentSelected} selectedRoleVal={this.state.isRoleTypeSeleted} roleReqChange={this.handleChangeRoleRequirements} expReqChange={this.handleChangeExp} budgetRoleChange={this.handleChangeBudget} 
              noOfPosChange={this.handleChangePos} roleDescriptionChange={this.handleChangeRoleDes} 
              handleRoleError={this.state.roleReqError} hanldeExpError={this.state.expError} handleBudgetError={this.state.budgetError} 
              handlenoOfPosError={this.state.noOfPosError} handleRoleDesError={this.state.roleDesError} handleSetEditJobRole={this.state.setEditJobRole}/>
          </div>
          </Modal>
            </Box>
           </Box>
          <Grid item sm={12} xl={12} xs={12} md={12} lg={12}>
            <AppBar
             className="appbar app_bar"
             color="transparent"
              position="static"
            >
              <Tabs
                onChange={this.handleAppBarValue}
                TabIndicatorProps={{ style: { height: '2px', background: "orange" } as React.CSSProperties }}
                scrollButtons="on"
                className="tabs_main"
                aria-label="scrollable prevent tabs example"
                value={this.state.tabValue}
                data-test-id="tabChange"
                variant="scrollable"
              >
                <Tab className={this.state.tabValue == 0 ? "active_btn" : "non_active_btn"} label="All Requests" />
                <Tab className={this.state.tabValue == 1 ? "active_btn" : "non_active_btn"} label="All Employees" />
            
                
              </Tabs>
            </AppBar>
          </Grid>
          <Grid container spacing={3} style={{ marginTop: "12px" }}>
          { this.renderJobRoleGrid(this.state.projectJobRoleRequest)}
            {this.OngoingProjectPH()}
          </Grid>
        </Grid >
        <Footer />
      </Grid >
     
    </>
  }
  // Customizable Area End

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Container style={webStyle.parentDivJobRolePH} >
          {
            this.state.isSideBarOpen ? (
              <Grid spacing={0} container >
                <Grid item xs={3} style={webStyle.sidebarParentJobRolePH} >
                  <SideBar
                    handleSideBar={this.handleSideBar}
                    userData={{ name: "user name" }}
                    activeMenuItem={"Projects"}
                    navigate={this.props.navigation.navigate}
                  />
                </Grid>
                <Grid item style={{ flexBasis: "77.75%", maxWidth: "77.75%" }} xs={9} >
                  {this.renderAllJobRolePHListMainContent()}
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12}>
                {this.renderAllJobRolePHListMainContent()}
              </Grid>
            )
          }
         
        </Container>
        
     
      </ThemeProvider>
    );
  }
}

const webStyle = {
  parentDivJobRolePH: {
    padding: "0px",
    maxWidth: "none"
  },
  sidebarParentJobRolePH: {
    minHeight: "100vh",
    maxWidth: "22.25%",
  },
  contentSetupJobRolePH: {
    
    borderLeft: "1px solid #E3EAF4"
  },
  container: {
    marginTop: "12px"
  },
  mainContentJobRolePH: {
    width: "100%",
    minHeight: "100vh",
    backgroundColor: "#F8FAFE",
    boxSizing: "border-box",
    padding: "24px"
  },
  blockInfoJobRolePH: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap"
  },
  highlightedTextJobRolePH: {
    color: "#F5C42C",
    fontFamily: "Urbanist",
    cursor: "pointer",
    textDecoration: "underline"
  },
 

  activeBlockJobRolePH: {
    fontSize: "28px",
    lineHeight: "34px",
    letterSpacing: "1px",
    color: "#17181D",
    fontWeight: "700",
  },
  blockPathJobRolePH: {
    letterSpacing: "1px",
    fontSize: "14px",
    marginBottom: "15px",
    color: "#6D89AF",
    paddingBottom: "3px",
    fontWeight: 500,
    paddingTop: "8px",
    lineHeight: "17px",
  },
  submitButtonJobRolePH: {
    width: '161px',
  
    borderRadius: '14px',
    background: '#17181d',
    color: ' #ffffff',
    fontFamily: 'urbanist',
    fontSize: '15px',
    fontWeight: 600,
    letterSpacing: ' 0.25px',
    alignItems: 'center',
    paddingTop: '15px',
    paddingBottom: '15px',
    display: 'flex',
    flexDirection: 'column-reverse',
    marginLeft: '208px',
    cursor:'pointer'

},
jobRoleReqWrapperJobRolePH: {
},
tabsContentJobRole: {
    paddingTop: "16px",
    display: "flex",
    gap: "1.5rem",
    flexWrap:"wrap",
    marginBottom: '15px',
    width: '100%',
},
jobRoleReqWrapperBoxJobRolePH: {
    border: '1px solid  #E3EAF4',
    borderRadius: '12px',
    padding: '12px',
},
jobRoleReqMoreInfoJobRolePH: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    gap: 10,
    paddingBottom: 2,
},
roleReqWrapperDegsJobRolePH: {
    color: '#17181d',
    fontFamily: 'Urbanist',
    fontSize: '13px',
    fontWeight: 500,
},
roleReqWrapperHeadJobRolePH: {
    fontSize: '16px',
    fontWeight: 600,
    color: '#17181d',
    fontFamily: 'Urbanist',
},
jobRoleReqDropdownIconJobRolePH: {
    cursor: 'pointer',
},
roleReqContentParaProjectDetails: {
    fontFamily: 'Urbanist',
    fontSize: '13px',
    fontWeight: 600,
    lineHeight: '16px',
},
jobRoleReqDropdownListTextProject: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#17181d',
    cursor: 'pointer',
},
jobRoleReqDropdown: {
    display: 'flex',
    flexDirection: 'column',
    gap: '18px',
    position: 'absolute',
    right: 0,
    minWidth: '170px',
    padding: '16px 18px',
    borderRadius: '10px',
    border: '0.5px solid #e3eaf4',
    background: '#ffffff',
    boxShadow: '0px 5px 12px rgba(23,24,29,0.07)',
},
jobRoleReqDropdownList: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '10px',
},
roleReqSectionProjectDeatil: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: '8px',
},
jobRequestInviteVendorProjectDetails: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '8px',
    borderTop: '1px solid #E3EAF4',
    margin: '8px 0 0',
    padding: '10px 0 0',
},
roleReqContentProjectDeatils: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '3px 0',
    color: '#6d89af',
},
roleReqContentIconBg: {
    height: '16px',
},
jobRequestInviteText: {
    width: '50%',
    textAlign: 'center',
    color: '#3389FF',
    fontWeight: 'bold',
    fontSize: '13px',
    cursor: 'pointer',
},
jobRequestHeadButtonProjectDetails: {
    minWidth: '161px',
    borderRadius: '10px',
    background: '#17181d',
    color: '#fff',
    fontFamily: 'urbanist',
    fontSize: '12px',
    fontWeight: 'bold',
    letterSpacing: ' 0.25px',
    textAlign: 'center',
    padding: '11px 10px',   
    cursor: 'pointer', 
},

};
