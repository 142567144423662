
import React from "react";
import {Grid,TextField,InputLabel, Typography,Dialog,DialogTitle,Box,DialogContent,DialogActions,withStyles,styled} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { CustomButton } from "../../blocks/email-account-login/src/CustomButton";
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';



const useStyles = makeStyles({
    dialogParent:{ width: 455, margin: "auto" },
    dialogHeader:{ display: "flex", justifyContent: "space-between" },
    dialogContaentContainer:{
        margin: "30px 0px 42px 0px",
        display: "flex",
        justifyContent: "space-between",
        gap: "14px",
    },
    inputLabel:{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#F5C42C", paddingBottom: "6px", paddingTop: '16px' },
    headerTitle:{
        color: ' #17181D',
        textAlign: 'center',
        fontFamily: 'Urbanist',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: 'normal',
        letterspacing: '0.2px',
    },
});
const CustomInputlabel = withStyles({
    root: {
        fontFamily: 'Urbanist',
        color: '#f5c42c',
        fontWeight: 600,
        "@media (max-width : 1440px)": {
            fontSize: '14px'
        },
        "@media (min-width : 1441px)": {
            fontSize: '14px',
            lineHeight: "14px",
            paddingBottom: "6px",
            paddingTop: "14px"

        }
    },
})(InputLabel);
const CustomCSSOfTextField = withStyles({
    root: {
        width: "100%",
        backgroundColor: 'white',
        borderColor: "#f5c42c",
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#E3EAF4",

            },
            "&:hover fieldset": {
                borderColor: "#f5c42c"
            },
            "&.Mui-focused fieldset": {
                borderColor: "#f5c42c",
                backgroundColor: 'rgba(245,196,44,0.04)',
            },
            fontWeight: 400,
            color: '#323232',
            borderRadius: "14px",
            "& .MuiOutlinedInput-input": {
                fontWeight: 600,
                lineHeight: '19px',
                borderColor: '#CCCCCC',
                fontSize: '14px',
            }
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#f5c42c"
        },
        "& .MuiInputBase-input::placeholder": {
            color: '#6d89af',
            fontWeight: 500,
            fontFamily: 'Urbanist',
        },
    }
})(TextField);

const CustomNextStepButtonBox = styled(CustomButton)({
    '& .MuiButton-label': {
        fontSize: 14,
        color: '#ffffff',
        fontWeight: 500,
        width: 163,
        height: 48,
        fontFamily:'urbanist'
    }
});

export default function AddNewDepartMentModal(props: any) {
  const classes = useStyles();

    return (
        <>
            <Dialog
                style={{ width: 455, margin: "auto" }}
                fullWidth={true}
                open={props.addNewDepartmentModal}
                onClose={props.handleaddNewDepartmentModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" >
                    <Box style={{ display: "flex", justifyContent: "space-between" }}>
                        <span></span>
                        <span className={classes.headerTitle} > Add New Department</span>
                        <span data-test-id="addDepartmentCloseBtn" onClick={props.handleaddNewDepartmentModal}><CloseIcon /></span>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <CustomInputlabel id="nameOfPH" className={classes.inputLabel}>Name of Dept</CustomInputlabel>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <CustomCSSOfTextField
                            data-test-id="projectNameInput"
                            type={'text'}
                            id="outlined-basic"
                            variant="outlined"
                            placeholder="Enter new department"
                            value={props?.newDepartmentName?.value}
                            onChange={(e: any) => props.handleChange(e)}
                            name="projectName"
                            className="email_input"
                            error={props.newDepartmentName.error}
                            helperText={
                                props.newDepartmentName.error && ( // Render the error message only if error is true
                                    <Box className={""} style={{ display: "flex", gap: "10px" }}>
                                        <ErrorOutlineOutlinedIcon className={"classes.errorIconStyle"} color="error" />
                                        <Typography style={{
                                            color: "#D53333",
                                            fontFamily: "Poppins",
                                            fontSize: "16px",
                                            fontWeight: 400,
                                        }}>
                                            {props.newDepartmentName.errMessage}
                                        </Typography>
                                    </Box>
                                )
                            }
                        />

                    </Grid>
                </DialogContent>
                <DialogActions style={{ padding: "0px 19px 24px" }}>
                    <CustomNextStepButtonBox data-test-id="department_button" style={{ height: 48 } as React.CSSProperties} className="button" onClick={props.handleCloseAddNewDepartmentModal}>
                        Save Department
                    </CustomNextStepButtonBox>
                </DialogActions>
            </Dialog>

        </>
  );
}
