//Customizable Area Start
import { Box, Grid, Typography } from '@material-ui/core';
import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PopupController from './PopupController';
import { CustomButton } from '../../email-account-login/src/CustomButton';
export const configJSON = require("./config");

export default class Popup extends PopupController {
    render() {
        return (
            <Grid container className="popup_box">
                <Grid item xs={4} sm={4} md={4} lg={4} xl={5} className="popup_box">
                    <Box className="popup_box_main">
                        <Box className="popup_box" style={{ marginTop : '34px' ,marginBottom : '16px', paddingTop : '4%' , paddingBottom : '4%' , background: 'rgba(245,196,44,0.12)', width: '17%', height: '36%', borderRadius: '30%' } as React.CSSProperties}>
                            <CheckCircleIcon className="checked_circle" />
                        </Box>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style = {{ textAlign : 'center', marginBottom: "8px" }}>
                            <Typography className = "popup_success_msg">{configJSON.passwordChangeSuccessfullMsg}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='success_msg' style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                            <Typography style = {{ width : "333px", fontSize: "14px", fontWeight: 500, lineHeight: "20px", letterSpacing: "0.21538462px", color: '#6d89af' }}>{configJSON.passwordSuccessMsgHelper}</Typography>
                            <Typography style = {{ width : "333px", fontSize: "14px", fontWeight: 500, lineHeight: "20px", letterSpacing: "0.21538462px", color: '#6d89af' }}>{configJSON.passwordChangeMsg}</Typography>
                        </Grid>
                        <Grid item xs = {12} sm = {12} md = {12} lg = {12} xl = {12} className='popupBox'>
                          <CustomButton data-test-id = "popup_but" style={{ fontSize: "16px", fontWeight: 600 }} className="popup_button" onClick = {this.handlePopupMsgSubmit}>{configJSON.popupButton}</CustomButton>
                        </Grid>
                    </Box>
                </Grid>
            </Grid >
        );
    }
}
//Customizable Area End