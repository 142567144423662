import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");


export interface Props {
    // Customizable Area Start
    navigation: any;
    //id: string;
    personaldetailmodelclose:any
    inviteMemberModal:any
    // Customizable Area End
}
interface S {
    // Customizable Area Start
   
   
    jobRoleInputs:any;
    validationStatusJobRole:any;
    projectRole:any[];
    totalPages: number
    count:number;
    loading: boolean;
    isJobRoleRequestSideBarOpen: boolean;
    isJobRoleRequestHeaderDropdownOpenVal: boolean;
    txtSavedValue: string;
    isLoading: boolean;
    totalJobRoleCount : number;
    vendorInviteCount : number;
    currentpage: number;
    jobRoleAllRequest: any[];
    projectName:any[];
    projectDepartment:any[];
    projectJobRoleRequest:any[];
    open:boolean;
    open2:boolean
    tabIndex:number
    jobRoleprojectName:string
    projectRequest:any[];
    allJobRoleRequestData:any[];
    requestProjectData:any[];
    requestProfileProjects:any[];
   
    isRoleTypeSeleted:boolean
    roleReqError:any
    expError:any
    budgetError:any
    noOfPosError:any
    roleDesError:any
    setEditJobRole:boolean
    requestedVendorDataPersonal:any[];
    notification: { type: string, open: boolean, message: string, route?: string };
    JobRoleNotification: { type: string, open: boolean, message: string, route?: string };
    isProjectSelectedPersonal:boolean
    isDepartmentSelectedPersonal:boolean
   
    requestProfileRateValuePersonal:number;
    requestProfileCommentValuePersonal:string;
    requestRatingValidationPersonal:string;
    requestCommentValidationPersonal:string;
    inviteVendorModalJobRolePersonal:boolean;
    vendorListPersonal:any[];
    requestProfileData:any;
    requestProfileTabValue: number;
    bioReadMoreLessRequestPro: boolean;
    ratingAndReviewData:any;
    inviteVendorManageProfilePersonal:any;
    inviteVendorAccountId:number;
    vendorInvitedToJobRole:boolean;
    vendorListSearch:any[];
    inviteVendorProjectsPersonal:any[];
    inviteVendorRatingAndReviewPersonal:any;
    vendorInvitedToJobRoleIdPersonal:number
    manageProfileInviteVendorPersonal:any[];
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: string | number;
    // Customizable Area End
}

export default class PersonalDetailInviteVendorControlller extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
   
    
    getAllJobRoleRequestCallID :string = "";
    addJobRoleApiCallIdPersonal:string ="";
    deleteJobRoleApiCallIdPersonal:string ="";
    getJobRoleApiCallIdPersonal: string = "";
    getProjectNameApiCallIdPersonal : string ="";
    getProjectDepartmentCallIdPersonal :string = "";
    getProjectDeptRoleApiCallIdPersonal :string ="";
    getAllProjectJobRoleRequestCallIDPersonal : string ="";
    requestProfileApiCallIdPersonal:string="";
    ratingAndReviewApiCallIdPersonal:string="";
    postRatingAndReviewApiCallIdPersonal:string="";
    editJobRoleApiCallIdPersonal:string="";
    acceptVendorRequestCallIdPersonal:string="";
    declineVendorRequestCallIdPersonal:string="";
    inviteVendorUserApiCallId:string="";
    inviteVendorManageProfileApiCallIdPersonal:string="";
    inviteVendorProfileProjectsApiCallIdPersonal:string="";
    ratingAndReviewInviteVendorApiCallIdPersonal:string="";
    postRatingAndReviewInviteVendorApiCallIdPersonal:string="";
    postInviteVendorApiCallIdPersonal:string=""
    acceptRequestProfileCallIdPersonal:string="";
    declineRequestProfileCallIdPersonal:string="";
    removeFromJobApiCallIdPersonal:string=""
    projectRequestProfileCallIdPersonal:string="";
    requestVendorApiCallIdPersonal:string="";
    inviteVendorApiCallId:string="";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
          
            isLoading: false,
            jobRoleAllRequest: [],
            totalPages: 0,
            currentpage: 1,
            count:0,
            loading: false,
            isJobRoleRequestSideBarOpen: true,
            isJobRoleRequestHeaderDropdownOpenVal: false,
            txtSavedValue: "A",
           
            projectName:[],
            projectDepartment:[],
             totalJobRoleCount:0,
            vendorInviteCount:0,
          
            jobRoleInputs:{
                selectedProject:null,
                selectedProjectDept:null,
                selectedProjectRole:null,
                roleDescription:"",
                projectId:null,
                projectDeptId:null,
                roleID:null,
                roleRequirements:[],
                expRequired:"",
                roleBudget:"",
                noOfPos:"",
              


            },
            projectRole:[],
            open:false,
            open2:false,
            roleReqError:{
                require:false
            },
            expError:{
                require:false
            },
            budgetError:{
                require:false
            },
            noOfPosError:{
                require:false
            },
            roleDesError:{
                require:false
            },
            validationStatusJobRole:{
                noOfPos:{label:"No of Pos" , valid:false , message :""},
                roleDescription:{label:"Role Description" , valid:false , message :""},
                roleRequirements:{label:"Role Requirements" , valid:false , message :""},
                expRequired:{label:"Exp Required" , valid:false , message :""},
                roleBudget:{label:"Role Budget" , valid:false , message :""},
              
            },
           
            requestProjectData:[],
            requestProfileProjects:[],
            requestedVendorDataPersonal:[],
            notification: { type: "", open: false, message: "" },
            JobRoleNotification: { type: "", open: false, message: "" },
            isProjectSelectedPersonal:true,
            isDepartmentSelectedPersonal:true,
            isRoleTypeSeleted:true,
            tabIndex:0,
            jobRoleprojectName:'',
            projectJobRoleRequest:[],
            projectRequest:[],
            allJobRoleRequestData:[],
            setEditJobRole:false,
            requestProfileData: {
                id: "",
                profile_image: "",
                role: "",
                city: "",
                state: "",
                experience: "",
                gallary_photos: [],
                bank_detail: {
                  data: {
                    attributes: {
                      image: "",
                      bank_name: "",
                    }
                  }
                },
                account_detail: {
                  data: {
                    attributes: {
                      full_name: "",
                      country_code: "",
                      phone_number: "",
                      email: "",
                      aadhar_card_number: "",
                      gst_number: "",
                      pan_card_number: "",
                      tan_number: "",
                      account_number: "",
                      ifsc_code: "",
                      branch_name: "",
                    }
                  }
                },
                profile_bio: {
                  data: {
                    attributes: {
                      about_business: "",
                    }
                  },
                },
                documents: [],
              },
            requestProfileTabValue:0,
            bioReadMoreLessRequestPro:true,
            ratingAndReviewData:{},
            requestProfileRateValuePersonal:0,
            requestRatingValidationPersonal:'',
            requestCommentValidationPersonal:'',
            requestProfileCommentValuePersonal:'',
            inviteVendorModalJobRolePersonal:false,
            vendorListPersonal:[],
            vendorListSearch:[],
            inviteVendorProjectsPersonal:[],
            inviteVendorRatingAndReviewPersonal:{},
            inviteVendorAccountId:0,
            vendorInvitedToJobRole:false,
            vendorInvitedToJobRoleIdPersonal:0,
            inviteVendorManageProfilePersonal:{
                attributes: {
                  id: 12,
                  country: "",
                  city: "",
                  postal_code: "",
                  account_id: "",
                  state: "",
                  address: "",
                  role: "",
                  department: "",
                  experience: "",
                  production_house_name: "",
                  profile_image: "",
                  documents: [],
                  bank_detail: {
                    data: null
                  },
                  account_detail: {
                    data: {
                      id: "",
                      type: "",
                      attributes: {
                        country_code: "",
                        email: "",
                        first_name: "",
                        full_phone_number: "",
                        last_name: "",
                        phone_number: "",
                        production_house_name: "",
                        production_house_address: "",
                        full_name: "",
                        gst_number: "",
                        tan_number: "",
                        aadhar_card_number: "",
                        pan_card_number: "",
                        account_number: "",
                        branch_name:"" ,
                        bank_name: "",
                        ifsc_code: "",
                        experience: "",
                        notification: "",
                        country_name: "",
                        platform: "",
                        device_id: "",
                        role_detail: {
                          id: "",
                          name: ""
                        },
                        profile_id: "",
                        profile_image: ""
                      }
                    }
                  },
                  profile_bio: {
                    data: ""
                  },
                  employee_invited: "",
                  vendor_invited_to_job_role: "",
                  gallary_photos: []
                }
              },

              manageProfileInviteVendorPersonal:[]
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
            let valueBalanceSheet = message.getData(getName(MessageEnum.AuthTokenDataMessage));


            this.setState({ txtSavedValue: valueBalanceSheet });
            this.showAlert(
                "Change Value",
                "From: " + this.state.txtSavedValue + " To: " + valueBalanceSheet
            );

        }
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if(apiRequestCallId === this.inviteVendorManageProfileApiCallIdPersonal) {
                this.setState({
                    inviteVendorManageProfilePersonal:responseJson.data,
                 inviteVendorAccountId:responseJson.data.attributes.account_id,
                 vendorInvitedToJobRole : responseJson.data.attributes.employee_invited,
                 vendorInvitedToJobRoleIdPersonal : responseJson.data.attributes?.employee_id
                })
              }

            const apiHandlers = {
               
                [this.getProjectDeptRoleApiCallIdPersonal]: this.getProjectDepartmentRolePersonal,
                [this. getAllProjectJobRoleRequestCallIDPersonal]: this.handleAllProjectJobRoleResponsePersonal,
                [this.getAllJobRoleRequestCallID]: this.getAllJobRoleResponsePersonal,
                [this.addJobRoleApiCallIdPersonal]: this.handleAddJobRoleResponsePersonal,
                [this.editJobRoleApiCallIdPersonal]: this.handleEditJobRoleResponsePersonal,
                [this.deleteJobRoleApiCallIdPersonal]: this.handleDeleteJobRoleResponsePersonal,
                [this.acceptVendorRequestCallIdPersonal]:this.handleAcceptUpdateVendorRequestPersonal,
                [this.declineVendorRequestCallIdPersonal]:this.handleDeclineUpdateVendorRequestPersonal,
                [this.acceptRequestProfileCallIdPersonal] :this.handleAcceptRequestProfilePersonal,
                [this.declineRequestProfileCallIdPersonal] : this.handleDeclineRequestProfilePersonal,
    
                [this.ratingAndReviewApiCallIdPersonal]: this.handleRatingAndReviewResponsePersonal,
                [this.postRatingAndReviewApiCallIdPersonal] : this.handlePostRatingAndReviewResponsePersonal,
                [this.projectRequestProfileCallIdPersonal] : this.handleProjectTabRequestProfilePersonal,
                [this.inviteVendorProfileProjectsApiCallIdPersonal] : this.handleInviteVendorProjectResponsePersonal, 
                [this.ratingAndReviewInviteVendorApiCallIdPersonal] : this.handleInviteVendorRatingReviewResponsePersonal,
                [this.requestVendorApiCallIdPersonal] : this.handleRequestedVendorResponsePersonal,
                [this.inviteVendorApiCallId] :this.handleInviteVendorResposnePersonal,   
               
                [this.postRatingAndReviewInviteVendorApiCallIdPersonal] : this.handlePostRatingInviteVendorResponsePersonal,    
                [this.postInviteVendorApiCallIdPersonal] : this.handlePostInviteVendorResponsePersonal,
                [this.inviteVendorApiCallId] :this.handleInviteVendorResposnePersonal,    
                [this.removeFromJobApiCallIdPersonal] : this.handleRemoveFromJobResponsePersonal,
                [this.getJobRoleApiCallIdPersonal]: this.handleJobRoleApiResponsePersonal,
                [this.getProjectNameApiCallIdPersonal]: this.getProjectNameDataPersonal,
                [this.getProjectDepartmentCallIdPersonal]: this.getProjectDepartmentPersonal,         
            };
        
            const handler = apiHandlers[apiRequestCallId];
            if (handler) {
                handler.call(this, responseJson);
            }
            
        }

        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
       

        await this.getInviteVendorProfileProjectPersonal();
        await this.getRatingAndReviewInviteVendorProfilePersonal();
        await this.getRequestedVendorRequestJobRoleDataPersonal();
        await this.getInviteVendorJobRoleResponsePersonal();
        await this.getManageProfileInviteVendorResponsePersonal()
       
        await this.getRatingAndReviewRequestProfilePersonal();
        await this.getMyAllJobRolePersonal();
        await this.getMyAllProjectNamePersonal();
        await this.getMyJobRoleAndRequestPersonal();
        await this.getMyAllRequestprofileDataPersonal();
        await this.getProjectRequestProfilePersonal();
        let isSideBarOpen = await getStorageData("sidebarvalue");
        this.setState({ isJobRoleRequestSideBarOpen: JSON.parse(isSideBarOpen) })
        const projectNames=  await getStorageData("jobRoleproject_Name");
        this.setState({jobRoleprojectName:projectNames})

    }

    handleJobRoleSideBarPersonal = () => {
        this.setState((prevState) => ({
            isJobRoleRequestSideBarOpen: !prevState.isJobRoleRequestSideBarOpen
        }), async () => {
            setStorageData("sidebarvalue", this.state.isJobRoleRequestSideBarOpen.toString())
        });
    };

    handleJobRoleHeaderDropdownValPersonal = () => {
        this.setState({
            isJobRoleRequestHeaderDropdownOpenVal: !this.state.isJobRoleRequestHeaderDropdownOpenVal
        })
    };
    handleTabValueRequestProfileChangePersonal = (tabVal: number) => {
        this.setState({ requestProfileTabValue: tabVal });
    }

    handleBioReadRequestProfilePersonal = () => {
        this.setState({ bioReadMoreLessRequestPro: !this.state.bioReadMoreLessRequestPro });
    }

    requestProfileHandleDownloadPDFPersonal = (pdfURL: any, fileName: string) => {
        fetch(pdfURL)
          .then(VendorManageProfileResponse => {
            VendorManageProfileResponse.blob().then(blob => {
              let photoUrl = window.URL.createObjectURL(blob);
              let aTag = document.createElement('a');
              aTag.href = photoUrl;
              aTag.download = fileName;
              aTag.click();
            });
          });
    }

    handleJobRoleApiResponsePersonal(responseJson:any) {
        this.setState({
            jobRoleAllRequest: responseJson.data, 
            count: responseJson.meta.projects_count,
            totalJobRoleCount: responseJson.meta.total_invoice_count,
            isLoading: false,
        });
    }

    handleAllProjectJobRoleResponsePersonal= async(responseJson:any) => {
        this.setState({
            projectJobRoleRequest: responseJson.data,
            isLoading: false,
        });
    }

    handleAddJobRoleResponsePersonal(responseJson:any) {
        if (responseJson) {
            this.handleCloseJobRoleModelPersonal();
            this.setState({
                isLoading: false,
            });
        }
    }

    handleEditJobRoleResponsePersonal(responseJson:any) {
        if (responseJson) {
            this.handleCloseJobRoleModelPersonal1();
            this.getMyJobRoleAndRequestPersonal();
            this.setState({
                isLoading: false,
            });
        }
    }

    handleDeleteJobRoleResponsePersonal(responseJson:any) {
        if (responseJson) {
            this.getMyJobRoleAndRequestPersonal();
            this.setState({
                isLoading: false,
            });
        }
    }

    handleAcceptUpdateVendorRequestPersonal(responseJson:any) {
        if (responseJson.data) {
            this.getMyJobRoleAndRequestPersonal();
            this.setState({
                requestedVendorDataPersonal:[]
            })
        }
        else{
            if (responseJson.errors && responseJson.errors.length > 0) {
                this.setState({ notification: { open: true, type: "error", message: responseJson.errors[0]} });
            }
        }
        this.setState({
            isLoading: false,
        });
    }

    handleDeclineUpdateVendorRequestPersonal(responseJson:any) {
        if (responseJson) {
            this.getRequestedVendorRequestJobRoleDataPersonal();
            this.setState({
                isLoading: false,
                requestedVendorDataPersonal:[]
            });
        }
    }

    handleAcceptRequestProfilePersonal(responseJson:any) {
        if (responseJson.data) {
            this.props.navigation.navigate("JobRolesAndRequest")
            this.getMyJobRoleAndRequestPersonal();

        }
        else{
            if (responseJson.errors && responseJson.errors.length > 0) {
                this.setState({ notification: { open: true, type: "error", message: responseJson.errors[0]} });
            }
        }
        this.setState({
            isLoading: false,
        });
    }

    handleDeclineRequestProfilePersonal(responseJson:any) {
        if (responseJson) {
            this.props.navigation.navigate("JobRolesAndRequest")
            this.getMyJobRoleAndRequestPersonal();
            this.setState({
                isLoading: false,
            });
        }
    }

    handleRequestProjectResponsePersonal(responseJson:any) {
       
        if(responseJson) {
            this.setState({
                requestProfileData:responseJson.data.attributes
            })
        }
    }
    handleRatingAndReviewResponsePersonal(responseJson:any) {
        if(responseJson){
            this.setState({
                ratingAndReviewData:responseJson,
                isLoading:false
            })
        }
    }

    handlePostRatingAndReviewResponsePersonal(responseJson:any){
        if(responseJson){
        this.getRatingAndReviewRequestProfilePersonal();
        this.setState({
            isLoading: false,
        });
        this.setState({
            requestProfileRateValuePersonal:0,
            requestProfileCommentValuePersonal:''
        })
        }
    }

    handleProjectTabRequestProfilePersonal(responseJson:any){
        if(responseJson){
            this.setState({
                requestProfileProjects:responseJson.data,
                isLoading: false,
            })
        }
    }

      handleRequestedVendorResponsePersonal(responseJson:any){
        if(responseJson.data){
            this.setState({
                requestedVendorDataPersonal:responseJson.data
            })
        }
        this.setState({ isLoading: false });
    }

    handleInviteVendorResposnePersonal(responseJson:any){
        if(responseJson){
            this.setState({
                vendorListPersonal:responseJson.data,
                vendorListSearch:responseJson.data,
                vendorInviteCount: responseJson.meta.count,
            })
        }
    }

    handleInviteVendorProjectResponsePersonal(responseJson:any){
        if(responseJson){
            this.setState({
                inviteVendorProjectsPersonal:responseJson.data
            })
        }
    }
   
    handleInviteVendorRatingReviewResponsePersonal(responseJson:any){
        if(responseJson){
            this.setState({
                inviteVendorRatingAndReviewPersonal:responseJson
            })
        }
    }

    handleRemoveFromJobResponsePersonal(responseJson:any){
        if(responseJson){
            this.getManageProfileInviteVendorResponsePersonal()
            this.setState({ isLoading: false });
        }
    }

    navigateToAllJobRolePage=(navigate:string)=>{
        this.props.navigation.navigate(navigate)
    }

    navigateToRequestProfilePage=(navigate:string,profile_id:number,rateable_id:number,requestId:number)=>{
        setStorageData("profileId", profile_id);  
        setStorageData("rateableId",rateable_id)
        setStorageData("requestIdProfile",requestId)
        this.props.navigation.navigate(navigate)
    }

    handleSearchInviteVendor = (event:any) => {
        const updatedList: any[] | null = this.state.vendorListSearch && (this.state.vendorListSearch.filter((item) => {
          return item.attributes.full_name.toLowerCase().includes(event.target.value.toLowerCase())
        }) ?? null);
        this.setState({
            vendorListPersonal: updatedList
        })
      };

      handlePostRatingInviteVendorResponsePersonal(responseJson:any){
        if(responseJson){
        this.getRatingAndReviewInviteVendorProfilePersonal();
        this.setState({
            isLoading: false,
        });
        this.setState({
            requestProfileRateValuePersonal:0,
            requestProfileCommentValuePersonal:''
        })
        }
    }

    handlePostInviteVendorResponsePersonal(responseJson:any){
        if(responseJson){
            this.getManageProfileInviteVendorResponsePersonal()
            this.setState({
                isLoading: false,
            }); 
        }
    }



    navigateToRequestPage=(job_role_id:number)=>{
        setStorageData("jobRoleId", job_role_id);  
        this.props.navigation.navigate("Request",)
    }

    getMyAllJobRolePersonal = async () => {
        this.getJobRoleApiCallIdPersonal = await this.allJobRoleRequestPersonal(configJSON.GET_Method, configJSON.getallJobRoleRequestEndPoint + `?page=1&per_page=9`);
    };

    getMyAllProjectNamePersonal = async () => {
        this.getProjectNameApiCallIdPersonal = await this.allProjectNamePersonal(configJSON.GET_Method, configJSON.getAllProjectNameEndPoint);
    };

    getMyAllprojectDeptRole =async () =>{
        this.getProjectDeptRoleApiCallIdPersonal = await this.allProjectRoleTypePersonal(configJSON.GET_Method, configJSON.getProjectDeptRoleEndPoint)
    }

    getMyAllProjectDepartment =async () =>{
        this.getProjectDepartmentCallIdPersonal =await this.allProjectDepartmentPersonal(configJSON.GET_Method, configJSON.getAllProjectDepartmentEndPoint + `?project_id=${this.state.jobRoleInputs.projectId}`)
    }

    getMyAllProjectDepartmentEdit =async (projectId:any) =>{
        this.getProjectDepartmentCallIdPersonal =await this.allProjectDepartmentPersonal(configJSON.GET_Method, configJSON.getAllProjectDepartmentEndPoint + `?project_id=${projectId}`)
    }


    getMyJobRoleAndRequestPersonal = async () => {
        const projectJobId=  await getStorageData("jobRoleproject_Id");
        this.getAllProjectJobRoleRequestCallIDPersonal = await this.allProjectJobRoleRequestPersonal(configJSON.GET_Method , configJSON.getAllProjectJobRoleRequestEndPoint + `?project_id=${projectJobId}`);
    }
    postMyAddJobRoleFormData =async () =>{
        this.addJobRoleApiCallIdPersonal = await this.addJobRoleApiDepartment(configJSON.httpPostMethod,configJSON.postAddJobRoleEndPoint)
    }

    editMyJobRoleFormData = async () =>{
        let editJobId = await getStorageData("editJobRoleId");
        this.editJobRoleApiCallIdPersonal = await this.jobRoleRequestEditPersonal("PUT","bx_block_roles_permissions/job_roles/" + `${editJobId}`)
    }

    deleteJobRoleRequest =async (id:any) =>{
        this.deleteJobRoleApiCallIdPersonal = await this.jobRoleRequestDeletePersonal(configJSON.DELETE_Method,configJSON.deleteJobRoleRequest + `/${id}`)
    }

    acceptVendorRequest =async(id:any)=>{
        this.acceptVendorRequestCallIdPersonal = await this.acceptUpdateVendorRequestPersonal(configJSON.PATCH_Method,configJSON.acceptUpdateVendorEndPoint + `/${id}`)
    }

    declineVendorRequest = async(id:any)=>{
        this.declineVendorRequestCallIdPersonal = await this.declineUpdateVendorRequestPersonal(configJSON.PATCH_Method,configJSON.acceptUpdateVendorEndPoint + `/${id}`)
    }

    acceptVendorRequestProfile=async()=>{
        let requestAcceptId = await getStorageData("requestIdProfile");
        this.acceptRequestProfileCallIdPersonal = await this.acceptRequestProfilePersonal(configJSON.PATCH_Method,configJSON.acceptUpdateVendorEndPoint + `/${requestAcceptId}`)
    }

    declineVendorRequestProfile=async()=>{
        let requestDeclineId = await getStorageData("requestIdProfile");
        this.declineRequestProfileCallIdPersonal = await this.declineRequestProfilePersonal(configJSON.PATCH_Method, configJSON.acceptUpdateVendorEndPoint + `/${requestDeclineId}`)
    }

    getMyAllRequestprofileDataPersonal = async()=>{
        let requestProfileId = await getStorageData("profileId");
        this.requestProfileApiCallIdPersonal = await this.getRequestProfileDataPersonal(configJSON.GET_Method ,configJSON.getRequestProfileEndPoint + `/${requestProfileId}`)
    }

    getRatingAndReviewRequestProfilePersonal = async()=>{
        let requestProfileIdForRating = await getStorageData("profileId");
        this.ratingAndReviewApiCallIdPersonal = await this.getRatingAndReviewDataPersonal(configJSON.GET_Method , configJSON.getRatingAndReviewEndPoint + `?profile_id=${requestProfileIdForRating}`);
    }

    getProjectRequestProfilePersonal = async()=>{
        let requestProfileTabDataId = await getStorageData("profileId");
        this.projectRequestProfileCallIdPersonal = await this.getProjectTabDataRequestProfilePersonal(configJSON.GET_Method , configJSON.getProfileDataRequestEndPoint + `/${requestProfileTabDataId}`)
    }

    getRequestedVendorRequestJobRoleDataPersonal= async()=>{
        let requestVendorId = await getStorageData("jobRoleId");
        this.requestVendorApiCallIdPersonal = await this.getJobRoleRequestedVendorPersonal(configJSON.GET_Method , configJSON.getRequestVendorEndPoint + `/${requestVendorId}`)
    }

    getInviteVendorJobRoleResponsePersonal=async()=>{
        this.inviteVendorApiCallId = await this.getInviteVendorJobRolePersonal(configJSON.GET_Method,configJSON.getInviteVendorEndPoint + `?page=1&per_page=9`)
    }

    getManageProfileInviteVendorResponsePersonal=async()=>{
        let jobRoleAndRequestCardId =   await getStorageData("jobRoleproject_Id");;
        let inviteVendorUserProfileId = await getStorageData("userInviteVendor");
        if(inviteVendorUserProfileId && jobRoleAndRequestCardId){
        this.inviteVendorManageProfileApiCallIdPersonal = await this.getInviteVendorManageProfilePersonal(configJSON.GET_Method,configJSON.getRequestProfileEndPoint + `/${inviteVendorUserProfileId}?project_id=${jobRoleAndRequestCardId}`)
        }
    }

    getInviteVendorProfileProjectPersonal=async()=>{
        let inviteVendorUserProfileId = await getStorageData("userInviteVendor");
        this.inviteVendorProfileProjectsApiCallIdPersonal = await this.getInviteVendorManageProfileProjectsPersonal(configJSON.GET_Method , configJSON.getProfileDataRequestEndPoint + `/${inviteVendorUserProfileId}`)
    }

    getRatingAndReviewInviteVendorProfilePersonal = async()=>{
        let inviteVendorUserProfileId = await getStorageData("userInviteVendor");
        this.ratingAndReviewInviteVendorApiCallIdPersonal = await this.getInviteVendorManageProfileRatingsPersonal(configJSON.GET_Method , configJSON.getRatingAndReviewEndPoint + `?profile_id=${inviteVendorUserProfileId}`);
    }

    deletefromJobInviteVendorPersonal = async()=>{
        this.removeFromJobApiCallIdPersonal = await this.removeFromJobInviteVendorPersonalDetail(configJSON.DELETE_Method,configJSON.deleteFormJobEndPoint + `/${this.state.vendorInvitedToJobRoleIdPersonal}`)
    }

    editJobRoleRequestPersonal = async(id:any) =>{       
        setStorageData("editJobRoleId", id);  
        this.setState({ setEditJobRole: true });
        this.setState({open2:false})
        for(let i=0;i<=this.state.projectJobRoleRequest.length;i++){
             if(this.state.projectJobRoleRequest[i]?.id === id){
                this.getMyAllProjectDepartmentEdit(this.state.projectJobRoleRequest[i].attributes.project_id);
                this.getMyAllprojectDeptRole();
                const project= {

                    label: this.state.projectJobRoleRequest[i].attributes.project_name,
                     value: this.state.projectJobRoleRequest[i].attributes.project_id
                }
                const projectDeptSelection= {

                    label: this.state.projectJobRoleRequest[i].attributes.project_department,
                     value: this.state.projectJobRoleRequest[i].attributes.project_department_id
                }
                const projectRoleSelection= {

                    label: this.state.projectJobRoleRequest[i].attributes.role,
                     value: this.state.projectJobRoleRequest[i].attributes.role_id
                }
                this.setState({jobRoleInputs:{
                selectedProject:project,
                selectedProjectDept:projectDeptSelection,
                selectedProjectRole:projectRoleSelection,
                roleRequirements:this.state.projectJobRoleRequest[i].attributes.job_role_requirment.map((item:any)=>item),
                expRequired:this.state.projectJobRoleRequest[i].attributes.exp_required,
                roleBudget:this.state.projectJobRoleRequest[i].attributes.budget_of_role,
                noOfPos:this.state.projectJobRoleRequest[i].attributes.number_of_positions,
                roleDescription:this.state.projectJobRoleRequest[i].attributes.role_description
    }})
}}}

    handlePageChangePersonal = async (event: any, value: number) => {
        this.getJobRoleApiCallIdPersonal = await this.allJobRoleRequestPersonal(configJSON.GET_Method, configJSON.getallJobRoleRequestEndPoint + `?page=${value}&per_page=9`);
        window.scrollTo(0, 0);
        this.setState({ currentpage: value });
    };

    handlePageChangeInviteVendorPersonal =async(event:any,value:number)=>{
        this.inviteVendorApiCallId = await this.getInviteVendorJobRolePersonal(configJSON.GET_Method,configJSON.getInviteVendorEndPoint + `?page=${value}&per_page=9`);
        window.scrollTo(0, 0);
        this.setState({ currentpage: value });
    }

    handleRateStarPersonal=(e:any)=>{
    if(e.target.value===''){
        this.setState({requestRatingValidationPersonal:'Please give rating'})
    }
    else{
        this.setState({requestRatingValidationPersonal:''});
    }
    this.setState({requestProfileRateValuePersonal:e.target.value}) 
    }

    handleCommentRequestProfilePersonal=(e:any)=>{
    if(e.target.value===''){
        this.setState({requestCommentValidationPersonal:'Please give rating'})
    }
    else{
        this.setState({requestCommentValidationPersonal:''});
    }
    this.setState({requestProfileCommentValuePersonal:e.target.value})
    } 


    handleOpenAddJobRolePersonal=()=>{
        this.setState({
            open:true
        })
    }
    navigateToJobRolesAndRequestPersonal=(jobRoleprojectId:any,jobRoleprojectName:any)=>{
        this.props.navigation.navigate("JobRolesAndRequest")
        setStorageData("jobRoleproject_Id", jobRoleprojectId);   
        setStorageData("jobRoleproject_Name",jobRoleprojectName);
      }

    covertToPascalCaseProfileProjectPersonal = (text = '', trimSpace = false) => {
        if (typeof text !== 'string') {
            return '';
        }
    
        return text.split(' ').map((t) => t[0]?.toUpperCase() + t.slice(1).toLowerCase()).join(trimSpace ? '' : ' ')
    }

    handleCloseJobRoleModelPersonal = () => {
        this.setState({
            open: false,
            jobRoleInputs: {
                ...this.state.jobRoleInputs,
                selectedProject: null,
                selectedProjectDept:null,
                selectedProjectRole:null,
                roleRequirements:[],
                expRequired:"",
                roleBudget:"",
                noOfPos:"",
                roleDescription:"",
            },
            projectDepartment: [],
            projectRole: [],
            isProjectSelectedPersonal: true,
            isDepartmentSelectedPersonal: true,
            isRoleTypeSeleted: true ,
            roleReqError:{require:false},
            expError:{require:false},
            budgetError:{require:false},
            noOfPosError:{require:false},
            roleDesError:{require:false},
            requestProfileCommentValuePersonal:"",
            requestProfileRateValuePersonal:0,
            requestRatingValidationPersonal:'',
            requestCommentValidationPersonal:'',
        });
    }

    handleCloseJobRoleModelPersonal1 =()=>{
        this.setState({
            setEditJobRole:false
        })
    }

    handleJobRoleEditAndDelete = (id: any) => {
        this.setState(prevState => ({
            open2: prevState.open2 === id ? null : id
        }));
    }


    getProjectNameDataPersonal=(responseJson:any)=>{
        const fetchedProject = responseJson?.data?.map((item: { id: number, attributes: { project_name: string } }) => ({
            id: item.id,
            name: item.attributes.project_name,
        }));
        this.setState({
             projectName:fetchedProject,
             isLoading:false
         });
    }

    getProjectDepartmentPersonal=(responseJson:any)=>{
        const projectDepartmentResponse = responseJson?.data?.map((item :{ id: number, attributes: { name: string } })=>({
            department:item.attributes.name,
            dept_id:item.id,
        }));
        this.setState({
            projectDepartment:projectDepartmentResponse,
            isLoading:false
        }) 
    }

    getProjectDepartmentRolePersonal=(responseJson:any)=>{
        const projectDeptRoleRes = responseJson?.data?.map((item : {id:number , name:string})=>({
            deptRole:item.name,
            role_id:item.id
        }));
        this.setState({
            projectRole:projectDeptRoleRes,
            isLoading:false
        })
    }

    getAllJobRoleResponsePersonal=(responseJson:{ errors: { token: string }[], data: any })=>{
        if(responseJson.data){
            this.setState({
                allJobRoleRequestData:responseJson.data
            })
        }
        else if({ notification: { open: true, type: "error", message: responseJson.errors[0].token, route: "Request" } }){
            this.setState({ notification: { open: true, type: "error", message: responseJson.errors[0].token, route: "Request" } });
        }
        this.setState({ isLoading: false });
    }

   
    
   

   


  

    

    

   
   

   

   

  

    handleRatingAndReviewInviteVendorFormDepartment=()=>{
        if(this.state.requestProfileCommentValuePersonal===''&& this.state.requestProfileRateValuePersonal===0){
          this.setState({
            requestRatingValidationPersonal:"Please give rating",
            requestCommentValidationPersonal:'Please give review'
        })
        }
        else if(this.state.requestProfileRateValuePersonal===0){
          this.setState({requestRatingValidationPersonal:"Please give rating"})
        }
        else if(this.state.requestProfileCommentValuePersonal===''){
            this.setState({requestCommentValidationPersonal:'Please give review'})
            this.setState({requestProfileRateValuePersonal:0})
        }
        else{
            this.postInviteVendorRatingAndReviewDataPersonal();
        }

    }

   

    allJobRoleRequestPersonal = async (methodalljobrole: string, endPointalljobrole: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageJobRoleRequestAll = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageJobRoleRequestAll.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPointalljobrole
        );

        requestMessageJobRoleRequestAll.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageJobRoleRequestAll.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            methodalljobrole
        );
        runEngine.sendMessage(requestMessageJobRoleRequestAll.id, requestMessageJobRoleRequestAll);
        return requestMessageJobRoleRequestAll.messageId;
    };

    allProjectNamePersonal = async (methodProjectpersonal: string, endPointProjectpersonal: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageJobRoleRequestProjectName = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageJobRoleRequestProjectName.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPointProjectpersonal
        );

        requestMessageJobRoleRequestProjectName.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageJobRoleRequestProjectName.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            methodProjectpersonal
        );
        runEngine.sendMessage(requestMessageJobRoleRequestProjectName.id, requestMessageJobRoleRequestProjectName);
        return requestMessageJobRoleRequestProjectName.messageId;
    };

    allProjectDepartmentPersonal = async (methoddepartmentpersonal: string, endPointdepartmentpersonal: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageJobRoleRequestProjectDept = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageJobRoleRequestProjectDept.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPointdepartmentpersonal
        );

        requestMessageJobRoleRequestProjectDept.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageJobRoleRequestProjectDept.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            methoddepartmentpersonal
        );
        runEngine.sendMessage(requestMessageJobRoleRequestProjectDept.id, requestMessageJobRoleRequestProjectDept);
        return requestMessageJobRoleRequestProjectDept.messageId;
    };

    allProjectRoleTypePersonal = async (methodRoletype: string, endPointRoleType: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageJobRoleRequestRoleType = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageJobRoleRequestRoleType.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPointRoleType
        );

        requestMessageJobRoleRequestRoleType.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageJobRoleRequestRoleType.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            methodRoletype
        );
        runEngine.sendMessage(requestMessageJobRoleRequestRoleType.id, requestMessageJobRoleRequestRoleType);
        return requestMessageJobRoleRequestRoleType.messageId;
    };

    allProjectJobRoleRequestPersonal= async (methodjobrolerequest: string, endPointjobrolerequest: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageJobRoleRequestProject = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageJobRoleRequestProject.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPointjobrolerequest
        );

        requestMessageJobRoleRequestProject.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageJobRoleRequestProject.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            methodjobrolerequest
        );
        runEngine.sendMessage(requestMessageJobRoleRequestProject.id, requestMessageJobRoleRequestProject);
        return requestMessageJobRoleRequestProject.messageId;
    };

   

    addJobRoleApiDepartment = async (methodpersonaljobrole: string, endPointpersonaljobrole: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageJobRoleRequestProject = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageJobRoleRequestProject.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPointpersonaljobrole
        );

        const job_roleData = {
            job_role:{
            role_description: this.state.jobRoleInputs.roleDescription,
            exp_required: this.state.jobRoleInputs.expRequired,
            budget_of_role: this.state.jobRoleInputs.roleBudget,
            number_of_positions:this.state.jobRoleInputs.noOfPos,
            project_id:this.state.jobRoleInputs.projectId,
            project_department_id :this.state.jobRoleInputs.projectDeptId,
            role_id:this.state.jobRoleInputs.roleID,
            job_role_requirment: this.state.jobRoleInputs.roleRequirements
            }
            }

        requestMessageJobRoleRequestProject.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(job_roleData)
        );
        requestMessageJobRoleRequestProject.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            header
        );
        requestMessageJobRoleRequestProject.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            methodpersonaljobrole
        );
        runEngine.sendMessage(requestMessageJobRoleRequestProject.id, requestMessageJobRoleRequestProject);
        return requestMessageJobRoleRequestProject.messageId;
    };

    jobRoleRequestDeletePersonal = async (method: string, endPoint: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageJobRoleRequestRole = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageJobRoleRequestRole.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        requestMessageJobRoleRequestRole.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageJobRoleRequestRole.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(requestMessageJobRoleRequestRole.id, requestMessageJobRoleRequestRole);
        return requestMessageJobRoleRequestRole.messageId;
    };

    jobRoleRequestEditPersonal = async (methodeditjobrole: string, endPointeditjobrole: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageJobRoleRequestRole = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageJobRoleRequestRole.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPointeditjobrole
        );

        const job_roleData_edit = {
            job_role:{
            role_description: this.state.jobRoleInputs.roleDescription,
            exp_required: this.state.jobRoleInputs.expRequired,
            budget_of_role: this.state.jobRoleInputs.roleBudget,
            number_of_positions:this.state.jobRoleInputs.noOfPos,
            project_id:this.state.jobRoleInputs.projectId,
            project_department_id :this.state.jobRoleInputs.projectDeptId,
            role_id:this.state.jobRoleInputs.roleID,
            job_role_requirment: this.state.jobRoleInputs.roleRequirements
            }
            }

            requestMessageJobRoleRequestRole.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(job_roleData_edit)
        );

        

        requestMessageJobRoleRequestRole.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageJobRoleRequestRole.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            methodeditjobrole
        );
        runEngine.sendMessage(requestMessageJobRoleRequestRole.id, requestMessageJobRoleRequestRole);
        return requestMessageJobRoleRequestRole.messageId;
    };

    acceptUpdateVendorRequestPersonal = async (methodaccept: string, endPointaccept: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageJobRoleRequestAccept = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageJobRoleRequestAccept.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPointaccept
        );
        const vendorJobRequest = {
            vendor_job_request:{
                status: "accept",
            }
        }
        requestMessageJobRoleRequestAccept.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(vendorJobRequest)
        );

        requestMessageJobRoleRequestAccept.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageJobRoleRequestAccept.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            methodaccept
        );
        runEngine.sendMessage(requestMessageJobRoleRequestAccept.id, requestMessageJobRoleRequestAccept);
        return requestMessageJobRoleRequestAccept.messageId;
    };

    declineUpdateVendorRequestPersonal = async (methoddecline: string, endPointdecline: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageJobRoleRequestDecline = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageJobRoleRequestDecline.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPointdecline
        );

        const vendorJobRequest = {
            vendor_job_request:{
                status: "decline",
            }
        }

        requestMessageJobRoleRequestDecline.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(vendorJobRequest)
        );

        requestMessageJobRoleRequestDecline.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageJobRoleRequestDecline.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            methoddecline
        );
        runEngine.sendMessage(requestMessageJobRoleRequestDecline.id, requestMessageJobRoleRequestDecline);
        return requestMessageJobRoleRequestDecline.messageId;
    };

    acceptRequestProfilePersonal = async (methodacceptrequest: string, endPointrequest: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageAcceptRequestProfile = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageAcceptRequestProfile.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPointrequest
        );
        const vendorJobRequest = {
            vendor_job_request:{
                status: "accept",
            }
        }
        requestMessageAcceptRequestProfile.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(vendorJobRequest)
        );

        requestMessageAcceptRequestProfile.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageAcceptRequestProfile.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            methodacceptrequest
        );
        runEngine.sendMessage(requestMessageAcceptRequestProfile.id, requestMessageAcceptRequestProfile);
        return requestMessageAcceptRequestProfile.messageId;
    };

    declineRequestProfilePersonal = async (method: string, endPoint: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageDeclineRequestProfile = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageDeclineRequestProfile.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        const vendorJobRequest = {
            vendor_job_request:{
                status: "decline",
            }
        }
        requestMessageDeclineRequestProfile.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(vendorJobRequest)
        );

        requestMessageDeclineRequestProfile.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageDeclineRequestProfile.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(requestMessageDeclineRequestProfile.id, requestMessageDeclineRequestProfile);
        return requestMessageDeclineRequestProfile.messageId;
    };


    getRequestProfileDataPersonal = async (method: string, endPoint: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageJobRoleRequestProfile = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageJobRoleRequestProfile.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        requestMessageJobRoleRequestProfile.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageJobRoleRequestProfile.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(requestMessageJobRoleRequestProfile.id, requestMessageJobRoleRequestProfile);
        return requestMessageJobRoleRequestProfile.messageId;
    };

    getRatingAndReviewDataPersonal = async (method: string, endPoint: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageJobRoleRequestProjects = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageJobRoleRequestProjects.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        requestMessageJobRoleRequestProjects.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageJobRoleRequestProjects.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(requestMessageJobRoleRequestProjects.id, requestMessageJobRoleRequestProjects);
        return requestMessageJobRoleRequestProjects.messageId;
    };

    getProjectTabDataRequestProfilePersonal = async (method: string, endPoint: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageRequestedJobRoleVendor = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageRequestedJobRoleVendor.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        requestMessageRequestedJobRoleVendor.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageRequestedJobRoleVendor.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(requestMessageRequestedJobRoleVendor.id, requestMessageRequestedJobRoleVendor);
        return requestMessageRequestedJobRoleVendor.messageId;
    };

   

    getJobRoleRequestedVendorPersonal = async (method: string, endPoint: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageJobRoleRequestVendor = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageJobRoleRequestVendor.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        requestMessageJobRoleRequestVendor.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageJobRoleRequestVendor.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(requestMessageJobRoleRequestVendor.id, requestMessageJobRoleRequestVendor);
        return requestMessageJobRoleRequestVendor.messageId;
    };

    getInviteVendorJobRolePersonal = async (methodinvitevendor: string, endPointinvitevendor: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        const requestMessageJobRoleRequestInviteVendor = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageJobRoleRequestInviteVendor.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPointinvitevendor
        );

        requestMessageJobRoleRequestInviteVendor.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageJobRoleRequestInviteVendor.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            methodinvitevendor
        );
        runEngine.sendMessage(requestMessageJobRoleRequestInviteVendor.id, requestMessageJobRoleRequestInviteVendor);
        return requestMessageJobRoleRequestInviteVendor.messageId;
    };

    getInviteVendorManageProfilePersonal = async (methodManageProfile: string, endPointManageProfile: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        const requestMessageManageProfileInviteVendor = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageManageProfileInviteVendor.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPointManageProfile
        );

        requestMessageManageProfileInviteVendor.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageManageProfileInviteVendor.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            methodManageProfile
        );
        runEngine.sendMessage(requestMessageManageProfileInviteVendor.id, requestMessageManageProfileInviteVendor);
        return requestMessageManageProfileInviteVendor.messageId;
    };

    getInviteVendorManageProfileProjectsPersonal = async (methodProfileproject: string, endPointprofileproject: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        const requestMessageManageProfileInviteVendorProjects = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageManageProfileInviteVendorProjects.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPointprofileproject
        );

        requestMessageManageProfileInviteVendorProjects.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageManageProfileInviteVendorProjects.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            methodProfileproject
        );
        runEngine.sendMessage(requestMessageManageProfileInviteVendorProjects.id, requestMessageManageProfileInviteVendorProjects);
        return requestMessageManageProfileInviteVendorProjects.messageId;
    };

    getInviteVendorManageProfileRatingsPersonal = async (methodRatingandReview: string, endPointRatingandReview: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        const requestMessageManageProfileInviteVendorRatings = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageManageProfileInviteVendorRatings.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPointRatingandReview
        );

        requestMessageManageProfileInviteVendorRatings.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageManageProfileInviteVendorRatings.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            methodRatingandReview
        );
        runEngine.sendMessage(requestMessageManageProfileInviteVendorRatings.id, requestMessageManageProfileInviteVendorRatings);
        return requestMessageManageProfileInviteVendorRatings.messageId;
    };

    postInviteVendorRatingAndReviewDataPersonal = async () => {
        this.setState({open:false})
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageInviteVendorRating = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.postRatingAndReviewInviteVendorApiCallIdPersonal = requestMessageInviteVendorRating.messageId;

        requestMessageInviteVendorRating.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.postRatingAndReviewEndPoint
        );

        const httpBody = {
            review : {
              "comment":this.state.requestProfileCommentValuePersonal,
              "given_star":this.state.requestProfileRateValuePersonal,
              "reviewable_id":this.state.inviteVendorAccountId,
              "reviewable_type":"AccountBlock::Account"
            }
        };

        requestMessageInviteVendorRating.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
        );

        requestMessageInviteVendorRating.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageInviteVendorRating.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpPostMethod
        );
        runEngine.sendMessage(requestMessageInviteVendorRating.id, requestMessageInviteVendorRating);
        return requestMessageInviteVendorRating.messageId;
    };

    postInviteforJobPersonal = async () => {
        
        const projectJobId=  await getStorageData("jobRoleproject_Id");
        this.setState({open:false})
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageInviteForJob = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.postInviteVendorApiCallIdPersonal = requestMessageInviteForJob.messageId;

        requestMessageInviteForJob.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.postInviteVendorEndPoint+`=${projectJobId}`
        );

        const httpBody = {
            "employee":{
                "account_id":this.state.inviteVendorAccountId,
            }
        };

        requestMessageInviteForJob.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
        );

        requestMessageInviteForJob.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpPostMethod
        );
        requestMessageInviteForJob.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
      
        runEngine.sendMessage(requestMessageInviteForJob.id, requestMessageInviteForJob);
        return requestMessageInviteForJob.messageId;
    };

    removeFromJobInviteVendorPersonalDetail = async (methodPersonal: string, endPointPersonal: string) => {
        const authenticationToken = await getStorageData('token', false)
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": authenticationToken
        };
        this.setState({ isLoading: true })
        const requestMessageManageProfileremoveFromJob = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageManageProfileremoveFromJob.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPointPersonal
        );

        requestMessageManageProfileremoveFromJob.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageManageProfileremoveFromJob.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            methodPersonal
        );
        runEngine.sendMessage(requestMessageManageProfileremoveFromJob.id, requestMessageManageProfileremoveFromJob);
        return requestMessageManageProfileremoveFromJob.messageId;
    };
    // Customizable Area End
}
