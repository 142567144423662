import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isVendorAnalyticsSideBarOpen: boolean;
  isVendorAnalyticsDropdownOpen: boolean;
  values: string[];
  balanceSheetValues: any;
  vendorInvoicesIndex: number;
  vendorProjectsIndex: number;
  balanceSheetIndex: number;
  vendorProjectsFilteredBars: any[];
  vendorInvoicesFilteredBars: any[];
  token: string;
  isLoading: boolean;
  vendorAnalyticsProjectsData: any;
  vendorAnalyticsInvoicesData: any;
  analyticsBalanceSheetsData: any;
  vendorAnalyticsNotification: { type: string, open: boolean, message: string, route?: string };
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class VendorAnalyticsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getVednorAnalyticsProjectsMessgaeId: string = "";
  getVendorAnalyticsInvoicesMessgaeId: string = "";
  getVendorAnalyticsBalanceSheetMessgaeId: string = "";
  vendorProjectsAllBars = [
    {
      name: "Ongoing",
      color: "#F7B500",
    },
    {
      name: "Upcoming",
      color: "#3389FF"
    },
    {
      name: "Completed",
      color: "#f5c42c"
    },
  ];
  vendorInvoicesAllBars = [
    { name: "pending", color: "#F7B500" },
    { name: "rejected", color: "#FF5C5C" },
    { name: "approved", color: "#3389FF" },
    { name: "completed", color: "#42A200" },
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isVendorAnalyticsSideBarOpen: true,
      isVendorAnalyticsDropdownOpen: false,
      values: ["Weekly", "Monthly", "Yearly"],
      balanceSheetValues: { values: [], text: "" },
      vendorInvoicesIndex: 0,
      vendorProjectsIndex: 0,
      balanceSheetIndex: 0,
      vendorProjectsFilteredBars: this.vendorProjectsAllBars,
      vendorInvoicesFilteredBars: this.vendorInvoicesAllBars,
      token: "",
      isLoading: false,
      vendorAnalyticsProjectsData: {},
      vendorAnalyticsInvoicesData: {},
      analyticsBalanceSheetsData: [],
      vendorAnalyticsNotification: { type: "", open: false, message: "" },
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getVednorAnalyticsProjectsMessgaeId) {
        this.getVendorAnalyticsProjectsDataHandleResponse(responseJson);
      } else if (apiRequestCallId === this.getVendorAnalyticsInvoicesMessgaeId) {
        this.getVendorAnalyticsInvoicesDataHandleResponse(responseJson);
      } else if (apiRequestCallId === this.getVendorAnalyticsBalanceSheetMessgaeId) {
        this.getVendorAnalyticsBalanceSheetDataHandleResponse(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getVendorAnalyticsProjectsDataHandleResponse = (responseJson: { data: any; meta: any; errors: [{ message: string }] }) => {
    if (responseJson.data) {
      this.setState({ vendorAnalyticsProjectsData: Object.keys(responseJson.data).map((key) => ({ name: key, ...responseJson.data[key] })) })
    }
    else {
      this.setState({ vendorAnalyticsNotification: { open: true, type: "error", message: responseJson.errors[0].message } });
    }
    this.setState({ isLoading: false });
  }

  getVendorAnalyticsInvoicesDataHandleResponse = (responseJson: { data: any; meta: any; errors: [{ message: string }] }) => {
    if (responseJson.data) {
      this.setState({ vendorAnalyticsInvoicesData: Object.keys(responseJson.data).map((key) => ({ name: key, ...responseJson.data[key] })) })
    }
    else {
      this.setState({ vendorAnalyticsNotification: { open: true, type: "error", message: responseJson.errors[0].message } });
    }
    this.setState({ isLoading: false });
  }

  getVendorAnalyticsBalanceSheetDataHandleResponse = (responseJson: any) => {
    if (!responseJson?.errors && responseJson.length) {
      this.setState({ analyticsBalanceSheetsData: responseJson });
    }
    else if (responseJson?.errors) {
      this.setState({ vendorAnalyticsNotification: { open: true, type: "error", message: responseJson.errors[0].message } });
    }
    this.setState({ isLoading: false });
  }

  async componentDidMount(): Promise<void> {
    let updateValues = this.state.balanceSheetValues;
    updateValues.values = [new Date().getFullYear(), new Date().getFullYear() + 1];
    updateValues.text = `${updateValues.values[0]}-${updateValues.values[1].toString().substring(updateValues.values[1].toString().length - 2)}`

    const vendorAnalyticsAuthToken = await getStorageData("token");
    this.setState({ token: vendorAnalyticsAuthToken, balanceSheetValues: updateValues }, () => {
      this.getAllVendorAnalyticsData();

    });
    let isVendorAnalyticsSideBarOpen = await getStorageData("sidebarvalue");
    this.setState({ isVendorAnalyticsSideBarOpen: JSON.parse(isVendorAnalyticsSideBarOpen) })
  }

  apiCall = async (vendorAnalyticsApiMethod: string, vendorAnalyticsApiEndPoint: string) => {
    const analyticsHeader = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token,
    };
    this.setState({ isLoading: true });
    const vendorAnalyticsRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    vendorAnalyticsRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(analyticsHeader)
    );
    vendorAnalyticsRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      vendorAnalyticsApiEndPoint
    );
    vendorAnalyticsRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      vendorAnalyticsApiMethod
    );
    runEngine.sendMessage(vendorAnalyticsRequestMessage.id, vendorAnalyticsRequestMessage);
    return vendorAnalyticsRequestMessage.messageId;
  };

  getAllVendorAnalyticsData = async () => {
    this.getVednorAnalyticsProjectsMessgaeId = await this.apiCall(configJSON.validationApiMethodType, configJSON.getAnalyticsProjectsAPI + "weekly");
    this.getVendorAnalyticsInvoicesMessgaeId = await this.apiCall(configJSON.validationApiMethodType, configJSON.getAnalyticsInvoicesAPI + "weekly");
    this.getVendorAnalyticsBalanceSheetMessgaeId = await this.apiCall(configJSON.validationApiMethodType, configJSON.getAnalyticsBalanceSheetAPI + `${this.state.balanceSheetValues.values[0]}&end_year=${this.state.balanceSheetValues.values[1]}`);
  }

  vendorAnalyticsHandleDropdown = () => {
    this.setState({ isVendorAnalyticsDropdownOpen: !this.state.isVendorAnalyticsDropdownOpen });
  };

  vendorAnalyticsHandleNextClick = (vendorAnalyticsIndex: keyof S) => {
    if (this.state[vendorAnalyticsIndex] === 2) { return false; }
    if (this.state[vendorAnalyticsIndex] !== 2) {
      this.setState(({
        [vendorAnalyticsIndex]: (this.state[vendorAnalyticsIndex] as number) + 1
      } as unknown) as Pick<S, keyof S>, () => {
        this.getVendorAnalyticsChartsDynamicData(vendorAnalyticsIndex);
      });
    }
  };

  vendorAnalyticsHandleBalanceSheetChartNextClick = () => {
    let updateValues = this.state.balanceSheetValues;
    updateValues.values = [updateValues.values[0] + 1, updateValues.values[1] + 1];
    updateValues.text = `${updateValues.values[0]}-${updateValues.values[1].toString().substring(updateValues.values[1].toString().length - 2)}`
    this.setState({ balanceSheetValues: updateValues }, () => {
      this.getVendorAnalyticsChartsDynamicData("balanceSheetIndex");
    }); this.getVendorAnalyticsChartsDynamicData("balanceSheetIndex");
  };

  vendorAnalyticsHandleSideBar = () => {
    setStorageData("sidebarvalue", (!this.state.isVendorAnalyticsSideBarOpen).toString())
    this.setState({ isVendorAnalyticsSideBarOpen: !this.state.isVendorAnalyticsSideBarOpen });
  };

  vendorAnalyticsHandlePreviousClick = (vendorAnalyticsIndex: keyof S) => {
    if (this.state[vendorAnalyticsIndex] !== 0) {
      this.setState(({
        [vendorAnalyticsIndex]: (this.state[vendorAnalyticsIndex] as number) - 1
      } as unknown) as Pick<S, keyof S>, () => {
        this.getVendorAnalyticsChartsDynamicData(vendorAnalyticsIndex);
      });
    }
  };

  vendorAnalyticsHandleBalanceSheetChartPreviousClick = () => {
    let updateValues = this.state.balanceSheetValues;
    updateValues.values = [updateValues.values[0] - 1, updateValues.values[1] - 1];
    updateValues.text = `${updateValues.values[0]}-${updateValues.values[1].toString().substring(updateValues.values[1].toString().length - 2)}`
    this.setState({ balanceSheetValues: updateValues }, () => {
      this.getVendorAnalyticsChartsDynamicData("balanceSheetIndex");
    });
  };

  getVendorAnalyticsChartsDynamicData = async (index: keyof S) => {
    if (index === "vendorInvoicesIndex") {
      this.getVendorAnalyticsInvoicesMessgaeId = await this.apiCall(configJSON.validationApiMethodType, configJSON.getAnalyticsInvoicesAPI + this.state.values[this.state.vendorInvoicesIndex].toLocaleLowerCase());
    } else if (index === "vendorProjectsIndex") {
      this.getVednorAnalyticsProjectsMessgaeId = await this.apiCall(configJSON.validationApiMethodType, configJSON.getAnalyticsProjectsAPI + this.state.values[this.state.vendorProjectsIndex].toLocaleLowerCase());
    } else if (index === "balanceSheetIndex") {
      this.getVendorAnalyticsBalanceSheetMessgaeId = await this.apiCall(configJSON.validationApiMethodType, configJSON.getAnalyticsBalanceSheetAPI + `${this.state.balanceSheetValues.values[0]}&end_year=${this.state.balanceSheetValues.values[1]}`);
    }
  }

  vendorAnalyticsHandleNavigation = (page: string, type?: string) => {
    this.props.navigation.navigate(page);
    if(type) 
      setStorageData("vendorAnalyticsDetailedReportType", type);
  };

  handleChangeInvoiceGraphBar = (text: string) => {
    const updatedBars = this.vendorInvoicesAllBars.filter((barValue: any) => text.toLowerCase().includes(barValue.name.toLowerCase()));
    this.setState({ vendorInvoicesFilteredBars: updatedBars });
  }

  handleChangeProjectGraphBar = (text: string) => {
    const updatedBars = this.vendorProjectsAllBars.filter((barValue: any) => barValue.name.toLowerCase() === text.toLowerCase());
    this.setState({ vendorProjectsFilteredBars: updatedBars });
  }

  getInvoicesLegendsBorder = (text: string, color: string) => {
    return this.state.vendorInvoicesFilteredBars.length === 1 && this.state.vendorInvoicesFilteredBars[0].name === text ? color : "none"
  }

  getProjectsLegendsBorder = (text: string, color: string) => {
    return this.state.vendorProjectsFilteredBars.length === 1 && this.state.vendorProjectsFilteredBars[0].name === text ? color : "none"
  }

  handleVendorAnalyticsCloseNotification = () => {
    this.setState({ vendorAnalyticsNotification: { type: "", open: false, message: "" } });
  }
  // Customizable Area End
}
