import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ChangeEvent } from "react";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
interface ProductionHouse {
  id: string;
  type: string;
  attributes: {
    user_type: string;
    full_name: null;
    full_phone_number: string;
    country_code: number;
    phone_number: number;
    email: string;
    role_id: null;
    activated: boolean;
    blocked: boolean;
    production_house_name: string;
    production_house_address: string;
    rating_and_review: number;
    join_date: string;
    projects_count: number;
    studios_count: number;
    profile: { data: { attributes: { profile_image: string } } };
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  allVendorsDataValue: any[];
  vendorListTotal: number;
  issidebaropen: boolean;
  open:boolean;
  isDropdownOpenVal: boolean;
  isSideBarOpen: boolean;
  isDropdownOpen: boolean;
  allProductionHouses: ProductionHouse[] | null;
  filterHousesText: string;
  isLoading: boolean;
  currentpage: number;
  productionHouseTotal: number;
  productionHouseSearchText: string;
  city:any[]|null;
  state:any[]|null;
  statesearch:any[]|null;
  citysearch:any[]|null;
  isBlocked:boolean;
  count:number;
  selectedDate:string;
  selectedValues:any[];
  selectedDatevalue:string;
  statename:string;
  stateindex:any[]|null;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class FullTeamVisibilityController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllVendorsDataApiCalledId: string = "";
  putAllVendorsDataApiCalledId: string = "";
  putAllProductionHousesApiCalledId: string = "";
  allProductionHousesMessageId: string = "";
  allProductionHousesSearchDataMessageId: string = "";
  StateSearchApiProduction:string="";
  CitySearchApiProduction:string="";
  sortproductionApi:string="";
  sortproductionhouseApi:string="";
  
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      allVendorsDataValue: [],
      vendorListTotal: 0,
      issidebaropen: true,
      isDropdownOpenVal: false,
      isSideBarOpen: true,
      isDropdownOpen: false,
      allProductionHouses: null,
      filterHousesText: "",
      isLoading: false,
      currentpage: 1,
      productionHouseTotal: 0,
      productionHouseSearchText: "",
      open:false,
      city:null,
      state:null,
      citysearch:null,
      statesearch:null,
     isBlocked:false,
     count:0,
     selectedDate:'',
     selectedValues:[],
     selectedDatevalue:'',
     statename:'',
     stateindex:null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.getAllVendorsDataApiCalledId) {
        this.getAllVendorsDataHandleResponse(responseJson);
      } else if (apiRequestCallId === this.putAllVendorsDataApiCalledId) {
        this.putAllVendorsDataHandleResponse(responseJson);
      } else if (apiRequestCallId === this.putAllProductionHousesApiCalledId) {
        this.putAllProductionHousesDataHandleResponse(responseJson);
      } else if (this.allProductionHousesMessageId === apiRequestCallId|| this.allProductionHousesSearchDataMessageId === apiRequestCallId) {
        this.setState({
          allProductionHouses: responseJson.data,
          productionHouseTotal: responseJson.meta.total,
          isLoading: false,
        })
      }else if(this.CitySearchApiProduction===apiRequestCallId)
      {
        console.log("yyyyyyyy",responseJson)
        this.setState({city:responseJson})
        this.setState({citysearch:responseJson})
      }else if(this.StateSearchApiProduction===apiRequestCallId)
      {
        console.log("kkk",responseJson)
        this.setState({state:responseJson})
        this.setState({statesearch:responseJson})
        console.log("state..",this.state.state)
      }
      
      else if(this.sortproductionApi===apiRequestCallId)
      {
        console.log("33333333333333",responseJson)
        this.setState({
          allProductionHouses: responseJson.data,
          productionHouseTotal: responseJson.meta.total,
          
        })
      }
  
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getAllVendorsDataHandleResponse = (responseJson: { data: any[]; meta: any; errors: [{ token: string }] }) => {
    if (responseJson.data) {
      this.setState({
        allVendorsDataValue: responseJson.data,
        vendorListTotal: responseJson.meta.total,
        isLoading: false,
      });
    }
    else {
      this.showAlert("Error", responseJson.errors[0].token);
      this.setState({ isLoading: false });
    }
  }

  putAllVendorsDataHandleResponse = (responseJson: { data: { id: number } }) => {
    if (responseJson.data) {
      const currentList = [...this.state.allVendorsDataValue];
      const id = responseJson.data.id;
      const fIndex = currentList.findIndex((item: any) => item.id == id);
      if (fIndex !== -1) {
        let obj = currentList[fIndex];
        let attributes = obj && obj.attributes;
        attributes = {
          ...attributes,
          blocked: attributes && !attributes.blocked
        }
        obj = {
          ...obj,
          attributes
        }
        currentList[fIndex] = obj;
        this.setState({
          allVendorsDataValue: currentList
        })
      }
    }
    else {
      this.showAlert("Error", "Error");
    }
  }
   handleSearch = (event:any) => {
   
    const updatedList: any[] | null = this.state.statesearch && (this.state.statesearch.filter((state) => {
      return state.name.toLowerCase().includes(event.target.value.toLowerCase())
    }) ?? null);
    this.setState({
      state: updatedList
    })
  };
  handleSearchcity = (event:any) => {
    
   
    const updatedList: any[] | null = this.state.citysearch && (this.state.citysearch.filter((city) => {
      return city.name.toLowerCase().includes(event.target.value.toLowerCase())
    }) ?? null);
    this.setState({
      city: updatedList
    })
  };
  putAllProductionHousesDataHandleResponse = (responseJson: { data: { id: number } }) => {
    if (responseJson.data) {
      const currentList = this.state.allProductionHouses ? [...this.state.allProductionHouses] : [];
      const id = responseJson.data.id;
      const fIndex = currentList.findIndex((item: any) => item.id == id);
      if (fIndex !== -1) {
        let obj = currentList[fIndex];
        let attributes = obj && obj.attributes;
        attributes = {
          ...attributes,
          blocked: attributes && !attributes.blocked
        }
        obj = {
          ...obj,
          attributes
        }
        currentList[fIndex] = obj;
        this.setState({
          allProductionHouses: currentList,
          isLoading: false,
        })
      }
    }
    else {
      this.setState({ isLoading: false });
      this.showAlert("Error", "error");
    }
  }

  getApiCallForAllVendors = async (pageNumber: number) => {
    const token = await getStorageData("token", false);
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": token
    };
    this.setState({ isLoading: true });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllVendorsDataApiCalledId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllVendorDataApiEndPoint + pageNumber
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET_Method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleRadioChange = () => {
    this.setState({isBlocked:true}) // Toggle the value between true and false
  };
  handleRadioChange1 = () => {
    this.setState({isBlocked:false,
                    count:1
    });}
  apiCall = async (method: string, endPoint: string) => {
    const authenticationToken = await getStorageData('token', false)
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": authenticationToken
    };
    this.setState({ isLoading: true });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  async componentDidMount() {
    await this.getAllProductionHouses();
    await this.getApiCallForAllVendors(1);
    window.scrollTo(0, 0);
    this.searchStateApi();
    let isSideBarOpen=await getStorageData("sidebarvalue");
    this.setState({isSideBarOpen:JSON.parse(isSideBarOpen)})
  }
  
   handleOpen = () =>{
    console.log("rrrr",this.state.open)
     this.setState({open:true})
     console.log("rrrr",this.state.open)
  }
  handleClose = () => {
    this.setState({open:false})
    console.log("onclose",this.state.open)
  };
  handleBadgeVisibility = async (e: any, id: any, blockStatus: boolean, type?: string) => {
    e.stopPropagation();
    const token = await getStorageData("token", false);
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const attrs = { blocked: !blockStatus }
    if (type === "productionhouse") {
      this.setState({ isLoading: true });
      this.putAllProductionHousesApiCalledId = requestMessage.messageId;
    }
    else {
      this.putAllVendorsDataApiCalledId = requestMessage.messageId;
    }

    const data = {
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      type === "productionhouse" ? configJSON.putApiForAllProductionHousesEndPoint + id : configJSON.putApiForAllVendorsEndPoint + id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.put_method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSideBarisDropdownOpenVal = () => {
    this.setState({
      issidebaropen: !this.state.issidebaropen
    },
    async() => {
      setStorageData("sidebarvalue",this.state.issidebaropen.toString())
     
   }
    )
  };

  handleDropdownVal = () => {
    this.setState({
      isDropdownOpenVal: !this.state.isDropdownOpenVal
    })
  };

  getAllProductionHouses = async () => {
    this.allProductionHousesMessageId = await this.apiCall(configJSON.GET_Method, configJSON.AllProductionHouses + 1)
  }
  handleSideBar = () => {
    this.setState({
      isSideBarOpen: !this.state.isSideBarOpen
    },
    async() => {
      setStorageData("sidebarvalue",this.state.isSideBarOpen.toString())
      
    }
    
    );
  };
  handleDropdown = () => {
    this.setState({
      isDropdownOpen: !this.state.isDropdownOpen
    });
  };
  handleBlockChange = () => {

  }

  handleNavigation = (page: string) => {
    this.props.navigation.navigate(page)
  };

  filterHouses = async (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      currentpage: 1,
      productionHouseSearchText: event.target.value,
    });
    if (event.target.value)
      this.allProductionHousesSearchDataMessageId = await this.apiCall(configJSON.GET_Method, configJSON.AllProductionHousesSearchAPIEndPoint + `${event.target.value}&page=1`);
    else
      this.allProductionHousesMessageId = await this.apiCall(configJSON.GET_Method, configJSON.AllProductionHouses + 1);
  }

  updateAllvendorDataValue = (data: any, total: number) => {
    this.setState({
      allVendorsDataValue: data,
      vendorListTotal: total,
      isLoading: false,
    })
  };

  updateLoadingStateValue = (value: boolean) => {
    this.setState({
      isLoading: value,
    })
  };

  handlePageChange = async (event: any, value: number) => {
    if (this.state.productionHouseSearchText)
    {
      this.allProductionHousesSearchDataMessageId = await this.apiCall(configJSON.GET_Method, configJSON.AllProductionHousesSearchAPIEndPoint + `${this.state.productionHouseSearchText}&page=${value}`);
      window.scrollTo(0, 0); 
    }   
     else
     {
      this.allProductionHousesMessageId = await this.apiCall(configJSON.GET_Method, configJSON.AllProductionHouses + value);
      window.scrollTo(0, 0);
     }
    this.setState({ currentpage: value });
  };
  searchStateApi = async () => {
    const XCSCAPIKEY = "Z1dmZFJDWjlMSjl0M082ZUUyU3JESkJCQTdZeTd6UEVINXdreTdjWQ=="
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "X-CSCAPI-KEY": XCSCAPIKEY 
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.StateSearchApiProduction = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.StateEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET_Method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleValue=(value:string)=>{
    const statevalue=value
    const XCSCAPIKEY = "Z1dmZFJDWjlMSjl0M082ZUUyU3JESkJCQTdZeTd6UEVINXdreTdjWQ=="
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "X-CSCAPI-KEY": XCSCAPIKEY 
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.CitySearchApiProduction = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.CityEndPoint+`${statevalue}/cities`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET_Method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    
    
  }
   handleDateChange = (event: any) => {
    const selectedDatevalue = event.target.value;
    this.setState({selectedDate:selectedDatevalue})
    const formattedDate = this.formatDate(this.state.selectedDate);
    console.log("dddddd", formattedDate);
    this.setState({selectedDatevalue:formattedDate})
    console.log("dddddd", this.state.selectedDatevalue);
};

 formatDate = (date: any) => {
    const dateObject = new Date(date);
    const year = dateObject.getFullYear().toString().slice(-4); 
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); 
    const day = dateObject.getDate().toString().padStart(2, '0');
    return `${year}/${month}/${day}`;
};
toggleCheckbox = (value:any) => {
  
  if (this.state.selectedValues.includes(value)) {
    this.setState({
      selectedValues: this.state.selectedValues.filter((val) => val !== value),
    });
  } else {
    this.setState({
      selectedValues: [...this.state.selectedValues, value],
    });
    console.log("333333333",this.state.selectedValues)
  }
  console.log("333333333",this.state.selectedValues)
}
handleOptionChange = (index: any, state: any) => {
  this.setState({statename:state.name})
  this.setState({stateindex:index})
  //this.setState({selectedValues:[]})
  console.log("22222", state.name)
};

sortApiCallForAllProduction = async () => {
  this.setState({open:false})
  const cityname=this.state.selectedValues.join(", ");
  const authenticationToken = await getStorageData('token', false)
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": authenticationToken
    };
      
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.sortproductionApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    if(this.state.stateindex===null&&this.state.isBlocked===false&&this.state.count===0&&this.state.selectedDatevalue===''){
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getAllProductionDataApiEndPoint1+`&page=1`
        
      );
    }
  
    else{
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.allproductionsortDataApiEndPoint+`=${this.state.statename}&cities=${cityname}&start_date=${this.state.selectedDate}&blocked=${this.state.isBlocked}&page=1`
        
      );
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET_Method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
 
};
ClearDataValues = async () => {
  //this.setState({open:false})
  this.setState({stateindex:null})
  this.setState({selectedValues:[]})
  this.setState({isBlocked:false})
  this.componentDidMount()

  const authenticationToken = await getStorageData('token', false)
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": authenticationToken
    };
      
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sortproductionApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getAllProductionDataApiEndPoint1+`&page=1`
        
      );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET_Method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
 
};

  // Customizable Area End
}