import React, {createRef} from "react";
import {
    // Customizable Area Start
    
    Container,
    Grid,
    Box,Typography, IconButton, Divider, Avatar, LinearProgress, Modal, withStyles, InputLabel, TextField, styled,
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import Header from "../../../components/src/Header.web";
import SideBar from "../../../components/src/SideBar";
import Footer from "../../../components/src/Footer";
import Loader from "../../../components/src/Loader.web";
import { arrowYellowIcon, certificate, checkedIcon, outgoingMail, tipsUpdates } from "./assets";
import Rating from "@material-ui/lab/Rating";
import GetAppIcon from '@material-ui/icons/GetApp';
import StarOutlinedIcon from '@material-ui/icons/StarOutlined';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PlaceOutlinedIcon from '@material-ui/icons/PlaceOutlined';
import WorkOutlineTwoToneIcon from '@material-ui/icons/WorkOutlineTwoTone';
import SettingsPhoneIcon from '@material-ui/icons/SettingsPhone';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { StyledBadge } from "../../fullteamvisibility/src/CardComponent.web";
import { dummyImage } from "../../../components/src/assets";
const baseURL = require("../../../framework/src/config");
import CloseIcon from '@material-ui/icons/Close';

import PersonalDetailInviteVendorControlller,{Props,configJSON} from "./PersonalDetailInviteVendorController";

// Customizable Area End

// Customizable Area Start
const CustomInputlabelInviteVendor = withStyles({
    root: {
        fontSize: "13px", 
        fontFamily: "Urbanist", 
        fontWeight: 600, 
        lineHeight: '18px', 
        color: "#6D89AF", 
        padding: "16px 0 6px",
        "@media (min-width : 1441px)": {
          fontSize: '14px',
          lineHeight: "14px",
          paddingBottom: "6px",
          paddingTop: "14px"

      },
        "@media (max-width : 1440px)": {
            fontSize: '14px'
        },
    },
})(InputLabel);

const CustomCSSOfTextFieldManageProfile = withStyles({
    root: {
        width: '100%',
        borderColor: "#E3EAF4",
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#E3EAF4",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#f5c42c",
              backgroundColor: 'rgba(245,196,44,0.04)',
          },
            "&:hover fieldset": {
                borderColor: "#f5c42c"
            },
            color: '#323232',
            borderRadius: "14px",
            "& .MuiOutlinedInput-input": {
                lineHeight: '19px',
                borderColor: '#E3EAF4',
                color: '#6D89AF',
                minHeight: '50px',
            }
        },
        "& .MuiInputBase-input::placeholder": {
          color: '#6D89AF',
          fontWeight: 500,
          fontFamily: 'Urbanist',
      },   
        "& .MuiInput-underline:after": {
            borderBottomColor: "#f5c42c"
        },     
    }
})(TextField);
const StyledModal = styled(Modal)({
  //position: 'fixed',
  zIndex: 1300,
  inset: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
// Customizable Area End

export default class PersonalDetailInviteVendor extends  PersonalDetailInviteVendorControlller{
    stepperRef: React.RefObject<any>;
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.stepperRef = createRef();
        // Customizable Area End
    }
   // Customizable Area Start
    renderVendorManageProfileOverviewTabDocumentsContent = () => {
        return <>
          <Typography style={webStyle.inviteVendorAllReviewsTitle as React.CSSProperties}>Certificates & Documents <span style={webStyle.spanLineInviteVendor}>------------------------------------------------------------------------------------------------------------------------------------</span></Typography>
          <Grid container style={{ marginTop: "8px", width: "100%", minHeight: "88px", borderRadius: "12px", border: "1px solid #e3eaf4", padding: 12, boxSizing: "border-box", color: "#17181d", fontFamily: "Urbanist", fontSize: "12px", fontWeight: 500, lineHeight: "16px" }}>
            <Grid container spacing={2} className="cert_box">
              {this.state.inviteVendorManageProfilePersonal.attributes.documents.length ?
                this.state.inviteVendorManageProfilePersonal.attributes.documents.map((document: any, index: number) => {
                  return <Grid item key={`${index + document.id}_documentlist`} xs={6} sm={6} md={3} lg={3} xl={3}>
                    <Box className="vendor_manage_profile_cert_box_invite_vendor">
                      <Box className="cert_inner_tool">
                        <Box style={{ backgroundColor: "rgba(245,196,44,0.09)", borderRadius: "8px", padding: "9.9% 13.6%" }}>
                          <img style={{ width: "14.61px", height: "21px" } as React.CSSProperties} src={certificate} alt="" />
                        </Box>
                        <IconButton onClick={() => this.requestProfileHandleDownloadPDFPersonal(document.path_url, document.filename)} className="download_icon" size="medium">
                          <GetAppIcon fontSize="small" className="download_icon_color" />
                        </IconButton>
                      </Box>
                      <Box className="project_name_invite_vendor">
                        <Typography noWrap className="file_name">{document.filename}</Typography>
                      </Box>
                    </Box>
                  </Grid>
                }) : null
              }
            </Grid>
          </Grid>
        </>
      }
    
      renderReadMoreLess = () => {
        return <>
          {
            this.state.inviteVendorManageProfilePersonal.attributes.profile_bio.data.attributes.about_business.length > 100
              ? <>
                <span>
                  {this.state.inviteVendorManageProfilePersonal.attributes.profile_bio.data.attributes.about_business.substr(0, this.state.bioReadMoreLessRequestPro ? 176 : this.state.inviteVendorManageProfilePersonal.attributes.profile_bio.data.attributes.about_business.length)}...
                </span>
                <span style={webStyle.readMoreTextInviteVendor} data-test-id="readMoreLess" onClick={this.handleBioReadRequestProfilePersonal}>
                  {this.state.bioReadMoreLessRequestPro ? " Read more" : " Read less"}
                </span>
              </> :
              this.state.inviteVendorManageProfilePersonal.attributes.profile_bio.data.attributes.about_business
          }
        </>
      }
    
      renderVendorManageProfileOverviewTabProfileBioContent = () => {
        return <>
          <Typography style={webStyle.inviteVendorAllReviewsTitle as React.CSSProperties}>Bio <span style={webStyle.spanLineInviteVendor}>------------------------------------------------------------------------------------------------------------------------------------</span></Typography>
          <div style={{ marginTop: "8px", width: "100%", minHeight: "72px", borderRadius: "12px", border: "1px solid #e3eaf4", padding: 12, boxSizing: "border-box", color: "#17181d", fontFamily: "Urbanist", fontSize: "12px", fontWeight: 600, lineHeight: "16px" }}>
            {this.state.inviteVendorManageProfilePersonal.attributes.profile_bio.data ? this.renderReadMoreLess() : null}
          </div>
        </>
      }
    
      renderVendorManageProfileOverviewTabContent = () => {
        return <>
          {this.state.requestProfileTabValue === 0 ? <Box style={{ background: "#ffffff", width: "calc(100% - 186px)", padding: "13px 24px 16px 24px" }}>
            <Box>
              <Typography style={webStyle.inviteVendorAllReviewsTitle as React.CSSProperties}>Official Details & Docs <span style={webStyle.spanLineInviteVendor}>------------------------------------------------------------------------------------------------------------------------------------</span></Typography>
              <Grid container style={{ marginTop: "8px", width: "100%", height: "123px", borderRadius: "12px", border: "1px solid #e3eaf4", padding: 14, display: "flex", flexDirection: "row" }}>
                <Grid container style={{ flexWrap: "nowrap" }}>
                  <Grid item sm={6} md={6} lg={6} >
                    <Typography style={{ color: "#6d89af", fontFamily: "Urbanist", fontSize: "12px", fontWeight: 500, lineHeight: "15px", letterSpacing: "0.2px" }}>Aadhaar Number</Typography>
                    <Typography style={{ marginTop: "3px", color: "#18272a", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 600, lineHeight: "17px", letterSpacing: "0.2px" }}>{this.state.inviteVendorManageProfilePersonal.attributes.account_detail.data.attributes?.aadhar_card_number ? `${this.state.inviteVendorManageProfilePersonal.attributes.account_detail.data.attributes.aadhar_card_number.toString().slice(0, 4)} xxxx ${this.state.inviteVendorManageProfilePersonal.attributes.account_detail.data.attributes.aadhar_card_number.toString().slice(-4)}` : null}</Typography>
                  </Grid>
                  <Grid item sm={6} md={6} lg={6}>
                    <Typography style={{ color: "#6d89af", fontFamily: "Urbanist", fontSize: "12px", fontWeight: 500, lineHeight: "15px", letterSpacing: "0.2px" }}>GST Number</Typography>
                    <Typography style={{ marginTop: "3px", color: "#18272a", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 600, lineHeight: "17px", letterSpacing: "0.2px" }}>{this.state.inviteVendorManageProfilePersonal.attributes.account_detail.data.attributes?.gst_number}</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Divider />
                </Grid>
                <Grid container style={{ flexWrap: "nowrap" }}>
                  <Grid item sm={6} md={6} lg={6} >
                    <Typography style={{ color: "#6d89af", fontFamily: "Urbanist", fontSize: "12px", fontWeight: 500, lineHeight: "15px", letterSpacing: "0.2px" }}>PAN Number</Typography>
                    <Typography style={{ marginTop: "3px", color: "#18272a", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 600, lineHeight: "17px", letterSpacing: "0.2px" }}>{this.state.inviteVendorManageProfilePersonal.attributes.account_detail.data.attributes?.pan_card_number ? `${this.state.inviteVendorManageProfilePersonal.attributes.account_detail.data.attributes.pan_card_number.toString().slice(0, 2)}xxxxxx${this.state.inviteVendorManageProfilePersonal.attributes.account_detail.data.attributes.pan_card_number.toString().slice(-2)}` : null}</Typography>
                  </Grid>
                  <Grid item sm={6} md={6} lg={6}>
                    <Typography style={{ color: "#6d89af", fontFamily: "Urbanist", fontSize: "12px", fontWeight: 500, lineHeight: "15px", letterSpacing: "0.2px" }}>TAN Number</Typography>
                    <Typography style={{ marginTop: "3px", color: "#18272a", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 600, lineHeight: "17px", letterSpacing: "0.2px" }}>
                        {this.state.inviteVendorManageProfilePersonal.attributes.account_detail.data.attributes?.tan_number ? `${this.state.inviteVendorManageProfilePersonal.attributes.account_detail.data.attributes.tan_number.toString().slice(0, 4)}xxxx${this.state.inviteVendorManageProfilePersonal.attributes.account_detail.data.attributes.tan_number.toString().slice(-3)}` : null}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box style={{ marginTop: "16px" }}>
              {this.renderVendorManageProfileOverviewTabProfileBioContent()}
            </Box>
            <Box style={{ marginTop: "16px" }}>
              {this.renderVendorManageProfileOverviewTabDocumentsContent()}
            </Box>
            <Box style={{ marginTop: "16px" }}>
              <Typography style={webStyle.inviteVendorAllReviewsTitle as React.CSSProperties}>Account Details <span style={webStyle.spanLineInviteVendor}>------------------------------------------------------------------------------------------------------------------------------------</span></Typography>
              <Grid container style={{ marginTop: "8px", width: "100%", height: "123px", borderRadius: "12px", border: "1px solid #e3eaf4", padding: 14, display: "flex", flexDirection: "row" }}>
                <Grid container style={{ flexWrap: "nowrap" }}>
                  <Grid item sm={6} md={6} lg={6} >
                    <Typography style={{ color: "#6d89af", fontFamily: "Urbanist", fontSize: "12px", fontWeight: 500, lineHeight: "15px", letterSpacing: "0.2px" }}>Account Number</Typography>
                    <Typography style={{ marginTop: "3px", color: "#18272a", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 600, lineHeight: "17px", letterSpacing: "0.2px" }}>{this.state.inviteVendorManageProfilePersonal.attributes.account_detail.data.attributes?.account_number}</Typography>
                  </Grid>
                  <Grid item sm={6} md={6} lg={6}>
                    <Typography style={{ color: "#6d89af", fontFamily: "Urbanist", fontSize: "12px", fontWeight: 500, lineHeight: "15px", letterSpacing: "0.2px" }}>Bank Name</Typography>
                    <Typography style={{ marginTop: "3px", color: "#18272a", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 600, lineHeight: "17px", letterSpacing: "0.2px" }}> <img style={{ marginRight: "3px" }} src={baseURL.baseURL + this.state.inviteVendorManageProfilePersonal.attributes.bank_detail.data?.attributes.image} width={15} height={15} /> {this.state.inviteVendorManageProfilePersonal.attributes.bank_detail.data?.attributes.bank_name}</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                  <Divider />
                </Grid>
                <Grid container style={{ flexWrap: "nowrap" }}>
                  <Grid item sm={6} md={6} lg={6} >
                    <Typography style={{ color: "#6d89af", fontFamily: "Urbanist", fontSize: "12px", fontWeight: 500, lineHeight: "15px", letterSpacing: "0.2px" }}>IFSC CODE</Typography>
                    <Typography style={{ marginTop: "3px", color: "#18272a", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 600, lineHeight: "17px", letterSpacing: "0.2px" }}>{this.state.inviteVendorManageProfilePersonal.attributes.account_detail.data.attributes?.ifsc_code}</Typography>
                  </Grid>
                  <Grid item sm={6} md={6} lg={6}>
                    <Typography style={{ color: "#6d89af", fontFamily: "Urbanist", fontSize: "12px", fontWeight: 500, lineHeight: "15px", letterSpacing: "0.2px" }}>Branch</Typography>
                    <Typography style={{ marginTop: "3px", color: "#18272a", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 600, lineHeight: "17px", letterSpacing: "0.2px" }}>{this.state.inviteVendorManageProfilePersonal.attributes.account_detail.data.attributes?.branch_name}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box> : null}
        </>
      }
    
      renderVendorManageProfileGalleryTabContentInviteVendor = () => {
        return <>
          {this.state.requestProfileTabValue === 1 ? <Box style={{ background: "#ffffff", width: "100%", padding: "15px", height: "594px" }}>
            <Box style={{ display: "flex", flexDirection: "row", gap: "13px", flexWrap: "wrap", overflowY: "auto", maxHeight: "594px" }}>
              {this.state.inviteVendorManageProfilePersonal.attributes.gallary_photos.length ? this.state.inviteVendorManageProfilePersonal.attributes.gallary_photos.map((item: any) => {
                return <img key={item.id + "gallery"}
                  className="studioGalleryImages"
                  src={item.path_url || dummyImage}
                 
                />
                
               
              }) : null}
            </Box>
          </Box> : null}
        </>
      }
    
      renderVendorManageProfileProjectsTabContent = () => {
        return <>
          {this.state.requestProfileTabValue === 2 ? <Box style={{ background: "#ffffff", width: "calc(100% - 186px)", padding: "13px 24px 16px 24px", overflowY: "auto", height: "648px" }}>
            <Typography style={{ ...webStyle.inviteVendorAllReviewsTitle as React.CSSProperties, marginBottom: "3px" }}>{this.state.inviteVendorProjectsPersonal.length ? `${this.state.inviteVendorProjectsPersonal.length} Successful Projects` : "No Data Found"} <span style={webStyle.spanLineInviteVendor}>------------------------------------------------------------------------------------------------------------------------------------</span></Typography>
            {this.state.inviteVendorProjectsPersonal.length ? this.state.inviteVendorProjectsPersonal.map((item: any) => {
              return <Grid key={item.id + "manageprofile_projects"} container style={webStyle.projectsCardInviteVendor as React.CSSProperties}>
                <Grid item style={{ display: "flex" }}>
                  <Avatar
                    style={{ borderRadius: "12px", width: 64, height: 64 }}
                    src={item.attributes?.image ||dummyImage}
                  >
                  </Avatar>
                  <Grid style={{ marginLeft: "12px" }}>
                    <Typography style={{ lineHeight: "19px", color: "#113260", fontFamily: "Urbanist", fontSize: "16px", fontWeight: 600, letterSpacing: "0.2px" }}>{item.attributes?.project_name}</Typography>
                    <Typography style={{ marginTop: "3px", lineHeight: "19px", color: "#6d89af", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 500, letterSpacing: "0.23333333px" }}>Job Role: {item.attributes?.job_role_name}</Typography>
                    <Typography style={{ marginTop: "3px", lineHeight: "19px", color: "#6d89af", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 500, letterSpacing: "0.23333333px" }}>Organized By: {item.attributes?.production_house}</Typography>
                  </Grid>
                </Grid>
                <Grid item style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                  <Box style={webStyle.inviteVendorProjectStatusBtn}>
                    <span style={webStyle.inviteVendorProjectStatusText} >{this.covertToPascalCaseProfileProjectPersonal(item.attributes?.status)}</span>
                  </Box>
                  <Box style={webStyle.inviteVendorProjectDateText as React.CSSProperties}>
                    {item.attributes?.created_at}
                  </Box>
                </Grid>
              </Grid>
            }) : null}
          </Box> : null}
        </>
      }

    renderVendorManageProfileRatingAndReviewTabContent = () => {
        return <>
          <Box style={webStyle.inviteVendorRatingStartMainBox}>
            <Box style={webStyle.inviteVendorRatingStartBox} >
              <Box style={{ display: "flex", alignItems: "center" }}>
                <Box className="star_icon rating_size">
                  <Typography className="total_rating_typo">{this.state.inviteVendorRatingAndReviewPersonal.average_rating || 0}</Typography>
                  <Typography className="divide_num">{configJSON.divide}</Typography>
                </Box>
                <Box style={{ marginLeft: "12px", marginRight: "10px" }} className="rating_text">
                  <Divider orientation="vertical" flexItem style={{ height: 23 } as React.CSSProperties} />
                </Box>
                <Rating
                  name="customized-empty"
                  value={this.state.inviteVendorRatingAndReviewPersonal.average_rating || 0}
                  size="small"
                  precision={0.5}
                  readOnly
                  emptyIcon={<StarBorderIcon htmlColor="#ffb400" style={{ width: "18px", height: "18px" } as React.CSSProperties} />}
                />
              </Box>
              <Box style={webStyle.jobViewMoreInviteVendor as React.CSSProperties} data-test-id="openRateNowModel" onClick={this.handleOpenAddJobRolePersonal}>
                    Rate Now <img src={arrowYellowIcon} alt="More" style={webStyle.jobrateMoreIconInviteVendor} />
                </Box>
                <Modal
                    open={this.state.open}
                    onClose={this.handleCloseJobRoleModelPersonal}
                    aria-describedby="simple-modal-description"
                    aria-labelledby="simple-modal-title"
                >
                    <Box style={ webStyle.jobRequestModalInviteVendor as React.CSSProperties}>
                        <Box style={ webStyle.jobRequestModalHeadInviteVendor}>
                            <Typography style={webStyle.jobReqModalHeadingInviteVendor}>Rate & Review </Typography>
                            <CloseIcon style={webStyle.jobReqCrossIconInviteVendor as React.CSSProperties} onClick={this.handleCloseJobRoleModelPersonal}/>
                        </Box>
                        <Box style={webStyle.jobRequestModalProfileInviteVendor as React.CSSProperties}>
                            <StyledBadge
                                overlap="circular"
                                anchorOrigin={{
                                vertical: "bottom", horizontal: "right"
                                }}
                                badgeContent={
                                <img
                                    style={{ width: 24, height: 24 }}
                                    src={checkedIcon}
                                />
                                }
                                style={{
                                  width: 75, height: 75
                                  }}
                            >
                                <Avatar
                                style={{
                                    marginRight: 1,
                                    width: 69,
                                    border: "3px solid #f5c42c",
                                    height: 69,
                                }}
                                src={this.state.inviteVendorManageProfilePersonal.attributes.profile_image || dummyImage}
                                >
                                </Avatar>
                            </StyledBadge>
                            <Typography variant="h6" style={webStyle.jobReqProfileNameInviteVendor}>{this.state.inviteVendorManageProfilePersonal.attributes.account_detail.data.attributes?.full_name}</Typography>
                            <Box style={webStyle.jobreqModalRateInviteVendor}>
                                <Rating
                                    name="customized-empty"
                                    value={this.state.requestProfileRateValuePersonal}
                                    size="large"
                                    precision={1}
                                    onChange={this.handleRateStarPersonal}
                                    emptyIcon={<StarBorderIcon htmlColor="#ffb400" style={{ width: "32px", height: "32px" } as React.CSSProperties} />}
                                    />
                            </Box>
                            <Typography style={webStyle.errorMessageInviteVendor as React.CSSProperties}>{this.state.requestRatingValidationPersonal}</Typography>
                        </Box>
                        <Grid item container spacing={3}> 
                            <Grid item xs={12} sm={12} md={12} lg={12} style={webStyle.reviewBlkInviteVendor as React.CSSProperties}>
                                <CustomInputlabelInviteVendor id="review">Review</CustomInputlabelInviteVendor>
                                <CustomCSSOfTextFieldManageProfile multiline data-test-id={"noOfPosition"} type={'text'} id="outlined-basic" variant="outlined" placeholder="Enter your comment"
                                        inputProps={{
                                            'aria-label': 'review',
                                            type: 'text',
                                            name: 'review',
                                            value: this.state.requestProfileCommentValuePersonal,
                                            onChange:this.handleCommentRequestProfilePersonal,
                                            required: true,
                                        }}
                                    />
                                <Typography style={webStyle.errorMessageTextInviteVendor as React.CSSProperties}>{this.state.requestCommentValidationPersonal}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Box style={webStyle.jobRequestSaveButtonInviteVendor as React.CSSProperties} onClick={this.handleRatingAndReviewInviteVendorFormDepartment}>
                                    Submit
                                </Box>                        
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
            </Box>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="user_detail_grid_container_rating">
              <Typography className="rating_helper">{this.state.inviteVendorRatingAndReviewPersonal.message}</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Divider style={{ marginTop: "12px", marginBottom: "15px" }} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ margin: "0px 12px 16px", display: "flex", justifyContent: "space-between" }}>
              {
                Object.entries(this.state.inviteVendorRatingAndReviewPersonal.Given_Star || { 5: 0, 4: 0, 3: 0, 2: 0, 1: 0 }).reverse().map((item: any, index: number) => {
                  return <Box style={{ width: "calc(20% - 8px)", display: "flex", flexDirection: "column" }} key={`${item[0]}_ratingvalue`}>
                    <LinearProgress variant="determinate" value={item[1] | 0} className="progress_bar_rating" style={{ backgroundColor: '#e3eaf4', borderRadius: '30px', marginBottom: "5px" }} />
                    <Typography style={{ height: "12px", color: "#17181d", fontFamily: "Urbanist", fontSize: "10px", fontWeight: 600, letterSpacing: "0.2px" }}>{item[0]}{" "}Star</Typography>
                  </Box>
                })
              }
            </Grid>
          </Box>
          <Box style={{ display: "flex", alignItems: "center", margin: "0px 12px" }}>
            <Typography style={webStyle.inviteVendorAllReviewsTitle as React.CSSProperties}>All Reviews --------------------------------------------------</Typography>
          </Box>
          <Grid style={{ margin: "0px 12px", maxHeight: "390px", overflowY: "auto", msOverflowStyle: "none", scrollbarWidth: "none" }} item xs={12} sm={12} md={12} lg={12} xl={12}>
            {
              this.state.inviteVendorRatingAndReviewPersonal.reviews?.data?.length ? (this.state.inviteVendorRatingAndReviewPersonal.reviews.data.map((item: any, index: number) => {
                return <Box key={`${index}_review_box_allProjectList`} style={{ padding: '12px' }} className="review_box">
                  <Grid container spacing={3}>
                    <Box sx={webStyle.inviteVendorProjectcardParentBox} className="review_card_box">
                      <Grid item xs={12} md={2} sm={12} lg={2} xl={2}>
                        <Avatar style={webStyle.inviteVendorProfileImage} src={item.attributes.account.image ||dummyImage}> </Avatar>
                      </Grid>
                      <Grid item xs={12} md={8} sm={12} lg={8} xl={8}>
                        <Typography
                          style={webStyle.inviteVendorFullName}
                        >
                          {item.attributes.account.full_name}
                        </Typography>
                        <Typography
                          style={webStyle.inviteVendorCreatedAt}
                        >
                          {item.attributes.created_at}
                        </Typography>
                      </Grid>
                      <Grid item className="project_date" xs={12} sm={12} md={2} lg={2} xl={2} >
                        <Box style={webStyle.inviteVendorStars}>
                          <StarOutlinedIcon className="start_color" />
                          <Typography style={webStyle.inviteVendorStarColor}>&nbsp;{item.attributes.given_star}</Typography>
                        </Box>
                      </Grid>
                    </Box>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      style={{ padding: "0px 12px 12px", marginTop: '-2%' }}
                    >
                      <Typography
                        style={webStyle.inviteVendorComment}
                      >
                        {item.attributes.comment}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              })) : null
            }
          </Grid>
        </>
      }

    
      renderInviteVendorManageProfileBasicDetailsContent = () => {
        return <>
          <Box
            style={{ justifyContent: "space-between", display: "flex", flexWrap: "wrap" , alignItems: "center"}}
          >
            <Box
              style={{ margin: "16px 0 16px 16px", display: "flex", flexWrap: "wrap" }}
            >
              <StyledBadge
                anchorOrigin={{
                  vertical: "bottom", horizontal: "right"
                }}
                overlap="circular"
                style={webStyle.inviteVendorBadge}
                badgeContent={
                  <img
                    style={{ width: 24, height: 24 }}
                    src={checkedIcon}
                  />
                }
              >
                <Avatar
                  style={webStyle.contentProfileAvator}
                src={this.state.inviteVendorManageProfilePersonal.attributes.profile_image || dummyImage}
                >
                </Avatar>
              </StyledBadge>
              <Box style={webStyle.inviteVendorLeftBox as React.CSSProperties}>
                <Typography
                  style={{
                    lineHeight: "22px",
                    color: "#17181d",
                    fontFamily: "Urbanist",
                    fontSize: "18px",
                    fontWeight: 600,
                    textAlign: "center",
                    letterSpacing: "0.225px",
                  }}
                >
                  {this.state.inviteVendorManageProfilePersonal.attributes.account_detail.data.attributes?.full_name}
                </Typography>
                <Typography style={webStyle.inviteVendorPhName}>
                  <WorkOutlineTwoToneIcon style={{
                    fontSize: "small",
                    width: "22px",
                    height: "22px",
                    marginRight: 3,
                  }} />
                  {this.state.inviteVendorManageProfilePersonal.attributes.role}
                </Typography>
                <Typography style={webStyle.inviteVendorAddressText}>
                  <PlaceOutlinedIcon style={{
                    fontSize: "small",
                    width: "22px",
                    height: "22px",
                    marginRight: 2,
                  }} />
                  {this.state.inviteVendorManageProfilePersonal.attributes.city}, {this.state.inviteVendorManageProfilePersonal.attributes.state}
                </Typography>
              </Box>
              <Box style={webStyle.inviteVendorRightBox as React.CSSProperties}>
                <Typography style={webStyle.inviteVendorRightSideBoxText}>
                  <SettingsPhoneIcon style={{
                    fontSize: "small",
                    width: "20.09px",
                    height: "20px",
                    marginRight: 10.4,
                    color: "#f5c42c",
                  }} />
                  +{this.state.inviteVendorManageProfilePersonal.attributes.account_detail.data.attributes.country_code}-{this.state.inviteVendorManageProfilePersonal.attributes.account_detail.data.attributes?.phone_number}
                </Typography>
                <Typography style={{ ...webStyle.inviteVendorRightSideBoxText, margin: "9px 0px" }}>
                  <img style={{ marginRight: 10.4 }} src={`${outgoingMail}`} />
                  {this.state.inviteVendorManageProfilePersonal.attributes.account_detail.data.attributes?.email}
                </Typography>
                <Typography style={webStyle.inviteVendorRightSideBoxText}>
                  <img style={{ marginRight: 10.4 }} src={`${tipsUpdates}`} />
                  {this.state.inviteVendorManageProfilePersonal.attributes.experience} yrs
                </Typography>
              </Box>
            </Box>
            <Box style={webStyle.jobRequestInviteVendorManageProfile}>
                <Box style={this.state.vendorInvitedToJobRole === false ? webStyle.inviteVendorButton as React.CSSProperties : webStyle.removeVendorButton as React.CSSProperties} onClick={this.state.vendorInvitedToJobRole === false ? this.postInviteforJobPersonal : this.deletefromJobInviteVendorPersonal}>
                  {this.state.vendorInvitedToJobRole === false ? "Invite for Job" : "Remove From Job"}  
                </Box>
            </Box>
          </Box>
        </>
      }
    
      renderInviteVendorManageProfileMainContent = (isJobRoleideBarStatusPH: boolean = this.state.isJobRoleRequestSideBarOpen) => {
        return <>
          <Loader loading={this.state.isLoading}></Loader>
          <Grid style={webStyle.inviteVendorWholePageContentSetup} >
            < Header
                navigate={this.props.navigation.navigate}
                sideBarStatus={isJobRoleideBarStatusPH}
                dropDownStatus={this.state.isJobRoleRequestHeaderDropdownOpenVal}
                handleDropdown={this.handleJobRoleHeaderDropdownValPersonal}
                handleSideBar={this.handleJobRoleSideBarPersonal}  
            />

            <Grid style={webStyle.inviteVendorDynamicMainContent as React.CSSProperties}>
              <Box sx={webStyle.inviteVendorBlockInfoTitle}>
                <Box sx={webStyle.wrapBlockAllArrowInviteVendor}>
                   
                <Box style={webStyle.mainContentHeader}>
                                <Box style={{ display: "flex", alignItems: "center", gap: "10px" }} >
                                    <ArrowBackIcon data-test-id="backArrow" onClick={this.props.personaldetailmodelclose} style={{
                                        background: "white",cursor:"pointer", border: "1px solid grey", borderRadius: "50%", marginRight: "10px", padding: "2px"
                                    }} />
                                <div>

                                    <Typography style={webStyle.headerTitle}>Person Details</Typography>
                                    <Typography style={{...webStyle.breadcrumbTesxt}}><span  onClick={()=>this.props.navigation.navigate("Projects")} style={{textDecoration:'underline',fontFamily:'urbanist',fontWeight:600,fontSize:'14px',cursor:"pointer"}}>Home</span> <span   onClick={()=>this.props.navigation.navigate("Projects")} style={{...webStyle.breadcrumbTesxt, color: "#F5C42C" ,textDecoration:'underline',fontFamily:'urbanist',fontWeight:600,fontSize:'14px',cursor:'pointer'}}>/ Projects</span>
                                    <span   onClick={()=>this.props.navigation.navigate("AddNewProject")} style={{...webStyle.breadcrumbTesxt, color: "#F5C42C" ,textDecoration:'underline',fontFamily:'urbanist',fontWeight:600,fontSize:'14px',cursor:'pointer'}}>/ Add New Projects</span>
                                     <span style={{ color: "#6D89AF",fontFamily:'urbanist',fontWeight:600,fontSize:'14px' }}>/ Person Details</span></Typography>
                                </div>
                                </Box>
                               
                            </Box>
                </Box>
              </Box>
              <Grid style={{ marginTop: "24px", borderRadius: "12px", border: "1px solid #e3eaf4", background: "#ffffff" }} item sm={12} xl={12} xs={12} md={12} lg={12}>
                {this.renderInviteVendorManageProfileBasicDetailsContent()}
              </Grid>
              <Grid container style={{ width: "100%", marginTop: "24px", alignItems: "flex-start" }}>
                <Grid md={8} sm={12} item className="manageProfileResponsive" style={{ maxHeight: "730px", minHeight: "406px", borderRadius: "12px", border: "1px solid #e3eaf4" }}>
                  <Box style={{ display: "flex" }}>
                    <Box style={webStyle.inviteVendorLefttabsMainBox}>
                      <Box data-test-id="overViewTab" onClick={() => this.handleTabValueRequestProfileChangePersonal(0)} style={{ borderBottom: "1px solid #e3eaf4", cursor: "pointer", width: "138px" }}>
                        <Typography style={{ ...webStyle.inviteVendorLeftSideTabText, background: this.state.requestProfileTabValue === 0 ? "#ffffff" : "#f8fafe", color: this.state.requestProfileTabValue === 0 ? "#f5c42c" : "#17181d" }}>Overview</Typography>
                      </Box>
                      <Box data-test-id="galleryTab" onClick={() => this.handleTabValueRequestProfileChangePersonal(1)} style={{ borderBottom: "1px solid #e3eaf4", cursor: "pointer", width: "138px" }}>
                        <Typography style={{ ...webStyle.inviteVendorLeftSideTabText, background: this.state.requestProfileTabValue === 1 ? "#ffffff" : "#f8fafe", color: this.state.requestProfileTabValue === 1 ? "#f5c42c" : "#17181d" }}>Gallery</Typography>
                      </Box>
                      <Box data-test-id="projectTab" onClick={() => this.handleTabValueRequestProfileChangePersonal(2)} style={{ borderBottom: "1px solid #e3eaf4", cursor: "pointer", width: "138px" }}>
                        <Typography style={{ ...webStyle.inviteVendorLeftSideTabText, background: this.state.requestProfileTabValue === 2 ? "#ffffff" : "#f8fafe", color: this.state.requestProfileTabValue === 2 ? "#f5c42c" : "#17181d" }}>Projects</Typography>
                      </Box>
                    </Box>
                    {this.renderVendorManageProfileOverviewTabContent()}
                    {this.renderVendorManageProfileGalleryTabContentInviteVendor()}
                    {this.renderVendorManageProfileProjectsTabContent()}
                  </Box>
                </Grid>
                <Grid md={4} sm={12} item style={{ paddingLeft: "24px" }}>
                <Grid item style={webStyle.ratingAndReviewTab}>
                  {this.renderVendorManageProfileRatingAndReviewTabContent()}
                </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Footer />
        </>
      }
      // Customizable Area End
    
    render() {
        return ( 
            // Customizable Area Start
            <StyledModal
            aria-labelledby="unstyled-modal-title"
            aria-describedby="unstyled-modal-description"
            open={this.props.inviteMemberModal}
             onClose={this.props.personaldetailmodelclose}
        >
               <Container maxWidth="xl" className="user_detail_container" style={{backgroundColor:"#ffffff",overflowY:'scroll',height:'100%',scrollbarWidth:'none'}}>
                {
                    this.state.isJobRoleRequestSideBarOpen?
                        <Grid container spacing={0}>
                            <Grid style={webStyle.inviteVendorSidebarParentGrid} item xs={3}>
                              
                                <SideBar
                                activeMenuItem={"Projects"}
                                userData={{ age: 25 }}
                                navigate={this.props.navigation.navigate}
                                handleSideBar={this.handleJobRoleSideBarPersonal}   
                                />
                            </Grid>
                            <Grid style={{ flexDirection: "column",display: "flex",maxWidth: "77.75%" ,flexBasis: "77.75%", }} item xs={9} >
                                 {this.renderInviteVendorManageProfileMainContent()}
                            </Grid>
                        </Grid> :
                        <Grid style={{flexDirection: "column" , display: "flex"}} item xs={12}>
                              {this.renderInviteVendorManageProfileMainContent()}
                        </Grid>
                }
             </Container >
            </StyledModal>
             // Customizable Area End
        );  }
}
// Customizable Area Start
const webStyle = {
    inviteVendorLefttabsMainBox: {
      height: "100%",
      width: "138px",
      background: "#f8fafe",
    },
    RequestProfileGalleryPhotoAddMoreText: {
      color: "#f5c42c",
      lineHeight: "18px",
      fontSize: "14px",
      fontFamily: "Urbanist",
      letterSpacing: "0.21538462px",
      fontWeight: 600,
      marginTop: "2px",
    },
    readMoreTextInviteVendor: {
      color: "#f5c42c",
      lineHeight: "15px",
      fontSize: "12px",
      fontFamily: "Urbanist",
      textDecoration: "underline",
      fontWeight: 600,
      cursor: "pointer",
      letterSpacing: "0.18461539px",
    },
    projectsCardInviteVendor: {
      border: "1px solid #e3eaf4",
      height: "84px",
      width: "100%",
      borderRadius: "12px",
      padding: "10px",
      background: "#ffffff",
      display: "flex",
      marginBottom: "17px",
      justifyContent: "space-between",
      flexDirection: "row",
    },
    inviteVendorLeftSideTabText: {
      color: "#17181d",
      lineHeight: "17px",
      fontSize: "14px",
      fontFamily: "Urbanist",
      padding: "27px 16px 21px 16px",
      fontWeight: 600,
    },
    ratingAndReviewTab:{
       width: "100%", maxHeight: "680px", minHeight: "593px", borderRadius: "12px", border: "1px solid #e3eaf4", background: "#ffffff" ,
    },
    inviteVendorAllReviewsTitle: {
      fontFamily: "Urbanist",
      color: "#6d89af",
      fontWeight: 600,
      fontSize: "12px",
      whiteSpace: "nowrap",
      lineHeight: "19px",
      overflow: "hidden",
      textOverflow: "clip",
    },
    inviteVendorStars: {
      background: "linear-gradient(225deg, #58A2FF 0%, #1777E2 100%)",
      padding: "0px 8px",
      display: "flex",
      borderRadius: "12px",
      justifyContent: 'space-evenly',
      alignItems: "center",
    },
    inviteVendorManageProfileProjectStatus: {
      background: "linear-gradient(225deg, #58A2FF 0%, #1777E2 100%)",
      padding: "0px 8px",
      display: "flex",
      borderRadius: "12px",
      justifyContent: 'space-evenly',
      alignItems: "center",
    },
    inviteVendorStarColor: {
      color: 'white',
    },
    inviteVendorComment:{
       color: "#17181d", 
       fontFamily: "Urbanist", 
       fontSize: "12px", 
       fontWeight: 500, 
       lineHeight: "16px", 
       letterSpacing: "0.2px",
    },
    inviteVendorBadge:{
      width: 75, height: 75
    },
    contentProfileAvator:{
        width: 69,
        border: "3px solid #f5c42c",
        marginRight: 1,
        height: 69,
    },
    inviteVendorProjectcardParentBox: {
      flexDirection: "row",
      display: "flex",
      justifyContent: "space-between",
      padding: '2%',
      alignItems: "flex-start",
    },
    inviteVendorProfileImage:{
      borderRadius: "12px", width: 36, height: 36 
    },
    inviteVendorFullName:{
       lineHeight: "19px", color: "#17181d", fontFamily: "Urbanist", fontSize: "16px", fontWeight: 600, letterSpacing: "0.2px" ,
    },
    inviteVendorCreatedAt:{
       marginTop: "1px", lineHeight: "12px", color: "#6d89af", fontFamily: "Urbanist", fontSize: "10px", fontWeight: 500, letterSpacing: "0.2px",
    },
    inviteVendorProjectStatusBtn: {
      height: "24px",
      width: "74px",
      background: "linear-gradient(225deg, #50b609 0%, #42a200 100%)",
      borderRadius: "12px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    inviteVendorProjectStatusText: {
      color: "#ffffff",
      lineHeight: "12px",
      fontSize: "10px",
      fontWeight: 600,
      fontFamily: "Urbanist",
    },
    inviteVendorProjectDateText: {
      color: "#6d89af",
      lineHeight: "15px",
      fontSize: "12px",
      fontFamily: "Urbanist",
      letterSpacing: "0.24px",
      textAlign: "end",
      fontWeight: 500,
    },
    inviteVendorRightSideBoxText: {
      color: "#17181d",
      lineHeight: "14px",
      fontSize: "14px",
      fontFamily: "Urbanist",
      letterSpacing: "0.23333333px",
      fontWeight: 600,
      display: "flex",
      alignItems: "center",
    },
    inviteVendorRightBox: {
      display: "flex",
      paddingLeft: "24.5px",
      alignItems: "start",
      flexDirection: "column",
    },
    inviteVendorLeftBox: {
      height: "79px",
      borderRight: "1px solid #e3eaf4",
      display: "flex",
      paddingRight: "24px",
      alignItems: "start",
      flexDirection: "column",
      marginLeft: "10px",
    },
    inviteVendorAddressText: {
      color: "#6d89af",
      lineHeight: "17px",
      fontSize: "14px",
      fontFamily: "Urbanist",
      letterSpacing: "0.23333333px",
      margin: "4px 0px",
      fontWeight: 500,
      display: "flex",
      alignItems: "center",
    },
    inviteVendorPhName: {
      color: "#6d89af",
      lineHeight: "17px",
      fontSize: "14px",
      fontFamily: "Urbanist",
      letterSpacing: "0.23333333px",
      marginTop: "4px",
      fontWeight: 500,
      display: "flex",
      alignItems: "center",
    },
    inviteVendorRatingStartMainBox: {
      width: "100%",
    },
    inviteVendorRatingStartBox: {
      paddingRight: "12px",
      paddingLeft: "12px",
      display: "flex",
      paddingTop: "12px",
      justifyContent: "space-between",
      alignItems: "center",
    },
    inviteVendorSidebarParentGrid: {
      //minHeight: "100vh",
      maxWidth: "22.25%",
    },
    inviteVendorWholePageContentSetup: {
      borderLeft: "1px solid #E3EAF4",
      flex: 1,
    },
    inviteVendorDynamicMainContent: {
      boxSizing: "border-box",
      width: "100%",
      //minHeight: "100vh",
      backgroundColor: "#F8FAFE",
      padding: "24px",
    },
    breadcrumbTesxt: {
        color: "#F5C42C",
        fontSize: "14px",
        marginTop: "8px",
    },
    inviteVendorBlockInfoTitle: {
      display: "block",
    },
    mainContentHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    wrapBlockAllArrowInviteVendor: {
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        gap: 10,
        marginBottom: '5px'
    },
    headerTitle: {
        color: " #17181D",
        fontFamily: "Urbanist",
        fontSize: "28px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "normal",
        letterSpacing: "0.311px",
    },
    activeBlockAllArrowInviteVendor: {
        backgroundColor:' #fff',
        border: '1px solid #ddd',
        borderRadius: '50%',
        padding: '10px',   
        cursor: 'pointer',
    },

    wrapBlockAllArrowBackInviteVendor: {
        height: '16px'
    },
    activeBlockJobRoleInviteVendor: {
        fontSize: "28px",
        fontWeight: "600",
        letterSpacing: "1px",
        display: "flex",
        flexDirection: "row",
        lineHeight: "33px",
        alignItems: 'center',  
    },

    highlightedTextInviteVendor: {
        color: "#F5C42C",
        textDecoration: 'underline',
        textDecorationColor: "#F5C42C",
        fontWeight: 600,
        cursor:"pointer",
    },
    jobRequestInviteVendorManageProfile: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '13px',
        padding: '0 16px',
    },
    inviteVendorButton: {
        minWidth: '161px',
        borderRadius: '30px',
        textAlign: 'center',
        background: 'transparent',
        color: '#3389FF',
        fontFamily: 'urbanist',
        fontSize: '14px',
        fontWeight: 600,
        letterSpacing: ' 0.25px',
        padding: '15px 10px',   
        cursor: 'pointer', 
        border: '1px solid #3389FF',
    },
    removeVendorButton: {
        minWidth: '161px',
        borderRadius: '30px',
        textAlign: 'center',
        background: '#F8FAFE',
        color: '#6D89AF',
        fontFamily: 'urbanist',
        fontSize: '14px',
        fontWeight: 600,
        letterSpacing: ' 0.25px',
        padding: '15px 10px',   
        cursor: 'pointer', 
        border: '1px solid #6D89AF',
    },
    inviteVendorDeclineButton: {
        minWidth: '161px',
        borderRadius: '14px',
        textAlign: 'center',
        background: '#FFF',
        color: '#ff5c5c',
        fontFamily: 'urbanist',
        fontSize: '14px',
        fontWeight: 600,
        letterSpacing: ' 0.25px',
        padding: '15px 10px',   
        cursor: 'pointer',
        border: '1px solid #ff5c5c',
    },
    spanLineInviteVendor: {
        color: '#D8D8D8',
        paddingLeft: '12px',
    },
    jobRequestModalInviteVendor: {
        top: '50%',
        position: 'absolute',                                              
        left: '50%',
        backgroundColor: '#fff',
        borderRadius: '20px',
        transform: 'translate(-50%, -50%)',
        overflow: 'auto',
        width: '100%',
        maxWidth: '391px',  
        padding: '24px'                    
    },
    jobRequestModalHeadInviteVendor: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    jobReqCrossIconInviteVendor: {
        position: 'absolute',
        right: '22px',
        top: '22px',
        cursor: 'pointer',
    },
    jobReqModalHeadingInviteVendor: {
        fontSize: '20px',
        fontWeight: 600,
        paddingBottom: '24px',
    },
    jobRequestModalProfileInviteVendor: {
        textAlign: 'center',
        position: 'relative',
    },
    jobReqProfileNameInviteVendor: {
        fontSize: '14px',
        fontWeight: 600,
        padding: '10px',
    },
    jobreqModalRateInviteVendor: {
        padding: '14px',
        borderRadius: '14px',
        backgroundColor: '#E3EAF4',
        margin: '20px 0 16px',
    },
    jobRequestSaveButtonInviteVendor: {
        margin: '20px auto 0',
        borderRadius: '14px',
        background: '#17181d',
        color: '#ffffff',
        fontFamily: 'urbanist',
        fontSize: '15px',
        fontWeight: 600,
        letterSpacing: ' 0.25px',
        padding: '15px 10px',   
        cursor: 'pointer', 
        textAlign: 'center'
    },
    jobrateMoreIconInviteVendor: {
        height: '11px',
        transform: 'rotate(180deg)',
    },
    jobViewMoreInviteVendor: {
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        color: '#F5C42C',
        fontWeight: 700,
        fontSize: 14,
        cursor: 'pointer'
    },
    errorMessageInviteVendor: {
      color: "red", 
      fontSize: '14px', 
      position: 'absolute',
      left: 0,
      bottom: '-22px',
    },
    reviewBlkInviteVendor: {
      position: 'relative',
    },
    errorMessageTextInviteVendor: {
      color: "red", 
      fontSize: '14px', 
      position: 'absolute',
      left: '15px',
      bottom: '-10px',
    },
  };
// Customizable Area End