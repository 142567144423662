import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import RestClientBlock from "../../../framework/src/Blocks/RestApiClientBlock";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  allowAccess: boolean;
  gpsLocation: string;
  isSpinnerShowing: boolean;
  // Customizable Area Start
  isLocationSideBarOpen: boolean;
  isLocationDropdownOpen: boolean;
  tabLocationValue: number;
  liveTravellingList: any[];
  travelHistoryList: any[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LocationController extends BlockComponent<Props, S, SS> {
  getCityApiCallId: any;
  updateDefaultCityApiCallId: any;
  googleMapAPIId: any;
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    // Customizable Area Start
    this.state = {
      allowAccess: true,
      gpsLocation: "",
      isSpinnerShowing: false,
      isLocationSideBarOpen: true,
      isLocationDropdownOpen: false,
      tabLocationValue: 0,
      liveTravellingList: ["a", "b", "c", "d", "f"],
      travelHistoryList: ["a", "b", "c", "d", "f", "e"],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleLocationSideBar = () => {
    setStorageData("sidebarvalue", (!this.state.isLocationSideBarOpen).toString())
    this.setState({
      isLocationSideBarOpen: !this.state.isLocationSideBarOpen
    });
  };

  handleLocationDropdown = () => {
    this.setState({
      isLocationDropdownOpen: !this.state.isLocationDropdownOpen
    });
  };

  async componentDidMount(): Promise<void> {
    const isLocationSideBarOpen = await getStorageData("sidebarvalue");
    this.setState({ isLocationSideBarOpen: JSON.parse(isLocationSideBarOpen) });
  }

  handleLocationNavigation = (page: string) => {
    this.props.navigation.navigate(page);
    setStorageData("travelTab", this.state.tabLocationValue.toString());
  };

  handleLocationAppBarValue = (event: React.ChangeEvent<{}>, value: number) => {
    this.setState({ tabLocationValue: value });
  }

  checkGPS = () => {
    this.setState({ isSpinnerShowing: true });
  };

  getLocation = async () => {
    this.setState({ isSpinnerShowing: true });
  };
  // Customizable Area End
}
