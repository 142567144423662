import React from "react";
import {
    Box,
    // Customizable Area Start
    Container,
    Grid,
    Link,
    Typography,
    styled,
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SideBar from "../../../components/src/SideBar";
import AddNewDepartMentModal from "../../../components/src/AddNewDepartMentModal";
import Header from "../../../components/src/Header.web";
import Loader from "../../../components/src/Loader.web";
import Footer from "../../../components/src/Footer";
import { CustomButton } from "../../email-account-login/src/CustomButton";
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import AllDepartmentListPHController from "./AllDepartmentListPHController";
import { checkeddeprtment, uncheckdepartment } from "../../../components/src/assets";
import CustomNotification from "../../../components/src/CustomNotification";





const CustomNextStepButtonBox = styled(CustomButton)({
    '& .MuiButton-label': {
        fontSize: 14,
        color: '#ffffff',
        fontWeight: 600,
        width: 163,
        height: 48,
        fontFamily:'urbanist'
    }
});




// Customizable Area End
export default class AllDepartmentListPH extends AllDepartmentListPHController {


    constructor(props: any) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    DepartmentHeading() {
       
        let status = this.state.status;
        let headingText;
      
        if (status === "ongoing") {
          headingText = "Ongoing Projects";
        } else if (status === "upcoming") {
          headingText = "Upcoming Projects";
        } else {
          headingText = "All Projects";
        }
      
        return headingText;
    }
    deparmentList(ele:any){
        return(
            ele.attributes.pipeline_department?(
                < select data-test-id='deptLevelChange' value={ele.attributes.level} style={webStyle.listingLevel} onChange={(event)=>this.handleDepartmentLevelChange(event,ele.id)}>
                <option value="0">Level 0</option>
                <option value="1">Level 1</option>
                <option value="2">Level 2</option>
                <option value="3">Level 3</option>
                <option value="4">Level 4</option>
                <option value="5">Level 5</option>
                <option value="6">Level 6</option>
                <option value="7">Level 7</option>
                <option value="8">Level 8</option>
                <option value="9">Level 9</option>
                <option value="10">Level 10</option>


            </select>
            ):(null)
        )
    }
   
    getStepContent() {
        
            
               
            
                return (
                    <Grid spacing={2} container style={{ padding: "0px 24px" }} md={12}>
                     
                        <Grid style={{ flexWrap: "wrap", display: "flex",gap:"12px"}} spacing={2} md={12} container item className="t55">
                     
                            {this.state.departmentList&&this.state.departmentList?.map((ele:any)=>{
                                return        <Box key={ele.id} style={{width:"29.70%" ,padding: "14px 14px 12px 12px", height: "99px", borderRadius: "12px", border: "1px solid #F5C42C" }}>
                                <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", borderBottom: "1px solid #E3EAF4", paddingBottom: "12px" }}>
                                    <span style={{ fontSize: "15px",fontWeight:600, fontFamily: "Urbanist" }}>{ele.attributes.name}</span>
                                    <Box data-test-id='toggle' onClick={() => this.togglePipelineDepartmentPH(ele.id)}>
                  {ele.attributes.pipeline_department ? (
                    <img src={checkeddeprtment} alt="Checked" style={{height:'20px',cursor:'pointer'}} />
                  ) : (
                    <img src={uncheckdepartment} alt="Unchecked" style={{height:'20px',cursor:'pointer'}}/>
                  )}
                </Box>
                                </Box>

                                <Box style={{ marginTop: "12px", display: "flex", alignItems: "center", justifyContent: "space-between", padding: "12px 0px" }}>
                                    <span style={{ fontSize: "13px",fontFamily:'urbanist',fontWeight:500 }}>Select pipeline level of the dept.</span>
                                 { this.deparmentList(ele)}
                                </Box>

                            </Box>
                            })}

                        </Grid>
                        <AddNewDepartMentModal
                        handleCloseAddNewDepartmentModal={this.handleCloseAddNewDepartmentModalPH}
                        handleChange={this.handleaddNewDepartmentChangePH}
                        newDepartmentName={this.state.newDepartmentName}
                        addNewDepartmentModal={this.state.addNewDepartmentModal}
                        handleaddNewDepartmentModal={this.handleaddNewDepartmentModalPH}
                        />
                    </Grid>
                )
           
          
    
    }
    ProjectsMainContent(sideBarStatus: boolean = this.state.isSideBarOpen) {

        return (
            <>
                <Box data-test-id="addNewProjectContainer" style={{ borderLeft: "1px solid #E3EAF4" }} >
                    <Loader loading={false}></Loader>
                    <Header
                        sideBarStatus={sideBarStatus}
                        dropDownStatus={this.state.isDropdownOpen}
                        handleSideBar={this.handleSideBar}
                        handleDropdown={this.handleDropdown}
                        navigate={this.props.navigation.navigate}
                    />
                    <Box style={webStyle.mainContentJobDepartment as React.CSSProperties}>
                        <Box style={webStyle.mainContentDepartment}>
                            <Box style={webStyle.mainContentHeaderDepartment}>
                                <Box style={{ display: "flex", alignItems: "center", gap: "10px" }} >
                                    <ArrowBackIcon data-test-id="backArrow" 
                                    onClick={this.handlenavigateToProjectsPagePH}
                                     style={{
                                        background: "white",cursor:"pointer", border: "1px solid grey", borderRadius: "50%", marginRight: "10px", padding: "2px"
                                    }} />
                                
                                 <Box>
                                    <Typography style={webStyle.headerTitleDepartment}>All Departments</Typography>
                                    <Typography style={{...webStyle.breadcrumbTesxtDepartment,cursor:'pointer'}}>
                                        <span  style={{textDecoration:'underline',fontFamily:'urbanist',fontWeight:600,fontSize:'14px'}}>
                                        <Link  style={{color: "#F5C42C"}} data-test-id="headingproject" onClick={()=>this.props.navigation.navigate("Projects")}>
                    Home
                  </Link>
                                            </span> 
                                            <span style={{...webStyle.breadcrumbTesxtDepartment, color: "#F5C42C" ,textDecoration:'underline',fontFamily:'urbanist',fontWeight:600,fontSize:'14px'}}>
                                              <Link style={{color: "#F5C42C"}} onClick={()=>this.props.navigation.navigate("ProjectDetailPH")} > 
                                                /{this.DepartmentHeading()} / {this.state.ProjectName}
                                                </Link> 
                                                 </span> 
                                                 <span style={{ color: "#6D89AF",fontFamily:'urbanist',fontWeight:600,fontSize:'14px' }}>
                                            / Manage Departments</span>
                                            </Typography>
                                            </Box>
                                </Box>
                                <Box>
                            <CustomNextStepButtonBox data-test-id="newDepartmentButton" style={{ height: 48,background:"white",color:"black",border:"1px solid black" } as React.CSSProperties} className="button" onClick={this.handleaddNewDepartmentModalPH}>
                                        + Add New Department
                                    </CustomNextStepButtonBox>
                                </Box>
                            </Box>
                            <Box style={{ position: "relative", backgroundColor: "white", marginTop: "24px", minHeight: "681px", borderRadius: "12px" }}>
                                <div style={{ display: "flex" }}>
                                  
                                   
                                </div>

                                <Box>
                                   {this.getStepContent()}
                                </Box>
                            
                            </Box>
                        </Box>
                        <Box style={{ position: this.state.isSideBarOpen ? 'absolute' : "sticky", bottom: 0, left: 0, right: 0, backgroundColor: 'white' }}>

                            <Footer />
                        </Box>
                    </Box>

                </Box>
            </>
        )

    }
    render() {
        return (
            <Container style={webStyle.parentDivDeparment}>
                {
                    this.state.isSideBarOpen ?
                        <Grid container spacing={0}>
                            <Grid item xs={3} style={webStyle.websidebarParentDepartment}>
                                <SideBar
                                    userData={{ age: 25 }}
                                    activeMenuItem={"Projects"}
                                    navigate={this.props.navigation.navigate}
                                    handleSideBar={this.handleSideBar} />
                            </Grid>
                            <Grid item xs={9} md={9}>{this.ProjectsMainContent()}</Grid>
                        </Grid> :
                        <>
                        <Grid item xs={12} md={12}>{this.ProjectsMainContent()}</Grid>
                      
                        </>
                }
                    <CustomNotification navigation={(route: string) => this.props.navigation.navigate(route)} notification={this.state.emailLoginNotification} handleCloseNotification={this.handleEmailLoginCloseNotification} />
            </Container>
        );
    }
    // Customizable Area End

}

// Customizable Area Start
const webStyle = {
    departmentheading:{ fontSize: "13px",fontFamily:'urbanist',fontWeight:500 },
nameDepartment:{ fontSize: "15px",fontWeight:600, fontFamily: "Urbanist" },
    mainContentJobDepartment: {
        width: "100%",
        backgroundColor: "#F8FAFE",
        boxSizing: "border-box",
        padding: "24px",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        height: "100%",
        justifyContent: "space-between"
    },
    leveldepartment:{ marginTop: "12px", display: "flex", alignItems: "center", justifyContent: "space-between", padding: "12px 0px" },
    checkiconcss:{background:"#F5C42C",color:"white",borderRadius:"50%"},
    parentDivDeparment: {
        maxWidth: "none",
        padding: "0px",
    },
    websidebarParentDepartment: {
        minHeight: "100vh",
        maxWidth: "23%"
    },
    
    breadcrumbTesxtDepartment: {
        color: "#F5C42C",
        fontSize: "14px",
        marginTop: "8px",
    },
    mainContentHeaderDepartment: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    headerTitleDepartment: {
        color: " #17181D",
        fontFamily: "Urbanist",
        fontSize: "28px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "normal",
        letterSpacing: "0.311px",
    },
    mainContentDepartment: {
        backgroundColor: "#F8FAFE",
        
    },
    levellist:{fontWeight:600,width: '82px', height: '30px',
  borderRadius: '10px',
  border: '1px solid #e3eaf4',
  background: 'rgba(227,234,244,0.5)',fontFamily:'urbanist'},
  listingLevel:{fontWeight:600,width: '82px', height: '30px',
  borderRadius: '10px',
  border: '1px solid #e3eaf4',
  background: 'rgba(227,234,244,0.5)',fontFamily:'urbanist'}
  

};
// Customizable Area End
