import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  projectName: string;
  projectDescription: string;
  clientDetails: string;
  teamSize: string;
  projectUrl: string;

  startDate: string;
  endDate: string;
  images: { attributes: { public_url: string } }[];
  documents: { attributes: { file_name: string; public_url: string } }[];
  email: string;
  fullName: string;
  phoneNumber: string;
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProjectPortfolioDetailController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];
    // Customizable Area End

    this.state = {
      // Customizable Area Start
      projectName: "",
      projectDescription: "",
      clientDetails: "",
      teamSize: "",
      projectUrl: "",
      startDate: "",
      endDate: "",
      images: [],
      documents: [],
      email: "",
      fullName: "",
      phoneNumber: "",
      loading: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  apiGetProjectbyIDListCallId: string | null = "";
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const responseData = message.getData(getName(MessageEnum.PropsData));
      this.getProjectbyID(responseData);
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId !== null) {
        if (apiRequestCallId === this.apiGetProjectbyIDListCallId) {
          if (responseJson && responseJson.data) {
            const { data } = responseJson.data;
            this.setState({
              projectName: data.attributes.project_name,
              projectDescription:
                data.attributes.project.data.attributes.description,
              email: data.attributes.client_account.email,
              fullName: data.attributes.client_account.full_name,
              phoneNumber: data.attributes.client_account.full_phone_number,
              clientDetails: data.attributes.client_details,
              teamSize: JSON.stringify(data.attributes.team_size),
              projectUrl: data.attributes.project.data.attributes.url,
              startDate: data.attributes.project.data.attributes.start_date,
              endDate: data.attributes.project.data.attributes.end_date,
              images: data.attributes.images.data,
              documents: data.attributes.documents.data,
              loading: false,
            });
          } else {
            this.setState({ loading: false });
            this.showAlert("Error", responseJson.errors);
          }
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getProjectbyID = async (projectID: string) => {
    let token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    this.setState({ loading: true });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetProjectbyIDListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.viewProjectsAPIEndPoint + projectID
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  goBack() {
    this.props.navigation.goBack();
  }
  // Customizable Area End
}
