import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isVendorDetailedAnalyticsSideBarOpen: boolean;
  isVendorDetailedAnalyticsDropdownOpen: boolean;
  values: string[];
  vendorInvoicesIndex: number;
  vendorProjectsIndex: number;
  balanceSheetIndex: number;
  vendorProjectsFilteredBars: any[];
  vendorInvoicesFilteredBars: any[];
  token: string;
  isLoading: boolean;
  vendorAnalyticsProjectsData: any;
  vendorAnalyticsInvoicesData: any;
  vendorAnalyticsDetailedReportType: string;
  vendorDetailedAnalyticsNotification: { type: string, open: boolean, message: string, route?: string };
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class VendorDetailedAnalyticsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getVednorAnalyticsOngoingProjectsMessgaeId: string = "";
  getVednorAnalyticsUpcomingProjectsMessgaeId: string = "";
  getVednorAnalyticsCompletedProjectsMessgaeId: string = "";
  getVendorAnalyticsPendingInvoicesMessgaeId: string = "";
  getVendorAnalyticsRejectedInvoicesMessgaeId: string = "";
  getVendorAnalyticsApprovedInvoicesMessgaeId: string = "";
  getVendorAnalyticsCompletedInvoicesMessgaeId: string = "";
  vendorProjectsAllBars = [
    {
      title: "Ongoing Projects",
      name: "ongoing_project",
      data: {},
      bars: [{ name: "Ongoing", color: "#F7B500" }],
      index: 0,
    },
    {
      title: "Upcoming Projects",
      name: "upcoming_project",
      data: {},
      bars: [{ name: "Upcoming", color: "#3389FF" }],
      index: 0,
    },
    {
      title: "Completed Projects",
      name: "completed_project",
      data: {},
      bars: [{ name: "Completed", color: "#f5c42c" }],
      index: 0,
    },
  ];
  vendorInvoicesAllBars = [
    { title: "Raised/Pending Invoices", name: "raised_invoices", bars: [{ name: "pending", color: "#F7B500" }], data: {}, index: 0, },
    { title: "Rejected Invoices", name: "rejected_invoices", bars: [{ name: "rejected", color: "#FF5C5C" }], data: {}, index: 0, },
    { title: "Approved Invoices", name: "approved_invoices", bars: [{ name: "approved", color: "#3389FF" }], data: {}, index: 0, },
    { title: "Completed Invoices", name: "completed_invoices", bars: [{ name: "completed", color: "#42A200" }], data: {}, index: 0, },
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isVendorDetailedAnalyticsSideBarOpen: true,
      isVendorDetailedAnalyticsDropdownOpen: false,
      values: ["Weekly", "Monthly", "Yearly"],
      vendorInvoicesIndex: 0,
      vendorProjectsIndex: 0,
      balanceSheetIndex: 0,
      vendorProjectsFilteredBars: this.vendorProjectsAllBars,
      vendorInvoicesFilteredBars: this.vendorInvoicesAllBars,
      token: "",
      isLoading: false,
      vendorAnalyticsProjectsData: {},
      vendorAnalyticsInvoicesData: {},
      vendorAnalyticsDetailedReportType: "",
      vendorDetailedAnalyticsNotification: { type: "", open: false, message: "" },
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getVednorAnalyticsOngoingProjectsMessgaeId) {
        this.getVendorAnalyticsProjectsDataHandleResponse(responseJson, 0);
      } else if (apiRequestCallId === this.getVednorAnalyticsUpcomingProjectsMessgaeId) {
        this.getVendorAnalyticsProjectsDataHandleResponse(responseJson, 1);
      } else if (apiRequestCallId === this.getVednorAnalyticsCompletedProjectsMessgaeId) {
        this.getVendorAnalyticsProjectsDataHandleResponse(responseJson, 2);
      } else if (apiRequestCallId === this.getVendorAnalyticsPendingInvoicesMessgaeId) {
        this.getVendorAnalyticsInvoicesDataHandleResponse(responseJson, 0);
      } else if (apiRequestCallId === this.getVendorAnalyticsRejectedInvoicesMessgaeId) {
        this.getVendorAnalyticsInvoicesDataHandleResponse(responseJson, 1);
      } else if (apiRequestCallId === this.getVendorAnalyticsApprovedInvoicesMessgaeId) {
        this.getVendorAnalyticsInvoicesDataHandleResponse(responseJson, 2);
      } else if (apiRequestCallId === this.getVendorAnalyticsCompletedInvoicesMessgaeId) {
        this.getVendorAnalyticsInvoicesDataHandleResponse(responseJson, 3);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getVendorAnalyticsProjectsDataHandleResponse = (responseJson: { status: number, error: string, data: any; project_status: string; errors: [{ message: string }] }, index: number) => {
    if (responseJson.data) {
      let projectChartData = this.state.vendorProjectsFilteredBars;
      projectChartData[index].data = Object.keys(responseJson.data).map((key) => ({ name: key, ...responseJson.data[key] }));
      this.setState({ vendorProjectsFilteredBars: projectChartData });
    }
    else {
      this.setState({ vendorDetailedAnalyticsNotification: { open: true, type: "error", message: responseJson.status === 500 ? responseJson.error : responseJson.errors[0].message } });
    }
    this.setState({ isLoading: false });
  }

  getVendorAnalyticsInvoicesDataHandleResponse = (responseJson: { status: number, error: string, data: any; meta: any; errors: [{ message: string }] }, index: number) => {
    if (responseJson.data) {
      let invoiceChartData = this.state.vendorInvoicesFilteredBars;
      invoiceChartData[index].data = Object.keys(responseJson.data).map((key) => ({ name: key, ...responseJson.data[key] }));
      this.setState({ vendorAnalyticsInvoicesData: invoiceChartData });
    }
    else {
      this.setState({ vendorDetailedAnalyticsNotification: { open: true, type: "error", message: responseJson.status === 500 ? responseJson.error : responseJson.errors[0].message } });
    }
    this.setState({ isLoading: false });
  }

  async componentDidMount(): Promise<void> {
    const vendorAnalyticsAuthToken = await getStorageData("token");
    const vendorAnalyticsDetailedReportType = await getStorageData("vendorAnalyticsDetailedReportType");
    this.setState({ token: vendorAnalyticsAuthToken, vendorAnalyticsDetailedReportType: vendorAnalyticsDetailedReportType }, () => {
      this.getAllVendorDetailedAnalyticsData();
    });
    let isVendorDetailedAnalyticsSideBarOpen = await getStorageData("sidebarvalue");
    this.setState({ isVendorDetailedAnalyticsSideBarOpen: JSON.parse(isVendorDetailedAnalyticsSideBarOpen) })
  }

  apiCall = async (vendorDetailedAnalyticsApiMethod: string, vendorDetailedAnalyticsApiEndPoint: string) => {
    const vendorDetailedAnalyticsHeader = {
      "token": this.state.token,
      "Content-Type": configJSON.validationApiContentType,
    };
    this.setState({ isLoading: true });
    const vendorDetailedAnalyticsRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    vendorDetailedAnalyticsRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(vendorDetailedAnalyticsHeader)
    );
    vendorDetailedAnalyticsRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      vendorDetailedAnalyticsApiEndPoint
    );
    vendorDetailedAnalyticsRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      vendorDetailedAnalyticsApiMethod
    );
    runEngine.sendMessage(vendorDetailedAnalyticsRequestMessage.id, vendorDetailedAnalyticsRequestMessage);
    return vendorDetailedAnalyticsRequestMessage.messageId;
  };

  getAllVendorDetailedAnalyticsData = async () => {
    if (this.state.vendorAnalyticsDetailedReportType === "project") {
      this.getVednorAnalyticsOngoingProjectsMessgaeId = await this.apiCall(configJSON.validationApiMethodType, configJSON.viewDetailedAnalyticProjectsAPI + `weekly&project_status=${this.state.vendorProjectsFilteredBars[0].name}`);
      this.getVednorAnalyticsUpcomingProjectsMessgaeId = await this.apiCall(configJSON.validationApiMethodType, configJSON.viewDetailedAnalyticProjectsAPI + `weekly&project_status=${this.state.vendorProjectsFilteredBars[1].name}`);
      this.getVednorAnalyticsCompletedProjectsMessgaeId = await this.apiCall(configJSON.validationApiMethodType, configJSON.viewDetailedAnalyticProjectsAPI + `weekly&project_status=${this.state.vendorProjectsFilteredBars[2].name}`);
    } else if (this.state.vendorAnalyticsDetailedReportType === "invoice") {
      this.getVendorAnalyticsPendingInvoicesMessgaeId = await this.apiCall(configJSON.validationApiMethodType, configJSON.viewDetailedAnalyticInvoicesAPI + `weekly&invoice_status=${this.state.vendorInvoicesFilteredBars[0].name}`);
      this.getVendorAnalyticsRejectedInvoicesMessgaeId = await this.apiCall(configJSON.validationApiMethodType, configJSON.viewDetailedAnalyticInvoicesAPI + `weekly&invoice_status=${this.state.vendorInvoicesFilteredBars[1].name}`);
      this.getVendorAnalyticsApprovedInvoicesMessgaeId = await this.apiCall(configJSON.validationApiMethodType, configJSON.viewDetailedAnalyticInvoicesAPI + `weekly&invoice_status=${this.state.vendorInvoicesFilteredBars[2].name}`);
      this.getVendorAnalyticsCompletedInvoicesMessgaeId = await this.apiCall(configJSON.validationApiMethodType, configJSON.viewDetailedAnalyticInvoicesAPI + `weekly&invoice_status=${this.state.vendorInvoicesFilteredBars[3].name}`);
    }
  }

  vendorDetailedAnalyticsHandleDropdown = () => {
    this.setState({ isVendorDetailedAnalyticsDropdownOpen: !this.state.isVendorDetailedAnalyticsDropdownOpen });
  };

  vendorDetailedAnalyticsHandleNextClick = (vendorAnalyticsIndex: number, stateName: keyof S, chartIndex: number) => {
    if (vendorAnalyticsIndex === 2) { return false; }
    if (vendorAnalyticsIndex !== 2) {
      let updateChartData = this.state[stateName];
      updateChartData[chartIndex].index = vendorAnalyticsIndex + 1;
      this.setState(({
        [stateName]: updateChartData
      } as unknown) as Pick<S, keyof S>, () => {
        this.getVendorAnalyticsChartsDynamicData(stateName, chartIndex);
      });
    }
  };

  vendorDetailedAnalyticsHandleSideBar = () => {
    setStorageData("sidebarvalue", (!this.state.isVendorDetailedAnalyticsSideBarOpen).toString())
    this.setState({ isVendorDetailedAnalyticsSideBarOpen: !this.state.isVendorDetailedAnalyticsSideBarOpen });
  };

  vendorDetailedAnalyticsHandlePreviousClick = (vendorAnalyticsIndex: number, stateName: keyof S, chartIndex: number) => {
    if (vendorAnalyticsIndex !== 0) {
      let updateChartData = this.state[stateName];
      updateChartData[chartIndex].index = vendorAnalyticsIndex - 1;
      this.setState(({
        [stateName]: updateChartData
      } as unknown) as Pick<S, keyof S>, () => {
        this.getVendorAnalyticsChartsDynamicData(stateName, chartIndex);
      });
    }
  };

  getVendorAnalyticsChartsDynamicData = async (stateName: keyof S, chartIndex: number) => {
    if (stateName === "vendorInvoicesFilteredBars") {
      this.getVendorAnalyticInvoicesDynamicData(chartIndex);
    } else if (stateName === "vendorProjectsFilteredBars") {
      this.getVendorAnalyticsProjectsDynamicData(chartIndex);
    }
  }

  getVendorAnalyticsProjectsDynamicData = async (chartIndex: number) => {
    if (chartIndex === 0)
      this.getVednorAnalyticsOngoingProjectsMessgaeId = await this.apiCall(configJSON.validationApiMethodType, configJSON.viewDetailedAnalyticProjectsAPI + `${this.state.values[this.state.vendorProjectsFilteredBars[0].index].toLocaleLowerCase()}&project_status=${this.state.vendorProjectsFilteredBars[0].name}`);
    else if (chartIndex === 1)
      this.getVednorAnalyticsUpcomingProjectsMessgaeId = await this.apiCall(configJSON.validationApiMethodType, configJSON.viewDetailedAnalyticProjectsAPI + `${this.state.values[this.state.vendorProjectsFilteredBars[1].index].toLocaleLowerCase()}&project_status=${this.state.vendorProjectsFilteredBars[1].name}`);
    else if (chartIndex === 2)
      this.getVednorAnalyticsCompletedProjectsMessgaeId = await this.apiCall(configJSON.validationApiMethodType, configJSON.viewDetailedAnalyticProjectsAPI + `${this.state.values[this.state.vendorProjectsFilteredBars[2].index].toLocaleLowerCase()}&project_status=${this.state.vendorProjectsFilteredBars[2].name}`);
  }

  getVendorAnalyticInvoicesDynamicData = async (chartIndex: number) => {

    if (chartIndex === 0)
      this.getVendorAnalyticsPendingInvoicesMessgaeId = await this.apiCall(configJSON.validationApiMethodType, configJSON.viewDetailedAnalyticInvoicesAPI + `${this.state.values[this.state.vendorInvoicesFilteredBars[0].index].toLocaleLowerCase()}&invoice_status=${this.state.vendorInvoicesFilteredBars[0].name}`);
    else if (chartIndex === 1)
      this.getVendorAnalyticsRejectedInvoicesMessgaeId = await this.apiCall(configJSON.validationApiMethodType, configJSON.viewDetailedAnalyticInvoicesAPI + `${this.state.values[this.state.vendorInvoicesFilteredBars[1].index].toLocaleLowerCase()}&invoice_status=${this.state.vendorInvoicesFilteredBars[1].name}`);
    else if (chartIndex === 2)
      this.getVendorAnalyticsApprovedInvoicesMessgaeId = await this.apiCall(configJSON.validationApiMethodType, configJSON.viewDetailedAnalyticInvoicesAPI + `${this.state.values[this.state.vendorInvoicesFilteredBars[2].index].toLocaleLowerCase()}&invoice_status=${this.state.vendorInvoicesFilteredBars[2].name}`);
    else if (chartIndex === 3)
      this.getVendorAnalyticsCompletedInvoicesMessgaeId = await this.apiCall(configJSON.validationApiMethodType, configJSON.viewDetailedAnalyticInvoicesAPI + `${this.state.values[this.state.vendorInvoicesFilteredBars[3].index].toLocaleLowerCase()}&invoice_status=${this.state.vendorInvoicesFilteredBars[3].name}`);
  }

  vendorDetailedAnalyticsHandleNavigation = (page: string) => {
    this.props.navigation.navigate(page)
  };

  handleVendorDetailedAnalyticsCloseNotification = () => {
    this.setState({ vendorDetailedAnalyticsNotification: { type: "", open: false, message: "" } });
  }
  // Customizable Area End
}
