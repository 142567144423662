import React from "react";

// Customizable Area Start
import {
  InputAdornment,
  Grid,
  withStyles,
  Typography,
  TextField,
  styled
} from "@material-ui/core";
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import "./emailAccountLogin.css";

export let buttonHeight = window.innerWidth > 1440 ? "150px" : '52px';
export let emailInputHeight = window.innerWidth > 1440 ? "150px" : '52px';
export let fontSize = window.innerWidth > 1440 ? "40px" : "14px";
export let borderRadius = window.innerWidth > 1440 ? "24px" : "14px";

// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

// Customizable Area Start

export const configJSON = require("./config");
import { CustomInputlabelEmail, SignInInputLabel, CustomTextField, CustomButton } from "./CustomButton";
import CustomNotification from "../../../components/src/CustomNotification";

const CustomButtonBox = styled(CustomButton)({
  '& .MuiButton-label': {
    fontWeight: 600,
    fontSize: 16,
    color: '#ffffff',
  }
});
const CustomTextFieldpassword = withStyles({
  root: {

    backgroundColor: 'white',
    borderColor: "#f5c42c",

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#E3EAF4",
      },
      "&:hover fieldset": {
        borderColor: "#f5c42c",
      },
      "&.Mui-focused fieldset": {
        backgroundColor: 'rgba(245,196,44,0.04)',
        borderColor: "#f5c42c",

      },
      fontWeight: 400,
      borderRadius: "14px",
      color: '#323232',
      "& .MuiOutlinedInput-input": {
        fontWeight: 500,
        borderColor: '#e3eaf4',
        fontSize: '16px',
        lineHeight: '19px',
        color: '#17181D',
        fontFamily: 'Urbanist',
      }
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#f5c42c"
    },
    "& .MuiInputBase-input::placeholder": {
      fontWeight: 500,
      fontFamily: 'Urbanist',
      color: '#6d89af',

    },
  }
})(CustomTextField)

export const CssTextField = withStyles({
  root: {
    borderColor: "#f5c42c",


    "& .MuiInput-underline:after": {
      borderBottomColor: "#f5c42c"
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#E3EAF4",

      },
      "&:hover fieldset": {
        borderColor: "#f5c42c",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#f5c42c",
        backgroundColor: 'rgba(245,196,44,0.04)',
      },
      borderRadius: "14px",
      fontWeight: 400,
      color: '#323232',
      "& .MuiOutlinedInput-input": {
        borderColor: '#e3eaf4',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '19px',
        color: '#17181D',
        fontFamily: 'Urbanist',
      }
    },
    "& .MuiInputBase-input::placeholder": {
      color: '#6d89af',
      fontWeight: 500
    },
  }
})(TextField);
// Customizable Area End

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      // Customizable Area Start
      <>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomInputlabelEmail className="email_lable" id="emailname" style={{ fontSize: "14px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px", paddingTop: '11px' }}>{configJSON.emailText}</CustomInputlabelEmail>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CssTextField data-test-id="email_id" type="text" id="emailname" onChange={(e: any) => { this.setEmail(e) }} variant="outlined" className="email_input" placeholder="Enter your email ID" style={{
              color: "red"
            }} onFocus={() => {
              this.handleInputFocus("emailname");
            }}
              onBlur={() => {
                this.handleInputBlur("emailname");
              }} />
            {this.state.emailError && <Typography style={{ color: 'red' }}>{configJSON.emailTextError}</Typography>}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} className="password_grid">
            <SignInInputLabel style={{ color: "#6D89AF", fontSize: "14px", lineHeight: "18px", fontWeight: 600, paddingBottom: '6px', paddingTop: "11px" }} id="password">{configJSON.placeHolderPassword}</SignInInputLabel>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomTextFieldpassword type={this.state.visibiltyOfPassword ? 'text' : "password"} id="outlined-basic" variant="outlined" placeholder="Enter your password"
              InputProps={{
                autoComplete: 'new-password',
                endAdornment: (
                  <InputAdornment position="end" className="email_input_adornment_box">
                    <span data-test-id="passwordChange" onClick={this.handleClickShowPassword} style={{ cursor: 'pointer' }}>{this.state.visibiltyOfPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}</span>
                  </InputAdornment>
                ),
              }}
              value={this.state.passwordLogin}
              data-test-id="password"
              onChange={(e: any) => this.setpasswordOfEmail(e)}
              className="email_input"
              onFocus={() => {
                this.handleInputFocus("password");
              }}
              onBlur={() => {
                this.handleInputBlur("password");
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} className="sign_in_main_grid">
            <Grid item xs={7} sm={7} md={7} lg={7} xl={5}>
              <a href="/ForgotPassword" className="recover_password_link">{configJSON.recoverMsg}</a>
            </Grid>
            <Grid item xs={5} sm={5} md={5} lg={5} xl={5} className="button_box">
              <CustomButtonBox data-test-id="sign_in_button" className="button" onClick={this.doEmailLogIn}>
                {configJSON.signInText}
              </CustomButtonBox>
            </Grid>
          </Grid>
        </Grid>
        <CustomNotification navigation={(route: string) => this.props.navigation.navigate(route)} notification={this.state.emailLoginNotification} handleCloseNotification={this.handleEmailLoginCloseNotification} />
      </>
      // Customizable Area End
    );
  }
}
