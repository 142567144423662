import React from "react";
import {
    // Customizable Area Start
    Box,Typography, makeStyles, Link,
    List,
    ListItem,
    // Customizable Area End
} from "@material-ui/core";
import parse from 'html-react-parser';

interface MyProps {
  data: any;
}
const useStyles = makeStyles(() => ({
  vendorCardContentWrap: {
    padding: "20px",
    boxSizing: "border-box",
    border: "1px solid #E1E6EC",
    backgroundColor: "#fff",
    borderRadius: "14px",
    margin: "12px",
    minWidth: "80%",
    "& h5": {
      fontSize: "20px",
      fontWeight: "bold",
      fontFamily: "Urbanist",
      margin: "15px 0",
    },
    "& h6": {
      fontSize: "14px",
      padding:  "5px 0",
      fontFamily: "Urbanist",
      margin: 0,
      fontWeight: 400,
      lineHeight: '1.75',
      letterSpacing: '0.00938em',
    },
    "& ul": {
      paddingLeft: 0,
      margin: 0,
    },
    "& li": {
      fontSize: "14px",
      lineHeight: "1.75",
      padding:  "5px 0",
      fontFamily: "Urbanist",
      margin: "0 16px",
    }
  }
}));

export default function PrivacyPolicyContentData(props: MyProps) {
    const classes = useStyles();

  return (
    <Box className={classes.vendorCardContentWrap}>
      {parse(props.data)}
    </Box>
  );
}
