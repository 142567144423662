import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isStudioBookingDetailSideBarOpen: boolean;
  isLoading: boolean;
  isDropdownOpen: boolean;
  studioDetailsData: any;
  studioDetailsDataid:any;
  studioDetailReviewData: any;
  studioDetailTabValue: number;
  isModelOpen: boolean;
  studioBookingId: string;
  modelType: string;
  notification: { type: string, open: boolean, message: string, route?: string };
  OngoingStudioListData:any;
  UpcomingStudioListData:any;
  studioid:any
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class StudioDetailPHController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getStudioDetailsMessageId: string = "";
  getStudioDetailReviewDataMessageId: string = "";
  getOngoingrequestStudiocallid:string="";
  getUpcomingrequestStudiocallid:string="";
  
  bookStudioMessageId: string = "";
  giveRatingMessageId: string = "";
  cancelbookingcallid:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isStudioBookingDetailSideBarOpen: true,
      isLoading: false,
      isDropdownOpen: false,
      studioDetailsData: {},
      studioDetailReviewData: {},
      studioDetailTabValue: 0,
      isModelOpen: false,
      studioBookingId: "",
      modelType: "",
      notification: { type: "", open: false, message: "" },
      studioDetailsDataid:[],
      OngoingStudioListData:[],
      UpcomingStudioListData:[],
      studioid:null
      // Customizable Area End
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const studioDetailApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const studioDetailResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.getStudioDetailsMessageId === studioDetailApiRequestCallId) {
        this.getStudioDetailsResponse(studioDetailResponseJson);
      } else if (this.getStudioDetailReviewDataMessageId === studioDetailApiRequestCallId) {
        this.getStudioDetailReviewResponse(studioDetailResponseJson);
      } 
      this.apiCallResponseValue(studioDetailApiRequestCallId,studioDetailResponseJson)
    }
    // Customizable Area End
  }

  // Customizable Area Start
  apiCallResponseValue=async(studioDetailApiRequestCallId:any,studioDetailResponseJson:any)=>{
     if (this.bookStudioMessageId === studioDetailApiRequestCallId) {
      this.bookStudioResponse(studioDetailResponseJson);
    } else if (this.giveRatingMessageId === studioDetailApiRequestCallId) {
      this.giveRatingResponse(studioDetailResponseJson);
    }
     else if(this.cancelbookingcallid===studioDetailApiRequestCallId)
     {
      this.props.navigation.navigate("AllStudioBookingListPH")
      this.setState({isLoading:false})
     }
   else  if (this.getOngoingrequestStudiocallid === studioDetailApiRequestCallId) {
      this.setState({ OngoingStudioListData: studioDetailResponseJson.data })
      
  }
 else if (this.getUpcomingrequestStudiocallid === studioDetailApiRequestCallId) {
      this.setState({ UpcomingStudioListData: studioDetailResponseJson.data })
      
  }
  }
  getStudioDetailsResponse = (studioBookingDetailResponseJson: { errors: { token: string }[], data: any }) => {
    if (studioBookingDetailResponseJson.data) {
      this.setState({ studioDetailsData: studioBookingDetailResponseJson.data.attributes ,studioDetailsDataid:studioBookingDetailResponseJson.data});
    } else if (studioBookingDetailResponseJson.errors[0].token) {
      this.setState({ notification: { open: true, type: "error", message: studioBookingDetailResponseJson.errors[0].token, route: "LandingPage" } });
    }
    this.setState({ isLoading: false });
  }

  getStudioDetailReviewResponse = (studioBookingDetailResponseJson: { errors: { token: string }[], average_rating: number, rating_star: any, review_rating_count: string, reviews: { data: any[] } }) => {
    if (studioBookingDetailResponseJson.reviews?.data) {
      this.setState({ studioDetailReviewData: studioBookingDetailResponseJson });
    } else if (studioBookingDetailResponseJson.errors[0].token) {
      this.setState({ notification: { open: true, type: "error", message: studioBookingDetailResponseJson.errors[0].token, route: "LandingPage" } });
    }
    this.setState({ isLoading: false });
  }

  bookStudioResponse = (studioBookingDetailResponseJson: { errors: any, data: any }) => {
    if (studioBookingDetailResponseJson.data) {
      this.handleModelOpenStudioDetailPH();
      this.setState({ modelType: "successModel" });
    } 
     else if (studioBookingDetailResponseJson.errors.length && studioBookingDetailResponseJson.errors[0].token) {
      this.setState({ notification: { open: true, type: "error", message: studioBookingDetailResponseJson.errors[0].token, route: "LandingPage" } });
    }
    else if (typeof (studioBookingDetailResponseJson.errors.message) === "string") {
      this.setState({ notification: { open: true, type: "error", message: studioBookingDetailResponseJson.errors.message } });}
    this.setState({ isLoading: false });
  }

  giveRatingResponse = async (studioBookingDetailResponseJson: { errors: { token: string }[], data: any }) => {
    if (studioBookingDetailResponseJson.data) {
      this.handleModelClose();
      this.getStudioDetailReviewDataMessageId = await this.apiCallStudioDetailPH(configJSON.validationApiMethodType, configJSON.getStudioDetailReviewDataAPIEndPoint + this.state.studioBookingId);
    } else if (studioBookingDetailResponseJson.errors[0].token) {
      this.setState({ notification: { open: true, type: "error", message: studioBookingDetailResponseJson.errors[0].token, route: "LandingPage" } });
    }
    this.setState({ isLoading: false });
  }

  handleStudioDetailPHPageSideBar = () => {
    setStorageData("sidebarvalue", (!this.state.isStudioBookingDetailSideBarOpen).toString())
    this.setState({
      isStudioBookingDetailSideBarOpen: !this.state.isStudioBookingDetailSideBarOpen
    });
  };

  handleDropdownStudioDetailPH = () => {
    this.setState({
      isDropdownOpen: !this.state.isDropdownOpen
    });
  };

  handleNavigation = (page: string) => {
    this.props.navigation.navigate(page)
  };

  async componentDidMount() {
    const studioId = await getStorageData("detailstudioid");
   const bookingid= await getStorageData("bookingid")
    this.setState({
      studioid:bookingid
    })
    console.log("studioid......",this.state.studioid)
    const isSideBarOpen = await getStorageData("sidebarvalue");
    this.setState({ studioBookingId: studioId, isStudioBookingDetailSideBarOpen: JSON.parse(isSideBarOpen) });
    this.getStudioDetailsMessageId = await this.apiCallStudioDetailPH(configJSON.validationApiMethodType, configJSON.getStudioDetailsAPIEndPoint + studioId);
    this.getStudioDetailReviewDataMessageId = await this.apiCallStudioDetailPH(configJSON.validationApiMethodType, configJSON.getStudioDetailReviewDataAPIEndPoint + studioId);
      this.getOngoingRequestStudioList();
      this.getUpcomingRequestStudioList()
  }

  apiCallStudioDetailPH = async (studioBookingDetailMethod: string, studioBookingDetailEndPoint: string, studioBookingDetailBodyData?: any) => {
    const authToken = await getStorageData("token");
    const studioBookingDetailHeader = {
      "Content-Type": configJSON.contentTypeApiUpdateUser,
      token: authToken
    };
    const studioBookingDetailRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setState({ isLoading: true });
    studioBookingDetailRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      studioBookingDetailEndPoint
    );
    studioBookingDetailRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(studioBookingDetailHeader)
    );
    studioBookingDetailBodyData && studioBookingDetailRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(studioBookingDetailBodyData)
    );
    studioBookingDetailRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      studioBookingDetailMethod
    );
    runEngine.sendMessage(studioBookingDetailRequestMessage.id, studioBookingDetailRequestMessage);
    return studioBookingDetailRequestMessage.messageId;
  }

  handleModelOpenStudioDetailPH = () => {
    this.setState({ isModelOpen: true });
  }

  handleModelClose = () => {
    this.setState({ isModelOpen: false, modelType: "" });
  }

  handleTabValueChange = (tabVal: number) => {
    this.setState({ studioDetailTabValue: tabVal });
  }

  handleBookStudioModelOpen = () => {
    this.handleModelOpenStudioDetailPH();
  }

  sendBookingRequest = async (bookingData: any) => {
    this.bookStudioMessageId = await this.apiCallStudioDetailPH(configJSON.manageprofilemethodtype, configJSON.bookStudioAPIEndPoint, bookingData);
  }

  handleRatingModelOpen = () => {
    this.handleModelOpenStudioDetailPH();
    this.setState({ modelType: "ratingModel" });
  }

  handleGiveRatingAPICall = async (ratingData: any) => {
    if (!ratingData.review.given_star) {
      this.setState({ notification: { open: true, message: "Please add rating", type: "error" } });
      return false;
    } else if (!ratingData.review.comment) {
      this.setState({ notification: { open: true, message: "Please add review", type: "error" } });
      return false;
    }
    this.giveRatingMessageId = await this.apiCallStudioDetailPH(configJSON.manageprofilemethodtype, configJSON.giveRatingAPIEndPoint, ratingData);
  }

  handleCloseNotification = () => {
    this.setState({ notification: { type: "", open: false, message: "" } });
  }

  getStatusBG = (status: string, type: "bg" | "wid") => {
    if (status === "Available") {
      return type == "bg" ? "#3389ff" : "73px"
    } else if (status === "Not Available") {
      return type == "bg" ? "#000000" : "92px"
    } else if (status === "Booked") {
      return type == "bg" ? "#f5c42c" : "64px"
    }
  }
  cancelbooking = async(id:any) => {
    let token = await getStorageData("token");
    let bookingid=await getStorageData('bookingid')
    const header = {
      "Content-Type": "application/json",
      "token": token
    };
    const httpBody = {
        "data": {
            "attributes": {
                "status": "cancelled"
            }
        }
    };
    this.setState({isLoading:true})
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.cancelbookingcallid = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.cancelbookingApiEndPoint+`/${bookingid}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updatePasswordAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
      }
      handleNavigationRequestProfile= async (id:any,studioid:any,profileid:any,status:any)=>{

        setStorageData("dataid", id)
        setStorageData("statusrequest",status)
       await setStorageData("studioid",studioid)
     
       console.log("studioid...",studioid)
       setStorageData("profileid",profileid)
       
      
          this.props.navigation.navigate("RequestProfileDetailPH");
        
      }

      getOngoingRequestStudioList = async () => {
        this.getOngoingrequestStudiocallid = await this.apiCallStudioDetailPH(configJSON.validationApiMethodType, configJSON.getongingStudioListPHEndPoint+`?per_page=9&page=1`);
    };
    getUpcomingRequestStudioList = async () => {
        this.getUpcomingrequestStudiocallid = await this.apiCallStudioDetailPH(configJSON.validationApiMethodType, configJSON.getupcomingStudioListPHEndPoint+`?per_page=9&page=1`);
    };
   formatDate = (dateString:any) => {
      const options = { day: 'numeric', month: 'short', year: 'numeric' };
      const date = new Date(dateString);
      return date.toLocaleDateString('en-US', options as any);
    };
  // Customizable Area End
}
