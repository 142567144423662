import React from "react";

// Customizable Area Start
import {
  Container,
  createTheme,
  Grid,
  ThemeProvider
} from "@material-ui/core";
import { webStyle } from "../../fullteamvisibility/src/FullTeamVisibility.web";
import Header from "../../../components/src/Header.web";
import SideBar from "../../../components/src/SideBar";
import Footer from "../../../components/src/Footer";
import ManageProfile from "./ManageProfile.web";
const theme = createTheme({
  palette: {
    primary: {
      main: "#F5C42C",
      contrastText: "#fff"
    }
  },
  overrides: {
    MuiSelect: {
      select: {
        "&:focus": {
          border: "1px solid #F5C42C",
        }
      }
    }
  }
});
// Customizable Area End

import AccountGroupsController, {
  configJSON,
  Props,
} from "./AccountGroupsController";

export default class AccountGroups extends AccountGroupsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderMainContentAllVendor(sideBarStatus: boolean) {
    return <Grid style={webStyle.contentSetup}>
      < Header
        sideBarStatus={sideBarStatus}
        dropDownStatus={this.state.isDropdownOpenBlock}
        handleSideBar={this.handleSideBarisDropdownOpenBlock}
        handleDropdown={this.handleDropdownBlock}
        navigate={this.props.navigation.navigate}
      />
      <Grid style={webStyle.mainContent as React.CSSProperties}>
        <ManageProfile issidebaropenblock={this.state.issidebaropenblock} handleDropdownBlock={this.handleDropdownBlock} isDropdownOpenBlock={this.state.isDropdownOpenBlock} handleSideBarisDropdownOpenBlock={this.handleSideBarisDropdownOpenBlock} navigation={this.props.navigation} id="" />
      </Grid>
      <Footer />
    </Grid >
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <Container style={webStyle.parentDiv}>
          {
            this.state.issidebaropenblock ?
              <Grid container spacing={0}>
                <Grid item xs={3} style={webStyle.sidebarParent}>
                  <SideBar
                    navigate={this.props.navigation.navigate}
                    userData={{ age: 25 }}
                    activeMenuItem={"Blocked Profiles"}
                    handleSideBar={this.handleSideBarisDropdownOpenBlock} />
                </Grid>
                <Grid item xs={9} style={{ maxWidth: "77%", flexBasis: "77%" }}>{this.renderMainContentAllVendor(this.state.issidebaropenblock)}</Grid>
              </Grid> :
              <Grid item xs={12}>
                {this.renderMainContentAllVendor(this.state.issidebaropenblock)}
              </Grid>
          }
        </Container>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End
