import React from "react";

import {
  Button,
  Typography,
  // Customizable Area Start
  Grid,
  ImageListItem,
  ImageList,
  Box,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { backIcon } from "./assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 800,
    },
  },
});
// Customizable Area End

import ProjectPortfolioDetailController, {
  Props,
  configJSON,
} from "./ProjectPortfolioDetailController";
import Loader from "../../../components/src/Loader";

export default class ProjectPortfolioDetail extends ProjectPortfolioDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Loader loading={this.state.loading} />
        <Grid container>
          <Grid>
            <div style={webStyle.headingLeft}>
              <Button
                id="back-positioned-button"
                data-test-id="btnBack"
                onClick={() => this.goBack()}
              >
                <img
                  src={backIcon}
                  alt=""
                  style={webStyle.backIcon}
                />
              </Button>
              <Typography variant="h5" style={webStyle.titleText}>
                {configJSON.labelViewProjectText}
              </Typography>
            </div>
            <Typography style={webStyle.titleText}>
              {configJSON.labelsCreateFormFields.projectName.label} - {this.state.projectName}
            </Typography>
            <Typography style={webStyle.titleText}>
              {configJSON.labelsCreateFormFields.projectDescription.label} - {this.state.projectDescription}
            </Typography>
            <Typography style={webStyle.titleText}>
              {configJSON.labelsCreateFormFields.projectUrl.label} - {this.state.projectUrl}
            </Typography>
            <Typography style={webStyle.titleText}>
              {configJSON.labelsCreateFormFields.phoneNumber.label} - {this.state.phoneNumber}
            </Typography>
            <Typography style={webStyle.titleText}>
              {configJSON.labelsCreateFormFields.clientDetails.label} - {this.state.clientDetails}
            </Typography>
            <Typography style={webStyle.titleText}>
              {configJSON.labelsCreateFormFields.developerEmail.label} - {this.state.email}
            </Typography>
            <Typography style={webStyle.titleText}>
              {configJSON.labelsCreateFormFields.developerFullName.label} - {this.state.fullName}
            </Typography>
            <Typography style={webStyle.titleText}>
              {configJSON.labelsCreateFormFields.teamSize.label} - {this.state.teamSize}
            </Typography>
            <Typography style={webStyle.titleText}>
              {configJSON.labelsCreateFormFields.startDate.label} - {this.state.startDate}
            </Typography>
            <Typography style={webStyle.titleText}>
              {configJSON.labelsCreateFormFields.endDate.label} - {this.state.endDate}
            </Typography>
            <Typography style={webStyle.titleText}>
              {configJSON.labelsCreateFormFields.projectImages.label} -
              <ImageList cols={4} rowHeight={100}>
                {this.state.images.map((item, index) => (
                  <ImageListItem style={{ width: 100 }} key={item.attributes.public_url + index}>
                    <img
                      src={item.attributes.public_url} alt="not fount" width={"100px"}
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            </Typography>
            <Typography style={webStyle.titleText}>
              {configJSON.labelsCreateFormFields.projectDocuments.label} -
              <Box style={{ width: '100%' }}>
                {this.state.documents.map((item, index) => (
                  <div key={item.attributes.file_name + index} style={{ display: 'flex' }}>
                    <div>
                      {item.attributes.file_name}
                    </div>
                  </div>
                ))}
              </Box>
            </Typography>
          </Grid>
        </Grid>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "60%",
    height: "45px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "25%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  rightContent: {
    width: "100%",
    borderRadius: 12,
  },
  headingCont: {
    width: "90%",
    margin: "auto",
    padding: "20px 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  headingLeft: {
    width: "100%",
    margin: "auto",
    display: "flex",
    alignItems: "center",
  },
  titleText: {
    fontSize: 22,
    fontFamily: 'sofia_pro_regular',
    color: "#1e1f20",
  },
  backIcon: {
    width: 40,
    height: 40,
    marginLeft: 15,
    cursor: "pointer",
  },
  projectContainer: {
    margin: "10px auto",
  },
  eachProjectDetails: {
    marginTop: 20,
    padding: 10,
  },
  editButton: {
    background: "rgb(98, 0, 238)",
    display: "flex",
    padding: "5px",
    fontSize: "14px",
    alignItems: "center",
    fontFamily: 'sofia_pro_regular',
    marginRight: "5px",
    borderRadius: "4px",
    color: "white",
    border: "none",
  },
};
// Customizable Area End
