import React from "react";
import { InputLabel,Typography, Dialog, DialogActions, Switch, DialogTitle, styled, Box, TextField, withStyles, Grid, DialogContent, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { uploadProjectIcon, keyIcon,whiteCrossIcon } from "./assets";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { CustomButton } from "../../blocks/email-account-login/src/CustomButton";
import Select from "react-select";
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector.web";



const CustomInputlabel = withStyles({
    root: {
        fontFamily: 'Urbanist',
        color: '#f5c42c',
        fontWeight: 600,
        "@media (max-width : 1440px)": {
            fontSize: '14px'
        },
        "@media (min-width : 1441px)": {
            fontSize: '14px',
            lineHeight: "14px",
            paddingBottom: "6px",
            paddingTop: "14px"

        },
    },
})(InputLabel);

const CustomCSSOfTextField = withStyles({
    root: {
        width: "100%",
        backgroundColor: 'white',
        borderColor: "#f5c42c",
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#E3EAF4",
            },
            "&:hover fieldset": {
                borderColor: "#f5c42c"
            },
            "&.Mui-focused fieldset": {
                borderColor: "#f5c42c",
                backgroundColor: 'rgba(245,196,44,0.04)',
            },
            fontWeight: 400,
            color: '#323232',
            borderRadius: "14px",
            "& .MuiOutlinedInput-input": {
                fontWeight: 600,
                lineHeight: '19px',
                borderColor: '#CCCCCC',
                fontSize: '14px',
            }
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#f5c42c"
        },
        "& .MuiInputBase-input::placeholder": {
            color: '#6d89af',
            fontWeight: 500,
            fontFamily: 'Urbanist',
        },
        '& .MuiInputBase-input[type="date"]': {
            textTransform: "uppercase",
            fontWeight: 500,
            color: "#6D89AF",
            '&::after': {
                color: 'red',
            },
        },
        '& .MuiOutlinedInput-inputAdornedStart': {
            paddingLeft: '37px'
        }
    }
})(TextField);

const CustomNextStepButtonBox = styled(CustomButton)({
    '& .MuiButton-label': {
        fontSize: 16,
        color: '#ffffff',
        fontWeight: 600,
        width: 163,
        height: 48,
        fontFamily:'urbanist',
    
        
    }
});
const CustomReactSelect = styled(Select)({
    '& .react-select__control': {
        borderRadius: "14px",
        height: "52px",
    },
    '& .react-select__control:hover': {
        borderColor: "#f5c42c"
    },
    '& .react-select__control--menu-is-open': {
        borderColor: "#f5c42c"
    },
    '& .react-select__placeholder': {
        color: "#6D89AF",

        fontFamily: "Urbanist",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "18px",
        letterSpacing: "0.2px",
    },
    '& .react-select__control--is-focused':{
        outline: "none !important",
        boxShadow:"none !important",
        border: "1px solid #F5C42C",
    },
});

const CustomSwitch = withStyles((theme) => ({
    root: {
        width: 28,
        height: 16,
        padding: 0,
        display: "flex",
        borderRadius: "24px",
    },
    switchBase: {
        padding: 2,
        color: "white",

        "&$checked": {
            transform: "translateX(12px)",
            color: theme.palette.common.white,
            "& + $track": {
                opacity: 1,
                backgroundColor: "#F5C42C",
                borderColor: "#F5C42C",
            },
        },
    },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: "none",
    },
    track: {
        border: `0px solid #F5C42C`,
        borderRadius: "14px",
        opacity: 1,
        backgroundColor: "#6D89AF",
    },
    checked: {},
}))(Switch);
const useStyles = makeStyles({
    permissionDiv: {
        boxSizing: "border-box",
        height: "48px",
        padding: "12px",
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        borderRadius: "14px",
    },
    dialogParent: { width: '720px', height:'702px', margin: "0px auto" },
    invoiceDiv: { borderRadius: "14px", border: "0.75px dashed #F5C42C",width:"120px",margin:"auto",padding:"10px 0px", height: "72px", marginTop: "22px", display: "flex", gap: "16px", alignItems: "center", justifyContent: "center" },
    invoiceLabel: { display: "flex", gap: "16px", alignItems: "center", justifyContent: "center" },
    customLabel: { fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px", paddingTop: '16px' },
    imagePDiv: { display: "flex", justifyContent: "space-between", width: "32%", padding: "12px", alignItems: "center", margin: "auto" },
    seperator: { border: "1px solid #E3EAF4", width: "100%", margin: "22px 0px 5px 0px" },
    permissionsDiv: { cursor: "pointer", borderRadius: "14px", marginTop: "22px", display: "flex", flexDirection: "column", border: "1px solid #17181D", padding: "15px" },
    permissionContainer: { width: "100%", display: "flex", justifyContent: "space-between" },
    permissionListDiv: { display: "flex", alignItems: "center", gap: "7px", backgroundColor: "#E3EAF4", borderRadius: "12px", padding: "5px 8px", color: "#6D89AF" },
    dialogActionBtn: { height: 48, width: "160px", float: "right" },
    projectDiv:{
        borderRadius:"10px",
        background:"rgb(255, 240, 194)",
        display: "flex",
        alignItems: "center",
        padding: "10px",
        width:"40px",
        margin:"auto",
        marginBottom:"10px",
        justifyContent:"center"
    },

});
interface IProps{
    addNewMemberdepartmentList:any[];
    rolesList:any[];
    handleChangePermission:(permissionId:string|number)=>void;
    permssionsList:any[];
    handleSelectChange:(selectedOption: any, fieldName: string)=>void;
    handleSubmit:()=>void;
  createJobRole :()=>void;
    addNewMemberDetails:any;
    handleInputChange:(e:any)=>void;
    handleCancelPermission:(permissionId:string)=>void;
    imagePreviewUrl:string;
    handleFileChange:(event:any)=>void;
    addNewMemberModal:boolean;
    handleAddNewMemberModal:()=>void;
    addNewMemberModaltab:string|number;
    AddNewMemberModaltabPrevious:()=>void;
    AddNewMemberModaltabNext:()=>void;
    handleSavePermissions:()=>void
    countryCodeSelected:any
}

export default function ProductionHouseAddNewMemberDialog(props: IProps) {
    const classes = useStyles();

    return (
        <>
            <Dialog
                // @ts-ignore
                maxWidth={"720px"}
                className={classes.dialogParent}
                fullWidth={true}
                open={props.addNewMemberModal}
                onClose={props.handleAddNewMemberModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Box style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>{props.addNewMemberModaltab == 2 && <ArrowBackIcon onClick={props.AddNewMemberModaltabPrevious} />}</span>
                        {props.addNewMemberModaltab == 1 ? <span style={{fontWeight:600}}>Add New Member</span> : <span style={{fontWeight:700,fontFamily:'urbanist',fontSize:'20px',color:'#17181D',}}>Permissions</span>}
                        <span onClick={props.handleAddNewMemberModal}><CloseIcon /></span>
                    </Box>

                </DialogTitle>
                <DialogContent>
                    {props.addNewMemberModaltab == 1 &&
                        <Grid container md={12}>
                            <Grid item md={12}>
                                <Box className={classes.invoiceDiv}>
                                    {!props.imagePreviewUrl ? <label className={classes.invoiceLabel} htmlFor="invoiceId">
                                        <input id="invoiceId"
                                            type="file" onChange={props.handleFileChange} accept="image/*" style={{ display: "none" }} />
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <div className={classes.projectDiv}>
                                                <img
                                                    data-test-id="invoiceReId"
                                                    height={30} width={30} style={{ borderRadius: "50%"}} src={uploadProjectIcon} alt={"uploadIcon"} />
                                            </div>
                                            <span style={{ fontWeight: 600, fontSize: "14px" }}>Upload Image</span>
                                        </div>
                                    </label> : <div className={classes.imagePDiv}>
                                        <div style={{ display: "flex", gap: "13px", alignItems: "center" }}>
                                            <img src={props.imagePreviewUrl} height={48} width={48} style={{ borderRadius: "8px" }} />
                                        </div>
                                    </div>}
                                </Box>

                            </Grid>
                            <Grid item container md={12} spacing={2}>
                                <Grid item md={6}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <CustomInputlabel id="nameOfPH"className={classes.customLabel}>Full Name</CustomInputlabel>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <CustomCSSOfTextField
                                            inputProps={{
                                                'aria-label': 'nameOfPHInput',
                                                type: 'text',
                                                placeholder: 'Enter full name',
                                                name: 'fullName',
                                                onChange: props.handleInputChange,
                                                value: props.addNewMemberDetails.fullName.value,
                                                required: true,
                                            }}
                                            variant="outlined"
                                            error={props.addNewMemberDetails.fullName.error}
                                            helperText={
                                                props.addNewMemberDetails.fullName.error && (
                                                    <Box className={"classes.errorBoxStyle"} style={{ display: "flex", gap: "10px" }}>
                                                        <ErrorOutlineOutlinedIcon className={"classes.errorIconStyle"} color="error" />
                                                        <Typography style={{
                                                            color: "#D53333",
                                                            fontFamily: "Poppins",
                                                            fontSize: "16px",
                                                            fontWeight: 400,
                                                        }}>
                                                            {props.addNewMemberDetails.fullName.errorMessage}
                                                        </Typography>
                                                    </Box>
                                                )
                                            }
                                            data-test-id="nameOfPHInput"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item md={6}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <CustomInputlabel id="nameOfPH"className={classes.customLabel}>Phone Number</CustomInputlabel>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <CustomCSSOfTextField
                                         data-test-id="phoneNumberInput"
                                         type={'text'} id="outlined-basic" variant="outlined" placeholder="Enter Phone Number"
                                            InputProps={{
                                           

                                                startAdornment: (
                                                    <InputAdornment position="start" style={{ cursor: 'hand' }} className="phone_input_adornment_box">
                                                        <CountryCodeSelector
                                                            navigation={"navigation"}
                                                            id={"CountryCodeSelector"}
                                                            style={{
                                                                width: "100%",
                                                                option: (provided: any, state: any) => ({
                                                                    ...provided,
                                                                    fontWeight: 600,
                                                                    color: "#17181D",
                                                                    fontSize: "14px",
                                                                }),
                                                                singleValue: (provided: any, state: any) => ({
                                                                    ...provided,
                                                                    color: "#17181D",
                                                                    fontSize: "14px",
                                                                }),
                                                            }}
                                                            disable={false}
                                                            allowPropChange={true}
                                                            
                                                            value={props.countryCodeSelected}
                                                        />
                                                         <Typography style={{ marginLeft: '9px', color: '#17181d' }}> +{props.countryCodeSelected}</Typography> 
                                                         <Box style={{ borderLeft: '1px solid #6d89af', height: '23px', marginLeft: '12px' } as React.CSSProperties}></Box> 
                                                    </InputAdornment>
                                                ),
                                            }}
                                            name="phoneNumber"
                                            value={props.addNewMemberDetails.phoneNumber.value}
                                            onChange={(e: any) => props.handleInputChange(e)}
                                            className="email_input"

                                        />
                                    </Grid>
                                </Grid>

                            </Grid>
                            <Grid item container md={12} spacing={2}>
                                <Grid item md={6}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <CustomInputlabel id="nameOfPH" className={classes.customLabel}>Select Dept.</CustomInputlabel>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <CustomReactSelect
                                    classNamePrefix="react-select"
                                    value={ props.addNewMemberDetails.selectedDept.value}
                                        options={props.addNewMemberdepartmentList}
                                        placeholder={"Select Dept."}
                                        onChange={(e:any)=>props.handleSelectChange(e,"selectedDept")}
                                        components={{
                                            IndicatorSeparator: () => null
                                        }}
                                        name="selectedDept"
                                    />
                                      {
                                      props.addNewMemberDetails.selectedDept.error && (
                                        <Box className={"classes.errorBoxStyle"} style={{display:"flex",gap:"10px"}}>
                                          <ErrorOutlineOutlinedIcon className={"classes.errorIconStyle"} color="error" />
                                          <Typography style={{
                                            color: "#D53333",
                                            fontFamily: "Poppins",
                                            fontSize: "16px",
                                            fontWeight: 400,
                                          }}>
                                            {props.addNewMemberDetails.selectedDept.errorMessage}
                                          </Typography>
                                        </Box>
                                      )
                                    }
                                    </Grid>
                                </Grid>
                                <Grid item md={6}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <CustomInputlabel id="nameOfPH" className={classes.customLabel}>Select Role</CustomInputlabel>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <CustomReactSelect
                                    classNamePrefix="react-select"
                                    value={props.addNewMemberDetails.selectedRole.value}
                                        options={props.rolesList}
                                        placeholder={"Select Role"}
                                        onChange={(e:any)=>props.handleSelectChange(e,"selectedRole")}
                                        components={{
                                            IndicatorSeparator: () => null
                                        }}
                                        name="selectedRole"
                                    />
                                      {
                                      props.addNewMemberDetails.selectedDept.error && (
                                        <Box className={"classes.errorBoxStyle"} style={{display:"flex",gap:"10px"}}>
                                          <ErrorOutlineOutlinedIcon className={"classes.errorIconStyle"} color="error" />
                                          <Typography style={{
                                            color: "#D53333",
                                            fontFamily: "Poppins",
                                            fontSize: "16px",
                                            fontWeight: 400,
                                          }}>
                                            {props.addNewMemberDetails.selectedDept.errorMessage}
                                          </Typography>
                                        </Box>
                                      )
                                    }
                                    </Grid>
                                </Grid>

                            </Grid>
                            <hr className={classes.seperator} />
                            <Grid container item md={12}>
                                <Grid md={12} item className={classes.permissionsDiv}>
                                    <div onClick={props.AddNewMemberModaltabNext} className={classes.permissionContainer}>

                                        <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
                                            <img width={20} height={20} src={keyIcon} alt="keyIcon" />
                                            <span>Give Permissions</span>
                                        </div>
                                        <span><ArrowForwardIcon /></span>
                                    </div>
                                        {(props.permssionsList.some((ele:any)=>ele?.checked==true)) &&  <hr style={{width:"100%",border:"1px solid grey"}} /> }
                                    {props.permssionsList.length > 0 && <div>
                                        <div style={{ display: "flex", gap: "10px", width: "100%", flexWrap: "wrap" }}>

                                            {props.permssionsList.map((permissionObj: any) => {
                                                return permissionObj.checked && <div onClick={() => props.handleCancelPermission(permissionObj.id)} className={classes.permissionListDiv}>
                                                    <span style={{fontSize:"12px",fontWeight:600,color:"#6D89AF"}}>{permissionObj?.attributes?.name}</span> <img src={whiteCrossIcon} width={15} height={15} alt={"whiteCrossIcon"} />
                                                </div>
                                            })}
                                        </div>

                                    </div>}
                                </Grid>
                            </Grid>


                        </Grid>
                    }
                    {
                        props.addNewMemberModaltab === 2 && <>
                            <Grid container md={12} style={{ width: "100%", display: "grid", gridTemplateColumns: " repeat(2, 1fr)", gap: "16px" }} >
                               
                                {props.permssionsList.map((permission: any, index: number) => (
                                    <div className={classes.permissionDiv} key={index} style={{ border: `1px solid ${permission.checked ? "#F5C42C" : "#E3EAF4"}` }}>
                                        <span style={{fontSize:'14px',fontFamily:'urbanist',fontWeight:600,lineHeight:'600px',}}>{permission?.attributes?.name}</span>
                                        <CustomSwitch
                                            name={permission?.attributes?.name}
                                            checked={permission?.checked}
                                            onClick={() => { props.handleChangePermission(permission?.id) }}
                                        />
                                    </div>
                                ))}

                            </Grid>
                        </>

                    }
                </DialogContent>
                <DialogActions style={{ paddingBottom: "24px" }}>
                    {props.addNewMemberModaltab == 1?(<CustomNextStepButtonBox data-test-id="next_button"  className={`${classes.dialogActionBtn} button`} onClick={props.createJobRole}>
                        Save Role
                    </CustomNextStepButtonBox>):
                    (<CustomNextStepButtonBox data-test-id="next_button"  className={`${classes.dialogActionBtn} button`} onClick={props.handleSavePermissions}>
                        Save Permissions
                    </CustomNextStepButtonBox>)}
                </DialogActions>
            </Dialog>
        </>
    );
}
