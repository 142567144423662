import React from "react";

import {
  // Customizable Area Start
  Box,
  Typography,
  Grid,
  Select,
  MenuItem,
  Checkbox,
  Input,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import "./forecastingandbudgeting.css";
import SummaryCreateController, { Props } from "./SummaryCreateController";
import { CustomInputlabelEmail } from "../../email-account-login/src/CustomButton";
import Loader from "../../../components/src/Loader.web";
import CustomNotification from "../../../components/src/CustomNotification";
const images = require("./assets");

// Customizable Area End

export default class SummaryCreate extends SummaryCreateController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderSummaryViewBox = (data: any, index: number) => {
    return (
      <Grid data-test-id={`editSummary_${data.id}`} onClick={() => this.handleOpenEditSummary(data.id)} key={`${index + data.id}_summary_box`} container style={webStyle.mainBox}>
        <Grid item xs={10}>
          <Typography style={{ lineHeight: '18px', color: '#17181d', fontFamily: 'Urbanist', fontSize: 14, fontWeight: 600 }}>{data.attributes.summary_name}</Typography>
          <Typography style={{ lineHeight: '18px', color: '#6d89af', fontFamily: 'Urbanist', fontSize: 12, fontWeight: 600 }}>{data.attributes.detailed.project_department_name.join(", ")}</Typography>
          <Typography style={{ lineHeight: '18px', color: '#6d89af', fontFamily: 'Urbanist', fontSize: 12, fontWeight: 600 }}>{data.attributes.detailed.project_categories_name.join(", ")}</Typography>
        </Grid>
        <Grid item xs={2} style={{ textAlign: 'end' }}>
          <div style={{ lineHeight: '19px', color: '#17181d', fontWeight: 400, fontFamily: 'Helvetica-Bold', fontSize: 16 } as React.CSSProperties}>₹{data.attributes.detailed.budget_total}</div>
        </Grid>
      </Grid>
    )
  }
  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <>
        <Loader loading={this.state.isLoading}></Loader>
        <Grid container style={{ marginTop: 8 }}>
          <Box style={{ width: '100%' }}>
            <Box>
              <Typography style={webStyle.createSummaryTitle as React.CSSProperties}>Create Summary <span style={{ color: '#e3eaf4' }}>----------------------------------------------------------------</span></Typography>
            </Box>
            {!this.state.summaryData.length && !this.state.addEditRow ? <Box style={{ textAlign: 'center', display: 'flex', alignContent: 'center', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
              <Box style={{ textAlign: 'center', width: 324, padding: '31px 0px 13px 0px' }}>
                <img src={`${images.microsoftExcelIcon}`} />
                <Typography style={webStyle.createSummaryTitle as React.CSSProperties} >Create a budget summary</Typography>
                <Typography> <span style={webStyle.createSummarySubTitle as React.CSSProperties}> Create a budget summary by using of adding rows and multiple categories as well </span></Typography>
              </Box>
            </Box> :
              <>
                {this.state.addEditRow
                  ? <Box style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                    <Grid item xs={12} sm={12} md={6} lg={6} style={{ padding: '31px 0' }}>
                      <Box minWidth={'400px'}><Input className="createSummaryInputHead" value={this.state.summaryHeading} onChange={this.handleChangeHeading} placeholder="Enter summary heading" /></Box>
                      {/* <Typography style={{ height: 18, color: '#6d89af', fontFamily: 'Urbanist', fontSize: 12, fontWeight: 600, marginTop: 20 } as React.CSSProperties}>Enter summary heading<span style={{ color: '#e3eaf4' }}>----------------------------------------------</span></Typography> */}
                      <Grid container style={{ border: '1px solid #e3eaf4', borderRadius: '12px', padding: 12, margin: '6px 0px 20px 0px', maxWidth: 400 }}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <CustomInputlabelEmail id="departmentDropdown" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px" }}>Department</CustomInputlabelEmail>
                          </Grid>
                          <Grid className="roleDropdown" item xs={12} sm={12} md={12} lg={12}>
                            <Select
                              data-test-id="dept-select-dropdown"
                              id="demo-simple-select"
                              value={this.state.selectedDepartments}
                              label="Age"
                              multiple
                              onChange={(event) => this.handleSelectDepartment(event)}
                              onFocus={() => {
                                this.handleSummaryInputFocus("departmentDropdown");
                              }}
                              onBlur={() => {
                                this.handleSummaryInputBlur("departmentDropdown");
                              }}
                              renderValue={
                                (selected: any) =>
                                  this.state.departmentsData.filter((name: any) => selected.includes(name.id))
                                    .map((record: any) => record.name)
                                    .join(", ")
                              }
                            >
                              {this.state.departmentsData.map((dept: any) => {
                                return <MenuItem key={dept.is + "departmentsdropdwon"} value={dept.id}>
                                  {dept.name}
                                  <Checkbox checked={this.state.selectedDepartments.indexOf(dept.id) > -1} style={{
                                    color: "#F5C42C"
                                  }} />
                                </MenuItem>
                              })}
                            </Select>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <CustomInputlabelEmail className="email_lable" id="categoryDropdown" style={{ fontSize: "13px", fontFamily: "Urbanist", fontWeight: 600, lineHeight: '18px', color: "#6D89AF", paddingBottom: "6px", paddingTop: '11px' }}>Select category ({this.state.selectedCategories.length})</CustomInputlabelEmail>
                          </Grid>
                          <Grid className="roleDropdown" item xs={12} sm={12} md={12} lg={12}>
                            <Select
                              data-test-id="category-select-dropdown"
                              id="demo-simple-select"
                              value={this.state.selectedCategories}
                              label="Age"
                              multiple
                              onChange={this.handleSelectCategory}
                              onFocus={() => {
                                this.handleSummaryInputFocus("categoryDropdown");
                              }}
                              onBlur={() => {
                                this.handleSummaryInputBlur("categoryDropdown");
                              }}
                              renderValue={
                                (selected: any) =>
                                  this.state.categoriesData.filter((name: any) => selected.includes(name.id))
                                    .map((record: any) => record.name)
                                    .join(", ")
                              }
                            >
                              {this.state.categoriesData.map((dept: any) => {
                                return <MenuItem key={dept.is + "categoriesdropdwon"} value={dept.id}>
                                  {dept.name}
                                  <Checkbox checked={this.state.selectedCategories.indexOf(dept.id) > -1} style={{
                                    color: "#F5C42C"
                                  }} />
                                </MenuItem>
                              })}
                            </Select>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} style={{ minWidth: 398, display: 'flex', gap: 20 }} >
                        <Box data-test-id="backToSummary" onClick={this.handleBackToSummary} style={{ ...webStyle.addRowBtn, border: '1px solid #17181d', background: '#ffffff', width: '50%' }}>
                          <span style={webStyle.addRowBtnText as React.CSSProperties}>Back</span>
                        </Box>
                        <Box data-test-id="saveSummary" onClick={this.handleSaveSummary} style={{ ...webStyle.addRowBtn, border: '1px solid #17181d', background: '#ffffff', width: '50%' }}>
                          <span style={webStyle.addRowBtnText as React.CSSProperties}>Save</span>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  :
                  <Grid container>
                    <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', maxHeight: '403px', overflowX: 'auto' } as React.CSSProperties}>
                      {this.state.summaryData.map((item: any, index: number) => {
                        return this.renderSummaryViewBox(item, index)
                      })}
                    </Box>
                    <Box style={{ display: 'flex', justifyContent: 'center', width: '100%', marginBottom: 8 }}>
                      <Grid container style={webStyle.totalSummaryAmountBox}>
                        <Grid item xs={12} style={{ backgroundBlendMode: 'normal', height: 81, padding: 12, borderRadius: '10px', background: 'rgba(225, 230, 236, 0.3)' } as React.CSSProperties}>
                          <Grid container justifyContent="space-between">
                            <Grid>
                              <Typography style={webStyle.summaryBoxManagePageTotalText}>Contigency</Typography>
                            </Grid>
                            <Grid>
                              <Typography style={{ ...webStyle.summaryBoxManagePageAmount, lineHeight: '18px', letterSpacing: '0.22857143px' }} >Null</Typography>
                            </Grid>
                          </Grid>
                          <Grid item style={{ border: '1px solid #e3eaf4', marginTop: 10, marginBottom: 10 }}></Grid>
                          <Grid container justifyContent="space-between">
                            <Grid>
                              <Typography style={webStyle.summaryBoxManagePageTotalText}>Total</Typography>
                            </Grid>
                            <Grid>
                              <div style={webStyle.summaryBoxManagePageTotalAmount}>₹{this.state.total}</div>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ border: '1px solid #e3eaf4', marginTop: 12, marginBottom: 15 }}></Grid>
                        <Grid item xs={12}>
                          <Typography style={webStyle.managePageGrandTotalText}>Grand Total: <span style={webStyle.managePageGrandTotalAmount}>₹{this.state.grandTotal}</span></Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                }
              </>
            }
            {!this.state.addEditRow && <Box style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: 12 }}>
              <Grid data-test-id="addRowSummary" style={{ ...webStyle.addRowBtn, width: '324px', border: '1px solid #17181d', background: '#ffffff' }} onClick={this.handleAddRow} > <span style={webStyle.addRowBtnText as React.CSSProperties}>+ Add Row</span></Grid>
            </Box>}
          </Box>
        </Grid>
        <CustomNotification navigation={(route: string) => this.props.navigation.navigate(route)} notification={this.state.summaryCreateNotification} handleCloseNotification={this.handleSummaryCreateCloseNotification} />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  managePageGrandTotalAmount: {
    color: '#42a200',
    lineHeight: '24px',
    fontSize: '20px',
    fontFamily: 'Helvetica-Bold',
    letterSpacing: '0.2px',
    marginLeft: 4,
    fontWeight: 400,
  },
  managePageGrandTotalText: {
    color: '#18272a',
    lineHeight: '19px',
    fontSize: '16px',
    fontFamily: 'Urbanist',
    letterSpacing: '0.22857143px',
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  summaryBoxManagePageTotalAmount: {
    color: '#17181d',
    lineHeight: '18px',
    fontSize: 16,
    fontFamily: 'Helvetica-Bold',
    letterSpacing: '0.22857143px',
    fontWeight: 400,
  },
  summaryBoxManagePageAmount: {
    color: '#17181d',
    lineHeight: '19px',
    fontSize: 16,
    fontWeight: 700,
    fontFamily: 'Urbanist',
  },
  summaryBoxManagePageTotalText: {
    color: '#17181d',
    lineHeight: '18px',
    fontSize: 14,
    fontFamily: 'Urbanist',
    letterSpacing: '0.2px',
    fontWeight: 600,
  },
  totalSummaryAmountBox: {
    width: 629,
    height: 158,
    border: '1px solid #e3eaf4',
    borderRadius: 12,
    padding: 12,
    background: '#ffffff',
    boxShadow: '0px -3px 14px rgba(23,24,29,0.04)',
    marginLeft: '-13px',
    marginTop: '20px',
  },
  mainBox: {
    height: 94,
    width: 629,
    borderRadius: 12,
    border: '1px solid #e3eaf4',
    background: '#ffffff',
    padding: 16,
    alignItems: 'center',
    marginTop: 16,
    cursor: 'pointer',
  },
  createSummaryTitle: {
    height: 24,
    color: '#17181d',
    fontFamily: 'Urbanist',
    fontSize: 20,
    fontWeight: 700,
    letterSpacing: '0.22222222px',
    marginTop: 20,
  },
  createSummarySubTitle: {
    height: 34,
    color: '#6d89af',
    fontFamily: 'Urbanist',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '17px',
    letterSpacing: '0.13333334px',
    width: 254,
    display: 'inline-block',
    marginTop: 5,
  },
  addRowBtn: {
    alignItems: 'center',
    display: "flex",
    justifyContent: 'center',
    height: '38px',
    cursor: 'pointer',
    borderRadius: '10px',
  },
  addRowBtnText: {
    fontFamily: 'Urbanist',
    height: 14,
    color: '#17181d',
    fontWeight: 600,
    fontSize: 12,
    letterSpacing: '0.34285715px',
    textAlign: 'center',
  },
};
// Customizable Area End
