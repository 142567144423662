import React, {createRef} from "react";

import {
    // Customizable Area Start
    TypographyProps,
    Container,
    Grid,
    Box,Typography,Link,
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start

import Header from "../../../components/src/Header.web";
import SideBar from "../../../components/src/SideBar";
import Footer from "../../../components/src/Footer";
import Loader from "../../../components/src/Loader.web";
import {  forwardIcon, bagIcon , arrowicon} from "./assets";
import ProjectDetailRequestController,{Props} from "./ProjectDetailRequestController";
// Customizable Area End

export default class ProjectDetailRequest extends ProjectDetailRequestController {
    stepperRef: React.RefObject<any>;
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.stepperRef = createRef();
        // Customizable Area End
    }

    calculateTimeDifference = (timestamp:any) => {
        const currentTime = new Date().getTime();
        const previousTime = new Date(timestamp).getTime();
        const differenceInMilliseconds = currentTime - previousTime;
        const differenceInMinutes = Math.floor(differenceInMilliseconds / (1000 * 60));
        return `${differenceInMinutes} min ago`;
      };
    
      RequestProfileProjectDetail() {
        let projectDetail;
        if (this.state.Status === "ongoing") {
            projectDetail = "Ongoing Projects";
        } else if (this.state.Status === "upcoming") {
            projectDetail = "Upcoming Projects";
        } else {
            projectDetail = "All Projects";
        }
    
        return projectDetail;
    }
    renderJobRequestPage(isJobRoleideBarStatusPH: boolean  = this.state.isJobRoleRequestSideBarOpen) {
        return (
        <Grid style={webStyle.contentSetupAllRequest}>
              <Loader loading={this.state.isLoading}></Loader>
            < Header
                navigate={this.props.navigation.navigate}
                sideBarStatus={isJobRoleideBarStatusPH}
                dropDownStatus={this.state.isJobRoleRequestHeaderDropdownOpenVal}
                handleDropdown={this.handleJobRoleHeaderDropdownVal}
                handleSideBar={this.handleJobRoleSideBar}  
            />
            <Grid style={webStyle.mainContentAllRequest as React.CSSProperties}>
                <Box sx={webStyle.blockInfoJobRoleRequest}>
                    <Box sx={webStyle.headInfoJobRoleRequest}>
                        <Box>
                            <Box sx={webStyle.wrapBlockAllArrowJobRoles}>
                                <Box sx={webStyle.activeBlockAllArrowRequest}>
                                    <div  data-test-id="job-role-page-prev" onClick={()=> this.navigateToAllJobRolePage("ProjectDetailJobRoleandRequest")}> 
                                        <img src={arrowicon} alt="arrow icon" style={webStyle.wrapBlockAllArrowBack}/> 
                                    </div>
                                </Box>
                                <Box sx={webStyle.activeBlockJobRole}>
                                    <Typography variant="h3" style={webStyle.activeBlockJobRole as TypographyProps}><div style={{ fontSize: "15px" }}></div>Requests</Typography>
                                </Box>
                            </Box>    
                            <Box>
                                <Link style={webStyle.highlightedTextJobRoleRequest} data-test-id="job-role-breadcum-home-request" onClick={()=>this.navigateToAllJobRolePage("JobRoleRequest")}>
                                  Home 
                                 </Link> 
                                 <Link style={webStyle.highlightedTextJobRoleRequest} data-test-id="job-role-breadcum-jobRequest-ongoing" onClick={()=>this.navigateToAllJobRolePage("ProjectDetailJobRoleandRequest")}>
                                 / Ongoing projects
                                 </Link> 
                                 <Link style={webStyle.highlightedTextJobRoleRequest} data-test-id="job-role-breadcum-projectName" onClick={()=>this.navigateToAllJobRolePage("ProjectDetailJobRoleandRequest")}>
                                 / {this.state.jobRoleprojectName}
                                 </Link> 
                                <span style={{ color: "#6D89AF",fontWeight:600 ,fontSize: "14px"}}> / All Job role & requests</span>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                 <Box className="tabsContentAllInvoice" style={webStyle.tabsContentAllInvoice as React.CSSProperties}  >
                    <Grid container spacing={4}>
                      {this.state.requestedVendorData.length ? (
                        this.state.requestedVendorData.map((item)=>(
                            <Grid item xs={12} sm={12} md={6} lg={4}>
                            <Box style={webStyle.jobRoleReqWrapperBox}>
                                <Box style={webStyle.jobRoleReqMainBox}>
                                    <Box style={webStyle.jobRoleReqMainBox}>
                                        <Box style={webStyle.jobRoleReqImg}>
                                            <img src={item?.attributes?.account?.data.attributes?.profile_image} alt="Profile" style={webStyle.jobRoleImg}/>
                                        </Box>
                                        <Box>
                                            <Typography variant="h5" style={webStyle.roleReqWrapperHead}>{item?.attributes?.account?.data?.attributes?.full_name} <img src={forwardIcon} alt="More" style={webStyle.roleReqWrapperArrowProfile} onClick={()=>this.navigateToAllJobRolePage("RequestProfile")}/></Typography>              
                                            <Box style={webStyle.roleReqContent}>
                                                <img src={bagIcon} alt="payment" style={webStyle.roleReqContImg}/>
                                                <Typography style={webStyle.roleReqContentPara}>{item?.attributes?.account?.data?.attributes?.role_detail?.name} | {item?.attributes?.account?.data.attributes?.experience}Yrs</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Typography style={webStyle.jobRoleTiming}>
                                        {this.calculateTimeDifference(item?.attributes?.job_request_date)}
                                    </Typography>
                                </Box>
                                <Box style={webStyle.jobRequestInviteVendor}>
                                    <Typography style={webStyle.jobRequestInviteText as React.CSSProperties}  data-test-id="reject" onClick={()=>this.declineVendorRequest(item?.attributes?.id)}>Decline</Typography>
                                    <Box style={webStyle.jobRequestHeadButtonRequest as React.CSSProperties} data-test-id="accept"  onClick={()=>this.acceptVendorRequest(item?.attributes?.id)}>
                                        Accept
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        ))
                      ):(
                        <Typography
                         style={{
                         width: '100%',
                         textAlign: 'center',
                         marginTop: '30px',
                         marginBottom: '30px',
                         color: '#6d89af',
                         fontFamily: 'Urbanist',
                         fontSize: 14,
                         fontWeight: 600,
                    }}
          >
            No record available
          </Typography>
            )}
                    </Grid>
                    </Box>
                    </Grid>
            <Footer />  
        </Grid>
        )
    }
    
    render() {
        return ( 
            <Container maxWidth="xl" className="user_detail_container">
                {
                    this.state.isJobRoleRequestSideBarOpen?
                        <Grid container spacing={0}>
                            <Grid style={webStyle.sideBarJobRoleRequestPage}  item xs={3}>
                                <SideBar
                                   userData={{ age: 25 }}
                                    activeMenuItem={"Projects"}
                                    navigate={this.props.navigation.navigate}
                                    handleSideBar={this.handleJobRoleSideBar}
                                   
                                    />
                            </Grid>
                            <Grid item xs={9}>{this.renderJobRequestPage(this.state.isJobRoleRequestSideBarOpen)}</Grid>
                        </Grid> :
                        <Grid item xs={12}>
                            {
                                this.renderJobRequestPage(this.state.isJobRoleRequestSideBarOpen)
                            }
                           </Grid>
                }
            </Container >
        );  }
}

// Customizable Area Start
const webStyle = {
    blockInfoJobRoleRequest: {

    },
    headInfoJobRoleRequest: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: 15,
    },
    highlightedTextJobRoleRequest: {
        color: "#F5C42C",
        textDecoration: 'underline',
        textDecorationColor: "#F5C42C",
        fontWeight: 600,
        cursor:"pointer"
    },
    activeBlockJobRole: {
        fontSize: "28px",
        fontWeight: "600",
        letterSpacing: "1px",
        display: "flex",
        flexDirection: "row",
        lineHeight: "33px",
        alignItems: 'center',  
      },
    contentSetupAllRequest: {
        borderLeft: "1px solid #E3EAF4",
    },
    
    sideBarJobRoleRequestPage: {
        minHeight: "100vh",
        maxWidth: "23%",
    },
      mainContentAllRequest: {
        width: "100%",
        backgroundColor: "#F8FAFE",
        boxSizing: "border-box",
        padding: "24px",
        height: "100%"
      },
      tabsContentAllInvoice: {
        paddingTop: "16px",
        // paddingLeft:"24px",
        display: "flex",
        gap: "1.5rem",
        flexWrap:"wrap",
        marginBottom: '15px'
    },
    headInfoAllinvoice: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: 15,
    },
    jobRequestHeadButtonRequest: {
        minWidth: '161px',
        borderRadius: '10px',
        background: '#17181d',
        color: '#ffffff',
        fontFamily: 'urbanist',
        fontSize: '11px',
        fontWeight: 600,
        letterSpacing: ' 0.25px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '11px 10px',   
        cursor: 'pointer', 
    },
    roleReqContent: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        padding: '3px 0',
        color: '#6d89af',
    },
    roleReqContentPara: {
        fontFamily: 'Urbanist',
        fontSize: '13px',
        fontWeight: 600,
        lineHeight: '16px',
    },
    roleReqWrapperHead: {
        fontSize: '16px',
        fontWeight: 600,
        color: '#17181d',
        fontFamily: 'Urbanist',
    },
    roleReqWrapperArrowProfile: {
        cursor: 'pointer'
    },
    jobRoleReqMainBox: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        gap: 10,
    },
    jobRoleReqWrapperBox: {
        border: '1px solid  #E3EAF4',
        borderRadius: '12px',
        padding: '12px',
    },
    jobRoleReqImg: {
        height: 42,
        width: 42,
        borderRadius: 10,
        backgroundColor: '#f1f1f1',
    },
    jobRoleImg: {
        height: '100%',
        width: '100%',
        borderRadius: 10,
    },
    jobRoleTiming: {
        color: '#6D89AF',
        fontSize: '11px'
    },
    jobRequestInviteVendor: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '8px',
        borderTop: '1px solid #E3EAF4',
        margin: '8px 0 0',
        padding: '10px 0 0',
    },
    jobRequestInviteText: {
        width: '50%',
        textAlign: 'center',
        color: '#FF5C5C',
        fontWeight: 'bold',
        fontSize: '13px',
        cursor: 'pointer',
    },
    roleReqContImg: {
        height: 16,
        width: 16,
    },
    activeBlockAllArrowRequest: {
        backgroundColor:' #fff',
        border: '1px solid #ddd',
        borderRadius: '50%',
        padding: '10px',   
        cursor: 'pointer',
      },
      wrapBlockAllArrowJobRoles: {
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        gap: 10,
        marginBottom: '5px'
    },
    wrapBlockAllArrowBack: {
        height: '16px'
    },
};
// Customizable Area End