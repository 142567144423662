import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import React from "react";
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  projectMembersJobRole: any[];
  isLoading: boolean;
  isModelOpen: boolean;
  modelType: string;
  projectMembersJobRoleBreadCrumb: any;
  projectId: string;
  tabValue: number;
  anchorEl: any;
  departmentsList: any[];
  permssionsList: any[];
  manageModelPermssionList: any[];
  manageModelSelectedDept: any;
  employeeIdToUpdate: string;
  rolesList: any[];
  countryCodeSelected: string;
  currentPage: number;
  totalCountPMJR: number;
  statusvalue:string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class AllEmployeeListPHController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  layoutRef: any = null;
  projectMembersJobRoleTitle: string = "All Employees Job Roles";
  getPipelineEmployeeListApiCalledId: string = "";
  getPipelineEmployeePermissionListApiCalledId: string = "";
  getPipelineEmployeeDepartmentListApiCalledId: string = "";
  getPipelineEmployeeRoleListApiCalledId: string = "";
  managePipelineEmployeeAccessibilityApiCalledId: string = "";
  addPipelineEmployeeApiCalledId: string = "";
  removePipelineEmployeeFromProjectApiCalledId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      projectMembersJobRole: [],
      isLoading: false,
      isModelOpen: false,
      modelType: "",
      projectMembersJobRoleBreadCrumb: [
        { title: "Home", link: "Projects" },
        { title: "Members", link: "ProjectDetailPH" },
        { title: "", link: "ProjectDetailPH" },
        { title: "All Employees Job Roles" },
      ],
      projectId: "",
      tabValue: 0,
      anchorEl: null,
      departmentsList: [],
      permssionsList: [],
      manageModelPermssionList: [],
      manageModelSelectedDept: null,
      employeeIdToUpdate: "",
      rolesList: [],
      countryCodeSelected: "",
      currentPage: 1,
      totalCountPMJR: 0,
      statusvalue:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.layoutRef = React.createRef();
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const status=await getStorageData("statusPH");
   this.setState({statusvalue:status})
   let title;
if (status === "completed") {
    title = "All Project";
} else if (status === "ongoing") {
    title = "Ongoing Project";
} else if (status === "upcoming") {
    title = "Upcoming Project";
} 


this.state.projectMembersJobRoleBreadCrumb[1].title = title;
   
    const projectDetailsJson = await getStorageData("projectDetails");
    const projectDetails = JSON.parse(projectDetailsJson);
    let breadCrumb = this.state.projectMembersJobRoleBreadCrumb;
    breadCrumb[2].title = projectDetails.name;
    this.setState({ projectMembersJobRoleBreadCrumb: breadCrumb, projectId: projectDetails.id });
    this.getPipelineEmployeeListApiCalledId = await this.apiCall(configJSON.GET_Method, configJSON.getPipelineEmployeeListApiEndPoint + projectDetails.id);
    this.getPipelineEmployeePermissionListApiCalledId = await this.apiCall(configJSON.GET_Method, configJSON.getPipelineEmployeePermissionListApiEndPoint);
    this.getPipelineEmployeeDepartmentListApiCalledId = await this.apiCall(configJSON.GET_Method, configJSON.getPipelineEmployeeDepartmentListApiEndPoint + projectDetails.id);
    this.getPipelineEmployeeRoleListApiCalledId = await this.apiCall(configJSON.GET_Method, configJSON.getPipelineEmployeeRoleListApiEndPoint);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.getPipelineEmployeeListApiCalledId === apiRequestCallId) {
        this.getPipelineEmployeeListHandleResponse(responseJson);
      } else if (this.getPipelineEmployeePermissionListApiCalledId === apiRequestCallId) {
        this.getPipelineEmployeePermissionListHandleResponse(responseJson, "permssionsList");
      } else if (this.getPipelineEmployeeDepartmentListApiCalledId === apiRequestCallId) {
        this.getPipelineEmployeeDepartmentListHandleResponse(responseJson);
      } else if (this.managePipelineEmployeeAccessibilityApiCalledId === apiRequestCallId) {
        this.managePipelineEmployeeAccessibilityHandleResponse(responseJson);
      } else if (this.getPipelineEmployeeRoleListApiCalledId === apiRequestCallId) {
        this.getPipelineEmployeePermissionListHandleResponse(responseJson, "rolesList");
      } else if (this.addPipelineEmployeeApiCalledId === apiRequestCallId) {
        this.addPipelineEmployeeHandleResponse(responseJson);
      } else if (this.removePipelineEmployeeFromProjectApiCalledId === apiRequestCallId) {
        this.removePipelineEmployeeFromProjectHandleResponse(responseJson);
      }
    }

    this.getTokenAndCountryCodeSelected(message);
  }
  // Customizable Area End

  // Customizable Area Start
  getTokenAndCountryCodeSelected = (message: Message) => {
    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      let selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          countryCodeSelected:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode
        });
      }
    }
  }

  getPipelineEmployeeListHandleResponse = (pmjrResponseJson: { data: any, meta: { total_employees: number }, errors: { token: string }[] | { message: string } }) => {
    if (pmjrResponseJson.data) {
      this.setState({
        projectMembersJobRole: pmjrResponseJson.data, totalCountPMJR: pmjrResponseJson.meta.total_employees
      });
    } else if (Array.isArray(pmjrResponseJson.errors)) {
      this.layoutRef.current.handleChangeNotification("error", true, pmjrResponseJson.errors[0].token, "LandingPage");
    } else if (pmjrResponseJson.errors.message) {
      this.layoutRef.current.handleChangeNotification("error", true, pmjrResponseJson.errors.message);
    }
    this.setState({ isLoading: false });
  }

  getPipelineEmployeePermissionListHandleResponse = (pmjrResponseJson: { data: any }, stateName: keyof S) => {
    if (pmjrResponseJson.data) {
      this.setState(({
        [stateName]: pmjrResponseJson.data, isLoading: false
      } as unknown) as Pick<S, keyof S>);
    }
  }

  getPipelineEmployeeDepartmentListHandleResponse = (pmjrResponseJson: { data: any, errors: { token: string }[] | { message: string } }) => {
    if (pmjrResponseJson.data) {
      this.setState({
        departmentsList: pmjrResponseJson.data
      });
    } else if (Array.isArray(pmjrResponseJson.errors)) {
      this.layoutRef.current.handleChangeNotification("error", true, pmjrResponseJson.errors[0].token, "LandingPage");
    } else if (pmjrResponseJson.errors.message) {
      this.layoutRef.current.handleChangeNotification("error", true, pmjrResponseJson.errors.message);
    }
    this.setState({ isLoading: false });
  }

  managePipelineEmployeeAccessibilityHandleResponse = async (pmjrResponseJson: { data: any, meta: { message: string }, errors: { token: string }[] | { message: string } }) => {
    if (pmjrResponseJson.data) {
      this.layoutRef.current.handleChangeNotification("success", true, pmjrResponseJson.meta ? pmjrResponseJson.meta.message : "Employee details updated successfully");
      this.getPipelineEmployeeListApiCalledId = await this.apiCall(configJSON.GET_Method, configJSON.getPipelineEmployeeListApiEndPoint + this.state.projectId);
    } else if (Array.isArray(pmjrResponseJson.errors)) {
      this.layoutRef.current.handleChangeNotification("error", true, pmjrResponseJson.errors[0].token, "LandingPage");
    } else if (pmjrResponseJson.errors.message) {
      this.layoutRef.current.handleChangeNotification("error", true, pmjrResponseJson.errors.message);
    }
    this.setState({ isLoading: false });
  }

  addPipelineEmployeeHandleResponse = (pmjrResponseJson: { data: any, errors: { token: string }[] | { message: string } }) => {
    if (pmjrResponseJson.data) {
      this.setState({
        projectMembersJobRole: [pmjrResponseJson.data, ...this.state.projectMembersJobRole], totalCountPMJR: this.state.totalCountPMJR + 1
      });
      this.handleModelClose();
      this.layoutRef.current.handleChangeNotification("success", true, "Employee created successfully");
    } else if (Array.isArray(pmjrResponseJson.errors)) {
      this.layoutRef.current.handleChangeNotification("error", true, pmjrResponseJson.errors[0].token, "LandingPage");
    } else if (pmjrResponseJson.errors.message) {
      this.layoutRef.current.handleChangeNotification("error", true, pmjrResponseJson.errors.message);
    }
    this.setState({ isLoading: false });
  }

  removePipelineEmployeeFromProjectHandleResponse = async (pmjrResponseJson: { meta: { message: string }, status: number, error: string, errors: { token: string }[] | { message: string } }) => {
    if (pmjrResponseJson.meta) {
      this.layoutRef.current.handleChangeNotification("success", true, pmjrResponseJson.meta.message);
      this.getPipelineEmployeeListApiCalledId = await this.apiCall(configJSON.GET_Method, configJSON.getPipelineEmployeeListApiEndPoint + this.state.projectId);
    } else if (pmjrResponseJson.status) {
      this.layoutRef.current.handleChangeNotification("error", true, pmjrResponseJson.error);
    } else if (Array.isArray(pmjrResponseJson.errors)) {
      this.layoutRef.current.handleChangeNotification("error", true, pmjrResponseJson.errors[0].token, "LandingPage");
    } else if (pmjrResponseJson.errors.message) {
      this.layoutRef.current.handleChangeNotification("error", true, pmjrResponseJson.errors.message);
    }
    this.setState({ isLoading: false });
  }

  apiCall = async (pmjrMethod: string, pmjrEndPoint: string, pmjrFormData?: any) => {
    const pmjrAutoToken = await getStorageData("token");
    const pmjrHeader = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": pmjrAutoToken,
    };
    this.setState({ isLoading: true });
    const pmjrRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    pmjrRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(pmjrHeader)
    );
    pmjrRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      pmjrEndPoint
    );
    pmjrRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(pmjrFormData ? { token: pmjrAutoToken } : pmjrHeader)
    );
    pmjrFormData && pmjrRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      pmjrFormData
    );
    pmjrRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      pmjrMethod
    );
    runEngine.sendMessage(pmjrRequestMessage.id, pmjrRequestMessage);
    return pmjrRequestMessage.messageId;
  };

  handleModelOpen = (type: string) => {
    this.setState({ isModelOpen: true, modelType: type });
  }

  handleModelClose = () => {
    this.setState({ isModelOpen: false, modelType: "" });
  }

  handleManageAccessibility = (permissions: any, department: any, employeeId: string) => {
    this.handleModelOpen("manageAccessibility");
    const manageModelPermssionList = this.state.permssionsList.map((permission: any) => {
      return { ...permission, selected: permissions.some((data: any) => data.slug === permission.attributes.slug) }
    });
    const selectedDept = this.state.departmentsList.find((dept: any) => department && dept.attributes.name === department.name && dept.attributes.level === department.level);
 
    this.setState({ manageModelPermssionList: manageModelPermssionList, manageModelSelectedDept: department ? selectedDept.id : "", employeeIdToUpdate: employeeId });
  }

  handleRemoveFromProject = async () => {
    this.handleMenuClose();
    const emplyeeToUpddate = this.state.projectMembersJobRole.find((data: any) => data.id === this.state.employeeIdToUpdate);
    this.removePipelineEmployeeFromProjectApiCalledId = await this.apiCall(configJSON.DELETE_Method, (emplyeeToUpddate.attributes.activated ? configJSON.simpleRemovePipelineEmployeeFromProjectApiEndPoint : configJSON.jobRoleRemovePipelineEmployeeFromProjectApiEndPoint) + emplyeeToUpddate.id);
  }

  savePermissionsAPICall = async (formData: any) => {
    const emplyeeToUpddate = this.state.projectMembersJobRole.find((data: any) => data.id === this.state.employeeIdToUpdate);
    this.managePipelineEmployeeAccessibilityApiCalledId = await this.apiCall(configJSON.put_method,  configJSON.managePipelineEmployeeAccessibilityApiEndPoint+ emplyeeToUpddate.id, formData);
    this.handleModelClose();
    this.setState({ manageModelPermssionList: [], manageModelSelectedDept: "", employeeIdToUpdate: "" });
  }



  handleMenuOpen = (event: any, employeeId: string) => {
    this.setState({ anchorEl: event.currentTarget, employeeIdToUpdate: employeeId });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null, employeeIdToUpdate: "" });
  };

  handlePageChange = async (event: any, value: number) => {
    this.setState({ currentPage: value });
  }

  handleSaveEmployeeApiCall = async (formData: any) => {
    this.addPipelineEmployeeApiCalledId = await this.apiCall(configJSON.httpPostMethod, configJSON.addPipelineEmployeeApiEndPoint + this.state.projectId, formData);
  }
  // Customizable Area End
}