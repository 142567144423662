import React from "react";

import {
    // Customizable Area Start
    Grid,
    InputLabel,
    Divider,
    Avatar,
    Typography,
    Box,
    Card
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import "./fullVisibility.css";
import AllStudiosCardController, { Props, configJSON } from "./AllStudiosCardController";
// Customizable Area End

export default class AllStudiosCard extends AllStudiosCardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {

        return (
            // Customizable Area Start
            <Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <InputLabel style={webStyle.studioMainLabel}>{configJSON.studio}</InputLabel>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Divider style={{ marginTop: '3%' }} />
                </Grid>
                <Grid container spacing={2} className="description_grid" style={{ marginBottom: '1%' }}>
                    {
                        this.props.studios.map((item: any, index: number) => {
                            return (
                                <Grid item key={`${index}_allStudiosList`} xs={12} sm={12} md={6} lg={6} xl={6} data-test-id="card_parent_onclick">
                                    <Card style={webStyle.card}>
                                        <Grid container spacing={2}>
                                            <Box sx={webStyle.studioCardParentBox} style={webStyle.studioCardBox}>
                                                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                                    <img
                                                        className="project_avatar"
                                                        style={webStyle.studioAvatar}
                                                        src={item.attributes.image}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={9} lg={9} xl={9} style={webStyle.studioCardInner as React.CSSProperties} >
                                                    <Typography style={webStyle.studioNameTypo}>{item.attributes.name}</Typography>
                                                    <Typography style={webStyle.locationTypo}>{item.attributes.address}</Typography>
                                                    <Typography style={webStyle.rantTypo}>{`${item.attributes.price_per_day} Per Day`}</Typography>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    </Card>
                                </Grid>
                            )
                        })}
                </Grid>
            </Grid >
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const webStyle = {
    locationTypo: {
        color: "#6d89af",
        fontFamily: "Urbanist",
        fontSize: "11px",
        fontWeight: 600,
        letterSpacing: "0.1375px",
    },
    card: {
        borderRadius: "16px",
        padding: '2%',
        border: '1px solid lightgray',
        boxShadow: 'none'
    },
    studioMainLabel: {
        color: 'black',
        fontWeight: 600,
        fontSize: '23px',
        fontFamily: 'Urbanist',
        display: 'flex',
        columnGap: '1%'
    },
    studioCardParentBox: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "space-between",
        padding: '2%'
    },
    studioCardBox: {
        width: "100%",
        padding: "4%",
    },
    studioCardInner: {
        textAlign: "start",
        paddingLeft: "2%",
    },
    studioNameTypo: {
        fontFamily: "Urbanist",
        fontSize: "14px",
        fontWeight: 600,
        letterSpacing: "0.175px",
    },
    rantTypo: {
        color: "#f5c42c",
        fontFamily: "Helvetica-Bold",
        fontSize: "12px",
        fontWeight: 400,
        letterSpacing: "0.15px",
    },
    studioAvatar: {
        width: "100%",
        borderRadius: "10px",
        height: "100%",
    }
}
// Customizable Area End