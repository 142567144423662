import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import AllvendorContent from "./AllvendorContent.web";
import Header from "../../../components/src/Header.web";
import Footer from "../../../components/src/Footer";
import SideBar from "../../../components/src/SideBar";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Loader from "../../../components/src/Loader";
const theme = createTheme({
  palette: {
    primary: {
      main: "#f5c42c",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

import FullTeamVisibilityController, {
  Props,
  configJSON,
} from "./FullTeamVisibilityController";
import { Department, Organisation, Team, TeamMember } from "./IFullteamVisibility";

export default class FullTeamVisibility extends FullTeamVisibilityController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderMainContentAllVendor(sideBarStatus: boolean) {
    return <Grid style={webStyle.contentSetup}>
      <Loader loading={this.state.isLoading}></Loader>
      < Header
        sideBarStatus={sideBarStatus}
        dropDownStatus={this.state.isDropdownOpenVal}
        handleSideBar={this.handleSideBarisDropdownOpenVal}
        handleDropdown={this.handleDropdownVal}
        navigate={this.props.navigation.navigate}
      />
      <Grid style={webStyle.mainContent as React.CSSProperties}>
        <AllvendorContent data-test-id="mainContentAllVendorContent" isLoading={this.state.isLoading} updateLoadingStateValue={this.updateLoadingStateValue} issidebaropen={this.state.issidebaropen} handleDropdownVal={this.handleDropdownVal} isDropdownOpenVal={this.state.isDropdownOpenVal} handleSideBarisDropdownOpenVal={this.handleSideBarisDropdownOpenVal} allVendorsDataValue={this.state.allVendorsDataValue} vendorListTotal={this.state.vendorListTotal} updateAllvendorDataValue={this.updateAllvendorDataValue} handleBadgeVisibility={this.handleBadgeVisibility} getApiCallForAllVendors={this.getApiCallForAllVendors} navigation={this.props.navigation} id="" />
      </Grid>
      <Footer />
    </Grid >
  }

  // Customizable Area End

  render() {
    
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container style={webStyle.parentDiv}>
          {
            this.state.issidebaropen ?
              <Grid container spacing={0}>
                <Grid item xs={3} style={webStyle.sidebarParent}>
                  <SideBar
                    navigate={this.props.navigation.navigate}
                    userData={{ age: 25 }}
                    activeMenuItem={"All Vendors"}
                    handleSideBar={this.handleSideBarisDropdownOpenVal} />
                </Grid>
                <Grid item xs={9}>{this.renderMainContentAllVendor(this.state.issidebaropen)}</Grid>
              </Grid> :
              <Grid item xs={12}>
                {this.renderMainContentAllVendor(this.state.issidebaropen)}
              </Grid>
          }
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyle = {
  parentDiv: {
    padding: "0px",
    maxWidth: "none"
  },
  box: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: '1%'
  },
  sidebarParent: {
    minHeight: "100vh",
    maxWidth: "23%"
  },
  contentSetup: {
    borderLeft: "1px solid #E3EAF4",
  },
  card: {
    borderRadius: "16px",
    padding: '2%',
    border: '1px solid lightgray',
    boxShadow: 'none'
  },
  avatar: {
    border: "3px solid #f5c42c",
    width: 62,
    height: 62,
    marginRight: 1
  },
  nameBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start"
  },
  innerBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    columnGap: '7px'
  },
  locParent: {
    fontWeight: 500,
    fontSize: "12px",
    fontFamily: "Urbanist",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    columnGap: "4px",
    color: '#6d89af'
  },
  vendorLabel: {
    color: 'black',
    fontWeight: 600,
    fontSize: '28px',
    fontFamily: 'Urbanist',
    display: 'flex',
    columnGap: '1%'
  },
  vendor: {
    fontSize: '13px'
  },
  locIcon: {
    fontSize: "small",
    width: "0.875rem",
    height: "0.875rem",
  },
  cardContent: {
    padding: 0,
    marginBottom: 0
  },
  icon: {
    fontSize: "medium",
    fontWeight: "bold",
    marginRight: 10
  },
  cardParentBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: '2%',
    paddingRight: '2%'
  },
  projectcardParentBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    padding: '2%'
  },
  mainContent: {
    width: "100%",
    minHeight: "100vh",
    backgroundColor: "#F8FAFE",
    boxSizing: "border-box",
    paddingTop: '1%',
    paddingBottom: '1%'
  }
};
// Customizable Area End