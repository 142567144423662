import React from "react";

// Customizable Area Start
import {
  Modal,
  Container,
  Box,
  Button,
  Input,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Paper,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Grid,
  Typography,
  TypographyProps,
} from "@material-ui/core";
import { createTheme, ThemeProvider,styled } from "@material-ui/core/styles";
import MyTask from "../../../components/src/MyTask"
import { ExpandMore, ExpandLess } from "@material-ui/icons";
import { ITask, ITaskList } from "./types";
import SideBar from "../../../components/src/SideBar";
import Pagination from '@material-ui/lab/Pagination';
import Header from "../../../components/src/Header.web";
import Footer from "../../../components/src/Footer";
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import Select from "@material-ui/core/Select";
import Raiseinvoicefunctionality from "../../../components/src/Raiseinvoicefunctionality";
import SuccessfullmodelRaiseInvoice from "./SuccessfullmodelRaiseInvoice.web";
// Customizable Area End

import TaskListController, { configJSON, Props } from "./TaskListController";

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
const SortsubDivpTaskList = styled(Box)({
  display: 'flex',
  gap: "24px",
  alignItems: "end",
  flexDirection: 'column'
});
const SortDivTaskList = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  position: 'relative',
  "@media(max-width: 600px)": {
      gridColumnGap: '0px',
      display: 'block'
  },
});
const SearchInputTaskList = styled('input')({
  fontSize: "15px",
  outline: "none",
  border: "none",
  width: "85%",
  padding: "10px 3px",
  backgroundColor: "#F8FAFE",
  "@media(max-width: 500px)": {
      fontSize: '12px',
      overflow: 'hidden',
      width: "70%",
      padding: "10px 0px",
  },
});

const SearchIconTaskList = styled(SearchOutlinedIcon)({
  padding: "10px 0 10px 13px",
  "@media(max-width: 500px)": {
      padding: "10px 0 10px 8px",
      fontSize: '18px'
  },
});
const SelectBox1 = styled(Select)({
  padding: '5px 15px',
  borderRadius: '25px',
  border: '1px solid #e3eaf4',
  background: 'white',
  zIndex: 1,
  marginTop: 0,
  "& :focus": {
      background: 'white',
  },

});
// Customizable Area End


export default class TaskList extends TaskListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  webrenderMainContentTaskList(sideBarStatus: boolean = this.state.isSideBarOpen) {


    return (
        <>

            <Grid style={webStyle.contentSetupTaskList}>

            
                < Header
                    sideBarStatus={sideBarStatus}
                    dropDownStatus={this.state.isDropdownOpen}
                    handleSideBar={this.handleSideBar}
                    handleDropdown={this.handleDropdown}
                    navigate={this.props.navigation.navigate}
                />
                <Grid style={webStyle.mainContentTaskList as React.CSSProperties}>

                    <SortDivTaskList>
                        <Box sx={webStyle.blockInfoTaskList}>
                            <Typography variant="h3" style={webStyle.activeBlockTaskList as TypographyProps}>My Tasks</Typography>
                            <Typography variant="h6" style={webStyle.blockPathTaskList as TypographyProps}><span data-test-id="homeLink" onClick={() => this.handleNavigation("JobProfile")} style={webStyle.highlightedTextTaskList} >Home</span> /My Tasks </Typography>
                        </Box>
                        <SortsubDivpTaskList>
                            <Box style={{ display: 'flex' }}>
                                
                                <Box sx={webStyle.headerSearchBoxdropdownTaskList}>
                                    <SearchIconTaskList style={webStyle.searchiconTaskList} />
                                    <SearchInputTaskList placeholder={`Search your tasks Invoice`}
                                        style={{
                                            fontSize: "14px", fontWeight: 500,
                                            fontFamily: "Urbanist", letterSpacing: "0.25px"
                                        }}
                                        data-testid='search'   onChange={(event: any) => this.filterHousesMyTask(event)}  />


                                </Box>
                            </Box>
                            {
                                this.state.allinvoiceData.length? <div>
                                <Box
                                    sx={webStyle.submitButtonTaskList}
    
    
                                    onClick={this.handleOpenRaiseinvoice} >
                                    + Raise Invoice
                                </Box>
                                               
                                            </div>:null
                            }
                           
                          
                        </SortsubDivpTaskList>
                    </SortDivTaskList>

                    <Grid container spacing={3} style={{ paddingLeft: "24px",paddingTop:"24px"}} >
                        {this.state.allinvoiceData.length?  this.state.allinvoiceData.map((item:any,index:number) => (
                                <Grid item key={`${item}_project_card`} xl={3} lg={4} md={6} sm={12} xs={12} spacing={3}>
                                    <Box sx={webStyle.headerSearchParentBoxTaskList}>
                                        
                                        <MyTask data={item} navigaetoProject={this.navigaetoProject}/>
                                    </Box>
                                </Grid>
                            )):
                            
                            <Typography style={{ width: '100%', textAlign: 'center', marginTop: '30px', marginBottom: '30px', color: '#6d89af', fontFamily: 'Urbanist', fontSize: 14, fontWeight: 600 }}>No record available</Typography>
                
                            } 
                                
                            
                    </Grid>
                    {this.state.allinvoiceData.length&&this.state.countMyTask ?
                            (<Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Pagination style={{ marginTop: "30px", marginBottom: "30px", justifyContent: "center", display: "flex" }} data-test-id="pagination" count={Math.ceil((this.state.countMyTask) / 9)} page={this.state.currentpageMyTask} onChange={this.handlePageChangeMyTask} />
                                </Grid>
                            </Grid>)
                            : null}
                </Grid>
                <Footer />
            </Grid >
        </>
    )
}
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
         <Container style={webStyle.webparentDiv1}>
        {
                        this.state.isSideBarOpen ?
                            <Grid container spacing={0}>
                                <Grid item xs={3} style={webStyle.websidebarParentTaskList}>
                                    <SideBar
                                        navigate={this.props.navigation.navigate}
                                        activeMenuItem={"My Tasks"}
                                        userData={{ age: 25 }}
                                        handleSideBar={this.handleSideBar} />
                                </Grid>
                                <Grid item xs={9} style={{ flexBasis: "77.75%", maxWidth: "77.75%" }}>{this.webrenderMainContentTaskList()}</Grid>
                            </Grid> :
                            <Grid item xs={12}>{this.webrenderMainContentTaskList()}</Grid>
                    }
        </Container>
        <Modal
                                                open={this.state.openraiseinvoice}
                                                onClose={this.handleCloseRaiseinvoice} 
                                                
                                            >
                                                <div style={{ 
                                                     backgroundColor: 'white',
                                                     borderRadius: '20px',
                                                    top: '50%',
                                                    position: 'absolute',                                              
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)',


                                                }}>
                                        
                                        <Raiseinvoicefunctionality data={this.state.allinvoiceData} handleClose1={this.handleCloseRaiseinvoice} handleImage={this.handleImage}
                                                        handleChangeinvoice={this.handleChangeinvoiceTask} 
                                                        invoiceselect={this.state.invoiceselectTask} handleChangeProject={this.handleChangeProjectTask} selectproject={this.state.selectprojectTask}
                                                        handleChangeradio={this.handleChangeradioTask} selectinvoicetype={this.state.selectinvoicetypeTask} selectinvoicename={this.state.selectinvoicenameTask} 
                                                        handleChangeName={this.handleChangeNameTask} handleChangeDetail={this.handleChangeDetailTask} selectinvoicedetail={this.state.selectinvoicedetailTask}
                                                        handleChangeNo={this.handleChangeNoTask} selectinvoiceno={this.state.selectinvoicenoTask}
                                                        handleChangeAmount={this.handleChangeAmountTask} selectinvoiceamount={this.state.selectinvoiceamountTask} raiseinvoiceData={this.raiseinvoiceDataTask}  image={this.state.imageTask} />
                                                </div>
                                            </Modal>
                                            {this.state.isModelOpen &&this.state.modelType === "successModel" ? <SuccessfullmodelRaiseInvoice navigation={this.props.navigation} open={this.state.isModelOpen} handleClose={this.handleModelClose} handlevalue={this.handledatavalue} raiseinvoicedetail={this.state.raiseinvoicedetail} /> : null}
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  submitButtonTaskList: {
    width: '161px',
    //height: '52px',
    borderRadius: '14px',
    background: '#17181d',
    color: ' #ffffff',
    fontFamily: 'urbanist',
    fontSize: '15px',
    fontWeight: 600,
    letterSpacing: ' 0.25px',
    alignItems: 'center',
    paddingTop: '15px',
    paddingBottom: '15px',
    display: 'flex',
    flexDirection: 'column-reverse',
    marginLeft: '208px',
    cursor:'pointer'

},
headerSearchBoxdropdownTaskList: {
    display: "flex",
    border: '1px solid #e3eaf4',
    minWidth: "260px",
    borderRadius: "35px",
    alignItems: "end",
    backgroundColor: "#ffffff",
    overflowX: 'hidden',
    height: "44px",
    marginTop: '32px',
    "@media screen and (max-width: 500px)": {
        minWidth: "100px",
        width: '160px',
        marginRight: "0px",
    },
},
webparentDiv1: {
    padding: "0px",
    maxWidth: "none"
},
searchiconTaskList: {
    marginRight: '6px',
    fill: '#6D89AF',
    fontSize: "22px"
},
contentSetupTaskList: {
    borderLeft: "1px solid #E3EAF4",
},
websidebarParentTaskList: {
    minHeight: "100vh",
    maxWidth: "22.25%",
},
mainContentTaskList: {
    width: "100%",
    backgroundColor: "#F8FAFE",
    boxSizing: "border-box",
    minHeight:'100vh',
    paddingRight:"24px"
},
mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    align: "center",
    paddingBottom: "30px",
    background: "#fff",
},
highlightedTextTaskList: {
    color: "#F5C42C",
    textDecoration: 'underline',
    textDecorationColor: "#F5C42C",
    fontWeight: 600,
    cursor:'pointer'
},
headerSearchBoxTaskList: {
    display: "flex",
    justifyContent: "start",
    align: "end",
    flexDirection: "row",
    flexWrap: "wrap",
    paddingTop: '24px',
    paddingLeft: "24px"
},
headerSearchParentBoxTaskList: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
},
InvoiceNo: {
    color: '#6d89af',
    fontFamily: 'Urbanist',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '19px',
},
blockInfoTaskList: {
    paddingLeft: '24px',
   paddingTop:"24px"
},
activeBlockTaskList: {
    lineHeight: "34px",
    fontSize: "28px",
    fontWeight: "700",
    letterSpacing: "1px",
    display: "flex",
    flexDirection: "row",
    align: 'center',
    gap: '12px',
    marginBottom: '8px',
},
blockPathTaskList: {
    fontSize: "14px",
    letterSpacing: "1px",
    color: "#6D89AF",
    marginBottom: "5px",
    fontWeight: 500
},
};
// Customizable Area End
