import React from "react";

// Customizable Area Start
import { Container, Box, Typography, Grid, Link, TypographyProps, Card, CardContent } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { nextIcon, previousIcon } from "./assets"
import { Bar, BarChart, CartesianGrid, Rectangle, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import SideBar from "../../../components/src/SideBar";
import Header from "../../../components/src/Header.web";
import Footer from "../../../components/src/Footer";
import Loader from "../../../components/src/Loader.web";
interface AnalyticsChartsData {
  color: string;
  name: string;
}
// Bar customization
//istanbul ignore next//
const CustomBar = ({ ...props }) => {
  const { x, y, height, type } = props;
  const specificWidth: any = { invoce: 11.11, allUser: 26.43, project: 19.44 }
  return (
    <Rectangle
      x={x}
      y={y - 4}
      width={specificWidth[type]}
      height={height}
      fill={props.color}
      radius={type === "allUser" ? [17, 17, 17, 17] : [9, 9, 9, 9]} />
  );
};
//istanbul ignore next//
const AnalyticsLegends = ({ ...props }) => {
  return (
    <div style={webStyle.lengends as React.CSSProperties} className="hide-scrollbar">{
      props.legends.map((legendInfo: AnalyticsChartsData) => {
        return (<div key={`${legendInfo.color}_color`} style={webStyle.legendStyle as React.CSSProperties}>
          <div style={{ ...webStyle.legendDot, backgroundColor: `${legendInfo.color}` }}></div>
          {legendInfo.name}
        </div>
        )
      })}
    </div>
  )
}
// Bar Chart
//istanbul ignore next//
const AnalyticsBarChart = ({ ...props }) => {
  return (
    <>
      <ResponsiveContainer height={250} width="100%">
        <BarChart
          barGap={props.barGap}
          margin={{
            top: 13,
            left: -25,
          }}
          data={props.data}
        >
          <CartesianGrid vertical={false} strokeDasharray="3" />
          <XAxis dataKey="name" tick={{ fontSize: 10, fontFamily: "Urbanist", color: "#6d89af", fontWeight: 700 }} interval={0} />
          <YAxis tick={{ fontSize: 10, fontFamily: "Urbanist", color: "#6d89af", fontWeight: 700 }} />
          <Tooltip />
          {
            props.bars.map((barData: AnalyticsChartsData) => {
              return (
                <Bar dataKey={barData.name} barSize={20} shape={<CustomBar type={props.type} color={barData.color} />} key={barData.color} />
              )
            })
          }
        </BarChart>
      </ResponsiveContainer>
    </>
  )
}
// Customizable Area End

import AnalyticsController, {
  Props,
  configJSON
} from "./AnalyticsController.web";

export default class Analytics extends AnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  analyticsRenderMainContent(sideBarStatus: boolean = this.state.isSideBarOpen) {
    return <>
      <Loader loading={this.state.isLoading}></Loader>
      <Grid style={webStyle.analyticsContentSetup}>
        < Header
          dropDownStatus={this.state.isDropdownOpen}
          handleDropdown={this.handleDropdown}
          sideBarStatus={sideBarStatus}
          navigate={this.props.navigation.navigate}
          handleSideBar={this.handleSideBar}
        />
        <Grid style={webStyle.analyticsMainContent as React.CSSProperties}>
          <Box>
            <Typography variant="h3" style={webStyle.analyticsActiveBlock as TypographyProps}>Analytics</Typography>
            <Typography variant="h6" style={webStyle.analyticsBlockPath as TypographyProps}>
              <span style={webStyle.analyticsHighlightedText}>
                <Link data-test-id="homeLink" color="inherit" onClick={() => this.handleNavigation("Dashboard")}>
                  Home
                </Link>
              </span>
              {" "}/ Analytics
            </Typography>
          </Box>
          <Grid container spacing={3}>
            <Grid id="invoice" item lg={12} sm={12} xs={12}>
              <Card style={webStyle.cardContentCover}>
                <CardContent>
                  <div style={webStyle.chartInfo}>
                    <Typography style={webStyle.chartName as TypographyProps}>Invoices</Typography>
                    <div style={webStyle.slider}>
                      <div style={webStyle.updateCurrentPeriod} data-test-id="Handle-Previous-Click-1" onClick={() => { this.handlePreviousClick("invoicesIndex") }}><img src={previousIcon} alt="previous" /></div>
                      <div style={webStyle.currentPeriod as React.CSSProperties}>{this.state.values[this.state.invoicesIndex]}</div>
                      <div style={webStyle.updateCurrentPeriod} data-test-id="Handle-Next-Click-1" onClick={() => { this.handleNextClick("invoicesIndex") }}><img src={nextIcon} alt="next" /></div>
                    </div>
                  </div>
                  <hr style={webStyle.chartLineStyle} />
                  <AnalyticsLegends legends={[
                    {
                      name: "Rejected",
                      color: "#FF5C5C"
                    },
                    {
                      name: "Raised/Pending",
                      color: "#F7B500"
                    },
                    {
                      name: "Approved",
                      color: "#3389FF"
                    },
                    {
                      name: "Completed",
                      color: "#42A200"
                    }
                  ]} />
                  <div>
                    <AnalyticsBarChart
                      data={this.state.analyticsInvoicesData}
                      barGap={"2%"}
                      type="invoce"
                      navigate={this.props.navigation.navigate}
                      bars={[
                        { name: "pending", color: "#F7B500" },
                        { name: "rejected", color: "#FF5C5C" },
                        { name: "approved", color: "#3389FF" },
                        { name: "completed", color: "#42A200" },
                      ]}
                    />
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid id="project" lg={12} sm={12} xs={12} item>
              <Card
                style={webStyle.cardContentCover}
              >
                <CardContent>
                  <div
                    style={webStyle.chartInfo}
                  >
                    <Typography style={webStyle.chartName as TypographyProps}>Projects</Typography>
                    <div style={webStyle.slider}>
                      <div style={webStyle.updateCurrentPeriod} data-test-id="Handle-Previous-Click-2" onClick={() => { this.handlePreviousClick("projectsIndex") }}><img src={previousIcon} alt="previous" /></div>
                      <div style={webStyle.currentPeriod as React.CSSProperties}>{this.state.values[this.state.projectsIndex]}</div>
                      <div style={webStyle.updateCurrentPeriod} data-test-id="Handle-Next-Click-2" onClick={() => { this.handleNextClick("projectsIndex") }}><img src={nextIcon} alt="next" /></div>
                    </div>
                  </div>
                  <hr style={webStyle.chartLineStyle} />
                  <AnalyticsLegends legends={[
                    {
                      name: "Ongoing",
                      color: "#F7B500"
                    },
                    {
                      name: "Upcoming",
                      color: "#3389FF"
                    },
                    {
                      name: "Completed",
                      color: "#f5c42c"
                    },
                  ]} />
                  <div>
                    <AnalyticsBarChart
                      data={this.state.analyticsProjectsData}
                      barGap={"15%"}
                      type="project"
                      bars={[
                        {
                          name: "Ongoing",
                          color: "#F7B500",
                        },
                        {
                          name: "Upcoming",
                          color: "#3389FF"
                        },
                        {
                          name: "Completed",
                          color: "#f5c42c"
                        },
                      ]}
                      navigate={this.props.navigation.navigate}
                    />
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid id="allUser" item lg={12} sm={12} xs={12}>
              <Card style={webStyle.cardContentCover}>
                <CardContent>
                  <div style={webStyle.chartInfo}>
                    <Typography variant="body1" style={{ ...webStyle.chartName, letterSpacing: "0.00938em" } as React.CSSProperties}>Daily Active Users</Typography>
                    <div style={webStyle.slider}>
                      <div style={webStyle.updateCurrentPeriod as React.CSSProperties} data-test-id="Handle-Previous-Click-3" onClick={() => { this.handlePreviousClick("dailyActiveUsersIndex") }}><img src={previousIcon} alt="previous" /></div>
                      <div style={webStyle.currentPeriod as React.CSSProperties}>{this.state.values[this.state.dailyActiveUsersIndex]}</div>
                      <div style={webStyle.updateCurrentPeriod} data-test-id="Handle-Next-Click-3" onClick={() => { this.handleNextClick("dailyActiveUsersIndex") }}><img src={nextIcon} alt="next" /></div>
                    </div>
                  </div>
                  <hr style={webStyle.chartLineStyle} />
                  <AnalyticsLegends legends={[
                    {
                      name: "All Users",
                      color: "#f7b500"
                    },
                    {
                      name: "Active Users",
                      color: "#3389FF"
                    },
                    {
                      name: "Inactive Users",
                      color: "#FF5C5C"
                    },
                  ]} />
                  <div>
                    <AnalyticsBarChart
                      data={this.state.analyticsUsersData}
                      barGap={"29%"}
                      type="allUser"
                      bars={[
                        {
                          name: "active",
                          color: "#3389FF"
                        },
                        {
                          name: "inactive",
                          color: "#FF5C5C"
                        },
                      ]}
                      navigate={this.props.navigation.navigate}
                    />
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Footer />
      </Grid >
    </>
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme} >
        <Container style={webStyle.parentDiv}>
          {
            this.state.isSideBarOpen ? (
              <Grid spacing={0} container>
                <Grid xs={3} item style={webStyle.sidebarParent}>
                  <SideBar
                    handleSideBar={this.handleSideBar}
                    navigate={this.props.navigation.navigate}
                    userData={{ age: 9 }}
                    activeMenuItem={"Analytics"} />
                </Grid>
                <Grid item xs={9}>{this.analyticsRenderMainContent()}</Grid>
              </Grid>) :
              <Grid item xs={12}>{this.analyticsRenderMainContent()}</Grid>
          }
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff"
    }
  },
  typography: {
    h6: {
      fontWeight: 500
    },
    subtitle1: {
      margin: "20px 0px"
    }
  }
});

const webStyle = {
  parentDiv: {
    padding: "0px",
    maxWidth: "none"
  },
  sidebarParent: {
    minHeight: "100vh",
    maxWidth: "23%"
  },
  cardContentCover: {
    borderRadius: "14px",
    border: "1px solid #E1E6EC",
    boxShadow: "none",
  },
  chartInfo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  slider: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  chartName: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "19px",
    fontFamily: 'Urbanist',
    color: "#17181d"
  },
  currentPeriod: {
    border: "1px solid #E3EAF4",
    borderRadius: "16px",
    padding: "5.9px 14px",
    margin: "0px 5px",
    fontWeight: 400,
    fontSize: "11px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    lineHeight: "13px",
    color: "#17181D",
    fontFamily: "Urbanist",
    latterSpacing: '0.18px'
  },
  updateCurrentPeriod: {
    border: "1px solid #e3eaf4",
    height: "24.8px",
    width: "24.8px",
    borderRadius: "16px",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#ffffff",
  },
  chartLineStyle: {
    borderBottom: "1px solid #e3eaf4",
    marginTop: "14px",
    color: "#e3eaf4"
  },
  analyticsContentSetup: {
    borderLeft: "1px solid #E3EAF4",
    // minHeight: "100vh",
  },
  analyticsMainContent: {
    backgroundColor: "#F8FAFE",
    width: "100%",
    boxSizing: "border-box",
    padding: "24px",
    minHeight: "100vh",
  },
  analyticsHighlightedText: {
    fontFamily: 'Urbanist',
    color: "#F5C42C",
    textDecoration: 'underline',
    cursor: 'pointer',
    fontWeight: 600,
    fontSize: "14px"
  },
  analyticsActiveBlock: {
    fontSize: "28px",
    lineHeight: "34px",
    letterSpacing: "1px",
    marginBottom: "8px",
    fontWeight: 700,
  },
  analyticsBlockPath: {
    color: "#6D89AF",
    letterSpacing: "1px",
    fontWeight: 500,
    marginBottom: "18px",
    fontSize: "14px",
  },
  lengends: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    overflowX: "auto",
    overflow: "hidden"
  },
  legendStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "0.5px solid #E3EAF4",
    backgroundColor: "#F8FAFE",
    borderRadius: "15px",
    padding: "2px 10px 2px 6px",
    margin: "1px 5px 0px 0px",
    fontSize: "10px",
    whiteSpace: "nowrap",
    fontWeight: 500,
    fontFamily: "Urbanist",
    lineHeight: "24px",
    color: "#17181d",
  },
  legendDot: {
    width: "6px",
    height: "6px",
    borderRadius: "20px",
    backgroundColor: "#3EB489",
    margin: "0 3px",
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff"
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)"
  }
};
// Customizable Area End
